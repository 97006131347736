/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";
import Cookies from 'js-cookie';
import $ from 'jquery';


// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import { resetGlobalQuery } from "assets/Constants/config-constant";
import ComboBox from "Features/ComboBox";
import { globalQuery } from "assets/Constants/config-constant";

// File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import { useAppContext } from "context/AppContext";
import { CircularProgress } from "@material-ui/core";
import ConfigConstants from "assets/Constants/config-constant";


function Login() {
    const configConstants = ConfigConstants();
    const { IS_AUTHENTICATED } = configConstants;

    // Set values in the context
    const { dispatch } = useAppContext();

    const navigate = useNavigate();
    const validate = useRef();
    const isAuthenticated = localStorage.getItem('isAuthenticated');

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [rememberMe, setRememberMe] = useState(false);

    const handleSetRememberMe = () => setRememberMe(!rememberMe);

    const comboDataAPiCall = useRef();

    // combobox option
    const [companies, setCompanies] = useState([]);
    const [branches, setBranches] = useState([]);
    const [financialYear, setFinancialYear] = useState([]);
    const [txtErpVersion, setErpVersion] = useState();

    const [cmb_company_id, setCompany] = useState();
    const [cmb_company_branch_id, setBranch] = useState();
    const [cmb_financialyear, setfinancialyear] = useState();
    const [showPassword, setShowPassword] = useState(false);

    // Loader
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (IS_AUTHENTICATED) {
            navigate("/DashBoard")
        } else {
            const fetchData = async () => {
                await fillCombos();
            };
            fetchData();
        }

    }, [])



    const fillCombos = async () => {
        try {
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("company_short_name");
            globalQuery.columns.push("company_branch_id");
            globalQuery.columns.push("company_branch_name");

            globalQuery.table = "cmv_company";
            const companies = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setCompanies(companies)

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("short_year");

            globalQuery.table = "amv_financial_year";
            const finanicalyear = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setFinancialYear(finanicalyear)

            resetGlobalQuery();
            globalQuery.columns.push("erp_version");
            globalQuery.table = "amv_company_settings";
            const apiErpVersion = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setErpVersion(apiErpVersion[0].erp_version)

            // Check Cookiees
            if (Cookies.get('company_id') !== undefined) {
                const company_id = Cookies.get('company_id')
                setCompany(company_id);
                FnFillComobosOnChange();

                const branch_id = Cookies.get('branch_id')
                setBranch(branch_id)

                const financial_year = Cookies.get('financial_year')

                // here check the coockies value is present in combo-option or not?
                let fin_year = finanicalyear.find(item => item.field_id === financial_year)
                if (fin_year !== undefined) {
                    setfinancialyear(fin_year.field_id);
                }
                const userName = Cookies.get('userName')
                setUsername(userName)
                const password = Cookies.get('password')
                setPassword(password)
                setRememberMe(true)
            }

        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }
    }

    const FnLogin = async () => {
        debugger
        try {
            setIsLoading(true)
            const checkIsValidate = await validate.current.validateForm('loginId');
            if (checkIsValidate === true) {
                const loginInfo = {
                    company_id: parseInt(cmb_company_id),
                    // company_branch_id: parseInt(cmb_company_branch_id),
                    // finanicalyear: cmb_financialyear,
                    username: username,
                    password: password,
                    remember_me: rememberMe
                }

                const method = {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(loginInfo)
                }
                const signin = await fetch(`${process.env.REACT_APP_BASE_URL}/api/auth/signin`, method)
                if (signin.status === 200) {
                    const response = await signin.json();

                    const apiResponse = response
                    console.log('Login page routes: ', response.pageRoutes);
                    // sets the logged in user details in session..
                    let short = financialYear.find(item => item.field_id === cmb_financialyear)
                    let compShort = companies.find(item => item.field_id === parseInt(cmb_company_id))

                    localStorage.setItem('isAuthenticated', true);
                    localStorage.setItem('financialShortYear', short.short_year);
                    localStorage.setItem('financialYear', short.field_name);
                    localStorage.setItem('financial_short_name', short.field_id);
                    localStorage.setItem('companyShortName', compShort.company_short_name);
                    localStorage.setItem('userType', apiResponse.user_type);
                    localStorage.setItem('userId', apiResponse.user_id);
                    localStorage.setItem('user_code', apiResponse.user_code);
                    localStorage.setItem('userName', apiResponse.username);
                    localStorage.setItem('companyID', compShort.field_id)
                    localStorage.setItem('companyBranchID', compShort.company_branch_id)
                    localStorage.setItem('company_name', compShort.field_name)
                    localStorage.setItem('company_branch_name', compShort.company_branch_name)
                    localStorage.setItem('companyAddress', apiResponse.company_branch_address)
                    sessionStorage.setItem('companyAddress', apiResponse.company_branch_address)
                    localStorage.setItem('company_branch_type', apiResponse.company_branch_type)
                    localStorage.setItem('user_access', JSON.stringify(response.userAccessDetails))
                    localStorage.setItem('page_routes', JSON.stringify(response.pageRoutes))

                    dispatch({ type: 'SET_IS_AUTHENTICATED', payload: true });
                    dispatch({ type: 'SET_COMPANY_ID', payload: apiResponse.company_id });
                    dispatch({ type: 'SET_COMPANY_BRANCH_ID', payload: apiResponse.company_branch_id });
                    dispatch({ type: 'SET_COMPANY_NAME', payload: apiResponse.company_name });
                    dispatch({ type: 'SET_COMPANY_BRANCH_NAME', payload: apiResponse.company_branch_name });
                    dispatch({ type: 'SET_SHORT_FINANCIAL_YEAR', payload: short.short_year });
                    dispatch({ type: 'SET_FINANCIAL_YEAR', payload: short.field_name });
                    dispatch({ type: 'SET_FINANCIAL_SHORT_NAME', payload: short.field_id });
                    dispatch({ type: 'SET_UserId', payload: apiResponse.user_id });
                    dispatch({ type: 'SET_UserType', payload: apiResponse.user_type });
                    dispatch({ type: 'SET_USER_CODE', payload: apiResponse.user_code });
                    dispatch({ type: 'SET_UserName', payload: apiResponse.username });
                    dispatch({ type: 'SET_COMPANY_BRANCH_TYPE', payload: apiResponse.company_branch_type });
                    dispatch({ type: 'SET_USER_ACCESS', payload: response.userAccessDetails });
                    dispatch({ type: 'SET_PAGE_ROUTES', payload: response.pageRoutes });

                    await checkComRMCatCount();
                    navigate("/DashBoard")

                } else {
                    alert('Please check the login info.');
                    localStorage.setItem('isAuthenticated', false);
                }

            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        } finally {
            setIsLoading(false)
        }
    };

    const checkComRMCatCount = async () => {
        // Category count from am company settings
        resetGlobalQuery();
        globalQuery.columns.push("*");
        globalQuery.conditions.push({
            field: "company_id",
            operator: "=",
            value: cmb_company_id
        });
        globalQuery.table = "am_company_settings"
        let masterList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)

        if (masterList.length !== 0) {
            let categoryCount = masterList[0].RawMaterialCategoryCount;
            console.log("am company settins count: ", masterList)

            localStorage.setItem('is_excess_allowed', masterList[0].is_excess_allowed)
            localStorage.setItem('erp_version', masterList[0].erp_version)
            localStorage.setItem('company_category_count', categoryCount)
            localStorage.setItem('after_decimal_places', masterList[0].after_decimal_places)

            dispatch({ type: 'ERP_VERSION', payload: masterList[0].erp_version });
            dispatch({ type: 'IS_EXCESS_ALLOWED', payload: masterList[0].is_excess_allowed });
            dispatch({ type: 'COMPANY_CATEGORY_COUNT', payload: categoryCount });
            dispatch({ type: 'AFTER_DECIMAL_PLACES', payload: masterList[0].after_decimal_places });

        }
    }

    const FnFillComobosOnChange = async () => {
        const selectedCompany = document.getElementById("cmb_company").value;
        if (selectedCompany !== "") {
            try {
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.conditions.push({
                    field: "company_id",
                    operator: "=",
                    value: selectedCompany
                });
                globalQuery.table = "cmv_company_branch";
                const branches = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setBranches(branches)

            } catch (error) {
                console.log("error: ", error);
                navigate('/Error')
            }
        }
    }



    const validateFields = () => {
        validate.current.validateFieldsOnChange('loginId');
    }

    function handleKeyDown(event) {
        debugger
        if (event.key === 'Enter') {
            FnLogin();
        }
    }

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <FrmValidations ref={validate} />

            {isLoading ?
                <div className="spinner-overlay"  >
                    <div className="spinner-container">
                        <CircularProgress color="primary" />
                        <span id="spinner_text" className="text-dark">Loading...</span>
                    </div>
                </div> :
                null}

            <form>
                <IllustrationLayout
                    title="Sign In"
                    // title={`Sign In ${txtErpVersion}`}
                    description={`Enter your email and password to sign in  `}
                    illustration={bgImage}
                >
                    <MDBox textAlign="center" color="info" fontWeight="small" > {txtErpVersion}</MDBox>

                    <MDBox component="form" role="form" id="loginId">

                        <MDBox mb={2}>

                            <select
                                className="form-select login-combo" fullWidth id="cmb_company" value={cmb_company_id} onChange={(e) => { FnFillComobosOnChange(); setCompany(e.target.value); validateFields(); }}>
                                <option value="" disabled="disabled" selected>Select / Company</option>
                                {companies?.map(company => (
                                    <option value={company.field_id}>  {company.field_name} </option>
                                ))};
                            </select>
                            <MDTypography variant="button" id="error_cmb_company" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                            </MDTypography>
                        </MDBox>

                        <MDBox mb={2}>
                            <select
                                className="form-select login-combo " fullWidth id="branchId" value={cmb_company_branch_id} onChange={(e) => { setBranch(e.target.value); validateFields(); }}>
                                <option value="" disabled="disabled" selected>Select / Branch</option>
                                {branches?.map(branch => (
                                    <option value={branch.field_id}>  {branch.field_name} </option>
                                ))}
                            </select>
                            <MDTypography variant="button" id="error_branchId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                            </MDTypography>
                        </MDBox>

                        <MDBox mb={2}>
                            <select
                                className="form-select login-combo" id="finalyearId" fullWidth value={cmb_financialyear} onChange={(e) => { setfinancialyear(e.target.value); validateFields(); }}>
                                <option value="" disabled="disabled" selected>Select / Financial year</option>
                                {financialYear?.map(finalyear => (
                                    <option value={finalyear.field_id}>  {finalyear.field_name} </option>
                                ))}
                            </select>
                            <MDTypography variant="button" id="error_finalyearId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                            </MDTypography>
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput type="UserName" id="usernameId" label="User Name" value={username} onChange={(e) => { setUsername(e.target.value); validateFields(); }} fullWidth />
                            <MDTypography variant="button" id="error_usernameId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                            </MDTypography>
                        </MDBox>

                        <MDBox mb={2}>
                            <MDBox mb={2}>
                                <MDInput
                                    type={showPassword ? 'text' : 'password'}
                                    id="passwordId"
                                    label="Password"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        validateFields();
                                    }}
                                    onKeyDown={handleKeyDown}


                                    fullWidth
                                />

                            </MDBox>

                            <MDTypography variant="button" id="error_passwordId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                            </MDTypography>
                        </MDBox>

                        {/* <MDBox display="flex" alignItems="center" ml={-1}>
                            <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                            <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                                onClick={handleSetRememberMe}

                                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                            >
                                &nbsp;&nbsp;Remember me
                            </MDTypography>
                        </MDBox> */}
                        <div class="container">
                            <div class="row">
                                <div class="col">
                                    <MDBox mt={2} mb={0}>
                                        <MDButton
                                            type="button"
                                            variant="gradient"
                                            onClick={FnLogin}
                                            color="info"
                                            size="large"
                                            fullWidth
                                        >
                                            Login
                                        </MDButton>
                                    </MDBox>
                                </div>

                            </div>
                        </div>
                        <MDBox mt={3} textAlign="center">
                            <MDTypography variant="button" color="text" to="/authentication/New Customer/cover">
                                <a href=""> New Customer</a> /<a href="">  Supplier Registration? {" "}</a>

                                <MDTypography
                                    component={Link}
                                    to="/Reset"
                                    variant="button"
                                    color="info"
                                    fontWeight="medium"
                                    textGradient
                                >
                                    Forgot Password
                                </MDTypography>
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </IllustrationLayout >
            </form>
        </>
    )
}


export default Login
