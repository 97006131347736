import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Wizard page components
import FrmMGeneralEntry from "./components/FrmMGeneralEntry";
import FrmMBillOfMaterialEntry from "./components/FrmMBillOfMaterialEntry";

// imports react bootstrap
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function getSteps() {
  return ["General Information", "Bill Of Material(BOM)"];
}

function FrmMProductRawMaterial() {
  useEffect(() => {
    ActionType();
  }, [])

  //In listing page we passed in navigate
  const { state } = useLocation();
  const { productRmId = 0, productRmName, keyForViewUpdate } = state || {}

  const [actionType, setActionType] = useState('')
  const [rm_id, setRmId] = useState(productRmId)
  const [rm_name, setRmName] = useState(productRmName)
  const [ptShortName, setPtShortName] = useState(localStorage.getItem('ptShortName'));
  const [key, setKey] = useState(keyForViewUpdate)

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = (product_rm_id, product_rm_name, product_type_name) => {
    if (product_rm_id !== 0) {
      setPtShortName(product_type_name)
      setRmId(product_rm_id)
      setRmName(product_rm_name)
      setActiveStep(parseInt(activeStep) + 1)
    }
  };
  const handleBack = (productRmId) => {
    setRmId(productRmId)
    setActiveStep(parseInt(activeStep) - 1)
  };

  const moveByTabs = (eventKey) => {
    if (rm_id !== 0 && ptShortName !== null) {
      setActiveStep(eventKey)
    }
  }

  function getStepContent() {
    switch (parseInt(activeStep)) {
      case 0:
        return <FrmMGeneralEntry goToNext={handleNext} rm_id={rm_id} />;
      case 1:
        return (
          <FrmMBillOfMaterialEntry
            goBack={handleBack}
            values={{
              productRmId: rm_id,
              productRmName: rm_name,
              ptShortName: ptShortName,
              keyForViewUpdate: key
            }}
          />
        );
      default:
        return null;
    }
  }

  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modification)');
        break;
      case 'view':
        setActionType('(View)');
        break;
      default:
        setActionType('(Creation)');
        break;
    }
  };


  return (
    <>
      <DashboardLayout>
        <div className='main_heding'>
          <label className='erp-form-label-lg main_heding'>Raw Material {actionType}</label>
        </div>
        <MDBox pt={3} pb={8} className="erp_form_tab_div">
          <Grid justifyContent="center" sx={{ my: 4 }} >
            <Grid item xs={12} lg={8} className="erp_form_container_tabs">
              <MDBox mt={-3} mx={2}>
                <Tabs
                  activeKey={activeStep}
                  id="uncontrolled-tab-example"
                  className="mb-3 selectedTab erp-form-Tabs" onSelect={eventKey => moveByTabs(eventKey)}>
                  {steps.map((label, index) => (
                    <Tab eventKey={index} title={label} ></Tab>
                  ))}
                </Tabs>
                {getStepContent()}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {/* <Footer /> */}
      </DashboardLayout>
    </>
  );
}

export default FrmMProductRawMaterial;
