import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'

const WorkSchedulePlanningReport = React.forwardRef(({ invoiceContent }, ref) => {
    const data = invoiceContent.items
    const squareBorder = {
        border: '2px solid #000',
        // padding: '10px',
    };

    // Custome's Contact Details.
    const contactDetails = invoiceContent.contactDetails;
    // Iterate over each element in the contactDetails array
    contactDetails.forEach(contact => {
        const { cust_contact_person, cust_contact_no } = contact;
    });

    const itemData = extractItemData(invoiceContent.items);
    function extractItemData(items) {
        // Extract relevant data from the first item in the array
        if (items.length > 0) {
            const firstItem = items[0];
            return {
                // department_name: firstItem.department_name,
                company: firstItem.company,
                company_branch: firstItem.company_branch,
                address: firstItem.address,
                phone: firstItem.phone,
                cell: firstItem.cell,
                email: firstItem.email,
                website: firstItem.website,
                GSTIN: firstItem.GSTIN,
                pan_no: firstItem.pan_no,
                state_name: firstItem.state_name,
                company_pincode: firstItem.company_pincode,
            };
        }
        // Return default values or handle the case where the array is empty
        return {
            // department_name: 0,
            company: 0,
            company_branch: 0,
            address: 0,
            phone: 0,
            cell: 0,
            email: 0,
            website: 0,
            GSTIN: 0,
            pan_no: 0,
            state_name: 0,
            company_pincode: 0,
        };
    }

    const invoiceHeader = (
        <>
            <div className='bordered border-bottom-0 px-0'>
                <div className='row mb-0 p-2'>
                    <div className="col-sm-2 text-center text-sm-start">
                        <img src={DakshabhiLogo} alt="master card" width="100" height="100" mt={1} />
                    </div>
                    <div className="col-sm-10 transformed">
                        <div className='erp-invoice-print-label-lg text-center'>
                            {itemData.company}
                        </div>
                        <div className="row">
                            <div className="text-center">
                                <div className='erp-invoice-print-label text-center'>
                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>{itemData.address} ({itemData.company_branch}) {itemData.state_name}-{itemData.company_pincode} </span>
                                    </div>
                                    {/* <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>{itemData.state_name}-{itemData.company_pincode}</span>
                                    </div> */}
                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>WebSite: <a href={itemData.website}>{itemData.website}</a> || Email: {itemData.email} </span>
                                    </div>
                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'> Phone: {itemData.phone} || Cell:{itemData.cell} </span>
                                    </div>
                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>GSTN No: {itemData.GSTIN} || PAN No:{itemData.pan_no} </span>
                                    </div>

                                    <div className='erp-invoice-print-label-lg'> Work Schedule Planning </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='erp-invoice-print-label-lg text-center'>
                    Work Schedule Planning
                </div> */}
                {/* <div className='row  border-top border-dark p-0 m-0'>
                    <div className="row p-0 m-0">
                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Service Engineer :</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.headers.assign_to_employee_name}</dd>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Customer:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.headers.customer_name}</dd>

                                <dl className="row mb-0 mt-0">
                                    <div className="col-sm-6">
                                        <dt className='erp-invoice-print-label-md-lg'>From Date: <span class="erp-invoice-print-label"> {invoiceContent.headers.from_date}</span></dt>
                                    </div>
                                    <div className="col-sm-6">
                                        <dt className='erp-invoice-print-label-md-lg'>To Date: <span class="erp-invoice-print-label"> {invoiceContent.headers.to_date}</span></dt>
                                    </div>
                                </dl>
                            </dl>
                        </div>
                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Manager :</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.headers.reporting_to_name}</dd>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Service:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.headers.service_name}</dd>
                            </dl>
                        </div>
                    </div>
                </div> */}

                <div className='row  border-top border-dark p-0 m-0'>
                    <div className="row p-0 m-0">
                        <dt className='col-sm-6 erp-invoice-print-label-md-lg'></dt>
                        <dt className="col-sm-6 erp-invoice-print-label-md-lg" style={{fontSize: '10px'}}>Customer Contact Details:</dt>
                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Service Engineer:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.headers.assign_to_employee_name}</dd>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Manager:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.headers.reporting_to_name}</dd>

                                <dl className="row mb-0 mt-0">
                                    <div className="col-sm-6">
                                        <dt className='erp-invoice-print-label-md-lg'>From Date: <span class="erp-invoice-print-label"> {invoiceContent.headers.from_date}</span></dt>
                                    </div>
                                    <div className="col-sm-6">
                                        <dt className='erp-invoice-print-label-md-lg'>To Date: <span class="erp-invoice-print-label"> {invoiceContent.headers.to_date}</span></dt>
                                    </div>
                                </dl>
                            </dl>
                        </div>
                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Customer:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.headers.customer_name}</dd>
                                {/* <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Order No.:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.headers.customer_order_no}</dd> */}
                                <dd className="col-sm-10 m-0 p-0">
                                    <dl className="row m-0 p-0">
                                        <dl className="col-sm-6 mb-0 pb-0">
                                            <dt className="erp-invoice-print-label-md-lg">Person Name:</dt>
                                        </dl>
                                        <dl className="col-sm-6 mb-0 pb-0">
                                            <dt className="erp-invoice-print-label-md-lg">Contact No:</dt>
                                        </dl>
                                    </dl>
                                    {contactDetails.map((contactData, index) => (
                                        <dl className="row m-0 p-0" key={index}>
                                            <dl className="col-sm-6 mb-0 pb-0">
                                                <dd className="erp-invoice-print-label">{contactData.cust_contact_person}</dd>
                                            </dl>
                                            <dl className="col-sm-6 mb-0 pb-0">
                                                <dd className="erp-invoice-print-label">{contactData.cust_contact_no}</dd>
                                            </dl>
                                        </dl>
                                    ))}
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )

    return (
        <>
            {/* <div className="container"> */}
            <div className="row">
                <div className="col-12">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">

                                {/* <!-- HEADER--> */}
                                <div className="row headerContentHt" style={{ padding: '0px 15px 0px' }}>
                                    {invoiceHeader}
                                </div>
                                {/* <!--Activities DETAILS--> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive overflow-hidden">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            {data.length !== 0 && (
                                                                <thead className='tblHeaderContentHt'>
                                                                    <tr>
                                                                        <th className="erp_invoice_table_th text-end" rowspan={2}>Sr No.</th>
                                                                        {/* <th className="erp_invoice_table_th text-center" rowspan={2} >Work Schedule Code</th> */}
                                                                        {/* <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Customer Name</th> */}
                                                                        {/* <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Customer Ord. No.</th> */}
                                                                        <th className="erp_invoice_table_th text-center col-3" rowspan={2}>Service Name</th>
                                                                        <th className="erp_invoice_table_th text-center col-3" rowspan={2}>Activity</th>
                                                                        <th className="erp_invoice_table_th text-center col-3" rowspan={2}>Activity Desc.</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Service Start Date</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Service &nbsp; End Date</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Activity Hrs.</th>
                                                                        {/* <th className="erp_invoice_table_th text-center col-1" rowspan={2}>From Range</th> */}
                                                                        {/* <th className="erp_invoice_table_th text-center col-1" rowspan={2}>To Range</th> */}
                                                                        {/* <th className="erp_invoice_table_th text-center col-1" rowspan={2}>Remark</th> */}
                                                                    </tr>
                                                                </thead>
                                                            )}
                                                            <tbody id='detailstableId'>
                                                                {
                                                                    data.map((item, index) =>
                                                                        <>
                                                                            <tr className={`tblBodyTrHt-${index}`}>
                                                                                <td className="erp_invoice_table_td text-center">{item.sr_no}</td>
                                                                                {/* <td className="erp_invoice_table_td" style={{ width: '10%' }}>{item.work_schedule_code} </td> */}
                                                                                {/* <td className="erp_invoice_table_td">{item.customer_name}</td> */}
                                                                                {/* <td className="erp_invoice_table_td">{item.customer_order_no}</td> */}
                                                                                <td className="erp_invoice_table_td">{item.product_material_name}</td>
                                                                                <td className="erp_invoice_table_td">{item.product_material_activity_name}</td>
                                                                                <td className="erp_invoice_table_td">{item.product_material_activity_description}</td>
                                                                                <td className="erp_invoice_table_td">{item.expected_service_schedule_start_date}</td>
                                                                                <td className="erp_invoice_table_td">{item.expected_service_schedule_end_date}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_material_activity_std_hour}</td>
                                                                                {/* <td className="erp_invoice_table_td">{item.from_range}</td> */}
                                                                                {/* <td className="erp_invoice_table_td">{item.to_range}</td> */}
                                                                                {/* <td className="erp_invoice_table_td">{item.remark}</td> */}
                                                                            </tr>
                                                                            {
                                                                                data.length - 1 === index ? <>
                                                                                    <tr id='footerTr' className='footerContentHt'>
                                                                                    </tr>
                                                                                </> : null
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
})
export default WorkSchedulePlanningReport;

