// React Imports.
import React, { useState, useRef, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';

// Material UI Imports.
import { CircularProgress } from "@material-ui/core";
import { RxCrossCircled } from "react-icons/rx";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Accordion, Button, Modal, Table } from "react-bootstrap";

// Required Common Components.
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ConfigConstants from 'assets/Constants/config-constant';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ComboBox from 'Features/ComboBox';
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";


function TServiceReportingEntry() {
    // Required Constant's Fields For Form.
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, FINANCIAL_SHORT_NAME, UserName, UserType, UserId } = configConstants;

    // Accessing Hooks From listing page.
    const { state } = useLocation();
    const { idList, keyForViewUpdate, customerApprove, ownApprove, compType = "Transaction", deletionKey = false } = state || {};

    const navigator = useNavigate();
    const validateFrms = useRef();
    const comboDataApi = useRef();
    const validateNumberDateInput = useRef();
    const generateAutoNoApi = useRef();

    const FnFormattedDateForInputField = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const currentDate = FnFormattedDateForInputField(new Date());

    // Form's Combo's Options hooks.
    const [customerOpts, setCustomerOpts] = useState([]);
    const [serviceEngineerOpts, setServiceEngineerOpts] = useState([]);
    const [customerOrderOpts, setCustomerOrderOpts] = useState([]);
    const [mannualWorkSchedulesOpts, setMannualWorkSchedulesOpts] = useState([]);
    const [approvedByEmplOpts, setApprovedByEmplOpts] = useState([]);

    // Form Fields hooks.
    const [service_reporting_master_transaction_id, setServiceReportingMasterTransactionId] = useState(idList !== null ? idList.service_reporting_master_transaction_id : 0);
    const [rb_service_reporting_type, setServiceReportingCreationType] = useState("S");
    const [txt_service_reporting_code, setServiceReportingCode] = useState('');
    const [dt_service_reporting_date, setServiceReportingDate] = useState(currentDate);
    const [cmb_reporting_status, setReportingStatus] = useState('P');
    const [cmb_assigned_service_engineer_id, setAssignedServiceEngineerId] = useState('');
    const [cmb_service_engineer_reporting_to_id, setServiceEngineerReportingToId] = useState('');
    const [txt_service_engineer_reporting_to_name, setServiceEngineerReportingToName] = useState('');
    const [cmb_customer_id, setCustomerId] = useState('');
    const [cmb_customer_order_no, setCustomerOrderNo] = useState('');
    const [cmb_work_schedule_code, setWorkScheduleCode] = useState('');         // For mannual work schedules.
    const [dt_from_date, setFromDate] = useState('');
    const [dt_to_date, setToDate] = useState('');

    // Hooks for the 
    const [companySettings, setCompanySettings] = useState(null);
    const [customerApprovalIsRequired, setCustomerApprovalIsRequired] = useState(false);    // It will used everyWhere
    let customerApprovalIsRequired_var = false;                                             // It will added because it will not accessible when setting the acctivitiestbl data on view/update/approve.

    // Table's Data Hooks.
    const [activitiesTblData, setActivitiesTblData] = useState([]);
    const [storedActivitiesTblData, setStoredActivitiesTblData] = useState([]);

    const [receivedCustMaterialData, setReceivedCustMaterialData] = useState([]);   // For Customer's material receipt Table.    
    const [storedReceivedCustMaterialData, setStoredReceivedCustMaterialData] = useState([]);   // For Customer's material receipt Table.    

    // helping hooks.
    const [isLoading, setIsLoading] = useState(false);

    // Hooks for handle the accordions.
    const [openActivitiesAccord, setOpenActivitiesAccord] = useState(true);
    const [openCustomerMaterialAccord, setOpenCustomerMaterialAccord] = useState(true);

    //Error Msg Modal
    const FnCloseErrorModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Succ Msg Modal
    const FnCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        moveToListing();
    };
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    //Delete Popup modal.
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const FnCloseDeleteModal = () => setShowDeleteModal(false);

    const ActionType = () => {
        switch (keyForViewUpdate) {
            case 'view':
                if (deletionKey === true) {
                    return '(Deletion)';
                } else {
                    return '(View)';
                }
            case 'approve':
                if (ownApprove === true) {
                    return '(Own Approval)';
                } else if (customerApprove === true) {
                    return '(Customer Approval)';
                }
            case 'update': return '(Modification)';
            default: return '(Creation)';
        }
    };


    const FnSetFieldsByUserAccess = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                await validateFrms.current.readOnly("ServiceReportingHeaderFormId");
                $('input[name="rb_service_reporting_type"]').prop('disabled', true);
                break;

            case 'approve':
            case 'view':
                await validateFrms.current.readOnly("ServiceReportingHeaderFormId");
                $('input[name="rb_service_reporting_type"]').prop('disabled', true);
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        const initPageLayout = async () => {
            setIsLoading(true);
            await FnSetFieldsByUserAccess();
            await FnFillCombosOnPageLoad();
            if (idList !== null) {
                await FnCheckUpdateResponse();
            } else {
                await FnGenerateServiceReportingCode();
            }
            await FnSetFieldsByUserAccess();
            setIsLoading(false);
        };
        initPageLayout()
    }, []);

    const FnGenerateServiceReportingCode = async () => {
        const apiCallResp = await generateAutoNoApi.current.generateTAutoNo("mt_service_reporting_master", "service_reporting_code", "", 'SR', "5");
        setServiceReportingCode(apiCallResp);
        return apiCallResp;
    };

    // Function for filling the combo-boxes on page load.
    const FnFillCombosOnPageLoad = async () => {
        try {
            // Load the customer options.
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name']
            globalQuery.table = "cmv_customer_summary"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataApi.current.fillFiltersCombo(globalQuery).then(customerList => { setCustomerOpts(customerList); });

            // Load the Services options.
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'reporting_to_name', 'reporting_to']
            globalQuery.table = "cmv_employee_list"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataApi.current.fillFiltersCombo(globalQuery)
                .then(employeeList => {
                    setServiceEngineerOpts(employeeList);
                    setApprovedByEmplOpts(employeeList);

                    // Set the default service-engineer.
                    if (idList === null && keyForViewUpdate === '') {
                        const defaultServiceEngineer = employeeList.find((employee) => employee.field_id === parseInt(UserId));
                        if (defaultServiceEngineer !== undefined) {
                            setAssignedServiceEngineerId(defaultServiceEngineer.field_id);
                            setServiceEngineerReportingToId(defaultServiceEngineer.reporting_to);
                            setServiceEngineerReportingToName(defaultServiceEngineer.reporting_to_name);
                        }
                    }
                });

            // mannualWorkSchedulesOpts
            // Load the mannual work schedules options.
            resetGlobalQuery();
            globalQuery.columns = ['work_schedule_code'];
            globalQuery.table = "mt_service_planning_master"
            globalQuery.conditions.push({ field: "work_schedule_creation_type", operator: "=", value: 'M' });
            if (keyForViewUpdate === '') { globalQuery.conditions.push({ field: "work_schedule_status", operator: "IN", values: ['P', 'R'] }); }
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataApi.current.fillFiltersCombo(globalQuery).then(mannualWorkSchedulesList => { setMannualWorkSchedulesOpts(mannualWorkSchedulesList); });

            if (keyForViewUpdate === 'approve') {
                // Load the customer options.
                resetGlobalQuery();
                globalQuery.columns = ['customer_service_approval_flag']
                globalQuery.table = "am_company_settings"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getCompSettings = await comboDataApi.current.fillFiltersCombo(globalQuery);
                if (getCompSettings.length > 0) {
                    setCompanySettings(getCompSettings);
                    setCustomerApprovalIsRequired(getCompSettings[0].customer_service_approval_flag);
                    customerApprovalIsRequired_var = getCompSettings[0].customer_service_approval_flag;
                }
            }

        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }

    const FnCombosOnChange = async (comboName) => {
        try {
            switch (comboName) {
                case 'CreationType':
                    setActivitiesTblData([]);
                    setCustomerId('');
                    setCustomerOrderNo('');
                    setCustomerOrderOpts([]);
                    setWorkScheduleCode('');
                    validateFields();
                    setReceivedCustMaterialData([]);
                    break;

                case 'Customer':
                    setIsLoading(true);
                    let selectedCustomer = document.getElementById('cmb_customer_id').value;
                    if (selectedCustomer !== '') {
                        // // Load the customer orders.From customer Order.
                        resetGlobalQuery();
                        globalQuery.columns = ['DISTINCT(customer_order_no)']
                        globalQuery.table = "mtv_service_planning_details"
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: parseInt(selectedCustomer) });
                        globalQuery.conditions.push({ field: "work_schedule_status", operator: "NOT IN", values: ['A', 'Q', 'C', 'F'] });
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.orderBy = ['service_planning_master_transaction_id DESC']

                        const getCustomerOrdersApiCall = await comboDataApi.current.fillFiltersCombo(globalQuery);
                        if (getCustomerOrdersApiCall.length > 0) {
                            setCustomerOrderOpts(getCustomerOrdersApiCall);
                            $('#error_cmb_customer_order_no').hide();
                        } else {
                            setCustomerOrderOpts([]);
                            $('#error_cmb_customer_order_no').text('No customer orders are available...!');
                            $('#error_cmb_customer_order_no').show();
                        }
                    } else {
                        setCustomerOrderOpts([]);
                    }
                    setActivitiesTblData([]);
                    setReceivedCustMaterialData([]);
                    setIsLoading(false);
                    break;

                case 'Engineer':
                    setIsLoading(true);
                    let assignedEngineer = document.getElementById('cmb_assigned_service_engineer_id').value;
                    if (assignedEngineer !== '') {
                        // Set it's reporting person.
                        const selectedEngineer = serviceEngineerOpts.find((employee) => employee.field_id === parseInt(assignedEngineer));
                        if (selectedEngineer) {
                            setServiceEngineerReportingToId(selectedEngineer.reporting_to);
                            setServiceEngineerReportingToName(selectedEngineer.reporting_to_name);
                        } else {
                            setServiceEngineerReportingToId('');
                            setServiceEngineerReportingToName('');
                        }
                    } else {
                        setServiceEngineerReportingToId('');
                        setServiceEngineerReportingToName('');
                    }
                    // setActivitiesTblData([]);
                    // setReceivedCustMaterialData([]);
                    setIsLoading(false);
                    break;

                default:
                    break;
            }
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }

    // ******************************* Details Table Functionality Starts. *************************************
    // Load the activities from the service planning using selected filters.
    const FnGetActivitiesFromPlanning = async (alreadyStoredActivitiesTblData, serviceReportCreationType) => {
        try {

            setIsLoading(true);
            resetGlobalQuery();
            globalQuery.columns = ['*'];
            globalQuery.table = "mtv_service_planning_activities"
            globalQuery.conditions.push({ field: "assign_to_employee_id", operator: "=", value: document.getElementById('cmb_assigned_service_engineer_id').value, });
            if (serviceReportCreationType === 'S') {
                globalQuery.conditions.push({ field: "customer_id", operator: "=", value: document.getElementById('cmb_customer_id').value, });
                globalQuery.conditions.push({ field: "customer_order_no", operator: "=", value: document.getElementById('cmb_customer_order_no').value, });
            } else {
                globalQuery.conditions.push({ field: "work_schedule_code", operator: "=", value: document.getElementById('cmb_work_schedule_code').value, });
            }
            globalQuery.conditions.push({ field: "work_schedule_status", operator: "IN", values: ['P', 'R', 'I'] });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            if (dt_from_date !== '') { globalQuery.conditions.push({ field: "expected_service_schedule_start_date", operator: ">=", value: document.getElementById('dt_from_date').value, }); }
            if (dt_to_date !== '') { globalQuery.conditions.push({ field: "expected_service_schedule_end_date", operator: "<=", value: document.getElementById('dt_to_date').value, }); }
            const getActivities = await comboDataApi.current.removeCatcheFillCombo(globalQuery);
            if (getActivities.length !== 0) {
                FnMergeActivitiesTblData(getActivities, alreadyStoredActivitiesTblData);
                $('#error_activities').hide();
            } else {
                if (keyForViewUpdate === 'view') {
                    setActivitiesTblData(alreadyStoredActivitiesTblData);
                } else {
                    $('#error_activities').text('No activities are available...!');
                    $('#error_activities').show();
                    setActivitiesTblData([]);
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }

    const FnMergeActivitiesTblData = (latestData, alreadyStoredData) => {
        let selectedReportingDate = document.getElementById('dt_service_reporting_date').value;
        if (service_reporting_master_transaction_id === 0) {
            setActivitiesTblData((prevArray) => {
                const updatedArray = [
                    ...prevArray.filter(prevItem =>
                        latestData.some(newItem => newItem.service_planning_activities_transaction_id === prevItem.service_planning_activities_transaction_id)),
                    ...latestData.filter(newItem =>
                        !prevArray.some(prevItem => prevItem.service_planning_activities_transaction_id === newItem.service_planning_activities_transaction_id))
                        .map((activityFromPlanning) => {
                            const isOverdue = selectedReportingDate > activityFromPlanning.expected_service_schedule_start_date;
                            const convertedActivity = {
                                // Data From Planning Activities.
                                work_schedule_code: activityFromPlanning.work_schedule_code,   // Required for backend.
                                product_material_id: activityFromPlanning.product_material_id,
                                product_material_name: activityFromPlanning.product_material_name,
                                activity_id: activityFromPlanning.product_activity_id,
                                product_material_activity_name: activityFromPlanning.product_material_activity_name,
                                product_material_activity_description: activityFromPlanning.product_material_activity_description,
                                expected_service_schedule_start_date: activityFromPlanning.expected_service_schedule_start_date,
                                expected_service_schedule_end_date: activityFromPlanning.expected_service_schedule_end_date,
                                product_material_activity_std_hour: activityFromPlanning.product_material_activity_std_hour,
                                service_planning_activities_transaction_id: activityFromPlanning.service_planning_activities_transaction_id,
                                service_planning_details_transaction_id: activityFromPlanning.service_planning_details_transaction_id,

                                // Default Entry Fields.
                                actual_hour: '',
                                is_overdue: isOverdue,
                                extra_activities: '',
                                approved_by_id: '',
                                approved_date: '',
                                operator_status: 'P',
                                department_head_status: 'P',
                                customer_status: 'P',
                                customer_approved_date: '',
                                approval_remark: '',
                                customer_remark: '',
                            }
                            return convertedActivity;
                        })
                ];
                return updatedArray;
            });

        } else {
            setActivitiesTblData((prevArray) => {
                const filteredArray = latestData.map(activityFromPlanning => {
                    if (!alreadyStoredData.some(storedActivity => storedActivity.service_planning_activities_transaction_id === activityFromPlanning.service_planning_activities_transaction_id)) {
                        const isOverdue = selectedReportingDate > activityFromPlanning.expected_service_schedule_start_date;
                        const convertedActivity = {
                            // Data From Planning Activities.
                            work_schedule_code: activityFromPlanning.work_schedule_code,   // Required for backend.
                            product_material_id: activityFromPlanning.product_material_id,
                            product_material_name: activityFromPlanning.product_material_name,
                            activity_id: activityFromPlanning.product_activity_id,
                            product_material_activity_name: activityFromPlanning.product_material_activity_name,
                            product_material_activity_description: activityFromPlanning.product_material_activity_description,
                            expected_service_schedule_start_date: activityFromPlanning.expected_service_schedule_start_date,
                            expected_service_schedule_end_date: activityFromPlanning.expected_service_schedule_end_date,
                            product_material_activity_std_hour: activityFromPlanning.product_material_activity_std_hour,
                            service_planning_activities_transaction_id: activityFromPlanning.service_planning_activities_transaction_id,
                            service_planning_details_transaction_id: activityFromPlanning.service_planning_details_transaction_id,

                            // Default Entry Fields.
                            actual_hour: '',
                            is_overdue: isOverdue,
                            extra_activities: '',
                            approved_by_id: '',
                            approved_date: '',
                            operator_status: 'P',
                            department_head_status: 'P',
                            customer_status: 'P',
                            customer_approved_date: '',
                            approval_remark: '',
                            customer_remark: '',
                        }
                        return convertedActivity;
                    } else {
                        let storedActivity = alreadyStoredData.find(storedActivity => storedActivity.service_planning_activities_transaction_id === activityFromPlanning.service_planning_activities_transaction_id)
                        const convertedStoredActivity = {
                            // Data From Planning Activities.
                            service_reporting_activities_details_transaction_id: storedActivity.service_reporting_activities_details_transaction_id,
                            work_schedule_code: activityFromPlanning.work_schedule_code,   // Required for backend.
                            product_material_id: activityFromPlanning.product_material_id,
                            product_material_name: activityFromPlanning.product_material_name,
                            activity_id: activityFromPlanning.product_activity_id,
                            product_material_activity_name: activityFromPlanning.product_material_activity_name,
                            product_material_activity_description: activityFromPlanning.product_material_activity_description,
                            expected_service_schedule_start_date: activityFromPlanning.expected_service_schedule_start_date,
                            expected_service_schedule_end_date: activityFromPlanning.expected_service_schedule_end_date,
                            product_material_activity_std_hour: activityFromPlanning.product_material_activity_std_hour,
                            service_planning_activities_transaction_id: activityFromPlanning.service_planning_activities_transaction_id,
                            service_planning_details_transaction_id: activityFromPlanning.service_planning_details_transaction_id,

                            // Default Entry Fields.
                            actual_hour: storedActivity.actual_hour,
                            extra_activities: storedActivity.extra_activities,
                            is_overdue: storedActivity.is_overdue,
                            approved_by_id: storedActivity.approved_by_id,
                            approved_by_name: storedActivity.approved_by_name,
                            approved_date: storedActivity.approved_date,
                            operator_status: storedActivity.operator_status,
                            operator_status_desc: storedActivity.operator_status_desc,
                            department_head_status: storedActivity.department_head_status,
                            department_head_status_desc: storedActivity.department_head_status_desc,
                            customer_status: storedActivity.customer_status,
                            customer_status_desc: storedActivity.customer_status_desc,
                            customer_approved_date: storedActivity.customer_approved_date,
                            approval_remark: storedActivity.approval_remark,
                            customer_remark: storedActivity.customer_remark,
                            modified_by: UserName,
                        }

                        // At the time of the own approval set the default approve_by id for logged in userid.
                        if (ownApprove && (convertedStoredActivity.approved_by_id === '' || convertedStoredActivity.approved_by_id === null)) {
                            convertedStoredActivity.approved_by_id = UserId
                        }

                        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && ['P', 'R'].includes(storedActivity.operator_status)) {
                            return convertedStoredActivity; // Means it came add/update
                        } else if (ownApprove && ['P', 'R'].includes(storedActivity.department_head_status)) {
                            return convertedStoredActivity; // Means it came DeptHead Approval
                        } else if (ownApprove && !customerApprovalIsRequired_var && (['P', 'R'].includes(storedActivity.department_head_status) || ['P', 'R'].includes(storedActivity.customer_status))) {
                            return convertedStoredActivity; // Means it came DeptHead Approval but customer approval is not required.
                        } else if (customerApprove && ['P', 'R'].includes(storedActivity.customer_status)) {
                            return convertedStoredActivity; // Means it came Customer Approval
                        } else if (keyForViewUpdate === 'view') {
                            return convertedStoredActivity; // Means it will come for the view.
                        } else {
                            return null;
                        }
                    }
                }).filter(Boolean); // Remove null entries from the array

                return filteredArray;
            });
        }
    }


    const FnDisplayPlannedActivitiesTbl = useMemo(() => {
        return <>
            {
                activitiesTblData.length > 0
                    ? <>
                        <Table className="erp_table erp_table_scroll" id='serviceActivitiesTbl' bordered striped>
                            <thead className="erp_table_head">
                                <tr>
                                    <th className={`erp_table_th`}> Action
                                        {/* <Form.Check className="selectAllServiceActivities" type="checkbox"
                                            name="selectAllServiceActivities" id={'selectAllServiceActivities'}
                                            onChange={async (e) => { FnCheckBoxOnChage('selectAllServiceActivities'); }} optional="optional"
                                            {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}
                                            label="Select"
                                        /> */}
                                    </th>
                                    <th className={`erp_table_th`} style={{ width: '20px', paddingRight: '100px' }}>Service Name</th>
                                    <th className={`erp_table_th`} style={{ width: '20px', paddingRight: '100px' }}>Activity Name</th>
                                    <th className={`erp_table_th`} style={{ width: '20px', paddingRight: '150px' }}>Activity Desc.</th>
                                    <th className={`erp_table_th`}>Service Start Date</th>
                                    <th className={`erp_table_th`}>Service End Date</th>
                                    <th className={`erp_table_th`}>STD. Hrs.</th>
                                    <th className={`erp_table_th`}>Actual Hrs.</th>
                                    <th className={`erp_table_th`} style={{ width: '20px', paddingRight: '50px' }}>Extra Activities</th>
                                    <th className={`erp_table_th`}>Is Overdue</th>
                                    <th className={`erp_table_th`} style={{ paddingRight: '20px' }}>Operator Status</th>
                                    <th className={`erp_table_th`} style={{ paddingRight: '50px' }}>Approved By </th>
                                    <th className={`erp_table_th`} style={{ paddingRight: '50px' }}>Approval Remark </th>
                                    <th className={`erp_table_th`}>Dept. Head Status</th>
                                    <th className={`erp_table_th`}>Customer Status</th>
                                    <th className={`erp_table_th`} style={{ paddingRight: '50px' }}>Customer Remark </th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    activitiesTblData?.map((activity, Index) =>
                                        <tr rowIndex={Index}>
                                            <td className={`erp_table_td`}>
                                                <Form.Check className="erp-form-label selectServiceActivity" type="checkbox"
                                                    name="selectServiceActivity" id={'selectServiceActivity_' + Index}
                                                    value={activity.service_planning_activities_transaction_id}
                                                    onChange={async (e) => { FnCheckBoxOnChage('PartiallyServiceActivitySelection'); }} optional="optional"
                                                    {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}
                                                />
                                            </td>
                                            <td className={`erp_table_td`} style={{ width: '200px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{activity.product_material_name}</td>
                                            <td className={`erp_table_td`} style={{ width: '200px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{activity.product_material_activity_name}</td>
                                            <td className={`erp_table_td`} style={{ width: '250px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{activity.product_material_activity_description}</td>
                                            <td className={`erp_table_td`}>{validateNumberDateInput.current.formatDateToDDMMYYYY(activity.expected_service_schedule_start_date)}</td>
                                            <td className={`erp_table_td`}>{validateNumberDateInput.current.formatDateToDDMMYYYY(activity.expected_service_schedule_end_date)}</td>
                                            <td className={`erp_table_td text-end`}>{activity.product_material_activity_std_hour}</td>
                                            <td className={`erp_table_td text-end`}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <Form.Control type="text" id={'actual_hour_' + Index} className="erp_input_field mb-0 text-end" value={activity.actual_hour}
                                                                maxLength='19' onChange={(e) => { FnUpdateActivitiesTblData(activity, e); }} Headers='actual_hour' />
                                                        </>
                                                        : activity.actual_hour
                                                }
                                            </td>
                                            <td className={`erp_table_td`} style={{ width: '250px', display: 'table-cell', whiteSpace: 'break-spaces' }}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <Form.Control as="textarea" id={'extra_activities_' + Index} rows={1} className="erp_input_field mb-0" value={activity.extra_activities}
                                                                Headers="extra_activities" onChange={(e) => { FnUpdateActivitiesTblData(activity, e); }} maxlength="1000" />
                                                        </>
                                                        : activity.extra_activities
                                                }
                                            </td>
                                            <td className={`erp_table_td`}>
                                                <Form.Check className="erp-form-label text-center" type="checkbox" id={'is_overdue_' + Index} disabled checked={activity.is_overdue} />
                                            </td>
                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view'
                                                        ? <>
                                                            <select id={`operator_status_${Index}`} value={activity.operator_status} className="form-select form-select-sm"
                                                                Headers="operator_status" onChange={(e) => { FnUpdateActivitiesTblData(activity, e); }}
                                                                {...(keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}>
                                                                <option value="P">Pending</option>
                                                                <option value="A">Completed</option>
                                                                <option value="R">Rework</option>
                                                                <option value="Q">Rework Completed</option>
                                                                <option value="C">Canceled</option>
                                                                <option value="Z">Pree Closed</option>
                                                                <option value="F">Payment Done</option>
                                                            </select>
                                                        </>
                                                        : activity.operator_status_desc
                                                }
                                            </td>
                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view'
                                                        ? <>
                                                            <select id={`approved_by_id_${Index}`} value={activity.approved_by_id} className="form-select form-select-sm"
                                                                Headers="approved_by_id" onChange={(e) => { FnUpdateActivitiesTblData(activity, e); }}
                                                                {...(keyForViewUpdate !== 'approve' || (keyForViewUpdate === 'approve' && customerApprove) ? { disabled: 'disabled' } : {})}>
                                                                <option value="">Select</option>
                                                                {approvedByEmplOpts?.map(employee => (<option value={employee.field_id}>{employee.field_name}</option>))}
                                                            </select>
                                                        </>
                                                        : activity.approved_by_name
                                                }
                                            </td>
                                            <td className={`erp_table_td`} style={{ width: '250px', display: 'table-cell', whiteSpace: 'break-spaces' }}>
                                                {
                                                    keyForViewUpdate !== 'view'
                                                        ? <>
                                                            <Form.Control as="textarea" id={'approval_remark_' + Index} rows={1}
                                                                className="erp_input_field mb-0" value={activity.approval_remark}
                                                                Headers="approval_remark" onChange={(e) => { FnUpdateActivitiesTblData(activity, e); }}
                                                                maxlength="1000" {...(keyForViewUpdate !== 'approve' || (keyForViewUpdate === 'approve' && customerApprove) ? { disabled: 'disabled' } : {})} />
                                                        </>
                                                        : activity.approval_remark
                                                }
                                            </td>

                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view'
                                                        ? <>
                                                            <select id={`department_head_status_${Index}`} value={activity.department_head_status} className="form-select form-select-sm"
                                                                Headers="department_head_status" {...(keyForViewUpdate !== 'approve' || (keyForViewUpdate === 'approve' && customerApprove) ? { disabled: 'disabled' } : {})} onChange={(e) => { FnUpdateActivitiesTblData(activity, e); }}>
                                                                <option value="P">Pending</option>
                                                                <option value="A">Completed</option>
                                                                <option value="R">Rework</option>
                                                                <option value="Q">Rework Completed</option>
                                                                <option value="C">Canceled</option>
                                                                <option value="Z">Pree Closed</option>
                                                                <option value="F">Payment Done</option>
                                                            </select>
                                                        </>
                                                        : activity.department_head_status_desc
                                                }
                                            </td>

                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view'
                                                        ? <>
                                                            <select id={`customer_status_${Index}`} value={activity.customer_status} className="form-select form-select-sm"
                                                                Headers="customer_status" onChange={(e) => { FnUpdateActivitiesTblData(activity, e); }}
                                                                {...(keyForViewUpdate === 'approve' && customerApprovalIsRequired && UserType === 'customer' ? {}  // means customer-approval required
                                                                    : keyForViewUpdate === 'approve' && !customerApprovalIsRequired ? {}  // means customer-approval not required
                                                                        : { disabled: 'disabled' }
                                                                )} >
                                                                <option value="P">Pending</option>
                                                                <option value="A">Completed</option>
                                                                <option value="R">Rework</option>
                                                                <option value="Q">Rework Completed</option>
                                                                <option value="C">Canceled</option>
                                                                <option value="Z">Pree Closed</option>
                                                                <option value="F">Payment Done</option>
                                                            </select>
                                                        </>
                                                        : activity.customer_status_desc
                                                }
                                            </td>

                                            <td className={`erp_table_td`} style={{ width: '250px', display: 'table-cell', whiteSpace: 'break-spaces' }}>
                                                {
                                                    keyForViewUpdate !== 'view'
                                                        ? <>
                                                            <Form.Control as="textarea" id={'customer_remark_' + Index} rows={1}
                                                                className="erp_input_field mb-0" value={activity.customer_remark}
                                                                Headers="customer_remark" onChange={(e) => { FnUpdateActivitiesTblData(activity, e); }}
                                                                maxlength="1000"
                                                                {...(keyForViewUpdate === 'approve' && customerApprovalIsRequired && UserType === 'customer' ? {} // means customer-approval required
                                                                    : keyForViewUpdate === 'approve' && !customerApprovalIsRequired ? {}  // means customer-approval not required
                                                                        : { disabled: 'disabled' }
                                                                )} />
                                                        </>
                                                        : activity.customer_remark
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </>
                    : <>
                        {
                            keyForViewUpdate === 'approve' || keyForViewUpdate === 'update'
                                ? <>
                                    <MDTypography variant="button" className="erp_validation" fontWeight="regular" color="error">No pending records are available...!  </MDTypography>
                                </>
                                : null
                        }
                    </>
            }
        </>
    }, [activitiesTblData, approvedByEmplOpts]);

    const FnUpdateActivitiesTblData = (currentActivity, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        let inputValue = event.target.value;

        switch (clickedColName) {
            case 'actual_hour':
                currentActivity[clickedColName] = validateNumberDateInput.current.decimalNumber(inputValue.toString(), 4) || 0
                break;

            case 'approved_by_id':
            case 'extra_activities':
            case 'approval_remark':
            case 'customer_remark':
            case 'operator_status':
            case 'customer_status':
            case 'department_head_status':
                currentActivity[clickedColName] = inputValue.toString();
                break;

            default:
                break;
        }

        const updatedActivitiesData = [...activitiesTblData]
        const currentActivityIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedActivitiesData[currentActivityIndex] = currentActivity;
        setActivitiesTblData(updatedActivitiesData);
        // Check the checkbox on input-field change.
        $('#selectServiceActivity_' + currentActivityIndex).prop('checked', true);
        FnCheckBoxOnChage('PartiallyServiceActivitySelection');
        delete event.target.parentElement.dataset.tip;              // Delete the tooltip.
    }

    // ******************************* Details Table Functionality Ends. *************************************

    // ******************************* Customer Material Table Functionality Starts. *************************************
    const FnGetCutomerReceivedMaterial = async (alreadyStoredCustomerMaterialData) => {
        resetGlobalQuery();
        globalQuery.columns = ['*'];
        globalQuery.table = "mtv_sales_order_services_customer_material_receipt"
        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: document.getElementById('cmb_customer_id').value, });
        globalQuery.conditions.push({ field: "customer_order_no", operator: "=", value: document.getElementById('cmb_customer_order_no').value, });
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        if (!['view'].includes(keyForViewUpdate)) {
            globalQuery.conditions.push({ field: "'material_returned_quantity'", operator: "!=", value: 'material_quantity' });
        }
        let receivedCustomerMaterials = await comboDataApi.current.removeCatcheFillCombo(globalQuery);
        if (receivedCustomerMaterials.length !== 0) {
            FnMergeCustomerMaterialData(receivedCustomerMaterials, alreadyStoredCustomerMaterialData);
        }
    }

    const FnMergeCustomerMaterialData = async (latestData, alreadyStoredData) => {
        if (service_reporting_master_transaction_id === 0) {
            setReceivedCustMaterialData((prevArray) => {
                const updatedArray = [
                    ...prevArray.filter(prevItem =>
                        latestData.some(newItem => newItem.sales_order_customer_material_receipt_transaction_id === prevItem.sales_order_customer_material_receipt_transaction_id)),
                    ...latestData.filter(newItem =>
                        !prevArray.some(prevItem => prevItem.sales_order_customer_material_receipt_transaction_id === newItem.sales_order_customer_material_receipt_transaction_id))
                        .map((receivedCustomerMaterial) => {
                            const convertedObj = {
                                ...receivedCustomerMaterial,
                                service_reporting_customer_material_return_transaction_id: 0,
                                material_return_quantity: receivedCustomerMaterial.material_quantity - receivedCustomerMaterial.material_returned_quantity,
                                max_return_quantity: receivedCustomerMaterial.material_quantity - receivedCustomerMaterial.material_returned_quantity,
                                prev_material_return_quantity: 0,
                                working_desc: '',
                            }
                            return convertedObj;
                        })
                ];
                return updatedArray;
            });
        } else {
            setReceivedCustMaterialData((prevArray) => {
                const filteredArray = latestData.map(receivedCustomerMaterial => {
                    // Means it will not stored in reporting.
                    if (!alreadyStoredData.some(storedMaterial => storedMaterial.sales_order_customer_material_receipt_transaction_id === receivedCustomerMaterial.sales_order_customer_material_receipt_transaction_id)) {
                        const convertedObj = {
                            ...receivedCustomerMaterial,
                            service_reporting_customer_material_return_transaction_id: 0,
                            material_return_quantity : 0,
                            // material_return_quantity: receivedCustomerMaterial.material_quantity - receivedCustomerMaterial.material_returned_quantity,
                            max_return_quantity: receivedCustomerMaterial.material_quantity - receivedCustomerMaterial.material_returned_quantity,
                            prev_material_return_quantity: 0,
                            working_desc: '',
                        }
                        return convertedObj;

                    } else {
                        let storedMaterialInReporting = alreadyStoredData.find(storedMaterial => storedMaterial.sales_order_customer_material_receipt_transaction_id === receivedCustomerMaterial.sales_order_customer_material_receipt_transaction_id)
                        const convertedObj = {
                            ...storedMaterialInReporting,
                            service_reporting_customer_material_return_transaction_id: storedMaterialInReporting.service_reporting_customer_material_return_transaction_id,
                            material_return_quantity: storedMaterialInReporting.material_return_quantity,
                            max_return_quantity: (receivedCustomerMaterial.material_quantity - receivedCustomerMaterial.material_returned_quantity) + storedMaterialInReporting.material_return_quantity,
                            prev_material_return_quantity: storedMaterialInReporting.material_return_quantity,
                            material_status: receivedCustomerMaterial.material_status,
                            material_returned_quantity: receivedCustomerMaterial.material_returned_quantity,
                            modified_by: UserName,
                        }
                        return convertedObj;
                    }
                }).filter(Boolean); // Remove null entries from the array
                return filteredArray;
            });
        }
    }

    const FnDisplayCustomerMaterialReceiptTbl = useMemo(() => {
        return <> <Table className="erp_table erp_table_scroll" id="customerMaterialReceiptTbl" bordered striped hover>
            <thead className='erp_table_head'>
                <tr>
                    <th className="erp_table_th">Sr. No.</th>
                    <th className="erp_table_th" style={{ width: '20px', paddingRight: '130px' }}>Material Name</th>
                    <th className="erp_table_th" style={{ width: '20px', paddingRight: '130px' }}>Material Description</th>
                    <th className="erp_table_th" style={{ width: '20px', paddingRight: '130px' }}>Material Tech. Spec.</th>
                    {/* <th className="erp_table_th" style={{ width: '20px', paddingRight: '130px' }}>Material Remark</th> */}

                    <th className="erp_table_th">Received Qty.</th>
                    <th className="erp_table_th col-1">Return Qty.</th>
                    <th className="erp_table_th" style={{ width: '20px', paddingRight: '130px' }}>Working Desc.</th>
                    <th className="erp_table_th" style={{ paddingRight: '30px' }}>Material Status</th>

                    <th className="erp_table_th">Material Serial No.</th>
                    <th className="erp_table_th">Material Model No.</th>
                    <th className="erp_table_th col-1">Returned Qty.</th>

                </tr>
            </thead>
            <tbody className="erp_table_td">
                {
                    receivedCustMaterialData?.map((receiptMaterial, index) =>
                        <tr rowIndex={index}>
                            <td className="erp_table_td">{index + 1}</td>
                            <td className="erp_table_td" style={{ width: '200px', display: 'table-cell', whiteSpace: 'break-spaces' }}> {receiptMaterial.material_name} </td>
                            <td className="erp_table_td" style={{ width: '200px', display: 'table-cell', whiteSpace: 'break-spaces' }}> {receiptMaterial.material_desc} </td>
                            <td className="erp_table_td" style={{ width: '200px', display: 'table-cell', whiteSpace: 'break-spaces' }}> {receiptMaterial.material_tech_spec} </td>
                            {/* <td className="erp_table_td" style={{ width: '200px', display: 'table-cell', whiteSpace: 'break-spaces' }}> {receiptMaterial.material_remark} </td> */}

                            <td className="erp_table_td text-end">{receiptMaterial.material_quantity}</td>

                            <td className="erp_table_td text-end">
                                {
                                    !['approve', 'view'].includes(keyForViewUpdate)
                                        ? <>
                                            <Form.Control type="text" id={`material_return_quantity_${index}`} className="erp_input_field text-end"
                                                value={receiptMaterial.material_return_quantity} Headers="material_return_quantity" maxLength='19'
                                                {...['approve', 'view'].includes(keyForViewUpdate) ? { disabled: 'disabled' } : {}}
                                                onInput={(e) => { FnUpdateCustMaterialRcptTblData(receiptMaterial, e, index) }} />
                                        </>
                                        : receiptMaterial.material_return_quantity
                                }
                            </td>

                            <td className="erp_table_td" style={{ width: '200px', display: 'table-cell', whiteSpace: 'break-spaces' }}>
                                {
                                    !['approve', 'view'].includes(keyForViewUpdate)
                                        ? <>
                                            <Form.Control as="textarea" id={`working_desc_${index}`} value={receiptMaterial.working_desc}
                                                Headers="working_desc" onInput={(e) => { FnUpdateCustMaterialRcptTblData(receiptMaterial, e, index) }}
                                                {...['approve', 'view'].includes(keyForViewUpdate) ? { disabled: 'disabled' } : {}}
                                                className="erp_input_field mb-0" />
                                        </>
                                        : receiptMaterial.working_desc
                                }
                            </td>

                            <td className="erp_table_td">
                                <select id={`material_status_${index}`} value={receiptMaterial.material_status} class="form-select form-select-sm erp_input_field_table_txt m-0"
                                    disabled // Headers="material_status" onChange={(e) => { FnUpdateCustMaterialRcptTblData(receiptMaterial, e, index) }}
                                >
                                    <option value="P">Pending</option>
                                    <option value="R">Repaired</option>
                                    <option value="I">Issued</option>
                                    <option value="Z">Partial Return</option>
                                    <option value="C">Returned</option>
                                </select>
                            </td>

                            <td className="erp_table_td">{receiptMaterial.material_serial_no}</td>
                            <td className="erp_table_td">{receiptMaterial.material_model_no}</td>
                            <td className="erp_table_td text-end">{receiptMaterial.material_returned_quantity}</td>
                        </tr>
                    )
                }
            </tbody>
        </Table>
        </>
    }, [receivedCustMaterialData]);

    const FnUpdateCustMaterialRcptTblData = (currentReceiptMaterial, event, material_index) => {
        delete event.target.parentElement.dataset.tip;
        let clickedColName = event.target.getAttribute('Headers');
        switch (clickedColName) {
            case 'material_return_quantity':
                if (event._reactName === 'onBlur' && event.target.value === '') {
                    currentReceiptMaterial[clickedColName] = 0;
                } else {
                    if (validateNumberDateInput.current.isInteger(event.target.value)) {
                        if (currentReceiptMaterial.max_return_quantity >= event.target.value) {
                            currentReceiptMaterial[clickedColName] = event.target.value
                        }
                    }
                }
                break;

            case 'working_desc':
                currentReceiptMaterial[clickedColName] = event.target.value;
                break;

            case 'material_status':
                currentReceiptMaterial[clickedColName] = event.target.value;
                break;

            default:
                break;
        }

        setReceivedCustMaterialData(prevData => {
            return prevData.map((data, index) => {
                if (index === material_index) {
                    return { ...data, ...currentReceiptMaterial };
                } else {
                    return data;
                }
            });
        });
    };

    // ******************************* Customer Material Table Functionality Ends. *************************************

    // ******************************* Common Functions Starts. *************************************

    const FnCheckOverduesActivities = (activities, updateTblDataFlag) => {
        let selectedReportingDate = document.getElementById('dt_service_reporting_date').value;
        const updatedActivities = activities.map(activity => {
            const isOverdue = selectedReportingDate > activity.expected_service_schedule_start_date;
            return {
                ...activity,
                is_overdue: isOverdue
            };
        });
        if (updateTblDataFlag) {
            setActivitiesTblData(updatedActivities);
        }
        return updatedActivities;
    }

    const FnCheckBoxOnChage = (checkboxType) => {
        switch (checkboxType) {
            // For Services Activities.
            case "selectAllServiceActivities":
                // $('.selectServiceActivity').prop('checked', $('#selectAllServiceActivities').is(":checked"));
                $(`input[type="checkbox"][name='selectServiceActivity']`).prop('checked', $('#selectAllServiceActivities').is(":checked"));

                break;
            case 'PartiallyServiceActivitySelection':
                $('#selectAllServiceActivities').prop('checked', $('#serviceActivitiesTbl tbody tr input[type="checkbox"][name="selectServiceActivity"]:checked').length === $('#serviceActivitiesTbl tbody tr input[type="checkbox"][name="selectServiceActivity"]').length)
                // $('#selectAllServiceActivities').prop('checked', $('input:checkbox.selectServiceActivity:checked').length === $('input:checkbox.selectServiceActivity').length);
                break;
            default:
                break;
        }
    }

    const moveToListing = () => {
        const Path = compType === 'Register' ? '/Transactions/TServiceReportingListing/ServiceReportingListing/reg' : `/Transactions/TServiceReportingListing/ServiceReportingListing`;
        navigator(Path);
    }

    // Validate Form. (For remove the error msgs.)
    const validateFields = () => {
        validateFrms.current.validateFieldsOnChange('ServiceReportingHeaderFormId')
    }

    const FnValidateServiceReportingOnSave = async () => {
        let isValidServiceReportingForSave = true;
        isValidServiceReportingForSave = await validateFrms.current.validateForm("ServiceReportingHeaderFormId");
        if (!isValidServiceReportingForSave) { return false; }

        let checkedServiceActivities = $('#serviceActivitiesTbl tbody tr input[type="checkbox"][name="selectServiceActivity"]:checked');
        // Select atleast one service activity.
        if (checkedServiceActivities.length === 0) {
            setErrMsg('Please Select atleast one service activity....!');
            setShowErrorMsgModal(true);
            setOpenActivitiesAccord(true);
            isValidServiceReportingForSave = false;
            return false;
        }

        // Then validate the activities.
        checkedServiceActivities.each(function () {
            let currentTblRow = $(this.parentElement.parentElement.parentElement);
            let actual_hour = parseFloat(currentTblRow.find('input[id^="actual_hour_"]').val());

            if (isNaN(actual_hour) || actual_hour <= 0 || actual_hour === '') {
                $(currentTblRow.find('input[id^="actual_hour"]'))[0].parentElement.dataset.tip = 'Actual hours are not a zero or blank...!';
                $(currentTblRow.find('input[id^="actual_hour"]'))[0].focus();
                isValidServiceReportingForSave = false;
                return false;
            }

        });
        if (!isValidServiceReportingForSave) { return false; }
        return isValidServiceReportingForSave;
    }

    const FnValidateServiceReportingOnApproval = async () => {
        let isValidServiceReportingForApprove = true;
        isValidServiceReportingForApprove = await validateFrms.current.validateForm("ServiceReportingHeaderFormId");
        if (!isValidServiceReportingForApprove) { return false; }

        // Then validate the activities.
        let checkedServiceActivities = $('#serviceActivitiesTbl tbody tr input[type="checkbox"][name="selectServiceActivity"]:checked');
        checkedServiceActivities.each(function () {
            let currentTblRow = $(this.parentElement.parentElement.parentElement);
            let approvedById = currentTblRow.find('select[id^="approved_by_id_"]').val();

            if (approvedById === '' && ownApprove) {
                $(currentTblRow.find('select[id^="approved_by_id_"]'))[0].parentElement.dataset.tip = 'Please select at least one option...!';
                $(currentTblRow.find('select[id^="approved_by_id_"]'))[0].focus();
                isValidServiceReportingForApprove = false;
                setOpenActivitiesAccord(true);
                return false;
            }

        });
        if (!isValidServiceReportingForApprove) { return false; }
        return isValidServiceReportingForApprove;

    }
    // ******************************* Common Functions Ends. *************************************

    // ******************************* CRUD Functionality Starts. ********************************

    // For Save the Service Reporting.
    const FnSaveServiceReporting = async () => {
        try {
            setIsLoading(true);
            // First Validate the transaction.
            let isValidServiceReporting = false;
            if (keyForViewUpdate !== 'approve') {
                isValidServiceReporting = await FnValidateServiceReportingOnSave();
            } else if (keyForViewUpdate === 'approve') {
                isValidServiceReporting = await FnValidateServiceReportingOnApproval();
            }

            if (isValidServiceReporting) {
                let JSONForSave = { 'TransHeaderData': {}, 'TransDetailData': [], 'ReceivedCustomerMaterialsData': [], 'commonIds': {}, }
                let latestServiceReportingCode = txt_service_reporting_code;
                if (service_reporting_master_transaction_id === 0) {
                    latestServiceReportingCode = await FnGenerateServiceReportingCode();
                }

                // JSON Which Fields Common in all tables.
                let commonFieldsInTransaction = {
                    service_reporting_master_transaction_id: service_reporting_master_transaction_id,
                    service_reporting_code: latestServiceReportingCode,
                    service_reporting_creation_type: rb_service_reporting_type,
                    service_reporting_date: dt_service_reporting_date,
                    company_id: parseInt(COMPANY_ID),
                    company_branch_id: parseInt(COMPANY_BRANCH_ID),
                    financial_year: FINANCIAL_SHORT_NAME,
                    created_by: UserName,
                };

                // Generate JSON For Header Data.
                JSONForSave.TransHeaderData = {
                    ...commonFieldsInTransaction,               // Common Fields. 
                    reporting_status: cmb_reporting_status,
                    assigned_service_engineer_id: cmb_assigned_service_engineer_id,
                    service_engineer_reporting_to_id: cmb_service_engineer_reporting_to_id,
                    customer_id: cmb_customer_id,
                    customer_order_no: cmb_customer_order_no,
                    work_schedule_code: cmb_work_schedule_code,
                    from_date: dt_from_date,
                    to_date: dt_to_date,
                    modified_by: service_reporting_master_transaction_id !== 0 ? UserName : null,
                }

                // JSONArray For the Service Activities.
                $("input:checkbox[name=selectServiceActivity]:checked").each(function () {
                    let checkedServiceActivity = activitiesTblData.find(activity => activity.service_planning_activities_transaction_id === parseInt($(this).val()));
                    const serviceActivity = {
                        ...checkedServiceActivity,
                        ...commonFieldsInTransaction,    // set all common fields.
                    }

                    // If deptHead Login and it will set status as rework then set operatorStatus as 'R'
                    if (ownApprove && serviceActivity.department_head_status === 'R') {
                        serviceActivity.operator_status = 'R'
                    }

                    // If Custmer Login and it will set status as rework then set deptHeadStatus and operatorStatus as 'R'
                    if (customerApprove && serviceActivity.customer_status === 'R') {
                        serviceActivity.department_head_status = 'R'
                        serviceActivity.operator_status = 'R'
                    }

                    // Update the Customer Status if customer-approval-not required.
                    if (keyForViewUpdate === 'approve' && !customerApprovalIsRequired) {
                        serviceActivity.customer_status = serviceActivity.department_head_status;
                    }
                    JSONForSave.TransDetailData.push(serviceActivity);
                });

                // Update the master status if all_customer status is not includes the pending/Rework.
                if (keyForViewUpdate === 'approve') {
                    let customerStatusContainsP_R = JSONForSave.TransDetailData.some(detail => detail.customer_status === "P" || detail.customer_status === "R");
                    // Means ownApprove and customer approval not required and in details doesn't contains the P||R
                    if (ownApprove && !customerApprovalIsRequired && !customerStatusContainsP_R) {
                        JSONForSave.TransHeaderData.reporting_status = "A";
                    } else if (customerApprove && customerApprovalIsRequired && !customerStatusContainsP_R) {
                        JSONForSave.TransHeaderData.reporting_status = "A";
                    }
                }

                // Save that data only at the time of Transaction New/Modification
                if (['', 'update'].includes(keyForViewUpdate)) {
                    // const materialRcptDataForSave = receivedCustMaterialData
                    //     .filter((receivedMaterial) => receivedMaterial?.material_return_quantity > 0)
                    //     .map((receivedMaterial) => ({
                    //         ...receivedMaterial,
                    //         ...commonFieldsInTransaction,               // Common Fields. 
                    //     }))
                    // // service_reporting_customer_material_return_transaction_id

                    const materialRcptDataForSave = receivedCustMaterialData
                        .map((receivedMaterial) => {
                            if (!['approve', 'view', 'update'].includes(keyForViewUpdate) && receivedMaterial?.material_return_quantity > 0) {
                                // Means at the time of New Transaction
                                return {
                                    ...receivedMaterial,
                                    ...commonFieldsInTransaction,               // Common Fields. 
                                }
                            } else if (keyForViewUpdate === 'update') {
                                // Means at the time of Transaction Modification
                                if (receivedMaterial.service_reporting_customer_material_return_transaction_id === 0 && receivedMaterial?.material_return_quantity > 0) {
                                    // Means newly added record at the time of modification (For return customer material)
                                    return {
                                        ...receivedMaterial,
                                        ...commonFieldsInTransaction,               // Common Fields. 
                                    }
                                } else if (receivedMaterial.service_reporting_customer_material_return_transaction_id !== 0) {
                                    // Means modified record (For return customer material)
                                    if (receivedMaterial?.material_return_quantity == 0) {
                                        // Means changed in quantity. 
                                        return {
                                            ...receivedMaterial,
                                            material_return_quantity: receivedMaterial.prev_material_return_quantity,
                                            ...commonFieldsInTransaction,               // Common Fields. 
                                        }
                                    }
                                    // Means No changes into quantity.
                                    return {
                                        ...receivedMaterial,
                                        ...commonFieldsInTransaction,               // Common Fields. 
                                    }
                                }
                            }
                        }).filter(Boolean);
                    JSONForSave.ReceivedCustomerMaterialsData = materialRcptDataForSave;
                }

                // Common Ids;
                JSONForSave.commonIds.service_reporting_master_transaction_id = service_reporting_master_transaction_id;
                JSONForSave.commonIds.created_by = UserName;
                JSONForSave.commonIds.company_id = COMPANY_ID;
                JSONForSave.commonIds.company_branch_id = COMPANY_BRANCH_ID
                JSONForSave.commonIds.financial_year = FINANCIAL_SHORT_NAME

                JSONForSave.commonIds.action_type = keyForViewUpdate;
                JSONForSave.commonIds.cust_approval_is_required = customerApprovalIsRequired;
                JSONForSave.commonIds.user_type = UserType;

                console.log(`JSON Sending For Saving Service Reporting: `, JSONForSave);
                const formData = new FormData();
                formData.append(`ServiceReportData`, JSON.stringify(JSONForSave))
                const requestOptions = { method: 'POST', body: formData };
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtServiceReportingMaster/FnAddUpdateRecord`, requestOptions)
                const response = await apiCall.json();
                console.log('responceAfterSave: ', response);
                if (response.success === 1 && response.error === '') {
                    const evitCache = await comboDataApi.current.evitCache();
                    setServiceReportingMasterTransactionId(response.data.service_reporting_master_transaction_id);
                    setSuccMsg(response.message);
                    setShowSuccessMsgModal(true);
                } else {
                    console.log('response error on saving the service reporting: ', response.error);
                    setErrMsg(response.error);
                    setShowErrorMsgModal(true);
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }

    // For Filled the data on view/update action
    const FnCheckUpdateResponse = async () => {
        try {
            setIsLoading(true);
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtServiceReportingMaster/FnShowParticularRecordForUpdate/${idList.service_reporting_master_transaction_id}/${COMPANY_ID}`)
            const responce = await apiCall.json();

            if (responce.ServiceReportingMasterData !== null) {
                let masterData = responce.ServiceReportingMasterData;
                setServiceReportingMasterTransactionId(masterData.service_reporting_master_transaction_id);
                setServiceReportingCreationType(masterData.service_reporting_creation_type);
                setServiceReportingCode(masterData.service_reporting_code);
                setServiceReportingDate(masterData.service_reporting_date);
                setReportingStatus(masterData.reporting_status);
                setAssignedServiceEngineerId(masterData.assigned_service_engineer_id);
                setServiceEngineerReportingToId(masterData.service_engineer_reporting_to_id);
                setServiceEngineerReportingToName(masterData.service_engineer_reporting_to);
                setWorkScheduleCode(masterData.work_schedule_code);

                setCustomerId(masterData.customer_id);
                if (masterData.customer_id !== '' && masterData.service_reporting_creation_type === 'S') {
                    resetGlobalQuery();
                    globalQuery.columns = ['customer_order_no']
                    globalQuery.table = "mt_sales_order_master_services"
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: parseInt(masterData.customer_id) });
                    globalQuery.conditions.push({ field: "sales_order_status", operator: "IN", values: ['A'] });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.orderBy = ['sales_order_master_transaction_id DESC']
                    const getCustomerOrdersApiCall = await comboDataApi.current.fillFiltersCombo(globalQuery);
                    if (getCustomerOrdersApiCall.length > 0) {
                        setCustomerOrderOpts(getCustomerOrdersApiCall);
                        $('#error_cmb_customer_order_no').hide();
                    } else {
                        setCustomerOrderOpts([]);
                        $('#error_cmb_customer_order_no').text('No customer orders are available...!');
                        $('#error_cmb_customer_order_no').show();
                    }
                }
                setCustomerOrderNo(masterData.customer_order_no);
                setFromDate(masterData.from_date);
                setToDate(masterData.to_date);

                if (responce.ServiceReportingActivitiesDetailsData !== null) {
                    await FnGetActivitiesFromPlanning(responce.ServiceReportingActivitiesDetailsData, masterData.service_reporting_creation_type);
                    setStoredActivitiesTblData(responce.ServiceReportingActivitiesDetailsData);

                    // Check the checkboxes of selected activities.
                    responce.ServiceReportingActivitiesDetailsData?.map((selectedActivity) => {
                        $(`input[type="checkbox"][name='selectServiceActivity'][value="${selectedActivity.service_planning_activities_transaction_id}"]`).prop('checked', true);
                    });
                    FnCheckBoxOnChage('PartiallyServiceActivitySelection');
                }

                if (responce.ServiceReportingCustomerMaterialReturnData !== null && masterData.service_reporting_creation_type === 'S') {
                    await FnGetCutomerReceivedMaterial(responce.ServiceReportingCustomerMaterialReturnData, masterData.service_reporting_creation_type);
                    setStoredReceivedCustMaterialData(responce.ServiceReportingCustomerMaterialReturnData);
                }

            }
            setIsLoading(false);
        } catch (error) {
            console.log("error : ", error)
            navigator('/Error')
        }
    }

    // For Deletion
    const FnShowDeleteModal = () => { setShowDeleteModal(true); }
    const FnDeleteServiceReporting = async () => {
        setIsLoading(true);
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtServiceReportingMaster/FnDeleteRecord/${idList.service_reporting_master_transaction_id}/${COMPANY_ID}/${UserName}`, method)
            const responce = await deleteApiCall.json();
            console.log("Service Reporting Deleted: ", responce);
            if (responce.success == 1) {
                setShowDeleteModal(false)
                moveToListing();
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }

    // ******************************* CRUD Functionality Ends. ********************************

    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataApi} />
                <FrmValidations ref={validateFrms} />
                <ValidateNumberDateInput ref={validateNumberDateInput} />
                <GenerateTAutoNo ref={generateAutoNoApi} />

                {isLoading
                    ? <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div>
                    : ''}

                <div className='card p-1'>
                    <div className='card-header text-center py-1'>
                        <label className='erp-form-label-lg text-center'>Service Reporting {ActionType()}</label>
                    </div>

                    <div className="card-body">

                        <form id='ServiceReportingHeaderFormId'>
                            <div className="row p-1">
                                {/* First column */}
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Creation Type <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col-sm-8">
                                            <div className="erp_form_radio">
                                                <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Mannual" type="radio" lbl="Mannual" value="M" name="rb_service_reporting_type" checked={rb_service_reporting_type === "M"} onClick={(e) => { setServiceReportingCreationType('M'); validateFields(); FnCombosOnChange('CreationType'); }} /> </div>
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="SO Based" type="radio" lbl="SO Based" value="S" name="rb_service_reporting_type" checked={rb_service_reporting_type === "S"} onClick={(e) => { setServiceReportingCreationType('S'); validateFields(); FnCombosOnChange('CreationType'); }} /> </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label">Reporting Code<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <Form.Control type="text" id="txt_service_reporting_code" className="erp_input_field" value={txt_service_reporting_code} disabled />
                                            <MDTypography variant="button" id="error_txt_service_reporting_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label">Reporting Date<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <Form.Control type="date" id='dt_service_reporting_date' className="erp_input_field" value={dt_service_reporting_date} onChange={e => { setServiceReportingDate(e.target.value); validateFields(); FnCheckOverduesActivities(activitiesTblData, true); }} />
                                            <MDTypography variant="button" id="error_dt_service_reporting_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Reporting Status<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <select id="cmb_reporting_status" className="form-select form-select-sm" value={cmb_reporting_status} disabled >
                                                <option value="P">Pending</option>
                                                <option value="A">Completed</option>
                                                <option value="R">Rework</option>
                                                <option value="Q">Rework Completed</option>
                                                <option value="C">Canceled</option>
                                                <option value="Z">Pree Closed</option>
                                                <option value="F">Payment Done</option>
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_reporting_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                </div>

                                {/* Second column */}
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Service Engineer<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <select id="cmb_assigned_service_engineer_id" className="form-select form-select-sm" value={cmb_assigned_service_engineer_id} onChange={(e) => { setAssignedServiceEngineerId(e.target.value); validateFields(); FnCombosOnChange('Engineer'); }} {...UserType === 'employee' && ['', 'approve', 'view', 'update'].includes(keyForViewUpdate) ? { disabled: 'disabled' } : {}}>
                                                <option value="">Select</option>
                                                {serviceEngineerOpts?.map(employee => (<option value={employee.field_id}>{employee.field_name}</option>))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_assigned_service_engineer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label">Reporting To<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <Form.Control type="text" id="txt_service_engineer_reporting_to_name" className="erp_input_field" value={txt_service_engineer_reporting_to_name} disabled />
                                            <MDTypography variant="button" id="error_txt_service_engineer_reporting_to_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Customer{rb_service_reporting_type === 'S' ? <span className="required">*</span> : null} </Form.Label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <select id="cmb_customer_id" className="form-select form-select-sm" value={cmb_customer_id} onChange={(e) => { setCustomerId(e.target.value); validateFields(); FnCombosOnChange('Customer'); }} {...rb_service_reporting_type === 'M' || keyForViewUpdate === 'view' ? { disabled: 'disabled', optional: "optional" } : {}}>
                                                <option value="">Select</option>
                                                {customerOpts?.map(customer => (<option value={customer.field_id}>{customer.field_name}</option>))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Customer Order{rb_service_reporting_type === 'S' ? <span className="required">*</span> : null}</Form.Label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <select id="cmb_customer_order_no" className="form-select form-select-sm" value={cmb_customer_order_no} onChange={(e) => { setCustomerOrderNo(e.target.value); validateFields(); }} {...rb_service_reporting_type === 'M' || keyForViewUpdate === 'view' ? { disabled: 'disabled', optional: "optional" } : {}}>
                                                <option value="">Select</option>
                                                {customerOrderOpts?.map(order => (<option value={order.customer_order_no}>{order.customer_order_no}</option>))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_customer_order_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>
                                </div>

                                {/* Third column */}
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Work Schedule{rb_service_reporting_type === 'M' ? <span className="required">*</span> : null}</Form.Label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <select id="cmb_work_schedule_code" className="form-select form-select-sm" value={cmb_work_schedule_code} onChange={(e) => { setWorkScheduleCode(e.target.value); validateFields(); }} {...rb_service_reporting_type === 'S' || keyForViewUpdate === 'view' ? { disabled: 'disabled', optional: "optional" } : {}}>
                                                <option value="">Select</option>
                                                {mannualWorkSchedulesOpts?.map(workSchedule => (<option value={workSchedule.work_schedule_code}>{workSchedule.work_schedule_code}</option>))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_work_schedule_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">From Date </Form.Label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <Form.Control type="date" id='dt_from_date' className="erp_input_field" value={dt_from_date} onChange={(e) => { setFromDate(e.target.value); validateFields(); }} max={dt_to_date} optional="optional" />
                                            <MDTypography variant="button" id="error_dt_from_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">To Date </Form.Label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <Form.Control type="date" id='dt_to_date' className="erp_input_field" value={dt_to_date} onChange={(e) => { setToDate(e.target.value); validateFields(); }} min={dt_from_date} optional="optional" />
                                            <MDTypography variant="button" id="error_dt_to_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </form>

                        <div className="text-start mb-3 mt-3 mt-sm-0">
                            {
                                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'update'
                                    ? <>
                                        <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular"
                                            onClick={async () => {
                                                let isValid = await validateFrms.current.validateForm("ServiceReportingHeaderFormId");
                                                if (isValid) {
                                                    FnGetActivitiesFromPlanning(storedActivitiesTblData, rb_service_reporting_type);
                                                    if (rb_service_reporting_type === 'S') {
                                                        FnGetCutomerReceivedMaterial(storedReceivedCustMaterialData);
                                                    }
                                                }
                                            }}>Show Activities</MDButton>
                                    </>
                                    : null
                            }
                        </div >

                        <Accordion defaultActiveKey='0' activeKey={openActivitiesAccord ? '0' : null}>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenActivitiesAccord(!openActivitiesAccord)}>Activities</Accordion.Header>
                                <Accordion.Body className="p-0">
                                    <div className="row">
                                        <div className={`table-responsive ${activitiesTblData.length > 0 ? 'erp_table_scroll' : ''}`}>
                                            <> {FnDisplayPlannedActivitiesTbl}</>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <MDTypography variant="button" id="error_activities" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <hr />

                        {
                            receivedCustMaterialData.length > 0
                                ? <>
                                    <Accordion defaultActiveKey='0' activeKey={openCustomerMaterialAccord ? '0' : null}>
                                        <Accordion.Item eventKey='0'>
                                            <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenCustomerMaterialAccord(!openCustomerMaterialAccord)}>Customer Materials</Accordion.Header>
                                            <Accordion.Body className="p-0">
                                                <div className="row">
                                                    <div className={`table-responsive ${receivedCustMaterialData.length > 0 ? 'erp_table_scroll' : ''}`}>
                                                        <> {FnDisplayCustomerMaterialReceiptTbl}</>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    <hr />
                                </>
                                : null
                        }

                        <div className="text-center mb-2">
                            <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                            {
                                keyForViewUpdate !== 'view'
                                    ? <> <MDButton type="button" className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" onClick={() => FnSaveServiceReporting()}>Save</MDButton> </>
                                    : null
                            }
                            {
                                deletionKey
                                    ? <> <MDButton type="button" className={`erp-gb-button erp_MLeft_btn`} variant="button" fontWeight="regular" onClick={() => FnShowDeleteModal()}> Delete </MDButton> </>
                                    : null
                            }
                        </div>
                    </div>
                </div >

                {/* Delete Modal */}
                < Modal show={showDeleteModal} onHide={FnCloseDeleteModal} backdrop="static" keyboard={false} centered >
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseDeleteModal}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        {isLoading
                            ? <div className="spinner-overlay"  >
                                <div className="spinner-container">
                                    <CircularProgress color="primary" />
                                    <span>Loading...</span>
                                </div>
                            </div>
                            : ''}

                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to delete this record ?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={FnCloseDeleteModal}> Cancel </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={FnDeleteServiceReporting}>Delete</Button>
                    </Modal.Footer>
                </Modal >

                {/* Generic Response Messaage Modals */}
                < SuccessModal handleCloseSuccessModal={() => FnCloseSuccessModal()
                } show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => FnCloseErrorModal()} show={[showErrorMsgModal, errMsg]} />

            </DashboardLayout >
        </>
    )
}

export default TServiceReportingEntry;