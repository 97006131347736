import React, { useState, useRef, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $, { event } from "jquery";

//File Imports
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";
import ConfigConstants from "assets/Constants/config-constant";
import ComboBox from "Features/ComboBox";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import FrmValidations from "FrmGeneric/FrmValidations";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import DocumentF from "Features/Document";
import ErrorModal from "components/Modals/ErrorModal";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import MailSentModal from "components/Modals/MailSentModal";
import html2pdf from 'html2pdf.js';
import { renderToString } from "react-dom/server";
import SupplierPurchasePaymentInvoice from "FrmGeneric/Invoice/SupplierPurchasePayment";
import ExcelExport from "Features/Exports/ExcelExport";


//React Bootstrap components
import Tooltip from "@mui/material/Tooltip";
import { Form, Button } from "react-bootstrap";
import { Accordion, Modal, Table } from "react-bootstrap";
import { MdRefresh } from "react-icons/md";
import { CircularProgress } from "@material-ui/core";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import { RxCrossCircled } from "react-icons/rx";

// React icons

import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { keys } from "@material-ui/core/styles/createBreakpoints";
import FrmTaxationEntry from "Masters/MTaxation/FrmTaxationEntry";


function FrmPurchasePaymentSupplierEntry() {
  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, FINANCIAL_SHORT_NAME, UserId, UserName, COMPANY_NAME } = configConstants;

  const { state } = useLocation()
  const { idList, keyForViewUpdate, compType } = state || {};
  // UseRefs
  const navigate = useNavigate();
  const validate = useRef();
  const comboDataAPiCall = useRef();
  const generateAutoNoAPiCall = useRef();
  const validateNumberDateInput = useRef();
  const exlsExp = useRef();

  //combo options
  // const [producttypeOptions, setProductTypeOptions] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [expectedBranchOptions, setExpectedBranchOptions] = useState([]);
  const [approvedByOptions, setApprovedByOptions] = useState([]);
  const [tax1Options, setTax1Options] = useState([])
  const [bankOptions, setBankOptions] = useState([])
  const [supplierBankOptions, setSupplierBankOptions] = useState([])

  //common hooks
  const [isLoading, setIsLoading] = useState(false);
  const currentDate = new Date().toISOString().split('T')[0];
  const [isApprove, setIsApprove] = useState(false);
  const [isView, setIsView] = useState(false);
  // const [financial_year, setFinancial_year] = useState(idList !== null ? idList.financial_year : FINANCIAL_SHORT_NAME);

  //master's hooks
  const [supplier_purchase_payment_master_transaction_id, setsupplier_purchase_payment_master_transaction_id] = useState(idList !== null ? idList.supplier_purchase_payment_master_transaction_id : 0)
  const [txt_supplier_purchase_payment_no, setSupplier_purchase_payment_no] = useState(idList !== null ? idList.supplier_purchase_payment_no : '0')
  const [txt_supplier_purchase_payment_version, setSupplier_purchase_payment_version] = useState(idList !== null ? idList.supplier_purchase_payment_version : 1)
  const [txt_supplier_purchase_payment_date, setSupplier_purchase_payment_date] = useState(currentDate);
  // const [cmb_supplier_purchase_payment_type_id, setSupplier_purchase_payment_type_id] = useState('')
  const [cmb_supplier_id, setSupplier_id] = useState("");
  const [txt_supplier_contacts_ids, setsupplier_contacts_ids] = useState('')
  const [cmb_expected_branch_id, setExpected_branch_id] = useState(COMPANY_BRANCH_ID);
  const [cmb_approved_by_id, setApproved_by_id] = useState("");
  const [txt_approved_date, setApproved_date] = useState("");
  const [cmb_bank_id, setBank_id] = useState('')
  const [chk_payment_type, setPayment_type] = useState('1')
  const [txt_supplier_bank_details, setSupplier_bank_details] = useState('')
  const [cmb_supplier_bank_id, setSupplier_bank_id] = useState('')
  const [txt_supplier_bank_account_number, setSupplier_bank_account_number] = useState('')
  const [txt_supplier_bank_ifsc_code, setSupplier_bank_ifsc_code] = useState('')
  const [txt_payment_transaction_details, setPayment_transaction_details] = useState('')
  const [dt_payment_transaction_date, setPayment_transaction_date] = useState(currentDate)
  const [chk_purchasePayment_status, setPurchasePayment_status] = useState('P')
  const [txt_total_outstanding, setTotal_outstanding] = useState(0)
  const [txt_header_paid_amount, setHeaderPaid_amount] = useState(0)
  const [chk_Payment_mail_sent_status, setPayment_mail_sent_status] = useState('P')

  const [txt_total_billing_amount, setTotal_billing_amount] = useState(0)
  const [txt_tax1_id, setTax1_id] = useState('')
  const [txt_tax1_percent, setTax1_percent] = useState(0)
  const [txt_tax1_amount, setTax1_amount] = useState(0)
  const [txt_tax2_id, setTax2_id] = useState('')
  const [txt_tax2_percent, setTax2_percent] = useState(0)
  const [txt_tax2_amount, setTax2_amount] = useState(0)
  const [txt_total_billing_deduction, setTotal_billing_deduction] = useState(0)
  const [txt_total_paid_amount, setTotal_paid_amount] = useState(0)
  const [txt_deduction_remark, setDeduction_remark] = useState('')
  const [txt_remark, setRemark] = useState('')
  const [chk_purchase_payment_isactive, setPurchase_payment_isactive] = useState(true)


  // Supplier contact details table data hooks
  const [suppContactDetails, setSuppContactDetails] = useState([]);
  // bill book Table Data
  const [purchasePaymentDetailsData, setPurchasePaymentDetailsData] = useState([])
  const [storedpurchasePaymentDetailsData, setStoredpurchasePaymentDetailsData] = useState([])

  //---------------Modals starts-----------------------------------------------------------------------------------------------------
  // Document Form
  let docGroup = "Purchase Payment"
  const [showDocumentForm, setShowDocumentForm] = useState(false);
  const handleCloseDocumentForm = () => { showDocumentRecords(); setShowDocumentForm(false); }
  const viewDocumentForm = () => setShowDocumentForm(true);
  // doc list
  const [docData, setDocumentData] = useState([]);
  //Error Msg
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  // Confirmation Modal fields
  const [modalOrderDetails, setModalOrderDetails] = useState('')
  const [showConfirmationModal, setShowConfiemationModal] = useState(false)
  const [message, setMessage] = useState('')
  const [messageForConfirmation, setMessageForConfirmation] = useState('')
  const closeConfirmationModal = (key) => { setShowConfiemationModal(false); moveToListing(); }
  // After approved mail sent confirmation modal
  const handleCloseMailSentModal = () => {
    setShowMailSentModal(false);
    moveToListing();
  }
  const [showMailSentModal, setShowMailSentModal] = useState(false);

  // Hooks for handle the accordions.
  const [openContactsAccord, setOpenContactsAccord] = useState(false);
  const [openDetailsAccord, setOpenDetailsAccord] = useState(true);

  // delete modal Fields
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  //---------------Modals Ends-----------------------------------------------------------------------------------------------------

  //------------------------------------------------Add New Record start-------------------------------------
  // to add new records in combo box 
  const [showAddRecModal, setShowAddRecModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('')

  const displayRecordComponent = () => {
    switch (modalHeaderName) {
      // case 'Product Type':
      //   return <FrmMProductTypeEntry />;
      case 'Add Taxation':
        return <FrmTaxationEntry />;
      default:
        return null;
    }
  }

  // Show ADD record Modal
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {
      case 'Add Taxation':
        //get expected branch list
        resetGlobalQuery();
        globalQuery.columns.push("field_name");
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("tax_value");
        globalQuery.table = "cmv_taxation";
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
        const taxationApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
        setTax1Options(taxationApiCall);
        break;
      default:
        break;
    }
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)

  }
  //------------------------------------------------Add New Record End--------------------------------------------------------


  const ActionType = () => {
    switch (keyForViewUpdate) {

      case 'approve':
        return '(Approval)';
      case 'financeApprove':
        return '(Finance Approve)';
      case 'view':
        return '(View)';
      case 'cancel':
        return '(cancelation)';
      case 'delete':
        return '(Delete)';
      default:
        return '(Creation)';
    }
  }
  useEffect(async () => {
    try {
      setIsLoading(true)
      ActionType();
      await FnloadDefaultData();
      if (idList !== null) {
        await FnCheckUpdateResponse();
      } else {
        await GeneratePurchasePaymentNo();
      }
      FnSetFieldsByUserAccess();
    } catch (error) {
      console.error(error);
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
    // Close customer order no dropdown
    $(document).on('mouseup', function (e) {
      var container = $("#bill-booked-order-ul");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.hide();
      }
    });
  }, [])
  useEffect(() => {
    if (purchasePaymentDetailsData.length !== 0) {
      FnCalculateDetailsTotalSum();
    }
  }, [purchasePaymentDetailsData])




  // navigate to back listing page 
  const moveToListing = () => {
    const Path = compType === 'Register' ? '/Transactions/TPurchaseOrder/TPurchasePaymentSupplier/FrmPurchasePaymentSupplierListing/reg' : `/Transactions/TPurchaseOrder/TPurchasePaymentSupplier/FrmPurchasePaymentSupplierListing`;
    navigate(Path);
  }
  const refreshData = async (key) => {

    switch (key) {
      case 'get_supplier':
        // get suppliers list with default state & city
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.table = "cmv_supplier";
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        const suppliersApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
        setSupplierOptions(suppliersApiCall)
        break;
      case 'get_exptBranch':
        //get expected branch list
        resetGlobalQuery();
        globalQuery.columns.push("company_branch_id");
        globalQuery.columns.push("company_branch_name");
        globalQuery.columns.push("is_sez");
        globalQuery.columns.push("branch_EmailId");
        globalQuery.table = "cmv_company_branch";
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
        const exptBranchApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
        setExpectedBranchOptions(exptBranchApiCall);

        break;
      default:
        break;
    }
  }
  // to scroll suppliers contact ids box
  function scrollToTableRow() {
    var keywords = $("#txt-detail-data-highliter").val();
    var table = document.getElementById('billBook-details-table');
    var rows = table.getElementsByTagName('tr');
    for (var trNum = 1; trNum < rows.length; trNum++) {
      var row = rows[trNum];
      if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
        var w = $(window);
        var row = $('#billBook-details-table').find('tr').eq(trNum);

        if (row.length) {
          w.scrollTop(row.offset().top - (w.height() / 2));
        }

        $(row).find('td').each(function () {
          $(this).addClass('highlight');
        });
        removeOtherTrHighlightClass(trNum)
        break;
      } else {
        $(row).find('td').each(function () {
          $(this).removeClass('highlight');
        });
      }
    }
  }
  const removeOtherTrHighlightClass = (rowNum) => {
    var table = document.getElementById('billBook-details-table');
    var rows = table.getElementsByTagName('tr');
    for (var trNum = 1; trNum < rows.length; trNum++) {
      var row = rows[trNum];
      if (trNum !== rowNum) {
        $(row).find('td').each(function () {
          $(this).removeClass('highlight');
        });
      }
    }
  }
  // fn for fields onchange action
  const comboOnChange = async (key) => {
    switch (key) {

      case "supplier":
        let supplierTpVal = document.getElementById("cmb_supplier_id").value;
        setSupplier_id(supplierTpVal);
        if (supplierTpVal === '0') {
          localStorage.setItem('supplierIDs', JSON.stringify({ supplierId: 0, keyForViewUpdate: '', compType: 'Master' }))
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Add Supplier')
          const newTab = window.open('/Masters/FrmSupplier', '_blank');
          if (newTab) {
            newTab.focus();
          }
        }
        if (supplierTpVal != null && supplierTpVal != '' & supplierTpVal != '0') {
          await FnGetSupplierContacts(parseInt(supplierTpVal));
          await FnloadSupplierBankDetails(supplierTpVal);
          if (keyForViewUpdate === '') {
            await showBillBookedDetails(supplierTpVal);
          }
        } else {
          setSupplierBankOptions([])
          setSuppContactDetails([])
          setPurchasePaymentDetailsData([])
          setSupplier_bank_account_number('')
          setSupplier_bank_details('')
          setSupplier_bank_ifsc_code('')
          setTotal_outstanding(0)
          setHeaderPaid_amount(0)
        }
        break;
      case "expectBranch":
        let exptBranchIdVal = document.getElementById("cmb_expected_branch_id").value;
        setExpected_branch_id(exptBranchIdVal);
        if (exptBranchIdVal === '0') {
          const newTab = window.open('Masters/Company', '_blank');
          if (newTab) {
            newTab.focus();
          }
          setExpected_branch_id('');
        }
        // if (exptBranchIdVal != null && exptBranchIdVal != '' & exptBranchIdVal != '0') {
        //   setPurchasePaymentDetailsData([])
        // } else {
        //   setPurchasePaymentDetailsData([])
        // }
        break;
      case "supplier_bank_id":
        let supplierBankIdVal = document.getElementById("cmb_supplier_bank_id").value;
        // if (supplierBankIdVal === '0') {
        //   sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
        //   setHeaderName('Add Department')
        //   setShowAddRecModal(true)
        //   setTimeout(() => {
        //     $(".erp_top_Form").css({
        //       "padding-top": "0px"
        //     });
        //   }, 200)
        // }
        if (supplierBankIdVal != null && supplierBankIdVal != '' & supplierBankIdVal != '0') {
          debugger
          const suppBankDetails = supplierBankOptions.find(item => item.field_id === parseInt(supplierBankIdVal));
          setSupplier_bank_account_number(suppBankDetails.supp_bank_account_no)
          setSupplier_bank_ifsc_code(suppBankDetails.supp_bank_ifsc_code)
          setSupplier_bank_details('')

        } else {
          setSupplier_bank_account_number('')
          setSupplier_bank_details('')
          setSupplier_bank_ifsc_code('')
        }
        break;
      case "tax1":
        var tax_1Val = document.getElementById("txt_tax1_id").value;
        setTax1_id(tax_1Val);
        if (tax_1Val !== '0' && tax_1Val !== "") {
          const tax_percent = $('#txt_tax1_id option:selected').attr('tax_value');
          setTax1_percent(tax_percent)
          FnCalculateTaxAmount(tax_percent, 'tax1amount');
        } else if (tax_1Val === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Add Taxation')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css({
              "padding-top": "0px"
            });
          }, 200)
          setTax1_percent(0);
          setTax1_amount(0);
          setTimeout(() => {
            FnCalculateDetailsTotalSum();
          }, 300);

        } else {
          setTax1_percent(0);
          setTax1_amount(0);
          setTimeout(() => {
            FnCalculateDetailsTotalSum();
          }, 300);
        }
        break;
      case "tax2":
        var tax_2Val = document.getElementById("txt_tax2_id").value;
        setTax2_id(tax_2Val);
        if (tax_2Val !== '0' && tax_2Val !== "") {
          const tax_percent = $('#txt_tax2_id option:selected').attr('tax_value');
          setTax2_percent(tax_percent)
          FnCalculateTaxAmount(tax_percent, 'tax2amount')
        } else if (tax_2Val === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Add Taxation')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css({
              "padding-top": "0px"
            });
          }, 200)
          setTax2_percent(0);
          setTax2_amount(0);
          setTimeout(() => {
            FnCalculateDetailsTotalSum();
          }, 300);
        } else {
          setTax2_percent(0);
          setTax2_amount(0);
          setTimeout(() => {
            FnCalculateDetailsTotalSum();
          }, 300);
        }
        break;
      case "selectPaymentItem":
        calculateHeaderPaidAmount();
        toggleChkAllBoxes('PartiallyPaymentItemSelection');
        break;
      default:
        break;
    }
  };
  // Fn for calculate tax on Total billing Amount
  const FnCalculateTaxAmount = (tax_percent, tax_type) => {
    debugger
    let totalPaid_amount = 0;
    const totalPendingAmount = [...purchasePaymentDetailsData].reduce((total, item) => total + (parseFloat(item.item_pending_amount)), 0);

    const netBillingAmount = txt_total_billing_amount - txt_total_billing_deduction - totalPendingAmount;
    const tax_amount = netBillingAmount * tax_percent / 100;
    switch (tax_type) {
      case 'tax1amount':
        totalPaid_amount = netBillingAmount - tax_amount - txt_tax2_amount;
        setTax1_amount(validateNumberDateInput.current.decimalNumber((tax_amount).toString(), 4))
        break;
      case 'tax2amount':
        totalPaid_amount = netBillingAmount - txt_tax1_amount - tax_amount;
        setTax2_amount(validateNumberDateInput.current.decimalNumber((tax_amount).toString(), 4))
        break;
      default:
        break;
    }
    setTotal_paid_amount(validateNumberDateInput.current.decimalNumber((totalPaid_amount).toString(), 4))
  }
  // Fn For get supplier bank details
  const FnloadSupplierBankDetails = async (supplierId) => {

    resetGlobalQuery();
    globalQuery.columns = ["field_id", "field_name", "supp_bank_account_no", "supp_bank_ifsc_code", "supplier_id"]
    globalQuery.table = "cmv_supplier_banks";
    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    globalQuery.conditions.push({ field: "supplier_id", operator: "=", value: supplierId });
    const suppliersApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
    setSupplierBankOptions(suppliersApiCall)
  }
  //fn for User Access Manage .
  const FnSetFieldsByUserAccess = () => {
    if (keyForViewUpdate != null) {
      setIsApprove(keyForViewUpdate === 'financeApprove' ? true : false);
      // $('#cancel-btn').hide();
      // $('#finance-btn').hide();

      switch (keyForViewUpdate) {
        case 'financeApprove':
        case 'cancel':
        case 'view':
        case 'delete':
          setIsView(true)
          $("form").find("input,textarea,select").attr("disabled", "disabled");
          $("table").find("input,button,textarea,select,checkbox").attr("disabled", "disabled");
          $('input[type="checkbox"]').prop('disabled', true);
          // Disable specific options
          // $("form").find('select#chk_purchasePayment_status option').each(function () {
          //     if ($(this).val() !== 'Z' && $(this).val() !== po_status) {// Assuming you want to disable options with values 'option2' and 'option3'
          //         $(this).prop('disabled', true);
          //     } else {
          //         $(this).prop('disabled', false); // Enable the specific option
          //     }
          // });
          // $('#clearForm_btn_id').hide();
          // $('#viewdocument-id').hide();
          // $('#save-btn').hide();
          $('#print_btn_id').show();
          // $('#calculate_btn_id').attr('disabled', true);
          // if (keyForViewUpdate === 'cancel') {
          //   $('#cancel-btn').show();
          // } else if (keyForViewUpdate === 'financeApprove') {
          //   $('#finance-btn').show();
          // }
          break;
      }
    }
  }
  const validateFields = (form_id) => {
    var formObj = $("#" + form_id);
    var inputObj;
    for (var i = 0; i <= formObj.get(0).length - 1; i++) {
      inputObj = formObj.get(0)[i];
      if (inputObj.type === "text" && inputObj.value !== "") {
        $("#error_" + inputObj.id).hide();
      } else if (inputObj.type === "select-one" && inputObj.value !== "") {
        $("#error_" + inputObj.id).hide();
      } else if (inputObj.type === "textarea" && inputObj.value !== "") {
        $("#error_" + inputObj.id).hide();
      } else if (inputObj.type === "date" && inputObj.value !== "") {
        $("#error_" + inputObj.id).hide();
      }
    }
  };
  //Fn to get default preload data
  const FnloadDefaultData = async () => {
    // get product types list
    // resetGlobalQuery();
    // globalQuery.columns.push("field_id");
    // globalQuery.columns.push("field_name");
    // globalQuery.columns.push("product_type_short_name");
    // globalQuery.table = "smv_product_type";
    // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
    // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
    // const poTypeApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
    // setProductTypeOptions(poTypeApiCall);

    resetGlobalQuery();
    globalQuery.columns.push("field_id");
    globalQuery.columns.push("field_name");
    globalQuery.table = "cmv_supplier";
    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    const suppliersApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
    setSupplierOptions(suppliersApiCall)

    //get expected branch list
    resetGlobalQuery();
    globalQuery.columns.push("company_branch_id");
    globalQuery.columns.push("company_branch_name");
    globalQuery.columns.push("is_sez");
    globalQuery.columns.push("branch_EmailId");
    globalQuery.table = "cmv_company_branch";
    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
    const exptBranchApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
    setExpectedBranchOptions(exptBranchApiCall);

    const getEmployees = await comboDataAPiCall.current.fillMasterData("cmv_employee", "", "");
    setApprovedByOptions(getEmployees);

    //get expected branch list
    resetGlobalQuery();
    globalQuery.columns.push("field_name");
    globalQuery.columns.push("field_id");
    globalQuery.columns.push("tax_value");
    globalQuery.table = "cmv_taxation";
    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
    const taxationApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
    setTax1Options(taxationApiCall);

    //get expected branch list
    resetGlobalQuery();
    globalQuery.columns.push("field_name");
    globalQuery.columns.push("field_id");
    globalQuery.table = "cmv_banks";
    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
    const banksApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
    console.log(banksApiCall);
    setBankOptions(banksApiCall);


  }
  //Fn for generate bill booking no
  const GeneratePurchasePaymentNo = async () => {
    const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("ft_supplier_purchase_payment_master", "supplier_purchase_payment_no", "", "PP", "5");
    setSupplier_purchase_payment_no(autoNoApiCall);
    setSupplier_purchase_payment_version(1);
    return autoNoApiCall;
  }

  const FngetBillStatus = (item) => {
    // const item_billing_amount = item.item_billing_amount;
    let isPending = false;
    const pendingAmt = parseFloat(item.item_pending_amount);
    if (pendingAmt > 0) {
      isPending = true;
    }
    return isPending;
  }
  //--------------------------------------------------  Finance Approve, view Entry ----------------------------------------------------------------------------
  const FnCheckUpdateResponse = async () => {
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtSupplierPurchasePaymentMaster/FnShowAllDetailsAndMastermodelRecords/${txt_supplier_purchase_payment_version}/${FINANCIAL_SHORT_NAME}/${COMPANY_ID}?supplier_purchase_payment_no=${txt_supplier_purchase_payment_no}`)
      const response = await apiCall.json();
      if (response.SupplierPurchasePaymentMasterRecord !== null) {
        let purchasePaymentMasterData = response.SupplierPurchasePaymentMasterRecord;
        let purchasePaymentDetailsRecords = response.SupplierPurchasePaymentDetailsRecord;

        let { supplier_purchase_payment_master_transaction_id, supplier_purchase_payment_no, supplier_purchase_payment_date, supplier_purchase_payment_version, supplier_purchase_payment_type_id,
          supplier_purchase_payment_type, supplier_id, supplier_contacts_ids, expected_branch_id, approved_by_id, approved_date, bank_id, payment_type, supplier_bank_details,
          supplier_bank_id, supplier_bank_account_number, supplier_bank_ifsc_code, total_billing_amount, total_billing_deduction, tax1_id, tax1_percent, tax1_amount, tax2_id, tax2_percent, tax2_amount,
          total_paid_amount, supplier_purchase_payment_status, payment_transaction_details, payment_transaction_date, deduction_remark, purchase_payment_mail_sent_status, remark, is_active
        } = purchasePaymentMasterData;

        setsupplier_purchase_payment_master_transaction_id(supplier_purchase_payment_master_transaction_id)
        setSupplier_purchase_payment_no(supplier_purchase_payment_no)
        setSupplier_purchase_payment_date(supplier_purchase_payment_date)
        setSupplier_purchase_payment_version(supplier_purchase_payment_version)
        // setSupplier_purchase_payment_type_id(supplier_purchase_payment_type_id)
        setSupplier_id(supplier_id)
        await comboOnChange("supplier");
        setsupplier_contacts_ids(supplier_contacts_ids)
        setExpected_branch_id(expected_branch_id)
        setBank_id(bank_id)
        setPayment_type(payment_type)
        setSupplier_bank_details(supplier_bank_details)
        setSupplier_bank_id(supplier_bank_id)
        setSupplier_bank_account_number(supplier_bank_account_number)
        setSupplier_bank_ifsc_code(supplier_bank_ifsc_code)
        setTotal_billing_amount(total_billing_amount)
        setTotal_billing_deduction(total_billing_deduction)
        setTax1_id(tax1_id)
        setTax1_percent(tax1_percent)
        setTax1_amount(tax1_amount)
        setTax2_id(tax2_id)
        setTax2_percent(tax2_percent)
        setTax2_amount(tax2_amount)
        setTotal_paid_amount(total_paid_amount)
        setPurchasePayment_status(supplier_purchase_payment_status)
        setPayment_transaction_details(payment_transaction_details)
        setPayment_transaction_date(payment_transaction_date)
        setDeduction_remark(deduction_remark)
        setPayment_mail_sent_status(purchase_payment_mail_sent_status)
        setRemark(remark)
        if (supplier_contacts_ids !== '') {
          FnSetExistingSupplierContactPersonIds(supplier_contacts_ids)
        }
        if (keyForViewUpdate === 'financeApprove') {
          setApproved_by_id(UserId)
          setApproved_date(currentDate)
        } else {
          setApproved_by_id(approved_by_id)
          setApproved_date(approved_date)
        }
        if (keyForViewUpdate === 'cancel' || keyForViewUpdate === 'financeApprove') {
          setPurchasePayment_status(keyForViewUpdate === 'cancel' ? 'X' : 'F')
          const updatedpurchasePaymentDetailsRecords = purchasePaymentDetailsRecords.map(item => {
            let billBookstatus = '';
            // Update the keys you want to modify
            if (keyForViewUpdate === 'financeApprove') {
              const isPending = FngetBillStatus(item);
              if (isPending) {
                billBookstatus = "R";
              } else {
                billBookstatus = "C";
              }
            }
            return {
              ...item,
              supplier_bill_item_booking_status: billBookstatus,
              supplier_purchase_item_payment_status: keyForViewUpdate === 'cancel' ? 'X' : 'F',
            };
          });
          // Set the state with the updated array
          setPurchasePaymentDetailsData(updatedpurchasePaymentDetailsRecords)
        } else {
          setPurchasePaymentDetailsData(purchasePaymentDetailsRecords)
        }

        let outstandingAmt = 0;
        let headerPaidAmt = 0;
        purchasePaymentDetailsRecords.forEach(element => {
          if (element.item_paid_amount > 0) {
            $("input:checkbox[name=selectPaymentItem][value='" + element.supplier_bill_booking_details_transaction_id + "']").prop('checked', true);
          }
          outstandingAmt = outstandingAmt + element.item_billing_amount;
          headerPaidAmt = headerPaidAmt + element.item_paid_amount;
        });
        setTotal_outstanding(validateNumberDateInput.current.decimalNumber((outstandingAmt).toString(), 4))
        setHeaderPaid_amount(validateNumberDateInput.current.decimalNumber((headerPaidAmt).toString(), 4))
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  //--------------------------------------------------  Finance Approve, view Entry ----------------------------------------------------------------------------

  //------------------------------------------------Starts Supplier Contact------------------------------------------
  //Function for get contact ids,name & mobile nos of supplier
  const FnGetSupplierContacts = async (SelectedSupplier) => {
    debugger
    if (SelectedSupplier !== '' || SelectedSupplier !== '0') {
      resetGlobalQuery();
      globalQuery.columns = ["supplier_id", "supplier_branch_name", "supp_branch_id", "supplier_contact_id",
        "supp_contact_person", "supp_contact_no", "supp_alternate_contact", "supp_email_id", "supp_alternate_EmailId", "supp_designation"]

      globalQuery.table = "cmv_supplier_contacts";
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
      globalQuery.conditions.push({ field: "supplier_id", operator: "=", value: SelectedSupplier });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      const getContactDtlsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

      if (getContactDtlsApiCall.length !== 0) {
        setSuppContactDetails(getContactDtlsApiCall);
      } else {
        setSuppContactDetails([]);
      }
    }
    // Onchange event listener for the customer contact Selection
    $('.selectSuppContactPerson').on('change', function () {
      toggleChkAllBoxes('PartiallySuppContactSelection');
    });

  }

  //Fn to render supplier Contact details table
  const renderSupplierContactsTbl = useMemo(() => {
    return <>
      <Table className="erp_table " id='supplier-contacts-table' responsive bordered striped erp_table_scroll>
        <thead className="erp_table_head">
          <tr>
            <th className="erp_table_th">Select</th>
            <th className="erp_table_th">Branch Name</th>
            <th className="erp_table_th">Contact Person</th>
            <th className="erp_table_th">Contact No</th>
            <th className="erp_table_th">Alternate Contact No</th>
            <th className="erp_table_th">Email</th>
            <th className="erp_table_th">Alternate Email</th>
            <th className="erp_table_th">Designation</th>
          </tr>
        </thead>
        <tbody>
          {suppContactDetails.map((contactItem, index) => (
            <tr rowIndex={index}>
              <td className="erp_table_td">
                <div style={{ display: "flex" }}>
                  <div className="text-center">
                    <input type='checkbox' class="selectSuppContactPerson" name="selectSuppContactPerson" id={'selectSuppContactPerson_' + contactItem.supplier_contact_id} contact_personId={contactItem.supplier_contact_id} value={contactItem.supplier_contact_id} />
                  </div>
                </div>
              </td>
              <td className="erp_table_td text-end">{contactItem.supplier_branch_name}</td>
              <td className="erp_table_td text-end">{contactItem.supp_contact_person}</td>
              <td className="erp_table_td text-end">{contactItem.supp_contact_no}</td>
              <td className="erp_table_td text-end">{contactItem.supp_alternate_contact}</td>
              <td className="erp_table_td text-end">{contactItem.supp_email_id}</td>
              <td className="erp_table_td text-end">{contactItem.supp_alternate_EmailId}</td>
              <td className="erp_table_td text-end">{contactItem.supp_designation}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  }, [suppContactDetails]);
  //function to check All checkBoxes of po terms and payment terms
  function toggleChkAllBoxes(key) {

    switch (key) {
      // For Supplier's contact
      case 'selectAllSuppContact':
        $('.selectSuppContactPerson').prop('checked', $('#selectAllSuppContact').is(":checked"));
        break;
      case 'PartiallySuppContactSelection':
        $('#selectAllSuppContact').prop('checked', $('input:checkbox.selectSuppContactPerson:checked').length === $('input:checkbox.selectSuppContactPerson').length);
        break;
      case 'selectAllPaymentItems':
        $('.selectPaymentItem').prop('checked', $('#selectAllPaymentItems').is(":checked"));
        calculateHeaderPaidAmount();
        break;
      case 'PartiallyPaymentItemSelection':
        $('#selectAllPaymentItems').prop('checked', $('input:checkbox.selectPaymentItem:checked').length === $('input:checkbox.selectPaymentItem').length);
        break;
    }
  }
  const FnGetSupplierContactPersonIds = () => {
    let supplierContactPersonsIds = '';
    const checkboxes = $('.selectSuppContactPerson:checked');
    checkboxes.each(function () {
      supplierContactPersonsIds += $(this).val() + ":";
    });
    return supplierContactPersonsIds.replace(/:$/, '');
  }
  const FnSetExistingSupplierContactPersonIds = (contactIds) => {
    const supplierContactPersonsIds = contactIds.split(':');
    supplierContactPersonsIds.forEach(function (contactId, index) {
      $('#selectSuppContactPerson_' + supplierContactPersonsIds[index]).prop('checked', true);
    });
    toggleChkAllBoxes('PartiallySuppContactSelection');
  }
  //------------------------------------------------End Supplier Contact-----------------------------------------------------------------
  // -----------------------------------------------Add Purchase Payment Starts-------------------------------------------------------------------
  const addPurchasePayment = async (payment_status) => {

    try {
      const validateForm = await FnValidatePurchasePayment();
      if (validateForm) {
        setIsLoading(true);
        let json = { 'TransHeaderFooterData': {}, 'TransDetailData': [], 'commonIds': {} }
        let supplierContactPersonsIds = FnGetSupplierContactPersonIds();
        let supplier_purchase_payment_no = txt_supplier_purchase_payment_no;

        if (keyForViewUpdate === '') {
          // let shortname = $('#cmb_supplier_purchase_payment_type_id option:selected').attr('shortname');
          supplier_purchase_payment_no = await GeneratePurchasePaymentNo();
        }
        // TransHeaderData
        const purchasePayment_form_data = {
          supplier_purchase_payment_master_transaction_id: isApprove || keyForViewUpdate === 'cancel' || keyForViewUpdate === 'financeApprove' ? supplier_purchase_payment_master_transaction_id : 0,
          company_id: COMPANY_ID,
          company_branch_id: COMPANY_BRANCH_ID,
          financial_year: FINANCIAL_SHORT_NAME,
          supplier_purchase_payment_no: supplier_purchase_payment_no,
          supplier_purchase_payment_date: txt_supplier_purchase_payment_date,
          supplier_purchase_payment_version: txt_supplier_purchase_payment_version,
          // supplier_purchase_payment_type_id: cmb_supplier_purchase_payment_type_id,
          // supplier_purchase_payment_type: $('#cmb_supplier_purchase_payment_type_id').find(":selected").text(),
          supplier_id: cmb_supplier_id,
          supplier_contacts_ids: supplierContactPersonsIds,
          expected_branch_id: cmb_expected_branch_id,
          approved_by_id: cmb_approved_by_id,
          approved_date: txt_approved_date,
          bank_id: cmb_bank_id,
          payment_type: chk_payment_type,
          supplier_bank_details: txt_supplier_bank_details,
          supplier_bank_id: cmb_supplier_bank_id,
          supplier_bank_account_number: txt_supplier_bank_account_number,
          supplier_bank_ifsc_code: txt_supplier_bank_ifsc_code,
          total_billing_amount: txt_total_billing_amount,
          total_billing_deduction: txt_total_billing_deduction,
          tax1_id: txt_tax1_id,
          tax1_percent: txt_tax1_percent,
          tax1_amount: txt_tax1_amount,
          tax2_id: txt_tax2_id,
          tax2_percent: txt_tax2_percent,
          tax2_amount: txt_tax2_amount,
          total_paid_amount: txt_total_paid_amount,
          supplier_purchase_payment_status: payment_status === 'X' ? payment_status : chk_purchasePayment_status,
          payment_transaction_details: txt_payment_transaction_details,
          payment_transaction_date: dt_payment_transaction_date,
          deduction_remark: txt_deduction_remark,
          remark: txt_remark,
          is_active: chk_purchase_payment_isactive,
          created_by: UserName,
          modified_by: keyForViewUpdate === 'cancel' || keyForViewUpdate === 'financeApprove' ? UserName : '',
          purchase_payment_mail_sent_status: chk_Payment_mail_sent_status,
        }
        json.TransHeaderFooterData = purchasePayment_form_data;
        console.log('HeaderData: ', json.TransHeaderFooterData);

        // for (let paymentItem = 0; paymentItem < purchasePaymentDetailsData.length; paymentItem++) {
        $("input:checkbox[name=selectPaymentItem]:checked").each(function () {

          const paymentDetailsRow = purchasePaymentDetailsData.find(item => item.supplier_bill_booking_details_transaction_id === parseInt($(this).val()));

          const purchasePaymentRow = {};
          // Set values for each column
          purchasePaymentRow['supplier_purchase_payment_details_transaction_id'] = isApprove || keyForViewUpdate === 'cancel' ? paymentDetailsRow.supplier_purchase_payment_details_transaction_id : 0
          purchasePaymentRow['supplier_purchase_payment_master_transaction_id'] = isApprove || keyForViewUpdate === 'cancel' ? supplier_purchase_payment_master_transaction_id : 0
          purchasePaymentRow['company_id'] = COMPANY_ID
          purchasePaymentRow['company_branch_id'] = COMPANY_BRANCH_ID
          purchasePaymentRow['financial_year'] = FINANCIAL_SHORT_NAME
          purchasePaymentRow['supplier_purchase_payment_no'] = supplier_purchase_payment_no
          purchasePaymentRow['supplier_purchase_payment_date'] = txt_supplier_purchase_payment_date
          purchasePaymentRow['supplier_purchase_payment_version'] = txt_supplier_purchase_payment_version
          purchasePaymentRow['supplier_bill_booking_no'] = paymentDetailsRow.supplier_bill_booking_no
          purchasePaymentRow['supplier_bill_booking_date'] = paymentDetailsRow.supplier_bill_booking_date
          purchasePaymentRow['supplier_bill_booking_version'] = paymentDetailsRow.supplier_bill_booking_version
          purchasePaymentRow['purchase_order_no'] = paymentDetailsRow.purchase_order_no
          purchasePaymentRow['goods_receipt_no'] = paymentDetailsRow.goods_receipt_no
          purchasePaymentRow['supplier_challan_no'] = paymentDetailsRow.supplier_challan_no
          purchasePaymentRow['supplier_challan_date'] = paymentDetailsRow.supplier_challan_date
          purchasePaymentRow['supplier_invoice_no'] = paymentDetailsRow.supplier_invoice_no
          purchasePaymentRow['supplier_invoice_date'] = paymentDetailsRow.supplier_invoice_date
          purchasePaymentRow['expected_schedule_date'] = paymentDetailsRow.expected_schedule_date
          purchasePaymentRow['payment_due_date'] = paymentDetailsRow.payment_due_date
          purchasePaymentRow['item_billing_amount'] = paymentDetailsRow.item_billing_amount
          purchasePaymentRow['item_billing_deduction'] = paymentDetailsRow.item_billing_deduction
          purchasePaymentRow['item_paid_amount'] = paymentDetailsRow.item_paid_amount
          purchasePaymentRow['purchase_order_date'] = paymentDetailsRow.purchase_order_date
          purchasePaymentRow['purchase_order_version'] = paymentDetailsRow.purchase_order_version
          purchasePaymentRow['goods_receipt_date'] = paymentDetailsRow.goods_receipt_date
          purchasePaymentRow['goods_receipt_version'] = paymentDetailsRow.goods_receipt_version
          purchasePaymentRow['supplier_bill_item_booking_status'] = paymentDetailsRow.supplier_bill_item_booking_status
          purchasePaymentRow['supplier_purchase_item_payment_status'] = payment_status === 'X' ? payment_status : paymentDetailsRow.supplier_purchase_item_payment_status
          purchasePaymentRow['remark'] = paymentDetailsRow.remark
          purchasePaymentRow['created_by'] = keyForViewUpdate === '' ? UserName : paymentDetailsRow.created_by
          purchasePaymentRow['supplier_bill_booking_details_transaction_id'] = paymentDetailsRow.supplier_bill_booking_details_transaction_id
          purchasePaymentRow['modified_by'] = keyForViewUpdate === 'cancel' || keyForViewUpdate === 'financeApprove' ? UserName : ''

          json.TransDetailData.push(purchasePaymentRow);
        })
        console.log(json.TransDetailData);
        // Common Ids
        json.commonIds.company_id = COMPANY_ID
        json.commonIds.supplier_purchase_payment_no = supplier_purchase_payment_no
        json.commonIds.supplier_purchase_payment_version = txt_supplier_purchase_payment_version
        json.commonIds.financial_year = FINANCIAL_SHORT_NAME

        console.log("json of Purchase Payment New: ", json);
        let isApproveOrCancel = 'AddUpdate';
        if (keyForViewUpdate === 'financeApprove') {
          isApproveOrCancel = 'financeApprove';
        } else if (keyForViewUpdate === 'cancel') {
          isApproveOrCancel = 'cancel';
        }

        const formData = new FormData();
        formData.append(`ptSupplierPurchasePaymentData`, JSON.stringify(json))
        const requestOptions = {
          method: 'POST',
          body: formData
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtSupplierPurchasePaymentMaster/FnAddUpdateRecord/${isApproveOrCancel}`, requestOptions);
        const response = await apicall.json();
        if (response.success === "0") {
          setErrMsg(response.error)
          setShowErrorMsgModal(true)
        } else {
          const evitCache = comboDataAPiCall.current.evitCache();
          console.log(evitCache);
          setMessage(response.message)
          if (response.data.hasOwnProperty('supplier_purchase_payment_no') && response.data.hasOwnProperty('supplier_purchase_payment_version')) {
            setModalOrderDetails(`Purchase Payment No: ${response.data.supplier_purchase_payment_no} and Version: ${response.data.supplier_purchase_payment_version}`)
          }
          if (chk_purchasePayment_status !== 'P' && keyForViewUpdate === 'financeApprove') {
            setShowMailSentModal(true)
          } else {
            setMessageForConfirmation('')
            setShowConfiemationModal(true);
          }
        }
      }
    } catch (error) {
      console.log('error on addBill: ', error);
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  }

  const FnValidatePurchasePayment = async () => {
    let headerDataIsValid = await validate.current.validateForm("purchasePayment_header_formId");//billBook_footer_formId
    if (!headerDataIsValid) { return false; }
    if ($('input:checkbox.selectSuppContactPerson:checked').length === 0) {
      $('#error_supplier_contact_persons').text('Please select atleast one contact person.');
      $('#error_supplier_contact_persons').show();
      $('#txt_supplier_bank_ifsc_code').focus();
      setErrMsg('Please select atleast one contact person...!');
      setShowErrorMsgModal(true);
      setOpenContactsAccord(true);
      return false;
    } else {
      $('#error_supplier_contact_persons').hide();
    }
    if (parseFloat(txt_header_paid_amount) > parseFloat(txt_total_outstanding)) {
      $('#error_txt_header_paid_amount').text('Please Enter Less Than Outstanding Amount...!');
      $('#error_txt_header_paid_amount').show();
      setHeaderPaid_amount(0)
      return false;
    } else if (parseFloat(txt_header_paid_amount) === 0) {
      $('#error_txt_header_paid_amount').text('Please Enter Greater Than 0...!');
      $('#error_txt_header_paid_amount').show();
      setHeaderPaid_amount(0)
      return false;
    } else if (parseFloat(txt_header_paid_amount) === '') {
      $('#error_txt_header_paid_amount').text('Please Enter Valid Amount...!');
      $('#error_txt_header_paid_amount').show();
      setHeaderPaid_amount('')
      return false;
    } else {
      $('#error_txt_header_paid_amount').hide();
      setHeaderPaid_amount(txt_header_paid_amount)
    }
    let paymentDetailsIsValid = true;
    // let addedMaterials = $('#billBook-details-table tbody tr').length;
    // if (addedMaterials <= 0 && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
    //   setErrMsg('Please Add atleast one Good Receipt Note...!');
    //   setShowErrorMsgModal(true);
    //   return paymentDetailsIsValid = false;
    // } else {
    //   paymentDetailsIsValid = true;
    // }
    if (txt_supplier_purchase_payment_date === '') {
      $('#error_txt_supplier_purchase_payment_date').text('Please fill this Field...!');
      $('#error_txt_supplier_purchase_payment_date').show();
      return paymentDetailsIsValid = false;
    }

    if (txt_total_billing_deduction > 0 && paymentDetailsIsValid) {
      if (parseInt(txt_total_billing_deduction) > parseInt(txt_total_billing_amount)) {
        $('#error_txt_total_billing_deduction').text('Please Enter Less then Total Billing Amount...!');
        $('#error_txt_total_billing_deduction').show();
        return paymentDetailsIsValid = false;
      } else if (txt_deduction_remark === '') {
        $('#error_txt_deduction_remark').text('Please Enter Deduction Remark...!');
        $('#error_txt_deduction_remark').show();
        return paymentDetailsIsValid = false;
      }
    }
    return paymentDetailsIsValid;
  }

  // -----------------------------------------------Add Purchase Payment Ends------------------------------------------------------------------------------------
  // -----------------------------------------------payment Email starts------------------------------------------------------------------------------------

  const sendEmail = async () => {
    try {
      setIsLoading(true);
      setShowMailSentModal(false);
      $('#spinner_text').text('Sending Email...');
      const EmailData = await FngenerateMailJson();

      // Generate the sales-order PDF.
      let purchasePaymentContent = await printInvoice(false);
      const fileName = `${txt_supplier_purchase_payment_no.replaceAll('/', '_')}_@${COMPANY_ID}.pdf`;
      const purchasePaymentContainer = document.createElement('div');
      purchasePaymentContainer.style.margin = '20px';
      purchasePaymentContainer.innerHTML = purchasePaymentContent;
      let worker = html2pdf().from(purchasePaymentContainer).set({
        margin: 0,
        filename: fileName,
        html2canvas: { scale: 2 },
        jsPDF: { orientation: 'portrait', unit: 'pt', format: 'a4', compressPDF: true }
      }).toPdf();

      let pages = [];
      pages.slice(1).forEach(function (page) {
        worker = worker.get('pdf').then(function (pdf) {
          pdf.addPage();
        }).from(page).toContainer().toCanvas().toPdf();
      });

      worker = worker.output("datauristring").then(async function (pdf) {
        const preBlob = dataURItoBlob(pdf);
        const file = new File([preBlob], fileName, { type: 'application/pdf' });

        const formData = new FormData();
        formData.append(`EmailData`, JSON.stringify(EmailData))
        formData.append("purchasePaymentPDF", file);

        const requestOptions = { method: 'POST', body: formData };
        const emailingApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtSupplierPurchasePaymentMaster/FnSendEmail/${supplier_purchase_payment_master_transaction_id}/${COMPANY_ID}`, requestOptions);
        const emailingApiCallResponse = await emailingApiCall.json();

        if (emailingApiCallResponse.success === "1") {
          // Show success message.
          setMessage(emailingApiCallResponse.message)
          setMessageForConfirmation('')
          setShowConfiemationModal(true);
        } else {
          // Show error message.
          setErrMsg(emailingApiCallResponse.message);
          setShowErrorMsgModal(true);
        }
        setIsLoading(false);
      });
    } catch (error) {
      console.log('Error in email sending: ', error);
      navigate('/Error')
    }
  }
  const FngenerateMailJson = async () => {
    let EmailData = { 'EmailType': "Purchase Payment Success", 'toMailData': [], 'bccData': [], 'ccData': [], 'subject': {}, 'mailAttachmentFilePaths': [], 'parameters': [] }
    const checkboxes = $('.selectSuppContactPerson:checked');
    checkboxes.each(function () {
      let selectedContact_id = parseInt($(this).val());
      const supplierContact = suppContactDetails.find(contactPerson => contactPerson.supplier_contact_id === selectedContact_id);
      if (supplierContact) {
        EmailData.toMailData.push(supplierContact.supp_email_id);
      }
    });
    EmailData.subject = `Purchase Payment Sent ${txt_supplier_purchase_payment_no}`;    // Subject
    EmailData.parameters.push("Purchase Payment Information");                            // Param0 It is a heading.
    EmailData.parameters.push($('#cmb_supplier_id').find(":selected").text());          // Param1
    EmailData.parameters.push(`${txt_supplier_purchase_payment_no}`);                                          // Param2
    EmailData.parameters.push($('#chk_purchasePayment_status').find(":selected").text());            // Param3
    EmailData.parameters.push(`${txt_supplier_purchase_payment_date}`);                                        // Param4
    EmailData.parameters.push(sessionStorage.getItem('companyLegalName'));              // Param6
    EmailData.parameters.push(sessionStorage.getItem('companyAddress'));                // Param7
    EmailData.parameters.push($('#cmb_approved_by_id').find(":selected").text());       // Param8
    console.log('emailData: ', EmailData);
    return EmailData;
  }
  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  // -----------------------------------------------payment Email Ends------------------------------------------------------------------------------------
  //--------------------------------------------------Purchase Payment Details ----------------------------------------------------------------------------------

  const showBillBookedDetails = async (supplier_id) => {
    debugger
    let apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtSupplierBillBookingMaster/FnShowBillBookingDetailsRecords/${supplier_id}/${COMPANY_ID}`)
    const response = await apicall.json()
    console.log(response);
    if (response.success === '1') {
      $('#error_good_receipt_orderNoEmpty').hide();
      displayPaymentDetailsData(response);

    } else {
      setErrMsg('No Records Found...!')
      setShowErrorMsgModal(true);
      console.log('Bill Book Data Error: ', response.error);
    }
    // }
  }
  const displayPaymentDetailsData = (response) => {
    let responsePaymentData = [];
    setPurchasePaymentDetailsData((prevArray) => {
      const calculateValues = (item) => {
        return {
          ...item,
          supplier_purchase_payment_no: txt_supplier_purchase_payment_no,
          supplier_purchase_payment_date: txt_supplier_purchase_payment_date,
          supplier_purchase_payment_version: txt_supplier_purchase_payment_version,
          item_billing_amount: item.bill_total,
          item_billing_deduction: 0,
          item_paid_amount: 0,
          supplier_purchase_item_payment_status: 'P',
          remark: ""
        };
      };
      responsePaymentData = [...response.SupplierBillBookingDetailsRecords.map(calculateValues)]
      return [...responsePaymentData];
    });
    FnCalculatePaymentItemPaidAmt(responsePaymentData);
  };

  //Fn for calculate paid amount of payment details
  const FnCalculatePaymentItemPaidAmt = (paymentDetailsData) => {
    paymentDetailsData.forEach(item => {
      item.item_paid_amount = item.item_billing_amount - item.item_prev_paid_amount - item.item_billing_deduction;
      item.item_pending_amount = 0;
      $("input:checkbox[name=selectPaymentItem][value='" + item.supplier_bill_booking_details_transaction_id + "']").prop('checked', true);
    });
    console.log('Updated paymentDetailsData:', paymentDetailsData);
    setPurchasePaymentDetailsData(paymentDetailsData)
    FnCalculateOustandingPaidAmt(paymentDetailsData);
  };

  //Fn for set total outstanding and Total paid amounts to show by on change ckeckbox selectPayment
  const FnCalculateOustandingPaidAmt = (paymentDetailsData) => {
    if (paymentDetailsData === undefined || paymentDetailsData === '') {
      paymentDetailsData = [...purchasePaymentDetailsData];
    }
    let outstandingAmt = 0;
    let prevPendingAmount = 0;
    paymentDetailsData.forEach(item => {
      outstandingAmt = outstandingAmt + parseFloat(item.item_billing_amount);
      prevPendingAmount = prevPendingAmount + parseFloat(item.item_prev_paid_amount);
    });
    setTotal_outstanding(validateNumberDateInput.current.decimalNumber((outstandingAmt).toString(), 4))
    setHeaderPaid_amount(validateNumberDateInput.current.decimalNumber((outstandingAmt - prevPendingAmount).toString(), 4))
  }

  const calculateHeaderPaidAmount = () => {
    debugger
    const totalHeaderPaidAmt = purchasePaymentDetailsData.reduce((total, item) => {
      const checkbox = $(`input:checkbox[name=selectPaymentItem][value='${item.supplier_bill_booking_details_transaction_id}']`);
      if (checkbox.prop('checked')) {
        total += parseFloat(item.item_billing_amount);
      }
      return total;
    }, 0);
    const formattedAmount = validateNumberDateInput.current.decimalNumber(totalHeaderPaidAmt.toString(), 4);
    setHeaderPaid_amount(formattedAmount);
    FnCalPaymentDetailsOnChange(formattedAmount);
  }

  //Fn for set detail data on change checkbox
  const FnCalPaymentDetailsOnChange = (headerPaidAmount) => {
    debugger
    setPurchasePaymentDetailsData((prevData) => {
      const updatedPaymentDetails = [...prevData.map((selectedItemData) => {
        // var selector = "input:checkbox[name='selectPaymentItem'][value='" + selectedItemData.supplier_bill_booking_details_transaction_id + "']";
        var checkboxId = `selectPaymentItem_${selectedItemData.supplier_bill_booking_details_transaction_id}`;
        var checkbox = document.getElementById(checkboxId);
        // Select the checkbox element
        // var checkbox = document.querySelector(selector);

        selectedItemData.item_paid_amount = selectedItemData.item_billing_amount - selectedItemData.item_prev_paid_amount - selectedItemData.item_billing_deduction;

        if (headerPaidAmount !== 0) {
          if (headerPaidAmount >= selectedItemData.item_paid_amount) {
            headerPaidAmount -= selectedItemData.item_paid_amount;
            selectedItemData.item_pending_amount = 0;
            // Check the checkbox if found
            if (checkbox) {
              checkbox.checked = true;
            }
            // $("input:checkbox[name='selectPaymentItem'][value='" + selectedItemData.supplier_bill_booking_details_transaction_id + "']").prop("checked", true);
          } else {
            selectedItemData.item_pending_amount = selectedItemData.item_paid_amount - headerPaidAmount;
            selectedItemData.item_paid_amount = headerPaidAmount;
            headerPaidAmount = 0;
            // Check the checkbox if found
            if (checkbox) {
              checkbox.checked = true;
            }
            // $("input:checkbox[name='selectPaymentItem'][value='" + selectedItemData.supplier_bill_booking_details_transaction_id + "']").prop("checked", true);
          }
        } else {
          selectedItemData.item_paid_amount = 0;
          selectedItemData.item_pending_amount = 0;
          selectedItemData.item_billing_deduction = 0;
          // Check the checkbox if found
          if (checkbox) {
            checkbox.checked = false;
          }
          // $("input:checkbox[name='selectPaymentItem'][value='" + selectedItemData.supplier_bill_booking_details_transaction_id + "']").prop("checked", false);
        }

        return selectedItemData;
      })]
      return updatedPaymentDetails;
    });
  };

  //fn for calculate details data on initial state
  const FnCalculatePurchaseDetails = () => {
    let headerPaidAmount = $('#txt_header_paid_amount').val() === '' || $('#txt_header_paid_amount').val() === '0' ? 0 : $('#txt_header_paid_amount').val();
    debugger
    if (purchasePaymentDetailsData.length !== 0) {

      const updatedDetailsData = purchasePaymentDetailsData.map((item) => {
        item.item_paid_amount = item.item_billing_amount - item.item_prev_paid_amount - item.item_billing_deduction;

        if (headerPaidAmount !== 0) {
          if (headerPaidAmount > item.item_paid_amount) {
            headerPaidAmount -= item.item_paid_amount;
            item.item_pending_amount = 0;
            // item.supplier_purchase_item_payment_status = 'C';
            $("input:checkbox[name='selectPaymentItem'][value='" + item.supplier_bill_booking_details_transaction_id + "']").prop("checked", true);
          } else {
            item.item_pending_amount = item.item_paid_amount - headerPaidAmount;
            item.item_paid_amount = headerPaidAmount;
            headerPaidAmount = 0;
            // item.supplier_purchase_item_payment_status = 'C';
            $("input:checkbox[name='selectPaymentItem'][value='" + item.supplier_bill_booking_details_transaction_id + "']").prop("checked", true);
          }
          return item; // Ensure you return the updated item in the map function
        } else {
          item.item_paid_amount = 0;
          item.item_pending_amount = 0;
          $("input:checkbox[name='selectPaymentItem'][value='" + item.supplier_bill_booking_details_transaction_id + "']").prop("checked", false);
          return item;
        }
      });
      console.log('updatedDetailsData after change of HeaderPaidAmount: ', updatedDetailsData);
      // Update state after the loop
      setPurchasePaymentDetailsData(updatedDetailsData);
    }
  };
  //Fn to render bill book  details table
  const renderPurchasePaymentDetailsTbl = useMemo(() => {
    return <>
      <Table className="erp_table " id='billBook-details-table' responsive bordered striped erp_table_scroll>
        <thead className="erp_table_head">
          <tr>
            <th className='erp_table_th'>Action</th>
            <th className='erp_table_th'>Sr.No.</th>
            <th className="erp_table_th">Bill Booking  No</th>
            <th className="erp_table_th">purchase Order No</th>
            <th className="erp_table_th">Goods Receipt No</th>
            <th className="erp_table_th">Schedule Payment Date</th>
            <th className="erp_table_th">Payment Due Date</th>
            <th className="erp_table_th">Billing Amount</th>
            <th className="erp_table_th">Previous Paid Amount</th>
            <th className="erp_table_th">Billing Deduction</th>
            <th className="erp_table_th">Paid Amount</th>
            <th className="erp_table_th">Pending Amount</th>
            <th className="erp_table_th">Purchase Payment Item Status</th>
            <th className="erp_table_th">Payment Remark</th>
            <th className="erp_table_th">Supplier Challan No</th>
            <th className="erp_table_th">Supplier Invoice No</th>
            <th className="erp_table_th">Supplier Challan Date</th>
            <th className="erp_table_th">Supplier Invoice Date</th>
            <th className="erp_table_th">purchase Order Date</th>
            <th className="erp_table_th">purchase Order Version</th>
            <th className="erp_table_th">Goods Receipt Date</th>
            <th className="erp_table_th">Goods Receipt Version</th>
            <th className="erp_table_th">Bill Booking  Date</th>
            <th className="erp_table_th">Bill Booking  Version</th>
          </tr>
        </thead>
        <tbody>
          {purchasePaymentDetailsData.map((payment_item, index) => (
            <tr rowIndex={index}>
              <td className="erp_table_td">
                <div style={{ display: "flex" }}>
                  <div className="text-center">
                    <input type='checkbox' class="selectPaymentItem" name="selectPaymentItem" onChange={() => comboOnChange("selectPaymentItem")}
                      id={'selectPaymentItem_' + payment_item.supplier_bill_booking_details_transaction_id} value={payment_item.supplier_bill_booking_details_transaction_id} />
                  </div>
                </div>
              </td>
              <td className="erp_table_td text-end">{index + 1}</td>
              <td className="erp_table_td">{payment_item.supplier_bill_booking_no}</td>
              <td className="erp_table_td">{payment_item.purchase_order_no}</td>
              <td className="erp_table_td">{payment_item.goods_receipt_no}</td>
              <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(payment_item.expected_schedule_date)}</td>
              <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(payment_item.payment_due_date)}</td>
              <td className="erp_table_td text-end">{payment_item.item_billing_amount}</td>
              <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber((payment_item.item_prev_paid_amount).toString(), 4)}</td>
              <td className="erp_table_td text-end">
                {keyForViewUpdate !== "" ?
                  <>
                    {validateNumberDateInput.current.decimalNumber((payment_item.item_billing_deduction).toString(), 4)}</> :
                  <input type="text" className="erp_input_field mb-0 text-end" Headers="item_billing_deduction"
                    id={`item_billing_deduction_${index}`} value={validateNumberDateInput.current.decimalNumber((payment_item.item_billing_deduction).toString(), 4)} onChange={(e) => { updatePurchasePaymentTblRows(payment_item, e); }} />
                }
              </td>
              <td className="erp_table_td  text-end">{validateNumberDateInput.current.decimalNumber((payment_item.item_paid_amount).toString(), 4)}</td>
              <td className="erp_table_td  text-end">{validateNumberDateInput.current.decimalNumber((payment_item.item_pending_amount).toString(), 4)}</td>
              <td className="erp_table_td ">
                <select id={`supplier_purchase_item_payment_status_${index}`} disabled={!isApprove} value={payment_item.supplier_purchase_item_payment_status} className="form-select form-select-sm approveField"
                  Headers='supplier_purchase_item_payment_status' onChange={(e) => { updatePurchasePaymentTblRows(payment_item, e); }} >
                  <option value="P">Pending</option>
                  <option value="C">Payment Done</option>
                  <option value="X">Canceled</option>
                  <option value="F">Finance Approve</option>
                </select>
              </td>
              <td className="erp_table_td ">
                {keyForViewUpdate !== "" ?
                  <>
                    {payment_item.remark}</> :
                  <input type="text" className="erp_input_field mb-0" Headers="remark"
                    id={`remark_${index}`} value={payment_item.remark} onChange={(e) => { updatePurchasePaymentTblRows(payment_item, e); }} />
                }

              </td>
              <td className="erp_table_td">{payment_item.supplier_challan_no}</td>
              <td className="erp_table_td">{payment_item.supplier_invoice_no}</td>
              <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(payment_item.supplier_challan_date)}</td>
              <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(payment_item.supplier_invoice_date)}</td>
              <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(payment_item.purchase_order_date)}</td>
              <td className="erp_table_td  text-end">{payment_item.purchase_order_version}</td>
              <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(payment_item.goods_receipt_date)}</td>
              <td className="erp_table_td  text-end">{payment_item.goods_receipt_version}</td>
              <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(payment_item.purchase_order_date)}</td>
              <td className="erp_table_td text-end">{payment_item.supplier_bill_booking_version}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  }, [purchasePaymentDetailsData]);

  const updatePurchasePaymentTblRows = (rowData, event) => {

    let clickedColName = event.target.getAttribute('Headers');
    let enteredValue = parseInt(event.target.value);
    if (isNaN(enteredValue)) {
      enteredValue = 0;
    }
    switch (clickedColName) {
      case 'item_billing_deduction':
        let focusedInputField = document.querySelector('#' + event.target.id);
        rowData[clickedColName] = event.target.value;
        rowData['item_paid_amount'] = validateNumberDateInput.current.decimalNumber(('0').toString(), 4);
        if (event.target.value !== '') {
          const itemBillingAmount = rowData.item_billing_amount;
          const itemprevPaidAmt = rowData.item_prev_paid_amount;
          const billingMinusPrev = itemBillingAmount - itemprevPaidAmt;
          if (itemBillingAmount > itemprevPaidAmt || itemBillingAmount !== itemprevPaidAmt) {
            if (event.target.value <= billingMinusPrev) {
              delete focusedInputField.parentElement.dataset.tip;
              const itemBillingDeduction = event.target.value;
              let itemPaidAmount = billingMinusPrev - itemBillingDeduction;
              rowData['item_paid_amount'] = validateNumberDateInput.current.decimalNumber((itemPaidAmount).toString(), 4);
              FnCalculatePurchaseDetails();
            } else {
              rowData[clickedColName] = '0';
              focusedInputField.parentElement.dataset.tip = "Please Enter Less Than " + billingMinusPrev + "...! ";
            }
          } else {
            rowData[clickedColName] = '0';
          }

        } else {
          delete focusedInputField.parentElement.dataset.tip;
          focusedInputField.parentElement.dataset.tip = 'Please fill this field...!';
        }

        break;
      case 'supplier_purchase_item_payment_status':
      case 'remark':
        rowData[clickedColName] = event.target.value;
        break;
    }

    const detailData = [...purchasePaymentDetailsData]
    const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
    detailData[arrayIndex] = rowData
    setPurchasePaymentDetailsData(detailData);

  }

  const FnCalculateDetailsTotalSum = () => {
    debugger
    let total_billing_amount = 0;
    let total_prev_paid_amount = 0;
    let total_deduction_amount = 0;
    let total_pending_amount = 0;
    let tax1_amount = 0;
    let tax2_amount = 0;
    // let tax1_amount = document.getElementById('txt_tax1_amount').value;
    // let tax2_amount = document.getElementById('txt_tax2_amount').value;
    let tax1_percent = document.getElementById('txt_tax1_percent').value;
    let tax2_percent = document.getElementById('txt_tax2_percent').value;
    let totalPaid_amount = 0;

    // details Data
    $("input:checkbox[name=selectPaymentItem]:checked").each(function () {

      let findPaymentItemData = [...purchasePaymentDetailsData].find(item => item.supplier_bill_booking_details_transaction_id === parseInt($(this).val()));
      total_billing_amount += isNaN(parseFloat(findPaymentItemData.item_billing_amount)) ? 0 : parseFloat(findPaymentItemData.item_billing_amount);
      total_prev_paid_amount += isNaN(parseFloat(findPaymentItemData.item_prev_paid_amount)) ? 0 : parseFloat(findPaymentItemData.item_prev_paid_amount);
      total_deduction_amount += isNaN(parseFloat(findPaymentItemData.item_billing_deduction)) ? 0 : parseFloat(findPaymentItemData.item_billing_deduction);
      total_pending_amount += isNaN(parseFloat(findPaymentItemData.item_pending_amount)) ? 0 : parseFloat(findPaymentItemData.item_pending_amount);
    });
    console.log('Total_billing_amount: ', total_billing_amount, 'total_deduction_amount: ', total_deduction_amount);
    const netBillingAmount = total_billing_amount - total_prev_paid_amount - total_deduction_amount - total_pending_amount;

    tax1_amount = netBillingAmount * parseInt(tax1_percent) / 100;
    tax2_amount = netBillingAmount * parseInt(tax2_percent) / 100;
    totalPaid_amount = netBillingAmount - tax1_amount - tax2_amount;

    setTax1_amount(validateNumberDateInput.current.decimalNumber((tax1_amount).toString(), 4))
    setTax2_amount(validateNumberDateInput.current.decimalNumber((tax2_amount).toString(), 4))
    setTotal_billing_amount(validateNumberDateInput.current.decimalNumber((total_billing_amount).toString(), 4));
    setTotal_billing_deduction(validateNumberDateInput.current.decimalNumber((total_deduction_amount).toString(), 4));
    setTotal_paid_amount(validateNumberDateInput.current.decimalNumber((totalPaid_amount).toString(), 4))
  }
  const FnValidatePaidAmt = (e) => {

    if (e.target.value === '') {
      $('#error_txt_header_paid_amount').text('Please fill this field...!');
      $('#error_txt_header_paid_amount').show();
      setHeaderPaid_amount('')
    } else if (parseFloat(e.target.value) === 0) {
      $('#error_txt_header_paid_amount').text('Please Enter Greater Than 0...!');
      $('#error_txt_header_paid_amount').show();
      setHeaderPaid_amount(0)
    } else if (parseFloat(e.target.value) > parseFloat(txt_total_outstanding)) {
      $('#error_txt_header_paid_amount').text('Please Enter Less Than Outstanding Amount...!');
      $('#error_txt_header_paid_amount').show();
      setHeaderPaid_amount(0)
    } else {
      $('#error_txt_header_paid_amount').hide();
      setHeaderPaid_amount(e.target.value)
    }
  }

  //Fn validate  account no 
  const validateAccNo = (noKey) => {
    const regexNo = /^[0-9\b]+$/;
    const value = noKey.target.value
    switch (noKey.target.id) {
      case 'txt_supplier_bank_account_number':
        if (regexNo.test(value) || value === '') {
          setSupplier_bank_account_number(value);
        }
        break;
    }
  }

  //----------------------------------------------Show Bill Booked Nos Ends----------------------------------------------------------------------

  //---------------------------------------------- Payment Print Starts ----------------------------------------------------------------------
  const purchasePaymentInvoiceContent = {
    company: {
      company: '',
      company_branch: '',
      phone: '',
      city_name: '',
      state_name: '',
      company_pincode: '',
      bank_branch_name: '',
      bank_account_no: '',
      bank_ifsc_code: '',

    },
    supplierDetails: {
      name: '',
      address: '',
      state: '',
      GSTIN: '',
      pan_no: '',
      contact: '',
      supplier_bank_name: '',
      supplier_bank_account_number: '',
      supplier_bank_ifsc_code: '',
      supp_branch_EmailId: '',
      payment_transaction_details: '',
      payment_transaction_date: '',

    },

    paymentDetails: {
      supplier_purchase_payment_no: '',
      supplier_purchase_payment_date: '',
      supplier_purchase_payment_version: '',
      supplier_purchase_payment_type: '',
    },

    footer: {
      total_billing_amount_words: '',
      total_billing_deduction_words: '',
      total_paid_amount_words: '',

      total_billing_amount: '',
      total_billing_deduction: '',
      tax1_percent: '',
      tax1_amount: '',
      tax2_percent: '',
      tax2_amount: '',
      total_paid_amount: '',
      taxtype_name1: '',
      taxtype_name2: '',
      deduction_remark: '',
    },

    items: [],
    paymentTerms: [],
  };
  const printInvoice = async (openPrintModal) => {

    debugger
    let fin_year = idList.financial_year;
    let purchase_payment_version = idList.supplier_purchase_payment_version;
    setSupplier_purchase_payment_no(idList.supplier_purchase_payment_no)
    setSupplier_purchase_payment_version(idList.supplier_purchase_payment_version)
    console.log(idList.supplier_purchase_payment_version)
    console.log(idList.supplier_purchase_payment_no)
    const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtSupplierPurchasePaymentMaster/FnShowAllDetailsAndMastermodelRecords/${purchase_payment_version}/${fin_year}/${COMPANY_ID}?supplier_purchase_payment_no=${idList.supplier_purchase_payment_no}`)
    const response = await apiCall.json();
    console.log("Response Payment Invoice : ", response);
    debugger
    let paymentMasterData = response.SupplierPurchasePaymentMasterRecord;
    let paymentDetailsData = response.SupplierPurchasePaymentDetailsRecord;
    // let PurchaseOrderPaymentTermsData = response.PurchaseOrderPaymentTerms;

    //Purchase details
    purchasePaymentInvoiceContent.paymentDetails.supplier_purchase_payment_no = paymentMasterData.supplier_purchase_payment_no
    purchasePaymentInvoiceContent.paymentDetails.supplier_purchase_payment_date = validateNumberDateInput.current.formatDateToDDMMYYYY(paymentMasterData.supplier_purchase_payment_date)
    purchasePaymentInvoiceContent.paymentDetails.supplier_purchase_payment_version = paymentMasterData.supplier_purchase_payment_version
    purchasePaymentInvoiceContent.paymentDetails.supplier_purchase_payment_type = paymentMasterData.supplier_purchase_payment_type



    //Company details
    purchasePaymentInvoiceContent.company.company = paymentMasterData.company_name
    purchasePaymentInvoiceContent.company.company_branch = paymentMasterData.company_branch_name
    purchasePaymentInvoiceContent.company.phone = paymentMasterData.expected_phone_no
    purchasePaymentInvoiceContent.company.pan_no = paymentMasterData.expected_branch_city
    purchasePaymentInvoiceContent.company.state_name = paymentMasterData.expected_branch_state
    purchasePaymentInvoiceContent.company.company_pincode = paymentMasterData.expected_pincode

    //supplier details
    purchasePaymentInvoiceContent.supplierDetails.name = paymentMasterData.supplier_name
    purchasePaymentInvoiceContent.supplierDetails.GSTIN = paymentMasterData.supp_branch_gst_no
    purchasePaymentInvoiceContent.supplierDetails.pan_no = paymentMasterData.supp_branch_pan_no
    purchasePaymentInvoiceContent.supplierDetails.contact = paymentMasterData.supp_branch_phone_no

    purchasePaymentInvoiceContent.supplierDetails.supplier_bank_name = paymentMasterData.supplier_bank_name
    purchasePaymentInvoiceContent.supplierDetails.supplier_bank_account_number = paymentMasterData.supplier_bank_account_number
    purchasePaymentInvoiceContent.supplierDetails.supplier_bank_ifsc_code = paymentMasterData.supplier_bank_ifsc_code
    purchasePaymentInvoiceContent.supplierDetails.supp_branch_EmailId = paymentMasterData.supp_branch_EmailId
    purchasePaymentInvoiceContent.supplierDetails.payment_transaction_details = paymentMasterData.payment_transaction_details
    purchasePaymentInvoiceContent.supplierDetails.payment_transaction_date = validateNumberDateInput.current.formatDateToDDMMYYYY(paymentMasterData.payment_transaction_date)
    purchasePaymentInvoiceContent.supplierDetails.payment_type_desc = paymentMasterData.payment_type_desc

    //footer details 
    purchasePaymentInvoiceContent.footer.total_billing_amount = paymentMasterData.total_billing_amount
    purchasePaymentInvoiceContent.footer.total_billing_deduction = paymentMasterData.total_billing_deduction
    purchasePaymentInvoiceContent.footer.tax1_percent = paymentMasterData.tax1_percent
    purchasePaymentInvoiceContent.footer.tax1_amount = paymentMasterData.tax1_amount
    purchasePaymentInvoiceContent.footer.tax2_percent = paymentMasterData.tax2_percent
    purchasePaymentInvoiceContent.footer.tax2_amount = paymentMasterData.tax2_amount
    purchasePaymentInvoiceContent.footer.total_paid_amount = paymentMasterData.total_paid_amount
    purchasePaymentInvoiceContent.footer.taxtype_name1 = paymentMasterData.taxtype_name1
    purchasePaymentInvoiceContent.footer.taxtype_name2 = paymentMasterData.taxtype_name2
    purchasePaymentInvoiceContent.footer.deduction_remark = paymentMasterData.deduction_remark

    for (let index = 0; index < paymentDetailsData.length; index++) {
      const element = paymentDetailsData[index];

      const detailsData = {
        sr_no: index + 1,
        supplier_bill_booking_no: element.supplier_bill_booking_no,
        supplier_bill_booking_date: validateNumberDateInput.current.formatDateToDDMMYYYY(element.supplier_bill_booking_date),
        goods_receipt_no: element.goods_receipt_no,
        purchase_order_no: element.purchase_order_no,
        supplier_challan_no: element.supplier_challan_no,
        supplier_challan_date: validateNumberDateInput.current.formatDateToDDMMYYYY(element.supplier_challan_date),
        supplier_invoice_no: element.supplier_invoice_no,
        supplier_invoice_date: validateNumberDateInput.current.formatDateToDDMMYYYY(element.supplier_invoice_date),
        payment_due_date: validateNumberDateInput.current.formatDateToDDMMYYYY(element.payment_due_date),
        item_billing_amount: element.item_billing_amount,
        item_prev_paid_amount: element.item_prev_paid_amount,
        item_pending_amount: element.item_pending_amount,
        item_billing_deduction: element.item_billing_deduction,
        item_paid_amount: element.item_paid_amount,
      }
      purchasePaymentInvoiceContent.items.push(detailsData)
    }

    if (openPrintModal) {
      navigate('/Invoice', { state: { invoiceData: purchasePaymentInvoiceContent, navigationLink: '/Transactions/TPurchaseOrder/TPurchasePaymentSupplier/FrmPurchasePaymentSupplierEntry', invoiceType: 'SPP', title: 'Supplier Purchase Payment', idList: idList, keyForViewUpdate: keyForViewUpdate } });

    } else {
      // return the html code.
      return renderToString(<SupplierPurchasePaymentInvoice invoiceContent={purchasePaymentInvoiceContent} />);
    }

  };
  //---------------------------------------------- Payment Print Ends --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  //--------------------------------------- Payment EXcelToExport Start ----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  const ExportToExcel = () => {
    debugger
    const catCount = sessionStorage.getItem('compRMCatCount')
    var counter = 1;
    if (purchasePaymentDetailsData.length !== 0) {
      var jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', editable_cols: [] }
      jsonToExportExcel.filtrKeyValue['0'] = "Supplier Purchase Payment No" + ' : ' + txt_supplier_purchase_payment_no;
      jsonToExportExcel.filtrKeyValue['1'] = "Supplier Purchase Payment Version" + ' : ' + txt_supplier_purchase_payment_version;
      jsonToExportExcel.filtrKeyValue['2'] = "Supplier Purchase Payment Date" + ' : ' + validateNumberDateInput.current.formatDateToDDMMYYYY(txt_supplier_purchase_payment_date);
      jsonToExportExcel.filtrKeyValue['3'] = "Supplier" + ' : ' + $('#cmb_supplier_id').find(":selected").text() + "(" + cmb_supplier_id + ")";
      jsonToExportExcel.filtrKeyValue['4'] = "Supplier Bank" + ' : ' + (cmb_supplier_bank_id !== "" ? $('#cmb_supplier_bank_id').find(":selected").text() + "(" + cmb_supplier_bank_id + ")" : "");
      jsonToExportExcel.filtrKeyValue['5'] = "Supplier Bank Account No" + ' : ' + txt_supplier_bank_account_number;
      jsonToExportExcel.filtrKeyValue['6'] = "Supplier Bank IFSC Code" + ' : ' + txt_supplier_bank_ifsc_code;
      jsonToExportExcel.filtrKeyValue['7'] = "Payment Type" + ' : ' + $('#chk_payment_type').find(":selected").text() + "(" + chk_payment_type + ")";
      jsonToExportExcel.filtrKeyValue['8'] = "Payment Details" + ' : ' + txt_payment_transaction_details;
      jsonToExportExcel.filtrKeyValue['9'] = "Payment Date : " + validateNumberDateInput.current.formatDateToDDMMYYYY(dt_payment_transaction_date);
      jsonToExportExcel.filtrKeyValue['10'] = "Company Branch" + ' : ' + $('#cmb_expected_branch_id').find(":selected").text() + "(" + cmb_expected_branch_id + ")";
      jsonToExportExcel.filtrKeyValue['11'] = "Approved By : " + (cmb_approved_by_id === '0' || cmb_approved_by_id === '' || cmb_approved_by_id === null ? '' : $('#cmb_approved_by_id').find(":selected").text() + "(" + cmb_approved_by_id + ")");
      jsonToExportExcel.filtrKeyValue['12'] = "Approved Date : " + validateNumberDateInput.current.formatDateToDDMMYYYY(txt_approved_date);
      jsonToExportExcel.filtrKeyValue['13'] = "Purchase Payment Status : " + $('#chk_purchasePayment_status').find(":selected").text() + "(" + chk_purchasePayment_status + ")";
      jsonToExportExcel.filtrKeyValue['14'] = "Total OutStanding" + ' : ' + txt_total_outstanding;
      jsonToExportExcel.filtrKeyValue['15'] = "Total Paid Amount" + ' : ' + txt_header_paid_amount;
      jsonToExportExcel.filtrKeyValue['16'] = "Remark" + ' : ' + txt_remark;
      jsonToExportExcel.filtrKeyValue['17'] = "Mail Status" + ' : ' + $('input[name="chk_Payment_mail_sent_status"]:checked').attr("lbl") + "(" + chk_Payment_mail_sent_status + ")";
      let colNames = [
        "sr_no",
        "supplier_bill_booking_no",
        "purchase_order_no",
        "goods_receipt_no",
        "expected_schedule_date",
        "payment_due_date",
        "item_billing_amount",
        "item_prev_paid_amount",
        "item_billing_deduction",
        "item_paid_amount",
        "item_pending_amount",
        "supplier_purchase_item_payment_status",
        "remark",
        "supplier_challan_no",
        "supplier_invoice_no",
        "supplier_challan_date",
        "supplier_invoice_date",
        "purchase_order_date",
        "purchase_order_version",
        "goods_receipt_date",
        "goods_receipt_version",
        "supplier_bill_booking_date",
        "supplier_bill_booking_version",
      ];
      for (let col = 0; col < colNames.length; col++) {
        jsonToExportExcel.columns.push({ "Headers": colNames[col], "accessor": colNames[col] })
      }
      purchasePaymentDetailsData.map((orderDetail, Index) => {
        const keysConvertedOrderDetail = {
          ...orderDetail,
          sr_no: Index + 1,
        };
        jsonToExportExcel['allData'][Index] = keysConvertedOrderDetail;
      });
      jsonToExportExcel['headings']['ReportName'] = "Supplier Purchase Payment"
      jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
      jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
      console.log("jsonToExportExcel: ", jsonToExportExcel)
      exlsExp.current.excel(jsonToExportExcel, txt_supplier_purchase_payment_no + "@" + COMPANY_ID)
    } else {
      setErrMsg('Please add at least one material!... ')
      setShowErrorMsgModal(true)
    }
  }
  // --------------------------------------- PO EXcelToExport End ------------------------------------------------------------------
  //------------------------------------------ Clear Form Fields -------------------------------------------------------------------------------------------------
  const FnClearFormFields = async () => {
    //cleared master hooks
    setsupplier_purchase_payment_master_transaction_id(0)
    await GeneratePurchasePaymentNo()
    setSupplier_purchase_payment_version(1)
    setSupplier_purchase_payment_date(currentDate)
    setSupplier_id('')
    setsupplier_contacts_ids('')
    setExpected_branch_id(COMPANY_BRANCH_ID)
    setApproved_by_id('')
    setApproved_date('')
    setBank_id('')
    setPayment_type('1')
    setSupplier_bank_details('')
    setSupplier_bank_id('')
    setSupplier_bank_account_number('')
    setSupplier_bank_ifsc_code('')
    setPayment_transaction_details('')
    setPayment_transaction_date(currentDate)
    setPurchasePayment_status('P')
    setTotal_outstanding(0)
    setHeaderPaid_amount(0)
    setPayment_mail_sent_status('F')
    setTotal_billing_amount(0)
    setTax1_id('');
    setTax1_percent(0);
    setTax1_amount(0);
    setTax2_id('');
    setTax2_percent(0);
    setTax2_amount(0);
    setTotal_billing_deduction(0)
    setTotal_paid_amount(0)
    setDeduction_remark('')
    setRemark('')
    setPurchase_payment_isactive(true)
    setSupplierBankOptions([])
    setSuppContactDetails([])
    setPurchasePaymentDetailsData([])
  }
  //------------------------------------------ Clear Form Fields Ends-------------------------------------------------------------------------------------------------

  // Reverse Calculations to update dettails data table on change of discount & freight amount
  const reverseOnTotalDeductionChange = (e) => {
    debugger
    let billing_deduction = e.target.value;
    setTotal_billing_deduction(validateNumberDateInput.current.decimalNumber(e.target.value, 4));
    const paymentDetailData = [...purchasePaymentDetailsData];
    const checkedLength = $("input:checkbox[name=selectPaymentItem]:checked").length;

    $("input:checkbox[name=selectPaymentItem]:checked").each(function () {
      const supplier_bill_booking_details_transaction_id = parseInt($(this).val());
      const findPaymentDetailsIndex = paymentDetailData.findIndex((item) => item.supplier_bill_booking_details_transaction_id === supplier_bill_booking_details_transaction_id);
      if (findPaymentDetailsIndex != -1) {
        let itemDeductionAmount = billing_deduction !== '0' ? billing_deduction / checkedLength : 0;
        paymentDetailData[findPaymentDetailsIndex].item_billing_deduction = validateNumberDateInput.current.decimalNumber(JSON.stringify(itemDeductionAmount), 4);
        paymentDetailData[findPaymentDetailsIndex].item_paid_amount = paymentDetailData[findPaymentDetailsIndex].item_billing_amount - paymentDetailData[findPaymentDetailsIndex].item_prev_paid_amount - paymentDetailData[findPaymentDetailsIndex].item_billing_deduction;
      }
    });
    setPurchasePaymentDetailsData(paymentDetailData)
    FnCalculatePurchaseDetails();

  }

  // const reverseOnTotalDeductionChange = (e) => {
  //   let billing_deduction = e.target.value;
  //   setTotal_billing_deduction(validateNumberDateInput.current.decimalNumber(e.target.value, 4));
  //   debugger
  //   let paymentDetailData = [...purchasePaymentDetailsData];
  //   const checkedLength = $("input:checkbox[name=selectPaymentItem]:checked").length;

  //   const updatedData = paymentDetailData.map(obj => {
  //     if (billing_deduction !== '0') {
  //       let itemDeductionAmount;
  //       if (itemDeductionAmount !== 0) {
  //         itemDeductionAmount = (billing_deduction / checkedLength);
  //       }
  //       return {
  //         ...obj,
  //         item_billing_deduction: itemDeductionAmount === 0 ? obj.item_billing_deduction : validateNumberDateInput.current.decimalNumber(JSON.stringify(itemDeductionAmount), 4),
  //       };
  //     } else {
  //       return {
  //         ...obj,
  //         item_billing_deduction: 0,
  //       };
  //     }
  //   });
  // // FnCalPaymentDetailsOnChange(updatedData)
  // }

  //---------------------------------------------------- Accordian data load starts --------------------------------------------------------------------------------
  const FnLoadAccordionData = async (eventKey) => {
    let checkedLength = 0;
    switch (eventKey) {
      case 'documentList':
        await showDocumentRecords();
        break;
      default:
        break;
    }

  }
  //--------------------------------- Document List Starts--------------------------------------------------

  // Fn for get document list
  const showDocumentRecords = async () => {
    try {
      if (COMPANY_ID !== null) {
        const res = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_supplier_purchase_payment_no !== 0 ? txt_supplier_purchase_payment_no.replaceAll('/', '_') : null}`
        );
        const resp = await res.json();
        if (resp.content.length > 0) {
          setDocumentData(resp.content);
        }
      }

    } catch (error) {
      console.log("error: ", error)
    }
  }

  // Fn for get document table
  const renderDocumentTable = useMemo(() => {
    return <>
      <Table className="erp_table " id='document-table' responsive bordered striped>
        <thead className="erp_table_head">
          <tr>
            <th className="erp_table_th">Group Id</th>
            <th className="erp_table_th">Document Group</th>
            <th className="erp_table_th">Document Name</th>
            <th className="erp_table_th">Registration No</th>
            <th className="erp_table_th">Registration Date</th>
            <th className="erp_table_th">Renewal Date</th>
            <th className="erp_table_th">Document Path</th>
            <th className="erp_table_th">Remark</th>
            <th className="erp_table_th">File Name</th>
          </tr>
        </thead>
        <tbody>
          {
            docData.map((docItem, Index) =>
              <tr rowIndex={Index}>
                <td className="erp_table_td"> {docItem.group_id} </td>
                <td className="erp_table_td">{docItem.document_group}</td>
                <td className="erp_table_td">{docItem.document_name}</td>
                <td className="erp_table_td ">{docItem.document_registration_no}</td>
                <td className="erp_table_td ">{docItem.document_registration_date}</td>
                <td className="erp_table_td ">{docItem.document_renewal_date}</td>
                <td className="erp_table_td ">{docItem.document_path}</td>
                <td className="erp_table_td ">{docItem.remark}</td>
                <td className="erp_table_td ">
                  <MDTypography component="label" className="erp-form-label" variant="button" id="logoFile" fontWeight="regular" color="info" >
                    <Link to="#" onClick={() => { fetchDocument(docItem) }}>
                      {docItem.file_name}
                    </Link></MDTypography>
                </td>
              </tr>
            )
          }
        </tbody>
      </Table>
    </>
  }, [docData]);



  // Fn for get document download
  const fetchDocument = async (docItem) => {
    try {

      const formData = new FormData()
      const data = {
        document_group: docItem.document_group,
        group_id: docItem.group_id,
        document_path: docItem.document_path
      }
      formData.append(`getFile`, JSON.stringify(data))
      const requestOptions = {
        method: 'POST',
        body: formData
      };

      const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
      const blob = await getDocApiCall.blob()

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${docItem.file_name}`,);
      document.body.appendChild(link);

      link.click();
      link.parentNode.removeChild(link);


    } catch (error) {
      console.log("error: ", error)
    }
  }

  //--------------------------------- Document List Ends---------------------------------------------------------------------------------
  //--------------------------------- Delete Payment Record Starts ---------------------------------------------------------------------------------

  async function deletePurchasePayment() {
    try {
      const method = { method: 'DELETE' }
      const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtSupplierPurchasePaymentMaster/FnDeleteRecord/${idList.supplier_purchase_payment_version}/${COMPANY_ID}?supplier_purchase_payment_no=${idList.supplier_purchase_payment_no}&user_name=${UserName}`, method)
      const responce = await deleteApiCall.json();
      console.log("Payment Transaction Deleted: ", responce);
      setShowDeleteModal(false)
      moveToListing();
    } catch (error) {
      console.log("error: ", error)
    }
  }


  //--------------------------------- Delete Payment Record Starts ---------------------------------------------------------------------------------


  return (
    <>
      <ComboBox ref={comboDataAPiCall} />
      <GenerateTAutoNo ref={generateAutoNoAPiCall} />
      <FrmValidations ref={validate} />
      <ValidateNumberDateInput ref={validateNumberDateInput} />
      <ExcelExport ref={exlsExp} />
      <DashboardLayout>
        <>
          {isLoading ?
            <div className="spinner-overlay">
              < div className="spinner-container" >
                <CircularProgress color="primary" />
                <span id="spinner_text" className="text-dark">Loading...</span>
              </div >
            </div > :
            ''}
          <div className='main_heding'>
            <label className='erp-form-label-lg main_heding'>
              Purchase Payment {ActionType()}</label>
          </div>

          <div className='card p-4'>
            <form id="purchasePayment_header_formId">
              <div className="row">
                {/* //first column */}
                <div className="col-sm-6 erp_form_col_div">

                  {/* <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Supplier Purchase Payment Type<span className="required">*</span> </Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_supplier_purchase_payment_type_id" className="form-select form-select-sm" value={cmb_supplier_purchase_payment_type_id} onChange={(e) => { setSupplier_purchase_payment_type_id(e.target.value); comboOnChange('productType'); validateFields('purchasePayment_header_formId'); }}  >
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {producttypeOptions.length !== 0 ?
                          <>
                            {producttypeOptions?.map(poTypeId => (
                              <option value={poTypeId.field_id} shortname={poTypeId.product_type_short_name}>{poTypeId.field_name}</option>
                            ))}
                          </>
                          : ''
                        }
                      </select>
                      <MDTypography variant="button" id="error_cmb_supplier_purchase_payment_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div> */}
                  <div className='row'>
                    <div className='col-sm-4 col-12'>
                      <Form.Label className="erp-form-label">Purchase Payment No,Version & Date</Form.Label>
                    </div>
                    <div className='col-sm-8 col-12'>
                      <div className="row">
                        <div className='col-12 col-md-6 pe-md-0'>
                          <Form.Control type="text" id="txt_supplier_purchase_payment_no" className="erp_input_field" value={txt_supplier_purchase_payment_no} optional='optional' disabled />

                        </div>
                        <div className="col-12 col-md-2 pt-md-0 pt-3">
                          <Form.Control type="text" id='txt_supplier_purchase_payment_version' className="erp_input_field" value={txt_supplier_purchase_payment_version} optional='optional' disabled />

                        </div>
                        <div className="col-12 col-md-4 pt-md-0 pt-3">
                          <Form.Control type="date" id='txt_supplier_purchase_payment_date' className="erp_input_field" value={txt_supplier_purchase_payment_date} onChange={e => { setSupplier_purchase_payment_date(e.target.value); validateFields('purchasePayment_header_formId'); }} min={currentDate} />
                          <MDTypography variant="button" id="error_txt_billBook_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Payment Type & Date</Form.Label>
                    </div>
                    <div className='col-sm-8 col-12'>
                      <div className="row">
                        <div className='col-12 col-md-6 pt-md-0 pt-3'>
                          <select id="chk_payment_type" className="form-select form-select-sm" value={chk_payment_type} onChange={(e) => { setPayment_type(e.target.value); comboOnChange('payment_type'); validateFields('purchasePayment_header_formId'); }} >
                            <option value="1">Cash</option>
                            <option value="2">Cheque</option>
                            <option value="3">Net Banking(NEFT/RTGS)</option>
                            <option value="4">UPI </option>
                          </select>
                        </div>
                        <div className="col-12 col-md-6 pt-md-0 pt-3">
                          <Form.Control type="date" id='dt_payment_transaction_date' className="erp_input_field" value={dt_payment_transaction_date} onChange={(e) => { setPayment_transaction_date(e.target.value); validateFields('purchasePayment_header_formId') }} />
                          <MDTypography variant="button" id="error_dt_payment_transaction_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Payment Details<span className="required">*</span></Form.Label>
                    </div>
                    <div className="col">
                      <Form.Control as="textarea" rows={1} id='txt_payment_transaction_details' className="erp_input_field" value={txt_payment_transaction_details} onChange={(e) => { setPayment_transaction_details(e.target.value); validateFields('purchasePayment_header_formId') }} maxlength="1000" />
                      <MDTypography variant="button" id="error_txt_payment_transaction_details" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row pt-1'>
                    <div className='col-sm-4 col-12'>
                      <Form.Label className="erp-form-label">Supplier<span className="required">*</span> </Form.Label>
                    </div>
                    <div className='col-sm-7 col-10'>
                      <select id="cmb_supplier_id" className="form-select form-select-sm" value={cmb_supplier_id} onChange={() => { comboOnChange("supplier"); validateFields('purchasePayment_header_formId'); }}  >
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {supplierOptions.length !== 0 ?
                          <>
                            {supplierOptions?.map(cmb_supplier_id => (
                              <option value={cmb_supplier_id.field_id} >{cmb_supplier_id.field_name}</option>
                            ))}
                          </>
                          : ''
                        }
                      </select>
                      <MDTypography variant="button" id="error_cmb_supplier_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    <div className="col-sm-1 col-2">
                      <Tooltip title="Refresh" placement="top">
                        <MDTypography className={`${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} >
                          <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_supplier') } }} />
                        </MDTypography>
                      </Tooltip>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Supplier Banks {chk_payment_type === '1' || chk_payment_type === '4' ? "" : <span className="required">*</span>}</Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_supplier_bank_id" className="form-select form-select-sm" value={cmb_supplier_bank_id} optional={`${chk_payment_type === '1' || chk_payment_type === '4' ? "optional" : ''}`}
                        onChange={(e) => { setSupplier_bank_id(e.target.value); comboOnChange('supplier_bank_id'); validateFields('purchasePayment_header_formId'); }} >
                        <option value="">Select</option>
                        {supplierBankOptions.length !== 0 ?
                          <>
                            {supplierBankOptions?.map(cmb_supplier_bank_id => (
                              <option value={cmb_supplier_bank_id.field_id} >{cmb_supplier_bank_id.field_name}</option>
                            ))}
                          </>
                          : ''
                        }
                      </select>
                      <MDTypography variant="button" id="error_cmb_supplier_bank_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Supplier Bank Account No{chk_payment_type === '1' || chk_payment_type === '4' ? "" : <span className="required">*</span>}</Form.Label>
                    </div>
                    <div className="col">
                      <Form.Control type="text" id='txt_supplier_bank_account_number' className="erp_input_field" value={txt_supplier_bank_account_number} optional={`${chk_payment_type === '1' || chk_payment_type === '4' ? "optional" : ''}`}
                        onChange={(e) => { validateAccNo(e); validateFields('purchasePayment_header_formId'); }} />
                      <MDTypography variant="button" id="error_txt_supplier_bank_account_number" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Supplier Bank IFSC Code{chk_payment_type === '1' || chk_payment_type === '4' ? "" : <span className="required">*</span>}</Form.Label>
                    </div>
                    <div className="col">
                      <Form.Control type="text" id='txt_supplier_bank_ifsc_code' className="erp_input_field" value={txt_supplier_bank_ifsc_code} optional={`${chk_payment_type === '1' || chk_payment_type === '4' ? "optional" : ''}`}
                        onChange={(e) => { setSupplier_bank_ifsc_code(e.target.value.split(' ').join('').toUpperCase()); validateFields('purchasePayment_header_formId'); }} />
                      <MDTypography variant="button" id="error_txt_supplier_bank_ifsc_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                </div>
                {/* //second column */}
                <div className="col-sm-6 erp_form_col_div">
                  <div className='row'>
                    <div className='col-sm-4 col-12'>
                      <Form.Label className="erp-form-label">Company Branch<span className="required">*</span> </Form.Label>
                    </div>
                    <div className='col-sm-7 col-10'>
                      <select id="cmb_expected_branch_id" className="form-select form-select-sm" value={cmb_expected_branch_id} onChange={(e) => { setExpected_branch_id(e.target.value); comboOnChange('expectBranch'); validateFields('purchasePayment_header_formId'); }} >
                        <option value="">Select</option>
                        <option value="0">Add Record+</option>
                        {expectedBranchOptions.length !== 0 ?
                          <>
                            {expectedBranchOptions?.map(cmb_expected_branch_id => (
                              <option value={cmb_expected_branch_id.company_branch_id} chk_isSez={`${cmb_expected_branch_id.is_sez}`}>{cmb_expected_branch_id.company_branch_name}</option>
                            ))}
                          </>
                          : ''
                        }
                      </select>
                      <MDTypography variant="button" id="error_cmb_expected_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    <div className="col-sm-1 col-2">
                      <Tooltip title="Refresh" placement="top">
                        <MDTypography className={`${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} >
                          <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_exptBranch') } }} />
                        </MDTypography>
                      </Tooltip>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Bank{chk_payment_type === '1' || chk_payment_type === '4' ? "" : <span className="required">*</span>}</Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_bank_id" className="form-select form-select-sm" value={cmb_bank_id} optional={`${chk_payment_type === '1' || chk_payment_type === '4' ? "optional" : ''}`}
                        onChange={(e) => { setBank_id(e.target.value); comboOnChange('bank_id'); validateFields('purchasePayment_header_formId'); }} >
                        <option value="">Select</option>
                        {bankOptions.length !== 0 ?
                          <>
                            {bankOptions?.map(cmb_bank_id => (
                              <option value={cmb_bank_id.field_id} >{cmb_bank_id.field_name}</option>
                            ))}
                          </>
                          : ''
                        }
                      </select>
                      <MDTypography variant="button" id="error_cmb_bank_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  {(((keyForViewUpdate === 'view' || keyForViewUpdate === 'financeApprove') && chk_purchasePayment_status !== 'P')) ? (
                    <>
                      <div className='row'>
                        <div className='col-sm-4'>
                          <Form.Label className="erp-form-label">Finanace Approved By</Form.Label>
                        </div>
                        <div className='col'>
                          <select id="cmb_approved_by_id" className="form-select form-select-sm " value={cmb_approved_by_id} onChange={(e) => { setApproved_by_id(e.target.value); }} optional='optional' disabled='disabled' >
                            <option value="">Select</option>
                            {approvedByOptions.length !== 0 ?
                              <>
                                {approvedByOptions?.map(cmb_approved_by_id => (
                                  <option value={cmb_approved_by_id.field_id}>{cmb_approved_by_id.field_name}</option>
                                ))}
                              </>
                              : ''
                            }
                          </select>
                          <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>
                      <div className='row'>
                        <div className="col-sm-4">
                          <Form.Label className="erp-form-label">Finanace Approved Date </Form.Label>
                        </div>
                        <div className="col">
                          <Form.Control type="date" id='txt_approved_date' className="erp_input_field" value={txt_approved_date} onChange={e => { setApproved_date(e.target.value); }} optional='optional' disabled='disabled' />
                          <MDTypography variant="button" id="error_txt_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>
                    </>)
                    : ''
                  }
                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Purchase Payment Status</Form.Label>
                    </div>
                    <div className='col'>
                      <select id="chk_purchasePayment_status" className="form-select form-select-sm " value={chk_purchasePayment_status} onChange={(e) => { setPurchasePayment_status(e.target.value); }} disabled >
                        <option value="P">Pending</option>
                        <option value="C">Payment Done</option>
                        <option value="X">Canceled</option>
                        <option value="F">Finance Approve</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Mail Status</Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck"> <Form.Check className="erp_radio_button" lbl="Pending" label="pending" type="radio" value="P" name="chk_Payment_mail_sent_status" checked={chk_Payment_mail_sent_status === 'P'} onClick={() => { setPayment_mail_sent_status('P'); }} disabled /> </div>
                        <div className="sCheck"> <Form.Check className="erp_radio_button" lbl="Fail" label="Fail" type="radio" value="F" name="chk_Payment_mail_sent_status" checked={chk_Payment_mail_sent_status === 'F'} onClick={() => { setPayment_mail_sent_status("F"); }} disabled /> </div>
                        <div className="sCheck"> <Form.Check className="erp_radio_button" lbl="Success" label="Success" type="radio" value="S" name="chk_Payment_mail_sent_status" checked={chk_Payment_mail_sent_status === 'S'} onClick={() => { setPayment_mail_sent_status("S"); }} disabled /> </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Total OutStanding <span className="required">*</span></Form.Label>
                    </div>
                    <div className="col">
                      <Form.Control type="text" id='txt_total_outstanding' className="erp_input_field text-end" value={txt_total_outstanding} onChange={(e) => { setTotal_outstanding(e.target.value) }} />
                      <MDTypography variant="button" id="error_txt_total_outstanding" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-sm-4 col-12">
                      <Form.Label className="erp-form-label">Total Paid Amount<span className="required">*</span></Form.Label>
                    </div>
                    <div className="col-sm-6 col-12">
                      <Form.Control type="text" id='txt_header_paid_amount' className="erp_input_field text-end" value={txt_header_paid_amount} onChange={(e) => { FnValidatePaidAmt(e); }} />
                      <MDTypography variant="button" id="error_txt_header_paid_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    <div className="col-sm-2 col-12 pt-md-0 pt-2 float-start">
                      <MDButton type="button" id="calculate_btn_id" className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === "" ? "display" : "d-none"}`} variant="button" onClick={() => { if (txt_header_paid_amount !== "") { FnCalculatePurchaseDetails() } }} fontWeight="regular">Calculate</MDButton>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <Accordion className="mt-3 mt-lg-1" defaultActiveKey="0" activeKey={openContactsAccord ? '0' : null}>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenContactsAccord(!openContactsAccord)}>Supplier's Contact Details</Accordion.Header>
                        <Accordion.Body className="p-0">
                          {
                            cmb_supplier_id !== '' && cmb_supplier_id !== '0'
                              ? <>
                                <div className={`row ps-1 py-1 ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`}>
                                  <div className={` d-flex justify-content-start align-items-center`}>
                                    <Tooltip title="Add new supplier's contact if doesn't exist in list...!" placement="top">
                                      <MDButton type="button" className={`erp-gb-button float-start col-12 col-md-4`} variant="button" fontWeight="regular" onClick={() => {
                                        localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                        localStorage.setItem('supplierIDs', JSON.stringify({ supplierId: cmb_supplier_id, keyForViewUpdate: 'update', compType: 'Master' }))
                                      }
                                      }>  <Link className={'text-light'} to={{ pathname: "/Masters/FrmSupplier" }} target="_blank" >Add New Supplier's Contact +</Link></MDButton>
                                    </Tooltip>
                                    <Tooltip title="Refresh supplier contact list...!" placement="top">
                                      <MDButton type="button" className={`erp-gb-button float-end col-1 ms-0 ms-md-2 mt-1 mt-md-0`} variant="button" fontWeight="regular" onClick={() => { FnGetSupplierContacts(cmb_supplier_id) }}>Refresh</MDButton>
                                    </Tooltip>
                                  </div>
                                </div>
                              </>
                              : null
                          }
                          {suppContactDetails.length > 0
                            ? <>
                              <div className='row  ps-2'>
                                <div className="col-12 erp_table_scroll">
                                  <div class="ps-2">
                                    <input type='checkbox' class="me-1" name="selectAllSuppContact" id="selectAllSuppContact" onClick={(e) => { toggleChkAllBoxes('selectAllSuppContact'); }} /> <label class="erp-form-label pb-1"> Select All </label>
                                  </div>
                                  {renderSupplierContactsTbl}
                                </div>
                              </div>

                            </>
                            :
                            <>
                              <div className='row text-center'>
                                <div className="col-12">
                                  <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                </div>
                              </div></>
                          }
                          {
                            suppContactDetails.length !== 0 && !['approve', 'view', 'delete', 'financeApprove', 'cancel'].includes(keyForViewUpdate)
                              ? <>
                                <div className="row justify-content-center my-1">
                                  <Tooltip title="Confirm selection...!" placement="right">
                                    <MDButton type="button" className={`erp-gb-button col-1`} variant="button" fontWeight="regular"
                                      onClick={(e) => {
                                        let selectedCustCont = $('.selectSuppContactPerson:checked');
                                        if (selectedCustCont.length > 0) {
                                          setOpenContactsAccord(false)
                                          $('#error_supplier_contact_persons').hide();
                                        } else {
                                          $('#error_supplier_contact_persons').text('Please select atleast one contact person.');
                                          $('#error_supplier_contact_persons').show();
                                        }
                                      }}
                                    >Confirm</MDButton>
                                  </Tooltip>
                                </div>
                              </>
                              : null
                          }
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion >
                    <MDTypography variant="button" id="error_supplier_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <hr />
          <Accordion defaultActiveKey="0" activeKey={openDetailsAccord ? '0' : null}>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenDetailsAccord(!openDetailsAccord)}>Purchase Payment Details</Accordion.Header>
              <Accordion.Body>
                <div className="d-flex row">
                  <div className="col-md-4 col-12">
                    <div className="row">
                      <div className="col-sm-6 d-flex">
                        <Form.Control type="text" id="txt-detail-data-highliter" className={`erp_input_field txt-filter-bom ${purchasePaymentDetailsData.length === 0 ? 'd-none' : 'display'}`} />
                        <MDButton type="button" className={`erp-gb-button erp_MLeft_btn ${purchasePaymentDetailsData.length === 0 ? 'd-none' : 'display'}`} variant="button" onClick={() => scrollToTableRow()}
                          fontWeight="regular">Search</MDButton>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                  </div>
                  <div className="col-md-2 col-12 text-end">
                    <MDButton type="button" id="export-btn" className={`erp-gb-button erp_MLeft_btn  ${keyForViewUpdate === "" ? "d-none" : "display"}`} variant="button" fontWeight="regular" onClick={ExportToExcel}>Export</MDButton>
                  </div>
                </div>
                <div className="erp-Mt-10">
                  {purchasePaymentDetailsData.length !== 0 ?
                    <>
                      <div class="col  pl-2">
                        <input type='checkbox' class="me-1" name="selectAllPaymentItems" id="selectAllPaymentItems" onClick={(e) => { toggleChkAllBoxes('selectAllPaymentItems'); }} /> <label class="erp-form-label pb-1"> Select All </label>
                      </div>
                      {renderPurchasePaymentDetailsTbl}
                    </> :
                    ''
                  }
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {keyForViewUpdate !== '' ?
            <> <hr />
              <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                <Accordion.Item eventKey="documentList">
                  <Accordion.Header className="erp-form-label-md p-0">Document List</Accordion.Header>
                  <Accordion.Body>
                    {docData.length !== 0 ? (
                      renderDocumentTable
                    ) : (
                      <div className='row text-center'>
                        <div className="col-12">
                          <span className="erp_validation text-center" fontWeight="regular" color="error">
                            No Records Found...
                          </span>
                        </div>
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
            : null
          }
          {/* footer Section Starts*/}
          <div className="row">
            <div className='main_heding text-start'>
              <label className='erp-form-label-lg'>Purchase Payment Totals:</label>
            </div>
            <form id="purchase_payment_footer_formId">
              <div className="row">
                {/* //first column */}
                <div className="col-lg-6 col-md-6 col-12 erp_form_col_div">
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Total Billing Amount</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_total_billing_amount" className="erp_input_field text-end" value={txt_total_billing_amount} disabled onChange={(e) => setTotal_billing_amount(e.target.value)} />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Total Deduction Amount</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_total_billing_deduction" className="erp_input_field text-end" value={txt_total_billing_deduction} maxLength={19} onChange={(e) => { setTotal_billing_amount(e.target.value); validateFields('purchase_payment_footer_formId'); }} disabled />
                      <MDTypography variant="button" id="error_txt_total_billing_deduction" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Deduction Remark</Form.Label>
                    </div>
                    <div className="col">
                      <Form.Control type="text" id='txt_deduction_remark' className="erp_input_field " value={txt_deduction_remark} disabled={txt_total_billing_deduction > 0 ? '' : 'disabled'} onChange={(e) => { setDeduction_remark(e.target.value); validateFields('purchase_payment_footer_formId'); }} optional={txt_total_billing_deduction > 0 && txt_total_billing_deduction !== '' ? 'optional' : ''} />
                      <MDTypography variant="button" id="error_txt_deduction_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Remark</Form.Label>
                    </div>
                    <div className="col">
                      <Form.Control type="text" id='txt_remark' className="erp_input_field" value={txt_remark} onChange={(e) => { setRemark(e.target.value) }} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Purchase Payment Active</Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="chk_purchase_payment_isactive" checked={chk_purchase_payment_isactive === true} onClick={() => { setPurchase_payment_isactive(true); }} /> </div>
                        <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" value="false" name="chk_purchase_payment_isactive" checked={chk_purchase_payment_isactive === false} onClick={() => { setPurchase_payment_isactive(false); }} /> </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* //second column */}
                <div className="col-lg-6 col-md-6 col-12 erp_form_col_div">
                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Tax 1</Form.Label>
                    </div>
                    <div className="col">
                      <select id="txt_tax1_id" className="form-select form-select-sm" value={txt_tax1_id} onChange={(e) => { comboOnChange('tax1'); }} >
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {tax1Options?.map(txt_tax1_id => (
                          <option value={txt_tax1_id.field_id} tax_value={txt_tax1_id.tax_value}>{txt_tax1_id.field_name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Tax 1 Percent & Amount</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_tax1_percent" className="erp_input_field text-end" value={txt_tax1_percent} onChange={(e) => setTax1_percent(e.target.value)} disabled />
                      <MDTypography variant="button" id="error_txt_tax1_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_tax1_amount" className="erp_input_field text-end" value={txt_tax1_amount} onChange={(e) => { setTax1_amount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); }} disabled />
                      <MDTypography variant="button" id="error_txt_tax1_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Tax 2</Form.Label>
                    </div>
                    <div className="col">
                      <select id="txt_tax2_id" className="form-select form-select-sm" value={txt_tax2_id} onChange={(e) => { comboOnChange('tax2'); }} >
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {tax1Options?.map(txt_tax2_id => (
                          <option value={txt_tax2_id.field_id} tax_value={txt_tax2_id.tax_value}>{txt_tax2_id.field_name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Tax 2 Percent & Amount</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_tax2_percent" className="erp_input_field text-end" value={txt_tax2_percent} onChange={(e) => setTax2_percent(e.target.value)} disabled />
                      <MDTypography variant="button" id="error_txt_tax2_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_tax2_amount" className="erp_input_field text-end" value={txt_tax2_amount} onChange={(e) => { setTax2_amount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); }} disabled />
                      <MDTypography variant="button" id="error_txt_tax2_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Total Paid Amount</Form.Label>
                    </div>
                    <div className="col">
                      <Form.Control type="text" id='txt_total_paid_amount' className="erp_input_field text-end" value={txt_total_paid_amount} disabled />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/* Payment Footer Section Ends*/}

          </div>
          <div className="erp_frm_Btns">
            <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
            <MDButton type="button" id="save-btn" className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === '' ? 'display' : 'd-none'}`} variant="button" onClick={() => addPurchasePayment('A')} fontWeight="regular"  >Save</MDButton>
            <MDButton type="button" id="cancel-btn" className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'cancel' ? 'display' : 'd-none'}`} variant="button" onClick={() => addPurchasePayment('X')}>Cancel</MDButton>
            <MDButton type="button" id="finance-btn" className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'financeApprove' ? 'display' : 'd-none'}`} variant="button" onClick={() => addPurchasePayment('F')}>Finanace Approve</MDButton>
            <MDButton type="button" id="viewdocument-id" className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'financeApprove' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={viewDocumentForm} disabled={supplier_purchase_payment_master_transaction_id === 0 || txt_supplier_purchase_payment_no === '' ? true : false}>Upload Document</MDButton>&nbsp;
            <MDButton type="button" id="clearForm_btn_id" className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === '' ? 'display' : 'd-none'}`} variant="button" onClick={FnClearFormFields} fontWeight="regular"
              disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'financeApprove' ? 'disabled' : ''} >Clear</MDButton>
            <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' && (chk_purchasePayment_status === 'A' || chk_purchasePayment_status === 'F') ? 'display' : 'd-none'}`} id="print_btn_id" variant="button" fontWeight="regular" onClick={() => { printInvoice(true); }} disabled={chk_purchasePayment_status !== "P" ? '' : 'disabled'}
            >Print<FiDownload className="erp-download-icon-btn" /></MDButton>
            <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => setShowDeleteModal(true)}>Delete</MDButton>
          </div >


          {/* Add new Record Popup */}
          <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
            <Modal.Header>
              <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
              <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
            </Modal.Header>
            <Modal.Body className='erp_city_modal_body'>
              {displayRecordComponent()}
            </Modal.Body>
            <Modal.Footer>
              <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
                fontWeight="regular">Close</MDButton>

            </Modal.Footer>
          </Modal >
          {/* Document modal */}
          <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
            <Modal.Header>
              <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
              <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
            </Modal.Header>
            <Modal.Body>
              <DocumentF group_id={txt_supplier_purchase_payment_no} document_group={docGroup} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Delete Modal */}
          <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} backdrop="static" keyboard={false} centered>
            <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDeleteModal}></button></span>
            <Modal.Body className='text-center'>
              <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
              <h6>Do you wish to delete this record ?</h6>
            </Modal.Body>
            <Modal.Footer className='justify-content-center'>
              <Button variant="success" className='erp-gb-button' onClick={handleCloseDeleteModal}> Cancel  </Button>&nbsp;
              <Button variant="danger" className='erp-gb-button' onClick={deletePurchasePayment}>Delete</Button>
            </Modal.Footer>

          </Modal>
          {showErrorMsgModal ?
            <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />
            : null
          }
          {/* Send Email On Approval Modal */}
          {showConfirmationModal ?
            <ConfirmationModal close={() => closeConfirmationModal('Exit')} confirmation={() => closeConfirmationModal('Exit')} show={[showConfirmationModal, message, modalOrderDetails, messageForConfirmation]} />
            : null
          }
          <MailSentModal handleCloseMailSentModal={() => handleCloseMailSentModal()} sendConfirm={() => sendEmail()} show={[showMailSentModal, message, modalOrderDetails]} />
        </>
      </DashboardLayout >
    </>
  )
}

export default FrmPurchasePaymentSupplierEntry
