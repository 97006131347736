// React Imports.
import React, { useState, useRef, useEffect, useMemo, useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';

// Material UI Imports.
import { CircularProgress } from "@material-ui/core";
import { RxCrossCircled } from "react-icons/rx";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Accordion, Button, Modal, Table } from "react-bootstrap";
import Select from 'react-select';


import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ConfirmationModal from "components/Modals/ConfirmationModal";
import html2pdf from 'html2pdf.js';

// Required Common Components.
import ConfigConstants from 'assets/Constants/config-constant';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ComboBox from 'Features/ComboBox';
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import { Assignment, BrokenImage } from "@mui/icons-material";
import { MdRefresh } from "react-icons/md";
import FrmMServiceActivityEntry from "Masters/MServiceActivity/MServiceActivityEntry";
import FrmCustomerEntryAerotech from "Masters/MCCustomer/FrmCustomerEntryAerotech";


function FrmTaskEntry(props) {
  // Required Constant's Fields For Form.
  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, FINANCIAL_SHORT_NAME, UserName, UserId } = configConstants;

  // Accessing Hooks From listing page.
  const { state } = useLocation();
  let { idList, keyForViewUpdate, compType = "Transaction", deletionKey = false, handelclose } = state || {};
  if (sessionStorage.getItem('dataAddedByCombo') === 'dataAddedByCombo') {
    const taskmanagement = JSON.parse(localStorage.getItem('taskmanagement'));
    if (taskmanagement !== null && taskmanagement !== undefined) {
      idList = taskmanagement.idList
      keyForViewUpdate = taskmanagement.keyForViewUpdate
      compType = taskmanagement.compType
    }
  }

  
  const navigator = useNavigate();
  const validateFrms = useRef();
  const comboDataApi = useRef();
  const validateNumberDateInput = useRef();

  const FnFormattedDateForInputField = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const currentDate = FnFormattedDateForInputField(new Date())

  // Form's Combo's Options hooks.
  const [companyOptions, setcompanyOptions] = useState([]);
  const [customersOpts, setCustomerOpts] = useState([]);
  const [jobReceiptOptions, setJobReceiptOptions] = useState([]);
  const [txt_task_management_code, setTask_management_code] = useState('');
  const [assignToEmplsOpts, setAssignToEmplsOpts] = useState([]);
  const [assignedByEmplsOpts, setAssignedByEmplsOpts] = useState([]);
  const [approveByEmplsOpts, setApproveByEmplsOpts] = useState([]);
  const [activityOpts, setActivityOpts] = useState([]);

  // Form Fields hooks.
  const [task_transaction_id, setTaskTransactionId] = useState(idList !== null ? idList.task_transaction_id : 0);
  const [chk_assigntasktype, setassigntasktype] = useState("Assignnow")

  const [cmb_task_status, setTaskStatus] = useState('P');
  const [dt_task_date, setTaskDate] = useState(currentDate);
  const [cmb_customer_id, setCustomer_ID] = useState('');

  const [cmb_Job_Receipt_Code, setJob_Receipt_Code] = useState('');
  const [cmb_activity_type, setactivity_type] = useState('');

  const [cmb_assign_to_id, setAssignToId] = useState('');
  const [cmb_assigned_by_id, setAssignedById] = useState('');
  const [cmb_approved_by_id, setApprovedById] = useState('');
  const [cmb_activity_id, setActivityId] = useState('');
  const [txt_activity_duration, setActivityDuration] = useState(0);
  const [dt_task_start_time, setTaskStartTime] = useState(() => {
    if (keyForViewUpdate === '') {
      const currentDate = new Date();
      currentDate.setMinutes((currentDate.getMinutes() + 0) - currentDate.getTimezoneOffset());
      return currentDate.toISOString().slice(0, 16);
    } else {
      return '';
    }
  });
  const [dt_task_end_time, setTaskEndTime] = useState();
  const [dt_actual_start_time, setActualStartTime] = useState('');
  const [dt_actual_end_time, setActualEndTime] = useState('');
  const [delayed_time, setDelayedTime] = useState('');
  const [txt_task_remark, setTaskRemark] = useState('');
  const [txt_approval_remark, setApprovalRemark] = useState('');
  const [txt_customer_feed_back, setcustomer_feed_back] = useState('');
  const [txt_user_task_remark, setUserTaskRemark] = useState('');
  const [cmb_company_id, setcompany_id] = useState('');

  let customer_idComboRef = useRef(null);

  const [showConfirmationModal, setShowConfiemationModal] = useState(false)
  const [modalOrderDetails, setModalOrderDetails] = useState('')
  const [message, setMessage] = useState('')
  const [messageForConfirmation, setMessageForConfirmation] = useState(false)



  const closeConfirmationModal = () => {
    setShowConfiemationModal(false);
    let dataAddedByCombo = sessionStorage.getItem("dataAddedByCombo");
    if (dataAddedByCombo !== "dataAddedByCombo") {
      moveToListing();
    }


  }


  // helping hooks.
  const [isLoading, setIsLoading] = useState(false);
  const ActionType = () => {
    switch (keyForViewUpdate) {
      case 'view':
        if (deletionKey === true) {
          return '(Deletion)';
        } else {
          return '(View)';
        }
      case 'update':
        return '(Modification)';
      case 'approve':
        return '(Approval)';
      default:
        return '(Creation)';
    }
  };

  const [isApprove, setIsApprove] = useState(false);
  const FnSetFieldsByUserAccess = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        $('#save-btn').text('Update');

        $('#cmb_assigned_by_id').prop('disabled', 'disabled');

        if (parseInt(localStorage.userId) !== 100 && parseInt(localStorage.userId) !== 101) {
          // await validateFrms.current.readOnly("TaskManagementFrm");
          $('#dt_actual_start_time').attr('readonly', false)
          // $("input[type=radio]").attr('disabled', true);
          $('#dt_actual_end_time').attr('readonly', false)
          // $('#txt_customer_feed_back').attr('readonly', false)
          // $('#cmb_task_status').prop('disabled', false);
          // $('#txt_task_remark').attr('readonly', false)
          $('#txt_user_task_remark').attr('readonly', true)
          // $('#txt_user_task_remark').prop('disabled', true);

        } else {
          $('#dt_task_end_time').attr('readonly', false)
          $('#dt_task_date').attr('readonly', false)
        }
        break;

      case 'view':
        $('#save-btn').attr('disabled', 'disabled');
        await validateFrms.current.readOnly("TaskManagementFrm");
        break;

      case 'approve':
        setIsApprove(true);
        await validateFrms.current.readOnly("TaskManagementFrm");
        $('#save-btn').text('Approve');
        $('#save-btn').attr('BtnType', 'approve');
        $('#cmb_task_status').removeAttr('readonly');
        $('#cmb_task_status').prop('disabled', false);
        $('#txt_approval_remark').removeAttr('readonly');

        $('#dt_actual_start_time').removeAttr('readonly');
        $('#dt_actual_end_time').removeAttr('readonly');

        break;

      default:
        $('#dt_actual_start_time').attr('readonly', true)
        $('#dt_actual_end_time').attr('readonly', true)
        break;
    }
  }

  //Error Msg Modal
  const FnCloseErrorModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');


  if(props.handelclose===true){
    debugger
    handleCloseAddRecModal();
  }
  // Succ Msg Modal
  const FnCloseSuccessModal = () => {
    
    setShowSuccessMsgModal(false);
    if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
      moveToListing();
    }

    

  };
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  //Delete Popup modal.
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const FnCloseDeleteModal = () => setShowDeleteModal(false);

  // to add new records in combo box 
  const [showAddRecModal, setShowAddRecModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('');

  useEffect(() => {
    debugger
    const initPageLayout = async () => {
      setIsLoading(true);
      await FnSetFieldsByUserAccess();
      await FnFillComboOnPageLoad();
      if (idList !== null) {
        await FnCheckUpdateResponse();

      }
      await FnSetFieldsByUserAccess();
      setIsLoading(false);
    };
    initPageLayout()
  }, []);

  // Function for filling the combo-boxes on page load.
  const FnFillComboOnPageLoad = async () => {
    debugger
    try {

      //company combo box
      resetGlobalQuery();
      globalQuery.columns = ["field_id", "field_name"];
      globalQuery.table = "cmv_company"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      comboDataApi.current.removeCatcheFillCombo(globalQuery).then((getcompanyTypes) => {
        setcompanyOptions(getcompanyTypes);
      })

      // Load the customer options.
      // resetGlobalQuery();
      // globalQuery.columns = ['field_id', 'field_name']
      // globalQuery.table = "cmv_customer_summary"
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
      // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      // comboDataApi.current.fillFiltersCombo(globalQuery)
      //   .then(customerList => { setCustomerOpts(customerList); });
      // const comboboxdata = await comboDataApi.current.fillMasterData("cmv_customer", "", "");
      // const options = [
      //   { value: '', label: 'Select' },
      //   { value: '0', label: 'Add New Record+' },
      //   ...comboboxdata.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
      // ];
      // setCustomerOpts(options);


      resetGlobalQuery();
      globalQuery.columns = ["field_id", "field_name"];
      globalQuery.table = "cmv_customer"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      comboDataApi.current.removeCatcheFillCombo(globalQuery).then((getCustomerTypes) => {

        // const comboboxdata = await combobox.current.fillMasterData("cmv_customer", "", "");
        const options = [
          { value: '', label: 'Select' },
          { value: '0', label: 'Add New Record+' },
          ...getCustomerTypes.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
        ];
        setCustomerOpts(options);
      });



      // Load the Activities.
      resetGlobalQuery();
      globalQuery.columns = ['field_id', 'field_name', 'activity_description', 'std_hour',]
      globalQuery.table = "smv_product_service_activity_master"
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataApi.current.fillFiltersCombo(globalQuery)
        .then(activityList => { setActivityOpts(activityList); });

      // Load the Employees options.
      resetGlobalQuery();
      globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
      globalQuery.table = "cmv_employee"
      // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      comboDataApi.current.fillFiltersCombo(globalQuery)
        .then(employeeList => {
          setAssignToEmplsOpts(employeeList);
          setAssignedByEmplsOpts(employeeList);
          setApproveByEmplsOpts(employeeList);

          let loggedInUser = employeeList.find((employee) => employee.field_id == UserId);
          if (loggedInUser) {
            setAssignedById(UserId);
          } else if (loggedInUser && keyForViewUpdate === 'approve') {
            setApprovedById(UserId);
          }
        });
    } catch (error) {
      console.log("error: ", error)
      navigator('/Error')
    }
  }

  const FnComboOnChange = async (comboName) => {
    debugger
    try {
      switch (comboName) {
        case 'customeridsearch':
          let customerVal = customer_idComboRef.current.value || '';

          // let selectedCustomer = $('#cmb_customer_id').val();
          // let selectedCustomername = $('#cmb_customer_id').find(":selected").text()

          if (customerVal === '0') {
            // localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            // localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master' }))
            // const newTab = window.open('/Masters/Customer', '_blank');
            // if (newTab) { newTab.focus(); }
            // setCustomer_ID('');
            if (cmb_company_id !== "3") {

              localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
              localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master' }))
              const newTab = window.open('/Masters/Customer', '_blank');
              if (newTab) { newTab.focus(); }
              setCustomer_ID('');
            } else {

              sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
              setHeaderName('customer')
              setShowAddRecModal(true)
              setTimeout(() => {
                $(".erp_top_Form").eq(1).css("padding-top", "0px");
              }, 200)
            }
          } else {
            // Load the service job receipt code options.
            resetGlobalQuery();
            globalQuery.columns = ['service_job_receipt_code']
            globalQuery.table = "mtv_service_job_receipts"
            if (keyForViewUpdate === '') {
              globalQuery.conditions.push({ field: "job_receipt_status", operator: "=", value: "Pending" });
            }
            globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customerVal });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const servicejobreceiptcodeList = await comboDataApi.current.removeCatcheFillCombo(globalQuery);
            setJobReceiptOptions(servicejobreceiptcodeList);
            console.log(servicejobreceiptcodeList);
          }
          break;

        case 'AssignTo':
          let assignedEmpl = $('#cmb_assign_to_id option:selected').val();
          setAssignToId(assignedEmpl);
          if (assignedEmpl === '0') {
            localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            const newTab = window.open('/Masters/Employees', '_blank');
            if (newTab) { newTab.focus(); }
            setAssignToId('');
          }
          break;

        case 'ActivityType':
          let activityTypeval = $('#cmb_activity_type option:selected').val();
          if (activityTypeval === "Delivery") {
            setActivityId('');
          }
          var companyType = document.getElementById('cmb_activity_type').value;
          // const shortAttributeValue = selectedOption.getAttribute('data-short');
          let shortAttributeValue = $('#cmb_activity_type option:selected').attr('data-short');

          if (activityTypeval !== '') {
            await generateTaskMangementCode('mt_task', 'task_management_code', 5, shortAttributeValue, 'C');

          }
          console.log(activityTypeval);

          break

        case 'Activity':
          let selectedActivityOpt = $('#cmb_activity_id').val();
          if (selectedActivityOpt === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Activity')
            setShowAddRecModal(true)
            setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200);
            setActivityDuration(0);
            setActivityId('');

          } else if (!isNaN(parseInt(selectedActivityOpt))) {
            const selectedActivity = activityOpts.find((activity) => activity.field_id === parseInt(selectedActivityOpt));
            if (chk_assigntasktype === 'Assignnow') {
              setActivityDuration(selectedActivity?.std_hour || 0);
              updateEndTime(dt_task_start_time, selectedActivity?.std_hour || 0);
            } else {
              setTaskStartTime('');
              setActivityDuration('');
              setTaskEndTime('');
            }

          } else {
            setActivityDuration(0);
          }
          break;

        case 'company':
          var companyType = document.getElementById('cmb_company_id').value;
          if (companyType === '0') {
            localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            const newTab = window.open('/Masters/Company', '_blank');
            if (newTab) { newTab.focus(); }
            setcompany_id('');
          }
          break;


        case 'AssignBy':
          let assignedByEmpl = $('#cmb_assigned_by_id').val();
          if (assignedByEmpl === '0') {
            localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            const newTab = window.open('/Masters/Employees', '_blank');
            if (newTab) { newTab.focus(); }
            setAssignToId('');
          }
          break;

        case 'JobReceiptCode':
          let JobReceiptCodeval = $('#cmb_Job_Receipt_Code').val();
          if (JobReceiptCodeval !== '') {
            resetGlobalQuery();
            globalQuery.columns = ['service_job_receipt_code']
            globalQuery.table = "mtv_task"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "service_job_receipt_code", operator: "=", value: JobReceiptCodeval });
            const servicejobreceiptcode = await comboDataApi.current.removeCatcheFillCombo(globalQuery);
            var checkduplicateserviceJobcode = servicejobreceiptcode[0].service_job_receipt_code
            if (checkduplicateserviceJobcode === JobReceiptCodeval) {
              setJob_Receipt_Code('')

              $("#error_cmb_Job_Receipt_Code").text(`Task is already created with code ${checkduplicateserviceJobcode}.`);
              $("#error_cmb_Job_Receipt_Code").show();
              $("#cmb_Job_Receipt_Code").focus();
              setTimeout(() => {
                $("#error_cmb_Job_Receipt_Code").hide();
              }, 5000);
            }
          }

          break;


        default:
          break;
      }
    } catch (error) {
      console.log("error: ", error)
      navigator('/Error')
    }
  }
  const generateTaskMangementCode = async (tblName, fieldName, Length, companyTypeShortName, type) => {
    const data = {
      tblName: tblName, fieldName: fieldName, Length: Length, company_id: COMPANY_ID,
      additionalParam1: 'company_id',
      additionalParam1Value: ''
    };
    const formData = new FormData();
    formData.append(`data`, JSON.stringify(data))
    const requestOptions = { method: 'POST', body: formData };
    try {
      const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/customer/FnGenerateCode/${COMPANY_ID}`, requestOptions)
      const resp = await fetchResponse.text();
      console.log("GenerateTAuto Api: ", resp)
      if (resp) {
        setTask_management_code(companyTypeShortName + resp);
        return companyTypeShortName + resp;
      }
    } catch (error) {
      console.error(error);
    }
  }
  const FnRefreshComboData = async (comboName) => {
    if (keyForViewUpdate === 'view' || keyForViewUpdate === 'approve') {
      return false;
    }

    try {
      switch (comboName) {
        case 'getnewCustomer':
          // resetGlobalQuery();
          // globalQuery.columns = ['field_id', 'field_name',];
          // globalQuery.table = "cmv_customer_summary"
          // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
          // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          // const getUpdatedCustomerData = await comboDataApi.current.removeCatcheFillCombo(globalQuery)
          // setCustomerOpts(getUpdatedCustomerData);

          // const comboboxdata = await comboDataApi.current.fillMasterData("cmv_customer", "", "");
          // const options = [
          //   { value: '', label: 'Select' },
          //   { value: '0', label: 'Add New Record+' },
          //   ...comboboxdata.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
          // ];
          // setCustomerOpts(options);

          resetGlobalQuery();
          globalQuery.columns = ["field_id", "field_name"];
          globalQuery.table = "cmv_customer"
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
          // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          comboDataApi.current.removeCatcheFillCombo(globalQuery).then((getCustomerTypes) => {

            // const comboboxdata = await combobox.current.fillMasterData("cmv_customer", "", "");
            const options = [
              { value: '', label: 'Select' },
              { value: '0', label: 'Add New Record+' },
              ...getCustomerTypes.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
            ];
            setCustomerOpts(options);
          });
          break;

        case 'AssignedTo':
        case 'AssignedBy':
          resetGlobalQuery();
          globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
          globalQuery.table = "cmv_employee"
          // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          const getUpdatedEmplsData = await comboDataApi.current.removeCatcheFillCombo(globalQuery);
          setAssignToEmplsOpts(getUpdatedEmplsData);
          setAssignedByEmplsOpts(getUpdatedEmplsData);
          break;

        case 'Company':
          resetGlobalQuery();
          globalQuery.columns = ["field_id", "field_name"];
          globalQuery.table = "cmv_company"
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
          // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          const getcompanyTypes = await comboDataApi.current.removeCatcheFillCombo(globalQuery);
          setcompanyOptions(getcompanyTypes);
          break;

        default:
          break;
      }
    } catch (error) {
      console.log("error: ", error)
      navigator('/Error')
    }
  }

  const openAddRecordPopUp = () => {
    switch (modalHeaderName) {
      case 'Activity':
        return <FrmMServiceActivityEntry btn_disabled={true} />;
      case 'customer':
        localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master', gstOptional: cmb_company_id !== '1' ? cmb_company_id : null }))
        return < FrmCustomerEntryAerotech btn_disabled modelClose={handleCloseAddRecModal}/>;
      default:
        return null;
    }
  }

  const handleCloseAddRecModal = async () => {
    debugger
    switch (modalHeaderName) {
      case 'Activity':
        resetGlobalQuery();
        globalQuery.columns = ['field_id', 'field_name', 'activity_description', 'std_hour',]
        globalQuery.table = "smv_product_service_activity_master"
        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        const getUpdatedActivities = await comboDataApi.current.removeCatcheFillCombo(globalQuery);
        setActivityOpts(getUpdatedActivities);
        break;

      default:
        break;
    }

    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "110px" }); }, 200)
  }

  // ******************************* Common Functions Starts. *************************************
  // Validate Form. (For remove the error msgs.)
  const validateFields = () => {
    validateFrms.current.validateFieldsOnChange('TaskManagementFrm')
  }

  const FnValidateTaskForm = async () => {
    let isValidTaskFrm = await validateFrms.current.validateForm("TaskManagementFrm");
    return isValidTaskFrm;
  }

  // // To validate the date ranges.
  // const FnValidateDateRanges = (changeOnField, event) => {
  //   switch (changeOnField) {
  //     case 'TaskStartTime':
  //     case 'TaskEndTime':
  //       const taskStartTime = new Date($("#dt_task_start_time").val());
  //       const taskEndTime = new Date($("#dt_task_end_time").val());
  //       if (changeOnField === 'TaskEndTime' && taskEndTime > taskStartTime && taskStartTime !== '' && taskEndTime !== '') {
  //         setTaskEndTime(event.target.value);
  //       }

  //       if (changeOnField === 'TaskStartTime' && taskEndTime < taskStartTime && taskStartTime !== '' && taskEndTime !== '') {
  //         setTaskStartTime(event.target.value);
  //       }
  //       break;

  //     case 'ActualStartTime':
  //     case 'ActualEndTime':
  //       const actualStartTime = new Date($("#dt_actual_start_time").val());
  //       const actualEndTime = new Date($("#dt_actual_end_time").val());
  //       if (changeOnField === 'ActualEndTime' && actualEndTime > actualStartTime && actualEndTime !== '' && actualStartTime !== '') {
  //         setActualEndTime(event.target.value);
  //       }

  //       if (changeOnField === 'ActualStartTime' && actualEndTime < actualStartTime && actualEndTime !== '' && actualStartTime !== '') {
  //         setActualStartTime(event.target.value);
  //       }
  //       break;

  //     default:
  //       break;
  //   }
  // }

  // To validate the date ranges.
  const FnValidateDateRanges = (changeOnField, event) => {
    if (event.target.value === '') { return false; }
    debugger
    const taskStartTime = new Date($("#dt_task_start_time").val());
    const taskEndTime = new Date($("#dt_task_end_time").val());

    const actualStartTime = new Date($("#dt_actual_start_time").val());
    const actualEndTime = new Date($("#dt_actual_end_time").val());

    switch (changeOnField) {
      case 'TaskStartTime':
        // if (taskEndTime < taskStartTime && taskStartTime !== '' && taskEndTime !== '') { setTaskStartTime(event.target.value); }
        // if ((taskStartTime < taskEndTime || taskEndTime == 'Invalid Date') && (taskEndTime !== '' && taskStartTime !== '')) {
        if ((taskEndTime !== '' && taskStartTime !== '')) {

          setTaskStartTime(event.target.value);
          updateEndTime(event.target.value, txt_activity_duration);
        }
        // else { setTaskStartTime(dt_task_start_time); }
        break;

      case 'TaskEndTime':
        // if (taskEndTime > taskStartTime && taskStartTime !== '' && taskEndTime !== '') { setTaskEndTime(event.target.value); }
        // if ((taskStartTime == 'Invalid Date') && (taskEndTime !== '' && taskStartTime !== '')) 
        if (taskEndTime !== '' && taskStartTime !== '') {
          setTaskEndTime(event.target.value);

        }
        // else { setTaskEndTime(dt_task_end_time); }
        break;

      case 'ActualStartTime':
        if ((actualStartTime < actualEndTime || actualEndTime == 'Invalid Date') && (actualEndTime !== '' && actualStartTime !== '')) {
          setActualStartTime(event.target.value);
        } else {
          setActualStartTime(dt_actual_start_time)
        }
        break;

      case 'ActualEndTime':
        if ((actualEndTime > actualStartTime || actualStartTime == 'Invalid Date') && (actualEndTime !== '' && actualStartTime !== '')) {
          setActualEndTime(event.target.value);
        } else {
          setActualEndTime(dt_actual_end_time)
        }
        break;

      default:
        break;
    }
  }

  // ******************************* Common Functions Ends. *************************************

  // ******************************* CRUD Functionality Starts. ********************************
  // For Save the Service Planning.
  const FnSaveTask = async () => {
    debugger
    try {
      setIsLoading(true);
      let isValidTaskFrm = await FnValidateTaskForm();
      if (isValidTaskFrm) {
        let JSONForSave = { 'TransHeaderData': {}, 'commonIds': {}, }
        let customeridVal = customer_idComboRef.current.value || '';

        // JSON Which Fields Common in all tables.
        let commonFieldsInTransaction = {
          task_transaction_id: task_transaction_id,
          task_date: dt_task_date,
          task_status: cmb_task_status,
          company_id: parseInt(cmb_company_id),
          company_branch_id: parseInt(COMPANY_BRANCH_ID),
          financial_year: FINANCIAL_SHORT_NAME,
          created_by: UserName
        };

        // Generate JSON For Header Data.
        JSONForSave.TransHeaderData = {
          ...commonFieldsInTransaction,               // Common Fields. 

          customer_id: customeridVal,
          task_assign_type: chk_assigntasktype,
          assigned_to_id: cmb_assign_to_id,
          assigned_by_id: cmb_assigned_by_id,
          activity_type: cmb_activity_type,
          task_management_code: txt_task_management_code,
          activity_id: cmb_activity_id,
          activity_duration: txt_activity_duration,
          task_start_time: dt_task_start_time === "" ? null : dt_task_start_time,
          task_end_time: dt_task_end_time === "" ? null : dt_task_end_time,
          actual_start_time: dt_actual_start_time,
          actual_end_time: dt_actual_end_time,
          task_remark: txt_task_remark,
          user_task_remark: txt_user_task_remark,
          approved_by_id: cmb_approved_by_id,
          approval_remark: txt_approval_remark,
          customer_feed_back: txt_customer_feed_back,
          modified_by: task_transaction_id !== 0 ? UserName : null,
          service_job_receipt_code: cmb_Job_Receipt_Code,

        }

        // Common Ids;
        JSONForSave.commonIds.task_transaction_id = task_transaction_id;
        JSONForSave.commonIds.company_id = COMPANY_ID;
        JSONForSave.commonIds.company_branch_id = COMPANY_BRANCH_ID
        JSONForSave.commonIds.financial_year = FINANCIAL_SHORT_NAME

        console.log(`JSON Sending For Saving Task Management: `, JSONForSave);
        const formData = new FormData();
        formData.append(`TaskTransactionData`, JSON.stringify(JSONForSave))
        const requestOptions = { method: 'POST', body: formData };
        const apiCallForSave = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtTask/FnAddUpdateRecord/${isApprove}`, requestOptions)
        const response = await apiCallForSave.json();
        console.log('responceAfterSave: ', response);

        if (response.success === 1 && response.error === '') {
          setTaskTransactionId(response.data.task_transaction_id);
          // setSuccMsg(response.message);
          // setShowSuccessMsgModal(true);
          // clearTaskEntryform();

          // const evitCache = comboDataFunc.current.evitCache();
          // console.log(evitCache);
          // setMessage(responce.message)
          // if (responce.data.hasOwnProperty('dispatch_challan_version') && responce.data.hasOwnProperty('dispatch_challan_no')) {
          //     setDispatchChallanMasterTransactionId(responce.data.task_transaction_id);
          //     setDispatchChallanVersion(responce.data.dispatch_challan_version);
          //     setModalOrderDetails(`Dispatch-Challan No: ${responce.data.dispatch_challan_no} and Version: ${responce.data.dispatch_challan_version}`)
          // }
          if (response.data.task_transaction_id !== 0) {
            debugger
            setMessageForConfirmation('Do you want to send email...!')
          }
          setShowConfiemationModal(true);

        } else {
          setErrMsg(response.error);
          setShowErrorMsgModal(true);

        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error: ", error)
      navigator('/Error')
    }
  }

  // For Filled the data on view/update action
  const FnCheckUpdateResponse = async () => {
    debugger
    try {
      setIsLoading(true);
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtTask/FnShowParticularRecordForUpdate/${idList.task_transaction_id}/${COMPANY_ID}`)
      const responce = await apiCall.json();

      if (responce.TaskMasterModel !== null) {
        let masterData = responce.TaskMasterModel;
        setTaskTransactionId(masterData.task_transaction_id);
        setTask_management_code(masterData.task_management_code)
        setcompany_id(masterData.company_id)
        setCustomer_ID(masterData.customer_id);
        customer_idComboRef.current.value = masterData.customer_id
        customer_idComboRef.current.label = masterData.customer_name


        await FnComboOnChange('customeridsearch');
        setJob_Receipt_Code(masterData.service_job_receipt_code);
        setassigntasktype(masterData.task_assign_type);


        if (masterData.task_assign_type === 'Assignlater') {
          $('#cmb_assign_to_id').attr('disabled', true)
          $('#dt_task_start_time').attr('disabled', true)
          $('#dt_task_end_time').attr('disabled', true)
          setActivityId('')
        }

        setAssignToId(masterData.assigned_to_id);
        setactivity_type(masterData.activity_type);
        if (masterData.activity_type === "Delivery") {
          setActivityId('')
        } else (
          setActivityId(masterData.activity_id)
        )

        setActivityDuration(masterData.activity_duration);
        setTaskStartTime(masterData.task_start_time);
        setTaskEndTime(masterData.task_end_time);
        // setActualStartTime(masterData.actual_start_time);
        // setActualEndTime(masterData.actual_end_time);
        setAssignedById(masterData.assigned_by_id);
        if (keyForViewUpdate !== 'approve') { setApprovedById(masterData.approved_by_id); }
        if (keyForViewUpdate === 'approve') {
          setTaskStatus('A');
          // // Set the prefilled data on approval.
          setActualStartTime(masterData.task_start_time);  // set the actual-start-time as a start-time
          setActualEndTime(masterData.task_end_time);      // set the actual-end-time as a start-end
        } else {
          setTaskStatus(masterData.task_status);
          setActualStartTime(masterData.actual_start_time);
          setActualEndTime(masterData.actual_end_time);
        }
        setTaskRemark(masterData.task_remark);
        setUserTaskRemark(masterData.user_task_remark);
        setApprovalRemark(masterData.approval_remark);
        setcustomer_feed_back(masterData.customer_feed_back)

        if (masterData.task_management_code === '') {

          let shortAttributeValue = $('#cmb_activity_type option:selected').attr('data-short');
          if (masterData.activity_type !== '') {
            await generateTaskMangementCode('mt_task', 'task_management_code', 5, shortAttributeValue, 'C');

          }
        }

        if (keyForViewUpdate === 'view' && masterData.task_start_time !== null && masterData.actual_start_time !== null) {
          // // Convert the input values to Date objects
          const taskStartTime = new Date(masterData.task_start_time);
          const actualStartTime = new Date(masterData.actual_start_time);
          const diffInMilliseconds = actualStartTime - taskStartTime;

          // // Convert the difference to a more readable format (e.g., days, hours, minutes, seconds)
          const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
          const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
          const diffInMinutes = diffInMilliseconds / (1000 * 60);
          const diffInSeconds = diffInMilliseconds / 1000;
          setDelayedTime(diffInHours);
        }

      }
      setIsLoading(false);
    } catch (error) {
      console.log("error : ", error)
      navigator('/Error')
    }
  }

  // For Deletion
  const FnShowDeleteModal = () => { setShowDeleteModal(true); }
  const FnDeleteServicePlanning = async () => {
    try {
      setIsLoading(true);
      const method = { method: 'DELETE' }
      const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtTask/FnDeleteRecord/${idList.task_transaction_id}/${COMPANY_ID}/${UserName}`, method)
      const responce = await deleteApiCall.json();
      console.log("Service Planning Deleted: ", responce);
      if (responce.success == 1) {
        setShowDeleteModal(false)
        moveToListing();
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error: ", error)
      navigator('/Error')
    }
  }


  // ******************************* CRUD Functionality Ends. ********************************

  const moveToListing = () => {
    const Path = compType === 'Register' ? '/Transactions/TTaskManagement/FrmTaskListing/reg' : `/Transactions/TTaskManagement/FrmTaskListing`;
    navigator(Path);
  }

  const updateEndTime = (start, duration) => {
    if (start && duration) {
      const startDate = new Date(start);
      const durationInMilliseconds = duration * 60 * 60 * 1000;
      let endDate = new Date(startDate.getTime() + durationInMilliseconds);
      const offsetInMilliseconds = startDate.getTimezoneOffset() * 60 * 1000;
      endDate = new Date(endDate.getTime() - offsetInMilliseconds);
      const formattedEndDate = endDate.toISOString().slice(0, 16);
      setTaskEndTime(formattedEndDate);
    } else {
      setTaskEndTime('');
    }
  };





  const clearTaskEntryform = () => {
    setTaskTransactionId('')
    setTaskStatus('')
    setTaskDate('')
    setCustomer_ID('')
    setAssignToId('')
    setAssignedById('')
    setApprovedById('')
    setActivityId('')
    setActivityDuration('')
    setTaskStartTime('')
    setTaskEndTime('')
    setActualStartTime('')
    setActualEndTime('')
    setDelayedTime('')
    setTaskRemark('')
    setUserTaskRemark('')
    setApprovalRemark('')
    setcustomer_feed_back('')

  }

  // Handler for radio button change
  const handleCalculationTypeChange = (type) => {
    debugger
    if (type === 'Assignnow') {
      $('#cmb_assign_to_id').attr('disabled', false)
      $('#dt_task_start_time').attr('disabled', false)
      $('#dt_task_end_time').attr('disabled', false)
      setTaskStartTime(() => {
        if (keyForViewUpdate === '' || keyForViewUpdate === 'update') {
          const currentDate = new Date();
          currentDate.setMinutes((currentDate.getMinutes() + 0) - currentDate.getTimezoneOffset());
          return currentDate.toISOString().slice(0, 16);
        } else {
          return '';
        }
      }
      );
    } else if (type === 'Assignlater') {
      $('#cmb_assign_to_id').attr('disabled', true)
      $('#dt_task_start_time').attr('disabled', true)
      $('#dt_task_end_time').attr('disabled', true)
      setTaskStartTime('');
      setActivityDuration('');
      setTaskEndTime('');
      setAssignToId('');
    }
  };
  //************************************* Email Functionality******************************** */
  const FnGenerateMailJson = async () => {
    debugger
    let EmailData = { 'EmailType': "Task Assignment Email", 'toMailData': [], 'bccData': [], 'ccData': [], 'subject': {}, 'mailAttachmentFilePaths': [], 'parameters': [] }

    let selectedAssignedById = parseInt(document.getElementById("cmb_assigned_by_id").value);
    let selectedAssignedTo = parseInt(document.getElementById("cmb_assign_to_id").value);

    // Retrieve the details for the "Assigned By" contact
    const assignedByDetails = assignToEmplsOpts.find(contactPerson => contactPerson.field_id === selectedAssignedById);
    if (assignedByDetails) {
      EmailData.toMailData.push(assignedByDetails.email_id1);
    }

    // Retrieve the details for the "Assigned To" contact
    const assignedToDetails = assignToEmplsOpts.find(contactPerson => contactPerson.field_id === selectedAssignedTo);
    if (assignedToDetails) {
      EmailData.toMailData.push(assignedToDetails.email_id1);
    }
    const startTime = document.getElementById("dt_task_start_time").value;
    const styledStartTime = `<span style="font-size: 12px;">${startTime}</span>`;
    const endTime = document.getElementById("dt_task_end_time").value;
    const styledEndTime = `<span style="font-size: 12px;">${endTime}</span>`;

    EmailData.subject = `Task Assignment: ${$('#cmb_activity_id').find(":selected").text()}`;

    EmailData.parameters.push("Task Information");                                                    // Param0 It is a heading.
    EmailData.parameters.push($('#cmb_assign_to_id').find(":selected").text());                       // Param1
    EmailData.parameters.push(document.getElementById("txt_task_remark").value);                      //Param2
    EmailData.parameters.push(`<span style="font-size: 12px;">Start Time:</span> ${styledStartTime}`);  // Param3
    EmailData.parameters.push(`<span style="font-size: 12px;">End Time:</span> ${styledEndTime}`);  // Param4
    //Param4
    EmailData.parameters.push($('#cmb_assigned_by_id').find(":selected").text());                    //Param5
    EmailData.parameters.push(localStorage.getItem('company_name'));                                 // Param6
    return EmailData;
  }

  const FnSendEmail = async () => {
    debugger
    try {
      setShowConfiemationModal(false);
      setIsLoading(true);
      let emailContent = await FnGenerateMailJson();

      let dispatchChallanContent = 'dispatch-challan printouts.';
      const fileName = `${txt_task_management_code.replaceAll('/', '_')}_@${COMPANY_ID}.pdf`;
      const dispatchChallanContainer = document.createElement('div');
      dispatchChallanContainer.style.margin = '20px';
      dispatchChallanContainer.innerHTML = dispatchChallanContent;
      let worker = html2pdf().from(dispatchChallanContainer).set({
        margin: 0,
        filename: fileName,
        html2canvas: { scale: 2 },
        jsPDF: { orientation: 'portrait', unit: 'pt', format: 'a4', compressPDF: true }
      }).toPdf();

      let pages = [];
      pages.slice(1).forEach(function (page) {
        worker = worker.get('pdf').then(function (pdf) {
          pdf.addPage();
        }).from(page).toContainer().toCanvas().toPdf();
      });

      worker = worker.output("datauristring").then(async function (pdf) {
        const preBlob = dataURItoBlob(pdf);
        const file = new File([preBlob], fileName, { type: 'application/pdf' });

        const formData = new FormData();
        formData.append(`EmailData`, JSON.stringify(emailContent))
        // formData.append("dispatchChallanPDF", file);

        const requestOptions = { method: 'POST', body: formData };
        const emailingApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtTask/FnSendEmail/${task_transaction_id}/${COMPANY_ID}`, requestOptions);
        const emailingApiCallResponse = await emailingApiCall.json();

        if (emailingApiCallResponse.success === "1") {
          // Show success message.
          setIsLoading(false);
          closeConfirmationModal();
          setSuccMsg(emailingApiCallResponse.message);
          setShowSuccessMsgModal(true);
        } else {
          // Show error message.
          setErrMsg(emailingApiCallResponse.message);
          setShowErrorMsgModal(true);
        }
      });

    } catch (error) {
      setIsLoading(false);
      console.log('Error in email sending: ', error);
      navigator('/Error')
    }
  }

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  return (
    <>
      {/* <DashboardLayout> */}
      <ComboBox ref={comboDataApi} />
      <FrmValidations ref={validateFrms} />
      <ValidateNumberDateInput ref={validateNumberDateInput} />
      <div className="erp_top_Form">

        {isLoading
          ? <div className="spinner-overlay"  >
            <div className="spinner-container">
              <CircularProgress color="primary" />
              <span>Loading...</span>
            </div>
          </div>
          : ''}


        <div className='card p-1'>
          <div className='card-header text-center py-1'>
            <label className='erp-form-label-lg text-center'>Task Management {ActionType()}</label>
          </div>

          <div className="card-body">
            <form id='TaskManagementFrm'>
              <div className="row p-1">
                {/* First Column */}
                <div className="col-md-4">

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label-md">Task<span className="required">*</span> </Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck me-2"> <Form.Check className="erp_radio_button" id="chk_assign_now_type" label="Assign" type="radio" lbl="Assign" value="Assignnow" name="chk_assigntasktype" checked={chk_assigntasktype === "Assignnow"} onChange={() => handleCalculationTypeChange('Assignnow')} onClick={() => setassigntasktype("Assignnow")} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'} /> </div>
                        <div className="sCheck"> <Form.Check className="erp_radio_button" id="chk_assign_later_type" label="Assign Later" type="radio" lbl="Assign Later" value="Assignlater" name="chk_assigntasktype" checked={chk_assigntasktype === "Assignlater"} onChange={() => handleCalculationTypeChange('Assignlater')} onClick={() => setassigntasktype("Assignlater")} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'} /> </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className='col-sm-4 col-12'>
                      <Form.Label className="erp-form-label-md">Activity Type <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col-sm-8 col-10'>
                      <select id="cmb_activity_type" className="form-select form-select-sm" value={cmb_activity_type} onChange={(e) => { setactivity_type(e.target.value); FnComboOnChange('ActivityType'); validateFields(); }} disabled={keyForViewUpdate === 'view' ? true : false} >
                        <option value="">Select</option>
                        <option value="Service" data-short="S">Service</option>
                        <option value="Delivery" data-short="D">Delivery</option>
                        <option value="Repair job service" data-short="RJS">Repair job service</option>
                      </select>
                      <MDTypography variant="button" id="error_cmb_activity_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4 col-12">
                      <Form.Label className="erp-form-label-md">Task Manag. Code : <span className="required">*</span></Form.Label>
                    </div>
                    <div className="col-sm-8 col-10">
                      <Form.Control type="text" className="erp_input_field" id="txt_task_management_code" value={txt_task_management_code} onChange={e => { setTask_management_code(e.target.value); validateFields(); }} maxLength="500" disabled="disabled" />
                      {/* <MDTypography variant="button" id="error_txt_task_management_code" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography> */}
                    </div>

                  </div>

                  <div className='row'>
                    <div className="col-sm-4 col-12">
                      <Form.Label className="erp-form-label-md">Task Date<span className="required">*</span> </Form.Label>
                    </div>
                    <div className='col-sm-8'>
                      <Form.Control type="date" id='dt_task_date' className="erp_input_field" value={dt_task_date} onChange={e => { if (e.target.value !== '') { setTaskDate(e.target.value); } validateFields(); }} />
                      <MDTypography variant="button" id="error_dt_task_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                    </div>
                  </div>

                  <div className="row">
                    <div className='col-sm-4 col-12'>
                      <Form.Label className="erp-form-label-md">Task Status<span className="required">*</span> </Form.Label>
                    </div>
                    <div className='col-sm-8 col-12'>
                      <select id="cmb_task_status" className="form-select form-select-sm" value={cmb_task_status} onChange={(e) => { setTaskStatus(e.target.value); validateFields(); }} {...keyForViewUpdate === '' ? { disabled: 'disabled', optional: "optional" } : {}}>
                        <option value="P">Pending</option>
                        <option value="D">Done</option>
                        {keyForViewUpdate === "approve" && <option value="A">Completed</option>}
                        {/* <option value="A">Completed</option> */}
                        <option value="C">Cancelled</option>
                      </select>
                      <MDTypography variant="button" id="error_cmb_task_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label-md">Company <span className="required">*</span> </Form.Label>
                    </div>
                    <div className='col-md-7 col-10'>
                      <select id="cmb_company_id" className="form-select form-select-sm" value={cmb_company_id} onChange={(e) => { setcompany_id(e.target.value); FnComboOnChange('company'); validateFields() }} disabled={keyForViewUpdate === 'view' ? true : false} >
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {companyOptions?.map(company => (
                          <option value={company.field_id}  >{company.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_company_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    <div className='col-sm-1 col-2 refreshIconCol'>
                      <Tooltip title="Refresh" placement="right">
                        <MdRefresh style={{ color: 'black' }} onClick={e => { FnRefreshComboData('Company'); }} />
                      </Tooltip>
                    </div>
                  </div>

                  {/* <div className="row">
                    <div className='col-sm-4 col-12'>
                      <Form.Label className="erp-form-label-md">Customer </Form.Label>
                    </div>
                    <div className='col-sm-7 col-10'>
                      <select id="cmb_customer_id" className="form-select form-select-sm" value={cmb_customer_id} onChange={(e) => { setCustomer_ID(e.target.value); validateFields(); FnComboOnChange('Customer'); }} optional="optional">
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {customersOpts?.map(customer => (<option value={customer.field_id}>{customer.field_name}</option>))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                    </div>
                    <div className='col-sm-1 col-2 refreshIconCol'>
                      <Tooltip title="Refresh" placement="right">
                        <MdRefresh style={{ color: 'black' }} onClick={e => { FnRefreshComboData('Customer'); }} />
                      </Tooltip>
                    </div>
                  </div> */}


                  <div className="row">
                    <div className='col-sm-4 col-12'>
                      <Form.Label className="erp-form-label-md">Customer Name </Form.Label>
                    </div>
                    <div className='col-sm-7 col-10 mb-1'>
                      <Select ref={customer_idComboRef}
                        // isDisabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? true : false}
                        isDisabled={(parseInt(localStorage.userId) !== 100 && parseInt(localStorage.userId) !== 101) &&
                          (keyForViewUpdate === 'approve' || keyForViewUpdate === 'view')
                        }
                        options={customersOpts}
                        inputId="cmb_customer_id" // Provide the ID for the input box
                        value={customersOpts.find(option => option.value === cmb_customer_id)}

                        onChange={(selectedOpt) => {
                          setCustomer_ID(selectedOpt.value);
                          customer_idComboRef.current = selectedOpt;
                          FnComboOnChange('customeridsearch');

                        }}
                        placeholder="Search for a customer..."
                        className="form-search-custom"
                        classNamePrefix="custom-select" // Add custom prefix for class names
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          singleValue: (provided, state) => ({
                            ...provided,
                            fontSize: '12px' // Adjust the font size as per your requirement
                          }),
                          input: (provided, state) => ({
                            ...provided,
                            fontSize: '12px' // Adjust the font size as per your requirement
                          })
                        }} optional="optional"
                      />
                      <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    <div className={`col-sm-1 col-2  `}>
                      <Tooltip title="Refresh" placement="top">
                        <MDTypography className={`${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' ? 'd-none' : 'display'}`} >
                          <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'view') { FnRefreshComboData('getnewCustomer') } }} />
                        </MDTypography>
                      </Tooltip>
                    </div>
                  </div>




                </div>

                {/* Second Column */}
                <div className="col-md-4">
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label-md">Service Job Receipt Code   </Form.Label>
                    </div>
                    <div className='col-md-8 col-10'>
                      <select id="cmb_Job_Receipt_Code" className="form-select form-select-sm" value={cmb_Job_Receipt_Code} onChange={(e) => { setJob_Receipt_Code(e.target.value); validateFields(); FnComboOnChange('JobReceiptCode'); }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'update' ? true : false} optional="optional" >
                        <option value="">Select</option>
                        {jobReceiptOptions?.map(JobReceipt => (
                          <option value={JobReceipt.service_job_receipt_code}>{JobReceipt.service_job_receipt_code}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_Job_Receipt_Code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>


                  <div className="row">
                    <div className='col-sm-4 col-12'>
                      <Form.Label className="erp-form-label-md">Assign To{chk_assigntasktype === "Assignnow" ? <span className="required">*</span> : ''}</Form.Label>
                    </div>
                    <div className='col-sm-7 col-10'>
                      <select id="cmb_assign_to_id" className="form-select form-select-sm" value={cmb_assign_to_id} onChange={(e) => { setAssignToId(e.target.value); validateFields(); FnComboOnChange('AssignTo'); }} optional={chk_assigntasktype === "Assignnow" ? '' : 'optional'} >
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {assignToEmplsOpts?.map(empl => (<option value={empl.field_id}>{empl.field_name}</option>))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_assign_to_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                    </div>
                    <div className='col-sm-1 col-2 refreshIconCol'>
                      <Tooltip title="Refresh" placement="right">
                        <MdRefresh style={{ color: 'black' }} onClick={e => { FnRefreshComboData('AssignedTo'); }} />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="row">
                    <div className='col-sm-4 col-12'>
                      <Form.Label className="erp-form-label-md">Activity{cmb_activity_type === "Service" ? <span className="required">*</span> : ''}</Form.Label>
                    </div>
                    <div className='col-sm-8'>
                      <select id="cmb_activity_id" className="form-select form-select-sm" value={cmb_activity_id} onChange={(e) => { setActivityId(e.target.value); validateFields(); FnComboOnChange('Activity'); }} disabled={cmb_activity_type === 'Delivery'} optional={cmb_activity_type === "Service" ? '' : 'optional'}   >
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {activityOpts?.map(activity => (<option value={activity.field_id}>{activity.field_name}</option>))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_activity_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4 col-12'>
                      <Form.Label className="erp-form-label-md"> Task Description</Form.Label>
                    </div>
                    <div className='col-sm-8'>
                      <Form.Control as="textarea" rows={1} id="txt_task_remark" className="erp_txt_area" value={txt_task_remark} onInput={e => { setTaskRemark(e.target.value); }} maxlength="1000" optional="optional"  
                      disabled={(parseInt(localStorage.userId) !== 100 && parseInt(localStorage.userId) !== 101) 
                        // disabled={parseInt(localStorage.getItem('userId')) !== 100 && parseInt(localStorage.getItem('userId')) !== 101}

                        }/>
                      <MDTypography variant="button" id="error_txt_task_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4 col-12'>
                      <Form.Label className="erp-form-label-md"> User Description</Form.Label>
                    </div>
                    <div className='col-sm-8'>
                      <Form.Control as="textarea" rows={1} id="txt_user_task_remark" className="erp_txt_area" value={txt_user_task_remark} onInput={e => { setUserTaskRemark(e.target.value); }} maxlength="1000" optional="optional" />
                      <MDTypography variant="button" id="error_txt_user_task_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-sm-4 col-12">
                      <Form.Label className="erp-form-label-md">Activity Duration (Hrs)</Form.Label>
                    </div>
                    <div className='col-sm-8'>
                      <Form.Control type="number" id="txt_activity_duration" className="erp_input_field text-end" value={txt_activity_duration}
                        onChange={(e) => {
                          // onChange={(e) => { if (validateNumberDateInput.current.percentValidate(e.target.value)) { settxt_std_wastage_percent(e.target.value) }; validateFields(); }}
                          const inputValue = e.target.value; if (validateNumberDateInput.current.percentValidate(inputValue)) { setActivityDuration(inputValue); }
                          // if (validateNumberDateInput.current.percentValidate(e.target.value)) { setActivityDuration(e.target.value) };

                          // setActivityDuration(validateNumberDateInput.current.decimalNumber((e.target.value).toString(), 4) || 0);
                          validateFields();
                          updateEndTime(dt_task_start_time, e.target.value);
                        }}
                        onFocus={(e) => {
                          if (e.target.value === '0' || e.target.value === '0.0000') {
                            e.target.value = '';
                          }
                        }}
                        optional={chk_assigntasktype === "Assignnow" ? '' : 'optional'}
                      />
                      <MDTypography variant="button" id="error_txt_activity_duration" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                    </div>
                  </div>







                </div>


                {/* Third Column */}
                <div className="col-md-4">
                  <div className='row'>
                    <div className="col-sm-4 col-12">
                      <Form.Label className="erp-form-label-md">Start Time{chk_assigntasktype === "Assignnow" ? <span className="required">*</span> : ''}</Form.Label>
                    </div>
                    <div className='col-sm-8'>
                      {/* max={dt_task_end_time} */}
                      <Form.Control type="datetime-local" id="dt_task_start_time" className="erp_input_field" value={dt_task_start_time} onChange={e => { validateFields(); FnValidateDateRanges('TaskStartTime', e); }} optional={chk_assigntasktype === "Assignnow" ? '' : 'optional'} />
                      <MDTypography variant="button" id="error_dt_task_start_time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-sm-4 col-12">
                      <Form.Label className="erp-form-label-md">End Time{chk_assigntasktype === "Assignnow" ? <span className="required">*</span> : ''}</Form.Label>
                    </div>
                    <div className='col-sm-8'>
                      <Form.Control type="datetime-local" id="dt_task_end_time" className="erp_input_field" value={dt_task_end_time} onChange={e => { validateFields(); FnValidateDateRanges('TaskEndTime', e); }} optional={chk_assigntasktype === "Assignnow" ? '' : 'optional'} />
                      <MDTypography variant="button" id="error_dt_task_end_time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-sm-4 col-12">
                      <Form.Label className="erp-form-label-md">Actual Start Time{keyForViewUpdate === 'approve' ? <span className="required">*</span> : null}</Form.Label>
                    </div>
                    <div className='col-sm-8'>
                      <Form.Control type="datetime-local" id="dt_actual_start_time" className="erp_input_field" value={dt_actual_start_time} onChange={e => { validateFields(); FnValidateDateRanges('ActualStartTime', e); }} max={dt_actual_end_time} optional="optional" />
                      <MDTypography variant="button" id="error_dt_actual_start_time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-sm-4 col-12">
                      <Form.Label className="erp-form-label-md">Actual End Time{keyForViewUpdate === 'approve' ? <span className="required">*</span> : null}</Form.Label>
                    </div>
                    <div className='col-sm-8'>
                      <Form.Control type="datetime-local" id="dt_actual_end_time" className="erp_input_field" value={dt_actual_end_time} onChange={e => { validateFields(); FnValidateDateRanges('ActualEndTime', e); }} min={dt_actual_start_time} optional="optional" />
                      <MDTypography variant="button" id="error_dt_actual_end_time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                    </div>
                  </div>
                  {/* <div className={`row`}>
                    <div className="col-sm-4 col-12">
                      <Form.Label className="erp-form-label-md">Delayed Time (hrs.)</Form.Label>
                    </div>
                    <div className='col-sm-8'>
                      <Form.Control type="text" id="delayed_time" className="erp_input_field text-end" value={delayed_time} optional="optional" disabled />
                    </div>
                  </div> */}

                  <div className="row">
                    <div className='col-sm-4 col-12'>
                      <Form.Label className="erp-form-label-md">Assigned By<span className="required">*</span></Form.Label>
                    </div>
                    <div className='col-sm-7 col-10'>
                      <select id="cmb_assigned_by_id" className="form-select form-select-sm" value={cmb_assigned_by_id} onChange={(e) => { setAssignedById(e.target.value); validateFields(); FnComboOnChange('AssignBy'); }}>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {assignedByEmplsOpts?.map(empl => (<option value={empl.field_id}>{empl.field_name}</option>))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_assigned_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                    </div>
                    <div className='col-sm-1 col-2 refreshIconCol'>
                      <Tooltip title="Refresh" placement="right">
                        <MdRefresh style={{ color: 'black' }} onClick={e => { FnRefreshComboData('AssignedBy'); }} />
                      </Tooltip>
                    </div>
                  </div>

                  {
                    keyForViewUpdate !== '' && keyForViewUpdate !== 'update'
                      ? <>
                        <div className="row">
                          <div className='col-sm-4 col-12'>
                            <Form.Label className="erp-form-label-md">Approved By{keyForViewUpdate === 'approve' ? <span className="required">*</span> : null}</Form.Label>
                          </div>
                          <div className='col-sm-8'>
                            <select id="cmb_approved_by_id" className="form-select form-select-sm" value={cmb_approved_by_id} onChange={(e) => { setApprovedById(e.target.value); validateFields(); }} {...keyForViewUpdate !== 'approve' ? { disabled: 'disabled', optional: "optional" } : {}}>
                              <option value="">Select</option>
                              <option value="0">Add New Record+</option>
                              {approveByEmplsOpts?.map(empl => (<option value={empl.field_id}>{empl.field_name}</option>))}
                            </select>
                            <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-sm-4 col-12'>
                            <Form.Label className="erp-form-label-md">Approval Remark</Form.Label>
                          </div>
                          <div className='col-sm-8'>
                            <Form.Control as="textarea" rows={1} id="txt_approval_remark" className="erp_txt_area" value={txt_approval_remark} onInput={e => { setApprovalRemark(e.target.value); }} maxlength="1000" {...keyForViewUpdate !== 'approve' ? { disabled: 'disabled' } : {}} optional="optional" />
                            <MDTypography variant="button" id="error_txt_approval_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                          </div>
                        </div>
                      </>
                      : null
                  }

                  <div className='row'>
                    <div className='col-sm-4 col-12'>
                      <Form.Label className="erp-form-label-md">Customor Feed Back</Form.Label>
                    </div>
                    <div className='col-sm-8'>
                      <Form.Control as="textarea" rows={1} id="txt_customer_feed_back" className="erp_txt_area" value={txt_customer_feed_back} onInput={e => { setcustomer_feed_back(e.target.value); }} maxlength="1000" optional="optional" />
                      <MDTypography variant="button" id="error_txt_customer_feed_back" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* <hr /> */}
        <div className="text-center mb-5">
          <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" disabled={props.btn_disabled ? true : false} onClick={() => moveToListing()}>Back</MDButton>
          {
            keyForViewUpdate !== 'view'
              ? <>
                <MDButton type="button" id="save-btn" className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular"
                  onClick={() => FnSaveTask()}
                >Save</MDButton>
              </>
              : null
          }
          {
            deletionKey
              ? <> <MDButton type="button" className={`erp-gb-button erp_MLeft_btn`} variant="button" fontWeight="regular" onClick={() => FnShowDeleteModal()}> Delete </MDButton> </>
              : null
          }

        </div>

        {/* Delete Modal */}
        <Modal show={showDeleteModal} onHide={FnCloseDeleteModal} backdrop="static" keyboard={false} centered>
          <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseDeleteModal}></button></span>
          <Modal.Body className='erp_modal_body'>
            {isLoading
              ? <div className="spinner-overlay"  >
                <div className="spinner-container">
                  <CircularProgress color="primary" />
                  <span>Loading...</span>
                </div>
              </div>
              : ''}

            <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
            <h6>Are you sure?</h6>
            <div className="erp-form-label">Do you wish to delete this record ?</div>
          </Modal.Body>
          <Modal.Footer className='justify-content-center'>
            <Button variant="success" className='erp-gb-button' onClick={FnCloseDeleteModal}>
              Cancel
            </Button>&nbsp;
            <Button variant="danger" className='erp-gb-button' onClick={FnDeleteServicePlanning}>Delete</Button>
          </Modal.Footer>
        </Modal>

        {/* Add new Record Popup */}
        <Modal size="lg" show={showAddRecModal} onHide={handleCloseAddRecModal} backdrop="static" keyboard={false} centered >
          <Modal.Body className='erp_city_modal_body'>
            <div className='row'>
              <div className='col-12 align-self-end'>
                <button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseAddRecModal}></button>
              </div>
            </div>
            {openAddRecordPopUp()}
          </Modal.Body>
        </Modal >
        <ConfirmationModal close={() => closeConfirmationModal()} confirmation={() => FnSendEmail()} show={[showConfirmationModal, message, modalOrderDetails, messageForConfirmation]} />

        {/* Generic Response Messaage Modals */}
        <SuccessModal handleCloseSuccessModal={() => FnCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        <ErrorModal handleCloseErrModal={() => FnCloseErrorModal()} show={[showErrorMsgModal, errMsg]} />


      </div>


      {/* </DashboardLayout> */}
    </>
  )
}

export default FrmTaskEntry