import React, { useEffect, useRef, useState } from 'react'

// React bootstrap imports
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { RxAvatar } from 'react-icons/rx';
import { Link, useNavigate } from 'react-router-dom'; // Import Link for navigation
import ConfigConstants from 'assets/Constants/config-constant';
import ComboBox from 'Features/ComboBox';
import { useAppContext } from 'context/AppContext';


function DashboardNav() {
    const configConstants = ConfigConstants();
    const { COMPANY_NAME, ERP_VERSION, UserName } = configConstants;
    const [html, setHtml] = useState(null);
    const navigate = useNavigate();
    const comboBoxRef = useRef()
    const navBarCollapseRef = useRef(null);
    const { dispatch } = useAppContext();

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the clicked element is inside the navBarCollapse or the RxAvatar button
            if (navBarCollapseRef.current && !navBarCollapseRef.current.contains(event.target) && !event.target.classList.contains('head_nav_brand')) {
                setHtml(null);
            }
        };

        // Add the event listener to the document body
        document.body.addEventListener('click', handleClickOutside);

        // Remove the event listener when the component is unmounted
        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const signout = async () => {
        sessionStorage.clear()
        localStorage.clear()

        const signOut = await fetch(`${process.env.REACT_APP_BASE_URL}/api/auth/signout`, {
            method: 'POST'
        })
        const response = await signOut.text()
        console.log("Result: ", response)

        dispatch({ type: 'LOGOUT' });
        navigate('/login')
    }

    function navBarCollapse() {

        if (html != null) {
            return (setHtml(null))
        } else {
            return (<div id="mySidenav" className="z-1 py-4 border rounded" style={{
                position: "absolute", right: "18px", top: "42px", backgroundColor: "white", width: "230px", height: "250px"
            }}>

                <div><h6 className="mx-3">{UserName}</h6></div>
                <hr />
                <div className="my-2">
                    {/* <div><h6 className="mx-3 my-2 fw-normal">Dakshabhi</h6></div> */}
                    {/* Use Link to navigate to the login page */}
                    <div class="sign-out"><Link to="#" onClick={signout} className="mx-3 my-2 fw-normal">Sign Out</Link></div>
                </div>
            </div >)
        }

    }

    return (
        <>
            <ComboBox ref={comboBoxRef} />

            <Navbar expand="sm" className="erp_Header_navbar">
                <div className="container-fluid">
                    <Navbar.Brand className="head_nav_brand" onClick={(e) => {navigate('/DashBoard');}}> {ERP_VERSION}</Navbar.Brand>

                    <div className="justify-content-end" ref={navBarCollapseRef}>
                        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                        <Nav className="me-auto">
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Navbar.Brand className="head_nav_brand">{COMPANY_NAME}</Navbar.Brand>
                            </Navbar.Collapse>

                            <div onClick={(() => setHtml(navBarCollapse()))} className="head_nav_brand p-1"><RxAvatar size="1.7em" /></div>
                        </Nav>
                    </div>
                    {html}
                </div>
            </Navbar >
        </>
    )
}

export default DashboardNav;