import React from 'react'
import { useTable, useGlobalFilter } from 'react-table';

// React Bootstrap imports
import { Table } from "react-bootstrap"
import { useEffect } from 'react';
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";


const Datatable = ({ data, columns, freezeColumnCount = 3, stateValue }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter, // for global filter function
    } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter, // Hook for global filtering
    );

    // Assuming setGlobalFilterProp is a function passed as a prop
    useEffect(() => {
        setGlobalFilter(stateValue);
    }, [stateValue]);

    return (
        <>
            {columns.length < 8 ?
                <>
                    <Table className="erp_table" responsive bordered striped {...getTableProps()}>
                        <thead className="erp_table_head">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th className="erp_table_th" {...column.getHeaderProps()}>{column.render('Headers')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row)

                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td className="erp_table_td" {...cell.getCellProps()}>{cell.render('Cell')}</td>)
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>

                    </Table>

                </> :
                <div className="table-container">
                    <div className="fixed-table">
                        <Table className="erp_table" responsive bordered striped>
                            <thead className="erp_table_head">
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.slice(0, freezeColumnCount).map(column => (
                                            <th className="erp_table_th" {...column.getHeaderProps()}>{column.render('Headers')}</th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {rows.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.slice(0, freezeColumnCount).map(cell => {
                                                return (
                                                    // <td className="erp_table_td" {...cell.getCellProps()}>
                                                    //     {cell.column.id.includes('_date')
                                                    //         ? new Intl.DateTimeFormat('en-GB').format(new Date(cell.render('Cell')))
                                                    //         : cell.render('Cell')}
                                                    // </td>
                                                    <td className="erp_table_td" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <div className="scrollable-table">
                        <Table className='mb-0' bordered striped>
                            <thead className="erp_table_head">
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.slice(freezeColumnCount).map(column => (
                                            <th className="erp_table_th" {...column.getHeaderProps()}>{column.render('Headers')}</th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {rows.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.slice(freezeColumnCount).map(cell => {
                                                return (
                                                    // <td className="erp_table_td" {...cell.getCellProps()}>
                                                    //     {cell.column.id.includes('_date')
                                                    //         ? new Intl.DateTimeFormat('en-GB').format(new Date(cell.render('Cell')))
                                                    //         : cell.render('Cell')}
                                                    // </td>
                                                    <td className="erp_table_td" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>

            }


        </>
    )
}

export const DatatableWithId = ({ data, columns, tableId }) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data
    })

    return (
        <>
            <Table className="erp_table" id={tableId} responsive bordered striped  {...getTableProps()}>
                <thead className="erp_table_head">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th className="erp_table_th" {...column.getHeaderProps()}>{column.render('Headers')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td className="erp_table_td" {...cell.getCellProps()}>{cell.render('Cell')}</td>)
                                })}
                            </tr>
                        )
                    })}
                </tbody>

            </Table>


        </>
    )
}

export default Datatable
