import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $ from "jquery";
import { resetGlobalQuery } from 'assets/Constants/config-constant';
import { globalQuery } from 'assets/Constants/config-constant';

//React Bootstrap components
import { Table } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { CircularProgress } from "@material-ui/core";
import ConfigConstants from "assets/Constants/config-constant";
import MDButton from "components/MDButton";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import MDTypography from "components/MDTypography";
import FrmValidations from 'FrmGeneric/FrmValidations';

// import files
import ComboBox from "Features/ComboBox";
import ExcelExport from "Features/Exports/ExcelExport";

function FrmManualAttendanceEntry() {

    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const configConstants = ConfigConstants();
    const { COMPANY_ID, UserName, COMPANY_NAME, } = configConstants;

    const { state } = useLocation();
    const { keyForViewUpdate } = state || {}

    // UseRefs
    const navigate = useNavigate();
    const exlsExp = useRef();
    const importFile = useRef(null)
    const frmValidation = useRef();
    const comboDataAPiCall = useRef();
    const validateNumberPercentInput = useRef();

    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [succMsg, setSuccMsg] = useState("");
    const handleCloseSuccessModal = () => setShowSuccessMsgModal(false);
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [employeeTypeOptions, setEmployeeTypeOptions] = useState([]);
    const [departmentopt, setDepartmentOption] = useState([]);
    const [cmb_employee_type, setEmployeeType] = useState('');
    const [txt_department_id, setDepartmentId] = useState('');
    const [employee_code, setEmloyeeCode] = useState('');
    const [employee_name, setEmloyeeNameOnchange] = useState('');
    const [attendance_type_id, setAttendanceTypeId] = useState(1);
    const [employeeListData, setEmployeeListData] = useState([]);
    const [attendanceDetails, setAttendanceDetails] = useState([]);
    const [cmb_employee_id, setEmployeeName] = useState('');
    const [dt_attendance_from_date, setFromDate] = useState(today);
    const [dt_attendance_to_date, setToDate] = useState(today);
    const attendanceValidDate = new Date().toISOString().split('T')[0];
    const [attendanceDateColumns, setAttendanceDates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    //Error Msg
    const handleCloseErrModal = () => {
        setShowErrorMsgModal(false)
    };

    const validateErrorMsgs = () => {
        frmValidation.current.validateFieldsOnChange('attendanceFormId')
    }
    const ClearPage = () => {
        setEmployeeType('');
        setDepartmentId('');
        setEmployeeName('');
        setAttendanceDetails('');
        setFromDate(today)
        setToDate(today)
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                await fillPropertyComboBoxes();
            } catch (error) {
                console.error(error);
                navigate('/Error');
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);


    const fillPropertyComboBoxes = async () => {
        try {
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name'];
            globalQuery.table = "amv_properties";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'EmployeeType' });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.orderBy = ['property_name']
            const employeeTypesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setEmployeeTypeOptions(employeeTypesApiCall);

            const departmentIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_department", "", "");
            setDepartmentOption(departmentIdApiCall);
        } catch (error) {
            console.log("error : ", error)
        }
    }

    const FnComboOnchange = async (key) => {
        switch (key) {
            case 'employee_type':
                setDepartmentId('')
                setEmployeeName('')
                const employeeType = document.getElementById('cmb_employee_type').value;
                if (employeeType !== "") {
                    const getEmployeeListApiCall = await comboDataAPiCall.current.fillMasterData("cmv_employee_summary", "employee_type", employeeType);
                    setEmployeeListData(getEmployeeListApiCall);
                }
                break;
            case 'department':
                const departmentID = document.getElementById('txt_department_id').value;
                const employee_type = document.getElementById('cmb_employee_type').value;

                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'department_name', 'employee_type'];
                globalQuery.table = "cmv_employee_summary";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                if (departmentID !== "" && departmentID !== "All") {
                    globalQuery.conditions.push({ field: "department_id", operator: "=", value: departmentID });
                }
                if (employee_type !== "" && employee_type !== "All") {
                    globalQuery.conditions.push({ field: "employee_type", operator: "=", value: employee_type });
                }
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getEmployeeLIstApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setEmployeeListData(getEmployeeLIstApiCall);
                break;
            case 'employee_name':
                const employeeId = document.getElementById('cmb_employee_id').value;
                if (employeeId !== "" && employeeId !== "All") {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'department_name', 'employee_code'];
                    globalQuery.table = "cmv_employee_summary";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "employee_id", operator: "=", value: employeeId });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const getEmployeeLIstApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setEmloyeeCode(getEmployeeLIstApiCall[0].employee_code)
                    setEmloyeeNameOnchange(getEmployeeLIstApiCall[0].field_name)
                }
                break;
        }
    };

    const FnShowAttendanceDetails = async () => {
        debugger
        const employee_Type = document.getElementById('cmb_employee_type').value;
        let departmentId = document.getElementById('txt_department_id').value;
        if (employee_Type === "" && departmentId === "" && employee_name === "") {
            setEmployeeType('All')
            setEmployeeName('All')
            setDepartmentId('All')
        }
        const checkIsValidate = await frmValidation.current.validateForm("attendanceFormId");
        if (checkIsValidate) {
            try {
                let json = { 'MannualAttendanceData': [], 'commonIds': {} };
                setIsLoading(true);
                let employeeType = document.getElementById('cmb_employee_type').value;
                if (employeeType === "All") {
                    employeeType = '';
                }
                let departmentId = document.getElementById('txt_department_id').value;
                if (departmentId === "All") {
                    departmentId = '';
                }
                const employeeId = document.getElementById('cmb_employee_id').value;
                const fromDate = document.getElementById('dt_attendance_from_date').value;
                const toDate = document.getElementById('dt_attendance_to_date').value;
                const attendanceData = {
                    employee_type: employeeType,
                    department_id: departmentId,
                    employee_code: employee_code,
                    employee_name: employee_name,
                    from_date: fromDate,
                    to_date: toDate,
                };
                json.MannualAttendanceData = attendanceData;
                json.commonIds.company_id = COMPANY_ID;
                const formData = new FormData();
                formData.append(`GetMannualAttendanceData`, JSON.stringify(json));
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HmManualAttendance/FnShowMannualAttendanceForUpdate`, requestOptions);
                const response = await apiCall.json();

                resetGlobalQuery();
                globalQuery.columns = ['field_name', 'employee_code', 'field_id', 'department_name', 'department_id', 'employee_type'];
                globalQuery.table = "cmv_employee_summary";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                if (employeeType !== "" && employeeType !== "All") {
                    globalQuery.conditions.push({ field: "employee_type", operator: "=", value: employeeType });
                }
                if (departmentId !== "" && departmentId !== "All") {
                    globalQuery.conditions.push({ field: "department_id", operator: "=", value: departmentId });
                }
                if (employeeId !== "" && employeeId !== "All") {
                    globalQuery.conditions.push({ field: "employee_id", operator: "=", value: employeeId });
                }
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getEmployeeListApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                if (getEmployeeListApiCall.length === 0) {
                    setAttendanceDetails('');
                }

                const filteredEmployeeWithoutDuplicates = getEmployeeListApiCall.filter(data => !response.MannualAttendanceDetails.some(detail => detail.employee_code === data.employee_code));

                const filteredCombineEmployeeListData = [...filteredEmployeeWithoutDuplicates, ...response.MannualAttendanceDetails];

                await FnShowAttendanceByDate(filteredCombineEmployeeListData);

            } catch (error) {
                console.error('Error showing attendance details:', error);
                navigate('/Error');
            } finally {
                setIsLoading(false);
            }
        }
    }

    const updateAttendanceTblData = (currentAttendanceDetails, event) => {
        try {
            let clickedColName = event.target.getAttribute('Headers');
            delete event.target.parentElement.dataset.tip;
            currentAttendanceDetails[clickedColName] = event.target.value;

            const updateAttendanceDetails = [...attendanceDetails];
            const attendanceIndexInArray = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'));
            updateAttendanceDetails[attendanceIndexInArray] = currentAttendanceDetails;
            setAttendanceDetails(updateAttendanceDetails);
        } catch (error) {
            console.error("error :", error);
        }
    }

    const FnValidateAttendanceDetails = async () => {
        if (attendanceDetails.length === 0) {
            setErrMsg('Please add at least one Attendance Details...! ');
            setShowErrorMsgModal(true);
            return false;
        } else {
            let selectedAttendanceRows = $('#attendanceFormId-table tbody tr');
            let attendance = true;
            $(selectedAttendanceRows.get().reverse()).each(function () {
                let currentTblRow = $(this);
                let inputsInRow = currentTblRow.find('input[type="text"]');
                inputsInRow.each(function () {
                    if ($(this).val() === '') {
                        this.parentElement.dataset.tip = 'Please fill this field...!';
                        $(this).focus();
                        attendance = false;
                    }
                });
            });
            return attendance;
        }
    }

    const FnShowAttendanceByDate = (attendanceDetails) => {
        debugger
        try {
            if (attendanceDetails.length !== 0) {
                const fromDate = new Date(document.getElementById('dt_attendance_from_date').value);
                const toDate = new Date(document.getElementById('dt_attendance_to_date').value);
                const dateColumns = [];
                let currentDate = new Date(fromDate);

                while (currentDate <= toDate) {
                    const day = currentDate.getDate();
                    const month = currentDate.getMonth() + 1;
                    const dateStr = `${day}/${month}/${currentDate.getFullYear()}`;
                    const headerStr = `${day}/${month}`;
                    dateColumns.push({ header: headerStr, accessor: dateStr });
                    currentDate.setDate(currentDate.getDate() + 1);
                }

                const updatedAttendanceDetails = attendanceDetails.map(attendanceDetail => {
                    const updatedDetails = { ...attendanceDetail };

                    dateColumns.forEach((date) => {
                        const dateString = date.header;
                        if (updatedDetails[dateString] === '' || updatedDetails[dateString] === undefined) {
                            updatedDetails[dateString] = 'P';  // Setting default 'P' for all dates
                        }
                    });
                    return updatedDetails;
                });

                const headersAndAccessors = [...dateColumns];
                setAttendanceDates(headersAndAccessors);
                setAttendanceDetails(updatedAttendanceDetails);
                return updatedAttendanceDetails;
            }
        } catch (error) {
            console.log('error: ', error);
            navigate('/Error');
        }
    };

    // ---------------------------- save Attendance Details. -------------------------------------

    const FnAddManualAttendanceData = async () => {
        debugger
        const checkIsValidate = await frmValidation.current.validateForm("attendanceFormId");
        if (checkIsValidate) {
            let json = { 'MannualAttendanceData': [], 'ManualAttendaceDates': [], 'commonIds': {} }
            try {
                if (await FnValidateAttendanceDetails()) {
                    json.MannualAttendanceData = attendanceDetails
                    json.ManualAttendaceDates = attendanceDateColumns
                    json.commonIds.attendance_type_id = attendance_type_id;
                    json.commonIds.modified_by = UserName
                    json.commonIds.company_id = COMPANY_ID
                    json.commonIds.employee_name = employee_name
                    const formData = new FormData();
                    formData.append(`MannualAttendanceData`, JSON.stringify(json))
                    const requestOptions = {
                        method: 'POST',
                        body: formData
                    };

                    setIsLoading(true);
                    const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HmManualAttendance/FnAddUpdateRecord`, requestOptions)
                    const resp = await apiCall.json()
                    if (resp.success === 0) {
                        setErrMsg(resp.error)
                        setShowErrorMsgModal(true)
                    } else {
                        console.log("resp: ", resp)
                        ClearPage();
                        setSuccMsg(resp.message)
                        setShowSuccessMsgModal(true)
                    }
                }
            } catch (error) {
                console.log("error: ", error)
                navigate('/Error')
            } finally {
                setIsLoading(false);
            }
        }
    }

    // ---------------------------- Export2Excel Functionality Start. -------------------------------------

    const ExportToExcel = async () => {
        try {
            setIsLoading(true);
            const headerFormIsValid = await frmValidation.current.validateForm("attendanceFormId");
            if (attendanceDetails.length !== 0 && headerFormIsValid) {
                let jsonToExportExcel = {
                    'allData': {},
                    'columns': [],
                    'filtrKeyValue': {},
                    'headings': {},
                    'key': 'bomExport',
                    'editable_cols': []
                };

                attendanceDateColumns.forEach((column, index) => {
                    if (index === 0) {
                        jsonToExportExcel.columns.push({ "Headers": 'Employee Name', "accessor": 'field_name' });
                    }
                    jsonToExportExcel.columns.push({ "Headers": column.header, "accessor": column.header });
                    jsonToExportExcel.editable_cols.push(index + 1); // Index starts from 0, so increment by 1
                });

                let filtrKeyValue = {};
                filtrKeyValue['0'] = "Employee Type: " + ($('#cmb_employee_type').find(":selected").text().trim() || "") + "(" + cmb_employee_type + ")";
                filtrKeyValue['1'] = cmb_employee_id ? "Employee Name: " + $('#cmb_employee_id').find(":selected").text() + "(" + cmb_employee_id + ")" : "Employee Name: ";
                filtrKeyValue['2'] = txt_department_id ? "Department Name: " + $('#txt_department_id').find(":selected").text() + "(" + txt_department_id + ")" : "Department Name: ";
                filtrKeyValue['3'] = "From Date" + ' : ' + $('#dt_attendance_from_date').val()
                filtrKeyValue['4'] = "To Date" + ' : ' + $('#dt_attendance_to_date').val()
                jsonToExportExcel['filtrKeyValue'] = filtrKeyValue;

                for (let index = 0; index < attendanceDetails.length; index++) {
                    jsonToExportExcel['allData'][index] = attendanceDetails[index]
                }
                jsonToExportExcel['headings']['ReportName'] = "Manual Attendance Details";
                jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME;
                jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress');
                console.log("jsonToExportExcel: ", jsonToExportExcel);
                exlsExp.current.excel(jsonToExportExcel, "@" + COMPANY_ID);
            }
        } catch (error) {
            console.error('Error exporting to Excel:', error);
            navigate('/Error')
        } finally {
            setIsLoading(false);
        }
    }

    // ---------------------------- Export2Excel Functionality Ends. -------------------------------------

    // ---------------------------- ImportExcel Functionality Starts. -------------------------------------
    const onImportClick = () => {
        importFile.current.click();
    };

    const importedExcelFile = async (event) => {
        setIsLoading(true);
        debugger
        try {
            if (event.target.files) {
                var file = event.target.files[0];
                const formData = new FormData();
                formData.append(`file`, file)
                const requestOptions = { method: 'POST', body: formData };

                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HmManualAttendance/FnReadExcel`, requestOptions)
                const response = await apicall.json();

                if (response !== null && response.success === 1) {
                    let comboNames = ['Employee Type', 'Employee Name', 'Department Name']

                    if (response.data.length !== 0) {
                        for (let formFieldIndex = 0; formFieldIndex < response.formFieldData.length; formFieldIndex++) {
                            const element = response.formFieldData[formFieldIndex];
                            if (element !== "") {
                                const field = element.split(':');
                                const fieldName = field[0].trim();
                                const fieldVal = field[1].trim();
                                let comboVal;

                                if (fieldVal !== "" && comboNames.includes(fieldName)) {
                                    let lastBracesVal = fieldVal.match(/\(([^)]*)\)\s*$/);
                                    comboVal = lastBracesVal[1];
                                }

                                switch (fieldName) {
                                    case 'Employee Type':
                                        setEmployeeType(comboVal);
                                        break;

                                    case 'Employee Name':
                                        FnComboOnchange('department')
                                        FnComboOnchange('employee_name')
                                        setEmployeeName(comboVal);
                                        break;

                                    case 'Department Name':
                                        setDepartmentId(comboVal);
                                        break;
                                    case 'From Date':
                                        setFromDate(fieldVal);
                                        break;

                                    case 'To Date':
                                        setToDate(fieldVal);
                                        break;
                                }
                            }
                        }

                        const fromDate = new Date(document.getElementById('dt_attendance_from_date').value);
                        const toDate = new Date(document.getElementById('dt_attendance_to_date').value);

                        const dateColumns = [];
                        let currentDate = new Date(fromDate);

                        while (currentDate <= toDate) {
                            const day = currentDate.getDate();
                            const month = currentDate.getMonth() + 1;
                            const dateStr = `${day}/${month}/${currentDate.getFullYear()}`;
                            const headerStr = `${day}/${month}`;
                            dateColumns.push({ header: headerStr, accessor: dateStr });
                            currentDate.setDate(currentDate.getDate() + 1);
                        }
                        setAttendanceDates(dateColumns);
                        setAttendanceDetails(response.data);
                    }
                } else {
                    setErrMsg(response.error)
                    setShowErrorMsgModal(true)
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        } finally {
            setIsLoading(false);
        }
    }

    // ---------------------------- ImportExcel Functionality Ends. -------------------------------------

    const renderLeavesDetailsTable = useMemo(() => {
        if (attendanceDetails.length !== 0) {
            return (
                <>
                    <Table className="erp_table" style={{ display: "block", padding: "10px" }} id="attendanceFormId-table" responsive bordered striped>
                        <thead className="erp_table_head">
                            <tr>
                                {/* First column for employee_name */}
                                <th className="erp_table_th" id={`attendance_employee_name`}>Employee Name</th>

                                {/* Date columns */}
                                {attendanceDateColumns.map((date, indexOfItem) => (
                                    <th key={indexOfItem} className="erp_table_th" id={`attendance_${date}`}>
                                        {date.header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {attendanceDetails?.map((attendaceParam, rowIndex) => (
                                <tr rowIndex={rowIndex} key={`row_${rowIndex}`} className="sticky-column">
                                    {/* First cell for employee_name */}
                                    <td className='erp_table_td'>{attendaceParam.field_name}</td>

                                    {/* Date cells */}
                                    {attendanceDateColumns.map((date, idx) => (
                                        <td className='erp_table_td' key={idx}>
                                            <input
                                                type="text" id={`attendance_${date.header}`} className="erp_input_field_table_txt attendance mb-0"
                                                value={attendaceParam[date.header]} style={{ width: '37px' }}
                                                onChange={(e) => { updateAttendanceTblData(attendaceParam, e); }}
                                                onBlur={(e) => { updateAttendanceTblData(attendaceParam, e); }}
                                                onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }}
                                                Headers={`${date.header}`}
                                            />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            );
        }
    }, [dt_attendance_from_date, dt_attendance_to_date, attendanceDetails]);


    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ValidateNumberDateInput ref={validateNumberPercentInput} />
            <FrmValidations ref={frmValidation} />
            <ExcelExport ref={exlsExp} />
            {isLoading ?
                <div className="spinner-overlay"  >
                    <div className="spinner-container">
                        <CircularProgress color="primary" />
                        <span>Loading...</span>
                    </div>
                </div> :
                ''}
            <div>
                <div className="erp_top_Form">
                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg text-center'>Manual Attendance </label>
                        </div>
                        <form id='attendanceFormId'>
                            <div className="row erp_transporter_div ">
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Employee Type <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <select id="cmb_employee_type" className="form-select form-select-sm" value={cmb_employee_type} onChange={e => { FnComboOnchange('employee_type'); setEmployeeType(e.target.value); validateErrorMsgs() }} optional="optional" maxLength="255">
                                                <option value="" disabled>Select</option>
                                                <option value="All">All</option>
                                                {employeeTypeOptions?.map(employeeTypes => (
                                                    <option value={employeeTypes.field_name}>{employeeTypes.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_employee_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Department Name<span className="required">*</span></Form.Label>
                                        </div>

                                        <div className="col">
                                            <select id="txt_department_id" className="form-select form-select-sm" value={txt_department_id} onChange={e => { FnComboOnchange('department'); setDepartmentId(e.target.value); validateErrorMsgs() }} maxLength="20" optional="optional" >
                                                <option value="" disabled>Select</option>
                                                <option value="All">All</option>
                                                {departmentopt.map(department => (
                                                    <option value={department.field_id}>{department.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_txt_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label"> Employee Name  <span className="required">*</span></Form.Label>
                                        </div>

                                        <div className="col">
                                            <select id="cmb_employee_id" className="form-select form-select-sm" value={cmb_employee_id} onChange={e => { FnComboOnchange('employee_name'); setEmployeeName(e.target.value); validateErrorMsgs() }} maxLength="20" optional="optional">
                                                <option value="" disabled>Select</option>
                                                <option value="All">All</option>
                                                {employeeListData?.map(employeename => (
                                                    <option value={employeename.field_id}>{employeename.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>
                                {/* second column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label"> From Date <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="date" id="dt_attendance_from_date" className="erp_input_field" value={dt_attendance_from_date} onChange={e => { setFromDate(e.target.value); }} max={attendanceValidDate} />
                                            <MDTypography variant="button" id="error_dt_attendance_from_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label"> To Date <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="date" id="dt_attendance_to_date" className="erp_input_field" value={dt_attendance_to_date} onChange={e => { setToDate(e.target.value); }} max={attendanceValidDate} />
                                            <MDTypography variant="button" id="error_dt_attendance_to_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}  >
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div>
                                            <MDButton onClick={() => FnShowAttendanceDetails()} className={`erp-gb-button ms-2 mt-1`} variant="button"
                                                fontWeight="regular">show</MDButton>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>

                        <div className="row col-12">
                            <div style={{ height: '250px', overflowY: 'auto' }}>
                                {renderLeavesDetailsTable}
                            </div>
                            <MDTypography variant="button" id="error_select_earningHead" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>

                            <div className="erp_frm_Btns  py-2">
                                <MDButton type="submit" id="saveBtn" onClick={() => FnAddManualAttendanceData()} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                                    fontWeight="regular">Save</MDButton>
                                <input type="file" id="Excelfile" ref={importFile} onChange={importedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                <MDButton type="button" className="erp-gb-button ms-2" onClick={() => { navigate(`/DashBoard`) }} variant="button"
                                    fontWeight="regular">Exit</MDButton>
                                <MDButton className="erp-gb-button ms-2" variant="button" fontWeight="regular" onClick={ClearPage}>Clear</MDButton>

                                <MDButton type="button" id="export-btn" className={`erp-gb-button ms-2 ${attendanceDetails.length === 0 ? 'd-none' : ''}`}
                                    variant="button" onClick={ExportToExcel} fontWeight="regular">Export</MDButton>
                                <MDButton type="button" id="import-btn" className="erp-gb-button ms-2" variant="button" onClick={onImportClick} fontWeight="regular"> Import
                                </MDButton>

                            </div>
                            {/* Success Msg Popup */}
                            <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                            {/* Error Msg Popup */}
                            <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default FrmManualAttendanceEntry;

