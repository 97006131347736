import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import SalesOrderInvoice from 'FrmGeneric/Invoice/SalesOrderInvoice';
import MDButton from 'components/MDButton';
import PurchaseOrderInvoice from 'FrmGeneric/Invoice/PurchaseOrderInvoice';
import Enquiry from 'FrmGeneric/Invoice/EnquiryInvoice';
import QuotationInvoice from 'FrmGeneric/Invoice/QuotationInvoice';
import DispatchNote from 'FrmGeneric/Invoice/DispatchNote';
import GoodReceiptNote from 'FrmGeneric/Invoice/GRNInvoice';
import PurchaseRequisition from 'FrmGeneric/Invoice/PurchaseRequisition';
import SupplierPurchasePaymentInvoice from 'FrmGeneric/Invoice/SupplierPurchasePayment';
import CustomerSalesReciptInvoice from 'FrmGeneric/Invoice/CustomerSalesReciptInvoice';
import EnquiryService from 'FrmGeneric/Invoice/EnquiryService';
import WorkSchedulePlanningReport from 'FrmGeneric/Invoice/WorkSchedulePlanningReport';
import ServiceReportingReport from 'FrmGeneric/Invoice/ServiceReportingReport';
import SalesInvoice from 'FrmGeneric/Invoice/SalesInvoice';
import SalesOrderService from 'FrmGeneric/Invoice/SalesOrderService';
import SalesOrderServiceCustomerMaterialReceipt from 'FrmGeneric/Invoice/SalesOrderServiceCustomerMaterialReceipt';
import QuotationService from 'FrmGeneric/Invoice/QuotationService';
import DispatchChallan from 'FrmGeneric/Invoice/DispatchChallan';
import ServiceSalesInvoice from 'FrmGeneric/Invoice/ServiceSalesInvoice';
import TServiceJobReciptprint from 'FrmGeneric/Invoice/SeviceJobReceipt';

const InvoiceTemplate = () => {
  const { state } = useLocation();
  const { navigationLink, invoiceData, invoiceType, title, idList, keyForViewUpdate, compType } = state;

  const navigate = useNavigate();
  const [isPrinting, setIsPrinting] = useState(false);
  const printRef = useRef(null);
  const buttonsRef = useRef(null);
  const copyType = ['Original For Buyer', 'Duplicate Copy', 'Triplicate for Transporter', 'Extra Copy']

  // We store the resolve Promise being used in `onBeforeGetContent` here
  const promiseResolveRef = useRef(null);

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
    // printInvoice()
  }, [isPrinting]);

  useEffect(() => {
    // Print the invoice when the component loads
    printInvoice();
  }, []);

  debugger
  const printInvoice = useReactToPrint({
    content: () => {
      debugger
      let printContentRef = printRef.current
      let secondPrintContentRef = null;

      printRef.current.childNodes.forEach((node, index) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          if (index === 1) {
            printContentRef = node;
          } else if (index === 2) {
            secondPrintContentRef = node;
          }
        }
      });


      // Access the existing footer tr
      const footerTr = printContentRef.querySelector('#footerTr');

      if (!['SJR'].includes(invoiceType)) {

        let tbody = printContentRef.querySelector('#detailstableId');
        let firstRowCells = tbody.querySelector('tr').querySelectorAll('td');
        let rowTds = '';

        const trs = tbody.getElementsByTagName('tr'); // Get all <tr> elements within the tbody
        let tbodyTotalHeight = 0;                     // Calculate the total height

        // Iterate over the cells of the first row
        firstRowCells.forEach(() => { rowTds += `<td></td>` });

        // Array to keep track of added rows
        const addedRows = [];
        let tempContentHeight = printContentRef.offsetHeight
        const totalpages = Math.ceil(tempContentHeight / 1062);
        const pageHeightNeeded = totalpages * 1062

        if (!(tempContentHeight < 1067) || tempContentHeight < 1050) {
          const rowsToAdd = Math.round(pageHeightNeeded - tempContentHeight)
          const tolerance = 10;
          for (let index = 0; index < Math.round(rowsToAdd / 20); index++) {
            tempContentHeight += 20;
            if (Math.abs(tempContentHeight - pageHeightNeeded) <= tolerance) {
              break;
            }

            const newRow = document.createElement('tr');
            newRow.className = `addedTr-${index}`
            newRow.style.border = 'none'; // Remove border lines
            newRow.style.height = '20px'

            newRow.innerHTML = rowTds;

            // Append the new row to the current page
            footerTr.parentNode.insertBefore(newRow, footerTr);
            addedRows.push(newRow);
          }
        }

        let afterTbodyHt = 0
        // Check which row has more than 25pcx height
        for (let i = 0; i < trs.length; i++) {
          const className = trs[i].className;
          // Check if className contains "tblBodyTrHt"
          if (className.includes('tblBodyTrHt') && trs[i].offsetHeight > 25) {
            afterTbodyHt += trs[i].offsetHeight - 20;
          }
        }

        if (!(printContentRef.offsetHeight < 1050)) {
          let removeRows = Math.ceil((afterTbodyHt) / 20)
          let removeCount = 0
          for (let i = addedRows.length - 1; i >= 0; i--) {
            if (removeRows === removeCount || printContentRef.offsetHeight < pageHeightNeeded - 150) {
              break;
            }
            addedRows[i].parentNode.removeChild(addedRows[i]);
            removeCount += 1
          }
        }
      }

      // Clone the content node
      const printContentClone = printContentRef.cloneNode(true);
      const wrapperDiv = document.createElement('div');
      // wrapperDiv.classList.add('content');
      wrapperDiv.style.pageBreakAfter = 'always';
      wrapperDiv.appendChild(printContentClone);
      const contentWrapper = document.createElement('div');

      for (let index = 0; index < 4; index++) {
        contentWrapper.appendChild(wrapperDiv.cloneNode(true));
        // // If Sales Invoice then it will created the 4-copies.
        if (invoiceType !== "SI" && invoiceType !== "SIS") { break; }

        // Create a div for the copy type label
        // const copyTypeLabelDiv = document.createElement('dt');
        // copyTypeLabelDiv.className = 'erp-invoice-print-label-md-lg text-end me-2';
        // copyTypeLabelDiv.textContent = copyType[i];

        // Append the copy type label to the wrapper
        // contentWrapper.appendChild(copyTypeLabelDiv);
        // contentWrapper.appendChild(wrapperDiv.cloneNode(true));
      }

      if (invoiceType === "SOSR" && secondPrintContentRef !== null) {
        // Clone the content node
        const printContentClone = secondPrintContentRef.cloneNode(true);
        const wrapperDiv = document.createElement('div');
        wrapperDiv.style.pageBreakAfter = 'always';
        wrapperDiv.appendChild(printContentClone);
        contentWrapper.appendChild(wrapperDiv.cloneNode(true));
      }

      return contentWrapper;
    },
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null;
      setIsPrinting(false);
      buttonsRef.current.focus();
    },
    documentTitle: title
  });

  const invoiceComponent = () => {
    debugger
    switch (invoiceType) {
      case 'SO':
        return <SalesOrderInvoice invoiceContent={invoiceData} ref={printRef} />;
      case 'SOSR':
        return <>
          <SalesOrderService invoiceContent={invoiceData} ref={printRef} />
          {
            invoiceData.customerReciptMaterials.length > 0
              ? <SalesOrderServiceCustomerMaterialReceipt invoiceContent={invoiceData} ref={printRef} />
              : null
          }
        </>
      // return <SalesOrderService invoiceContent={invoiceData} ref={printRef} />;
      case 'PO':
        return <PurchaseOrderInvoice invoiceContent={invoiceData} ref={printRef} />;
      case 'EN':
        return <Enquiry invoiceContent={invoiceData} ref={printRef} />;
      case 'QU':
        return <QuotationInvoice invoiceContent={invoiceData} ref={printRef} />;
      case 'DN':
        return <DispatchNote dispatchNoteContent={invoiceData} ref={printRef} />;
      case 'GRN':
        return <GoodReceiptNote invoiceContent={invoiceData} ref={printRef} />;
      case 'PR':
        return <PurchaseRequisition invoiceContent={invoiceData} ref={printRef} />;
      case 'SPP':
        return <SupplierPurchasePaymentInvoice invoiceContent={invoiceData} ref={printRef} />;
      case 'CSR':
        return <CustomerSalesReciptInvoice invoiceContent={invoiceData} ref={printRef} />;
      case 'ENSR':
        return <EnquiryService invoiceContent={invoiceData} ref={printRef} />;
      case 'WSP':
        return <WorkSchedulePlanningReport invoiceContent={invoiceData} ref={printRef} />;
      case 'SR':
        return <ServiceReportingReport invoiceContent={invoiceData} ref={printRef} />;
      case 'SI':
        return <SalesInvoice invoiceContent={invoiceData} ref={printRef} />;
      case 'DC':
        return <DispatchChallan dispatchChallanContent={invoiceData} ref={printRef} />;
      case 'SIS':
        return <ServiceSalesInvoice invoiceContent={invoiceData} ref={printRef} />;
      case 'QS':
        return <QuotationService quotationContent={invoiceData} ref={printRef} />;
      case 'SJR':
        return <TServiceJobReciptprint invoiceContent={invoiceData} ref={printRef} />;
      default:
        return null;
    }
  };


  return (
    <>
      <ReactToPrint content={() => printRef.current} />
      <div ref={printRef}> {invoiceComponent()} </div>

      <div className='text-center m-25'>
        <MDButton type="button" ref={buttonsRef} className="erp-gb-button" variant="button" onClick={(e) => navigate(navigationLink, { state: { idList: idList, keyForViewUpdate: keyForViewUpdate } })}
          fontWeight="regular">Back</MDButton>
        <MDButton type="button" ref={buttonsRef} className="erp-gb-button ms-2" variant="button" onClick={printInvoice}
          fontWeight="regular">Print Invoice</MDButton>
      </div>
    </>
  );
};

export default InvoiceTemplate;
