import React from 'react'

// react-router components
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React page layout routes
import pageRoutes from "page.routes";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";


// React bootstrap imports
// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ConfigConstants from 'assets/Constants/config-constant';
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";


export default function MenuNavbar({ routes }) {
    const configConstants = ConfigConstants();
    const { PAGE_ROUTES } = configConstants;
    const navigate = useNavigate();

    // const renderRoutes = routes.map(({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
    // const renderRoutes = routes.map(({ type, name, route }) => {
    //     let returnValue;
    //     if (type === "masters") {
    //         returnValue = (
    //             <>
    //                 <NavDropdown.Item className="erp_nav_menu_item" onClick={() => { navigate(route) }}>
    //                     {name}
    //                 </NavDropdown.Item>
    //             </>
    //         )
    //     }
    //     return returnValue;
    // }
    // )

    // const renderPurchaseRoutes = routes.map(({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
    // const renderPurchaseRoutes = routes.map(({ type, name, route }) => {
    //     let returnValue;
    //     if (type === "purchase") {
    //         returnValue = (
    //             <>
    //                 <NavDropdown.Item className="erp_nav_menu_item" onClick={() => { navigate(route) }}>
    //                     {name}
    //                 </NavDropdown.Item>
    //             </>
    //         )
    //     }
    //     return returnValue;
    // }
    // )

    // const renderSalesRoutes = routes.map(({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
    // const renderSalesRoutes = routes.map(({ type, name, route }) => {
    //     let returnValue;
    //     if (type === "sales") {
    //         returnValue = (
    //             <>
    //                 <NavDropdown.Item className="erp_nav_menu_item" onClick={() => { navigate(route) }}>
    //                     {name}
    //                 </NavDropdown.Item>
    //             </>
    //         )
    //     }
    //     return returnValue;
    // }
    // )


    // const renderRegistersRoutes = routes.map(({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
    // const renderRegistersRoutes = routes.map(({ type, name, route, key }) => {
    //     let returnValue;
    //     if (type === "registers") {
    //         returnValue = (
    //             <>
    //                 <NavDropdown.Item className="erp_nav_menu_item" onClick={() => { navigate(route) }}>
    //                     {name}
    //                 </NavDropdown.Item >


    //             </>
    //         )
    //     }
    //     return returnValue;
    // }
    // )
    return (
        <>
            {/* <Navbar bg="dark" variant="dark" className="erp_dashboard_navbar">
                <Container className="menu_nav_container">
                    <Nav className="me-auto erp_navbar_nav">
                        <Navbar.Brand></Navbar.Brand>
                        <NavDropdown title="Masters" id="basic-nav-dropdown" className="erp_nav_menu">
                            {renderRoutes}
                        </NavDropdown>
                        <NavDropdown title="Purchase" id="basic-nav-dropdown" className="erp_nav_menu">
                            {renderPurchaseRoutes}
                        </NavDropdown>
                        <NavDropdown title="Sales" id="basic-nav-dropdown" className="erp_nav_menu">
                            {renderSalesRoutes}
                        </NavDropdown>
                        <NavDropdown title="Registers" id="basic-nav-dropdown" className="erp_nav_menu">
                            {renderRegistersRoutes}
                        </NavDropdown>

                    </Nav>

                </Container>
            </Navbar> */}


            <DefaultNavbar
                routes={PAGE_ROUTES}
                action={{
                    type: "external",
                    route: "https://creative-tim.com/product/material-dashboard-pro-react",
                    label: "buy now",
                }}
            />
        </>
    )
}
