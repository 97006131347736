// React Imports.
import React, { useState, useRef, useEffect, useMemo, useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';

// Material UI Imports.
import { CircularProgress } from "@material-ui/core";
import { RxCrossCircled } from "react-icons/rx";
import { GoTasklist } from "react-icons/go";
import Tooltip from "@mui/material/Tooltip";
// import { AiOutlineSchedule } from "react-icons/ai";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Accordion, Button, Modal, Table } from "react-bootstrap";

// Required Common Components.
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ConfigConstants from 'assets/Constants/config-constant';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ComboBox from 'Features/ComboBox';
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ProductList from "FrmGeneric/ProductList";
import { MdDelete } from "react-icons/md";


function TServicePlanningEntry() {
    // Required Constant's Fields For Form.
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, FINANCIAL_SHORT_NAME, UserName } = configConstants;

    // Accessing Hooks From listing page.
    const { state } = useLocation();
    const { idList, keyForViewUpdate, compType = "Transaction", deletionKey = false } = state || {};

    const navigator = useNavigate();
    const validateFrms = useRef();
    const comboDataApi = useRef();
    const validateNumberDateInput = useRef();
    const generateAutoNoApi = useRef();

    const FnFormattedDateForInputField = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const currentDate = FnFormattedDateForInputField(new Date());

    // Form's Combo's Options hooks.
    const [customersOpts, setCustomerOpts] = useState([]);
    const [servicesOpts, setServicesOpts] = useState([]);
    const [assignToEmplsOpts, setAssignToEmplsOpts] = useState([]);

    // Form Fields hooks.
    const [service_planning_master_transaction_id, setServicePlanningMasterTransactionId] = useState(idList !== null ? idList.service_planning_master_transaction_id : 0);
    const [txt_work_schedule_code, setWorkScheduleCode] = useState('0');
    const [dt_planning_date, setPlanningDate] = useState(currentDate);
    const [cmb_work_schedule_status, setWorkScheduleStatus] = useState('P');
    const [rb_work_schedule_creation_type, setWorkScheduleCreationType] = useState("S");
    const [cmb_customer_id, setCustomerId] = useState('0');
    const [cmb_service_id, setServiceId] = useState('0');
    const [dt_from_date, setFromDate] = useState('');
    const [dt_to_date, setToDate] = useState('');
    const [cmb_assign_all_to, setAssignAllTo] = useState('');

    // Table's Data Hooks.
    const [serviceSchedulingTblData, setServiceSchedulingTblData] = useState([]);
    const [activitiesTblData, setActivitiesTblData] = useState([]);

    // helping hooks.
    const [isLoading, setIsLoading] = useState(false);
    const ActionType = () => {
        switch (keyForViewUpdate) {
            case 'view':
                if (deletionKey === true) {
                    return '(Deletion)';
                } else {
                    return '(View)';
                }
            default:
                return '(Creation)';
        }
    };

    const FnSetFieldsByUserAccess = async () => {
        switch (keyForViewUpdate) {
            case 'view':
                await validateFrms.current.readOnly("ServicePlanningHeaderFormId");
                $('input[name="rb_work_schedule_creation_type"]').prop('disabled', true);
                break;

            default:
                break;
        }
    }

    // Hooks for handle the accordions.
    const [openDetailsAccord, setOpenDetailsAccord] = useState(true);
    const [openActivitiesAccord, setOpenActivitiesAccord] = useState(false);

    //Error Msg Modal
    const FnCloseErrorModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Succ Msg Modal
    const FnCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        moveToListing();
    };
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    //Delete Popup modal.
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const FnCloseDeleteModal = () => setShowDeleteModal(false);

    // Bom Filter Modal
    let requiredColsForOpenFilter = ['product_id', 'product_name', 'process_duration', 'product_std_hours',];
    const [showBomFilterForm, setShowBomFilterForm] = useState(false);
    const viewBomFilterForm = async () => {
        localStorage.setItem('requiredCols', JSON.stringify(requiredColsForOpenFilter));
        setShowBomFilterForm(true);
    }

    useEffect(() => {
        const initPageLayout = async () => {
            // First Remove the filtered data from session.
            localStorage.removeItem('filteredMaterialData');
            setIsLoading(true);
            await FnSetFieldsByUserAccess();
            await FnFillComboOnPageLoad();
            if (idList !== null) {
                await FnCheckUpdateResponse();
            } else {
                await FnGeneratePlanningNo();
            }
            await FnSetFieldsByUserAccess();
            setIsLoading(false);
        };
        initPageLayout()
    }, []);

    const FnGeneratePlanningNo = async () => {
        const apiCallResp = await generateAutoNoApi.current.generateTAutoNo("mt_service_planning_master", "work_schedule_code", "", 'SP', "5");
        setWorkScheduleCode(apiCallResp);
        return apiCallResp;
    };

    // Function for filling the combo-boxes on page load.
    const FnFillComboOnPageLoad = async () => {
        try {
            // Load the customer options.
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name']
            globalQuery.table = "cmv_customer_summary"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataApi.current.fillFiltersCombo(globalQuery)
                .then(customerList => {
                    setCustomerOpts(customerList);
                });

            // Load the Services options.
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name']
            globalQuery.table = "smv_product_sr"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataApi.current.fillFiltersCombo(globalQuery)
                .then(servicesList => {
                    setServicesOpts(servicesList);
                });

            // Load the Services options.
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name']
            globalQuery.table = "cmv_employee_list"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataApi.current.fillFiltersCombo(globalQuery)
                .then(employeeList => {
                    setAssignToEmplsOpts(employeeList);
                });
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }

    const FnComboOnChange = async (comboName) => {
        try {
            switch (comboName) {
                case 'CreationType':
                    setServiceSchedulingTblData([]);
                    setActivitiesTblData([]);
                    setCustomerId('0');
                    setServiceId('0');
                    setFromDate('');
                    setToDate('');
                    localStorage.removeItem('filteredMaterialData')
                    break;

                default:
                    break;
            }
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }

    // ******************************* Service Scheduling Functions Starts. *************************************
    // Getting data from the filter modal.
    const FnCloseFilterModal = async () => {
        setShowBomFilterForm(false)
        let newlyAddedDetails = JSON.parse(localStorage.getItem('filteredMaterialData')) || [];
        if (newlyAddedDetails != null && newlyAddedDetails != undefined) {
            let newMaterialIds = [];
            setServiceSchedulingTblData((prevArray) => {
                const updatedArray = [
                    ...prevArray,
                    ...newlyAddedDetails
                        .filter(item => !prevArray.some(prevItem => prevItem.product_id === item.product_id))
                        .map((newMaterial) => {
                            newMaterialIds.push(newMaterial.product_id);
                            let newServiceSchedule = {
                                product_id: newMaterial.product_id,
                                product_material_id: newMaterial.product_id,
                                product_material_name: newMaterial.product_name,
                                process_duration: newMaterial.process_duration,
                                product_material_std_hours: newMaterial.product_std_hours,
                                assign_to_employee_id: '',
                                operator_status: 'P',
                                department_head_status: 'P',
                                customer_status: 'P',
                                customer_name: '',
                                customer_order_no: '',
                                expected_service_schedule_start_date: currentDate,
                                expected_service_schedule_end_date: '',
                                is_billable: false,
                            }
                            let EndDateChange = FnGetNextExpectedDate(newServiceSchedule.expected_service_schedule_start_date, Math.ceil(newServiceSchedule.product_material_std_hours / 8));
                            newServiceSchedule.expected_service_schedule_end_date = EndDateChange;
                            return newServiceSchedule;
                        })
                ];
                return updatedArray;
            });
        }
    };

    // Getting data form the Sales Order.
    const FnGetSchedules = async () => {
        try {
            setIsLoading(true);
            resetGlobalQuery();
            globalQuery.columns = ['product_sr_name as product_material_name', 'customer_name', 'customer_order_no', 'process_duration', 'expected_schedule_start_date as expected_service_schedule_start_date', 'expected_schedule_end_date as expected_service_schedule_end_date', 'product_sr_std_hours as product_material_std_hours', 'product_material_id', 'product_type_id', 'sales_order_schedules_transaction_id', 'sales_order_no', 'sales_order_version', 'customer_id']
            globalQuery.table = "mtv_sales_order_schedules_services"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "sales_order_schedules_services_item_status", operator: "IN", values: ['A'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.orderBy = ["expected_schedule_start_date"];
            if (cmb_customer_id !== '' && cmb_customer_id !== '0') {
                globalQuery.conditions.push({ field: "customer_id", operator: "=", value: cmb_customer_id });
            }
            if (cmb_service_id !== '' && cmb_service_id !== '0') {
                globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: cmb_service_id });
            }
            if (dt_from_date !== '') {
                globalQuery.conditions.push({ field: "expected_schedule_start_date", operator: ">=", value: dt_from_date });
            }
            if (dt_to_date !== '') {
                globalQuery.conditions.push({ field: "expected_schedule_end_date", operator: "<=", value: dt_to_date });
            }
            const getSchedules = await comboDataApi.current.removeCatcheFillCombo(globalQuery);
            if (getSchedules.length > 0) {
                getSchedules.forEach((schedule) => {
                    schedule.assign_to_employee_id = '';
                    schedule.operator_status = 'P';
                    schedule.department_head_status = 'P';
                    schedule.customer_status = 'P';
                    schedule.is_billable = false;
                });
                // setServiceSchedulingTblData(getSchedules);
                setServiceSchedulingTblData((prevArray) => {
                    const updatedArray = [
                        ...prevArray.filter(prevItem =>
                            getSchedules.some(newItem => newItem.sales_order_schedules_transaction_id === prevItem.sales_order_schedules_transaction_id)
                        ),
                        ...getSchedules.filter(newItem =>
                            !prevArray.some(prevItem => prevItem.sales_order_schedules_transaction_id === newItem.sales_order_schedules_transaction_id)
                        )
                    ];
                    return updatedArray;
                });
                // setActivitiesTblData([]);
                setActivitiesTblData((prevArray) => {
                    const updatedArray = [
                        ...prevArray.filter(prevItem =>
                            getSchedules.some(newItem => newItem.sales_order_schedules_transaction_id === prevItem.sales_order_schedules_transaction_id)
                        )
                    ];
                    return updatedArray;
                });

                // $("input[type='checkbox'][name='selectServiceSchedule']").prop('checked', false);   // uncheck all previous service schedules.
                $('#error_schedules').hide();

            } else {
                $('#error_schedules').text('No schedules are available...!');
                $('#error_schedules').show();
                setServiceSchedulingTblData([]);
                setActivitiesTblData([]);
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }

    const FnDisplayServiceSchedulingTbl = useMemo(() => {
        return <>
            {
                serviceSchedulingTblData.length > 0
                    ? <>
                        <Table className="erp_table erp_table_scroll" id='serviceSchedulesTbl' bordered striped>
                            <thead className="erp_table_head">
                                <tr>
                                    <th className={`erp_table_th`}>Action</th>
                                    {
                                        rb_work_schedule_creation_type === 'S'
                                            ? <>
                                                <th className={`erp_table_th`} style={{ width: '100px', paddingRight: '100px' }}>Cust. Name</th>
                                                <th className={`erp_table_th`}>Cust. Ord. No.</th>
                                            </>
                                            : null
                                    }
                                    <th className={`erp_table_th`} style={{ width: '100px', paddingRight: '100px' }}>Service Name</th>
                                    <th className={`erp_table_th`}>Process Duration</th>
                                    <th className={`erp_table_th`}>STD. Hrs.</th>
                                    <th className={`erp_table_th`}>Sch. Start Date</th>
                                    <th className={`erp_table_th`}>Sch. End Date</th>
                                    <th className={`erp_table_th`}>Is Billable</th>
                                    <th className={`erp_table_th`} style={{ minWidth: '200px' }}>
                                        <div style={{ display: "flex" }}>
                                            <span>Assign To</span>
                                            {keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                ? <>
                                                    <select id={`cmb_assign_all_to`} value={cmb_assign_all_to}
                                                        onChange={(e) => { FnAssignedToAllSchedules(e); setAssignAllTo(e.target.value); }}
                                                        className="form-select form-select-sm ms-2 mb-0 erp_input_field" >
                                                        <option value="">Select</option>
                                                        {assignToEmplsOpts?.map(employee => (<option value={employee.field_id}>{employee.field_name}</option>))}
                                                    </select>
                                                </>
                                                : null
                                            }
                                        </div>
                                    </th>
                                    <th className={`erp_table_th`} style={{ paddingRight: '20px' }}>Operator Status</th>
                                    <th className={`erp_table_th`}>Dept. Head Status</th>
                                    <th className={`erp_table_th`}>Customer Status</th>
                                    <th className={`erp_table_th`} >Service ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    serviceSchedulingTblData?.map((serviceSchedule, Index) =>
                                        <tr rowIndex={Index} serviceScheduleId={serviceSchedule.sales_order_schedules_transaction_id} materialId={serviceSchedule.product_material_id}>
                                            <td className={`erp_table_td`}>
                                                <div style={{ display: "flex" }}>
                                                    {
                                                        rb_work_schedule_creation_type === 'S'
                                                            ? <>
                                                                <Form.Check className="erp-form-label selectServiceSchedule" type="checkbox"
                                                                    name="selectServiceSchedule" id={'selectServiceSchedule_' + Index}
                                                                    serviceScheduleId={serviceSchedule.sales_order_schedules_transaction_id}
                                                                    value={serviceSchedule.sales_order_schedules_transaction_id}
                                                                    onChange={async (e) => { FnCheckBoxOnChage('PartiallyServiceSchedulesSelection'); FnRemoveActivityOnUnCheck(serviceSchedule, Index); }} optional="optional"
                                                                    {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}
                                                                />
                                                            </>
                                                            : <>
                                                                <Tooltip title="Delete Service Schedule...!" placement="top">
                                                                    <MDTypography className="erp-view-btn" > <MdDelete className="erp-delete-btn" onClick={() => removeServiceSchedule(Index)} /> </MDTypography>
                                                                </Tooltip>
                                                            </>
                                                    }

                                                    {
                                                        keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                            ? <>
                                                                <Tooltip title="Select activities...!" placement="top">
                                                                    <MDTypography className="erp-view-btn" > <GoTasklist className="ms-1" onClick={(e) => { FnShowActivities(serviceSchedule, Index, rb_work_schedule_creation_type); }} /> </MDTypography>
                                                                </Tooltip>
                                                            </>
                                                            : null
                                                    }
                                                </div>
                                            </td>
                                            {
                                                rb_work_schedule_creation_type === 'S'
                                                    ? <>
                                                        <td className={`erp_table_td`} style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{serviceSchedule.customer_name}</td>
                                                        <td className={`erp_table_td`}>{serviceSchedule.customer_order_no}</td>
                                                    </>
                                                    : null
                                            }

                                            <td className={`erp_table_td`} style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{serviceSchedule.product_material_name}</td>
                                            <td className={`erp_table_td`}>{serviceSchedule.process_duration}</td>
                                            <td className={`erp_table_td text-end`}>{serviceSchedule.product_material_std_hours}</td>

                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <Form.Control type="date" id={'expected_service_schedule_start_date_' + Index} className="erp_input_field mb-0"
                                                                value={serviceSchedule.expected_service_schedule_start_date} min={currentDate}
                                                                onChange={(e) => { FnUpdateSchedulingTblData(serviceSchedule, e); }}
                                                                Headers='expected_service_schedule_start_date' />
                                                        </>
                                                        : validateNumberDateInput.current.formatDateToDDMMYYYY(serviceSchedule.expected_service_schedule_start_date)
                                                }
                                            </td>
                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <Form.Control type="date" id={'expected_service_schedule_end_date_' + Index} className="erp_input_field mb-0"
                                                                value={serviceSchedule.expected_service_schedule_end_date} min={currentDate}
                                                                onChange={(e) => { FnUpdateSchedulingTblData(serviceSchedule, e); }}
                                                                Headers='expected_service_schedule_end_date' />
                                                        </>
                                                        : validateNumberDateInput.current.formatDateToDDMMYYYY(serviceSchedule.expected_service_schedule_end_date)
                                                }
                                            </td>

                                            <td className={`erp_table_td`}>
                                                <Form.Check className="erp-form-label text-center" Headers="is_billable" type="checkbox" id={'is_billable_' + Index}
                                                    checked={serviceSchedule.is_billable} onClick={(e) => { FnUpdateSchedulingTblData(serviceSchedule, e); }}
                                                    {...(keyForViewUpdate === 'view' ? { disabled: 'disabled' } : {})} />
                                            </td>

                                            <td className={`erp_table_td`} style={{ width: '300px', display: 'table-cell', whiteSpace: 'break-spaces' }}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <select id={`assign_to_employee_id_${Index}`} value={serviceSchedule.assign_to_employee_id} className="form-select form-select-sm emplCombo"
                                                                Headers="assign_to_employee_id" onChange={(e) => { FnUpdateSchedulingTblData(serviceSchedule, e); }}>
                                                                <option value="">Select</option>
                                                                {assignToEmplsOpts?.map(employee => (<option value={employee.field_id}>{employee.field_name}</option>))}
                                                            </select>
                                                        </>
                                                        : serviceSchedule.assign_to_employee_name
                                                }
                                            </td>
                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <select id={`operator_status_${Index}`} value={serviceSchedule.operator_status} className="form-select form-select-sm"
                                                                Headers="operator_status" disabled>
                                                                <option value="P">Pending</option>
                                                                <option value="I">In Progress</option>
                                                                <option value="A">Completed</option>
                                                                <option value="R">Rework</option>
                                                                <option value="Q">Rework Completed</option>
                                                                <option value="C">Canceled</option>
                                                                <option value="Z">Pree Closed</option>
                                                                <option value="F">Payment Done</option>
                                                            </select>
                                                        </>
                                                        : serviceSchedule.operator_status_desc
                                                }
                                            </td>
                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <select id={`department_head_status_${Index}`} value={serviceSchedule.department_head_status} className="form-select form-select-sm"
                                                                Headers="department_head_status" disabled>
                                                                <option value="P">Pending</option>
                                                                <option value="A">Completed</option>
                                                                <option value="R">Rework</option>
                                                                <option value="Q">Rework Completed</option>
                                                                <option value="C">Canceled</option>
                                                                <option value="Z">Pree Closed</option>
                                                                <option value="F">Payment Done</option>
                                                            </select>
                                                        </>
                                                        : serviceSchedule.department_head_status_desc
                                                }
                                            </td>
                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <select id={`customer_status_${Index}`} value={serviceSchedule.customer_status} className="form-select form-select-sm"
                                                                Headers="customer_status" disabled>
                                                                <option value="P">Pending</option>
                                                                <option value="A">Completed</option>
                                                                <option value="R">Rework</option>
                                                                <option value="Q">Rework Completed</option>
                                                                <option value="C">Canceled</option>
                                                                <option value="Z">Pree Closed</option>
                                                                <option value="F">Payment Done</option>
                                                            </select>
                                                        </>
                                                        : serviceSchedule.customer_status_desc
                                                }
                                            </td>
                                            <td className={`erp_table_td`}>{serviceSchedule.product_material_id}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </>
                    : null
            }
        </>
    }, [serviceSchedulingTblData, assignToEmplsOpts, activitiesTblData, rb_work_schedule_creation_type]);


    // Set all service schedule to particular employee.
    const FnAssignedToAllSchedules = (event) => {
        // Update the Service Schedules tbl data.
        const updatedShedulesData = serviceSchedulingTblData.map(serviceSchedule => ({ ...serviceSchedule, assign_to_employee_id: event.target.value }));
        setServiceSchedulingTblData(updatedShedulesData);

        // Also Update the Service Schedules Activity tbl data.
        const updatedSheduleActivityData = activitiesTblData.map(serviceSchedule => ({ ...serviceSchedule, assign_to_employee_id: event.target.value }));
        setActivitiesTblData(updatedSheduleActivityData);

        // Remove all error tooltips from assign_to_employee combo.
        let assignToEmplCombos = document.querySelectorAll('.emplCombo');
        assignToEmplCombos.forEach(assignToEmplCombo => {
            delete assignToEmplCombo.parentElement.dataset.tip;
        });
    }

    // For remove the service schedule (Mannual Service Planning)
    const removeServiceSchedule = (currentServiceScheduleIndex) => {
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && rb_work_schedule_creation_type === 'M') {
            setServiceSchedulingTblData(prevData => {
                if (prevData.length > 0) {
                    let serviceScheduleToRemove = prevData[currentServiceScheduleIndex];

                    let updatedServicesScheduleData = prevData.filter((serviceSchedule, serviceScheduleIndex) => serviceScheduleIndex !== currentServiceScheduleIndex);

                    // Also Remove the it's related activity.
                    let activities = [...activitiesTblData];
                    let existingActivities = activities.filter(
                        activity => activity.product_material_id !== serviceScheduleToRemove.product_material_id
                    );
                    existingActivities.sort((firstActivity, secondActivity) => firstActivity.assign_to_employee_id - secondActivity.assign_to_employee_id);
                    setActivitiesTblData(existingActivities);

                    localStorage.setItem('filteredMaterialData', JSON.stringify(updatedServicesScheduleData));  // Set Updated data into the session filtered data.
                    return updatedServicesScheduleData;
                } else {
                    return [];
                }

            });
        }
    }

    const FnUpdateSchedulingTblData = (currentSchedule, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        let totalServiceDays;
        switch (clickedColName) {
            case 'expected_service_schedule_start_date':
                if (event.target.value !== '') {
                    totalServiceDays = Math.ceil(currentSchedule.product_material_std_hours / 8);
                    const EndDateChange = FnGetNextExpectedDate(event.target.value, totalServiceDays);
                    currentSchedule['expected_service_schedule_start_date'] = event.target.value;
                    currentSchedule['expected_service_schedule_end_date'] = EndDateChange;
                }
                break;

            case 'expected_service_schedule_end_date':
                if (event.target.value !== '') {
                    totalServiceDays = Math.ceil(currentSchedule.product_material_std_hours / 8);
                    const StartDateChange = FnGetPreviousExpectedDate(event.target.value, totalServiceDays);
                    currentSchedule['expected_service_schedule_end_date'] = event.target.value;
                    currentSchedule['expected_service_schedule_start_date'] = StartDateChange;
                }
                break;

            case 'assign_to_employee_id':
            case 'activities':
                currentSchedule[clickedColName] = event.target.value.toString();
                break;

            case 'is_billable':
                currentSchedule[clickedColName] = event.target.checked;
                break;

            default:
                break;
        }

        const updatedShedulingData = [...serviceSchedulingTblData]
        const currentScheduleIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedShedulingData[currentScheduleIndex] = currentSchedule;
        setServiceSchedulingTblData(updatedShedulingData);
        // Check the checkbox on input-field change.
        $('#selectServiceSchedule_' + currentScheduleIndex).prop('checked', true);
        delete event.target.parentElement.dataset.tip;              // Delete the tooltip.
    }
    // ******************************* Service Scheduling Functions Ends. *************************************

    // ******************************* Show Service Activities Functionality Starts.  *************************
    // ----------------- Schedule Activities Table In Accordion. ---------------------
    const [showActivityModal, setShowActivityModal] = useState(false);
    const [selectedServSchForActivity, setSelectedServSchForActivity] = useState(null);
    const [activitiesTblDataForPopUpModal, setActivitiesTblDataForPopUpModal] = useState([]);
    const FnCloseActivityModal = () => {
        setShowActivityModal(false);
        setActivitiesTblDataForPopUpModal([]);
        setSelectedServSchForActivity(null);
    }

    // load the activities related selected service schedule and show it in modal.
    const FnShowActivities = async (selectedServiceSchedule, rowIndex, workScheduleCreationType) => {
        try {
            $('#selectServiceSchedule_' + rowIndex).prop('checked', true);
            // First validate that service schedule.
            if (selectedServiceSchedule.assign_to_employee_id === '') {
                $(`#assign_to_employee_id_${rowIndex}`)[0].parentElement.dataset.tip = 'Please select assignee...!';
                return false;
            } else if (selectedServiceSchedule.expected_service_schedule_start_date === '') {
                $(`#expected_service_schedule_start_date_${rowIndex}`)[0].parentElement.dataset.tip = 'Please select expected start date...!';
                return false;
            } else if (selectedServiceSchedule.expected_service_schedule_end_date === '') {
                $(`#expected_service_schedule_end_date_${rowIndex}`)[0].parentElement.dataset.tip = 'Please select expected end date...!';
                return false;
            }

            setIsLoading(true);
            if (workScheduleCreationType === 'S') {
                // Means load the activities from the SalesOrder
                resetGlobalQuery();
                globalQuery.columns = ['customer_order_no', 'customer_name', 'product_material_name', 'product_material_activity_name', 'product_material_activity_description', 'product_sr_activity_std_hour as product_material_activity_std_hour', 'from_range', 'to_range', 'remark', 'sales_order_activity_transaction_id', 'product_service_activity_master_id as product_activity_id', 'product_material_id', 'customer_id'];
                globalQuery.table = "mtv_sales_order_service_activities"
                globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: selectedServiceSchedule.product_material_id });
                globalQuery.conditions.push({ field: "customer_order_no", operator: "=", value: selectedServiceSchedule.customer_order_no });
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

                const getServiceActivities = await comboDataApi.current.removeCatcheFillCombo(globalQuery);
                // Check Already Activities are selected or not?.
                let alreadySelectedActivities = activitiesTblData.filter(activity => activity.sales_order_schedules_transaction_id === selectedServiceSchedule.sales_order_schedules_transaction_id);

                if (alreadySelectedActivities.length > 0) {
                    // Iterate over the second array
                    getServiceActivities.forEach((serviceActivityFromDb) => {
                        // Find the matching object in the first array
                        const matchedActivity = alreadySelectedActivities.find((activity) => activity.sales_order_activity_transaction_id === serviceActivityFromDb.sales_order_activity_transaction_id);
                        // If a match is found, add the remark from the first array to the second array object
                        if (matchedActivity) {
                            serviceActivityFromDb.remark = matchedActivity.remark;
                            serviceActivityFromDb.from_range = matchedActivity.from_range;
                            serviceActivityFromDb.to_range = matchedActivity.to_range;
                        }
                    });
                    setActivitiesTblDataForPopUpModal(getServiceActivities);
                } else {
                    setActivitiesTblDataForPopUpModal(getServiceActivities);        // Set the selected material's activities.
                }

                // assign_to_employee_name
                let assignedEmployee = assignToEmplsOpts.find(empl => empl.field_id === parseInt(selectedServiceSchedule.assign_to_employee_id));
                selectedServiceSchedule.assign_to_employee_name = assignedEmployee ? assignedEmployee.field_name : '';
                setSelectedServSchForActivity(selectedServiceSchedule);         // set the selected material.

                setShowActivityModal(true);
                // Check the checked checkboxes if already services added..
                if (alreadySelectedActivities.length > 0) {
                    alreadySelectedActivities.forEach(function (alreadySelectedActivities) { $('#selectServiceSchActivity_' + alreadySelectedActivities.product_activity_id).prop('checked', true); });
                } else { $('.selectServiceSchActivity').prop('checked', true); }
            } else {

                // Means load the activities from the service-master.
                resetGlobalQuery();
                globalQuery.columns = ['product_sr_name as product_material_name', 'product_sr_activity_name as product_material_activity_name', 'product_sr_activity_description as product_material_activity_description', 'product_sr_activity_std_hour as product_material_activity_std_hour', 'product_sr_id as product_material_id', 'product_service_activity_master_id as product_activity_id'];
                globalQuery.table = "smv_product_sr_activities";
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0', });
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "product_sr_id", operator: "=", value: selectedServiceSchedule.product_material_id });
                const getServicesActivities = await comboDataApi.current.removeCatcheFillCombo(globalQuery);
                setActivitiesTblDataForPopUpModal(getServicesActivities);

                // Check Already Activities are selected or not?.
                let alreadySelectedActivities = activitiesTblData.filter(activity => activity.product_material_id === selectedServiceSchedule.product_material_id);

                if (alreadySelectedActivities.length > 0) {
                    // Iterate over the second array
                    getServicesActivities.forEach((serviceActivityFromDb) => {
                        // Find the matching object in the first array
                        const matchedActivity = alreadySelectedActivities.find((activity) => activity.product_material_id === serviceActivityFromDb.product_material_id && activity.product_activity_id === activity.product_activity_id);
                        // If a match is found, add the remark from the first array to the second array object
                        if (matchedActivity) {
                            serviceActivityFromDb.remark = matchedActivity.remark;
                            serviceActivityFromDb.from_range = matchedActivity.from_range;
                            serviceActivityFromDb.to_range = matchedActivity.to_range;
                        }
                    });
                    setActivitiesTblDataForPopUpModal(getServicesActivities);
                } else {
                    setActivitiesTblDataForPopUpModal(getServicesActivities);        // Set the selected material's activities.
                }
                let assignedEmployee = assignToEmplsOpts.find(empl => empl.field_id === parseInt(selectedServiceSchedule.assign_to_employee_id));
                selectedServiceSchedule.assign_to_employee_name = assignedEmployee ? assignedEmployee.field_name : '';
                setSelectedServSchForActivity(selectedServiceSchedule);         // set the selected material.
                setShowActivityModal(true);
                // Check the checked checkboxes if already services added..
                if (alreadySelectedActivities.length > 0) {
                    alreadySelectedActivities.forEach(function (alreadySelectedActivities) { $('#selectServiceSchActivity_' + alreadySelectedActivities.product_activity_id).prop('checked', true); });
                } else { $('.selectServiceSchActivity').prop('checked', true); }
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }

    const FnDisplayActivitiesTblForPopupModal = useMemo(() => {
        return <>
            <Table className="erp_table px-0" id="scheduleTable" bordered striped hover>
                <thead className='erp_table_head'>
                    <tr>
                        <th className="erp_table_th" style={{ paddingRight: '10px' }}>Action</th>
                        <th className="erp_table_th">Activity Name</th>
                        <th className="erp_table_th" style={{ width: '200px', paddingRight: '200px' }}>Activity Desc.</th>
                        <th className="erp_table_th">Activity Std. Hrs.</th>
                        <th className="erp_table_th" style={{ width: '200px', paddingRight: '200px' }}>From Range</th>
                        <th className="erp_table_th" style={{ width: '200px', paddingRight: '200px' }}> To Range</th>
                        <th className="erp_table_th" style={{ paddingRight: '200px' }}>Remark</th>
                    </tr>
                </thead>
                <tbody className="erp_table_td">
                    {
                        activitiesTblDataForPopUpModal?.map((activity, index) =>
                            <tr rowIndex={index}>
                                <td className={`erp_table_td`}>
                                    <div style={{ display: "flex" }}>
                                        <input type="checkbox" className="selectServiceSchActivity form-check-input" name="selectServiceSchActivity" value={activity.product_activity_id} id={`selectServiceSchActivity_${activity.product_activity_id}`}  {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})} />
                                    </div>
                                </td>
                                <td className="erp_table_td ">{activity.product_material_activity_name}</td>
                                <td className="erp_table_td " style={{ width: '200px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{activity.product_material_activity_description}</td>
                                <td className="erp_table_td text-end">{activity.product_material_activity_std_hour}</td>
                                <td className="erp_table_td " style={{ width: '200px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{activity.from_range}</td>
                                <td className="erp_table_td " style={{ width: '200px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{activity.to_range}</td>
                                <td className={`erp_table_td`}>
                                    {
                                        keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                            ? <>
                                                <Form.Control as="textarea" id={'remark_' + index} className="erp_input_field mb-0" value={activity.remark}
                                                    Headers="remark" onChange={(e) => { FnUpdateActivityTblDataInModal(activity, e); }} maxlength="1000" />
                                            </>
                                            : activity.remark
                                    }
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [activitiesTblDataForPopUpModal]);

    // Modified the Data of activities table in modal.
    const FnUpdateActivityTblDataInModal = (currentActivity, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        currentActivity[clickedColName] = event.target.value.toString();
        const updatedActivityTblDataInModal = [...activitiesTblDataForPopUpModal]
        const currentActivityIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedActivityTblDataInModal[currentActivityIndex] = currentActivity;
        setActivitiesTblDataForPopUpModal(updatedActivityTblDataInModal);

        // Checked the checkbox on input field change.
        $('#selectServiceSchActivity_' + currentActivity.product_activity_id).prop('checked', true);
    }

    const FnMapActivitiesAgainstSRSch = (selectedSRSch, activitiesAgainstSrSch, workScheduleCreationType) => {
        setIsLoading(true);
        if (workScheduleCreationType === 'S') {
            // For So-Based.
            let existingActivities = [...activitiesTblData].filter(
                activity => activity.sales_order_schedules_transaction_id !== selectedSRSch.sales_order_schedules_transaction_id
            );

            $("input:checkbox[name=selectServiceSchActivity]:checked").each(function () {
                let foundedActivity = activitiesAgainstSrSch.find(item => item.product_activity_id === parseInt($(this).val()));
                if (foundedActivity) {
                    const acitivity = {
                        company_id: COMPANY_ID,
                        company_branch_id: COMPANY_BRANCH_ID,
                        product_material_id: selectedSRSch.product_material_id,
                        sales_order_master_transaction_id: foundedActivity.sales_order_master_transaction_id,               // SO Service Master Id.
                        sales_order_schedules_transaction_id: selectedSRSch.sales_order_schedules_transaction_id,           // SO Schedule Id.
                        sales_order_activity_transaction_id: foundedActivity.sales_order_activity_transaction_id,           // SO Service Activity Id.
                        product_activity_id: foundedActivity.product_activity_id,
                        customer_order_no: foundedActivity.customer_order_no,
                        customer_name: foundedActivity.customer_name,
                        customer_id: foundedActivity.customer_id,
                        product_material_name: selectedSRSch.product_material_name,
                        product_material_activity_name: foundedActivity.product_material_activity_name,
                        product_material_activity_description: foundedActivity.product_material_activity_description,
                        product_material_activity_std_hour: foundedActivity.product_material_activity_std_hour,
                        from_range: foundedActivity.from_range,
                        to_range: foundedActivity.to_range,
                        remark: foundedActivity.remark,
                        assign_to_employee_id: selectedSRSch.assign_to_employee_id,
                        assign_to_employee_name: selectedSRSch.assign_to_employee_name,
                        operator_status: 'P',
                        department_head_status: 'P',
                        customer_status: 'P',
                        created_by: UserName,
                    }
                    existingActivities.push(acitivity);
                }
            });
            // Set Data Order By Employee.
            existingActivities.sort((firstActivity, secondActivity) => firstActivity.assign_to_employee_id - secondActivity.assign_to_employee_id);
            setActivitiesTblData([...existingActivities]);

        } else {
            // For Mannual WorkSchedule.
            let existingActivities = [...activitiesTblData].filter(
                activity => activity.product_activity_id !== selectedSRSch.product_activity_id && activity.product_material_id !== selectedSRSch.product_material_id
            );

            $("input:checkbox[name=selectServiceSchActivity]:checked").each(function () {
                let foundedActivity = activitiesAgainstSrSch.find(item => item.product_activity_id === parseInt($(this).val()));
                if (foundedActivity) {
                    const acitivity = {
                        company_id: COMPANY_ID,
                        company_branch_id: COMPANY_BRANCH_ID,
                        product_material_id: selectedSRSch.product_material_id,
                        sales_order_master_transaction_id: foundedActivity.sales_order_master_transaction_id,               // SO Service Master Id.
                        sales_order_schedules_transaction_id: selectedSRSch.sales_order_schedules_transaction_id,           // SO Schedule Id.
                        sales_order_activity_transaction_id: foundedActivity.sales_order_activity_transaction_id,           // SO Service Activity Id.
                        product_activity_id: foundedActivity.product_activity_id,
                        customer_order_no: foundedActivity.customer_order_no,
                        customer_name: foundedActivity.customer_name,
                        customer_id: foundedActivity.customer_id,
                        product_material_name: selectedSRSch.product_material_name,
                        product_material_activity_name: foundedActivity.product_material_activity_name,
                        product_material_activity_description: foundedActivity.product_material_activity_description,
                        product_material_activity_std_hour: foundedActivity.product_material_activity_std_hour,
                        from_range: foundedActivity.from_range,
                        to_range: foundedActivity.to_range,
                        remark: foundedActivity.remark,
                        assign_to_employee_id: selectedSRSch.assign_to_employee_id,
                        assign_to_employee_name: selectedSRSch.assign_to_employee_name,
                        operator_status: 'P',
                        department_head_status: 'P',
                        customer_status: 'P',
                        created_by: UserName,
                    }
                    existingActivities.push(acitivity);
                }
            });
            // Set Data Order By Employee.
            existingActivities.sort((firstActivity, secondActivity) => firstActivity.assign_to_employee_id - secondActivity.assign_to_employee_id);
            setActivitiesTblData([...existingActivities]);
        }

        setShowActivityModal(false);        // Close the modal.
        setIsLoading(false);
    }

    // ********************* Schedule Activities Table In Accordion. ************************************
    // Table In Accordion.
    const FnDisplayActivitiesTbl = useMemo(() => {
        return <>
            {
                activitiesTblData.length > 0
                    ? <>
                        <Table className="erp_table erp_table_scroll" id="scheduleTable" bordered striped hover>
                            <thead className='erp_table_head'>
                                <tr>
                                    {/* {
                                        keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                            ? <> <th className="erp_table_th">Action</th> </>
                                            : null
                                    } */}
                                    {
                                        rb_work_schedule_creation_type === 'S'
                                            ? <>
                                                <th className={`erp_table_th`} style={{ width: '100px', paddingRight: '100px' }}>Cust. Name</th>
                                                <th className={`erp_table_th`}>Cust. Ord. No.</th>
                                            </>
                                            : null
                                    }
                                    <th className="erp_table_th" style={{ width: '100px', paddingRight: '100px' }}>Assign To</th>
                                    <th className="erp_table_th" style={{ width: '200px', paddingRight: '200px' }}>Service Name</th>
                                    <th className="erp_table_th" style={{ width: '200px', paddingRight: '200px' }}>Activity Name</th>
                                    <th className="erp_table_th" style={{ width: '200px', paddingRight: '200px' }}>Activity Desc.</th>
                                    <th className="erp_table_th">Activity Std. Hrs.</th>
                                    <th className="erp_table_th" style={{ paddingRight: '200px' }}>From Range</th>
                                    <th className="erp_table_th" style={{ paddingRight: '200px' }}>To Range</th>
                                    <th className={`erp_table_th`} style={{ paddingRight: '20px' }}>Operator Status</th>
                                    <th className={`erp_table_th`}>Dept. Head Status</th>
                                    <th className={`erp_table_th`}>Customer Status</th>
                                    <th className="erp_table_th" style={{ paddingRight: '200px' }}>Remark</th>
                                </tr>
                            </thead>
                            <tbody className="erp_table_td">
                                {
                                    activitiesTblData?.map((activity, index) =>
                                        <tr rowIndex={index}>
                                            {/* {
                                                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                    ? <>
                                                        <td className="erp_table_td text-center">
                                                            <Tooltip title="Delete Service Schedule...!" placement="top">
                                                                <MDTypography className="erp-view-btn" > <MdDelete className="erp-delete-btn" onClick={() => removeServiceActity(index)} /> </MDTypography>
                                                            </Tooltip>
                                                        </td>
                                                    </>
                                                    : null
                                            } */}
                                            {
                                                rb_work_schedule_creation_type === 'S'
                                                    ? <>
                                                        <td className="erp_table_td " style={{ width: '100px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{activity.customer_name}</td>
                                                        <td className="erp_table_td ">{activity.customer_order_no}</td>
                                                    </>
                                                    : null
                                            }
                                            <td className="erp_table_td " style={{ width: '100px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{activity.assign_to_employee_name}</td>
                                            <td className="erp_table_td " style={{ width: '200px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{activity.product_material_name}</td>
                                            <td className="erp_table_td " style={{ width: '200px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{activity.product_material_activity_name}</td>
                                            <td className="erp_table_td " style={{ width: '200px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{activity.product_material_activity_description}</td>
                                            <td className="erp_table_td text-end">{activity.product_material_activity_std_hour}</td>
                                            <td className={`erp_table_td`} style={{ width: '200px', display: 'table-cell', whiteSpace: 'break-spaces' }}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <Form.Control as="textarea" id={'from_range_' + index} rows={1}
                                                                className="erp_input_field mb-0" value={activity.from_range}
                                                                Headers="from_range" onChange={(e) => { FnUpdateActivityTblDataInAccordion(activity, e); }}
                                                                maxlength="1000" />
                                                        </>
                                                        : activity.from_range
                                                }
                                            </td>
                                            <td className={`erp_table_td`} style={{ width: '200px', display: 'table-cell', whiteSpace: 'break-spaces' }}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <Form.Control as="textarea" id={'to_range_' + index} rows={1}
                                                                className="erp_input_field mb-0" value={activity.to_range}
                                                                Headers="to_range" onChange={(e) => { FnUpdateActivityTblDataInAccordion(activity, e); }}
                                                                maxlength="1000" />
                                                        </>
                                                        : activity.to_range
                                                }
                                            </td>
                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <select id={`operator_status_${index}`} value={activity.operator_status} className="form-select form-select-sm"
                                                                Headers="operator_status" disabled>
                                                                <option value="P">Pending</option>
                                                                <option value="A">Completed</option>
                                                                <option value="R">Rework</option>
                                                                <option value="Q">Rework Completed</option>
                                                                <option value="C">Canceled</option>
                                                                <option value="Z">Pree Closed</option>
                                                                <option value="F">Payment Done</option>
                                                            </select>
                                                        </>
                                                        : activity.operator_status_desc
                                                }
                                            </td>
                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <select id={`department_head_status_${index}`} value={activity.department_head_status} className="form-select form-select-sm"
                                                                Headers="department_head_status" disabled>
                                                                <option value="P">Pending</option>
                                                                <option value="A">Completed</option>
                                                                <option value="R">Rework</option>
                                                                <option value="Q">Rework Completed</option>
                                                                <option value="C">Canceled</option>
                                                                <option value="Z">Pree Closed</option>
                                                                <option value="F">Payment Done</option>
                                                            </select>
                                                        </>
                                                        : activity.department_head_status_desc
                                                }
                                            </td>
                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <select id={`customer_status_${index}`} value={activity.customer_status} className="form-select form-select-sm"
                                                                Headers="customer_status" disabled>
                                                                <option value="P">Pending</option>
                                                                <option value="A">Completed</option>
                                                                <option value="R">Rework</option>
                                                                <option value="Q">Rework Completed</option>
                                                                <option value="C">Canceled</option>
                                                                <option value="Z">Pree Closed</option>
                                                                <option value="F">Payment Done</option>
                                                            </select>
                                                        </>
                                                        : activity.customer_status_desc
                                                }
                                            </td>
                                            <td className={`erp_table_td`}>
                                                {
                                                    keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                                        ? <>
                                                            <Form.Control as="textarea" id={'remark_' + index} rows={1}
                                                                className="erp_input_field mb-0" value={activity.remark}
                                                                Headers="remark" onChange={(e) => { FnUpdateActivityTblDataInAccordion(activity, e); }}
                                                                maxlength="1000" />
                                                        </>
                                                        : activity.remark
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </>
                    : null
            }
        </>
    }, [activitiesTblData, rb_work_schedule_creation_type]);

    // For remove the activity. 
    const removeServiceActity = (currentActivityIndex) => {
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            setActivitiesTblData(prevData => {
                if (prevData.length > 0) {
                    let updatedServicesAcitivitiesData = prevData.filter((serviceActivity, serviceActivityIndex) => serviceActivityIndex !== currentActivityIndex);
                    return updatedServicesAcitivitiesData;
                } else {
                    return [];
                }
            });
        }
    }

    // For make the changes into data of activity table in accordion.
    const FnUpdateActivityTblDataInAccordion = (currentActivity, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        currentActivity[clickedColName] = event.target.value.toString();
        const updatedActivityTblDataInAccordion = [...activitiesTblData]
        const currentActivityIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedActivityTblDataInAccordion[currentActivityIndex] = currentActivity;
        setActivitiesTblData(updatedActivityTblDataInAccordion);
    }

    // If Uncheck the Service Schedule then remove it's realated activities.
    const FnRemoveActivityOnUnCheck = (currentServiceSchedule, currentRowIndex) => {
        let serviceSheduleIsChecked = $(`selectServiceSchedule_${currentRowIndex}`).prop('checked')
        if (!serviceSheduleIsChecked) {
            let existingActivities = activitiesTblData.filter(
                // activity => activity.product_material_id !== currentServiceSchedule.product_material_id || activity.customer_order_no !== currentServiceSchedule.customer_order_no
                activity => activity.sales_order_schedules_transaction_id !== currentServiceSchedule.sales_order_schedules_transaction_id
            );
            existingActivities.sort((firstActivity, secondActivity) => firstActivity.assign_to_employee_id - secondActivity.assign_to_employee_id);
            setActivitiesTblData(existingActivities);
        }
    }
    // ******************************* Show Service Activities Functionality Ends.  *************************

    // ******************************* Common Functions Starts. *************************************
    // Function to calculate the next expected start date
    const FnGetNextExpectedDate = (fromDate, afterDays) => {
        const dtAfterGraceDays = new Date(fromDate);
        dtAfterGraceDays.setDate(dtAfterGraceDays.getDate() + afterDays);
        const year = dtAfterGraceDays.getFullYear();
        const month = String(dtAfterGraceDays.getMonth() + 1).padStart(2, '0');
        const day = String(dtAfterGraceDays.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // Function to calculate the previous expected end date
    function FnGetPreviousExpectedDate(currentDate, daysToSubtract) {
        const currentDateObj = new Date(currentDate);
        const newDateObj = new Date(currentDateObj);
        newDateObj.setDate(currentDateObj.getDate() - daysToSubtract);

        // Format the date as YYYY-MM-DD (same as the input date format)
        const formattedDate = newDateObj.toISOString().split('T')[0];
        return formattedDate;
    }

    const FnCheckBoxOnChage = (checkboxType) => {
        switch (checkboxType) {
            // For Services Schedules
            case "selectServiceSchedules":
                $('.selectServiceSchedule').prop('checked', $('#selectServiceSchedules').is(":checked"));
                break;
            case 'PartiallyServiceSchedulesSelection':
                $('#selectServiceSchedules').prop('checked', $('input:checkbox.selectServiceSchedule:checked').length === $('input:checkbox.selectServiceSchedule').length);
                break;

            default:
                break;
        }
    }

    // Validate Form. (For remove the error msgs.)
    const validateFields = () => {
        validateFrms.current.validateFieldsOnChange('ServicePlanningHeaderFormId')
    }

    // Validate Service Planning.
    const FnValidateServicePlanning = async () => {
        let isValidServicePlanning = true;
        isValidServicePlanning = await validateFrms.current.validateForm("ServicePlanningHeaderFormId");
        if (!isValidServicePlanning) {
            return false;
        }

        if (rb_work_schedule_creation_type === 'S') {
            // For SO Based WorkSchedule.

            // Now Validate Activities Table. (if selected then required fields is not blank.)
            let checkedServiceScheduleIds = [];
            let checkedServiceSchedules = $('#serviceSchedulesTbl tbody tr input[type="checkbox"][name="selectServiceSchedule"]:checked');
            // Check atleast one service schedule will be checked or not?
            if (checkedServiceSchedules.length === 0) {
                setErrMsg('Please Select atleast one service schedule....!');
                setShowErrorMsgModal(true);
                setOpenDetailsAccord(true);
                isValidServicePlanning = false;
                return false;
            }

            checkedServiceSchedules.each(function () {
                if ($(this).attr('serviceScheduleId')) {
                    checkedServiceScheduleIds.push(parseInt($(this).attr('serviceScheduleId')));
                }

                let currentTblRow = $(this.parentElement.parentElement.parentElement.parentElement);
                let serviceStartDate = currentTblRow.find('input[id^="expected_service_schedule_start_date_"]').val();
                let serviceEndDate = currentTblRow.find('input[id^="expected_service_schedule_end_date_"]').val();
                let assignToEmployee = currentTblRow.find('select[id^="assign_to_employee_id_"]').val();
                if (serviceStartDate === '') {
                    $(currentTblRow.find('input[id^="expected_service_schedule_start_date_"]'))[0].parentElement.dataset.tip = 'Please select expected service schedule start date...!';
                    $(currentTblRow.find('input[id^="expected_service_schedule_start_date_"]'))[0].focus();
                    isValidServicePlanning = false;
                    setOpenDetailsAccord(true);
                } else if (serviceEndDate === '') {
                    $(currentTblRow.find('input[id^="expected_service_schedule_end_date_"]'))[0].parentElement.dataset.tip = 'Please select expected service schedule end date...!';
                    $(currentTblRow.find('input[id^="expected_service_schedule_end_date_"]'))[0].focus();
                    isValidServicePlanning = false;
                    setOpenDetailsAccord(true);
                } else if (assignToEmployee === '') {
                    $(currentTblRow.find('select[id^="assign_to_employee_id_"]'))[0].parentElement.dataset.tip = 'Please assign the employee...!';
                    $(currentTblRow.find('select[id^="assign_to_employee_id_"]'))[0].focus();
                    isValidServicePlanning = false;
                    setOpenDetailsAccord(true);
                }
                return isValidServicePlanning;
            });

            if (!isValidServicePlanning) {
                return false;
            }

            // Now Validate atleast one activity will be present into the service activity.
            let availableActivityDataMap = new Map(activitiesTblData.map(activity => [activity.sales_order_schedules_transaction_id, activity]));
            checkedServiceScheduleIds.forEach(scheduleId => {
                if (!availableActivityDataMap.has(scheduleId)) {
                    let nonAddedActivitySchedule = serviceSchedulingTblData.find(schedule => schedule.sales_order_schedules_transaction_id === scheduleId);
                    console.log('NonSelected For: ', nonAddedActivitySchedule);
                    isValidServicePlanning = false;

                    setErrMsg(`Please Select atleast one  activity For....! Service Name: ${nonAddedActivitySchedule.product_material_name} & Customer Ord. No. : ${nonAddedActivitySchedule.customer_order_no}`,);
                    setShowErrorMsgModal(true);
                    setOpenDetailsAccord(true);
                    setOpenActivitiesAccord(true);
                    return false;
                }
            });
        } else {

            // For Mannual WorkSchedule.
            // Check atleast one service schedule will be added or not?
            if (serviceSchedulingTblData.length === 0) {
                setErrMsg('Please add atleast one service schedule....!');
                setShowErrorMsgModal(true);
                isValidServicePlanning = false;
                return false;
            }

            // Set to store unique material Ids which activities are selected.
            const addedActivitiesUniqueMatIds = [...new Set(activitiesTblData.map(activity => activity.product_material_id))];


            $('#serviceSchedulesTbl tbody tr').each(function () {
                let currentTblRow = $(this);
                let currentMaterialId = currentTblRow[0].getAttribute('materialId');
                let serviceStartDate = currentTblRow.find('input[id^="expected_service_schedule_start_date_"]').val();
                let serviceEndDate = currentTblRow.find('input[id^="expected_service_schedule_end_date_"]').val();
                let assignToEmployee = currentTblRow.find('select[id^="assign_to_employee_id_"]').val();

                if (serviceStartDate === '') {
                    $(currentTblRow.find('input[id^="expected_service_schedule_start_date_"]'))[0].parentElement.dataset.tip = 'Please select expected service schedule start date...!';
                    $(currentTblRow.find('input[id^="expected_service_schedule_start_date_"]'))[0].focus();
                    isValidServicePlanning = false;
                } else if (serviceEndDate === '') {
                    $(currentTblRow.find('input[id^="expected_service_schedule_end_date_"]'))[0].parentElement.dataset.tip = 'Please select expected service schedule end date...!';
                    $(currentTblRow.find('input[id^="expected_service_schedule_end_date_"]'))[0].focus();
                    isValidServicePlanning = false;
                } else if (assignToEmployee === '') {
                    $(currentTblRow.find('select[id^="assign_to_employee_id_"]'))[0].parentElement.dataset.tip = 'Please assign the employee...!';
                    $(currentTblRow.find('select[id^="assign_to_employee_id_"]'))[0].focus();
                    isValidServicePlanning = false;
                }

                // Check activity will be added or not against that service.
                if (!addedActivitiesUniqueMatIds.includes(currentMaterialId)) {
                    let unselectedMaterial = serviceSchedulingTblData.find(material => material.product_material_id === currentMaterialId)
                    setErrMsg(`Please select atleast one activity against. "${unselectedMaterial.product_material_name}"`);
                    setShowErrorMsgModal(true);
                    isValidServicePlanning = false;
                }

                return isValidServicePlanning;
            });
        }

        if (!isValidServicePlanning) {
            return false;
        }
        return isValidServicePlanning;
    }

    // ******************************* Common Functions Ends. *************************************

    // ******************************* CRUD Functionality Starts. ********************************
    // For Save the Service Planning.
    const FnSaveServicePlanning = async () => {
        try {
            setIsLoading(true);
            let isValidServicePlanning = await FnValidateServicePlanning();
            if (isValidServicePlanning) {
                let JSONForSave = { 'TransHeaderData': {}, 'TransDetailData': [], 'TransActivitiesData': [], 'commonIds': {}, }
                let latestWorkScheduleCode = txt_work_schedule_code;
                if (service_planning_master_transaction_id === 0) {
                    latestWorkScheduleCode = await FnGeneratePlanningNo();
                }

                // JSON Which Fields Common in all tables.
                let commonFieldsInTransaction = {
                    work_schedule_code: latestWorkScheduleCode,
                    planning_date: dt_planning_date,
                    work_schedule_creation_type: rb_work_schedule_creation_type,
                    service_planning_master_transaction_id: service_planning_master_transaction_id,
                    company_id: parseInt(COMPANY_ID),
                    company_branch_id: parseInt(COMPANY_BRANCH_ID),
                    financial_year: FINANCIAL_SHORT_NAME,
                    created_by: UserName
                };

                // Generate JSON For Header Data.
                JSONForSave.TransHeaderData = {
                    ...commonFieldsInTransaction,               // Common Fields. 
                    work_schedule_status: cmb_work_schedule_status,
                    customer_id: cmb_customer_id,
                    product_material_id: cmb_service_id,
                    from_date: dt_from_date,
                    to_date: dt_to_date,
                    modified_by: service_planning_master_transaction_id !== 0 ? UserName : null,
                }

                // JSONArray For the Service Schedules.
                if (rb_work_schedule_creation_type === 'S') {
                    $("input:checkbox[name=selectServiceSchedule]:checked").each(function () {
                        let checkedServiceSchedule = serviceSchedulingTblData.find(serviceSchedule => serviceSchedule.sales_order_schedules_transaction_id === parseInt($(this).val()));
                        const serviceSchedule = {
                            ...checkedServiceSchedule,
                            ...commonFieldsInTransaction,    // set all common fields.
                        }
                        JSONForSave.TransDetailData.push(serviceSchedule);
                    });
                } else {
                    JSONForSave.TransDetailData = serviceSchedulingTblData.map(serviceSchedule => ({
                        ...commonFieldsInTransaction, // set all common fields.
                        ...serviceSchedule,
                    }));
                }


                JSONForSave.TransDetailData.forEach(currentServiceSchedule => {
                    let activitiesAgainstCurrentService = [];
                    if (rb_work_schedule_creation_type === 'S') {
                        activitiesAgainstCurrentService = activitiesTblData.filter(activity => activity.sales_order_schedules_transaction_id === currentServiceSchedule.sales_order_schedules_transaction_id);
                    } else {
                        activitiesAgainstCurrentService = activitiesTblData.filter(activity => activity.product_material_id === currentServiceSchedule.product_material_id);
                    }

                    if (activitiesAgainstCurrentService.length > 0) {
                        const updatedActivitiesData = activitiesAgainstCurrentService.map(activity => ({
                            ...commonFieldsInTransaction, // set all common fields.
                            ...activity,
                        }));
                        JSONForSave.TransActivitiesData.push(...updatedActivitiesData);
                    }
                });

                // Common Ids;
                JSONForSave.commonIds.service_planning_master_transaction_id = service_planning_master_transaction_id;
                JSONForSave.commonIds.company_id = COMPANY_ID;
                JSONForSave.commonIds.company_branch_id = COMPANY_BRANCH_ID
                JSONForSave.commonIds.financial_year = FINANCIAL_SHORT_NAME

                console.log(`JSON Sending For Saving Service Planning: `, JSONForSave);
                const formData = new FormData();
                formData.append(`ServicePlanningMasterData`, JSON.stringify(JSONForSave))
                const requestOptions = { method: 'POST', body: formData };
                const apiCallForSave = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtServicePlanningMaster/FnAddUpdateRecord`, requestOptions)
                const response = await apiCallForSave.json();
                console.log('responceAfterSave: ', response);

                if (response.success === 1 && response.error === '') {
                    const evitCache = await comboDataApi.current.evitCache();
                    setServicePlanningMasterTransactionId(response.data.service_planning_master_transaction_id);
                    setSuccMsg(response.message);
                    setShowSuccessMsgModal(true);
                    // moveToListing();
                } else {
                    console.log('response error on saving the service planning: ', response.error);
                    setErrMsg(response.error);
                    setShowErrorMsgModal(true);
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }

    // For Filled the data on view/update action
    const FnCheckUpdateResponse = async () => {
        try {
            setIsLoading(true);
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtServicePlanningMaster/FnShowParticularRecordForUpdate/${idList.service_planning_master_transaction_id}/${COMPANY_ID}`)
            const responce = await apiCall.json();

            if (responce.ServicePlanningMasterData !== null) {
                let masterData = responce.ServicePlanningMasterData;
                setWorkScheduleCode(masterData.work_schedule_code);
                setPlanningDate(masterData.planning_date);
                setWorkScheduleCreationType(masterData.work_schedule_creation_type);
                setWorkScheduleStatus(masterData.work_schedule_status);
                setFromDate(masterData.from_date);
                setToDate(masterData.to_date);
                setCustomerId(masterData.customer_id);
                setServiceId(masterData.product_material_id);

                if (responce.ServicePlanningDetailsData !== null) {
                    setServiceSchedulingTblData(responce.ServicePlanningDetailsData);
                    responce.ServicePlanningDetailsData.map((serviceSchedule) => {
                        $(`input[type="checkbox"][name='selectServiceSchedule'][value="${serviceSchedule.sales_order_schedules_transaction_id}"]`).prop('checked', true);
                    });
                }

                if (responce.ServicePlanningActivitiesData !== null) {
                    responce.ServicePlanningActivitiesData.sort((firstActivity, secondActivity) => firstActivity.assign_to_employee_id - secondActivity.assign_to_employee_id);
                    setActivitiesTblData(responce.ServicePlanningActivitiesData);
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error : ", error)
            navigator('/Error')
        }
    }

    // For Deletion
    const FnShowDeleteModal = () => { setShowDeleteModal(true); }
    const FnDeleteServicePlanning = async () => {
        try {
            setIsLoading(true);
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtServicePlanningMaster/FnDeleteRecord/${idList.service_planning_master_transaction_id}/${UserName}/${COMPANY_ID}`, method)
            const responce = await deleteApiCall.json();
            console.log("Service Planning Deleted: ", responce);
            if (responce.success == 1) {
                setShowDeleteModal(false)
                moveToListing();
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }


    // ******************************* CRUD Functionality Ends. ********************************

    const moveToListing = () => {
        const Path = compType === 'Register' ? '/Transactions/TServicePlanning/ServicePlanningListing/reg' : `/Transactions/TServicePlanning/ServicePlanningListing`;
        navigator(Path);
    }

    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataApi} />
                <FrmValidations ref={validateFrms} />
                <ValidateNumberDateInput ref={validateNumberDateInput} />
                <GenerateTAutoNo ref={generateAutoNoApi} />

                {isLoading
                    ? <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div>
                    : ''}

                <div className='card p-1'>
                    <div className='card-header text-center py-1'>
                        <label className='erp-form-label-lg text-center'>Work Schedule Planning {ActionType()}</label>
                    </div>

                    <div className="card-body">
                        <form id='ServicePlanningHeaderFormId'>
                            <div className="row p-1">
                                {/* First column */}
                                <div className="col-md-4">

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Creation Type <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col-sm-8">
                                            <div className="erp_form_radio">
                                                <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Mannual" type="radio" lbl="Mannual" value="M" name="rb_work_schedule_creation_type" checked={rb_work_schedule_creation_type === "M"} onClick={(e) => { setWorkScheduleCreationType('M'); validateFields(); FnComboOnChange('CreationType'); }} /> </div>
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="SO Based" type="radio" lbl="SO Based" value="S" name="rb_work_schedule_creation_type" checked={rb_work_schedule_creation_type === "S"} onClick={(e) => { setWorkScheduleCreationType('S'); validateFields(); FnComboOnChange('CreationType'); }} /> </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label">Work Schedule Code<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <Form.Control type="text" id="txt_work_schedule_code" className="erp_input_field" value={txt_work_schedule_code} disabled />
                                            <MDTypography variant="button" id="error_txt_work_schedule_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label">Planning Date<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <Form.Control type="date" id='dt_planning_date' className="erp_input_field" value={dt_planning_date} onChange={e => { setPlanningDate(e.target.value); validateFields(); }} />
                                            <MDTypography variant="button" id="error_dt_planning_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                </div>

                                {/* Second column */}
                                <div className="col-md-4">
                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Work Schedule Status<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <select id="cmb_work_schedule_status" className="form-select form-select-sm" value={cmb_work_schedule_status} disabled >
                                                <option value="P">Pending</option>
                                                <option value="I">In Progress</option>
                                                <option value="A">Completed</option>
                                                <option value="R">Rework</option>
                                                <option value="Q">Rework Completed</option>
                                                <option value="C">Canceled</option>
                                                <option value="Z">Pree Closed</option>
                                                <option value="F">Payment Done</option>
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_work_schedule_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Customer </Form.Label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <select id="cmb_customer_id" className="form-select form-select-sm" value={cmb_customer_id} onChange={(e) => { setCustomerId(e.target.value); validateFields(); }} optional="optional" {...rb_work_schedule_creation_type === 'M' || keyForViewUpdate === 'view' ? { disabled: 'disabled' } : {}}>
                                                {/* <option value="">Select</option> */}
                                                <option value="0">All</option>
                                                {customersOpts?.map(customer => (
                                                    <option value={customer.field_id}>{customer.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Service </Form.Label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <select id="cmb_service_id" className="form-select form-select-sm" value={cmb_service_id} onChange={(e) => { setServiceId(e.target.value); validateFields(); }} optional="optional" {...rb_work_schedule_creation_type === 'M' || keyForViewUpdate === 'view' ? { disabled: 'disabled' } : {}}>
                                                {/* <option value="">Select</option> */}
                                                <option value="0">All</option>
                                                {servicesOpts?.map(service => (
                                                    <option value={service.field_id}>{service.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_service_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>
                                </div>

                                {/* Third column */}
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">From Date </Form.Label>
                                        </div>
                                        <div className='col-sm-9'>
                                            <Form.Control type="date" id='dt_from_date' className="erp_input_field" value={dt_from_date} onChange={(e) => { setFromDate(e.target.value); validateFields(); }} max={dt_to_date} optional="optional" {...rb_work_schedule_creation_type === 'M' || keyForViewUpdate === 'view' ? { disabled: 'disabled' } : {}} />
                                            <MDTypography variant="button" id="error_dt_from_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">To Date </Form.Label>
                                        </div>
                                        <div className='col-sm-9'>
                                            <Form.Control type="date" id='dt_to_date' className="erp_input_field" value={dt_to_date} onChange={(e) => { setToDate(e.target.value); validateFields(); }} min={dt_from_date} optional="optional" {...rb_work_schedule_creation_type === 'M' || keyForViewUpdate === 'view' ? { disabled: 'disabled' } : {}} />
                                            <MDTypography variant="button" id="error_dt_to_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div className="text-start mb-3 mt-3 mt-sm-0">
                            {
                                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && rb_work_schedule_creation_type === 'S'
                                    ? <>
                                        <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => { FnGetSchedules(); }}>Show Schedules</MDButton>
                                    </>
                                    : <>
                                        <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={viewBomFilterForm} >Select Services</MDButton>
                                    </>
                            }
                        </div >

                        <Accordion defaultActiveKey="0" activeKey={openDetailsAccord ? '0' : null}>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenDetailsAccord(!openDetailsAccord)}>Services Schedules</Accordion.Header>
                                <Accordion.Body className="p-0">
                                    <div className="row">
                                        <div className={`table-responsive ${serviceSchedulingTblData.length > 0 ? 'erp_table_scroll' : ''}`}>
                                            <> {FnDisplayServiceSchedulingTbl}</>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <MDTypography variant="button" id="error_schedules" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <hr />
                        <Accordion defaultActiveKey="0" activeKey={openActivitiesAccord ? '0' : null}>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenActivitiesAccord(!openActivitiesAccord)}>Activities</Accordion.Header>
                                <Accordion.Body className="p-0">
                                    <div className="row">
                                        <div className={`table-responsive erp_table_scroll`}>
                                            <> {FnDisplayActivitiesTbl}</>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <MDTypography variant="button" id="error_activities_tbl" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </div>

                </div>

                <hr />
                <div className="text-center mb-5">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    {
                        keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                            ? <>
                                <MDButton type="button" className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" onClick={() => FnSaveServicePlanning()}>Save</MDButton>
                            </>
                            : null
                    }
                    {
                        deletionKey
                            ? <>
                                <MDButton type="button" className={`erp-gb-button erp_MLeft_btn`} variant="button" fontWeight="regular" onClick={() => FnShowDeleteModal()}> Delete </MDButton>
                            </>
                            : null
                    }

                </div>


                {/* Modal For Show Activities */}
                <Modal size="lg" className='erp_document_Form' show={showActivityModal} onHide={FnCloseActivityModal} backdrop="static" keyboard={false} centered>
                    <Modal.Header className="py-2">
                        <Modal.Title className='erp_modal_title'>Service Activities</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseActivityModal}></button></span>
                    </Modal.Header>
                    <Modal.Body className="">
                        <dl className="row mb-0">
                            <div className="col-md-6">
                                <dl className="row mb-0">
                                    <dt className="col-sm-4 erp-form-label-md-lg">Service Name:</dt>
                                    <dd className="col-sm-8 erp-form-label">{selectedServSchForActivity !== null ? selectedServSchForActivity.product_material_name : ''}</dd>
                                </dl>
                            </div>
                            {/* <div className="col-md-6 col-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-4 erp-form-label-md-lg">Customer Ord. No.:</dt>
                                    <dd className="col-sm-8 erp-form-label">{selectedServSchForActivity !== null ? selectedServSchForActivity.customer_order_no : ''}</dd>
                                </dl>
                            </div> */}

                            <div className="col-md-6 col-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-4 erp-form-label-md-lg">Assign To:</dt>
                                    <dd className="col-sm-8 erp-form-label">{selectedServSchForActivity !== null ? selectedServSchForActivity.assign_to_employee_name : ''}</dd>
                                </dl>
                            </div>
                        </dl>

                        <dl className="row mb-0">
                            <div className="col-md-6">
                                <dl className="row mb-0">
                                    <dt className="col-sm-4 erp-form-label-md-lg">Exp. Start Date:</dt>
                                    <dd className="col-sm-8 erp-form-label">{selectedServSchForActivity !== null ? validateNumberDateInput.current.formatDateToDDMMYYYY(selectedServSchForActivity.expected_service_schedule_start_date) : ''}</dd>
                                </dl>
                            </div>
                            <div className="col-md-6 col-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-4 erp-form-label-md-lg">Exp. Start End:</dt>
                                    <dd className="col-sm-8 erp-form-label">{selectedServSchForActivity !== null ? validateNumberDateInput.current.formatDateToDDMMYYYY(selectedServSchForActivity.expected_service_schedule_end_date) : ''}</dd>
                                </dl>
                            </div>
                        </dl>

                        <div className='row px-1'>
                            <div className="table-responsive erp_table_scroll px-1">
                                {FnDisplayActivitiesTblForPopupModal}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='erp-modal-footer'>
                        {
                            keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                ? <>
                                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => FnMapActivitiesAgainstSRSch(selectedServSchForActivity, activitiesTblDataForPopUpModal, rb_work_schedule_creation_type)}>Add Activities</MDButton>
                                </>
                                : null
                        }
                    </Modal.Footer>
                </Modal>

                {/* Delete Modal */}
                <Modal show={showDeleteModal} onHide={FnCloseDeleteModal} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseDeleteModal}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        {isLoading
                            ? <div className="spinner-overlay"  >
                                <div className="spinner-container">
                                    <CircularProgress color="primary" />
                                    <span>Loading...</span>
                                </div>
                            </div>
                            : ''}

                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to delete this record ?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={FnCloseDeleteModal}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={FnDeleteServicePlanning}>Delete</Button>
                    </Modal.Footer>
                </Modal>

                {/* Generic Response Messaage Modals */}
                <SuccessModal handleCloseSuccessModal={() => FnCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => FnCloseErrorModal()} show={[showErrorMsgModal, errMsg]} />

                {/* ADD Filter modal */}
                <Modal size="lg" className='erp_document_Form' show={showBomFilterForm} onHide={FnCloseFilterModal} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Service List</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseFilterModal}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <React.Fragment>
                            <ProductList closeModal={FnCloseFilterModal} props={{ selectedProduct: ['smv_product_sr_summary'] }} />
                        </React.Fragment>
                    </Modal.Body>
                </Modal>
            </DashboardLayout>

        </>
    )
}

export default TServicePlanningEntry;