import { React, useState, useEffect, useRef, useMemo, useLayoutEffect } from "react";
import $ from 'jquery';
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Accordion } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

// import files
import ComboBox from "Features/ComboBox";
import { resetGlobalQuery, globalQuery } from 'assets/Constants/config-constant';
import FrmCity from 'FrmGeneric/MCity/FrmCity';
import FrmValidations from 'FrmGeneric/FrmValidations';
import SuccessModal from 'components/Modals/SuccessModal';
import ErrorModal from 'components/Modals/ErrorModal';
import ConfigConstants from "assets/Constants/config-constant";
import GenerateMaterialId from "FrmGeneric/GenerateMaterialId/GenerateMaterialId";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmDestinationEntry from "Masters/MDestination/FrmDestinationEntry";


// Import React icons
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { MdRefresh } from "react-icons/md";
import { CircularProgress } from "@mui/material";
import DepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";
import FrmShiftEntry from "Masters/MShift/FrmShiftEntry";
import MCostCenterEntry from "Masters/MFMCostCenter/MCostCenterEntry/Index";



export default function FrmMEmployeesEntry(props) {
  // Config Constant
  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, EARNING_DEDUCTION_MAPPING_BASE } = configConstants;

  const [isLoading, setIsLoading] = useState(false);

  const { state } = useLocation();
  let { keyForViewUpdate = 'Add', compType = 'Masters' } = state || {}

  const combobox = useRef();
  const frmValidation = useRef();


  //changes by ujjwala on 8/1/2024 case no 1
  // Call ConfigConstants to get the configuration constants
  // For navigate
  const navigate = useNavigate();
  const generateMaterialIdAPiCall = useRef();

  // combobox Option hooks
  const [employeeGroupTypeOptions, setEmployeeGroupTypeOptions] = useState([]);
  const [employeeTypeOptions, setEmployeeTypeOptions] = useState([]);
  const [employeeStatusOptions, setEmployeeStatusOptions] = useState([]);
  const [destinationOptions, setDestinationOptions] = useState([]);
  const [employeeReferenceOptions, setEmployeeReferenceOptions] = useState([]);
  const [salutationOptions, setSalutationOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [religionOptions, setReligionOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [casteOptions, setCasteOptions] = useState([]);
  const [bloodGroupsOptions, setBloodGroupOptions] = useState([]);
  const [maritalStatusOptions, setMaritalStatusOptions] = useState([]);
  const [countryCodeOptions, setCountryCodeOptions] = useState([]);
  const [bankNamesOptions, setBankNameOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  // Employee Forms Fields
  // Employment Info Section
  const [employee_id, setEmployee_id] = useState(props.employeeID);

  const [txt_employee_code, setEmployeeCode] = useState('');
  const [txt_employee_machine_code, setEmployeeMachineCode] = useState('');
  const [cmb_employee_group_type, setEmployeeGroupType] = useState('');
  const [cmb_employee_type, setEmployeeType] = useState('');
  const [cmb_employee_status, setEmployeeStatus] = useState('');
  const [cmb_destination_id, setDestionationId] = useState('2');
  const [cmb_reference_id, setReferenceId] = useState('NA');
  const [txt_username, setUserName] = useState('');
  const [txt_password, setPassword] = useState('');

  // Personal Info Section
  const [cmb_salutation, setSalutation] = useState('1');
  const [txt_last_name, setLastName] = useState('');
  const [txt_first_name, setFirstName] = useState('');
  const [txt_middle_name, setMiddleName] = useState('');
  const [txt_employee_name, setEmployeeName] = useState('');
  const [dt_date_of_birth, setDateOfBirth] = useState('');
  const [cmb_gender, setGender] = useState('Male');
  const [cmb_religion, setReligion] = useState('NA');
  const [cmb_category_id, setCategoryId] = useState('NA');
  const [cmb_caste_id, setCasteId] = useState('NA');
  const [cmb_blood_group, setBloodGroupId] = useState('');
  const [cmb_marital_status, setMaritalStatus] = useState('');
  const [txt_email_id1, setEmailId1] = useState('');
  const [txt_email_id2, setEmailId2] = useState('');
  const [txt_phone_no, setPhoneNo] = useState('');
  const [txt_cell_no1, setCellNo1] = useState('');
  const [txt_cell_no2, setCellNo2] = useState('');

  const [chk_pmt_add, setPermanentAddressChechBox] = useState(false)

  // Document Info Section
  const [txt_aadhar_card_no, setAadharCardNo] = useState('');
  const [txt_passport_no, setPassportNo] = useState('');
  const [txt_pan_no, setPanNo] = useState('');
  const [txt_driving_licence, setDrivingLicence] = useState('');
  const [cmb_bank_id_1, setBankId1] = useState('1');
  const [txt_account_no1, setAccountNo1] = useState('');
  const [txt_ifsc_code1, setIFSCCode1] = useState('');
  const [cmb_bank_id_2, setBankId2] = useState('');
  const [txt_account_no2, setAccountNo2] = useState('');
  const [txt_ifsc_code2, setIFSCCode2] = useState('');
  const [finance_account_no, setFinanceAccountNo] = useState('');

  // Resident Info Section
  const [txt_current_address, setCurrentAddress] = useState('');
  const [txt_current_pincode, setCurrentPincode] = useState('');
  const [txt_permanant_address, setPermanantAddress] = useState('');
  const [txt_permanant_pincode, setPermanantPincode] = useState('');
  const [cmb_country_id, setCountryId] = useState('');
  const [cmb_state_id, setStateId] = useState('');
  const [cmb_city_id, setCityId] = useState('');
  const [cmb_district_id, setDistrictId] = useState('');
  const [actionType, setActionType] = useState('')
  const [actionLabel, setActionLabel] = useState('Save')

  //Employee Workprofile ......!!
  // Employee WorkProfile Form Combos Options
  const [departmentGroupOptions, setDepartmentGroupOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [subDepartmentGroupOptions, setSubDepartmentGroupOptions] = useState([]);
  const [designationIdOptions, setDesignationOptions] = useState([]);
  const [reportingToOptions, setReportingToOptions] = useState([]);
  const [weeklyOffOptions, setWeeklyOffOptions] = useState([]);
  const [shiftOptions, setShiftOptions] = useState([]);
  const [setContractorOptions, setContractorOption] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [costCenterOptions, setCostCenterOptions] = useState([]);

  // Employee WorkProfile Form Fields
  const [emplWorkPrfileID, setemplWorkPrfileID] = useState(0);
  const [cmb_department_group_id, setDepartmentGroupId] = useState('');
  const [cmb_department_id, setDepartmentId] = useState('');
  const [cmb_subdepartment_group_id, setSubDepartmentGroupId] = useState('');
  const [cmb_designation_id, setDesignationId] = useState('');
  const [cmb_reproting_to_id, setReportingToId] = useState('');
  const [cmb_weeklyoff_id, setWeeklyOffId] = useState('');
  const [cmb_shift_id, setShiftId] = useState('');
  const [dt_date_joining, setDateJoining] = useState('');
  const [dt_date_exit, setDateExist] = useState('');
  const [cmb_contractor_id, setContractorId] = useState('001');
  const [dt_contract_start_date, setContractStartDate] = useState('');
  const [dt_contract_end_date, setContractEndDate] = useState('');
  const [cmb_region_id, setRegionId] = useState('1');
  const [cmb_cost_center_id, setCostCenterId] = useState('');
  const [bondIsApplicable, setBondIsApplicable] = useState(true);
  const [txt_current_job, setCurrentJob] = useState('');

  //Employee Salary Hooks.......
  // combobox Option hooks
  const [salaryBands, setSalaryBands] = useState([]);
  const [salaryGrades, setSalaryGrades] = useState([]);

  // Employment Salary Section Forms Fields]
  const [salaryId, setSalaryId] = useState(0);
  const [cmb_band_id, setBandId] = useState("1");
  const [cmb_grade_id, setGradeId] = useState("1");
  const [txt_ctc, setCTC] = useState(0);
  const [txt_salary, setSalary] = useState(0);
  const [chk_ot_flag, setOTFlag] = useState(false);
  const [txt_ot_amount, setOTAmmount] = useState('');
  const [chk_gratuity_applicable, setGratuityIsApplicable] = useState(false);

  const [txt_uan_no, setUANNum] = useState('');
  const [chk_esic_flag, setESICIsApplicable] = useState(false);
  const [txt_esic_no, setESICNum] = useState('');
  const [dt_esic_date, setESICDate] = useState('');

  const [chk_pf_flag, setPFIsApplicable] = useState(false);
  const [dt_pf_date, setPFDate] = useState('');
  const [txt_pf_no, setPFnum] = useState('');

  const [chk_mlwf_flag, setMLWFIsApplicable] = useState(false);
  const [txt_mlwf_no, setMLWFNum] = useState('');

  //hide and show password 
  const [showPassword, setShowPassword] = useState(false);

  const validateNumberPercentInput = useRef();
  const today = new Date().toISOString().split('T')[0];
  const [deductionData, setDeductionData] = useState([]);
  const [earningData, setEarningData] = useState([]);
  const [effective_date, setEffectiveDate] = useState(today);

  const togglePasswordhideandshow = () => {
    setShowPassword(!showPassword);
  };

  //case no. 1 chnges by ujjwala 10/1/2024 Start
  useEffect(() => {
    const loadDataOnload = async () => {
      try {
        setIsLoading(true);
        await ActionType();
        await FnFillComboBoxes();
        await FnShowEarningAndDeductionRecrd();
      } catch (error) {
        console.error(error);
        navigate('/Error')
      } finally {
        setIsLoading(false);
      }
    }
    loadDataOnload()

  }, [])

  //end
  const [showAddRecModal, setShowAddRecModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('')

  //case no. 1 chnges by ujjwala 10/1/2024 Start
  const ActionType = async () => {
    $('#employee_code').attr('readonly', true);
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modify)');
        setActionLabel('Update')
        $('#employee_code').attr('readonly', true)
        $('#txt_last_name').attr('readonly', true)
        $('#txt_first_name').attr('readonly', true)
        $('#txt_middle_name').attr('readonly', true)
        $('#txt_employee_name').attr('readonly', true)
        document.getElementById('nxtBtn').disabled = false;
        break;
      case 'view':
        setActionType('(View)');
        await frmValidation.current.readOnly("employeeFormId");
        $("input[type=radio]").attr('disabled', true);
        break;
      default:
        setActionType('(Create)');
        break;
    }
  };

  // Show ADd record Modal
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {
      case 'City':
        if (cmb_district_id !== '' && cmb_district_id !== undefined) {
          combobox.current.fillMasterData("cmv_city", "district_id", cmb_district_id).then((propertyList2) => {
            setCityOptions(propertyList2)
          })

        }
        setShowAddRecModal(false);
        break;

      case 'Employee Destination':
        combobox.current.fillMasterData("cmv_destination", "", "").then((destinationsApiCall) => {
          setDestinationOptions(destinationsApiCall);
        })

        break;

      case 'Department':
        await comboBoxesOnChange("DepartmentGroup");
        break;

      case 'Sub Department':
        await comboBoxesOnChange("Department");
        break;

      case 'Contractor Information':
        combobox.current.fillMasterData("cmv_contractor", "", "").then((contractorList) => {
          setContractorOption(contractorList);
        });
        break;

      case 'Designation':
        combobox.current.fillMasterData("cmv_designation", "", "").then((designations) => {
          setDesignationOptions(designations);
        });
        break;

      case 'Shift':
        combobox.current.fillMasterData("cmv_shift", "", "").then((workingShifts) => {
          setShiftOptions(workingShifts);
        });
        break;

      case 'Cost Center':
        combobox.current.fillMasterData("fmv_cost_center", "", "").then((constCenterOptions) => {
          setCostCenterOptions(constCenterOptions);
        });
        break;

      default:
        break;
    }
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)

  }

  const displayRecordComponent = () => {
    switch (modalHeaderName) {
      case 'City':
        return <FrmCity btn_disabled={true} />;
      case 'Designation':
        return <FrmDestinationEntry btn_disabled={true} />;
      case 'Department':
      case 'Sub Department':
        return <DepartmentEntry btn_disabled={true} />;
      case 'Shift':  // Added case because it will not open the shift form.
        return <FrmShiftEntry btn_disabled={true} />;
      case 'Cost Center':
        return <MCostCenterEntry btn_disabled={true} />;
      default:
        return null;
    }
  }

  const usernameRef = useRef();

  const handleCloseErrModal = () => {
    if (errMsg === "User Name is already exist!") {
      setShowErrorMsgModal(false);
      usernameRef.current.focus();
    } else {
      setShowErrorMsgModal(false);
    }
  }

  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => setShowSuccessMsgModal(false);
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  // Fill the combo boxes from property table.
  const FnFillComboBoxes = async () => {
    try {
      combobox.current.fillMasterData("cmv_destination", "", "").then((destinationsApiCall) => {
        setDestinationOptions(destinationsApiCall);
      })

      const apiCallCountryCodeList = await fetch(`${process.env.REACT_APP_BASE_URL}/api/country/FnFetchCountryCodes`)
      const respCountryCode = await apiCallCountryCodeList.json();
      setCountryCodeOptions(respCountryCode)

      combobox.current.fillMasterData("cmv_banks_List", "", "").then((bankOptionsApiCall) => {
        setBankNameOptions(bankOptionsApiCall);
      })

      combobox.current.fillMasterData("cmv_country", "", "").then((countriesApiCall) => {
        setCountryOptions(countriesApiCall);
      })

      //Employee Salary 
      combobox.current.fillMasterData("cmv_employee_band", "", "").then((employeeBands) => {
        setSalaryBands(employeeBands);
      })

      combobox.current.fillMasterData("cmv_employee_grade", "", "")
        .then((employeeGrades) => {
          setSalaryGrades(employeeGrades);
        })

      //Employee Work Profile
      combobox.current.fillComboBox('DepartmentGroup').then((departmentGroups) => {
        setDepartmentGroupOptions(departmentGroups);
      })

      combobox.current.fillMasterData("cmv_designation", "", "").then((designations) => {
        setDesignationOptions(designations);
      })

      combobox.current.fillMasterData("cmv_employee", "", "").then((reportingToOptions) => {
        setReportingToOptions(reportingToOptions);
      })

      combobox.current.fillMasterData("hmv_weeklyoff", "", "").then((weeklyoffs) => {
        setWeeklyOffOptions(weeklyoffs);
        // Set Default Weekly Off as Sunday.
        setWeeklyOffId(1);    // Using PkID Id
      })

      combobox.current.fillMasterData("cmv_shift", "", "").then((workingShifts) => {
        setShiftOptions(workingShifts);
        setShiftId('8');
      })

      combobox.current.fillMasterData("cmv_contractor", "", "").then((contractorList) => {
        setContractorOption(contractorList);
      })

      combobox.current.fillComboBox("Regions").then((workingRegions) => {
        setRegionOptions(workingRegions);
      })

      combobox.current.fillMasterData("fmv_cost_center", "", "").then((constCenterOptions) => {
        setCostCenterOptions(constCenterOptions);
      })

      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.columns.push("property_value");
      globalQuery.table = "amv_properties"
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'EmployeeTypeGroup' });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

      combobox.current.fillFiltersCombo(globalQuery).then((employeeGroupTypeList) => {
        setEmployeeGroupTypeOptions(employeeGroupTypeList);
      })

      combobox.current.fillComboBox('EmployeeStatus').then((employeeStatusOptionsApiCall) => {
        setEmployeeStatusOptions(employeeStatusOptionsApiCall);
      })

      combobox.current.fillComboBox('EmployeeReference').then((employeeReferencesApiCall) => {
        setEmployeeReferenceOptions(employeeReferencesApiCall);
      })

      combobox.current.fillComboBox('Salutations').then((salutationsApiCall) => {
        setSalutationOptions(salutationsApiCall)
      })

      combobox.current.fillComboBox('Gender').then((gendersApiCall) => {
        setGenderOptions(gendersApiCall);
      })

      combobox.current.fillComboBox('Religion').then((religionsApiCall) => {
        setReligionOptions(religionsApiCall);
      })

      combobox.current.fillComboBox('EmployeeCategory').then((categoriesApiCall) => {
        setCategoryOptions(categoriesApiCall);

      })

      combobox.current.fillComboBox('EmployeeCaste').then((casteOptionsApiCall) => {
        setCasteOptions(casteOptionsApiCall)
      })

      combobox.current.fillComboBox('BloodGroups').then((bloodGroupsApiCall) => {
        setBloodGroupOptions(bloodGroupsApiCall);
      })

      combobox.current.fillComboBox('MaritalStatus').then((maritalStatusApiCall) => {
        setMaritalStatusOptions(maritalStatusApiCall);
      })
    } catch (error) {
      console.log("error : ", error)
      navigate('/Error')
    }
  }

  // Earning And Deduction Section 
  const FnShowEarningAndDeductionRecrd = async () => {
    try {
      const earnHeadDataApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/designation/FnShowEarningAndDeductionRecords/${(COMPANY_ID)}`)
      const { EarningMappingRecords, DeductionMappingRecords } = await earnHeadDataApiCall.json();

      if (employee_id !== 0) {
        await FnCheckUpdateResponce(EarningMappingRecords, DeductionMappingRecords)
      } else {
        setEarningData(EarningMappingRecords)
        setDeductionData(DeductionMappingRecords)
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }


  const FnCheckUpdateResponce = async (earningHeadData, deductionHeadData) => {
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/employees/FnShowSalaryAndWorkProfileRecords/${employee_id}`)
      const apiResponse = await apiCall.json();
      let employee_master_data = apiResponse.EmployeeMasterRecords;
      let employee_workprofile_data = apiResponse.EmployeeWorkprofileRecords;
      let employee_salary_data = apiResponse.EmployeeSalaryRecords;
      let employee_earnings_data = apiResponse.EarningMappingRecords
      let employee_deductions_data = apiResponse.DeductionMappingRecords

      //Setting employee master data
      setEmployee_id(employee_master_data.employee_id);
      setEmployeeCode(employee_master_data.employee_code);
      setEmployeeMachineCode(employee_master_data.machine_employee_code);
      setEmployeeGroupType(employee_master_data.employee_type_group);
      // // Old code
      // await comboBoxesOnChange('Employee_group');
      // setEmployeeType(employee_master_data.employee_type);
      // await comboBoxesOnChange('Employee_type');

      resetGlobalQuery();
      globalQuery.columns = ['field_id', 'field_name', 'property_value', 'property_group', 'properties_master_name'];
      globalQuery.table = "amv_properties"
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "properties_master_name", operator: "IN", values: ['EmployeeType', 'EmployeeTypeGroup'] });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      const propertiesForTypeNdGrp = await combobox.current.fillFiltersCombo(globalQuery)
      const selectedEmplGrp = propertiesForTypeNdGrp.find(prop => prop.properties_master_name === "EmployeeTypeGroup" && prop.field_name === employee_master_data.employee_type_group);
      const employeeTypes = propertiesForTypeNdGrp.filter(prop => prop.properties_master_name === "EmployeeType" && prop.property_group === selectedEmplGrp.property_value);
      setEmployeeTypeOptions(employeeTypes);
      const selectedEmplType = employeeTypes.find(prop => prop.field_name === employee_master_data.employee_type);
      if (selectedEmplType) { setEmployeeType(selectedEmplType.field_id); };


      setEmployeeStatus(employee_master_data.employee_status);
      setDestionationId(employee_master_data.destination_id);
      setReferenceId(employee_master_data.reference);
      setAadharCardNo(employee_master_data.aadhar_card_no);
      setPanNo(employee_master_data.pan_no);
      setPassportNo(employee_master_data.passport_no);
      setDrivingLicence(employee_master_data.driving_licence);
      setBankId1(employee_master_data.bank_id1);
      setAccountNo1(employee_master_data.account_no1);
      setIFSCCode1(employee_master_data.ifsc_code1);
      setBankId2(employee_master_data.bank_id2);
      setAccountNo2(employee_master_data.account_no2);
      setIFSCCode2(employee_master_data.ifsc_code2);
      setFinanceAccountNo(employee_master_data.finance_account_no);

      setSalutation(employee_master_data.salutation);
      setLastName(employee_master_data.last_name);
      setFirstName(employee_master_data.first_name);
      setMiddleName(employee_master_data.middle_name);
      setEmployeeName(employee_master_data.employee_name);
      setDateOfBirth(employee_master_data.date_of_birth);
      setGender(employee_master_data.gender);
      setReligion(employee_master_data.religion);
      setCategoryId(employee_master_data.employee_category);
      setCasteId(employee_master_data.employee_caste);
      setBloodGroupId(employee_master_data.blood_group);
      setMaritalStatus(employee_master_data.marital_status);
      setEmailId1(employee_master_data.email_id1);
      setEmailId2(employee_master_data.email_id2);
      setPhoneNo(employee_master_data.phone_no);
      setCellNo1(employee_master_data.cell_no1);
      setCellNo2(employee_master_data.cell_no2);

      setCurrentAddress(employee_master_data.current_address);
      setCurrentPincode(employee_master_data.current_pincode);
      if (employee_master_data.current_address === employee_master_data.permanant_address) {
        setPermanentAddressChechBox(true)
      }
      setPermanantAddress(employee_master_data.permanant_address);
      setPermanantPincode(employee_master_data.permanant_pincode);
      setCountryId(employee_master_data.country);
      await comboBoxesOnChange("Country")
      setStateId(employee_master_data.state_id);
      await comboBoxesOnChange("State");
      setDistrictId(employee_master_data.district_id);
      await comboBoxesOnChange("District");
      setCityId(employee_master_data.city_id);

      setUserName(employee_master_data.username);
      setPassword(employee_master_data.password);

      switch (employee_master_data.is_active) {
        case true:
          document.querySelector('input[name="employeeIsActive"][value="1"]').checked = true;
          break;
        case false:
          document.querySelector('input[name="employeeIsActive"][value="0"]').checked = true;
          break;
      }

      //Setting employee workprofile data
      setDepartmentGroupId(employee_workprofile_data.department_group_id);
      await comboBoxesOnChange("DepartmentGroup");
      setDepartmentId(employee_workprofile_data.department_id);
      await comboBoxesOnChange("Department");
      setemplWorkPrfileID(employee_workprofile_data.employee_workprofile_id);
      setSubDepartmentGroupId(employee_workprofile_data.subdepartment_group_id);
      setDesignationId(employee_workprofile_data.designation_id);
      setReportingToId(employee_workprofile_data.reporting_to);
      setWeeklyOffId(employee_workprofile_data.weeklyoff);
      setShiftId(employee_workprofile_data.shift_id);
      setDateJoining(employee_workprofile_data.date_joining);
      setDateExist(employee_workprofile_data.date_exit);
      setContractorId(employee_workprofile_data.contractor_id);
      setContractStartDate(employee_workprofile_data.contract_startdate);
      setContractEndDate(employee_workprofile_data.contract_enddate);
      setRegionId(employee_workprofile_data.region_id);
      setCostCenterId(employee_workprofile_data.cost_center_id);
      setBondIsApplicable(employee_workprofile_data.bond_applicable);
      setCurrentJob(employee_workprofile_data.current_job);

      //Setting employee salary data.........
      setSalaryId(employee_salary_data.employee_salary_id);
      setBandId(employee_salary_data.band_id);
      setGradeId(employee_salary_data.grade_id);
      setCTC(employee_salary_data.ctc);
      setSalary(employee_salary_data.salary);
      setOTFlag(employee_salary_data.ot_flag);
      setOTAmmount(employee_salary_data.ot_amount);
      setGratuityIsApplicable(employee_salary_data.gratuity_applicable);
      setPFIsApplicable(employee_salary_data.pf_flag);
      setPFnum(employee_salary_data.pf_no);
      setUANNum(employee_salary_data.uan_no);
      setPFDate(employee_salary_data.pf_date);
      setESICIsApplicable(employee_salary_data.esic_flag);
      setESICNum(employee_salary_data.esic_no);
      setESICDate(employee_salary_data.esic_date);
      setMLWFIsApplicable(employee_salary_data.mlwf_flag);
      setMLWFNum(employee_salary_data.mlwf_no);
      // Now Enable the Next tab button;
      document.getElementById('nxtBtn').disabled = false;

      updateEarningRecords(earningHeadData, employee_earnings_data)
      updateDeductionRecords(deductionHeadData, employee_deductions_data)
    } catch (error) {
      console.log("error", error)
      navigate('/Error')
    }
  }

  // Fill the combo boxes from property table.
  const comboBoxesOnChange = async (key) => {
    try {
      switch (key) {
        case 'Employee_group':
          var selectElement = document.getElementById('cmb_employee_group_type');
          var selectedOption = selectElement.options[selectElement.selectedIndex];
          var propertyValue = selectedOption.getAttribute('property_value');
          setEmployeeGroupType(selectElement.value)
          if (selectElement.value !== '') {
            $('#error_cmb_employee_group_type').hide();
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("property_value");
            globalQuery.table = "amv_properties"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "property_group", operator: "=", value: propertyValue });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'EmployeeType' });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

            const employeeGroupTypeResponse = await combobox.current.fillFiltersCombo(globalQuery)
            setEmployeeTypeOptions(employeeGroupTypeResponse)

            setEmployeeType('')
          } else {
            setEmployeeTypeOptions([]);
            setEmployeeType();
          }
          break;

        case 'Employee_type':
          var empTypeElement = document.getElementById('cmb_employee_type');
          var empTypeSelectedOption = empTypeElement.options[empTypeElement.selectedIndex];
          var empTypePropertyValue = empTypeSelectedOption.getAttribute('property_value');
          setEmployeeType(empTypeElement.value)

          if (empTypeElement.value !== '') {
            if (employee_id === 0 || employee_id === undefined || employee_id === null) {
              await FnGenerateEmployeeCode(empTypeSelectedOption.text, empTypePropertyValue);
            }
          }

          break;

        case 'Country':
          const getCountryId = document.getElementById('cmb_country_id').value;
          setCountryId(getCountryId)
          if (getCountryId !== '') {
            $('#error_countryId').hide();
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name']
            globalQuery.table = "cmv_state"
            globalQuery.conditions.push({ field: "country_id", operator: "=", value: getCountryId });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            const stateList = await combobox.current.fillFiltersCombo(globalQuery)
            setStateOptions(stateList)
            setStateId('')
            setDistrictOptions([])
            setCityOptions([])
            setDistrictId('')
            setCityId('')
          } else {
            setStateOptions([]);
            setDistrictOptions([])
            setCityOptions([]);
            setStateId('')
            setDistrictId('');
            setCityId('');
          }
          break;
        case 'State':
          const getStateId = document.getElementById('cmb_state_id').value;
          setStateId(getStateId)
          if (getStateId !== '') {
            $('#error_stateId').hide();
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name']
            globalQuery.table = "cmv_district"
            globalQuery.conditions.push({ field: "state_id", operator: "=", value: getStateId });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });

            const districtList = await combobox.current.fillFiltersCombo(globalQuery)
            setDistrictOptions(districtList)
            setDistrictId('')
            setCityId('')
            setCityOptions([])
          } else {
            setDistrictOptions([])
            setCityOptions([]);
            setDistrictId('');
            setCityId('')
          }
          break;
        case 'District':
          const getDistrictId = document.getElementById('cmb_district_id').value;
          setDistrictId(getDistrictId)
          if (getDistrictId === "") {
            setCityOptions([])
            setCityId('')
          } else {
            $('#error_districtId').hide();
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'city_pincode'];
            globalQuery.table = "cmv_city"
            globalQuery.conditions.push({ field: "district_id", operator: "=", value: getDistrictId });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            const cityList = await combobox.current.fillFiltersCombo(globalQuery)

            setCityOptions([])
            setCityOptions(cityList)
            setCityId('')
          }
          break;
        case 'City':
          const propertyValCity = document.getElementById('cmb_city_id').value;
          if (propertyValCity === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('City')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(0).css("padding-top", "0px");
            }, 100)
          } else {
            setCityId(propertyValCity)
            const selectedCity = cityOptions.find(city => city.field_id === parseInt(propertyValCity));
            setCurrentPincode(selectedCity.city_pincode);
            $('#error_cityId').hide();
          }
          break;

        case 'EmployeeDestination':
          let EmployeeDestinationval = document.getElementById('cmb_destination_id').value.trim();
          setStateId(EmployeeDestinationval)
          if (EmployeeDestinationval === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Employee Destination')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(0).css("padding-top", "0px");
            }, 100)
          }
          break;

        case 'PrimaryBankName':
          let PrimaryBankNameval = document.getElementById('cmb_bank_id_1').value.trim();
          setStateId(PrimaryBankNameval)
          if (PrimaryBankNameval === '0') {
            const newTab = window.open('/Masters/MBanks/FrmBank/FrmMBankEntry', '_blank');
            if (newTab) {
              newTab.focus();
            }
          }

          break;

        case 'SecondaryBankName':
          let SecondaryBankNameval = document.getElementById('cmb_bank_id_2').value.trim();
          setStateId(SecondaryBankNameval)
          if (SecondaryBankNameval === '0') {
            const newTab = window.open('/Masters/MBanks/FrmBank/FrmMBankEntry', '_blank');
            if (newTab) {
              newTab.focus();
            }
          }

          break;

        case 'DepartmentGroup':
          const departmentGrp = document.getElementById('cmb_department_group_id').value;
          var selectedGroup = document.querySelector("#cmb_department_group_id");
          var selectedGroupFieldName = selectedGroup.options[selectedGroup.selectedIndex].getAttribute('fieldName');
          if (departmentGrp !== "") {
            setDepartmentGroupId(departmentGrp);
            $('#error_department_group_id').hide();
            try {
              resetGlobalQuery();
              globalQuery.columns.push("field_id");
              globalQuery.columns.push("field_name");
              globalQuery.conditions.push({
                field: "department_group",
                operator: "=",
                value: selectedGroupFieldName
              });
              globalQuery.conditions.push({
                field: "department_type",
                operator: "=",
                value: "M"
              });
              globalQuery.table = "cmv_department";

              var deptOptions = await combobox.current.fillFiltersCombo(globalQuery);
              setDepartmentOptions(deptOptions);
              setSubDepartmentGroupOptions([]);
              setDepartmentId('');
              setSubDepartmentGroupId('');
            } catch (error) {
              console.log('Error: ' + error);
            }
          } else {
            setDepartmentOptions([]);
            setSubDepartmentGroupOptions([]);
            setDepartmentId('');
            setSubDepartmentGroupId('');
          }
          break;

        case 'Department':
          const departmentId = document.getElementById('cmb_department_id').value;
          if (departmentId === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Department')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(0).css("padding-top", "0px");
            }, 100)
          }


          if (departmentId !== "") {
            setDepartmentId(departmentId);
            $('#error_department_group_id').hide();
            try {
              resetGlobalQuery();
              globalQuery.columns.push("field_id");
              globalQuery.columns.push("field_name");
              globalQuery.conditions.push({
                field: "parent_department_id",
                operator: "=",
                value: departmentId
              });
              globalQuery.conditions.push({
                field: "department_type",
                operator: "=",
                value: "S"
              });
              globalQuery.table = "cmv_department";
              var subDeptOptions = await combobox.current.fillFiltersCombo(globalQuery);
              setSubDepartmentGroupOptions(subDeptOptions);
              setSubDepartmentGroupId('');
            } catch (error) {
              console.log('Error: ' + error);
            }
          } else {
            setSubDepartmentGroupOptions([]);
            setSubDepartmentGroupId('');
          }
          break;


        case 'Contractor':
          const contractorId = document.getElementById('cmb_contractor_id').value;
          if (contractorId === '0') {
            const newTab = window.open('/Masters/Contractor', '_blank');
            if (newTab) {
              newTab.focus();
            }
          }
          else if (contractorId === "001") {
            document.getElementById("dt_contract_start_date").setAttribute("optional", "optional");
            setContractStartDate('');
            document.getElementById("dt_contract_end_date").setAttribute("optional", "optional");
            setContractEndDate('');

          } else {
            document.getElementById("dt_contract_start_date").removeAttribute("optional");
            document.getElementById("dt_contract_end_date").removeAttribute("optional");
          }
          break;

        case 'SubDepartment':
          const SubDepartmentval = document.getElementById('cmb_subdepartment_group_id').value;
          if (SubDepartmentval === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Sub Department')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(0).css("padding-top", "0px");
            }, 100)
          }
          break;

        case 'Designation':
          const Designationval = document.getElementById('cmb_designation_id').value;
          if (Designationval === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            // setHeaderName('Designation');  setShowAddRecModal(true) // Commented because it will the tab form not opened into modal.
            // setTimeout(() => {
            //   $(".erp_top_Form").eq(0).css("padding-top", "0px");
            // }, 100)
            const newTab = window.open('/Masters/Designation', '_blank');
            if (newTab) { newTab.focus(); }
          }
          break;

        case 'Shift':
          const ShiftVal = document.getElementById('cmb_shift_id').value;

          if (ShiftVal === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Shift')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(0).css("padding-top", "0px");
            }, 100)
          }
          break;

        case 'Reportingto':
          const ReportingtoVal = document.getElementById('cmb_reproting_to_id').value;

          if (ReportingtoVal === '0') {
            const newTab = window.open('/Masters/Employees', '_blank');
            if (newTab) {
              newTab.focus();
            }
          }

          break;


        case 'CostCenter':
          const CostCenterVal = document.getElementById('cmb_cost_center_id').value;

          if (CostCenterVal === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Cost Center')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").css("padding-top", "0px");
            }, 100)
          }
          break;
      }

    } catch (error) {
      console.log("error : ", error)
      navigate('/Error')
    }
  }


  const inputBoxesOnChange = async (key) => {
    switch (key) {
      case 'EmployeeName':
        const salutation = document.getElementById('cmb_salutation').value.trim();
        const lastName = document.getElementById('txt_last_name').value.trim();
        const firstName = document.getElementById('txt_first_name').value.trim();
        const middleName = document.getElementById('txt_middle_name').value.trim();
        setEmployeeName(salutation + " " + lastName + " " + firstName + " " + middleName);
        document.getElementById('txt_employee_name').disabled = true;
        break;
    }
  }

  //case no. 1 chnges by ujjwala 10/1/2024 Start
  const validateEmployeeForm = async () => {
    if (await frmValidation.current.validateForm('employeeFormId') === true) {
      if (await frmValidation.current.validateForm('employeeWorkProfFormId') === true) {
        if (await frmValidation.current.validateForm('employeeSalaryInfoFormId') === true) {

          // let selectedEarningHead = $('#earningHead-table tbody tr .selectEarning:checked')
          // let selectedDuductionHead = $('#deductionHead-table tbody tr .selectDeduction:checked')

          // let EarningHead = true;
          // if (selectedEarningHead.length === 0 && selectedDuductionHead.length === 0) {
          //   setErrMsg('Please Select Atleast One Earning Head...!');
          //   setShowErrorMsgModal(true);
          //   return EarningHead = false;
          // } else {
          //   selectedEarningHead.each(function () {
          //     let currentTblRow = $(this.parentElement.parentElement)
          //     let calculatedValue = currentTblRow.find('input[id^="calculation_value_"]').val();
          //     let earningFormula = currentTblRow.find('input[id^="formula_"]').val();

          //     if (calculatedValue === '') {
          //       $(currentTblRow.find('input[id^="calculation_value_"]'))[0].parentElement.dataset.tip = 'please fill this field...!';
          //       $(currentTblRow.find('input[id^="calculation_value_"]'))[0].focus();
          //       return EarningHead = false;

          //     } else if (earningFormula === '' || earningFormula === null || earningFormula === undefined) {
          //       $(currentTblRow.find('input[id^="formula_"]'))[0].parentElement.dataset.tip = 'please fill this field...!';
          //       $(currentTblRow.find('input[id^="formula_"]'))[0].focus();
          //       return EarningHead = false;
          //     }

          //   });
          //   selectedDuductionHead.each(function () {
          //     let currentTblRow = $(this.parentElement.parentElement)
          //     let calculatedValue = currentTblRow.find('input[id^="calculation_value_"]').val();
          //     let earningFormula = currentTblRow.find('input[id^="formula_"]').val();

          //     if (calculatedValue === '') {
          //       $(currentTblRow.find('input[id^="calculation_value_"]'))[0].parentElement.dataset.tip = 'please fill this field...!';
          //       $(currentTblRow.find('input[id^="calculation_value_"]'))[0].focus();
          //       return EarningHead = false;

          //     } else if (earningFormula === '' || earningFormula === null || earningFormula === undefined) {
          //       $(currentTblRow.find('input[id^="formula_"]'))[0].parentElement.dataset.tip = 'please fill this field...!';
          //       $(currentTblRow.find('input[id^="formula_"]'))[0].focus();
          //       return EarningHead = false;
          //     }

          //   });
          //   return EarningHead;
          // }
          return true;
        }
      }
    }
  }

  const validateEmpInfoFields = () => {
    frmValidation.current.validateFieldsOnChange('employeeFormId')
  }

  const validateEmpWorkProfFields = () => {
    frmValidation.current.validateFieldsOnChange('employeeWorkProfFormId')
  }

  const validateEmpSalaryFields = () => {
    frmValidation.current.validateFieldsOnChange('employeeSalaryInfoFormId')
  }


  //end
  const validateErrorMsgsNumericInput = (event) => {
    var regexNoBank = /^[A-Za-z0-9]+$/;
    const regexNo = /^[0-9\b]+$/;
    var regexPassport = /^[A-Za-z0-9]+$/;
    const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;

    const value = event.target.value
    switch (event.target.id) {
      case 'txt_aadhar_card_no':
        if (regexNo.test(value) || value === '') {
          setAadharCardNo(value);
        }
        break;
      case 'txt_permanant_pincode':
        if (regexNo.test(value) || value === '') {
          setPermanantPincode(value);
        }
        break;
      case 'txt_current_pincode':
        if (regexNo.test(value) || value === '') {
          setCurrentPincode(value);
        }
        break;
      case 'txt_phone_no':
        if (regexNo.test(value) || value === '') {
          setPhoneNo(value);
        }
        break;
      case 'txt_cell_no1':
        if (regexNo.test(value) || value === '') {
          setCellNo1(value);
          setUserName(value);
        }
        break;
      case 'txt_cell_no2':
        if (regexNo.test(value) || value === '') {
          setCellNo2(value);
        }
        break;
      case 'txt_account_no1':
        if (regexNoBank.test(value) || value === '') {
          setAccountNo1(value);
        }
        break;
      case 'txt_account_no2':
        if (regexNoBank.test(value) || value === '') {
          setAccountNo2(value);
        }
        break;
      case 'finance_account_no':
        if (regexNoBank.test(value) || value === '') {
          setFinanceAccountNo(value);
        }
        break;
      case 'txt_passport_no':
        if (regexPassport.test(value) || value === '') {
          setPassportNo(value);
        }
        break;
      case 'txt_email_id1':
        if (emailRegex.test(value) || value === '') {
          setEmailId1(value);
        }
        break;
      case 'txt_email_id2':
        if (emailRegex.test(value) || value === '') {
          setEmailId2(value);
        }
        break;
    }

  }

  const handleSubmit = async () => {
    setIsLoading(true)
    const checkIsValidate = await validateEmployeeForm();
    if (checkIsValidate === true) {
      try {
        let json = {
          'EmployeeEarningsData': [], 'EmployeeDeductionData': [],
          'EmployeeMasterData': {}, 'EmployeeWorkProfileData': {}, 'EmployeeSalaryData': {}, 'commonIds': {
            'company_id': COMPANY_ID, 'company_branch_id': parseInt(COMPANY_BRANCH_ID), 'employee_id': employee_id, 'userName': UserName, 'designation_id': cmb_designation_id
          }
        }

        //Employee Master Data.............
        let activeValue;
        activeValue = document.querySelector('input[name=employeeIsActive]:checked').value

        switch (activeValue) {
          case '0': activeValue = false; break;
          case '1': activeValue = true; break;
        }

        const emp_master_data = {
          company_id: COMPANY_ID,
          company_branch_id: COMPANY_BRANCH_ID,
          employee_id: employee_id,
          created_by: UserName,
          modified_by: employee_id === null ? null : UserName,
          employee_code: txt_employee_code,
          machine_employee_code: txt_employee_machine_code === null || txt_employee_machine_code === '' ? txt_employee_code : txt_employee_machine_code,
          employee_type_group: cmb_employee_group_type,
          employee_type: $('#cmb_employee_type option:selected').text(),
          salutation: cmb_salutation,
          last_name: txt_last_name,
          first_name: txt_first_name,
          middle_name: txt_middle_name,
          employee_name: txt_employee_name,
          aadhar_card_no: txt_aadhar_card_no,
          passport_no: txt_passport_no,
          pan_no: txt_pan_no,
          current_address: txt_current_address,
          current_pincode: txt_current_pincode,
          city_id: cmb_city_id,
          district_id: cmb_district_id,
          state_id: cmb_state_id,
          country: $('#cmb_country_id option:selected').attr('countryname'),
          permanant_address: txt_permanant_address,
          permanant_pincode: txt_permanant_pincode,
          date_of_birth: dt_date_of_birth,
          email_id1: txt_email_id1,
          email_id2: txt_email_id2,
          phone_no: txt_phone_no,
          cell_no1: txt_cell_no1,
          cell_no2: txt_cell_no2,
          bank_id1: cmb_bank_id_1,
          account_no1: txt_account_no1,
          ifsc_code1: txt_ifsc_code1,
          bank_id2: cmb_bank_id_2,
          account_no2: txt_account_no2,
          ifsc_code2: txt_ifsc_code2,
          marital_status: cmb_marital_status,
          reference: cmb_reference_id,
          destination_id: cmb_destination_id,
          religion: cmb_religion,
          category: cmb_category_id,
          caste: cmb_caste_id,
          gender: cmb_gender,
          blood_group: cmb_blood_group,
          driving_licence: txt_driving_licence,
          finance_account_no: finance_account_no === null || finance_account_no === '' ? txt_employee_code : finance_account_no,
          employee_status: cmb_employee_status,
          username: txt_username,
          password: txt_password,
          is_active: activeValue
        };
        json.EmployeeMasterData = emp_master_data;

        //Employee Workprofile Data.........
        const emp_workprof_data = {
          company_id: COMPANY_ID,
          company_branch_id: COMPANY_BRANCH_ID,
          employee_id: employee_id,
          employee_workprofile_id: emplWorkPrfileID,
          department_group_id: cmb_department_group_id,
          department_id: cmb_department_id,
          subdepartment_group_id: cmb_subdepartment_group_id,
          designation_id: cmb_designation_id,
          reporting_to: cmb_reproting_to_id,
          weeklyoff: cmb_weeklyoff_id,
          shift_id: cmb_shift_id,
          date_joining: dt_date_joining,
          date_exit: dt_date_exit,
          contractor_id: cmb_contractor_id,
          contract_startdate: dt_contract_start_date,
          contract_enddate: dt_contract_end_date,
          region_id: cmb_region_id,
          cost_center_id: cmb_cost_center_id,
          bond_applicable: bondIsApplicable,
          current_job: txt_current_job
        };
        json.EmployeeWorkProfileData = emp_workprof_data;

        //Employee salary Data
        const emp_salary_data = {
          company_id: COMPANY_ID,
          company_branch_id: COMPANY_BRANCH_ID,
          employee_salary_id: salaryId,
          employee_id: employee_id,
          grade_id: cmb_grade_id,
          band_id: cmb_band_id,
          ctc: txt_ctc,
          salary: txt_salary,
          ot_flag: chk_ot_flag,
          ot_amount: txt_ot_amount,
          gratuity_applicable: chk_gratuity_applicable,
          pf_flag: chk_pf_flag,
          pf_no: txt_pf_no,
          uan_no: txt_uan_no,
          pf_date: dt_pf_date,
          esic_flag: chk_esic_flag,
          esic_no: txt_esic_no,
          esic_date: dt_esic_date,
          mlwf_flag: chk_mlwf_flag,
          mlwf_no: txt_mlwf_no
        };
        json.EmployeeSalaryData = emp_salary_data;

        //Earning And Deduction data
        $("input:checkbox[name=selectEarning]:checked").each(function () {
          let findEarningData = earningData.find(item => item.earning_heads_id === parseInt($(this).val()));

          const earningDatas = {
            company_id: COMPANY_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            earning_code: findEarningData.earning_code,
            earning_heads_id: findEarningData.earning_heads_id,
            earning_head_short_name: findEarningData.earning_head_short_name,
            earning_type: findEarningData.earning_type,
            calculation_type: findEarningData.calculation_type,
            calculation_value: findEarningData.calculation_value,
            effective_date: findEarningData.effective_date,
            formula: findEarningData.formula,
            effective_date: effective_date,
            designation_id: cmb_designation_id,
            created_by: UserName,

          }
          json.EmployeeEarningsData.push(earningDatas);
        });

        $("input:checkbox[name=selectDeduction]:checked").each(function () {
          let findDiductionData = deductionData.find(item => item.deduction_heads_id === parseInt($(this).val()));

          const deductionDatas = {
            company_id: COMPANY_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            deduction_code: findDiductionData.deduction_code,
            deduction_heads_id: findDiductionData.deduction_heads_id,
            deduction_head_short_name: findDiductionData.deduction_head_short_name,
            deduction_type: findDiductionData.deduction_type,
            calculation_type: findDiductionData.calculation_type,
            effective_date: findDiductionData.effective_date,
            calculation_value: findDiductionData.calculation_value,
            formula: findDiductionData.formula,
            effective_date: effective_date,
            designation_id: cmb_designation_id,
            created_by: UserName,

          }
          json.EmployeeDeductionData.push(deductionDatas);
        });

        const formData = new FormData();
        formData.append(`EmployeeServiceData`, JSON.stringify(json))
        const requestOptions = {
          method: 'POST',
          body: formData
        };

        try {
          const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/employees/FnAddUpdateRecord`, requestOptions)
          const apiResponse = await apiCall.json();

          if (apiResponse.success === 1) {
            const evitCache = combobox.current.evitCache();
            console.log(evitCache);
            setEmployee_id(apiResponse.data.employee_id);
            setIsLoading(false);
            setSuccMsg(apiResponse.message);
            setShowSuccessMsgModal(true);
            // Now Enable the Next tab button;
            document.getElementById('nxtBtn').disabled = false;
          } else if (apiResponse.success === 0) {
            console.log("response error: ", apiResponse.error);
            setIsLoading(false);
            setErrMsg(apiResponse.error)
            setShowErrorMsgModal(true);

          }
        } catch (error) {
          setIsLoading(false);
          console.log("error", error)
          navigate('/Error')
        }

      } catch (error) {
        setIsLoading(false);
        console.log("error", error)
        navigate('/Error')
      }
    } else {
      setIsLoading(false);
    }
  };
  //case no. 4 chnges by ujjwala 10/1/2024 Start
  const validationfornext = async () => {
    if (employee_id !== 0) {
      props.goToNext(employee_id, keyForViewUpdate, cmb_designation_id, txt_employee_code, txt_employee_name);
    }

  }


  const FnRefreshbtn = async (key) => {
    switch (key) {

      case 'PrimaryBank':
        combobox.current.fillMasterData("cmv_banks_List", "", "").then((bankOptionsApiCall1) => {
          setBankNameOptions(bankOptionsApiCall1);
        });
        break;
      case 'SecondaryBank':
        combobox.current.fillMasterData("cmv_banks_List", "", "").then((bankOptionsApiCall2) => {
          setBankNameOptions(bankOptionsApiCall2);
        });
        break;
      case 'Designation':
        combobox.current.fillMasterData("cmv_designation", "", "").then((designations) => {
          setDesignationOptions(designations);
        });
        break;
      default:
        break;

    }
  }

  //Employee Salary Starts here..........
  const validateNumericInput = (event, key) => {

    const regexNo = /^\d+(\.\d+)?$/;
    const inputBoxValue = event.target.value;
    switch (key) {
      case 'CTC':
        if (regexNo.test(inputBoxValue) && event.target.maxLength >= event.target.value.length) {
          setCTC(inputBoxValue);
        } else if (inputBoxValue === '') {
          setCTC(inputBoxValue);
        }
        break;
      case 'Salary':
        if (regexNo.test(inputBoxValue) && event.target.maxLength >= event.target.value.length) {
          setSalary(inputBoxValue);
        } else if (inputBoxValue === '') {
          setSalary(inputBoxValue);
        }
        break;
      case 'OTAmount':
        if (regexNo.test(inputBoxValue) && event.target.maxLength >= event.target.value.length) {
          setOTAmmount(inputBoxValue);
        } else if (inputBoxValue === '') {
          setOTAmmount(inputBoxValue);
        }
        break;
    }
  }


  const onRadioBtnChange = async (key) => {
    try {
      switch (key) {
        case 'OTApplicable':  // onRadioBtnChange("OTApplicable")
          var OTIsApplicable = document.querySelector('input[name="chk_ot_flag"]:checked').value;
          if (OTIsApplicable === 'true') {
            document.getElementById("txt_ot_amount").removeAttribute("optional");
          } else {
            document.getElementById("txt_ot_amount").setAttribute("optional", "optional");
          }
          break;

        case 'PFApplicable':   // onRadioBtnChange("PFApplicable")
          var PFIsApplicable = document.querySelector('input[name="chk_pf_flag"]:checked').value;
          if (PFIsApplicable === 'true') {
            document.getElementById("txt_pf_no").removeAttribute("optional");
            document.getElementById("txt_uan_no").removeAttribute("optional");
            document.getElementById("dt_pf_date").removeAttribute("optional");
          } else {
            document.getElementById("txt_pf_no").setAttribute("optional", "optional");
            document.getElementById("txt_uan_no").setAttribute("optional", "optional");
            document.getElementById("dt_pf_date").setAttribute("optional", "optional");
          }
          break;

        case 'ESICApplicable':   // onRadioBtnChange("ESICApplicable")
          var ESICIsApplicable = document.querySelector('input[name="chk_esic_flag"]:checked').value;
          if (ESICIsApplicable === 'true') {
            document.getElementById("txt_esic_no").removeAttribute("optional");
            document.getElementById("dt_esic_date").removeAttribute("optional");
          } else {
            document.getElementById("txt_esic_no").setAttribute("optional", "optional");
            document.getElementById("dt_esic_date").setAttribute("optional", "optional");
          }
          break;

        case 'MLWFApplicable':  // onRadioBtnChange("MLWFApplicable")
          var MLWFIsApplicable = document.querySelector('input[name="chk_mlwf_flag"]:checked').value;
          if (MLWFIsApplicable === 'true') {
            document.getElementById("txt_mlwf_no").removeAttribute("optional");
          } else {
            document.getElementById("txt_mlwf_no").setAttribute("optional", "optional");
          }
          break;
      }
    } catch (error) {
      console.log('error: ' + error)
      navigate('/Error')
    }
  }

  //Validations for Employee Info 
  const validateDOB = (dateofbirth) => {
    var dateOfBirth = dateofbirth;
    var dobDate = new Date(dateOfBirth);
    var today = new Date();
    var minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

    if (dobDate > minDate) {
      $('#error_dt_date_of_birth').css('display', 'block').text('You must be at least 18 years old.');
    } else {
      $('#error_dt_date_of_birth').hide();
    }
  }

  const validateTelePhone = (telephnno) => {
    var input = telephnno;
    var regex = /^(?:\d{11,15}\s?)/;

    if (regex.test(input) && input.length <= 15) {
      $('#error_txt_phone_no').hide();
    } else {
      $('#error_txt_phone_no').css('display', 'block').text('Please Enter Valid phone number.');
    }
  }

  const validateCheckBox = () => {
    let value = $('#permt_add_chechbox').prop('checked');
    if (value) {
      setPermanentAddressChechBox(true)
      setPermanantAddress(txt_current_address)
      setPermanantPincode(txt_current_pincode)
    } else {
      setPermanentAddressChechBox(false)
      setPermanantAddress('')
      setPermanantPincode('')
    }
  }

  const FnGenerateEmployeeCode = async (employeeType, property_value) => {
    const autoNoApiCall = await generateMaterialIdAPiCall.current.GenerateCode("cm_employee", "employee_code", "employee_type", employeeType, property_value, "4");
    setEmployeeCode(autoNoApiCall);
    setEmployeeMachineCode(autoNoApiCall);
    return autoNoApiCall;
  }

  // This useLayoutEffect render only when cmb_employee_type or cmb_designation_id will change
  useLayoutEffect(() => {
    if (EARNING_DEDUCTION_MAPPING_BASE === 'DW' && keyForViewUpdate === 'Add') {
      FnfillEmployeeTypeOrDesignationEarningAndDeductionRecord(earningData, deductionData, cmb_designation_id)
    } else if (EARNING_DEDUCTION_MAPPING_BASE === 'ETW' && keyForViewUpdate === 'Add') {
      FnfillEmployeeTypeOrDesignationEarningAndDeductionRecord(earningData, deductionData, cmb_employee_type)
    }
  }, [cmb_employee_type, cmb_designation_id])

  const FnfillEmployeeTypeOrDesignationEarningAndDeductionRecord = async (earningHeadData, deductionHeadData, common_id) => {
    try {
      // uncheck all checkboxes of earning & deduction heads 
      $('.selectEarning').prop('checked', false);
      $('.selectDeduction').prop('checked', false);

      if (common_id !== '' && common_id !== '0') {
        const UpadateEmployeeTypeAndDesignationEarningAndDeductionRecordApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/employees/GetDataFromDesignationOrEmplyeeType/${COMPANY_ID}/${common_id}?earning_deduction_mapping_baseKey=${EARNING_DEDUCTION_MAPPING_BASE}`);
        const { ExistingEarningRecords, ExistingDeductionRecords } = await UpadateEmployeeTypeAndDesignationEarningAndDeductionRecordApiCall.json();

        updateEarningRecords(earningHeadData, ExistingEarningRecords)
        updateDeductionRecords(deductionHeadData, ExistingDeductionRecords)
      }
    } catch (error) {
      console.log("error: ", error)
    }

  }

  const updateEarningRecords = (earningHeadData, ExistingEarningRecords) => {
    // Set earning head formula & calculation value
    if (ExistingEarningRecords.length !== 0) {
      let updatedEarningHeadData = earningHeadData.map((rowData) => {
        const correspondingMapRecord = ExistingEarningRecords.find((mapRecord) => mapRecord.earning_heads_id === rowData.earning_heads_id);
        if (correspondingMapRecord) {
          // If a matching mapRecord is found, update calculation_value and formula
          return {
            ...rowData,
            calculation_value: correspondingMapRecord.calculation_value,
            formula: correspondingMapRecord.formula, // Replace with the appropriate field from mapRecord
            effective_date: correspondingMapRecord.effective_date,
          };
        }

        // If no matching mapRecord is found, return the original rowData
        return rowData;
      });
      setEarningData(updatedEarningHeadData)

      ExistingEarningRecords.forEach(function (earningHead) {
        $('#selectEarning_' + earningHead.earning_heads_id).prop('checked', true);
      });
      setEffectiveDate(ExistingEarningRecords[0].effective_date)
    } else {
      setEarningData(earningHeadData)
    }
  };

  const updateDeductionRecords = (deductionHeadData, ExistingDeductionRecords) => {
    // Set deduction head formula & calculation value
    if (ExistingDeductionRecords.length !== 0) {
      let updatedDeductionHeadData = deductionHeadData.map((rowData) => {
        const correspondingMapRecord = ExistingDeductionRecords.find((mapRecord) => mapRecord.deduction_heads_id === rowData.deduction_heads_id);

        if (correspondingMapRecord) {
          // If a matching mapRecord is found, update calculation_value and formula
          return {
            ...rowData,
            calculation_value: correspondingMapRecord.calculation_value,
            formula: correspondingMapRecord.formula, // Replace with the appropriate field from mapRecord
            effective_date: correspondingMapRecord.effective_date,
          };
        }

        return rowData;
      });

      setDeductionData(updatedDeductionHeadData)

      ExistingDeductionRecords.forEach(function (existingDeduction) {
        $('#selectDeduction_' + existingDeduction.deduction_heads_id).prop('checked', true);
      });
      setEffectiveDate(ExistingDeductionRecords[0].effective_date)
    } else {
      setDeductionData(deductionHeadData)
    }

  }

  function handleEarningCheckbox(id) {
    $('input:checkbox[name="selectEarning"][value="' + id + '"]').attr('checked', false);
    const totalChkBoxes = document.querySelectorAll('input[name=selectEarning]').length;
    const totalChkBoxesChked = document.querySelectorAll('input[name=selectEarning]:checked').length;
    if (totalChkBoxes == totalChkBoxesChked) {
      document.getElementById('selectAllEarning').checked = true;
    } else if (totalChkBoxes > totalChkBoxesChked) {
      document.getElementById('selectAllEarning').checked = false;
    }
  }
  function handleDeductionCheckbox(id) {
    $('input:checkbox[name="selectDeduction"][value="' + id + '"]').attr('checked', false);
    const totalChkBoxes = document.querySelectorAll('input[name=selectDeduction]').length;
    const totalChkBoxesChked = document.querySelectorAll('input[name=selectDeduction]:checked').length;
    if (totalChkBoxes == totalChkBoxesChked) {

      document.getElementById('selectAllDeduction').checked = true;
    } else if (totalChkBoxes > totalChkBoxesChked) {
      ;
      document.getElementById('selectAllDeduction').checked = false;
    }
  }

  const updatePmtTermsTblData = (currentPaymentTerm, e) => {
    let clickedColName = e.target.getAttribute('Headers');
    delete e.target.parentElement.dataset.tip;

    switch (clickedColName) {

      case 'earning_head_value':
        let isValidPercentageInput = validateNumberPercentInput.current.percentValidate(JSON.stringify(e.target.value));
        if (isValidPercentageInput === true) {
          currentPaymentTerm[clickedColName] = e.target.value
          delete e.target.parentElement.dataset.tip;
        }
        break;
      case 'calculation_value':
        let isValiddecimalNumberInput = validateNumberPercentInput.current.decimalNumber(JSON.stringify(e.target.value), 2);
        currentPaymentTerm[clickedColName] = isValiddecimalNumberInput
        delete e.target.parentElement.dataset.tip;

        break;
      case 'formula':
        currentPaymentTerm[clickedColName] = e.target.value
        break;
      default:
        break;
    }

    // update the Payment terms table data.
    const updateEarningData = [...earningData]
    const pmtTermIndexInArray = parseInt(e.target.parentElement.parentElement.getAttribute('rowIndex'))
    updateEarningData[pmtTermIndexInArray] = currentPaymentTerm;
    setEarningData(updateEarningData);

  }


  const updateDeductionTblData = (currentPaymentTerm, e) => {
    let clickedColName = e.target.getAttribute('Headers');
    delete e.target.parentElement.dataset.tip;

    switch (clickedColName) {

      case 'earning_head_value':
        let isValidPercentageInput = validateNumberPercentInput.current.percentValidate(JSON.stringify(e.target.value));
        if (isValidPercentageInput === true) {
          currentPaymentTerm[clickedColName] = e.target.value
          delete e.target.parentElement.dataset.tip;
        } else {
          const currentTblRow = e.target.parentElement.parentElement;

        }
        break;
      case 'calculation_value':
        let isValiddecimalNumberInput = validateNumberPercentInput.current.decimalNumber(JSON.stringify(e.target.value), 2);
        currentPaymentTerm[clickedColName] = isValiddecimalNumberInput
        break;
      case 'formula':
        currentPaymentTerm[clickedColName] = e.target.value
        break;
      default:
        break;
    }
    // update the Payment terms table data.
    const updateDeductionData = [...deductionData]
    const pmtTermIndexInArray = parseInt(e.target.parentElement.parentElement.getAttribute('rowIndex'))
    updateDeductionData[pmtTermIndexInArray] = currentPaymentTerm;
    setDeductionData(updateDeductionData);

  }

  //function to check All checkBoxes of po terms and payment terms
  function toggleChkAllBoxes(key) {
    switch (key) {
      case "selectAllEarning":
        $('.selectEarning').prop('checked', $('#selectAllEarning').is(":checked"));
        break;
      case 'PartiallyEarningSelection':
        $('#selectAllEarning').prop('checked', $('input:checkbox.selectEarning:checked').length == $('input:checkbox.selectEarning').length);
        break;
      // For customer contact
      case 'selectAllDeduction':
        $('.selectDeduction').prop('checked', $('#selectAllDeduction').is(":checked"));
        break;
      case 'PartiallyDeductionSelection':
        $('#selectAllDeduction').prop('checked', $('input:checkbox.selectDeduction:checked').length === $('input:checkbox.selectDeduction').length);
        break;
    }
  }

  function handleEarningCheckbox(id) {
    $('input:checkbox[name="selectEarning"][value="' + id + '"]').attr('checked', false);
    const totalChkBoxes = document.querySelectorAll('input[name=selectEarning]').length;
    const totalChkBoxesChked = document.querySelectorAll('input[name=selectEarning]:checked').length;
    if (totalChkBoxes == totalChkBoxesChked) {

      document.getElementById('selectAllEarning').checked = true;
    } else if (totalChkBoxes > totalChkBoxesChked) {
      ;
      document.getElementById('selectAllEarning').checked = false;
    }
  }
  function handleDeductionCheckbox(id) {
    $('input:checkbox[name="selectDeduction"][value="' + id + '"]').attr('checked', false);
    const totalChkBoxes = document.querySelectorAll('input[name=selectDeduction]').length;
    const totalChkBoxesChked = document.querySelectorAll('input[name=selectDeduction]:checked').length;
    if (totalChkBoxes == totalChkBoxesChked) {

      document.getElementById('selectAllDeduction').checked = true;
    } else if (totalChkBoxes > totalChkBoxesChked) {
      ;
      document.getElementById('selectAllDeduction').checked = false;
    }
  }

  //Fn for render payment terms static table 
  const renderEarningTable = useMemo(() => {
    return <>
      {earningData.length !== 0 ?
        <Table className="erp_table " id='earningHead-table' bordered striped >
          <thead className="erp_table_head">
            <tr>
              <th className="erp_table_th">Action</th>
              <th className="erp_table_th">Earning Head </th>
              <th className="erp_table_th">Short Name</th>
              <th className="erp_table_th">Calculation Type</th>
              <th className="erp_table_th">Formula</th>
              <th className="erp_table_th">Calculation Value</th>
            </tr>
          </thead>
          <tbody>
            {
              earningData.map((earnItem, Index) =>
                <tr payTermItemIndex={Index} rowIndex={Index}>

                  <td className="erp_table_td">
                    <input type="checkbox" name="selectEarning" className="selectEarning" value={earnItem.earning_heads_id} id={'selectEarning_' + earnItem.earning_heads_id} onClick={() => handleEarningCheckbox(earnItem.earning_heads_id)} disabled={keyForViewUpdate === 'view'} />
                  </td>
                  <td className="erp_table_td">{earnItem.earning_head_name}</td>
                  <td className="erp_table_td">{earnItem.earning_head_short_name}</td>
                  <td className="erp_table_td">{earnItem.calculation_type}</td>
                  <td className="erp_table_td">
                    <input type="text" className="erp_input_field_table_txt mb-0" value={earnItem.formula} id={"formula_" + earnItem.earning_heads_id} onChange={(e) => { updatePmtTermsTblData(earnItem, e); }}
                      onBlur={(e) => { updatePmtTermsTblData(earnItem, e); }}
                      Headers='formula' disabled={keyForViewUpdate === 'view'}
                    />
                  </td>
                  <td className="erp_table_td">
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end" value={earnItem.calculation_value} id={"calculation_value_" + earnItem.earning_heads_id} onChange={(e) => { updatePmtTermsTblData(earnItem, e); }}
                      onBlur={(e) => { updatePmtTermsTblData(earnItem, e); }}
                      Headers='calculation_value' disabled={keyForViewUpdate === 'view'} />
                  </td>

                </tr>
              )
            }
          </tbody>
        </Table> : null
      }
    </>
  }, [earningData]);

  //Fn for render payment terms static table 
  const renderDeductionTable = useMemo(() => {
    return <>
      {deductionData.length !== 0 ?
        <Table className="erp_table " id='deductionHead-table' bordered striped >
          <thead className="erp_table_head">
            <tr>
              <th className="erp_table_th">Action</th>
              <th className="erp_table_th">Deduction Head </th>
              <th className="erp_table_th">Short Name</th>
              <th className="erp_table_th">Calculation Type</th>
              <th className="erp_table_th">Formula</th>
              <th className="erp_table_th">Calculation Value</th>
            </tr>
          </thead>
          <tbody>
            {
              deductionData.map((deductionItem, Index) =>
                <tr payTermItemIndex={Index} >

                  <td className="erp_table_td">
                    <input type="checkbox" name="selectDeduction" className="selectDeduction" value={deductionItem.deduction_heads_id} id={'selectDeduction_' + deductionItem.deduction_heads_id} onClick={() => handleDeductionCheckbox(deductionItem.deduction_heads_id)} disabled={keyForViewUpdate === 'view'} />
                  </td>
                  <td className="erp_table_td">{deductionItem.deduction_head_name}</td>
                  <td className="erp_table_td">{deductionItem.deduction_head_short_name}</td>
                  <td className="erp_table_td">{deductionItem.calculation_type}</td>
                  <td className="erp_table_td">
                    <input type="text" className="erp_input_field_table_txt mb-0" value={deductionItem.formula} id={"formula_" + deductionItem.deduction_heads_id} onChange={(e) => { updateDeductionTblData(deductionItem, e); }}
                      onBlur={(e) => { updateDeductionTblData(deductionItem, e); }}
                      Headers='formula' disabled={keyForViewUpdate === 'view'} />
                  </td>
                  <td className="erp_table_td">
                    <input type="text" className="erp_input_field_table_txt mb-0 text-end" value={deductionItem.calculation_value} id={"calculation_value_" + deductionItem.deduction_heads_id} onChange={(e) => { updateDeductionTblData(deductionItem, e); }}
                      onBlur={(e) => { updateDeductionTblData(deductionItem, e); }}
                      Headers='calculation_value' disabled={keyForViewUpdate === 'view'} />
                  </td>
                </tr>
              )
            }
          </tbody>
        </Table> : null}
    </>
  }, [deductionData]);


  return (
    <MDBox>
      <ComboBox ref={combobox} />
      <FrmValidations ref={frmValidation} />
      <GenerateMaterialId ref={generateMaterialIdAPiCall} />
      <ValidateNumberDateInput ref={validateNumberPercentInput} />
      {/* //case no. 1 chnges by ujjwala 10/1/2024 Start */}
      {isLoading ?
        <div className="spinner-overlay"  >
          <div className="spinner-container">
            <CircularProgress />
            <span>Loading...</span>
          </div>
        </div> :
        ''}
      <form id='employeeFormId'>
        <div className='row'>

          {/* 1 st Column */}
          <div className='col-sm-4 erp_form_col_div'>

            {/* Employment Details */}

            <div className='row'>
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Employee Group<span className="required">*</span></Form.Label>
              </div>
              <div className="col">
                <select id="cmb_employee_group_type" className="form-select form-select-sm" value={cmb_employee_group_type} onChange={e => { setEmployeeGroupType(e.target.value); comboBoxesOnChange('Employee_group'); validateEmpInfoFields(); }} maxLength="255">
                  <option value="">Select</option>
                  {employeeGroupTypeOptions?.map(groupType => (
                    <option value={groupType.field_name} property_value={groupType.property_value}>{groupType.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_employee_group_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Employee Type <span className="required">*</span></Form.Label>
              </div>
              <div className="col">
                <select id="cmb_employee_type" className="form-select form-select-sm" value={cmb_employee_type} onChange={e => { setEmployeeType(e.target.value); comboBoxesOnChange('Employee_type'); validateEmpInfoFields(); }} maxLength="255">
                  <option value="">Select</option>
                  {employeeTypeOptions?.map(employeeTypes => (
                    <option value={employeeTypes.field_id} property_value={employeeTypes.property_value}>{employeeTypes.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_employee_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Employee Code <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="employee_code" className="erp_input_field" value={txt_employee_code} onInput={e => { setEmployeeCode(e.target.value); validateEmpInfoFields(); }} maxLength="255" />
                <MDTypography variant="button" id="error_employee_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Machine Code </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_employee_machine_code" className="erp_input_field" value={txt_employee_machine_code} onInput={e => { setEmployeeMachineCode(e.target.value); }} maxLength="255" optional="optional" />
                <MDTypography variant="button" id="error_txt_employee_machine_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Salutation </Form.Label>
              </div>
              <div className="col">
                <select id="cmb_salutation" className="form-select form-select-sm" value={cmb_salutation} onInput={e => { setSalutation(e.target.value); inputBoxesOnChange('EmployeeName'); }} optional="optional" >
                  <option value="">Select</option>
                  {salutationOptions?.map(salutation => (
                    <option value={salutation.field_name}>{salutation.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_salutation" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Last Name <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_last_name" className="erp_input_field" value={txt_last_name} onInput={e => { setLastName(e.target.value.trim().charAt(0).toUpperCase() + e.target.value.slice(1)); validateEmpInfoFields(); inputBoxesOnChange('EmployeeName'); }} maxLength="100" />
                <MDTypography variant="button" id="error_txt_last_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">First Name <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_first_name" className="erp_input_field" value={txt_first_name} onInput={e => { setFirstName(e.target.value.trim().charAt(0).toUpperCase() + e.target.value.slice(1)); validateEmpInfoFields(); inputBoxesOnChange('EmployeeName'); }} maxLength="100" />
                <MDTypography variant="button" id="error_txt_first_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Middle Name </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_middle_name" className="erp_input_field" value={txt_middle_name} onInput={e => { setMiddleName(e.target.value.trim().charAt(0).toUpperCase() + e.target.value.slice(1)); inputBoxesOnChange('EmployeeName'); }} maxLength="100" optional="optional" />
                <MDTypography variant="button" id="error_txt_middle_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Full Name <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_employee_name" className="erp_input_field" value={txt_employee_name} onChange={e => { validateEmpInfoFields(); }} maxLength="300" />
                <MDTypography variant="button" id="error_txt_employee_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>


            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Employee Status <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id='cmb_employee_status' className='form-select form-select-sm' value={cmb_employee_status} onChange={e => { setEmployeeStatus(e.target.value); validateEmpInfoFields(); }} >
                  <option value="" disabled>Select</option>
                  {employeeStatusOptions?.map(status => (
                    <option value={status.field_name}>{status.field_name}</option>
                  ))
                  }
                </select>
                <MDTypography variant="button" id="error_cmb_employee_status" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Current Address<span className="required">*</span> </Form.Label>
              </div>
              <div className='col'>
                <Form.Control as="textarea" rows={1} id="txt_current_address" className="erp_txt_area" value={txt_current_address} onInput={e => { setCurrentAddress(e.target.value); validateEmpInfoFields(); }} maxlength="1000" />
                <MDTypography variant="button" id="error_txt_current_address" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Current Pincode<span className="required">*</span> </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" className="erp_input_field number" id="txt_current_pincode" value={txt_current_pincode} onInput={e => { validateErrorMsgsNumericInput(e); validateEmpInfoFields(); }} maxlength="6" />
                <MDTypography variant="button" id="error_txt_current_pincode" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm'>
                <Form>
                  <div className="erp_form_radio text-danger">
                    <div className="fCheck"> <Form.Check className="erp_radio_button" id="permt_add_chechbox" label="Is Permanent address, same as current address ?" type="checkbox" value={chk_pmt_add} checked={chk_pmt_add} name="chk_pmt_add" onChange={(e) => validateCheckBox()} /> </div>
                  </div>
                </Form>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Permanant Address{chk_pmt_add == true ? <span className="required">*</span> : ""}</Form.Label>
              </div>
              <div className='col'>
                <Form.Control as="textarea" rows={1} id="txt_permanant_address" className="erp_txt_area" value={txt_permanant_address} onInput={e => { setPermanantAddress(e.target.value); }} onChange={e => { validateEmpInfoFields(); }} maxlength="1000" optional={`${chk_pmt_add !== true ? "optional" : ''}`} />
                <MDTypography variant="button" id="error_txt_permanant_address" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Permanant Pincode{chk_pmt_add == true ? <span className="required">*</span> : ""}</Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" className="erp_input_field number" id="txt_permanant_pincode" value={txt_permanant_pincode} onInput={e => { validateErrorMsgsNumericInput(e); }} onChange={e => { validateEmpInfoFields(); }} maxlength="6" optional={`${chk_pmt_add !== true ? "optional" : ''}`} />
                <MDTypography variant="button" id="error_txt_permanant_pincode" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>
          </div>

          {/* 2 nd Column */}
          <div className='col-sm-4 erp_form_col_div'>
            {/* Personal Details */}

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Country <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select size="sm" id="cmb_country_id" className="form-select form-select-sm" value={cmb_country_id} onChange={(e) => { comboBoxesOnChange("Country"); validateEmpInfoFields(); }}>
                  <option value="" disabled>Select </option>
                  {countryOptions?.map(country => (
                    <option value={country.field_id} countryname={country.field_name}>{country.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_country_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">State <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select size="sm" id="cmb_state_id" className="form-select form-select-sm" value={cmb_state_id} onChange={(e) => { comboBoxesOnChange("State"); validateEmpInfoFields(); }}>
                  <option value="" disabled>Select </option>
                  {stateOptions?.map(state => (
                    <option value={state.field_id}>{state.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">District <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select size="sm" id="cmb_district_id" className="form-select form-select-sm" value={cmb_district_id} onChange={(e) => { comboBoxesOnChange("District"); validateEmpInfoFields(); }}>
                  <option value="" disabled>Select </option>
                  {districtOptions?.map(district => (
                    <option value={district.field_id}>{district.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_district_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">City <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_city_id" className="form-select form-select-sm" value={cmb_city_id} onChange={(e) => { comboBoxesOnChange("City"); validateEmpInfoFields(); }}>
                  <option value="" disabled>Select </option>
                  <option value="0">Add New Record +</option>
                  {cityOptions?.map(city => (
                    <option value={city.field_id}>{city.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Telephone No. {/* <span className="required">*</span> */}</Form.Label>
              </div>
              <div className='col'>
                <span className='erp_phone' >
                  <select size="sm" id='telPhoneCountryCode' className='form-select-phone'>
                    {countryCodeOptions?.map(option => (
                      <option value={option}>{option}</option>
                    ))}
                  </select>
                  <Form.Control type="text" className="erp_input_field erp_phn_border" id="txt_phone_no" value={txt_phone_no} onInput={(e) => { validateErrorMsgsNumericInput(e); validateEmpInfoFields(); validateTelePhone(e.target.value) }} maxLength="15" minLength="11" optional='optional' />
                </span>
                <MDTypography variant="button" id="error_txt_phone_no" className="erp_validation mt-2" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Primary Cell No. <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <span className='erp_phone' >
                  <select size="sm" id='priCellCountryCode' className='form-select-phone'>
                    {countryCodeOptions?.map(option => (
                      <option value={option}>{option}</option>
                    ))}
                  </select>
                  <Form.Control type="text" className="erp_input_field erp_phn_border" id="txt_cell_no1" value={txt_cell_no1} onInput={(e) => { validateErrorMsgsNumericInput(e); validateEmpInfoFields(); }} maxLength="10" minLength="10" />
                </span>
                <MDTypography variant="button" id="error_txt_cell_no1" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Secd. Cell No.</Form.Label>
              </div>
              <div className='col'>
                <span className='erp_phone' >
                  <select size="sm" id='secCellCountryCode' className='form-select-phone'>
                    {countryCodeOptions?.map(option => (
                      <option value={option}>{option}</option>
                    ))}
                  </select>
                  <Form.Control type="text" className="erp_input_field erp_phn_border" id="txt_cell_no2" value={txt_cell_no2} onInput={(e) => { validateErrorMsgsNumericInput(e); }} maxLength="10" minLength="10" optional='optional' />
                </span>
                <MDTypography variant="button" id="error_txt_cell_no2" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Primary Email<span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="email" id="txt_email_id1" className="erp_input_field" value={txt_email_id1} onInput={e => { setEmailId1(e.target.value); validateEmpInfoFields() }} maxLength="100" />
                <MDTypography variant="button" id="error_txt_email_id1" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Secondary Email</Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="email" id="txt_email_id2" className="erp_input_field" value={txt_email_id2} onInput={e => { setEmailId2(e.target.value); validateEmpInfoFields() }} maxLength="100" optional="optional" />
                <MDTypography variant="button" id="error1_txt_email_id2" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Date Of Birth<span className="required">*</span></Form.Label>
              </div>
              <div className="col">
                <Form.Control type="date" id="dt_date_of_birth" className="erp_input_field optional" value={dt_date_of_birth} onChange={e => { setDateOfBirth(e.target.value); validateEmpInfoFields(); validateDOB(e.target.value); }} />
                <MDTypography variant="button" id="error_dt_date_of_birth" className="erp_validation mt-2" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Gender </Form.Label>
              </div>
              <div className='col'>
                <select id='cmb_gender' className='form-select form-select-sm' value={cmb_gender} onChange={e => { setGender(e.target.value); }} optional="optional">
                  <option value="">Select</option>
                  {genderOptions?.map(gender => (
                    <option value={gender.field_name}>{gender.field_name}</option>
                  ))
                  }
                </select>
                <MDTypography variant="button" id="error_cmb_gender" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row d-none'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Religion </Form.Label>
              </div>
              <div className='col'>
                <select id='cmb_religion' className='form-select form-select-sm' value={cmb_religion} onChange={e => { setReligion(e.target.value); }} optional="optional">
                  <option value="" disabled>Select</option>
                  {religionOptions?.map(religion => (
                    <option value={religion.field_name}>{religion.field_name}</option>
                  ))
                  }
                </select>
                <MDTypography variant="button" id="error_cmb_religion" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row d-none'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Category </Form.Label>
              </div>
              <div className='col'>
                <select id='cmb_category_id' className='form-select form-select-sm' value={cmb_category_id} onChange={e => { setCategoryId(e.target.value); }} optional="optional">
                  <option value="" disabled>Select</option>
                  {categoryOptions?.map(category => (
                    <option value={category.field_name}>{category.field_name}</option>
                  ))
                  }
                </select>
                <MDTypography variant="button" id="error_cmb_category_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row d-none'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Caste </Form.Label>
              </div>
              <div className='col'>
                <select id='cmb_caste_id' className='form-select form-select-sm' value={cmb_caste_id} onChange={e => { setCasteId(e.target.value); }} optional="optional">
                  <option value="" disabled>Select</option>
                  {casteOptions?.map(caste => (
                    <option value={caste.field_name}>{caste.field_name}</option>
                  ))
                  }
                </select>
                <MDTypography variant="button" id="error_cmb_caste_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Aadhar No. <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id='txt_aadhar_card_no' className="erp_input_field" value={txt_aadhar_card_no} onInput={e => { validateErrorMsgsNumericInput(e); validateEmpInfoFields() }} maxLength="12" />
                <MDTypography variant="button" id="error_txt_aadhar_card_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">PAN No. </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_pan_no" className="erp_input_field" value={txt_pan_no} onInput={e => { setPanNo(e.target.value.split(' ').join('').toUpperCase()); }} maxLength="10" optional="optional" />
                <MDTypography variant="button" id="error_txt_pan_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Passport No. </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id='txt_passport_no' className="erp_input_field" value={txt_passport_no} onInput={e => { setPassportNo(e.target.value.split(' ').join('').toUpperCase()); }} maxLength="10" optional='optional' />
                <MDTypography variant="button" id="error_txt_passport_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

          </div>

          {/* 3rd Column */}
          <div className='col-sm-4 erp_form_col_div'>
            {/* Residentail Details */}

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Driving Licence </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" className="erp_input_field" id="txt_driving_licence" value={txt_driving_licence} onInput={e => { setDrivingLicence(e.target.value.split(' ').join('').toUpperCase()); }} maxLength="16" optional='optional' />
                <MDTypography variant="button" id="error_txt_driving_licence" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Blood Group<span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id='cmb_blood_group' className='form-select form-select-sm' value={cmb_blood_group} onChange={e => { setBloodGroupId(e.target.value); }}>
                  <option value="" disabled>Select </option>
                  {bloodGroupsOptions?.map(bloodGroup => (
                    <option value={bloodGroup.field_name}>{bloodGroup.field_name}</option>
                  ))
                  }
                </select>
                <MDTypography variant="button" id="error_cmb_blood_group" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Marital Status </Form.Label>
              </div>
              <div className='col'>
                <select id="empMaritalStatusId" className='form-select form-select-sm' value={cmb_marital_status} onChange={e => { setMaritalStatus(e.target.value); validateEmpInfoFields() }} optional="optional">
                  <option value="" disabled>Select </option>
                  {
                    maritalStatusOptions?.map(maritalStatus => (
                      <option value={maritalStatus.field_name}>{maritalStatus.field_name}</option>
                    ))
                  }
                </select>
                <MDTypography variant="button" id="error_empMaritalStatusId" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Emp. Destination </Form.Label>
              </div>
              <div className='col'>
                <select id='cmb_destination_id' className='form-select form-select-sm' value={cmb_destination_id} onChange={e => { setDestionationId(e.target.value); comboBoxesOnChange("EmployeeDestination"); }} optional="optional">
                  <option value="" disabled>Select</option>
                  <option value="0">Add New Record+</option>
                  {destinationOptions?.map(destination => (
                    <option value={destination.field_id}>{destination.field_name}</option>
                  ))
                  }
                </select>
                <MDTypography variant="button" id="error_cmb_destination_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Employee Reference </Form.Label>
              </div>
              <div className='col'>
                <select id='cmb_reference_id' className='form-select form-select-sm' value={cmb_reference_id} onChange={e => { setReferenceId(e.target.value); }} optional="optional">
                  <option value="" disabled>Select</option>
                  {employeeReferenceOptions?.map(reference => (
                    <option value={reference.field_name}>{reference.field_name}</option>
                  ))
                  }
                </select>
                <MDTypography variant="button" id="error_cmb_reference_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>


            {/* Documention Details */}
            <div className='row'>
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Primary Bank Name</Form.Label>
              </div>
              <div className="col">
                <select id="cmb_bank_id_1" className="form-select form-select-sm" value={cmb_bank_id_1} onChange={e => { setBankId1(e.target.value); comboBoxesOnChange("PrimaryBankName"); }} optional="optional">
                  <option value="" disabled>Select</option>
                  <option value="0">Add New Record+</option>
                  {bankNamesOptions?.map(bankId => (
                    <option value={bankId.field_id}>{bankId.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_bank_id_1" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>

              <div className="col-sm-1 ">
                <Tooltip title="Refresh" placement="top">
                  <MDTypography>
                    <MdRefresh onClick={() => FnRefreshbtn("PrimaryBank")} style={{ color: 'black' }} />
                  </MDTypography>
                </Tooltip>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Prim. Bank A/c No.{cmb_bank_id_1 !== '1' ? <span className="required">*</span> : ""} </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" className="erp_input_field" id="txt_account_no1" value={txt_account_no1}
                  onInput={e => { validateErrorMsgsNumericInput(e); validateEmpInfoFields(); }} optional={`${cmb_bank_id_1 === '1' ? "optional" : ''}`} maxLength="17" />
                <MDTypography variant="button" id="error_txt_account_no1" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Primary Bank IFSC {cmb_bank_id_1 !== '1' ? <span className="required">*</span> : ""}</Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" className="erp_input_field" id="txt_ifsc_code1" value={txt_ifsc_code1} onInput={e => { setIFSCCode1(e.target.value.split(' ').join('').toUpperCase()); validateEmpInfoFields(); }} maxLength="11" optional={`${cmb_bank_id_1 === '1' ? "optional" : ''}`} />
                <MDTypography variant="button" id="error_txt_ifsc_code1" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Secd. Bank Name</Form.Label>
              </div>
              <div className="col">
                <select id="cmb_bank_id_2" className="form-select form-select-sm" value={cmb_bank_id_2} onChange={e => { setBankId2(e.target.value); comboBoxesOnChange("SecondaryBankName"); }} optional="optional">
                  <option value="" disabled>Select</option>
                  <option value="0">Add New Record+</option>

                  {bankNamesOptions?.map(bankId => (
                    <option value={bankId.field_id}>{bankId.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_bank_id_2" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
              <div className="col-sm-1 ">
                <Tooltip title="Refresh" placement="top">
                  <MDTypography>
                    <MdRefresh onClick={() => FnRefreshbtn("SecondaryBank")} style={{ color: 'black' }} />
                  </MDTypography>
                </Tooltip>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Secd. Bank A/c No.</Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" className="erp_input_field" id="txt_account_no2" value={txt_account_no2}
                  onInput={e => { validateErrorMsgsNumericInput(e); }} maxLength="17" optional='optional' />
                <MDTypography variant="button" id="error_txt_account_no2" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} maxLength="20" >
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Secd. Bank IFSC</Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" className="erp_input_field" id="txt_ifsc_code2" value={txt_ifsc_code2} onInput={e => { setIFSCCode2(e.target.value.split(' ').join('').toUpperCase()); }} maxLength="11" optional='optional' />
                <MDTypography variant="button" id="error_txt_ifsc_code2" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Finance A/c No. </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" className="erp_input_field" id="finance_account_no" value={finance_account_no} onInput={e => { validateErrorMsgsNumericInput(e); }} maxLength="10" optional='optional' />
                <MDTypography variant="button" id="error_finance_account_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                </MDTypography>
              </div>
            </div>


            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">UserName <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" className="erp_input_field" id="txt_username" value={txt_username} onInput={(e) => { setUserName(e.target.value.trim()); }} maxlength="200" optional="optional" ref={usernameRef} />
                <MDTypography variant="button" id="error_txt_username" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            {
              keyForViewUpdate === "update" || keyForViewUpdate === 'view' ?
                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Password <span className="required">*</span></Form.Label>
                  </div>
                  <div className='col-sm-8'>
                    <div className="input-group mb-3">
                      <Form.Control type={showPassword ? 'text' : 'password'} className="erp_input_field number" id="txt_password" value={txt_password} onInput={(e) => { setPassword(e.target.value.trim()); }} maxLength="50" optional="optional" disabled={keyForViewUpdate === 'view'} />
                      <span className="input-group-text" id="basic-addon2">
                        {showPassword ? (<AiFillEye onClick={togglePasswordhideandshow} />) : (<AiFillEyeInvisible onClick={togglePasswordhideandshow} />)}
                      </span>
                    </div>
                    <MDTypography variant="button" id="error_txt_password" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                : ""
            }

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Is Active </Form.Label>
              </div>
              <div className='col'>
                <Form>
                  <div className="erp_form_radio">
                    <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="1" name="employeeIsActive" defaultChecked /></div>
                    <div className="sCheck"><Form.Check className="erp_radio_button" label="No" value="0" type="radio" name="employeeIsActive" />  </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </form>
      <hr />

      {/* Employee Work Profile Accordian */}
      <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}>
          <Accordion.Header className="erp-form-label-md">Workprofile Information</Accordion.Header>
          <Accordion.Body>
            <form id='employeeWorkProfFormId'>
              <div className='row'>
                {/* 1 st Column */}
                <div className='col-sm-4 erp_form_col_div'>
                  <div className='row'>
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Department Group <span className="required">*</span></Form.Label>
                    </div>
                    <div className="col">
                      <select id="cmb_department_group_id" value={cmb_department_group_id} className="form-select form-select-sm" onChange={(e) => { comboBoxesOnChange('DepartmentGroup'); validateEmpWorkProfFields(); }}>
                        <option value="" disabled="true">Select</option>
                        {departmentGroupOptions?.map(deptGroup => (
                          <option value={deptGroup.field_id} key={deptGroup.field_id} fieldName={deptGroup.field_name}>{deptGroup.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_department_group_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Department <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <select size="sm" id="cmb_department_id" className="form-select form-select-sm" value={cmb_department_id} onChange={() => { comboBoxesOnChange("Department"); validateEmpWorkProfFields(); }} >
                        <option value="" disabled>Select </option>
                        <option value="0">Add New Record+</option>

                        {departmentOptions?.map(deptOption => (
                          <option value={deptOption.field_id} key={deptOption.field_id}>{deptOption.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>

                    <div className='col-sm-4'>
                      {/* <span className="required">*</span> */}
                      <Form.Label className="erp-form-label">Sub-Department</Form.Label>

                    </div>
                    <div className='col'>
                      <select size="sm" id="cmb_subdepartment_group_id" className="form-select form-select-sm" value={cmb_subdepartment_group_id} onChange={(e) => { setSubDepartmentGroupId(e.target.value); comboBoxesOnChange('SubDepartment'); validateEmpWorkProfFields(); }} optional='optional'>
                        <option value="" disabled="true">Select </option>
                        <option value="0">Add New Record+</option>
                        {subDepartmentGroupOptions?.map(subDeptt => (
                          <option value={subDeptt.field_id} key={subDeptt.field_id}>{subDeptt.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_subdepartment_group_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Designation <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <select size="sm" id="cmb_designation_id" className="form-select form-select-sm" value={cmb_designation_id} onChange={(e) => { setDesignationId(e.target.value); comboBoxesOnChange('Designation'); validateEmpWorkProfFields(); }}>
                        <option value="" disabled="true">Select</option>
                        <option value="0">Add New Record+</option>
                        {designationIdOptions?.map(designation => (
                          <option value={designation.field_id} key={designation.field_id}>{designation.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_designation_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    <div className="col-sm-1 ">
                      <Tooltip title="Refresh" placement="top">
                        <MdRefresh className="erp-view-btn" onClick={() => FnRefreshbtn("Designation")} style={{ color: 'black' }} />
                      </Tooltip>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Reporting To <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_reproting_to_id" value={cmb_reproting_to_id} className="form-select form-select-sm" onChange={(e) => { setReportingToId(e.target.value); comboBoxesOnChange('Reportingto'); validateEmpWorkProfFields(); }}>
                        <option value="" disabled="true">Select</option>
                        <option value="0">Add New Record+</option>

                        {reportingToOptions?.map(reportTo => (
                          employee_id != reportTo.field_id ?
                            <option value={reportTo.field_id} key={reportTo.field_id}>{reportTo.field_name}</option>
                            : ""
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_reproting_to_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    <div className="col-sm-1 ">
                      <Tooltip title="Refresh" placement="top">

                        <MdRefresh className="erp-view-btn" onClick={() => FnRefreshbtn("ReportingTo")} style={{ color: 'black' }} />

                      </Tooltip>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Weekly Off <span className="required">*</span></Form.Label>
                    </div>
                    <div className="col">
                      <select id="cmb_weeklyoff_id" value={cmb_weeklyoff_id} className="form-select form-select-sm" onChange={(e) => { setWeeklyOffId(e.target.value); validateEmpWorkProfFields(); }}>
                        <option value="" disabled="true">Select </option>
                        {weeklyOffOptions?.map(weekOf => (
                          <option value={weekOf.field_id} key={weekOf.field_id}>{weekOf.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_weeklyoff_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Shift<span className="required">*</span> </Form.Label>
                    </div>
                    <div className="col">
                      <select id="cmb_shift_id" value={cmb_shift_id} className="form-select form-select-sm" onChange={(e) => { setShiftId(e.target.value); comboBoxesOnChange('Shift'); validateEmpWorkProfFields(); }} >
                        <option value="" disabled="true">Select </option>
                        <option value="0">Add New Record+</option>
                        {shiftOptions?.map(shift => (
                          <option value={shift.field_id} key={shift.field_id}>{shift.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_shift_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                </div>

                {/* 2 nd Column */}
                <div className='col-sm-4 erp_form_col_div'>
                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Date Joning <span className="required">*</span></Form.Label>
                    </div>
                    <div className="col">
                      <Form.Control type="date" id="dt_date_joining" className="erp_input_field optional" value={dt_date_joining} onChange={(e) => { setDateJoining(e.target.value); validateEmpWorkProfFields() }} />
                      <MDTypography variant="button" id="error_dt_date_joining" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Date Exit </Form.Label>
                    </div>
                    <div className="col">
                      <Form.Control type="date" id="dt_date_exit" className="erp_input_field optional" value={dt_date_exit} onChange={(e) => { setDateExist(e.target.value); }} optional='optional' />
                      <MDTypography variant="button" id="error_dt_date_exit" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Contractor <span className="required">*</span></Form.Label>
                    </div>
                    <div className="col">
                      <select id="cmb_contractor_id" value={cmb_contractor_id} className="form-select form-select-sm" onChange={(e) => { comboBoxesOnChange('Contractor'); setContractorId(e.target.value); }}>
                        <option value="0">Add New Record+</option>
                        <option value="001">Self</option>
                        {setContractorOptions?.map(contractor => (
                          <option value={contractor.field_id} key={contractor.field_id}>{contractor.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_contractor_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Contract Start Date
                        {cmb_contractor_id != "001" ? <span className="required">*</span> : ""}
                      </Form.Label>
                    </div>
                    <div className="col">
                      <Form.Control type="date" id="dt_contract_start_date" className="erp_input_field optional" value={dt_contract_start_date} onChange={(e) => { setContractStartDate(e.target.value); validateEmpWorkProfFields() }} optional="optional" />
                      <MDTypography variant="button" id="error_dt_contract_start_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Contract End Date
                        {cmb_contractor_id != "001" ? <span className="required">*</span> : ""}
                      </Form.Label>
                    </div>
                    <div className="col">
                      <Form.Control type="date" id="dt_contract_end_date" className="erp_input_field optional" value={dt_contract_end_date} onChange={(e) => { setContractEndDate(e.target.value); validateEmpWorkProfFields() }} optional="optional" />
                      <MDTypography variant="button" id="error_dt_contract_end_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Region </Form.Label>
                    </div>
                    <div className="col">
                      <select id="cmb_region_id" value={cmb_region_id} className="form-select form-select-sm" onChange={(e) => { setRegionId(e.target.value); }} optional="optional">
                        <option value="" disabled="true">Select</option>
                        {regionOptions?.map(region => (
                          <option value={region.field_id} key={region.field_id}>{region.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_region_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                </div>

                {/* 3rd Column */}
                <div className='col-sm-4 erp_form_col_div'>

                  <div className='row'>
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Cost Center <span className="required">*</span></Form.Label>
                    </div>
                    <div className="col">
                      <select id="cmb_cost_center_id" value={cmb_cost_center_id} className="form-select form-select-sm" onChange={(e) => { setCostCenterId(e.target.value); validateEmpWorkProfFields(); comboBoxesOnChange('CostCenter'); }} >
                        <option value="" disabled="true">Select</option>
                        <option value="0">Add New Record+</option>


                        {costCenterOptions?.map(cost => (
                          <option value={cost.field_id} key={cost.field_id} >{cost.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_cost_center_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Bond Applicable </Form.Label>
                    </div>
                    <div className='col'>
                      <Form>
                        <div className="erp_form_radio">
                          <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="bondIsApplicable" checked={bondIsApplicable === true} onClick={() => setBondIsApplicable(true)} /> </div>
                          <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" value="false" name="bondIsApplicable" checked={bondIsApplicable === false} onClick={() => setBondIsApplicable(false)} /> </div>
                        </div>
                      </Form>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label"> Current Job Responsibilities </Form.Label>
                    </div>
                    <div className="col">
                      <Form.Control as="textarea" id="txt_current_job" rows={4} className="erp_txt_area" value={txt_current_job} onInput={e => { setCurrentJob(e.target.value); }} maxlength="500" optional='optional' />
                      <MDTypography variant="button" id="error_txt_current_job" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />

      {/*Employee Salary Accordian */}
      <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}>
          <Accordion.Header className="erp-form-label-md">Salary Infomation</Accordion.Header>
          <Accordion.Body>
            <MDBox>
              <form id='employeeSalaryInfoFormId'>
                <div className='row'>

                  {/* 1 st Column */}
                  <div className='col-sm-4 erp_form_col_div'>

                    <div className='row'>
                      <div className="col-sm-4">
                        <Form.Label className="erp-form-label">Band</Form.Label>
                      </div>
                      <div className="col">
                        <select id="cmb_band_id" value={cmb_band_id} className="form-select form-select-sm" onChange={(e) => { setBandId(e.target.value); }} optional="optional">
                          <option value="" disabled="true">Select </option>
                          {salaryBands?.map(band => (
                            <option key={band.field_name} value={band.field_id}>{band.field_name}</option>
                          ))}
                        </select>
                        <MDTypography variant="button" id="error_cmb_band_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>

                    <div className='row'>
                      <div className="col-sm-4">
                        <Form.Label className="erp-form-label">Grade </Form.Label>
                      </div>
                      <div className="col">
                        <select id="cmb_grade_id" value={cmb_grade_id} className="form-select form-select-sm" onChange={(e) => { setGradeId(e.target.value); }} optional="optional">
                          <option value="" disabled="true">Select </option>
                          {salaryGrades?.map(grade => (
                            <option key={grade.field_name} value={grade.field_id}>{grade.field_name}</option>
                          ))}
                        </select>
                        <MDTypography variant="button" id="error_cmb_grade_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>

                    <div className='row'>
                      <div className="col-sm-4">
                        <Form.Label className="erp-form-label"> CTC Amount <span className="required">*</span></Form.Label>
                      </div>
                      <div className="col">
                        <Form.Control type="number" id="txt_ctc" className="erp_input_field" value={txt_ctc} onInput={(e) => { validateNumericInput(e, 'CTC') }} onChange={() => validateEmpSalaryFields()} maxlength="8" />
                        <MDTypography variant="button" id="error_txt_ctc" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>

                    <div className='row'>
                      <div className="col-sm-4">
                        <Form.Label className="erp-form-label"> Salary Amount <span className="required">*</span></Form.Label>
                      </div>
                      <div className="col">
                        <Form.Control type="number" id="txt_salary" className="erp_input_field" value={txt_salary} onInput={(e) => { validateNumericInput(e, 'Salary'); validateEmpSalaryFields(); }} maxlength="8" />
                        <MDTypography variant="button" id="error_txt_salary" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-sm-4'>
                        <Form.Label className="erp-form-label">Over-Time Applicable </Form.Label>
                      </div>
                      <div className='col'>
                        <div className='col'>
                          <Form>
                            <div className="erp_form_radio">
                              <div className="sCheck"> <Form.Check className="erp_radio_button me-1" label="No" type="radio" value="false" name="chk_ot_flag" checked={chk_ot_flag === false} onClick={() => { setOTFlag(false); onRadioBtnChange("OTApplicable") }} /> </div>
                              <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="chk_ot_flag" checked={chk_ot_flag === true} onClick={() => { setOTFlag(true); onRadioBtnChange("OTApplicable") }} /> </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className="col-sm-4">
                        <Form.Label className="erp-form-label"> Over-Time Amount per Hour
                          {chk_ot_flag == true ? <span className="required">*</span> : ""}
                        </Form.Label>
                      </div>
                      <div className="col">
                        <Form.Control type="number" id="txt_ot_amount" className="erp_input_field " value={txt_ot_amount} onInput={(e) => { validateNumericInput(e, 'OTAmount'); validateEmpSalaryFields(); }} optional={`${chk_ot_flag !== true ? "optional" : ''}`} maxlength="8" />
                        <MDTypography variant="button" id="error_txt_ot_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-sm-4'>
                        <Form.Label className="erp-form-label">Gratuity Applicable </Form.Label>
                      </div>
                      <div className='col'>
                        <div className='col'>
                          <Form>
                            <div className="erp_form_radio">
                              <div className="sCheck"> <Form.Check className="erp_radio_button me-1" label="No" type="radio" value="false" name="chk_gratuity_applicable" checked={chk_gratuity_applicable === false} onClick={() => setGratuityIsApplicable(false)} /> </div>
                              <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="chk_gratuity_applicable" checked={chk_gratuity_applicable === true} onClick={() => setGratuityIsApplicable(true)} /> </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>

                  </div>

                  {/* 2 nd Column */}
                  <div className='col-sm-4 erp_form_col_div'>

                    <div className='row'>
                      <div className='col-sm-4'>
                        <Form.Label className="erp-form-label">PF Applicable </Form.Label>
                      </div>
                      <div className='col'>
                        <div className='col'>
                          <Form>
                            <div className="erp_form_radio">
                              <div className="sCheck"> <Form.Check className="erp_radio_button me-1" label="No" type="radio" value="false" name="chk_pf_flag" checked={chk_pf_flag === false} onClick={() => { setPFIsApplicable(false); onRadioBtnChange("PFApplicable") }} /> </div>
                              <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="chk_pf_flag" checked={chk_pf_flag === true} onClick={() => { setPFIsApplicable(true); onRadioBtnChange("PFApplicable") }} /> </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-sm-4'>
                        <Form.Label className="erp-form-label"> PF Number {chk_pf_flag == true ? <span className="required">*</span> : ""}
                        </Form.Label>
                      </div>
                      <div className="col">
                        <Form.Control type="text" id="txt_pf_no" className="erp_input_field " value={txt_pf_no} onChange={(e) => { setPFnum(e.target.value); validateEmpSalaryFields(); }} optional={`${chk_pf_flag !== true ? "optional" : ''}`} maxlength="255" />
                        <MDTypography variant="button" id="error_txt_pf_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-sm-4'>
                        <Form.Label className="erp-form-label"> UAN Number
                          {chk_pf_flag == true ? <span className="required">*</span> : ""}
                        </Form.Label>
                      </div>
                      <div className="col">
                        <Form.Control type="text" id="txt_uan_no" className="erp_input_field" value={txt_uan_no} onChange={(e) => { setUANNum(e.target.value); validateEmpSalaryFields(); }} optional={`${chk_pf_flag !== true ? "optional" : ''}`} maxlength="255" />
                        <MDTypography variant="button" id="error_txt_uan_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-4">
                        <Form.Label className="erp-form-label"> PF Date
                          {chk_pf_flag == true ? <span className="required">*</span> : ""}
                        </Form.Label>
                      </div>
                      <div className="col">
                        <Form.Control type="date" id="dt_pf_date" className="erp_input_field" value={dt_pf_date} onChange={(e) => { setPFDate(e.target.value); validateEmpSalaryFields(); }} optional={`${chk_pf_flag !== true ? "optional" : ''}`} />
                        <MDTypography variant="button" id="error_dt_pf_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-sm-4'>
                        <Form.Label className="erp-form-label">ESIC Applicable </Form.Label>
                      </div>
                      <div className='col'>
                        <div className='col'>
                          <Form>
                            <div className="erp_form_radio">
                              <div className="sCheck"> <Form.Check className="erp_radio_button me-1" label="No" type="radio" value="false" name="chk_esic_flag" checked={chk_esic_flag === false} onClick={() => { setESICIsApplicable(false); onRadioBtnChange("ESICApplicable") }} /> </div>
                              <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="chk_esic_flag" checked={chk_esic_flag === true} onClick={() => { setESICIsApplicable(true); onRadioBtnChange("ESICApplicable") }} /> </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-sm-4'>
                        <Form.Label className="erp-form-label">ESIC Number
                          {chk_esic_flag == true ? <span className="required">*</span> : ""}
                        </Form.Label>
                      </div>
                      <div className="col">
                        <Form.Control type="text" id="txt_esic_no" className="erp_input_field" value={txt_esic_no} onChange={(e) => { setESICNum(e.target.value); validateEmpSalaryFields(); }} optional={`${chk_esic_flag !== true ? "optional" : ''}`} maxlength="255" />
                        <MDTypography variant="button" id="error_txt_esic_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-4">
                        <Form.Label className="erp-form-label">ESIC Date
                          {chk_esic_flag == true ? <span className="required">*</span> : ""}
                        </Form.Label>
                      </div>
                      <div className="col">
                        <Form.Control type="date" id="dt_esic_date" className="erp_input_field" value={dt_esic_date} onChange={(e) => { setESICDate(e.target.value); validateEmpSalaryFields(); }} optional={`${chk_esic_flag !== true ? "optional" : ''}`} />
                        <MDTypography variant="button" id="error_dt_esic_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>
                  </div>

                  {/* 3rd Column */}
                  <div className='col-sm-4 erp_form_col_div'>

                    <div className='row'>
                      <div className='col-sm-4'>
                        <Form.Label className="erp-form-label">MLWF Applicable </Form.Label>
                      </div>
                      <div className='col'>
                        <div className='col'>
                          <Form>
                            <div className="erp_form_radio">
                              <div className="sCheck"> <Form.Check className="erp_radio_button me-1" label="No" type="radio" value="false" name="chk_mlwf_flag" checked={chk_mlwf_flag === false} onClick={() => { setMLWFIsApplicable(false); onRadioBtnChange("MLWFApplicable") }} /> </div>
                              <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="chk_mlwf_flag" checked={chk_mlwf_flag === true} onClick={() => { setMLWFIsApplicable(true); onRadioBtnChange("MLWFApplicable") }} /> </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-sm-4'>
                        <Form.Label className="erp-form-label">MLWF Number
                          {chk_mlwf_flag == true ? <span className="required">*</span> : ""}
                        </Form.Label>
                      </div>
                      <div className="col">
                        <Form.Control type="text" id="txt_mlwf_no" className="erp_input_field" value={txt_mlwf_no} onChange={(e) => { setMLWFNum(e.target.value); validateEmpSalaryFields(); }} optional={`${chk_mlwf_flag !== true ? "optional" : ''}`} maxlength="255" />
                        <MDTypography variant="button" id="error_txt_mlwf_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>

                  </div>
                </div>
              </form>
            </MDBox>
            <hr></hr>

            <div>
              <div className='card-header text-center py-0'>
                <label className='erp-form-label-lg text-center'> Employee (Earning Deduction Mapping)</label>
              </div>
              <form id='employeeSalaryInfoFormId'>
                {/* <div className="row erp_transporter_div "> */}
                <div className="col-sm-6 erp_form_col_div">

                  <div className="col-sm-12 erp_form_col_div">
                    <div className="row">
                      <div className="col-sm-4">
                        <Form.Label className="erp-form-label"> Effective Date </Form.Label>
                      </div>
                      <div className="col">
                        <Form.Control type="date" id="effective_date" className="erp_input_field" value={effective_date} onChange={e => { setEffectiveDate(e.target.value); }} optional="optional" />
                        <MDTypography variant="button" id="error_effective_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                        </MDTypography>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </form>

              <div className="row col-12">
                <div class="col-12 col-lg-6" style={{ minHeight: "300px;" }}>
                  <div className='erp-form-label-lg'>Employee Wise Earning</div>
                  <div class="col pt-sm-1">
                    <input type='checkbox' class="selectAllEarning" id="selectAllEarning" onClick={(e) => toggleChkAllBoxes('selectAllEarning')} disabled={keyForViewUpdate === 'view'} /> <label class="erp-form-label pb-1"> Select All </label>
                  </div>
                  <div className="row  mt-3 gx-3" style={{ height: '500px', overflowY: 'auto' }}>

                    {renderEarningTable}

                    <MDTypography variant="button" id="error_select_earningHead" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                  </div>
                </div>
                <div class="col-12 col-lg-6" style={{ minHeight: "300px;" }}>
                  <div className='erp-form-label-lg'>Employee Wise Deduction</div>
                  <div class="col pt-sm-1">
                    <input type='checkbox' class="selectAllDeduction" id="selectAllDeduction" onClick={(e) => toggleChkAllBoxes('selectAllDeduction')} disabled={keyForViewUpdate === 'view'} /> <label class="erp-form-label pb-1"> Select All </label>
                  </div>
                  <div className="row  mt-3 gx-3" style={{ height: '500px', overflowY: 'auto' }}>

                    {renderDeductionTable}

                  </div>

                </div>

              </div>
            </div>

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />


      {/* //case no. 1 chnges by ujjwala 10/1/2024 Start */}
      <div className="card-footer py-0 text-center">
        <MDButton type="button" className="erp-gb-button"
          onClick={() => {
            const path = compType === 'Register' ? '/Masters/EmployeesListing/reg' : '/Masters/EmployeesListing';
            navigate(path);
          }} variant="button"
          fontWeight="regular">Back</MDButton>
        <MDButton type="submit" onClick={handleSubmit} id="saveBtn" className={`erp-gb-button ms-2 ${keyForViewUpdate === "view" ? 'd-none' : 'display'}`} variant="button"
          fontWeight="regular">{actionLabel}</MDButton>
        <MDButton type="button"
          onClick={validationfornext}
          id="nxtBtn" className="ms-2 erp-gb-button " variant="button"
          fontWeight="regular" >Next</MDButton>
      </div >

      <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />

      <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />



      {/* Add new Record Popup */}
      <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
        <Modal.Header>
          <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
          <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
        </Modal.Header>
        <Modal.Body className='erp_city_modal_body'>
          {displayRecordComponent()}
        </Modal.Body>
        <Modal.Footer>
          <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
            fontWeight="regular">Close</MDButton>

        </Modal.Footer>
      </Modal >
    </MDBox>
  )
}
