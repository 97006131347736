import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'

// import valid formate
let itemsPerPage = 0; // Number of items per page

const PurchaseRequisition = React.forwardRef(({ invoiceContent }, ref) => {
    const data = invoiceContent.items


    function numberToWordsWithCurrency(number) {
        debugger
        // Check if the input number is NA, null, or an empty string
        if (number === null || number === "NA" || number === "" || number === undefined) {
            return "-";
        }

        const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
        const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

        // Function to convert the integer part of the number to words
        function convertIntegerToWords(integer) {
            if (integer === 0) {
                return "";
            }

            if (integer < 20) {
                return ones[integer];
            }

            if (integer < 100) {
                const ten = Math.floor(integer / 10);
                const remainder = integer % 10;
                return tens[ten] + (remainder > 0 ? ` ${ones[remainder]}` : "");
            }

            if (integer < 1000) {
                const hundred = Math.floor(integer / 100);
                const remainder = integer % 100;
                return ones[hundred] + " Hundred" + (remainder > 0 ? ` and ${convertIntegerToWords(remainder)}` : "");
            }

            if (integer < 1000000) {
                const thousand = Math.floor(integer / 1000);
                const remainder = integer % 1000;
                return convertIntegerToWords(thousand) + " Thousand" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
            }
            if (integer < 1000000000) {
                const million = Math.floor(integer / 1000000);
                const remainder = integer % 1000000;
                return convertIntegerToWords(million) + " Million" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
            }

            return "Number too large to convert";
        }

        // Function to convert the decimal part of the number to words
        function convertDecimalToWords(decimal) {
            const tensValue = Math.floor(decimal / 10);
            const onesValue = decimal % 10;
            let words = "";

            if (tensValue > 0) {
                words += tens[tensValue];
            }

            if (onesValue > 0) {
                words += ` ${ones[onesValue]}`;
            }

            return words;
        }

        // Split the number into integer and decimal parts
        const integerPart = Math.floor(number);
        const decimalPart = Math.round((number - integerPart) * 100); // Convert the decimal to an integer

        // Convert the integer part to words
        let words = convertIntegerToWords(integerPart);

        // Add currency
        if (words) {
            words += " Rupees";
        }

        // Add the decimal part to the words
        if (decimalPart > 0) {
            words += ` and ${convertDecimalToWords(decimalPart)} Paise`;
        }

        return words || "Zero Rupees"; // If the input is 0, return "Zero Rupees"
    }


    const StockQuantity = invoiceContent.footer.sumofStockQuantity;
    const IndentQuantity = invoiceContent.footer.sumIndentQuantity;
    const ApprovedQuantity = invoiceContent.footer.sumofApprovedQuantity;

    const StockQuantityInWords = numberToWordsWithCurrency(StockQuantity);
    const IndentQuantityInWords = numberToWordsWithCurrency(IndentQuantity);
    const ApprovedQuantityInWords = numberToWordsWithCurrency(ApprovedQuantity);


    const invoiceHeader = (
        <>
            <div className='bordered border-bottom-0 px-0'>
                <div className='row mb-0 p-1'>
                    <div className="col-sm-2 text-center text-sm-start">
                        <img src={DakshabhiLogo} alt="master card" width="100" height="100" mt={1} />
                    </div>
                    <div className="col-sm-10 transformed">
                        <div className='erp-invoice-print-label text-center'>
                            <span className='erp-invoice-print-label-lg'>{invoiceContent.company.company}</span><br />
                            <span className='erp-invoice-print-label-md'>({invoiceContent.company.company_branch})</span>
                        </div>
                        <div className='erp-invoice-print-label-lg text-center'>
                            Purchase Requisition
                        </div>
                    </div>
                </div>

                <div className='row border-top border-dark p-0 m-0'>
                    <div className='col-sm-4'>
                        <dt className='erp-invoice-print-label-md-lg'> Indent No & version : {invoiceContent.indentdetails.indent_no} & {invoiceContent.indentdetails.indent_version}</dt>
                    </div>
                    <div className='col-sm-4'>
                        <dt className='erp-invoice-print-label-md-lg text-end'> Indent Type : {invoiceContent.header.indent_source}</dt>
                    </div>
                    <div className='col-sm-4'>
                        <dt className='erp-invoice-print-label-md-lg text-end'> Indent Date : {invoiceContent.indentdetails.indent_date}</dt>
                    </div>
                </div>
                <div className='row border-top border-dark p-0 m-0'>
                    <div className="row p-0 m-0">
                        {/* {
                            invoiceContent.header.indent_source !== 'Direct' ? <>
                                <div className="col-sm-5">
                                    <dl className="row pb-0 mb-0">
                                        <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Customer:</dt>
                                        <dd className='col-sm-9 erp-invoice-print-label'>  {invoiceContent.customerDetails.customer}</dd>

                                        <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Email:</dt>
                                        <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.customerDetails.email}</dd>

                                        <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Customer Order No:</dt>
                                        <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.header.customer_order_no}</dd>

                                        <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Customer Order Date:</dt>
                                        <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.header.customer_order_date}</dd>

                                        <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Address : </dt>
                                        <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.customerDetails.address}</dd>

                                        <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Contact No:</dt>
                                        <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.customerDetails.contact}</dd>

                                    </dl>
                                </div>

                            </> : null
                        } */}
                        <div className="col-sm-12">
                            <dl className="row mb-0 pb-0">
                                <dl className="col-sm-6 mb-0">
                                    <dl className="row mb-0 pb-0">

                                        <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Indent Source:</dt>
                                        <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.header.indent_source}</dd>

                                        <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Approved By:</dt>
                                        <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.header.approved_by_name}</dd>

                                        <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Approved Date:</dt>
                                        <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.header.approved_date}</dd>

                                        {
                                            invoiceContent.header.indent_source !== 'Direct' ? <>

                                                <div className="row pb-0 mb-0">
                                                    <div className="col-sm-12">
                                                        <dl className="row m-0 p-0">
                                                            <dt className='col-sm-3 m-0 p-0 erp-invoice-print-label-md-lg'>Customer:</dt>
                                                            <dd className='col-sm-9 m-0 p-0 erp-invoice-print-label'>{invoiceContent.customerDetails.customer}</dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                                <div className="row pb-0 mb-0">
                                                    <div className="col-sm-6">
                                                        <dl className="row m-0 p-0">
                                                            <dt className='col-sm-6  m-0 p-0 erp-invoice-print-label-md-lg'>Customer Order No:</dt>
                                                            <dd className='col-sm-6 m-0 p-0 erp-invoice-print-label'>{invoiceContent.header.customer_order_no}</dd>
                                                        </dl>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <dl className="row m-0 p-0">
                                                            <dt className='col-sm-6 m-0 p-0 erp-invoice-print-label-md-lg'>Customer Order Date:</dt>
                                                            <dd className='col-sm-6 m-0 p-0 erp-invoice-print-label'>{invoiceContent.header.customer_order_date}</dd>
                                                        </dl>
                                                    </div>
                                                </div>


                                            </> : null
                                        }
                                    </dl>
                                </dl>
                                <dl className="col-sm-6 mb-0">
                                    <dl className="row mb-0 pb-0">
                                        <dt className='col-sm-3  erp-invoice-print-label-md-lg'>Indent By:</dt>
                                        <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.header.indented_by}</dd>

                                        <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Department:</dt>
                                        <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.header.department_name}</dd>

                                        <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Approved Remark:</dt>
                                        <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.header.remark}</dd>
                                    </dl>
                                </dl>
                            </dl>
                            {/* {
                                invoiceContent.header.indent_source !== 'Direct' ? <>
                                    <dl className="row pb-0 mb-0">
                                        <dl className="row m-0 p-0">
                                            <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Customer:</dt>
                                            <dd className='col-sm-9 erp-invoice-print-label'>  {invoiceContent.customerDetails.customer}</dd>
                                        </dl>
                                        <dl className="row pb-0 mb-0">
                                            <dl className="col-sm-6">

                                                <dt className='col-sm-6 erp-invoice-print-label-md-lg'>Customer Order No:</dt>
                                                <dd className='col-sm-6 erp-invoice-print-label'>  {invoiceContent.header.customer_order_no}</dd>
                                            </dl>
                                            <dl className="col-sm-6">

                                                <dt className='col-sm-6 erp-invoice-print-label-md-lg'>Customer Order Date:</dt>
                                                <dd className='col-sm-6 erp-invoice-print-label'>  {invoiceContent.header.customer_order_date}</dd>
                                            </dl>
                                        </dl>
                                    </dl>

                                </> : null
                            } */}
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
    const invoiceFooter = (
        <div className="invoice-footer">
            {/* <div className='row'>
                <div className='col-sm-6'>
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">Requested By</dt>
                    </div>
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg">{invoiceContent.footer.indented_by_name}</dt>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">Approved By</dt>
                    </div>
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg">{invoiceContent.footer.approved_by_name}</dt>
                    </div>
                </div>
            </div> */}
            <div className='row mt-2'>
                <div className='col-sm-3'>
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">Requested By</dt>
                    </div>
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg">{invoiceContent.footer.indented_by_name}</dt>
                    </div>
                </div>
                <div className='col-sm-3 offset-sm-6'> {/* This offsets the third column */}
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">Approved By</dt>
                    </div>
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg">{invoiceContent.footer.approved_by_name}</dt>
                    </div>
                </div>
            </div>

        </div>
    );

    return (
        <>
            {/* <div className="container"> */}

            <div className="row">
                <div className="col-12">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0 mt-3">

                                {/* <!--BILL HEADER--> */}
                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    {invoiceHeader}
                                </div>

                                {/* <!--PARCEL DETAILS--> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12 ">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            <thead className='erp_table_border'>
                                                                <tr>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Sr No.</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Raw Material Name(FG Name,Technical Spect.,oem Part Code,our Part Code,Make Name, Grade Name )</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Stock Qty.</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Lead time</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Unit</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Indent Qty.</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Approved Qty.</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Rejected Qty.</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody id='detailstableId'>
                                                                {
                                                                    data.map((item, index) =>
                                                                        <>
                                                                            <tr className={`tblBodyTrHt-${index}`}>
                                                                                <td className="erp_invoice_table_td text-center">{item.sr_no}</td>
                                                                                {/* <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.product_rm_name}<span>({item.product_parent_fg_name},{item.material_tech_spect},{item.product_rm_oem_part_code},{item.product_rm_our_part_code},{item.product_make_name},{item.product_material_grade_name})</span></td> */}
                                                                                <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.product_rm_name}
                                                                                    <span>
                                                                                        {
                                                                                        item.product_parent_fg_name === '' && item.material_tech_spect === '' && item.product_rm_oem_part_code === '' && item.product_rm_our_part_code === '' && item.product_make_name === '' && item.product_material_grade_name === ''
                                                                                            ? ''
                                                                                            : <>
                                                                                            ({
                                                                                                [item.product_parent_fg_name, item.material_tech_spect, item.product_rm_oem_part_code, item.product_rm_our_part_code, item.product_make_name, item.product_material_grade_name].filter(str => str !== "" && str !== "NA" && str !== null && str !== undefined ).join(", ")
                                                                                            })
                                                                                            </>
                                                                                        }
                                                                                    </span>
                                                                                </td>
                                                                               
                                                                                <td className="erp_invoice_table_td text-end">{item.product_material_stock_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.lead_time}</td>
                                                                                <td className="erp_invoice_table_td">{item.unit}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.indent_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_material_approved_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_material_rejected_quantity}</td>
                                                                            </tr>
                                                                            {
                                                                                data.length - 1 === index ? <>
                                                                                    {/* Add blank rows to fill the space */}
                                                                                    <tr id='footerTr'>
                                                                                        <td className="erp_invoice_table_td" colSpan={4}>
                                                                                            <div className='row mt-1 m-0 p-0'>
                                                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">Stock Qty. Total(in Words) :</dt>
                                                                                                <dd className="col-sm-8 erp-invoice-print-label">{StockQuantityInWords}</dd>
                                                                                            </div>
                                                                                            <div className='row mt-1 m-0 p-0'>
                                                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">Indent Qty. Total(in Words) :</dt>
                                                                                                <dd className="col-sm-8 erp-invoice-print-label">{IndentQuantityInWords}</dd>
                                                                                            </div>
                                                                                            <div className='row  mt-1 m-0 p-0'>
                                                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">Approved Qty. Total(in Words) :</dt>
                                                                                                <dd className="col-sm-8 erp-invoice-print-label">{ApprovedQuantityInWords}</dd>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="erp_invoice_table_td p-0 m-0" colSpan={8}>
                                                                                            <table className="table p-0 m-0 table-borderless">
                                                                                                <tbody>
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Stock Qty. Total:</span> </td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{invoiceContent.footer.sumofStockQuantity}</td>
                                                                                                    </tr>
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg ">Indent Qty. Total:</span></td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{invoiceContent.footer.sumIndentQuantity}</td>
                                                                                                    </tr>
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Approved Qty. Total:</span> </td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{invoiceContent.footer.sumofApprovedQuantity}</td>

                                                                                                    </tr>

                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan={12}>
                                                                                            {/* Your invoice footer content */}
                                                                                            {invoiceFooter}
                                                                                        </td>
                                                                                    </tr>

                                                                                </> : null
                                                                            }

                                                                        </>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
})


export default PurchaseRequisition;

