import React, { useState, useRef, useEffect, useMemo } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import $ from 'jquery';
import html2pdf from 'html2pdf.js';

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table, Button } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// React icons
import { MdDelete, MdRefresh } from "react-icons/md";
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";
import { FiPrinter } from "react-icons/fi";
import { renderToString } from "react-dom/server";

//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ExcelExport from "Features/Exports/ExcelExport";
import Document from "Features/Document";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import FrmMEmployeesEntry from "Masters/MEmployee/MEmployeeEntry/components/FrmMEmployeesEntry";
import FrmDepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";
import FrmCity from "FrmGeneric/MCity/FrmCity";
import { CircularProgress } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import QuotationInvoice from "FrmGeneric/Invoice/QuotationInvoice";
import MailSentModal from "components/Modals/MailSentModal";
import ConfigConstants from "assets/Constants/config-constant";
import ProductList from "FrmGeneric/ProductList";
import CommonParamterEntry from "Masters/MCommonParameter/CommonParamterEntry";
import FrmPaymentTermsEntry from "Masters/MPaymentTerms/FrmPaymentTermsEntry";
import QuotationService from "FrmGeneric/Invoice/QuotationService";

export default function FrmQuotationServiceEntry(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isApprove, setIsApprove] = useState(false);

    const [isCancelationFlag, setIscancelationFlag] = useState(false);
    let docGroup = "Service Quotation"
    let commonTermMstName = 'Quotation Commercial Terms'
    let expanded = false;

    const configConstants = ConfigConstants();
    const { UserId, COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME, COMPANY_NAME } = configConstants;

    const { state } = useLocation()
    const { idList, keyForViewUpdate, compType, deletionKey = false } = state || {};

    // UseRefs
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const validateNumberDateInput = useRef();
    const importFile = useRef(null)
    const exlsExp = useRef();
    // let keyForViewUpdate = keyForViewUpdate;
    let updateData = idList;

    // Table data Hooks.
    const [followupdetailsData, setFollowupdetailsData] = useState([])
    const [existingFunctionalityTblData, setExistingFunctionalityTblData] = useState([])

    //combo options
    const [enquiryOrderNoList, setEnquiryOrderNoList] = useState([])
    const [stateOpts, setStateOpts] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [customerCityOpts, setCustomerCityOpts] = useState([]);
    const [expectedBranchOptions, setExpectedBranchOptions] = useState([]);
    const [expectedBranchStateOptions, setExpectedBranchStateOptions] = useState([]);
    const [expectedBranchCityOptions, setExpectedBranchCityOptions] = useState([]);
    const [assignToHead, setAssignToHead] = useState([]);
    const [assignToemployee, setAssignToemployee] = useState([]);

    const [approvedbyid, setApproved] = useState([]);
    const [adminstationOpts, setAdminstationOpts] = useState([]);
    const [productUnitOpts, setProductUnitOpts] = useState([]);
    const [followupbyId, setFollowupbyId] = useState([]);

    const [agentOpts, setAgentOpts] = useState([]);
    const [enquiryByIdOptions, setEnquiryByIdOptions] = useState([]);
    const [departmentOpts, setDepartmentOpts] = useState([])
    const [quotationByIdOptions, setQuotationByIdOptions] = useState([])
    const [quotationtypeOptions, setQuotationTypeOptions] = useState([])

    // Hooks for handle the accordions.
    const [openCustContAccord, setOpenCustContAccord] = useState(false);
    const [openDetailsAccord, setOpenDetailsAccord] = useState(keyForViewUpdate === 'followup' ? false : true);
    const [openQuotationTermsAccord, setOpenQuotationTermsAccord] = useState(false);
    const [openPaymentTermsAccord, setOpenPaymentTermsAccord] = useState(false);
    const [openFollowUpAccord, setOpenFollowUpAccord] = useState(keyForViewUpdate === 'followup' ? true : false);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    // Succ Msg
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Transactions/TQuotationService/FrmQuotationServiceListing`);
        }
    }
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    // Emailing Modal.
    const [showMailSentModal, setShowMailSentModal] = useState(false);

    // After approved mail sent confirmation modal
    const handleCloseMailSentModal = () => {
        setShowMailSentModal(false);
        moveToListing();
    }

    //Delete Popup modal.
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const FnCloseDeleteModal = () => setShowDeleteModal(false);

    // From am_company_settings.
    let expectedScheduleGraceDays = 0;

    // Accordion Collapsable hook.  0 means open 1 means closed     eventKey
    let [detailsAccordEventKey, setDetailsAccordEventKey] = useState(0);

    const FnGetExpectedScheduleGraceDate = (afterDays) => {
        let currentDate = new Date();
        const dtAfterGraceDays = new Date(currentDate);
        dtAfterGraceDays.setDate(currentDate.getDate() + afterDays);
        const year = dtAfterGraceDays.getFullYear();
        const month = String(dtAfterGraceDays.getMonth() + 1).padStart(2, '0');
        const day = String(dtAfterGraceDays.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // Bom Filter 
    const [bomfilteredData, setBomfilteredData] = useState([])
    const [showBomFilterForm, setShowBomFilterForm] = useState(false);

    // Bom Filter 
    let requiredColsForOpenFilter = ['product_type_short_name', 'product_id', 'product_name', 'product_tech_spect', 'product_hsn_sac_rate', 'product_hsn_sac_code', 'product_landed_price', 'process_duration',
        'product_unit_name', 'product_packing_name', 'product_moq', 'product_lead_time', 'product_mrp', 'product_std_weight', 'product_type_id', 'product_unit_id', 'product_packing_id', 'product_hsn_sac_code_id'
    ];

    const viewBomFilterForm = async () => {
        validateFields();
        let checkIsValidate = await validate.current.validateForm("quotationFormId");
        if (checkIsValidate === true && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            localStorage.setItem('requiredCols', JSON.stringify(requiredColsForOpenFilter));
            setShowBomFilterForm(true);
        }
    }

    // For navigate
    const navigate = useNavigate();
    const navigator = useNavigate();
    const [quotation_master_transaction_id, setQuotationMasterTransactionId] = useState(0);

    // Document Form
    const [docData, setDocumentData] = useState([]);
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const handleCloseDocumentForm = async () => {
        setShowDocumentForm(false);
        await showDocumentRecords();
    };
    const viewDocumentForm = () => setShowDocumentForm(true);

    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const todaydate = today();
    const [txt_quotation_date, setQuotation_date] = useState(today);
    const [dt_approved_date, setApprovedDate] = useState('');
    const [dt_overall_schedule_date, setOverAllScheduleDate] = useState(today);

    // Customers contact details table data hooks
    const [custContactDetails, setCustContactDetails] = useState([]);

    //Form Fields 
    const [checkedEnquiryOrderNoLength, setCheckedEnquiryOrderNoLength] = useState(0)
    const [txt_quotation_no, setQuotationno] = useState('');
    const [txt_quotation_version, setQuotationVersion] = useState(1);
    const [cmb_quotation_type_id, setQuotationTypeId] = useState(3);
    const [quotation_type, setQuotationType] = useState('SR');
    const [rb_quotation_life, setQuotationLife] = useState('C');
    const [rb_quotation_transaction_type, setQuotationTransactionType] = useState('M');
    const [cmb_customer_id, setCustomerId] = useState('');
    // const [txt_quotation_no, setEnquiryno] = useState(0);
    // const [txt_enquiry_version, setEnquiryVersion] = useState(1);
    // const [dt_enquiry_date, setEnquiryDate] = useState(today);
    const [cmb_department_id, setDepartmentId] = useState('');
    const [cmb_customer_state_id, setCustomerState] = useState('');
    const [cmb_customer_city_id, setCustomerCity] = useState('');
    const [cmb_expected_branch_id, setExpected_branch_id] = useState(COMPANY_BRANCH_ID)
    const [cmb_expected_branch_state_id, setExpected_branch_state_id] = useState("")
    const [cmb_expected_branch_city_id, setExpected_branch_city_id] = useState("")
    const [cmb_quotation_by_id, setQuotationById] = useState('');
    const [cmb_enquiry_by_id, setEnquiryById] = useState('');
    const [rb_tax_applicable, setTaxApplicable] = useState('E');
    const [rb_transportation_applicable, setTransportationApplicable] = useState('E');
    const [rb_bom_applicable, setBomApplicable] = useState('N');
    const [rb_costing_applicable, setCostingApplicable] = useState('N');
    const [cmb_assign_to_head_id, setAssigntoHeadId] = useState('');
    const [cmb_assign_to_id, setAssigToId] = useState('');
    const [cmb_approved_by_id, setApprovedById] = useState('');
    const [cmb_quotation_status, setQuotationStatus] = useState('P');
    const [txt_status_remark, setStatusremark] = useState('');

    //footer fild
    const [txt_quotation_material_cost, setQuotationMaterialCost] = useState(0);
    const [txt_quotation_process_cost, setQuotationProcessCost] = useState(0);
    const [txt_quotation_labour_cost, setQuotationLabourCost] = useState(0);
    const [txt_quotation_other_cost, setQuotationOtherCost] = useState(0);
    const [txt_quotation_other_cost_remark, setQuotationOtherCostRemark] = useState('');
    const [txt_quotation_administration_percent, setquotationAdministrationPercent] = useState(0);
    const [txt_quotation_administration_cost, setQuotationAdministrationCost] = useState(0);
    const [txt_quotation_profit_percent, setQuotationProfitPercent] = useState(0);
    const [txt_quotation_profit_cost, setQuotationProfitCost] = useState(0);
    const [txt_quotation_discount_percent, setQuotationDiscountPercent] = useState(0);
    const [txt_quotation_discount_cost, setQuotationDiscountCost] = useState(0);
    const [txt_quotation_taxable_cost, setQuotationTaxableCost] = useState(0);
    const [txt_quotation_cgst_cost, setQuotationCgstCost] = useState(0);
    const [txt_quotation_sgst_cost, setQuotationSgstCost] = useState(0);
    const [txt_quotation_igst_cost, setQuotationIgstCost] = useState(0);
    const [txt_quotation_total_cost, setQuotationTotalCost] = useState(0);
    const [rb_quotation_mail_sent_status, setQuotationMailSentStatus] = useState('P');
    const [chk_isactive, setIsActive] = useState('true');
    const [cmb_agent_id, setAgentId] = useState('');
    const [txt_agent_percent, setAgentPercent] = useState(0);
    const [txt_other_terms_conditions, setOtherTermsConditions] = useState('');
    const [txt_remark, setRemark] = useState('');

    //detels filds
    // const [cmb_product_fg_bom_no, setproduct_fg_bom_no] = useState('');
    // const [cmb_product_fg_bom_version, setproduct_fg_bom_version] = useState('');
    // const [cmb_product_costing_no, setProductCostingNo] = useState('');

    // Payment terms Table Data Hooks
    let [PmtTermsdata, setPmtTermsData] = useState([]);
    let [PmtTermsdataResp, setPmtTermsDataResp] = useState([]);

    // quotationterms Table Data
    const [QuotationTermsdata, setQuotationTermsdata] = useState([]);
    const [QuotationTermsdataResp, setQuotationTermsdataResp] = useState([]);

    // supplier contacts
    const [rowCount, setRowCount] = useState(1);

    //Enquiry Functionality Details Table Hooks
    const [functionalityrowcount, setFunctionalityrowcount] = useState(1);
    var existingFunctionRowCount = 1;

    //Quotation Followup fuctionality Table Hooks
    const [followuprowcount, setFollowuprowcount] = useState(1);
    var followuoFunctionRowCount = 1;


    function addDaysToDate(inputDate, numberOfDays) {
        var newDate = new Date(inputDate);
        newDate.setDate(newDate.getDate() + numberOfDays);
        var formattedDate = newDate.toISOString().substr(0, 10);
        return formattedDate;
    }

    useEffect(async () => {
        setIsLoading(true);
        try {
            FnSetFieldsByUserAccess();
            await fillComobos();
            await removeSessions();
            // await FnGetPaymentTerms();
            // await FnShowQuotationTermsRecords();
            if (updateData !== null) {
                await FnCheckUpdateResponce();
            } else {
                await fillAutoNo(quotation_type);
            }
            FnSetFieldsByUserAccess();
        } catch (error) {
            console.error(error);
            navigate('/Error')
        } finally {
            setIsLoading(false);
        }

        $(document).on('mouseup', function (e) {
            var container = $("#enquiry-order-ul");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
    }, [])

    const FnSetFieldsByUserAccess = () => {

        // Disable buttons.
        // $('#uploadDocBtn').attr('disabled', 'disabled');
        // User Access Manage.
        let userAccessKey = keyForViewUpdate
        setIsApprove(userAccessKey === 'approve' ? true : false);

        switch (userAccessKey) {

            case 'update':
                $('input[name="rb_quotation_transaction_type"]').prop('disabled', true);
                $('#submain_heading').append('');
                $('#submain_heading').text('(Modification)');
                $('#cmb_approved_by_id').attr('disabled', true);
                $('#dt_approved_date').attr('disabled', true);
                $('#cmb_quotation_type_id').attr('disabled', true);
                $('#clearFormBtn').attr('disabled', 'disabled');
                $('#cmb_customer_id').attr('disabled', true);
                // $('.erp_trRemove_icon').prop('disabled', true);
                // $('.erp_trRemove_icon').prop('disabled', true);
                $('#FollowupDataTable').find("input,textarea,select").attr("disabled", "disabled");
                $('#save-btn').text('Update');
                $('#save-btn').attr('BtnType', 'update');
                break;

            case 'view':
                $('input[name="rb_quotation_transaction_type"]').prop('disabled', true);
                $('#submain_heading').append('');
                if (deletionKey === true) {
                    $('#submain_heading').text('(Deletion)')
                } else {
                    $('#submain_heading').text('(View)')
                }
                setIsApprove(false)
                $("input[type=radio]").attr('disabled', true);
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $('#save-btn').attr('disabled', true);
                $('#clearFormBtn').attr('disabled', 'disabled');
                // $('#import-btn').attr('disabled', true);
                $('#export-btn').attr('disabled', true);
                // $('#import-btn').attr('disabled', true);
                $('#txt_schedule_date').attr('disabled', true);
                $('#viewdocument-id').attr('disabled', true);
                $('#chkAllCommonTerms').attr('disabled', true);
                $('#selectAllPmtTerms').attr('disabled', true);
                $('#selectAllCustContact').attr('disabled', true);
                $('#cmb_approved_by_id').attr('disabled', true);
                $("input[name=checkQuotationTerms]").attr('disabled', true);
                $('#common_parameters_expected_value_').attr('disabled', true);
                $("input[name=selectPmtTerm]").attr('disabled', true);
                $("input[name=selectCustContactPerson]").attr('disabled', true);
                //addReadOnlyAttr()

                break;
            case 'approve':
                $('input[name="rb_quotation_transaction_type"]').prop('disabled', true);
                setIsApprove(true)
                $('#submain_heading').append('');
                $('#submain_heading').text('(Approval)');
                //  addReadOnlyAttr()
                $('#save-btn').text('Approve');
                $('#save-btn').attr('BtnType', 'approve');
                $('#clearFormBtn').attr('disabled', 'disabled');
                $('#export-btn').attr('disabled', true);
                // $('#import-btn').attr('disabled', true);
                $('#viewdocument-id').attr('disabled', true);
                $('#chkAllCommonTerms').attr('disabled', true);
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $('#FollowupDataTable').find("input,textarea,select").attr("disabled", "disabled");
                // $('table .approveField').removeAttr("disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                $('table .approveField').removeAttr("disabled");
                $('#selectAllCustContact').attr('disabled', true);
                $("input[type=radio]").attr('disabled', true);
                $('#selectAllPmtTerms').attr('disabled', true);
                $("input[name=selectPmtTerm]").attr('disabled', true);
                $("input[name=checkQuotationTerms]").attr('disabled', true);
                $("input[name=selectCustContactPerson]").attr('disabled', true);
                setApprovedDate(today());
                $('#txt_status_remark').removeAttr("disabled");
                $('#common_parameters_expected_value_').attr('disabled', true);
                $('#txt_status_remark').removeAttr("readonly");
                $('#ExpectedFunctionality textarea').prop('disabled', true);
                break;

            case 'copy':
                $('#submain_heading').append('');
                $('#submain_heading').text('(Copy)');
                $('#cmb_approved_by_id').attr('disabled', true);
                $('#dt_approved_date').attr('disabled', true);
                $('#cmb_quotation_type_id').attr('disabled', true);
                $('#clearFormBtn').attr('disabled', 'disabled');
                $('#save-btn').text('Save');
                $('#save-btn').attr('BtnType', 'copy');
                break;

            case 'followup':
                $('input[name="rb_quotation_transaction_type"]').prop('disabled', true);
                setIscancelationFlag(false)
                $('#submain_heading').append('');
                $('#submain_heading').text('(Followup)')
                setIsApprove(false)
                $('#uploadDocBtn').attr('disabled', 'disabled');
                $("input[type=radio]").attr('disabled', true);
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $('#save-btn').attr('disabled', true);
                $('#clearFormBtn').attr('disabled', 'disabled');
                $('#export-btn').attr('disabled', true);
                // $('#import-btn').attr('disabled', true);
                $('#txt_schedule_date').attr('disabled', true);
                $('#viewdocument-id').attr('disabled', true);
                $('#chkAllCommonTerms').attr('disabled', true);
                $('#selectAllPmtTerms').attr('disabled', true);
                $('#selectAllCustContact').attr('disabled', true);
                $('#cmb_approved_by_id').attr('disabled', true);
                $("input[name=checkQuotationTerms]").attr('disabled', true);
                $("input[name=selectPmtTerm]").attr('disabled', true);
                $("input[name=selectCustContactPerson]").attr('disabled', true);
                $('#ExpectedFunctionality textarea').prop('disabled', true);
                //addReadOnlyAttr()

                break;
            default:
                break;

        }
    }

    const fillComobos = async () => {
        try {
            // Get Data From company settings.
            resetGlobalQuery();
            globalQuery.columns = ['so_schedule_grace_days', 'administration_percent', 'profit_percent'];
            globalQuery.table = "am_company_settings"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getScheduleGraceDaysApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
            expectedScheduleGraceDays = getScheduleGraceDaysApiCall.length === 0 ? 0 : getScheduleGraceDaysApiCall[0].so_schedule_grace_days;
            setOverAllScheduleDate(FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays));
            setAdminstationOpts(getScheduleGraceDaysApiCall[0]);

            // get the customer's data.
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'is_sez'];
            globalQuery.table = "cmv_customer"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getCustomersApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setCustomerOptions(getCustomersApiCall);

            // Get Department Data.
            const departmentIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_department", "is_delete", "0");
            setDepartmentOpts(departmentIdApiCall);
            // Set the default Department-option.
            const defaultDepartment = departmentIdApiCall.find(department => department.field_name === "Marketing Department");
            if (defaultDepartment) { // Set the default department.
                setDepartmentId(defaultDepartment.field_id);
                // Load the enquiry-by list.
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
                globalQuery.table = "cmv_employee"
                globalQuery.conditions.push({ field: "department_id", operator: "=", value: defaultDepartment.field_id });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                const getFilteredEmployeeList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setEnquiryByIdOptions(getFilteredEmployeeList)
            }

            // Load the employees data.
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
            globalQuery.table = "cmv_employee";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            const getEmployeeListApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setAssignToemployee(getEmployeeListApiCall);
            setAssignToHead(getEmployeeListApiCall);
            setApproved(getEmployeeListApiCall);
            setQuotationByIdOptions(getEmployeeListApiCall);
            setFollowupbyId(getEmployeeListApiCall);

            // Set the logged in employee as a assigned employee and it's head employee.
            setAssigToId(parseInt(UserId));
            setQuotationById(parseInt(UserId));
            const defaultAssignedEmployee = getEmployeeListApiCall.find(employee => parseInt(employee.field_id) === parseInt(UserId));
            if (defaultAssignedEmployee) {   // Set Head Name for Assigned Employee. // reporting_to
                setAssigntoHeadId(defaultAssignedEmployee.reporting_to);
            }

            // const getsetAssignToHeadApiCall = await comboDataAPiCall.current.fillMasterData("cmv_employee", "is_delete", "0");
            // setAssignToHead(getsetAssignToHeadApiCall);
            // const getsetAssignToemployeeApiCall = await comboDataAPiCall.current.fillMasterData("cmv_employee", "is_delete", "0");
            // setAssignToemployee(getsetAssignToemployeeApiCall);
            // const getsetApprovedCall = await comboDataAPiCall.current.fillMasterData("cmv_employee", "is_delete", "0");
            // setApproved(getsetApprovedCall);
            // const getfollowupByIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_employee", "is_delete", "0");
            // setFollowupbyId(getfollowupByIdApiCall);
            // const enquirybyIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_employee", "is_delete", "0");
            // setEnquiryByIdOptions(enquirybyIdApiCall)
            // const quotationbyIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_employee", "is_delete", "0");
            // setQuotationByIdOptions(quotationbyIdApiCall);


            // resetGlobalQuery();
            // globalQuery.columns = ['field_id', 'field_name', 'product_type_short_name'];
            // globalQuery.table = "smv_product_type"
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // const quotationTYpeApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            // setQuotationTypeOptions(quotationTYpeApiCall);

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'state_code']
            globalQuery.table = "cmv_state"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getStatesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setStateOpts(getStatesApiCall);
            setExpectedBranchStateOptions(getStatesApiCall);

            resetGlobalQuery();
            globalQuery.columns = ['company_branch_id', 'company_branch_name', 'branch_state_id', 'state_name', 'branch_city_id', 'city_name', 'is_sez', 'branch_EmailId'];
            globalQuery.table = "cmv_company_branch";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            const exptBranchApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setExpectedBranchOptions(exptBranchApiCall);
            if (exptBranchApiCall !== null) {
                setExpected_branch_state_id(exptBranchApiCall[0].branch_state_id)
                const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + exptBranchApiCall[0].branch_state_id + "");
                setExpectedBranchCityOptions(cityApiCall);
                setExpected_branch_city_id(exptBranchApiCall[0].branch_city_id)
            }

            // const getsetAgentToHeadApiCall = await comboDataAPiCall.current.fillMasterData("cmv_agent", "is_delete", "0");
            // setAgentOpts(getsetAgentToHeadApiCall);
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'agent_std_percent', 'agent_EmailId']
            globalQuery.table = "cmv_agent";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            const getAgentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setAgentOpts(getAgentApiCall);
            // Set the Default Agent as NA and load it's releated %.
            const defaultAgent = getAgentApiCall.find(agent => agent.field_name === 'NA');
            if (defaultAgent) {
                setAgentId(defaultAgent.field_id);
                setAgentPercent(defaultAgent.agent_std_percent);
            }

            const getProductUnitsApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_unit", "is_delete", "0");
            setProductUnitOpts(getProductUnitsApiCall);

        } catch (error) {
            console.log("error: ", error)
        }
    }



    // ----------------------------- Add New Records Functionality Starts -------------------------------
    const [showAddRecQuotationModal, setShowAddRecQuotationModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('');

    const displayRecordQuotationComponent = () => {
        switch (modalHeaderName) {
            case 'Quotation Type':
                return <FrmMProductTypeEntry btn_disabled={true} />;

            case 'Enquiry By':
                return <FrmMEmployeesEntry btn_disabled={true} />;

            case 'Department':
                return <FrmDepartmentEntry btn_disabled={true} />;

            case 'Add Customer City':
                return <FrmCity btn_disabled={true} />;

            case 'Common Parameter':
                return <CommonParamterEntry btn_disabled={true} master_name={commonTermMstName} />;

            case 'Payment Terms':
                return <FrmPaymentTermsEntry btn_disabled={true} />

            default:
                return null;
        }
    }

    const closeRecordAddQuotationModal = async () => {
        switch (modalHeaderName) {
            // case 'Quotation Type':
            //     resetGlobalQuery();
            //     globalQuery.columns = ['field_id', 'field_name', 'product_type_short_name'];
            //     globalQuery.table = "smv_product_type"
            //     globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            //     const quotationTYpeApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
            //     setQuotationTypeOptions(quotationTYpeApiCall)
            //     break;

            case 'Add Customer City':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name'];
                globalQuery.table = "cmv_city"
                // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(cmb_customer_state_id) });
                const getCustomerUpdatedCities = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setCustomerCityOpts(getCustomerUpdatedCities);
                break;

            // case 'Add Department':
            //     resetGlobalQuery();
            //     globalQuery.columns = ['field_id', 'field_name'];
            //     globalQuery.table = "cmv_department";
            //     globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            //     globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            //     const getUpdatedDepartments = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
            //     setDeptOpts(getUpdatedDepartments);
            //     break;

            case 'Department':
                const departmentIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_department", "", "")
                setDepartmentOpts(departmentIdApiCall)
                break;

            case 'Common Parameter':
                await FnShowQuotationTermsRecords();
                break;

            case 'Payment Terms':
                await FnShowPaymentTermsRecrds();

            default:
                break;
        }
        setShowAddRecQuotationModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
    }

    // ----------------------------- Add New Records Functionality Ends. -------------------------------

    const FnCheckUpdateResponce = async () => {

        let todayDateForInputField = today;
        let defaultExpecScheduleDate = FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays);

        let quotation_no;
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesQuotationMasterServices/FnShowAllDetailsAndMastermodelRecords/${updateData.quotation_version}/${updateData.financial_year}/${COMPANY_ID}?quotation_no=${updateData.quotation_no}`)
            const responce = await apiCall.json();
            if (responce.SalesQtMasterServicesRecords !== null) {
                let SalesQtMasterServicesRecords = responce.SalesQtMasterServicesRecords;

                if (SalesQtMasterServicesRecords !== null && SalesQtMasterServicesRecords !== "") {
                    setQuotationMasterTransactionId(SalesQtMasterServicesRecords.quotation_master_transaction_id);
                    setQuotationno(SalesQtMasterServicesRecords.quotation_no);
                    setQuotationVersion(SalesQtMasterServicesRecords.quotation_version);
                    setQuotationTypeId(SalesQtMasterServicesRecords.quotation_type_id);
                    setQuotationLife(SalesQtMasterServicesRecords.quotation_life);
                    setQuotationTransactionType(SalesQtMasterServicesRecords.quotation_transaction_type);
                    // setEnquiryno(SalesQtMasterServicesRecords.enquiry_no);
                    // setEnquiryVersion(SalesQtMasterServicesRecords.enquiry_version);
                    // setEnquiryDate(SalesQtMasterServicesRecords.enquiry_date);
                    setCustomerId(SalesQtMasterServicesRecords.customer_id);

                    // Load the customer state and city.   
                    let custContactData = await FnGetCustomersContactPersonsDetails(SalesQtMasterServicesRecords.customer_id);
                    const customerContactPersonsIds = SalesQtMasterServicesRecords.customer_contacts_ids.split(":").map(Number);
                    // Set the checked checkboxes first.
                    const rearrangedContactsRows = [
                        ...custContactData.filter(contact => customerContactPersonsIds.includes(contact.customer_contact_id)),
                        ...custContactData.filter(contact => !customerContactPersonsIds.includes(contact.customer_contact_id)),
                    ];
                    setCustContactDetails(rearrangedContactsRows);

                    setCustomerState(SalesQtMasterServicesRecords.customer_state_id);
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name']
                    globalQuery.table = "cmv_city"
                    // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "state_id", operator: "=", value: SalesQtMasterServicesRecords.customer_state_id });
                    const getCustomerCitiesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setCustomerCityOpts(getCustomerCitiesApiCall);
                    // await comboOnChange('Customer');
                    setCustomerCity(SalesQtMasterServicesRecords.customer_city_id);
                    await FnSetExistingCustomerContactPersonIds(SalesQtMasterServicesRecords.customer_contacts_ids);

                    setQuotationById(SalesQtMasterServicesRecords.quotation_by_id);
                    setExpected_branch_id(SalesQtMasterServicesRecords.expected_branch_id);
                    setExpected_branch_state_id(SalesQtMasterServicesRecords.expected_branch_state_id);
                    const exptBranchcityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", SalesQtMasterServicesRecords.expected_branch_state_id);
                    setExpectedBranchCityOptions(exptBranchcityApiCall);
                    setExpected_branch_city_id(SalesQtMasterServicesRecords.expected_branch_city_id);

                    setDepartmentId(SalesQtMasterServicesRecords.department_id);
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
                    globalQuery.table = "cmv_employee"
                    globalQuery.conditions.push({ field: "department_id", operator: "=", value: SalesQtMasterServicesRecords.department_id });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    const getFilteredEmployeeList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setEnquiryByIdOptions(getFilteredEmployeeList)

                    setEnquiryById(SalesQtMasterServicesRecords.enquiry_by_id);
                    setAssigToId(SalesQtMasterServicesRecords.assign_to_id);
                    setAssigntoHeadId(SalesQtMasterServicesRecords.assign_to_head_id);

                    if (keyForViewUpdate === 'approve') {
                        setQuotationStatus('A');
                    } else {
                        setQuotationStatus(SalesQtMasterServicesRecords.quotation_status);
                    }
                    if (keyForViewUpdate === 'approve' && (SalesQtMasterServicesRecords.approved_by_id === 0 || SalesQtMasterServicesRecords.approved_by_id === null)) {
                        setApprovedById(parseInt(UserId));
                    } else {
                        setApprovedById(SalesQtMasterServicesRecords.approved_by_id);
                    }
                    setStatusremark(SalesQtMasterServicesRecords.status_remark);
                    setApprovedDate(SalesQtMasterServicesRecords.approved_date);
                    setQuotation_date(SalesQtMasterServicesRecords.quotation_date);
                    setOverAllScheduleDate(SalesQtMasterServicesRecords.overall_schedule_date);
                    setTaxApplicable(SalesQtMasterServicesRecords.tax_applicable);
                    setTransportationApplicable(SalesQtMasterServicesRecords.transportation_applicable);
                    setBomApplicable(SalesQtMasterServicesRecords.bom_applicable);
                    setCostingApplicable(SalesQtMasterServicesRecords.costing_applicable);



                    //footerfileds
                    setQuotationMaterialCost(SalesQtMasterServicesRecords.quotation_material_cost);
                    setQuotationProcessCost(SalesQtMasterServicesRecords.quotation_process_cost);
                    setQuotationLabourCost(SalesQtMasterServicesRecords.quotation_labour_cost);
                    setQuotationOtherCost(SalesQtMasterServicesRecords.quotation_other_cost);
                    setQuotationOtherCostRemark(SalesQtMasterServicesRecords.quotation_other_cost_remark);
                    setquotationAdministrationPercent(SalesQtMasterServicesRecords.quotation_administration_percent);
                    setQuotationAdministrationCost(SalesQtMasterServicesRecords.quotation_administration_cost);
                    setQuotationProfitPercent(SalesQtMasterServicesRecords.quotation_profit_percent);
                    setQuotationProfitCost(SalesQtMasterServicesRecords.quotation_profit_cost);
                    setQuotationDiscountPercent(SalesQtMasterServicesRecords.quotation_discount_percent);
                    setQuotationDiscountCost(SalesQtMasterServicesRecords.quotation_discount_cost);
                    setQuotationTaxableCost(SalesQtMasterServicesRecords.quotation_taxable_cost);
                    setQuotationCgstCost(SalesQtMasterServicesRecords.quotation_cgst_cost);
                    setQuotationSgstCost(SalesQtMasterServicesRecords.quotation_sgst_cost);
                    setQuotationIgstCost(SalesQtMasterServicesRecords.quotation_igst_cost);
                    setQuotationTotalCost(SalesQtMasterServicesRecords.quotation_total_cost);
                    setAgentId(SalesQtMasterServicesRecords.agent_id);
                    setAgentPercent(SalesQtMasterServicesRecords.agent_percent);
                    setOtherTermsConditions(SalesQtMasterServicesRecords.other_terms_conditions);


                    setIsActive(SalesQtMasterServicesRecords.is_active.toString());
                    setRemark(SalesQtMasterServicesRecords.remark);
                    setQuotationMailSentStatus(SalesQtMasterServicesRecords.quotation_mail_sent_status);
                    //deteails filds
                    // setProductCostingNo(SalesQtMasterServicesRecords.cmb_product_costing_no);
                    // setproduct_fg_bom_no(SalesQtMasterServicesRecords.cmb_product_fg_bom_no);
                    // setproduct_fg_bom_version(SalesQtMasterServicesRecords.cmb_product_fg_bom_version);

                    if (keyForViewUpdate === 'copy') {
                        quotation_no = await fillAutoNo(quotation_type);
                        setQuotationMasterTransactionId(0);
                        setQuotation_date(todayDateForInputField);
                        setQuotationStatus('P');
                        setOverAllScheduleDate(defaultExpecScheduleDate);
                        setApprovedById('');
                        setApprovedDate('');
                        setStatusremark('');
                        setQuotationVersion(1);
                        setQuotationMailSentStatus('P');
                    }
                }

                // Set Quotation Payment terms data.
                if (responce.QuotationPaymentTermsRecord !== null) {
                    setPmtTermsDataResp(responce.QuotationPaymentTermsRecord);
                }

                // Function to update Quotation Terms responce.QuotationTermsTradingRecords
                if (responce.QuotationTermsTradingRecords !== null) {
                    // mark the checked checkboxes for order common term.
                    setQuotationTermsdataResp(responce.QuotationTermsTradingRecords);
                    responce.QuotationTermsTradingRecords.forEach(function (existingCommonTerm) {
                        $('#checkQuotationTerms_' + existingCommonTerm.quotation_terms_parameters_id).prop('checked', true);
                    });
                }

                // QuotationFollowupData update
                if (responce.QuotationFollowupTradingDetails.length !== 0) {
                    setFollowupdetailsData(responce.QuotationFollowupTradingDetails)
                } else {
                    const getExistingFollowupData = [...followupdetailsData]
                    getExistingFollowupData.push(followUpBlankObj)
                    setFollowupdetailsData(getExistingFollowupData)
                }

                // QuotationExistingExpectedFunctionalityData update
                if (responce.QuotationExistingExpectedFucntionalityRecords.length !== 0) {
                    setExistingFunctionalityTblData(responce.QuotationExistingExpectedFucntionalityRecords);
                } else {
                    const getExistingFollowupData = [...existingFunctionalityTblData]
                    getExistingFollowupData.push(existingFunctionalityBlankObj)
                    setExistingFunctionalityTblData(getExistingFollowupData);
                }

                if (responce.SalesQtDetailsServicesRecords !== null) {
                    let updatedBomFilteredDataArray = responce.SalesQtDetailsServicesRecords;
                    if (keyForViewUpdate === 'copy') {
                        // Update the data for copied sales order.
                        updatedBomFilteredDataArray = responce.SalesQtDetailsServicesRecords.map((ordDetail) => ({
                            ...ordDetail,
                            quotation_item_status: 'P',
                            quotation_master_transaction_id: 0,
                            quotation_details_transaction_id: 0,
                            quotation_no: quotation_no,
                            material_item_approval_remark: '',
                            quotation_date: todayDateForInputField,
                            quotation_version: 1,
                            product_id: ordDetail.product_material_id,
                            material_expected_schedule_date: defaultExpecScheduleDate,
                            product_material_name: ordDetail.product_sr_name,
                            product_material_hsn_sac_code: ordDetail.product_sr_hsn_sac_code,
                            hsn_sac_percent: ordDetail.product_sr_hsn_sac_rate,
                        }));
                    } else if (keyForViewUpdate === 'approve') {
                        updatedBomFilteredDataArray = responce.SalesQtDetailsServicesRecords.map((ordDetail) => ({
                            ...ordDetail,
                            quotation_item_status: ordDetail.quotation_item_status === 'P'
                                ? "A"
                                : ordDetail.quotation_item_status,
                            product_id: ordDetail.product_material_id,
                            product_material_name: ordDetail.product_sr_name,
                            product_material_hsn_sac_code: ordDetail.product_sr_hsn_sac_code,
                            hsn_sac_percent: ordDetail.product_sr_hsn_sac_rate,
                        }));
                    } else {
                        updatedBomFilteredDataArray = responce.SalesQtDetailsServicesRecords.map((ordDetail) => ({
                            ...ordDetail,
                            product_material_name: ordDetail.product_sr_name,
                            product_material_hsn_sac_code: ordDetail.product_sr_hsn_sac_code,
                            hsn_sac_percent: ordDetail.product_sr_hsn_sac_rate,
                            product_id: ordDetail.product_material_id,
                        }));
                    }
                    setBomfilteredData(updatedBomFilteredDataArray);
                    localStorage.setItem('filteredMaterialData', JSON.stringify(updatedBomFilteredDataArray))
                    FnSetFieldsByUserAccess();
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    // -------------------------------start Add the materials from the filter modal. ------------
    const FnCloseFilterModal = () => {
        setShowBomFilterForm(false)
        const getData = JSON.parse(localStorage.getItem('filteredMaterialData')) || [];
        if (getData.length !== 0) {
            setBomfilteredData((prevArray) => {
                const updatedArray = [
                    ...prevArray,
                    ...getData.filter(item => !prevArray.some(prevItem => prevItem.product_id === item.product_id)).map((newMaterial) => {
                        let convertedMaterialData = {
                            ...newMaterial,
                            product_material_id: newMaterial.product_id,
                            product_type_short_name: newMaterial.product_type_short_name,
                            product_material_name: newMaterial.product_name,
                            product_material_print_name: newMaterial.product_name,
                            product_material_tech_spect: newMaterial.product_tech_spect,
                            hsn_sac_id: newMaterial.product_hsn_sac_code_id,
                            product_material_hsn_sac_code: newMaterial.product_hsn_sac_code,
                            hsn_sac_percent: newMaterial.product_hsn_sac_rate,
                            process_duration: newMaterial.process_duration,
                            product_material_unit_id: newMaterial.product_unit_id,          // id
                            product_material_unit_name: newMaterial.product_unit_name,
                            product_material_packing_id: newMaterial.product_packing_id,    // id
                            product_packing_name: newMaterial.product_packing_name,
                            product_material_moq_quantity: newMaterial.product_moq,
                            product_material_quotation_quantity: 1,
                            material_std_rate: 0,
                            item_material_cost: 0,
                            item_taxable_cost: 0,
                            item_cgst_percent: 0,
                            item_cgst_cost: 0,
                            item_sgst_percent: 0,
                            item_sgst_cost: 0,
                            item_igst_percent: 0,
                            item_igst_cost: 0,
                            item_total_cost: 0,
                            product_material_notes: '',
                            expected_lead_time: newMaterial.product_lead_time,
                            material_expected_schedule_date: dt_overall_schedule_date,
                            quotation_item_status: 'P',
                            material_item_approval_remark: '',
                            item_process_cost: 0,
                            item_labour_cost: 0,
                            item_administration_percent: 0,
                            item_administration_cost: 0,
                            item_profit_percent: 0,
                            item_profit_cost: 0,
                            item_other_cost: 0,
                            item_other_cost_remark: '',
                            item_discount_percent: 0,
                            item_discount_cost: 0,
                            item_terms_conditions: '',
                            item_remark: '',
                            enquiry_no: '',
                            enquiry_version: '',
                            product_material_std_weight: newMaterial.product_std_weight,
                            product_material_quotation_weight: newMaterial.product_std_weight,
                            product_material_conversion_factor: 1,     // change it after discuss with prashant sir.
                        };
                        FnMaterialWiseCalculations(convertedMaterialData);
                        return convertedMaterialData;
                    })
                ];
                localStorage.setItem('filteredMaterialData', JSON.stringify(updatedArray));
                return updatedArray;
            });
            sessionStorage.setItem('isComboFilterExist', false)
            // Remove Bom Filter Session
            let LocalArr = JSON.parse(localStorage.getItem(`localStorageKeyArr`)) || [];
            if (LocalArr.length > 0) {
                for (let localArrIndex = 0; localArrIndex < LocalArr.length; localArrIndex++) {
                    localStorage.removeItem(LocalArr[localArrIndex])
                }
            }
        } else {
            setBomfilteredData([])
            localStorage.setItem('filteredMaterialData', JSON.stringify([]));
        }
        removeSessions();
    };
    // -------------------------------Ends Add the materials from the filter modal. ------------


    // --------------------start functionality for Manage the changes in the quotation details table.---------
    const FnUpdateDetailsTblData = (currentRowData, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        const excludedColumnsInCalculation = ['product_material_print_name', 'product_material_unit_id', 'product_material_tech_spect', 'product_material_notes', 'material_expected_schedule_date', 'material_item_approval_remark', 'item_other_cost_remark', 'item_terms_conditions', 'quotation_item_status', 'item_remark',];

        let updateCalculations = false;
        let inputValue = event.target.value;

        if (!excludedColumnsInCalculation.includes(clickedColName)) {
            inputValue = validateNumberDateInput.current.decimalNumber(inputValue.toString(), 4) || 0;
        }
        delete event.target.parentElement.dataset.tip;
        switch (clickedColName) {
            case 'product_material_print_name':
                currentRowData[clickedColName] = inputValue;
                break;

            case 'product_material_quotation_quantity':
                if (event._reactName === 'onChange') {
                    currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                    let calculationenquiryWt = currentRowData.product_material_std_weight * parseFloat(inputValue);
                    currentRowData['product_material_quotation_weight'] = validateNumberDateInput.current.decimalNumber(calculationenquiryWt.toString(), 4);
                    delete event.target.parentElement.dataset.tip;
                } else if ((isNaN(parseFloat(event.target.value)) || parseFloat(event.target.value) === 0) && event._reactName === 'onBlur') {
                    event.target.parentElement.dataset.tip = 'Service quantity should not be a zero or blank...!'
                    $(`#${event.target.id}`).focus();
                }
                updateCalculations = true;
                break;

            case 'material_std_rate':
                currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value.toString(), 4);
                updateCalculations = true;
                break;

            // case 'item_administration_percent':
            //     let isValidPercentageInput = validatePercentageInput(inputValue);
            //     if (isValidPercentageInput === true) {
            //         currentRowData.item_administration_percent = inputValue;
            //         delete event.target.parentElement.dataset.tip;
            //     } else {
            //         currentRowData[clickedColName] = currentRowData.item_administration_percent;
            //         event.target.parentElement.dataset.tip = isValidPercentageInput;
            //     }
            //     updateCalculations = true;
            //     break;

            // case 'item_profit_percent':
            //     let isValidPercentageInput1 = validatePercentageInput(inputValue);
            //     if (isValidPercentageInput1 === true) {
            //         currentRowData.item_profit_percent = inputValue;
            //         delete event.target.parentElement.dataset.tip;
            //     } else {
            //         currentRowData[clickedColName] = currentRowData.item_profit_percent;
            //         event.target.parentElement.dataset.tip = isValidPercentageInput1;
            //     }
            //     updateCalculations = true;
            //     break;

            case 'item_discount_percent':
                let isValidPercentageInput2 = validatePercentageInput(inputValue);
                if (isValidPercentageInput2 === true) {
                    currentRowData.item_discount_percent = inputValue;
                    delete event.target.parentElement.dataset.tip;
                } else {
                    currentRowData[clickedColName] = currentRowData.item_discount_percent;
                    event.target.parentElement.dataset.tip = isValidPercentageInput2;
                }
                updateCalculations = true;
                break;

            case 'item_material_cost':
            case 'item_process_cost':
            case 'item_labour_cost':
            case 'item_other_cost':
                currentRowData.item_other_cost = inputValue;
                updateCalculations = true;
                break;

            case 'product_material_unit_id':
                currentRowData[clickedColName] = inputValue;
                currentRowData['product_material_unit_name'] = event.target.selectedOptions[0].getAttribute('lbl');
                break;

            case 'material_expected_schedule_date':
                currentRowData[clickedColName] = inputValue;
                break;

            case 'quotation_item_status':
                currentRowData[clickedColName] = inputValue;
                if (inputValue.trim() === 'P') {
                    let inputFld = document.getElementById('material_item_approval_remark_' + currentRowData.product_material_id)
                    delete inputFld.parentElement.dataset.tip;
                }
                break;

            case 'material_item_approval_remark':
            case 'product_material_tech_spect':
            case 'product_fg_bom_no':
            case 'product_costing_no':
            case 'product_fg_bom_version':
            case 'item_other_cost_remark':
            case 'product_material_notes':
            case 'item_remark':
            case 'item_terms_conditions':
                currentRowData[clickedColName] = inputValue;
                break;

            default:
                break;
        }
        if ((updateCalculations || event._reactName === 'onBlur') && !excludedColumnsInCalculation.includes(clickedColName)) {
            currentRowData = FnMaterialWiseCalculations(currentRowData);
            const updatedDetailsData = [...bomfilteredData]
            const itemArrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
            updatedDetailsData[itemArrayIndex] = currentRowData;
            calculateGrandTotal(updatedDetailsData);
            setBomfilteredData(updatedDetailsData);
            localStorage.setItem('filteredMaterialData', JSON.stringify(updatedDetailsData));
            FnCalculateDiscSum(updatedDetailsData);
        } else {
            const updatedDetailsData = [...bomfilteredData]
            const itemArrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
            updatedDetailsData[itemArrayIndex] = currentRowData;
            setBomfilteredData(updatedDetailsData);
        }
    }
    // --------------------Ends functionality for Manage the changes in the quotation details table.---------

    // -----------------------Start Details Table Calculation functionality.-----------------
    const FnMaterialWiseCalculations = (currentRowData) => {
        let materialcost = isNaN(parseFloat(currentRowData.material_std_rate)) === NaN ? 0 : parseFloat(currentRowData.material_std_rate);
        let quantity = isNaN(parseFloat(currentRowData.product_material_quotation_quantity)) ? 0 : parseFloat(currentRowData.product_material_quotation_quantity)
        let discPercent = isNaN(parseInt(currentRowData.item_discount_percent)) === NaN ? 0 : parseInt(currentRowData.item_discount_percent);
        let item_discount_cost = (((materialcost) * discPercent) / 100)
        let item_taxable_cost = (materialcost - item_discount_cost) * quantity
        currentRowData['item_taxable_cost'] = validateNumberDateInput.current.decimalNumber(item_taxable_cost.toString(), 4);
        currentRowData['item_discount_cost'] = validateNumberDateInput.current.decimalNumber(item_discount_cost.toString(), 4);

        // let materialcost = isNaN(parseFloat(currentRowData.item_material_cost)) === NaN ? 0 : parseFloat(currentRowData.item_material_cost);
        // let process_cost = isNaN(parseFloat(currentRowData.item_process_cost)) === NaN ? 0 : parseFloat(currentRowData.item_process_cost);
        // let labour_cost = isNaN(parseFloat(currentRowData.item_labour_cost)) === NaN ? 0 : parseFloat(currentRowData.item_labour_cost);
        // let other_cost = isNaN(parseFloat(currentRowData.item_other_cost)) === NaN ? 0 : parseFloat(currentRowData.item_other_cost);
        // let administration_percent = isNaN(parseFloat(currentRowData.item_administration_percent)) === NaN ? 0 : parseFloat(currentRowData.item_administration_percent);
        // let profit_percent = isNaN(parseFloat(currentRowData.item_profit_percent)) === NaN ? 0 : parseFloat(currentRowData.item_profit_percent);
        // let quantity = isNaN(parseFloat(currentRowData.product_material_quotation_quantity)) ? 0 : parseFloat(currentRowData.product_material_quotation_quantity)
        // let discPercent = isNaN(parseInt(currentRowData.item_discount_percent)) === NaN ? 0 : parseInt(currentRowData.item_discount_percent);
        // let item_administration_cost = ((materialcost + process_cost + labour_cost + other_cost) * administration_percent) / 100
        // let item_profit_cost = ((materialcost + process_cost + labour_cost + other_cost + item_administration_cost) * profit_percent) / 100
        // let item_discount_cost = ((materialcost + process_cost + labour_cost + other_cost + item_administration_cost + item_profit_cost) * discPercent) / 100
        // let material_std_rate = (materialcost + process_cost + labour_cost + other_cost + item_administration_cost + item_profit_cost - item_discount_cost)
        // let item_taxable_cost = material_std_rate * quantity
        // currentRowData['item_administration_cost'] = validateNumberDateInput.current.decimalNumber(item_administration_cost.toString(), 4);
        // currentRowData['item_profit_cost'] = validateNumberDateInput.current.decimalNumber(item_profit_cost.toString(), 4);
        // currentRowData['item_discount_cost'] = validateNumberDateInput.current.decimalNumber(item_discount_cost.toString(), 4);
        // currentRowData['material_std_rate'] = validateNumberDateInput.current.decimalNumber(material_std_rate.toString(), 4);
        // currentRowData['item_taxable_cost'] = validateNumberDateInput.current.decimalNumber(item_taxable_cost.toString(), 4);
        FnCalculateMaterialTaxation(currentRowData)
        return currentRowData;
    };

    // ----- If user entered the material rate then set the std_rate.
    const FnUpdateMaterialCalculationAsPerStdRate = (currentMaterial) => {
        let materialStdRate = isNaN(parseFloat(currentMaterial.material_std_rate)) === NaN ? 0 : parseFloat(currentMaterial.material_std_rate);
        let quantity = isNaN(parseFloat(currentMaterial.product_material_quotation_quantity)) ? 0 : parseFloat(currentMaterial.product_material_quotation_quantity)

        let materialTaxableAmt = materialStdRate * quantity
        currentMaterial['item_other_cost'] = validateNumberDateInput.current.decimalNumber(materialStdRate.toString(), 4);
        currentMaterial['item_process_cost'] =
            currentMaterial['item_material_cost'] = 0;
        currentMaterial['item_labour_cost'] = 0;
        currentMaterial['item_administration_percent'] = 0;
        currentMaterial['item_administration_cost'] = 0;
        currentMaterial['item_profit_percent'] = 0;
        currentMaterial['item_profit_cost'] = 0;
        currentMaterial['item_discount_percent'] = 0;
        currentMaterial['item_discount_cost'] = 0;
        currentMaterial['item_taxable_cost'] = validateNumberDateInput.current.decimalNumber(materialTaxableAmt.toString(), 4);
        FnCalculateMaterialTaxation(currentMaterial)
        return currentMaterial;
    }

    const FnCalculateMaterialTaxation = (currentMaterial) => {
        let custState = parseInt($('#cmb_customer_state_id').val());
        let custStateCode = parseInt($('#cmb_customer_state_id option:selected').attr('stateCode'));
        let companyBranchStateId = parseInt($('#cmb_expected_branch_id').val());
        let customerIsSez = $('#cmb_customer_id option:selected').attr('is_sez');
        let appliedHSNPercent = isNaN(parseFloat(currentMaterial.hsn_sac_percent)) === NaN ? 0 : parseFloat(currentMaterial.hsn_sac_percent);
        let materialTaxableAmt = isNaN(parseFloat(currentMaterial.item_taxable_cost)) ? 0 : parseFloat(currentMaterial.item_taxable_cost)

        if (!isNaN(custState)) {
            let total_amount = 0;
            let cgst_percent = 0; let cgst_cost = 0; let sgst_percent = 0;
            let sgst_cost = 0; let igst_percent = 0; let igst_cost = 0;

            let item_total_cost = 0;
            if (custStateCode === 99) {
                cgst_percent = 0; cgst_cost = 0; sgst_percent = 0; sgst_cost = 0; igst_percent = 0; igst_cost = 0;
                item_total_cost = materialTaxableAmt

            } else if (customerIsSez === 'true') {
                cgst_percent = 0; cgst_cost = 0; sgst_percent = 0; sgst_cost = 0; igst_percent = 0; igst_cost = 0;
                item_total_cost = materialTaxableAmt

            } else if (companyBranchStateId === custState) {
                igst_percent = 0; igst_cost = 0;
                cgst_percent = appliedHSNPercent / 2;
                sgst_percent = appliedHSNPercent / 2;
                cgst_cost = (materialTaxableAmt * cgst_percent / 100)
                sgst_cost = (materialTaxableAmt * sgst_percent / 100)
                item_total_cost = materialTaxableAmt + cgst_cost + sgst_cost + igst_cost

            } else {
                sgst_percent = 0; cgst_percent = 0; cgst_cost = 0; sgst_cost = 0;
                igst_percent = appliedHSNPercent;
                igst_cost = (materialTaxableAmt * appliedHSNPercent) / 100;
                item_total_cost = materialTaxableAmt + igst_cost
            }

            currentMaterial['item_total_cost'] = validateNumberDateInput.current.decimalNumber((item_total_cost).toString(), 4);
            currentMaterial['item_cgst_percent'] = validateNumberDateInput.current.decimalNumber((cgst_percent).toString(), 4);
            currentMaterial['item_cgst_cost'] = validateNumberDateInput.current.decimalNumber((cgst_cost).toString(), 4);
            currentMaterial['item_sgst_percent'] = validateNumberDateInput.current.decimalNumber((sgst_percent).toString(), 4);
            currentMaterial['item_sgst_cost'] = validateNumberDateInput.current.decimalNumber((sgst_cost).toString(), 4);
            currentMaterial['item_igst_percent'] = validateNumberDateInput.current.decimalNumber((igst_percent).toString(), 4);
            currentMaterial['item_igst_cost'] = validateNumberDateInput.current.decimalNumber((igst_cost).toString(), 4);

        } else {
            $('#error_cmb_customer_state_id').text('Please select customer state...!');
            $('#error_cmb_customer_state_id').show();
        }
        return currentMaterial;
    }
    // -----------------------Ends Details Table Calculation functionality.-----------------

    // Fn for sum of discount percent and amount on material chabge
    const FnCalculateDiscSum = (quotationDetailsData) => {
        if (quotationDetailsData.length > 0) {
            let quotation_material_cost = quotationDetailsData.reduce(function (sum, item) { return sum + (parseFloat(item.material_std_rate * item.product_material_quotation_quantity) || 0); }, 0);
            let material_discount_amount = parseFloat(quotationDetailsData.reduce((total, item) => total + parseFloat(item.item_discount_cost * item.product_material_quotation_quantity), 0));
            let material_discount_percent = (material_discount_amount * 100) / quotation_material_cost;
            setQuotationDiscountPercent(validateNumberDateInput.current.decimalNumber((material_discount_percent).toString(), 2));
            setQuotationDiscountCost(validateNumberDateInput.current.decimalNumber((material_discount_amount).toString(), 4));
        } else {
            setQuotationDiscountPercent(0);
            setQuotationDiscountCost(0);
        }
    }

    const comboOnChange = async (key) => {
        switch (key) {
            case 'departmentId':
                var departmentIdVal = document.getElementById('cmb_department_id').value;
                setDepartmentId(departmentIdVal)
                if (departmentIdVal === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Department')
                    setShowAddRecQuotationModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                if (departmentIdVal !== "" && departmentIdVal !== "0") {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
                    globalQuery.table = "cmv_employee"
                    globalQuery.conditions.push({ field: "department_id", operator: "=", value: departmentIdVal });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const enquirybyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setEnquiryByIdOptions(enquirybyIdApiCall)
                    if (enquirybyIdApiCall.length <= 0) {
                        setEnquiryById('');
                    }
                    $('#error_cmb_department').hide();
                } else {
                    setEnquiryByIdOptions([])
                    setEnquiryById('');
                }
                break;

            case 'EnquiryBy':
                var assignToheadVal = document.getElementById('cmb_enquiry_by_id').value;
                if (assignToheadVal === "0") {
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                break;

            case 'AssignToHead':
                var assignToheadVal = document.getElementById('cmb_assign_to_head_id').value;
                if (assignToheadVal === "0") {
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                break;

            case 'AssignToemployee':
                var assignToIdVal = document.getElementById('cmb_assign_to_id').value;
                if (assignToIdVal === "0") {
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                } else if (assignToIdVal !== "0" && assignToIdVal !== "") {
                    // Set the it's head-name.
                    const assignedEmployee = assignToemployee.find(employee => parseInt(employee.field_id) === parseInt(assignToIdVal));
                    if (assignedEmployee) { // Set Head Name for Assigned Employee. // reporting_to
                        setAssigntoHeadId(assignedEmployee.reporting_to);
                    } else {
                        setAssigntoHeadId('');
                    }
                } else {
                    setAssigntoHeadId('');
                }
                break;

            case 'Agent':
                let selectedAgent = $('#cmb_agent_id').val();
                if ($('#cmb_agent_id').val() === "0") {
                    const newTab = window.open('/Masters/Agent', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                } else if (selectedAgent !== '0' && selectedAgent !== '') {
                    const selectedAgentData = agentOpts.find(agent => parseInt(agent.field_id) === parseInt(selectedAgent));
                    if (selectedAgentData) {
                        setAgentPercent(selectedAgentData.agent_std_percent);
                    } else {
                        setAgentPercent(0);
                    }
                } else {
                    setAgentPercent(0);
                }
                break;

            case 'quotationById':
                var quotationByEmploye = document.getElementById('cmb_quotation_by_id').value;
                if (quotationByEmploye === "0") {
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                break;


            case 'quotationTypeId':
                var quotationTypeId = document.getElementById('cmb_quotation_type_id').value;
                setQuotationTypeId(quotationTypeId)
                if (quotationTypeId !== '0' && quotationTypeId !== "") {
                    $('#error_cmb_quotation_type_id').hide();
                    await fillAutoNo(quotation_type);

                }
                else if (quotationTypeId === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Quotation Type')
                    setShowAddRecQuotationModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                    setQuotationno("0");

                } else {
                    setQuotationno("0");
                }
                break;

            case 'Customer':
                let selectedCustomer = parseInt($('#cmb_customer_id').val());
                if ($('#cmb_customer_id').val() === '0') {
                    localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master' }))
                    const newTab = window.open('/Masters/Customer', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                    setCustomerId('')
                } else if (selectedCustomer !== "" && selectedCustomer !== NaN) {
                    await FnGetCustomersContactPersonsDetails(parseInt(selectedCustomer));

                    resetGlobalQuery();
                    globalQuery.columns = ['cust_branch_state_id', 'state_name', 'cust_branch_city_id', 'city_name'];
                    globalQuery.table = "cmv_customer"
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: selectedCustomer });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const getCustomerDetailsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    if (getCustomerDetailsApiCall.length > 0) {
                        setCustomerState(getCustomerDetailsApiCall[0].cust_branch_state_id);
                        await comboOnChange('CustomerState');
                        setCustomerCity(getCustomerDetailsApiCall[0].cust_branch_city_id);
                    } else {
                        setCustomerState('');
                        setCustomerCity('');
                        setCustomerCityOpts([]);
                    }
                } else {
                    setCustomerState('');
                    setCustomerCity('');
                    setCustomerCityOpts([]);
                }
                break;

            case 'CustomerState':
                let customerState = parseInt($('#cmb_customer_state_id').val());
                if ($('#cmb_customer_state_id').val() === '0') {

                } else if (customerState != null && !isNaN(customerState)) {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name'];
                    globalQuery.table = "cmv_city"
                    // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(customerState) });
                    const getCustomerCitiesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setCustomerCityOpts(getCustomerCitiesApiCall);
                    if (getCustomerCitiesApiCall.length <= 0) { setCustomerCity(''); }
                    updateTblCalculationStateChange();

                } else {
                    setCustomerCity('');
                    setCustomerCityOpts([]);
                }
                break;

                // let customerState = $('#cmb_customer_state_id').val();
                // if (customerState != null && customerState != '') {
                //     resetGlobalQuery();
                //     globalQuery.columns.push("field_id");
                //     globalQuery.columns.push("field_name");
                //     globalQuery.table = "cmv_city"
                //     globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(customerState) });
                //     globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                //     const getCustomerCitiesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                //     setCustomerCityOpts(getCustomerCitiesApiCall);
                //     if (getCustomerCitiesApiCall.length <= 0) { setCustomerCity(''); }

                //      updateTblCalculationStateChange();

                // }
                // else {
                //     setCustomerCity('');
                //     setCustomerCityOpts([]);
                // }
                break;

            case "expt_branch":
                var exptBrachTpVal = document.getElementById("cmb_expected_branch_id").value;
                setExpected_branch_id(exptBrachTpVal)
                if (exptBrachTpVal !== '0' && exptBrachTpVal !== "") {
                    resetGlobalQuery();
                    globalQuery.columns = ['branch_state_id', 'branch_city_id']
                    globalQuery.table = "cm_company_branch";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                    globalQuery.conditions.push({ field: "company_branch_id", operator: "=", value: exptBrachTpVal, });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                    const exptBranchApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setExpected_branch_state_id(exptBranchApiCall[0].branch_state_id)
                    const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + exptBranchApiCall[0].branch_state_id + "");
                    setExpectedBranchCityOptions(cityApiCall);
                    setExpected_branch_city_id(exptBranchApiCall[0].branch_city_id)
                    updateTblCalculationStateChange();
                } else {
                    setExpected_branch_state_id("")
                    setExpected_branch_city_id("")
                }
                break;

            case "expt_branchState":
                var exptBrchStateTpVal = document.getElementById("cmb_expected_branch_state_id").value;
                setExpected_branch_state_id(exptBrchStateTpVal)
                const exptBranchcityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", exptBrchStateTpVal);
                setExpectedBranchCityOptions(exptBranchcityApiCall);
                if (exptBranchcityApiCall.length <= 0) { setCustomerCity(''); }
                updateTblCalculationStateChange()
                break;

            case "company_city":
                var companycityTpVal = document.getElementById("cmb_expected_branch_city_id").value;
                if (companycityTpVal === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add Customer City')
                    setShowAddRecQuotationModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200)
                }
                break;

            case 'CustomerCity':
                const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "is_delete", "0");
                setExpectedBranchCityOptions(cityApiCall);
                if ($('#cmb_customer_city_id').val() === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add Customer City')
                    setShowAddRecQuotationModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200)
                    setCustomerCity('');
                }
                break;

            case "by_enquiry":
                $('#add-material-id').prop('disabled', true);
                break;

            case "by_direct":
                $('#add-material-id').prop('disabled', false);
                setEnquiryOrderNoList([]);
                setCheckedEnquiryOrderNoLength(0);
                localStorage.setItem('filteredMaterialData', JSON.stringify([]));
                setBomfilteredData([]);
                calculateGrandTotal([]);
                break;

            default:
                break;
        }
    }


    const FnGetCustomerContactPersonIds = () => {
        let customerContactPersonsIds = '';
        const checkboxes = $('.selectCustContactPerson:checked');
        checkboxes.each(function () {
            customerContactPersonsIds += $(this).val() + ":";
        });
        return customerContactPersonsIds.replace(/:$/, '');
    }


    const FnSetExistingCustomerContactPersonIds = async (contactIds) => {
        const customerContactPersonsIds = contactIds.split(':');
        customerContactPersonsIds.forEach(function (contactId, index) {
            $('#selectCustContactPerson_' + customerContactPersonsIds[index]).prop('checked', true);
        });
        toggleQuotationChkBoxes('PartiallyCustContactSelection')
    }


    // const FnGetCustomersContactPersonsDetails = async (customerId) => {
    //     try {
    //         if (customerId !== null && customerId !== undefined && customerId !== '' && customerId !== NaN) {
    //             resetGlobalQuery();
    //             globalQuery.columns.push("customer_id");
    //             globalQuery.columns.push("customer_branch_name");
    //             globalQuery.columns.push("cust_branch_id");
    //             globalQuery.columns.push("customer_contact_id");
    //             globalQuery.columns.push("cust_contact_person");
    //             globalQuery.columns.push("cust_contact_no");
    //             globalQuery.columns.push("cust_alternate_contact");
    //             globalQuery.columns.push("cust_email_id");
    //             globalQuery.columns.push("cust_alternate_EmailId");
    //             globalQuery.table = "cmv_customer_contacts"
    //             globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
    //             globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    //             globalQuery.conditions.push({ field: "customer_id", operator: "=", value: parseInt(customerId) });
    //             const getContactDtlsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
    //             if (getContactDtlsApiCall.length !== 0) {
    //                 let column = [];
    //                 let columnHeads = Object.keys(getContactDtlsApiCall[0]);
    //                 for (let colKey = 0; colKey < columnHeads.length; colKey++) {
    //                     if (colKey === 0) {
    //                         column.push({
    //                             Headers: <div className="col-1"><input type='checkbox' class="me-1" name="selectAllCustContact" id="selectAllCustContact" onClick={(e) => { toggleQuotationChkBoxes('selectAllCustContact'); }} />Select</div>,
    //                             accessor: "Action",
    //                             Cell: row => (
    //                                 <div className="text-center">
    //                                     <input type='checkbox' class="selectCustContactPerson" name="selectCustContactPerson" id={'selectCustContactPerson_' + row.row.original.customer_contact_id} contact_personId={row.row.original.customer_contact_id} value={row.row.original.customer_contact_id} />
    //                                 </div>
    //                             ),
    //                         });
    //                     }
    //                     if (!columnHeads[colKey].includes('customer_id') && !columnHeads[colKey].includes('customer_contact_id') && !columnHeads[colKey].includes('cust_branch_id')) {
    //                         column.push({ Headers: columnHeads[colKey], accessor: columnHeads[colKey] });
    //                     }
    //                 }
    //                 setCustContactDetailsCols(column);
    //                 setCustContactDetails(getContactDtlsApiCall);
    //             } else {
    //                 setCustContactDetailsCols([]);
    //                 setCustContactDetails([]);
    //             }
    //         } else {
    //             setCustContactDetailsCols([]);
    //             setCustContactDetails([]);
    //         }
    //     } catch (error) {
    //         console.log("error: ", error)
    //     }
    //     // Onchange event listener for the customer contact Selection
    //     $('.selectCustContactPerson').on('change', function () {
    //         toggleQuotationChkBoxes('PartiallyCustContactSelection');
    //     });
    // }

    const FnGetCustomersContactPersonsDetails = async (customerId) => {
        try {
            if (customerId !== null && customerId !== undefined && customerId !== '' && customerId !== NaN) {
                resetGlobalQuery();
                globalQuery.columns = ["customer_id", "customer_branch_name", "cust_branch_id", "customer_contact_id", "cust_contact_person", "cust_contact_no", "cust_alternate_contact", "cust_email_id", "cust_alternate_EmailId"]
                globalQuery.table = "cmv_customer_contacts"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "customer_id", operator: "=", value: parseInt(customerId) });
                const getContactDtlsApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setCustContactDetails(getContactDtlsApiCall);
                $('.selectCustContactPerson').prop('checked', false);
                $('#selectAllCustContact').prop('checked', false);
                return getContactDtlsApiCall;
            } else {
                $('#selectAllCustContact').prop('checked', false);
                $('.selectCustContactPerson').prop('checked', false);
                setCustContactDetails([]);
                return [];
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
        // Onchange event listener for the customer contact Selection
        $('.selectCustContactPerson').on('change', function () {
            toggleQuotationChkBoxes('PartiallyCustContactSelection');
        });
    }

    const validateFields = () => {
        let formObj = $('#quotationFormId');
        let inputObj;

        for (let i = 0; i <= formObj.get(0).length - 1; i++) {
            inputObj = formObj.get(0)[i];
            if (inputObj.type === 'text' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'select-one' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'textarea' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'date' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            }
        }

    }

    const fillAutoNo = async (quotation_type) => {
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("mt_sales_quotation_master_services", "quotation_no", quotation_type, 'QT', "6");
        setQuotationno(autoNoApiCall);
        return autoNoApiCall;
    };

    const validatePercentageInput = (percentageInput) => {
        let percentageValidateMsg = '';
        percentageInput = validateNumberDateInput.current.decimalNumber(JSON.stringify(percentageInput), 4);

        if (percentageInput === '') {
            percentageValidateMsg = 'Please fill digits this field...!';
        } else if (parseFloat(percentageInput) > 100) {
            percentageValidateMsg = 'Percentage should not be greater than 100%...!';
        } else {
            return true;
        }
        return percentageValidateMsg;
    }

    const addReadOnlyAttr = () => {
        let formObj = $('#quotationFormId');
        let inputObj;

        for (let i = 0; i <= formObj.get(0).length - 1; i++) {
            inputObj = formObj.get(0)[i];
            if (inputObj.type === 'text') {
                $('#' + inputObj.id).attr('readonly', true);
            } else if (inputObj.type === 'select-one') {
                $('#' + inputObj.id).attr('disabled', true);
            } else if (inputObj.type === 'file') {
                $('#' + inputObj.id).attr('disabled', true);
            } else if (inputObj.type === 'textarea') {
                $('#' + inputObj.id).attr('readonly', true);
            } else if (inputObj.type === 'date') {
                $('#' + inputObj.id).attr('readonly', true);
            } else if (inputObj.type === 'email') {
                $('#' + inputObj.id).attr('readonly', true);
            } else if (inputObj.type === 'number') {
                $('#' + inputObj.id).attr('readonly', true);
            }

        }
    }


    const removeSessions = async () => {
        sessionStorage.removeItem(`comboFilterData`)
        sessionStorage.removeItem('isComboFilterExist')
        sessionStorage.removeItem('smv_product_type');
        sessionStorage.removeItem('smv_product_material_type');
        sessionStorage.removeItem('smv_product_material_grade');
        sessionStorage.removeItem('smv_product_material_shape');

        for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
            sessionStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
        }

        localStorage.removeItem('smv_product_material_type');
        localStorage.removeItem('smv_product_material_grade');
        localStorage.removeItem('smv_product_material_shape');
        localStorage.removeItem('smv_product_type');

        localStorage.removeItem('smv_product_material_typeName');
        localStorage.removeItem('smv_product_material_gradeName');
        localStorage.removeItem('smv_product_material_shapeName');
        localStorage.removeItem('smv_product_typeName');
    }


    const categoryDisplay = () => {
        const catCount = sessionStorage.getItem('compRMCatCount')
        for (let catCountIndex = 1; catCountIndex <= catCount; catCountIndex++) {
            $('.cat' + catCountIndex + 'Id').show();
        }
    }

    const deleteFooterRecrd = (row, e) => {
        // var keyForViewUpdate = sessionStorage.getItem('keyForViewUpdate');
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && keyForViewUpdate !== 'followup') {
            const detailData = [...bomfilteredData]
            // const arrayIndex = parseInt(e.target.parentElement.parentElement.parentElement.getAttribute('rowindex'))
            const arrayIndex = detailData.findIndex(item => item.product_material_id === row.product_material_id);
            if (arrayIndex !== -1) {
                detailData.splice(arrayIndex, 1);
                detailData.forEach((material, index) => {
                    material.sr_no = index + 1;
                });
            }
            setBomfilteredData(detailData);
            FnCalculateDiscSum(detailData);
            localStorage.setItem('filteredMaterialData', JSON.stringify(detailData));
            setTimeout(() => { calculateGrandTotal(detailData); }, 100);
        }
    }



    const onImportClick = () => {
        importFile.current.click();
    };



    const ExportToExcel = async () => {
        let headerFormIsValid = await validate.current.validateForm("quotationFormId");

        if (bomfilteredData.length !== 0 && headerFormIsValid) {
            let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [9, 10, 23] }
            var columnExport = ['sr_no', 'product_type_short_name', 'product_material_name', 'product_material_print_name',
                'product_material_tech_spect', 'product_material_hsn_sac_code', 'hsn_sac_percent',
                'product_material_unit_name', 'process_duration', 'product_material_quotation_quantity', 'material_std_rate',
                'item_taxable_cost', 'item_cgst_percent', 'item_cgst_cost', 'item_sgst_percent', 'item_sgst_cost', 'item_igst_percent', 'item_igst_cost', 'item_total_cost',
                'product_material_notes', 'material_expected_schedule_date',
                'quotation_item_status', 'material_item_approval_remark',
                'item_discount_percent', 'item_discount_cost',
                'item_terms_conditions', 'item_remark', 'enquiry_no', 'enquiry_version',
                'product_material_id', 'hsn_sac_id', 'product_material_unit_id', 'product_material_packing_id',
            ];

            let filtrKeyValue = {};
            filtrKeyValue['0'] = "Quotation Creation Type : " + $("input[name='rb_quotation_transaction_type']:checked").attr("lbl") + "(" + rb_quotation_transaction_type + ")"
            filtrKeyValue['1'] = "Quotation Type : " + 'Service' + "(" + cmb_quotation_type_id + ")"
            filtrKeyValue['2'] = "Quotation No : " + txt_quotation_no
            filtrKeyValue['3'] = "Quotation Version : " + txt_quotation_version
            filtrKeyValue['4'] = "Quotation Date : " + await validateNumberDateInput.current.formatDateToDDMMYYYY(txt_quotation_date)
            filtrKeyValue['5'] = "Quotation Status  : " + $('#cmb_quotation_status').find(":selected").text() + "(" + cmb_quotation_status + ")"
            filtrKeyValue['6'] = "Order Life : " + $('input[name="rb_quotation_life"]:checked').attr("lbl") + "(" + rb_quotation_life + ")"
            filtrKeyValue['7'] = "Customer : " + $('#cmb_customer_id').find(":selected").text() + "(" + cmb_customer_id + ")"
            filtrKeyValue['8'] = "Customer State : " + $('#cmb_customer_state_id').find(":selected").text() + "(" + cmb_customer_state_id + ")"
            filtrKeyValue['9'] = "Customer City : " + $('#cmb_customer_city_id').find(":selected").text() + "(" + cmb_customer_city_id + ")"
            filtrKeyValue['10'] = "Expected Company Branch : " + $('#cmb_expected_branch_id').find(":selected").text() + "(" + cmb_expected_branch_id + ")"
            filtrKeyValue['11'] = "Expected Branch State  : " + $('#cmb_expected_branch_state_id').find(":selected").text() + "(" + cmb_expected_branch_state_id + ")"
            filtrKeyValue['12'] = "Expected Branch City : " + $('#cmb_expected_branch_city_id').find(":selected").text() + "(" + cmb_expected_branch_city_id + ")"
            filtrKeyValue['13'] = "Department : " + ((cmb_department_id === '0' || cmb_department_id === '' || cmb_department_id === null) ? '' : $('#cmb_department_id').find(":selected").text() + "(" + cmb_department_id + ")")
            filtrKeyValue['14'] = "Quotation By : " + ((cmb_quotation_by_id === '0' || cmb_quotation_by_id === '' || cmb_quotation_by_id === null) ? '' : $('#cmb_quotation_by_id').find(":selected").text() + "(" + cmb_quotation_by_id + ")")
            filtrKeyValue['15'] = "Enquiry By : " + ((cmb_enquiry_by_id === '0' || cmb_enquiry_by_id === '' || cmb_enquiry_by_id === null) ? '' : $('#cmb_enquiry_by_id').find(":selected").text() + "(" + cmb_enquiry_by_id + ")")
            filtrKeyValue['16'] = "Assign To Head : " + ((cmb_assign_to_head_id === '0' || cmb_assign_to_head_id === '' || cmb_assign_to_head_id === null) ? '' : $('#cmb_assign_to_head_id').find(":selected").text() + "(" + cmb_assign_to_head_id + ")")
            filtrKeyValue['17'] = "Assign To ID : " + ((cmb_assign_to_id === '0' || cmb_assign_to_id === '' || cmb_assign_to_id === null) ? '' : $('#cmb_assign_to_id').find(":selected").text() + "(" + cmb_assign_to_id + ")")
            filtrKeyValue['18'] = "Approved By : " + ((cmb_approved_by_id === '0' || cmb_approved_by_id === '' || cmb_approved_by_id === null) ? '' : $('#cmb_approved_by_id').find(":selected").text() + "(" + cmb_approved_by_id + ")")
            filtrKeyValue['19'] = "Approved Date : " + ((dt_approved_date === '0' || dt_approved_date === '' || dt_approved_date === null) ? '' : await validateNumberDateInput.current.formatDateToDDMMYYYY(dt_approved_date))
            filtrKeyValue['20'] = "Remark : " + txt_remark
            filtrKeyValue['21'] = "Expected Schedule Date : " + await validateNumberDateInput.current.formatDateToDDMMYYYY(dt_overall_schedule_date)
            filtrKeyValue['22'] = "Agent : " + ((cmb_agent_id === '0' || cmb_agent_id === '' || cmb_agent_id === null) ? '' : $('#cmb_agent_id').find(":selected").text() + "(" + cmb_agent_id + ")")
            filtrKeyValue['23'] = "Agent Percentage  : " + txt_agent_percent
            filtrKeyValue['24'] = "Quotation IsActive  : " + $("input[name='chk_isactive']:checked").attr("lbl") + "(" + chk_isactive + ")"
            jsonToExportExcel['filtrKeyValue'] = filtrKeyValue;

            // set columns name.
            // set columns name.
            for (let col = 0; col < columnExport.length; col++) {
                jsonToExportExcel.columns.push({ "Headers": columnExport[col], "accessor": columnExport[col] })
            }

            bomfilteredData.map((quoValDetail, Index) => {
                var letCurrentMaterialUnit = productUnitOpts.find(unit => unit.field_id === quoValDetail.product_material_unit_id);
                const keysConvertedQuoValDetail = {
                    ...quoValDetail,
                    sr_no: Index + 1,
                    product_material_unit_name: letCurrentMaterialUnit ? letCurrentMaterialUnit.field_name : null,
                };
                jsonToExportExcel['allData'][Index] = keysConvertedQuoValDetail;
            });

            jsonToExportExcel['headings']['ReportName'] = "Quotation Details"
            jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
            jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
            console.log("jsonToExportExcel: ", jsonToExportExcel)
            exlsExp.current.excel(jsonToExportExcel, txt_quotation_no + "@" + COMPANY_ID)

        } else if (headerFormIsValid && bomfilteredData.length === 0) {
            setErrMsg('Please add at least one material!... ')
            setShowErrorMsgModal(true)
        }
    }


    const importedExcelFile = async (event) => {
        try {
            setIsLoading(true);
            if (event.target.files) {
                let file = event.target.files[0];

                const formData = new FormData();
                formData.append(`file`, file)
                const requestOptions = { method: 'POST', body: formData };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesQuotationMasterServices/FnReadExcel`, requestOptions)
                const fetchRes = await apicall.json();
                if (fetchRes.success === '1') {
                    const quotationNo = fetchRes.formFieldData.find(item => item.includes("Quotation No"))?.split(":")[1]?.trim();
                    // let quotationNo = fetchRes.formFieldData[12].split(':')[1].trim();
                    let comboNames = ['Quotation Creation Type', 'Quotation Type', 'Quotation Status', 'Order Life', 'Customer',
                        'Expected Company Branch', 'Department', 'Quotation By', 'Enquiry By', 'Assign To Head',
                        'Assign To ID', 'Approved By', 'Agent', 'Quotation IsActive']

                    if (fetchRes.data.length !== 0) {
                        for (let formFieldIndex = 0; formFieldIndex < fetchRes.formFieldData.length; formFieldIndex++) {
                            const element = fetchRes.formFieldData[formFieldIndex];

                            if (element !== "") {
                                const field = element.split(':');
                                const fieldName = field[0].trim();
                                const fieldVal = field[1].trim();
                                const dateParts = fieldVal.split("-");

                                let comboVal;
                                if (fieldVal !== "" && comboNames.includes(fieldName)) {
                                    // const splitVal = fieldVal.split('(')
                                    // comboVal = splitVal[1].replace(')', '')
                                    let lastBracesVal = fieldVal.match(/\(([^)]*)\)\s*$/);
                                    comboVal = lastBracesVal[1];
                                }
                                switch (fieldName) {
                                    case 'Quotation Creation Type': setQuotationTransactionType(comboVal); break;
                                    case 'Quotation Type': setQuotationTypeId(comboVal); break;
                                    // case 'Quotation No': setQuotationno(fieldVal); break;
                                    // case 'Quotation Version': setQuotationVersion(fieldVal); break;
                                    // case 'Quotation Date': setQuotation_date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`); break;
                                    case 'Quotation Status': setQuotationStatus(comboVal); break;
                                    case 'Order Life': setQuotationLife(comboVal); break;
                                    case 'Customer':
                                        setCustomerId(comboVal);
                                        await comboOnChange('Customer');
                                        break;
                                    case 'Expected Company Branch':
                                        setExpected_branch_id(comboVal);
                                        await comboOnChange('expt_branch');
                                        break;
                                    case 'Department':
                                        setDepartmentId(comboVal);
                                        resetGlobalQuery();
                                        globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
                                        globalQuery.table = "cmv_employee"
                                        globalQuery.conditions.push({ field: "department_id", operator: "=", value: comboVal });
                                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                                        const getFilteredEmployeeList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                                        setEnquiryByIdOptions(getFilteredEmployeeList);
                                        break;
                                    case 'Quotation By': setQuotationById(comboVal); break;
                                    case 'Enquiry By': setEnquiryById(comboVal); break;
                                    case 'Assign To Head': setAssigntoHeadId(comboVal); break;
                                    case 'Assign To ID': setAssigToId(comboVal); break;
                                    // case 'Approved By': setApprovedById(comboVal); break;
                                    // case 'Approved Date': setApprovedDate(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`); break;
                                    case 'Expected Schedule Date': setOverAllScheduleDate(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`); break;
                                    case 'Agent': setAgentId(comboVal); break;
                                    case 'Agent Percentage': setAgentPercent(fieldVal); break;
                                    case 'Quotation IsActive': setIsActive(comboVal); break;
                                    case 'Remark': setRemark(fieldVal); break;
                                    default: break;
                                }
                            }
                        }
                        // console.log('readedOrderDetailsFromExcel: ' + fetchRes.data);
                        const readedOrderDetailsFromExcel = fetchRes.data?.map(dataRow => {
                            const orderDetail = fetchRes.columns.reduce((acc, key, secIndex) => {
                                acc[key] = dataRow[secIndex];
                                return acc;
                            }, {});

                            // Here check the if input value for numeric fields contains blank or alphabate then set it as 0
                            orderDetail.product_id = orderDetail.product_material_id   // add the product id.
                            orderDetail.product_material_quotation_quantity = isNaN(parseFloat(orderDetail.product_material_quotation_quantity)) ? 0 : parseFloat(orderDetail.product_material_quotation_quantity)
                            orderDetail.item_material_cost = isNaN(parseFloat(orderDetail.item_material_cost)) ? 0 : parseFloat(orderDetail.item_material_cost)
                            orderDetail.item_process_cost = isNaN(parseFloat(orderDetail.item_process_cost)) ? 0 : parseFloat(orderDetail.item_process_cost)
                            orderDetail.item_labour_cost = isNaN(parseFloat(orderDetail.item_labour_cost)) ? 0 : parseFloat(orderDetail.item_labour_cost)
                            orderDetail.item_discount_percent = isNaN(parseFloat(orderDetail.item_discount_percent)) ? 0 : parseFloat(orderDetail.item_discount_percent)

                            if (isNaN(parseFloat(orderDetail.product_material_quotation_quantity))) {
                                orderDetail.product_material_quotation_quantity = 0;
                            }

                            // let calculatedWt = parseFloat(orderDetail.product_material_quotation_quantity) * parseFloat(orderDetail.product_material_std_weight);
                            // orderDetail.product_material_quotation_weight = validateNumberDateInput.current.decimalNumber(calculatedWt.toString(), 4);
                            // FnMaterialWiseCalculations(orderDetail);
                            return orderDetail;
                        });

                        console.log('readedOrderDetailsFromExcel after updatation: ' + readedOrderDetailsFromExcel);
                        // setBomfilteredData(readedOrderDetailsFromExcel);

                        setTimeout(() => {
                            let updatedTblData = [];
                            for (let rowIndex = 0; rowIndex < readedOrderDetailsFromExcel.length; rowIndex++) {
                                let updatedRowData = FnMaterialWiseCalculations(readedOrderDetailsFromExcel[rowIndex]);
                                updatedTblData.push(updatedRowData);
                            }
                            setBomfilteredData(updatedTblData);
                            FnCalculateDiscSum(updatedTblData);
                            calculateGrandTotal(updatedTblData);
                            localStorage.setItem('filteredMaterialData', JSON.stringify(updatedTblData))
                        }, 1000);

                    }
                    //else if (quotationNo !== txt_quotation_no) {
                    //     setErrMsg('You are trying to import the wrong order file...!')
                    //     setShowErrorMsgModal(true)
                    // }
                } else {
                    setErrMsg(fetchRes.error)
                    setShowErrorMsgModal(true)
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }


    function scrollToTableRow() {
        let keywords = $("#txt-detail-data-highliter").val();
        let table = document.getElementById('erp-quotationdetails-table');
        let rows = table.getElementsByTagName('tr');

        for (let trNum = 1; trNum < rows.length; trNum++) {
            let row = rows[trNum];
            if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
                let w = $(window);
                let row = $('#erp-quotationdetails-table').find('tr').eq(trNum);

                if (row.length) {
                    w.scrollTop(row.offset().top - (w.height() / 2));
                }

                $(row).find('td').each(function () {
                    $(this).addClass('highlight');
                });
                removeOtherTrHighlightClass(trNum)
                break;
            } else {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }


    const removeOtherTrHighlightClass = (rowNum) => {
        let table = document.getElementById('erp-quotationdetails-table');
        let rows = table.getElementsByTagName('tr');
        for (let trNum = 1; trNum < rows.length; trNum++) {
            let row = rows[trNum];
            if (trNum !== rowNum) {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }



    const addQuotation = async (e) => {
        try {
            setIsLoading(true)
            let QuotationIsValid;
            let NewQuotationNo = '';
            let clickedBtnType = e.target.getAttribute('BtnType')
            if ((clickedBtnType === 'reject' || clickedBtnType === 'approve') && clickedBtnType !== 'save') {
                if (clickedBtnType === 'reject' && $('#txt_status_remark').val().trim() === '') {
                    $('#error_txt_status_remark').text('Please enter the remark.');
                    $('#error_txt_status_remark').show();
                    $('#txt_status_remark').focus();
                    setIsLoading(false)
                    return false;
                } else if (clickedBtnType === 'approve') {
                    QuotationIsValid = await FnValidateQuotationAprove();
                } else {
                    QuotationIsValid = true;
                }
            } else if (clickedBtnType !== 'approve' && clickedBtnType !== 'reject') {
                QuotationIsValid = await FnValidateQuotation();
            }
            if (QuotationIsValid) {
                let NewQuotationNo = txt_quotation_no
                if (keyForViewUpdate !== 'update' && keyForViewUpdate !== 'approve') {
                    NewQuotationNo = await fillAutoNo(quotation_type);
                }

                const checkIsValidate = await validate.current.validateForm("quotationFormId");

                if (checkIsValidate === true) {
                    let json = { 'salesQtHeaderData': {}, 'salesQtDetailData': [], 'TransPaymentTermsData': [], 'TransTermsData': [], 'TransExistingFucntionalityData': [], 'commonIds': {} }

                    let commonFieldsJson = {
                        company_id: parseInt(COMPANY_ID),
                        company_branch_id: parseInt(COMPANY_BRANCH_ID),
                        financial_year: FINANCIAL_SHORT_NAME,
                        quotation_master_transaction_id: isApprove ? quotation_master_transaction_id : 0,
                        quotation_type_id: cmb_quotation_type_id,
                        quotation_type: quotation_type,
                        quotation_no: quotation_master_transaction_id === 0 ? NewQuotationNo : txt_quotation_no,
                        quotation_date: txt_quotation_date,
                        quotation_version: txt_quotation_version,
                        expected_branch_id: cmb_expected_branch_id,
                        expected_branch_state_id: cmb_expected_branch_state_id,
                        expected_branch_city_id: cmb_expected_branch_city_id,
                        is_active: chk_isactive,
                    };

                    // Quotation Header Data.
                    let customerContactIds = FnGetCustomerContactPersonIds();
                    const quotationMasterFormData = {
                        // quotation_master_transaction_id: isApprove ? quotation_master_transaction_id : 0,
                        // company_id: COMPANY_ID,
                        // company_branch_id: COMPANY_BRANCH_ID,
                        // financial_year: FINANCIAL_SHORT_NAME,
                        // quotation_no: quotation_master_transaction_id === 0 ? NewQuotationNo : txt_quotation_no,
                        // quotation_version: parseInt($('#txt_quotation_version').val()),
                        // quotation_date: txt_quotation_date,
                        // quotation_type_id: document.getElementById("cmb_quotation_type_id").value,
                        // quotation_type: $('#cmb_quotation_type_id').find(":selected").text(),
                        // expected_branch_id: cmb_expected_branch_id,
                        // expected_branch_state_id: cmb_expected_branch_state_id,
                        // expected_branch_city_id: cmb_expected_branch_city_id,
                        customer_id: cmb_customer_id,
                        customer_contacts_ids: customerContactIds,
                        customer_state_id: cmb_customer_state_id,
                        quotation_transaction_type: rb_quotation_transaction_type,
                        customer_city_id: cmb_customer_city_id,
                        department_id: cmb_department_id,
                        quotation_by_id: cmb_quotation_by_id,
                        enquiry_by_id: cmb_enquiry_by_id,
                        assign_to_head_id: cmb_assign_to_head_id,
                        assign_to_id: cmb_assign_to_id,
                        overall_schedule_date: dt_overall_schedule_date,
                        approved_by_id: isApprove ? cmb_approved_by_id : '',
                        approved_date: isApprove ? dt_approved_date : '',
                        status_remark: txt_status_remark,
                        quotation_status: cmb_quotation_status,
                        agent_id: cmb_agent_id,
                        agent_percent: txt_agent_percent,
                        remark: txt_remark,
                        quotation_date: txt_quotation_date,
                        quotation_life: rb_quotation_life,
                        tax_applicable: rb_tax_applicable,
                        transportation_applicable: rb_transportation_applicable,
                        bom_applicable: rb_bom_applicable,
                        costing_applicable: rb_costing_applicable,
                        //footer filds
                        quotation_material_cost: txt_quotation_material_cost,
                        quotation_process_cost: txt_quotation_process_cost,
                        quotation_labour_cost: txt_quotation_labour_cost,
                        quotation_other_cost: txt_quotation_other_cost,
                        quotation_other_cost_remark: txt_quotation_other_cost_remark,
                        quotation_administration_percent: txt_quotation_administration_percent,
                        quotation_administration_cost: txt_quotation_administration_cost,
                        quotation_profit_percent: txt_quotation_profit_percent,
                        quotation_profit_cost: txt_quotation_profit_cost,
                        quotation_discount_percent: txt_quotation_discount_percent,
                        quotation_discount_cost: txt_quotation_discount_cost,
                        quotation_taxable_cost: txt_quotation_taxable_cost,
                        quotation_cgst_cost: txt_quotation_cgst_cost,
                        quotation_sgst_cost: txt_quotation_sgst_cost,
                        quotation_igst_cost: txt_quotation_igst_cost,
                        quotation_total_cost: txt_quotation_total_cost,
                        other_terms_conditions: txt_other_terms_conditions,
                        is_active: chk_isactive,
                        quotation_mail_sent_status: rb_quotation_mail_sent_status,
                        created_by: UserName,

                        ...commonFieldsJson,    // set all common fields.
                    }
                    if (isApprove) {
                        quotationMasterFormData['quotation_master_transaction_id'] = quotation_master_transaction_id !== undefined ? quotation_master_transaction_id : 0;
                        const countOfP = $('#erp-quotationdetails-table tbody tr select[id^="quotation_item_status_"]').toArray()
                            .filter(selectElement => $(selectElement).val() === 'P')
                            .length;
                        if (clickedBtnType === 'approve' && countOfP > 0) {
                            quotationMasterFormData.quotation_status = 'P'
                        } else if (clickedBtnType === 'approve' && countOfP === 0) {
                            quotationMasterFormData.quotation_status = 'A'
                        }
                        else if (clickedBtnType === 'reject') {
                            quotationMasterFormData.quotation_status = 'R'
                        }
                    }
                    json.salesQtHeaderData = quotationMasterFormData;

                    // JSON Objects Array For Details Data.
                    let quotationMaterialsJSONArray = bomfilteredData?.map((quotationMaterial, index) => ({
                        ...quotationMaterial,             // Add all challan-material's existing fields.
                        quotation_details_transaction_id: isApprove && keyForViewUpdate !== 'copy' ? quotationMaterial.quotation_details_transaction_id : 0,
                        sr_no: index + 1,
                        department_id: cmb_department_id,
                        approved_by_id: isApprove && keyForViewUpdate !== 'copy' ? cmb_approved_by_id : '',
                        approved_date: isApprove && keyForViewUpdate !== 'copy' ? dt_approved_date : '',
                        product_material_conversion_factor: 1,
                        created_by: UserName,
                        quotation_item_status: isApprove && clickedBtnType === 'reject' ? 'R' : quotationMaterial.quotation_item_status,

                        // Setting Default values as per db description.
                        product_material_packing_id: 1,
                        product_material_quotation_weight: 1,
                        product_material_conversion_factor: 1,
                        expected_lead_time: 1,
                        product_fg_bom_no: 1,
                        product_fg_bom_version: 1,
                        product_costing_no: 1,
                        item_material_cost: 0,
                        item_process_cost: 0,
                        item_labour_cost: 0,
                        item_administration_percent: 0,
                        item_administration_cost: 0,
                        item_profit_percent: 0,
                        item_profit_cost: 0,
                        item_other_cost: 0,
                        item_other_cost_remark: '',

                        ...commonFieldsJson     // set all common fields.
                    }));
                    json.salesQtDetailData = quotationMaterialsJSONArray;

                    // Trans Payment Terms Data
                    $("input:checkbox[name=selectPmtTerm]:checked").each(function () {
                        let findPaymentTermsData = PmtTermsdata.find(item => item.payment_terms_id === parseInt($(this).val()));
                        let payment_term_idVal = parseInt($(this).val());
                        const paymentTermsData = {
                            // company_id: COMPANY_ID,
                            // company_branch_id: COMPANY_BRANCH_ID,
                            // quotation_master_transaction_id: quotation_master_transaction_id,
                            // quotation_no: quotation_master_transaction_id === 0 ? NewQuotationNo : txt_quotation_no,
                            // quotation_date: txt_quotation_date,
                            // quotation_version: parseInt($('#txt_quotation_version').val()),
                            quotation_details_transaction_id: 0,
                            payment_terms_id: payment_term_idVal,
                            payment_terms_name: findPaymentTermsData.payment_terms_name,
                            payment_terms_days: findPaymentTermsData.payment_terms_days,
                            payment_terms_grace_days: findPaymentTermsData.payment_terms_grace_days,
                            payment_terms_Milestome: $('#payment_milestone_' + payment_term_idVal).val(),
                            payment_percent: $('#payment_percent_' + payment_term_idVal).val(),
                            remark: findPaymentTermsData.remark,
                            created_by: UserName,
                            ...commonFieldsJson,    // set all common fields.
                        }
                        json.TransPaymentTermsData.push(paymentTermsData);
                    });

                    if (json.TransPaymentTermsData.length === 0) {
                        json.TransPaymentTermsData = PmtTermsdataResp.map((payment_term) => {
                            payment_term.quotation_no = keyForViewUpdate === 'copy' ? NewQuotationNo : payment_term.quotation_no;
                            payment_term.quotation_version = keyForViewUpdate === 'copy' ? 1 : payment_term.quotation_version;
                            payment_term.quotation_date = txt_quotation_date;
                            payment_term.quotation_master_transaction_id = 0;
                            payment_term.quotation_payment_terms_transaction_id = 0;
                            payment_term.quotation_details_transaction_id = 0
                            return payment_term;
                        })
                    }

                    // Trans Quotation Terms Data
                    $("input:checkbox[name=checkQuotationTerms]:checked").each(function () {
                        let findQuotationTermsData = QuotationTermsdata.find(item => item.common_parameters_id === parseInt($(this).val()));
                        const QuotationTermsData = {
                            // company_id: COMPANY_ID,
                            // company_branch_id: COMPANY_BRANCH_ID,
                            // quotation_master_transaction_id: 0,
                            // quotation_date: txt_quotation_date,
                            // quotation_no: quotation_master_transaction_id === 0 ? NewQuotationNo : txt_quotation_no,
                            // quotation_version: parseInt($('#txt_quotation_version').val()),
                            quotation_terms_transaction_id: 0,
                            quotation_details_transaction_id: 0,
                            quotation_terms_parameters_id: parseInt($(this).val()),
                            quotation_terms_parameters_name: findQuotationTermsData.common_parameters_name,
                            quotation_terms_parameters_value: findQuotationTermsData.common_parameters_value,
                            quotation_terms_parameters_expected_value: $("#common_parameters_expected_value_" + $(this).val()).val() === "" ? findQuotationTermsData.quotation_terms_parameters_value : $("#common_parameters_expected_value_" + $(this).val()).val(),
                            created_by: UserName,
                            remark: findQuotationTermsData.remark,

                            ...commonFieldsJson,    // set all common fields.
                        }
                        json.TransTermsData.push(QuotationTermsData);
                    });

                    if (json.TransTermsData.length === 0) {
                        json.TransTermsData = QuotationTermsdataResp.map((common_term) => {
                            common_term.quotation_no = keyForViewUpdate === 'copy' ? NewQuotationNo : common_term.quotation_no;
                            common_term.quotation_version = keyForViewUpdate === 'copy' ? 1 : common_term.quotation_version;
                            common_term.quotation_date = txt_quotation_date;
                            common_term.quotation_master_transaction_id = 0;
                            common_term.quotation_terms_transaction_id = 0;
                            common_term.quotation_details_transaction_id = 0
                            return common_term;
                        })
                    }

                    //QuotationExistingExpectedFunctionalityData // collect only those object having all three textbox filed.
                    let quotationExistingFunctionality = existingFunctionalityTblData
                        ?.filter(existingFunctionality => existingFunctionality.quotation_existing_functionality !== '' && existingFunctionality.quotation_expected_functionality !== '' && existingFunctionality.quotation_expected_value !== '')
                        .map((existingFunctionality, index) => ({
                            ...existingFunctionality,
                            quotation_functionality_transaction_id: 0,
                            quotation_details_transaction_id: 0,
                            created_by: UserName,
                            ...commonFieldsJson     // set all common fields.
                        }));
                    json.TransExistingFucntionalityData = quotationExistingFunctionality;

                    // Common Ids
                    json.commonIds.company_id = COMPANY_ID
                    json.commonIds.company_branch_id = COMPANY_BRANCH_ID
                    json.commonIds.quotation_no = quotation_master_transaction_id === 0 ? NewQuotationNo : txt_quotation_no
                    // json.commonIds.quotation_date = txt_quotation_date
                    json.commonIds.financial_year = JSON.stringify(FINANCIAL_SHORT_NAME)
                    console.log('Sending JSON For Save Quotation: ', json)
                    const formData = new FormData();
                    formData.append(`MtSalesQuotationServiceData`, JSON.stringify(json))
                    const requestOptions = { method: 'POST', body: formData };
                    const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesQuotationMasterServices/FnAddUpdateRecord/${isApprove}`, requestOptions)
                    const responce = await apicall.json();
                    if (responce.success === "1") {
                        const evitCache = comboDataAPiCall.current.evitCache();
                        console.log(evitCache);
                        setSuccMsg(responce.message)
                        if (responce.data.hasOwnProperty('quotation_version') && responce.data.hasOwnProperty('quotation_no')) {
                            setModalOrderDetails(`Quotation No.: ${responce.data.quotation_no} and Version: ${responce.data.quotation_version}`)
                        }
                        setQuotationMasterTransactionId(responce.data.quotation_master_transaction_id)
                        setQuotationVersion(responce.data.quotation_version);
                        // Enable upload document functionality.
                        $('#save-btn').attr('disabled', 'disabled');
                        $('#reject-btn').attr('disabled', 'disabled');
                        $('#clearFormBtn').attr('disabled', 'disabled');

                        // if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'reject') {
                        //     $('#uploadDocBtn').removeAttr('disabled');
                        // }

                        if (isApprove && responce.data.quotation_status === 'A') {
                            setShowMailSentModal(true);

                        } else {
                            const updateData = {
                                quotation_no: responce.data.quotation_no,
                                quotation_version: responce.data.quotation_version,
                                financial_year: responce.data.financial_year
                            }
                            sessionStorage.setItem('QuotationOrderDetails', JSON.stringify(updateData));
                            sessionStorage.setItem('keyForViewUpdate', 'update');
                            setShowSuccessMsgModal(true);
                            setSuccMsg(responce.message)
                        }

                    } else {
                        // $('#spinner_id').hide();
                        setErrMsg(responce.error);
                        setShowErrorMsgModal(true);
                    }
                    // $('#spinner_id').hide();
                    console.log(json)
                }
            }
            setIsLoading(false)

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }


    const FnValidateQuotation = async () => {

        let headerDataIsValid = await validate.current.validateForm("quotationFormId");
        if (!headerDataIsValid) {
            return false;
        }
        //customer contact validate
        const checkboxes = $('.selectCustContactPerson:checked');
        if (checkboxes.length === 0) {
            $('#error_customer_contact_persons').text('Please select atleast one contact person.');
            $('#error_customer_contact_persons').show();
            setErrMsg('Please select at least one customer contact.');
            setOpenCustContAccord(true);
            setShowErrorMsgModal(true);
            return null;
        }
        //Detailsvalidation
        let QuotationApproveIsValid = false;
        let addedMaterials = $('#erp-quotationdetails-table tbody tr').length;
        // checked the materials are added or not?
        if (addedMaterials <= 0 && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add atleast one material.');
            setShowErrorMsgModal(true);
            setOpenDetailsAccord(true);
            return QuotationApproveIsValid = false;

        } else {

            // Means materials are added.
            $('#erp-quotationdetails-table tbody tr').each(function () {
                let currentTblRow = $(this);
                let materialRate = parseFloat(currentTblRow.find('input[id^="material_std_rate"]').val());
                let materialQty = parseFloat(currentTblRow.find('input[id^="product_material_quotation_quantity_"]').val());

                let materialScheduleDate = currentTblRow.find('input[id^="material_expected_schedule_date_"]').val();

                if (isNaN(materialQty) || materialQty <= 0 || materialQty === '') {
                    $(currentTblRow.find('input[id^="product_material_quotation_quantity_"]'))[0].parentElement.dataset.tip = 'Service quantity should not be a zero or blank...!';
                    $(currentTblRow.find('input[id^="product_material_quotation_quantity_"]'))[0].focus();
                    setOpenDetailsAccord(true);
                    return QuotationApproveIsValid = false;
                    // return 

                } else if (isNaN(materialRate) || materialRate <= 0 || materialRate === '') {
                    $(currentTblRow.find('input[id^="material_std_rate"]'))[0].parentElement.dataset.tip = 'Service rate should not be a zero or blank...!';
                    $(currentTblRow.find('input[id^="material_std_rate"]'))[0].focus();
                    setOpenDetailsAccord(true);
                    return QuotationApproveIsValid = false;
                    // return

                } else if (materialScheduleDate === '' || materialScheduleDate === null || materialScheduleDate === undefined) {
                    $(currentTblRow.find('input[id^="material_expected_schedule_date_"]'))[0].parentElement.dataset.tip = 'Please select Service schdule date...!';
                    $(currentTblRow.find('input[id^="material_expected_schedule_date_"]'))[0].focus();
                    setOpenDetailsAccord(true);
                    return QuotationApproveIsValid = false;
                    // return 
                }
                QuotationApproveIsValid = true;
            });

            if (QuotationApproveIsValid === false) {
                return QuotationApproveIsValid;
            }

            if (QuotationApproveIsValid === true) {

                // Payment term validations
                let selectedPmtTerms = $('#QuotationPaymentTermsTbl tbody tr .selectPmtTerm:checked')
                let quotationPmtTermsIsValid = true;

                selectedPmtTerms.each(function () {
                    let currentTblRow = $(this.parentElement.parentElement.parentElement)
                    let pmtMilestome = currentTblRow.find('input[id^="payment_milestone_"]').val().trim();
                    let pmtPercent = parseFloat(currentTblRow.find('input[id^="payment_percent_"]').val());
                    if (pmtMilestome === '') {
                        $(currentTblRow.find('input[id^="payment_milestone_"]'))[0].parentElement.dataset.tip = 'Please enter payment milestome...!';
                        $(currentTblRow.find('input[id^="payment_milestone_"]'))[0].focus();
                        setOpenPaymentTermsAccord(true);
                        quotationPmtTermsIsValid = false;
                    } else if (isNaN(pmtPercent) || pmtPercent === '') {
                        $(currentTblRow.find('input[id^="payment_percent_"]'))[0].parentElement.dataset.tip = 'Payment term Percentage should not be blank otherwise set ...!';
                        $(currentTblRow.find('input[id^="payment_percent_"]'))[0].focus();
                        setOpenPaymentTermsAccord(true);
                        quotationPmtTermsIsValid = false;
                    } else if (pmtPercent > 100) {
                        $(currentTblRow.find('input[id^="payment_percent_"]'))[0].parentElement.dataset.tip = 'Payment term Percentage should not be greater than 100 ...!';
                        $(currentTblRow.find('input[id^="payment_percent_"]'))[0].focus();
                        setOpenPaymentTermsAccord(true);
                        quotationPmtTermsIsValid = false;
                    }
                    return quotationPmtTermsIsValid;
                });
                if (!quotationPmtTermsIsValid) {
                    return quotationPmtTermsIsValid;
                }

                let selectedCommonTerms = $('#erp-quotationterms-table tbody tr .checkQuotationTerms:checked')
                let QuotationCommonTermsIsValid = true;
                if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && keyForViewUpdate !== 'followup') {
                    selectedCommonTerms.each(function () {
                        let currentTblRow = $(this.parentElement.parentElement.parentElement)
                        let expectedValue = currentTblRow.find('input[id^="common_parameters_expected_value_"]').val().trim();;
                        if (expectedValue === '') {
                            $(currentTblRow.find('input[id^="common_parameters_expected_value_"]'))[0].parentElement.dataset.tip = 'Please enter valid common parameters expected value...!';
                            $(currentTblRow.find('input[id^="common_parameters_expected_value_"]'))[0].focus();
                            QuotationCommonTermsIsValid = false;
                            setOpenQuotationTermsAccord(true);
                            return false;
                        }
                    });
                    return QuotationCommonTermsIsValid
                }
                if (!QuotationCommonTermsIsValid) {
                    return QuotationCommonTermsIsValid;
                }

            } else if (keyForViewUpdate === 'approve' && QuotationApproveIsValid) {
                return true;
            } else {
                return keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && QuotationApproveIsValid
                // return true;
            }

        }
    }


    const FnValidateQuotationAprove = async () => {
        let QuotationIsValid = false;
        if (keyForViewUpdate === 'approve') {

            $('#erp-quotationdetails-table tbody tr').each(function () {
                let currentTblRow = $(this);
                let quotationStatus = currentTblRow.find('select[id^="quotation_item_status_"]').val();
                let materialApproval = currentTblRow.find('input[id^="material_item_approval_remark_"]').val().trim();;
                const countOfA = $('#erp-quotationdetails-table tbody tr select[id^="quotation_item_status_"]').toArray()
                    .filter(selectElement => $(selectElement).val() === 'A')
                    .length;

                const countOfP = $('#erp-quotationdetails-table tbody tr select[id^="quotation_item_status_"]').toArray()
                    .filter(selectElement => $(selectElement).val() === 'P')
                    .length;

                // if (materialApproval === '' && quotationStatus === 'A' && countOfA >= 0) {
                //     currentTblRow.find('input[id^="material_item_approval_remark_"]')[0].parentElement.dataset.tip = 'Please enter the approval remark....!';
                //     currentTblRow.find('input[id^="material_item_approval_remark_"]')[0].focus();
                //     QuotationIsValid = false;
                //     return false;
                // } else
                if (materialApproval === '' && quotationStatus !== 'A' && countOfA <= 0) {
                    setErrMsg('Please Approve at least one Quotation item status .');
                    setShowErrorMsgModal(true);
                    setOpenDetailsAccord(true);
                    return false;
                }
                else {
                    QuotationIsValid = true;
                }
            });
            if (!QuotationIsValid) {
                return QuotationIsValid;
            }
        } else {
            // validate details table for add_Quotation.
            $('#erp-quotationdetails-table tbody tr').each(function () {
                let currentTblRow = $(this);
                let materialQty = parseFloat(currentTblRow.find('input[id^="product_material_quotation_quantity_"]').val());
                // let QuotationIsValid = false;
                if (isNaN(materialQty) || materialQty <= 0 || materialQty === '') {
                    $(currentTblRow.find('input[id^="product_material_quotation_quantity_"]'))[0].parentElement.dataset.tip = 'Service quantity should not be a zero or blank...!';
                    $(currentTblRow.find('input[id^="product_material_quotation_quantity_"]'))[0].focus();
                    setOpenDetailsAccord(true);
                    return QuotationIsValid = false;
                }
                QuotationIsValid = true;
            });
        }

        return QuotationIsValid;


    }

    const clearFormFields = async () => {
        setQuotationTransactionType('M');
        setQuotationno('')
        setQuotationVersion(1);
        // setQuotationTypeId('')
        setQuotation_date(today);
        setQuotationStatus('P');
        setQuotationLife('C');
        setCustomerId('');
        setCustomerState('');
        setCustomerCity('');
        // uncheck the customer contacts's checkboxes
        $('.checkQuotationTerms').prop('checked', false);
        $('#chkAllCommonTerms').prop('checked', false);

        setExpected_branch_id(COMPANY_BRANCH_ID);
        const loggedInCompanyBranchObj = expectedBranchOptions.find(obj => obj.company_branch_id === parseInt(COMPANY_BRANCH_ID));
        setExpected_branch_state_id(loggedInCompanyBranchObj.branch_state_id);
        const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + loggedInCompanyBranchObj.branch_state_id + "");
        setExpectedBranchCityOptions(cityApiCall);
        setExpected_branch_city_id(loggedInCompanyBranchObj.branch_city_id);

        setOverAllScheduleDate(FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays));
        setDepartmentId('');
        setQuotationById('');
        setEnquiryById('');
        setAssigntoHeadId('');
        setAssigToId('');
        setApprovedById('');
        setApprovedDate('');
        setStatusremark('');
        setTaxApplicable('E');
        setTransportationApplicable('E');
        setBomApplicable('N');
        setCostingApplicable('N');
        setQuotationMaterialCost(0);
        setQuotationProcessCost(0);
        setQuotationLabourCost(0);
        setQuotationOtherCost(0);
        setQuotationOtherCostRemark('');
        setquotationAdministrationPercent(0);
        setQuotationAdministrationCost(0);
        setQuotationProfitPercent(0);
        setQuotationProfitCost(0);
        setQuotationDiscountPercent(0);
        setQuotationDiscountCost(0);
        setQuotationTaxableCost(0);
        setQuotationCgstCost(0);
        setQuotationSgstCost(0);
        setQuotationIgstCost(0);
        setQuotationTotalCost(0);
        setOtherTermsConditions('');
        setAgentId('');
        setAgentPercent(0);
        setQuotationMailSentStatus('P');
        setIsActive('true');

        sessionStorage.removeItem('filteredMaterialData');
        setBomfilteredData([]);     // set details data as blank array.

        // set default payment terms data.
        let clearedPMTTermsData = PmtTermsdata.map(function (pmtTerm) {
            return { ...pmtTerm, remark: '', payment_percent: '', payment_terms_Milestome: '' };
        });
        setPmtTermsData(clearedPMTTermsData);
        localStorage.setItem('QuotationPmtTermsData', JSON.stringify(clearedPMTTermsData));
        $('#selectAllPmtTerms').prop('checked', false);     // uncheck the checkboxes
        $('.selectPmtTerm').prop('checked', false);         // uncheck the checkboxes

        // set default common terms data.
        const clearedQuotationTermsData = QuotationTermsdata.map((commonTerm) => ({
            ...commonTerm, common_parameters_expected_value: commonTerm.common_parameters_value, remark: ''
        }));
        setQuotationTermsdata(clearedQuotationTermsData)
        // localStorage.setItem('EnquiryCommonTermsData', JSON.stringify(clearedQuotationTermsData));
        $('#selectAllCommonTerms').prop('checked', false);  // uncheck the checkboxes
        $('.selectCommonTerm').prop('checked', false);      // uncheck the checkboxes

        $('.erp_validation').hide(); // For hide the all error msgs.

        // For clear the existing functionality records.
        const getExistingFollowupData = []
        getExistingFollowupData.push(followUpBlankObj)
        setFollowupdetailsData(getExistingFollowupData)

        const existingFunctionalityData = []
        existingFunctionalityData.push(existingFunctionalityBlankObj)
        setExistingFunctionalityTblData(existingFunctionalityData);
    }

    const moveToListing = () => {
        localStorage.removeItem('filteredMaterialData')
        sessionStorage.removeItem('EnquiryCommonTermsData')
        const Path = compType === 'Register' ? '/Transactions/TQuotationService/FrmQuotationServiceListing/reg' : `/Transactions/TQuotationService/FrmQuotationServiceListing`;
        navigate(Path);


    }


    function toggleQuotationChkBoxes(key) {
        switch (key) {
            // For Payment terms
            case "selectAllPmtTerms":
                $('.selectPmtTerm').prop('checked', $('#selectAllPmtTerms').is(":checked"));
                break;
            case 'PartiallyPmtTermsSelection':
                $('#selectAllPmtTerms').prop('checked', $('input:checkbox.selectPmtTerm:checked').length === $('input:checkbox.selectPmtTerm').length);
                break;
            // For Common terms
            case 'chkAllCommonTerms':
                $('.checkQuotationTerms').prop('checked', $('#chkAllCommonTerms').is(":checked"));
                break;
            case 'PartiallyCommonTermSelection':
                $('#chkAllCommonTerms').prop('checked', $('input:checkbox.checkQuotationTerms:checked').length === $('input:checkbox.checkQuotationTerms').length);
                break;
            // For customer contact
            case 'selectAllCustContact':
                $('.selectCustContactPerson').prop('checked', $('#selectAllCustContact').is(":checked"));
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;
            case 'PartiallyCustContactSelection':
                $('#selectAllCustContact').prop('checked', $('input:checkbox.selectCustContactPerson:checked').length === $('input:checkbox.selectCustContactPerson').length);
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;
            default:
                break;
        }
    }

    const renderDetailsTable = useMemo(() => {
        return <>{bomfilteredData.length > 0
            ? <> <Table className="erp_table erp_table_scroll" id='erp-quotationdetails-table' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Action</th>
                        <th className="erp_table_th">Sr No</th>
                        <th className="erp_table_th">Service Type</th>
                        <th className="erp_table_th">Service Name</th>
                        <th className="erp_table_th">Service Print Name</th>
                        <th className="erp_table_th">Technical Specification</th>
                        <th className="erp_table_th">HSN Code</th>
                        <th className="erp_table_th">HSN %</th>
                        <th className="erp_table_th pe-5">Unit Name</th>
                        <th className="erp_table_th">Billing Cycle</th>
                        {/* <th className="erp_table_th">MOQ Qty.</th> */}
                        <th className="erp_table_th">Quotation Qty.</th>
                        <th className="erp_table_th">Service Rate</th>
                        <th className="erp_table_th">Taxable Cost</th>
                        <th className="erp_table_th">CGST %</th>
                        <th className="erp_table_th">CGST Cost</th>
                        <th className="erp_table_th">SGST %</th>
                        <th className="erp_table_th">SGST Cost</th>
                        <th className="erp_table_th">IGST %</th>
                        <th className="erp_table_th">IGST Cost</th>
                        <th className="erp_table_th">Total Cost</th>
                        <th className="erp_table_th">Service Notes</th>
                        <th className="erp_table_th">Expec. Sch. Date</th>
                        <th className="erp_table_th pe-3">Service Status</th>
                        <th className="erp_table_th">Approval Remark</th>
                        <th className="erp_table_th">Discount %</th>
                        <th className="erp_table_th">Discount Cost</th>
                        <th className="erp_table_th">Service Terms Conditions</th>
                        <th className="erp_table_th">Service Remark</th>
                        <th className="erp_table_th">Enquiry No.</th>
                        <th className="erp_table_th">Enquiry Ver.</th>
                        <th className="erp_table_th">Service ID</th>

                        {/* <th className="erp_table_th">Lead Time</th> */}
                        {/* <th className="erp_table_th">Material Cost</th> */}
                        {/* <th className="erp_table_th">Process Cost</th> */}
                        {/* <th className="erp_table_th">Labour Cost</th> */}
                        {/* <th className="erp_table_th">Admin %</th> */}
                        {/* <th className="erp_table_th">Admin Cost</th> */}
                        {/* <th className="erp_table_th">Profit %</th> */}
                        {/* <th className="erp_table_th">Profit Cost</th> */}
                        {/* <th className="erp_table_th">Other Cost</th> */}
                        {/* <th className="erp_table_th">Other Cost remark</th> */}
                        {/* <th className="erp_table_th">STD Wt.</th> */}
                        {/* <th className="erp_table_th">Quotation Wt.</th> */}
                        {/* <th className="erp_table_th">Conversion Factor</th> */}
                        {/* <th className="erp_table_th">FG Bom No.</th> */}
                        {/* <th className="erp_table_th">FG Bom Ver.</th> */}
                        {/* <th className="erp_table_th">Costing No.</th> */}
                        {/* <th className="erp_table_th">Dispatch Note Nos</th> */}
                        {/* <th className="erp_table_th">Dispatch Challan Nos</th> */}
                        {/* <th className="erp_table_th">Idtem Invoice Nos</th> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        bomfilteredData.map((quotationItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className={`erp_table_td`}>
                                    <div style={{ display: "flex" }}>
                                        <MdDelete className="erp-delete-btn" id="erp-delete-btn-id" rowindex={Index}
                                            onClick={(e) => deleteFooterRecrd(quotationItem, e)} />
                                    </div>
                                </td>
                                <td className="erp_table_td text-end">{Index + 1}</td>
                                <td className={`erp_table_td`}>{quotationItem.product_type_short_name}</td>
                                <td className={`erp_table_td`}>{quotationItem.product_material_name}</td>
                                <td className="erp_table_td ">
                                    {
                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                            ? quotationItem.product_material_print_name
                                            : <input type="text" id={`product_material_print_name_${quotationItem.product_material_id}`}
                                                className="erp_input_field_table_txt  mb-0 " value={quotationItem.product_material_print_name}
                                                Headers='product_material_print_name' onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }}
                                            />
                                    }
                                </td>
                                <td className="erp_table_td ">
                                    {
                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                            ? quotationItem.product_material_tech_spect
                                            : <input type="text" id={`product_material_tech_spect${quotationItem.product_material_id}`}
                                                className="erp_input_field_table_txt mb-0" value={quotationItem.product_material_tech_spect}
                                                onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }}
                                                Headers='product_material_tech_spect' />
                                    }
                                </td>
                                <td className="erp_table_td">{quotationItem.product_material_hsn_sac_code}</td>
                                <td className="erp_table_td text-end">{quotationItem.hsn_sac_percent}</td>
                                <td className={`erp_table_td `}>
                                    {
                                        keyForViewUpdate !== 'view' || keyForViewUpdate !== 'approve'
                                            ? <select id={`product_material_unit_name_${quotationItem.product_material_id}`} value={quotationItem.product_material_unit_id} className="form-select form-select-sm erp_input_field_table_txt m-0"
                                                Headers="product_material_unit_id" onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' || keyForViewUpdate === 'followup' ? true : false}  >
                                                {productUnitOpts?.map(unit => (<option value={unit.field_id} lbl={unit.field_name} >{unit.field_name}</option>))}
                                            </select>
                                            : quotationItem.product_material_unit_name
                                    }
                                </td>
                                <td className="erp_table_td">{quotationItem.process_duration}</td>
                                <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup' ? 'text-end' : ""}`}>
                                    {
                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                            ? quotationItem.product_material_quotation_quantity
                                            : <input type="text" className="erp_input_field text-end mb-0" id={`product_material_quotation_quantity_${quotationItem.product_material_id}`}
                                                value={quotationItem.product_material_quotation_quantity} maxLength='19'
                                                Headers='product_material_quotation_quantity' onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} onBlur={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} />
                                    }
                                </td>
                                <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup' ? 'text-end' : ""}`}>
                                    {
                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                            ? quotationItem.material_std_rate
                                            : <input type="text" className="erp_input_field text-end mb-0" id={`material_std_rate_${quotationItem.product_material_id}`}
                                                value={quotationItem.material_std_rate} maxLength='19'
                                                Headers='material_std_rate' onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} onBlur={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} />
                                    }
                                </td>
                                <td className="erp_table_td text-end">{quotationItem.item_taxable_cost}</td>
                                <td className="erp_table_td text-end">{quotationItem.item_cgst_percent}</td>
                                <td className="erp_table_td text-end">{quotationItem.item_cgst_cost}</td>
                                <td className="erp_table_td text-end">{quotationItem.item_sgst_percent}</td>
                                <td className="erp_table_td text-end">{quotationItem.item_sgst_cost}</td>
                                <td className="erp_table_td text-end">{quotationItem.item_igst_percent}</td>
                                <td className="erp_table_td text-end">{quotationItem.item_igst_cost}</td>
                                <td className="erp_table_td text-end">{quotationItem.item_total_cost}</td>
                                <td className="erp_table_td ">
                                    {
                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                            ? quotationItem.product_material_notes
                                            : <input type="text" className="erp_input_field_table_txt mb-0 " id={`product_material_notes_${quotationItem.product_material_id}`} value={quotationItem.product_material_notes} Headers='product_material_notes'
                                                onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} />
                                    }
                                </td>
                                <td className="erp_table_td">
                                    {
                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                            ? validateNumberDateInput.current.formatDateToDDMMYYYY(quotationItem.material_expected_schedule_date)  // quotationItem.material_expected_schedule_date
                                            : <input type="date" className="erp_input_field mb-0" id={`material_expected_schedule_date_${quotationItem.product_material_id}`} value={quotationItem.material_expected_schedule_date} Headers='material_expected_schedule_date' min={todaydate}
                                                onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} onBlur={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} />
                                    }
                                </td>
                                <td className="erp_table_td approveField">
                                    <select id={`quotation_item_status_${quotationItem.product_material_id}`} className="form-select form-select-sm approveField  pe-0" value={quotationItem.quotation_item_status}
                                        onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} onBlur={(e) => { FnUpdateDetailsTblData(quotationItem, e); }}
                                        disabled={!isApprove} Headers='quotation_item_status'>
                                        <option value="P">Pending</option>
                                        <option value="A">Approved</option>
                                        <option value="F">FollowUp</option>
                                        <option value="S">Sales Order Receipt</option>
                                        <option value="R">Rejected</option>
                                        <option value="C">Completed</option>
                                        <option value="X">Canceled</option>
                                        <option value="Z">PreeClosed</option>
                                    </select>
                                </td>
                                < td className="erp_table_td approveField">
                                    {
                                        keyForViewUpdate === 'approve'
                                            ? <input type="text" id={`material_item_approval_remark_${quotationItem.product_material_id}`}
                                                className="erp_input_field_table_txt mb-0  approveField" value={quotationItem.material_item_approval_remark}
                                                onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }}
                                                Headers='material_item_approval_remark' />
                                            : quotationItem.material_item_approval_remark
                                    }
                                </td>

                                <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup' ? 'text-end' : ""}`}>
                                    {
                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                            ? quotationItem.item_discount_percent
                                            : <input type="text" className="erp_input_field text-end mb-0" id={`item_discount_percent${quotationItem.product_material_id}`} value={quotationItem.item_discount_percent} maxLength='19'
                                                Headers='item_discount_percent' onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} onBlur={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} />
                                    }
                                </td>
                                <td className="erp_table_td text-end">{quotationItem.item_discount_cost}</td>
                                <td className="erp_table_td">
                                    {
                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                            ? quotationItem.item_terms_conditions
                                            : <input type="text" className="erp_input_field_table_txt mb-0 MaterialWiseRemark" id={`item_terms_conditions_${quotationItem.product_material_id}`} value={quotationItem.item_terms_conditions}
                                                onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} Headers='item_terms_conditions' />
                                    }
                                </td>
                                < td className="erp_table_td ">
                                    {
                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                            ? quotationItem.item_remark
                                            : <input type="text" className="erp_input_field_table_txt mb-0 MaterialWiseRemark" id={`item_remark_${quotationItem.product_material_id}`} value={quotationItem.item_remark} Headers='item_remark'
                                                onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} onBlur={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} />
                                    }
                                </td>
                                <td className="erp_table_td">{quotationItem.enquiry_no}</td>
                                <td className="erp_table_td text-end">{quotationItem.enquiry_version}</td>
                                <td className={`erp_table_td`}>{quotationItem.product_material_id}</td>

                                {/* <td className="erp_table_td text-end">{quotationItem.product_material_moq_quantity}</td> */}
                                {/* <td className="erp_table_td text-end">{quotationItem.expected_lead_time}</td> */}
                                {/* <td className={`erp_table_td ${keyForViewUpdate === 'followup' || keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'text-end' : ""}`}>
                                    {
                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                            ? quotationItem.item_material_cost
                                            : <input type="text" className="erp_input_field text-end mb-0" id={`item_material_cost${quotationItem.product_material_id}`}
                                                value={quotationItem.item_material_cost} maxLength='19'
                                                Headers='item_material_cost' onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} onBlur={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} />
                                    }
                                </td> */}
                                {/* <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup' ? 'text-end' : ""}`}>
                                    {
                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                            ? quotationItem.item_process_cost
                                            : <input type="text" className="erp_input_field text-end mb-0" id={`item_process_cost${quotationItem.product_material_id}`} value={quotationItem.item_process_cost} maxLength='19'
                                                Headers='item_process_cost' onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} onBlur={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} />
                                    }
                                </td> */}
                                {/* <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup' ? 'text-end' : ""}`}>
                                    {
                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                            ? quotationItem.item_labour_cost
                                            : <input type="text" className="erp_input_field text-end mb-0" id={`item_labour_cost${quotationItem.product_material_id}`} value={quotationItem.item_labour_cost} maxLength='19'
                                                Headers='item_labour_cost' onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} onBlur={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} />
                                    }
                                </td> */}
                                {/* <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup' ? 'text-end' : ""}`}>
                                    {
                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                            ? quotationItem.item_administration_percent
                                            : <input type="text" className="erp_input_field text-end mb-0" id={`item_administration_percent${quotationItem.product_material_id}`} value={quotationItem.item_administration_percent} maxLength='19'
                                                Headers='item_administration_percent' onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} onBlur={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} />
                                    }
                                </td> */}
                                {/* <td className="erp_table_td text-end">{quotationItem.item_administration_cost}</td> */}
                                {/* <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup' ? 'text-end' : ""}`}>
                                    {
                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                            ? quotationItem.item_profit_percent
                                            : <input type="text" className="erp_input_field text-end mb-0" id={`item_profit_percent${quotationItem.product_material_id}`} value={quotationItem.item_profit_percent} maxLength='19'
                                                Headers='item_profit_percent' onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} onBlur={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} />
                                    }
                                </td> */}
                                {/* <td className="erp_table_td text-end">{quotationItem.item_profit_cost}</td> */}
                                {/* <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup' ? 'text-end' : ""}`}>
                                    {
                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                            ? quotationItem.item_other_cost
                                            : <input type="text" className="erp_input_field text-end mb-0" id={`item_other_cost${quotationItem.product_material_id}`} value={quotationItem.item_other_cost} maxLength='19'
                                                Headers='item_other_cost' onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} onBlur={(e) => { FnUpdateDetailsTblData(quotationItem, e); }} />
                                    }
                                </td> */}
                                {/* <td className="erp_table_td ">
                                    {
                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                            ? quotationItem.item_other_cost_remark
                                            : <input type="text" Headers='item_other_cost_remark'
                                                id={`item_other_cost_remark_${quotationItem.product_material_id}`}
                                                className="erp_input_field mb-0" value={quotationItem.item_other_cost_remark}
                                                onChange={(e) => { FnUpdateDetailsTblData(quotationItem, e); }}
                                            />
                                    }
                                </td> */}
                                {/* <td className="erp_table_td mb-0 text-end">{quotationItem.product_material_std_weight}</td> */}
                                {/* <td className={`erp_table_td text-end`}>{quotationItem.product_material_quotation_weight}</td> */}
                                {/* <td className="erp_table_td text-end">{quotationItem.product_material_conversion_factor}</td> */}
                                {/* <td className="erp_table_td text-end">{quotationItem.product_fg_bom_no}</td> */}
                                {/* <td className="erp_table_td text-end">{quotationItem.product_fg_bom_version}</td> */}
                                {/* <td className="erp_table_td text-end">{quotationItem.product_costing_no}</td> */}
                                {/* <td className="erp_table_td text-end">{quotationItem.item_dispatch_note_nos}</td> */}
                                {/* <td className="erp_table_td text-end">{quotationItem.item_dispatch_challan_nos}</td> */}
                                {/* <td className="erp_table_td text-end">{quotationItem.item_invoice_nos}</td> */}
                            </tr>
                        )

                    }
                </tbody>
            </Table>
            </>
            : null
        }
        </>
    }, [bomfilteredData, isApprove])


    const showEnquiryCheckboxes = async () => {
        let headerDataIsValid = await validate.current.validateForm("quotationFormId");
        if (headerDataIsValid) {
            await FnGetEnquiryNos();
            var checkboxes = document.getElementById("enquiry-order-ul");
            if (!expanded) {
                checkboxes.style.display = "block";
                expanded = true;
            } else {
                checkboxes.style.display = "none";
                expanded = false;
            }
        } else {
            return false;
        }
    }


    const FnGetEnquiryNos = async () => {
        $('input[name="rb_quotation_transaction_type"]').prop('disabled', false);
        //if (cmb_department_id !== '' && cmb_department_id !== '0') {
        // Get customer order nos
        resetGlobalQuery();
        globalQuery.columns = ['enquiry_no', 'enquiry_date', 'enquiry_version'];
        globalQuery.table = "mtv_enquiry_master_services_summary"
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
        globalQuery.conditions.push({ field: "enquiry_status", operator: "=", value: 'A' });
        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: cmb_customer_id });
        globalQuery.orderBy = ["enquiry_master_transaction_id desc"];
        // globalQuery.conditions.push({ field: "department_id", operator: "=", value: cmb_department_id });
        const enquiry_order_no_list = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);

        if (enquiry_order_no_list.length !== 0) {
            let listItems = enquiry_order_no_list.map((item, index) => {
                if (index === 0) {
                    return (
                        <>
                            <li className="item enquiry_order_no">
                                <span className="checkbox">
                                    <input
                                        type="checkbox"
                                        id="all_enq_order_no_id"
                                        value={index}
                                        className="erp_radio_button filterCheckBox"
                                        onChange={(event) => checkEnquiryOrderNo(event.target.value)}
                                        disabled={isApprove || keyForViewUpdate === 'view' || keyForViewUpdate === 'followup' ? true : false}
                                        optional='optional'
                                    />
                                </span>
                                <span className="item-text">All</span>
                            </li>
                            <li className="item enquiry_order_no">
                                <span className="checkbox">
                                    <input
                                        type="checkbox"
                                        name="enquiryOrderNoCheckBox"
                                        value={item.enquiry_no} enquiry_ver={item.enquiry_version}
                                        id={`filterCheck-${item.enquiry_no}`}
                                        className="erp_radio_button enquiryOrderNoCheckBox"
                                        onChange={(event) => checkEnquiryOrderNo(event.target.value)}
                                        disabled={isApprove || keyForViewUpdate === 'view' || keyForViewUpdate === 'followup' ? true : false}
                                        optional='optional'
                                    />
                                </span>
                                <span className="item-text">{item.enquiry_no}</span>
                            </li>
                        </>
                    );
                }
                return (
                    <li className="item enquiry_order_no">
                        <span className="checkbox">
                            <input
                                type="checkbox"
                                fieldName={item.enquiry_no}
                                name="enquiryOrderNoCheckBox"
                                value={item.enquiry_no}
                                id={`filterCheck-${item.enquiry_no}`}
                                className="erp_radio_button enquiryOrderNoCheckBox"
                                onChange={(event) => checkEnquiryOrderNo(event.target.value)}
                                disabled={isApprove || keyForViewUpdate === 'view' || keyForViewUpdate === 'followup' ? true : false}

                            />
                        </span>
                        <span className="item-text">{item.enquiry_no}</span>
                    </li>
                );
            });
            setEnquiryOrderNoList(listItems)

            // Check the checkboxes.
            let distinctEnqNos = [...new Set(bomfilteredData.map(detail => detail.enquiry_no))];
            distinctEnqNos.forEach(function (enquiry_no, index) {
                $(`[name="enquiryOrderNoCheckBox"][value="${enquiry_no}"]`).prop('checked', true);
            });
            // Select All check-box selection.
            $('#all_enq_order_no_id').prop('checked', $('input[type="checkbox"][name="enquiryOrderNoCheckBox"]:checked').length === $('input[type="checkbox"][name="enquiryOrderNoCheckBox"]').length);
            setCheckedEnquiryOrderNoLength($('input[type="checkbox"][name="enquiryOrderNoCheckBox"]:checked').length);

        } else {
            setEnquiryOrderNoList([])
            setCheckedEnquiryOrderNoLength(0)
        }
    }

    const checkEnquiryOrderNo = async (enquiry_order_no) => {
        if (parseInt(enquiry_order_no) === 0) {
            $('.enquiryOrderNoCheckBox').prop('checked', $('#all_enq_order_no_id').is(":checked"));
        }
        //Check Partiallly selected all or not if selected then check the check all radio of dispatch details
        const totalChkBoxes = document.querySelectorAll('input[name=enquiryOrderNoCheckBox]').length;
        const totalChkBoxesChked = document.querySelectorAll('input[name=enquiryOrderNoCheckBox]:checked').length;
        if (totalChkBoxes === totalChkBoxesChked) {
            document.getElementById('all_enq_order_no_id').checked = true;
        } else if (totalChkBoxes > totalChkBoxesChked) {
            document.getElementById('all_enq_order_no_id').checked = false;
        }
        setCheckedEnquiryOrderNoLength(totalChkBoxesChked)
    }

    async function ShowEnquiryDetails() {
        let checkIsValidate = await validate.current.validateForm("quotationFormId");
        if (checkIsValidate === true) {
            setIsLoading(true);
            let json = { 'enquiry_nos': [] }
            $("input:checkbox[name=enquiryOrderNoCheckBox]:checked").each(function () {
                json.enquiry_nos.push($(this).val())
            })
            if (json.enquiry_nos.length !== 0) {
                $('#error_enquiry_nos').hide();
                const formData = new FormData();
                formData.append('enquiryNos', JSON.stringify(json))
                const requestOptions = { method: 'POST', body: formData };
                let apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesQuotationMasterServices/FnShowEnquiryDetailsRecords/${COMPANY_ID}`, requestOptions)
                const response = await apicall.json()
                if (response.EnquiryDetailsServicesRecords.length !== 0) {
                    let defaultGracedScheduleDate = FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays);
                    setDepartmentId(response.EnquiryDetailsServicesRecords[0].department_id);
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
                    globalQuery.table = "cmv_employee"
                    globalQuery.conditions.push({ field: "department_id", operator: "=", value: response.EnquiryDetailsServicesRecords[0].department_id });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    const getFilteredEmployeeList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setEnquiryByIdOptions(getFilteredEmployeeList);
                    setEnquiryById(response.EnquiryDetailsServicesRecords[0].enquiry_by_id);
                    setAssigToId(response.EnquiryDetailsServicesRecords[0].assign_to_id);
                    setAssigntoHeadId(response.EnquiryDetailsServicesRecords[0].assign_to_head_id);

                    let currentDate = new Date();
                    setBomfilteredData((prevArray) => {
                        const updatedArray = [
                            ...prevArray,
                            ...response.EnquiryDetailsServicesRecords.filter(item => !prevArray.some(prevItem => (prevItem.product_material_id === item.product_material_id && prevItem.enquiry_no === item.enquiry_no))).map((newMaterial) => {
                                let convertedMaterialData = {
                                    ...newMaterial,
                                    product_material_name: newMaterial.product_sr_name,
                                    product_material_quotation_quantity: newMaterial.product_material_enquiry_quantity,
                                    hsn_sac_id: newMaterial.hsn_sac_id,
                                    product_material_hsn_sac_code: newMaterial.hsn_sac_code,
                                    hsn_sac_percent: newMaterial.hsn_sac_percent,
                                    material_std_rate: 0,
                                    item_material_cost: 0,
                                    item_taxable_cost: 0,
                                    item_cgst_percent: 0,
                                    item_cgst_cost: 0,
                                    item_sgst_percent: 0,
                                    item_sgst_cost: 0,
                                    item_igst_percent: 0,
                                    item_igst_cost: 0,
                                    item_total_cost: 0,
                                    expected_lead_time: newMaterial.product_lead_time,
                                    material_expected_schedule_date: newMaterial.material_schedule_date,
                                    quotation_item_status: 'P',
                                    material_item_approval_remark: '',
                                    item_process_cost: 0,
                                    item_labour_cost: 0,
                                    item_administration_percent: 0,
                                    item_administration_cost: 0,
                                    item_profit_percent: 0,
                                    item_profit_cost: 0,
                                    item_other_cost: 0,
                                    item_other_cost_remark: '',
                                    item_discount_percent: 0,
                                    item_discount_cost: 0,
                                    item_terms_conditions: '',
                                    item_remark: '',
                                    product_material_quotation_weight: 1,
                                    product_material_conversion_factor: 1,     // change it after discuss with prashant sir.
                                };

                                let matScheduleDateByEnquiry = new Date(newMaterial.material_schedule_date);
                                if (matScheduleDateByEnquiry < currentDate || isNaN(matScheduleDateByEnquiry)) {
                                    convertedMaterialData.material_expected_schedule_date = dt_overall_schedule_date;
                                }
                                FnMaterialWiseCalculations(convertedMaterialData);
                                return convertedMaterialData;
                            })
                        ];
                        FnCalculateDiscSum(updatedArray);
                        calculateGrandTotal(updatedArray);
                        localStorage.setItem('filteredMaterialData', JSON.stringify(updatedArray));
                        return updatedArray;
                    });
                    $('.showData').show();
                    removeSessions();
                }
                setIsLoading(false);
            } else {
                setBomfilteredData([]);
                setIsLoading(false);
                calculateGrandTotal([]);
                $('#error_enquiry_nos').show();
            }
        }
    }

    const calculateGrandTotal = (bomfilteredData) => {
        // if (bomfilteredData !== null && bomfilteredData.length > 0) {
        let service_cost = bomfilteredData.reduce(function (sum, item) { return sum + (parseFloat(item.material_std_rate * item.product_material_quotation_quantity) || 0); }, 0);
        setQuotationMaterialCost(validateNumberDateInput.current.decimalNumber(service_cost.toString(), 4));

        // let quotation_material_cost = bomfilteredData.reduce((total, item) => total + parseFloat(item.item_taxable_cost), 0);
        // setQuotationMaterialCost(validateNumberDateInput.current.decimalNumber(quotation_material_cost.toString(), 4));

        let quotation_cgst_cost = bomfilteredData.reduce((total, item) => total + parseFloat(item.item_cgst_cost), 0);
        setQuotationCgstCost(validateNumberDateInput.current.decimalNumber(quotation_cgst_cost.toString(), 4));

        let quotation_sgst_cost = bomfilteredData.reduce((total, item) => total + parseFloat(item.item_sgst_cost), 0);
        setQuotationSgstCost(validateNumberDateInput.current.decimalNumber((quotation_sgst_cost).toString(), 4));

        let quotation_igst_cost = bomfilteredData.reduce((total, item) => total + parseFloat(item.item_igst_cost), 0);
        setQuotationIgstCost(validateNumberDateInput.current.decimalNumber((quotation_igst_cost).toString(), 4));

        let taxablecost = bomfilteredData.reduce(function (sum, item) { return sum + (parseFloat(item.item_taxable_cost) || 0); }, 0);
        setQuotationTaxableCost(validateNumberDateInput.current.decimalNumber(taxablecost.toString(), 4));

        let quotation_total_cost = taxablecost + quotation_cgst_cost + quotation_sgst_cost + quotation_igst_cost;
        setQuotationTotalCost(validateNumberDateInput.current.decimalNumber(quotation_total_cost.toString(), 4));
        // } else {
        //     setQuotationTaxableCost(0);
        //     setQuotationTotalCost(0);
        // }
    }

    const updateTblCalculationStateChange = () => {
        let updatedTblData = [];
        for (let rowIndex = 0; rowIndex < bomfilteredData.length; rowIndex++) {
            let updatedRowData = FnCalculateMaterialTaxation(bomfilteredData[rowIndex]);
            updatedTblData.push(updatedRowData);
        }
        setBomfilteredData(updatedTblData);
        calculateGrandTotal(updatedTblData);
    }

    const modifyEnquiryDetailsRowData = (rowData) => {
        return {
            ...rowData,
            //'sr_no': 0,
            // 'product_rm_name': rowData.product_material_name,
            // 'product_rm_id': rowData.product_material_id,
            // 'product_rm_stock_unit_name': rowData.product_material_unit_name,
            // 'product_rm_moq': rowData.product_material_moq_quantity,
            //'product_material_notes': rowData.product_material_notes,
            // 'product_rm_conversion_factor': rowData.product_material_conversion_factor,
            // 'lead_time': rowData.lead_time,
            // 'product_rm_tech_spect': rowData.product_material_tech_spect,
            'product_rm_packing_id': rowData.product_material_packing_id,
            'product_material_hsn_sac_code': rowData.hsn_sac_code,
            // 'product_rm_std_weight': rowData.product_material_std_weight,
            // 'product_rm_hsn_sac_rate':rowData.hsn_sac_code, 
            // 'product_rm_hsn_sac_rate': rowData.hsn_sac_percent,
            // 'product_rm_hsn_sac_code_id': rowData.hsn_sac_id,
            // 'product_rm_hsn_sac_code': rowData.hsn_sac_code,
            // 'product_rm_stock_unit_id': rowData.product_material_unit_id,
            'product_material_quotation_quantity': rowData.product_material_enquiry_quantity,
            'product_material_quotation_weight': rowData.product_material_enquiry_weight,
            'material_expected_schedule_date': rowData.material_schedule_date,
            // 'lead_time': rowData.expected_lead_time,
            'quotation_item_status': 'P',
            //'material_item_approval_remark': rowData.material_enquiry_approval_remark,
            'material_item_approval_remark': '',
            'product_fg_bom_no': 0,
            'product_fg_bom_version': 0,
            'product_costing_no': 0,
            // 'item_material_cost': rowData.item_material_landed_price,
            'item_material_cost': 0,
            'item_process_cost': 0,
            'item_labour_cost': 0,
            'item_administration_percent': 0,
            'item_administration_cost': 0,
            'item_profit_percent': 0,
            'item_profit_cost': 0,
            'item_other_cost': 0,
            'item_other_cost_remark': '',
            'item_discount_percent': 0,
            'item_discount_cost': 0,
            'item_taxable_cost': 0,
            'item_cgst_percent': 0,
            'item_cgst_cost': 0,
            'item_sgst_percent': 0,
            'item_sgst_cost': 0,
            'item_igst_percent': 0,
            'item_igst_cost': 0,
            'item_total_cost': 0,
            'item_terms_conditions': '',
            'item_remark': rowData.remark,
        };
    }


    const validateNumericInputs = (event) => {
        const floatNumReg = /^\d{1,18}(?:\.\d{0,4})?$/;
        let tblInputField = document.querySelector('#' + event.target.id);
        if (event.target.value === '') {
            $("#error_" + event.target.id).show();
            $("#" + event.target.id).focus();
            $("#error_" + event.target.id).text("Please enter valid input...!");
            return true;
        } else if (!floatNumReg.test(event.target.value)) {
            $("#error_" + event.target.id).show();
            $("#" + event.target.id).focus();
            $("#error_" + event.target.id).text("Please enter valid input...!");
            return false;
        } else {
            delete tblInputField.parentElement.dataset.tip;
            $("#error_" + event.target.id).hide();
            $("#error_" + event.target.id).text("");
            return true;
        }
    }

    const handleFooterDiscountPercentChange = async (e) => {
        let isValidPercentageInput = validatePercentageInput(e.target.value);
        if (isValidPercentageInput === true) {
            const percentInput = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4)
            const amount = (percentInput / 100) * txt_quotation_material_cost;
            setQuotationDiscountCost((amount).toFixed(2));
            setQuotationDiscountPercent(percentInput);
            calculateGrandTotal(bomfilteredData);
            $('#error_txt_quotation_discount_percent').hide();

        } else {
            $('#error_txt_quotation_discount_percent').text(isValidPercentageInput);
            $('#error_txt_quotation_discount_percent').show();
        }

        if (e._reactName === 'onBlur' && e.target.value === '') {
            setQuotationDiscountPercent(0);
            setQuotationDiscountPercent(0);
            calculateGrandTotal(bomfilteredData);
            delete e.target.parentElement.dataset.tip;
        }
        setTimeout(() => {
            calculateGrandTotal(bomfilteredData);
            // reverseCalculationForDetailData(bomfilteredData);
        }, 100);
    };

    const handleFooterDiscountAmountChange = async (e) => {
        const amount = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4)
        const percent = (amount * 100) / txt_quotation_material_cost;

        if (e._reactName === 'onBlur' && e.target.value === '') {
            setQuotationDiscountPercent(0);
            setQuotationDiscountCost(0);
            delete e.target.parentElement.dataset.tip;
        } else {
            setQuotationDiscountPercent((percent).toFixed(2));
            setQuotationDiscountCost(amount);
        }

        setTimeout(() => {
            calculateGrandTotal(bomfilteredData);
            // reverseCalculationForDetailData(bomfilteredData);
        }, 200);
    };

    const reverseCalculationForDetailData = (quotationDetailsData) => {
        const discount_amount = isNaN(parseFloat($('#txt_quotation_discount_cost').val())) ? 0 : parseFloat(parseFloat($('#txt_quotation_discount_cost').val()))
        if (quotationDetailsData.length !== 0) {
            const updatedData = quotationDetailsData.map(obj => {
                // Calculate item_discount_amount
                let discountAmount; let calculatedPercent;
                if (discountAmount !== 0) {
                    let quantity = isNaN(parseFloat(obj.product_material_quotation_quantity)) ? 0 : parseFloat(obj.product_material_quotation_quantity)
                    discountAmount = ((obj.material_std_rate / txt_quotation_material_cost) * discount_amount) * quantity;
                    calculatedPercent = (discountAmount * 100) / obj.material_std_rate;
                }
                // Create a new object with the updated properties
                return {
                    ...obj,
                    item_discount_cost: discountAmount === 0 ? obj.item_discount_cost : validateNumberDateInput.current.decimalNumber(JSON.stringify(discountAmount), 4),
                    item_discount_percent: discountAmount === 0 ? obj.item_discount_percent : validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedPercent), 2)
                };
            });
            setBomfilteredData(updatedData)
            sessionStorage.setItem('bomfilteredData', JSON.stringify(updatedData));
        }
    }

   
// *****************************************************Quotation Service Printout Start**************************************************************
    const quotationContent = {
        company: {
            company: '',
            company_branch: '',
        },
        customerDetails: {
            customer: '',
            address: '',
            city:'',
            state: '',
            GSTIN: '',
            pan_no: '',
            contact: '',
            email: ''
        },
        quotationDetails: {
            quotation_no: '',
            quotation_order_date: '',
            quotation_version: ''
        },
        branchDetails:{
            exp_branch_name:'',
            exp_branch_city_name:'',
            exp_branch_state_name:'',
            exp_branch_phone_no:'',
            enquiry_by:'',
        },
        footer: {
            cgst_words: '',
            sgst_words: '',
            igst_words: '',
            grand_total_words: '',

            basic_total: '',
            cgst_total: '',
            sgst_total: '',
            igst_total: '',
            taxable_total: '',
            grand_total: '',
            transport_amount: '',
            packing_amount: '',
            discount_percent:'',
            discount_amount:'',
        },

            items: [],
            paymentTerms: [],
            contactDetails:[],
            quotationTerms:[],
        };

    const printInvoice = async (openPrintModal) => {
        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesQuotationMasterServices/FnShowAllDetailsAndMastermodelRecords/${updateData.quotation_version}/${updateData.financial_year}/${COMPANY_ID}?quotation_no=${updateData.quotation_no}`)
        const response = await apiCall.json();
        console.log("Response Quotation Invoice : ", response);
        let quotationMasterData = response.SalesQtMasterServicesRecords;
        let quotationDetailsData = response.SalesQtDetailsServicesRecords;
        let QuotationPaymentTermsData = response.QuotationPaymentTermsRecord;
        let QuotationTermsServiceRecords = response.QuotationTermsTradingRecords;

        debugger

        //Quotation details
        quotationContent.quotationDetails.quotation_no = quotationMasterData.quotation_no
        quotationContent.quotationDetails.quotation_date = validateNumberDateInput.current.formatDateToDDMMYYYY(quotationMasterData.quotation_date)
        quotationContent.quotationDetails.quotation_version = quotationMasterData.quotation_version

        quotationContent.quotationDetails.quotation_type = quotationMasterData.quotation_transaction_type_desc

        //Company details
        quotationContent.company.company = quotationMasterData.company_name
        quotationContent.company.company_branch = quotationMasterData.company_branch_name
        //Customer details
        quotationContent.customerDetails.customer = quotationMasterData.customer_name
        quotationContent.customerDetails.address = quotationMasterData.customer_address
        quotationContent.customerDetails.state = quotationMasterData.customer_state_name
        quotationContent.customerDetails.city = quotationMasterData.customer_city_name
        quotationContent.customerDetails.GSTIN = quotationMasterData.cust_branch_gst_no
        quotationContent.customerDetails.pan_no = quotationMasterData.cust_branch_pan_no
        quotationContent.customerDetails.contact = quotationMasterData.cust_branch_phone_no
        quotationContent.customerDetails.email = quotationMasterData.customer_email

        //footer details    
        quotationContent.footer.basic_total = quotationMasterData.quotation_material_cost
        quotationContent.footer.cgst_total = quotationMasterData.quotation_cgst_cost
        quotationContent.footer.sgst_total = quotationMasterData.quotation_sgst_cost
        quotationContent.footer.igst_total = quotationMasterData.quotation_igst_cost
        quotationContent.footer.taxable_total = quotationMasterData.quotation_taxable_cost
        quotationContent.footer.grand_total = quotationMasterData.quotation_total_cost
        quotationContent.footer.transport_amount = quotationMasterData.transport_amount
        quotationContent.footer.packing_amount = quotationMasterData.packing_amount
        quotationContent.footer.discount_percent = txt_quotation_discount_percent
        quotationContent.footer.discount_amount = txt_quotation_discount_cost
        
        quotationContent.branchDetails.exp_branch_name = quotationMasterData.expected_branch_name
        quotationContent.branchDetails.exp_branch_city_name = quotationMasterData.expected_branch_city_name
        quotationContent.branchDetails.exp_branch_state_name = quotationMasterData.expected_branch_state_name
        quotationContent.branchDetails.exp_branch_phone_no = quotationMasterData.expected_branch_phone_no
        quotationContent.branchDetails.enquiry_by = quotationMasterData.enquiry_by_name

        for (let index = 0; index < quotationDetailsData.length; index++) {
            const element = quotationDetailsData[index];
            const detailsData = {
                sr_no: element.sr_no,
                service_name: element.product_sr_name,
                service_print_name: element.product_material_print_name,
                service_tech_spect: element.product_sr_tech_spect,
                schedule_date: validateNumberDateInput.current.formatDateToDDMMYYYY(element.material_expected_schedule_date) ,
                billing_cycle :element.process_duration,
                hsn_code: element.product_sr_hsn_sac_code,
                quantity: element.product_material_quotation_quantity,
                unit: element.product_material_unit_name,
                rate: element.material_std_rate,
                // discount_percent: element.item_discount_percent,
                discount_percent: element.quotation_discount_percent,
                discount_amount: element.quotation_discount_cost,
                cgst_percent: element.item_cgst_percent,
                sgst_percent: element.item_sgst_percent,
                igst_percent: element.item_igst_percent,
                material_total_amount: element.item_total_cost,
                company: element.company_name,
                company_branch: element.company_branch_name,
                address: element.company_address1,
                phone: element.company_phone_no,
                cell: element.company_cell_no,
                email: element.company_EmailId,
                website: element.company_website,
                GSTIN: element.company_gst_no,
                pan_no: element.company_pan_no,
                state_name: element.company_state,
                company_pincode: element.company_pincode,
            }
            quotationContent.items.push(detailsData)
        }


        const checkboxes = $('.selectCustContactPerson:checked');
        checkboxes.each(function () {
            let selectedContact_id = parseInt($(this).attr('contact_personId'));
            const customerContactDetails = custContactDetails.find(contactPerson => contactPerson.customer_contact_id === selectedContact_id);
            if (customerContactDetails) {
                const contactdata = {
                    cust_contact_person: customerContactDetails.cust_contact_person,
                    cust_contact_no: customerContactDetails.cust_contact_no,

                }
                quotationContent.contactDetails.push(contactdata);
            }
        });


        for (let index = 0; index < QuotationPaymentTermsData.length; index++) {
            const element = QuotationPaymentTermsData[index];
            const paymentTermsData = {
                payment_terms_name: element.payment_terms_name,
            }
            quotationContent.paymentTerms.push(paymentTermsData)
        }

        for (let index = 0; index < QuotationTermsServiceRecords.length; index++) {
            const element = QuotationTermsServiceRecords[index];
            const quotationTermsData = {
                quotation_terms_parameters_name: element.quotation_terms_parameters_name,
            }
            quotationContent.quotationTerms.push(quotationTermsData)
        }


        if (openPrintModal) {
            navigator('/Invoice', { state: { invoiceData: quotationContent, navigationLink: '/Transactions/TQuotationService/FrmQuotationServiceEntry', invoiceType: 'QS', title: 'Quotation Service', idList: idList, keyForViewUpdate: keyForViewUpdate, compType: compType } });
        } else {
            return renderToString(<QuotationService invoiceContent={quotationContent} />);
        }
    };
    // ************************************************Quotation Service Printout end*******************************************************************


    const FnRefreshbtn = async (key) => {
        if (keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup') {
            return;
        }

        switch (key) {
            case 'Customer':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'is_sez']
                globalQuery.table = "cmv_customer"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getCustomersApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setCustomerOptions(getCustomersApiCall);
                break;

            case 'quotationById':
            case 'AssignToemployee':
            case 'AssignToHead':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
                globalQuery.table = "cmv_employee";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                const getEmployeeListApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setQuotationByIdOptions(getEmployeeListApiCall);
                setAssignToemployee(getEmployeeListApiCall);
                setAssignToHead(getEmployeeListApiCall);
                // setApproved(getEmployeeListApiCall);
                setFollowupbyId(getEmployeeListApiCall);
                break;

            case 'EnquiryBy':
                if (cmb_department_id !== '' && cmb_department_id !== '0') {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
                    globalQuery.table = "cmv_employee"
                    globalQuery.conditions.push({ field: "department_id", operator: "=", value: cmb_department_id });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    const enquirybyIdApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                    setEnquiryByIdOptions(enquirybyIdApiCall)
                }
                break;

            case 'Agent':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'agent_std_percent', 'agent_EmailId']
                globalQuery.table = "cmv_agent"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedAgentData = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                setAgentOpts(getUpdatedAgentData);
                break;

            default:
                break;
        }
    }


    // ------------------------------- Follow Up Table Functionality starts. ----------------------------------

    const followUpBlankObj = {
        quotation_followup_transaction_id: 0,
        index: rowCount,
        followup_by_id: '',
        followup_date: today(),
        quotation_conversion_rating: '0',
        followup_remarks: '',
        positive_remarks: '',
        negative_remarks: '',
        cancelation_flag: false,
        cancelation_remarks: " ",
        next_followup_date: addDaysToDate(today(), 7),
        //next_followup_date: dt_overall_schedule_date,
        future_reference_remarks: '',
    }

    useEffect(() => {
        const getExistingFollowupData = [...followupdetailsData]
        getExistingFollowupData.push(followUpBlankObj)
        setFollowupdetailsData(getExistingFollowupData)
    }, [rowCount])


    const renderFollowupTable = useMemo(() => {
        return <>
            <Table className="erp_table" id="FollowupDataTable" bordered striped hover>
                <thead className='erp_table_head'>
                    <tr>
                        <th className="erp_table_th">Action</th>
                        <th className="erp_table_th" style={{ paddingRight: '190px' }}> Followup By</th>
                        <th className="erp_table_th"> Followup Date </th>
                        <th className="erp_table_th"> Quotation Conversion Rating</th>
                        <th className="erp_table_th"> Followup Remarks </th>
                        <th className="erp_table_th"> Positive Remarks</th>
                        <th className="erp_table_th"> Negative Remarks </th>
                        <th className="erp_table_th"> Cancelation Flag</th>
                        <th className="erp_table_th"> Cancelation Remarks</th>
                        <th className="erp_table_th"> Next Followup Date</th>
                        <th className="erp_table_th"> Future Reference Remarks</th>
                    </tr>
                </thead>

                <tbody className="erp_table_td">
                    {
                        followupdetailsData?.map((item, index) =>
                            <tr id="FollowupData-tr-1" followupval={index} rowIndex={index}>
                                <td>
                                    <IoRemoveCircleOutline className='erp_trRemove_icon'
                                        onClick={() => {
                                            if ((item.quotation_followup_transaction_id === 0 && keyForViewUpdate === 'followup')) {
                                                removeFollowupFirstRow(item.index);
                                            }
                                        }} rowIndex={index}
                                    />
                                    <IoAddCircleOutline
                                        className='erp_trAdd_icon'
                                        onClick={() => {
                                            if (txt_quotation_no !== 0 && keyForViewUpdate === 'followup') {
                                                setRowCount(rowCount + 1);
                                            }
                                        }} />
                                </td>

                                <td className='erp_table_td'>
                                    {
                                        keyForViewUpdate === 'followup'
                                            ? <>
                                                <select className="form-select form-select-sm mb-0 w-0" value={item.followup_by_id} onChange={(e) => { updatefollowupdetails(item, e); }}
                                                    disabled={keyForViewUpdate !== 'followup' || item.quotation_followup_transaction_id !== 0}
                                                    id={`followup_by_id_${index}`} Headers='followup_by_id' >
                                                    <option value=''>Select</option>
                                                    {followupbyId.map(item => <option value={item.field_id}>{item.field_name}</option>)}
                                                </select>
                                            </>
                                            : item.followup_by_name
                                    }
                                </td>
                                <td className="erp_table_td">
                                    {
                                        keyForViewUpdate === 'followup'
                                            ? <input type="date" className="erp_input_field mb-0" id={`followup_date${index}`}
                                                value={item.followup_date} max={todaydate} Headers='followup_date'
                                                disabled={keyForViewUpdate !== 'followup' || item.quotation_followup_transaction_id !== 0}
                                                onChange={(e) => { updatefollowupdetails(item, e); }} onBlur={(e) => { updatefollowupdetails(item, e); }} />
                                            : validateNumberDateInput.current.formatDateToDDMMYYYY(item.followup_date) // item.followup_date
                                    }
                                </td>

                                <td className='erp_table_td text-end'>
                                    {
                                        keyForViewUpdate === 'followup'
                                            ? <input type="text" className="erp_input_field text-end mb-0" id={`quotation_conversion_rating${index}`}
                                                value={item.quotation_conversion_rating} maxLength='19'
                                                disabled={keyForViewUpdate !== 'followup' || item.quotation_followup_transaction_id !== 0}
                                                Headers='quotation_conversion_rating' onChange={(e) => { updatefollowupdetails(item, e); }}
                                                onBlur={(e) => { updatefollowupdetails(item, e); }} />
                                            : item.quotation_conversion_rating
                                    }
                                </td>
                                < td className="erp_table_td ">
                                    {
                                        keyForViewUpdate === 'followup'
                                            ? <input type="text" className="erp_input_field_table_txt mb-0 "
                                                id={`followup_remarks_${index}`} value={item.followup_remarks} Headers='followup_remarks'
                                                disabled={keyForViewUpdate !== 'followup' || item.quotation_followup_transaction_id !== 0}
                                                onChange={(e) => { updatefollowupdetails(item, e); }} onBlur={(e) => { updatefollowupdetails(item, e); }} />
                                            : item.followup_remarks
                                    }
                                </td>
                                < td className="erp_table_td ">
                                    {
                                        keyForViewUpdate === 'followup'
                                            ? <input type="text" className="erp_input_field_table_txt mb-0 "
                                                id={`positive_remarks_${index}`}
                                                value={item.positive_remarks} Headers='positive_remarks'
                                                disabled={keyForViewUpdate !== 'followup' || item.quotation_followup_transaction_id !== 0}
                                                onChange={(e) => { updatefollowupdetails(item, e); }}
                                                onBlur={(e) => { updatefollowupdetails(item, e); }} />
                                            : item.positive_remarks
                                    }
                                </td>
                                < td className="erp_table_td ">
                                    {
                                        keyForViewUpdate === 'followup'
                                            ? <input type="text" className="erp_input_field_table_txt mb-0 " id={`negative_remarks_${index}`}
                                                value={item.negative_remarks} Headers='negative_remarks'
                                                disabled={keyForViewUpdate !== 'followup' || item.quotation_followup_transaction_id !== 0}
                                                onChange={(e) => { updatefollowupdetails(item, e); }} onBlur={(e) => { updatefollowupdetails(item, e); }} />
                                            : item.negative_remarks
                                    }
                                </td>
                                <td className="erp_table_td">
                                    <select id={`cancelation_flag${index}`}
                                        className="form-select form-select-sm mb-0" value={item.cancelation_flag}
                                        onChange={(e) => { updatefollowupdetails(item, e); }} onBlur={(e) => { updatefollowupdetails(item, e); }}
                                        disabled={keyForViewUpdate !== 'followup' || item.quotation_followup_transaction_id !== 0}
                                        Headers='cancelation_flag'>
                                        <option value={true}  >Yes</option>
                                        <option value={false} >No</option>
                                    </select></td>
                                < td className="erp_table_td ">
                                    {
                                        keyForViewUpdate === 'followup'
                                            ? <input type="text" className="erp_input_field_table_txt mb-0 " id={`cancelation_remarks${index}`}
                                                value={item.cancelation_remarks} Headers='cancelation_remarks'
                                                disabled={keyForViewUpdate !== 'followup' || item.cancelation_flag === false || item.quotation_followup_transaction_id !== 0}
                                                onChange={(e) => { updatefollowupdetails(item, e); }} onBlur={(e) => { updatefollowupdetails(item, e); }} />
                                            : item.cancelation_remarks
                                    }
                                </td>
                                <td className='erp_table_td'>
                                    {
                                        keyForViewUpdate === 'followup'
                                            ? <>
                                                <input type="date" id={`next_followup_date${index}`} className="erp_input_field mb-0" value={item.next_followup_date}
                                                    onChange={(e) => { updatefollowupdetails(item, e); }} Headers='next_followup_date'
                                                    disabled={keyForViewUpdate !== 'followup' || item.quotation_followup_transaction_id !== 0}
                                                    onBlur={(e) => { updatefollowupdetails(item, e); }} min={todaydate} />
                                            </>
                                            : validateNumberDateInput.current.formatDateToDDMMYYYY(item.next_followup_date)   // item.next_followup_date
                                    }
                                </td>
                                < td className="erp_table_td ">
                                    {
                                        keyForViewUpdate === 'followup'
                                            ? <input type="text" className="erp_input_field_table_txt mb-0 " id={`future_reference_remarks${index}`}
                                                value={item.future_reference_remarks} Headers='future_reference_remarks'
                                                onChange={(e) => { updatefollowupdetails(item, e); }} onBlur={(e) => { updatefollowupdetails(item, e); }}
                                                disabled={keyForViewUpdate !== 'followup' || item.quotation_followup_transaction_id !== 0} />
                                            : item.future_reference_remarks
                                    }
                                </td>
                            </tr>
                        )}

                </tbody>
            </Table>
        </>

    }, [followupdetailsData, followupbyId])

    const removeFollowupFirstRow = (index) => {
        if (keyForViewUpdate === 'followup' && index !== undefined) {
            if (index !== 0 || rowCount !== 1) {
                setFollowupdetailsData(followupdetailsData.filter(item => item.index !== index))
            }
        }
    }

    const updatefollowupdetails = async (rowData, event) => {
        // Destructuring the object
        let { followup_by_id, followup_date, quotation_conversion_rating, followup_remarks,
            positive_remarks, negative_remarks, cancelation_flag, cancelation_remarks,
            next_followup_date,
            future_reference_remarks, } = rowData;

        let clickedColName = event.target.getAttribute('Headers');
        let enteredValue = event.target.value;
        if (isNaN(enteredValue)) {
            enteredValue = " ";
        }
        switch (clickedColName) {
            case 'followup_by_id':
                rowData[clickedColName] = event.target.value;
                delete event.target.parentElement.dataset.tip;
                break;

            case 'followup_date':
                rowData[clickedColName] = event.target.value;
                delete event.target.parentElement.dataset.tip;
                break;

            case 'supp_designation':
                rowData[clickedColName] = event.target.value;
                delete event.target.parentElement.dataset.tip;
                break;

            case 'quotation_conversion_rating':
                let isValidPercentageInput = validatePercentageInput(enteredValue);
                if (isValidPercentageInput === true) {
                    rowData.quotation_conversion_rating = enteredValue;
                    event.target.parentElement.dataset.tip = enteredValue
                    delete event.target.parentElement.dataset.tip;
                } else {
                    rowData[clickedColName] = rowData.quotation_conversion_rating;
                    event.target.parentElement.dataset.tip = isValidPercentageInput;
                    // delete event.target.parentElement.dataset.tip;
                }
                break;

            case 'followup_remarks':
                rowData[clickedColName] = event.target.value;
                delete event.target.parentElement.dataset.tip;
                break;

            case 'positive_remarks':
                rowData[clickedColName] = event.target.value;
                delete event.target.parentElement.dataset.tip;
                break;

            case 'negative_remarks':
                rowData[clickedColName] = event.target.value;
                delete event.target.parentElement.dataset.tip;
                break;

            case 'cancelation_flag':
                rowData[clickedColName] = event.target.value;
                delete event.target.parentElement.dataset.tip;
                break;

            case 'cancelation_remarks':
                if (cancelation_flag === false) {
                    rowData[clickedColName] = '';
                    delete event.target.parentElement.dataset.tip;
                } else {
                    rowData[clickedColName] = event.target.value;
                    delete event.target.parentElement.dataset.tip;
                }
                break;

            case 'next_followup_date':
                rowData[clickedColName] = event.target.value;
                delete event.target.parentElement.dataset.tip;
                break;

            case 'future_reference_remarks':
                rowData[clickedColName] = event.target.value;
                delete event.target.parentElement.dataset.tip;
                break;

        }
        const quotaionfollowupdetailData = [...followupdetailsData]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        quotaionfollowupdetailData[arrayIndex] = rowData
        setFollowupdetailsData(quotaionfollowupdetailData);
        // localStorage.setItem('QuotationPmtTermsData', JSON.stringify(quotaionfollowupdetailData));
    }

    const addQuotationFollowupDetailData = async () => {
        setIsLoading(true)
        let checkIsValidated;
        checkIsValidated = validateRows();
        if (checkIsValidated === true) {
            var FollowupJson = { 'TransfollowupData': [], 'commonIds': {} }
            //QuotationfollowupFunctionalityData
            let Ftable = document.getElementById('FollowupDataTable');
            let Ftbody = Ftable.querySelector('tbody');
            let trFElements = Ftbody.querySelectorAll('tr');
            // let trFElements = setRowCount(rowCount + 1);

            for (let count = 0; count < trFElements.length; count++) {
                let trElement = trFElements[count];
                let FollowupTrCount = trElement.getAttribute('followupval');
                let followupBy = $('#followup_by_id_' + FollowupTrCount).val();
                let followupDate = $('#followup_date' + FollowupTrCount).val();
                let quotationConversionRating = $('#quotation_conversion_rating' + FollowupTrCount).val();
                let followupRemarks = $('#followup_remarks_' + FollowupTrCount).val();
                let positiveRemarks = $('#positive_remarks_' + FollowupTrCount).val();
                let negativeRemarks = $('#negative_remarks_' + FollowupTrCount).val();
                let cancelationFlag = $('#cancelation_flag' + FollowupTrCount).val();
                let cancelationRemarks = $('#cancelation_remarks' + FollowupTrCount).val();
                let nextFollowupDate = $('#next_followup_date' + FollowupTrCount).val();
                let futureReferenceRemarks = $('#future_reference_remarks' + FollowupTrCount).val();

                const TContactJsonData = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    quotation_followup_transaction_id: 0,
                    quotation_details_transaction_id: 0,
                    quotation_total_cost: txt_quotation_total_cost,
                    // setQuotationMasterTransactionId(quotation_master_transaction_id),
                    quotation_master_transaction_id: quotation_master_transaction_id,
                    created_by: UserName,
                    quotation_no: txt_quotation_no,
                    quotation_date: txt_quotation_date,
                    quotation_version: parseInt($('#txt_quotation_version').val()),
                    customer_id: cmb_customer_id,
                    expected_branch_id: cmb_expected_branch_id,
                    enquiry_by_id: cmb_enquiry_by_id,
                    quotation_by_id: cmb_quotation_by_id,
                    assign_to_head_id: cmb_assign_to_head_id,
                    assign_to_id: cmb_assign_to_id,
                    approved_by_id: isApprove ? cmb_approved_by_id : '',
                    approved_date: isApprove ? dt_approved_date : '',
                    followup_by_id: followupBy,
                    followup_date: followupDate,
                    quotation_conversion_rating: quotationConversionRating,
                    followup_remarks: followupRemarks,
                    positive_remarks: positiveRemarks,
                    negative_remarks: negativeRemarks,
                    cancelation_flag: cancelationFlag,
                    cancelation_remarks: cancelationRemarks,
                    next_followup_date: nextFollowupDate,
                    future_reference_remarks: futureReferenceRemarks,
                };
                console.log('tr-', count, " : ", followupBy + followupDate + quotationConversionRating + followupRemarks + positiveRemarks + negativeRemarks + cancelationFlag + cancelationRemarks + nextFollowupDate + futureReferenceRemarks);
                FollowupJson.TransfollowupData.push(TContactJsonData);
            }

            FollowupJson.commonIds.quotation_no = txt_quotation_no
            FollowupJson.commonIds.quotation_version = parseInt($('#txt_quotation_version').val())
            FollowupJson.commonIds.company_id = COMPANY_ID;
            FollowupJson.commonIds.business_type = 'Service';
            // console.log("FollowupDeatailaGirdData: ", FollowupJson)
            const FollowupDeatailaGirdData = JSON.stringify(FollowupJson)
            const formData = new FormData();
            formData.append(`MtQuotationFollowupData`, FollowupDeatailaGirdData)
            const requestOptions = { method: 'POST', body: formData };
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesQuotationMasterServices/FnAddUpdateQuotationFollowupRecord`, requestOptions)
            //const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesQuotationMasterServices/FnAddUpdateQuotationFollowupRecord`, requestOptions)
            const resp = await apiCall.json()
            if (resp.success === '0') {
                setErrMsg(resp.error)
                setShowErrorMsgModal(true)
            } else {
                const evitCache = comboDataAPiCall.current.evitCache();
                console.log(evitCache);
                console.log("resp: ", resp)
                setSuccMsg(resp.message)
                setShowSuccessMsgModal(true)
            }
        }
        setIsLoading(false);
    }

    const validateRows = () => {
        let headerDataForfollowupIsValid = validate.current.validateForm("quotationFormId");
        if (!headerDataForfollowupIsValid) {
            return false;
        }

        let QuotationFollowupIsValid = false;
        if (keyForViewUpdate === 'followup') {
            $('#FollowupDataTable tbody tr').each(function () {
                let currentTblRow = $(this);
                let quotationconversionrating = parseFloat(currentTblRow.find('input[id^="quotation_conversion_rating"]').val());
                let FollowUpEmployee = currentTblRow.find('select[id^="followup_by_id_"]').val();
                let followupdate = currentTblRow.find('input[id^="followup_date"]').val();
                let nextfollowupdate = currentTblRow.find('input[id^="next_followup_date"]').val();
                let followupremarks_ = currentTblRow.find('input[id^="followup_remarks_"]').val().trim();
                let cancelationremarks = currentTblRow.find('input[id^="cancelation_remarks"]').val().trim();
                let cancelationflag = currentTblRow.find('select[id^="cancelation_flag"]').val().trim();;

                if (isNaN(quotationconversionrating) || quotationconversionrating === '') {
                    $(currentTblRow.find('input[id^="quotation_conversion_rating"]'))[0].parentElement.dataset.tip = 'Quotation conversion rating should not be  blank...!';
                    $(currentTblRow.find('input[id^="quotation_conversion_rating"]'))[0].focus();
                    QuotationFollowupIsValid = false;
                    setOpenFollowUpAccord(true);
                    return
                } else if (FollowUpEmployee === '' || FollowUpEmployee === undefined) {
                    $(currentTblRow.find('select[id^="followup_by_id_"]'))[0].parentElement.dataset.tip = 'Please select Followup by...!';
                    $(currentTblRow.find('select[id^="followup_by_id_"]'))[0].focus();
                    QuotationFollowupIsValid = false;
                    setOpenFollowUpAccord(true);
                    return
                } else if (followupdate === '' || followupdate === null || followupdate === undefined) {
                    $(currentTblRow.find('input[id^="followup_date"]'))[0].parentElement.dataset.tip = 'Please select followup date...!';
                    $(currentTblRow.find('input[id^="followup_date"]'))[0].focus();
                    QuotationFollowupIsValid = false;
                    setOpenFollowUpAccord(true);
                    return
                } else if (nextfollowupdate === '' || nextfollowupdate === null || nextfollowupdate === undefined) {
                    $(currentTblRow.find('input[id^="next_followup_date"]'))[0].parentElement.dataset.tip = 'Please select  next followup date...!';
                    $(currentTblRow.find('input[id^="next_followup_date"]'))[0].focus();
                    QuotationFollowupIsValid = false;
                    setOpenFollowUpAccord(true);
                    return
                } else if (followupremarks_ === '' || followupremarks_ === null || followupremarks_ === undefined) {
                    $(currentTblRow.find('input[id^="followup_remarks_"]'))[0].parentElement.dataset.tip = 'Please fill followup remark...!';
                    $(currentTblRow.find('input[id^="followup_remarks_"]'))[0].focus();
                    QuotationFollowupIsValid = false;
                    setOpenFollowUpAccord(true);
                    return;
                } else if (cancelationflag === 'true' && cancelationremarks === "") {
                    $(currentTblRow.find('input[id^="cancelation_remarks"]'))[0].parentElement.dataset.tip = 'Please fill followup cancelation remark...!';
                    $(currentTblRow.find('input[id^="cancelation_remarks"]'))[0].focus();
                    QuotationFollowupIsValid = false;
                    setOpenFollowUpAccord(true);
                    return false;
                } else {
                    QuotationFollowupIsValid = true;
                    return true;
                }
            });
            return QuotationFollowupIsValid;
        }
        return QuotationFollowupIsValid;
    }

    // ------------------------------- Follow Up Table Functionality Ends. ----------------------------------



    // -----------------------------------------------------------------------------------------------------------------------
    const FnLoadAccordionData = async (eventKey) => {
        let checkedLength = 0;
        switch (eventKey) {
            case 'CommonTermsAccord':
                setIsLoading(true);
                checkedLength = $("input:checkbox[name=checkQuotationTerms]:checked").length;
                if (checkedLength === 0) {
                    await FnShowQuotationTermsRecords();
                }
                setIsLoading(false);
                break;

            case 'paymentTerms':
                setIsLoading(true);
                checkedLength = $("input:checkbox[name=selectPayTerm]:checked").length;
                if (checkedLength === 0) {
                    await FnShowPaymentTermsRecrds();
                }
                setIsLoading(false);
                break;

            case 'documentList':
                await showDocumentRecords();
                break;

            default:
                break;
        }
    }

    // ----------------------------- Common Terms section Start --------------------------------------------------------------------------------
    const FnShowQuotationTermsRecords = async () => {
        try {
            const MSTName = encodeURIComponent(commonTermMstName);
            const getQuotationCommonTerms = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CmCommonParameters/FnShowAllActiveRecords/${MSTName}/${COMPANY_ID}`)
            const responce = await getQuotationCommonTerms.json();
            if (responce.content.length !== 0) {
                const updatedContent = responce.content.map((commonTerm) => ({
                    ...commonTerm,
                    common_parameters_expected_value: commonTerm.common_parameters_value,
                    remark: '',
                }))
                    .sort((a, b) => a.common_parameters_id - b.common_parameters_id);
                responce.content = updatedContent;

                const existingCommonTermsData = [...QuotationTermsdataResp];
                let renderedCommonTermsData = responce.content
                for (let existCommonCounter = 0; existCommonCounter < existingCommonTermsData.length; existCommonCounter++) {
                    const existingCommonTermId = existingCommonTermsData[existCommonCounter].quotation_terms_parameters_id;
                    const renderedCommonTerm = renderedCommonTermsData.map((pmtTerm, index) => ({
                        index, item: pmtTerm,
                    })).find(data => parseInt(data.item.common_parameters_id) === parseInt(existingCommonTermId));
                    if (renderedCommonTerm) {
                        const existingIndex = renderedCommonTerm.index;
                        // Move the found object to the first index
                        const movedItem = renderedCommonTermsData.splice(existingIndex, 1)[0];
                        renderedCommonTermsData.unshift(movedItem);
                        renderedCommonTermsData[0].common_parameters_expected_value = existingCommonTermsData[existCommonCounter].quotation_terms_parameters_expected_value;
                        renderedCommonTermsData[0].remark = existingCommonTermsData[existCommonCounter].remark;
                    }
                }
                setQuotationTermsdata(renderedCommonTermsData);
                setTimeout(() => {
                    existingCommonTermsData.forEach(function (existingCommonTerm) {
                        $('#checkQuotationTerms_' + existingCommonTerm.quotation_terms_parameters_id).prop('checked', true);
                    });
                }, 300);
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }


    const renderTermsTable = useMemo(() => {
        return <>
            {
                QuotationTermsdata.length > 0
                    ? <Table className="erp_table" id='erp-quotationterms-table' bordered striped hover>
                        <thead className="erp_table_head">
                            <tr>
                                <th className={`erp_table_th`}>Action</th>
                                <th className="erp_table_th">Common Parameters Master </th>
                                <th className="erp_table_th">Common Parameters </th>
                                <th className="erp_table_th">Common Parameters Value</th>
                                <th className="erp_table_th">Common Prameters Expected Value</th>
                                <th className="erp_table_th">Remark</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                QuotationTermsdata.map((quotationTerms, Index) =>
                                    <tr rowIndex={Index}>
                                        <td className={`erp_table_td`}>
                                            <div style={{ display: "flex" }}>
                                                <input type="checkbox" name="checkQuotationTerms" className="form-check-input checkQuotationTerms" commonTermId={quotationTerms.common_parameters_id}
                                                    value={quotationTerms.common_parameters_id} id={'checkQuotationTerms_' + quotationTerms.common_parameters_id}
                                                    onChange={() => toggleQuotationChkBoxes('PartiallyCommonTermSelection')}
                                                    {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'followup' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}
                                                />
                                            </div>
                                        </td>
                                        <td className="erp_table_td">{quotationTerms.common_parameters_master_name}</td>
                                        <td className="erp_table_td">{quotationTerms.common_parameters_name}</td>
                                        <td className="erp_table_td">{quotationTerms.common_parameters_value}</td>
                                        <td className="erp_table_td">
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'followup' || keyForViewUpdate === 'approve'
                                                    ? quotationTerms.common_parameters_expected_value
                                                    : <><input type="text" id={`common_parameters_expected_value_${quotationTerms.common_parameters_id}`}
                                                        className="erp_input_field mb-0 "
                                                        value={quotationTerms.common_parameters_expected_value}
                                                        disabled={isApprove || keyForViewUpdate === 'followup' || keyForViewUpdate === 'view' ? true : false}
                                                        onChange={(e) => { FnUpdateTermsTblData(quotationTerms, e); }}
                                                        Headers='common_parameters_expected_value'
                                                    /></>
                                            }
                                        </td>
                                        <td className="erp_table_td">
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                                    ? quotationTerms.remark
                                                    : <textarea type="text" className="erp_input_field mb-0" id={`remark_${quotationTerms.common_parameters_id}`}
                                                        Headers='remark' value={quotationTerms.remark} onChange={(e) => { FnUpdateTermsTblData(quotationTerms, e) }}></textarea>
                                            }
                                        </td>
                                    </tr>
                                )

                            }
                        </tbody>
                    </Table>
                    : ""
            }
        </>
    }, [QuotationTermsdata]);

    const FnUpdateTermsTblData = (currentCommmonTerm, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        delete event.target.parentElement.dataset.tip;
        currentCommmonTerm[clickedColName] = event.target.value;
        const updatedCommonTermsData = [...QuotationTermsdata];
        const commonTermIndexInArray = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedCommonTermsData[commonTermIndexInArray] = currentCommmonTerm;
        setQuotationTermsdata(updatedCommonTermsData);
        localStorage.setItem('EnquiryCommonTermsData', JSON.stringify(updatedCommonTermsData));
    }

    // ----------------------------- Common Terms section Ends --------------------------------------------------------------------------------



    //---------------------------------- Payment Terms section Start -----------------------------------------------------------------------
    const FnShowPaymentTermsRecrds = async () => {
        try {
            const pyTermsApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/paymentterms/FnShowAllActiveRecords`)
            const responce = await pyTermsApiCall.json();
            if (responce.data.content.length !== 0 && responce.data.content.length > 0) {
                const updatedContent = responce.data.content.map((paymentTerm) => ({
                    ...paymentTerm,
                    remark: '',
                })).sort((a, b) => a.payment_terms_id - b.payment_terms_id);
                responce.data.content = updatedContent;
                const existingPMTTermsData = [...PmtTermsdataResp];
                let renderedPMTTermsData = responce.data.content
                for (let existPMTCounter = 0; existPMTCounter < existingPMTTermsData.length; existPMTCounter++) {
                    const existingPMTTermId = existingPMTTermsData[existPMTCounter].payment_terms_id;
                    const renderedPMTTerm = renderedPMTTermsData.map((pmtTerm, index) => ({
                        index, item: pmtTerm,
                    })).find(data => data.item.payment_terms_id === existingPMTTermId);
                    if (renderedPMTTerm) {
                        const existingIndex = renderedPMTTerm.index;
                        // Move the found object to the first index
                        const movedItem = renderedPMTTermsData.splice(existingIndex, 1)[0];
                        renderedPMTTermsData.unshift(movedItem);
                        renderedPMTTermsData[0].payment_terms_Milestome = existingPMTTermsData[existPMTCounter].payment_terms_Milestome;
                        renderedPMTTermsData[0].payment_percent = existingPMTTermsData[existPMTCounter].payment_percent;
                        renderedPMTTermsData[0].remark = existingPMTTermsData[existPMTCounter].remark;
                    }
                }
                setPmtTermsData(renderedPMTTermsData);
                setTimeout(() => {
                    PmtTermsdataResp.forEach(function (existingPayTerm) {
                        $('#selectPmtTerm_' + existingPayTerm.payment_terms_id).prop('checked', true);
                    });
                }, 200);
                // }
            } else {
                setPmtTermsData([]);
            }
        } catch (error) {
            console.log("error: ", error)
        }

        // Onchange event listener for the POTerm Selection
        $('.selectPmtTerm').on('change', function () {
            // if it is checked then focus the.
            let paymentTermId = $(this).attr("pmtTermId");
            if (this.checked) {
                if ($(`#payment_milestone_${paymentTermId}`).val() === '') {
                    $(`#payment_milestone_${paymentTermId}`).focus();
                } else if ($(`#payment_percent_${paymentTermId}`).val() === '') {
                    $(`#payment_percent_${paymentTermId}`).focus();
                }
            } else {
                let mileStoneField = document.querySelector('#payment_milestone_' + paymentTermId);
                let percentageField = document.querySelector('#payment_percent_' + paymentTermId);
                // let dateField = document.querySelector('#payment_expected_date_' + paymentTermId);
                delete mileStoneField.parentElement.dataset.tip;
            }
            toggleQuotationChkBoxes('PartiallyPmtTermsSelection');
        });
    }

    const renderPaymentTermsTable = useMemo(() => {
        return <>
            {
                PmtTermsdata.length > 0
                    ? <Table className="erp_table" id='QuotationPaymentTermsTbl' bordered striped>
                        <thead className="erp_table_head">
                            <tr>
                                <th className={`erp_table_th`}>Action</th>
                                <th className="erp_table_th">Payment Term Name</th>
                                <th className="erp_table_th">Payment Term Days</th>
                                <th className="erp_table_th">Grace Days</th>
                                <th className={`erp_table_th`}>Payment Milestome</th>
                                <th className="erp_table_th">Payment Percentage</th>
                                <th className="erp_table_th">Remark</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                PmtTermsdata?.map((paymentTerm, Index) =>
                                    <tr rowIndex={Index} paymentTermId={paymentTerm.payment_terms_id}>
                                        <td className={`erp_table_td`}>
                                            <div style={{ display: "flex" }}>
                                                <input type="checkbox" name="selectPmtTerm" className="form-check-input selectPmtTerm" pmtTermId={paymentTerm.payment_terms_id} value={paymentTerm.payment_terms_id} id={'selectPmtTerm_' + paymentTerm.payment_terms_id}
                                                    {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'followup' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}
                                                />
                                            </div>
                                        </td>
                                        <td className="erp_table_td">{paymentTerm.payment_terms_name}</td>
                                        <td className="erp_table_td text-end">{paymentTerm.payment_terms_days}</td>
                                        <td className="erp_table_td text-end">{paymentTerm.payment_terms_grace_days}</td>

                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                                ? paymentTerm.payment_terms_Milestome
                                                : <input type="text" className="erp_input_field mb-0" value={paymentTerm.payment_terms_Milestome} pmtTermId={paymentTerm.payment_terms_id} id={"payment_milestone_" + paymentTerm.payment_terms_id} maxlength='255'
                                                    Headers='payment_terms_Milestome' onChange={(e) => { updatePmtTermsTblData(paymentTerm, e); }} onBlur={(e) => { updatePmtTermsTblData(paymentTerm, e); }} />
                                            }
                                        </td>

                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                                ? paymentTerm.payment_percent
                                                : <input type="text" className="erp_input_field mb-0 text-end" value={paymentTerm.payment_percent} pmtTermId={paymentTerm.payment_terms_id} id={"payment_percent_" + paymentTerm.payment_terms_id}
                                                    Headers='payment_percent' onChange={(e) => { updatePmtTermsTblData(paymentTerm, e); }} onBlur={(e) => { updatePmtTermsTblData(paymentTerm, e); }} />
                                            }
                                        </td>
                                        <td className="erp_table_td">
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup'
                                                    ? paymentTerm.remark
                                                    : <textarea type="text" rows={1} className="erp_input_field mb-0" value={paymentTerm.remark} pmtTermId={paymentTerm.payment_terms_id} id={"payment_term_remark_" + paymentTerm.payment_terms_id}
                                                        Headers='remark' onChange={(e) => { updatePmtTermsTblData(paymentTerm, e); }} onBlur={(e) => { updatePmtTermsTblData(paymentTerm, e); }}>
                                                    </textarea>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    : ''
            }
        </>
    }, [PmtTermsdata]);

    const updatePmtTermsTblData = (currentPaymentTerm, e) => {
        let clickedColName = e.target.getAttribute('Headers');
        delete e.target.parentElement.dataset.tip;
        switch (clickedColName) {
            case 'payment_expected_value':
                currentPaymentTerm[clickedColName] = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4);
                delete e.target.parentElement.dataset.tip;
                break;

            case 'payment_percent':
                let isValidPercentageInput = validatePercentageInput(e.target.value);
                if (isValidPercentageInput === true) {
                    currentPaymentTerm[clickedColName] = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4);
                    delete e.target.parentElement.dataset.tip;
                } else {
                    const currentTblRow = e.target.parentElement.parentElement;
                    const checkbox = currentTblRow.querySelector('input[id^="selectPmtTerm_"]');
                }
                break;

            case 'payment_terms_Milestome':
            case 'remark':
                currentPaymentTerm[clickedColName] = e.target.value;
                delete e.target.parentElement.dataset.tip;
                break;
            default:
                break;
        }

        // update the Payment terms table data.
        const updatedPaymentTermsData = [...PmtTermsdata]
        const pmtTermIndexInArray = parseInt(e.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedPaymentTermsData[pmtTermIndexInArray] = currentPaymentTerm;
        setPmtTermsData(updatedPaymentTermsData);
        localStorage.setItem('QuotationPmtTermsData', JSON.stringify(updatedPaymentTermsData));
    }
    //---------------------------------- Payment Terms section Ends -----------------------------------------------------------------------

    //---------------------------------------------------- Accordian data load Ends --------------------------------------------------------------------------------
    const excludedcustFields = ['is_delete', 'is_active', 'created_on', 'created_by', 'modified_on', 'modified_by', 'deleted_by',
        'deleted_on', 'document_id', 'company_id'];

    // Use the replace method to replace "/" with "_"
    let group_id = txt_quotation_no.replace(/\//g, "_");
    const memorizedDocumentComponent = useMemo(() => {
        return <>
            {/* Document modal */}
            <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title className='erp_modal_title'>Document For {txt_quotation_no}</Modal.Title>
                    <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                </Modal.Header>
                <Modal.Body>
                    <Document group_id={group_id} document_group={docGroup} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }, [showDocumentForm])

    const showDocumentRecords = async () => {
        try {
            if (COMPANY_ID !== null) {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_quotation_no !== 0 ? txt_quotation_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    setDocumentData(resp.content);
                } else {
                    setDocumentData([]);
                }
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const renderDocumentTable = useMemo(() => {
        return <>
            <Table className="erp_table" id='document-table' bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Group Id</th>
                        <th className="erp_table_th">Document Group</th>
                        <th className="erp_table_th">Document Name</th>
                        <th className="erp_table_th">Registration No</th>
                        <th className="erp_table_th">Registration Date</th>
                        <th className="erp_table_th">Renewal Date</th>
                        <th className="erp_table_th">Document Path</th>
                        <th className="erp_table_th">Remark</th>
                        <th className="erp_table_th">File Name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        docData.map((docItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td"> {docItem.group_id} </td>
                                <td className="erp_table_td">{docItem.document_group}</td>
                                <td className="erp_table_td">{docItem.document_name}</td>
                                <td className="erp_table_td">{docItem.document_registration_no}</td>
                                <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(docItem.document_registration_date)}</td>
                                <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(docItem.document_renewal_date)}</td>
                                <td className="erp_table_td">{docItem.document_path}</td>
                                <td className="erp_table_td">{docItem.remark}</td>
                                <td className="erp_table_td">
                                    <MDTypography component="label" className="erp-form-label" variant="button" id="logoFile" fontWeight="regular" color="info" >
                                        <Link to="#" onClick={() => { fetchDocument(docItem) }}> {docItem.file_name} </Link>
                                    </MDTypography>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [docData]);

    const fetchDocument = async (docItem) => {
        try {
            const formData = new FormData()
            const data = {
                document_group: docItem.documentGroup,
                group_id: docItem.group_id,
                document_path: docItem.document_path
            }
            formData.append(`getFile`, JSON.stringify(data))
            const requestOptions = { method: 'POST', body: formData };
            const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
            const blob = await getDocApiCall.blob()

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${docItem.file_name}`,);
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.log("error: ", error)
        }
    }

    //---------------------------------------------------- Accordian data load Ends --------------------------------------------------------------------------------

    // --------------------------------------- Delete the transaction functionality starts.------------------------------------------------------------------
    const deleteRecords = async () => {
        // setIsLoading(true);
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesQuotationMasterServices/FnDeleteRecord/${updateData.quotation_version}/${COMPANY_ID}/${UserName}?quotation_no=${updateData.quotation_no}`, method)
            const responce = await deleteApiCall.json();
            // console.log("Company Deleted: ", responce);
            if (responce.success == '1') {
                setShowDeleteModal(false)
                moveToListing();
            }
        } catch (error) {
            console.log("error: ", error)
        }
        // setIsLoading(false);
    }

    const deleteshow = () => {
        setShowDeleteModal(true)
    }
    // --------------------------------------- Delete the transaction functionality ends.------------------------------------------------------------------


    // ------------------- Existing functionality table Functionality start.---------------------------------
    const existingFunctionalityBlankObj = {
        quotation_existing_functionality: '',
        quotation_expected_functionality: '',
        quotation_expected_value: '',
    }

    useEffect(() => {
        const getExistingFollowupData = [...existingFunctionalityTblData]
        getExistingFollowupData.push(existingFunctionalityBlankObj)
        setExistingFunctionalityTblData(getExistingFollowupData)
    }, [functionalityrowcount]);

    const renderExistingFunctionalityTable = useMemo(() => {
        return <> <Table className="erp_table" id="ExpectedFunctionality" bordered striped hover>
            <thead className='erp_table_head'>
                <tr>
                    <th className="erp_table_th">Action</th>
                    <th className="erp_table_th"> Existing Functionality</th>
                    <th className="erp_table_th"> Expected Functionality </th>
                    <th className="erp_table_th"> Expected Value</th>
                </tr>
            </thead>
            <tbody className="erp_table_td">
                {
                    existingFunctionalityTblData?.map((existingFunctionality, index) =>
                        <tr rowIndex={index}>
                            <td>
                                <IoRemoveCircleOutline className='erp_trRemove_icon'
                                    onClick={() => {
                                        if ((keyForViewUpdate !== 'followup' && keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve')) {
                                            removeExistingFunctionalityTblRow(index);
                                        }
                                    }} rowIndex={index} />
                                <IoAddCircleOutline className='erp_trAdd_icon'
                                    onClick={() => {
                                        if ((keyForViewUpdate !== 'followup' && keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve')) {
                                            setFunctionalityrowcount(functionalityrowcount + 1);
                                        }
                                    }} />
                            </td>
                            < td className="erp_table_td ">
                                {
                                    keyForViewUpdate !== 'followup' && keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                        ? <Form.Control as="textarea" id={`expectedvalue-${index}`} value={existingFunctionality.quotation_existing_functionality}
                                            Headers="quotation_existing_functionality" onInput={(e) => { updateExistingFunctionalityTbl(existingFunctionality, e) }} className="erp_input_field mb-0" maxLength="1000" />
                                        : existingFunctionality.quotation_existing_functionality
                                }
                            </td>
                            < td className="erp_table_td ">
                                {
                                    keyForViewUpdate !== 'followup' && keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                        ? <Form.Control as="textarea" id={`expectedfunctionality-${index}`} value={existingFunctionality.quotation_expected_functionality}
                                            Headers="quotation_expected_functionality" onInput={(e) => { updateExistingFunctionalityTbl(existingFunctionality, e) }} className="erp_input_field mb-0" maxLength="1000" />
                                        : existingFunctionality.quotation_expected_functionality
                                }
                            </td>
                            < td className="erp_table_td ">
                                {
                                    keyForViewUpdate !== 'followup' && keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                                        ? <Form.Control as="textarea" id={`expectedvalue-${index}`} value={existingFunctionality.quotation_expected_value}
                                            Headers="quotation_expected_value" onInput={(e) => { updateExistingFunctionalityTbl(existingFunctionality, e) }} className="erp_input_field mb-0" maxLength="1000" />
                                        : existingFunctionality.quotation_expected_value
                                }
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </Table>
        </>
    }, [existingFunctionalityTblData, functionalityrowcount]);

    const updateExistingFunctionalityTbl = (currentExistingFunctionality, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        currentExistingFunctionality[clickedColName] = event.target.value;
        const updatedTblData = [...existingFunctionalityTblData]
        const existingTblData = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedTblData[existingTblData] = currentExistingFunctionality;
        setExistingFunctionalityTblData(updatedTblData)
    };

    const removeExistingFunctionalityTblRow = (indexToRemove) => {
        if (keyForViewUpdate !== 'followup' && keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && indexToRemove !== undefined) {
            if (indexToRemove !== 0) {
                const updatedTblData = existingFunctionalityTblData.filter((item, index) => index !== indexToRemove);
                setExistingFunctionalityTblData(updatedTblData);
            } else {
                const getExistingFollowupData = [...existingFunctionalityTblData]
                getExistingFollowupData[0] = { ...existingFunctionalityBlankObj }
                setExistingFunctionalityTblData(getExistingFollowupData)
            }
        }
    }
    // ------------------- Existing functionality table Functionality start.---------------------------------

    // -------------------- Emailing Functionality Starts. ------------------------------------
    const generateMailJson = async () => {
        let EmailData = { 'EmailType': "Quotation Status Updatation", 'toMailData': [], 'bccData': [], 'ccData': [], 'subject': {}, 'mailAttachmentFilePaths': [], 'parameters': [] }
        const checkboxes = $('.selectCustContactPerson:checked');
        checkboxes.each(function () {
            let selectedContact_id = parseInt($(this).attr('contact_personId'));
            const customerContactPersonDetails = custContactDetails.find(contactPerson => contactPerson.customer_contact_id === selectedContact_id);
            if (customerContactPersonDetails) {
                EmailData.toMailData.push(customerContactPersonDetails.cust_email_id);
            }
        });
        EmailData.subject = `Quotation Approval Confirmation - Order ${txt_quotation_no}`;          // Subject
        EmailData.parameters.push("Quotation Information");                                         // Param0 It is a heading.
        EmailData.parameters.push($('#cmb_customer_id').find(":selected").text());                  // Param1
        EmailData.parameters.push(COMPANY_NAME);
        EmailData.parameters.push(COMPANY_NAME);
        return EmailData;
    }

    function dataURItoBlob(dataURI) {
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    }

    const sendEmail = async () => {
        try {
            setShowMailSentModal(false);
            setIsLoading(true);
            // $('#spinner_id').show();
            // $('#spinner_text').text('Sending mail...');
            let emailContent = await generateMailJson();

            // Generate the sales-order PDF.
            // let quotationContent = await printInvoice(false);
            let quotationContent = '';
            const fileName = `${txt_quotation_no.replaceAll('/', '_')}_@${COMPANY_ID}.pdf`;
            const quotaionContainer = document.createElement('div');
            quotaionContainer.style.margin = '20px';
            quotaionContainer.innerHTML = quotationContent;
            let worker = html2pdf().from(quotaionContainer).set({
                margin: 0,
                filename: fileName,
                html2canvas: { scale: 2 },
                jsPDF: { orientation: 'portrait', unit: 'pt', format: 'a4', compressPDF: false }
            }).toPdf();

            let pages = [];
            pages.slice(1).forEach(function (page) {
                worker = worker.get('pdf').then(function (pdf) {
                    pdf.addPage();
                }).from(page).toContainer().toCanvas().toPdf();
            });

            worker = worker.output("datauristring").then(async function (pdf) {
                const preBlob = dataURItoBlob(pdf);
                const file = new File([preBlob], fileName, { type: 'application/pdf' });

                const formData = new FormData();
                formData.append(`EmailData`, JSON.stringify(emailContent))
                formData.append("quotationPDF", file);

                const requestOptions = { method: 'POST', body: formData };
                const emailingApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesQuotationMasterServices/FnSendEmail/${quotation_master_transaction_id}/${COMPANY_ID}`, requestOptions);
                const emailingApiCallResponse = await emailingApiCall.json();

                if (emailingApiCallResponse.success === "1") {
                    // Show success message.
                    setSuccMsg(emailingApiCallResponse.message);
                    setShowSuccessMsgModal(true);
                } else {
                    // Show error message.
                    setErrMsg(emailingApiCallResponse.message);
                    setShowErrorMsgModal(true);
                }
            });
            //$('#spinner_id').hide();
        } catch (error) {
            // $('#spinner_id').hide();
            console.log('Error in email sending: ', error);
        }
    }
    // ------------------------------Emailing functionality ends. ---------------------------------------

    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <ExcelExport ref={exlsExp} />
                <GenerateTAutoNo ref={generateAutoNoAPiCall} />
                <FrmValidations ref={validate} />
                <ValidateNumberDateInput ref={validateNumberDateInput} />
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" className="text-primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}
                <div className='card p-1'>
                    <div className='card-header text-center py-1'>
                        <label className='erp-form-label-lg text-center'>Service Quotation <span id="submain_heading">(Creation)</span></label>
                    </div>

                    <form id="quotationFormId">

                        <div className="row p-1">

                            {/* //first column */}
                            <div className="col-sm-6 erp_form_col_div">
                                <   div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Quotation Transaction Type<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Manual" type="radio" lbl="Manual" value="M" name="rb_quotation_transaction_type" checked={rb_quotation_transaction_type === "M"} onClick={() => { setQuotationTransactionType("M"); comboOnChange('by_direct') }} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="Enquiry Based" type="radio" lbl="Enquiry Based" value="E" name="rb_quotation_transaction_type" checked={rb_quotation_transaction_type === "E"} onClick={() => { setQuotationTransactionType("E"); comboOnChange('by_direct'); }} /> </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4 col-12">
                                        <Form.Label className="erp-form-label">Quotation No., Ver.& Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-8 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id="txt_quotation_no" className="erp_input_field" value={txt_quotation_no} optional='optional' disabled />
                                                <MDTypography variant="button" id="error_txt_quotation_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-2 pt-md-0 pt-3 pe-md-0 ps-md-1">
                                                <Form.Control type="text" id='txt_quotation_version' className="erp_input_field text-end" value={txt_quotation_version} optional='optional' disabled />
                                                <MDTypography variant="button" id="error_txt_quotation_version" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-4 pt-md-0 pt-3 ps-md-1">
                                                <Form.Control type="date" id='txt_quotation_date' className="erp_input_field" value={txt_quotation_date} onChange={e => { setQuotation_date(e.target.value); validateFields(); }} {...(keyForViewUpdate === '' || keyForViewUpdate === 'copy' ? {} : { disabled: true })} />
                                                {/* <Form.Control type="date" id='txt_quotation_date' className="erp_input_field" value={txt_quotation_date} onChange={e => { setEnquiryDate(e.target.value); validateFields(); }} disabled /> */}
                                                <MDTypography variant="button" id="error_txt_quotation_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Quotation Status<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_quotation_status" className="form-select form-select-sm" value={cmb_quotation_status} onChange={() => { comboOnChange('quotationdById'); validateFields(); }} disabled >
                                            <option value="P" selected>Pending</option>
                                            <option value="A" disabled>Approved</option>
                                            <option value="R" disabled>Rejected</option>
                                            <option value="O" disabled>Order Confirm</option>
                                            <option value="C" disabled>Completed</option>
                                            <option value="X" disabled>Canceled</option>
                                            <option value="Z" disabled>PreeClosed</option>
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_quotation_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Order Life <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Closed" type="radio" lbl="Closed" value="C" name="rb_quotation_life" checked={rb_quotation_life === "C"} onClick={() => setQuotationLife("C")} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="Open" type="radio" lbl="Open" value="O" name="rb_quotation_life" checked={rb_quotation_life === "O"} onClick={() => setQuotationLife("O")} /> </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Customer<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-md-7 col-sm-12 col-10'>
                                        <select id="cmb_customer_id" className="form-select form-select-sm" value={cmb_customer_id} onChange={(e) => { { if (e.target.value !== '0') { setCustomerId(e.target.value) } }; comboOnChange('Customer'); validateFields(); }}>
                                            <option value="" disabled>Select</option>
                                            <option value="0">Add New Record+</option>
                                            {customerOptions?.map(customer => (
                                                <option value={customer.field_id} is_sez={`${customer.is_sez}`}>{customer.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="right">
                                            <MDTypography >
                                                <MdRefresh onClick={() => FnRefreshbtn('Customer')} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Customer State & City</Form.Label>
                                    </div>
                                    <div className="col-sm-8 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <select id="cmb_customer_state_id" className="form-select form-select-sm" value={cmb_customer_state_id} onChange={(e) => { setCustomerState(e.target.value); comboOnChange('CustomerState'); validateFields(); }} disabled='disabled'  >
                                                    <option value="">Select</option>
                                                    {stateOpts?.map(state => (
                                                        <option value={state.field_id} stateCode={state.state_code}>{state.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_customer_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                <select id="cmb_customer_city_id" className="form-select form-select-sm" value={cmb_customer_city_id} onChange={(e) => { setCustomerCity(e.target.value); comboOnChange('CustomerCity'); validateFields(); }} optional="optional" disabled='disabled'>
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {customerCityOpts?.map(city => (
                                                        <option value={city.field_id}>{city.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_customer_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Our Company Branch<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_expected_branch_id" className="form-select form-select-sm" value={cmb_expected_branch_id} onChange={(e) => { comboOnChange("expt_branch"); validateFields(); }} >
                                            <option value="" disabled>Select</option>
                                            {expectedBranchOptions.length !== 0 ?
                                                <>
                                                    {expectedBranchOptions?.map(cmb_expected_branch_id => (
                                                        <option value={cmb_expected_branch_id.company_branch_id} chk_isSez={`${cmb_expected_branch_id.is_sez}`}>{cmb_expected_branch_id.company_branch_name}</option>
                                                    ))}
                                                </>
                                                : ''
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_expected_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row d-none'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Our Branch State & City</Form.Label>
                                    </div>
                                    <div className="col-sm-8 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <select id="cmb_expected_branch_state_id" className="form-select form-select-sm" value={cmb_expected_branch_state_id} onChange={(e) => { comboOnChange("expt_branchState"); validateFields(); }} disabled='disabled' >
                                                    <option value="">Select</option>
                                                    {expectedBranchStateOptions?.map(cmb_expected_branch_state_id => (
                                                        <option value={cmb_expected_branch_state_id.field_id}>{cmb_expected_branch_state_id.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_expected_branch_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3">
                                                <select id="cmb_expected_branch_city_id" className="form-select form-select-sm" value={cmb_expected_branch_city_id} onChange={(e) => { setExpected_branch_city_id(e.target.value); comboOnChange('company_city'); validateFields(); }} disabled='disabled' >
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {expectedBranchCityOptions?.map(cmb_expected_branch_city_id => (
                                                        <option value={cmb_expected_branch_city_id.field_id}>{cmb_expected_branch_city_id.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_expected_branch_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Expected Scheduled Date<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="date" id='dt_overall_schedule_date' className="erp_input_field" value={dt_overall_schedule_date} onChange={(e) => { setOverAllScheduleDate(e.target.value); validateFields(); }} min={todaydate} />
                                        <MDTypography variant="button" id="error_dt_overall_schedule_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                </div>

                                <div className="row mt-3 mt-md-0">
                                    <Accordion defaultActiveKey="0" activeKey={openCustContAccord ? '0' : null}>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenCustContAccord(!openCustContAccord)}>Customer's Contact Details</Accordion.Header>
                                            <Accordion.Body className="p-0">
                                                {
                                                    cmb_customer_id !== '' && cmb_customer_id !== '0'
                                                        ? <>
                                                            <div className={`row ps-3 py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                                                <Tooltip title="Add new customer's contact if doesn't exist in list...!" placement="top">
                                                                    <MDButton type="button" className={`erp-gb-button float-start  col-12 col-md-4`} variant="button" fontWeight="regular" onClick={() => {
                                                                        localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                                        localStorage.setItem('customerIDs', JSON.stringify({ customerID: cmb_customer_id, keyForViewUpdate: 'update', compType: 'Master' }))
                                                                    }
                                                                    }  ><Link className={'text-light'} to={{ pathname: "/Masters/Customer" }} target="_blank" >Add New Customer's Contact +</Link></MDButton>
                                                                </Tooltip>
                                                                <Tooltip title="Refresh customer contact list...!" placement="top">
                                                                    <MDButton type="button" className={`erp-gb-button float-end col-1 ms-0 ms-md-2 mt-1 mt-md-0`} variant="button" fontWeight="regular" onClick={() => { FnGetCustomersContactPersonsDetails(parseInt(cmb_customer_id)) }}>Refresh</MDButton>
                                                                </Tooltip>
                                                            </div>
                                                        </>
                                                        : null
                                                }

                                                {
                                                    custContactDetails.length > 0
                                                        ? <>
                                                            <div className='row'>
                                                                <div className="col-12">
                                                                    <Table className="erp_table erp_table_scroll" id='customerContactPersonsTable' responsive bordered striped>
                                                                        <thead className="erp_table_head">
                                                                            <tr>
                                                                                <th className={`erp_table_th`}> <div className="col-1"><input type='checkbox' className="form-check-input me-1" name="selectAllCustContact" id="selectAllCustContact" onClick={(e) => { toggleQuotationChkBoxes('selectAllCustContact'); }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false} />Select</div></th>
                                                                                <th className='erp_table_th'>Customer Branch</th>
                                                                                <th className='erp_table_th'>Contact Person</th>
                                                                                <th className='erp_table_th'>Contact No</th>
                                                                                <th className={`erp_table_th `}>Email</th>
                                                                                <th className={`erp_table_th`}>Alternate Email</th>
                                                                                <th className={`erp_table_th`}>Alternate Contact No</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                custContactDetails.map((item, index) =>
                                                                                    <tr rowindex={index}>
                                                                                        <td className='erp_table_td'>
                                                                                            <input type='checkbox' className="form-check-input selectCustContactPerson" name="selectCustContactPerson" id={'selectCustContactPerson_' + item.customer_contact_id} contact_personId={item.customer_contact_id} value={item.customer_contact_id}
                                                                                                onClick={() => toggleQuotationChkBoxes('PartiallyCustContactSelection')} />
                                                                                        </td>
                                                                                        <td className="erp_table_td">{item.customer_branch_name}</td>
                                                                                        <td className="erp_table_td">{item.cust_contact_person}</td>
                                                                                        <td className="erp_table_td"> {item.cust_contact_no} </td>
                                                                                        <td className={`erp_table_td`}>{item.cust_email_id}</td>
                                                                                        <td className={`erp_table_td`}>{item.cust_alternate_EmailId}</td>
                                                                                        <td className={`erp_table_td`}>{item.cust_alternate_contact}</td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : ""
                                                }
                                                {
                                                    custContactDetails.length !== 0 && !['approve', 'view'].includes(keyForViewUpdate)
                                                        ? <>
                                                            <div className="row justify-content-center my-1">
                                                                <Tooltip title="Confirm selection...!" placement="right">
                                                                    <MDButton type="button" className={`erp-gb-button float-end col-1`} variant="button" fontWeight="regular"
                                                                        onClick={(e) => {
                                                                            let selectedCustCont = $('.selectCustContactPerson:checked');
                                                                            if (selectedCustCont.length > 0) {
                                                                                setOpenCustContAccord(false)
                                                                                $('#error_customer_contact_persons').hide();
                                                                            } else {
                                                                                $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                                                                                $('#error_customer_contact_persons').show();
                                                                            }
                                                                        }}
                                                                    >Confirm</MDButton>
                                                                </Tooltip>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion >
                                    <MDTypography variant="button" id="error_customer_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                </div>
                            </div>

                            {/* //second column */}
                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Department </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_department_id" className="form-select form-select-sm" value={cmb_department_id} onChange={() => { comboOnChange('departmentId'); validateFields(); }} optional="optional" >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {departmentOpts?.map(departmentId => (
                                                <option value={departmentId.field_id}>{departmentId.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-4 col-12">
                                        <Form.Label className="erp-form-label">Quotation By <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-md-7 col-sm-10 col-10'>
                                        <select id="cmb_quotation_by_id" className="form-select form-select-sm" value={cmb_quotation_by_id} onChange={(e) => { setQuotationById(e.target.value); comboOnChange('quotationById'); validateFields(); }} >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {quotationByIdOptions?.map(quotationdById => (
                                                <option value={quotationdById.field_id}>{quotationdById.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_quotation_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                    <div className="col">
                                        <Tooltip title="Refresh" placement="right">
                                            <MDTypography >
                                                <MdRefresh onClick={() => FnRefreshbtn('quotationById')} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4 ">
                                        <Form.Label className="erp-form-label">Enquiry By <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-md-7 col-sm-10 col-10'>
                                        <select id="cmb_enquiry_by_id" className="form-select form-select-sm" value={cmb_enquiry_by_id} onChange={(e) => { setEnquiryById(e.target.value); comboOnChange('EnquiryBy'); validateFields(); }}>
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {enquiryByIdOptions?.map(enquirydById => (
                                                <option value={enquirydById.field_id}>{enquirydById.field_name}</option>

                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_enquiry_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>  </MDTypography>
                                    </div>
                                    <div className="col">
                                        <Tooltip title="Refresh" placement="right">
                                            <MDTypography >
                                                <MdRefresh onClick={() => FnRefreshbtn('EnquiryBy')} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label"> Assign To Employee <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-md-7 col-sm-10 col-10'>
                                        <select id="cmb_assign_to_id" className="form-select form-select-sm" value={cmb_assign_to_id} onChange={(e) => { setAssigToId(e.target.value); comboOnChange('AssignToemployee'); validateFields(); }} >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {assignToemployee?.map(branchId => (
                                                <option value={branchId.field_id}>{branchId.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_assign_to_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                    <div className="col">
                                        <Tooltip title="Refresh" placement="right">
                                            <MDTypography >
                                                <MdRefresh onClick={() => FnRefreshbtn('AssignToemployee')} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label"> Assign To Head <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-md-7 col-sm-10 col-10'>
                                        <select id="cmb_assign_to_head_id" className="form-select form-select-sm" value={cmb_assign_to_head_id} onChange={(e) => { setAssigntoHeadId(e.target.value); comboOnChange('AssignToHead'); validateFields(); }} >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {assignToHead?.map(branchId => (
                                                <option value={branchId.field_id}>{branchId.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_assign_to_head_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                    <div className="col">
                                        <Tooltip title="Refresh" placement="right">
                                            <MDTypography >
                                                <MdRefresh onClick={() => FnRefreshbtn('AssignToHead')} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                {
                                    keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                        ? <>
                                            <div className='row'>
                                                <div className='col-sm-4'>
                                                    <Form.Label className="erp-form-label"> Approved By & Date</Form.Label>
                                                </div>
                                                <div className="col-sm-8 col-12">
                                                    <div className="row">
                                                        <div className="col-12 col-md-8 pe-md-0">
                                                            <select id="cmb_approved_by_id" className="form-select form-select-sm" name="approved_by" value={cmb_approved_by_id} disabled={true}
                                                                onChange={(e) => { setApprovedById(e.target.value); comboOnChange('ApprovedBy') }} optional='optional' >
                                                                <option value="">Select</option>
                                                                {approvedbyid?.map(branchId => (
                                                                    <option value={branchId.field_id}>{branchId.field_name}</option>
                                                                ))}
                                                            </select>
                                                            <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                            </MDTypography>
                                                        </div>
                                                        <div className="col-12 col-md-4 pt-md-0 pt-3 ps-md-1">
                                                            <Form.Control type="date" id='dt_approved_date' className="erp_input_field" value={dt_approved_date} onChange={e => { setApprovedDate(e.target.value); validateFields(); }} optional='optional' disabled={true} />
                                                            <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                            </MDTypography>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className="col-sm-4">
                                                    <Form.Label className="erp-form-label">Status Remark</Form.Label>
                                                </div>
                                                <div className="col">
                                                    <Form.Control as="textarea" rows={2} id='txt_status_remark' className="erp_input_field" value={txt_status_remark} onChange={(e) => { setStatusremark(e.target.value); validateFields(); }} optional='optional' disabled={!isApprove} placeholder="Reason of canceled, Rejection, Preeclosed." />
                                                    <MDTypography variant="button" id="error_txt_status_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </>
                                        : null
                                }

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Tax Applicable </Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Inclusive" type="radio" lbl="Inclusive" value="I" name="rb_tax_applicable" checked={rb_tax_applicable === "I"} onClick={() => setTaxApplicable("I")} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="Exclusive" type="radio" lbl="Exclusive" value="E" name="rb_tax_applicable" checked={rb_tax_applicable === "E"} onClick={() => setTaxApplicable("E")} /> </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Transportation Applicable </Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Inclusive" type="radio" lbl="Inclusive" value="I" name="rb_transportation_applicable" checked={rb_transportation_applicable === "I"} onClick={() => setTransportationApplicable("I")} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="Exclusive" type="radio" lbl="Exclusive" value="E" name="rb_transportation_applicable" checked={rb_transportation_applicable === "E"} onClick={() => setTransportationApplicable("E")} /> </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Bom Applicable </Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="Y" name="rb_bom_applicable" checked={rb_bom_applicable === "Y"} onClick={() => setBomApplicable("Y")} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="N" name="rb_bom_applicable" checked={rb_bom_applicable === "N"} onClick={() => setBomApplicable("N")} /> </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Costing Applicable </Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="Y" name="rb_costing_applicable" checked={rb_costing_applicable === "Y"} onClick={() => setCostingApplicable("Y")} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="N" name="rb_costing_applicable" checked={rb_costing_applicable === "N"} onClick={() => setCostingApplicable("N")} /> </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div >
                <hr />
                <Accordion defaultActiveKey="0" activeKey={openDetailsAccord ? '0' : null} >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenDetailsAccord(!openDetailsAccord)}>Quotation Details</Accordion.Header>
                        <Accordion.Body>
                            <div className="row justify-content-between">
                                <div className="col-lg-5 col-12">
                                    <div className="row">
                                        {
                                            keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && rb_quotation_transaction_type === 'M' && keyForViewUpdate !== 'followup'
                                                ? <>
                                                    <div className="col-sm-3">
                                                        <MDButton type="button" className={`erp-gb-button`} variant="button" id='add-material-id' fontWeight="regular" onClick={viewBomFilterForm} >Add Services</MDButton>
                                                    </div>
                                                </>
                                                : null
                                        }
                                        <div className="col-sm-9 d-flex pt-1">
                                            <Form.Control type="text" id="txt-detail-data-highliter" className="erp_input_field showData float-start" />
                                            <MDButton type="button" className="erp-gb-button erp_MLeft_btn showData float-start" variant="button" onClick={() => scrollToTableRow()}
                                                fontWeight="regular" style={{ float: "left" }}>Search</MDButton>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-lg-7 col-12">
                                    <div className="row justify-content-between pt-1">
                                        <div className={`col-md-8 col-12 ${rb_quotation_transaction_type !== 'E' ? 'd-none' : ''}`}>
                                            <div className="row">
                                                <div className="col-md-4 col-12">
                                                    <Form.Label className="erp-form-label">Enquiry Nos <span className="required">*</span> </Form.Label>
                                                </div>

                                                <div className="col-9 col-sm-6 pt-1">
                                                    <div className="select-btn" onClick={() => { if (rb_quotation_transaction_type === 'E') { showEnquiryCheckboxes() } }} optional='optional'>
                                                        <span className="form-select form-select-sm" id="">{checkedEnquiryOrderNoLength !== 0 ? checkedEnquiryOrderNoLength + 'Selected' : 'Select'} </span>
                                                    </div>
                                                    <ul className="list-items" id="enquiry-order-ul">
                                                        {
                                                            enquiryOrderNoList.length > 0
                                                                ? <> {enquiryOrderNoList} </>
                                                                : <li className="item enquiry_order_no">  <span className="item-text"> No Orders Found... </span> </li>
                                                        }
                                                    </ul>
                                                    <MDTypography variant="button" id="error_enquiry_nos" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>Please Select atleast one...! </MDTypography>
                                                </div>

                                                <div className="col-3 col-sm-2 pt-md-0 px-0">
                                                    <MDButton type="button" id="show-btn" className="erp-gb-button" variant="button" onClick={() => { if (rb_quotation_transaction_type === 'E') { ShowEnquiryDetails() } }}
                                                        fontWeight="regular" disabled={isApprove || keyForViewUpdate === 'view' || rb_quotation_transaction_type === 'M' || keyForViewUpdate === 'followup' ? true : false}>Show</MDButton>
                                                </div>

                                                {/* <div className="col-sm-4 col-12">
                                                    <Form.Label className="erp-form-label">Enquiry Nos <span className="required">*</span> </Form.Label>
                                                </div>

                                                <div className="col-sm-8 col-12">
                                                    <div className="row">
                                                        <div className="col-12 col-md-8 pe-md-0">
                                                            <div className="select-btn" onClick={() => { if (rb_quotation_transaction_type === 'E') { showEnquiryCheckboxes() } }} optional='optional'>
                                                                <span className="form-select form-select-sm" id="">{checkedEnquiryOrderNoLength !== 0 ? checkedEnquiryOrderNoLength + 'Selected' : 'Select'} </span>
                                                            </div>
                                                            <ul className="list-items" id="enquiry-order-ul">
                                                                {
                                                                    enquiryOrderNoList.length > 0
                                                                        ? <> {enquiryOrderNoList} </>
                                                                        : <li className="item enquiry_order_no">  <span className="item-text"> No Orders Found... </span> </li>
                                                                }
                                                            </ul>
                                                            <MDTypography variant="button" id="error_enquiry_nos" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>Please Select atleast one...!
                                                            </MDTypography>
                                                        </div>
                                                        <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                            <MDButton type="button" id="show-btn" className="erp-gb-button erp_MLeft_btn" variant="button" onClick={() => { if (rb_quotation_transaction_type === 'E') { ShowEnquiryDetails() } }}
                                                                fontWeight="regular" disabled={isApprove || keyForViewUpdate === 'view' || rb_quotation_transaction_type === 'M' || keyForViewUpdate === 'followup' ? true : false}>Show</MDButton>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className={`align-self-end ${rb_quotation_transaction_type === 'E' ? 'col-md-4' : 'col-sm-12'}`}>
                                            <MDButton type="button" id="import-btn" className={`erp-gb-button float-end ms-2 ${keyForViewUpdate !== '' && keyForViewUpdate !== 'copy' ? 'd-none' : 'display'}`}
                                                variant="button" onClick={onImportClick} fontWeight="regular" > Import </MDButton>

                                            <MDButton type="button" id="export-btn" variant="button" onClick={ExportToExcel} fontWeight="regular" className={`erp-gb-button float-end ms-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'followup' || isApprove ? 'd-none' : 'display'}`}
                                            // disabled={quotation_master_transaction_id === 0 || quotation_master_transaction_id === '' || isApprove === false ? false : true}
                                            > Export </MDButton>

                                        </div>
                                    </div>

                                    {/* <div className="row">


                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <Form.Label className="erp-form-label">Product Costing No <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className='col'>
                                                <select id="cmb_product_costing_no" className="form-select form-select-sm" value={cmb_product_costing_no} onChange={(e) => { setProductCostingNo(e.target.value); validateFields(); }} >
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {enquiryByIdOptions?.map(enquirydById => (
                                                        <option value={enquirydById.field_id}>{enquirydById.field_name}</option>

                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_product_costing_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <Form.Label className="erp-form-label"> Product Fg Bom No<span className="required">*</span></Form.Label>
                                            </div>
                                            <div className='col'>
                                                <select id="cmb_product_fg_bom_no" className="form-select form-select-sm" value={cmb_product_fg_bom_no} onChange={(e) => { setproduct_fg_bom_no(e.target.value); validateFields(); }} >
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {enquiryByIdOptions?.map(enquirydById => (
                                                        <option value={enquirydById.field_id}>{enquirydById.field_name}</option>

                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_product_fg_bom_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <Form.Label className="erp-form-label">Product Fg Bom Version <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className='col'>
                                                <select id=" cmb_product_fg_bom_version" className="form-select form-select-sm" value={cmb_product_fg_bom_version} onChange={(e) => { setproduct_fg_bom_version(e.target.value); validateFields(); }} >
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {enquiryByIdOptions?.map(enquirydById => (
                                                        <option value={enquirydById.field_id}>{enquirydById.field_name}</option>

                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_ cmb_product_fg_bom_version" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>


                                    </div> */}
                                </div>
                            </div>
                            <div className="row py-1 py-md-3">
                                {renderDetailsTable}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion >
                <hr />


                <Accordion defaultActiveKey="0" activeKey={openPaymentTermsAccord ? '0' : null} onSelect={(e) => FnLoadAccordionData('paymentTerms')} >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenPaymentTermsAccord(!openPaymentTermsAccord)}>Payment Terms</Accordion.Header>
                        <Accordion.Body className="ps-1 pt-0">
                            {
                                PmtTermsdata.length > 0
                                    ? <>
                                        <div className={`row py-1 px-0 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                            <div className="col-12 col-sm-6">
                                                {/* <input type='checkbox' class="" id="selectAllPmtTerms" onClick={(e) => toggleQuotationChkBoxes('selectAllPmtTerms')} /> <label class="erp-form-label pb-1"> Select All Payment Terms. </label> */}
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <MDButton type="button" className={`erp-gb-button float-sm-end col-1`} variant="button" fontWeight="regular"
                                                    onClick={() => {
                                                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                        setHeaderName('Payment Terms'); // CommonParamterEntry
                                                        setShowAddRecQuotationModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                                                    }}>Add
                                                </MDButton>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className={`table-responsive ${PmtTermsdata.length > 0 ? 'erp_table_scroll' : ''}`}>
                                                <>{renderPaymentTermsTable}</>
                                            </div>
                                        </div>
                                    </>
                                    : ""
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion >

                <hr />
                <Accordion defaultActiveKey="0" activeKey={openQuotationTermsAccord ? '0' : null} onSelect={(e) => FnLoadAccordionData('CommonTermsAccord')} >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenQuotationTermsAccord(!openQuotationTermsAccord)}>Quotation Terms</Accordion.Header>
                        <Accordion.Body className="p-1 pt-0">
                            <div className={`row py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                <div className="col-12 col-sm-6">
                                    <input type='checkbox' class="" id="chkAllCommonTerms" onClick={(e) => toggleQuotationChkBoxes('chkAllCommonTerms')} /> <label class="erp-form-label pb-1"> Select All</label>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <MDButton type="button" className={`erp-gb-button float-sm-end col-1`} variant="button" fontWeight="regular"
                                        onClick={() => {
                                            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                            setHeaderName('Common Parameter'); // CommonParamterEntry
                                            setShowAddRecQuotationModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                                        }}>Add
                                    </MDButton>
                                </div>
                            </div>
                            <div className={`table-responsive ${QuotationTermsdata.length > 0 ? 'erp_table_scroll' : ''}`}>
                                <>{renderTermsTable}</>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <hr />

                <Accordion defaultActiveKey="1" >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="erp-form-label-md">Expected Functionality</Accordion.Header>
                        <Accordion.Body>
                            <div className='row table-responsive erp_table_scroll'>
                                {renderExistingFunctionalityTable}
                                {/* <Table className="erp_table" id="ExpectedFunctionality" bordered striped >
                                    <thead className='erp_table_head erp_table_th'>
                                        <tr>
                                            <th>Action</th>
                                            <th> Existing Functionality</th>
                                            <th> Expected Functionality </th>
                                            <th> Expected Value</th>
                                        </tr>
                                    </thead>
                                    <tbody className="erp_table_td">
                                        <tr rowindex='1' existingFuncTrCount={existingFunctionRowCount} id="RMSchedulesEntry-tr-1">
                                            <td><IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => removeFirstRow()} /> <IoAddCircleOutline className='erp_trAdd_icon disableClass' onClick={() => addNewFunctionalityRow()} /></td>
                                            <td><Form.Control as="textarea" id='existingfunctionality-1' className="erp_input_field mb-0" maxLength="1000" /></td>
                                            <td><Form.Control as="textarea" id='expectedfunctionality-1' className="erp_input_field mb-0" maxLength="1000" /></td>
                                            <td><Form.Control as="textarea" id='expectedvalue-1' className="erp_input_field mb-0" maxLength="1000" /></td>
                                        </tr>
                                    </tbody>
                                </Table> */}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <hr />

                {
                    keyForViewUpdate === 'update' || keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'followup' ? (
                        <>
                            {/* <Accordion defaultActiveKey={`${keyForViewUpdate === 'followup' ? '1' : '0'}`} > */}
                            <Accordion defaultActiveKey="0" activeKey={openFollowUpAccord ? '0' : null}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenFollowUpAccord(!openFollowUpAccord)}>Followup Details</Accordion.Header>
                                    <Accordion.Body className="py-1">
                                        <div className="row py-1 py-md-3">
                                            <div className={`table-responsive ${followupdetailsData.length > 4 ? 'erp_table_scroll' : ''}`}>
                                                {renderFollowupTable}
                                            </div>
                                            {/* {renderFollowupTable} */}
                                        </div>

                                        {
                                            keyForViewUpdate === 'followup'
                                                ? <>
                                                    <div className='erp_frm_Btns'>
                                                        <MDButton type="button" onClick={() => addQuotationFollowupDetailData()} disabled={txt_quotation_no !== 0 && keyForViewUpdate === 'followup' ? false : true} className="btn erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular">Save</MDButton>
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <hr />
                        </>
                    ) : null
                }

                {keyForViewUpdate !== '' && keyForViewUpdate !== 'copy' ?
                    <>
                        <Accordion defaultActiveKey="0" onSelect={(e) => FnLoadAccordionData('documentList')}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className="erp-form-label-md">Document List</Accordion.Header>
                                <Accordion.Body className="p-0">
                                    <div className={`table-responsive ${docData.length > 4 ? 'erp_table_scroll' : ''}`}>
                                        {docData.length !== 0 ? (
                                            renderDocumentTable
                                        ) : (null)}
                                    </div>

                                    {
                                        docData.length === 0
                                            ? <div className='row'>
                                                <div className="col-12 text-center">
                                                    <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                        No Records Found...
                                                    </span>
                                                </div>
                                            </div>
                                            : null
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion> <hr /></> : null
                }

                <div className="card">
                    <div className="card-header main_heding text-start py-1">
                        <label className='erp-form-label-lg'>Billing Details</label>
                    </div>
                    <div className="card-body">
                        <form id="quotationBillingFrm">
                            <div className="row">
                                {/* //first column */}
                                <div className="col-sm-4 erp_form_col_div">
                                    <div className='row'>
                                        <div className="col-sm-3 pe-0">
                                            <Form.Label className="erp-form-label">  Service Cost </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_quotation_material_cost' className="erp_input_field text-end" value={txt_quotation_material_cost} readOnly />
                                            <MDTypography variant="button" id="error_txt_quotation_material_cost" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 pe-0">
                                            <Form.Label className="erp-form-label">  Discount(%)  </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="number" id='txt_quotation_discount_percent' className="erp_input_field  text-end" value={txt_quotation_discount_percent} readOnly
                                            // onChange={(e) => { if (validateNumericInputs(e)) { handleFooterDiscountPercentChange(e); } }}
                                            // onBlur={(e) => { if (validateNumericInputs(e)) { handleFooterDiscountPercentChange(e); } }}
                                            />
                                            <MDTypography variant="button" id="error_txt_quotation_discount_percent" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 pe-0">
                                            <Form.Label className="erp-form-label"> Discount Cost</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="number" id='txt_quotation_discount_cost' className="erp_input_field text-end" value={txt_quotation_discount_cost} readOnly
                                            // onChange={(e) => { if (validateNumericInputs(e)) { handleFooterDiscountAmountChange(e) } }}
                                            // onBlur={(e) => { if (validateNumericInputs(e)) { handleFooterDiscountAmountChange(e) } }}
                                            />
                                            <MDTypography variant="button" id="error_txt_quotation_discount_cost" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 pe-0">
                                            <Form.Label className="erp-form-label"> Taxable Cost </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_quotation_taxable_cost' className="erp_input_field text-end" value={txt_quotation_taxable_cost} readOnly maxLength='19' />
                                            <MDTypography variant="button" id="error_txt_quotation_taxable_cost" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    {/* <div className='row'>
                                        <div className="col-sm-4 pe-0">
                                            <Form.Label className="erp-form-label">  Process Cost </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_quotation_process_cost' className="erp_input_field text-end" value={txt_quotation_process_cost} readOnly />
                                            <MDTypography variant="button" id="error_txt_quotation_process_cost" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> */}
                                    {/* <div className='row'>
                                        <div className="col-sm-4 pe-0">
                                            <Form.Label className="erp-form-label">Labour Cost </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_quotation_labour_cost' className="erp_input_field text-end" value={txt_quotation_labour_cost} readOnly />
                                            <MDTypography variant="button" id="error_txt_quotation_labour_cost" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> */}

                                    {/* <div className='row'>
                                        <div className="col-sm-4 pe-0">
                                            <Form.Label className="erp-form-label"> Other Cost</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_quotation_other_cost' className="erp_input_field text-end" value={txt_quotation_other_cost} readOnly />
                                            <MDTypography variant="button" id="error_txt_quotation_other_cost" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> */}

                                    {/* <div className='row pb-1'>
                                        <div className="col-sm-4 pe-0">
                                            <Form.Label className="erp-form-label">  Other Cost Remark </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" rows={2} id='txt_quotation_other_cost_remark' className="erp_input_field" value={txt_quotation_other_cost_remark} onChange={(e) => { setQuotationOtherCostRemark(e.target.value) }} optional="optional" />
                                            <MDTypography variant="button" id="error_txt_quotation_other_cost_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> */}

                                    {/* <div className='row'>
                                        <div className="col-sm-4 pe-0">
                                            <Form.Label className="erp-form-label"> Administration %</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_quotation_administration_percent' className="erp_input_field text-end" value={txt_quotation_administration_percent} readOnly />
                                            <MDTypography variant="button" id="error_txt_quotation_administration_percent" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> */}

                                    {/* <div className='row'>
                                        <div className="col-sm-4 pe-0">
                                            <Form.Label className="erp-form-label"> Administration Cost </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_quotation_administration_cost' className="erp_input_field text-end" value={txt_quotation_administration_cost} readOnly />
                                            <MDTypography variant="button" id="error_txt_quotation_administration_cost" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> */}


                                </div>

                                {/* //Second column */}
                                <div className="col-sm-3 erp_form_col_div">
                                    {/* <div className='row'>
                                        <div className="col-sm-4 pe-0">
                                            <Form.Label className="erp-form-label"> Profit %</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_quotation_profit_percent' className="erp_input_field text-end" value={txt_quotation_profit_percent} readOnly />
                                            <MDTypography variant="button" id="error_txt_quotation_profit_percent" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> */}

                                    {/* <div className='row'>
                                        <div className="col-sm-4 pe-0">
                                            <Form.Label className="erp-form-label">  Profit Cost </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_quotation_profit_cost' className="erp_input_field text-end" value={txt_quotation_profit_cost} readOnly />
                                            <MDTypography variant="button" id="error_txt_quotation_profit_cost" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> */}

                                    <div className='row'>
                                        <div className="col-sm-3 pe-0">
                                            <Form.Label className="erp-form-label"> CGST Cost </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_quotation_cgst_cost' className="erp_input_field text-end" value={txt_quotation_cgst_cost} readOnly maxLength='19' />
                                            <MDTypography variant="button" id="error_txt_quotation_cgst_cost" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 pe-0">
                                            <Form.Label className="erp-form-label"> SGST Cost </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_quotation_sgst_cost' className="erp_input_field text-end" value={txt_quotation_sgst_cost} readOnly />
                                            <MDTypography variant="button" id="error_txt_quotation_sgst_cost" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 pe-0">
                                            <Form.Label className="erp-form-label">IGST Cost </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_quotation_igst_cost' className="erp_input_field text-end" value={txt_quotation_igst_cost} readOnly />
                                            <MDTypography variant="button" id="error_txt_quotation_igst_cost" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 pe-0">
                                            <Form.Label className="erp-form-label"> Total Cost </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_quotation_total_cost' className="erp_input_field text-end" value={txt_quotation_total_cost} readOnly />
                                            <MDTypography variant="button" id="error_txt_quotation_total_cost" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                </div>

                                {/* therd colum */}
                                <div className="col-sm-5 erp_form_col_div">

                                    <div className='row pb-1'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Terms Conditions</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" rows={3} id='txt_other_terms_conditions' className="erp_input_field" value={txt_other_terms_conditions} onChange={e => setOtherTermsConditions(e.target.value)} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_other_terms_conditions" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Agent & Percentage</Form.Label>
                                        </div>
                                        <div className='col-sm-9 col-12'>
                                            <div className="row">
                                                <div className="col-12 col-md-8 pe-md-0">
                                                    <select id="cmb_agent_id" className="form-select form-select-sm" value={cmb_agent_id} onChange={(e) => { setAgentId(e.target.value); comboOnChange('Agent') }} optional="optional" >
                                                        <option value="">Select</option>
                                                        <option value="0">Add New Record+</option>
                                                        {agentOpts?.map(agent => (
                                                            <option value={agent.field_id} lbl={agent.field_name}>{agent.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_agent_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>

                                                <div className="col-10 col-md-2 pt-md-0 pt-3 pe-md-0 ps-md-1">
                                                    <Form.Control type="number" id='txt_agent_percent' className="erp_input_field erp_align-right" value={txt_agent_percent}
                                                        onChange={(e) => {
                                                            let isValidPercentageInput = validatePercentageInput(e.target.value);
                                                            if (isValidPercentageInput === true) {
                                                                setAgentPercent(validateNumberDateInput.current.decimalNumber(e.target.value, 4));
                                                                validateFields();
                                                                delete e.target.parentElement.dataset.tip;
                                                            }
                                                        }} optional="optional" />
                                                    <MDTypography variant="button" id="error_txt_agent_percent" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>

                                                <div className="col-2 col-md-2 pt-md-0 pt-3 pe-md-0 ps-md-1 text-center">
                                                    <Tooltip title="Refresh" placement="right">
                                                        <MDTypography >
                                                            <MdRefresh onClick={() => FnRefreshbtn('Agent')} style={{ color: 'black' }} />
                                                        </MDTypography>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Agent</Form.Label>
                                        </div>
                                        <div className='col-lg-7 col-sm-12 col-10'>
                                            <select id="cmb_agent_id" className="form-select form-select-sm" value={cmb_agent_id} onChange={(e) => { setAgentId(e.target.value); comboOnChange('Agent') }} optional="optional" >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {agentOpts?.map(agent => (
                                                    <option value={agent.field_id} lbl={agent.field_name}>{agent.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_agent_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col-2 col-md-1">
                                            <Tooltip title="Refresh" placement="right">
                                                <MDTypography >
                                                    <MdRefresh onClick={() => FnRefreshbtn('Agent')} style={{ color: 'black' }} />
                                                </MDTypography>
                                            </Tooltip>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4 pe-0">
                                            <Form.Label className="erp-form-label"> Agent's % </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="number" id='txt_agent_percent' className="erp_input_field erp_align-right" value={txt_agent_percent}
                                                onChange={(e) => {
                                                    let isValidPercentageInput = validatePercentageInput(e.target.value);
                                                    if (isValidPercentageInput === true) {
                                                        setAgentPercent(validateNumberDateInput.current.decimalNumber(e.target.value, 4));
                                                        validateFields(); calculateGrandTotal(bomfilteredData);
                                                    }
                                                }} optional="optional" />
                                            <MDTypography variant="button" id="error_txt_agent_percent" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> */}

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Remark</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" rows={2} id='txt_remark' className="erp_input_field" value={txt_remark} onChange={e => setRemark(e.target.value)} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label"> Mail Sent Status</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Pending" type="radio" lbl="Pending" value="P" name="rb_quotation_mail_sent_status" checked={rb_quotation_mail_sent_status === "P"} disabled='disabled' optional="optional" /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="Sent" type="radio" lbl="Sent" value="S" name="rb_quotation_mail_sent_status" checked={rb_quotation_mail_sent_status === "S"} disabled='disabled' optional="optional" /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="Failed" type="radio" lbl="Failed" value="F" name="rb_quotation_mail_sent_status" checked={rb_quotation_mail_sent_status === "F"} disabled='disabled' optional="optional" /> </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Active</Form.Label>
                                        </div>
                                        <div className="col">

                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" name="chk_isactive" checked={chk_isactive === "true"} onClick={() => { setIsActive("true"); }} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" name="chk_isactive" checked={chk_isactive === "false"} onClick={() => { setIsActive("false"); }} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form >
                    </div>
                </div>
                <hr />


                <div className="text-center mb-5">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <input type="file" id="Excelfile" ref={importFile} onChange={importedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                    <MDButton type="button" className={`erp-gb-button ms-2 ${deletionKey === true ? 'display' : 'd-none'}`} variant="button"
                        fontWeight="regular" onClick={() => deleteshow()}> Delete </MDButton>

                    <MDButton type="button" id="save-btn" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'followup' ? 'd-none' : 'display'}`} variant="button" BtnType="save" onClick={(e) => addQuotation(e)} fontWeight="regular">Save</MDButton>
                    {
                        keyForViewUpdate === 'approve'
                            ? <>
                                <MDButton type="button" id="reject-btn" className="erp-gb-button erp_MLeft_btn" variant="button" BtnType='reject' onClick={(e) => addQuotation(e)} fontWeight="regular">Reject</MDButton>
                            </>
                            : ""
                    }

                    <MDButton className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'followup' || isApprove ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" id="uploadDocBtn" onClick={viewDocumentForm} disabled={quotation_master_transaction_id === 0} >Upload Document</MDButton>&nbsp;

                    {
                        keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'update' && keyForViewUpdate !== 'followup'
                            ? <>
                                <MDButton type="button" id="clearFormBtn" className={`erp-gb-button ms-2`} variant="button" onClick={e => { clearFormFields(); }} fontWeight="regular" >Clear</MDButton>
                            </>
                            : null
                    }

                    {
                        (keyForViewUpdate === 'view') && (['A', 'R', 'O'].includes(cmb_quotation_status)) && !deletionKey
                            ? <>
                                <MDButton className={`erp-gb-button ms-2`} variant="button" fontWeight="regular" onClick={() => { printInvoice(true); }} >Print   <FiPrinter className="erp-download-icon-btn" /> </MDButton>
                            </>
                            : null
                    }

                </div >

                {/* ADD Filter modal */}
                <Modal size="lg" className='erp_document_Form' show={showBomFilterForm} onHide={FnCloseFilterModal} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Service List</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseFilterModal}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <React.Fragment>
                            <ProductList closeModal={FnCloseFilterModal} props={{ selectedProduct: ['smv_product_sr_summary'] }} />
                            {/* <FrmMBOMEntry closeModal={FnCloseFilterModal} ptSelected={{ product_type: cmb_quotation_type_id, master_val: 'smv_product_type' }} /> */}
                        </React.Fragment>
                    </Modal.Body>
                </Modal>

                {/* Document modal */}
                {/* <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <DocumentF group_id={txt_quotation_no} document_group={docGroup} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal> */}
                {memorizedDocumentComponent}

                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />

                {/* <Modal size="lg" show={showAddRecQuotationModal} onHide={closeRecordAddQuotationModal} backdrop="static" keyboard={false} centered >
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeRecordAddQuotationModal}></button></span>
                    </Modal.Header>
                    <Modal.Body className='erp_city_modal_body'>
                        {displayRecordQuotationComponent()}
                    </Modal.Body>
                    <Modal.Footer>
                        <MDButton type="button" onClick={closeRecordAddQuotationModal} className="btn erp-gb-button" variant="button"
                            fontWeight="regular">Close</MDButton>

                    </Modal.Footer>
                </Modal > */}

                <Modal size="lg" show={showAddRecQuotationModal} onHide={closeRecordAddQuotationModal} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        <div className='row'>
                            <div className='col-12 align-self-end'>
                                <button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeRecordAddQuotationModal}></button>
                            </div>
                        </div>
                        {displayRecordQuotationComponent()}
                    </Modal.Body>
                </Modal >

                {/* Delete Modal */}
                <Modal show={showDeleteModal} onHide={FnCloseDeleteModal} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseDeleteModal}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to delete this record ?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={FnCloseDeleteModal}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button>
                    </Modal.Footer>
                </Modal>

                {/* Send Email On Approval Modal */}
                <MailSentModal handleCloseMailSentModal={() => handleCloseMailSentModal()} sendConfirm={() => sendEmail()} show={[showMailSentModal, succMsg, modalOrderDetails]} />
            </DashboardLayout >

        </>
    )
}