import React from 'react'
import { forwardRef, useImperativeHandle } from "react"
import ConfigConstants from "assets/Constants/config-constant"
import { useNavigate } from "react-router-dom";

const GenerateTAutoNo = forwardRef((props, ref) => {
    // For navigate
    const navigate = useNavigate();
    const configConstants = ConfigConstants();
    const { SHORT_FINANCIAL_YEAR, COMPANY_ID, FINANCIAL_SHORT_NAME, SHORT_COMPANY } = configConstants;

    // const financialShortYear = sessionStorage.getItem('financialShortYear');
    // const companyShortName = sessionStorage.getItem('companyShortName');

    let generatedAutoNo = "0";
    useImperativeHandle(ref, () => ({

        async generateTAutoNo(tblName, fieldName, type, autoNoShortName, Length) {
            const data = {
                financialShortYear: FINANCIAL_SHORT_NAME,
                tblName: tblName,
                fieldName: fieldName,
                Length: Length,
                company_id: COMPANY_ID
            }
            const formData = new FormData();
            formData.append(`data`, JSON.stringify(data))
            const requestOptions = { method: 'POST', body: formData };
            try {
                const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/GenerateNo/FnGenerateAutoNo`, requestOptions)
                const resp = await fetchResponse.text();
                // console.log("GenerateTAuto Api: ", resp)
                if (type === "") {
                    generatedAutoNo = SHORT_COMPANY + "/" + SHORT_FINANCIAL_YEAR + "/" + autoNoShortName + resp;
                    return generatedAutoNo;
                }
                else {
                    // generatedAutoNo = SHORT_COMPANY + "/" + SHORT_FINANCIAL_YEAR + "/" + type + "/" + autoNoShortName + resp;
                    generatedAutoNo = type + "/" + SHORT_FINANCIAL_YEAR + "/" + autoNoShortName + resp;
                    return generatedAutoNo;
                }
            } catch (error) {
                console.error(error);
                return generatedAutoNo;
                navigate('/Error')

            }
        }
    }))
    return <div> </div>
})

export default GenerateTAutoNo
