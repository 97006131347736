import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'


let itemsPerPage = 0; // Number of items per page

const CustomerSalesReciptInvoice = React.forwardRef(({ invoiceContent }, ref) => {
    const data = invoiceContent.items
    function numberToWordsWithCurrency(number) {

        // Check if the input number is NA, null, or an empty string
        if (number === null || number === "NA" || number === "" || number === undefined) {
            return "-";
        }

        const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
        const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

        // Function to convert the integer part of the number to words
        function convertIntegerToWords(integer) {
            if (integer === 0) {
                return "";
            }

            if (integer < 20) {
                return ones[integer];
            }

            if (integer < 100) {
                const ten = Math.floor(integer / 10);
                const remainder = integer % 10;
                return tens[ten] + (remainder > 0 ? ` ${ones[remainder]}` : "");
            }

            if (integer < 1000) {
                const hundred = Math.floor(integer / 100);
                const remainder = integer % 100;
                return ones[hundred] + " Hundred" + (remainder > 0 ? ` and ${convertIntegerToWords(remainder)}` : "");
            }

            if (integer < 1000000) {
                const thousand = Math.floor(integer / 1000);
                const remainder = integer % 1000;
                return convertIntegerToWords(thousand) + " Thousand" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
            }
            if (integer < 1000000000) {
                const million = Math.floor(integer / 1000000);
                const remainder = integer % 1000000;
                return convertIntegerToWords(million) + " Million" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
            }

            return "Number too large to convert";
        }

        // Function to convert the decimal part of the number to words
        function convertDecimalToWords(decimal) {
            const tensValue = Math.floor(decimal / 10);
            const onesValue = decimal % 10;
            let words = "";

            if (tensValue > 0) {
                words += tens[tensValue];
            }

            if (onesValue > 0) {
                words += ` ${ones[onesValue]}`;
            }

            return words;
        }

        // Split the number into integer and decimal parts
        const integerPart = Math.floor(number);
        const decimalPart = Math.round((number - integerPart) * 100); // Convert the decimal to an integer

        // Convert the integer part to words
        let words = convertIntegerToWords(integerPart);

        // Add currency
        if (words) {
            words += " Rupees";
        }

        // Add the decimal part to the words
        if (decimalPart > 0) {
            words += ` and ${convertDecimalToWords(decimalPart)} Paise`;
        }

        return words || "Zero Rupees"; // If the input is 0, return "Zero Rupees"
    }
    const totalBillingAmount = invoiceContent.footer.total_billing_amount;
    const totalBillingDeduction = invoiceContent.footer.total_billing_deduction;
    // const totalPaidAmount = invoiceContent.footer.total_billing_amount;
    const totalReceiptAmount = invoiceContent.footer.total_receipt_amount;

    const totalBillingAmountWords = numberToWordsWithCurrency(totalBillingAmount);
    const totalBillingDeductionWords = numberToWordsWithCurrency(totalBillingDeduction);
    const totalPaidAmountWords = numberToWordsWithCurrency(totalReceiptAmount);


    console.log(`Billing Amount Total: ${totalBillingAmountWords}`);
    console.log(`Deduction Amount Total: ${totalBillingDeductionWords}`);
    console.log(`Paid Amount Total: ${totalPaidAmountWords}`);

    const invoiceHeader = (
        <>
            <div className='bordered border-bottom-0 px-0'>
                <div className='row mb-0 p-2'>
                    <div className="col-sm-2 text-center text-sm-start">
                        <img src={DakshabhiLogo} alt="master card" width="100" height="100" mt={1} />
                    </div>
                    <div className="col-sm-10 transformed">
                        <div className='erp-invoice-print-label-lg text-center'>
                            {invoiceContent.company.company}
                        </div>
                        <div className='erp-invoice-print-label-md-lg  text-center'>
                            {/* {invoiceContent.company.address} */}
                            <span className='erp-invoice-print-label-md-lg'> ({invoiceContent.company.company_branch},{invoiceContent.company.state_name}-{invoiceContent.company.company_pincode}) </span>
                        </div>
                        <div className='erp-invoice-print-label text-center'>
                            {/* <div>
                                <span className='erp-invoice-print-label-md-lg'>{invoiceContent.company.state_name}-{invoiceContent.company.company_pincode} </span>
                            </div> */}
                            <div className='erp-invoice-print-label-lg text-center'>
                                Customer Sales Receipt
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row border-top border-dark p-0 m-0'>
                    <div className='col-sm-6'>
                        <dt className='erp-invoice-print-label-md-lg '> Customer Receipt  No & version  : {invoiceContent.reciptDetails.customer_sales_receipt_no} & {invoiceContent.reciptDetails.customer_sales_receipt_version}</dt>
                    </div>
                    <div className='col-sm-6'>
                        <dt className='erp-invoice-print-label-md-lg text-end'>Customer Sales Receipt  Date : {invoiceContent.reciptDetails.customer_sales_receipt_date}</dt>
                    </div>
                </div>
                <div className='row  border-top border-dark p-0 m-0'>
                    <div className="row p-0 m-0">
                        <dt className='erp-invoice-print-label-md-lg'>Customer  Details:</dt>
                        <div className="col-sm-4">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Customer:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.customerDetails.name}</dd>
                                {/* <dt className='col-sm-3 erp-invoice-print-label-md-lg'>GSTIN:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.customerDetails.GSTIN}</dd>
                                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>PAN No:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.customerDetails.pan_no}</dd> */}
                                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Email:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.customerDetails.cust_branch_EmailId}</dd>
                                <dt className='col-sm-3 erp-invoice-print-label-md-lg'>Contact No:</dt>
                                <dd className='col-sm-9 erp-invoice-print-label'> {invoiceContent.customerDetails.contact}</dd>
                            </dl>
                        </div>
                        <div className="col-sm-4">
                            <dl className="row">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Bank Name:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.customerDetails.customer_bank_name}</dd>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Account No:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.customerDetails.customer_bank_account_number}</dd>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>IFSC Code:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.customerDetails.customer_bank_ifsc_code}</dd>
                            </dl>
                        </div>
                        <div className="col-sm-4">
                            <dl className="row">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Transaction Date:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.customerDetails.receipt_transaction_date}</dd>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Receipt  Type:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.customerDetails.receipt_type_desc}</dd>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Transaction Details:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.customerDetails.receipt_transaction_details}</dd>

                            </dl>
                        </div>
                    </div>
                </div>

                <div className='row  border-top border-dark p-0 m-0'>
                    <div className="row p-0 m-0">
                        <dt className='erp-invoice-print-label-md-lg'>Our Details:</dt>
                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Bank Name:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.ourDetails.bank_name}</dd>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'> Branch Name:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.ourDetails.bank_branch_name}</dd>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Account No:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.ourDetails.bank_account_no}</dd>
                            </dl>
                        </div>
                        <div className="col-sm-6">
                            <dl className="row row pb-0 mb-0">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Total OutStanding:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.ourDetails.total_outstanding}</dd>
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Total Paid Amount</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.ourDetails.total_paid_amount}</dd>
                            </dl>
                        </div>

                    </div>

                </div>

            </div>

        </>
    )
    const invoiceFooter = (
        <div className="invoice-footer">
            <div className='row'>
                <div className='col-sm-6'>
                </div>
                <div className='col-sm-6'>
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">For {invoiceContent.company.company}</dt>
                    </div>
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg">Authorised Signatory</dt>
                    </div>
                </div>
            </div>
        </div>
    );
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">

                                {/* <!--BILL HEADER--> */}
                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    {invoiceHeader}
                                </div>
                                {/* <!--PARCEL DETAILS--> */}

                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            {data.length !== 0 && (
                                                                <thead>
                                                                    <tr>
                                                                        <th className="erp_invoice_table_th text-center" rowspan={2}>Sr No.</th>
                                                                        <th className="erp_invoice_table_th text-center" rowspan={2}>Sales Order No</th>
                                                                        <th className="erp_invoice_table_th text-center" rowspan={2}>Dispatch Challan No</th>
                                                                        <th className="erp_invoice_table_th text-center" rowspan={2}>Sales Invoice No</th>
                                                                        <th className="erp_invoice_table_th text-center" rowspan={2}>Sales Invoice Date</th>
                                                                        <th className="erp_invoice_table_th text-center " rowspan={2}>Payment Due Date</th>
                                                                        <th className="erp_invoice_table_th text-center" rowspan={2}>Billing Amount</th>
                                                                        <th className="erp_invoice_table_th text-center" rowspan={2}>Prev Paid Amount</th>
                                                                        <th className="erp_invoice_table_th text-center" rowspan={2}>Billing Deduction</th>
                                                                        <th className="erp_invoice_table_th text-center" rowspan={2}>Paid Amount</th>

                                                                    </tr>
                                                                </thead>
                                                            )}
                                                            <tbody id='detailstableId'>
                                                                {
                                                                    data.map((item, index) =>
                                                                        <>
                                                                            <tr  className={`tblBodyTrHt-${index}`}>
                                                                                <td className="erp_invoice_table_td text-center" >{item.sr_no} </td>
                                                                                <td className="erp_invoice_table_td text-center">{item.sales_order_no}</td>
                                                                                <td className="erp_invoice_table_td text-center">{item.dispatch_challan_no}</td>
                                                                                <td className="erp_invoice_table_td text-center">{item.sales_invoice_no}</td>
                                                                                <td className="erp_invoice_table_td text-center">{item.sales_invoice_date}</td>
                                                                                <td className="erp_invoice_table_td text-center">{item.payment_due_date}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.item_billing_amount}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.item_prev_paid_amount}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.item_billing_deduction}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.item_paid_amount}</td>
                                                                            </tr>
                                                                            {
                                                                                data.length - 1 === index ? <>
                                                                                    {/* Add blank rows to fill the space */}

                                                                                    <tr id='footerTr'>
                                                                                        <td className="erp_invoice_table_td" colSpan={6}>
                                                                                            <div className='row'>
                                                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg"> Billing Amount(in words) :</dt>
                                                                                                <dd className="col-sm-8 erp-invoice-print-label">{totalBillingAmountWords}</dd>
                                                                                            </div>
                                                                                            <div className='row'>
                                                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg"> Deduction Amount(in words) :</dt>
                                                                                                <dd className="col-sm-8 erp-invoice-print-label">{totalBillingDeductionWords}</dd>
                                                                                            </div>
                                                                                            <div className='row'>
                                                                                                {/* <dt className="col-sm-4 erp-invoice-print-label-md-lg"> Paid Amount(in words) :</dt> */}
                                                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg"> Total Receipt Amount(in words) :</dt>
                                                                                                <dd className="col-sm-8 erp-invoice-print-label">{totalPaidAmountWords}</dd>
                                                                                            </div>
                                                                                            <div className='row'>
                                                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">Deduction Remark :</dt>
                                                                                                <dd className="col-sm-8 erp-invoice-print-label">{invoiceContent.footer.deduction_remark === '' ? '-' : invoiceContent.footer.deduction_remark}</dd>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="erp_invoice_table_td p-0 m-0" colSpan={4}>
                                                                                            <table className="table p-0 m-0 table-borderless">
                                                                                                <tbody>
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Billing Amount :</span> </td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{invoiceContent.footer.total_billing_amount}</td>
                                                                                                    </tr>
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg ">Deduction Amount :</span></td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{invoiceContent.footer.total_billing_deduction}</td>
                                                                                                    </tr>
                                                                                                    {/* <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Tax1 Percent : ({invoiceContent.footer.tax1_percent}) Tax1 Amount :</span> </td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{invoiceContent.footer.tax1_amount}</td>
                                                                                                    </tr>
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Tax2 Percent : ({invoiceContent.footer.tax2_percent}) Tax2 Amount :</span> </td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{invoiceContent.footer.tax2_amount}</td>
                                                                                                    </tr> */}
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">{invoiceContent.footer.taxtype_name1}- Percent: ({invoiceContent.footer.tax1_percent})  Amount :</span> </td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{invoiceContent.footer.tax1_amount}</td>
                                                                                                    </tr>
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">{invoiceContent.footer.taxtype_name2}- Percent: ({invoiceContent.footer.tax2_percent})  Amount :</span> </td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{invoiceContent.footer.tax2_amount}</td>
                                                                                                    </tr>
                                                                                                    <tr className='border-0'>
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0"> Receipt Amount : </span></td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{invoiceContent.footer.total_receipt_amount}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan={12}>
                                                                                            {/* Your invoice footer content */}
                                                                                            {invoiceFooter}
                                                                                        </td>
                                                                                    </tr>

                                                                                </> : null
                                                                            }

                                                                        </>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
})
export default CustomerSalesReciptInvoice;

