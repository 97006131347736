import React, { useEffect } from 'react'
import $ from 'jquery';
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Form from 'react-bootstrap/Form';
import { Button, Card, Modal } from 'react-bootstrap';
// React icons
import { AiOutlineDownCircle } from "react-icons/ai";
import { RxCrossCircled } from "react-icons/rx";
import { TbArrowsRight } from "react-icons/tb";
import { TbArrowsLeft } from "react-icons/tb";
import { TbArrowsDown } from "react-icons/tb";
import { TbArrowsUp } from "react-icons/tb";
import { HiDocumentDuplicate, HiOutlineArrowNarrowRight } from "react-icons/hi";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { HiOutlineArrowNarrowUp } from "react-icons/hi";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { FiDownload } from 'react-icons/fi';
//File Imports
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
//Export Related imports
import PdfExport from 'Features/Exports/PdfExport';
import ExcelExport from "Features/Exports/ExcelExport";
import JsonExport from "Features/Exports/JsonExport";
import CSVExport from "Features/Exports/CSVExport";
import Datatable from 'components/DataTable';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { Tooltip } from '@mui/material';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import { RiChatFollowUpFill } from 'react-icons/ri';
import ConfigConstants from 'assets/Constants/config-constant';
import DeleteModal from 'components/Modals/DeleteModal';

function FrmQuotationListing({ compType = 'Masters' }) {
    let storeSelectedValues = [];
    const [selectedFilters, setSelectedFilters] = useState([]);
    //const [optionBox, setOptionBox] = useState([]);
    const [recordData, setRecordData] = useState([]);
    const [filterComboBox, setFilterComboBox] = useState([]);
    const [reportType, setReportType] = useState('summary');
    let reportName = "Quotation Report";
    const [selectedQuotationReqData, setSelectedQuotationReqData] = useState();
    //useRef
    const pdfExp = useRef();
    const exlsExp = useRef();
    const jsonExp = useRef();
    const csvExp = useRef();
    const pdfExpFilters = {};
    const comboBox = useRef();
    const navigate = useNavigate();
    const validateNumberDateInput = useRef();

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, COMPANY_NAME, USER_ACCESS } = configConstants;


    // Table Data
    const [QuotationTbldata, setQuotationData] = useState([]);
    const [QuotationTblcolumns, setColumns] = useState([]);
    const [availableColumns, setAvailableColumns] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [groupByColumns, setGroupByColumns] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");
    // Export Fields
    var dataExport = [];
    var columnExport = [];
    let rptColumnHeads;

    useEffect(() => {
        $('.hide-show-filters').hide();

    }, [])

    useEffect(async () => {
        const rptApiCall = async () => {
            const availCols = await FnShowAllReportRecords(reportType);
            await fetchFilteredData(PageCurrent, entriesPerPage, availCols)
        }
        rptApiCall()
    }, [reportType, compType])

    function toggleFilter() {
        $(this).text(function (_, currentText) {
            return currentText == "▼" ? "▲" : "▼";
        }, []);
        $('.hide-show-filters').toggle('fast');
    };

    // Pagination Variables
    const pageEntriesOptions = [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "500", value: 500 },
    ];
    let [entriesPerPage, setEntriesPerPage] = useState(
        pageEntriesOptions[2].value
    );
    const [pageCount, setpageCount] = useState(0);
    const [PageCurrent, setcurrentPage] = useState(0);

    // Popup Fields
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var operatorLabels = { "=": "is", "!=": "is not", "1": "active", "0": "closed", "o": "open", "!*": "none", "*": "any", "~": "contains", "!~": "doesn't contain", "^": "starts with", "$": "ends with", "<=": "less than or equal", ">=": "greater than or equal" };
    var operatorByType = {
        "list": ["=", "!="], "status": ["1", "0"], "list_status": ["o", "=", "!", "c", "*"], "list_optional": ["=", "!", "!*", "*"],
        "list_subprojects": ["*", "!*", "=", "!"], "string": ["~", "=", "!~", "!", "^", "$"], "text": ["~", "!~", "^", "$", "!*", "*"],
        "integer": ["=", "\u003e=", "\u003c=", "\u003e\u003c", "!*", "*"], "float": ["=", "\u003e=", "\u003c=", "\u003e\u003c", "!*", "*"],
        "relation": ["=", "!", "=p", "=!p", "!p", "*o", "!o", "!*", "*"], "tree": ["=", "~", "!*", "*"], "date": ["=", "<=", '>='],
    };

    const [groupByOpts, setGroupByOpts] = useState([]);
    const FnShowAllReportRecords = async (reportIdentifierKey) => {
        try {
            const summaryRptApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/report/FnShowAllReportRecords?reportType=${reportIdentifierKey}&viewName=${reportIdentifierKey === 'summary' ? 'mtv_sales_quotation_trading_summary_rpt' : 'mtv_sales_quotation_trading_details_rpt'}`)
            const summaryResponce = await summaryRptApiCall.json();
            console.log("summaryResponce responce: ", summaryResponce)
            if (summaryResponce.content.length !== 0) {
                var rptColumnHeads = summaryResponce.headerKeys;
                setRecordData(summaryResponce.content)
                let filterBox = [];
                let tempOptionBox = []
                for (let colKey = 0; colKey < rptColumnHeads.length; colKey++) {
                    const content = summaryResponce.content;
                    const columnName = rptColumnHeads[colKey];
                    const value = content[columnName];
                    if (value !== null) {
                        const myArray = value.split(":");

                        switch (myArray[2]) {
                            case 'Y':
                                filterBox.push({ Headers: myArray[1], accessor: columnName });
                                tempOptionBox.push({ Headers: myArray[1], accessor: columnName });
                                break;
                            case 'O':
                                tempOptionBox.push({ Headers: myArray[1], accessor: columnName });
                                break;
                            default:
                                break

                        }

                    }

                }
                setFilterComboBox(filterBox)
                setAvailableColumns(tempOptionBox)
                setGroupByColumns(tempOptionBox)
                return tempOptionBox;
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }

    }
    const fetchFilteredData = async (page, size, availCols) => {
        debugger
        const executeQuery = await submitQuery(availCols);

        const formData = new FormData();
        formData.append(`jsonQuery`, executeQuery)
        const requestOptions = { method: 'POST', body: formData };

        try {
            const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/filter/FnMShowFilterRecords/${page}/${size}`, requestOptions)
            const responce = await fetchResponse.json();
            let nColumn = [];
            if (responce.content.length > 0) {
                // This for pagination 
                const total = responce.totalElements;
                setpageCount(Math.ceil(total / size));
                const access_control = FnCheckUserAccess() || { all_access: false, read_access: false, modify_access: false, delete_access: false, add_access: false }; // Setting default access control when USER_ACCESS is undefined


                // Get column Name & Accessor
                let colNameAccessor;
                let colNameHeader;

                for (let colKey = 0; colKey < availCols.length; colKey++) {
                    colNameAccessor = availCols[colKey].accessor
                    colNameHeader = availCols[colKey].Headers
                    if (colKey === 0) {
                        nColumn.push({
                            Headers: "Action", accessor: "Action",
                            Cell: row => (
                                <div style={{ display: "flex" }}>
                                    <Tooltip title="View" placement="top">
                                        <MDTypography className="erp-view-btn" >
                                            <AiFillEye className={`erp-view-btn ${access_control.all_access || access_control.read_access ? 'display' : 'd-none'}`} onClick={e => viewUpdateDelete(row.row.original, 'view')} />
                                        </MDTypography>
                                    </Tooltip>

                                    {compType === 'Register' ?
                                        null : <>
                                            <Tooltip title={row.row.original.quotation_status === 'A' ? 'Quotation is approved...!' : row.row.original.quotation_status === 'R' ? 'Quotation is rejected...!' : row.row.original.quotation_status === 'O' ? 'Sales Order Generated...!' : "Edit"} placement="top">
                                                <MDTypography className="erp-view-btn" >
                                                    <MdModeEdit className={`erp-edit-btn ${access_control.all_access || access_control.modify_access ? 'display' : 'd-none'}`} onClick={e => viewUpdateDelete(row.row.original, 'update')} />
                                                </MDTypography>
                                            </Tooltip>

                                            <Tooltip title="Approve" placement="top">
                                                <MDTypography className="erp-view-btn" >
                                                    < BsFillCheckCircleFill className={`erp-view-btn ${access_control.all_access || access_control.approve_access ? 'display' : 'd-none'}`} onClick={(e) => { { viewUpdateDelete(row.row.original, 'approve') } }} />
                                                </MDTypography>
                                            </Tooltip>

                                            <Tooltip title="Followup Quotation " placement="top">
                                                <MDTypography className="erp-view-btn" >
                                                    <RiChatFollowUpFill className={`erp-view-btn ${access_control.all_access || access_control.add_access || access_control.modify_access ? 'display' : 'd-none'}`} onClick={e => viewUpdateDelete(row.row.original, 'followup')} />
                                                </MDTypography>
                                            </Tooltip>

                                            <Tooltip title="Copy Quotation " placement="top">
                                                <MDTypography className="erp-view-btn" >
                                                    <HiDocumentDuplicate className={`erp-view-btn ${access_control.all_access || access_control.add_access ? 'display' : 'd-none'}`} onClick={e => viewUpdateDelete(row.row.original, 'copy')} />
                                                </MDTypography>
                                            </Tooltip>

                                            <Tooltip title="Delete" placement="top">
                                                <MDTypography className="erp-view-btn" >
                                                    <MdDelete className={`erp-delete-btn ${access_control.all_access || access_control.delete_access ? 'display' : 'd-none'}`} onClick={e => viewUpdateDelete(row.row.original, 'delete')} />
                                                </MDTypography>
                                            </Tooltip>

                                        </>
                                    }


                                </div>
                            ),
                        });
                    }
                    nColumn.push({ Headers: colNameHeader, accessor: colNameAccessor });

                }
                setColumns(nColumn);
                setQuotationData(responce.content)
            } else {
                setColumns([]);
                setQuotationData([])
            }
            return responce;
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }

    };

    const FnCheckUserAccess = () => {
        const currentRoute = window.location.pathname;
        const obj = USER_ACCESS ? USER_ACCESS.find(item => item.listing_navigation_url === currentRoute) : null;
        console.log("Particular Page user access: ", obj);
        return obj;
    }


    // const fetchFilteredData = async (page, size) => {
    //     const executeQuery = await submitQuery();
    //     const formData = new FormData();
    //     formData.append(`jsonQuery`, executeQuery)
    //     const requestOptions = { method: 'POST', body: formData };
    //     try {
    //         const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/filter/FnMShowFilterRecords/${page}/${size}`, requestOptions)
    //         const responce = await fetchResponse.json();
    //         console.log("Responce when fetch FilteredData For Quotation listing details: ", responce)
    //         let nColumn = [];
    //         const total = responce.totalElements;
    //         setpageCount(Math.ceil(total / size));
    //         if (responce.content.length > 0) {
    //             $('#NoRcrdId').hide();
    //             let newColumnHeads = responce.Headerkeys;
    //             if (storeSelectedValues.length !== 0) {
    //                 for (let selValCount = 0; selValCount < storeSelectedValues.length; selValCount++) {
    //                     if (selValCount === 0) {
    //                         nColumn.push({
    //                             Headers: "Action", accessor: "Action",
    //                             Cell: row => (
    //                                 <div style={{ display: "flex" }}>
    //                                     {compType === 'Register' ?
    //                                         null : <>
    //                                             <Tooltip title={row.row.original.quotation_status === 'A' ? 'Quotation is approved...!' : row.row.original.quotation_status === 'R' ? 'Quotation is rejected...!' : "Edit"} placement="top">

    //                                                 <MDTypography className="erp-view-btn" >
    //                                                     <MdModeEdit className="erp-edit-btn" onClick={e => viewUpdateDelete(row.row.original, 'update')} />
    //                                                 </MDTypography>
    //                                             </Tooltip>
    //                                             <Tooltip title="Delete" placement="top">
    //                                                 <MDTypography className="erp-view-btn" >
    //                                                     <MdDelete className="erp-delete-btn" onClick={e => viewUpdateDelete(row.row.original, 'delete')} />
    //                                                 </MDTypography>
    //                                             </Tooltip>

    //                                             <Tooltip title="Copy Quotation " placement="top">
    //                                                 <MDTypography className="erp-view-btn" >
    //                                                     <HiDocumentDuplicate className="erp-view-btn" onClick={e => viewUpdateDelete(row.row.original, 'copy')} />
    //                                                 </MDTypography>
    //                                             </Tooltip>

    //                                             <Tooltip title="Followup Quotation " placement="top">
    //                                                 <MDTypography className="erp-view-btn" >
    //                                                     <RiChatFollowUpFill className="erp-view-btn" onClick={e => viewUpdateDelete(row.row.original, 'followup')} />
    //                                                 </MDTypography>
    //                                             </Tooltip>

    //                                             {row.row.original.quotation_status !== "A" || row.row.original.quotation_status !== "R" ?
    //                                                 <>
    //                                                     {
    //                                                         <Tooltip title="Approve" placement="top">
    //                                                             <MDTypography className="erp-view-btn" >
    //                                                                 < BsFillCheckCircleFill className="erp-view-btn"
    //                                                                     onClick={(e) => { { viewUpdateDelete(row.row.original, 'approve') } }} />                          </MDTypography>
    //                                                         </Tooltip>
    //                                                     }
    //                                                 </>
    //                                                 : ''
    //                                             }

    //                                         </>
    //                                     }
    //                                     <Tooltip title="View" placement="top">
    //                                         <MDTypography className="erp-view-btn" >
    //                                             <AiFillEye className="erp-view-btn" onClick={e => viewUpdateDelete(row.row.original, 'view')} />
    //                                         </MDTypography>
    //                                     </Tooltip>






    //                                 </div>
    //                             ),
    //                         });
    //                     }
    //                     for (let colKey = 0; colKey < newColumnHeads.length; colKey++) {
    //                         if (storeSelectedValues[selValCount] === newColumnHeads[colKey]) {
    //                             if (newColumnHeads[colKey].includes('_date')) {
    //                                 nColumn.push({
    //                                     Headers: newColumnHeads[colKey], accessor: newColumnHeads[colKey],
    //                                     Cell: row => (
    //                                         <>
    //                                             {validateNumberDateInput.current.formatDateToDDMMYYYY(row.row.original[newColumnHeads[colKey]])}
    //                                         </>
    //                                     ),
    //                                 });
    //                             } else {
    //                                 nColumn.push({ Headers: newColumnHeads[colKey], accessor: newColumnHeads[colKey] });
    //                             }
    //                         }
    //                     }
    //                 }
    //             } else {
    //                 for (let colKey = 0; colKey < newColumnHeads.length; colKey++) {
    //                     if (colKey === 0) {
    //                         nColumn.push({
    //                             Headers: "Action", accessor: "Action",
    //                             Cell: row => (
    //                                 <div style={{ display: "flex" }}>
    //                                     {compType === 'Register' ?
    //                                         null : <>
    //                                             <Tooltip title={row.row.original.quotation_status === 'A' ? 'Quotation is approved...!' : row.row.original.quotation_status === 'R' ? 'Quotation is rejected...!' : "Edit"} placement="top">

    //                                                 <MDTypography className="erp-view-btn" >
    //                                                     <MdModeEdit className="erp-edit-btn" onClick={e => viewUpdateDelete(row.row.original, 'update')} />
    //                                                 </MDTypography>
    //                                             </Tooltip>
    //                                             <Tooltip title="Delete" placement="top">
    //                                                 <MDTypography className="erp-view-btn" >
    //                                                     <MdDelete className="erp-delete-btn" onClick={e => viewUpdateDelete(row.row.original, 'delete')} />
    //                                                 </MDTypography>
    //                                             </Tooltip>
    //                                             <Tooltip title="Followup Quotation " placement="top">
    //                                                 <MDTypography className="erp-view-btn" >
    //                                                     <RiChatFollowUpFill className="erp-view-btn" onClick={e => viewUpdateDelete(row.row.original, 'followup')} />
    //                                                 </MDTypography>
    //                                             </Tooltip>

    //                                             {row.row.original.quotation_status !== "A" || row.row.original.quotation_status !== "R" ?
    //                                                 <>
    //                                                     {
    //                                                         <Tooltip title="Approve" placement="top">
    //                                                             <MDTypography className="erp-view-btn" >
    //                                                                 < BsFillCheckCircleFill className="erp-view-btn"
    //                                                                     onClick={(e) => { { viewUpdateDelete(row.row.original, 'approve') } }} />                          </MDTypography>
    //                                                         </Tooltip>
    //                                                     }
    //                                                 </>
    //                                                 : ''
    //                                             }
    //                                             <Tooltip title="Copy Quotation " placement="top">
    //                                                 <MDTypography className="erp-view-btn" >
    //                                                     <HiDocumentDuplicate className="erp-view-btn" onClick={e => viewUpdateDelete(row.row.original, 'copy')} />
    //                                                 </MDTypography>
    //                                             </Tooltip>
    //                                         </>

    //                                     }
    //                                     <Tooltip title="View" placement="top">
    //                                         <MDTypography className="erp-view-btn" >
    //                                             <AiFillEye className="erp-view-btn" onClick={e => viewUpdateDelete(row.row.original, 'view')} />
    //                                         </MDTypography>
    //                                     </Tooltip>


    //                                 </div>

    //                             ),
    //                         });
    //                     }
    //                     if (!newColumnHeads[colKey].includes('company_') && !newColumnHeads[colKey].includes('_id') && !newColumnHeads[colKey].includes('is_') && !newColumnHeads[colKey].includes('_on') && !newColumnHeads[colKey].includes('_by') && !newColumnHeads[colKey].includes('field_name')) {
    //                         if (newColumnHeads[colKey].includes('_date')) {
    //                             nColumn.push({
    //                                 Headers: newColumnHeads[colKey], accessor: newColumnHeads[colKey],
    //                                 Cell: row => (
    //                                     <>
    //                                         {validateNumberDateInput.current.formatDateToDDMMYYYY(row.row.original[newColumnHeads[colKey]])}
    //                                     </>
    //                                 ),
    //                             });
    //                         } else {
    //                             nColumn.push({ Headers: newColumnHeads[colKey], accessor: newColumnHeads[colKey] });
    //                         }
    //                     }
    //                 }
    //             }
    //             setColumns(nColumn);
    //             setQuotationData(responce.content)
    //         } else {
    //             setColumns([]);
    //             setQuotationData([])
    //             $('#NoRcrdId').show();
    //         }
    //         return responce;
    //     } catch (error) {
    //         console.log("error in fetch FilteredData For Quotation: ", error)
    //     }
    // };


    const fetchFilteredDataToExport = async (availCols) => {
        const executeQuery = await submitQuery(availCols);
        const formData = new FormData();
        formData.append(`jsonQuery`, executeQuery)
        const requestOptions = { method: 'POST', body: formData };
        try {
            const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/filter/FnShowFilterRecords`, requestOptions)
            const responce = await fetchResponse.json();

            let filterColumnsToExport = [];

            if (responce.content.length > 0) {
                // var newColumnHeads = responce.Headerkeys

                // Get column Name & Accessor
                let colNameAccessor;
                let colNameHeader;

                for (let colKey = 0; colKey < availCols.length; colKey++) {
                    colNameAccessor = availCols[colKey].accessor
                    colNameHeader = availCols[colKey].Headers

                    filterColumnsToExport.push({ Headers: colNameHeader, accessor: colNameAccessor });
                }

                dataExport = responce.content
                columnExport = filterColumnsToExport
            } else {
                dataExport = []
                columnExport = []
            }
            return responce;
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }

    };


    //     const submitQuery = async () => {
    // debugger
    //         let selectBox = document.getElementById("selectedColumns");
    //         var group_by = document.getElementById("group_by").value;

    //         let jsonQuery = { 'viewname': {}, 'selectQuery': {}, 'whereQuery': {}, 'operators': {}, 'group_by': {}, "additionalParam": {}, "orderBy": {} };
    //         storeSelectedValues = []

    //         if (selectBox.length > 4) {
    //             for (let i = 0; i < selectBox.length; i++) {
    //                 let optionValue = selectBox.options[i].value;
    //                 jsonQuery['selectQuery'][optionValue] = optionValue;
    //                 storeSelectedValues.push(optionValue)
    //             }
    //         }

    //         jsonQuery['group_by']["GROUP BY"] = group_by;
    //         let table = document.getElementById("filters-table");
    //         let tr = table.getElementsByTagName("tr");

    //         for (let j = 0; j < tr.length; j++) {
    //             let tdLabel = tr[j].getElementsByTagName("td")[0];
    //             let tdOperator = tr[j].getElementsByTagName("td")[1];
    //             let tdValues = tr[j].getElementsByTagName("td")[2];
    //             let field = tdLabel.getElementsByTagName("label")[0];
    //             let operator = tdOperator.getElementsByTagName("select")[0];
    //             let values;
    //             let valueSelectValue;
    //             let fieldvalue;
    //             let operatorSelectValue;

    //             if (tdValues.children[0].type === 'text') {
    //                 values = tdValues.children[0].value
    //                 if (values !== '') {
    //                     fieldvalue = field.textContent.trim();
    //                     let checkOperator = operator.options[operator.selectedIndex].value;
    //                     switch (checkOperator) {
    //                         case '~':
    //                             operatorSelectValue = "LIKE"
    //                             valueSelectValue = "%" + values + "%";
    //                             break;
    //                         case '!~':
    //                             operatorSelectValue = "NOT LIKE"
    //                             valueSelectValue = "%" + values + "%";
    //                             break;
    //                         case '^':
    //                             operatorSelectValue = "LIKE"
    //                             valueSelectValue = "%" + values;
    //                             break;
    //                         case '$':
    //                             operatorSelectValue = "LIKE"
    //                             valueSelectValue = values + "%";
    //                             break;
    //                         default:
    //                             break;
    //                     }

    //                 }
    //             } else {
    //                 values = tdValues.getElementsByTagName("select")[0];
    //                 valueSelectValue = values.options[values.selectedIndex].value;
    //                 fieldvalue = field.textContent.trim();
    //                 operatorSelectValue = operator.options[operator.selectedIndex].value;
    //             }
    //             jsonQuery['whereQuery'][fieldvalue] = valueSelectValue;
    //             jsonQuery['operators'][fieldvalue] = operatorSelectValue;
    //             pdfExpFilters[fieldvalue] = valueSelectValue;
    //         }
    //         jsonQuery['additionalParam']['is_delete'] = "0";
    //         jsonQuery['additionalParam']['company_id'] = COMPANY_ID;
    //         jsonQuery['additionalParam']['company_branch_id'] = COMPANY_BRANCH_ID;
    //         jsonQuery['orderBy']['ORDER BY'] = 'quotation_master_transaction_id';

    //         if (reportType === 'summary') {
    //             reportName = "Quotation Summary Report"
    //             jsonQuery['viewname']['viewName'] = 'mtv_sales_quotation_trading_summary'
    //         } else {
    //             reportName = "Quotation Details Report"
    //             jsonQuery['viewname']['viewName'] = 'mtv_sales_quotation_trading_details_rpt'
    //         }

    //         let executeQuery = JSON.stringify(jsonQuery)
    //         return executeQuery;
    //     }


    function viewUpdateDelete(data, key, event) {
        const updateData = {

            quotation_no: data.quotation_no,
            quotation_version: data.quotation_version,
            financial_year: data.financial_year
        }
        setSelectedQuotationReqData(updateData)
        if (key === 'view' || key === 'copy' || key === 'followup') {
            key === 'copy' ? infoForUpdate(updateData, 'copy') : key === 'followup' ? infoForUpdate(updateData, 'followup') : infoForUpdate(updateData, 'view');
        }
        if (data.quotation_status !== 'A' && data.quotation_status !== 'R' && data.quotation_status !== 'X' && data.quotation_status !== 'O') {
            switch (key) {
                case 'update': infoForUpdate(updateData, 'update'); break;
                case 'delete': infoForUpdate(updateData, 'delete'); break;
                case 'view': infoForUpdate(updateData, 'view'); break;
                case 'approve': infoForUpdate(updateData, 'approve'); break;
                case 'add': infoForUpdate(updateData, 'add'); break;
                case 'copy': infoForUpdate(updateData, 'copy'); break;
                case 'followup': infoForUpdate(updateData, 'followup'); break;
                default: break;
            }
        }
    }
    const removeFilter = (filterId) => {
        // Remove the filter from the state
        setSelectedFilters(prevFilters => prevFilters.filter(filter => filter.id !== filterId));
        document.querySelectorAll("#add_filter_select option").forEach(opt => {
            if (opt.value === filterId) {
                opt.disabled = false;
            }
        });
        $("#add_filter_select").val('0');
    };

    // const submitQuery = async () => {
    //     let selectBox = document.getElementById("selectedColumns");
    //     let group_by = document.getElementById("group_by").value;
    //     let jsonQuery = { 'viewname': {}, 'selectQuery': {}, 'whereQuery': {}, 'operators': {}, 'group_by': {}, "additionalParam": {}, "orderBy": {} };
    //     storeSelectedValues = []
    //     if (selectBox.length > 4) {
    //         for (let index = 0; index < selectBox.length; index++) {
    //             let optionValue = selectBox.options[index].value;
    //             jsonQuery['selectQuery'][optionValue] = optionValue;
    //             storeSelectedValues.push(optionValue)
    //         }
    //     }
    //     jsonQuery['group_by']["GROUP BY"] = group_by;
    //     for (let selIndex = 0; selIndex < selectedFilters.length; selIndex++) {
    //         let fieldvalue = selectedFilters[selIndex].label.trim()
    //         let operatorSelectValue = document.getElementById(`operators_${fieldvalue}_id`).value;
    //         let valueSelectValue = document.getElementById(`values_${fieldvalue}_id`).value;
    //         if (selectedFilters[selIndex].type === 'T') {
    //             switch (operatorSelectValue) {
    //                 case '~':
    //                     operatorSelectValue = "LIKE"
    //                     valueSelectValue = "%" + valueSelectValue + "%";
    //                     break;
    //                 case '!~':
    //                     operatorSelectValue = "NOT LIKE"
    //                     valueSelectValue = "%" + valueSelectValue + "%";
    //                     break;
    //                 case '^':
    //                     operatorSelectValue = "LIKE"
    //                     valueSelectValue = "%" + valueSelectValue;
    //                     break;
    //                 case '$':
    //                     operatorSelectValue = "LIKE"
    //                     valueSelectValue = valueSelectValue + "%";
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         }
    //         jsonQuery['whereQuery'][fieldvalue] = valueSelectValue;
    //         jsonQuery['operators'][fieldvalue] = operatorSelectValue;
    //         pdfExpFilters[fieldvalue] = fieldvalue;
    //     }
    //     jsonQuery['additionalParam']['is_delete'] = "0";
    //     jsonQuery['additionalParam']['company_id'] = COMPANY_ID;
    //     jsonQuery['additionalParam']['company_branch_id'] = COMPANY_BRANCH_ID;
    //     jsonQuery['orderBy']['ORDER BY'] = 'quotation_master_transaction_id';

    //     if (reportType === 'summary') {
    //         reportName = "Quotation Summary Report"
    //         jsonQuery['viewname']['viewName'] = 'mtv_sales_quotation_trading_summary'
    //     } else {
    //         reportName = "Quotation Details Report"
    //         jsonQuery['viewname']['viewName'] = 'mtv_sales_quotation_trading_details'
    //     }

    //     let executeQuery = JSON.stringify(jsonQuery)
    //     return executeQuery;
    // }

    const submitQuery = async (availCols) => {
        try {
            const requiredColumns = ['quotation_no', 'quotation_version', 'financial_year']

            let selectBox = document.getElementById("selectedColumns");
            let group_by = document.getElementById("group_by").value;
            let jsonQuery = { 'viewname': {}, 'selectQuery': {}, 'whereQuery': {}, 'operators': {}, 'group_by': {}, "additionalParam": {}, "orderBy": {} };
            storeSelectedValues = []
            if (selectBox.length !== 0) {
                let selectOrder = []; // Array to store the order of elements
                let storeSelectedValues = []; // Assuming this is defined somewhere in your code

                // Loop through selectBox to populate selectQuery and selectOrder
                for (let index = 0; index < selectBox.length; index++) {
                    let optionValue = selectBox.options[index].value;
                    jsonQuery['selectQuery'][index] = optionValue;
                    selectOrder.push(index); // Store the index in the order array
                    storeSelectedValues.push(optionValue);
                }

                // Loop through requiredColumns to add missing elements in the specified order
                for (let index = 0; index < requiredColumns.length; index++) {
                    const element = requiredColumns[index];
                    if (!jsonQuery.selectQuery.hasOwnProperty(element)) {
                        // Add the element at the end of the order array and assign the value
                        selectOrder.push(selectOrder.length);
                        jsonQuery['selectQuery'][selectOrder.length - 1] = element;
                    }
                }

                // Now, construct the final selectQuery object using the specified order
                let finalSelectQuery = {};
                for (let orderIndex of selectOrder) {
                    finalSelectQuery[orderIndex] = jsonQuery['selectQuery'][orderIndex];
                }

                // Replace the original selectQuery with the finalSelectQuery
                jsonQuery['selectQuery'] = finalSelectQuery;


            } else {
                for (let index = 0; index < availCols.length; index++) {
                    let optionValue = availCols[index].accessor;
                    jsonQuery['selectQuery'][optionValue] = optionValue;
                }
            }

            for (let selIndex = 0; selIndex < selectedFilters.length; selIndex++) {
                let fieldvalue = selectedFilters[selIndex].id.trim()
                let operatorSelectValue = document.getElementById(`operators_${fieldvalue}_id`).value;
                let valueSelectValue = document.getElementById(`values_${fieldvalue}_id`).value;
                if (selectedFilters[selIndex].type === 'T') {
                    switch (operatorSelectValue) {
                        case '~':
                            operatorSelectValue = "LIKE"
                            valueSelectValue = "%" + valueSelectValue + "%";
                            break;
                        case '!~':
                            operatorSelectValue = "NOT LIKE"
                            valueSelectValue = "%" + valueSelectValue + "%";
                            break;
                        case '^':
                            operatorSelectValue = "LIKE"
                            valueSelectValue = "%" + valueSelectValue;
                            break;
                        case '$':
                            operatorSelectValue = "LIKE"
                            valueSelectValue = valueSelectValue + "%";
                            break;
                        default:
                            break;
                    }
                }
                jsonQuery['whereQuery'][fieldvalue] = valueSelectValue;
                jsonQuery['operators'][fieldvalue] = operatorSelectValue;
                pdfExpFilters[selectedFilters[selIndex].label] = valueSelectValue;
            }
            jsonQuery['group_by']["GROUP BY"] = group_by;
            jsonQuery['additionalParam']['is_delete'] = "0";
            jsonQuery['additionalParam']['company_id'] = COMPANY_ID;
            jsonQuery['orderBy']['ORDER BY'] = 'quotation_master_transaction_id';

            if (reportType === 'summary') {
                reportName = "Quotation Summary Report"
                jsonQuery['viewname']['viewName'] = 'mtv_sales_quotation_trading_summary'
            } else {
                reportName = "Product FG Details Report"
                jsonQuery['viewname']['viewName'] = 'mtv_sales_quotation_trading_details'
            }
            let executeQuery = JSON.stringify(jsonQuery)
            return executeQuery;

        } catch (error) {
            console.log("error", error);
            navigate('/Error')
        }
    }


    // const submitQuery = async () => {
    //     let selectBox = document.getElementById("selectedColumns");
    //     let group_by = document.getElementById("group_by").value;
    //     let jsonQuery = { 'viewname': {}, 'selectQuery': {}, 'whereQuery': {}, 'operators': {}, 'group_by': {}, "additionalParam": {}, "orderBy": {} };
    //     storeSelectedValues = []

    //     if (selectBox.length > 4) {
    //         for (let i = 0; i < selectBox.length; i++) {
    //             let optionValue = selectBox.options[i].value;
    //             jsonQuery['selectQuery'][optionValue] = optionValue;
    //             storeSelectedValues.push(optionValue)
    //         }
    //     }

    //     jsonQuery['group_by']["GROUP BY"] = group_by;
    //     let table = document.getElementById("filters-table");
    //     let tr = table.getElementsByTagName("tr");

    //     for (let j = 0; j < tr.length; j++) {
    //         let tdLabel = tr[j].getElementsByTagName("td")[0];
    //         let tdOperator = tr[j].getElementsByTagName("td")[1];
    //         let tdValues = tr[j].getElementsByTagName("td")[2];
    //         let field = tdLabel.getElementsByTagName("label")[0];
    //         let operator = tdOperator.getElementsByTagName("select")[0];
    //         let values;
    //         let valueSelectValue;
    //         let fieldvalue;
    //         let operatorSelectValue;

    //         if (tdValues.children[0].type === 'text') {
    //             values = tdValues.children[0].value
    //             if (values !== '') {
    //                 fieldvalue = field.textContent.trim();
    //                 let checkOperator = operator.options[operator.selectedIndex].value;
    //                 switch (checkOperator) {
    //                     case '~':
    //                         operatorSelectValue = "LIKE"
    //                         valueSelectValue = "%" + values + "%";
    //                         break;
    //                     case '!~':
    //                         operatorSelectValue = "NOT LIKE"
    //                         valueSelectValue = "%" + values + "%";
    //                         break;
    //                     case '^':
    //                         operatorSelectValue = "LIKE"
    //                         valueSelectValue = "%" + values;
    //                         break;
    //                     case '$':
    //                         operatorSelectValue = "LIKE"
    //                         valueSelectValue = values + "%";
    //                         break;
    //                     default:
    //                         break;
    //                 }

    //             }
    //         } else if (tdValues.children[0].type === 'date') {
    //             fieldvalue = field.textContent.trim();
    //             valueSelectValue = tdValues.children[0].value
    //             operatorSelectValue = operator.options[operator.selectedIndex].value;

    //         } else {
    //             values = tdValues.getElementsByTagName("select")[0];
    //             valueSelectValue = values.options[values.selectedIndex].value;
    //             fieldvalue = field.textContent.trim();
    //             operatorSelectValue = operator.options[operator.selectedIndex].value;
    //         }
    //         jsonQuery['whereQuery'][fieldvalue] = valueSelectValue;
    //         jsonQuery['operators'][fieldvalue] = operatorSelectValue;
    //         pdfExpFilters[fieldvalue] = valueSelectValue;
    //     }
    //     jsonQuery['additionalParam']['is_delete'] = "0";
    //     jsonQuery['additionalParam']['company_id'] = COMPANY_ID;
    //     jsonQuery['additionalParam']['company_branch_id'] = COMPANY_BRANCH_ID;
    //     jsonQuery['orderBy']['ORDER BY'] = 'quotation_master_transaction_id';

    //     if (reportType === 'summary') {
    //         reportName = "Quotation Summary Report"
    //         jsonQuery['viewname']['viewName'] = 'mtv_sales_quotation_trading_summary'
    //     } else {
    //         reportName = "Quotation Details Report"
    //         jsonQuery['viewname']['viewName'] = 'mtv_sales_quotation_trading_details_rpt'
    //     }

    //     let executeQuery = JSON.stringify(jsonQuery)
    //     return executeQuery;
    // }

    const deleteRecords = async () => {
        try {

            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtQuotationDetails/FnDeleteRecord/${selectedQuotationReqData.quotation_version}/${COMPANY_ID}?quotation_no=${selectedQuotationReqData.quotation_no}`, method)
            const responce = await deleteApiCall.json();
            console.log("Quotation Deleted: ", responce);
            if (responce.success == '1') {
                setShow(false)
                fetchFilteredData(PageCurrent, entriesPerPage);
            }

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }


    const handlePageClick = async (pageNo) => {
        let currentPage = pageNo.selected;
        setcurrentPage(currentPage);
        const commentsFormServer = await fetchFilteredData(currentPage, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
        console.log("commentsFormServer: ", commentsFormServer)

    }

    const handlePageCountClick = async () => {
        let count = document.getElementById("page_entries_id").value;
        setEntriesPerPage(count)
        setcurrentPage(0)
        await fetchFilteredData(0, count, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
    }

    // const infoForUpdate = async (updateData, key) => {
    //     sessionStorage.removeItem('keyForViewUpdate');
    //     sessionStorage.removeItem('quotation_no');
    //     sessionStorage.setItem('quotation_no', JSON.stringify(updateData));
    //     sessionStorage.setItem('keyForViewUpdate', key);
    //     sessionStorage.removeItem('bomfilteredData');
    //     localStorage.removeItem('EnquiryCommonTermsData');

    //     navigate("/Transactions/TQuotation/FrmQuotationMasterEntry")
    // }

    const infoForUpdate = async (updateData, key) => {
        sessionStorage.removeItem('bomfilteredData');
        localStorage.removeItem('filteredMaterialData');
        localStorage.removeItem('EnquiryCommonTermsData');
        if (key === 'delete') {
            navigate("/Transactions/TQuotation/FrmQuotationMasterEntry", { state: { idList: updateData, keyForViewUpdate: 'view', compType: compType, deletionKey: true, } })
        } else {
            navigate("/Transactions/TQuotation/FrmQuotationMasterEntry", { state: { idList: updateData, keyForViewUpdate: key, compType: compType, deletionKey: false, } })
        }
        // navigate("/Transactions/TQuotation/FrmQuotationMasterEntry", { state: { idList: updateData, keyForViewUpdate: key, compType: compType } })
    }




    const switchReport = () => {
        setSelectedColumns([])
        setSelectedFilters([])
        setFilterComboBox([])
        let activeReport = document.querySelector('input[name=summaryOrDetails]:checked').value
        setReportType(activeReport)
    }
    const addSelectedColumns = () => {
        const selectedOption = document.getElementById("availableColumns");
        const selectedValue = selectedOption.value;
        if (selectedValue) {
            const selectedColumn = availableColumns.find(column => column.accessor === selectedValue);
            if (selectedColumn) {
                setAvailableColumns(availableColumns.filter(column => column.accessor !== selectedValue));
                setSelectedColumns([...selectedColumns, selectedColumn]);
            }
        }
    }

    const addAvailableColumns = () => {
        const selectedOption = document.getElementById("selectedColumns");
        const selectedValue = selectedOption.value;
        if (selectedValue) {
            const selectedColumn = selectedColumns.find(column => column.accessor === selectedValue);
            if (selectedColumn) {
                setAvailableColumns([...availableColumns, selectedColumn]);
                setSelectedColumns(selectedColumns.filter(column => column.accessor !== selectedValue));
            }
        }
    }


    const moveAllToSelectedColumns = () => {
        setSelectedColumns([...selectedColumns, ...availableColumns]);
        setAvailableColumns([]);
    }


    const moveAllToAvailableColumns = () => {
        // Move all selected columns to available columns
        setAvailableColumns([...availableColumns, ...selectedColumns]);

        // Clear the selected columns
        setSelectedColumns([]);
    };


    const moveOptionUp = () => {
        const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);

        if (selectedIndex > 0) {
            const newSelectedColumns = [...selectedColumns];
            [newSelectedColumns[selectedIndex], newSelectedColumns[selectedIndex - 1]] = [newSelectedColumns[selectedIndex - 1], newSelectedColumns[selectedIndex]];
            setSelectedColumns(newSelectedColumns);
        }
    };

    const moveOptionDown = () => {
        const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);

        if (selectedIndex < selectedColumns.length - 1 && selectedIndex !== -1) {
            const newSelectedColumns = [...selectedColumns];
            [newSelectedColumns[selectedIndex], newSelectedColumns[selectedIndex + 1]] = [newSelectedColumns[selectedIndex + 1], newSelectedColumns[selectedIndex]];
            setSelectedColumns(newSelectedColumns);
        }
    };

    const moveOptionTop = () => {
        const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);

        if (selectedIndex !== -1) {
            const newSelectedColumns = [...selectedColumns];
            const movedColumn = newSelectedColumns.splice(selectedIndex, 1)[0];
            setSelectedColumns([movedColumn, ...newSelectedColumns]);
        }
    };

    const moveOptionBottom = () => {
        const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);

        if (selectedIndex !== -1) {
            const newSelectedColumns = [...selectedColumns];
            const movedColumn = newSelectedColumns.splice(selectedIndex, 1)[0];
            setSelectedColumns([...newSelectedColumns, movedColumn]);
        }
    };

    const addFilterSelect = async () => {
        let filterText = document.getElementById('add_filter_select');
        if (filterText.value !== '0') {
            document.querySelectorAll("#add_filter_select option").forEach(opt => {
                if (opt.value === filterText.value) {
                    opt.disabled = true;
                }
            });
            const value = recordData[filterText.value];
            const myArray = value.split(":");
            const newFilter = {
                id: filterText.value,
                label: myArray[1],
                type: myArray[3],
                operatorArray: [],
                dataArray: []
            };
            switch (myArray[3]) {
                case 'C':
                    resetGlobalQuery();
                    globalQuery.columns.push(`DISTINCT (${filterText.value})`);
                    if (!(myArray[4] === 'cmv_city' || myArray[4] === 'cmv_country' || myArray[4] === 'cmv_district' || myArray[4] === 'cmv_state')) {
                        globalQuery.conditions.push({
                            field: "company_id",
                            operator: "=",
                            value: COMPANY_ID
                        });
                    }
                    globalQuery.conditions.push({
                        field: "is_delete",
                        operator: "=",
                        value: 0
                    });
                    globalQuery.table = myArray[4]
                    let masterList = await comboBox.current.fillFiltersCombo(globalQuery)
                    newFilter.operatorArray = operatorByType.list
                    newFilter.dataArray = masterList
                    break;
                case 'P':
                    let propertyList = await comboBox.current.fillComboBox(myArray[3])
                    newFilter.operatorArray = operatorByType.list
                    newFilter.dataArray = propertyList
                    break;
                case 'T':
                    newFilter.operatorArray = operatorByType.string
                    break;
                case 'H':
                    // Extracting values within parentheses from the last element
                    const valuesInParentheses = myArray[myArray.length - 1].slice(1, -1);
                    // Splitting the string into an array of values
                    const resultArray = valuesInParentheses.split(',');
                    console.log(resultArray);
                    newFilter.operatorArray = operatorByType.list
                    newFilter.dataArray = resultArray
                    break;
                case 'D':
                    newFilter.operatorArray = operatorByType.date
                    break;
            }
            // Update the state with the new filter
            setSelectedFilters(prevFilters => [...prevFilters, newFilter]);
        }
    }



    const exporttoPdf = async () => {
        await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
        const filtrdata = await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);

        if (filtrdata.length !== 0) {
            var filtrObjKeys = Object.keys(pdfExpFilters);
            for (let objKey = 0; objKey < filtrObjKeys.length; objKey++) {
                var key = filtrObjKeys[objKey];
                var value = pdfExpFilters[key];
            }
        }
        if (dataExport.length !== 0) {
            pdfExp.current.pdf(dataExport, columnExport, reportName, pdfExpFilters)
        }

    }

    const exporttoExcel = async () => {
        await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
        const filtrdata = await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);
        if (dataExport.length !== 0) {
            var jsonToExportExcel = { 'allData': {}, 'columns': columnExport, 'filtrKeyValue': {}, 'headings': {}, 'key': 'reportExport' }

            if (filtrdata.length !== 0) {
                var filtrObjKeys = Object.keys(pdfExpFilters);
                for (let objKey = 0; objKey < filtrObjKeys.length; objKey++) {
                    var key = filtrObjKeys[objKey];
                    var value = pdfExpFilters[filtrObjKeys[objKey]];
                    jsonToExportExcel['filtrKeyValue'][objKey] = key + ' : ' + value
                }
            }

            for (let arrKey = 0; arrKey < dataExport.length; arrKey++) {
                jsonToExportExcel['allData'][arrKey] = dataExport[arrKey];
            }
            jsonToExportExcel['headings']['ReportName'] = reportName
            jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
            jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')

            console.log("jsonToExportExcel: ", jsonToExportExcel)
            exlsExp.current.excel(jsonToExportExcel, reportName)
        }
    }

    const exporttoJSON = async () => {
        await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
        await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);
        if (dataExport.length !== 0) {
            let data = dataExport.map(element => {
                return columnExport.reduce((dataJson, col) => {
                    dataJson[col.accessor] = element[col.accessor];
                    return dataJson;
                }, {});
            });
            jsonExp.current.json(data, reportName)
        }

    }


    const exportToCSV = async () => {
        await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
        await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);
        if (dataExport.length !== 0) {
            let data = dataExport.map(element => {
                return columnExport.reduce((dataJson, col) => {
                    dataJson[col.accessor] = element[col.accessor];
                    return dataJson;
                }, {});
            });
            csvExp.current.csv(data, columnExport, reportName)
        }
    }


    // const openForm = () => {
    //     sessionStorage.removeItem('keyForViewUpdate');
    //     sessionStorage.removeItem('quotation_no');
    //     localStorage.removeItem('quotationMasterData');
    //     localStorage.removeItem('quotationMasterFormData');
    //     sessionStorage.removeItem('bomfilteredData');
    //     localStorage.removeItem('EnquiryCommonTermsData');
    //     navigate("/Transactions/TQuotation/FrmQuotationMasterEntry")
    // }

    const openForm = () => {
        sessionStorage.removeItem('keyForViewUpdate');
        localStorage.removeItem('enquiryMasterData');
        localStorage.removeItem('enquiryMasterFormData');
        sessionStorage.removeItem('bomfilteredData');
        localStorage.removeItem('EnquiryCommonTermsData');
        localStorage.removeItem('filteredMaterialData');
        navigate("/Transactions/TQuotation/FrmQuotationMasterEntry", { state: { idList: null, keyForViewUpdate: '', compType: compType, deletionKey: false, } })
    }


    const reload = () => {
        window.location.reload();
    }


    return (
        <>
            <ComboBox ref={comboBox} />
            <PdfExport ref={pdfExp} />
            <ExcelExport ref={exlsExp} />
            <JsonExport ref={jsonExp} />
            <CSVExport ref={csvExp} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <DashboardLayout>

                <button className="erp_toggle-filter" onClick={toggleFilter}>
                    <AiOutlineDownCircle className="rotate_filtr_arrow" />
                    <MDTypography component="label" variant="button" className="erp-form-label-md-lg">
                        &nbsp; filters
                    </MDTypography></button>


                <div className="hide-show-filters card filter">
                    <div className="row">
                        <div className="col-sm-6 erp_filter_table_avl_col">
                            <div className='container shadow h-100 rounded erp_group-resl_container'>
                                <div className='row erp_filter_row_tab_view'>
                                    <div className="erp_form_radio">
                                        <div className="fCheck">
                                            <Form.Check
                                                className="erp_radio_button erp-form-label-md"
                                                label="Summary"
                                                type="radio"
                                                value="summary"
                                                name="summaryOrDetails"
                                                onChange={() => switchReport()}
                                                defaultChecked
                                            />
                                        </div>
                                        <div className="sCheck">
                                            <Form.Check
                                                className="erp_radio_button erp-form-label-md"
                                                label="Details"
                                                value="details"
                                                type="radio"
                                                onChange={() => switchReport()}
                                                name="summaryOrDetails"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row erp_filter_row_tab_view'>
                                    <div className='col-sm-4'>
                                        <span>
                                            <MDTypography component="label" variant="button" className="erp-form-label-md">
                                                Available Columns
                                            </MDTypography>
                                            <select size="10" id="availableColumns" className="erp_form-select-sm-filter erp_form-select-filter">
                                                {availableColumns?.map(column => (
                                                    <option value={column.accessor}>  {column.Headers} </option>
                                                ))}
                                            </select>

                                        </span>
                                    </div>

                                    <div className='col-sm-1'>
                                        <div className="buttons" id="buttons_row1">
                                            <TbArrowsRight size={15} className='buttons_move erp_filtr_moveBtn' onClick={moveAllToSelectedColumns} /><br></br>
                                            <HiOutlineArrowNarrowRight size={15} className='buttons_move erp_filtr_moveBtn' onClick={addSelectedColumns} /><br></br>
                                            <HiOutlineArrowNarrowLeft size={15} className='buttons_move erp_filtr_moveBtn' onClick={addAvailableColumns} /><br></br>
                                            <TbArrowsLeft size={15} className='buttons_move erp_filtr_moveBtn' onClick={moveAllToAvailableColumns} />
                                        </div>
                                    </div>

                                    <div className='col-sm-4'>
                                        <div className="col">
                                            <MDTypography component="label" variant="button" className="erp-form-label-md">
                                                Selected Columns
                                            </MDTypography>

                                            <select size="10" id="selectedColumns" className="erp_form-select-sm-filter erp_form-select-filter" onChange={e => setSelectedValue(e.target.value)}>
                                                {selectedColumns.map((column, index) => (
                                                    <option key={index} value={column.accessor}>{column.Headers}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-sm-1'>
                                        <div className="buttons" id="buttons_row2">
                                            <TbArrowsUp size={15} className="erp_filtr_moveBtn" onClick={moveOptionTop} /><br></br>
                                            <HiOutlineArrowNarrowUp size={15} className="erp_filtr_moveBtn" onClick={moveOptionUp} /><br></br>
                                            <HiOutlineArrowNarrowDown size={15} className="erp_filtr_moveBtn" onClick={moveOptionDown} /><br></br>
                                            <TbArrowsDown size={15} className="erp_filtr_moveBtn" onClick={moveOptionBottom} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="col-sm-6 erp_filter_group-by-result">
                            <div className='container shadow h-100 rounded erp_group-resl_container'>
                                <div className='row erp_group-reslut-by'>
                                    <div className='col-sm-3 filtr_gropBy'>
                                        <MDTypography
                                            className="erp-form-label-md"
                                            component="label"
                                            variant="button"
                                        >Group results by</MDTypography>
                                    </div>
                                    <div className='col filtr_gropBy'>
                                        <Form.Select size="sm" className="erp_form_control operatorSelect" id="group_by">
                                            <option value=""></option>
                                            {groupByColumns?.map(column => (
                                                <option value={column.accessor}> {column.Headers} </option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-sm-3'>
                                        <MDTypography component="label" variant="button" className="erp-form-label-md">
                                            Add Filter
                                        </MDTypography>
                                    </div>
                                    <div className="col">
                                        <Form.Select size="sm" onChange={addFilterSelect} className="erp_form_control group_by operatorSelect erp_add_filter" id="add_filter_select" >
                                            <option value="0"></option>
                                            {filterComboBox?.map(column => (
                                                <option value={column.accessor}>{column.Headers}</option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                </div>
                                <table id="filters-table" className='erp-filters-table-scroll'>
                                    <tbody>
                                        {selectedFilters.map(filter => (
                                            <tr key={filter.id}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        id={`cb_${filter.id}_id`}
                                                        value={filter.id}
                                                        checked
                                                        onClick={() => removeFilter(filter.id)}
                                                    />
                                                    <label className='erp-form-label'>&nbsp;{filter.label}</label>
                                                </td>
                                                {/* Operators */}
                                                <td>
                                                    {(filter.type === 'C' || filter.type === 'P' || filter.type === 'H') && (
                                                        <select id={`operators_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                                            {filter.operatorArray.map(operator => (
                                                                <option key={operator} value={operator}>
                                                                    {operatorLabels[operator]}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                    {filter.type === 'T' && (
                                                        <select id={`operators_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                                            {filter.operatorArray.map(operator => (
                                                                <option key={operator} value={operator}>
                                                                    {operatorLabels[operator]}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}

                                                    {filter.type === 'D' && (
                                                        <select id={`operators_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                                            {filter.operatorArray.map(operator => (
                                                                <option key={operator} value={operator}>
                                                                    {operatorLabels[operator]}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                </td>
                                                {/* Values */}
                                                <td>
                                                    {filter.type === 'C' && (
                                                        <select id={`values_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                                            {filter.dataArray.map((item, index) => (
                                                                <option key={index} value={item[filter.id]}>
                                                                    {item[filter.id]}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                    {filter.type === 'P' && (
                                                        <select id={`values_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                                            {filter.dataArray.map((item, index) => (
                                                                <option key={index} value={item.field_name}>
                                                                    {item.field_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                    {filter.type === 'T' && (
                                                        <input type="text" id={`values_${filter.id}_id`} className='erp_form_control erp_input_field inputValue erp_operator_val' />
                                                    )}
                                                    {filter.type === 'D' && (
                                                        <input type="date" id={`values_${filter.id}_id`} className='erp_form_control erp_input_field inputValue erp_operator_val' />
                                                    )}
                                                    {filter.type === 'H' && (
                                                        <select id={`values_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                                            {filter.dataArray.map((operator, index) => (
                                                                <option key={index} value={operator}>
                                                                    {operator}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=" row btn_row_class">
                    <div className="col-sm-8" >
                        <MDButton className={`btn erp-gb-button ${compType === 'Register' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={openForm}>Add</MDButton> &nbsp;
                        <MDButton class="btn erp-gb-button" variant="button" fontWeight="regular" onClick={() => fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns)}>Apply Filter</MDButton> &nbsp;
                        <MDButton class="btn erp-gb-button" variant="button" fontWeight="regular" onClick={reload}>Clear Filter</MDButton>&nbsp;
                        <span className="page_entries">
                            <MDTypography component="label" variant="button" className="erp-form-label-md">Entries per page: &nbsp;</MDTypography>

                            <Form.Select onChange={handlePageCountClick} value={entriesPerPage} className="erp_page_select erp_form_control" id="page_entries_id" >
                                {pageEntriesOptions.map(pageEntriesOptions => (
                                    <option value={pageEntriesOptions.value}>{pageEntriesOptions.label}</option>

                                ))}
                            </Form.Select>
                        </span>
                    </div>
                    <div className="col-4 pagination_id">
                        <span className="exports">
                            <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoPdf()}>PDF<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
                            <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoExcel()}>EXCEL<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
                            <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exportToCSV()}>CSV<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
                            <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoJSON()}>JSON<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
                        </span>
                    </div>
                </div>


                {QuotationTbldata.length !== 0 ? <>
                    <Datatable data={QuotationTbldata} columns={QuotationTblcolumns} freezeColumnCount={5} />
                    {pageCount !== 1 ?
                        <ReactPaginate
                            className='erp_pagination'
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link erp-gb-button"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link erp-gb-button"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link erp-gb-button"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"} /> : null}
                </> : <Card id="NoRcrdId" >
                    <Card.Body>No records found...</Card.Body>
                </Card>}


            </DashboardLayout>
            {/* Delete Modal */}
            <DeleteModal show={show} closeModal={handleClose} delete={deleteRecords} />
        </>
    )
}

export default FrmQuotationListing;

