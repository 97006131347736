import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';


// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstra
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

// File Imports
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import ConfigConstants from "assets/Constants/config-constant";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmValidations from "FrmGeneric/FrmValidations";
import FrmDepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry"
import { resetGlobalQuery } from "assets/Constants/config-constant";
import { globalQuery } from "assets/Constants/config-constant";
import ComboBox from "Features/ComboBox";
import FrmProductionEntry from "Transactions/TProduction/FrmProductionEntry";
import FrmProductionSectionEntry from "Masters/MProductionSection/FrmProductionSectionEntry";
import FrmProductionSubSectionEntry from "Masters/MProductionSubSection/FrmProductionSubSectionEntry";

function FrmJobTypeEntry(props) {

    // Call ConfigConstants to get the configuration constants
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName } = configConstants;
    const { state } = useLocation();
    const { jobTypeId = 0, keyForViewUpdate = 'Add', compType = 'Masters' } = state || {}

    // Use Refs to call external functions
    const validateNumberDateInput = useRef();
    const validate = useRef();
    const navigate = useNavigate()
    const comboDataFunc = useRef();


    //radio btn
    const [modalHeaderName, setHeaderName] = useState('')
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    //financial Year Feilds
    const [txt_job_type_id, setJobTypeId] = useState(jobTypeId);
    const [txt_job_type_name, setJobTypeName] = useState('');
    const [txt_job_type_short_name, setJobTypeShortName] = useState('');
    const [txt_job_type_rate, setJobTypeRate] = useState(1);
    const [txt_job_type_rate_group, setJobTypeRateGroup] = useState(1);
    const [chk_job_type_category, setJobTypeCategory] = useState('Standard Rates');
    const [cmb_job_type_department_id, setJobTypeDeparmentId] = useState([]);
    const [cmb_section_id, setProdSection] = useState();
    const [cmb_sub_section_id, setProdSubSection] = useState();

    const [chk_isactive, setIsActive] = useState(true);
    const [actionType, setActionType] = useState('')
    const [actionLabel, setActionLabel] = useState('Save')

    // hooks for combo
    const [jobTypeDepartmentOption, setJobTypeDepartmentOption] = useState([]);
    const [prodsectionOptions, setProdSectionOptions] = useState([]);
    const [prodsubsectionOptions, setProdSubSectionOptions] = useState([]);
    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Masters/MJobType/FrmJobTypeListing`)
        }
    }

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    useEffect(() => {
        const loadDataOnload = async () => {
            await ActionType()
            await fillComobos();
            if (jobTypeId !== 0) {
                await FnCheckUpdateResponce()
            }
        }
        loadDataOnload()
    }, [])


    const addJobType = async () => {
        try {
            const checkIsValidate = await validate.current.validateForm("jobTypeform");
            if (checkIsValidate === true) {
                const data = {
                    company_branch_id: COMPANY_BRANCH_ID,
                    company_id: COMPANY_ID,
                    job_type_id: txt_job_type_id,
                    created_by: UserName,
                    modified_by: txt_job_type_id === 0 ? null : UserName,
                    job_type_short_name: txt_job_type_short_name,
                    job_type_section_id: cmb_section_id,
                    job_type_sub_section_id: cmb_sub_section_id,
                    job_type_name: txt_job_type_name,
                    job_type_rate: txt_job_type_rate,
                    job_type_category: chk_job_type_category,
                    job_type_rate_group: txt_job_type_rate_group,
                    job_type_department_id: cmb_job_type_department_id,
                    is_active: chk_isactive,
                };
                console.log(data);
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/jobType/FnAddUpdateRecord`, requestOptions)
                const responce = await apicall.json()
                if (responce.error !== "") {
                    console.log("response error: ", responce.error);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = comboDataFunc.current.evitCache();
                    console.log(evitCache);
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }

    };


    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                $('#txt_job_type_name').attr('disabled', true)
                $('#txt_job_type_short_name').attr('disabled', true)
                break;
            case 'view':
                setActionType('(View)');

                await validate.current.readOnly("jobTypeform");
                $("input[type=radio]").attr('disabled', true);
                break;
            default:
                setActionType('(Creation)');
                break;
        }
    };

    const validateFields = () => {
        validate.current.validateFieldsOnChange('jobTypeform')
    }

    const FnCheckUpdateResponce = async () => {
        debugger
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/jobType/FnShowParticularRecord/${COMPANY_ID}/${txt_job_type_id}`)
            const updateRes = await apiCall.json();
            const data = JSON.parse(updateRes.data)
            setJobTypeId(data.job_type_id)
            setJobTypeName(data.job_type_name)
            setJobTypeShortName(data.job_type_short_name);
            setJobTypeRate(data.job_type_rate);
            setJobTypeRateGroup(data.job_type_rate_group);
            setJobTypeCategory(data.job_type_category);
            setJobTypeDeparmentId(data.job_type_department_id);
            setProdSection(data.job_type_section_id);
            FnCombosOnChange('cmb_section_id')
            setProdSubSection(data.job_type_sub_section_id);
            setIsActive(data.is_active);
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }

    }

    const fillComobos = async () => {
        comboDataFunc.current.fillMasterData("cmv_department", "parent_department_id", "4").then((departmentidListApiCall) => {
            setJobTypeDepartmentOption(departmentidListApiCall);
        })

        ////Production section
        comboDataFunc.current.fillMasterData("xmv_production_section", "", "").then((getProdSectionApiCall) => {
            setProdSectionOptions(getProdSectionApiCall);
        })
    }

    const handleCloseRecModal = async () => {
        switch (modalHeaderName) {
            case 'Parent Department':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'parent_department_id'];
                globalQuery.table = "cmv_department"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: "4" });
                const parentDepartment = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
                setJobTypeDeparmentId(parentDepartment)
                break;

        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
        }, 200)

    }

    const FnCombosOnChange = async (key) => {
        debugger
        switch (key) {
            case 'parentDepartment':
                const parentDepartmentVar = document.getElementById('cmb_job_type_department_id').value;
                setJobTypeDeparmentId(parentDepartmentVar)
                $('#error_cmb_job_type_department_id').hide();
                if (parentDepartmentVar === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Parent Department')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");

                    }, 100)
                }
                break;

            case 'cmb_section_id':
                const prod_section_Id = $('#cmb_section_id').val();
                if (prod_section_Id === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Production Section')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");

                    }, 100)
                }
                const getProdSubSectionApiCall = await comboDataFunc.current.fillMasterData("xmv_production_sub_section", "production_section_id", prod_section_Id);
                setProdSubSectionOptions(getProdSubSectionApiCall);

                break;

            case 'cmb_sub_section_id':
                const prod_sub_section_Id = $('#cmb_sub_section_id').val();
                if (prod_sub_section_Id === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Production Sub Section')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");

                    }, 100)
                }
                break;

        }
    }
    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'Parent Department':
                return <FrmDepartmentEntry btn_disabled={true} />;
            case 'Production Section':
                return <FrmProductionSectionEntry btn_disabled={true} />;
            case 'Production Sub Section':
                return <FrmProductionSubSectionEntry btn_disabled={true} />
            default:
                return null;
        }
    }

    const validateNo = (key) => {
        const numCheck = /^[0-9]*\.?[0-9]*$/;
        const regexNo = /^[0-9]*\.[0-9]{5}$/
        var dot = '.';
        switch (key) {
            case 'pFromRange':
                var pFromRangeVal = $('#txt_job_type_rate').val();
                if (!regexNo.test(pFromRangeVal) && pFromRangeVal.length <= 14 || pFromRangeVal.indexOf(dot) === 14) {
                    if (numCheck.test(pFromRangeVal)) {
                        setJobTypeRate(pFromRangeVal)
                    }

                }
                break;
            case 'pToRange':
                var pToRangeVal = $('#txt_job_type_rate_group').val();
                if (!regexNo.test(pToRangeVal) && pToRangeVal.length <= 14 || pToRangeVal.indexOf(dot) === 14) {
                    if (numCheck.test(pToRangeVal)) {
                        setJobTypeRateGroup(pToRangeVal)
                    }

                }
                break;
        }
    }
    return (
        <>
            <ComboBox ref={comboDataFunc} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <FrmValidations ref={validate} />
            <div className="erp_top_Form">
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Job Type {actionType} </label>
                    </div>
                    <form id="jobTypeform">
                        <div className="row erp_transporter_div">
                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Job Type Name <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" className="erp_input_field" id="txt_job_type_name" value={txt_job_type_name} onChange={e => { setJobTypeName(e.target.value); validateFields() }} maxLength="255" disabled={keyForViewUpdate === 'update'} />
                                        <MDTypography variant="button" id="error_txt_job_type_name" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Short Name </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_job_type_short_name" className="erp_input_field" value={txt_job_type_short_name} onChange={(e) => { setJobTypeShortName(e.target.value.toUpperCase()); validateFields() }} optional="optional" maxLength="4" />
                                        <MDTypography variant="button" id="error_txt_job_type_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Production Section <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_section_id" className="form-select form-select-sm" value={cmb_section_id} onChange={(e) => { FnCombosOnChange('cmb_section_id'); setProdSection(e.target.value); }}>
                                            <option value="">Select</option>
                                            <option value="0" disabled={props.btn_disabled}>Add New Record+</option>
                                            {prodsectionOptions.length !== 0 ? (
                                                <>
                                                    {prodsectionOptions?.map(production => (
                                                        <option value={production.field_id}>{production.field_name}</option>
                                                    ))}
                                                </>
                                            ) : null

                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Prod. Sub Section <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_sub_section_id" className="form-select form-select-sm" value={cmb_sub_section_id} onChange={(e) => { FnCombosOnChange('cmb_sub_section_id'); setProdSubSection(e.target.value) }}>
                                            <option value="">Select</option>
                                            <option value="0" disabled={props.btn_disabled}>Add New Record+</option>
                                            {prodsubsectionOptions.length !== 0 ? (
                                                <>
                                                    {prodsubsectionOptions?.map(subproduction => (
                                                        <option value={subproduction.field_id}>{subproduction.field_name}</option>
                                                    ))}
                                                </>
                                            ) : null

                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_sub_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                            </div>

                            {/* second column */}
                            <div className="col-sm-6 erp_form_col_div">

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Job Type Rate</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_job_type_rate" className="erp_input_field text-end" value={txt_job_type_rate} onChange={e => { validateNo('pFromRange'); }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_job_type_rate" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Job Type Rate Group</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_job_type_rate_group" className="erp_input_field text-end" value={txt_job_type_rate_group} onChange={e => { validateNo('pToRange'); }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_job_type_rate_group" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Job Type Category</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Standard Rates" type="radio" lbl="Yes" value="Standard Rates" name="chk_job_type_category" checked={chk_job_type_category === "Standard Rates"} onClick={() => { setJobTypeCategory('Standard Rates'); }} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="MachineWise Rates" type="radio" lbl="No" value="MachineWise Rates" name="chk_job_type_category" checked={chk_job_type_category === "MachineWise Rates"} onClick={() => { setJobTypeCategory('MachineWise Rates'); }} /> </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Job Type Department <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_job_type_department_id" className="form-select form-select-sm" value={cmb_job_type_department_id} onChange={() => FnCombosOnChange('parentDepartment')}  >
                                            <option value="">Select</option>
                                            <option value="0" disabled={props.btn_disabled}>Add New Record+</option>
                                            {jobTypeDepartmentOption?.map(DepartmentOption => (
                                                <option value={DepartmentOption.field_id}>{DepartmentOption.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_job_type_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Is Active</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" name="chk_isactive" checked={chk_isactive} onClick={() => { setIsActive(true); }} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" name="chk_isactive" checked={!chk_isactive} onClick={() => { setIsActive(false); }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="card-footer py-0 text-center">
                        <MDButton type="button" className="erp-gb-button"
                            onClick={() => {
                                const path = compType === 'Register' ? '/Masters/MJobType/FrmJobTypeListing/reg' : '/Masters/MJobType/FrmJobTypeListing';
                                navigate(path);
                            }}
                            variant="button"
                            fontWeight="regular" disabled={props.btn_disabled ? true : false}>Back</MDButton>
                        <MDButton type="submit" onClick={addJobType} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            fontWeight="regular">{actionLabel}</MDButton>

                    </div >
                </div>
                {/* Success Msg Popup */}
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />

                {/* Error Msg Popup */}
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
                {showAddRecModal ? <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        <div className='row'>
                            <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
                        </div>
                        {displayRecordComponent()}
                    </Modal.Body>
                </Modal > : null}
            </div>
        </>
    )
}

export default FrmJobTypeEntry;
