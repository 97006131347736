import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import { Form, Table } from 'react-bootstrap';
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import GenerateTAutoNo from 'FrmGeneric/GenerateTAutoNo';


import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import ExcelExport from 'Features/Exports/ExcelExport';
import FrmValidations from 'FrmGeneric/FrmValidations';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { CircularProgress } from '@material-ui/core';


function FrmSpinPlanEntry() {

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_NAME, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME } = configConstants;

    const { state } = useLocation();
    const { spinPlanId = 0, plantId, plantCode, keyForViewUpdate = 'Add', compType } = state || {};

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const [action_Button, setActionButton] = useState(keyForViewUpdate === 'Add' ? 'Save' : keyForViewUpdate === 'approve' ? 'Approve' : keyForViewUpdate === '' ? 'Cancel' : 'Save');
    const [action_Label, setActionLabel] = useState(keyForViewUpdate === 'Add' ? '(Creation)' : keyForViewUpdate === 'approve' ? '(Approval)' : keyForViewUpdate === 'copy' ? '(Copy)' : keyForViewUpdate === 'cancel' ? '(Cancel)' : '(View)');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => setShowSuccessMsgModal(false);
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    // Loader
    const [isLoading, setIsLoading] = useState(false);
    const is_approve = keyForViewUpdate === 'approve'

    //Hooks for useRef
    const validateNumberDateInput = useRef();
    const generateAutoNoAPiCall = useRef();
    const comboDataAPiCall = useRef();
    const exlsExp = useRef();
    const validate = useRef();
    const navigate = useNavigate();

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    //////Converting date to string format///////
    const date_toString = (input_Date) => {
        debugger
        const parsed_Date = new Date(input_Date);

        const date_OfInputDate = String(parsed_Date.getDate()).padStart(2, '0');
        const month_OfInputDate = String(parsed_Date.getMonth() + 1).padStart(2, '0');
        const year_OfInputDate = parsed_Date.getFullYear();

        const formatted_Date = date_OfInputDate + month_OfInputDate + year_OfInputDate;
        return formatted_Date;
    }


    //useRef Hooks 
    const [prod_spin_param_Arr_forFillingFields, setProdParamArrForFields] = useState(['Actual Count', '40 s conversion factor', 'Type of card'])
    const [production_spin_plan_param_array, setProdSpinPlanParamArray] = useState([
        'No. of spindles/machine',
        'No. of ring frames to be allotted',
        'Utilisation %',
        'Spindle speed in RPM',
        'T.P.I.',
        'T.M.',
        'Expected efficiency %',
        'Hank simplex',
        'Spindle speed (RPM)',
        'T.P.I.',
        'T.M.',
        'Expected efficiency %',
        'M/c. to be allotted',
        'Hank drawing',
        'Delivery speed in mtrs. per min.',
        'Expected efficiency %',
        'Total Machine to be allotted',
        'Hank drawing',
        'Delivery speed in mtrs./min',
        'Expected efficiency %',
        'Total Machine allotted',
        'Type of card',
        'Hank card sliver',
        'Carding Speed',
        'Expected efficiency %',
        'Total cards allotted',
        'Winding speed in mtrs./min',
        'Expected efficiency %',
        'No. of m/cs.',]);


    //Hooks fro spin plan data
    const [cmb_planmonth, setSpinPlanMonth] = useState("");
    const [cmb_planyear, setSpinPlanYear] = useState();
    const [txt_spin_plancode, setSpinPlanCode] = useState();
    const [cmb_spin_plantname, setSpinPlantName] = useState("");
    const [cmb_customername, setSpinPlanCustomerName] = useState();
    const [txt_spin_plantremark, setSpinRemark] = useState();
    const [cmb_spin_plancountname, setSpinPlanCountName] = useState("");
    const [dt_spin_planstartdate, setSpinPlanStartDate] = useState(today);
    const [dt_spin_planenddate, setSpinPlanEndDate] = useState(today);
    const [cmb_spin_plantstatus, setSpinPlantStatus] = useState('Pending');
    const [cmb_spin_plancreatedby, setPlanCreatedBy] = useState("");
    const [cmb_spin_planapprovedby, setPlanApprovedBy] = useState("");
    const [txt_spin_plan_avg_count, setSpinPlanAvgCount] = useState('0');
    const [txt_spin_plantAppRemark, setSpinApproveRemark] = useState('');
    const [txt_spin_plantCancelRemark, setSpinCancelRemark] = useState('');
    const [spinn_plan_id, setSpinPlanId] = useState(spinPlanId);


    //Combo Options
    const [spinplanyearOptions, setSpinPlanYearOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [plantOptions, setPlantOptions] = useState([]);
    const [prodcountOptions, setProdCountOptions] = useState([]);
    const [spinplanCreatedByOptions, setSpinPlanCreatedByOptions] = useState([]);
    const [spinplanApprovedByOptions, setSpinPlanApprovedByOptions] = useState([]);

    ///Product Count Check boxes
    var all = "all";
    const [selectAllCheckBoxes, setSelectAllCheckBoxes] = useState('all');
    const [saveKeys, setSaveKeys] = useState([]);

    //Hook for table
    const [headerdata, setHeaderData] = useState([]);
    const headerDataLength = headerdata.length;
    const [planparamdata, setPlanParamData] = useState([]);
    const [totalsUpdated, setTotalsUpdated] = useState(false);



    useEffect(async () => {
        setIsLoading(true)
        await fillComboBox();
        if (spinPlanId !== 0) {
            await FnCheckUpdateResponce();
        }
        setIsLoading(false)
        $(document).on('mouseup', function (e) {
            let container1 = $("#prodcount-order-ul");

            if (!container1.is(e.target) && container1.has(e.target).length === 0) {
                container1.hide();
            }
        });
    }, [])

    //function for fill all combo box
    const fillComboBox = async () => {
        try {
            const plantApiCall = await comboDataAPiCall.current.fillMasterData("amv_financial_year", "", "");
            setSpinPlanYearOptions(plantApiCall);

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'customer_name'];
            globalQuery.table = "cmv_customer"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getCustomersApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setCustomerOptions(getCustomersApiCall);

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'plant_name'];
            globalQuery.table = "cmv_plant"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getPlantApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setPlantOptions(getPlantApiCall);

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'production_count_name', 'production_count_id'];
            globalQuery.table = "xmv_spinning_prod_count"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getProdCountApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
            setProdCountOptions(getProdCountApiCall);

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name'];
            globalQuery.table = "cmv_employee"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getEmployeesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setSpinPlanCreatedByOptions(getEmployeesApiCall);
            setSpinPlanApprovedByOptions(getEmployeesApiCall);
        } catch (error) {
            navigate('/Error')
        }
    }

    const fillAutoNo = async () => {
        debugger
        try {
            const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("xt_spinning_prod_spinn_plan_master", "spinn_plan_code",
                "", "SP", "4");

            let startDate = dt_spin_planstartdate === undefined ? today() : $('#dt_spin_planstartdate').val();
            let endDate = dt_spin_planenddate === undefined ? today() : $('#dt_spin_planenddate').val();
            let string_plantName_Date = ($('#cmb_spin_plantname').find(":selected").text() + '/' + date_toString(startDate) + '/' + date_toString(endDate) + '/');
            const index_Of_Slash = autoNoApiCall.indexOf('/');

            if (startDate !== endDate) {
                if (index_Of_Slash !== -1) {
                    const spin_plan_code = autoNoApiCall.substring(0, index_Of_Slash + 1) + string_plantName_Date + autoNoApiCall.substring(index_Of_Slash + 1);
                    setSpinPlanCode(spin_plan_code);
                } else {
                    setSpinPlanCode(0);
                }
                return autoNoApiCall;
            }else{
                $('#error_dt_spin_planenddate').text('Please change end date...').show();
            }


        } catch (error) {
            console.log('error: ', error);
            navigate('/Error')
        }
    };

    const renderTableData = async (selectedValue) => {
        setIsLoading(true)
        try {
            validateFields();
            setSpinPlanCountName("");
            setPlanParamData([])

            if (plantOptions.find(plant => plant.field_id === parseInt(selectedValue))) {
                resetGlobalQuery();
                globalQuery.columns = ['production_sub_section_id', 'production_section_id', 'production_spinning_plan_parameter_id', 'production_spinning_plan_parameter_name', 'production_spinning_plan_parameter_sequance', 'production_sub_section_name'];
                globalQuery.table = "xmv_production_spinning_plan_parameter"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getPlanParamApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

                setHeaderData(['Sr. No', 'Section', 'Particular', 'Total']);
                setPlanParamData(getPlanParamApiCall);
            } else {
                setHeaderData([]);
                setPlanParamData([]);
            }
            setIsLoading(false)
        } catch (error) {
            navigate('/Error');
        }
    }


    const FnCheckUpdateResponce = async () => {
        try {
            const json = { 'SppinProdSpinPlanData': { 'commonIds': { 'spinn_plan_code': plantCode, 'company_id': COMPANY_ID, 'plant_id': plantId, } } }
            const formData = new FormData();
            formData.append(`SppiningProdAndPlantProdDetails`, JSON.stringify(json))
            const requestOptions = {
                method: 'POST',
                body: formData
            };
            const apiCallForSpinPlanData = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtSpinningProdSpinnPlanMaster/FnShowMasterAndDetailsModelRecordForUpdate`, requestOptions);
            const response = await apiCallForSpinPlanData.json();
            if (response.success === 1) {
                const { spinningPlanDetails, productionCountNameList } = response

                //Set spin plan master Data.................
                let spinn_plan_master_data = response.spinningPlanDetails[0];
                setSpinPlanId(spinn_plan_master_data.spinn_plan_id)
                setSpinPlanMonth(spinn_plan_master_data.spinn_plan_month);
                setSpinPlanYear(spinn_plan_master_data.spinn_plan_year);
                setSpinPlanCode(spinn_plan_master_data.spinn_plan_code);
                setSpinPlantName(spinn_plan_master_data.plant_id);
                setSpinPlanCustomerName(spinn_plan_master_data.customer_id);
                setSpinRemark(spinn_plan_master_data.spinn_plan_description);
                setSpinPlanStartDate(spinn_plan_master_data.spinn_plan_start_date);
                setSpinPlanEndDate(spinn_plan_master_data.spinn_plan_end_date);

                setSpinPlantStatus(keyForViewUpdate === 'approve' ? 'Approved' : keyForViewUpdate === 'cancel' ? 'Cancelled' : spinn_plan_master_data.spinn_plan_status);
                setPlanCreatedBy(spinn_plan_master_data.spinn_plan_created_by_id);
                setPlanApprovedBy(spinn_plan_master_data.spinn_plan_approved_by_id);
                setSpinPlanAvgCount(spinn_plan_master_data.spinn_plan_avg_count);

                let headerDataResp = [];
                headerDataResp.push('Sr. no', 'Section', 'Particular', 'Total', ...response.productionCountNameList);
                setHeaderData(headerDataResp);
                setPlanParamData(response.spinningPlanDetails);

                console.log('SpinningProdSpinnPlanDetails: ', spinningPlanDetails);
                console.log('productionCountNameList: ', productionCountNameList);
            }



            switch (keyForViewUpdate) {
                case 'view':
                    $('#cmb_planmonth, #cmb_planyear, #dt_spin_planstartdate, #dt_spin_planenddate, #cmb_spin_plantname, #cmb_customername, cmb_spin_plantstatus, #txt_spin_plantremark, #cmb_spin_plancreatedby').prop('disabled', true);
                    $('#action_Button, #print_Button').hide();
                    break
                case 'approve':
                case 'cancel':
                    $('#cmb_planmonth, #cmb_planyear, #dt_spin_planstartdate, #dt_spin_planenddate, #cmb_spin_plantname, #cmb_customername, #txt_spin_plantremark, #cmb_spin_plancreatedby').prop('disabled', true);
                    $('#print_Button').hide();
                    break;
                case 'copy':

                    break;
                default:
                    break;
            }

            if (['view', 'approve', 'cancel', 'copy'].includes(keyForViewUpdate)) {
                $('.product_count_values').prop('disabled', true);
                $('#spin_plancountname').hide();
            }
        } catch (error) {
            navigate('/Error');
        }
    }



    const renderSpinPlanTable = useMemo(() => {

        return <Table id="spinplantable1" style={{ display: "block", overflow: "auto", height: "250px" }} bordered striped>
            <thead className="erp_table_head">
                <tr>
                    {headerdata.map((header, indexOfItem) => (
                        <th key={indexOfItem} className="erp_table_th" id={`${header}`} >
                            {header}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {planparamdata?.map((planParameter, index) => (
                    <tr rowindex={index} className="sticky-column">
                        <td className='erp_table_td' id={`${planParameter.production_spinning_plan_parameter_sequance}_${index}`}>
                            {planParameter.production_spinning_plan_parameter_sequance}
                        </td>
                        <td className='erp_table_td' id={`${planParameter.production_sub_section_name}_${index}`}>
                            {planParameter.production_sub_section_name}
                        </td>
                        <td className='erp_table_td' id={`${planParameter.production_spinning_plan_parameter_name}_${index}`}>
                            {planParameter.production_spinning_plan_parameter_name}
                        </td>
                        <td className='erp_table_td fw-bolder planParameter_total' style={{ width: "75px" }} id={`${planParameter.total}_${index}`}>
                            {planParameter.total}
                        </td>


                        {Object.keys(planParameter)?.map((key) => {
                            const excludedKeys = ['customer_id', 'plant_id', 'plant_name', 'spinn_plan_approved_by_id', 'spinn_plan_avg_count', 'spinn_plan_code', 'spinn_plan_created_by_id', 'spinn_plan_description', 'spinn_plan_end_date', 'spinn_plan_id', 'spinn_plan_month', 'spinn_plan_start_date', 'spinn_plan_status', 'spinn_plan_transaction_id', 'spinn_plan_year', 'production_sub_section_id', 'production_count_id', 'production_section_id', 'production_section_name', 'production_spinning_plan_parameter_id', 'production_spinning_plan_parameter_name', 'production_spinning_plan_parameter_value', 'production_spinning_plan_parameter_sequance', 'production_sub_section_name', 'sub_section_id', 'section_id', 'total'];
                            if (!excludedKeys.includes(key)) {
                                return (
                                    <td key={key} className='erp_table_td'>
                                        {
                                            <input type="text"
                                                id={`product_count_${key}_${index}`}
                                                className="erp_input_field_table_txt mb-0 text-end product_count_values"
                                                value={planParameter[key]}
                                                onKeyDown={(e) => handleKeyDown(e, index)}
                                                style={{
                                                    width: "100px",
                                                    ...(production_spin_plan_param_array.includes(planParameter.production_spinning_plan_parameter_name) && {
                                                        backgroundColor: '#AFE1AF',
                                                    }),
                                                }}
                                                {...(production_spin_plan_param_array.includes(planParameter.production_spinning_plan_parameter_name) ? { width: '100px' } : { disabled: true })}
                                                onChange={(e) => { updateParameterTable(planParameter, e); }}
                                                Headers={key}
                                            />
                                        }
                                    </td>
                                );
                            }
                        })}

                    </tr>
                ))}
            </tbody>

        </Table>

    }, [cmb_spin_plantname, headerdata, planparamdata]);

    const handleKeyDown = (event, index) => {
        if (event.key === 'Enter' && event.target.value !== "0" && event.target.value !== "") {
            event.preventDefault();
            var currentInput = event.target;
            var currentTd = currentInput.parentElement;
            var currentRow = currentTd.parentElement;
            var currentIndex = Array.from(currentRow.children).indexOf(currentTd);

            function findNextEnabledInput(row) {
                var nextRow = row.nextElementSibling;
                while (nextRow) {
                    var nextInput = nextRow.querySelector('input[type="text"]:not([disabled])');
                    if (nextInput) {
                        nextInput.focus();
                        return;
                    }
                    nextRow = nextRow.nextElementSibling;
                }
            }

            if (currentIndex === currentRow.children.length - 1) {
                findNextEnabledInput(currentRow);
            } else {
                var nextTd = currentRow.children[currentIndex + 1];
                if (nextTd && nextTd.tagName === 'TD') {
                    var nextInput = nextTd.querySelector('input[type="text"]:not([disabled])');
                    if (nextInput) {
                        nextInput.focus();
                    } else {
                        findNextEnabledInput(currentRow);
                    }
                }
            }
        } else {
            var element = document.getElementById(event.target.id);
            if (element) {
                element.parentElement.dataset.tip = "Please enter a valid number....";
            }
        }
    };

    const addNewColumn = async () => {
        debugger
        try {
            setIsLoading(true)
            if (cmb_spin_plantname !== "" && cmb_spin_plantname !== undefined) {
                $('#error_cmb_spin_plantname').hide();

                let selectedCheckboxes = [];
                $('.productCountCheckBox:checked').each(function () {
                    selectedCheckboxes.push($(this).val());
                });

                const excludedHeaderData = headerdata.slice(4);

                ///////////////////////Making total = 0 or updating the total value;////////////////////////////
                let plan_param_data = [...planparamdata];
                if (selectedCheckboxes.length === 0 && !totalsUpdated) {
                    setTotalsUpdated(true);
                    plan_param_data.forEach((paramdata, index) => {
                        if (paramdata.total !== 0) {
                            paramdata.total = 0;
                        }
                    });
                    setPlanParamData(plan_param_data);
                } else {
                    setTotalsUpdated(false);
                }


                setSaveKeys(excludedHeaderData);
                const newArray = []
                excludedHeaderData.filter(value => {
                    if (!selectedCheckboxes.includes(value)) {
                        newArray.push(value);
                    }
                });

                newArray.filter((selectCheckBox, index) => {
                    if ((!selectedCheckboxes.includes(selectCheckBox))) {
                        deleteFromTable(selectCheckBox);
                        return true;
                    }
                    return false;
                });


                if (selectedCheckboxes.includes('all')) {
                    $('.productCountCheckBox:checked').each(function () {
                        renderingTable($(this).val())
                    });
                } else {
                    const resultArray = selectedCheckboxes.filter(element => (!newArray.includes(element)));
                    for (const resultArray of selectedCheckboxes) {
                        if (!headerdata.includes(resultArray)) {
                            renderingTable(resultArray);
                        }
                    }
                }
            } else {
                $('#error_cmb_spin_plantname').css({
                    'display': 'block',
                    'margin-top': '8px'
                }).text('Select the plant...');

            }
        } catch (error) {
            navigate('/Error');
        } finally {
            setIsLoading(false)
        }
    }

    const renderingTable = async (selectedProdCountValue) => {
        try {
            setIsLoading(true)
            if (!headerdata.includes(selectedProdCountValue) && selectedProdCountValue !== 'all') {
                resetGlobalQuery();
                globalQuery.columns = ['production_actual_count', 'count_conversionfactor_ringframe'];
                globalQuery.table = "xm_spinning_prod_count"
                globalQuery.conditions.push({ field: "production_count_name", operator: "=", value: selectedProdCountValue });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getPlanProdActualCountApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

                let updatedPlanparamdata = [...planparamdata];

                updatedPlanparamdata.forEach((paramdata) => {
                    paramdata[`production_actual_count_${selectedProdCountValue}`] = '';

                    if (prod_spin_param_Arr_forFillingFields.includes(paramdata.production_spinning_plan_parameter_name)) {
                        switch (true) {
                            case (paramdata.production_spinning_plan_parameter_name === 'Actual Count'):
                                paramdata[`production_actual_count_${selectedProdCountValue}`] = getPlanProdActualCountApiCall[0].production_actual_count;
                                break;
                            case (paramdata.production_spinning_plan_parameter_name === '40 s conversion factor'):
                                paramdata[`production_actual_count_${selectedProdCountValue}`] = getPlanProdActualCountApiCall[0].count_conversionfactor_ringframe;
                                break;
                            default:
                                paramdata[`production_actual_count_${selectedProdCountValue}`] = 0;
                                break;
                        }
                    } else if (!prod_spin_param_Arr_forFillingFields.includes(paramdata.production_spinning_plan_parameter_name)) {
                        paramdata[`production_actual_count_${selectedProdCountValue}`] = 0;
                    }
                });

                // headerdata.push(selectedProdCountValue)
                setHeaderData(prevArray => [...prevArray, selectedProdCountValue]);
                setPlanParamData(updatedPlanparamdata);
            }

        } catch (error) {
            navigate('/Error');
        } finally {
            setIsLoading(false)
        }
    }

    ////Deleting row from the table
    const deleteFromTable = (header) => {
        try {
            setIsLoading(true)
            let header_data = headerdata;
            let planPrama_data = [...planparamdata];

            var headerIndex = header_data.indexOf(header);
            if (headerIndex !== -1) {
                header_data.splice(headerIndex, 1);
            }
            setHeaderData(header_data);

            planPrama_data.forEach((paramdata) => {
                let keyToDelete = Object.keys(paramdata).find(key => key === `production_actual_count_${header}`);
                if (keyToDelete) {
                    delete paramdata[keyToDelete];
                }
                calculateRowTotal(paramdata);
                calculateRowAvg(planPrama_data[0]);
            });
            setPlanParamData(planPrama_data);
        } catch (error) {
            navigate('/Error')
        }
        setIsLoading(false)
    };


    /////Print Functionality.............................///////////////////////
    const ExportToExcel = async () => {
        if (planparamdata.length !== 0) {
            var jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', editable_cols: [] }

            jsonToExportExcel['filtrKeyValue']['0'] = "Spin Plan Month" + ' : ' + $('#cmb_planmonth').find(":selected").text();
            jsonToExportExcel['filtrKeyValue']['1'] = "Spin Plan Year " + ' : ' + $('#cmb_planyear').find(":selected").text();
            jsonToExportExcel['filtrKeyValue']['2'] = "Spin Plan Start Date" + ' : ' + validateNumberDateInput.current.formatDateToDDMMYYYY(dt_spin_planstartdate);
            jsonToExportExcel['filtrKeyValue']['3'] = "Spin Plan End Date " + ' : ' + validateNumberDateInput.current.formatDateToDDMMYYYY(dt_spin_planenddate);
            jsonToExportExcel['filtrKeyValue']['4'] = "Plant Name" + ' : ' + $('#cmb_spin_plantname').find(":selected").text();
            jsonToExportExcel['filtrKeyValue']['5'] = "Spin Plan Code" + txt_spin_plancode
            jsonToExportExcel['filtrKeyValue']['6'] = "Customer Name " + ' : ' + $('#cmb_customername').find(":selected").text();
            jsonToExportExcel['filtrKeyValue']['7'] = "Spin Plan Desc." + ' : ' + $('#cmb_spin_plantname').find(":selected").text();
            jsonToExportExcel['filtrKeyValue']['8'] = "Spin Plant Status" + ' : ' + $('#cmb_spin_plantstatus').find(":selected").text();
            jsonToExportExcel['filtrKeyValue']['9'] = "Plan Created By " + $('#cmb_spin_plancreatedby').find(":selected").text();
            jsonToExportExcel['filtrKeyValue']['10'] = "Plan Approved By " + $('#cmb_spin_planapprovedby').find(":selected").text();
            jsonToExportExcel['filtrKeyValue']['11'] = "Prod. Count Name " + $('#cmb_spin_plancountname').find(":selected").text();
            jsonToExportExcel['filtrKeyValue']['12'] = "Spin Plan Avg Count" + ' : ' + txt_spin_plan_avg_count;

            let columnKeys = Object.keys(planparamdata[0])
            for (let index = 0; index < columnKeys.length; index++) {
                const element = columnKeys[index];
                jsonToExportExcel.columns.push({ "Headers": element, "accessor": element })
            }

            planparamdata.map((orderDetail, Index) => {
                const keysConvertedOrderDetail = {
                    ...orderDetail,
                    sr_no: Index + 1,
                };
                jsonToExportExcel['allData'][Index] = keysConvertedOrderDetail;
            })

            jsonToExportExcel['headings']['ReportName'] = "Spin Plan Details"
            jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
            jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
            console.log("jsonToExportExcel: ", jsonToExportExcel)
            exlsExp.current.excel(jsonToExportExcel, txt_spin_plancode + "@" + COMPANY_ID)
        } else {
            setErrMsg('Please add at least one material!... ')
            setShowErrorMsgModal(true)
        }
    }


    const updateParameterTable = async (currentPlanParam, event) => {
        const updatedPlanParameterData = [...planparamdata]
        ////Calculating average for spin plan average count
        calculateRowAvg(updatedPlanParameterData[0]);
        setSpinPlanAvgCount(updatedPlanParameterData[0].total)

        let eventId = document.getElementById(event.target.id);

        let enteredValue = event.target.value;
        if (enteredValue != 0 || enteredValue != "" || enteredValue != NaN) {
            delete eventId.parentElement.dataset.tip;
        }
        let validate_Value = validateNumberDateInput.current.decimalNumber(enteredValue, 4);

        let clickedColName = event.target.getAttribute('Headers');
        currentPlanParam[clickedColName] = validate_Value;

        calculateRowTotal(currentPlanParam);
        let dataOfOnChangeField = (keyForData) => {
            let reqData = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === keyForData);
            let keysForReqData = Object.keys(reqData).filter(key => key.startsWith('production_actual_count_'));
            return { reqData, keysForReqData }
        }

        /////////// Only Calculations For Ring Frame  ///////////////

        if (currentPlanParam.production_sub_section_id === 13) {
            //Calculating NO OF MC/S WORKED
            if (currentPlanParam.production_spinning_plan_parameter_name === 'No. of ring frames to be allotted') {
                let responce = dataOfOnChangeField('No. of m/cs working');
                let rpmObj = responce.reqData;
                const summationKeys = responce.keysForReqData;
                summationKeys.forEach(objectKey => {
                    rpmObj[objectKey] = currentPlanParam[objectKey]
                });
                rpmObj.total = currentPlanParam.total;
            }

            ///Calculation for No Of spindles to be alloted
            if (currentPlanParam.production_spinning_plan_parameter_name === 'No. of ring frames to be allotted' || currentPlanParam.production_spinning_plan_parameter_name === 'No. of spindles/machine') {
                let respForNoOfRingFrames = dataOfOnChangeField('No. of ring frames to be allotted');
                let ringFramesData = respForNoOfRingFrames.reqData;
                let summationKeysOfRinfFrames = respForNoOfRingFrames.keysForReqData;

                let respForNoOfSpindles = dataOfOnChangeField('No. of spindles/machine');
                let noOfSpindlesData = respForNoOfSpindles.reqData;
                let summationKeysOfNoOfSpindles = respForNoOfSpindles.keysForReqData;

                const resultObject = {};
                summationKeysOfRinfFrames.forEach((value, index) => {
                    let numericValue1 = ringFramesData[value]
                    let numericValue2 = noOfSpindlesData[value]
                    resultObject[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(numericValue1 * numericValue2), 4)
                });

                let respForNoOfSpindlesToBeAlloted = dataOfOnChangeField('No. of spindles to be allotted');
                let spindlestobeallotedData = respForNoOfSpindlesToBeAlloted.reqData;
                let summationKeysOfSpindlestobeAlloted = respForNoOfSpindlesToBeAlloted.keysForReqData;
                summationKeysOfSpindlestobeAlloted.forEach(objectKey => {
                    spindlestobeallotedData[objectKey] = resultObject[objectKey];
                });
                calculateRowTotal(spindlestobeallotedData);
            }

            ///Calculating Actual spindles to work
            if (currentPlanParam.production_spinning_plan_parameter_name === 'No. of spindles to be allotted' || currentPlanParam.production_spinning_plan_parameter_name === 'Utilisation %') {


                let noOfspindlestobealltedData = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === 'No. of spindles to be allotted');
                let utilisationData = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === 'Utilisation %');

                let keysOfSpindlesAlloted = Object.keys(noOfspindlestobealltedData).filter(key => key.startsWith('production_actual_count_'));
                let keysOfNoOfUtilisation = Object.keys(utilisationData).filter(key => key.startsWith('production_actual_count_'));

                const resultObject = {};
                keysOfSpindlesAlloted.forEach((value, index) => {
                    let numericValue1 = noOfspindlestobealltedData[value]
                    let numericValue2 = utilisationData[value]
                    resultObject[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((numericValue1 * numericValue2 / 100)), 4);
                });

                let actualSpindlestoWorkData = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === 'Actual spindles to work');
                let keysOfActSpindlestoWork = Object.keys(actualSpindlestoWorkData).filter(key => key.startsWith('production_actual_count_'));
                keysOfActSpindlestoWork.forEach(objectKey => {
                    actualSpindlestoWorkData[objectKey] = resultObject[objectKey];
                });
                calculateRowTotal(actualSpindlestoWorkData);
            }

            //Calculating 100% production/spdl./shift in gms.
            if (currentPlanParam.production_spinning_plan_parameter_name === 'Spindle speed in RPM' || currentPlanParam.production_spinning_plan_parameter_name === 'T.P.I.' || currentPlanParam.production_spinning_plan_parameter_name === 'T.M.') {
                let spindleSpeedinRPMData = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === 'Spindle speed in RPM');
                let tPIData = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === 'T.P.I.');
                let tMData = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === 'T.M.');

                let keysOfSpindlesSpeedRPM = Object.keys(spindleSpeedinRPMData).filter(key => key.startsWith('production_actual_count_'));
                let keysOfTPI = Object.keys(tPIData).filter(key => key.startsWith('production_actual_count_'));
                let keysOfTM = Object.keys(tMData).filter(key => key.startsWith('production_actual_count_'));

                let actualCOuntData = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === 'Actual Count');
                let keysOfActualCount = Object.keys(actualCOuntData).filter(key => key.startsWith('production_actual_count_'));

                const resultObject = {};
                keysOfSpindlesSpeedRPM.forEach((value, index) => {
                    let numericValue1 = spindleSpeedinRPMData[value]
                    let numericValue2 = tPIData[value]
                    let numericValue3 = actualCOuntData[value]
                    resultObject[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(7.2 * parseFloat(numericValue1) / parseFloat(numericValue2) / parseFloat(numericValue3), 2)), 4);
                });

                let hundredProdSpdlShiftData = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === '100% production/spdl./shift in gms.');
                let keysOfhundredProdSpdlShift = Object.keys(hundredProdSpdlShiftData).filter(key => key.startsWith('production_actual_count_'));
                keysOfhundredProdSpdlShift.forEach(objectKey => {
                    hundredProdSpdlShiftData[objectKey] = resultObject[objectKey];
                });
                calculateRowTotal(hundredProdSpdlShiftData);
            }

            //Calculating 100% production/spdl./shift in gms.
            if (currentPlanParam.production_spinning_plan_parameter_name === '100% production/spdl./shift in gms.' || currentPlanParam.production_spinning_plan_parameter_name === 'Expected efficiency %') {
                let respForHundProdSpdl = dataOfOnChangeField('100% production/spdl./shift in gms.');
                let hundProdData = respForHundProdSpdl.reqData;
                let KeysOfHundProd = respForHundProdSpdl.keysForReqData;

                let respForEfficiency = dataOfOnChangeField('Expected efficiency %');
                let efficiencyData = respForEfficiency.reqData;
                let KeysOfNoOfEfficiency = respForEfficiency.keysForReqData;

                const resultObject = {};
                KeysOfHundProd.forEach((value, index) => {
                    let numericValue1 = hundProdData[value]
                    let numericValue2 = efficiencyData[value]
                    resultObject[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((parseFloat(numericValue1) * parseFloat(numericValue2) / 100)), 4);
                });

                let respForExpecProd = dataOfOnChangeField('Expected production/spdl.per shift in gms.');
                let expecProducData = respForExpecProd.reqData;
                let KeysOfExpecProduc = respForExpecProd.keysForReqData;

                KeysOfExpecProduc.forEach(objectKey => {
                    expecProducData[objectKey] = resultObject[objectKey];
                });
                calculateRowTotal(expecProducData);

            }

            //Calculating 40 s gms / spl

            if (currentPlanParam.production_spinning_plan_parameter_name === 'Expected efficiency %') {
                let respForExpProdSpdl = dataOfOnChangeField('Expected production/spdl.per shift in gms.');
                let expecProdData = respForExpProdSpdl.reqData;
                let KeysOfExpecProd = respForExpProdSpdl.keysForReqData;

                let respForConvFactor = dataOfOnChangeField('40 s conversion factor');
                let convFactorData = respForConvFactor.reqData;
                let KeysOfConvFac = respForConvFactor.keysForReqData;
                calculateRowTotal(convFactorData);

                const resultObject = {};
                KeysOfExpecProd.forEach((value, index) => {
                    let numericValue1 = expecProdData[value]
                    let numericValue2 = convFactorData[value]
                    resultObject[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(parseFloat(numericValue1) * parseFloat(numericValue2)), 4);
                });

                let respForFortyGMS = dataOfOnChangeField('40 s gms / spl');
                let fortyGMSData = respForFortyGMS.reqData;
                let KeysOfFortyGMS = respForFortyGMS.keysForReqData;

                KeysOfFortyGMS.forEach(objectKey => {
                    fortyGMSData[objectKey] = resultObject[objectKey];
                });
                calculateRowTotal(fortyGMSData);
            }
            //Calculating Total production/shift in kgs. & Total production/Day in kgs.
            if ((currentPlanParam.production_spinning_plan_parameter_name === 'Utilisation %' && currentPlanParam.production_sub_section_id === 13) || (currentPlanParam.production_spinning_plan_parameter_name === 'Expected efficiency %' && currentPlanParam.production_sub_section_id === 13)) {

                let respForActSpindlesToWork = dataOfOnChangeField('Actual spindles to work');
                let spindlesWorkData = respForActSpindlesToWork.reqData;
                let KeysOfSpindlesWork = respForActSpindlesToWork.keysForReqData;

                let respForExpecProduc = dataOfOnChangeField('Expected production/spdl.per shift in gms.');
                let expecProducData = respForExpecProduc.reqData;
                let KeysOfExpecProduc = respForExpecProduc.keysForReqData;

                //Total production/shift in kgs.
                const resultObject = {};
                KeysOfSpindlesWork.forEach((value, index) => {
                    let numericValue1 = spindlesWorkData[value]
                    let numericValue2 = expecProducData[value]
                    resultObject[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(parseFloat(numericValue1) * parseFloat(numericValue2) / 1000), 4);
                });

                let respForTotalProduc = dataOfOnChangeField('Total production/shift in kgs.');
                let totProducData = respForTotalProduc.reqData;
                let KeysOfTotProduc = respForTotalProduc.keysForReqData;

                KeysOfTotProduc.forEach(objectKey => {
                    totProducData[objectKey] = resultObject[objectKey];
                });
                calculateRowTotal(totProducData);

                //Calculating Addition for waste @ 3%
                const resultForAddWaste = {};
                KeysOfTotProduc.forEach((value, index) => {
                    let numericValue1 = totProducData[value]
                    resultForAddWaste[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(parseFloat(numericValue1) * 3 / 100), 4);
                });

                const updatedDataForAddWaste = updatedPlanParameterData.find((updatedData) =>
                    updatedData.production_spinning_plan_parameter_name === 'Addition for waste @ 3%' && updatedData.production_sub_section_id === 13
                );
                if (updatedDataForAddWaste) {
                    let respForAddWaste = dataOfOnChangeField(updatedDataForAddWaste.production_spinning_plan_parameter_name);
                    let addWasteData = respForAddWaste.reqData;
                    let KeysOfAddWaste = respForAddWaste.keysForReqData;

                    KeysOfAddWaste.forEach(objectKey => {
                        addWasteData[objectKey] = resultForAddWaste[objectKey];
                    });
                    calculateRowTotal(addWasteData);
                }

                // setPlanParamData(updatedPlanParameterData);

                //Calculating Total production/Day in kgs. for Ring Frame

                const resultantObject = {};
                KeysOfTotProduc.forEach((value, index) => {
                    let numericValue1 = totProducData[value]
                    resultantObject[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(parseFloat(numericValue1) * 3), 4);
                });

                const updatedData = updatedPlanParameterData.find((updatedData) =>
                    updatedData.production_spinning_plan_parameter_name === 'Total production/Day in kgs.' && updatedData.production_sub_section_id === 13
                );

                if (updatedData) {
                    let respForTotalProducDay = dataOfOnChangeField(updatedData.production_spinning_plan_parameter_name);
                    let totProducDayData = respForTotalProducDay.reqData;
                    let KeysOfTotProducday = respForTotalProducDay.keysForReqData;

                    KeysOfTotProducday.forEach(objectKey => {
                        totProducDayData[objectKey] = resultantObject[objectKey];
                    });
                    calculateRowTotal(totProducDayData);

                    // Calculating Total production per month kgs.

                    const resultForTotProd = {};
                    KeysOfTotProducday.forEach((value, index) => {
                        let numericValue1 = totProducDayData[value]
                        resultForTotProd[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(parseFloat(numericValue1) * 30), 4);
                    });

                    const updatedDataforTot = updatedPlanParameterData.find((updatedData) =>
                        updatedData.production_spinning_plan_parameter_name === 'Total production per month kgs.' && updatedData.production_sub_section_id === 13
                    );

                    if (updatedDataforTot) {
                        let respForTotalProducMonth = dataOfOnChangeField(updatedDataforTot.production_spinning_plan_parameter_name);
                        let totProducMonthData = respForTotalProducMonth.reqData;
                        let KeysOfTotProducMonth = respForTotalProducMonth.keysForReqData;

                        KeysOfTotProducMonth.forEach(objectKey => {
                            totProducMonthData[objectKey] = resultForTotProd[objectKey];
                        });
                        calculateRowTotal(totProducMonthData);
                    }
                }
            }
        }

        /////// Calculations for Speed Frame Starts Here  /////////
        if (currentPlanParam.production_sub_section_id === 13 || currentPlanParam.production_sub_section_id === 12) {
            if ((currentPlanParam.production_spinning_plan_parameter_name === 'Utilisation %') || (currentPlanParam.production_spinning_plan_parameter_name === 'Expected efficiency %')) {

                /////Calculating Total Simplex Production 
                let respForTotProdday = dataOfOnChangeField('Total production/Day in kgs.');
                let totProdPerDayData = respForTotProdday.reqData;
                let KeysOfProdPerDay = respForTotProdday.keysForReqData;

                let respForAddForWaste = dataOfOnChangeField('Addition for waste @ 3%');
                let addFor3Data = respForAddForWaste.reqData;
                let KeysOfAddFor3waste = respForAddForWaste.keysForReqData;

                const resultObject = {};
                KeysOfProdPerDay.forEach((value, index) => {
                    let numericValue1 = totProdPerDayData[value]
                    let numericValue2 = addFor3Data[value]
                    let resultForTotSimplexProd = Math.round(parseFloat(numericValue1) + parseFloat(numericValue2), 0);
                    resultObject[value] = validateNumberDateInput.current.decimalNumber((JSON.stringify(resultForTotSimplexProd)), 4);
                });

                const updatedDataforTotSimplex = updatedPlanParameterData.find((updatedData) =>
                    updatedData.production_spinning_plan_parameter_name === 'Total simplex production required in kgs./day' && updatedData.production_sub_section_id === 12
                );

                if (updatedDataforTotSimplex) {
                    let respForTotSimplex = dataOfOnChangeField(updatedDataforTotSimplex.production_spinning_plan_parameter_name);
                    let totSimplexData = respForTotSimplex.reqData;
                    let KeysOfTotSimplex = respForTotSimplex.keysForReqData;

                    KeysOfTotSimplex.forEach(objectKey => {
                        totSimplexData[objectKey] = resultObject[objectKey];
                    });
                    calculateRowTotal(totSimplexData);

                    //Calculating Addition for waste @ 0.5%
                    const resultForAddForWaste = {};
                    KeysOfTotSimplex.forEach((value, index) => {
                        let numericValue1 = totSimplexData[value]
                        resultForAddForWaste[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(numericValue1 * 0.5 / 100), 4);
                    });

                    let respForAddWaste = dataOfOnChangeField('Addition for waste @ 0.5%');
                    let addWasteData = respForAddWaste.reqData;
                    let keysOfaddWaste = respForAddWaste.keysForReqData;

                    keysOfaddWaste.forEach(objectKey => {
                        addWasteData[objectKey] = resultForAddForWaste[objectKey];
                    });
                    calculateRowTotal(addWasteData);
                }
            }

            //Calculating 100% production per shift in kgs.
            if (currentPlanParam.production_sub_section_id === 12) {
                if (currentPlanParam.production_spinning_plan_parameter_name === 'Hank simplex' || currentPlanParam.production_spinning_plan_parameter_name === 'Spindle speed (RPM)' ||
                    currentPlanParam.production_spinning_plan_parameter_name === 'T.P.I.' || currentPlanParam.production_spinning_plan_parameter_name === 'Expected efficiency %'
                ) {
                    let respForHankSimplex = dataOfOnChangeField('Hank simplex');
                    let hankSimplexData = respForHankSimplex.reqData;
                    let KeysOfHankSimplex = respForHankSimplex.keysForReqData;

                    let respForSpindleSpeed = dataOfOnChangeField('Spindle speed (RPM)');
                    let spindleSpeedData = respForSpindleSpeed.reqData;
                    let KeysOfSpindleSpeed = respForSpindleSpeed.keysForReqData;

                    const updatedDataforTPI = updatedPlanParameterData.find((updatedData) =>
                        updatedData.production_spinning_plan_parameter_name === 'T.P.I.' && updatedData.production_sub_section_id === 12
                    );

                    if (updatedDataforTPI) {
                        let tpiData = updatedDataforTPI;
                        let KeysOfTPI = Object.keys(tpiData).filter(key => key.startsWith('production_actual_count_'));
                        const resultObject = {};
                        KeysOfHankSimplex.forEach((value, index) => {
                            let numericValue1 = hankSimplexData[value]
                            let numericValue2 = spindleSpeedData[value]
                            let numericValue3 = tpiData[value]
                            resultObject[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify((7.2 * numericValue2 / numericValue3 / numericValue1 / 1000) * 160), 4);
                        });

                        let respForHundProd = dataOfOnChangeField('100% production per shift in kgs.');
                        let hundProdData = respForHundProd.reqData;
                        let KeysOfHundProd = respForHundProd.keysForReqData;

                        KeysOfHundProd.forEach(objectKey => {
                            hundProdData[objectKey] = resultObject[objectKey];
                        });
                        calculateRowTotal(hundProdData);

                        // Calculating Expected production/shift in kg
                        const updatedDataForExpProd = await updatedPlanParameterData.find((updatedData) =>
                            updatedData.production_spinning_plan_parameter_name === 'Expected efficiency %' && updatedData.production_sub_section_id === 12
                        );

                        if (updatedDataForExpProd) {
                            let expEffData = updatedDataForExpProd;
                            let KeysOfexpEff = Object.keys(expEffData).filter(key => key.startsWith('production_actual_count_'));

                            const resultant = {};
                            KeysOfHundProd.forEach((value, index) => {
                                let numericValue1 = hundProdData[value]
                                let numericValue2 = expEffData[value]
                                resultant[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(parseFloat(numericValue1) * parseFloat(numericValue2) / 100), 4);
                            });

                            const updatedData = updatedPlanParameterData.find((updatedData) =>
                                updatedData.production_spinning_plan_parameter_name === 'Expected production/shift in kg' && updatedData.production_sub_section_id === 12
                            );

                            if (updatedData) {
                                let respForExpProd = dataOfOnChangeField(updatedData.production_spinning_plan_parameter_name);
                                let expProdData = respForExpProd.reqData;
                                let KeysOfExpProd = respForExpProd.keysForReqData;

                                KeysOfExpProd.forEach(objectKey => {
                                    expProdData[objectKey] = resultant[objectKey];
                                });
                                calculateRowTotal(expProdData);

                                // Calculating Total production/mc/Day in kgs.
                                const resultTotProd = {};
                                KeysOfExpProd.forEach((value, index) => {
                                    let numericValue1 = expProdData[value]
                                    resultTotProd[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(numericValue1 * 3), 4);
                                });

                                const updatedDataTotProdMC = await updatedPlanParameterData.find((updatedData) =>
                                    updatedData.production_spinning_plan_parameter_name === 'Total production/mc/Day in kgs.' && updatedData.production_sub_section_id === 12
                                );

                                if (updatedDataTotProdMC) {
                                    let totProdMCData = updatedDataTotProdMC;
                                    let KeysOftotProdMC = Object.keys(totProdMCData).filter(key => key.startsWith('production_actual_count_'));

                                    KeysOftotProdMC.forEach(objectKey => {
                                        totProdMCData[objectKey] = resultTotProd[objectKey];
                                    });
                                    calculateRowTotal(totProdMCData);

                                    //Calculating Total sf required
                                    let respForTotSimplex = dataOfOnChangeField('Total simplex production required in kgs./day');
                                    let totSimplexData = respForTotSimplex.reqData;
                                    let KeysOfSimpData = respForTotSimplex.keysForReqData;

                                    const resultant = {};
                                    KeysOfSimpData.forEach((value, index) => {
                                        let numericValue1 = totSimplexData[value]
                                        let numericValue2 = totProdMCData[value]
                                        resultant[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(numericValue1 / numericValue2), 4);
                                    });


                                    let respForSfReq = dataOfOnChangeField('Total sf required');
                                    let sfReqData = respForSfReq.reqData;
                                    let keysOfSFreq = respForSfReq.keysForReqData;

                                    keysOfSFreq.forEach(objectKey => {
                                        sfReqData[objectKey] = resultant[objectKey];
                                    });
                                    calculateRowTotal(sfReqData);
                                }
                            }
                        }
                    }
                }
            }
        }

        ////////Calculating Finisher Draw Frames//////////////////
        debugger
        if (currentPlanParam.production_sub_section_id === 11 || 13) {
            if ((currentPlanParam.production_spinning_plan_parameter_name === 'Utilisation %') || (currentPlanParam.production_spinning_plan_parameter_name === 'Expected efficiency %') ||
                (currentPlanParam.production_spinning_plan_parameter_name === 'Hank drawing') || (currentPlanParam.production_spinning_plan_parameter_name === 'Delivery speed in mtrs. per min.')
            ) {
                if (currentPlanParam.production_sub_section_id === 13) {

                    ///calculating Total finisher drawing production required/day in kgs.
                    let respForTotSimplex = dataOfOnChangeField("Total simplex production required in kgs./day");
                    let totSimplexData = respForTotSimplex.reqData;
                    let KeysOfTotSimplex = respForTotSimplex.keysForReqData;

                    let respForAddWaste = dataOfOnChangeField('Addition for waste @ 0.5%');
                    let addWasteData = respForAddWaste.reqData;
                    let keysOfaddWaste = respForAddWaste.keysForReqData;

                    const resultant = {};
                    KeysOfTotSimplex.forEach((value, index) => {
                        let numericValue1 = totSimplexData[value]
                        let numericValue2 = addWasteData[value]
                        resultant[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(parseFloat(numericValue1) + parseFloat(numericValue2)), 4);
                    });

                    let respForTotFin = dataOfOnChangeField('Total finisher drawing production required/day in kgs.');
                    let totFinDrawData = respForTotFin.reqData;
                    let keysOftotFinDraw = respForTotFin.keysForReqData;

                    keysOftotFinDraw.forEach(objectKey => {
                        totFinDrawData[objectKey] = resultant[objectKey];
                    });
                    calculateRowTotal(totFinDrawData);


                    //Creating Add for sliver  waste (0.5%)
                    const resultSilverWaste = {};
                    keysOftotFinDraw.forEach((value, index) => {
                        let numericValue1 = totFinDrawData[value]
                        resultSilverWaste[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(numericValue1 * 0.5 / 100), 4);
                    });

                    let respForSilverWaste = dataOfOnChangeField('Add for sliver  waste (0.5%)');
                    let silverWasteData = respForSilverWaste.reqData;
                    let keysOfSilverWaste = respForSilverWaste.keysForReqData;

                    keysOfSilverWaste.forEach(objectKey => {
                        silverWasteData[objectKey] = resultSilverWaste[objectKey];
                    });
                    calculateRowTotal(silverWasteData);
                }

                if (currentPlanParam.production_sub_section_id === 11) {
                    //Calculating 100% production per machine per shift in kgs.

                    const updatedDataHankDrawing = await updatedPlanParameterData.find((updatedData) =>
                        updatedData.production_spinning_plan_parameter_name === 'Hank drawing' && updatedData.production_sub_section_id === 11
                    );
                    if (updatedDataHankDrawing) {
                        let hankDrawingData = updatedDataHankDrawing;
                        let KeysOfHankDrawing = Object.keys(hankDrawingData).filter(key => key.startsWith('production_actual_count_'));

                        let respForDelSpeed = dataOfOnChangeField('Delivery speed in mtrs. per min.');
                        let delSpeedData = respForDelSpeed.reqData;
                        let keysOfDelSpeed = respForDelSpeed.keysForReqData;

                        const resultant = {};
                        KeysOfHankDrawing.forEach((value, index) => {
                            let numericValue1 = hankDrawingData[value]
                            let numericValue2 = delSpeedData[value]
                            resultant[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(0.28415 * numericValue2 / numericValue1), 4);
                        });

                        let respForProdPerMach = dataOfOnChangeField('100% production per machine per shift in kgs.');
                        let prodPermachData = respForProdPerMach.reqData;
                        let keysOfProdPerMach = respForProdPerMach.keysForReqData;

                        keysOfProdPerMach.forEach(objectKey => {
                            prodPermachData[objectKey] = resultant[objectKey];
                        });
                        calculateRowTotal(prodPermachData);


                        //calculating Expected production per machine per shift in kgs.
                        const updatedDataExpecEff = await updatedPlanParameterData.find((updatedData) =>
                            updatedData.production_spinning_plan_parameter_name === 'Expected efficiency %' && updatedData.production_sub_section_id === 11
                        );

                        if (updatedDataExpecEff) {
                            let expecEffData = updatedDataExpecEff;
                            let keysForExpecEff = Object.keys(expecEffData).filter(key => key.startsWith('production_actual_count_'));

                            const result = {};
                            KeysOfHankDrawing.forEach((value, index) => {
                                let numericValue1 = prodPermachData[value]
                                let numericValue2 = expecEffData[value]
                                result[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(numericValue1 * numericValue2 / 100), 4);
                            });

                            let respForExpProdPerMach = dataOfOnChangeField('Expected production per machine per shift in kgs.');
                            let expprodPermachData = respForExpProdPerMach.reqData;
                            let keysOfexpProdPerMach = respForExpProdPerMach.keysForReqData;

                            keysOfexpProdPerMach.forEach(objectKey => {
                                expprodPermachData[objectKey] = result[objectKey];
                            });
                            calculateRowTotal(expprodPermachData);

                            //Calculating Total production/mc/Day in kgs.
                            const resultToTProdday = {};
                            KeysOfHankDrawing.forEach((value, index) => {
                                let numericValue1 = expprodPermachData[value]
                                resultToTProdday[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(numericValue1 * 3), 4);
                            });

                            const updatedDataTotProdDay = await updatedPlanParameterData.find((updatedData) =>
                                updatedData.production_spinning_plan_parameter_name === 'Total production/mc/Day in kgs.' && updatedData.production_sub_section_id === 11
                            );
                            if (updatedDataTotProdDay) {
                                let totProddataData = updatedDataTotProdDay;
                                let keysFortotProdData = Object.keys(totProddataData).filter(key => key.startsWith('production_actual_count_'));

                                keysFortotProdData.forEach(objectKey => {
                                    totProddataData[objectKey] = resultToTProdday[objectKey];
                                });
                                calculateRowTotal(totProddataData);

                                //Calculating Total Machine required
                                debugger

                                let respForTotFin = dataOfOnChangeField('Total finisher drawing production required/day in kgs.');
                                let totFinDrawData = respForTotFin.reqData;
                                let keysOftotFinDraw = respForTotFin.keysForReqData;

                                const resultant = {};
                                keysOftotFinDraw.forEach((value, index) => {
                                    let numericValue1 = totFinDrawData[value]
                                    let numericValue2 = totProddataData[value]
                                    resultant[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(parseFloat(numericValue1) / parseFloat(numericValue2)), 4);
                                });

                                const updatedDataTotMach = await updatedPlanParameterData.find((updatedData) =>
                                    updatedData.production_spinning_plan_parameter_name === 'Total Machine required' && updatedData.production_sub_section_id === 11
                                );
                                let totMachData = updatedDataTotMach;
                                let keysForTotmach = Object.keys(totMachData).filter(key => key.startsWith('production_actual_count_'));

                                keysForTotmach.forEach(objectKey => {
                                    totMachData[objectKey] = resultant[objectKey];
                                });
                                calculateRowTotal(totMachData);
                            }
                        }
                    }
                }
            }
        }

        //////////Calculations for Beaker Draw Frame//////////
        if ([13, 12, 11, 8].includes(currentPlanParam.production_sub_section_id)) {
            if (['Utilisation %', 'Expected efficiency %', 'Delivery speed in mtrs. per min.'].includes(currentPlanParam.production_spinning_plan_parameter_name) && [11].includes(currentPlanParam.production_sub_section_id)) {
                //Calculation for Total  Breaker production required/day in kgs.
                let respForTotFin = dataOfOnChangeField('Total finisher drawing production required/day in kgs.');
                let totFinDrawData = respForTotFin.reqData;
                let keysOftotFinDraw = respForTotFin.keysForReqData;

                let respForSilverWaste = dataOfOnChangeField('Add for sliver  waste (0.5%)');
                let silverWasteData = respForSilverWaste.reqData;
                let keysOfSilverWaste = respForSilverWaste.keysForReqData;

                const result = {};
                keysOftotFinDraw.forEach((value, index) => {
                    let numericValue1 = totFinDrawData[value]
                    let numericValue2 = silverWasteData[value]
                    result[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(Math.round(parseFloat(numericValue1) + parseFloat(numericValue2), 0)), 4);
                });

                let respForTotBeakProd = dataOfOnChangeField('Total  Breaker production required/day in kgs.');
                let breakProdData = respForTotBeakProd.reqData;
                let keysOfBreakerProd = respForTotBeakProd.keysForReqData;

                keysOfBreakerProd.forEach(objectKey => {
                    breakProdData[objectKey] = result[objectKey];
                });
                calculateRowTotal(breakProdData);
            }

            if (['Hank drawing', 'Delivery speed in mtrs./min', 'Expected efficiency %'].includes(currentPlanParam.production_spinning_plan_parameter_name)) {
                //Calculating 100% production Two delivery per shift in kgs.
                const updatedDataHankDraw = await updatedPlanParameterData.find((updatedData) =>
                    updatedData.production_spinning_plan_parameter_name === 'Hank drawing' && updatedData.production_sub_section_id === 8
                );
                if (updatedDataHankDraw) {
                    let hankDrawData = updatedDataHankDraw
                    let keysOfHankDraw = Object.keys(hankDrawData).filter(key => key.startsWith('production_actual_count_'));

                    let respForDelSpeed = dataOfOnChangeField('Delivery speed in mtrs./min');
                    let delSpeedData = respForDelSpeed.reqData;
                    let keysOfDelSpeed = respForDelSpeed.keysForReqData;

                    const result = {};
                    keysOfHankDraw.forEach((value, index) => {
                        let numericValue1 = hankDrawData[value]
                        let numericValue2 = delSpeedData[value]
                        result[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(0.28415 * numericValue2 / numericValue1 * 2), 4);
                    });

                    let respForProdTwoDel = dataOfOnChangeField('100% production Two delivery per shift in kgs.');
                    let prodTwoDelData = respForProdTwoDel.reqData;
                    let keysProdTwoData = respForProdTwoDel.keysForReqData;

                    keysProdTwoData.forEach(objectKey => {
                        prodTwoDelData[objectKey] = result[objectKey];
                    });
                    calculateRowTotal(prodTwoDelData);

                    //Calculating Expected production Two delivery per shift in kgs.
                    const updatedDataExpEff = await updatedPlanParameterData.find((updatedData) =>
                        updatedData.production_spinning_plan_parameter_name === 'Expected efficiency %' && updatedData.production_sub_section_id === 8
                    );
                    if (updatedDataExpEff) {
                        let expEffData = updatedDataExpEff;
                        let keysOfExpEff = Object.keys(expEffData).filter(key => key.startsWith('production_actual_count_'));

                        const resultForExpecProdDel = {};
                        keysOfExpEff.forEach((value, index) => {
                            let numericValue1 = prodTwoDelData[value]
                            let numericValue2 = expEffData[value]
                            resultForExpecProdDel[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+numericValue1 * numericValue2 / 100), 4);
                        });

                        let respForExpecProdel = dataOfOnChangeField('Expected production Two delivery per shift in kgs.');
                        let expecProdDelData = respForExpecProdel.reqData;
                        let keysExpProdDel = respForExpecProdel.keysForReqData;

                        keysExpProdDel.forEach(objectKey => {
                            expecProdDelData[objectKey] = resultForExpecProdDel[objectKey];
                        });
                        calculateRowTotal(expecProdDelData);


                        //Calculating Total production/mc/Day in kgs.
                        const resultForTotProdDay = {};
                        keysOfExpEff.forEach((value, index) => {
                            let numericValue1 = expecProdDelData[value]
                            resultForTotProdDay[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(numericValue1 * 3), 4);
                        });

                        const updatedDataTotProdDay = await updatedPlanParameterData.find((updatedData) =>
                            updatedData.production_spinning_plan_parameter_name === 'Total production/mc/Day in kgs.' && updatedData.production_sub_section_id === 8
                        );
                        if (updatedDataTotProdDay) {
                            let totProdDayData = updatedDataTotProdDay;
                            let keysOfTotProdDay = Object.keys(totProdDayData).filter(key => key.startsWith('production_actual_count_'));

                            keysOfTotProdDay.forEach(objectKey => {
                                totProdDayData[objectKey] = resultForTotProdDay[objectKey];
                            });
                            calculateRowTotal(totProdDayData);

                            //Calculating Total Machine required
                            let respForTotBeakProd = dataOfOnChangeField('Total  Breaker production required/day in kgs.');
                            let breakProdData = respForTotBeakProd.reqData;
                            let keysOfBreakerProd = respForTotBeakProd.keysForReqData;

                            const resultForTotMachReq = {};
                            keysOfExpEff.forEach((value, index) => {
                                let numericValue1 = breakProdData[value]
                                let numericValue2 = totProdDayData[value]
                                resultForTotMachReq[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+parseFloat(numericValue1) / parseFloat(numericValue2)), 4);
                            });

                            const updatedDataTotMachReq = await updatedPlanParameterData.find((updatedData) =>
                                updatedData.production_spinning_plan_parameter_name === 'Total Machine required' && updatedData.production_sub_section_id === 8
                            );
                            if (updatedDataTotMachReq) {
                                let totMachReqData = updatedDataTotMachReq;
                                let keysOftotMachReq = Object.keys(totMachReqData).filter(key => key.startsWith('production_actual_count_'));

                                keysOftotMachReq.forEach(objectKey => {
                                    totMachReqData[objectKey] = resultForTotMachReq[objectKey];
                                });
                                calculateRowTotal(totMachReqData);
                            }
                        }
                    }
                }
            }
        }

        ///////////Calculations for CARDING starts here //////////////

        if ([13, 12, 11, 8, 7].includes(currentPlanParam.production_sub_section_id)) {
            if (['Utilisation %', 'Expected efficiency %', 'Delivery speed in mtrs. per min.'].includes(currentPlanParam.production_spinning_plan_parameter_name) && [11, 8].includes(currentPlanParam.production_sub_section_id)) {
                ////Calculating Total carding production reqd./day in kgs.
                let respForTotBeakProd = dataOfOnChangeField('Total  Breaker production required/day in kgs.');
                let breakProdData = respForTotBeakProd.reqData;
                let keysOfBreakerProd = respForTotBeakProd.keysForReqData;

                const result = {};
                keysOfBreakerProd.forEach((value, index) => {
                    let numericValue1 = breakProdData[value]
                    result[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(parseFloat(numericValue1) * 0.5 / 100 + parseFloat(numericValue1)), 4);
                });

                let respForTotCardProd = dataOfOnChangeField('Total carding production reqd./day in kgs.');
                let totCardData = respForTotCardProd.reqData;
                let keysOftotCard = respForTotCardProd.keysForReqData;

                keysOftotCard.forEach(objectKey => {
                    totCardData[objectKey] = result[objectKey];
                });
                calculateRowTotal(totCardData);
            }

            if (['Carding Speed', 'Expected efficiency %'].includes(currentPlanParam.production_spinning_plan_parameter_name) && [7].includes(currentPlanParam.production_sub_section_id)) {

                let respForCardingSpeed = dataOfOnChangeField('Carding Speed');
                let cardspeedingData = respForCardingSpeed.reqData;
                let keysOfcardingSpeed = respForCardingSpeed.keysForReqData;

                const result = {};
                keysOfcardingSpeed.forEach((value, index) => {
                    let numericValue1 = cardspeedingData[value]
                    result[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+numericValue1 * 5.36 * 60 / 1000), 4);
                });

                let respForProdRate = dataOfOnChangeField('Production rate (kgs./hrs)');
                let prodRateData = respForProdRate.reqData;
                let keysOfProdRate = respForProdRate.keysForReqData;

                keysOfProdRate.forEach(objectKey => {
                    prodRateData[objectKey] = result[objectKey];
                });
                calculateRowTotal(prodRateData);

                ///Calculating 100% production/card/8 hrs. in kgs.

                const resultant = {};
                keysOfProdRate.forEach((value, index) => {
                    let numericValue1 = prodRateData[value]
                    resultant[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+numericValue1 * 8), 4);
                });

                let respForProdcard = dataOfOnChangeField('100% production/card/8 hrs. in kgs.');
                let prodCardData = respForProdcard.reqData;
                let keysOfProdCard = respForProdcard.keysForReqData;

                keysOfProdCard.forEach(objectKey => {
                    prodCardData[objectKey] = resultant[objectKey];
                });
                calculateRowTotal(prodCardData);

                ////Calculating	Expected production/8 hrs. in kgs.
                const updatedDataExpEff = await updatedPlanParameterData.find((updatedData) =>
                    updatedData.production_spinning_plan_parameter_name === 'Expected efficiency %' && updatedData.production_sub_section_id === 7
                );
                if (updatedDataExpEff) {
                    let expEffData = updatedDataExpEff;
                    let keysOfExpEff = Object.keys(expEffData).filter(key => key.startsWith('production_actual_count_'));

                    const resultForExpecProdDel = {};
                    keysOfProdCard.forEach((value, index) => {
                        let numericValue1 = prodCardData[value]
                        let numericValue2 = expEffData[value]
                        resultForExpecProdDel[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+numericValue1 * numericValue2 / 100), 4);
                    });

                    let respForExpProd = dataOfOnChangeField('Expected production/8 hrs. in kgs.');
                    let expProdData = respForExpProd.reqData;
                    let keysOfExpProd = respForExpProd.keysForReqData;

                    keysOfExpProd.forEach(objectKey => {
                        expProdData[objectKey] = resultForExpecProdDel[objectKey];
                    });
                    calculateRowTotal(expProdData);

                    ///Calculating Total production/machine/Day in kgs.

                    const resultantProdMac = {};
                    keysOfExpProd.forEach((value, index) => {
                        let numericValue1 = expProdData[value]
                        resultantProdMac[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+numericValue1 * 3), 4);
                    });

                    let respForTotProdMach = dataOfOnChangeField('Total production/machine/Day in kgs.');
                    let totProdMachData = respForTotProdMach.reqData;
                    let keysOftotProdMac = respForTotProdMach.keysForReqData;

                    keysOftotProdMac.forEach(objectKey => {
                        totProdMachData[objectKey] = resultantProdMac[objectKey];
                    });
                    calculateRowTotal(totProdMachData);

                    ///calculating Total cards required
                    let respForTotCardProd = dataOfOnChangeField('Total carding production reqd./day in kgs.');
                    let totCardData = respForTotCardProd.reqData;
                    let keysOftotCard = respForTotCardProd.keysForReqData;

                    const resultanttotCarding = {};
                    keysOftotCard.forEach((value, index) => {
                        let numericValue1 = totCardData[value]
                        let numericValue2 = totProdMachData[value]
                        resultanttotCarding[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+numericValue1 / numericValue2), 4);
                    });

                    let respForTotCardsReq = dataOfOnChangeField('Total cards required');
                    let totCardReqData = respForTotCardsReq.reqData;
                    let keysOftotCardReq = respForTotCardsReq.keysForReqData;

                    keysOftotCardReq.forEach(objectKey => {
                        totCardReqData[objectKey] = resultanttotCarding[objectKey];
                    });
                    calculateRowTotal(totCardReqData);

                }
            }
        }

        ////////Blow Room Calculations ////////
        if ([7].includes(currentPlanParam.production_sub_section_id)) {
            if (['Utilisation %', 'Expected efficiency %', 'Delivery speed in mtrs. per min.'].includes(currentPlanParam.production_spinning_plan_parameter_name)) {
                ///calculating Total blow room production reqd(kgs.)/day
                let respForTotCardProd = dataOfOnChangeField('Total carding production reqd./day in kgs.');
                let totCardData = respForTotCardProd.reqData;
                let keysOftotCard = respForTotCardProd.keysForReqData;

                const resultant = {};
                keysOftotCard.forEach((value, index) => {
                    let numericValue1 = totCardData[value]
                    resultant[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+parseFloat(numericValue1) * 7 / 100 + parseFloat(numericValue1)), 4);
                });

                let respForTotalBlow = dataOfOnChangeField('Total blow room production reqd(kgs.)/day');
                let totBlowData = respForTotalBlow.reqData;
                let keysOftotBlow = respForTotalBlow.keysForReqData;

                keysOftotBlow.forEach(objectKey => {
                    totBlowData[objectKey] = resultant[objectKey];
                });
                calculateRowTotal(totBlowData);

                //Calculating Total Bale Required/Day

                const result = {};
                keysOftotBlow.forEach((value, index) => {
                    let numericValue1 = totBlowData[value]
                    result[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+numericValue1 * 1.035 / 165), 4);
                });

                let respForTotalBale = dataOfOnChangeField('Total Bale Required/Day');
                let totBaleData = respForTotalBale.reqData;
                let keysOftotBale = respForTotalBale.keysForReqData;

                keysOftotBale.forEach(objectKey => {
                    totBaleData[objectKey] = result[objectKey];
                });
                calculateRowTotal(totBaleData);

                ////Calculating Total Bale Required 30 days	
                const resultTotBale = {};
                keysOftotBale.forEach((value, index) => {
                    let numericValue1 = totBaleData[value]
                    resultTotBale[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+numericValue1 * 30), 4);
                });

                let respForBale30days = dataOfOnChangeField('Total Bale Required 30 days');
                let bale30DaysData = respForBale30days.reqData;
                let keysOfbale30Days = respForBale30days.keysForReqData;

                keysOfbale30Days.forEach(objectKey => {
                    bale30DaysData[objectKey] = resultTotBale[objectKey];
                });
                calculateRowTotal(bale30DaysData);
            }
        }

        ///Calculations for POST SPINNING
        if (([7].includes(currentPlanParam.production_sub_section_id) && ['Utilisation %', 'Expected efficiency %'].includes(currentPlanParam.production_spinning_plan_parameter_name)) ||
            ([14].includes(currentPlanParam.production_sub_section_id) && ['Winding speed in mtrs./min', 'Expected efficiency %'].includes(currentPlanParam.production_spinning_plan_parameter_name))
        ) {
            ///Calculating Yarn available for cone winding in per day in kgs.
            const updatedDataTOTProd = await updatedPlanParameterData.find((updatedData) =>
                updatedData.production_spinning_plan_parameter_name === 'Total production/Day in kgs.' && updatedData.production_sub_section_id === 13
            );
            if (updatedDataTOTProd) {
                let totProdData = updatedDataTOTProd;
                let keysOfTotProd = Object.keys(totProdData).filter(key => key.startsWith('production_actual_count_'));

                let respForYarn = dataOfOnChangeField('Yarn available for cone winding in per day in kgs. ');
                let yarnData = respForYarn.reqData;
                let keysOfYarnData = respForYarn.keysForReqData;

                keysOfYarnData.forEach(objectKey => {
                    yarnData[objectKey] = totProdData[objectKey];
                });
                calculateRowTotal(yarnData);
            }

            //Calculating 100% production per drum in 8  hrs. in kgs.
            let respForWindSpeed = dataOfOnChangeField('Winding speed in mtrs./min');
            let windSpeedData = respForWindSpeed.reqData;
            let keysOfwindSpeed = respForWindSpeed.keysForReqData;

            let actualCOuntData = updatedPlanParameterData.find(rowData => rowData.production_spinning_plan_parameter_name === 'Actual Count');
            let keysOfActualCount = Object.keys(actualCOuntData).filter(key => key.startsWith('production_actual_count_'));

            const resultOfProdPerDrum = {};
            keysOfActualCount.forEach((value, index) => {
                let numericValue1 = windSpeedData[value]
                let numericValue2 = actualCOuntData[value]
                resultOfProdPerDrum[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+parseFloat(numericValue1) * 0.284 / parseFloat(numericValue2)), 4);
            });

            let respForProdPerDrum = dataOfOnChangeField('100% production per drum in 8  hrs. in kgs.');
            let prodPerDrumData = respForProdPerDrum.reqData;
            let keysOfProdPerDrum = respForProdPerDrum.keysForReqData;

            keysOfProdPerDrum.forEach(objectKey => {
                prodPerDrumData[objectKey] = resultOfProdPerDrum[objectKey];
            });
            calculateRowTotal(prodPerDrumData);
            // setPlanParamData(updatedPlanParameterData);

            //Calculating Expected production/drum in  8 hrs. in kgs.
            const updatedDataExpEffic = await updatedPlanParameterData.find((updatedData) =>
                updatedData.production_spinning_plan_parameter_name === 'Expected efficiency %' && updatedData.production_sub_section_id === 14
            );
            if (updatedDataExpEffic) {
                let effData = updatedDataExpEffic;
                let keysOfEff = Object.keys(effData).filter(key => key.startsWith('production_actual_count_'));

                const resultOfExpProd = {};
                keysOfEff.forEach((value, index) => {
                    let numericValue1 = prodPerDrumData[value]
                    let numericValue2 = effData[value]
                    resultOfExpProd[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+parseFloat(numericValue1) * parseFloat(numericValue2) / 100), 4);
                });

                let respForProdDrum = dataOfOnChangeField('Expected production/drum in  8 hrs. in kgs.');
                let prodDrumData = respForProdDrum.reqData;
                let keysOfProdDrum = respForProdDrum.keysForReqData;

                keysOfProdDrum.forEach(objectKey => {
                    prodDrumData[objectKey] = resultOfExpProd[objectKey];
                });
                calculateRowTotal(prodDrumData);

                //Calculating Expected production/machine in  8 hrs. in kgs.
                const result = {};
                keysOfProdDrum.forEach((value, index) => {
                    let numericValue1 = prodDrumData[value]
                    result[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+parseFloat(numericValue1) * 72), 4);
                });

                let respForProdMach = dataOfOnChangeField('Expected production/machine in  8 hrs. in kgs.');
                let prodMachData = respForProdMach.reqData;
                let keysOfProdMach = respForProdMach.keysForReqData;

                keysOfProdMach.forEach(objectKey => {
                    prodMachData[objectKey] = result[objectKey];
                });
                calculateRowTotal(prodMachData);

                ///Calculating Total production/Day in kgs. Autoconner spinning
                const resultant = {};
                keysOfProdMach.forEach((value, index) => {
                    let numericValue1 = prodMachData[value]
                    resultant[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+numericValue1 * 3), 4);
                });
                const updatedDataTotProdKg = await updatedPlanParameterData.find((updatedData) =>
                    updatedData.production_spinning_plan_parameter_name === 'Total production/Day in kgs.' && updatedData.production_sub_section_id === 14
                );
                if (updatedDataTotProdKg) {
                    let totProdKgData = updatedDataTotProdKg;
                    let keystotProdKg = Object.keys(totProdKgData).filter(key => key.startsWith('production_actual_count_'));

                    keystotProdKg.forEach(objectKey => {
                        totProdKgData[objectKey] = resultant[objectKey];
                    });
                    calculateRowTotal(totProdKgData);

                    ////Calculating Total Machine required
                    let respForYarn = dataOfOnChangeField('Yarn available for cone winding in per day in kgs. ');
                    let yarnData = respForYarn.reqData;
                    let keysOfYarnData = respForYarn.keysForReqData;

                    const resultTotMachReq = {};
                    keysOfProdMach.forEach((value, index) => {
                        let numericValue1 = yarnData[value]
                        let numericValue2 = totProdKgData[value]
                        resultTotMachReq[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+parseFloat(numericValue1) / parseFloat(numericValue2)), 4);
                    });

                    const updatedDataMachReq = await updatedPlanParameterData.find((updatedData) =>
                        updatedData.production_spinning_plan_parameter_name === 'Total machine required' && updatedData.production_sub_section_id === 14
                    );
                    if (updatedDataMachReq) {
                        let machReqData = updatedDataMachReq;
                        let keystoMachReq = Object.keys(machReqData).filter(key => key.startsWith('production_actual_count_'));

                        keystoMachReq.forEach(objectKey => {
                            machReqData[objectKey] = resultTotMachReq[objectKey];
                        });
                        calculateRowTotal(machReqData);
                    }

                    ///Calculating Total Bags / shift (45.36kg Packing)
                    const resultTotBags = {};
                    keysOfYarnData.forEach((value, index) => {
                        let numericValue1 = yarnData[value]
                        resultTotBags[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+numericValue1 * 0.995 / 3 / 45.36), 4);
                    });

                    let respForTotBags = dataOfOnChangeField('Total Bags / shift (45.36kg Packing)');
                    let totBagData = respForTotBags.reqData;
                    let keysOfTotBag = respForTotBags.keysForReqData;

                    keysOfTotBag.forEach(objectKey => {
                        totBagData[objectKey] = resultTotBags[objectKey];
                    });
                    calculateRowTotal(totBagData);

                    ///Calculating Total Bags / day (45.36kg Packing)
                    const resultTotDay = {};
                    keysOfTotBag.forEach((value, index) => {
                        let numericValue1 = totBagData[value]
                        resultTotDay[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+numericValue1 * 3), 4);
                    });

                    let respForTotday = dataOfOnChangeField('Total Bags / day (45.36kg Packing)');
                    let totDayData = respForTotday.reqData;
                    let keysOfTotDay = respForTotday.keysForReqData;

                    keysOfTotDay.forEach(objectKey => {
                        totDayData[objectKey] = resultTotDay[objectKey];
                    });
                    calculateRowTotal(totDayData);

                    ////Calculating Total Bags in 30 days (45.36kg Packing)
                    const resultTot_Bags_30Days = {};
                    keysOfTotDay.forEach((value, index) => {
                        let numericValue1 = totDayData[value]
                        resultTot_Bags_30Days[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+numericValue1 * 3), 4);
                    });

                    let respForTot_bags_30Days = dataOfOnChangeField('Total Bags in 30 days (45.36kg Packing)');
                    let totbags_30DaysData = respForTot_bags_30Days.reqData;
                    let keysOfTotbags_30Days = respForTot_bags_30Days.keysForReqData;

                    keysOfTotbags_30Days.forEach(objectKey => {
                        totbags_30DaysData[objectKey] = resultTot_Bags_30Days[objectKey];
                    });
                    calculateRowTotal(totbags_30DaysData);

                    ////Calculating TOTAL WEIGHT- IN 30 DAYS

                    const resultTotWeight = {};
                    keysOfTotbags_30Days.forEach((value, index) => {
                        let numericValue1 = totbags_30DaysData[value]
                        resultTotWeight[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+numericValue1 * 45.36), 4);
                    });

                    let respForTotWeight = dataOfOnChangeField('TOTAL WEIGHT- IN 30 DAYS ');
                    let totweightData = respForTotWeight.reqData;
                    let keysOfTotWeight = respForTotWeight.keysForReqData;

                    keysOfTotWeight.forEach(objectKey => {
                        totweightData[objectKey] = resultTotWeight[objectKey];
                    });
                    calculateRowTotal(totweightData);

                    ////Calculating CONTAINER (450 BAG  X 45.36 = 20412KG
                    const resultContainer = {};
                    keysOfTotWeight.forEach((value, index) => {
                        let numericValue1 = totweightData[value]
                        resultContainer[value] = validateNumberDateInput.current.decimalNumber(JSON.stringify(+parseFloat(numericValue1) / 20412), 4);
                    });

                    let respForCont = dataOfOnChangeField('CONTAINER (450 BAG  X 45.36 = 20412KG');
                    let contData = respForCont.reqData;
                    let keysOfCont = respForCont.keysForReqData;

                    keysOfCont.forEach(objectKey => {
                        contData[objectKey] = resultContainer[objectKey];
                    });
                    calculateRowTotal(contData);
                }
            }

        }

        const inputs = document.querySelectorAll('#spinplantable1 input[type="text"]:disabled');
        inputs.forEach(input => {
            const value = parseFloat(input.value);

            if (isNaN(value) || value === Infinity) {
                input.value = 0;
            }
        });

        const currentObjIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        updatedPlanParameterData[currentObjIndex] = currentPlanParam
        setPlanParamData(updatedPlanParameterData);
    }

    const calculateRowTotal = (addParameterData) => {
        const summationKeys = Object.keys(addParameterData).filter(key => key.startsWith('production_actual_count_'));
        const sum = summationKeys.reduce((accumulator, key) => {
            const value = parseFloat(addParameterData[key]) || 0;
            return accumulator + value;
        }, 0);

        addParameterData.total = validateNumberDateInput.current.decimalNumber(JSON.stringify(sum), 4);
        return addParameterData;
    }

    const calculateRowAvg = (currentPlanParam) => {
        let actualCount_Data = planparamdata.find(rowData => rowData.production_spinning_plan_parameter_name === 'Actual Count');
        let actualCount_Keys = Object.keys(actualCount_Data).filter(key => key.startsWith('production_actual_count_'));
        let currentParamData = calculateRowTotal(actualCount_Data);
        let actualCountData_AVG = ((currentParamData.total) / actualCount_Keys.length);
        currentPlanParam.total = validateNumberDateInput.current.decimalNumber(JSON.stringify(actualCountData_AVG), 4);
        return currentPlanParam;
    }

    ///Checking and unchecking all checkboxes
    $(document).on('change', '#checkAll', function () {
        // Set all checkboxes with class "productCountCheckBox" to the same state as "Check All" checkbox
        $('.productCountCheckBox').prop('checked', $(this).prop('checked'));
    });
    $(document).on('change', '.productCountCheckBox', function () {
        // If any individual checkbox is unchecked, uncheck the "Check All" checkbox
        if (!$(this).prop('checked')) {
            $('#checkAll').prop('checked', false);
        }
    });

    const validateInputs = () => {
        debugger
        const inputs = document.querySelectorAll('#spinplantable1 input[type="text"]:not([disabled])');
        let isValid = true;
        for (const input of inputs) {
            const value = parseFloat(input.value);
            if (value === undefined || value === '' || isNaN(value) || value === 0) {
                input.focus();
                input.parentElement.dataset.tip = "Please enter a valid value...";
                isValid = false;
                break
            } else {
                delete input.parentElement.dataset.tip;
            }
        };
        return isValid;
    };


    const saveSpinPlanMasterData = async () => {

        const validate_form = await validate.current.validateForm("spinplanmasterID");
        const validate_table = validateInputs();
        if (validate_form && validate_table) {
            try {
                setIsLoading(true)
                let jsonData = {
                    TransHeaderData: {}, TransDetailData: [], 'commonIds': {
                        'company_id': COMPANY_ID
                    }, saveProdCountKey: []
                }

                //Spin Plan Data
                const spinPlanData = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    spinn_plan_id: spinPlanId === "" ? 0 : spinPlanId,
                    financial_year: FINANCIAL_SHORT_NAME,
                    plant_id: cmb_spin_plantname,
                    spinn_plan_code: txt_spin_plancode,
                    spinn_plan_month: cmb_planmonth,
                    spinn_plan_year: cmb_planyear,
                    spinn_plan_start_date: dt_spin_planstartdate,
                    spinn_plan_end_date: dt_spin_planenddate,
                    customer_id: cmb_customername,
                    spinn_plan_created_by_id: cmb_spin_plancreatedby,
                    spinn_plan_approved_by_id: cmb_spin_planapprovedby,
                    spinn_plan_description: txt_spin_plantremark,
                    spinn_plan_avg_count: txt_spin_plan_avg_count,
                    spinn_plan_status: cmb_spin_plantstatus,
                    created_by: UserName,
                    modified_by: UserName,
                    spinn_plan_approved_by_id: keyForViewUpdate === 'approve' ? cmb_spin_planapprovedby : null
                }
                jsonData.TransHeaderData = spinPlanData;

                if (!is_approve) {
                    ///Spin plan param Data
                    jsonData.TransDetailData = planparamdata;

                    let selectedCheckboxes = [];
                    $('.productCountCheckBox:checked').each(function () {
                        selectedCheckboxes.push($(this).val());
                    });

                    let selected_prod_count_keys = [];
                    selectedCheckboxes.forEach(function (resultArray) {
                        let foundObject = prodcountOptions.find(item => item.field_name === resultArray);
                        if (foundObject) {
                            selected_prod_count_keys.push(foundObject.production_count_id);
                        }
                    });

                    let excluded_array = ['Sr. No', 'Section', 'Particular', 'Total'];
                    let header_data = [...headerdata];
                    let saveKeys_array = header_data.filter(item => !excluded_array.includes(item));

                    // prod_count_keys
                    var combinedObject = {};
                    for (var i = 0; i < saveKeys_array.length; i++) {
                        combinedObject[saveKeys_array[i]] = selected_prod_count_keys[i];
                    }

                    jsonData.saveProdCountKey.push(combinedObject);
                    console.log(jsonData);
                }
                planparamdata.map(plan_data => {
                    console.log(plan_data.total);
                })
                const formData = new FormData();
                formData.append(`SppiningProdAndPlantProdData`, JSON.stringify(jsonData))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                try {
                    const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtSpinningProdSpinnPlanMaster/FnAddUpdateRecord/${keyForViewUpdate}`, requestOptions)
                    const apiResponse = await apiCall.json();

                    if (apiResponse.success === 1) {
                        const evitCache = comboDataAPiCall.current.evitCache();
                        console.log(evitCache);
                        setSuccMsg(apiResponse.message);
                        setShowSuccessMsgModal(true);
                    } else if (apiResponse.success === 0) {
                        setErrMsg(apiResponse.error)
                        setShowErrorMsgModal(true);
                    }
                } catch (error) {
                    console.log("error", error)
                }
            } catch (error) {
                navigate('/Error');
            } finally {
                setIsLoading(false)
            }
        }

    }

    ////Validations starts here................
    const validateFields = () => {
        validate.current.validateFieldsOnChange('spinplanmasterID');
    }

    ///Validating dates
    const dateValidation = async (e) => {
        debugger
        let start_Date = $('#dt_spin_planstartdate').val();
        let end_Date = $('#dt_spin_planenddate').val();
        if (start_Date === end_Date) {
            $('#error_dt_spin_planenddate').text('Please change end date...');
            $('#error_dt_spin_planenddate').show()
        } else {
            $('#error_dt_spin_planenddate').hide();
        }
        setSpinPlanEndDate(e.target.value);
    };

    var expanded = false;

    // Hide show customer order no's
    const FnToggleProdCountList = () => {
        var checkboxes = document.getElementById("prodcount-order-ul");
        const items = document.querySelectorAll(".prodcount_order_no");
        if (!expanded) {
            if (items.length !== 0) {
                checkboxes.style.display = "block";
                expanded = true;
            }
        } else {
            checkboxes.style.display = "none";
            expanded = false;
        }
    }


    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <ExcelExport ref={exlsExp} />
            <FrmValidations ref={validate} />

            {isLoading ?
                <div className="spinner-overlay"  >
                    <div className="spinner-container">
                        <CircularProgress color="primary" />
                        <span id="spinner_text" className="text-dark">Loading...</span>
                    </div>
                </div> :
                null}

            <DashboardLayout>
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Spin Plan Master {action_Label} </label>
                    </div>
                    <form id="spinplanmasterID">
                        <div className='row p-1'>
                            {/* 1 st Column */}
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plan Month <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_planmonth" className="form-select form-select-sm" value={cmb_planmonth} onChange={(e) => { setSpinPlanMonth(e.target.value); validateFields() }}>
                                            <option value="">Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_planmonth" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Plan Year <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_planyear" className="form-select form-select-sm" value={cmb_planyear} onChange={(e) => { setSpinPlanYear(e.target.value); validateFields() }}>
                                            <option value="">Select</option>
                                            {spinplanyearOptions.length !== 0 ? (
                                                <>
                                                    {spinplanyearOptions?.map(year => (
                                                        <option value={year.field_id}>{year.field_name}</option>
                                                    ))}
                                                </>
                                            ) : null

                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_planyear" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plan Start Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="date" id='dt_spin_planstartdate' className="erp_input_field" value={dt_spin_planstartdate} onChange={(e) => { setSpinPlanStartDate(e.target.value); fillAutoNo(); validateFields() }} />
                                        <MDTypography variant="button" id="error_dt_spin_planstartdate" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plan End Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="date" id='dt_spin_planenddate' className="erp_input_field" value={dt_spin_planenddate} onChange={(e) => { dateValidation(e); fillAutoNo(); validateFields(); }} />
                                        <MDTypography variant="button" id="error_dt_spin_planenddate" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            {/* 2 nd Column */}
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plant Name <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_spin_plantname" className="form-select form-select-sm" value={cmb_spin_plantname} onChange={(e) => { setSpinPlantName(e.target.value); fillAutoNo(); renderTableData(e.target.value); }}>
                                            <option value="" disabled>Select</option>
                                            {plantOptions.length !== 0 ? (
                                                <>{plantOptions?.map(plant => (
                                                    <option value={plant.field_id}>{plant.plant_name}</option>
                                                ))} </>
                                            ) : null
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_spin_plantname" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plan Code <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" className="erp_input_field" id="txt_spin_plancode" maxLength="5" value={txt_spin_plancode} disabled onInput={() => validateFields()} />
                                        <MDTypography variant="button" id="error_txt_spin_plancode" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Customer Name </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_customername" className="form-select form-select-sm" value={cmb_customername} optional='optional' onChange={(e) => { setSpinPlanCustomerName(e.target.value); validateFields() }} >
                                            <option value="">Select</option>
                                            {customerOptions.length !== 0 ? (
                                                <>{customerOptions?.map(customer => (
                                                    <option value={customer.field_id}>{customer.field_name}</option>
                                                ))}
                                                </>
                                            ) : null
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_customername" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plan Description <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" rows={1} id="txt_spin_plantremark" className="erp_input_field form-control" maxlength="255" value={txt_spin_plantremark} onInput={() => validateFields()} onChange={(e) => setSpinRemark(e.target.value)} />
                                        <MDTypography variant="button" id="error_txt_spin_plantremark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                            </div>

                            {/* 3rd Column */}
                            <div className='col-sm-4 erp_form_col_div'>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plant Status <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_spin_plantstatus" className="form-select form-select-sm" value={cmb_spin_plantstatus} onChange={(e) => { setSpinPlantStatus(e.target.value); validateFields() }} >
                                            <option value={" "} disabled>Select</option>
                                            <option value="Pending" >Pending</option>
                                            <option value="WIP" >WIP</option>
                                            <option value="completed" >Completed</option>
                                            <option value="preclosed" >Preclosed</option>
                                            <option value="Cancelled" >Cancelled</option>
                                            <option value="Approved" >Approved</option>
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_spin_plantstatus" className="erp_validation mt-2" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plan Created By <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_spin_plancreatedby" className="form-select form-select-sm" value={cmb_spin_plancreatedby} onChange={(e) => { setPlanCreatedBy(e.target.value); validateFields() }} >
                                            <option value="" disabled>Select</option>
                                            <>
                                                {spinplanCreatedByOptions.length !== 0 ? (
                                                    <>{spinplanCreatedByOptions?.map(employee => (
                                                        <option value={employee.field_id}>{employee.field_name}</option>
                                                    ))}
                                                    </>
                                                ) : null
                                                }
                                            </>
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_spin_plancreatedby" className="erp_validation mt-2" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div id='planAppRowId' style={cmb_spin_plantstatus === 'Approved' ? null : { display: "none" }} >
                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Approved By <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <select id="cmb_spin_planapprovedby" className="form-select form-select-sm" value={cmb_spin_planapprovedby} onChange={(e) => { setPlanApprovedBy(e.target.value); validateFields() }} optional={cmb_spin_plantstatus === 'Approved' ? null : "optional"}>
                                                <option value="" disabled>Select</option>
                                                <>
                                                    {spinplanApprovedByOptions.length !== 0 ? (
                                                        <>{spinplanApprovedByOptions?.map(employee => (
                                                            <option value={employee.field_id}>{employee.field_name}</option>
                                                        ))}
                                                        </>
                                                    ) : null
                                                    }
                                                </>
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_spin_planapprovedby" className="erp_validation mt-2" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Approved Remark <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" rows={1} id="txt_spin_plantAppRemark" className="erp_input_field form-control" maxlength="255" value={txt_spin_plantAppRemark} onInput={() => validateFields()} onChange={(e) => setSpinApproveRemark(e.target.value)} optional={cmb_spin_plantstatus === 'Approved' ? null : "optional"} />
                                            <MDTypography variant="button" id="error_txt_spin_plantAppRemark" className="erp_validation mb-2" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>
                                <div id='planCancelRemarkId' style={cmb_spin_plantstatus === 'Cancelled' ? null : { display: "none" }}>
                                    <div className="row" >
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Cancelled Remark <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" rows={1} id="txt_spin_plantCancelRemark" className="erp_input_field form-control" maxlength="255" value={txt_spin_plantCancelRemark} onInput={() => validateFields()} onChange={(e) => setSpinCancelRemark(e.target.value)} optional={cmb_spin_plantstatus === 'Cancelled' ? null : "optional"} />
                                            <MDTypography variant="button" id="error_txt_spin_plantCancelRemark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </form>
                    <hr />
                    <div className='row'>
                        <div className='col-sm-3 erp_form_col_div'>
                            <div className='erp-form-label-lg'>Spin Plan Parameter Master</div>
                        </div>
                        <div className='col-sm-4 erp_form_col_div' id='spin_plancountname'>
                            <div className='row'>
                                <div className="col-auto">
                                    <Form.Label className="erp-form-label">Prod. Count Name<span className="required">*</span></Form.Label>
                                </div>
                                <div className="col-auto" id='cmb_spin_plancountname'>
                                    <div className="form-select form-select-sm"
                                        onClick={() => FnToggleProdCountList()}
                                        data-optional="optional">
                                        {'Select'}
                                    </div>
                                    <ul className="list-items" id="prodcount-order-ul" >
                                        <li className="item prodcount_order_no">
                                            <span className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    id="checkAll"
                                                    name="productCountCheckBox"
                                                    value={selectAllCheckBoxes}
                                                    className="erp_radio_button filterCheckBox productCountCheckBox"
                                                    optional='optional'
                                                />
                                            </span>
                                            <span className="item-text">All</span>
                                        </li>
                                        {prodcountOptions.length !== 0 ?
                                            prodcountOptions.map((prodcount, index) =>
                                                <li className="item prodcount_order_no" key={index}>
                                                    <span className="checkbox">
                                                        <input
                                                            type="checkbox"
                                                            name="productCountCheckBox"

                                                            value={prodcount.field_name}
                                                            className="erp_radio_button productCountCheckBox"
                                                            optional='optional'
                                                        />
                                                    </span>
                                                    <span className="item-text">{prodcount.field_name}</span>
                                                </li>
                                            ) : null
                                        }
                                    </ul>


                                    <MDTypography variant="button" id="error_cmb_spin_plancountname" className="erp_validation mt-2" fontWeight="regular" color="error" >
                                    </MDTypography>
                                </div>

                                <MDButton className="col-auto erp-gb-button" variant="button" fontWeight="regular" onClick={() => addNewColumn()}>
                                    Add Count
                                </MDButton>

                            </div>
                        </div>


                        <div className='col-sm-4 erp_form_col_div'>
                            <div className="row">
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label">Spin Plan Avg Count</Form.Label>
                                </div>
                                <div className="col-sm-3">
                                    <Form.Control type="text" id='txt_spin_plan_avg_count' className="erp_input_field mt-1" value={txt_spin_plan_avg_count} disabled />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='row mt-1'>
                        <div className='col-auto' style={{ height: "470px;" }}>
                            {renderSpinPlanTable}
                        </div>
                    </div>
                </div >
                <div className="erp_frm_Btns">
                    <MDButton className="erp-gb-button ms-2" variant="button" id='back_Button' fontWeight="regular" onClick={() => navigate('/Masters/MProductionSpinPlan/MProductionSpinPlanListing')}>Back</MDButton>
                    <MDButton type="submit" id="action_Button" className="erp-gb-button ms-2" variant="button"
                        onClick={() => saveSpinPlanMasterData()} fontWeight="regular">{action_Button}</MDButton>
                    <MDButton className="erp-gb-button ms-2" variant="button" id='print_Button' fontWeight="regular" onClick={() => ExportToExcel()}>Print</MDButton>
                </div >

                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
            </DashboardLayout>
        </>
    )
}

export default FrmSpinPlanEntry