import React, { useState, useRef, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';
import html2pdf from 'html2pdf.js';

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table, Button } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Datatable from "components/DataTable";

// React icons
import { MdDelete, MdRefresh } from "react-icons/md";
import { AiOutlineSchedule } from "react-icons/ai";

//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ExcelExport from "Features/Exports/ExcelExport";

// PopUp Modal Display Components.
import DocumentF from "Features/Document";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import FrmDepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";
import FrmCity from "FrmGeneric/MCity/FrmCity";
import { CircularProgress } from "@mui/material";
import MailSentModal from "components/Modals/MailSentModal";
import { FiDownload } from "react-icons/fi";
import { renderToString } from "react-dom/server";
import SalesOrderInvoice from "FrmGeneric/Invoice/SalesOrderInvoice";
import FrmMBOMSRFilter from "FrmGeneric/MBOMFilter/FrmMBOMSRFilter";
import ConfigConstants from "assets/Constants/config-constant";
import { DatatableWithId } from "components/DataTable";

function SalesOrderProjectEntry() {
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, SHORT_FINANCIAL_YEAR, UserId, UserName, COMPANY_NAME } = configConstants;
    const { state } = useLocation()
    const { idList, keyForViewUpdate, compType } = state || {};

    // For upload documents
    let docGroup = "Sales";
    const ActionType = () => {
        switch (keyForViewUpdate) {
            case 'approve':
                return '(Approval)';
            case 'update':
                return '(Modification)';
            case 'view':
                return '(View)';
            case 'copy':
                return '(Copy Sales Order)';
            default:
                return '(Generate)';
        }
    };
    const [isApprove, setIsApprove] = useState(false);

    // Required columns for the sales order details table.
    let requiredColsForBomFilter = ['sr_no', 'product_sr_name', 'product_sr_tech_spect', 'process_duration', 'product_sr_std_hours', 'product_sr_std_price',
        'product_sr_hsn_sac_code', 'product_sr_hsn_sac_rate', 'product_rm_std_weight', 'product_sr_quantity', 'material_weight', 'expected_schedule_start_date', 'expected_schedule_end_date',
        'quotation_rate', 'product_sr_std_price', 'material_discount_percent', 'material_discount_amount', 'material_basic_amount', 'material_taxable_amount',
        'material_cgst_percent', 'material_cgst_total', 'material_sgst_percent', 'material_sgst_total', 'material_igst_percent', 'material_igst_total',
        'material_total_amount', 'material_schedule_date',
        'product_sr_id', 'product_rm_stock_unit_id', 'product_rm_packing_id', 'product_sr_hsn_sac_code_id',
    ]

    // Column Names for sales order table.
    let requiredOrderTblCols = ['sr_no', 'material_name', 'material_technical_specification', 'material_unit_name', 'material_packing',
        'mateiral_hsn_code', 'mateiral_hsn_rate', 'mateiral_std_weight', 'product_sr_quantity', 'material_weight', 'expected_schedule_start_date', 'expected_schedule_end_date',
        'quotation_rate', 'mateiral_rate', 'material_discount_percent', 'material_discount_amount', 'material_basic_amount', 'material_taxable_amount',
        'material_cgst_percent', 'material_cgst_total', 'material_sgst_percent', 'material_sgst_total', 'material_igst_percent', 'material_igst_total',
        'material_total_amount', 'material_schedule_date',
        'material_id', 'product_rm_stock_unit_id', 'product_rm_packing_id', 'product_sr_hsn_sac_code_id',
    ]

    // For Taxation Summary.
    let taxationSummary = [];
    const currentDate = new Date().toISOString().split('T')[0];

    // For today get date
    const FnFormattedDateForInputField = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const FnGetExpectedScheduleGraceDate = (afterDays) => {
        let currentDate = new Date();
        const dtAfterGraceDays = new Date(currentDate);
        dtAfterGraceDays.setDate(currentDate.getDate() + afterDays);

        const year = dtAfterGraceDays.getFullYear();
        const month = String(dtAfterGraceDays.getMonth() + 1).padStart(2, '0');
        const day = String(dtAfterGraceDays.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const todayDate = FnFormattedDateForInputField(new Date());

    // For navigate
    const navigator = useNavigate();
    const validate = useRef();
    const comboDataFunc = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const importFile = useRef(null)
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();

    // Combo box options.
    const [companyBranchesOpts, setCompanyBranchesOpts] = useState([]);
    const [companyBrancheCityOpts, setCompanyBrancheCityOpts] = useState([]);
    const [salesOrdTypesOpts, setSalesOrdTypesOpts] = useState([]);
    const [stateOpts, setStateOpts] = useState([]);
    const [customerOpts, setCustomerOpts] = useState([]);
    const [customerCityOpts, setCustomerCityOpts] = useState([]);
    const [consigneeOpts, setConsigneeOpts] = useState([]);
    const [consigneeCityOpts, setConsigneeCityOpts] = useState([]);
    const [quotationOpts, setQuotationOpts] = useState();
    const [deptOpts, setDeptOpts] = useState([]);
    const [approveByOpts, setApproveByOpts] = useState([]);
    const [freightHSNOpts, setFreightHSNOpts] = useState([]);
    const [agentOpts, setAgentOpts] = useState([]);
    const [productUnitOpts, setProductUnitOpts] = useState([]);
    const [companyBranchOpts, setCompanyBranchOpts] = useState([]);
    const [transporterOpts, setTransporterOpts] = useState([]);

    // Header section form fields.
    const [cmb_companyBranchId, setCompanyBranchId] = useState();
    const [cmb_companyBranchStateId, setCompanyBranchStateId] = useState();
    const [cmb_companyBranchCityId, setCompanyBranchCityId] = useState();
    const [sales_order_master_transaction_id, setSalesOrderMasterTransactionId] = useState(0)
    const [rb_sales_order_creation_type, setSalesOrdCreationType] = useState("M");
    const [cmb_sales_order_type_id, setSalesOrderTypeId] = useState();
    const [txt_sales_order_no, setSalesOrdNo] = useState(0);
    const [txt_sales_order_version, setSalesOrdVer] = useState(1);
    const [dt_sales_order_date, setSalesOrdDate] = useState(todayDate);
    const [rb_sales_order_life, setSalesOrdLife] = useState('C');
    // customer details form fields
    const [cmb_customer_id, setCustomerId] = useState('');
    // const [cmb_customer_order_no, setCustomerOrderNo] = useState();
    const [txt_customer_order_no, setCustomerOrderNo] = useState('');
    const [cmb_sales_quotation_no, setSalesQuotationNo] = useState('');
    const [dt_sales_quotation_date, setSalesQuotationDate] = useState('');
    const [dt_customer_order_Date, setCustomerOrdDate] = useState('');
    const [cmb_customer_state_id, setCustomerState] = useState('');
    const [cmb_customer_city_id, setCustomerCity] = useState('');
    // consignee details form fields
    const [cmb_consignee_id, setConsigneeId] = useState('');
    const [cmb_consignee_state_id, setConsigneeState] = useState('');
    const [cmb_consignee_city_id, setConsigneeCity] = useState('');
    // Sanction details form fields
    const [cmb_department_id, setDeptId] = useState();
    const [cmb_approved_by_id, setApprovedById] = useState(parseInt(UserId));
    const [dt_approved_date, setApproveDate] = useState(todayDate);
    const [dt_overall_schedule_date, setOverAllScheduleDate] = useState();
    const [txt_remark, setRemark] = useState();
    const [rb_is_active, setIsActive] = useState('true');
    // Taxation and Billing section form fields
    const [txt_basic_total, setBasicTotal] = useState(0);
    const [txt_transport_amount, setTransportAmt] = useState(0);
    const [chk_freight_isTaxable, setFreightIsTaxable] = useState(false);
    const [txt_freight_amount, setFreightAmt] = useState(0);
    //const [cmb_freight_hsn_id, setFreightHSNId] = useState();
    const [txt_packing_amount, setPackingAmt] = useState(0);
    const [txt_discount_percent, setDiscountPercent] = useState(0);
    const [txt_discount_amount, setDiscountAmt] = useState(0);
    const [txt_other_amount, setOtherAmt] = useState(0);
    const [txt_cgst_percent, setCGSTPercent] = useState(0);
    const [txt_cgst_total, setCGSTTotal] = useState(0);
    const [txt_sgst_percent, setSGSTPercent] = useState(0);
    const [txt_sgst_total, setSGSTTotal] = useState(0);
    const [txt_igst_percent, setIGSTPercent] = useState(0);
    const [txt_igst_total, setIGSTTotal] = useState(0);
    const [txt_taxable_total, setTaxableTotal] = useState(0);
    const [txt_roundoff, setRoundOff] = useState(0);
    const [txt_grand_total, setGrandTotal] = useState(0);
    const [cmb_agent_id, setAgentId] = useState();
    const [txt_agent_percent, setAgentPercent] = useState(0);
    const [cmb_agent_paid_status, setAgentPaidStatus] = useState('P');
    const [cmb_sales_order_status, setSalesOrdStatus] = useState('P');
    const [rb_sales_order_acceptance_status, setSalesOrdAcceptanceStatus] = useState('P');
    const [txt_status_remark, setStatusRemark] = useState();
    const [txt_other_terms_conditions, setOtherTermsConditions] = useState('');
    const [rb_sales_order_mail_sent_status, setSalesOrderMailSentStatus] = useState('P');
    const [rb_preeclose_sales_order, setPreeCloseSalesOrder] = useState('0');


    // From am_company_settings.
    const [expected_schedule_grace_days, setExpectedScheduleGraceDays] = useState(0);
    let expectedScheduleGraceDays = 0;


    // Customers contact details table data hooks
    const [custContactDetails, setCustContactDetails] = useState([]);
    const [custContactDetailsCols, setCustContactDetailsCols] = useState([]);

    // Sales Order details Table Data Hooks
    const [salesOrdDetailsData, setSalesOrdDetailsData] = useState([]);

    // For print
    const [salesOrderMasterDataJson, setSalesOrderMasterDataJson] = useState({});

    // Sales Order details tracking Table Data Hooks
    const [salesOrdDetailsTrackingData, setSalesOrdDetailsTrackingData] = useState([]);

    // Payment terms Table Data Hooks
    let [PmtTermsdata, setPmtTermsData] = useState([]);

    // Common Terms for sales order Table Data Hooks
    let [SOCommonTermsData, setSOCommonTermsData] = useState([]);

    // Taxation Table Data Hooks
    let [taxationSummaryData, setTaxationSummaryData] = useState([]);

    // Accordion Collapsable hook.  0 means open 1 means closed     eventKey
    let [detailsAccordEventKey, setDetailsAccordEventKey] = useState(0);
    let [paymentTermsAccordEventKey, setPaymentTermsAccordEventKey] = useState(1);
    let [commonTermsAccordEventKey, setCommonTermsAccordEventKey] = useState(1);
    let [taxationAccordEventKey, setTaxationAccordEventKey] = useState(1);

    // to add new records in combo box 
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    const openAddRecordPopUp = () => {
        switch (modalHeaderName) {
            case 'Order Type':
                return <FrmMProductTypeEntry btn_disabled={true} />;
            case 'Add Department':
                return <FrmDepartmentEntry btn_disabled={true} />
            case 'Add Customer City':
            case 'Add Company Branch City':
            case 'Add Consignee City':
                return <FrmCity />;
            default:
                return null;
        }
    }

    const handleCloseAddRecModal = async () => {
        switch (modalHeaderName) {
            case 'Order Type':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("product_type_short_name");
                globalQuery.table = "smv_product_type";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ['SR', 'GN', 'TM'], });
                const getOrderTypesUpdatedApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setSalesOrdTypesOpts(getOrderTypesUpdatedApiCall);
                break;

            case 'Add Customer City':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_city"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(cmb_customer_state_id) });
                const getCustomerUpdatedCities = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setCustomerCityOpts(getCustomerUpdatedCities);
                break;

            case 'Add Company Branch City':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_city"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(cmb_customer_state_id) });
                const getCompanyUpdatedCities = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setCompanyBrancheCityOpts(getCompanyUpdatedCities);
                break;

            case 'Add Consignee City':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_city"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(cmb_customer_state_id) });
                const getConsigneeUpdatedCities = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setConsigneeCityOpts(getConsigneeUpdatedCities);
                break;

            case 'Add Department':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_department";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedDepartments = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setDeptOpts(getUpdatedDepartments);
                break;

            default:
                break;
        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)
    }

    //Error Msg Modal
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    // Succ Msg Modal
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        moveToListing();
    };
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    // Emailing Modal.
    const [showMailSentModal, setShowMailSentModal] = useState(false);

    // After approved mail sent confirmation modal
    const handleCloseMailSentModal = () => {
        setShowMailSentModal(false);
        // moveToListing();
    }

    const generateMailJson = async () => {
        let EmailData = { 'EmailType': "Sales Order Status Updatation", 'toMailData': [], 'bccData': [], 'ccData': [], 'subject': {}, 'mailAttachmentFilePaths': [], 'parameters': [] }
        const checkboxes = $('.selectCustContactPerson:checked');
        checkboxes.each(function () {
            let selectedContact_id = parseInt($(this).attr('contact_personId'));
            const customerContactPersonDetails = custContactDetails.find(contactPerson => contactPerson.customer_contact_id === selectedContact_id);
            if (customerContactPersonDetails) {
                EmailData.toMailData.push(customerContactPersonDetails.cust_email_id);
            }
        });
        EmailData.subject = `Sales Order Approval Confirmation - Order ${txt_sales_order_no}`;      // Subject
        EmailData.parameters.push("Sales Order Information");                                       // Param0 It is a heading.
        EmailData.parameters.push($('#cmb_customer_id').find(":selected").text());                  // Param1
        EmailData.parameters.push(`${txt_sales_order_no}`);                                         // Param2
        EmailData.parameters.push($('#cmb_sales_order_status').find(":selected").text());           // Param3
        EmailData.parameters.push(`${dt_sales_order_date}`);                                        // Param4
        EmailData.parameters.push(`${txt_sales_order_no}`);                                         // Param4
        EmailData.parameters.push(sessionStorage.getItem('companyLegalName'));                      // Param6
        // console.log('emailData: ', EmailData);
        return EmailData;
    }

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }

    const sendEmail = async () => {

        try {
            $('#spinner_id').show();
            $('#spinner_text').text('Sending mail...');
            setShowMailSentModal(false);

            let emailContent = await generateMailJson();

            // Generate the sales-order PDF.
            let salesOrderContent = await printInvoice(false);
            const fileName = `${txt_sales_order_no.replaceAll('/', '_')}_@${COMPANY_ID}.pdf`;
            const salesOrderContainer = document.createElement('div');
            salesOrderContainer.style.margin = '20px';
            salesOrderContainer.innerHTML = salesOrderContent;
            let worker = html2pdf().from(salesOrderContainer).set({
                margin: 0,
                filename: fileName,
                html2canvas: { scale: 2 },
                jsPDF: { orientation: 'portrait', unit: 'pt', format: 'a4', compressPDF: true }
            }).toPdf();

            let pages = [];
            pages.slice(1).forEach(function (page) {
                worker = worker.get('pdf').then(function (pdf) {
                    pdf.addPage();
                }).from(page).toContainer().toCanvas().toPdf();
            });

            worker = worker.output("datauristring").then(async function (pdf) {
                const preBlob = dataURItoBlob(pdf);
                const file = new File([preBlob], fileName, { type: 'application/pdf' });

                const formData = new FormData();
                formData.append(`EmailData`, JSON.stringify(emailContent))
                formData.append("salesOrderPDF", file);

                const requestOptions = { method: 'POST', body: formData };
                const emailingApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesOrderMasterService/FnSendEmail/${sales_order_master_transaction_id}/${COMPANY_ID}`, requestOptions);
                const emailingApiCallResponse = await emailingApiCall.json();

                if (emailingApiCallResponse.success === "1") {
                    // Show success message.
                    setSuccMsg(emailingApiCallResponse.message);
                    setShowSuccessMsgModal(true);
                } else {
                    // Show error message.
                    setErrMsg(emailingApiCallResponse.message);
                    setShowErrorMsgModal(true);
                }
            });
            $('#spinner_id').hide();
        } catch (error) {
            $('#spinner_id').hide();
            console.log('Error in email sending: ', error);
            navigator('/Error')
        }
    }

    // Document Form Modal
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const handleCloseDocumentForm = () => setShowDocumentForm(false);
    const viewDocumentForm = () => {
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            setShowDocumentForm(true);
        }
    }

    // Bom Filter Modal
    const [showBomFilterForm, setShowBomFilterForm] = useState(false);
    const viewBomFilterForm = async () => {
        let checkIsValidate = await validate.current.validateForm("salesOrderFormId");
        if (checkIsValidate === true && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            // set disabled the order type.
            // $('#cmb_sales_order_type_id').attr('disabled', 'disabled');
            localStorage.setItem('requiredCols', JSON.stringify(requiredColsForBomFilter));
            setShowBomFilterForm(true);
        }
    }

    //Add Schedules Modal
    const [scheduleDataArray, setScheduleDataArray] = useState([]);
    const [showSchedule, setSchedules] = useState(false);
    const handleCloseSchedulesPopup = () => {
        setSchedules(false);
        setSchedulerowcount(1);
    }
    const [schedulerowcount, setSchedulerowcount] = useState(1);
    let remainingSchduleQty = 0;

    useEffect(() => {
        const fetchInitOrderLayoutData = async () => {
            FnSetDefaultFormFields();
            $('#spinner_id').show();
            $('#spinner_text').text('Loading...');
            await FnSetFieldsByUserAccess();
            await FnInitOrderLayoutData();

            if (idList !== null) {
                await FnGetDataForUpdate();
            }
            await FnSetFieldsByUserAccess();
            $('#spinner_id').hide();
        };
        fetchInitOrderLayoutData()
    }, []);
    const FnInitOrderLayoutData = async () => {
        try {

            // First get the grace days count from company settings.
            resetGlobalQuery();
            globalQuery.columns.push('so_schedule_grace_days');
            globalQuery.table = "am_company_settings"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getScheduleGraceDaysApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
            expectedScheduleGraceDays = getScheduleGraceDaysApiCall.length === 0 ? 0 : getScheduleGraceDaysApiCall[0].so_schedule_grace_days;
            setOverAllScheduleDate(FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays));

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("product_type_short_name");
            globalQuery.table = "smv_product_type"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ['SR', 'GN', 'TM'], });

            const getSalesOrdTypesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setSalesOrdTypesOpts(getSalesOrdTypesApiCall);

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("is_sez");
            globalQuery.table = "cmv_customer"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getCustomersApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setCustomerOpts(getCustomersApiCall);
            setConsigneeOpts(getCustomersApiCall);

            const getDeptsApiCall = await comboDataAPiCall.current.fillMasterData("cmv_department", "is_delete", "0");
            setDeptOpts(getDeptsApiCall);

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "cmv_employee"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getEmployeeList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setApproveByOpts(getEmployeeList)

            const getProductUnitsApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_unit ", "is_delete", "0");
            setProductUnitOpts(getProductUnitsApiCall);

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("state_code");
            globalQuery.table = "cmv_state"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getStatesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setStateOpts(getStatesApiCall);

            const getAgentsApiCall = await comboDataAPiCall.current.fillMasterData("cmv_agent", "is_delete", "0");
            setAgentOpts(getAgentsApiCall);

            resetGlobalQuery();
            globalQuery.columns.push("hsn_sac_id");
            globalQuery.columns.push("hsn_sac_code");
            globalQuery.columns.push("hsn_sac_rate");
            globalQuery.table = "cmv_hsn_sac"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getFreightHSNCodesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setFreightHSNOpts(getFreightHSNCodesApiCall);

            resetGlobalQuery();
            globalQuery.columns.push("company_branch_id");
            globalQuery.columns.push("company_branch_name");
            globalQuery.columns.push("branch_state_id");
            globalQuery.columns.push('branch_city_id');
            globalQuery.table = "cmv_company_branch"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getCompanyBranchesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setCompanyBranchesOpts(getCompanyBranchesApiCall);
            setCompanyBranchId(parseInt(COMPANY_BRANCH_ID));
            const branchInSession = getCompanyBranchesApiCall.find((branch) => branch.company_branch_id === parseInt(COMPANY_BRANCH_ID));
            if (branchInSession !== null) {
                setCompanyBranchStateId(branchInSession.branch_state_id)
                const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + branchInSession.branch_state_id + "");
                setCompanyBrancheCityOpts(cityApiCall);
                setCompanyBranchCityId(branchInSession.branch_city_id)
            }

            // For get the Payment-terms details.
            await FnGetPaymentTerms();

            // For get the Sales Order common terms details.
            await FnGetCommonSalesOrderTerms();

        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }

    const FnSetFieldsByUserAccess = async () => {
        // Disable upload buttons.
        $('#uploadDocBtn').attr('disabled', 'disabled');

        // User Access Manage.
        let userAccessKey = sessionStorage.getItem('keyForViewUpdate');
        setIsApprove(userAccessKey === 'approve' ? true : false);
        switch (userAccessKey) {
            case 'update':
                $('#save-btn').text('Update');
                $('#clearFormBtn').attr('disabled', 'disabled');
                $('#uploadDocBtn').removeAttr('disabled');
                $('input[name="rb_sales_order_creation_type"]').prop('disabled', true);
                $('#cmb_sales_order_type_id').prop('disabled', true);
                $('#dt_sales_order_date').prop('disabled', true);
                // $('#cmb_customer_id').prop('disabled', true);
                // $('#txt_customer_order_no').prop('disabled', true);
                // $('#dt_customer_order_Date').prop('disabled', true);
                // $('#cmb_sales_quotation_no, #dt_sales_quotation_date').prop("disabled", true);

                break;
            case 'view':
                $('#save-btn').attr('disabled', 'disabled');
                $('#clearFormBtn').attr('disabled', 'disabled');
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                $('input[type="checkbox"]').prop('disabled', true);
                $('#selectAllPmtTerms, #selectAllCommonTerms').prop("disabled", true);
                $('#cmb_sales_quotation_no, #dt_sales_quotation_date').prop("disabled", true);

                break;
            case 'approve':
                $('#save-btn').text('Approve');
                $('#save-btn').attr('BtnType', 'approve');
                $('#clearFormBtn').attr('disabled', 'disabled');

                // Input Fields
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                // $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                $('table .approveField').removeAttr("disabled");
                $('input[type="checkbox"]').prop('disabled', true);
                $('#selectAllPmtTerms, #selectAllCommonTerms').prop("disabled", true);
                $('#cmb_sales_quotation_no, #dt_sales_quotation_date').prop("disabled", true);
                $('#txt_status_remark').prop("disabled", false);
                // $('#cmb_sales_order_status').prop('disabled', false);
                $('input[name="rb_sales_order_acceptance_status"]').prop('disabled', true);
                $('input[name="rb_preeclose_sales_order"]').removeAttr("disabled");
                $('.MaterialWiseRemark').removeAttr('disabled');
                setApproveDate(todayDate);
                break;
            default:
                break;
        }
    }

    const FnGetDataForUpdate = async () => {
        debugger
        try {
            let todayDateForInputField = todayDate;
            let defaultExpecScheduleDate = FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays);

            let sales_order_no;
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesOrderMasterService/FnShowAllDetailsAndMastermodelRecords/${idList.sales_order_version}/${idList.financial_year}/${COMPANY_ID}?sales_order_no=${idList.sales_order_no}`)
            const responce = await apiCall.json();

            if (responce.SalesOrderMasterServiceRecord !== null) {
                const existingSalesOrdMasterData = responce.SalesOrderMasterServiceRecord
                setSalesOrderMasterDataJson(existingSalesOrdMasterData)
                setSalesOrderMasterTransactionId(existingSalesOrdMasterData.sales_order_master_transaction_id);
                setSalesOrdCreationType(existingSalesOrdMasterData.sales_order_creation_type);
                setSalesOrderTypeId(existingSalesOrdMasterData.sales_order_type_id);
                setSalesOrdNo(existingSalesOrdMasterData.sales_order_no);
                sales_order_no = existingSalesOrdMasterData.sales_order_no;
                setSalesOrdDate(existingSalesOrdMasterData.sales_order_date);
                setSalesOrdVer(existingSalesOrdMasterData.sales_order_version);
                setSalesOrdLife(existingSalesOrdMasterData.sales_order_life);

                if (keyForViewUpdate === 'approve') {
                    setSalesOrdStatus('A');
                } else {
                    setSalesOrdStatus(existingSalesOrdMasterData.sales_order_status);
                }
                setCustomerId(existingSalesOrdMasterData.customer_id);
                await FnComboOnChange('Customer');
                setCustomerOrderNo(existingSalesOrdMasterData.customer_order_no);
                setCustomerOrdDate(existingSalesOrdMasterData.customer_order_Date);
                setCustomerState(existingSalesOrdMasterData.customer_state_id);
                await FnComboOnChange('CustomerState');
                setCustomerCity(existingSalesOrdMasterData.customer_city_id);

                setTimeout(() => {
                    // Check the contact-person checkboxes.
                    if (responce.SalesOrderMasterServiceRecord.customer_contacts_ids !== '') {
                        const customerContactPersonsIds = existingSalesOrdMasterData.customer_contacts_ids.split(':');
                        customerContactPersonsIds.forEach(function (contactId, index) {
                            $('#selectCustContactPerson_' + customerContactPersonsIds[index]).prop('checked', true);
                        });
                        checkBoxesSelection('PartiallyCustContactSelection')
                    }
                }, 500);

                setDeptId(existingSalesOrdMasterData.department_id);
                // await FnComboOnChange('Department');

                if (keyForViewUpdate === 'approve' && (existingSalesOrdMasterData.approved_by_id === 0 || existingSalesOrdMasterData.approved_by_id === null)) {
                    setApprovedById(parseInt(UserId));
                } else {
                    setApprovedById(existingSalesOrdMasterData.approved_by_id);
                }

                setApproveDate(existingSalesOrdMasterData.approved_date);
                setOverAllScheduleDate(existingSalesOrdMasterData.overall_schedule_date);
                setConsigneeId(existingSalesOrdMasterData.consignee_id);
                await FnComboOnChange('Consignee');
                setConsigneeState(existingSalesOrdMasterData.consignee_state_id);
                await FnComboOnChange('ConsigneeState');
                setConsigneeCity(existingSalesOrdMasterData.consignee_city_id);
                setRemark(existingSalesOrdMasterData.remark);
                setIsActive(existingSalesOrdMasterData.is_active.toString());
                setSalesQuotationDate(existingSalesOrdMasterData.sales_quotation_date);
                setSalesQuotationNo(existingSalesOrdMasterData.sales_quotation_no);

                // Footer section.
                setBasicTotal(existingSalesOrdMasterData.basic_total);
                setTransportAmt(existingSalesOrdMasterData.transport_amount);
                setFreightAmt(existingSalesOrdMasterData.freight_amount);
                // setFreightHSNId(existingSalesOrdMasterData.freight_hsn_code_id);
                setFreightIsTaxable(existingSalesOrdMasterData.is_freight_taxable);
                setPackingAmt(existingSalesOrdMasterData.packing_amount);
                setDiscountPercent(existingSalesOrdMasterData.discount_percent);
                setDiscountAmt(existingSalesOrdMasterData.discount_amount);
                setOtherAmt(existingSalesOrdMasterData.other_amount);
                setTaxableTotal(existingSalesOrdMasterData.taxable_total);
                setCGSTTotal(existingSalesOrdMasterData.cgst_total);
                setSGSTTotal(existingSalesOrdMasterData.sgst_total);
                setIGSTTotal(existingSalesOrdMasterData.igst_total);
                setGrandTotal(existingSalesOrdMasterData.grand_total);
                setRoundOff(existingSalesOrdMasterData.roundoff);
                setAgentId(existingSalesOrdMasterData.agent_id);
                setAgentPercent(existingSalesOrdMasterData.agent_percent);
                setAgentPaidStatus(existingSalesOrdMasterData.agent_paid_status);
                setSalesOrdAcceptanceStatus(existingSalesOrdMasterData.sales_order_acceptance_status);
                setStatusRemark(existingSalesOrdMasterData.status_remark);
                setOtherTermsConditions(existingSalesOrdMasterData.other_terms_conditions);
                setSalesOrderMailSentStatus(existingSalesOrdMasterData.sales_order_mail_sent_status);
                setPreeCloseSalesOrder(existingSalesOrdMasterData.sales_order_status === 'Z' ? '1' : '0');

                if (keyForViewUpdate === 'copy') {
                    let salesOrderTypeShortName = $('#cmb_sales_order_type_id option:selected').attr('shortName');
                    // const salesOrderTypeShortName = salesOrdTypesOpts.find(item => item.field_id === parseInt(existingSalesOrdMasterData.sales_order_type_id));
                    sales_order_no = await GenerateSalesOrderNo(salesOrderTypeShortName);
                    setSalesOrderMasterTransactionId(0);
                    setSalesOrdDate(todayDateForInputField);
                    setSalesOrdStatus('P');
                    // setCustomerOrderNo('');
                    // setCustomerOrdDate(todayDateForInputField);

                    setOverAllScheduleDate(defaultExpecScheduleDate);
                    // setApprovedById('');
                    // setApproveDate('');
                    setAgentPaidStatus('P');
                    setSalesOrdAcceptanceStatus('P');
                    setStatusRemark('');
                    setSalesOrderMailSentStatus('P');
                    setPreeCloseSalesOrder('0');
                }

                // Set the Material Details.
                if (responce.SalesOrderDetailsServiceRecord !== null) {

                    let taxationSummary = JSON.parse(localStorage.getItem('taxationSummary')) === null ? [] : JSON.parse(localStorage.getItem('taxationSummary'));

                    // Function to update keys dynamically
                    const updateKeys = (object) => {
                        let taxSummary = {
                            materialId: parseInt(object.product_material_id),
                            // so_sr_no: parseInt(object.so_sr_no),
                            // hsnCode: object.product_sr_hsn_sac_code,
                            hsnCode: object.product_material_hsn_sac_code,
                            hsn_code_id: object.product_material_hsn_code_id,
                            // hsnRate: object.product_sr_hsn_sac_rate,
                            hsnRate: object.product_material_hsn_sac_rate,
                            taxableAmt: object.material_taxable_amount,
                            CGSTPercent: object.material_cgst_percent,
                            CGSTAmt: object.material_cgst_total,
                            SGSTPercent: object.material_sgst_percent,
                            SGSTAmt: object.material_sgst_total,
                            IGSTPercent: object.material_igst_percent,
                            IGSTAmt: object.material_igst_total,
                            totalAmt: object.material_total_amount
                        }
                        const materialIndex = taxationSummary.findIndex((item) => parseInt(item.materialId) === parseInt(object.product_sr_id));
                        if (materialIndex !== -1) {
                            taxationSummary[materialIndex] = taxSummary;    // Replace the object in-place
                        } else {
                            taxationSummary.push(taxSummary);               // Push the new object if not found
                        }
                        localStorage.setItem('taxationSummary', JSON.stringify(taxationSummary));

                        const updatedObject = {};
                        for (const key in object) {
                            if (key === 'product_material_id') {
                                updatedObject[`product_sr_id`] = object[key];
                            } else if (key === 'product_material_unit_id') {
                                updatedObject[`product_rm_stock_unit_id`] = object[key];
                            } else if (key === 'product_material_packing_id') {
                                updatedObject[`product_rm_packing_id`] = object[key];
                            } else if (key === 'product_material_hsn_code_id') {
                                updatedObject[`product_sr_hsn_sac_code_id`] = object[key];
                            } else if (key === 'product_material_hsn_sac_code') {
                                updatedObject[`product_sr_hsn_sac_code`] = object[key];
                            } else if (key === 'product_material_hsn_sac_rate') {
                                updatedObject[`product_sr_hsn_sac_rate`] = object[key];
                            } else if (key === 'material_quantity') {
                                updatedObject[`product_sr_quantity`] = object[key];
                            } else if (key === 'material_rate') {
                                updatedObject[`product_sr_std_price`] = object[key];
                            }
                            else {
                                updatedObject[`${key}`] = object[key];
                            }
                        }
                        return updatedObject;
                    };

                    let updatedOrderDetailsData = responce.SalesOrderDetailsServiceRecord.map(updateKeys);

                    if (keyForViewUpdate === 'copy') {
                        // Update the data for copied sales order.
                        updatedOrderDetailsData = updatedOrderDetailsData.map((ordDetail) => ({
                            ...ordDetail,
                            sales_order_item_status: 'P',
                            sales_order_master_transaction_id: 0,
                            sales_order_details_transaction_id: 0,
                            sales_order_no: sales_order_no,
                            sales_order_date: todayDateForInputField,
                            sales_order_version: 1,
                            // customer_order_no: '',
                            // customer_order_Date: todayDateForInputField,
                            material_schedule_date: defaultExpecScheduleDate,
                        }));

                    } else if (keyForViewUpdate === 'approve') {
                        updatedOrderDetailsData = updatedOrderDetailsData.map((ordDetail) => ({
                            ...ordDetail,
                            sales_order_item_status: ordDetail.sales_order_item_status === 'P'
                                ? "A"
                                : ordDetail.sales_order_item_status,
                        }));
                    }
                    setSalesOrdDetailsData(updatedOrderDetailsData);
                }

                // Set the Tracking details.
                // if (responce.salesOrderDetailsTrackingTrading !== null && responce.salesOrderDetailsTrackingTrading.length > 0) {
                //     setSalesOrdDetailsTrackingData(keyForViewUpdate === 'copy' ? [] : responce.salesOrderDetailsTrackingTrading);
                // }

                // Set Order Material Schedule data.
                if (responce.SalesOrderScheduleServiceRecords !== null && responce.SalesOrderScheduleServiceRecords.length > 0) {
                    const existingSalesOrdDetailsSchedules = responce.SalesOrderScheduleServiceRecords;
                    const updatedSchedulesData = [];

                    const materialWiseSchedules = {};
                    existingSalesOrdDetailsSchedules.forEach((item) => {
                        const key = `${item.product_material_id}`;
                        if (!materialWiseSchedules[key]) {
                            materialWiseSchedules[key] = [];
                        }
                        materialWiseSchedules[key].push(item);
                    });
                    // Looping for set the id for schedule
                    for (const key of Object.keys(materialWiseSchedules)) {
                        const [rm_id] = key.split(':');
                        const materialGroup = materialWiseSchedules[key];
                        materialGroup.forEach((schedule, scheduleCounter) => {
                            schedule.id = `${rm_id}:${scheduleCounter}`;
                            if (keyForViewUpdate === 'copy') {
                                //  //Update the keys for copied sales order.
                                schedule.sales_order_schedules_transaction_id = 0;
                                schedule.sales_order_master_transaction_id = 0;
                                schedule.sales_order_no = sales_order_no;
                                schedule.sales_order_date = todayDateForInputField;
                                schedule.sales_order_version = 1;
                                // schedule.customer_order_no = '';
                                // schedule.customer_order_Date = todayDateForInputField;
                                schedule.sales_order_schedules_trading_item_status = 'P';
                                schedule.sales_order_schedules_trading_item_status_desc = 'Pending';
                                schedule.expected_schedule_date = defaultExpecScheduleDate;

                            } else if (keyForViewUpdate === 'approve') {
                                schedule.sales_order_schedules_trading_item_status = schedule.sales_order_schedules_trading_item_status = 'P' ? 'A' : schedule.sales_order_schedules_trading_item_status;
                                schedule.sales_order_schedules_trading_item_status_desc = schedule.sales_order_schedules_trading_item_status = 'P' ? 'Aprroved' : schedule.sales_order_schedules_trading_item_status_desc;

                            } else if (keyForViewUpdate !== 'approve') {
                                schedule.sales_order_schedules_transaction_id = 0;
                            }
                            updatedSchedulesData.push(schedule);
                        });
                    }
                    setScheduleDataArray(updatedSchedulesData)
                    localStorage.setItem('scheduledData', JSON.stringify(responce.SalesOrderScheduleServiceRecords));
                }

                // Set Sales order Payment terms data.
                if (responce.ServiceSaleOrderPaymentTerms !== null) {
                    const existingSalesOrdPmtTermsData = responce.ServiceSaleOrderPaymentTerms;
                    let renderedPmtTermsData = JSON.parse(localStorage.getItem('SalesOrdPmtTermsData'));
                    for (let existPmtCounter = 0; existPmtCounter < existingSalesOrdPmtTermsData.length; existPmtCounter++) {
                        const existingPmtTermId = existingSalesOrdPmtTermsData[existPmtCounter].payment_terms_id;
                        const renderedPmtTerm = renderedPmtTermsData.map((pmtTerm, index) => ({
                            index, item: pmtTerm,
                        })).find(data => parseInt(data.item.payment_terms_id) === parseInt(existingPmtTermId));

                        if (renderedPmtTerm) {
                            renderedPmtTermsData[renderedPmtTerm.index].payment_terms_Milestome = existingSalesOrdPmtTermsData[existPmtCounter].payment_terms_Milestome;
                            renderedPmtTermsData[renderedPmtTerm.index].payment_expected_value = existingSalesOrdPmtTermsData[existPmtCounter].payment_expected_value;
                            renderedPmtTermsData[renderedPmtTerm.index].payment_expected_date = existingSalesOrdPmtTermsData[existPmtCounter].payment_expected_date;
                            renderedPmtTermsData[renderedPmtTerm.index].payment_percent = existingSalesOrdPmtTermsData[existPmtCounter].payment_percent;
                            renderedPmtTermsData[renderedPmtTerm.index].payment_receipt_flag = existingSalesOrdPmtTermsData[existPmtCounter].payment_receipt_flag;
                            renderedPmtTermsData[renderedPmtTerm.index].payment_receipt_transaction_id = existingSalesOrdPmtTermsData[existPmtCounter].payment_receipt_transaction_id;
                            renderedPmtTermsData[renderedPmtTerm.index].payment_receipt_date = existingSalesOrdPmtTermsData[existPmtCounter].payment_receipt_date;
                            renderedPmtTermsData[renderedPmtTerm.index].remark = existingSalesOrdPmtTermsData[existPmtCounter].remark;
                        }

                        // Update Data if order is copied then set default data.
                        if (keyForViewUpdate === 'copy') {
                            renderedPmtTermsData[renderedPmtTerm.index].payment_receipt_transaction_id = '';
                            renderedPmtTermsData[renderedPmtTerm.index].payment_receipt_flag = 'P';
                            renderedPmtTermsData[renderedPmtTerm.index].payment_receipt_date = '';
                            renderedPmtTermsData[renderedPmtTerm.index].payment_expected_date = defaultExpecScheduleDate
                        }
                    }
                    localStorage.setItem('SalesOrdPmtTermsData', JSON.stringify(renderedPmtTermsData));
                    setPmtTermsData(renderedPmtTermsData);
                    // mark the checked checkboxes for order payment term.
                    existingSalesOrdPmtTermsData.forEach(function (existingPmtTerm) {
                        $('#selectPmtTerm_' + existingPmtTerm.payment_terms_id).prop('checked', true);
                    });
                }

                // Set Sales order common terms data.
                if (responce.ServiceSalesOrderTermTrading !== null) {
                    const existingCommonTermsData = responce.ServiceSalesOrderTermTrading;
                    let renderedCommonTermsData = JSON.parse(localStorage.getItem('SalesOrdCommonTermsData'));
                    for (let existCommonCounter = 0; existCommonCounter < existingCommonTermsData.length; existCommonCounter++) {
                        const existingCommonTermId = existingCommonTermsData[existCommonCounter].sales_order_terms_parameters_id;
                        const renderedCommonTerm = renderedCommonTermsData.map((pmtTerm, index) => ({
                            index, item: pmtTerm,
                        })).find(data => data.item.common_parameters_id === existingCommonTermId);

                        if (renderedCommonTerm) {
                            renderedCommonTermsData[renderedCommonTerm.index].common_parameters_expected_value = existingCommonTermsData[existCommonCounter].sales_order_terms_parameters_expected_value;
                            renderedCommonTermsData[renderedCommonTerm.index].remark = existingCommonTermsData[existCommonCounter].remark;
                        }
                    }
                    localStorage.setItem('SalesOrdCommonTermsData', JSON.stringify(renderedCommonTermsData));
                    setSOCommonTermsData(renderedCommonTermsData);
                    // mark the checked checkboxes for order common term.
                    existingCommonTermsData.forEach(function (existingCommonTerm) {
                        $('#selectCommonTerm_' + existingCommonTerm.sales_order_terms_parameters_id).prop('checked', true);
                    });
                }

                // //  Sets the taxation Summary
                if (responce.ServiceSalesOrderTaxSummary !== null) {
                    setCompanyBranchId(responce.ServiceSalesOrderTaxSummary[0].expected_branch_id)
                    setCompanyBranchStateId(responce.ServiceSalesOrderTaxSummary[0].expected_branch_state_id)
                    const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + responce.salesOrderTaxSummary[0].expected_branch_state_id + "");
                    setCompanyBrancheCityOpts(cityApiCall);
                    setCompanyBranchCityId(responce.ServiceSalesOrderTaxSummary[0].expected_branch_city_id)

                    // // Function to update keys dynamically
                    // responce.salesOrderTaxSummary = responce.salesOrderTaxSummary?.map(taxSummaryTblRecord => {
                    //     // Create a new object with the updated key name
                    //     const updatedKeysObj = {
                    //         ...taxSummaryTblRecord,
                    //         product_rm_name: taxSummaryTblRecord.hsn_sac_code,
                    //     };
                    //     return updatedKeysObj;
                    // });

                    // setTaxationSummaryData(responce.salesOrderTaxSummary);
                    // console.log('salesOrderTaxSummary: ',responce.salesOrderTaxSummary);
                }

                setTimeout(() => {
                    calculateGrandTotal(responce.SalesOrderDetailsServiceRecord)
                }, 300);

            }
        } catch (error) {
            console.log("error in getting existing sales order data for update: ", error)
            navigator('/Error')
        }
    }

    const FnComboOnChange = async (key) => {
        try {
            switch (key) {
                case 'CreationType':
                    // Get the Quotations list.
                    await FnGetCustomerApprovedQuotations();
                    break;

                case 'SalesOrderStatus':
                    // Update the sales order status
                    break;

                case 'SalesOrderType':
                    let selectedSalesOrdType = $('#cmb_sales_order_type_id').val();
                    if (selectedSalesOrdType === "0") {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Order Type')
                        setShowAddRecModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").css({ "padding-top": "0px" });
                        }, 200);
                        setSalesOrderTypeId('')
                    } else if (selectedSalesOrdType !== "") {
                        setSalesOrderTypeId(selectedSalesOrdType)

                        let salesOrderTypeShortName = $('#cmb_sales_order_type_id option:selected').attr('shortName');
                        await GenerateSalesOrderNo(salesOrderTypeShortName);
                    }
                    break;

                case 'CompanyBranch':
                    let companyBranchID = parseInt($('#cmb_companyBranchId').val());
                    if ($('#cmb_companyBranchId').val() === '0') {
                        const newTab = window.open('/Masters/Company', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                    } else if (!isNaN(companyBranchID)) {
                        const selectedBranchObj = companyBranchesOpts.find((branch) => branch.company_branch_id === companyBranchID);
                        if (selectedBranchObj) {
                            setCompanyBranchStateId(selectedBranchObj.branch_state_id);
                            const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", (selectedBranchObj.branch_state_id).toString());
                            setCompanyBrancheCityOpts(cityApiCall);
                            setCompanyBranchCityId(selectedBranchObj.branch_city_id)
                        }
                        updateTblCalculationStateChange();
                    }
                    break;

                case 'CompanyBranchState':
                    let selectedBranchState = parseInt($('#cmb_companyBranchStateId').val());
                    if (selectedBranchState !== "") {
                        resetGlobalQuery();
                        globalQuery.columns.push("field_id");
                        globalQuery.columns.push("field_name");
                        globalQuery.table = "cmv_city"
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(selectedBranchState) });
                        const getCompanyCitiesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        setCompanyBrancheCityOpts(getCompanyCitiesApiCall);
                        if (getCompanyCitiesApiCall.length <= 0) { setCustomerCity(''); }


                    } else {
                        setCompanyBrancheCityOpts([]);
                    }
                    break;

                case 'CompanyBranchCity':
                    if ($('#cmb_companyBranchCityId').val() === '0') {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Add Company Branch City')
                        setShowAddRecModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").css({
                                "padding-top": "0px"
                            });
                        }, 200);
                        setCompanyBranchCityId('');
                    }
                    break;

                case 'Customer':
                    let selectedCustomer = parseInt($('#cmb_customer_id').val());
                    if ($('#cmb_customer_id').val() === '0') {
                        const newTab = window.open('/Masters/Customer', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                    } else if (selectedCustomer !== "0" && !isNaN(selectedCustomer)) {
                        resetGlobalQuery();
                        globalQuery.columns = ["cust_branch_state_id", "cust_branch_city_id"];
                        globalQuery.table = "cmv_customer";
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: selectedCustomer });
                        const getCustomerDetailsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        if (getCustomerDetailsApiCall.length > 0) {
                            setCustomerState(getCustomerDetailsApiCall[0].cust_branch_state_id);
                            $('#error_cmb_customer_state_id').hide();
                            await FnComboOnChange('CustomerState');
                            setCustomerCity(getCustomerDetailsApiCall[0].cust_branch_city_id);
                        } else {
                            setCustomerState('');
                            setCustomerCity('');
                            setCustomerCityOpts([]);
                        }
                        FnGetCustomersContactPersonsDetails(parseInt(selectedCustomer));

                        // Get the Quotations list.
                        await FnGetCustomerApprovedQuotations();

                        setCustomerOrderNo('')
                        setCustomerOrdDate('')
                    } else {
                        setCustomerOrderNo('')
                        setCustomerOrdDate('')
                        setCustomerState('');
                        setCustomerCity('');
                        setCustomerCityOpts([]);
                        setQuotationOpts([]);
                        setCustContactDetailsCols([]);
                        setCustContactDetails([]);
                    }
                    break;

                case 'CustomerOrder':
                    if ($('#cmb_customer_order_no').val() !== null && $('#cmb_customer_order_no').val() !== '') {
                        let selectedOption = $('#cmb_customer_order_no').find('option:selected');
                        let selectedOrderDate = selectedOption.attr('ord_date');
                        setCustomerOrdDate(selectedOrderDate)
                    } else {
                        setCustomerOrdDate('')
                    }
                    break;

                case 'CustomerState':
                    let customerState = parseInt($('#cmb_customer_state_id').val());
                    if ($('#cmb_customer_state_id').val() === '0') {

                    } else if (customerState != null && !isNaN(customerState)) {
                        resetGlobalQuery();
                        globalQuery.columns.push("field_id");
                        globalQuery.columns.push("field_name");
                        globalQuery.table = "cmv_city"
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(customerState) });
                        const getCustomerCitiesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        setCustomerCityOpts(getCustomerCitiesApiCall);
                        if (getCustomerCitiesApiCall.length <= 0) { setCustomerCity(''); }
                        updateTblCalculationStateChange();

                    } else {
                        setCustomerCity('');
                        setCustomerCityOpts([]);
                    }
                    break;

                case 'CustomerCity':
                    if ($('#cmb_customer_city_id').val() === '0') {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Add Customer City')
                        setShowAddRecModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").css({
                                "padding-top": "0px"
                            });
                        }, 200)
                        setCustomerCity('');
                    }
                    break;

                case 'Consignee':
                    let selectedConsigneeId = parseInt($('#cmb_consignee_id').val());
                    if ($('#cmb_consignee_id').val() === '0') {
                        const newTab = window.open('/Masters/Customer', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                        // setConsigneeId('');
                    } else if (selectedConsigneeId !== "" && !isNaN(selectedConsigneeId)) {
                        resetGlobalQuery();
                        globalQuery.columns.push("cust_branch_state_id");
                        globalQuery.columns.push("cust_branch_city_id");
                        globalQuery.table = "cmv_customer"
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: selectedConsigneeId });
                        const getConsineeDetailsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        if (getConsineeDetailsApiCall.length > 0) {
                            setConsigneeState(getConsineeDetailsApiCall[0].cust_branch_state_id);
                            FnComboOnChange('ConsigneeState');
                            setConsigneeCity(getConsineeDetailsApiCall[0].cust_branch_city_id);
                        } else {
                            setConsigneeState('');
                            setConsigneeCity('');
                            setConsigneeCityOpts([]);
                        }
                    } else {
                        setConsigneeState('');
                        setConsigneeCity('');
                        setConsigneeCityOpts([]);
                    }
                    break;

                case 'ConsigneeState':
                    let consigneeState = parseInt($('#cmb_consignee_state_id').val());
                    if (consigneeState != null && !isNaN(consigneeState)) {
                        resetGlobalQuery();
                        globalQuery.columns.push("field_id");
                        globalQuery.columns.push("field_name");
                        globalQuery.table = "cmv_city"
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "state_id", operator: "=", value: parseInt(consigneeState) });
                        const getConsigneeCitiesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        setConsigneeCityOpts(getConsigneeCitiesApiCall);
                        if (getConsigneeCitiesApiCall.length <= 0) { setConsigneeCity(''); }
                    } else {
                        setConsigneeCityOpts([]);
                        setConsigneeCity('');
                    }
                    break;

                case 'ConsigneeCity':
                    if ($('#cmb_consignee_city_id').val() === '0') {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Add Consignee City')
                        setShowAddRecModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").css({
                                "padding-top": "0px"
                            });
                        }, 200);
                        setConsigneeCity('');
                    }
                    break;

                case 'Department':
                    let departmentIdVal = parseInt($('#cmb_department_id').val());
                    setDeptId(departmentIdVal)
                    if ($('#cmb_department_id').val() === "0") {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Add Department')
                        setShowAddRecModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").css({
                                "padding-top": "0px"
                            });
                        }, 200);
                        setDeptId('');
                    } else if (departmentIdVal !== "" && !isNaN(departmentIdVal) !== "0") {
                        resetGlobalQuery();
                        globalQuery.columns.push("field_id");
                        globalQuery.columns.push("field_name");
                        globalQuery.table = "cmv_employee"
                        globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        // globalQuery.conditions.push({ field: "department_id", operator: "=", value: departmentIdVal });
                        const identedbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        setApproveByOpts(identedbyIdApiCall)
                        $('#error_cmb_department').hide();
                    } else {
                        setApproveByOpts([])
                    }
                    break;

                case 'Quotation':
                    if ($('#cmb_sales_quotation_no').val() != null && $('#cmb_sales_quotation_no').val() != '') {
                        let selectedQuotation = $('#cmb_sales_quotation_no').find('option:selected');
                        let quotationDate = selectedQuotation.attr('quotation_date');
                        setSalesQuotationDate(quotationDate);

                        // Now get the releated quotation data.
                        await FnGetOrderDetailsByQuotation();

                    } else {
                        setSalesQuotationDate('')
                    }
                    break;

                case 'Agent':
                    if ($('#cmb_agent_id').val() === "0") {
                        const newTab = window.open('/Masters/Agent', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                    }
                    break;

                default:
                    break;
            }
        } catch (error) {
            console.log("Error: " + error)
            navigator('/Error')
        }
    }

    // For get the selected customer's approved quotations.
    const FnGetCustomerApprovedQuotations = async () => {
        let selectedCustomer = $('#cmb_customer_id').val();
        const orderCreationType = document.querySelector('input[name="rb_sales_order_creation_type"]:checked').value;
        if (orderCreationType === 'Q' && selectedCustomer !== '' && selectedCustomer !== '' && selectedCustomer !== null) {
            try {
                resetGlobalQuery();
                globalQuery.columns = ["quotation_no", "quotation_date", "financial_year", "quotation_version"];
                globalQuery.table = "mtv_sales_quotation_services_summary";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "customer_id", operator: "=", value: parseInt(selectedCustomer) });
                globalQuery.conditions.push({ field: "quotation_status", operator: "=", value: 'A' });
                const getQuotationsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setQuotationOpts(getQuotationsApiCall);
            } catch (error) {
                console.log("Error in getting customer's approved quotations : ", error)
                navigator('/Error')
            }
        } else {
            setQuotationOpts([]);
            setSalesQuotationNo('');
            setSalesQuotationDate('');
        }
        setSalesOrdDetailsData([]);
        setScheduleDataArray([]);
        localStorage.removeItem('salesOrderMaterialSchedules');
        localStorage.removeItem('scheduledData');
        taxationSummaryData = [];
        localStorage.removeItem('taxationSummary');
        calculateGrandTotal([]);

    }

    // For get the customer's selected quotation.
    const FnGetOrderDetailsByQuotation = async () => {

        try {
            let selectedQuotationNumber = $('#cmb_sales_quotation_no').val();
            let selectedQuotationData = quotationOpts.find(quotation => quotation.quotation_no === selectedQuotationNumber);

            if (selectedQuotationData) {
                let defaultGracedScheduleDate = FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays);

                selectedQuotationData['company_id'] = COMPANY_ID;
                selectedQuotationData['quotation_item_status'] = "A"
                //  // 1. Get the data from mtv_quotation_details using api.
                const formData = new FormData();
                formData.append(`QuotationInformation`, JSON.stringify(selectedQuotationData))
                const requestOptions = { method: 'POST', body: formData };
                const getOrderDetailsByQuotationApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesOrderMasterService/FnGetQuotationDetailsByItemStatus/${COMPANY_ID}`, requestOptions);
                const responce = await getOrderDetailsByQuotationApiCall.json();

                if (responce.hasOwnProperty('salesQuotationServicesDetailsData')) {
                    let quotationDetails = responce.salesQuotationServicesDetailsData;

                    //  // 2. Remove first old order details.
                    setScheduleDataArray([]);
                    setSalesOrdDetailsTrackingData([]);
                    localStorage.removeItem('scheduledData');
                    taxationSummaryData = [];
                    localStorage.removeItem('taxationSummary');

                    //  // 3. Update the details keys as per the mannual order details.
                    let updatedData = await Promise.all(quotationDetails?.map(async (quotationOrderDetail, index) => {
                        const {
                            product_material_name: product_sr_name,
                            product_material_tech_spect: product_sr_tech_spect,
                            product_material_unit_name: product_rm_stock_unit_name,
                            product_packing_name: product_rm_packing_name,
                            product_material_hsn_sac_code: product_sr_hsn_sac_code,
                            hsn_sac_percent: product_sr_hsn_sac_rate,
                            product_material_std_weight: product_rm_std_weight,
                            product_material_quotation_quantity: product_sr_quantity,
                            product_material_quotation_weight: material_weight,
                            material_std_rate: quotation_rate,
                            material_std_rate: product_sr_std_price,
                            item_discount_percent: material_discount_percent,
                            item_discount_cost: material_discount_amount,
                            material_expected_schedule_date: material_schedule_date,
                            product_material_id: product_sr_id,
                            product_material_unit_id: product_rm_stock_unit_id,
                            product_material_packing_id: product_rm_packing_id,
                            hsn_sac_id: product_sr_hsn_sac_code_id,
                            product_sr_std_hours: product_sr_std_hours,
                            remark: remark,
                            ...rest
                        } = quotationOrderDetail;

                        let keysUpdatedOrderDetail = {
                            product_sr_name, product_sr_tech_spect, product_rm_stock_unit_name, product_rm_packing_name, product_sr_hsn_sac_rate,
                            product_sr_hsn_sac_code, product_rm_std_weight, product_sr_quantity, product_sr_std_hours, remark,
                            material_weight, quotation_rate, product_sr_std_price, material_discount_percent, material_discount_amount, material_schedule_date,
                            product_sr_id, product_rm_stock_unit_id, product_rm_packing_id, product_sr_hsn_sac_code_id, ...rest
                        };

                        keysUpdatedOrderDetail.sr_no = index + 1;
                        // keysUpdatedOrderDetail.so_sr_no = 1;
                        keysUpdatedOrderDetail.sales_order_item_status = "P";
                        keysUpdatedOrderDetail.remark = '';

                        // Check the material schedule-date
                        let matScheduleDateByQuotation = new Date(keysUpdatedOrderDetail.material_schedule_date);
                        let currentDate = new Date();
                        if (matScheduleDateByQuotation < currentDate || isNaN(matScheduleDateByQuotation)) {
                            // set the over_all_schedule_date.
                            keysUpdatedOrderDetail.material_schedule_date = dt_overall_schedule_date;

                            // if dt_overall_schedule_date is also before today then set grace date.
                            matScheduleDateByQuotation = new Date(keysUpdatedOrderDetail.material_schedule_date);
                            if (matScheduleDateByQuotation < currentDate || isNaN(matScheduleDateByQuotation)) {
                                keysUpdatedOrderDetail.material_schedule_date = defaultGracedScheduleDate;
                            }
                        }

                        keysUpdatedOrderDetail.material_basic_amount = validateNumberDateInput.current.decimalNumber((keysUpdatedOrderDetail.product_sr_std_price * parseFloat(keysUpdatedOrderDetail.product_sr_quantity)).toString(), 4);
                        FnCalculateMaterialWiseDiscount(keysUpdatedOrderDetail);

                        keysUpdatedOrderDetail = FnMaterialWiseCalculations(keysUpdatedOrderDetail);      // Send For calculations.
                        return keysUpdatedOrderDetail;
                    }));
                    setSalesOrdDetailsData(updatedData);
                    calculateGrandTotal(updatedData);

                    // Now set the payment terms as per quotation.
                    if (responce.hasOwnProperty('quotationPaymentTermsData')) {
                        let renderedPmtTermsData = JSON.parse(localStorage.getItem('SalesOrdPmtTermsData'));
                        const existingQuotationPaymentTermsData = responce.quotationPaymentTermsData;
                        for (let existPmtCounter = 0; existPmtCounter < renderedPmtTermsData.length; existPmtCounter++) {
                            const renderedPmtTerm = renderedPmtTermsData[existPmtCounter];

                            // Set default data.
                            renderedPmtTerm.payment_terms_Milestome = '';
                            renderedPmtTerm.payment_percent = '';
                            renderedPmtTerm.payment_expected_value = '';
                            renderedPmtTerm.payment_expected_date = '';
                            renderedPmtTerm.payment_receipt_flag = 'P';
                            renderedPmtTerm.payment_receipt_transaction_id = '';
                            renderedPmtTerm.payment_receipt_date = '';
                            renderedPmtTerm.remark = '';

                            existingQuotationPaymentTermsData?.map(quotationBasedPmtTerm => {
                                if (renderedPmtTerm.payment_terms_id === quotationBasedPmtTerm.payment_terms_id) {
                                    // set data according to quotation payment terms.
                                    renderedPmtTerm.payment_terms_Milestome = quotationBasedPmtTerm.payment_terms_Milestome;
                                    renderedPmtTerm.payment_percent = quotationBasedPmtTerm.payment_percent;
                                    renderedPmtTerm.remark = quotationBasedPmtTerm.remark;
                                }
                            });
                            // update it in table data array.
                            renderedPmtTermsData[existPmtCounter] = renderedPmtTerm;
                        }
                        localStorage.setItem('SalesOrdPmtTermsData', JSON.stringify(renderedPmtTermsData));
                        setPmtTermsData(renderedPmtTermsData);
                        // First un-check all checkbox.
                        $('.selectPmtTerm').prop('checked', false);

                        // mark the checked checkboxes for order payment term.
                        existingQuotationPaymentTermsData.forEach(function (existingPmtTerm) {
                            $('#selectPmtTerm_' + existingPmtTerm.payment_terms_id).prop('checked', true);
                        });
                    }
                }

            } else {
                // Clear the Details Section data.
                setSalesOrdDetailsData([]);
                calculateGrandTotal([]);
                setScheduleDataArray([]);
                localStorage.removeItem('salesOrderMaterialSchedules');
                localStorage.removeItem('scheduledData');
                taxationSummaryData = [];
                localStorage.removeItem('taxationSummary');
                setQuotationOpts([]);
                setSalesQuotationNo('');
                setSalesQuotationDate('');
            }
        } catch (error) {
            console.log("Error in getting order details from quotation : ", error)
            navigator('/Error')
        }
    }


    // For update the combobox data after add new record.
    const FnUpdateComboBoxData = async (key) => {
        if (keyForViewUpdate === 'view' || keyForViewUpdate === 'approve') {
            return false;
        }
        switch (key) {
            case 'UpdateConsignees':
            case 'UpdateCustomers':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("is_sez");
                globalQuery.table = "cmv_customer"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedCustomerData = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                if (modalHeaderName === 'Add Customer') {
                    setCustomerOpts(getUpdatedCustomerData);
                } else if (modalHeaderName === 'Add Consignee') {
                    setConsigneeOpts(getUpdatedCustomerData);
                }
                break;

            case 'UpdateAgents':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_agent"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedAgentData = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                setAgentOpts(getUpdatedAgentData);
                break;

            default:
                break;
        }

    }

    const FnGetCustomersContactPersonsDetails = async (customerId) => {
        try {
            if (customerId != null && customerId != undefined && customerId != '' && customerId != NaN) {
                resetGlobalQuery();
                globalQuery.columns.push("customer_id");
                globalQuery.columns.push("customer_branch_name");
                globalQuery.columns.push("cust_branch_id");
                globalQuery.columns.push("customer_contact_id");
                globalQuery.columns.push("cust_contact_person");
                globalQuery.columns.push("cust_contact_no");
                globalQuery.columns.push("cust_alternate_contact");
                globalQuery.columns.push("cust_email_id");
                globalQuery.columns.push("cust_alternate_EmailId");
                globalQuery.table = "cmv_customer_contacts"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "customer_id", operator: "=", value: parseInt(customerId) });
                const getContactDtlsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

                if (getContactDtlsApiCall.length !== 0) {
                    let column = [];
                    let columnHeads = Object.keys(getContactDtlsApiCall[0]);
                    for (let colKey = 0; colKey < columnHeads.length; colKey++) {
                        if (colKey === 0) {
                            column.push({
                                Headers: <div className="col-1"><input type='checkbox' class="form-check-input me-1" name="selectAllCustContact" id="selectAllCustContact" onClick={(e) => { checkBoxesSelection('selectAllCustContact'); }} />Select</div>,
                                accessor: "Action",
                                Cell: row => (
                                    <div className="text-center">
                                        <input type='checkbox' class="form-check-input selectCustContactPerson" name="selectCustContactPerson" id={'selectCustContactPerson_' + row.row.original.customer_contact_id} contact_personId={row.row.original.customer_contact_id} value={row.row.original.customer_contact_id} />
                                    </div>
                                ),
                            });
                        }
                        if (!columnHeads[colKey].includes('customer_id') && !columnHeads[colKey].includes('customer_contact_id') && !columnHeads[colKey].includes('cust_branch_id')) {
                            column.push({ Headers: columnHeads[colKey], accessor: columnHeads[colKey] });
                        }
                    }
                    setCustContactDetailsCols(column);
                    setCustContactDetails(getContactDtlsApiCall);
                } else {
                    setCustContactDetailsCols([]);
                    setCustContactDetails([]);
                }
            } else {
                setCustContactDetailsCols([]);
                setCustContactDetails([]);
            }
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }

        // Onchange event listener for the customer contact Selection
        $('.selectCustContactPerson').on('change', function () {
            checkBoxesSelection('PartiallyCustContactSelection');
        });
    }

    const FnGetPaymentTerms = async () => {
        try {
            const getPaymentTermsApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/paymentterms/FnShowAllActiveRecords/`)
            const responce = await getPaymentTermsApiCall.json();

            const updatedPmtTermsData = responce.data.content?.map((pmtTerm) => {
                const { remark, ...updatedPmtTerm } = pmtTerm;
                return updatedPmtTerm;
            });

            setPmtTermsData(updatedPmtTermsData.length > 0 ? updatedPmtTermsData : []);
            localStorage.setItem('SalesOrdPmtTermsData', JSON.stringify(updatedPmtTermsData));
        } catch (error) {
            console.log("error in getting payment terms master data: ", error)
            navigator('/Error')
        }

        // Onchange event listener for the POTerm Selection
        $('.selectPmtTerm').on('change', function () {
            // if it is checked then focus the.
            let paymentTermId = $(this).attr("pmtTermId");
            if (this.checked) {
                if ($(`#payment_milestone_${paymentTermId}`).val() === '') {
                    $(`#payment_milestone_${paymentTermId}`).focus();

                } else if ($(`#payment_percent_${paymentTermId}`).val() === '') {
                    $(`#payment_percent_${paymentTermId}`).focus();

                } else if ($(`#payment_expected_value_${paymentTermId}`).val() === '') {
                    $(`#payment_expected_date_${paymentTermId}`).focus();

                }
            } else {
                let mileStoneField = document.querySelector('#payment_milestone_' + paymentTermId);
                delete mileStoneField.parentElement.dataset.tip;
            }
            checkBoxesSelection('PartiallyPmtTermsSelection');
        });
    }

    const updatePmtTermsTblData = (currentPaymentTerm, e) => {
        let clickedColName = e.target.getAttribute('Headers');

        switch (clickedColName) {
            case 'payment_expected_value':
                currentPaymentTerm[clickedColName] = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4);
                delete e.target.parentElement.dataset.tip;
                break;

            case 'payment_percent':
                let isValidPercentageInput = validatePercentageInput(e.target.value);
                if (isValidPercentageInput === true) {
                    currentPaymentTerm[clickedColName] = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4);
                    delete e.target.parentElement.dataset.tip;
                } else {
                    const currentTblRow = e.target.parentElement.parentElement;
                    const checkbox = currentTblRow.querySelector('input[id^="selectPmtTerm_"]');
                    if (checkbox.checked) {
                        currentPaymentTerm[clickedColName] = currentPaymentTerm.payment_percent;
                        e.target.parentElement.dataset.tip = isValidPercentageInput;
                    } else {
                        currentPaymentTerm[clickedColName] = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4);
                        delete e.target.parentElement.dataset.tip;
                    }

                }
                break;

            case 'payment_terms_Milestome':
            case 'payment_expected_date':
            case 'remark':
                currentPaymentTerm[clickedColName] = e.target.value;
                delete e.target.parentElement.dataset.tip;
                break;
            default:
                break;
        }

        // update the Payment terms table data.
        const updatedPaymentTermsData = [...PmtTermsdata]
        const pmtTermIndexInArray = parseInt(e.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedPaymentTermsData[pmtTermIndexInArray] = currentPaymentTerm;
        setPmtTermsData(updatedPaymentTermsData);
        localStorage.setItem('SalesOrdPmtTermsData', JSON.stringify(updatedPaymentTermsData));
    }

    const FnGetCommonSalesOrderTerms = async () => {
        try {
            const commonTermsFor = encodeURIComponent("Sales Orders Commercial Terms");
            const poTermsApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CmCommonParameters/FnShowAllActiveRecords/${commonTermsFor}/${COMPANY_ID}`)
            const responce = await poTermsApiCall.json();

            // Set the value common_parameters_value as a common_parameters_expected_value.
            const updatedContent = responce.content.map((commonTerm) => ({
                ...commonTerm,
                common_parameters_expected_value: commonTerm.common_parameters_value,
            }));

            setSOCommonTermsData(updatedContent)
            localStorage.setItem('SalesOrdCommonTermsData', JSON.stringify(responce.content));

        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
        // Onchange event listener for the POTerm Selection
        $('.selectCommonTerm').on('change', function () {
            checkBoxesSelection('PartiallyCommonTermSelection');
        });
    }

    const updateSalesOrdCommonTermsTblData = (currentCommmonTerm, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        currentCommmonTerm[clickedColName] = event.target.value;
        const updatedCommonTermsData = [...SOCommonTermsData];
        const commonTermIndexInArray = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedCommonTermsData[commonTermIndexInArray] = currentCommmonTerm;
        setSOCommonTermsData(updatedCommonTermsData);
        localStorage.setItem('SalesOrdCommonTermsData', JSON.stringify(updatedCommonTermsData));
    }

    const FnGetCustomerContactPersonIds = () => {
        let customerContactPersonsIds = '';
        const checkboxes = $('.selectCustContactPerson:checked');
        checkboxes.each(function () {
            customerContactPersonsIds += $(this).val() + ":";
        });
        return customerContactPersonsIds.replace(/:$/, '');
    }

    const handleCloseBomFilterForm = async () => {

        setShowBomFilterForm(false)
        // let srNo = salesOrdDetailsData.length + 1;
        let NewlyAddedDetailsData = JSON.parse(sessionStorage.getItem('bomfilteredData'));


        if (NewlyAddedDetailsData != null && NewlyAddedDetailsData != undefined) {
            let srNo = salesOrdDetailsData.length + 1;

            // sessionStorage.removeItem('bomfilteredData');

            for (let newMaterialIndex = 0; newMaterialIndex < NewlyAddedDetailsData.length; newMaterialIndex++) {
                let newMaterial = NewlyAddedDetailsData[newMaterialIndex];
                //  const similarMaterials = salesOrdDetailsData.filter((existingMaterials) => existingMaterials.product_sr_id === newMaterial.product_sr_id);

                //  if (similarMaterials.length > 0) {
                //     const existingSoSrNo = similarMaterials.map((material) => material.sr_no);
                //     const maxSoSrNo = Math.max(...existingSoSrNo);
                //      newMaterial.sr_no = maxSoSrNo + 1;
                //  } else {
                //      newMaterial.sr_no = 1;
                //  }

                newMaterial.material_schedule_date = dt_overall_schedule_date;
                newMaterial.sales_order_item_status = 'P';
                newMaterial.material_weight = newMaterial.product_sr_quantity * newMaterial.product_rm_std_weight
                newMaterial.quotation_rate = newMaterial.product_rm_rate;
                newMaterial.material_basic_amount = newMaterial.product_sr_std_price;
                newMaterial.product_sr_quantity = 1;
                newMaterial.quotation_rate = 0;
                newMaterial.remark = '';
                newMaterial.sr_no = srNo;
                FnMaterialWiseCalculations(newMaterial);
                NewlyAddedDetailsData[newMaterialIndex] = newMaterial;
                //srNo++;
            };
            setSalesOrdDetailsData([...salesOrdDetailsData, ...NewlyAddedDetailsData]);
            // sessionStorage.setItem('bomfilteredData', JSON.stringify(NewlyAddedDetailsData));
            calculateGrandTotal([...salesOrdDetailsData, ...NewlyAddedDetailsData]);
            sessionStorage.setItem('isComboFilterExist', false)
            setSalesOrdDetailsData(NewlyAddedDetailsData === null ? [] : NewlyAddedDetailsData)
            removeSessions();
        }

        if (salesOrdDetailsData.length > 0) { $('#cmb_sales_order_type_id').attr('disabled', 'disabled'); }
    };




    const updateSalesOrdDetailsTblData = async (currentRowData, event) => {
        debugger
        let clickedColName = event.target.getAttribute('Headers');
        const excludedColumnsInCalculation = ['product_rm_stock_unit_id', 'material_weight', 'remark', 'material_schedule_date', 'sales_order_item_status', 'product_sr_tech_spect'];

        let updateCalculations = false;
        let inputValue = event.target.value;

        if (!excludedColumnsInCalculation.includes(clickedColName)) {
            inputValue = validateNumberDateInput.current.decimalNumber(inputValue.toString(), 4) || 0;
        }

        // Remove error msgs on input change.
        if (event._reactName === 'onChange') { delete event.target.parentElement.dataset.tip; }

        switch (clickedColName) {
            case 'product_sr_quantity':
                // If Service quantity is changed to less than a previous assigned schedule hten need to remove extra added schedules
                let existingSchedules = JSON.parse(localStorage.getItem('scheduledData')) || [];
                let particularSrIdSchedules = existingSchedules.filter(item => item.product_material_id === currentRowData.product_sr_id);

                if (particularSrIdSchedules && particularSrIdSchedules.length > parseInt(inputValue, 10)) {
                    for (let index = particularSrIdSchedules.length - 1; index >= inputValue; index--) {
                        particularSrIdSchedules.splice(index, 1);
                    }

                    // Update the local storage after removing elements
                    existingSchedules = existingSchedules.filter(item => item.product_material_id !== currentRowData.product_sr_id);
                    existingSchedules = existingSchedules.concat(particularSrIdSchedules);

                    localStorage.setItem('scheduledData', JSON.stringify(existingSchedules));
                    setScheduleDataArray(existingSchedules);
                }

                let material_weight = parseFloat(inputValue) * parseFloat(currentRowData.product_rm_std_weight);
                currentRowData.product_sr_quantity = inputValue
                currentRowData.material_weight = validateNumberDateInput.current.decimalNumber(material_weight.toString(), 4);
                currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((inputValue * parseFloat(currentRowData.product_sr_std_price)).toString(), 4);
                FnCalculateMaterialWiseDiscount(currentRowData);
                updateCalculations = true;
                break;

            case 'product_sr_std_price':
                currentRowData.product_sr_std_price = inputValue;
                currentRowData.quotation_rate = inputValue;
                currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber((inputValue * parseFloat(currentRowData.product_sr_quantity)).toString(), 4);
                FnCalculateMaterialWiseDiscount(currentRowData);
                updateCalculations = true;
                break;

            case 'material_discount_percent':
                let isValidPercentageInput = validatePercentageInput(inputValue);
                if (isValidPercentageInput === true) {
                    const calculatedDiscountAmount = (inputValue / 100) * currentRowData.material_basic_amount;
                    // Then set the discount amount and discount percentage.
                    currentRowData.material_discount_percent = inputValue;
                    currentRowData.material_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedDiscountAmount), 4);
                    delete event.target.parentElement.dataset.tip;
                } else {
                    currentRowData[clickedColName] = currentRowData.material_discount_percent;
                    event.target.parentElement.dataset.tip = isValidPercentageInput;
                }
                updateCalculations = true;
                break;

            case 'material_discount_amount':
                const calculatedPercent = (inputValue * 100) / currentRowData.material_basic_amount;
                // Then set the discount amount and discount percentage.
                currentRowData.material_discount_amount = inputValue;
                currentRowData.material_discount_percent = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedPercent), 4);
                updateCalculations = true;
                break;

            // Non Calculated Fields.
            case 'sales_order_item_status':
                currentRowData[clickedColName] = inputValue;

                if (inputValue === 'Z') {
                    currentRowData.pree_closed_quantity = currentRowData.product_sr_quantity;
                    currentRowData.pree_closed_weight = currentRowData.material_weight;
                } else {
                    currentRowData.pree_closed_quantity = 0;
                    currentRowData.pree_closed_weight = 0;
                }

                // Then update the schedules as per it.
                let materialScheduleTblRows = JSON.parse(localStorage.getItem('scheduledData')) === null ? [] : JSON.parse(localStorage.getItem('scheduledData'));
                // Find and update matching schedules
                let material_status_desc = event.target.options[event.target.selectedIndex].getAttribute('lbl');
                const updatedSchedules = materialScheduleTblRows.map(item => {
                    if (parseInt(item.product_material_id) === parseInt(currentRowData.product_sr_id)) {
                        return {
                            ...item,
                            sales_order_schedules_trading_item_status: inputValue,
                            sales_order_schedules_trading_item_status_desc: material_status_desc,
                        };
                    }
                    return item;
                });
                setScheduleDataArray(updatedSchedules);
                localStorage.setItem('scheduledData', JSON.stringify(updatedSchedules));
                break;


            case 'product_sr_tech_spect':
                currentRowData[clickedColName] = inputValue;
                break;

            case 'material_schedule_date':
            case 'remark':
                currentRowData[clickedColName] = inputValue;
                break;
            default:
                break;
        }

        // Calculate the other dependent Fields on changed field.
        if ((updateCalculations || event._reactName === 'onBlur') && !excludedColumnsInCalculation.includes(clickedColName)) {
            currentRowData = FnMaterialWiseCalculations(currentRowData);
            const updatedDetailsData = [...salesOrdDetailsData]
            const itemArrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
            updatedDetailsData[itemArrayIndex] = currentRowData;
            calculateGrandTotal(updatedDetailsData);
            setSalesOrdDetailsData(updatedDetailsData);

            // Update the footer Discount percentage and amount.
            FnCalculateDiscSum(updatedDetailsData);
        } else {
            const updatedDetailsData = [...salesOrdDetailsData]
            const itemArrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
            updatedDetailsData[itemArrayIndex] = currentRowData;
            setSalesOrdDetailsData(updatedDetailsData);

            // Update the sales order master status.
            if (clickedColName === 'sales_order_item_status') {
                FnUpdateSalesOrderStatus(updatedDetailsData, scheduleDataArray, 'SalesOrderStatus');
            }
        }
    }

    // Fn for sum of discount percent and amount on material chabge
    const FnCalculateDiscSum = (updatedDetailsData) => {
        let material_basic_totals = parseFloat(updatedDetailsData.reduce((total, item) => total + parseFloat(item.material_basic_amount), 0));
        let material_discount_amount = parseFloat(updatedDetailsData.reduce((total, item) => total + parseFloat(item.material_discount_amount), 0));
        let material_discount_percent = (material_discount_amount * 100) / material_basic_totals;
        setDiscountPercent(validateNumberDateInput.current.decimalNumber((material_discount_percent).toString(), 2));
        setDiscountAmt(validateNumberDateInput.current.decimalNumber((material_discount_amount).toString(), 4));
    }

    const FnUpdateSalesOrderStatus = (details, schedules, whichStatus) => {
        switch (whichStatus) {
            case 'SalesOrderStatus':
                const statusWiseMaterialCount = details.reduce((counts, material) => {
                    const status = material.sales_order_item_status;
                    counts[status] = (counts[status] || 0) + 1;
                    return counts;
                }, {});

                const pendingMaterials = statusWiseMaterialCount.P === undefined ? 0 : statusWiseMaterialCount.P
                const approvedMaterials = statusWiseMaterialCount.A === undefined ? 0 : statusWiseMaterialCount.A
                const rejectedMaterial = statusWiseMaterialCount.R === undefined ? 0 : statusWiseMaterialCount.R

                if (pendingMaterials > 0) {
                    setSalesOrdStatus('P');
                } else if (approvedMaterials === details.length) {
                    setSalesOrdStatus('A');
                } else if (rejectedMaterial === details.length) {
                    setSalesOrdStatus('R');
                }
                break;

            case 'Material&SchedulesStatus':
                // update all material and schedules status.
                let salesOrderStatus = $('#cmb_sales_order_status').val();
                let salesOrderStatusDesc = $('#cmb_sales_order_status option:selected').attr('lbl');

                let orderIsPreeClosed = document.querySelector('input[name="rb_preeclose_sales_order"]:checked');
                if (orderIsPreeClosed.value === '1') {
                    salesOrderStatus = 'Z';
                    salesOrderStatusDesc = 'PreeClosed'
                };

                let updatedDetails = details?.map(detail => {
                    return {
                        ...detail,
                        // sales_order_item_status: salesOrderStatus,
                        sales_order_item_status: detail.sales_order_item_status === 'P'
                            ? salesOrderStatus
                            : detail.sales_order_item_status,
                    }
                });
                setSalesOrdDetailsData(updatedDetails);

                // Update Schedules Status.
                let updatedSchedules = schedules?.map(schedule => {
                    return {
                        ...schedule,
                        // sales_order_schedules_trading_item_status: salesOrderStatus,
                        // sales_order_schedules_trading_item_status_desc: salesOrderStatusDesc,
                        sales_order_schedules_trading_item_status: schedule.sales_order_schedules_trading_item_status === 'P'
                            ? salesOrderStatus
                            : schedule.sales_order_schedules_trading_item_status,

                        sales_order_schedules_trading_item_status: schedule.sales_order_schedules_trading_item_status === 'P'
                            ? salesOrderStatusDesc
                            : schedule.sales_order_schedules_trading_item_status,
                    };
                });
                setScheduleDataArray(updatedSchedules);
                localStorage.setItem('scheduledData', JSON.stringify(updatedSchedules));
                break;
        }
    }

    const validatePercentageInput = (percentageInput) => {
        let percentageValidateMsg = '';
        percentageInput = validateNumberDateInput.current.decimalNumber(JSON.stringify(percentageInput), 4);

        if (percentageInput === '') {
            percentageValidateMsg = 'Please fill this field...!';
        } else if (parseFloat(percentageInput) > 100) {
            percentageValidateMsg = 'Percentage should not be greater than 100%...!';
        } else {
            return true;
        }
        return percentageValidateMsg;
    }

    // const handleSoSrNumberChange = async (currentRowData, event) => {

    //     let clickedColName = event.target.getAttribute('Headers');

    //     if (clickedColName === 'so_sr_no') {
    //         if (event._reactName === 'onFocus') {
    //             // OnFocus add the lastSoSrNo Attribute on element. which is used in onBlur event.
    //             let initialSOSrNo = currentRowData.so_sr_no;
    //             event.target.setAttribute('lastSoSrNo', initialSOSrNo);
    //             return;

    //         } else if (event._reactName === 'onChange') {

    //             // First set it last srNo in input field.
    //             const isDuplicateSoSrNo = salesOrdDetailsData.find(orderItem => orderItem.product_sr_id === currentRowData.product_sr_id && orderItem.so_sr_no === parseInt(event.target.value));

    //             if (isDuplicateSoSrNo) {
    //                 event.target.parentElement.dataset.tip = `So_sr_no is duplicate for product ${currentRowData.product_rm_name}...!`;
    //             } else if (event.target.value === '') {
    //                 event.target.parentElement.dataset.tip = `Please enter so_sr_no ${currentRowData.product_rm_name}...!`;
    //             } else {
    //                 delete event.target.parentElement.dataset.tip;
    //             }

    //             if (!isNaN(parseInt(event.target.value, 10))) {
    //                 currentRowData[clickedColName] = parseInt(event.target.value, 10);
    //             } else {
    //                 currentRowData[clickedColName] = '';
    //             }

    //         } else if (event._reactName === 'onBlur') {
    //             // update the schedules and taxations objects.
    //             let initialSOSrNo = parseInt(event.target.getAttribute('lastSoSrNo'));
    //             let updatedSoSrNo = parseInt(event.target.value, 10);

    //             if (event.target.value === '' || isNaN(updatedSoSrNo)) {
    //                 currentRowData.so_sr_no = initialSOSrNo;
    //             } else {
    //                 const similarMaterialsBySoSrNo = salesOrdDetailsData.filter((material) => parseInt(material.product_sr_id) === parseInt(currentRowData.product_sr_id) && parseInt(material.so_sr_no) === parseInt(updatedSoSrNo));
    //                 if (similarMaterialsBySoSrNo.length > 1) {
    //                     currentRowData.so_sr_no = initialSOSrNo;
    //                 } else {
    //                     currentRowData.so_sr_no = updatedSoSrNo;
    //                 }
    //             }

    //             // Means initial SoSrNo is exist then update the schedules as per the new SoSrNo.
    //             if (!isNaN(initialSOSrNo) && initialSOSrNo !== updatedSoSrNo) {
    //                 // All existing schedules for all materials
    //                 let allExistingSchedules = JSON.parse(localStorage.getItem('salesOrderMaterialSchedules'));

    //                 if (allExistingSchedules !== null) {        // Means schedules are available.
    //                     let updatedShedules = [];
    //                     const { schedulesWithoutCurrentMat, schedulesToUpdate } = allExistingSchedules.reduce(
    //                         (result, schedule) => {
    //                             if (parseInt(schedule.so_sr_no) === parseInt(initialSOSrNo) && schedule.product_material_id === currentRowData.product_sr_id) {
    //                                 result.schedulesToUpdate.push(schedule);
    //                             } else {
    //                                 result.schedulesWithoutCurrentMat.push(schedule);
    //                             }
    //                             return result;
    //                         },
    //                         { schedulesWithoutCurrentMat: [], schedulesToUpdate: [] }
    //                     );

    //                     if (schedulesToUpdate.length > 0) {
    //                         const updatedMaterialSchedules = schedulesToUpdate.map((schedule, index) => {
    //                             const updatedId = `${currentRowData.product_sr_id}:${currentRowData.so_sr_no}:${index}`;
    //                             return {
    //                                 ...schedule, id: updatedId,
    //                                 so_sr_no: currentRowData.so_sr_no,
    //                             };
    //                         });
    //                         // merge the schedules existing and updated.
    //                         updatedShedules = schedulesWithoutCurrentMat.concat(updatedMaterialSchedules);
    //                     } else {
    //                         updatedShedules = allExistingSchedules;
    //                     }

    //                     updatedShedules.sort((material1, material2) => material1.product_material_id - material2.product_material_id);
    //                     setScheduleDataArray(updatedShedules);
    //                     localStorage.setItem('salesOrderMaterialSchedules', JSON.stringify(updatedShedules));
    //                 }

    // Update the taxation summary data.
    //                 if (taxationSummary.length > 0) {

    //                     const { taxationSummaryWithoutCurrentMat, taxationSummaryWithCurrentMat } = taxationSummary.reduce(
    //                         (result, taxSummary) => {
    //                             if (parseInt(taxSummary.so_sr_no) === parseInt(initialSOSrNo) && parseInt(taxSummary.materialId) === parseInt(currentRowData.product_sr_id)) {
    //                                 result.taxationSummaryWithCurrentMat.push(taxSummary);
    //                             } else {
    //                                 result.taxationSummaryWithoutCurrentMat.push(taxSummary);
    //                             }
    //                             return result;
    //                         },
    //                         { taxationSummaryWithoutCurrentMat: [], taxationSummaryWithCurrentMat: [] }
    //                     );

    //                     if (taxationSummaryWithCurrentMat.length > 0) {
    //                         const updatedMaterialTaxSummary = taxationSummaryWithCurrentMat.map((schedule, index) => {
    //                             return {
    //                                 ...schedule,
    //                                 so_sr_no: parseInt(currentRowData.so_sr_no),
    //                             };
    //                         });
    //                         // merge the taxation existing and updated.
    //                         taxationSummary = taxationSummaryWithoutCurrentMat.concat(updatedMaterialTaxSummary);
    //                         FnGetTaxationSummary();
    //                     }
    //                     localStorage.setItem('taxationSummary', JSON.stringify(taxationSummary));
    //                 }
    //             }
    //             delete event.target.parentElement.dataset.tip;
    //         }

    //         const updatedDetailsData = [...salesOrdDetailsData]
    //         const itemArrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
    //         updatedDetailsData[itemArrayIndex] = currentRowData;
    //         setSalesOrdDetailsData(updatedDetailsData);
    //     }
    // }

    const updateTblCalculationStateChange = () => {
        let updatedTblData = [];
        for (let rowIndex = 0; rowIndex < salesOrdDetailsData.length; rowIndex++) {
            let updatedRowData = FnMaterialWiseCalculations(salesOrdDetailsData[rowIndex]);
            updatedTblData.push(updatedRowData);
        }
        setSalesOrdDetailsData(updatedTblData);
        calculateGrandTotal(updatedTblData);
    }

    // Calculate discount amount 
    const FnCalculateMaterialWiseDiscount = (currentMaterial) => {
        const { material_discount_percent, material_basic_amount, } = currentMaterial;
        const calculatedAmount = (material_discount_percent / 100) * material_basic_amount;
        currentMaterial.material_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedAmount), 4);
    }

    const FnMaterialWiseCalculations = (currentRowData) => {

        let custState = parseInt($('#cmb_customer_state_id').val());
        let custStateCode = parseInt($('#cmb_customer_state_id option:selected').attr('stateCode'));
        let companyBranchStateId = parseInt($('#cmb_companyBranchStateId').val());
        let customerIsSez = $('#cmb_customer_id option:selected').attr('is_sez');

        if (!isNaN(custState)) {
            let discAmt = isNaN(parseFloat(currentRowData.material_discount_amount)) ? 0 : parseFloat(currentRowData.material_discount_amount);
            let hsn_code_rate = isNaN(parseFloat(currentRowData.product_sr_hsn_sac_rate)) ? 0 : parseFloat(currentRowData.product_sr_hsn_sac_rate);

            let total_amount = 0; let cgst_sgst_per = 0; let cgst_amt = 0;
            let sgst_amt = 0; let igst_per = 0; let igst_amt = 0;

            const material_taxable_amount = currentRowData.material_basic_amount - discAmt;
            let so_rate = material_taxable_amount;
            if (parseFloat(currentRowData.product_sr_quantity) !== 0 && !isNaN(parseFloat(currentRowData.product_sr_quantity))) {
                so_rate = parseFloat(material_taxable_amount) / parseFloat(currentRowData.product_sr_quantity)
            }
            // const so_rate = parseFloat(material_taxable_amount) / parseFloat(currentRowData.product_rm_quantity);

            if (custStateCode === 99) {
                cgst_sgst_per = 0; cgst_amt = 0; sgst_amt = 0; igst_per = 0; igst_amt = 0;
                total_amount = material_taxable_amount;

            } else if (customerIsSez === 'true') {
                // If customer is sezzed
                cgst_sgst_per = 0; cgst_amt = 0; sgst_amt = 0; igst_per = 0; igst_amt = 0;
                total_amount = material_taxable_amount;

            } else if (companyBranchStateId === custState) {
                cgst_sgst_per = hsn_code_rate / 2;
                const gst_amt = (material_taxable_amount * hsn_code_rate) / 100;
                cgst_amt = gst_amt / 2;
                sgst_amt = gst_amt / 2;
                total_amount = material_taxable_amount + gst_amt;
                // remove the igst taxation
                igst_per = 0; igst_amt = 0;

            } else {
                igst_per = hsn_code_rate;
                igst_amt = (material_taxable_amount * hsn_code_rate) / 100;
                total_amount = material_taxable_amount + igst_amt;
                // remove the cgtst, sgst taxation
                cgst_sgst_per = 0; cgst_amt = 0; sgst_amt = 0;
            }

            currentRowData['material_taxable_amount'] = validateNumberDateInput.current.decimalNumber(material_taxable_amount.toString(), 4);
            currentRowData['so_rate'] = validateNumberDateInput.current.decimalNumber(so_rate.toString(), 4);
            currentRowData['material_cgst_percent'] = validateNumberDateInput.current.decimalNumber(cgst_sgst_per.toString(), 4);
            currentRowData['material_cgst_total'] = validateNumberDateInput.current.decimalNumber(cgst_amt.toString(), 4);
            currentRowData['material_sgst_percent'] = validateNumberDateInput.current.decimalNumber(cgst_sgst_per.toString(), 4);
            currentRowData['material_sgst_total'] = validateNumberDateInput.current.decimalNumber(sgst_amt.toString(), 4);
            currentRowData['material_igst_percent'] = validateNumberDateInput.current.decimalNumber(igst_per.toString(), 4);
            currentRowData['material_igst_total'] = validateNumberDateInput.current.decimalNumber(igst_amt.toString(), 4);
            currentRowData['material_total_amount'] = validateNumberDateInput.current.decimalNumber(total_amount.toString(), 4);

            // push data in taxation summary list.
            let taxSummary = {
                materialId: currentRowData.product_sr_id,
                //so_sr_no: currentRowData.so_sr_no,
                hsnCode: currentRowData.product_sr_hsn_sac_code,
                hsn_code_id: currentRowData.product_sr_hsn_sac_code_id,
                hsnRate: currentRowData.product_sr_hsn_sac_rate,
                taxableAmt: currentRowData.material_taxable_amount,
                CGSTPercent: currentRowData.material_cgst_percent,
                CGSTAmt: currentRowData.material_cgst_total,
                SGSTPercent: currentRowData.material_sgst_percent,
                SGSTAmt: currentRowData.material_sgst_total,
                IGSTPercent: currentRowData.material_igst_percent,
                IGSTAmt: currentRowData.material_igst_total,
                totalAmt: currentRowData.material_total_amount
            };

            let taxationSummary = JSON.parse(localStorage.getItem('taxationSummary')) === null ? [] : JSON.parse(localStorage.getItem('taxationSummary'));
            const materialIndex = taxationSummary.findIndex((item) => parseInt(item.materialId) === parseInt(currentRowData.product_sr_id));
            if (materialIndex !== -1) {
                taxationSummary[materialIndex] = taxSummary;
            } else {
                taxationSummary.push(taxSummary);
            }
            localStorage.setItem('taxationSummary', JSON.stringify(taxationSummary));
            $('#error_cmb_customer_state_id').hide();
        } else {
            $('#error_cmb_customer_state_id').text('Please select customer state...!');
            $('#error_cmb_customer_state_id').show();
        }
        return currentRowData;
    };

    // Function to normalize hsnCode values for grouping this function remove the space for the set as a properObjKey
    function normalizeHsnCode(hsnCode) {

        return hsnCode.replace(/\s+/g, '').toUpperCase();
    }

    const FnGetTaxationSummary = () => {

        let taxSummaryData = JSON.parse(localStorage.getItem('taxationSummary')) === null ? [] : JSON.parse(localStorage.getItem('taxationSummary'));
        if (taxSummaryData === null || taxSummaryData.length === 0) {
            setTaxationSummaryData([])
            return null;
        }

        const groupedData = taxSummaryData.reduce((acc, item) => {
            const hsnCode = normalizeHsnCode(item.hsnCode);
            if (!acc[hsnCode]) { acc[hsnCode] = []; }
            acc[hsnCode].push(item);
            return acc;
        }, {});

        if (Object.keys(groupedData).length > 0) {
            const hsnCodesGrps = Object.keys(groupedData);
            let taxationTblData = [];
            for (let hsnType = 0; hsnType < hsnCodesGrps.length; hsnType++) {
                let particularHSNGrpList = groupedData[hsnCodesGrps[hsnType]]
                const groupTotalIGSTAmt = particularHSNGrpList.reduce((total, item) => total + parseFloat(item.IGSTAmt), 0);
                const groupTotalSGSTAmt = particularHSNGrpList.reduce((total, item) => total + parseFloat(item.SGSTAmt), 0);
                const groupTotalCGSTAmt = particularHSNGrpList.reduce((total, item) => total + parseFloat(item.CGSTAmt), 0);
                const groupTotalAmt = particularHSNGrpList.reduce((total, item) => total + parseFloat(item.totalAmt), 0);
                const groupTaxableAmt = particularHSNGrpList.reduce((total, item) => total + parseFloat(item.taxableAmt), 0);

                let groupData = {
                    product_sr_hsn_sac_code: particularHSNGrpList[0].hsnCode,
                    hsn_id: particularHSNGrpList[0].hsn_code_id,
                    summary_taxable_amount: validateNumberDateInput.current.decimalNumber(groupTaxableAmt.toString(), 4),
                    summary_cgst_percent: particularHSNGrpList[0].CGSTPercent,
                    summary_cgst_total: validateNumberDateInput.current.decimalNumber(groupTotalCGSTAmt.toString(), 4),
                    summary_sgst_percent: particularHSNGrpList[0].SGSTPercent,
                    summary_sgst_total: validateNumberDateInput.current.decimalNumber(groupTotalSGSTAmt.toString(), 4),
                    summary_igst_percent: particularHSNGrpList[0].IGSTPercent,
                    summary_igst_total: validateNumberDateInput.current.decimalNumber(groupTotalIGSTAmt.toString(), 4),
                    summary_total_amount: validateNumberDateInput.current.decimalNumber(groupTotalAmt.toString(), 4),
                }
                taxationTblData.push(groupData);
            }
            // await FnSetTaxationSummaryColumns();
            setTaxationSummaryData(taxationTblData)
        } else {
            setTaxationSummaryData([])
        }
    }

    const handleFooterDiscountPercentChange = async (e) => {
        let isValidPercentageInput = validatePercentageInput(e.target.value);

        if (isValidPercentageInput === true) {
            const percentInput = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4)
            const amount = (percentInput / 100) * txt_basic_total;
            setDiscountAmt((amount).toFixed(2));
            setDiscountPercent(percentInput);
            calculateGrandTotal(salesOrdDetailsData);
            $('#error_txt_discount_percent').hide();

        } else {
            $('#error_txt_discount_percent').text(isValidPercentageInput);
            $('#error_txt_discount_percent').show();
        }

        if (e._reactName === 'onBlur' && e.target.value === '') {
            setDiscountPercent(0);
            setDiscountPercent(0);
            calculateGrandTotal(salesOrdDetailsData);
            delete e.target.parentElement.dataset.tip;
        }
        setTimeout(() => { 
            calculateGrandTotal(salesOrdDetailsData); 
            reverseCalculationForDetailData();
        }, 200);
    };
    const handleFooterDiscountAmountChange = async (e) => {
        const amount = validateNumberDateInput.current.decimalNumber(e.target.value.toString(), 4)
        const percent = (amount * 100) / txt_basic_total;

        if (e._reactName === 'onBlur' && e.target.value === '') {
            setDiscountPercent(0);
            setDiscountAmt(0);
            delete e.target.parentElement.dataset.tip;
        } else {
            setDiscountPercent((percent).toFixed(2));
            setDiscountAmt(amount);
        }

        setTimeout(() => { 
            calculateGrandTotal(salesOrdDetailsData); 
            reverseCalculationForDetailData();
        }, 200);
    };

    // Reverse Calculations to update dettails data table on change of discount & freight amount
    const reverseCalculationForDetailData = () => {
        debugger
        let salesOrderData = [...salesOrdDetailsData];
        // const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
        const discount_amount = isNaN(parseFloat($('#txt_discount_amount').val())) ? 0 : parseFloat(parseFloat($('#txt_discount_amount').val()))

        if (salesOrderData.length !== 0) {

            const updatedData = salesOrderData.map(obj => {
                // Calculate item_freight_amount and discount_amount
                // let itemFreightAmount = (obj.material_basic_amount / txt_basic_total) * freightAmt;
                let discountAmount;
                let calculatedPercent;
                if (discountAmount !== 0) {
                    discountAmount = (obj.material_basic_amount / txt_basic_total) * discount_amount;
                    calculatedPercent = (discountAmount * 100) / obj.material_basic_amount;
                }
                // Create a new object with the updated properties
                return {
                    ...obj,
                    // material_freight_amount: validateNumberDateInput.current.decimalNumber(JSON.stringify(itemFreightAmount), 4),
                    material_discount_amount: discountAmount === 0 ? obj.material_discount_amount : validateNumberDateInput.current.decimalNumber(JSON.stringify(discountAmount), 4),
                    material_discount_percent: calculatedPercent === 0 ? obj.material_discount_percent : validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedPercent), 2)
                };
            });
            setSalesOrdDetailsData(updatedData)
        }
    }

    const calculateGrandTotal = (salesOrdDetailsData) => {
        let taxSummaryData = JSON.parse(localStorage.getItem('taxationSummary')) === null ? [] : JSON.parse(localStorage.getItem('taxationSummary'));
        let customerState = $('#cmb_customer_state_id').val();
        let custStateCode = parseInt($('#cmb_customer_state_id option:selected').attr('stateCode'));
        let companyBranchStateId = $('#cmb_companyBranchStateId').val();
        // let freightIsTaxable = document.getElementById('chk_freight_isTaxable').checked;
        // let freightTaxHSNIsSelected = $('#cmb_freight_hsn_id').val();

        // if (freightIsTaxable && (freightTaxHSNIsSelected === '' || freightTaxHSNIsSelected === null)) {
        //     $('#cmb_freight_hsn_id').removeAttr('optional', 'optional');
        //     $('#error_cmb_freight_hsn_id').text('Please select the freight tax hsn code.');
        //     $('#error_cmb_freight_hsn_id').show();
        //     return false;
        // } else if (!freightIsTaxable) {
        //     setFreightHSNId('');
        //}

        if (salesOrdDetailsData !== null && salesOrdDetailsData.length > 0) {
            let material_basic_total = salesOrdDetailsData.reduce((total, item) => total + parseFloat(item.material_taxable_amount), 0);
            setBasicTotal(validateNumberDateInput.current.decimalNumber(material_basic_total.toString(), 4));

            let cgst_total = salesOrdDetailsData.reduce((total, item) => total + parseFloat(item.material_cgst_total), 0);
            let sgst_total = salesOrdDetailsData.reduce((total, item) => total + parseFloat(item.material_sgst_total), 0);
            let igst_total = salesOrdDetailsData.reduce((total, item) => total + parseFloat(item.material_igst_total), 0);

            let discAmt = isNaN(parseFloat($('#txt_discount_amount').val())) === true ? 0 : parseFloat($('#txt_discount_amount').val())
            const transportAmt = isNaN(parseFloat($('#txt_transport_amount').val())) === true ? 0 : parseFloat($('#txt_transport_amount').val())
            //const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) === true ? 0 : parseFloat($('#txt_freight_amount').val())
            const packingAmt = isNaN(parseFloat($('#txt_packing_amount').val())) === true ? 0 : parseFloat($('#txt_packing_amount').val())
            const otherAmt = isNaN(parseFloat($('#txt_other_amount').val())) === true ? 0 : parseFloat($('#txt_other_amount').val())

            const taxableAmount = material_basic_total - discAmt;
            setTaxableTotal(validateNumberDateInput.current.decimalNumber(taxableAmount.toString(), 4));

            // if (freightIsTaxable) {
            //let taxSummary = {
            // materialId: 'Freight', so_sr_no: 'Freight',
            // hsnCode: $('#cmb_freight_hsn_id option:selected').attr('lbl'),
            // hsn_code_id: $('#cmb_freight_hsn_id').val(),
            //  hsnRate: $('#cmb_freight_hsn_id option:selected').attr('rate'),
            // taxableAmt: CGSTPercent: 0, CGSTAmt: 0,
            // SGSTPercent: 0, SGSTAmt: 0, IGSTPercent: 0, IGSTAmt: 0, totalAmt: 0
            // };

            let customerIsSez = $('#cmb_customer_id option:selected').attr('is_sez');
            //  $('#cmb_freight_hsn_id').removeAttr('optional');
            //let freightTaxRate = isNaN(parseFloat($('#cmb_freight_hsn_id option:selected').attr('rate'))) === true ? 0 : parseFloat($('#cmb_freight_hsn_id option:selected').attr('rate'));
            //const freightTaxAmt = (freightAmt * freightTaxRate) / 100;

            if (customerIsSez === 'true') {
                // If customer is sezzed
                igst_total += 0;
                cgst_total += 0;
                sgst_total += 0;

            } else if (custStateCode === 99) {
                // Set outer teritory tax totals
                igst_total += 0;
                cgst_total += 0;
                sgst_total += 0;

            }
            //else if (companyBranchStateId === customerState) {
            // Set inter state tax totals
            // taxSummary['CGSTAmt'] = (freightTaxAmt / 2);
            //  taxSummary['CGSTPercent'] = (freightTaxRate / 2);
            // taxSummary['SGSTAmt'] = (freightTaxAmt / 2);
            // taxSummary['SGSTPercent'] = (freightTaxRate / 2);
            //   taxSummary['totalAmt'] = (freightAmt + freightTaxAmt);
            //
            //  cgst_total += freightTaxAmt / 2;
            //  sgst_total += freightTaxAmt / 2;

            // } else {
            // Set outer state tax totals
            //   taxSummary['IGSTAmt'] = (freightTaxAmt);
            //  taxSummary['IGSTPercent'] = (freightTaxRate);
            //  taxSummary['totalAmt'] = (freightAmt + freightTaxAmt);

            // igst_total += freightTaxAmt;
            // }
            //  taxSummaryData.push(taxSummary);      
            //const existingFreightTaxationObj = taxSummaryData.findIndex((record) => record.materialId === "Freight" && record.so_sr_no === "Freight");
            // if (existingFreightTaxationObj !== -1) {
            // taxSummaryData[existingFreightTaxationObj] = taxSummary;   // Replace the object in-place
            // } else {
            // Push the new object if not found
            //}
            // } else {
            //$('#cmb_freight_hsn_id').attr('optional', 'optional');
            //  // if it is exist in the taxation summary object then remove it
            // const existingFreightTaxationObj = taxSummaryData.findIndex((record) => record.materialId === "Freight" && record.so_sr_no === "Freight");
            // if (existingFreightTaxationObj !== -1) { taxSummaryData.splice(existingFreightTaxationObj, 1); }
            // }
            // localStorage.setItem('taxationSummary', JSON.stringify(taxSummaryData));

            setCGSTTotal(validateNumberDateInput.current.decimalNumber((cgst_total).toString(), 4));
            setSGSTTotal(validateNumberDateInput.current.decimalNumber((sgst_total).toString(), 4));
            setIGSTTotal(validateNumberDateInput.current.decimalNumber((igst_total).toString(), 4))

            let grandTotal = taxableAmount + transportAmt + packingAmt + otherAmt + cgst_total + sgst_total + igst_total;
            setGrandTotal(Math.round(grandTotal))

            let roundOffValue = grandTotal - Math.floor(grandTotal);

            if (roundOffValue >= 0.50) {
                roundOffValue = roundOffValue - 1; // Subtract 1 to get negative round-off
            }
            setRoundOff((roundOffValue).toFixed(2))
        } else {
            setBasicTotal(0);
            setTransportAmt(0);
            setTaxableTotal(0);
            setGrandTotal(0);
            setRoundOff(0);
            setCGSTTotal(0);
            setSGSTTotal(0);
            setIGSTTotal(0);
        }
        FnGetTaxationSummary();
    }

    const removeMaterial = async (currentOrderItem) => {


        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            let detailTblRows = [...salesOrdDetailsData];      // Store all table rows original object in array.

            // Remove it from details.
            let rowIndexInDetailsTbl = detailTblRows.findIndex((record) => parseInt(record.product_sr_id) === parseInt(currentOrderItem.product_sr_id));
            if (rowIndexInDetailsTbl !== -1) {
                detailTblRows.splice(rowIndexInDetailsTbl, 1);
                detailTblRows.forEach((material, index) => {
                    material.sr_no = index + 1;
                });
                setSalesOrdDetailsData(detailTblRows);                            // Set that updated data in table data hook;
            }

            // Remove it from taxation summary.
            // let taxSummaryData = JSON.parse(localStorage.getItem('taxationSummary')) === null ? [] : JSON.parse(localStorage.getItem('taxationSummary'));
            // let deletedMaterialIndex = taxSummaryData.findIndex((record) => parseInt(record.materialId) === parseInt(currentOrderItem.product_sr_id));
            // if (deletedMaterialIndex !== -1) { taxSummaryData.splice(deletedMaterialIndex, 1); }
            // localStorage.setItem('taxationSummary', JSON.stringify(taxSummaryData));

            // remove the schedules for that material
            let materialScheduleTblRows = JSON.parse(localStorage.getItem('scheduledData')) === null ? [] : JSON.parse(localStorage.getItem('scheduledData'));
            if (materialScheduleTblRows.length > 0) {
                const updatedMaterialSchedules = materialScheduleTblRows.filter(
                    item => parseInt(item.product_material_id) !== parseInt(currentOrderItem.product_sr_id)
                );
                setScheduleDataArray(updatedMaterialSchedules);
                localStorage.setItem('scheduledData', JSON.stringify(updatedMaterialSchedules));
            }

            setTimeout(() => { calculateGrandTotal(detailTblRows); }, 100);
        }
    }

    const addNewScheduleRow = () => {
        addScheduleTblRow(schedulerowcount + 1);
        setSchedulerowcount(schedulerowcount + 1);
    }

    // Create a formatting function inline
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const FnGetNextExpectedDate = (fromDate, afterDays) => {
        const dtAfterGraceDays = new Date(fromDate);
        dtAfterGraceDays.setDate(dtAfterGraceDays.getDate() + afterDays);
        const year = dtAfterGraceDays.getFullYear();
        const month = String(dtAfterGraceDays.getMonth() + 1).padStart(2, '0');
        const day = String(dtAfterGraceDays.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const FnGetPreviousExpectedDate = (fromDate, beforeDays) => {
        const dtAfterGraceDays = new Date(fromDate);
        dtAfterGraceDays.setDate(dtAfterGraceDays.getDate() - beforeDays);
        const year = dtAfterGraceDays.getFullYear();
        const month = String(dtAfterGraceDays.getMonth() + 1).padStart(2, '0');
        const day = String(dtAfterGraceDays.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const FnSetServiceDateProcessDuration = (processDuration) => {
        switch (processDuration) {

            case 'One Time':
                return 0;
            case 'Daily':
                return 1;
            case 'Weekly':
                return 7;
            case 'ForthNightly':
                return 14;
            case 'Monthly':
                return 30;
            case '2 Monthly':
                return 60;
            case '3 Monthly':
                return 90;
            case 'Quarterly':
                return 91;
            case 'Half Yearly':
                return 180;
            case 'Yearly':
                return 365;

            default:
                break;
        }
    }

    const openSchedules = (rowData) => {

        const { product_sr_id, product_sr_name, process_duration, product_sr_quantity,
            product_sr_std_price, product_sr_std_hours, product_sr_tech_spect, remark } = rowData


        let sheduleStatus = rowData.sales_order_item_status;
        let totalServiceDays = Math.ceil(rowData.product_sr_std_hours / 8);
        let serviceStartDate = new Date(rowData.material_schedule_date);

        if (keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve') {
            setSchedules(true);

            setTimeout(() => {
                // First set the header labels and values.
                $('#productMatName').append(product_sr_name);
                $('#productMatName').attr('product_sr_id', product_sr_id);
                $('#processduration_').append(process_duration);
                $('#scheduleQty-').append(product_sr_quantity);
                $('#stdPrice_').append(product_sr_std_price);
                $('#stdhr').append(product_sr_std_hours);
                $('#techspec').append(product_sr_tech_spect);

                let getExistingSchedules = JSON.parse(localStorage.getItem('scheduledData'))
                let existingRecordsById = [];


                if (getExistingSchedules !== null) {
                    existingRecordsById = getExistingSchedules.filter(item => item.product_sr_id === product_sr_id)
                    existingRecordsById.forEach((item, index) => {
                        addScheduleTblRow(index + 1, rowData);

                        $(`#serviceStartDate_${index + 1}`).val(item.expected_schedule_start_date);
                        $(`#serviceEndDate_${index + 1}`).val(item.expected_schedule_end_date);
                        $(`#remark-${index + 1}`).val(item.remark);
                    })

                }
                const remainingSchduleQty = parseInt(product_sr_quantity) - existingRecordsById.length
                let startCount = existingRecordsById.length + 1

                // else {
                for (let serviceCounter = startCount; serviceCounter < startCount + remainingSchduleQty; serviceCounter++) {

                    // Add row for schedule table.
                    addScheduleTblRow(serviceCounter, rowData)

                    if (serviceCounter === 1) {
                        $('#serviceStartDate_1').val(rowData.material_schedule_date);
                        $('#serviceEndDate_1').val(FnGetNextExpectedDate(rowData.material_schedule_date, totalServiceDays));
                    } else {

                        let previousServiceStartDate = $('#serviceStartDate_' + (serviceCounter - 1)).val()
                        let currentSchStartDate = FnGetNextExpectedDate(previousServiceStartDate, FnSetServiceDateProcessDuration(process_duration));

                        $('#serviceStartDate_' + serviceCounter).val(currentSchStartDate);
                        $('#serviceEndDate_' + serviceCounter).val(FnGetNextExpectedDate(currentSchStartDate, totalServiceDays));
                    }

                }
                // }
            }, 300);
        }


    }




    const addScheduleTblRow = (rowCount, rowData) => {

        // First create a row and append to the table.
        if (rowCount !== 1) {
            let newServiceSheduleRow = `<tr scheduleTrCount="${rowCount}" id="RMSchedulesEntry-tr-${rowCount}"></tr>`
            $("#scheduleTable tbody").append(newServiceSheduleRow);
        }

        const createTd1 = $('<input type="date" rowIndex="' + rowCount + '"  class="serviceStartDate" id="serviceStartDate_' + rowCount + '"   />').addClass("erp_input_field mb-0")
        const createTd2 = $('<input type="date" rowIndex="' + rowCount + '"  class="serviceEndDate"  id="serviceEndDate_' + rowCount + '"   />').addClass("erp_input_field mb-0")
        const createTd3 = $('<textarea type="text" rowIndex="' + rowCount + '"  rowIndex="' + rowCount + '" id="remark-' + rowCount + '" maxLength="255" ></textarea>').addClass("erp_input_field mb-0")

        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd1));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd2));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd3));

        const datePicker = document.getElementById('serviceStartDate_' + rowCount);
        datePicker.min = todayDate;

        $('.serviceStartDate').on('change', function () {
            const rowIndex = $(this).attr('rowIndex');
            const totalServiceDays = Math.ceil(rowData.product_sr_std_hours / 8);
            const StartDateChange = $(this).val();
            const EndDateChange = FnGetNextExpectedDate(StartDateChange, totalServiceDays);
            $('#serviceEndDate_' + rowIndex).val(EndDateChange);
        });


        $('.serviceEndDate').on('change', function () {
            const rowIndex = $(this).attr('rowIndex');
            const totalServiceDays = Math.ceil(rowData.product_sr_std_hours / 8);
            const EndDateChange = $(this).val();
            const StartDateChange = FnGetPreviousExpectedDate(EndDateChange, totalServiceDays);
            $('#serviceStartDate_' + rowIndex).val(StartDateChange);

        });


        // Function to calculate the previous expected date
        function FnGetPreviousExpectedDate(currentDate, daysToSubtract) {
            const currentDateObj = new Date(currentDate);
            const newDateObj = new Date(currentDateObj);
            newDateObj.setDate(currentDateObj.getDate() - daysToSubtract);

            // Format the date as YYYY-MM-DD (same as the input date format)
            const formattedDate = newDateObj.toISOString().split('T')[0];
            return formattedDate;
        }

    }

    const FnValidateSchedules = () => {

        let result = true;
        const table = document.getElementById('scheduleTable');
        const tbody = table.querySelector('tbody');
        const trElements = tbody.querySelectorAll('tr');
        let addedQty = 0;

        for (let count = 0; count < trElements.length; count++) {
            let scheduleTrCount = trElements[count].getAttribute('scheduleTrCount');
            let scheduleQty = $('#scheduleQty-' + scheduleTrCount).val();
            let scheduleQtyinp = document.querySelector('#scheduleQty-' + scheduleTrCount);
            addedQty += parseFloat(scheduleQty);
            const scheduleDate = $('#serviceStartDate_' + scheduleTrCount).val();
            const scheduleDateinp = document.querySelector('#serviceStartDate_' + scheduleTrCount);
            if (scheduleDate === '') {
                scheduleDateinp.parentElement.dataset.tip = 'Please enter start date!...';
                return false;
            } else { delete scheduleDateinp.parentElement.dataset.tip; }


        }




        addedQty = validateNumberDateInput.current.decimalNumber((addedQty).toString(), 4);
        let totalQty = validateNumberDateInput.current.decimalNumber(($('#productMatQty').text()).toString(), 4);


        return result;
    }

    function schedule() {


        let checkIsValidated = FnValidateSchedules();

        if (checkIsValidated === true) {
            const table = document.getElementById('scheduleTable');
            const tbody = table.querySelector('tbody');
            const trElements = tbody.querySelectorAll('tr');
            let updatedScheduleDataArray = [...scheduleDataArray];

            // let soSrNo = parseInt($('#soSrNo').attr('soSrNo'));
            let materialId = parseInt($('#productMatName').attr('product_sr_id'));

            //This will check if already exist
            updatedScheduleDataArray = updatedScheduleDataArray.filter(
                item => parseInt(item.product_sr_id) !== parseInt(materialId)
            );

            for (let count = 0; count < trElements.length; count++) {
                let scheduleTrCount = trElements[count].getAttribute('scheduleTrCount');
                const scheduleData = {
                    id: parseInt($('#productMatName').attr('product_sr_id')) + count,
                    product_material_id: materialId,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: SHORT_FINANCIAL_YEAR,
                    product_sr_id: materialId,
                    sales_order_master_transaction_id: 0,
                    sales_order_details_transaction_id: 0,
                    product_sr_name: $('#productMatName').text(),
                    // product_sr_name: $('#productMatName').text(),
                    process_duration: $('#processduration_').text(),
                    sales_order_no: $('#txt_sales_order_no').val(),
                    sales_order_date: $('#dt_sales_order_date').val(),
                    sales_order_version: $('#txt_sales_order_version').val(),
                    product_type_id: $('#cmb_sales_order_type_id').val(),
                    product_type: $('#cmb_sales_order_type_id option:selected').attr('lbl'),
                    customer_order_no: txt_customer_order_no === "" ? txt_sales_order_no : txt_customer_order_no,
                    customer_order_Date: dt_customer_order_Date === "" ? dt_sales_order_date : dt_customer_order_Date,
                    product_sr_quantity: $('#scheduleQty-' + scheduleTrCount).val(),
                    product_material_unit_id: $('#productMatUnit').attr('product_material_unit_id'),
                    // product_sr_std_price: $('#stdPrice_' + scheduleTrCount).val(),
                    // product_sr_tech_spect: $('#techspec').text(),
                    product_sr_std_hours: $('#stdhr').text(),
                    expected_schedule_start_date: $('#serviceStartDate_' + scheduleTrCount).val(),
                    expected_schedule_end_date: $('#serviceEndDate_' + scheduleTrCount).val(),
                    //expected_schedule_date: $('#scheduleDate-' + scheduleTrCount).val(),
                    remark: $('#remark-' + scheduleTrCount).val(),
                    product_sr_tech_spect: $('#techspec').text(),
                    created_by: UserName
                }

                // Add the new data to the updatedScheduleDataArray
                updatedScheduleDataArray.push(scheduleData);
            }
            updatedScheduleDataArray.sort((material1, material2) => material1.product_material_id - material2.product_material_id);
            setScheduleDataArray(updatedScheduleDataArray);
            localStorage.setItem('scheduledData', JSON.stringify(updatedScheduleDataArray));

            setSchedulerowcount(1);
            setSchedules(false)
        }
    }

    const deleteSchedules = (idToRemove) => {
        if (keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve') {
            // let updatedScheduleDataArray = [...scheduleDataArray]
            let scheduleData = JSON.parse(localStorage.getItem('scheduledData')) === null ? [] : JSON.parse(localStorage.getItem('scheduledData'));
            const { updateSchedulesData, scheduleToRemove } = scheduleData.reduce(
                (result, schedule) => {
                    if (schedule.id !== idToRemove) { result.updateSchedulesData.push(schedule); }
                    else { result.scheduleToRemove = schedule; }
                    return result;
                },
                { updateSchedulesData: [], scheduleToRemove: null }
            );

            const [materialId, materialSoSrNo] = idToRemove.split(':').map(Number);
            const simillarMaterialSchedule = updateSchedulesData.filter(item => item.product_material_id === materialId);
            if (simillarMaterialSchedule.length > 0) {
                // let maxSoSrNo = simillarMaterialSchedule.reduce((max, schedule) => Math.max(max, schedule.so_sr_no), -Infinity);
                const lastScheduleForSimilarMat = updateSchedulesData.findIndex(item => item.product_material_id === materialId);
                if (lastScheduleForSimilarMat !== -1) {
                    // validateNumberDateInput.current.decimalNumber(e.target.value, 4)
                    let updatedQty = updateSchedulesData[lastScheduleForSimilarMat].product_material_schedule_quantity + scheduleToRemove.product_material_schedule_quantity;
                    let updatedWt = updateSchedulesData[lastScheduleForSimilarMat].product_material_schedule_weight + scheduleToRemove.product_material_schedule_weight;

                    updateSchedulesData[lastScheduleForSimilarMat].product_material_schedule_quantity = validateNumberDateInput.current.decimalNumber((updatedQty).toString(), 4)
                    updateSchedulesData[lastScheduleForSimilarMat].product_material_schedule_weight = validateNumberDateInput.current.decimalNumber((updatedWt).toString(), 4)
                }
            }
            setScheduleDataArray(updateSchedulesData)
            localStorage.setItem('scheduledData', JSON.stringify(updateSchedulesData));
        }
    }

    const GenerateSalesOrderNo = async (product_type_short_name) => {
        // const salesOrderTypeShortName = salesOrdTypesOpts.find(item => item.field_id === parseInt($('#cmb_sales_order_type_id').val()));
        const getSalesOrderNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("mt_sales_order_master_services", "sales_order_no", product_type_short_name, 'SOS', "6");
        setSalesOrdNo(getSalesOrderNoApiCall);
        setSalesOrdVer(1);
        return getSalesOrderNoApiCall;
    };
    const ExportToExcel = async () => {
        let headerFormIsValid = await validate.current.validateForm("salesOrderFormId");

        if (salesOrdDetailsData.length !== 0 && headerFormIsValid) {
            let jsonToExportExcel = { 'allData': {}, 'columns': {}, 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [7, 8] }

            let columnExport = ['sr_no', 'product_sr_name', 'product_sr_tech_spect', 'process_duration', 'product_sr_std_hours', 'product_sr_hsn_sac_code', 'product_sr_hsn_sac_rate', 'product_sr_quantity', 'product_sr_std_price',
                'material_basic_amount', 'material_discount_percent', 'material_discount_amount', 'material_taxable_amount', 'material_cgst_percent', 'material_cgst_total', 'material_sgst_percent', 'material_sgst_total',
                'material_igst_percent', 'material_igst_total', 'material_total_amount', 'material_schedule_date', 'remark',
                'product_sr_id', 'product_sr_hsn_sac_code_id'];

            let filtrKeyValue = {}; // cmb_sales_order_type_id

            filtrKeyValue['0'] = "Order Creation Type : " + $("input[name='rb_sales_order_creation_type']:checked").attr("lbl") + "(" + rb_sales_order_creation_type + ")"
            filtrKeyValue['1'] = "Order Type : " + $('#cmb_sales_order_type_id').find(":selected").text() + "(" + cmb_sales_order_type_id + ")"
            filtrKeyValue['2'] = "Sales Order No. : " + txt_sales_order_no
            filtrKeyValue['3'] = "Order Version : " + txt_sales_order_version
            filtrKeyValue['4'] = "Order Date : " + (dt_sales_order_date !== null ? validateNumberDateInput.current.formatDateToDDMMYYYY(dt_sales_order_date) : '')
            filtrKeyValue['5'] = "Order Life : " + $("input[name='rb_sales_order_life']:checked").attr("lbl") + "(" + rb_sales_order_life + ")"
            filtrKeyValue['6'] = "Order Status : " + $('#cmb_sales_order_status').find(":selected").text() + "(" + cmb_sales_order_status + ")"
            filtrKeyValue['7'] = "Customer : " + $('#cmb_customer_id').find(":selected").text() + "(" + cmb_customer_id + ")"
            filtrKeyValue['8'] = "Customer Order No. : " + txt_customer_order_no
            filtrKeyValue['9'] = "Customer Order Date : " + (dt_customer_order_Date !== null ? validateNumberDateInput.current.formatDateToDDMMYYYY(dt_customer_order_Date) : dt_sales_order_date)
            filtrKeyValue['10'] = "Quotation No. : " + ($('#cmb_sales_quotation_no').find(":selected").text() !== 'Select' ? $('#cmb_sales_quotation_no').find(":selected").text() : '');
            filtrKeyValue['11'] = "Quotation Date : " + (dt_sales_quotation_date !== null ? validateNumberDateInput.current.formatDateToDDMMYYYY(dt_sales_quotation_date) : '')
            filtrKeyValue['12'] = "Company Branch : " + $('#cmb_companyBranchId').find(":selected").text() + "(" + cmb_companyBranchId + ")"
            filtrKeyValue['13'] = "Company State : " + $('#cmb_companyBranchStateId').find(":selected").text() + "(" + cmb_companyBranchStateId + ")"
            filtrKeyValue['14'] = "Company City : " + $('#cmb_companyBranchCityId').find(":selected").text() + "(" + cmb_companyBranchCityId + ")"
            // filtrKeyValue['14'] = "Department : " + $('#cmb_department_id').find(":selected").text() + "(" + cmb_department_id + ")"
            filtrKeyValue['15'] = "Department : " + (cmb_department_id === '0' || cmb_department_id === '' || cmb_department_id === null ? '' : $('#cmb_department_id').find(":selected").text() + "(" + cmb_department_id + ")")
            filtrKeyValue['16'] = "Approved By : " + (cmb_approved_by_id === '0' || cmb_approved_by_id === '' || cmb_approved_by_id === null ? '' : $('#cmb_approved_by_id').find(":selected").text() + "(" + cmb_approved_by_id + ")")
            filtrKeyValue['17'] = "Approved Date : " + (dt_approved_date === null ? '' : validateNumberDateInput.current.formatDateToDDMMYYYY(dt_approved_date))
            filtrKeyValue['18'] = "Expected Schedule Date : " + validateNumberDateInput.current.formatDateToDDMMYYYY(dt_overall_schedule_date)
            filtrKeyValue['19'] = "Consignee : " + (cmb_consignee_id === '0' || cmb_consignee_id === '' ? '' : $('#cmb_consignee_id').find(":selected").text() + "(" + cmb_consignee_id + ")")
            filtrKeyValue['20'] = "Consignee State : " + (cmb_consignee_state_id === '0' || cmb_consignee_state_id === '' ? '' : $('#cmb_consignee_state_id').find(":selected").text() + "(" + cmb_consignee_state_id + ")")
            filtrKeyValue['21'] = "Consignee City : " + (cmb_consignee_city_id === '0' || cmb_consignee_city_id === '' ? '' : $('#cmb_consignee_city_id').find(":selected").text() + "(" + cmb_consignee_city_id + ")")
            filtrKeyValue['22'] = "Remark : " + txt_remark
            filtrKeyValue['23'] = "Order IsActive : " + $("input[name='rb_is_active']:checked").attr("lbl") + "(" + rb_is_active + ")"

            jsonToExportExcel['filtrKeyValue'] = filtrKeyValue;

            // set columns name.
            jsonToExportExcel['columns'] = columnExport.reduce((column, colName, colIndex) => {
                column[colIndex] = colName;
                return column;
            }, {});

            // set table data.   // Create a new object with the updated key name
            salesOrdDetailsData.map((orderDetail, Index) => {
                const keysConvertedOrderDetail = {
                    ...orderDetail,
                    remark: orderDetail.remark,
                    material_name: orderDetail.product_sr_name,
                    material_technical_specification: orderDetail.product_sr_tech_spect,
                    material_unit_name: orderDetail.product_sr_name,
                    material_packing: orderDetail.product_sr_packing_name,
                    mateiral_hsn_code: orderDetail.product_sr_hsn_sac_code,
                    mateiral_hsn_rate: orderDetail.product_sr_hsn_sac_rate,
                    mateiral_std_weight: orderDetail.product_st_std_weight,
                    product_sr_quantity: orderDetail.product_sr_quantity,
                    mateiral_rate: orderDetail.product_sr_std_price,
                    // material_schedule_date: await validateNumberDateInput.current.formatDateToDDMMYYYY(orderDetail.material_schedule_date),
                    material_id: orderDetail.product_sr_id,
                };
                // keysConvertedOrderDetail['material_schedule_date'] = await validateNumberDateInput.current.formatDateToDDMMYYYY(orderDetail.material_schedule_date)
                jsonToExportExcel['allData'][Index] = keysConvertedOrderDetail;
            });

            jsonToExportExcel['headings']['ReportName'] = "Sales Order Service Details"
            jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
            jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
            console.log("jsonToExportExcel: ", jsonToExportExcel)
            exlsExp.current.excel(jsonToExportExcel, txt_sales_order_no + "@" + COMPANY_ID)

        } else {
            setErrMsg('Please add at least one material!... ')
            setShowErrorMsgModal(true)
        }
    }

    const onImportClick = () => {
        importFile.current.click();
    };

    const importedExcelFile = async (event) => {
        try {
            debugger
            if (event.target.files) {
                $('#spinner_id').show();
                $('#spinner_text').text('Loading...');
                let file = event.target.files[0];

                const formData = new FormData();
                formData.append(`file`, file)
                const requestOptions = { method: 'POST', body: formData };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesOrderMasterService/FnReadExcel`, requestOptions)
                const fetchRes = await apicall.json();

                if (fetchRes.success === '1') {
                    const salesOrderNo = fetchRes.formFieldData.find(item => item.includes("Sales Order No."))?.split(":")[1]?.trim();

                    let comboNames = ['Order Creation Type', 'Order Type', 'Order Life', 'Order Status', 'Customer',
                        'Quotation No.', 'Company Branch', 'Department', 'Consignee', 'Order IsActive'
                    ]

                    if (fetchRes.data.length !== 0 && txt_sales_order_no === salesOrderNo) {
                        for (let formFieldIndex = 0; formFieldIndex < fetchRes.formFieldData.length; formFieldIndex++) {
                            const element = fetchRes.formFieldData[formFieldIndex];

                            if (element !== "") {
                                const field = element.split(':');
                                const fieldName = field[0].trim();
                                const fieldVal = field[1].trim();
                                const dateParts = fieldVal.split("-");

                                let comboVal;
                                if (fieldVal !== "" && comboNames.includes(fieldName)) {
                                    let lastBracesVal = fieldVal.match(/\(([^)]*)\)\s*$/);
                                    comboVal = lastBracesVal[1];
                                }
                                switch (fieldName) {
                                    case 'Order Creation Type': setSalesOrdCreationType(comboVal); break;
                                    case 'Order Type': setSalesOrderTypeId(comboVal); break;
                                    case 'Sales Order No.': setSalesOrdNo(fieldVal); break;
                                    case 'Order Version': setSalesOrdVer(fieldVal); break;
                                    case 'Order Date': setSalesOrdDate(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`); break;
                                    case 'Order Life': setSalesOrdLife(comboVal); break;
                                    case 'Order Status': setSalesOrdStatus(comboVal); break;
                                    case 'Customer':
                                        setCustomerId(comboVal);
                                        await FnComboOnChange('Customer');
                                        break;
                                    case 'Customer Order No.': setCustomerOrderNo(fieldVal); break;
                                    case 'Customer Order Date': setCustomerOrdDate(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`); break;
                                    case 'Quotation No.': setSalesQuotationNo(comboVal); break;
                                    case 'Quotation Date': setSalesQuotationDate(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`); break;
                                    case 'Company Branch':
                                        setCompanyBranchId(comboVal);
                                        await FnComboOnChange('CompanyBranch');
                                        break;
                                    case 'Company State': break;
                                    case 'Company City': break;
                                    case 'Department': setDeptId(comboVal); break;
                                    case 'Approved By': break;
                                    case 'Approved Date': break;
                                    case 'Expected Schedule Date': setOverAllScheduleDate(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`); break;
                                    case 'Consignee':
                                        setCustomerId(comboVal);
                                        await FnComboOnChange('Consignee');
                                        break;
                                    case 'Consignee State': break;
                                    case 'Consignee City': break;
                                    case 'Remark': setRemark(fieldVal); break;
                                    case 'Order IsActive': setIsActive(comboVal); break;
                                    default: break;
                                }
                            }
                        }
                        console.log('readedOrderDetailsFromExcel: ' + fetchRes.data);
                        const readedOrderDetailsFromExcel = fetchRes.data?.map(dataRow => {
                            const orderDetail = fetchRes.columns.reduce((acc, key, secIndex) => {
                                acc[key] = dataRow[secIndex];
                                return acc;
                            }, {});
                            return orderDetail;
                        });

                        console.log('readedOrderDetailsFromExcel after updatation: ' + readedOrderDetailsFromExcel);
                        // setBomfilteredData(readedOrderDetailsFromExcel);

                        setTimeout(() => {
                            let updatedTblData = [];
                            for (let rowIndex = 0; rowIndex < readedOrderDetailsFromExcel.length; rowIndex++) {
                                let updatedRowData = FnMaterialWiseCalculations(readedOrderDetailsFromExcel[rowIndex]);
                                updatedTblData.push(updatedRowData);
                            }
                            setSalesOrdDetailsData(updatedTblData);
                            calculateGrandTotal(updatedTblData);
                            sessionStorage.setItem('bomfilteredData', JSON.stringify(updatedTblData))
                        }, 1000);

                    } else if (txt_sales_order_no !== salesOrderNo) {
                        setErrMsg('You are trying to import the wrong order file...!')
                        setShowErrorMsgModal(true)
                    }

                }
                else {
                    setErrMsg(fetchRes.error)
                    setShowErrorMsgModal(true)
                }
                $('#spinner_id').hide();

            }
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }

    const removeSessions = async () => {
        sessionStorage.removeItem(`comboFilterData`)
        sessionStorage.removeItem('isComboFilterExist')
        sessionStorage.removeItem('smv_product_type');
        sessionStorage.removeItem('smv_product_material_type');
        sessionStorage.removeItem('smv_product_material_grade');
        sessionStorage.removeItem('smv_product_material_shape');
        localStorage.removeItem('scheduledData');

        for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
            sessionStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
        }

        localStorage.removeItem('smv_product_material_type');
        localStorage.removeItem('smv_product_material_grade');
        localStorage.removeItem('smv_product_material_shape');
        localStorage.removeItem('smv_product_type');

        localStorage.removeItem('smv_product_material_typeName');
        localStorage.removeItem('smv_product_material_gradeName');
        localStorage.removeItem('smv_product_material_shapeName');
        localStorage.removeItem('smv_product_typeName');
    }

    const validateFields = () => {
        let formObj = $('#salesOrderFormId');
        let inputObj;
        for (let i = 0; i <= formObj.get(0).length - 1; i++) {
            inputObj = formObj.get(0)[i];
            if (inputObj.type === 'text' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'select-one' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'textarea' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'date' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            }
        }

        formObj = $('#salesOrderTotalsFrm');
        for (let i = 0; i <= formObj.get(0).length - 1; i++) {
            inputObj = formObj.get(0)[i];
            if (inputObj.type === 'text' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'select-one' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'textarea' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'date' && inputObj.value !== '') {
                $("#error_" + inputObj.id).hide();
            }
        }
    }

    function scrollToTableRow() {
        let keywords = $("#txt-detail-data-highliter").val();
        if (keywords !== '') {
            let table = document.getElementById('salesOrdMaterialDtlsTbl');
            let tblRows = table.getElementsByTagName('tr');
            for (let trNum = 1; trNum < tblRows.length; trNum++) {
                let row = tblRows[trNum];
                if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
                    let w = $(window);
                    let row = $('#salesOrdMaterialDtlsTbl').find('tr').eq(trNum);
                    if (row.length) {
                        w.scrollTop(row.offset().top - (w.height() / 2));
                    }
                    $(row).find('td').each(function () {
                        $(this).addClass('highlight');
                    });
                    removeOtherTrHighlightClass(trNum)
                    break;
                } else {
                    // $(row).find('td').each(function () { $(this).removeClass('highlight'); });
                    $("#salesOrdMaterialDtlsTbl td.highlight").removeClass("highlight");
                }
            }
        } else {
            $("#salesOrdMaterialDtlsTbl td.highlight").removeClass("highlight");
        }
    }

    const removeOtherTrHighlightClass = (rowNum) => {
        let table = document.getElementById('salesOrdMaterialDtlsTbl');
        let tblRows = table.getElementsByTagName('tr');
        for (let trNum = 1; trNum < tblRows.length; trNum++) {
            let row = tblRows[trNum];
            if (trNum !== rowNum) {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }

    const moveToListing = () => {
        // Remove all sessions.
        sessionStorage.removeItem('keyForViewUpdate');
        sessionStorage.removeItem('SalesOrderDetails');
        sessionStorage.removeItem('bomfilteredData');
        sessionStorage.removeItem('orderDetailsData')
        localStorage.removeItem('SalesOrdPmtTermsData');
        localStorage.removeItem('salesOrderMaterialSchedules');
        localStorage.removeItem('scheduledData');
        localStorage.removeItem('SalesOrdCommonTermsData');
        localStorage.removeItem('taxationSummary');
        sessionStorage.removeItem('salesOrder_no');

        const Path = compType === 'Register' ? '/Transactions/TSalesOrder/Projects/SalesOrderProjectsListing/reg' : `/Transactions/TSalesOrder/Projects/SalesOrderProjectsListing`;
        navigator(Path);

    }

    function checkBoxesSelection(key) {
        switch (key) {
            // For Payment terms
            case "selectAllPmtTerms":
                $('.selectPmtTerm').prop('checked', $('#selectAllPmtTerms').is(":checked"));
                break;
            case 'PartiallyPmtTermsSelection':
                $('#selectAllPmtTerms').prop('checked', $('input:checkbox.selectPmtTerm:checked').length === $('input:checkbox.selectPmtTerm').length);
                break;
            // For customer contact
            case 'selectAllCustContact':
                $('.selectCustContactPerson').prop('checked', $('#selectAllCustContact').is(":checked"));
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;
            case 'PartiallyCustContactSelection':
                $('#selectAllCustContact').prop('checked', $('input:checkbox.selectCustContactPerson:checked').length === $('input:checkbox.selectCustContactPerson').length);
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;
            // For Common terms
            case 'selectAllCommonTerms':
                $('.selectCommonTerm').prop('checked', $('#selectAllCommonTerms').is(":checked"));
                break;
            case 'PartiallyCommonTermSelection':
                $('#selectAllCommonTerms').prop('checked', $('input:checkbox.selectCommonTerm:checked').length === $('input:checkbox.selectCommonTerm').length);
                break;
            default:
                break;
        }
    }

    const FnSetDefaultFormFields = async () => {
        // Header section form fields.
        setSalesOrdCreationType('M');
        setSalesOrderTypeId('');
        setSalesOrdNo(0);
        setSalesOrdVer(1);
        setSalesOrdDate(todayDate);
        setSalesOrdLife('C');
        // customer details form fields
        setCustomerId('');
        setCustomerOrderNo('');
        setCustomerOrdDate('');
        setCustomerState('');
        setCustomerCityOpts([]);
        setCustomerCity('');

        setSalesQuotationNo('');
        setSalesQuotationDate('');

        // consignee details form fields
        setConsigneeId('');
        setConsigneeState('');
        setConsigneeCityOpts([]);
        setConsigneeCity('');
        // Sanction details form fields
        setDeptId('');
        setApproveByOpts([]);
        // setApprovedById('');
        // setApproveDate('');
        setOverAllScheduleDate(FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays));
        setRemark('');
        setIsActive('true');
        // Taxation and Billing section form fields
        setBasicTotal(0);
        setTransportAmt(0);
        setFreightAmt(0);
        setFreightIsTaxable(false);
        // setFreightHSNId('');
        setPackingAmt(0);
        setDiscountPercent(0);
        setDiscountAmt(0);
        setOtherAmt(0);
        setCGSTPercent(0);
        setCGSTTotal(0);
        setSGSTPercent(0);
        setSGSTTotal(0);
        setIGSTPercent(0);
        setIGSTTotal(0);
        setTaxableTotal(0);
        setRoundOff(0);
        setGrandTotal(0);
        setAgentId('');
        setAgentPercent(0);
        setAgentPaidStatus('P');
        setSalesOrdStatus('P');
        setSalesOrdAcceptanceStatus('P');
        setStatusRemark('');
        setOtherTermsConditions('');
        setSalesOrderMailSentStatus('P');
        // Payment Term Details.
        $('#selectAllPmtTerms').prop('checked', false);
        $('.selectPmtTerm').prop('checked', false);
        $('#selectAllCommonTerms').prop('checked', false);
        $('.selectCommonTerm').prop('checked', false);

        // Customers contact details table data hooks
        setCustContactDetails([]);
        setCustContactDetailsCols([]);

        sessionStorage.removeItem('txt_sales_order_no')
        sessionStorage.removeItem('bomfilteredData')
        sessionStorage.removeItem('orderDetailsData')
        localStorage.removeItem('salesOrderMaterialSchedules');
        localStorage.removeItem('scheduledData');
        localStorage.removeItem('taxationSummary');

        setSalesOrdDetailsData([]);
        setScheduleDataArray([]);
        taxationSummary = [];
        setTaxationSummaryData([]);
        await FnGetPaymentTerms();
        await FnGetCommonSalesOrderTerms();
    }

    const acceptSalesOrder = async () => {
        $('#spinner_id').show();
        $('#spinner_text').text('Validating Order Details...');
        try {
            let customerSOAcceptanceJson = {
                sales_order_master_transaction_id: sales_order_master_transaction_id,
                sales_order_acceptance_status: 'A'
            }
            const formData = new FormData();
            formData.append(`customerSOAcceptanceJson`, JSON.stringify(customerSOAcceptanceJson))
            const requestOptions = { method: 'POST', body: formData };
            const acceptSalesOrderAPICall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesOrderMasterService/FnAcceptCustomerOrder/${COMPANY_ID}`, requestOptions)
            const acceptSalesOrderAPICallResponse = await acceptSalesOrderAPICall.json();
            $('#spinner_id').hide();

            if (acceptSalesOrderAPICallResponse.success === "1") {

                setSuccMsg(acceptSalesOrderAPICallResponse.message)
                setShowSuccessMsgModal(true);
            } else {
                setErrMsg(acceptSalesOrderAPICallResponse.error);
                setShowErrorMsgModal(true);
            }

        } catch (error) {
            console.log('Error in accepting sales order: ', error)
            $('#spinner_id').hide();
            navigator('/Error')
        }
    }

    const FnGenerateSalesOrder = async (e, ordStatus) => {

        $('#spinner_id').show();
        $('#spinner_text').text('Validating Order Details...');
        try {
            let salesOrderIsValid;
            let clickedBtnType = e.target.getAttribute('BtnType')

            if (clickedBtnType !== 'save') {
                if (clickedBtnType === 'reject' || rb_preeclose_sales_order === 'Z' && $('#txt_status_remark').val().trim() === '') {
                    $('#spinner_id').hide();
                    $('#error_txt_status_remark').text(`Please enter the remark for ${$("#cmb_").attr("lbl")}.`);
                    $('#error_txt_status_remark').show();
                    return false;
                } else if (clickedBtnType === 'approve' || clickedBtnType === 'reject') {
                    salesOrderIsValid = true;
                }
            } else if (clickedBtnType !== 'approve' && clickedBtnType !== 'reject') {
                salesOrderIsValid = await FnValidateSalesOrder();
            }

            if (salesOrderIsValid) {
                if (keyForViewUpdate === 'update') {
                    $('#spinner_text').text('Updating Sales Order Details...');
                } else if (keyForViewUpdate === 'approve') {
                    if (clickedBtnType === 'approve') {
                        $('#spinner_text').text('Approving Sales Order...');
                    } else if (clickedBtnType === 'reject') {
                        $('#spinner_text').text('Rejecting Sales Order...');
                    }
                } else {
                    $('#spinner_text').text('Generating Sales Order...');
                }

                let salesOrderNo = txt_sales_order_no
                if (keyForViewUpdate !== 'update' && keyForViewUpdate !== 'approve') {
                    let salesOrderTypeShortName = $('#cmb_sales_order_type_id option:selected').attr('shortName');
                    salesOrderNo = await GenerateSalesOrderNo(salesOrderTypeShortName);
                }

                let json = { 'TransMasterData': {}, 'TransDetailData': [], 'TransScheduleData': [], 'TransPaymentTermsData': [], 'TransTaxSummaryData': [], 'TransSalesOrderTermsData': [], 'commonIds': {} }

                // mt_sales_order_master_trading table object
                let customerContactIds = FnGetCustomerContactPersonIds();
                const salesOrderMasterFormData = {
                    company_id: COMPANY_ID,
                    company_branch_id: cmb_companyBranchId,
                    financial_year: SHORT_FINANCIAL_YEAR,
                    sales_order_master_transaction_id: 0,
                    sales_order_no: salesOrderNo,
                    sales_order_version: txt_sales_order_version,
                    sales_order_type_id: cmb_sales_order_type_id,
                    sales_order_type: $('#cmb_sales_order_type_id option:selected').attr('lbl'),
                    sales_order_date: dt_sales_order_date,
                    customer_id: cmb_customer_id,
                    customer_order_no: txt_customer_order_no === "" ? salesOrderNo : txt_customer_order_no,
                    customer_order_Date: dt_customer_order_Date === "" ? dt_sales_order_date : dt_customer_order_Date,
                    sales_order_creation_type: rb_sales_order_creation_type,
                    sales_order_life: rb_sales_order_life,
                    sales_quotation_no: cmb_sales_quotation_no,
                    sales_quotation_date: dt_sales_quotation_date,
                    customer_state_id: cmb_customer_state_id,
                    customer_city_id: cmb_customer_city_id,
                    customer_contacts_ids: customerContactIds,
                    consignee_id: cmb_consignee_id,
                    consignee_state_id: cmb_consignee_state_id,
                    consignee_city_id: cmb_consignee_city_id,
                    department_id: cmb_department_id,
                    approved_by_id: cmb_approved_by_id,
                    approved_date: dt_approved_date,
                    overall_schedule_date: dt_overall_schedule_date,
                    remark: txt_remark,
                    is_active: rb_is_active,
                    basic_total: txt_basic_total,
                    transport_amount: txt_transport_amount,
                    freight_amount: txt_freight_amount,
                    // is_freight_taxable: chk_freight_isTaxable,
                    // freight_hsn_code_id: cmb_freight_hsn_id,
                    packing_amount: txt_packing_amount,
                    discount_percent: txt_discount_percent,
                    discount_amount: txt_discount_amount,
                    other_amount: txt_other_amount,
                    cgst_percent: txt_cgst_percent,
                    cgst_total: txt_cgst_total,
                    sgst_percent: txt_sgst_percent,
                    sgst_total: txt_sgst_total,
                    igst_percent: txt_igst_percent,
                    igst_total: txt_igst_total,
                    taxable_total: txt_taxable_total,
                    roundoff: txt_roundoff,
                    grand_total: txt_grand_total,
                    agent_id: cmb_agent_id,
                    agent_percent: txt_agent_percent,
                    agent_paid_status: cmb_agent_paid_status,
                    sales_order_status: rb_preeclose_sales_order === '1' ? 'Z' : keyForViewUpdate !== 'approve' ? 'A' : ordStatus,
                    sales_order_acceptance_status: rb_sales_order_acceptance_status,
                    status_remark: txt_status_remark,
                    other_terms_conditions: txt_other_terms_conditions,
                    sales_order_mail_sent_status: rb_sales_order_mail_sent_status,
                    created_by: UserName
                }
                if (cmb_consignee_id === '' || cmb_consignee_id === undefined) {
                    salesOrderMasterFormData['consignee_id'] = cmb_customer_id;
                    salesOrderMasterFormData['consignee_state_id'] = cmb_customer_state_id;
                    salesOrderMasterFormData['consignee_city_id'] = cmb_customer_city_id;
                }

                if (keyForViewUpdate === 'approve') {
                    salesOrderMasterFormData['sales_order_master_transaction_id'] = sales_order_master_transaction_id !== undefined ? sales_order_master_transaction_id : 0;
                    // salesOrderMasterFormData['sales_order_status'] = rb_preeclose_sales_order === '1' ? 'Z' : clickedBtnType === 'approve' && cmb_sales_order_status === 'P' ? 'A' : clickedBtnType === 'reject' && cmb_sales_order_status === 'P' ? 'R' : cmb_sales_order_status;

                    if (rb_preeclose_sales_order === '1') {
                        salesOrderMasterFormData['sales_order_status'] = 'Z'
                    } else if (clickedBtnType === 'approve' && cmb_sales_order_status === 'P') {
                        salesOrderMasterFormData['sales_order_status'] = 'A'
                    } else if (clickedBtnType === 'reject' && cmb_sales_order_status === 'P') {
                        salesOrderMasterFormData['sales_order_status'] = 'R'
                    } else {
                        salesOrderMasterFormData['sales_order_status'] = cmb_sales_order_status
                    }
                }
                json.TransMasterData = salesOrderMasterFormData;

                // mt_sales_order_details_trading table object
                for (let materialCount = 0; materialCount < salesOrdDetailsData.length; materialCount++) {
                    const salesOrderMaterial = salesOrdDetailsData[materialCount];

                    let orderMaterialJson = {
                        company_id: COMPANY_ID,
                        company_branch_id: cmb_companyBranchId,
                        sales_order_details_transaction_id: salesOrderMaterial.sales_order_details_transaction_id !== undefined && isApprove ? salesOrderMaterial.sales_order_details_transaction_id : 0,
                        sales_order_master_transaction_id: salesOrderMaterial.sales_order_master_transaction_id,
                        financial_year: SHORT_FINANCIAL_YEAR,
                        sales_order_no: salesOrderNo,
                        sales_order_version: txt_sales_order_version,
                        sales_order_date: dt_sales_order_date,
                        sales_quotation_no: cmb_sales_quotation_no,
                        sales_quotation_date: dt_sales_quotation_date,
                        customer_order_no: txt_customer_order_no === "" ? txt_sales_order_no : txt_customer_order_no,
                        customer_order_Date: dt_customer_order_Date === "" ? dt_sales_order_date : dt_customer_order_Date,
                        product_type_id: cmb_sales_order_type_id,
                        product_type: $('#cmb_sales_order_type_id option:selected').attr('lbl'),
                        product_material_id: salesOrderMaterial.product_sr_id,
                        product_material_print_name: salesOrderMaterial.product_sr_name,
                        product_material_tech_spect: salesOrderMaterial.product_sr_tech_spect,
                        remark: salesOrderMaterial.remark,
                        sr_no: salesOrderMaterial.sr_no,
                        // so_sr_no: salesOrderMaterial.so_sr_no,
                        product_material_unit_id: salesOrderMaterial.product_sr_stock_unit_id,
                        product_material_packing_id: salesOrderMaterial.product_sr_packing_id,
                        product_material_hsn_code_id: salesOrderMaterial.product_sr_hsn_sac_code_id,
                        material_quantity: salesOrderMaterial.product_sr_quantity,
                        material_weight: salesOrderMaterial.material_weight,
                        quotation_rate: salesOrderMaterial.quotation_rate,
                        material_rate: salesOrderMaterial.product_sr_std_price,
                        material_basic_amount: salesOrderMaterial.material_basic_amount,
                        material_discount_percent: salesOrderMaterial.material_discount_percent,
                        material_discount_amount: salesOrderMaterial.material_discount_amount,
                        so_rate: salesOrderMaterial.so_rate,
                        material_taxable_amount: salesOrderMaterial.material_taxable_amount,
                        material_cgst_percent: salesOrderMaterial.material_cgst_percent,
                        material_cgst_total: salesOrderMaterial.material_cgst_total,
                        material_sgst_percent: salesOrderMaterial.material_sgst_percent,
                        material_sgst_total: salesOrderMaterial.material_sgst_total,
                        material_igst_percent: salesOrderMaterial.material_igst_percent,
                        material_igst_total: salesOrderMaterial.material_igst_total,
                        material_total_amount: salesOrderMaterial.material_total_amount,
                        material_schedule_date: salesOrderMaterial.material_schedule_date,
                        // sales_order_item_status: salesOrderMaterial.sales_order_item_status === 'P' && isApprove ? ordStatus : salesOrderMaterial.sales_order_item_status,
                        sales_order_item_status: 'A',
                        remark: salesOrderMaterial.remark,
                        created_by: UserName
                    }
                    json.TransDetailData.push(orderMaterialJson)
                }

                // Trans Schedule Data     
                for (let index = 0; index < salesOrdDetailsData.length; index++) {
                    const element = salesOrdDetailsData[index];
                    let scData = scheduleDataArray.filter(item => parseInt(item.product_sr_id) === parseInt(element.product_sr_id));
                    if (scData.length === 0) {
                        const totalServiceDays = Math.ceil(element.product_sr_std_hours / 8);

                        for (let index = 0; index < element.product_sr_quantity; index++) {
                            let dt_expected_schedule_start_date = element.material_schedule_date
                            let dt_expected_schedule_end_date = FnGetNextExpectedDate(dt_expected_schedule_start_date, totalServiceDays)

                            if (index !== 0) {
                                let previousServiceStartDate = json.TransScheduleData[index - 1].expected_schedule_start_date
                                let currentSchStartDate = FnGetNextExpectedDate(previousServiceStartDate, FnSetServiceDateProcessDuration(element.process_duration));
                                dt_expected_schedule_start_date = currentSchStartDate
                                dt_expected_schedule_end_date = FnGetNextExpectedDate(currentSchStartDate, totalServiceDays)
                            }

                            const scheduleData = {
                                company_id: COMPANY_ID,
                                company_branch_id: cmb_companyBranchId,
                                financial_year: SHORT_FINANCIAL_YEAR,
                                sales_order_master_transaction_id: sales_order_master_transaction_id,
                                sales_order_no: salesOrderNo,
                                product_material_unit_id: element.product_material_unit_id,
                                sales_order_date: dt_sales_order_date,
                                sales_order_version: txt_sales_order_version,
                                customer_order_no: txt_customer_order_no === "" ? salesOrderNo : txt_customer_order_no,
                                customer_order_Date: dt_customer_order_Date === "" ? dt_sales_order_date : dt_customer_order_Date,
                                product_type_id: $('#cmb_sales_order_type_id').val(),
                                product_type: $('#cmb_sales_order_type_id option:selected').attr('lbl'),
                                product_material_id: element.product_sr_id,
                                product_material_unit_id: element.product_rm_stock_unit_id,
                                process_duration: element.process_duration,
                                expected_schedule_start_date: dt_expected_schedule_start_date,
                                expected_schedule_end_date: dt_expected_schedule_end_date,
                                created_by: UserName,
                                product_sr_std_hours: element.product_sr_std_hours,
                                remark: '',
                            }
                            json.TransScheduleData.push(scheduleData)
                        }
                    } else {
                        const updatedSchedules = scData.map(schedule => ({
                            ...schedule,
                            sales_order_no: salesOrderNo,
                            customer_order_no: txt_customer_order_no === "" ? salesOrderNo : txt_customer_order_no,
                            customer_order_Date: dt_customer_order_Date === "" ? dt_sales_order_date : dt_customer_order_Date,
                            sales_order_schedules_transaction_id: isApprove ? schedule.sales_order_schedules_transaction_id : 0,
                            sales_order_schedules_trading_item_status: rb_preeclose_sales_order === '1' ? 'Z' : element.sales_order_item_status === 'P' && isApprove ? ordStatus : element.sales_order_item_status,
                        }));
                        json.TransScheduleData.push(...updatedSchedules);
                    }
                }

                // If user not come for approve then send whole data.
                if (!isApprove) {
                    // Trans Payment Terms Data
                    $("input:checkbox[name=selectPmtTerm]:checked").each(function () {
                        let findPaymentTermsData = PmtTermsdata.find(item => item.payment_terms_id === parseInt($(this).val()));
                        let payment_term_idVal = parseInt($(this).val());
                        const paymentTermsData = {
                            company_id: COMPANY_ID,
                            company_branch_id: cmb_companyBranchId,
                            sales_order_master_transaction_id: sales_order_master_transaction_id,
                            sales_order_no: salesOrderNo,
                            sales_order_date: dt_sales_order_date,
                            sales_order_version: txt_sales_order_version,
                            customer_order_no: txt_customer_order_no === "" ? salesOrderNo : txt_customer_order_no,
                            customer_order_Date: dt_customer_order_Date === "" ? dt_sales_order_date : dt_customer_order_Date,
                            payment_terms_id: payment_term_idVal,
                            payment_terms_name: findPaymentTermsData.payment_terms_name,
                            payment_terms_days: findPaymentTermsData.payment_terms_days,
                            payment_terms_grace_days: findPaymentTermsData.payment_terms_grace_days,
                            payment_terms_Milestome: $('#payment_milestone_' + payment_term_idVal).val(),
                            payment_percent: $('#payment_percent_' + payment_term_idVal).val(),
                            payment_expected_value: $('#payment_expected_value_' + payment_term_idVal).val(),
                            payment_expected_date: $('#payment_expected_date_' + payment_term_idVal).val(),
                            remark: findPaymentTermsData.remark,
                            created_by: UserName
                        }
                        json.TransPaymentTermsData.push(paymentTermsData);
                    });

                    // Trans Common Terms Data
                    $("input:checkbox[name=selectCommonTerm]:checked").each(function () {
                        let findPoTermsData = SOCommonTermsData.find(item => item.common_parameters_id === parseInt($(this).val()));
                        let pmtTermId = parseInt($(this).val());
                        const poTermsData = {
                            company_id: COMPANY_ID,
                            company_branch_id: cmb_companyBranchId,
                            sales_order_master_transaction_id: sales_order_master_transaction_id,
                            sales_order_no: salesOrderNo,
                            sales_order_date: dt_sales_order_date,
                            sales_order_version: txt_sales_order_version,
                            customer_order_no: txt_customer_order_no === "" ? salesOrderNo : txt_customer_order_no,
                            customer_order_Date: dt_customer_order_Date === "" ? dt_sales_order_date : dt_customer_order_Date,
                            sales_order_terms_parameters_id: pmtTermId,
                            sales_order_terms_parameters_name: findPoTermsData.common_parameters_name,
                            sales_order_terms_parameters_value: findPoTermsData.common_parameters_value,
                            sales_order_terms_parameters_expected_value: $("#common_parameters_expected_value_" + pmtTermId).val() === "" ? findPoTermsData.common_parameters_value : $("#common_parameters_expected_value_" + pmtTermId).val(),
                            remark: findPoTermsData.remark,
                            created_by: UserName
                        }
                        json.TransSalesOrderTermsData.push(poTermsData);
                    });

                    // taxationSummaryData collection
                    for (let index = 0; index < taxationSummaryData.length; index++) {
                        const element = taxationSummaryData[index];
                        const taxationSummarygroup = {
                            company_id: COMPANY_ID,
                            company_branch_id: cmb_companyBranchId,
                            financial_year: SHORT_FINANCIAL_YEAR,
                            sales_order_type_id: $('#cmb_sales_order_type_id').val(),
                            sales_order_type: $('#cmb_sales_order_type_id option:selected').attr('lbl'),
                            sales_order_master_transaction_id: 0,
                            customer_id: cmb_customer_id,
                            customer_state_id: cmb_customer_state_id,
                            customer_city_id: cmb_customer_city_id,
                            customer_contacts_ids: customerContactIds,
                            expected_branch_id: COMPANY_BRANCH_ID,
                            expected_branch_state_id: parseInt(cmb_companyBranchStateId),
                            expected_branch_city_id: parseInt(cmb_companyBranchCityId),
                            sales_order_no: salesOrderNo,
                            sales_order_date: dt_sales_order_date,
                            sales_order_version: txt_sales_order_version,
                            hsn_code_id: element.hsn_id,
                            summary_taxable_amount: element.summary_taxable_amount,
                            summary_cgst_percent: element.summary_cgst_percent,
                            summary_cgst_total: element.summary_cgst_total,
                            summary_sgst_percent: element.summary_sgst_percent,
                            summary_sgst_total: element.summary_sgst_total,
                            summary_igst_percent: element.summary_igst_percent,
                            summary_igst_total: element.summary_igst_total,
                            summary_total_amount: element.summary_total_amount,
                            created_by: UserName
                        }
                        json.TransTaxSummaryData.push(taxationSummarygroup)
                    }
                }


                // Common Ids
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.company_branch_id = cmb_companyBranchId
                json.commonIds.sales_order_no = salesOrderNo
                json.commonIds.sales_order_date = dt_sales_order_date
                json.commonIds.financial_year = SHORT_FINANCIAL_YEAR

                console.log(`Sales Order Save JSON: `);
                console.log(json)

                const formData = new FormData();
                formData.append(`MtSalesOrderServiceData`, JSON.stringify(json))
                const requestOptions = { method: 'POST', body: formData };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesOrderMasterService/FnAddUpdateRecord/${isApprove}`, requestOptions)
                const responce = await apicall.json();

                console.log('Save Response: ')
                console.log(responce)
                if (responce.success === "1") {
                    const evitCache = comboDataAPiCall.current.evitCache();
                    console.log(evitCache);
                    $('#spinner_id').hide();
                    setSuccMsg(responce.message)
                    if (responce.data.hasOwnProperty('sales_order_version') && responce.data.hasOwnProperty('sales_order_no')) {
                        setModalOrderDetails(`Sales Order No: ${responce.data.sales_order_no} and Version: ${responce.data.sales_order_version}.`)
                    }

                    setSalesOrderMasterTransactionId(responce.data.sales_order_master_transaction_id)
                    setSalesOrdVer(responce.data.sales_order_version);

                    // Enable upload document functionality.
                    $('#save-btn').attr('disabled', 'disabled');
                    $('#reject-btn').attr('disabled', 'disabled');
                    $('#clearFormBtn').attr('disabled', 'disabled');

                    if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'reject') {
                        $('#uploadDocBtn').removeAttr('disabled');
                    }

                    setShowMailSentModal(true);

                    if (isApprove) {
                        setShowMailSentModal(true);

                    } else {
                        const updateData = {
                            sales_order_no: responce.data.sales_order_no,
                            sales_order_version: responce.data.sales_order_version,
                            financial_year: responce.data.financial_year
                        }
                        sessionStorage.setItem('SalesOrderDetails', JSON.stringify(updateData));
                        sessionStorage.setItem('keyForViewUpdate', 'update');
                        // setShowSuccessMsgModal(true);
                    }

                } else {
                    $('#spinner_id').hide();
                    setErrMsg(responce.error);
                    setShowErrorMsgModal(true);
                }

            } else {
                $('#spinner_id').hide();
            }
        } catch (error) {
            // $('#spinner_id').hide();
            console.log("error: ", error)
            navigator('/Error')
        }
    }

    const FnValidateSalesOrder = async () => {

        // Validating master forms data.
        let headerDataIsValid = await validate.current.validateForm("salesOrderFormId");
        let footerDataIsValidate = await validate.current.validateForm("salesOrderTotalsFrm");
        if (!headerDataIsValid || !footerDataIsValidate) { return false; }


        if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
            $('#error_customer_contact_persons').text('Please select atleast one contact person.');
            $('#error_cmb_customer_city_id').focus();

            $('#error_customer_contact_persons').show();
            setErrMsg('Please select atleast one contact person.');
            setShowErrorMsgModal(true);
            return false;
        } else {
            $('#error_customer_contact_persons').hide();
        }

        // Check checkbox.
        //let freightIsTaxable = document.getElementById('chk_freight_isTaxable').checked;
        // if (($('#cmb_freight_hsn_id').val() === '' || $('#cmb_freight_hsn_id').val() === null)) {
        //     $('#cmb_freight_hsn_id').removeAttr('optional', 'optional');
        //     $('#error_cmb_freight_hsn_id').text('Please select the freight tax hsn code.');
        //     $('#error_cmb_freight_hsn_id').show();
        //     return false;
        // }


        let salesOrderDetailsIsValid = false;
        let addedMaterials = $('#salesOrdMaterialDtlsTbl tbody tr').length;
        // checked the materials are added or not?
        if (addedMaterials <= 0 && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add atleast one material.');
            setShowErrorMsgModal(true);
            setDetailsAccordEventKey(0)
            return salesOrderDetailsIsValid = false;

        } else {

            // Means materials are added.
            $('#salesOrdMaterialDtlsTbl tbody tr').each(function () {
                let currentTblRow = $(this);
                let materialRate = parseFloat(currentTblRow.find('input[id^="product_sr_std_price_"]').val());
                let materialQty = parseFloat(currentTblRow.find('input[id^="product_sr_quantity_"]').val());
                let materialDiscPercent = parseFloat(currentTblRow.find('input[id^="material_discount_percent_"]').val());
                let materialScheduleDate = currentTblRow.find('input[id^="material_schedule_date_"]').val();

                if (isNaN(materialQty) || materialQty <= 0 || materialQty === '') {
                    $(currentTblRow.find('input[id^="product_sr_quantity_"]'))[0].parentElement.dataset.tip = 'Material quantity should not be a zero or blank...!';
                    $(currentTblRow.find('input[id^="product_sr_quantity_"]'))[0].focus();
                    // setDetailsAccordEventKey(0)
                    return salesOrderDetailsIsValid = false;

                } else if (isNaN(materialRate) || materialRate <= 0 || materialRate === '') {
                    $(currentTblRow.find('input[id^="product_sr_std_price_"]'))[0].parentElement.dataset.tip = 'Material rate should not be a zero or blank...!';
                    $(currentTblRow.find('input[id^="product_sr_std_price_"]'))[0].focus();
                    // setDetailsAccordEventKey(0)
                    return salesOrderDetailsIsValid = false;

                } else if (isNaN(materialDiscPercent) || materialDiscPercent === '') {
                    $(currentTblRow.find('input[id^="material_discount_percent_"]'))[0].parentElement.dataset.tip = 'Material Discount Percentage should not be blank otherwise set ...!';
                    $(currentTblRow.find('input[id^="material_discount_percent_"]'))[0].focus();
                    // setDetailsAccordEventKey(0)
                    return salesOrderDetailsIsValid = false;

                } else if (materialScheduleDate === '' || materialScheduleDate === null || materialScheduleDate === undefined) {
                    $(currentTblRow.find('input[id^="material_schedule_date_"]'))[0].parentElement.dataset.tip = 'Please select material schdule date...!';
                    $(currentTblRow.find('input[id^="material_schedule_date_"]'))[0].focus();
                    // setDetailsAccordEventKey(0)
                    return salesOrderDetailsIsValid = false;

                }
                salesOrderDetailsIsValid = true;
            });

            if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && salesOrderDetailsIsValid) {

                let SchedulesIsvalid = false;
                let scheduledata = JSON.parse(localStorage.getItem('scheduledData'));
                // let scheduledata = [...scheduleDataArray]
                if (scheduledata !== null) {

                    // groupedData will return the json array with rm_id, totalQuantity, 
                    const groupedData = scheduledata.reduce((result, item) => {
                        const key = `${item.product_material_id}`;
                        const { product_material_id, product_sr_quantity } = item;
                        // First initiallize the object
                        if (!result[key]) {
                            result[key] = { product_material_id, total_product_material_schedule_quantity: 0, };
                        }
                        result[key].total_product_material_schedule_quantity += 1;
                        return result;
                    }, {});

                    let groupedArray = Object.values(groupedData);
                    // console.log(groupedArray);
                    for (let detailItem = 0; detailItem < groupedArray.length; detailItem++) {
                        let groupedElement = groupedArray[detailItem];
                        let isDetailpressent = salesOrdDetailsData.find(item => item.product_sr_id === parseInt(groupedElement.product_material_id))
                        if (isDetailpressent) {
                            if (parseFloat(isDetailpressent.product_sr_quantity) !== parseFloat(groupedElement.total_product_material_schedule_quantity)) {
                                setErrMsg(`The schedule quantity for ${isDetailpressent.product_sr_name}(Total Quanity is ${isDetailpressent.product_sr_quantity} and are you scheduling ${groupedElement.total_product_material_schedule_quantity}.)`)
                                setShowErrorMsgModal(true)
                                return SchedulesIsvalid = false;
                            }
                        }
                    }
                }


                // Payment term validations
                let selectedPmtTerms = $('#salesOrdPaymentTermsTbl tbody tr .selectPmtTerm:checked')
                let salesOrderPmtTermsIsValid = true;
                selectedPmtTerms.each(function () {
                    let currentTblRow = $(this.parentElement.parentElement.parentElement)
                    let pmtMilestome = currentTblRow.find('input[id^="payment_milestone_"]').val();
                    let pmtPercent = parseFloat(currentTblRow.find('input[id^="payment_percent_"]').val());
                    let pmtExpectedDate = currentTblRow.find('input[id^="payment_expected_date_"]').val();

                    if (pmtMilestome === '') {
                        $(currentTblRow.find('input[id^="payment_milestone_"]'))[0].parentElement.dataset.tip = 'Please enter payment milestome...!';
                        $(currentTblRow.find('input[id^="payment_milestone_"]'))[0].focus();
                        // setPaymentTermsAccordEventKey(0);
                        salesOrderPmtTermsIsValid = false;
                    } else if (isNaN(pmtPercent) || pmtPercent === '') {
                        $(currentTblRow.find('input[id^="payment_percent_"]'))[0].parentElement.dataset.tip = 'Payment term Percentage should not be blank otherwise set ...!';
                        $(currentTblRow.find('input[id^="payment_percent_"]'))[0].focus();
                        // setPaymentTermsAccordEventKey(0);
                        salesOrderPmtTermsIsValid = false;
                    } else if (pmtPercent > 100) {
                        $(currentTblRow.find('input[id^="payment_percent_"]'))[0].parentElement.dataset.tip = 'Payment term Percentage should not be greater than 100 ...!';
                        $(currentTblRow.find('input[id^="payment_percent_"]'))[0].focus();
                        salesOrderPmtTermsIsValid = false;
                    } else if (pmtExpectedDate === '' || pmtExpectedDate === null || pmtExpectedDate === undefined) {
                        $(currentTblRow.find('input[id^="payment_expected_date_"]'))[0].parentElement.dataset.tip = 'Please select payment expected schdule date...!';
                        $(currentTblRow.find('input[id^="payment_expected_date_"]'))[0].focus();
                        // setPaymentTermsAccordEventKey(0);
                        salesOrderPmtTermsIsValid = false;
                    }
                    return salesOrderPmtTermsIsValid;
                });

                // Common terms validations
                let salesOrderCommonTermsIsValid = true;
                if (salesOrderPmtTermsIsValid) {
                    salesOrderCommonTermsIsValid = true;
                    let selectedCommonTerms = $('#commonTermsTbl tbody tr .selectCommonTerm:checked')
                    selectedCommonTerms.each(function () {
                        let currentTblRow = $(this.parentElement.parentElement.parentElement)
                        let expectedValue = currentTblRow.find('input[id^="common_parameters_expected_value_"]').val();
                        if (expectedValue === '') {
                            $(currentTblRow.find('input[id^="common_parameters_expected_value_"]'))[0].parentElement.dataset.tip = 'Please enter valid common parameters expected value...!';
                            $(currentTblRow.find('input[id^="common_parameters_expected_value_"]'))[0].focus();
                            // setCommonTermsAccordEventKey(0);
                            return salesOrderCommonTermsIsValid = false;
                        }
                    });
                    return salesOrderCommonTermsIsValid;
                }

                return salesOrderDetailsIsValid === true && salesOrderPmtTermsIsValid === true && salesOrderCommonTermsIsValid === true;

            } else if (keyForViewUpdate === 'approve' && salesOrderDetailsIsValid) {
                return true;
            } else {
                return keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && salesOrderDetailsIsValid
                // return true;
            }

        }
    }

    // Render the Details table.
    const renderDetailsTable = useMemo(() => {
        return <>
            {
                salesOrdDetailsData.length > 0
                    ? <Table className="erp_table erp_table_scroll" id='salesOrdMaterialDtlsTbl' bordered striped hover>
                        <thead className="erp_table_head">
                            <tr>
                                <th className={`erp_table_th`}>Action</th>
                                <th className="erp_table_th">Sr No</th>
                                <th className="erp_table_th">Service Name</th>
                                <th className={`erp_table_th`}>Tech. Specification</th>
                                <th className="erp_table_th">Process Duration</th>
                                <th className={`erp_table_th`}>Service std hours</th>

                                <th className="erp_table_th">HSN Code</th>
                                <th className="erp_table_th">HSN Rate (%)</th>
                                <th className={`erp_table_th`}>Service Quantity</th>
                                <th className={`erp_table_th  ${rb_sales_order_creation_type === 'Q' ? 'display' : 'd-none'}`}>Quotation Rate</th>
                                <th className="erp_table_th">Service Rate</th>
                                <th className="erp_table_th">Basic  Amt.</th>
                                <th className="erp_table_th">Discount (%)</th>
                                <th className="erp_table_th">Discount Amt.</th>
                                <th className="erp_table_th">Taxable Amt.</th>
                                <th className="erp_table_th">Taxable Amt. Per Qty.</th>
                                <th className="erp_table_th">CGST (%)</th>
                                <th className="erp_table_th">CGST  Amt.</th>
                                <th className="erp_table_th">SGST (%)</th>
                                <th className="erp_table_th">SGST Amt.</th>
                                <th className="erp_table_th">IGST (%)</th>
                                <th className="erp_table_th">IGST Amt.</th>
                                <th className="erp_table_th">Total Amt.</th>
                                <th className="erp_table_th">Service Start Date</th>
                                <th className="erp_table_th">Service Status</th>
                                <th className="erp_table_th">Remark</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                salesOrdDetailsData?.map((salesOrderItem, Index) =>
                                    <tr rowIndex={Index} rowSrNo={salesOrderItem.sr_no} rowMaterialId={salesOrderItem.product_sr_id}>
                                        <td className={`erp_table_td`}>
                                            <div style={{ display: "flex" }}>
                                                <MdDelete className="erp-delete-btn" onClick={() => removeMaterial(salesOrderItem)} />
                                                <AiOutlineSchedule className="erp-schedule-btn" onClick={() => openSchedules(salesOrderItem)} />
                                            </div>
                                        </td>
                                        <td className={`erp_table_td `} id={`sr_no_${salesOrderItem.product_sr_id}`}>{Index + 1}</td>
                                        <td className="erp_table_td">{salesOrderItem.product_sr_name}</td>

                                        <td className={`erp_table_td`}>
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? salesOrderItem.product_sr_tech_spect
                                                    : <input type="text" className="erp_input_field_table_txt mb-0" id={`product_sr_tech_spect_${Index}`} value={salesOrderItem.product_sr_tech_spect} Headers='product_sr_tech_spect'
                                                        onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} onBlur={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} />

                                            }
                                        </td>



                                        <td className="erp_table_td">{salesOrderItem.process_duration}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.product_sr_std_hours}</td>
                                        <td className="erp_table_td">{salesOrderItem.product_sr_hsn_sac_code}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.product_sr_hsn_sac_rate}</td>

                                        <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'text-end' : ""}`}>
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? salesOrderItem.product_sr_quantity
                                                    : <input type="text" className="erp_input_field text-end mb-0" id={`product_sr_quantity_${salesOrderItem.product_sr_id}`} value={salesOrderItem.product_sr_quantity} maxLength='19'
                                                        Headers='product_sr_quantity' onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} onBlur={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} />
                                            }
                                        </td>
                                        <td className={`erp_table_td text-end ${rb_sales_order_creation_type === 'Q' ? 'display' : 'd-none'}`}>{salesOrderItem.quotation_rate}</td>

                                        <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'text-end' : ""}`}>
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? salesOrderItem.product_sr_std_price
                                                    : <input type="text" className="erp_input_field text-end mb-0" id={`product_sr_std_price_${salesOrderItem.product_sr_id}`} value={salesOrderItem.product_sr_std_price} maxLength='19'
                                                        Headers='product_sr_std_price' onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} onBlur={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} />
                                            }
                                        </td>
                                        <td className="erp_table_td text-end">{salesOrderItem.material_basic_amount}</td>


                                        <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'text-end' : ""}`}>
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? salesOrderItem.material_discount_percent
                                                    : <input type="text" className="erp_input_field text-end mb-0" id={`material_discount_percent_${salesOrderItem.product_sr_id}`} value={salesOrderItem.material_discount_percent}
                                                        Headers='material_discount_percent' onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} onBlur={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} />
                                            }
                                        </td>

                                        <td className={`erp_table_td ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'text-end' : ""}`}>
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? salesOrderItem.material_discount_amount
                                                    : <input type="text" className="erp_input_field text-end mb-0" id={`material_discount_amount_${salesOrderItem.product_sr_id}`} value={salesOrderItem.material_discount_amount}
                                                        Headers='material_discount_amount' onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} onBlur={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} />
                                            }
                                        </td>

                                        <td className="erp_table_td text-end">{salesOrderItem.material_taxable_amount}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.so_rate}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.material_cgst_percent}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.material_cgst_total}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.material_sgst_percent}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.material_sgst_total}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.material_igst_percent}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.material_igst_total}</td>
                                        <td className="erp_table_td text-end">{salesOrderItem.material_total_amount}</td>

                                        <td className="erp_table_td">
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? salesOrderItem.material_schedule_date
                                                    : <input type="date" className="erp_input_field mb-0" id={`material_schedule_date_${salesOrderItem.product_sr_id}`} value={salesOrderItem.material_schedule_date} Headers='material_schedule_date' min={currentDate}
                                                        onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} onBlur={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} />
                                            }
                                        </td>


                                        <td className="erp_table_td">
                                            <select id={`sales_order_item_status_${salesOrderItem.product_sr_id}`} value={salesOrderItem.sales_order_item_status} className="form-select form-select-sm erp_input_field_table_txt m-0"
                                                Headers="sales_order_item_status" onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} disabled={keyForViewUpdate !== 'approve' || rb_preeclose_sales_order == '1' ? true : false}  >
                                                <option value="P" lbl='Pending'>Pending</option>
                                                <option value="A" lbl='Approved'>Approved</option>
                                                <option value="R" lbl='Rejected'>Rejected</option>
                                                <option value="Z" lbl='PreeClosed'>PreeClosed</option>

                                                {
                                                    keyForViewUpdate === 'approve'
                                                        ? ''
                                                        : <><option value="I" lbl='Partial Issue'>Partial Issue</option>
                                                            <option value="C" lbl='Completed'>Completed</option>
                                                            <option value="X" lbl='Canceled'>Canceled</option>
                                                        </>
                                                }
                                            </select>
                                        </td>

                                        <td className="erp_table_td">
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? salesOrderItem.remark
                                                    : <input type="text" className="erp_input_field_table_txt mb-0" id={`remark_${salesOrderItem.product_sr_id}`} value={salesOrderItem.remark} Headers='remark'
                                                        onChange={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} onBlur={(e) => { updateSalesOrdDetailsTblData(salesOrderItem, e); }} />

                                            }
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    : null
            }
        </>
    }, [salesOrdDetailsData]);


    const renderDetailsTrackingTbl = useMemo(() => {
        return <>
            {
                salesOrdDetailsTrackingData.length > 0
                    ? <Table className="erp_table" id='salesOrdMaterialSchedulesTbl' bordered striped>
                        <thead className="erp_table_head">
                            <tr>
                                <th className="erp_table_th">SrNo</th>
                                {/* <th className="erp_table_th">So SrNo</th> */}
                                <th className="erp_table_th">Product Name</th>
                                <th className="erp_table_th">Order Qty</th>
                                <th className="erp_table_th">Order Wght</th>
                                <th className="erp_table_th">Material Status</th>
                                <th className="erp_table_th">Pending Qty</th>
                                <th className="erp_table_th">Pending Wght</th>
                                <th className="erp_table_th">Opening Qty</th>
                                <th className="erp_table_th">Opening Wght</th>
                                <th className="erp_table_th">Reserve Qty</th>
                                <th className="erp_table_th">Reserve Wght</th>
                                <th className="erp_table_th">Excess Qty</th>
                                <th className="erp_table_th">Excess Wght</th>
                                <th className="erp_table_th">Pree Closed Qty</th>
                                <th className="erp_table_th">Pree Closed Wght</th>
                                <th className="erp_table_th">Purchase Qty</th>
                                <th className="erp_table_th">Purchase Wght</th>
                                <th className="erp_table_th">Purchase Return Qty</th>
                                <th className="erp_table_th">Purchase Return Wght</th>
                                <th className="erp_table_th">Purchase Rejection Qty</th>
                                <th className="erp_table_th">Purchase Rejection Wght</th>
                                <th className="erp_table_th">Jobcard Qty</th>
                                <th className="erp_table_th">Jobcard Wght</th>
                                <th className="erp_table_th">Production Issue Qty</th>
                                <th className="erp_table_th">Production Issue Wght</th>
                                <th className="erp_table_th">Production Issue Return Qty</th>
                                <th className="erp_table_th">Production Issue Return Wght</th>
                                <th className="erp_table_th">Production Issue Rejection Qty</th>
                                <th className="erp_table_th">Production Issue Rejection Wght</th>
                                <th className="erp_table_th">Production Qty</th>
                                <th className="erp_table_th">Production Wght</th>
                                <th className="erp_table_th">Production Return Qty</th>
                                <th className="erp_table_th">Production Return Wght</th>
                                <th className="erp_table_th">Production Rejection Qty</th>
                                <th className="erp_table_th">Production Rejection Wght</th>
                                <th className="erp_table_th">Assembly Production Issue Qty</th>
                                <th className="erp_table_th">Assembly Production Issue Wght</th>
                                <th className="erp_table_th">Sales Qty</th>
                                <th className="erp_table_th">Sales Wght</th>
                                <th className="erp_table_th">Sales Return Qty</th>
                                <th className="erp_table_th">Sales Return Wght</th>
                                <th className="erp_table_th">Sales Rejection Qty</th>
                                <th className="erp_table_th">Sales Rejection Wght</th>
                                <th className="erp_table_th">Transfer Issue Qty</th>
                                <th className="erp_table_th">Transfer Issue Wght</th>
                                <th className="erp_table_th">Transfer Receipt Qty</th>
                                <th className="erp_table_th">Transfer Receipt Wght</th>
                                <th className="erp_table_th">Outsources Out Qty</th>
                                <th className="erp_table_th">Outsources Out Wght</th>
                                <th className="erp_table_th">Outsources In Qty</th>
                                <th className="erp_table_th">Outsources In Wght</th>
                                <th className="erp_table_th">Outsources Rejection Qty</th>
                                <th className="erp_table_th">Outsources Rejection Wght</th>
                                <th className="erp_table_th">Loan Receipt Qty</th>
                                <th className="erp_table_th">Loan Receipt Wght</th>
                                <th className="erp_table_th">Loan Issue Qty</th>
                                <th className="erp_table_th">Loan Issue Wght</th>
                                <th className="erp_table_th">Cancel Qty</th>
                                <th className="erp_table_th">Cancel Wght</th>
                                <th className="erp_table_th">Difference Qty</th>
                                <th className="erp_table_th">Difference Wght</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                salesOrdDetailsTrackingData?.map((tackingData, Index) =>
                                    <tr rowIndex={Index} >
                                        <td className="erp_table_td text-end">{tackingData.sr_no}</td>
                                        {/* <td className="erp_table_td text-end">{tackingData.so_sr_no}</td> */}
                                        <td className="erp_table_td">{tackingData.product_sr_name}</td>
                                        <td className="erp_table_td text-end">{tackingData.material_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.material_weight}</td>
                                        <td className="erp_table_td">{tackingData.sales_order_item_status_desc}</td>
                                        <td className="erp_table_td text-end">{tackingData.pending_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.pending_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.opening_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.opening_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.reserve_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.reserve_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.excess_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.excess_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.pree_closed_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.pree_closed_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.purchase_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.purchase_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.purchase_return_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.purchase_return_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.purchase_rejection_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.purchase_rejection_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.jobcard_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.jobcard_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_issue_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_issue_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_issue_return_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_issue_return_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_issue_rejection_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_issue_rejection_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_return_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_return_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_rejection_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.production_rejection_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.assembly_production_issue_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.assembly_production_issue_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.sales_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.sales_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.sales_return_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.sales_return_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.sales_rejection_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.sales_rejection_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.transfer_issue_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.transfer_issue_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.transfer_receipt_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.transfer_receipt_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.outsources_out_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.outsources_out_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.outsources_in_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.outsources_in_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.outsources_rejection_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.outsources_rejection_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.loan_receipt_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.loan_receipt_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.loan_issue_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.loan_issue_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.cancel_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.cancel_weight}</td>
                                        <td className="erp_table_td text-end">{tackingData.difference_quantity}</td>
                                        <td className="erp_table_td text-end">{tackingData.difference_weight}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    : ''
            }
        </>
    }, [salesOrdDetailsTrackingData]);

    // Render the Details schedules table.
    const renderSchedulesTable = useMemo(() => {
        return <>
            {
                scheduleDataArray.length > 0
                    ? <Table className="erp_table" id='salesOrdMaterialSchedulesTbl' bordered striped>
                        <thead className="erp_table_head">
                            <tr>
                                <th className="erp_table_th">Service Name</th>
                                <th className="erp_table_th">Process Duration</th>
                                <th className="erp_table_th">service std hours</th>
                                <th className="erp_table_th">Service Technical Spec.</th>
                                <th className="erp_table_th">Sevice Start Date</th>
                                <th className="erp_table_th">Sevice End Date</th>
                                <th className="erp_table_th">Remark</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                scheduleDataArray?.map((schedule, Index) =>
                                    <tr rowIndex={Index} scheduleMaterialId={schedule.product_sr_id} >
                                        {/* ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : ''} */}
                                        {/* <td className={`erp_table_td`}>
                                            <div style={{ display: "flex" }}>
                                                <MdDelete className="erp-delete-btn" id={`schedule_${schedule.product_sr_id}`} onClick={() => deleteSchedules(schedule.product_sr_id)} />
                                            </div>
                                        </td> */}
                                        <td className="erp_table_td">{schedule.product_sr_name}</td>
                                        <td className="erp_table_td">{schedule.process_duration}</td>
                                        <td className="erp_table_td text-end">{schedule.product_sr_std_hours}</td>
                                        <td className="erp_table_td">{schedule.product_sr_tech_spect}</td>
                                        <td className="erp_table_td">{schedule.expected_schedule_start_date}</td>
                                        <td className="erp_table_td">{schedule.expected_schedule_end_date}</td>
                                        <td className="erp_table_td">{schedule.remark}</td>

                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    : ''
            }
        </>
    }, [scheduleDataArray, showSchedule]);


    const renderPaymentTermsTable = useMemo(() => {
        return <>
            {
                PmtTermsdata.length > 0
                    ? <Table className="erp_table" id='salesOrdPaymentTermsTbl' bordered striped>
                        <thead className="erp_table_head">
                            <tr>
                                <th className={`erp_table_th`}>Action</th>
                                <th className="erp_table_th">Payment Term Name</th>
                                <th className="erp_table_th">Payment Term Days</th>
                                <th className="erp_table_th">Grace Days</th>
                                <th className={`erp_table_th`}>Payment Milestome</th>
                                <th className="erp_table_th">Payment Percentage</th>
                                <th className="erp_table_th">Payment Expected Value</th>
                                <th className="erp_table_th">Payment Expected Date</th>
                                <th className="erp_table_th">Payment Receipt Status</th>
                                <th className="erp_table_th">Payment Receipt Transaction Id</th>
                                <th className={`erp_table_th`}>Payment Receipt Date</th>
                                <th className="erp_table_th">Remark</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                PmtTermsdata?.map((paymentTerm, Index) =>
                                    <tr rowIndex={Index} paymentTermId={paymentTerm.payment_terms_id}>
                                        <td className={`erp_table_td`}>
                                            <div style={{ display: "flex" }}>
                                                <input type="checkbox" name="selectPmtTerm" className="form-check-input selectPmtTerm" pmtTermId={paymentTerm.payment_terms_id} value={paymentTerm.payment_terms_id} id={'selectPmtTerm_' + paymentTerm.payment_terms_id} />
                                            </div>
                                        </td>
                                        <td className="erp_table_td">{paymentTerm.payment_terms_name}</td>
                                        <td className="erp_table_td text-end">{paymentTerm.payment_terms_days}</td>
                                        <td className="erp_table_td text-end">{paymentTerm.payment_terms_grace_days}</td>

                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                ? paymentTerm.payment_terms_Milestome
                                                : <input type="text" className="erp_input_field mb-0" value={paymentTerm.payment_terms_Milestome} pmtTermId={paymentTerm.payment_terms_id} id={"payment_milestone_" + paymentTerm.payment_terms_id} maxlength='255'
                                                    Headers='payment_terms_Milestome' onChange={(e) => { updatePmtTermsTblData(paymentTerm, e); }} onBlur={(e) => { updatePmtTermsTblData(paymentTerm, e); }} />
                                            }
                                        </td>

                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                ? paymentTerm.payment_percent
                                                : <input type="text" className="erp_input_field mb-0 text-end" value={paymentTerm.payment_percent} pmtTermId={paymentTerm.payment_terms_id} id={"payment_percent_" + paymentTerm.payment_terms_id}
                                                    Headers='payment_percent' onChange={(e) => { updatePmtTermsTblData(paymentTerm, e); }} onBlur={(e) => { updatePmtTermsTblData(paymentTerm, e); }} />
                                            }
                                        </td>


                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                ? paymentTerm.payment_expected_value
                                                : <input type="text" className="erp_input_field mb-0 text-end" value={paymentTerm.payment_expected_value} pmtTermId={paymentTerm.payment_terms_id} id={"payment_expected_value_" + paymentTerm.payment_terms_id}
                                                    Headers='payment_expected_value' onChange={(e) => { updatePmtTermsTblData(paymentTerm, e); }} onBlur={(e) => { updatePmtTermsTblData(paymentTerm, e); }} />
                                            }
                                        </td>

                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                ? paymentTerm.payment_expected_date
                                                : <input type="date" className="erp_input_field mb-0" value={paymentTerm.payment_expected_date} min={currentDate} pmtTermId={paymentTerm.payment_terms_id} id={"payment_expected_date_" + paymentTerm.payment_terms_id}
                                                    Headers='payment_expected_date' onChange={(e) => { updatePmtTermsTblData(paymentTerm, e); }} onBlur={(e) => { updatePmtTermsTblData(paymentTerm, e); }} />
                                            }
                                        </td>

                                        <td className="erp_table_td">
                                            <select id={`payment_receipt_flag_${paymentTerm.payment_terms_id}`} Headers='payment_receipt_flag' disabled='disabled' value={paymentTerm.payment_receipt_flag} className="form-select form-select-sm erp_input_field_table_txt m-0">
                                                <option value="P">Pending</option>
                                                <option value="T">Partial</option>
                                                <option value="R">Receipt</option>
                                            </select>
                                        </td>

                                        <td className="erp_table_td">{paymentTerm.payment_receipt_transaction_id}</td>
                                        <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(paymentTerm.expected_schedule_date)}</td>

                                        <td className="erp_table_td">
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? paymentTerm.remark
                                                    : <textarea type="text" className="erp_input_field_table_txt mb-0" value={paymentTerm.remark} pmtTermId={paymentTerm.payment_terms_id} id={"payment_term_remark_" + paymentTerm.payment_terms_id}
                                                        Headers='remark' onChange={(e) => { updatePmtTermsTblData(paymentTerm, e); }} onBlur={(e) => { updatePmtTermsTblData(paymentTerm, e); }}>
                                                    </textarea>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    : ''
            }
        </>
    }, [PmtTermsdata]);

    // Render the  Common terms table.
    const renderCommonTermsTable = useMemo(() => {
        return <>
            {
                SOCommonTermsData.length > 0
                    ? <Table className="erp_table" id='salesOrdCommonTermsTbl' bordered striped>
                        <thead className="erp_table_head">

                            <tr>
                                <th className={`erp_table_th`}>Action</th>
                                <th className="erp_table_th">Common Parameters Master </th>
                                <th className="erp_table_th">Common Parameters </th>
                                <th className="erp_table_th">Common Parameters Value</th>
                                <th className="erp_table_th">Common Prameters Expected Value</th>
                                <th className="erp_table_th">Remark</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                SOCommonTermsData?.map((commonTerm, Index) =>
                                    <tr rowIndex={Index} commonTermId={commonTerm.common_parameters_id}>
                                        <td className={`erp_table_td`}>
                                            <div style={{ display: "flex" }}>
                                                <input type="checkbox" className="selectCommonTerm form-check-input" name="selectCommonTerm" value={commonTerm.common_parameters_id} commonTermId={commonTerm.common_parameters_id} id={`selectCommonTerm_${commonTerm.common_parameters_id}`} />
                                            </div>
                                        </td>

                                        <td className="erp_table_td">{commonTerm.common_parameters_master_name}</td>
                                        <td className="erp_table_td">{commonTerm.common_parameters_name}</td>
                                        <td className="erp_table_td">{commonTerm.common_parameters_value}</td>

                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                ? commonTerm.common_parameters_expected_value
                                                : <input type="text" className="erp_input_field mb-0" id={`common_parameters_expected_value_${commonTerm.common_parameters_id}`}
                                                    Headers='common_parameters_expected_value' value={commonTerm.common_parameters_expected_value} onChange={(e) => { updateSalesOrdCommonTermsTblData(commonTerm, e) }}
                                                />
                                            }
                                        </td>

                                        <td className="erp_table_td">
                                            {
                                                keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                    ? commonTerm.remark
                                                    : <textarea type="text" className="erp_input_field mb-0" id={`remark_${commonTerm.common_parameters_id}`}
                                                        Headers='remark' value={commonTerm.remark} onChange={(e) => { updateSalesOrdCommonTermsTblData(commonTerm, e) }}></textarea>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    : ""
            }
        </>
    }, [SOCommonTermsData]);

    // Render the  Taxation Summary table.
    const renderTaxationSummaryTable = useMemo(() => {

        return <>
            {
                taxationSummaryData.length > 0
                    ? <Table className="erp_table" id='salesOrdTaxationSummaryTbl' bordered striped>
                        <thead className="erp_table_head">

                            <tr>
                                <th className="erp_table_th">HSN Sac Code </th>
                                <th className="erp_table_th">Taxable Amount Total </th>
                                <th className="erp_table_th">CGST Percentage</th>
                                <th className="erp_table_th">CGST Amount Total</th>
                                <th className="erp_table_th">SGST Percentage</th>
                                <th className="erp_table_th">SGST Amount Total</th>
                                <th className="erp_table_th">IGST Percentage</th>
                                <th className="erp_table_th">IGST Amount Total</th>
                                <th className="erp_table_th">Total Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                taxationSummaryData?.map((taxSummary, Index) =>
                                    <tr rowIndex={Index} hsnId={taxSummary.common_parameters_id}>
                                        <td className="erp_table_td">{taxSummary.product_sr_hsn_sac_code}</td>
                                        <td className="erp_table_td">{taxSummary.summary_taxable_amount}</td>
                                        <td className="erp_table_td">{taxSummary.summary_cgst_percent}</td>
                                        <td className="erp_table_td">{taxSummary.summary_cgst_total}</td>
                                        <td className="erp_table_td">{taxSummary.summary_sgst_percent}</td>
                                        <td className="erp_table_td">{taxSummary.summary_sgst_total}</td>
                                        <td className="erp_table_td">{taxSummary.summary_igst_percent}</td>
                                        <td className="erp_table_td">{taxSummary.summary_igst_total}</td>
                                        <td className="erp_table_td">{taxSummary.summary_total_amount}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                    : ""
            }
        </>
    }, [taxationSummaryData]);

    //Sales Order Invoice Creation 
    const SalesOrderInvoiceContent = {
        company: {
            company: '',
            company_branch: '',
            address: '',
            phone: '',
            email: '',
            cell: '',
            website: '',
            GSTIN: '',
            pan_no: '',
            state_name: '',
            company_pincode: ''
        },
        consigneeDetails: {
            name: '',
            address: '',
            state: '',
            GSTIN: '',
            pan_no: '',
            contact: '',
        },
        customerDetails: {
            customer: '',
            address: '',
            state: '',
            GSTIN: '',
            pan_no: '',
            contact: '',
            email: ''
        },
        salesOrderDetails: {
            sales_order_no: '',
            sales_order_date: '',
            department_name: '',
            sales_order_version: '',
            customer_order_no: '',
            customer_order_Date: ''
        },

        footer: {
            cgst_words: '',
            sgst_words: '',
            igst_words: '',
            grand_total_words: '',

            basic_total: '',
            cgst_total: '',
            sgst_total: '',
            igst_total: '',
            taxable_total: '',
            grand_total: '',
            transport_amount: '',
            packing_amount: '',
        },

        items: [],
        paymentTerms: [],
    };


    const printInvoice = async (openPrintModal) => {
        //Purchase details
        SalesOrderInvoiceContent.salesOrderDetails.sales_order_no = salesOrderMasterDataJson.sales_order_no
        SalesOrderInvoiceContent.salesOrderDetails.sales_order_date = validateNumberDateInput.current.formatDateToDDMMYYYY(salesOrderMasterDataJson.sales_order_date)
        SalesOrderInvoiceContent.salesOrderDetails.sales_order_version = salesOrderMasterDataJson.sales_order_version
        SalesOrderInvoiceContent.salesOrderDetails.customer_order_no = salesOrderMasterDataJson.customer_order_no
        SalesOrderInvoiceContent.salesOrderDetails.customer_order_Date = validateNumberDateInput.current.formatDateToDDMMYYYY(salesOrderMasterDataJson.customer_order_Date)
        SalesOrderInvoiceContent.salesOrderDetails.department_name = salesOrderMasterDataJson.department_name
        //Company details
        SalesOrderInvoiceContent.company.company = salesOrderMasterDataJson.company_name
        SalesOrderInvoiceContent.company.company_branch = salesOrderMasterDataJson.company_branch_name
        SalesOrderInvoiceContent.company.address = salesOrderMasterDataJson.company_address1
        SalesOrderInvoiceContent.company.phone = salesOrderMasterDataJson.company_phone_no
        SalesOrderInvoiceContent.company.cell = salesOrderMasterDataJson.company_cell_no
        SalesOrderInvoiceContent.company.email = salesOrderMasterDataJson.company_EmailId
        SalesOrderInvoiceContent.company.website = salesOrderMasterDataJson.company_website
        SalesOrderInvoiceContent.company.GSTIN = salesOrderMasterDataJson.company_gst_no
        SalesOrderInvoiceContent.company.pan_no = salesOrderMasterDataJson.company_pan_no
        SalesOrderInvoiceContent.company.state_name = salesOrderMasterDataJson.company_branch_state
        SalesOrderInvoiceContent.company.company_pincode = salesOrderMasterDataJson.company_pincode

        //Consignee details
        SalesOrderInvoiceContent.consigneeDetails.name = salesOrderMasterDataJson.consignee_name
        SalesOrderInvoiceContent.consigneeDetails.address = salesOrderMasterDataJson.consignee_address1
        SalesOrderInvoiceContent.consigneeDetails.state = salesOrderMasterDataJson.consignee_state_name
        SalesOrderInvoiceContent.consigneeDetails.GSTIN = salesOrderMasterDataJson.consignee_gst_no
        SalesOrderInvoiceContent.consigneeDetails.contact = salesOrderMasterDataJson.consignee_phone_no
        SalesOrderInvoiceContent.consigneeDetails.pan_no = salesOrderMasterDataJson.consignee_pan_no

        //Customer details
        SalesOrderInvoiceContent.customerDetails.customer = salesOrderMasterDataJson.customer_name
        SalesOrderInvoiceContent.customerDetails.address = salesOrderMasterDataJson.cust_branch_address1
        SalesOrderInvoiceContent.customerDetails.state = salesOrderMasterDataJson.customer_state_name
        SalesOrderInvoiceContent.customerDetails.GSTIN = salesOrderMasterDataJson.cust_branch_gst_no
        SalesOrderInvoiceContent.customerDetails.pan_no = salesOrderMasterDataJson.cust_branch_pan_no
        SalesOrderInvoiceContent.customerDetails.contact = salesOrderMasterDataJson.cust_branch_phone_no
        SalesOrderInvoiceContent.customerDetails.email = salesOrderMasterDataJson.customer_email

        SalesOrderInvoiceContent.footer.basic_total = salesOrderMasterDataJson.basic_total
        SalesOrderInvoiceContent.footer.cgst_total = salesOrderMasterDataJson.cgst_total
        SalesOrderInvoiceContent.footer.sgst_total = salesOrderMasterDataJson.sgst_total
        SalesOrderInvoiceContent.footer.igst_total = salesOrderMasterDataJson.igst_total
        SalesOrderInvoiceContent.footer.taxable_total = salesOrderMasterDataJson.taxable_total
        SalesOrderInvoiceContent.footer.grand_total = salesOrderMasterDataJson.grand_total
        SalesOrderInvoiceContent.footer.transport_amount = salesOrderMasterDataJson.transport_amount
        SalesOrderInvoiceContent.footer.packing_amount = salesOrderMasterDataJson.packing_amount

        for (let index = 0; index < salesOrdDetailsData.length; index++) {
            const element = salesOrdDetailsData[index];

            const detailsData = {
                sr_no: element.sr_no,
                // so_sr_no: element.so_sr_no,
                material_name: element.product_material_print_name,
                material_tech_spect: element.product_material_tech_spect,
                hsn_rate: element.product_sr_hsn_sac_rate,
                quantity: element.product_sr_quantity,
                unit: element.product_rm_stock_unit_name,
                rate: element.product_sr_hsn_sac_rate,
                discount_percent: element.material_discount_percent,
                discount_amount: element.material_discount_amount,
                cgst_percent: element.material_cgst_percent,
                sgst_percent: element.material_sgst_percent,
                igst_percent: element.material_igst_percent,
                material_total_amount: element.material_total_amount,

            }

            SalesOrderInvoiceContent.items.push(detailsData)
        }

        for (let index = 0; index < PmtTermsdata.length; index++) {
            const element = PmtTermsdata[index];
            const paymentTermsData = {
                payment_terms_name: element.payment_terms_name,
                payment_terms_days: element.payment_terms_days,
            }
            SalesOrderInvoiceContent.paymentTerms.push(paymentTermsData)

        }


        if (openPrintModal) {
            navigator('/Invoice', { state: { invoiceData: SalesOrderInvoiceContent, navigationLink: '/Transactions/TSalesOrder/Projects/SalesOrderProjectEntry', invoiceType: 'SO', title: 'Sales Order' } });
        } else {
            // return the html code.
            return renderToString(<SalesOrderInvoice invoiceContent={SalesOrderInvoiceContent} />);
        }


    };

    // For update the combobox data after add new record.
    // const FnRefreshComboData = async (key) => {
    //     if (keyForViewUpdate === 'view') {
    //         return false;
    //     }
    //     switch (key) {
    //         case 'UpdateCompanyBranches':
    //             resetGlobalQuery();
    //             globalQuery.columns = ["company_branch_id", "company_branch_name", "branch_state_id", "branch_city_id"]
    //             globalQuery.table = "cmv_company_branch"
    //             globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
    //             globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    //             const getCompanyBranchesApiCall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
    //             setCompanyBranchOpts(getCompanyBranchesApiCall);
    //             break;

    //         case 'UpdateConsignees':
    //         case 'UpdateCustomers':
    //             resetGlobalQuery();
    //             globalQuery.columns = ["field_id", "field_name", "is_sez"]
    //             globalQuery.table = "cmv_customer"
    //             globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
    //             globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    //             const getUpdatedCustomerData = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
    //             if (modalHeaderName === 'Add Customer') {
    //                 setCustomerOpts(getUpdatedCustomerData);
    //             } else if (modalHeaderName === 'Add Consignee') {
    //                 setConsigneeOpts(getUpdatedCustomerData);
    //             }
    //             break;

    //         case 'UpdateAgents':
    //             resetGlobalQuery();
    //             globalQuery.columns = ["field_id", "field_name"]
    //             globalQuery.table = "cmv_agent"
    //             globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
    //             globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    //             const getUpdatedAgentData = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
    //             setAgentOpts(getUpdatedAgentData);
    //             break;

    //         case 'UpdateTransporters':
    //             resetGlobalQuery();
    //             globalQuery.columns = ["field_id", "field_name", "transporter_gst_no"]
    //             globalQuery.table = "cmv_transporter"
    //             globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
    //             globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    //             const getTransporterApiCall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
    //             setTransporterOpts(getTransporterApiCall);
    //             break;
    //         default:
    //             break;
    //     }
    // }

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ExcelExport ref={exlsExp} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />

            <div className="erp_top_Form pb-5" id="SalesOrderComp">
                <>
                    <div className="spinner-overlay" id='spinner_id' style={{ display: "none" }}>
                        <div className="spinner-container">
                            <CircularProgress color="primary" className="text-primary" />
                            <span id="spinner_text" className="text-dark">Loading...</span>
                        </div>
                    </div>

                    {/* <div className='main_heding'>
                        <label className='erp-form-label-lg'>Service Sales Order {ActionType()}</label>
                    </div> */}

                    <div className='card p-2 pt-0'>
                        <div className='card-header py-0 text-center mb-2'>
                            <label className='erp-form-label-lg'>Service Sales Order {ActionType()}</label>
                        </div>

                        <form id="salesOrderFormId">
                            <div className="row">
                                {/* first column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Order Creation Type <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Mannual" type="radio" lbl="Mannual" value="M" name="rb_sales_order_creation_type" checked={rb_sales_order_creation_type === "M"} onClick={(e) => { setSalesOrdCreationType('M'); FnComboOnChange('CreationType'); }} /> </div>
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Quotation Based" type="radio" lbl="Quotation Based" value="Q" name="rb_sales_order_creation_type" checked={rb_sales_order_creation_type === "Q"} onClick={(e) => { setSalesOrdCreationType('Q'); FnComboOnChange('CreationType'); }} /> </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Order Type <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_sales_order_type_id" className="form-select form-select-sm" value={cmb_sales_order_type_id} onChange={() => { FnComboOnChange('SalesOrderType'); validateFields(); }}>
                                                <option value="" disabled>Select</option>
                                                <option value="0">Add New Record+</option>
                                                {salesOrdTypesOpts.length !== 0 ? (
                                                    <>
                                                        {salesOrdTypesOpts.map(orderType => (
                                                            <option key={orderType.field_id} value={orderType.field_id} lbl={orderType.field_name} shortName={`${orderType.product_type_short_name}`}> {orderType.field_name} </option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_sales_order_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3 col-12">
                                            <Form.Label className="erp-form-label">Order No., Ver. & Date <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_sales_order_no" className="erp_input_field" value={txt_sales_order_no} disabled />
                                                    <MDTypography variant="button" id="error_txt_sales_order_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-4 pe-md-0 pt-md-0 pt-3 ps-md-1">
                                                    <Form.Control type="date" id='dt_sales_order_date' className="erp_input_field" value={dt_sales_order_date} onChange={(e) => { setSalesOrdDate(e.target.value); validateFields(); }} disabled />
                                                    <MDTypography variant="button" id="error_dt_sales_order_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-2 pt-md-0 pt-3 ps-md-1">
                                                    <Form.Control type="text" id='txt_sales_order_version' className="erp_input_field text-end" value={txt_sales_order_version} disabled />
                                                    <MDTypography variant="button" id="error_txt_sales_order_version" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className="col-sm-3 col-12">
                                            <Form.Label className="erp-form-label">Order Life & Status <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-3 pe-md-0">
                                                    <div className="erp_form_radio">
                                                        <div className="fCheck"> <Form.Check className="erp_radio_button" label="Closed" type="radio" lbl="Closed" value="C" name="rb_sales_order_life" checked={rb_sales_order_life === "C"} onClick={() => setSalesOrdLife("C")} /> </div>
                                                        <div className="sCheck"> <Form.Check className="erp_radio_button" label="Open" type="radio" lbl="Open" value="O" name="rb_sales_order_life" checked={rb_sales_order_life === "O"} onClick={() => setSalesOrdLife("O")} /> </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-9">
                                                    <select id="cmb_sales_order_status" className="form-select form-select-sm" value={cmb_sales_order_status} onChange={(e) => { setSalesOrdStatus(e.target.value); validateFields(); }} disabled='disabled' >
                                                        <option value="P" lbl="Pending">Pending</option>
                                                        <option value="A" lbl="Approved">Approved</option>
                                                        <option value="R" lbl="Rejected">Rejected</option>
                                                        {
                                                            keyForViewUpdate === 'approve'
                                                                ? ''
                                                                : <><option value="I" lbl="Partial Issue">Partial Issue</option>
                                                                    <option value="C" lbl="Completed">Completed</option>
                                                                    <option value="X" lbl="Canceled">Canceled</option>
                                                                    <option value="Z" lbl="PreeClosed">PreeClosed</option>
                                                                </>
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Customer <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-10'>
                                            <select id="cmb_customer_id" className="form-select form-select-sm" value={cmb_customer_id} onChange={(e) => { { if (e.target.value !== '0') { setCustomerId(e.target.value) } }; FnComboOnChange('Customer'); validateFields(); }}>
                                                <option value="" disabled>Select</option>
                                                <option value="0">Add New Record+</option>
                                                {customerOpts?.map(customer => (
                                                    <option value={customer.field_id} is_sez={`${customer.is_sez}`}>{customer.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className='col-sm-1 col-2'>
                                            <MdRefresh style={{ color: 'black' }} onClick={e => { FnUpdateComboBoxData('UpdateCustomers'); }} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Customer Order No. & Date</Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-8 pe-md-0">
                                                    <Form.Control type="text" id='txt_customer_order_no' className="erp_input_field" value={txt_customer_order_no} onChange={(e) => { setCustomerOrderNo(e.target.value); validateFields(); }} optional="optional" maxlength="500" />
                                                    <MDTypography variant="button" id="error_txt_customer_order_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                                <div className="col-12 col-md-4 pt-md-0 pt-3 ps-md-1">
                                                    <Form.Control type="date" id='dt_customer_order_Date' className="erp_input_field" value={dt_customer_order_Date} optional="optional" onChange={(e) => { setCustomerOrdDate(e.target.value); validateFields(); }} />
                                                    <MDTypography variant="button" id="error_dt_customer_order_Date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Customer State <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_customer_state_id" className="form-select form-select-sm" value={cmb_customer_state_id} onChange={(e) => { setCustomerState(e.target.value); FnComboOnChange('CustomerState'); validateFields(); }} disabled='disabled'>
                                                <option value="" disabled>Select</option>
                                                {stateOpts?.map(state => (
                                                    <option value={state.field_id} stateCode={state.state_code}>{state.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_customer_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Customer City</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_customer_city_id" className="form-select form-select-sm" value={cmb_customer_city_id} onChange={(e) => { setCustomerCity(e.target.value); FnComboOnChange('CustomerCity'); validateFields(); }} optional="optional" disabled='disabled'>
                                                <option value="" disabled>Select</option>
                                                <option value="0">Add New Record+</option>
                                                {customerCityOpts?.map(city => (
                                                    <option value={city.field_id}>{city.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_customer_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">

                                        <Accordion defaultActiveKey="0" className="mt-3 mt-lg-1">
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header className="erp-form-label-md">Customer's Contact Details</Accordion.Header>
                                                <Accordion.Body className="p-0">
                                                    {
                                                        custContactDetails.length > 0
                                                            ? <>
                                                                <div className='row'>
                                                                    <div className="col-12 erp_table_scroll">
                                                                        <DatatableWithId data={custContactDetails} columns={custContactDetailsCols} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : ""
                                                    }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion >
                                        <MDTypography variant="button" id="error_customer_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>

                                </div>

                                {/* second column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-3 mt-3 mt-md-0'>
                                            <Form.Label className="erp-form-label">Company Branch <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_companyBranchId" className="form-select form-select-sm" value={cmb_companyBranchId} onChange={(e) => { if (e.target.value !== '0') { setCompanyBranchId(e.target.value) }; FnComboOnChange('CompanyBranch'); validateFields(); }}>
                                                <option value="" disabled>Select</option>
                                                <option value="0">Add New Record+</option>
                                                {companyBranchesOpts?.map(branch => (
                                                    <option key={branch.company_branch_id} value={branch.company_branch_id}> {branch.company_branch_name} </option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_companyBranchId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Company State <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_companyBranchStateId" className="form-select form-select-sm" value={cmb_companyBranchStateId} onChange={(e) => { setCompanyBranchStateId(e.target.value); FnComboOnChange('CompanyBranchState'); validateFields(); }} disabled="disabled">
                                                <option value="" disabled>Select</option>
                                                {stateOpts?.map(state => (
                                                    <option value={state.field_id} stateCode={state.state_code}>{state.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_companyBranchStateId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Company City <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_companyBranchCityId" className="form-select form-select-sm" value={cmb_companyBranchCityId} onChange={(e) => { setCompanyBranchCityId(e.target.value); FnComboOnChange('CompanyBranchCity'); validateFields(); }} disabled="disabled">
                                                <option value="" disabled>Select</option>
                                                <option value="0">Add New Record+</option>
                                                {companyBrancheCityOpts?.map(city => (
                                                    <option value={city.field_id}>{city.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_companyBranchCityId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Department</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_department_id" className="form-select form-select-sm" value={cmb_department_id} onChange={() => { FnComboOnChange('Department'); validateFields(); }} optional="optional">
                                                <option value="" disabled>Select</option>
                                                <option value="0">Add New Record+</option>
                                                {deptOpts?.map(department => (
                                                    <option key={department.field_id} value={department.field_id}> {department.field_name} </option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    {
                                        keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                            ?

                                            <div className='row'>
                                                <div className='col-sm-3'>
                                                    <Form.Label className="erp-form-label">Apprv. By & Date <span className="required">*</span></Form.Label>
                                                </div>
                                                <div className="col-sm-9 col-12">
                                                    <div className="row">
                                                        <div className="col-12 col-md-8 pe-md-0">
                                                            <select id="cmb_approved_by_id" className="form-select form-select-sm" value={cmb_approved_by_id} onChange={(e) => { setApprovedById(e.target.value); validateFields(); }} >
                                                                <option value="" disabled>Select</option>
                                                                <option value="0">Add New Record+</option>
                                                                {approveByOpts?.map(employee => (
                                                                    <option value={employee.field_id}>{employee.field_name}</option>
                                                                ))}
                                                            </select>

                                                        </div>
                                                        <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                            <Form.Control type="date" id='dt_approved_date' className="erp_input_field" value={dt_approved_date} onChange={(e) => { setApproveDate(e.target.value); validateFields(); }} />
                                                            <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }


                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Expec. Start Date <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="date" id='dt_overall_schedule_date' className="erp_input_field" value={dt_overall_schedule_date} onChange={(e) => { setOverAllScheduleDate(e.target.value); validateFields(); }} min={currentDate} />
                                            <MDTypography variant="button" id="error_dt_overall_schedule_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Consignee</Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-10'>
                                            <select id="cmb_consignee_id" className="form-select form-select-sm" value={cmb_consignee_id} onChange={(e) => { setConsigneeId(e.target.value); FnComboOnChange('Consignee'); validateFields(); }} optional="optional">
                                                <option value="" disabled>Select</option>
                                                <option value="0">Add New Record+</option>
                                                {consigneeOpts?.map(consignee => (
                                                    <option value={consignee.field_id}>{consignee.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_consignee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className='col-sm-1 col-2'>
                                            <MdRefresh style={{ color: 'black' }} onClick={e => { FnUpdateComboBoxData('UpdateConsignees'); }} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Consignee State & City</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <select id="cmb_consignee_state_id" className="form-select form-select-sm" value={cmb_consignee_state_id} optional='optional' disabled>
                                                        <option value=""></option>
                                                        {stateOpts?.map(ConsigneeState => (
                                                            <option value={ConsigneeState.field_id} lbl={ConsigneeState.field_name}>{ConsigneeState.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_consignee_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3 ">
                                                    <select id="cmb_consignee_city_id" className="form-select form-select-sm" value={cmb_consignee_city_id} optional='optional' disabled>
                                                        <option value=""></option>
                                                        {consigneeCityOpts?.map(ConsigneeCity => (
                                                            <option value={ConsigneeCity.field_id} lbl={ConsigneeCity.field_name}>{ConsigneeCity.field_name}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_consignee_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Remark</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" rows={2} id="txt_remark" className="erp_txt_area" value={txt_remark} onInput={e => { setRemark(e.target.value); }} maxlength="1000" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Order IsActive</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" name="rb_is_active" checked={rb_is_active === "true"} onClick={() => setIsActive("true")} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" name="rb_is_active" checked={rb_is_active === "false"} onClick={() => setIsActive("false")} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <hr />

                    <Accordion defaultActiveKey={'0'} id="orderDetailsAccordion">
                        <Accordion.Item eventKey={`${detailsAccordEventKey}`} id="orderDetailsAccordionItem" >
                            <Accordion.Header id="orderDetailsAccordionHeader" className="erp-form-label-md">Sales Order Service Details</Accordion.Header>
                            <Accordion.Body id="orderDetailsAccordionBody">
                                <div className="row justify-content-between">
                                    <div className="col-lg-5 col-12">
                                        <div className="row">
                                            {
                                                keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && rb_sales_order_creation_type === 'M'
                                                    ? <>
                                                        <div className="col-md-4 col-12">
                                                            <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={viewBomFilterForm} >Add Materials</MDButton>
                                                        </div>
                                                    </>
                                                    : null
                                            }
                                            <div className="col-md-8 col-12">
                                                <div className="row">
                                                    <div className="col-12 col-sm-9 pe-md-0 pt-1">
                                                        <Form.Control type="text" id="txt-detail-data-highliter" className={`erp_input_field ${salesOrdDetailsData.length <= 0 ? 'd-none' : ""}`} />

                                                    </div>
                                                    <div className="col-12 col-sm-3 pt-2 pt-md-0">
                                                        <MDButton type="button" className={`erp-gb-button ${salesOrdDetailsData.length <= 0 ? 'd-none' : ""}`} variant="button" onClick={() => scrollToTableRow()} fontWeight="regular">Search</MDButton>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-5 col-12">
                                        <div className='row pt-md-1 pt-0 pt-lg-0'>
                                            {rb_sales_order_creation_type === 'Q' ?
                                                <>
                                                    <div className='col-sm-3 pt-lg-0 pt-1'>
                                                        <Form.Label className="erp-form-label">Quotation No. & Date {
                                                            rb_sales_order_creation_type === 'Q'
                                                                ? <span className="required">*</span>
                                                                : ''}
                                                        </Form.Label>
                                                    </div>
                                                    <div className="col-sm-9 col-12">
                                                        <div className="row">
                                                            <div className="col-12 col-md-8 pe-md-0">
                                                                <select id="cmb_sales_quotation_no" className="form-select form-select-sm" value={cmb_sales_quotation_no} onChange={(e) => { setSalesQuotationNo(e.target.value); FnComboOnChange('Quotation'); }} {...rb_sales_order_creation_type === 'M' ? { optional: 'optional', disabled: 'disabled' } : {}}>
                                                                    <option value="" disabled>Select</option>    {/* "quotation_no", "quotation_date" */}
                                                                    {quotationOpts?.map(quotation => (
                                                                        <option value={quotation.quotation_no} quotation_date={quotation.quotation_date}>{quotation.quotation_no}</option>
                                                                    ))}
                                                                </select>
                                                                <MDTypography variant="button" id="error_cmb_sales_quotation_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                            </div>
                                                            <div className="col-12 col-md-4 pt-md-0 pt-3 pe-1 pe-sm-0">
                                                                <Form.Control type="date" id='dt_sales_quotation_date' className="erp_input_field" value={dt_sales_quotation_date} onChange={(e) => { setSalesQuotationDate(e.target.value); validateFields(); }} {...rb_sales_order_creation_type === 'M' ? { optional: 'optional' } : {}} disabled='disabled' />
                                                                <MDTypography variant="button" id="error_dt_sales_quotation_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : null}

                                        </div>
                                    </div>

                                    <div className="col-lg-2 col-12">
                                        <div className='d-flex justify-content-end pt-3 pt-md-0'>
                                            <MDButton type="button" id="export-btn" className="erp-gb-button erp_MLeft_btn" variant="button" onClick={ExportToExcel} fontWeight="regular" disabled={salesOrdDetailsData.length <= 0 ? 'disabled' : ''}>Export</MDButton>
                                            <MDButton type="button" id="import-btn" className="erp-gb-button erp_MLeft_btn" variant="button" onClick={onImportClick} fontWeight="regular">Import</MDButton>
                                        </div>
                                    </div>

                                </div>
                                <div className="row py-1 py-md-3">
                                    {renderDetailsTable}
                                </div>
                                {/* {scheduleDataArray.length > 0 ?
                                <>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-9 col-12">
                                            <div className="row"> <Form.Label className="erp-form-label-lg text-start">Material Schedules</Form.Label> </div>
                                        </div>
                                    </div>
                                    <div className='row erp_table_scroll'>
                                        {renderSchedulesTable}
                                    </div></> : ""
                            } */}


                                {/* Schedules nested accordion. */}
                                <Accordion defaultActiveKey='1' id="schedulesAccordion">
                                    <Accordion.Item eventKey='0' id="schedulesAccordionItem">
                                        <Accordion.Header id="schedulesAccordionHeader" className="erp-form-label-md">Sales Project Schedules</Accordion.Header>
                                        <Accordion.Body id="schedulesAccordionBody">
                                            <div className="row">
                                                <div className={`table-responsive ${scheduleDataArray.length > 0 ? 'erp_table_scroll' : ''}`}>
                                                    {scheduleDataArray.length > 0
                                                        ? <>{renderSchedulesTable}</>
                                                        : ""
                                                    }
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                                {/* Sales order items tracking accordion */}
                                {salesOrdDetailsTrackingData.length > 0
                                    ?
                                    <>
                                        <hr />
                                        <Accordion defaultActiveKey='1' id="soItemsTrackingAccordion">
                                            <Accordion.Item eventKey='0' id="soItemsTrackingAccordionItem">
                                                <Accordion.Header id="soItemsTrackingAccordionHeader" className="erp-form-label-md">Materials Tracking Details</Accordion.Header>
                                                <Accordion.Body id="soItemsTrackingAccordionBody">
                                                    <div className="row">
                                                        <div className={`table-responsive ${salesOrdDetailsTrackingData.length > 0 ? 'erp_table_scroll' : ''}`}>
                                                            <> {renderDetailsTrackingTbl}</>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </>
                                    : ''}


                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion >

                    <hr />

                    <Accordion defaultActiveKey="0" >
                        <Accordion.Item eventKey={`${paymentTermsAccordEventKey}`}>
                            <Accordion.Header className="erp-form-label-md">Payment Terms & Details</Accordion.Header>
                            <Accordion.Body>
                                {
                                    PmtTermsdata.length > 0
                                        ? <>
                                            <div className="row">
                                                <span>
                                                    <input type='checkbox' class="" id="selectAllPmtTerms" onClick={(e) => checkBoxesSelection('selectAllPmtTerms')} /> <label class="erp-form-label pb-1"> Select All Payment Terms. </label>
                                                </span>
                                            </div>

                                            <div className="row">
                                                <div className={`table-responsive ${PmtTermsdata.length > 0 ? 'erp_table_scroll' : ''}`}>
                                                    <>{renderPaymentTermsTable}</>
                                                </div>
                                            </div>
                                        </>
                                        : ""
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion >

                    <hr />
                    <Accordion defaultActiveKey="0" >
                        <Accordion.Item eventKey={`${commonTermsAccordEventKey}`}>
                            <Accordion.Header className="erp-form-label-md">Sales Order Common Terms</Accordion.Header>
                            <Accordion.Body>
                                {
                                    SOCommonTermsData.length > 0
                                        ? <>
                                            <div className="row">
                                                <span>
                                                    <input type='checkbox' class="" id="selectAllCommonTerms" onClick={(e) => checkBoxesSelection('selectAllCommonTerms')} /> <label class="erp-form-label pb-1"> Select All Common Terms. </label>
                                                </span>
                                            </div>
                                            <div className="row">
                                                <div className={`table-responsive ${SOCommonTermsData.length > 0 ? 'erp_table_scroll' : ''}`}>
                                                    <>{renderCommonTermsTable}</>
                                                </div>
                                            </div>
                                        </>
                                        : ""
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <hr />

                    <Accordion defaultActiveKey="0" >
                        <Accordion.Item eventKey={`${taxationAccordEventKey}`}>
                            <Accordion.Header className="erp-form-label-md">Taxation Summary</Accordion.Header>
                            <Accordion.Body className="">
                                <div className="row">
                                    <div className={`table-responsive ${taxationSummaryData.length > 0 ? 'erp_table_scroll' : ''}`}>
                                        <>{renderTaxationSummaryTable}</>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion >

                    <hr />

                    {/* Billing Section */}
                    <div className="card">
                        <div className="card-header main_heding text-start py-1">
                            <label className='erp-form-label-lg'>Sales Order Totals</label>
                        </div>
                        {/* <div className='main_heding text-start'>
                        <label className='erp-form-label-lg'>Sales Order Totals</label>
                    </div> */}

                        <div className="card-body">
                            <form id="salesOrderTotalsFrm">
                                <div className="row">
                                    {/* first column */}
                                    <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Basic Total <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_basic_total' className="erp_input_field text-end" value={txt_basic_total} readOnly />
                                                <MDTypography variant="button" id="error_txt_basic_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        {/* <div className='row'>
                                            <div className="col-sm-5 col-lg-3">
                                                <Form.Label className="erp-form-label"> Transport Amount <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_transport_amount' className="erp_input_field text-end" value={txt_transport_amount} maxLength='19'
                                                    onChange={(e) => { setTransportAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); }}
                                                    onBlur={(e) => { setTransportAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); }}
                                                />
                                                <MDTypography variant="button" id="error_txt_transport_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 col-12">
                                                <Form.Label className="erp-form-label">Freight Amount <span className="required">*</span> </Form.Label>
                                            </div>
                                            <div className="col-sm-9 col-12">
                                                <div className="row">
                                                    <div className="col-12 col-md-3">
                                                        <Form.Check className="erp-form-label" type="checkbox" label={`IsTaxable`} id="chk_freight_isTaxable" checked={chk_freight_isTaxable} onChange={async (e) => { setFreightIsTaxable(e.target.checked); validateFields(); }} />
                                                    </div>
                                                    <div className="col-12 col-md-3 pt-md-0 pt-3 pe-0 me-0">
                                                        <Form.Control type="text" id='txt_freight_amount' className="erp_input_field text-end" value={txt_freight_amount} maxLength='19'
                                                            onChange={async (e) => { setFreightAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); }}
                                                            onBlur={async (e) => { setFreightAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4) === '' ? 0 : validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); }}
                                                        />
                                                        <MDTypography variant="button" id="error_txt_freight_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-md-0 pt-3">
                                                        <select id="cmb_freight_hsn_id" className="form-select form-select-sm" value={cmb_freight_hsn_id} onChange={async (e) => { setFreightHSNId(e.target.value); calculateGrandTotal(salesOrdDetailsData); validateFields() }} disabled={chk_freight_isTaxable ? false : true} {...(chk_freight_isTaxable ? {} : { optional: 'optional' })}>
                                                            <option value="" disabled>Select</option>
                                                            {freightHSNOpts?.map(hsnCode => (
                                                                <option value={hsnCode.hsn_sac_id} rate={hsnCode.hsn_sac_rate} lbl={hsnCode.hsn_sac_code}>{hsnCode.hsn_sac_code}</option>
                                                            ))}
                                                        </select>
                                                        <MDTypography variant="button" id="error_cmb_freight_hsn_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                        {/* <div className='row'>
                                            <div className="col-sm-5 col-lg-3">
                                                <Form.Label className="erp-form-label"> Packaging Amount <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_packing_amount' className="erp_input_field text-end" value={txt_packing_amount} maxLength='19'
                                                    onChange={(e) => { setPackingAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); }}
                                                    onBlur={(e) => { setPackingAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); }}
                                                />
                                                <MDTypography variant="button" id="error_txt_packing_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div> */}

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Discount (%)</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_discount_percent' className="erp_input_field  text-end" value={txt_discount_percent} optional="optional"
                                                    onChange={(e) => { handleFooterDiscountPercentChange(e); }} onBlur={(e) => { handleFooterDiscountPercentChange(e); }} />
                                                <MDTypography variant="button" id="error_txt_discount_percent" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Discount Amt.</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_discount_amount' className="erp_input_field text-end" value={txt_discount_amount} optional="optional"
                                                    onChange={(e) => { handleFooterDiscountAmountChange(e) }} onBlur={(e) => { handleFooterDiscountAmountChange(e) }} />
                                                <MDTypography variant="button" id="error_txt_discount_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Other Amt.</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_other_amount' className="erp_input_field text-end" value={txt_other_amount} maxLength='19' optional="optional"
                                                    onChange={(e) => { setOtherAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); }}
                                                    onBlur={(e) => { setOtherAmt(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); }}
                                                />
                                                <MDTypography variant="button" id="error_txt_other_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 pe-0">
                                                <Form.Label className="erp-form-label"> CGST Total <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_cgst_total' className="erp_input_field text-end" value={txt_cgst_total} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_cgst_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 pe-0">
                                                <Form.Label className="erp-form-label"> SGST Total <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_sgst_total' className="erp_input_field text-end" value={txt_sgst_total} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_sgst_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 pe-0">
                                                <Form.Label className="erp-form-label"> IGST Total <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_igst_total' className="erp_input_field text-end" value={txt_igst_total} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_igst_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                    </div>

                                    {/* second column */}
                                    <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">
                                        <div className='row'>
                                            <div className="col-sm-3 col-lg-3">
                                                <Form.Label className="erp-form-label"> Taxable Total <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_taxable_total' className="erp_input_field text-end" value={txt_taxable_total} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_taxable_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>


                                        <div className='row'>
                                            <div className="col-sm-3 pe-0">
                                                <Form.Label className="erp-form-label"> Grand Total <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_grand_total' className="erp_input_field text-end" value={txt_grand_total} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_grand_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 pe-0">
                                                <Form.Label className="erp-form-label"> Round Off <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_roundoff' className="erp_input_field text-end" value={txt_roundoff} readOnly maxLength='19' />
                                                <MDTypography variant="button" id="error_txt_roundoff" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-sm-3 col-12'>
                                                <Form.Label className="erp-form-label">Agents</Form.Label>
                                            </div>
                                            <div className='col-sm-8 col-10'>
                                                <select id="cmb_agent_id" className="form-select form-select-sm" value={cmb_agent_id} onChange={(e) => { setAgentId(e.target.value); FnComboOnChange('Agent') }} optional="optional" >
                                                    <option value="" disabled>Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {agentOpts?.map(agent => (
                                                        <option value={agent.field_id} lbl={agent.field_name}>{agent.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_agent_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className='col-sm-1 col-2'>
                                                <MdRefresh style={{ color: 'black' }} onClick={e => { FnUpdateComboBoxData('UpdateAgents'); }} />
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3 pe-0">
                                                <Form.Label className="erp-form-label"> Agent's(%) </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_agent_percent' className="erp_input_field text-end" value={txt_agent_percent} onChange={(e) => { setAgentPercent(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesOrdDetailsData); }} maxLength='5' optional="optional" />
                                                <MDTypography variant="button" id="error_txt_agent_percent" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-sm-3'>
                                                <Form.Label className="erp-form-label">Agent's Paid Status</Form.Label>
                                            </div>
                                            <div className='col'>
                                                <select id="cmb_agent_paid_status" className="form-select form-select-sm" value={cmb_agent_paid_status} onChange={(e) => { setAgentPaidStatus(e.target.value); }} optional="optional"  >
                                                    <option value="P" lbl="Pending">Pending</option>
                                                    <option value="A" lbl="Approved">Approved</option>
                                                    <option value="C" lbl="Completed">Completed</option>
                                                    <option value="X" lbl="Canceled">Canceled</option>
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_sales_order_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                    </div>

                                    {/* third column */}
                                    <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">
                                        <div className="row">
                                            <div className="col-sm-3 col-12">
                                                <Form.Label className="erp-form-label">Ord. Accept Status <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col-sm-3 col-12">
                                                <div className="erp_form_radio">
                                                    <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Pending" type="radio" lbl="Pending" value="P" name="rb_sales_order_acceptance_status" checked={rb_sales_order_acceptance_status === "P"} onClick={(e) => { setSalesOrdAcceptanceStatus('P'); }} disabled={!isApprove} /> </div>
                                                    <div className="sCheck me-3"> <Form.Check className="erp_radio_button" label="Aprroved" type="radio" lbl="Aprroved" value="A" name="rb_sales_order_acceptance_status" checked={rb_sales_order_acceptance_status === "A"} onClick={(e) => { setSalesOrdAcceptanceStatus('A') }} disabled={!isApprove} /> </div>
                                                    <div className="fCheck"> <Form.Check className="erp_radio_button" label="Canceled" type="radio" lbl="Canceled" value="X" name="rb_sales_order_acceptance_status" checked={rb_sales_order_acceptance_status === "X"} onClick={(e) => { setSalesOrdAcceptanceStatus('X') }} disabled={!isApprove} /> </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3">
                                                <Form.Label className="erp-form-label">Status Remark
                                                    {
                                                        ['R', 'C', 'Z'].includes(cmb_sales_order_status) || rb_preeclose_sales_order === '1' ? <span className="required">*</span> : ""
                                                    }
                                                </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control as="textarea" rows={1} id="txt_status_remark" className="erp_txt_area" value={txt_status_remark} onInput={e => { setStatusRemark(e.target.value); validateFields(); }} maxlength="1000" placeholder="Reason of canceled, Rejection, Preeclosed."
                                                    {...(['R', 'C', 'Z'].includes(cmb_sales_order_status) || rb_preeclose_sales_order === '1' ? {} : { optional: 'optional', disabled: true })}
                                                // disabled={!isApprove || ['R', 'C', 'Z'].includes(cmb_sales_order_status)  || rb_preeclose_sales_order === '0' ? true : false}
                                                // optional={['R', 'C', 'Z'].includes(cmb_sales_order_status) || rb_preeclose_sales_order === '1' ? '' : 'optional'} 
                                                />
                                                <MDTypography variant="button" id="error_txt_status_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-sm-3">
                                                <Form.Label className="erp-form-label">Other Terms & Conditions</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control as="textarea" rows={2} id="txt_other_terms_conditions" className="erp_txt_area" value={txt_other_terms_conditions} onInput={e => { setOtherTermsConditions(e.target.value); }} maxlength="1000" optional="optional" />
                                                <MDTypography variant="button" id="error_txt_other_terms_conditions" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-sm-3">
                                                <Form.Label className="erp-form-label">Mail Send Status</Form.Label>
                                            </div>
                                            <div className="col">
                                                <div className="erp_form_radio">
                                                    <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Pending" type="radio" lbl="Pending" value="P" name="rb_sales_order_mail_sent_status" checked={rb_sales_order_mail_sent_status === "P"} onClick={(e) => { setSalesOrderMailSentStatus('P'); }} disabled='disabled' optional="optional" /> </div>
                                                    <div className="sCheck me-3"> <Form.Check className="erp_radio_button" label="Sent" type="radio" lbl="Sent" value="S" name="rb_sales_order_mail_sent_status" checked={rb_sales_order_mail_sent_status === "S"} onClick={(e) => { setSalesOrderMailSentStatus('S') }} disabled='disabled' optional="optional" /> </div>
                                                    <div className="fCheck"> <Form.Check className="erp_radio_button" label="Canceled" type="radio" lbl="Failed" value="F" name="rb_sales_order_mail_sent_status" checked={rb_sales_order_mail_sent_status === "F"} onClick={(e) => { setSalesOrderMailSentStatus('F') }} disabled='disabled' optional="optional" /> </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-3">
                                                <Form.Label className="erp-form-label">Pree-Closed Sales Order</Form.Label>
                                            </div>
                                            <div className="col">
                                                <div className="erp_form_radio">
                                                    <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="1" name="rb_preeclose_sales_order" checked={rb_preeclose_sales_order === "1"} onChange={(e) => { setPreeCloseSalesOrder('1'); }} optional="optional" /> </div>
                                                    <div className="sCheck me-3"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="0" name="rb_preeclose_sales_order" checked={rb_preeclose_sales_order === "0"} onChange={(e) => { setPreeCloseSalesOrder('0'); }} optional="optional" /> </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                {/* Row End Billing */}
                            </form>
                        </div>
                    </div>
                    {/* Billing Section Ends*/}

                    <hr />
                    <div className="text-center mb-5">
                        <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>

                        <input type="file" id="Excelfile" ref={importFile} onChange={importedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                        <MDButton type="button" id="save-btn" className="erp-gb-button erp_MLeft_btn" variant="button" BtnType='save' onClick={(e) => FnGenerateSalesOrder(e, 'A')}
                            fontWeight="regular">Save</MDButton>

                        {/* {
                            keyForViewUpdate === 'approve'
                                ? <>
                                    <MDButton type="button" id="reject-btn" className="erp-gb-button erp_MLeft_btn" variant="button" BtnType='reject' onClick={(e) => FnGenerateSalesOrder(e, 'R')} fontWeight="regular">Reject</MDButton>
                                </>
                                : ""
                        } */}

                        <MDButton className="erp-gb-button erp_MLeft_btn" id='uploadDocBtn' variant="button" fontWeight="regular" onClick={viewDocumentForm} disabled={txt_sales_order_no !== 0 && keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' ? false : true}>Upload Document</MDButton>&nbsp;

                        {
                            keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'update' && keyForViewUpdate !== 'followup'
                                ? <>
                                    <MDButton type="button" id="clearFormBtn" className="erp-gb-button erp_MLeft_btn" variant="button" onClick={e => { FnSetDefaultFormFields(); }} fontWeight="regular" disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'disabled' : ''} >Clear</MDButton>
                                </>
                                : null
                        }

                        {
                            keyForViewUpdate === 'view' && cmb_sales_order_status !== 'P'
                                ? <>
                                    <MDButton type="button" className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" onClick={() => acceptSalesOrder()}>Accept</MDButton>
                                    <MDButton className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" onClick={() => { printInvoice(true); }} >Print Sales Order<FiDownload className="erp-download-icon-btn" /></MDButton>
                                </>
                                : null
                        }

                    </div >
                </>
            </div>

            {/* ADD Filter modal */}
            <Modal size="lg" className='erp_document_Form' show={showBomFilterForm} onHide={handleCloseBomFilterForm} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title className='erp_modal_title'>Project Selection</Modal.Title>
                    <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseBomFilterForm}></button></span>
                </Modal.Header>
                <Modal.Body>
                    <React.Fragment>
                        <FrmMBOMSRFilter closeModal={handleCloseBomFilterForm} ptSelected={{ product_type: cmb_sales_order_type_id, master_val: 'smv_product_type' }} />
                    </React.Fragment>
                </Modal.Body>
            </Modal>

            {/* Modal for schedule quantity */}
            {/* {renderSchedulesModal} */}
            <Modal size="lg" className='erp_document_Form' show={showSchedule} onHide={handleCloseSchedulesPopup} backdrop="static" keyboard={false} centered>
                <Modal.Header className="py-2">
                    <Modal.Title className='erp_modal_title'>Add Project Schedules</Modal.Title>
                    <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseSchedulesPopup}></button></span>
                </Modal.Header>
                <Modal.Body className="pb-0">
                    <dl className="row mb-0">
                        <div className="col-md-6">
                            <dl className="row mb-0">
                                <dt className="col-sm-3 erp-form-label-md-lg">Service Name:</dt>
                                <dd className="col-sm-9 erp-form-label" id='productMatName'></dd>
                            </dl>
                        </div>
                        <div className="col-md-6 col-12">
                            <dl className="row mb-0">
                                <dt className="col-sm-4 erp-form-label-md-lg">Process Duration:</dt>
                                <dd className="col-sm-8 erp-form-label" id='processduration_'></dd>
                            </dl>
                        </div>

                    </dl>

                    <dl className="row mb-0">
                        <div className="col-md-6">
                            <dl className="row mb-0">
                                <dt className="col-sm-3 erp-form-label-md-lg">Service STD. Hrs:</dt>
                                <dd className="col-sm-9 erp-form-label" id='stdhr'></dd>
                            </dl>
                        </div>

                        <div className="col-md-6 col-12">
                            <dl className="row mb-0">
                                <dt className="col-sm-4 erp-form-label-md-lg">Technical Specification:</dt>
                                <dd className="col-sm-8 erp-form-label" id='techspec'></dd>
                            </dl>
                        </div>

                    </dl>

                    <div className='row table-responsive my-3'>
                        <Table className="erp_table" id="scheduleTable" bordered striped >
                            <thead className='erp_table_head erp_table_th'>
                                <tr>
                                    <th>Sevice Start Date</th>
                                    <th>Sevice End Date</th>
                                    <th>Remark</th>
                                </tr>
                            </thead>

                            <tbody role="rowgroup">
                                <tr scheduleTrCount='1' id="RMSchedulesEntry-tr-1">
                                    {/* //<td style={{ width: '100px' }}><IoRemoveCircleOutline className='erp_trRemove_icon' /> <IoAddCircleOutline className='erp_trAdd_icon disableClass' onClick={() => addNewScheduleRow()} /></td> */}
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                </Modal.Body>
                <Modal.Footer className='erp-modal-footer'>
                    <MDButton type="button" className="erp-gb-button" variant="button"
                        fontWeight="regular" onClick={() => schedule()}>Schedule</MDButton>
                </Modal.Footer>
            </Modal>

            {/* Document modal */}
            <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
                    <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                </Modal.Header>
                <Modal.Body>
                    <DocumentF group_id={txt_sales_order_no} document_group={docGroup} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Generic Response Messaage Modals */}
            <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
            <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />

            {/* Add new Record Popup */}
            <Modal size="lg" show={showAddRecModal} onHide={handleCloseAddRecModal} backdrop="static" keyboard={false} centered >
                <Modal.Body className='erp_city_modal_body'>
                    <div className='row'>
                        <div className='col-12 align-self-end'>
                            <button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseAddRecModal}></button>
                        </div>
                    </div>
                    {openAddRecordPopUp()}
                </Modal.Body>
            </Modal >

            {/* Send Email On Approval Modal */}
            <MailSentModal handleCloseMailSentModal={() => handleCloseMailSentModal()} sendConfirm={() => sendEmail()} show={[showMailSentModal, succMsg, modalOrderDetails]} />
        </>
    )
}

export default SalesOrderProjectEntry;
