// React Imports
import { React, useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Form from 'react-bootstrap/Form';
import { Button, Card, Modal } from 'react-bootstrap';
import Tooltip from "@mui/material/Tooltip";

// React icons
import { AiOutlineDownCircle } from "react-icons/ai";
import { RxCrossCircled } from "react-icons/rx";
import { TbArrowsRight } from "react-icons/tb";
import { TbArrowsLeft } from "react-icons/tb";
import { TbArrowsDown } from "react-icons/tb";
import { TbArrowsUp } from "react-icons/tb";
import { HiDocumentDuplicate, HiOutlineArrowNarrowRight } from "react-icons/hi";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { HiOutlineArrowNarrowUp } from "react-icons/hi";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { FiDownload } from 'react-icons/fi';

//File Imports
import ComboBox from "Features/ComboBox";

//Export Related imports
import PdfExport from 'Features/Exports/PdfExport';
import ExcelExport from "Features/Exports/ExcelExport";
import JsonExport from "Features/Exports/JsonExport";
import CSVExport from "Features/Exports/CSVExport";
import Datatable from 'components/DataTable';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import { resetGlobalQuery } from 'assets/Constants/config-constant';
import { globalQuery } from 'assets/Constants/config-constant';
import ConfigConstants from 'assets/Constants/config-constant';


function SalesOrderProjectsListing({ compType = 'Masters' }) {

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, COMPANY_NAME } = configConstants;

    let storeSelectedValues = [];
    const [recordData, setRecordData] = useState([]);
    const [filterComboBox, setFilterComboBox] = useState([]);
    let reportName = "Sales Order Report";

    //useRef
    const pdfExp = useRef();
    const exlsExp = useRef();
    const jsonExp = useRef();
    const csvExp = useRef();
    const pdfExpFilters = {};
    const comboBox = useRef();
    const navigate = useNavigate();
    const validateNumberDateInput = useRef();

    // Table Data
    const [salesOrderTblData, setSalesOrderTblData] = useState([]);
    const [salesOrderTblColumns, setSalesOrderTblColumns] = useState([]);
    const [selectedSalesOrdReqData, setSelectedSalesOrdReqData] = useState();

    const [reportType, setReportType] = useState('summary');
    const [selectedFilters, setSelectedFilters] = useState([]);



    // Export Fields
    let dataExport = [];
    let columnExport = [];
    let rptColumnHeads;


    useEffect(() => {
        $('.hide-show-filters').hide();

    }, [])

    useEffect(() => {
        const rptApiCall = async () => {
            await FnShowSalesOrderRptRecords(reportType);
            await fetchFilteredData(PageCurrent, entriesPerPage);
        }
        rptApiCall()

    }, [reportType, compType])

    function toggleFilter() {
        $(this).text(function (_, currentText) {
            return currentText == "▼" ? "▲" : "▼";
        }, []);
        $('.hide-show-filters').toggle('fast');
    };


    // Pagination Variables
    const pageEntriesOptions = [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "500", value: 500 },
    ];
    let [entriesPerPage, setEntriesPerPage] = useState(
        pageEntriesOptions[0].value
    );
    const [pageCount, setpageCount] = useState(0);
    const [PageCurrent, setcurrentPage] = useState(0);

    // Popup Fields
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    let operatorLabels = { "=": "is", "!=": "is not", "1": "active", "0": "closed", "o": "open", "!*": "none", "*": "any", "~": "contains", "!~": "doesn't contain", "^": "starts with", "$": "ends with" };
    let operatorByType = {
        "list": ["=", "!="], "status": ["1", "0"], "list_status": ["o", "=", "!", "c", "*"], "list_optional": ["=", "!", "!*", "*"],
        "list_subprojects": ["*", "!*", "=", "!"], "string": ["~", "=", "!~", "!", "^", "$"], "text": ["~", "!~", "^", "$", "!*", "*"],
        "integer": ["=", "\u003e=", "\u003c=", "\u003e\u003c", "!*", "*"], "float": ["=", "\u003e=", "\u003c=", "\u003e\u003c", "!*", "*"],
        "relation": ["=", "!", "=p", "=!p", "!p", "*o", "!o", "!*", "*"], "tree": ["=", "~", "!*", "*"]
    };

    const [availableColumns, setAvailableColumns] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [groupByOpts, setGroupByOpts] = useState([]);


    const FnShowSalesOrderRptRecords = async (reportIdentifierKey) => {
        try {
            const rptApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesOrderMasterService/FnShowAllReportRecords?reportType=${reportIdentifierKey}`);
            const responce = await rptApiCall.json();
            console.log(responce);
            if (responce.content.length !== 0) {
                console.log("Sales Order summary Report Details: ", responce);
                setRecordData(responce.content);
                rptColumnHeads = Object.keys(responce.content);
                let tempOptionBox = [];
                let filterBox = [];
                for (let colKey = 0; colKey < rptColumnHeads.length; colKey++) {
                    const content = responce.content;
                    const columnName = rptColumnHeads[colKey];
                    const value = content[columnName];
                    if (value !== null) {
                        const myArray = value.split(":");
                        console.log(myArray[1]);
                        switch (myArray[1]) {
                            case "Y":
                                filterBox.push({
                                    Headers: rptColumnHeads[colKey],
                                    accessor: rptColumnHeads[colKey],
                                });
                            case "O":
                                if (
                                    rptColumnHeads[colKey] !== "sales_order_no" && rptColumnHeads[colKey] !== "sales_order_version" && rptColumnHeads[colKey] !== "sales_order_status" && rptColumnHeads[colKey] !== "financial_year") {
                                    tempOptionBox.push({
                                        Headers: rptColumnHeads[colKey],
                                        accessor: rptColumnHeads[colKey],
                                    });
                                }
                                break;
                            default:
                                break;
                        }
                    }
                }
                setFilterComboBox(filterBox);
                setAvailableColumns(tempOptionBox)
                setGroupByOpts(tempOptionBox);

            } else {
                setFilterComboBox([]);
                // setOptionBox([]);
                setAvailableColumns([]);
                setGroupByOpts([]);
            }
        } catch (error) {
            console.log("error: ", error);
        }
    };


    const fetchFilteredData = async (page, size) => {
        const executeQuery = submitQuery();
        const formData = new FormData();
        formData.append(`jsonQuery`, executeQuery)
        const requestOptions = { method: 'POST', body: formData };
        try {
            const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/filter/FnMShowFilterRecords/${page}/${size}`, requestOptions)
            const responce = await fetchResponse.json();
            console.log("Responce when fetch FilteredData For Sales order listing details: ", responce)
            let nColumn = [];
            const total = responce.totalElements;
            setpageCount(Math.ceil(total / size));
            if (responce.content.length > 0) {
                let newColumnHeads = responce.Headerkeys;
                if (storeSelectedValues.length !== 0) {
                    for (let selValCount = 0; selValCount < storeSelectedValues.length; selValCount++) {
                        if (selValCount === 0) {
                            nColumn.push({
                                Headers: "Action", accessor: "Action",
                                Cell: row => (
                                    <div style={{ display: "flex" }}>
                                        {compType === 'Register' ?
                                            null : <>
                                                {/* <Tooltip title={row.row.original.sales_order_status === 'A' ? 'Sales order is approved...!' : row.row.original.sales_order_status === 'R' ? 'Sales order is rejected...!' : "Edit"} placement="top">
                                                    <MDTypography className="erp-view-btn" >
                                                        <MdModeEdit className="erp-edit-btn" onClick={e => viewUpdateDelete(row.row.original, 'update')} />
                                                    </MDTypography>
                                                </Tooltip> */}
                                                <Tooltip title="Delete" placement="top">
                                                    <MDTypography className="erp-view-btn" >
                                                        <MdDelete className="erp-delete-btn" onClick={e => viewUpdateDelete(row.row.original, 'delete')} />
                                                    </MDTypography>
                                                </Tooltip>
                                                {/* <Tooltip title={row.row.original.sales_order_status === 'A' ? 'Sales order is approved...!' : row.row.original.sales_order_status === 'R' ? 'Sales order is rejected...!' : "Approve"} placement="top">
                                                    <MDTypography className="erp-view-btn" >
                                                            <BsFillCheckCircleFill className="erp-view-btn" onClick={e => viewUpdateDelete(row.row.original, 'approve')} />
                                                        </MDTypography>
                                                </Tooltip> */}
                                                <Tooltip title="Copy Sales Order" placement="top">
                                                    <MDTypography className="erp-view-btn" >
                                                        <HiDocumentDuplicate className="erp-view-btn" onClick={e => viewUpdateDelete(row.row.original, 'copy')} />
                                                    </MDTypography>
                                                </Tooltip>
                                            </>
                                        }
                                        <Tooltip title="View" placement="top">
                                            <MDTypography className="erp-view-btn" >
                                                <AiFillEye className="erp-view-btn" onClick={e => viewUpdateDelete(row.row.original, 'view')} />
                                            </MDTypography>
                                        </Tooltip>

                                    </div >
                                ),
                            });
                        }
                        for (let colKey = 0; colKey < newColumnHeads.length; colKey++) {
                            if (storeSelectedValues[selValCount] === newColumnHeads[colKey]) {
                                if (newColumnHeads[colKey].includes('_date')) {
                                    nColumn.push({
                                        Headers: newColumnHeads[colKey], accessor: newColumnHeads[colKey],
                                        Cell: row => (
                                            <>
                                                {validateNumberDateInput.current.formatDateToDDMMYYYY(row.row.original[newColumnHeads[colKey]])}
                                            </>
                                        ),
                                    });
                                } else {
                                    nColumn.push({ Headers: newColumnHeads[colKey], accessor: newColumnHeads[colKey] });
                                }
                            }
                        }
                    }
                } else {
                    for (let colKey = 0; colKey < newColumnHeads.length; colKey++) {
                        if (colKey === 0) {
                            nColumn.push({
                                Headers: "Action", accessor: "Action",
                                Cell: row => (
                                    <div style={{ display: "flex" }}>
                                        {compType === 'Register' ?
                                            null : <>
                                                {/* <Tooltip title={row.row.original.sales_order_status === 'A' ? 'Sales order is approved...!' : row.row.original.sales_order_status === 'R' ? 'Sales order is rejected...!' : "Edit"} placement="top">
                                                    <MDTypography className="erp-view-btn" >
                                                        <MdModeEdit className="erp-edit-btn" onClick={e => viewUpdateDelete(row.row.original, 'update')} />
                                                    </MDTypography>
                                                </Tooltip> */}
                                                <Tooltip title="Delete" placement="top">
                                                    <MDTypography className="erp-view-btn" >
                                                        <MdDelete className="erp-delete-btn" onClick={e => viewUpdateDelete(row.row.original, 'delete')} />
                                                    </MDTypography>
                                                </Tooltip>
                                                {/* <Tooltip title={row.row.original.sales_order_status === 'A' ? 'Sales order is approved...!' : row.row.original.sales_order_status === 'R' ? 'Sales order is rejected...!' : "Approve"} placement="top">
                                                    <MDTypography className="erp-view-btn" >
                                                        <BsFillCheckCircleFill className="erp-view-btn" onClick={e => viewUpdateDelete(row.row.original, 'approve')} />
                                                    </MDTypography>
                                                </Tooltip> */}
                                                <Tooltip title="Copy Sales Order" placement="top">
                                                    <MDTypography className="erp-view-btn" >
                                                        <HiDocumentDuplicate className="erp-view-btn" onClick={e => viewUpdateDelete(row.row.original, 'copy')} />
                                                    </MDTypography>
                                                </Tooltip>
                                            </>
                                        }
                                        <Tooltip title="View" placement="top">
                                            <MDTypography className="erp-view-btn" >
                                                <AiFillEye className="erp-view-btn" onClick={e => viewUpdateDelete(row.row.original, 'view')} />
                                            </MDTypography>
                                        </Tooltip>

                                    </div>
                                ),
                            });
                        }
                        if (!newColumnHeads[colKey].includes('company_') && !newColumnHeads[colKey].includes('_id') && !newColumnHeads[colKey].includes('is_') && !newColumnHeads[colKey].includes('_on') && !newColumnHeads[colKey].includes('_by') && !newColumnHeads[colKey].includes('field_name')) {
                            if (newColumnHeads[colKey].includes('_date')) {
                                nColumn.push({
                                    Headers: newColumnHeads[colKey], accessor: newColumnHeads[colKey],
                                    Cell: row => (
                                        <>
                                            {validateNumberDateInput.current.formatDateToDDMMYYYY(row.row.original[newColumnHeads[colKey]])}
                                        </>
                                    ),
                                });
                            } else {
                                nColumn.push({ Headers: newColumnHeads[colKey], accessor: newColumnHeads[colKey] });
                            }
                        }
                    }
                }
                setSalesOrderTblColumns(nColumn);
                setSalesOrderTblData(responce.content)
            } else {
                setSalesOrderTblColumns([]);
                setSalesOrderTblData([])
            }
            return responce;
        } catch (error) {
            console.log("error in fetch FilteredData For Sales Order: ", error)
        }
    };

    const fetchFilteredDataToExport = async () => {
        const executeQuery = submitQuery();
        const formData = new FormData();
        formData.append(`jsonQuery`, executeQuery)
        const requestOptions = { method: 'POST', body: formData };
        try {
            const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/filter/FnShowFilterRecords`, requestOptions)
            const responce = await fetchResponse.json();
            console.log("Responce when fetch FilteredData : ", responce)
            let filterColumnsToExport = [];
            if (responce.content.length > 0) {
                let newColumnHeads = responce.Headerkeys;

                if (storeSelectedValues.length > 2) {
                    filterColumnsToExport = storeSelectedValues

                } else {
                    for (let colKey = 0; colKey < newColumnHeads.length; colKey++) {
                        if (!newColumnHeads[colKey].includes('_id') && !newColumnHeads[colKey].includes('is_') && !newColumnHeads[colKey].includes('_on') && !newColumnHeads[colKey].includes('_by') && !newColumnHeads[colKey].includes('field_name') || colKey === 0) {
                            filterColumnsToExport.push(newColumnHeads[colKey]);
                        }
                    }
                }
                dataExport = responce.content
                columnExport = filterColumnsToExport
            } else {
                dataExport = []
                columnExport = []
            }
            return responce;
        } catch (error) {
            console.log("error: ", error)
        }
    };


    const submitQuery = () => {
        let selectBox = document.getElementById("selectedColumns");
        let group_by = document.getElementById("group_by").value;
        let jsonQuery = { 'viewname': {}, 'selectQuery': {}, 'whereQuery': {}, 'operators': {}, 'group_by': {}, "additionalParam": {}, "orderBy": {} };
        storeSelectedValues = []
        if (selectBox.length > 4) {
            for (let index = 0; index < selectBox.length; index++) {
                let optionValue = selectBox.options[index].value;
                jsonQuery['selectQuery'][optionValue] = optionValue;
                storeSelectedValues.push(optionValue)
            }
        }
        jsonQuery['group_by']["GROUP BY"] = group_by;
        for (let selIndex = 0; selIndex < selectedFilters.length; selIndex++) {
            let fieldvalue = selectedFilters[selIndex].label.trim()
            let operatorSelectValue = document.getElementById(`operators_${fieldvalue}_id`).value;
            let valueSelectValue = document.getElementById(`values_${fieldvalue}_id`).value;
            if (selectedFilters[selIndex].type === 'T') {
                switch (operatorSelectValue) {
                    case '~':
                        operatorSelectValue = "LIKE"
                        valueSelectValue = "%" + valueSelectValue + "%";
                        break;
                    case '!~':
                        operatorSelectValue = "NOT LIKE"
                        valueSelectValue = "%" + valueSelectValue + "%";
                        break;
                    case '^':
                        operatorSelectValue = "LIKE"
                        valueSelectValue = "%" + valueSelectValue;
                        break;
                    case '$':
                        operatorSelectValue = "LIKE"
                        valueSelectValue = valueSelectValue + "%";
                        break;
                    default:
                        break;
                }
            }
            jsonQuery['whereQuery'][fieldvalue] = valueSelectValue;
            jsonQuery['operators'][fieldvalue] = operatorSelectValue;
            pdfExpFilters[fieldvalue] = fieldvalue;
        }
        jsonQuery['additionalParam']['is_delete'] = "0";
        jsonQuery['additionalParam']['company_id'] = COMPANY_ID;
        jsonQuery['additionalParam']['company_branch_id'] = COMPANY_BRANCH_ID;
        jsonQuery['orderBy']['ORDER BY'] = 'sales_order_master_transaction_id';

        if (reportType === 'summary') {
            reportName = "Sales Order Summary Report"
            jsonQuery['viewname']['viewName'] = 'mtv_sales_order_master_services_summary'
        } else {
            reportName = "Sales Order Details Report"
            jsonQuery['viewname']['viewName'] = 'mtv_sales_order_details_services'
        }
        let executeQuery = JSON.stringify(jsonQuery)
        return executeQuery;
    }

    function viewUpdateDelete(data, key) {
        const updateData = {
            // sales_order_master_transaction_id: data.sales_order_master_transaction_id,
            sales_order_no: data.sales_order_no,
            sales_order_version: data.sales_order_version,
            financial_year: data.financial_year
        }
        setSelectedSalesOrdReqData(updateData)
        if (key === 'view' || key === 'delete' || key === 'copy') {
            key === 'delete' ? setShow(true) : key === 'copy' ? infoForUpdate(updateData, 'copy') : infoForUpdate(updateData, 'view');
        }

        // if (data.sales_order_status !== 'A' && data.sales_order_status !== 'R') {
        if (data.sales_order_status === 'P') {
            switch (key) {
                case 'update': infoForUpdate(updateData, 'update'); break;
                case 'delete': setShow(true); break;
                case 'approve': infoForUpdate(updateData, 'approve'); break;
                case 'add': infoForUpdate(updateData, 'add'); break;
                case 'copy': infoForUpdate(updateData, 'copy'); break;
                default: break;
            }
        }
    }

    const deleteRecords = async () => {
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesOrderMasterService/FnDeleteRecord/${selectedSalesOrdReqData.sales_order_version}/${COMPANY_ID}?sales_order_no=${selectedSalesOrdReqData.sales_order_no}`, method)
            const responce = await deleteApiCall.json();
            console.log("Sales Order Deleted: ", responce);
            if (responce.success == '1') {
                setShow(false)
                fetchFilteredData(PageCurrent, entriesPerPage);
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const handlePageCountClick = async () => {
        let count = document.getElementById("page_entries_id").value;
        setEntriesPerPage(count)
        console.log(pageCount)
        setcurrentPage(0)
        await fetchFilteredData(0, count);
    }

    const handlePageClick = async (pageNo) => {
        console.log(pageNo.selected)
        let currentPage = pageNo.selected;
        console.log("current Page: ", currentPage)
        setcurrentPage(currentPage);
        const commentsFormServer = await fetchFilteredData(currentPage, entriesPerPage);
        console.log("commentsFormServer: ", commentsFormServer)
    }

    const infoForUpdate = async (updateData, key) => {
        sessionStorage.removeItem('keyForViewUpdate');
        sessionStorage.setItem('SalesOrderDetails', JSON.stringify(updateData));
        sessionStorage.setItem('keyForViewUpdate', key);
        sessionStorage.removeItem('bomfilteredData');
        sessionStorage.removeItem('orderDetailsData')
        localStorage.removeItem('SalesOrdPmtTermsData');
        localStorage.removeItem('salesOrderMaterialSchedules');
        localStorage.removeItem('SalesOrdCommonTermsData');
        localStorage.removeItem('taxationSummary')
        localStorage.removeItem('scheduledData')
        navigate("/Transactions/TSalesOrder/Projects/SalesOrderProjectEntry", { state: { idList: updateData, keyForViewUpdate: key, compType: compType } })
    }

    const moveAllToAvailableColumns = () => {
        const columnsToMove = selectedColumns.filter(
            (column) => column !== 'sales_order_no' && column !== 'sales_order_version'
        );
        setSelectedColumns(selectedColumns.filter(
            (column) => column === 'sales_order_no' || column === 'sales_order_version'
        ));
        setAvailableColumns([...availableColumns, ...columnsToMove]);
    }

    function moveOptionTop() {
        let opt = $('#selectedColumns option:selected');
        opt.insertBefore($('#selectedColumns option:first-child'));
    }

    function moveOptionBottom() {
        let opt = $('#selectedColumns option:selected');
        opt.insertAfter($('#selectedColumns option:last-child'));
    }

    function moveOptionup() {
        let opt = $('#selectedColumns option:selected');
        if (opt.is(':first-child')) {
            opt.insertAfter($('#selectedColumns option:last-child'));
        }
        else {
            opt.insertBefore(opt.prev());
        }
    };

    function moveOptiondown() {
        let opt = $('#selectedColumns option:selected');
        if (opt.is(':last-child')) {
            opt.insertBefore($('#selectedColumns option:first-child'));
        }
        else {
            opt.insertAfter(opt.next());
        }
    };

    const addSelectedColumns = () => {
        const selectedOption = document.getElementById("availableColumns");
        const selectedValue = selectedOption.value;
        if (selectedValue) {
            const selectedColumn = availableColumns.find(column => column.accessor === selectedValue);
            if (selectedColumn) {
                setAvailableColumns(availableColumns.filter(column => column.Headers !== selectedValue));
                setSelectedColumns([...selectedColumns, selectedColumn]);
            }
        }
    }


    const moveAllToSelectedColumns = () => {
        setSelectedColumns([...selectedColumns, ...availableColumns]);
        setAvailableColumns([]);
    }


    const addAvailableColumns = () => {
        const selectedOption = document.getElementById("selectedColumns");
        const selectedValue = selectedOption.value;
        if (selectedValue) {
            const selectedColumn = selectedColumns.find(column => column.Headers === selectedValue);
            if (selectedColumn) {
                setAvailableColumns([...availableColumns, selectedColumn]);
                setSelectedColumns(selectedColumns.filter(column => column.Headers !== selectedValue));
            }
        }
    }

    const addFilterSelect = async () => {
        debugger
        let filterText = document.getElementById('add_filter_select');
        document.querySelectorAll("#add_filter_select option").forEach(opt => {
            if (opt.value === filterText.value) {
                opt.disabled = true;
            }
        });
        const value = recordData[filterText.value];
        const myArray = value.split(":");
        const newFilter = {
            id: filterText.value,
            label: filterText.value,
            type: myArray[2],
            operatorArray: [],
            dataArray: []
        };
        switch (myArray[2]) {
            case 'C':
                resetGlobalQuery();
                globalQuery.columns.push(`DISTINCT (${filterText.value})`);
                globalQuery.conditions.push({
                    field: "company_id",
                    operator: "=",
                    value: COMPANY_ID
                });
                globalQuery.conditions.push({
                    field: "is_delete",
                    operator: "=",
                    value: 0
                });
                globalQuery.table = myArray[3]
                let masterList = await comboBox.current.fillFiltersCombo(globalQuery)
                newFilter.operatorArray = operatorByType.list
                newFilter.dataArray = masterList
                break;
            case 'P':
                let propertyList = await comboBox.current.fillComboBox(myArray[3])
                newFilter.operatorArray = operatorByType.list
                newFilter.dataArray = propertyList
                break;
            case 'T':
                newFilter.operatorArray = operatorByType.string
                break;
            case 'H':
                // Extracting values within parentheses from the last element
                const valuesInParentheses = myArray[myArray.length - 1].slice(1, -1);
                // Splitting the string into an array of values
                const resultArray = valuesInParentheses.split(',');
                console.log(resultArray);
                newFilter.operatorArray = operatorByType.list
                newFilter.dataArray = resultArray
                break;
            case 'D':
                newFilter.operatorArray = operatorByType.list
                break;
        }
        // Update the state with the new filter
        setSelectedFilters(prevFilters => [...prevFilters, newFilter]);
    }

    const removeFilter = (filterId) => {
        // Remove the filter from the state
        setSelectedFilters(prevFilters => prevFilters.filter(filter => filter.id !== filterId));
        document.querySelectorAll("#add_filter_select option").forEach(opt => {
            if (opt.value === filterId) {
                opt.disabled = false;
            }
        });
        $("#add_filter_select").val('0');
    };

    const exporttoPdf = async () => {
        await fetchFilteredData(PageCurrent, entriesPerPage);
        const filtrdata = await fetchFilteredDataToExport();
        console.log("filtrdata for Sales Orders Listing: ", filtrdata)
        console.log("pdfExpFilters for Sales Orders Listing: ", pdfExpFilters)
        if (filtrdata.length !== 0) {
            let filtrObjKeys = Object.keys(pdfExpFilters);
            for (let objKey = 0; objKey < filtrObjKeys.length; objKey++) {
                let key = filtrObjKeys[objKey];
                let value = pdfExpFilters[filtrObjKeys[objKey]];
            }
        }
        if (dataExport.length !== 0) {
            await pdfExp.current.pdf(dataExport, columnExport, reportName, pdfExpFilters)
        }
    }

    const exporttoExcel = async () => {
        await fetchFilteredData(PageCurrent, entriesPerPage);
        const filtrdata = await fetchFilteredDataToExport();
        if (dataExport.length !== 0) {
            let jsonToExportExcel = { 'allData': {}, 'columns': {}, 'filtrKeyValue': {}, 'headings': {}, 'key': 'reportExport' }
            if (filtrdata.length !== 0) {
                let filtrObjKeys = Object.keys(pdfExpFilters);
                for (let objKey = 0; objKey < filtrObjKeys.length; objKey++) {
                    let key = filtrObjKeys[objKey];
                    let value = pdfExpFilters[filtrObjKeys[objKey]];
                    jsonToExportExcel['filtrKeyValue'][objKey] = key + ' : ' + value
                }
            }
            for (let col = 0; col < columnExport.length; col++) {
                jsonToExportExcel['columns'][col] = columnExport[col]
            }
            for (let arrKey = 0; arrKey < dataExport.length; arrKey++) {
                jsonToExportExcel['allData'][arrKey] = dataExport[arrKey];
            }
            jsonToExportExcel['headings']['ReportName'] = reportName
            jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
            jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
            console.log("jsonToExportExcel: ", jsonToExportExcel)
            await exlsExp.current.excel(jsonToExportExcel, reportName)
        }
    }

    const exporttoJSON = async () => {
        await fetchFilteredData(PageCurrent, entriesPerPage);
        await fetchFilteredDataToExport();
        if (dataExport.length !== 0) {
            let data = [];
            for (let index = 0; index < dataExport.length; index++) {
                let dataJson = {};
                const element = dataExport[index];
                for (let colExIndex = 0; colExIndex < columnExport.length; colExIndex++) {
                    dataJson[columnExport[colExIndex]] = element[columnExport[colExIndex]]
                }
                data.push(dataJson)
            }
            await jsonExp.current.json(data, reportName)
        }
    }

    const exportToCSV = async () => {
        await fetchFilteredData(PageCurrent, entriesPerPage);
        await fetchFilteredDataToExport();
        if (dataExport.length !== 0) {
            let data = [];
            for (let index = 0; index < dataExport.length; index++) {
                let dataJson = {};
                const element = dataExport[index];
                for (let colExIndex = 0; colExIndex < columnExport.length; colExIndex++) {
                    dataJson[columnExport[colExIndex]] = element[columnExport[colExIndex]]
                }
                data.push(dataJson)
            }
            await csvExp.current.csv(data, reportName)
        }
    }

    const switchReport = async () => {
        setSelectedColumns([])
        setSelectedFilters([])
        var activeReport = document.querySelector('input[name=summaryOrDetails]:checked').value
        setReportType(activeReport)
        await fetchFilteredData(PageCurrent, entriesPerPage)
    }

    const openForm = () => {
        sessionStorage.removeItem('keyForViewUpdate');
        sessionStorage.removeItem('SalesOrderDetails');
        sessionStorage.removeItem('bomfilteredData');
        localStorage.removeItem('SalesOrdPmtTermsData');
        localStorage.removeItem('salesOrderMaterialSchedules');
        localStorage.removeItem('SalesOrdPmtTermsData');
        localStorage.removeItem('SalesOrdCommonTermsData');
        localStorage.removeItem('scheduledData')
        localStorage.removeItem('taxationSummary')
        navigate("/Transactions/TSalesOrder/Projects/SalesOrderProjectEntry", { state: { idList: null, keyForViewUpdate: '', compType: compType } })
    }
    const reload = () => {
        window.location.reload();
    }

    return (
        <>
            <ComboBox ref={comboBox} />
            <PdfExport ref={pdfExp} />
            <ExcelExport ref={exlsExp} />
            <JsonExport ref={jsonExp} />
            <CSVExport ref={csvExp} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />

            <DashboardLayout>
                <div>
                    <button className="erp_toggle-filter" onClick={toggleFilter}>
                        <AiOutlineDownCircle className="rotate_filtr_arrow" />
                        <MDTypography component="label" variant="button" className="erp-form-label-md-lg">  &nbsp; filters </MDTypography>
                    </button>

                    <div className="hide-show-filters card filter">
                        <div className='row'>
                            <div className="col-sm-6 erp_filter_table_avl_col">
                                <div className='container shadow h-100 rounded erp_group-resl_container'>
                                    <div className='row erp_filter_row_tab_view'>

                                        <div className='row erp_filter_row_tab_view'>
                                            <div className="erp_form_radio">
                                                <div className="fCheck">
                                                    <Form.Check className="erp_radio_button erp-form-label-md"
                                                        label="Summary" type="radio" value="summary"
                                                        name="summaryOrDetails" onChange={() => switchReport()} defaultChecked />
                                                </div>
                                                <div className="sCheck">
                                                    <Form.Check className="erp_radio_button erp-form-label-md"
                                                        label="Details" value="details" type="radio"
                                                        onChange={() => switchReport()} name="summaryOrDetails" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-4'>
                                            <span>
                                                <MDTypography component="label" variant="button" className="erp-form-label-md"> Available Columns </MDTypography>
                                                <select size="10" id="availableColumns" className="erp_form-select-sm-filter erp_form-select-filter">
                                                    {availableColumns?.map(column => (
                                                        <option value={column.id}>{column.Headers} </option>
                                                    ))}
                                                </select>
                                            </span>
                                        </div>

                                        <div className='col-sm-1'>
                                            <div className="buttons" id="buttons_row1">
                                                <TbArrowsRight size={15} className='buttons_move erp_filtr_moveBtn' onClick={moveAllToSelectedColumns} /><br></br>
                                                <HiOutlineArrowNarrowRight size={15} className='buttons_move erp_filtr_moveBtn' onClick={addSelectedColumns} /><br></br>
                                                <HiOutlineArrowNarrowLeft size={15} className='buttons_move erp_filtr_moveBtn' onClick={addAvailableColumns} /><br></br>
                                                <TbArrowsLeft size={15} className='buttons_move erp_filtr_moveBtn' onClick={moveAllToAvailableColumns} />
                                            </div>
                                        </div>

                                        <div className='col-sm-4'>
                                            <div className="col">
                                                <MDTypography component="label" variant="button" className="erp-form-label-md">
                                                    Selected Columns
                                                </MDTypography>
                                                <select size="10" id="selectedColumns" className="erp_form-select-sm-filter erp_form-select-filter">
                                                    <option value='sales_order_no' disabled>sales_order_no</option>
                                                    <option value='sales_order_version' disabled>sales_order_version</option>
                                                    <option value='sales_order_status' disabled>sales_order_status</option>

                                                    <option value='financial_year' disabled>financial_year</option>
                                                    {selectedColumns.map((column, index) => (
                                                        <option key={index} value={column.accessor}>{column.Headers}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-sm-1'>
                                            <div className="buttons" id="buttons_row2">
                                                <TbArrowsUp size={15} className="erp_filtr_moveBtn" onClick={moveOptionTop} /><br></br>
                                                <HiOutlineArrowNarrowUp size={15} className="erp_filtr_moveBtn" onClick={moveOptionup} /><br></br>
                                                <HiOutlineArrowNarrowDown size={15} className="erp_filtr_moveBtn" onClick={moveOptiondown} /><br></br>
                                                <TbArrowsDown size={15} className="erp_filtr_moveBtn" onClick={moveOptionBottom} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 erp_filter_group-by-result">
                                <div className='container shadow h-100 rounded erp_group-resl_container'>
                                    <div className='row erp_group-reslut-by'>
                                        <div className='col-sm-3 filtr_gropBy'>
                                            <MDTypography className="erp-form-label-md" component="label" variant="button" >Group results by</MDTypography>
                                        </div>
                                        <div className='col filtr_gropBy'>
                                            <Form.Select size="sm" className="erp_form_control operatorSelect" id="group_by">
                                                <option value=""></option>
                                                {groupByOpts?.map(column => (
                                                    <option value={column.id}>  {column.Headers} </option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col-sm-3'>
                                            <MDTypography component="label" variant="button" className="erp-form-label-md">
                                                Add Filter
                                            </MDTypography>
                                        </div>
                                        <div className="col">
                                            <Form.Select size="sm" onChange={addFilterSelect} className="erp_form_control group_by operatorSelect erp_add_filter" id="add_filter_select" >
                                                <option value="0"></option>
                                                {filterComboBox?.map(column => (
                                                    <option value={column.accessor}>{column.Headers}</option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <table id="filters-table" className='erp-filters-table-scroll'>
                                        <tbody>
                                            {selectedFilters.map(filter => (
                                                <tr key={filter.id}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            id={`cb_${filter.id}_id`}
                                                            value={filter.id}
                                                            checked
                                                            onClick={() => removeFilter(filter.id)}
                                                        />
                                                        <label className='erp-form-label'>&nbsp;{filter.label}</label>
                                                    </td>
                                                    {/* Operators */}
                                                    <td>
                                                        {(filter.type === 'C' || filter.type === 'P' || filter.type === 'H' || filter.type === 'D') && (
                                                            <select id={`operators_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                                                {filter.operatorArray.map(operator => (
                                                                    <option key={operator} value={operator}>
                                                                        {operatorLabels[operator]}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        )}
                                                        {filter.type === 'T' && (
                                                            <select id={`operators_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                                                {filter.operatorArray.map(operator => (
                                                                    <option key={operator} value={operator}>
                                                                        {operatorLabels[operator]}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        )}
                                                    </td>
                                                    {/* Values */}
                                                    <td>
                                                        {filter.type === 'C' && (
                                                            <select id={`values_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                                                {filter.dataArray.map((item, index) => (
                                                                    <option key={index} value={item[filter.label]}>
                                                                        {item[filter.label]}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        )}
                                                        {filter.type === 'P' && (
                                                            <select id={`values_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                                                {filter.dataArray.map((item, index) => (
                                                                    <option key={index} value={item.field_name}>
                                                                        {item.field_name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        )}
                                                        {filter.type === 'T' && (
                                                            <input type="text" id={`values_${filter.id}_id`} className='erp_form_control erp_input_field inputValue erp_operator_val' />
                                                        )}
                                                        {filter.type === 'D' && (
                                                            <input type="date" id={`values_${filter.id}_id`} className='erp_form_control erp_input_field inputValue erp_operator_val' />
                                                        )}
                                                        {filter.type === 'H' && (
                                                            <select id={`values_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                                                                {filter.dataArray.map((operator, index) => (
                                                                    <option key={index} value={operator}>
                                                                        {operator}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row btn_row_class">
                        <div className="col-sm-8" >
                            <MDButton className={`btn erp-gb-button ${compType === 'Register' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={openForm}>Add</MDButton> &nbsp;
                            <MDButton class="btn erp-gb-button" variant="button" fontWeight="regular" onClick={() => fetchFilteredData(PageCurrent, entriesPerPage)}>Apply Filter</MDButton> &nbsp;
                            <MDButton class="btn erp-gb-button" variant="button" fontWeight="regular" onClick={reload}>Clear Filter</MDButton>&nbsp;
                            <span className="page_entries">
                                <MDTypography component="label" variant="button" className="erp-form-label-md">Entries per page</MDTypography>
                                <Form.Select onChange={handlePageCountClick} className="erp_page_select erp_form_control" id="page_entries_id" >
                                    {pageEntriesOptions.map(pageEntriesOptions => (
                                        <option value={pageEntriesOptions.value}>{pageEntriesOptions.label}</option>
                                    ))}
                                </Form.Select>
                            </span>
                        </div>
                        <div className="col-4 pagination_id">
                            <span className="exports">
                                <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoPdf()} >PDF<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
                                <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoExcel()} s >EXCEL<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
                                <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exportToCSV()} >CSV<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
                                <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoJSON()} >JSON<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
                            </span>
                        </div>
                    </div>
                </div>

                {salesOrderTblData.length !== 0 ? <Datatable data={salesOrderTblData} columns={salesOrderTblColumns} /> :
                    <Card id="NoRcrdId" >
                        <Card.Body>No records found...</Card.Body>
                    </Card>}




                {
                    salesOrderTblData.length !== 0 && pageCount !== 1
                        ? <ReactPaginate className="erp_pagination"
                            marginPagesDisplayed={2} pageRangeDisplayed={3}
                            pageCount={pageCount} onPageChange={handlePageClick}
                            containerClassName={"pagination justify-content-center"} pageClassName={"page-item"}
                            pageLinkClassName={"page-link erp-gb-button"} previousClassName={"page-item"}
                            previousLinkClassName={"page-link erp-gb-button"} nextClassName={"page-item"}
                            nextLinkClassName={"page-link erp-gb-button"} breakClassName={"page-item"}
                            breakLinkClassName={"page-link"} activeClassName={"active"}
                        />
                        : ''
                }

            </DashboardLayout>

            {/* Delete Modal */}
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
                <Modal.Body className='erp_modal_body'>
                    <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                    <h6>Are you sure?</h6>
                    <div className="erp-form-label">Do you wish to delete this record ?</div>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <Button variant="success" className='erp-gb-button' onClick={handleClose}>
                        Cancel
                    </Button>&nbsp;
                    <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SalesOrderProjectsListing
