import React, { useEffect } from 'react'
import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// imports react bootstrap
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Wizard page components
import Supplier from "Masters/MSupplier/FrmSupplier/components/FrmSupplierEntry";
import FrmSupplierAccessRightsEntry from './components/FrmSupplierAccessRightsEntry';
import { useLocation } from 'react-router-dom';

function getSteps() {
  return ["Supplier Information", "Access Rights"];
}

export default function FrmSupplier() {

  const [activeStep, setActiveStep] = useState(0);
  const { state } = useLocation();
  let { supplierId =0 , keyForViewUpdate, supplierCode, supplierName, compType = 'Masters' } = state || {}

  if (localStorage.getItem('dataAddedByCombo') === 'dataAddedByCombo') {
    const supplierIDs = JSON.parse(localStorage.getItem('supplierIDs'));
    if (supplierIDs !== null && supplierIDs !== undefined) {
      supplierId = supplierIDs.supplierId;
      keyForViewUpdate = supplierIDs.keyForViewUpdate;
      compType = supplierIDs.compType;
    }
  }
  const steps = getSteps();
  const [key, setkey] = useState(keyForViewUpdate)
  const [supplier_id, setSupplierId] = useState(supplierId)
  const [supplier_code, setSupplierCode] = useState(supplierCode)
  const [supplier_name, setSupplierName] = useState(supplierName)
  const [actionType, setActionType] = useState('')

  useEffect(() => {
    ActionType()
  }, [])

  const ActionType = () => {
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modification)');
        break;
      case 'view':
        setActionType('(View)');
        break;
      default:
        setActionType('(Creation)');
        break;
    }

  };

  const handleNext = (supplierId, supplierCode, supplierName, keyForViewUpdate) => {
    if (supplierId !== 0) {
      setSupplierId(supplierId)
      setkey(keyForViewUpdate)
      setSupplierCode(supplierCode)
      setSupplierName(supplierName)
      setActiveStep(activeStep + 1)
    }
  };
  const handleBack = (supplierId) => {
    setSupplierId(supplierId)
    setActiveStep(activeStep - 1)
  };

  const moveByTabs = (eventKey) => {
    if (supplierId !== null && supplierId !== 0) {
      setActiveStep(eventKey)
    }
  }


  function getStepContent() {
    switch (parseInt(activeStep)) {
      case 0:
        return <Supplier goToNext={handleNext} suppId={supplier_id}/>;
      case 1:
        return <FrmSupplierAccessRightsEntry goBack={handleBack} supplierId={supplier_id} supplierCode={supplier_code} supplierName={supplier_name} keyForViewUpdate={key} compType={compType} />;
      default:
        return null;
    }
  }

  return (
    <>
      <DashboardLayout>
        <div className='main_heding'>
          <label className='erp-form-label-lg main_heding'> Supplier Information {actionType}</label>
        </div>
        <MDBox pt={3} pb={8} className="erp_form_tab_div">
          <Grid justifyContent="center" sx={{ my: 4 }} >
            <Grid item xs={12} lg={8} className="erp_form_container_tabs">
              <MDBox mt={-3} mx={2}>
                <Tabs
                  activeKey={activeStep}
                  id="uncontrolled-tab-example"
                  className="mb-3 selectedTab erp-form-Tabs" onSelect={eventKey => moveByTabs(eventKey)}>
                  {steps.map((label, index) => (
                    <Tab eventKey={index} title={label} ></Tab>
                  ))}
                </Tabs>
                {getStepContent()}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  )
}
