import { useState, useEffect, useRef, useMemo, useLayoutEffect } from "react";
import $ from 'jquery';

import { Accordion, Button, Card, Table } from "react-bootstrap";

// React Bootstrap Imports
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

// import files
import ComboBox from "Features/ComboBox";
import FrmCity from "FrmGeneric/MCity/FrmCity";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import PdfExport from 'Features/Exports/PdfExport';
import ExcelExport from "Features/Exports/ExcelExport";
import JsonExport from "Features/Exports/JsonExport";
import CSVExport from "Features/Exports/CSVExport";
import FrmValidations from 'FrmGeneric/FrmValidations';
import FrmPaymentScheduleEntry from 'Masters/MPaymentSchedule/FrmPaymentScheduleEntry';
import ConfigConstants from "assets/Constants/config-constant";
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import { globalQuery, resetGlobalQuery } from 'assets/Constants/config-constant';
import DocumentF from "Features/Document";


// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import FrmPropertyEntry from "Masters/MProperty/FrmPropertyEntry";
import { CircularProgress } from "@mui/material";
import { IoAddCircleOutline } from "react-icons/io5"
import { IoRemoveCircleOutline } from "react-icons/io5"


export default function FrmCustomerEntryAerotech(props, { customerId }) {
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, COMPANY_NAME } = configConstants;
    const { state } = useLocation();
    let { customerID = 0, keyForViewUpdate = 'Add', compType = 'Masters', gstOptional = null, } = state || {}


    // if (localStorage.getItem('dataAddedByCombo') === 'dataAddedByCombo') {
    const customerIDs = JSON.parse(localStorage.getItem('customerIDs'));
    if (customerIDs !== null && customerIDs !== undefined) {
        customerID = customerIDs.customerID;
        keyForViewUpdate = customerIDs.keyForViewUpdate;
        compType = customerIDs.compType;
        gstOptional = customerIDs.gstOptional;

    }
    // }
    // const [handelclose, setHandelclose] = useState(true);

    const [isLoading, setIsLoading] = useState(true);

    //Setting hook for infoupdate
    const [keyFVUD, setKeyVUD] = useState('');

    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const handleCloseDocumentForm = () => setShowDocumentForm(false);
    const viewDocumentForm = () => setShowDocumentForm(true);

    const [customerBranchId, setBranchID] = useState(0);
    var Branch_ID = sessionStorage.getItem("BranchID")
    var FTcustomerData;

    let customerMainBranchId = 0;
    const customerIdRef = useRef(customerID === 0 ? customerId : customerID); // Because at the time of new entry it will not loading the branch listing.
    const customerMainBranchIdRef = useRef(0);                                // Set the default branch for customer_contact.

    const comboBox = useRef();
    const validateNumberDateInput = useRef();
    const validate = useRef();
    let activeValue = '';

    let expandedGlCodeCombo = false;

    // For navigate
    const navigate = useNavigate();

    // combobox Option
    const [customerTypeOption, setCustomerTypeOption] = useState([]);
    const [customerSectorOption, setCustomerSectorOption] = useState([]);
    const [paymentTermOption, setPaymentTermOption] = useState([]);

    //GL codes Customer
    const [GLCodesOption, setGlCodesOption] = useState([]);
    const [GLCodesCheckboxes, setGLCodesCheckboxes] = useState();
    const [totalSelectedGLCodeCheckboxes, setTotalSelectedGLCodeCheckboxes] = useState(0);
    const [customerGlCodes, setCustomerGLCodes] = useState('');


    const [customer_id, setCustomerId] = useState(customerID)
    const [cmb_customers_type, setCustomerTypes] = useState('');
    const [txt_customer_code, setCustomerCodes] = useState('');
    const [txt_customer_name, setCustomerName] = useState('');
    const [txt_customer_short_name, setCustomerShortName] = useState('');
    const [cmb_company_sector, setCustomerSector] = useState('');
    const [txt_nature_of_business, setNatureOfBusiness] = useState('');
    const [cmb_payment_term, setPaymentTermId] = useState('');
    const [txt_customer_ratings, setCustomerRating] = useState('');
    const [txt_customer_accounts_id, setCustomerAccountId] = useState('');
    const [txt_customer_history, setCustomerHistory] = useState('');
    const [txt_username, setUserName] = useState('');
    const [txt_password, setPassword] = useState('');
    const [remark, setRemark] = useState('');
    const [cust_is_active, setCustomerIsActive] = useState(true)
    //hide and show password 
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordhideandshow = () => {
        setShowPassword(!showPassword);
    };

    //Bank Branch Information
    // Option Box
    const [stateOptions, setStateOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [districtOptions, setDistrictOptions] = useState([]);
    const [countryCodeOptions, setCountryCodeOptions] = useState([]);
    const [customerRegionOption, setcustomerRegionOption] = useState([]);
    const [bankAccountTypeList, setBankAccountTypeList] = useState([]);
    const [bankList, setBankList] = useState([]);
    const [currencyTypeList, setCurrencyTypeList] = useState([]);

    //GL codes for branch
    const [cmb_cust_branch_gl_codes, setBranchGLCodes] = useState('');
    const [BranchGLCodesCheckboxes, setBranchGLCodesCheckboxes] = useState();
    const [totalSelectedBranchGLCodeCheckboxes, setTotalSelectedBranchGLCodeCheckboxes] = useState(0);
    const [txt_cell_no2, setCellNo2] = useState('');
    const [txt_email_id1, setEmailId1] = useState('');


    // const [tempCustomerId, setTempCustomerId] = useState(customer_id)
    let tempCustomerId = customer_id
    const [chkBranchtype, setBranchType] = useState(customer_id === 0 ? 'Main' : 'Sub');

    const [txt_cust_branch_name, setCustBranchName] = useState('');
    const [txt_cust_branch_vendor_code, setBranchVendorCode] = useState(sessionStorage.getItem('defaultCustomerCode'));
    const [txt_cust_branch_short_name, setCustBranchShortName] = useState('');
    const [txt_cust_branch_address1, setBranchAddress1] = useState('');
    const [txt_cust_branch_address2, setBranchAddress2] = useState('');
    const [cmb_customer_region, setCustBranchRegion] = useState('NA');
    const [cmb_cust_branch_state_id, setCustBranchState] = useState('');
    const [cmb_cust_branch__City_id, setCustBranchCity] = useState('');
    const [cmb_cust_branch__Country_id, setCountry] = useState('');
    const [cmb_cust_branch__District_id, setDistrict] = useState('1');
    const [txt_cust_branch_pin_code, setCustBranchPincode] = useState('');
    const [txt_cust_branch_phone_no, setBranchPhoneNo] = useState('');
    const [txt_cust_branch_cell_no, setCustBranchcellNo] = useState('');
    const [txt_cust_branch_email_id, setCustBranchEmail] = useState('');
    const [txt_cust_branch_website, setCustBranchWebsite] = useState(''); // set default value because giving .trim() error on save.
    const [txt_cust_branch_profile, setCustBranchProfile] = useState(''); // set default value because giving .trim() error on save.
    const [txt_cust_branch_linkedIn_profile, setBranchLinkedinProfile] = useState(''); // set default value because giving .trim() error on save.
    const [txt_cust_branch_twitter_profile, setBranchTwitterProfile] = useState(''); // set default value because giving .trim() error on save.
    const [txt_cust_branch_gst_no, setCustBranchGstNo] = useState('');
    const [txt_branch_gst_division, setCustGstDivision] = useState('');
    const [txt_branch_gst_range, setCustGstRange] = useState('');
    const [txt_cust_branch_pan_no, setCustBranchPanNo] = useState('');
    const [txt_cust_branch_udyog_adhar_no, setBranchUdyogAdharNo] = useState('');
    const [txt_cust_branch_vat_no, setCustBranchVatNo] = useState('');
    const [txt_cust_branch_service_tax_no, setBranchServiceTaxNo] = useState('');
    const [cmb_cust_payment_term_ID, setBranchPaymentTermID] = useState('');
    const [txt_branch_excise_no, setBranchExciseNo] = useState('');
    const [txt_branch_cst_no, setBranchCstNo] = useState('');
    const [txt_cust_branch_bst_no, setBranchBstNo] = useState('');

    const [rb_is_sez, setIsSez] = useState(false);
    const [txt_sez_name, setCustSezName] = useState('');

    const [txt_cust_branch_accounts_id, setBranchAccountsID] = useState('');
    const [txt_cust_branch__ratings, setBranchRating] = useState('');
    const [txt_physical_distance, setPhysicalDistance] = useState('');
    const [txt_tally_ID, setCustTallyID] = useState('');
    const [actionType, setActionType] = useState('')

    const [actionLabel, setActionLabel] = useState('Save')

    const [customerBlocked, setcustomerBlocked] = useState(false);
    const [customerBlockedRemark, setcustomerBlockedRemark] = useState('');
    const [customerPaymentBlocked, setcustomerPaymentBlocked] = useState(false);
    const [customerPaymentBlockedRemark, setcustomerPaymentBlockedRemark] = useState('');




    //Pagination Fields For bank
    const [keys, setKeys] = useState('')

    const reload = () => {
        window.location.reload();
    }

    //Contact Starts here
    const [rowCount, setRowCount] = useState(1);
    const [customerContactData, setCustomerContactData] = useState([])

    //Option box 
    const [designationOptions, setDesignationOptions] = useState([]);
    const [customerBranchOptions, setCustomerBranchOptions] = useState([]);

    // to add new records in combo box 
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')
    const [reportType, setReportType] = useState('summary');

    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const usernameRef = useRef();


    const handleCloseErrModal = () => {
        if (errMsg === "User Name is already exist!") {
            setShowErrorMsgModal(false);
            usernameRef.current.focus();
        } else {
            setShowErrorMsgModal(false);
        }
    }


    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Masters/MCCustomer/FrmCustomerListAerotech`)
        }else{
            props.modelClose()
        }
       
    }

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);

    // Delete Modal
    const handleClose = () => setShowDeleteModal(false);
    const [showDeletemodal, setShowDeleteModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    useEffect(() => {
        const fetchDataOnload = async () => {
            setIsLoading(true)
            await ActionType()
            await FillComobos();
            await FnFetchPropertyRecords();
            if (customer_id !== 0) {
                await FnCheckUpdateResponce();
            }
            if (keyForViewUpdate === 'Add') {
                await generateCustomerCode('cm_customer', 'customer_code', 5, "AR", 'C')
            }

            setIsLoading(false)
        }
        fetchDataOnload()



        $(document).on('mouseup', function (e) {
            let container = $("#gl_code_ul");
            let container1 = $("#gl_code_ul_branch");

            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
            if (!container1.is(e.target) && container1.has(e.target).length === 0) {
                container1.hide();
            }
        });
        $('.hide-show-filters').hide();

    }, [reportType])

    const ActionType = async () => {

        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modify)');

                break;
            case 'view':
                setActionType('(View)');
                // await validate.current.readOnly("custBranchFormId");
                // await validate.current.readOnly("bankForm");
                $("input[type=radio]").attr('disabled', true);
                break;
            default:
                setActionType('(Create)');
                return validate.current.removeReadOnlyAttr('customerFormId');
        }
    };
    const validateErrorMsgsNumericInput = (event) => {
        var regexNoBank = /^[A-Za-z0-9]+$/;
        const regexNo = /^[0-9\b]+$/;
        var regexPassport = /^[A-Za-z0-9]+$/;
        const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;

        const value = event.target.value
        switch (event.target.id) {

            case 'txt_cell_no2':
                if (regexNo.test(value) || value === '') {
                    setCellNo2(value);
                }
                break;

            case 'txt_email_id1':
                if (emailRegex.test(value) || value === '') {
                    setEmailId1(value);
                }
                break;

        }

    }

    const FillComobos = async () => {
        try {
            if (comboBox.current) {
                comboBox.current.fillMasterData("cmv_country", "", "").then((cmv_countryList) => {
                    setCountryOptions(cmv_countryList);
                    // Set the default Country India.
                    const defaultCountry = cmv_countryList.find(country => country.field_name === "India");
                    setCountry(defaultCountry.field_id);

                    comboBox.current.fillMasterData("cmv_state", "country_id", defaultCountry.field_id).then((getStateList) => {
                        setStateOptions(getStateList);
                        // Set the default State Maharashtra.
                        const defaultState = getStateList.find(state => state.field_name === "Maharashtra");
                        setCustBranchState(defaultState.field_id);

                        // Load the district options.
                        comboBox.current.fillMasterData("cmv_district", "state_id", defaultState.field_id).then((getDistrictList) => {
                            setDistrictOptions(getDistrictList);
                        })
                        comboBox.current.fillMasterData("cmv_city", "district_id", "1").then((getCityList) => {
                            setCityOptions(getCityList);
                        })
                    })
                })

                comboBox.current.fillComboBox("PaymentTermDays").then((paymentTermDays) => {
                    setPaymentTermOption(paymentTermDays)
                })

                comboBox.current.fillComboBox("Regions").then((regionList) => {
                    setcustomerRegionOption(regionList)
                })

                comboBox.current.fillMasterData("fmv_currency", "", "").then((currencyList) => {
                    setCurrencyTypeList(currencyList)
                })

                comboBox.current.fillComboBox("BankAccountsType").then((bankAccountTypeList) => {
                    setBankAccountTypeList(bankAccountTypeList)
                })

                comboBox.current.fillMasterData("cmv_banks_List", "", "").then((bankList) => {
                    setBankList(bankList)
                })
            }

            const apiCallCountryCodeList = await fetch(`${process.env.REACT_APP_BASE_URL}/api/country/FnFetchCountryCodes`)
            const respCountryCode = await apiCallCountryCodeList.json();
            setCountryCodeOptions(respCountryCode)

        } catch (error) {
            console.log("error: ", error)
        }
    }



    const FnCheckUpdateResponce = async () => {
        debugger
        try {
            if (customer_id !== null) {
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/customer/FnShowCustomerBankBranchAndContactAllRecords/${customer_id}/${COMPANY_ID}`)
                const updateRes = await apiCall.json();

                let custEntryData = updateRes.CustomerRecords;
                let custContactData = updateRes.CustomerContactRecords;
                let custBrachData = updateRes.CustomerBranchRecords

                //Setting customer data
                setCustomerId(custEntryData.customer_id)
                customerIdRef.current = custEntryData.customer_id;
                setCustomerTypes(custEntryData.customer_type)
                setCustomerCodes(custEntryData.customer_code)
                setCustomerName(custEntryData.customer_name)
                setCustomerShortName(custEntryData.customer_short_name)
                setCustomerSector(custEntryData.customer_sector)
                setNatureOfBusiness(custEntryData.nature_of_business)
                setPaymentTermId(custEntryData.payment_term_id)
                setCustomerRating(custEntryData.customer_rating)
                setCellNo2(custEntryData.mobile_no)
                setEmailId1(custEntryData.email)
                setCustomerGLCodes(custEntryData.customer_gl_codes)
                setCustomerAccountId(custEntryData.customer_accounts_id)
                setCustomerHistory(custEntryData.customer_history)
                setUserName(custEntryData.username);
                setPassword(custEntryData.password);
                setRemark(custEntryData.remark)
                setCustomerIsActive(custEntryData.is_active)

                setDistrict(custBrachData.cust_branch_district_id)
                setCustBranchState(custBrachData.cust_branch_state_id)
                setCountry(custBrachData.cust_branch_country_id)
                await addRecordInProperty("District")
                setCustBranchCity(custBrachData.cust_branch_city_id)
                setCustBranchGstNo(custBrachData.cust_branch_gst_no)
                //Setting Customer contact DEtails
                if (custContactData.length !== 0) {
                    setCustomerContactData(custContactData);
                } else {
                    const getExistingCustomerContactData = [...customerContactData]
                    getExistingCustomerContactData.push(contactBlankObject)
                    setCustomerContactData(getExistingCustomerContactData)
                }
                setBranchAddress1(custBrachData.cust_branch_address1)
                switch (keyForViewUpdate) {
                    case 'update':
                        setActionLabel('Update');
                        $('#customerTypeId').attr('disabled', true);
                        $('#customerNameID').attr('disabled', true);
                        $('#customerCode').attr('disabled', true);
                        $('#shortName').attr('disabled', true);
                        $('#branchVendorId').attr('disabled', true);
                        break;
                    case 'view':
                        $("input[type=radio]").attr('disabled', true);
                        await validate.current.readOnly("customerFormId");

                        break;
                }
            }
        } catch (error) {
            console.log('error: ', error);
        }
    }


    const FnFetchPropertyRecords = async () => {
        try {
            if (comboBox.current) {
                comboBox.current.fillComboBox("CompanySectors").then((customerSector) => {
                    setCustomerSectorOption(customerSector)
                })

                resetGlobalQuery();
                globalQuery.columns = ['property_id', 'property_name', 'property_value'];
                globalQuery.table = "amv_properties"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'CustomerTypes' });
                comboBox.current.removeCatcheFillCombo(globalQuery).then((getCustomerTypes) => {
                    setCustomerTypeOption(getCustomerTypes);
                })

                comboBox.current.fillComboBox("PaymentTermDays").then((paymentTermDays) => {
                    setPaymentTermOption(paymentTermDays)
                })


                var fmv_general_ledgerList = await comboBox.current.fillMasterData("fmv_general_ledger", "", "")
                setGlCodesOption(fmv_general_ledgerList)

                comboBox.current.fillMasterData("cmv_designation", "", "").then((masterList) => {
                    setDesignationOptions(masterList)
                    localStorage.setItem("designations", JSON.stringify(masterList))
                })

                comboBox.current.fillMasterData("cmv_customer_branch", "customer_id", customer_id).then((custBranchList) => {
                    setCustomerBranchOptions(custBranchList)
                    localStorage.setItem("customerBranchs", JSON.stringify(custBranchList))
                })
            }

        } catch (error) {
            console.log('error:', error)
        }
    }

    const generateCustomerCode = async (tblName, fieldName, Length, type) => {
        const data = {
            tblName: tblName, fieldName: fieldName, Length: Length, company_id: COMPANY_ID,
            additionalParam1: 'customer_type',
            additionalParam1Value: ''
        };
        const formData = new FormData();
        formData.append(`data`, JSON.stringify(data))
        const requestOptions = { method: 'POST', body: formData };
        try {
            const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/customer/FnGenerateCode/${COMPANY_ID}`, requestOptions)
            const resp = await fetchResponse.text();
            console.log("GenerateTAuto Api: ", resp)
            if (resp) {
                setCustomerCodes("AR" + '-' + type + resp);
                return "AR" + '-' + type + resp;
            }
        } catch (error) {
            console.error(error);
        }
    }


    //-------------------------------------GL Codes Functionality starts here---------------------------------//
    //Branch GL Codes


    const FnCreateBranchGLCodesChks = async () => {
        if (GLCodesOption.length !== 0) {
            let checkboxes = GLCodesOption.map((item, index) => {
                if (index === 0) {
                    return (
                        <>
                            <li className="item branch_gl_code_checkboxes">
                                <span className="checkbox">
                                    <input type="checkbox" id="CheckAllSupplierBranchGLCodes" value={index} className="erp_radio_button"
                                        onChange={(event) => FnToggleCheckBoxes('CheckAllSupplierBranchGLCodes')} {...(keyForViewUpdate === "view" || keyFVUD === "view" ? { disabled: true } : {})} />
                                </span>
                                <span className="item-text">All</span>
                            </li>
                            <li className="item branch_gl_code_checkboxes">
                                <span className="checkbox">
                                    <input type="checkbox" name="BranchglCodeCheckBox" value={item.field_id} {...(keyForViewUpdate === "view" || keyFVUD === "view" ? { disabled: true } : {})}
                                        id={`glCodeCheckBox_${item.field_id}`} className="erp_radio_button BranchglCodeCheckBox" onChange={(event) => FnToggleCheckBoxes('PartiallyCheckBranchGLCode')}
                                    />
                                </span>
                                <span className="item-text">{item.field_name}</span>
                            </li>
                        </>
                    );
                }
                return (
                    <li className="item branch_gl_code_checkboxes">
                        <span className="checkbox">
                            <input type="checkbox" name="BranchglCodeCheckBox" value={item.field_id} id={`glCodeCheckBox_${item.field_id}`} {...(keyForViewUpdate === "view" || keyFVUD === "view" ? { disabled: true } : {})}
                                className="erp_radio_button BranchglCodeCheckBox" onChange={(event) => FnToggleCheckBoxes('PartiallyCheckBranchGLCode')}
                            />
                        </span>
                        <span className="item-text">{item.field_name}</span>
                    </li>
                );
            });
            setBranchGLCodesCheckboxes(checkboxes);
        }
    }

    const FnGetSelectedBranchGLCodesIds = () => {
        let selectedGLCodes = '';
        const checkboxesBranch = $('.branch_gl_code_checkboxes:checked');

        checkboxesBranch.each(function () {
            if ($(this).val() !== '0' && $(this).val() !== '') {
                selectedGLCodes += $(this).val() + ":";
            }
        });
        setBranchGLCodes(selectedGLCodes.replace(/:$/, ''));
        return selectedGLCodes.replace(/:$/, '');
    }

    //Customer GL codes 
    const FnShowGlCodes = async () => {
        if (GLCodesOption.length > 0) {
            await FnCreateGLCodesChks();

            // Also check the already checked checkboxes.
            if (customerGlCodes !== '' && customerGlCodes !== null) {
                const glCodeIds = customerGlCodes.split(':');
                glCodeIds.forEach(function (contactId, index) {
                    $('#glCodeCheckBox_' + glCodeIds[index]).prop('checked', true);
                });
                FnToggleCheckBoxes('PartiallyCheckGLCode');
            }

            let checkBoxesContainer = document.getElementById("gl_code_ul");
            if (!expandedGlCodeCombo) {
                checkBoxesContainer.style.display = "block";
                expandedGlCodeCombo = true;
            } else {
                checkBoxesContainer.style.display = "none";
                expandedGlCodeCombo = false;
            }

            $('.gl_code_checkboxes').on('input blur', function (e) {
                FnGetSelectedGLCodesIds();
            });
        }
    }

    const FnCreateGLCodesChks = async () => {
        if (GLCodesOption.length !== 0) {
            let checkboxes = GLCodesOption.map((item, index) => {
                if (index === 0) {
                    return (
                        <>
                            <li className="item gl_code_checkboxes">
                                <span className="checkbox">
                                    <input type="checkbox" id="CheckAllGLCodes" value={index} className="erp_radio_button"
                                        onChange={(event) => FnToggleCheckBoxes('CheckAllGLCodes')} {...(keyForViewUpdate === "view" ? { disabled: true } : {})} />
                                </span>
                                <span className="item-text">All</span>
                            </li>
                            <li className="item gl_code_checkboxes">
                                <span className="checkbox">
                                    <input type="checkbox" name="glCodeCheckBox" value={item.field_id} {...(keyForViewUpdate === "view" ? { disabled: true } : {})}
                                        id={`glCodeCheckBox_${item.field_id}`} className="erp_radio_button glCodeCheckBox" onChange={(event) => FnToggleCheckBoxes('PartiallyCheckGLCode')}
                                    />
                                </span>
                                <span className="item-text">{item.field_name}</span>
                            </li>
                        </>
                    );
                }
                return (
                    <li className="item gl_code_checkboxes">
                        <span className="checkbox">
                            <input type="checkbox" name="glCodeCheckBox" value={item.field_id} id={`glCodeCheckBox_${item.field_id}`} {...(keyForViewUpdate === "view" ? { disabled: true } : {})}
                                className="erp_radio_button glCodeCheckBox" onChange={(event) => FnToggleCheckBoxes('PartiallyCheckGLCode')}
                            />
                        </span>
                        <span className="item-text">{item.field_name}</span>
                    </li>
                );
            });
            setGLCodesCheckboxes(checkboxes);
        }
    }


    const FnToggleCheckBoxes = (checkboxType) => {
        switch (checkboxType) {
            case "CheckAllGLCodes":
                $('.glCodeCheckBox').prop('checked', $('#CheckAllGLCodes').is(":checked"));
                setTotalSelectedGLCodeCheckboxes($('input:checkbox.glCodeCheckBox:checked').length);
                // setCustomerGLCodes
                break;

            case 'PartiallyCheckGLCode':
                $('#CheckAllGLCodes').prop('checked', $('input:checkbox.glCodeCheckBox:checked').length === $('input:checkbox.glCodeCheckBox').length);
                setTotalSelectedGLCodeCheckboxes($('input:checkbox.glCodeCheckBox:checked').length);
                break;

            case "CheckAllSupplierBranchGLCodes":
                $('.BranchglCodeCheckBox').prop('checked', $('#CheckAllSupplierBranchGLCodes').is(":checked"));
                setTotalSelectedBranchGLCodeCheckboxes($('input:checkbox.BranchglCodeCheckBox:checked').length);
                // setCustomerGLCodes
                break;

            case 'PartiallyCheckBranchGLCode':
                $('#CheckAllSupplierBranchGLCodes').prop('checked', $('input:checkbox.BranchglCodeCheckBox:checked').length === $('input:checkbox.BranchglCodeCheckBox').length);
                setTotalSelectedBranchGLCodeCheckboxes($('input:checkbox.BranchglCodeCheckBox:checked').length);
                break;

            default:
                break;
        }
    }


    const FnGetSelectedGLCodesIds = () => {
        let selectedGLCodes = '';
        const checkboxes = $('.glCodeCheckBox:checked');
        checkboxes.each(function () {
            if ($(this).val() !== '0' && $(this).val() !== '') {
                selectedGLCodes += $(this).val() + ":";
            }
        });
        setCustomerGLCodes(selectedGLCodes.replace(/:$/, ''));
        return selectedGLCodes.replace(/:$/, '');
    }



    //Validations for all entries starts here.................................




    const validateFields = async (functionType) => {

        if (customer_id === 0) {
            if (await validate.current.validateFieldsOnChange('customerFormId') === true) {
                alert('First Form Validation Successfull');
                if (await validate.current.validateFieldsOnChange('custBranchFormId') === true) {
                    return true;
                }
            }
        } switch (functionType) {
            case 'custInfo':
                return await validate.current.validateFieldsOnChange('customerFormId');

            default:
                break;
        }
    }



    const checkCustContactEntr = () => {
        if (customerContactData[0].cust_contact_person.length !== 0) {
            $('#error_custContact').text('Please Fill the Customer Contact.....').css('display', 'none');
        } else {
            $('#error_custContact').text('Please Fill the Customer Contact.....').css('display', 'block');
        }
    }

    const validateWebSite = (obj) => {
        var validateWebSite = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
        if (obj.value === "" || validateWebSite.test(obj.value)) {
            $("#error_webSite").hide();
        } else if (obj.value !== "" && !validateWebSite.test(obj.value)) {
            $("#error_webSite").text("Please enter valid url!...");
            $("#error_webSite").show();
        }
    }


    const FnAddUpdateCustomer = async (functionType) => {
        setIsLoading(true);
        try {
            let checkIsValidate = true;
            checkIsValidate = await validate.current.validateForm('customerFormId');
            // if (checkIsValidate) {
            //     checkIsValidate = await FnCustContactValidation();
            // }

            if (checkIsValidate) {

                let json = {
                    'CustomerMasterData': {}, 'CustomerBranchData': {}, 'CustomerBankData': [], 'CustomerContactData': [], 'commonIds': {
                        'company_id': COMPANY_ID, 'customer_id': customer_id, 'userName': UserName, 'cust_branch_id': customerBranchId
                    },
                    'saveKey': functionType
                }

                const customerDATA = {
                    company_id: COMPANY_ID === '' ? null : COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID === '' ? null : COMPANY_BRANCH_ID,
                    customer_id: customer_id === '' ? null : customer_id,
                    customer_type: cmb_customers_type === '' ? null : cmb_customers_type,
                    customer_code: txt_customer_code === '' ? null : txt_customer_code,
                    customer_name: txt_customer_name === '' ? null : txt_customer_name,
                    customer_short_name: txt_customer_short_name === '' ? null : txt_customer_short_name,
                    customer_sector: cmb_company_sector === '' ? null : cmb_company_sector,
                    nature_of_business: txt_nature_of_business === '' ? null : txt_nature_of_business,
                    payment_term_id: cmb_payment_term === '' ? 152 : cmb_payment_term,
                    customer_rating: txt_customer_ratings === '' ? null : txt_customer_ratings,
                    customer_gl_codes: customerGlCodes === '' ? '' : customerGlCodes,
                    customer_accounts_id: txt_customer_accounts_id === '' ? null : txt_customer_accounts_id,
                    customer_history: txt_customer_history === '' ? null : txt_customer_history,
                    username: txt_username === '' ? null : txt_username,
                    password: txt_password === '' ? null : txt_password,
                    email: txt_email_id1,
                    mobile_no: txt_cell_no2,
                    is_active: cust_is_active === '' ? null : cust_is_active,
                    remark: remark === '' ? null : remark,
                    created_by: customer_id === 0 ? UserName : null,
                    modified_by: customer_id !== 0 ? UserName : null,
                };
                json.CustomerMasterData = customerDATA;

                const custBranchData = {
                    company_id: COMPANY_ID === '' ? null : COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID === '' ? null : COMPANY_BRANCH_ID,
                    customer_id: customer_id === '' ? null : customer_id,
                    branch_type: chkBranchtype === '' ? null : chkBranchtype,
                    cust_branch_id: customerBranchId === '' ? null : customerBranchId,
                    cust_branch_name: txt_customer_name === '' ? null : txt_customer_name,
                    cust_branch_short_name: txt_cust_branch_short_name === '' ? null : txt_cust_branch_short_name,
                    cust_branch_vendor_code: txt_customer_code === '' ? null : txt_customer_code,
                    cust_branch_address1: txt_cust_branch_address1 === '' ? null : txt_cust_branch_address1,
                    cust_branch_address2: txt_cust_branch_address2 === '' ? null : txt_cust_branch_address2,
                    cust_branch_city_id: cmb_cust_branch__City_id === '' ? null : cmb_cust_branch__City_id,
                    cust_branch_pincode: txt_cust_branch_pin_code === '' ? null : txt_cust_branch_pin_code,
                    cust_branch_district_id: cmb_cust_branch__District_id === '' ? null : cmb_cust_branch__District_id,
                    cust_branch_state_id: cmb_cust_branch_state_id === '' ? null : cmb_cust_branch_state_id,
                    cust_branch_country_id: cmb_cust_branch__Country_id === '' ? null : cmb_cust_branch__Country_id,
                    cust_branch_region: cmb_customer_region === '' ? null : cmb_customer_region,
                    cust_branch_phone_no: txt_cust_branch_phone_no === '' ? null : txt_cust_branch_phone_no,
                    cust_branch_cell_no: txt_cust_branch_cell_no === '' ? null : txt_cust_branch_cell_no,
                    cust_branch_EmailId: txt_cust_branch_email_id === '' ? null : txt_cust_branch_email_id,
                    cust_branch_website: txt_cust_branch_website === '' ? null : txt_cust_branch_website.trim(),
                    cust_branch_linkedin_profile: txt_cust_branch_linkedIn_profile === '' ? null : txt_cust_branch_linkedIn_profile.trim(),
                    cust_branch_twitter_profile: txt_cust_branch_twitter_profile === '' ? null : txt_cust_branch_twitter_profile.trim(),
                    cust_branch_facebook_profile: txt_cust_branch_profile === '' ? null : txt_cust_branch_profile.trim(),
                    cust_branch_gst_no: txt_cust_branch_gst_no === '' ? null : txt_cust_branch_gst_no,
                    cust_branch_gst_division: txt_branch_gst_division === '' ? null : txt_branch_gst_division,
                    cust_branch_gst_range: txt_branch_gst_range === '' ? null : txt_branch_gst_range,
                    cust_branch_pan_no: txt_cust_branch_pan_no === '' ? null : txt_cust_branch_pan_no,
                    cust_branch_udyog_adhar_no: txt_cust_branch_udyog_adhar_no === '' ? null : txt_cust_branch_udyog_adhar_no,
                    cust_branch_vat_no: txt_cust_branch_vat_no === '' ? null : txt_cust_branch_vat_no,
                    cust_branch_service_tax_no: txt_cust_branch_service_tax_no === '' ? null : txt_cust_branch_service_tax_no,
                    cust_branch_excise_no: txt_branch_excise_no === '' ? null : txt_branch_excise_no,
                    cust_branch_cst_no: txt_branch_cst_no === '' ? null : txt_branch_cst_no,
                    cust_branch_bst_no: txt_cust_branch_bst_no === '' ? null : txt_cust_branch_bst_no,
                    cust_branch_payment_term_id: cmb_cust_payment_term_ID === '' ? null : cmb_cust_payment_term_ID,
                    cust_branch_rating: txt_cust_branch__ratings === '' ? null : txt_cust_branch__ratings,
                    cust_branch_gl_codes: cmb_cust_branch_gl_codes === '' ? null : cmb_cust_branch_gl_codes,
                    cust_branch_accounts_id: txt_cust_branch_accounts_id === '' ? null : txt_cust_branch_accounts_id,
                    cust_branch_blocked_remark: customerBlockedRemark === '' ? null : customerBlockedRemark,
                    cust_branch_payment_blocked_remark: customerPaymentBlockedRemark === '' ? null : customerPaymentBlockedRemark,
                    cust_branch_blocked: customerBlocked === '' ? null : customerBlocked,
                    cust_branch_payment_blocked: customerPaymentBlocked === '' ? null : customerPaymentBlocked,
                    sez_name: txt_sez_name === '' ? null : txt_sez_name,
                    cust_branch_tally_id: txt_tally_ID === '' ? null : txt_tally_ID,
                    pysical_distance: txt_physical_distance === '' ? null : txt_physical_distance,
                    is_sez: rb_is_sez === '' ? null : rb_is_sez,
                    created_by: customer_id === 0 ? UserName : null,
                    modified_by: tempCustomerId !== 0 ? UserName : null
                };
                json.CustomerBranchData = custBranchData;


                if (customerContactData.length !== 0) {
                    if (customerContactData[0].cust_contact_person !== '') {
                        json.CustomerContactData = customerContactData;
                    }
                }
                console.log("Customer Details", json);
                const formData = new FormData();
                formData.append(`CustomerServiceData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };

                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/customer/FnAddUpdateRecord`, requestOptions)
                const responce = await apicall.json();
                console.log("response error: ", responce.data);
                keyForViewUpdate = 'update'

                if (responce.error !== "") {
                    console.log("response error: ", responce.error);
                    setIsLoading(false);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)

                } else {
                    // Catche evict for server side catche
                    const evitCache = await comboBox.current.evitCache();
                    console.log(evitCache);

                    if (customerContactData[0].cust_contact_person.length !== 0) {
                        $('#accessContactAcc').show();
                    }
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                    checkCustContactEntr();
                    clearCustomerform();
                }
            }
        } catch (error) {
            console.log("error: ", error);
        } finally {
            setIsLoading(false);
        }
    }


    const clearCustomerform = () => {
        setCustomerCodes('')
        setCustomerName('')
        setCountry('')
        setCustBranchState('')
        setDistrict('')
        setCustBranchCity('')
        setCustBranchGstNo('')
        setCustomerAccountId('')
        // setCustomerContactData([])

    }


    // Show ADd record Modal
    const handleCloseRecModal = async () => {
        switch (modalHeaderName) {

            case 'City':
                if (cmb_cust_branch__District_id !== '' && cmb_cust_branch__District_id !== undefined) {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'city_pincode'];
                    globalQuery.table = "cmv_city"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
                    globalQuery.conditions.push({ field: "district_id", operator: "=", value: cmb_cust_branch__District_id });
                    comboBox.current.removeCatcheFillCombo(globalQuery).then((propertyList2) => {
                        setCityOptions(propertyList2)
                    })

                }
                setShowAddRecModal(false);
                break;

            case 'Payment Terms':
                comboBox.current.fillComboBox("PaymentTermDays").then((paymentTermDays) => {
                    setPaymentTermOption(paymentTermDays)
                })
                break;

            case 'Customer Sector':
                comboBox.current.fillComboBox("CompanySectors").then((customerSector) => {
                    setCustomerSectorOption(customerSector)
                })
                break;

            default:
                break;
        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
    }


    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'City':
                return <FrmCity btn_disabled={true} />;

            case 'Payment Terms':
                return <FrmPaymentScheduleEntry btn_disabled={true} />;

            case 'Customer Sector':
                return <FrmPropertyEntry btn_disabled={true} />;

            default:
                return null;
        }
    }


    //---------------------------------------Bank Information Starts here-------------------------------------//


    const addRecordInProperty = async (key) => {
        switch (key) {
            case 'Country':
                const getCountryId = document.getElementById('countryId').value;
                setCountry(getCountryId)
                if (getCountryId !== '') {
                    $('#error_countryId').hide();
                    var stateList = await comboBox.current.fillMasterData("cmv_state", "country_id", getCountryId)
                    setStateOptions(stateList)
                    setCustBranchState('');
                    setDistrictOptions([])
                    setDistrict('');
                    setCityOptions([]);
                    setCustBranchCity('');
                    setCustBranchRegion('')
                }
                break;
            case 'State':
                const getStateId = document.getElementById('stateId').value;
                setCustBranchState(getStateId)
                if (getStateId !== '') {
                    $('#error_stateId').hide();
                    var districtList = await comboBox.current.fillMasterData("cmv_district", "state_id", getStateId)
                    setDistrictOptions([])
                    setDistrictOptions(districtList)
                    setDistrict('');
                    setCityOptions([]);
                    setCustBranchCity('');
                }
                break;
            case 'District':
                debugger
                const getDistrictId = document.getElementById('districtId').value;
                setDistrict(getDistrictId)
                if (getDistrictId !== '') {
                    $('#error_districtId').hide();
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'city_pincode'];
                    globalQuery.table = "cmv_city"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
                    globalQuery.conditions.push({ field: "district_id", operator: "=", value: getDistrictId });
                    let getCityList = await comboBox.current.removeCatcheFillCombo(globalQuery);
                    setCityOptions(getCityList)
                    setCustBranchCity('');
                }
                break;
            case 'City':
                const propertyValCity = document.getElementById('cityId').value;
                if (propertyValCity === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('City')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(0).css("padding-top", "0px");
                    }, 100)
                }
                else {
                    setCustBranchCity(propertyValCity)
                    // Set the pincode from the city list.
                    const selectedCity = cityOptions.find(city => city.field_id === parseInt(propertyValCity));
                    setCustBranchPincode(selectedCity.city_pincode);

                    if (propertyValCity !== "") { $('#error_cityId').hide(); }
                }
                break;

            case 'SuppPaymentTermDays':
                const supppaymenttermdays = document.getElementById('supplierPaymentTermID').value;
                setBranchPaymentTermID(supppaymenttermdays)
                $('#error_supplierPaymentTermID').hide();
                if (supppaymenttermdays === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Payment Terms')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(0).css("padding-top", "0px");
                    }, 100)
                }

                break;
            case 'Regions':
                const Region = document.getElementById('customerRegionID').value;
                setCustBranchRegion(Region)
                $('#error_customerRegionID').hide();
                break



            case 'CustPaymentTermDays':
                const custpaymentTermDays = document.getElementById('customerPaymentTermID').value;
                setPaymentTermId(custpaymentTermDays)
                $('#error_customerPaymentTermID').hide();

                if (custpaymentTermDays === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Payment Terms')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(0).css("padding-top", "0px");
                    }, 100)
                }

                break;
            case 'CustomerSectors':
                var propertyVal = document.getElementById('customerSectorId').value;
                if (propertyVal === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Customer Sector')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css("padding-top", "0px");
                    }, 100)
                }
                setCustomerSector(propertyVal)
                if (propertyVal !== "") { $('#error_customerSectorId').hide(); }
                break;
            case 'customerTypes':
                var customerType = document.getElementById('customerTypeId').value;
                setCustomerTypes(customerType);
                // Generate the customer-code using cust-type-shortName.
                // tblName, fieldName, type, autoNoShortName, Length
                // let companyTypeShortName = "AR";

                if (customerType !== '' && customerType !== '0') {
                    let generatedCustomerCode = await generateCustomerCode('cm_customer', 'customer_code', 5, 'C');
                    if (txt_username.trim() === '') {
                        setUserName(generatedCustomerCode);
                        setBranchVendorCode(generatedCustomerCode);
                    }

                    $('#error_customerTypeId').hide();
                } else {
                    setCustomerCodes('');
                }
                break;
        }
    }

    //------------------------------ Customer Contact Functionality --------------------------------------- //
    const fnsetRowCountAndAddRow = () => {
        const lastRowIndex = customerContactData.length - 1;
        const lastRowContactPerson = customerContactData[lastRowIndex].cust_contact_person;
        if (lastRowContactPerson !== '') {
            setRowCount(rowCount + 1);
        } else {
            const tableRows = document.querySelectorAll('#customerContactTbl tbody tr');
            tableRows.forEach(row => {
                const suppContactName = row.querySelector('input[id^="cust_contact_person"]').value;
                if (suppContactName === '') {
                    row.querySelector('input[id^="cust_contact_person"]').parentElement.dataset.tip = 'Please fill this Field...!';
                    row.querySelector('input[id^="cust_contact_person"]').focus();
                    return;
                } else {
                    delete row.querySelector('input[id^="cust_contact_person"]').parentElement.dataset.tip;
                }
            })
        }
    };

    const FnUpdateCustomerContactTblRows = (rowData, event) => {
        let eventId = document.getElementById(event.target.id);
        let clickedColName = event.target.getAttribute('Headers');
        let enteredValue = event.target.value;

        switch (clickedColName) {
            case 'cust_contact_person':
                rowData[clickedColName] = enteredValue;
                if (enteredValue !== '')
                    delete document.querySelector('input[id^="cust_contact_person"]').parentElement.dataset.tip;
                break;
            case 'cust_dob':
            case 'cust_designation':
            case 'cust_contact_no':
            case 'cust_alternate_contact':
            case 'cust_branch_id':
            case 'escalation_level':
                rowData[clickedColName] = enteredValue;
                break;

            case 'cust_email_id':
            case 'cust_alternate_EmailId':
                rowData[clickedColName] = enteredValue;
                let customerMailInp = document.getElementById(event.target.id);
                if (!validateNumberDateInput.current.validateEmail(enteredValue)) {
                    customerMailInp.parentElement.dataset.tip = 'Please enter valid Mail...!';
                } else {
                    delete customerMailInp.parentElement.dataset.tip;
                }
                break;
        }
        delete event.target.parentElement.dataset.tip;
        const customerContactDetails = [...customerContactData]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        customerContactDetails[arrayIndex] = rowData
        setCustomerContactData(customerContactDetails);
    }

    const removeFirstRow = (indexToRemove) => {
        if (indexToRemove !== 0) {
            // Create a new array without the item to be removed
            const updatedCustomerContactData = customerContactData.filter((item, index) => index !== indexToRemove);
            // Set the state with the updated array
            setCustomerContactData(updatedCustomerContactData)
        } else {
            const updatedContactData = [...customerContactData];  // Create a copy of the array
            updatedContactData[0] = { ...contactBlankObject }; // Set values of 0th index to the contactBlankObject
            updatedContactData[0].cust_branch_id = customerMainBranchIdRef.current;
            setCustomerContactData(updatedContactData);
        }
    }

    const contactBlankObject = {
        company_id: COMPANY_ID,
        company_branch_id: COMPANY_BRANCH_ID,
        customer_contact_id: 0,
        cust_branch_id: customerMainBranchIdRef.current, // Set the default branch for customer_contact.
        customer_id: customer_id,
        cust_contact_person: '',
        cust_designation: '',
        cust_contact_no: '',
        cust_email_id: '',
        cust_dob: '',
        cust_alternate_contact: '',
        cust_alternate_EmailId: '',
        escalation_level: '',
        created_by: customer_id === 0 ? UserName : null,
        modified_by: customer_id !== 0 ? UserName : null
    }

    useLayoutEffect(() => {
        const getExistingCustomerContactData = [...customerContactData]
        // contactBlankObject.cust_branch_id = customerMainBranchIdRef.current;     // Set the default branch for customer_contact.
        getExistingCustomerContactData.push(contactBlankObject)
        setCustomerContactData(getExistingCustomerContactData)
    }, [rowCount, customerMainBranchId]);

    const renderCustomerContactTable = useMemo(() => {
        return <Table id='customerContactTbl' className={`erp_table`} responsive bordered striped>
            <thead className="erp_table_head">
                <tr>
                    {keyForViewUpdate !== 'view' ? (<th className="erp_table_th">Action</th>) : null}
                    <th className="erp_table_th">Customer Contact Person</th>
                    {/* <th className="erp_table_th">Customer Branch </th> */}
                    {/* <th className="erp_table_th">Customer Designation</th> */}
                    <th className="erp_table_th">Customer Contact no</th>
                    <th className="erp_table_th">Customer Alternate Contact</th>
                    <th className="erp_table_th">Customer Email</th>
                    <th className="erp_table_th">Customer Alternate Email</th>
                    {/* <th className="erp_table_th">Customer DOB</th> */}
                    {/* <th className="erp_table_th">Escalation level</th> */}

                </tr>
            </thead>
            <tbody>
                {customerContactData.map((item, index) =>
                    <tr rowindex={index} className="sticky-column">
                        {keyForViewUpdate !== 'view' ? (
                            <td>
                                <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => removeFirstRow(index)} />
                                <IoAddCircleOutline className='erp_trAdd_icon' onClick={fnsetRowCountAndAddRow} />
                                {/* <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => setRowCount(rowCount + 1)} /> */}
                                {/* {
                  index === 0 ? <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => setRowCount(rowCount + 1)} /> : null
                } */}
                            </td>) : null}
                        <td className='erp_table_td'>
                            <input type="text"
                                className="erp_input_field mb-0"
                                id={`cust_contact_person_${index}`}
                                value={item.cust_contact_person}
                                onChange={(e) => { FnUpdateCustomerContactTblRows(item, e); }}
                                Headers='cust_contact_person' disabled={keyForViewUpdate === 'view'}
                            />
                        </td>

                        {/* <td className='erp_table_td'>
                            <select className="form-select form-select-sm mb-0"
                                value={item.cust_branch_id}
                                onChange={(e) => { FnUpdateCustomerContactTblRows(item, e); }}
                                id={`cust_branch_id_${index}`}
                                Headers='cust_branch_id' disabled={keyForViewUpdate === 'view'}>
                                <option value=''>Select</option>
                                {customerBranchOptions.map(item =>
                                    <option value={item.field_id}>{item.field_name}</option>
                                )
                                }
                            </select>
                        </td> */}

                        {/* <td className='erp_table_td'>
                             
                            <input type="text" id={`cust_designation_${index}`}
                                className="erp_input_field mb-0" value={item.cust_designation}
                                onChange={(e) => { FnUpdateCustomerContactTblRows(item, e); }}
                                Headers='cust_designation' maxLength="50" disabled={keyForViewUpdate === 'view'} />
                        </td> */}

                        <td className='erp_table_td'>
                            <input type="text"
                                id={`cust_contact_no_${index}`}
                                className="erp_input_field mb-0"
                                value={item.cust_contact_no}
                                onChange={(e) => {
                                    if (validateNumberDateInput.current.isInteger(e.target.value)) {
                                        FnUpdateCustomerContactTblRows(item, e);
                                    }
                                }}
                                Headers='cust_contact_no'
                                maxLength="10" disabled={keyForViewUpdate === 'view'} />
                        </td>

                        <td className='erp_table_td'>
                            <input type="text"
                                id={`cust_alternate_contact_${index}`}
                                className="erp_input_field mb-0"
                                value={item.cust_alternate_contact}
                                onChange={(e) => {
                                    if (validateNumberDateInput.current.isInteger(e.target.value)) {
                                        FnUpdateCustomerContactTblRows(item, e);
                                    }
                                }}
                                Headers='cust_alternate_contact'
                                maxLength="10"
                                disabled={keyForViewUpdate === 'view'} />
                        </td>


                        <td className='erp_table_td'>
                            <input type="email"
                                id={`cust_email_id_${index}`}
                                className="erp_input_field mb-0"
                                value={item.cust_email_id}
                                onChange={(e) => { FnUpdateCustomerContactTblRows(item, e); }}
                                onBlur={(e) => { FnUpdateCustomerContactTblRows(item, e); }}
                                Headers='cust_email_id' disabled={keyForViewUpdate === 'view'} />
                        </td>

                        <td className='erp_table_td'>
                            <input type="email"
                                id={`cust_alternate_EmailId_${index}`}
                                className="erp_input_field mb-0"
                                value={item.cust_alternate_EmailId}
                                onChange={(e) => { FnUpdateCustomerContactTblRows(item, e); }}
                                onBlur={(e) => { FnUpdateCustomerContactTblRows(item, e); }}
                                Headers='cust_alternate_EmailId' disabled={keyForViewUpdate === 'view'} />
                        </td>

                        {/* <td className='erp_table_td'>
                            <input type="date"
                                className="erp_input_field mb-0"
                                id={`cust_dob_${index}`}
                                value={item.cust_dob}
                                onChange={(e) => { FnUpdateCustomerContactTblRows(item, e); }}
                                Headers='cust_dob'
                                disabled={keyForViewUpdate === 'view'} />
                        </td> */}

                        {/* <td className='erp_table_td'>
                            <input type="text"
                                className="erp_input_field mb-0"
                                id={`escalation_level_${index}`}
                                value={item.escalation_level}
                                onChange={(e) => { FnUpdateCustomerContactTblRows(item, e); }}
                                Headers='escalation_level'
                                disabled={keyForViewUpdate === 'view'} />
                        </td> */}

                    </tr>
                )}
            </tbody>
        </Table>
    }, [customerContactData, customerBranchOptions, designationOptions])
    // ----------------------------------------------------------------------------------------

    // const FnCustContactValidation = async () => {
    //     let customerContactValid = true;
    //     const tableRows = document.querySelectorAll('#customerContactTbl tbody tr');
    //     tableRows.forEach(row => {
    //         const custContactName = row.querySelector('input[id^="cust_contact_person_"]').value;
    //         if (custContactName === '') {
    //             row.querySelector('input[id^="cust_contact_person_"]').parentElement.dataset.tip = 'Please fill this Field...!';
    //             row.querySelector('input[id^="cust_contact_person_"]').focus();
    //             customerContactValid = false;
    //             $('#error_custContact').text('Please Fill the Customer Contact.....').show();
    //             $('#error_custContact').focus();
    //             return;
    //         } else {
    //             delete row.querySelector('input[id^="cust_contact_person_"]').parentElement.dataset.tip;
    //             $('#error_custContact').hide()
    //         }
    //     })

    //     if (!customerContactValid) {
    //         return false;
    //     }

    //     return customerContactValid;
    // }



    return (
        <>
            <ComboBox ref={comboBox} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />

            {isLoading ?
                <div className="spinner-overlay"  >
                    <div className="spinner-container">
                        <CircularProgress color="primary" />
                        <span>Loading...</span>
                    </div>
                </div> :
                ''}
            <div className="erp_top_Form">
                <div className='card p-1'>

                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg main_heding'>Customer {actionType}</label>
                    </div>
                    <form id="customerFormId">
                        <div className='row mt-3'>
                            <div className="col-sm-6 erp_form_col_div">

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Customer Code<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="customerCode" className="erp_input_field" value={txt_customer_code} onChange={e => { setCustomerCodes(e.target.value); validateFields('custInfo'); }} maxLength="50" />
                                        <MDTypography variant="button" id="error_customerCode" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Customer Name<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="customerNameID" className="erp_input_field" value={txt_customer_name} onChange={e => { setCustomerName(e.target.value); validateFields('custInfo') }} maxLength="200" />
                                        <MDTypography variant="button" id="error_customerNameID" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Country <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select size="sm" id="countryId" className="form-select form-select-sm" value={cmb_cust_branch__Country_id} onChange={() => addRecordInProperty("Country")}  >
                                            <option value="" disabled>Select</option>
                                            {countryOptions?.map(country => (
                                                <option value={country.field_id}>{country.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_countryId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">State<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select size="sm" id="stateId" className="form-select form-select-sm" value={cmb_cust_branch_state_id} onChange={() => addRecordInProperty("State")}  >
                                            <option value="" disabled>Select</option>
                                            {stateOptions?.map(state => (
                                                <option value={state.field_id}>{state.field_name}</option>
                                            ))}

                                        </select>
                                        <MDTypography variant="button" id="error_stateId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">District<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select size="sm" id="districtId" className="form-select form-select-sm" value={cmb_cust_branch__District_id} onChange={() => addRecordInProperty("District")}  >
                                            <option value="" disabled>Select</option>
                                            {districtOptions?.map(district => (
                                                <option value={district.field_id}>{district.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_districtId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 erp_form_col_div">

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">City <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cityId" value={cmb_cust_branch__City_id} className="form-select form-select-sm" onChange={() => addRecordInProperty("City")} >
                                            <option value="" disabled>Select</option>
                                            <option value="0">Add New Record +</option>
                                            {cityOptions?.map(city => (
                                                <option value={city.field_id}>{city.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cityId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label"> Address<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control as="textarea" rows={1} id="branchAddr1" className="erp_txt_area" value={txt_cust_branch_address1} onChange={e => { setBranchAddress1(e.target.value); validateFields('custInfo') }} maxlength="500" />
                                        <MDTypography variant="button" id="error_branchAddr1" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>




                                <div className='row'>
                                    <div className='col-sm-4'>
                                        {/* gstOptional */}
                                        <Form.Label className="erp-form-label">GST NO. </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="gstNo" className="erp_input_field" value={txt_cust_branch_gst_no} onChange={e => { setCustBranchGstNo(e.target.value.split(' ').join('').toUpperCase()); validateFields('custInfo') }} maxLength="15" optional="optional" />
                                        <MDTypography variant="button" id="error_gstNo" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Mobile No.</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <span className='erp_phone' >
                                            {/* <select size="sm" id='secCellCountryCode' className='form-select-phone'>
                    {countryCodeOptions?.map(option => (
                      <option value={option}>{option}</option>
                    ))}
                  </select> */}
                                            <Form.Control type="text" className="erp_input_field erp_phn_border" id="txt_cell_no2" value={txt_cell_no2} onInput={(e) => { validateErrorMsgsNumericInput(e); }} maxLength="10" minLength="10" optional='optional' />
                                        </span>
                                        <MDTypography variant="button" id="error_txt_cell_no2" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label"> Email</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="email" id="txt_email_id1" className="erp_input_field" value={txt_email_id1} onInput={e => { setEmailId1(e.target.value); }} maxLength="100" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_email_id1" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                {/* <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Cust. GL Codes</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="select-btn" onClick={() => { FnShowGlCodes() }} optional='optional'>
                                            <span className="form-select form-select-sm" id="">
                                                {totalSelectedGLCodeCheckboxes !== 0 ? totalSelectedGLCodeCheckboxes + ' Selected GL Codes ' : 'Select GL Code'}
                                            </span>
                                        </div>
                                        <ul className="list-items" id="gl_code_ul">
                                            {GLCodesCheckboxes}
                                        </ul>
                                        <MDTypography variant="button" id="error_supplierGLCodesId" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div> */}



                                {/* <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Customer A/C. ID</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='customerAccountsId' className="erp_input_field" value={txt_customer_accounts_id} onChange={e => { setCustomerAccountId(e.target.value); validateFields('custInfo'); }} maxlength="17" optional="optional" />
                                        <MDTypography variant="button" id="error_customerAccountsId" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </form>
                    <hr />
                    {/*Customer Contact Accordian */}
                    {/* <div className={customer_id === 0 ? "accessContactAcc  d-none" : "accessContactAcc"}> */}
                    {/* <Accordion defaultActiveKey="0" >
                        <Accordion.Item eventKey={0}>
                            <Accordion.Header className="erp-form-label-md">Customer Contact Info.</Accordion.Header>
                            <Accordion.Body>
                                {renderCustomerContactTable}

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div className='row'>
                        <div className='col'>
                            <MDTypography variant="button" id="error_custContact" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                            </MDTypography>
                        </div>
                    </div> */}
                    {/* </div> */}

                    {/* <hr /> */}
                </div>
            </div>



            <div className="erp_frm_Btns">
                <MDButton type="button" className="erp-gb-button"
                    onClick={() => {
                        const path = compType === 'Register' ? '/Masters/CustomerListing/reg' : '/Masters/CustomerListing';
                        navigate(path);
                    }} variant="button" fontWeight="regular" disabled={props.btn_disabled ? true : false} >Back</MDButton>
                <MDButton type="button" id='actionlabel_btn' onClick={() => FnAddUpdateCustomer('totalCustEntryInfo')} className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                    fontWeight="regular">{actionLabel}</MDButton>
            </div >

            {/* Success Msg Popup */}
            <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />

            {/* Error Msg Popup */}
            <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

            {/* Add new Record Popup */}
            <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                <Modal.Body className='erp_city_modal_body'>
                    <div className='row'>
                        <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
                    </div>
                    {displayRecordComponent()}
                </Modal.Body>

            </Modal >

            {/* Document modal */}
            {showDocumentForm ?
                <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <DocumentF group_id={customer_id} document_group={"Customer"} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal> : null}


        </>
    )
}