import React, { useState, useEffect, useRef } from "react";
import $ from 'jquery';
import { useTable } from 'react-table'

// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";

// Imports React bootstrap
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

// File Imports
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";


function FrmMBOMSRFilter(props) {
    const configConstants = ConfigConstants();
    const { COMPANY_ID } = configConstants;

    // Table Data
    const [data, setFilteredData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [localStorageKeyArr, setLocalStrKeyArr] = useState([])

    // Get Checked data
    const [checkedData, setCheckedData] = useState([]);

    // Filter Master Combo
    const [cmbMasterOptions, setCmbMasterOptions] = useState([]);

    const comboBox = useRef();
    const [isPropsExist, setIsPropsExist] = useState(false)


    // Select box
    let expanded = false;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
        allColumns,
        getToggleHideAllColumnsProps,
    } = useTable({
        columns,
        data
    })

    useEffect(async () => {

        await createFilterQuery();
        await FnFillCombos();

        $(document).mouseup(function (e) {
            let container = $("#erp-checkboxes");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });

    }, [])


    const FnFillCombos = async () => {

        // Fill filter Master Combo
        resetGlobalQuery();
        globalQuery.columns.push("property_name");
        globalQuery.columns.push("property_value");

        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: "SRFilterMasters" });
        globalQuery.table = "amv_properties";

        const apiCall = await comboBox.current.fillFiltersCombo(globalQuery);
        setCmbMasterOptions(apiCall)

        // Category display
        const catCount = sessionStorage.getItem('compRMCatCount')
        const cmbMasterElements = document.getElementById('cmb_master_name');
        let counter = 1;

        for (let cmbMasterEleInd = 0; cmbMasterEleInd < cmbMasterElements.length; cmbMasterEleInd++) {
            if (cmbMasterElements[cmbMasterEleInd].value === 'smv_product_category' + counter + '') {
                if (counter > parseInt(catCount)) {
                    cmbMasterElements[cmbMasterEleInd].style.display = 'none'
                    counter++;
                } else {
                    counter++;
                    cmbMasterElements[cmbMasterEleInd].style.display = 'block'
                }
            }
        }

        if (props.ptSelected.product_type !== '') {
            setIsPropsExist(true)
            let selectElement = document.getElementById("cmb_master_name");
            selectElement.value = props.ptSelected.master_val;
            await masterOnChange()
            let checkbox = document.getElementById(`filterCheck-${props.ptSelected.product_type}`);
            if (checkbox) {
                checkbox.checked = true;
                const inputElements = document.querySelectorAll('input[name="filterCheckBox"]');
                inputElements.forEach((checkbox) => {
                    checkbox.disabled = true;
                });
                await checkFilterCheckbox()
            }
        }
    }

    const showCheckboxes = () => {

        let checkboxes = document.getElementById("erp-checkboxes");
        const items = document.querySelectorAll(".item");
        if (!expanded) {
            if (items.length !== 0) {
                checkboxes.style.display = "block";
                expanded = true;
            }
        } else {
            checkboxes.style.display = "none";
            expanded = false;
        }
    }

    const masterOnChange = async () => {

        let IsProductTypePresent = sessionStorage.getItem('smv_product_type');
        let IsCategory1Present = sessionStorage.getItem('smv_product_category1');
        let IsCategory2Present = sessionStorage.getItem('smv_product_category2');
        let IsCategory3Present = sessionStorage.getItem('smv_product_category3');
        let IsCategory4Present = sessionStorage.getItem('smv_product_category4');
        let IsCategory5Present = sessionStorage.getItem('smv_product_category5');

        $('#erp-checkboxes').empty();
        let masterValue = $('#cmb_master_name').val();

        switch (masterValue) {
            case 'smv_product_type':
                const filterList = await createWhereJson(masterValue, "productTpDependent")
                checkedSelectedFilterFields(IsProductTypePresent, filterList)
                break;
            case 'smv_product_category1':
                const filterCat1List = await createWhereJson(masterValue, 1)
                checkedSelectedFilterFields(IsCategory1Present, filterCat1List)
                break;
            case 'smv_product_category2':
                const filterCat2List = await createWhereJson(masterValue, 2)
                checkedSelectedFilterFields(IsCategory2Present, filterCat2List)
                break;
            case 'smv_product_category3':
                const filterCat3List = await createWhereJson(masterValue, 3)
                checkedSelectedFilterFields(IsCategory3Present, filterCat3List)
                break;
            case 'smv_product_category4':
                const filterCat4List = await createWhereJson(masterValue, 4)
                checkedSelectedFilterFields(IsCategory4Present, filterCat4List)
                break;
            case 'smv_product_category5':
                const filterCat5List = await createWhereJson(masterValue, 5)
                checkedSelectedFilterFields(IsCategory5Present, filterCat5List)
                break;
            default:
                break;
        }
    }

    const createWhereJson = async (masterValue, count) => {

        let IsProductTypePresent = JSON.parse(sessionStorage.getItem('smv_product_type'));

        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.table = masterValue

        let createJson = { 'viewOrTable': {}, 'selectQuery': {}, 'whereQuery': {} }
        createJson['viewOrTable'][masterValue] = masterValue
        createJson['selectQuery']["field_id, field_name"] = "field_id, field_name"
        if (count !== 'productTpDependent') {
            for (let countIndex = 1; countIndex <= count; countIndex++) {
                if (countIndex === 1) {
                    if (IsProductTypePresent !== '' && IsProductTypePresent !== null && IsProductTypePresent.length !== 0) {
                        globalQuery.conditions.push({ field: "product_type_id", operator: "IN", values: JSON.parse(sessionStorage.getItem('smv_product_type')) });
                    }
                }
                if (sessionStorage.getItem('smv_product_category' + countIndex) !== null && sessionStorage.getItem('smv_product_category' + countIndex) !== '') {
                    globalQuery.conditions.push({ field: 'product_category' + countIndex + '_id', operator: "IN", values: JSON.parse(sessionStorage.getItem('smv_product_category' + countIndex)) });
                }
            }
        } else {
            if (masterValue !== 'smv_product_type') {
                if (IsProductTypePresent !== '' && IsProductTypePresent !== null && IsProductTypePresent.length !== 0) {
                    globalQuery.conditions.push({ field: "product_type_id", operator: "IN", values: JSON.parse(sessionStorage.getItem('smv_product_type')) });
                }
            } else if (masterValue === 'smv_product_type') {
                if (isPropsExist) {
                    globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ['SR', 'GN', 'TM'] });
                }
            }
        }

        // APi Call
        let itemsList = await comboBox.current.fillFiltersCombo(globalQuery)

       
        const ulElement = document.getElementById('erp-checkboxes');

        // Empty the ulElement
        ulElement.innerHTML = '';

        itemsList.forEach((item, index) => {
            const liElement = document.createElement('li');
            liElement.className = 'item bomItems';

            const spanCheckbox = document.createElement('span');
            spanCheckbox.className = 'checkbox';

            const checkboxInput = document.createElement('input');
            checkboxInput.type = 'checkbox';
            checkboxInput.setAttribute('fieldName', item.field_name); // Use data-* attribute to store additional data

            //   checkboxInput.fieldName = item.field_name; // You can set any other properties if needed
            checkboxInput.name = 'filterCheckBox';
            checkboxInput.value = item.field_id;
            checkboxInput.id = `filterCheck-${item.field_id}`;
            checkboxInput.className = 'erp_radio_button filterCheckBox';
            checkboxInput.addEventListener('change', (event) => checkFilterCheckbox(event.target.value, masterValue));

            spanCheckbox.appendChild(checkboxInput);

            const spanItemText = document.createElement('span');
            spanItemText.className = 'item-text';
            spanItemText.textContent = item.field_name;

            liElement.appendChild(spanCheckbox);
            liElement.appendChild(spanItemText);

            ulElement.appendChild(liElement);
        });

        
        return data;
    }

    const checkFilterCheckbox = async (checkboxVal, masterValue) => {
        // const checkboxVal = $(this).val();
        if (parseInt(checkboxVal) === 0) {
            if ($("#All").is(":checked") === false) {
                await checkAllFieldsFrFiltrList('uncheck');
            } else {
                await checkAllFieldsFrFiltrList('check');
            }
        }
        let checkedList = [];
        $("input:checkbox[name=filterCheckBox]:checked").each(async function () {
            const value = $(this).val()
            if (parseInt(value) !== 0) {
                checkedList.push(value);
            }
        });
        sessionStorage.setItem(masterValue, JSON.stringify(checkedList))
        await createFilterQuery();
    }

    const checkAllFieldsFrFiltrList = async (key) => {

        const fliterCheckbox = document.getElementsByName('filterCheckBox');
        if (key === 'check') {
            for (let checkbox of fliterCheckbox) {
                $('input:checkbox[name="filterCheckBox"][value="' + checkbox.value + '"]').attr('checked', true);
                if (parseInt(checkbox.value) !== 0)
                    $('input:checkbox[name="filterCheckBox"][value="' + checkbox.value + '"]').attr('disabled', true);
            }
        } else {
            for (let checkbox of fliterCheckbox) {
                $('input:checkbox[name="filterCheckBox"][value="' + checkbox.value + '"]').attr('checked', false);
                $('input:checkbox[name="filterCheckBox"][value="' + checkbox.value + '"]').attr('disabled', false);
            }
        }
    }

    const checkedSelectedFilterFields = async (sesssionVal, filterList) => {

        if (sesssionVal !== '' && sesssionVal !== null) {
            let fliterCheckbox = document.getElementsByName('filterCheckBox');
            let IsSessionvalPresentParse = JSON.parse(sesssionVal)
            for (let index = 0; index < filterList.length; index++) {
                for (let checkbox of fliterCheckbox) {
                    if (parseInt(checkbox.value) === filterList[index].field_id) {
                        $('input:checkbox[name="filterCheckBox"][value="' + IsSessionvalPresentParse[index] + '"]').attr('checked', true);
                    }
                }
            }
        }
    }

    const createFilterQuery = async () => {

        let checkedList = [];
        let checkListName = [];

        resetGlobalQuery();
        globalQuery.columns.push("*");
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        globalQuery.table = "smv_product_sr_summary"

        $("input:checkbox[name=filterCheckBox]:checked").each(function () {
            const checkbValue = $(this).val()
            if (parseInt(checkbValue) !== 0) {
                checkedList.push(checkbValue);
                checkListName.push($(this).attr('fieldName'));
            }
        });

        localStorage.setItem($('#cmb_master_name').val(), JSON.stringify(checkedList))
        localStorage.setItem($('#cmb_master_name').val() + "Name", JSON.stringify(checkListName))

        localStorageKeyArr.push($('#cmb_master_name').val())
        localStorageKeyArr.push($('#cmb_master_name').val() + "Name")
        localStorage.setItem(`localStorageKeyArr`, JSON.stringify(localStorageKeyArr))


        let query = ""
        let productTypes = JSON.parse(localStorage.getItem('smv_product_type'));
        let productTypesName = JSON.parse(localStorage.getItem('smv_product_typeName'))
        if (localStorage.getItem('smv_product_type') !== null && localStorage.getItem('smv_product_type') !== '') {
            if (productTypes.length !== 0) {
                let productTPStr = [];
                let productTPNameStr = '';
                for (let indexPT = 0; indexPT < productTypes.length; indexPT++) {
                    productTPStr.push(productTypes[indexPT])
                    productTPNameStr += JSON.stringify(productTypesName[indexPT]) + ', '
                }
                productTPNameStr = productTPNameStr.substring(0, productTPNameStr.length - 2)
                globalQuery.conditions.push({ field: "product_sr_type_id", operator: "IN", values: productTPStr });

                query += "product_sr_type_id IN" + "(" + productTPNameStr + ")\n"
            }
        }

        for (let catIndex = 1; catIndex <= 5; catIndex++) {
            let category = JSON.parse(localStorage.getItem('smv_product_category' + catIndex))
            let categoryName = JSON.parse(localStorage.getItem('smv_product_category' + catIndex + 'Name'))
            if (localStorage.getItem('smv_product_category' + catIndex) !== null && localStorage.getItem('smv_product_category' + catIndex) !== '') {
                if (category.length !== 0) {
                    let catStr = [];
                    let catStrName = '';
                    for (let indexCat = 0; indexCat < category.length; indexCat++) {
                        catStr.push(category[indexCat])
                        catStrName += JSON.stringify(categoryName[indexCat]) + ', '
                    }
                    catStrName = catStrName.substring(0, catStrName.length - 2)

                    globalQuery.conditions.push({ field: 'product_sr_category' + catIndex + '_id', operator: "IN", values: catStr });
                    query += 'product_sr_category' + catIndex + '_id IN' + "(" + catStrName + ")\n"
                }

            }
        }

        $('#selctedFilters').text(query)

        // APi Call
        if (query !== "") {
            const responceOfFilteredData = await comboBox.current.fillFiltersCombo(globalQuery);
            console.log("responceOfFilteredData: ", responceOfFilteredData)
            if (responceOfFilteredData.length > 0) {
                $('#bomNoRcrdId').hide();
                $('.bomFilterData').show();
                let column = [];
                let columnHeads = [];
                for (let keys in responceOfFilteredData[0]) { columnHeads.push(keys) };
                console.log("column heads: ", columnHeads)
                for (let colKey = 0; colKey < columnHeads.length; colKey++) {
                    if (colKey == 0) {
                        column.push({
                            Headers: "Action",
                            accessor: "Action",
                            Cell: row => (
                                <div style={{ display: "flex" }}>
                                    <input type='checkbox' class="option" name="checkFilteredData" id={'checkFilteredDataId_' + row.row.original.product_sr_id}
                                        value={row.row.original.product_sr_id} onClick={() => { handleCheckbox(row.row.original.product_sr_id); }}>
                                    </input>
                                </div>
                            ),
                        });
                    }
                    if (!columnHeads[colKey].includes('company_name') && !columnHeads[colKey].includes('company_branch_name') && !columnHeads[colKey].includes('_id') && !columnHeads[colKey].includes('is_') && !columnHeads[colKey].includes('field_name')) {
                        column.push({ Headers: columnHeads[colKey], accessor: columnHeads[colKey] });
                    }
                }
                setColumns(column)
                setFilteredData(responceOfFilteredData)
                if (sessionStorage.getItem('isComboFilterExist') === 'true') {
                    sessionStorage.setItem(`comboFilterData`, JSON.stringify(responceOfFilteredData))
                }
                await checkIfBomFilterDataIsExist();
            } else {
                $('.bomFilterData').hide();
                $('#bomNoRcrdId').show();
            }
        } else {
            $('.bomFilterData').hide();
            $('#bomNoRcrdId').show();
        }
    }

    const handleCheckbox = (product_sr_id) => {
        $('input:checkbox[name="checkFilteredData"][value="' + product_sr_id + '"]').attr('checked', false);
    }

    const checkIfBomFilterDataIsExist = async () => {

        const getDataIfExist = JSON.parse(sessionStorage.getItem('bomfilteredData'))
        if (getDataIfExist !== null && getDataIfExist !== '') {
            const valuesToCheck = getDataIfExist.map(item => item.product_sr_id.toString());
            $('input:checkbox[name="checkFilteredData"]').each(function () {
                if (valuesToCheck.includes($(this).val())) {
                    $(this).prop('checked', true);
                }
            });
        }
    }

    const applyFilterNShowData = () => {
        let availObj;
        const requiredCols = JSON.parse(localStorage.getItem('requiredCols'));
        const checked = $("input:checkbox[name=checkFilteredData]:checked")
        for (let index = 0; index < checked.length; index++) {
            const element = parseInt(checked[index].value);

            let availableBomData = JSON.parse(sessionStorage.getItem('bomfilteredData'));
            if (availableBomData !== null && availableBomData !== "") {
                availObj = availableBomData.find(item => item.product_sr_id === element);
            }

            if (availObj === undefined) {

                for (let dataIndex = 0; dataIndex < data.length; dataIndex++) {
                    const tdData = data[dataIndex];
                    if (tdData.product_sr_id === element) {
                        const filteredDataRecord = {};
                        requiredCols.forEach((col) => {
                            if (tdData.hasOwnProperty(col)) {
                                filteredDataRecord[col] = tdData[col];
                            } else {
                                filteredDataRecord[col] = 0;
                            }
                        });
                        filteredDataRecord['product_sr_quantity'] = 1;
                        checkedData.push(filteredDataRecord);
                    }
                }
            } else {
                checkedData.push(availObj)
            }
        }
        console.log("applyFilterNShowData", checkedData)
        sessionStorage.setItem('bomfilteredData', JSON.stringify(checkedData))
        props.closeModal();
    }

    function toggleOptionsChkBoxes(key) {

        switch (key) {
            case "checkAll":
                $('.option').prop('checked', $('#chkAllOptions').is(":checked"))
                break;
        }
    }

    $('.option').change(function () {
        $('#chkAllOptions').prop('checked', $('input:checkbox.option:checked').length == $('input:checkbox.option').length);
    });

    return (
        <>
            <ComboBox ref={comboBox} />
            <div className="card filter">
                <div className='row'>
                    <Form.Label className="erp-form-label-md">Filters:</Form.Label>
                </div>
                <div className='row'>
                    <div className='col-sm-4 erp_form_col_div'>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <Form.Label className="erp-form-label">Masters:</Form.Label>
                            </div>
                            <div className='col'>
                                <select id="cmb_master_name" className="form-select form-select-sm" onChange={() => masterOnChange()} disabled={isPropsExist ? true : false}>
                                    <option value=""></option>
                                    {cmbMasterOptions?.map((masterVal) => (
                                        <option value={masterVal.property_name}>{masterVal.property_value}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>


                    <div className='col-sm-4 erp_form_col_div'>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <Form.Label className="erp-form-label">Filters:</Form.Label>
                            </div>
                            <div className='col'>
                                <div className="erp-container">
                                    <div className="select-btn" onClick={() => showCheckboxes()}>
                                        <span className="form-select form-select-sm">Select</span>
                                    </div>
                                    <ul className="list-items" id="erp-checkboxes">

                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='col-sm-4 erp_form_col_div'>
                        <div className='row'>
                            <div className='col-sm-2'>
                                <Form.Label className="erp-form-label">Selection Criteria:</Form.Label>
                            </div>
                            <div className='col'>
                                <Form.Control as="textarea" id="selctedFilters" rows={1} className="erp_txt_area" readOnly />

                            </div>
                        </div>


                    </div>

                </div>
            </div>

            <div className="row">
                <span id="bomNoRcrdId" className="no-recrds erp-form-label-md" style={{ display: "none" }}>No records found...</span>
                {
                    columns.length > 0 ?
                        <span>
                            <input type='checkbox' id="chkAllOptions" onClick={(e) => toggleOptionsChkBoxes('checkAll')} /> <label for="chkAllOptions" class="erp-form-label pb-1"> Select All Options</label>
                        </span>
                        : ""
                }
                <div className="bomFilterData" style={{ display: 'none' }}>
                    <Table className="erp_table erp_table_scroll" bordered {...getTableProps()} striped>
                        <thead className="erp_table_head">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th className="erp_table_th" {...column.getHeaderProps()}>{column.render('Headers')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row)

                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td className="erp_table_td" {...cell.getCellProps()}>{cell.render('Cell')}</td>)
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>

                    </Table>
                </div>
            </div>
            <div className="erp_frm_Btns bomFilterData" style={{ display: 'none' }}>
                <MDButton className="erp-gb-button erp_MLeft_btn" variant="button" onClick={applyFilterNShowData} fontWeight="regular">
                    Add
                </MDButton>
            </div>
        </>
    )


}

export default FrmMBOMSRFilter