import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Configurator from "examples/Configurator";
import Footer from "examples/Footer";
import MenuNavbar from "./components/Navbars/MenuNavbar"
import DashboardNav from "components/Navbars/DashboardNav";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import InvoiceTemplate from "FrmGeneric/InvoiceTemplate";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import "assets/css/style.js";
import ConfigConstants from "assets/Constants/config-constant";

export default function App() {
  const configConstants = ConfigConstants();
  const { IS_AUTHENTICATED } = configConstants;

  // const isAuthenticated = localStorage.getItem('isAuthenticated');
  // sessionStorage.setItem('companyAddress', "Datta Mandir Rd, in front of Shakti Sports, near Polarise Healthcare Hospital, Shankar Kalat Nagar, Wakad, Pune, Maharashtra 411057")

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    darkMode,
  } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Cache for the rtl
  // useMemo(() => {
  //   const cacheRtl = createCache({
  //     key: "rtl",
  //     stylisPlugins: [rtlPlugin],
  //   });

  //   setRtlCache(cacheRtl);
  // }, []);



  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    if (!IS_AUTHENTICATED) {
      navigate("/login")
    }
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((routePath) => {
      if (routePath.collapse) {
        return getRoutes(routePath.collapse);
      }

      if (routePath.route) {
        if (routePath.protected && !IS_AUTHENTICATED) {
          return (<Route exact path={routePath.route} element={routePath.component} key={routePath.key} />);
        } else if (IS_AUTHENTICATED && routePath.type !== 'registers') {
          return (<Route exact path={routePath.route} element={routePath.component} key={routePath.key} />);
        } else {
          return (<Route exact path={routePath.route} element={routePath.component} key={routePath.key} />);
        }
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    //   <CacheProvider value={rtlCache}>
    //     <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
    //       <CssBaseline />
    //       {layout === "dashboard" && (
    //         <>
    //           <div style={{ display: 'none' }}>
    //             {/* <Sidenav
    //               color={sidenavColor}
    //               brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
    //               brandName="ERP DEV"
    //               routes={routes}
    //               onMouseEnter={handleOnMouseEnter}
    //               onMouseLeave={handleOnMouseLeave}
    //             /> */}
    //           </div>
    //           <Configurator />
    //           {configsButton}
    //         </>
    //       )}
    //       {layout === "vr" && <Configurator />}
    //       <Routes>
    //         {getRoutes(routes)}
    //         <Route path="*" element={<Navigate to="" />} />
    //       </Routes>
    //     </ThemeProvider>
    //   </CacheProvider >
    // ) :
    //  (
    <>
      <ThemeProvider theme={darkMode ? theme : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <div>
            {pathname !== '/Invoice' && pathname !== '/Error' && IS_AUTHENTICATED && (
              <div className="erp_header_fix">
                <DashboardNav />
                <MenuNavbar routes={routes} />
              </div>
            )}

            {/* <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} allRoutes={routes} pathname={pathname} /> */}

            {/* <div style={{ display: 'none' }}> */}
            {/* <Sidenav
                color={sidenavColor}
                // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName="ERP DEV"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              /> */}
            {/* </div> */}
            <Configurator />
            {/* {configsButton} */}
          </div>
        )}
        {layout === "vr" && <Configurator />}

        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/login" />} />
          <Route path='/Invoice' element={<InvoiceTemplate />}></Route>
        </Routes>
        {pathname !== '/Invoice' && pathname !== '/login' && pathname !== '/Error' && <Footer />}

      </ThemeProvider>
    </>
  );

}


