
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";


// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// imports react bootstrap
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Wizard page components
import FrmMProductFGEntry from "./components/FrmMProductFGEntry";
import FrmMBillOfMaterialEntry from "./components/FrmMBillOfMaterialEntry";

function getSteps() {
  return ["Product Finish Good", "Bill Of Material(BOM)"];
}

function FrmMProductFG() {

  useEffect(async () => {
    ActionType();
  }, [])

  //In listing page we passed in navigate
  const { state } = useLocation();
  const { productFGId = 0, productFGName, keyForViewUpdate} = state || {}

  const [fg_id, setFgId] = useState(productFGId)
  const [fg_name, setFgName] = useState(productFGName)
  const [key, setKey] = useState(keyForViewUpdate)
  const [ptShortName, setPtShortName] = useState(localStorage.getItem('ptShortName'));
  const [actionType, setActionType] = useState('')


  const [activeStep, setActiveStep] = useState(0);

  const steps = getSteps();

  const handleNext = (product_fg_id, product_fg_name, product_type_name) => {
    if (product_fg_id !== 0) {
      setPtShortName(product_type_name)
      setFgId(product_fg_id)
      setFgName(product_fg_name)
      setActiveStep(parseInt(activeStep) + 1)
    }
  }

  const handleBack = (productFGId) => {
    setFgId(productFGId)
    setActiveStep(parseInt(activeStep) - 1)
  };

  const moveByTabs = (eventKey) => {
    if (fg_id !== 0 && ptShortName !== null) {
      setActiveStep(eventKey)
    }
  }
  function getStepContent() {
    switch (parseInt(activeStep)) {
      case 0:
        return <FrmMProductFGEntry goToNext={handleNext} fg_id={fg_id} />;
      case 1:
        return <FrmMBillOfMaterialEntry goBack={handleBack}
          values={{
            productFGId: fg_id,
            productFGName: fg_name,
            ptShortName: ptShortName,
            keyForViewUpdate: key
          }} />;
      default:
        return null;
    }
  }


  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modification)');
        break;
      case 'view':
        setActionType('(View)');
        break;
      case 'delete':
        setActionType('(Delete)');
        break;
      default:
        setActionType('(Creation)');
        break;
    }
  };

  return (
    <>
      <DashboardLayout>
        <div className='main_heding'>
          <label className='erp-form-label-lg main_heding'>Finish Goods Infomation {actionType} </label>
        </div>
        <MDBox pt={3} pb={8} className="erp_form_tab_div">
          <Grid justifyContent="center" sx={{ my: 4 }} >
            <Grid item xs={12} lg={8} className="erp_form_container_tabs">
              <MDBox mt={-3} mx={2}>
                <Tabs
                  activeKey={activeStep}
                  id="uncontrolled-tab-example"
                  className="mb-3 selectedTab erp-form-Tabs" onSelect={eventKey => moveByTabs(eventKey)}>
                  {steps.map((label, index) => (
                    <Tab eventKey={index} title={label} ></Tab>
                  ))}
                </Tabs>
                {getStepContent()}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default FrmMProductFG;
