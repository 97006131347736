import React, { useEffect } from 'react'
import { useState } from "react";
import { useLocation } from 'react-router-dom';

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// imports react bootstrap
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import FrmMEmployeesEntry from './components/FrmMEmployeesEntry';
import FrmMEmployeeAccessRights from './components/FrmMEmployeeAccessRights';


function getSteps() {
  return ["Employee Information", "Employee Access Rights"];
}


export default function MEmployeeEntry() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const { state } = useLocation();
  const { employeeID = 0, keyForViewUpdate = 'Add', compType = 'Masters', designation_id, employee_code, employee_name } = state || {}

  const [employeeId, setEmployeeId] = useState(employeeID)
  const [viewOrUpdate, setViewOrUpdate] = useState(keyForViewUpdate)
  const [designationId, setDesignationId] = useState(designation_id)
  const [employeeCode, setEmployeeCode] = useState(employee_code)
  const [employeeName, setEmployeeName] = useState(employee_name)


  const handleNext = (employee_id, viewUpdateKey, designation_id, employee_code, employee_name) => {
    if (employee_id !== 0) {
      setEmployeeId(employee_id)
      setDesignationId(designation_id)
      setViewOrUpdate(viewUpdateKey)
      setEmployeeCode(employee_code)
      setEmployeeName(employee_name)
      setActiveStep((activeStep) + 1)
    }

  };
  const handleBack = (employeeID) => {
    setEmployeeId(employeeID)
    setActiveStep((activeStep) - 1)
  };
  const [actionType, setActionType] = useState('')

  useEffect(() => {
    ActionType()
  }, [])

  const ActionType = () => {
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modify)');
        break;
      case 'view':
        setActionType('(View)');
        break;
      default:
        setActionType('(Create)');
        break;
    }

  };

  const moveByTabs = (eventKey) => {
    if (employeeId !== 0)
      setActiveStep(eventKey)
  }

  function getStepContent() {
    switch (parseInt(activeStep)) {
      case 0:
        return <FrmMEmployeesEntry goToNext={handleNext} employeeID={employeeId}/>;
      case 1:
        return <FrmMEmployeeAccessRights goBack={handleBack} employeeID={employeeId} compType={compType} keyForViewUpdate={viewOrUpdate} designationId={designationId} employeeCode={employeeCode} employeeName={employeeName}/>;
    }
  }

  return (
    <>
      <DashboardLayout>
        <div className='main_heding'>
          <label className='erp-form-label-lg main_heding'> Employee Information {actionType}</label>
        </div>
        <MDBox pt={3} pb={8} className="erp_form_tab_div">
          <Grid justifyContent="center" sx={{ my: 4 }} >
            <Grid item xs={12} lg={8} className="erp_form_container_tabs">
              <MDBox mt={-3} mx={2}>
                <Tabs
                  activeKey={activeStep}
                  id="uncontrolled-tab-example"
                  className="mb-3 selectedTab erp-form-Tabs" onSelect={eventKey => moveByTabs(eventKey)}>
                  {steps.map((label, index) => (
                    <Tab eventKey={index} title={label} ></Tab>
                  ))}
                </Tabs>
                {getStepContent()}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {/* <Footer /> */}
      </DashboardLayout>
    </>
  );
}
