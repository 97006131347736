import React, { useEffect, useRef, useState, useMemo } from "react";
import { Accordion, Modal, Table, Button, Card } from "react-bootstrap";

import $ from 'jquery';
import { useNavigate, useLocation } from "react-router-dom";

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { RxCrossCircled } from "react-icons/rx";
import { CircularProgress } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";

// React icons
import { MdDelete, MdRefresh } from "react-icons/md";

//File Imports
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ComboBox from "Features/ComboBox";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import DocumentF from "Features/Document";


// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import MDTypography from "components/MDTypography";
import FrmValidations from "FrmGeneric/FrmValidations";
import Datatable from "components/DataTable";
import { Link } from "react-router-dom";
import ConfigConstants from "assets/Constants/config-constant";
import ExcelExport from "Features/Exports/ExcelExport";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import ConfirmationModal from "components/Modals/ConfirmationModal";


function TCustomerMaterialReturnEntry() {

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_NAME, COMPANY_BRANCH_ID, FINANCIAL_SHORT_NAME, UserId, UserName } = configConstants;

    const { state } = useLocation();
    const { idList, keyForViewUpdate, compType } = state || {};
    const [isLoading, setIsLoading] = useState(false);

    //for current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    var expanded = false;


    const [actionLabel, setActionLabel] = useState('Save')
    const [actionType, setActionType] = useState('')

    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    // Customers contact details table data hooks
    const [custContactDetails, setCustContactDetails] = useState([]);
    const [custContactDetailsCols, setCustContactDetailsCols] = useState([]);

    //UseRefs
    const validateNumberDateInput = useRef();
    const navigate = useNavigate();
    const validate = useRef();
    const generateAutoNoAPiCall = useRef();
    const comboDataFunc = useRef();
    const exlsExp = useRef();

    //Header hooks
    // const [customer_goods_receipt_master_transaction_id, setCustomerGoodsReceiptMasterTransactionId] = useState(0)
    const [cmb_material_type, setmaterialType] = useState('');
    const [txt_customer_material_return_version, setcustomer_material_return_version] = useState(1);
    const [txt_customer_material_return_no, setcustomer_material_return_no] = useState(0);
    const [dt_customer_material_return_date, setMaterialreturnDate] = useState(today());
    const [txt_customer_challan_no, setCustomerChallanNo] = useState('');
    const [dt_customer_challan_Date, setdtCustomerChallanDate] = useState('');
    const [cmb_expected_branch_id, setExpectedBranchId] = useState(COMPANY_BRANCH_ID);
    const [cmb_customer_id, setCustomerId] = useState(idList !== null && idList !== undefined ? idList.customer_id : '');
    const [cmb_customer_state_id, setCustomerStateId] = useState('');
    const [cmb_customer_state_name, setCustomerStateName] = useState('');
    const [cmb_customer_order_no, setCustomerOrderNo] = useState('')
    const [customer_order_date, setCustomerOrderDate] = useState('');

    const [storedCustGoodsReceiptNoData, setCustomerGoodsreciptsOption] = useState('');
    const [CustomerGoodsreciptsNoList, setCustomerGoodsreciptsNoList] = useState([])


    // const [showConfirmationModal, setShowConfiemationModal] = useState(false)
    // const [message, setMessage] = useState('')


    const closeConfirmationModal = async (key) => {
        switch (key) {
            case 'Exit':
                setShowSuccessMsgModal(false);
                break;
            default:
                break;
        }
        moveToListing();
    }

    const [cmb_qa_by_id, setQabyId] = useState(UserId);
    const [dt_qa_date, setQadate] = useState(today);



    // Document Form
    let documentGroup = "Customer Return Material";
    const [docData, setDocumentData] = useState([]);


    //Footer hooks
    //changes by tushar     
    const [txt_grandtotal, setgrandtotal] = useState(0);
    const [cmb_customer_material_return_status, setCustomerMaterialReturnStatus] = useState("P");
    const [txt_lr_no, setlrNo] = useState('');
    const [dt_lr_date, setlrDate] = useState('');
    const [txt_ebw_no, setEbwNo] = useState('');
    const [dt_ebw_date, setEbwDate] = useState('');
    const [txt_vehicle_no, setVehicleNo] = useState('');
    const [txt_remark, setRemark] = useState('');
    const [is_active, setIsActive] = useState(true);

    //combo options
    const [rejectionParameterList, setRejectionParameterList] = useState([])
    const [customerOrdOpts, setCustomerOrdOpts] = useState([]);
    const [expectedBranchList, setExpectedBranchList] = useState([])
    const [employeeList, setEmployeeList] = useState([]);
    const [godownsList, setGodownsList] = useState([]);
    const [godownsSectionList, setGodownsSectionList] = useState([]);
    const [godownsSectioonBeanList, setGodownsSectionBeanList] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [materialtypeOptions, setmaterialtypeOptions] = useState([])
    const [customerOptions, setCustomerOptions] = useState([])
    const [departmentList, setDepartmentList] = useState([]);

    const [CustomerMaterialRetunData, setCustomerMaterialRetunData] = useState([])


    const [checkedCustomerGoodsReceiptNoLength, setCheckedCustomerGoodsReceiptNoLength] = useState(0)


    //Popup fields
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Succ Msg
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        moveToListing();
    }

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    // const [IsView, setIsView] = useState(false)

    // Document Form
    // const [showDocumentForm, setShowDocumentForm] = useState(false);
    // const handleCloseDocumentForm = () => setShowDocumentForm(false);
    // const viewDocumentForm = () => {
    //     if (keyForViewUpdate !== 'view') {
    //         setShowDocumentForm(true);
    //     }
    // }

    let fin_year = "";
    let customer_goods_receipt_version = "";


    useEffect(async () => {

        setIsLoading(true);
        try {
            await ActionType();
            await fillComobos();
            if (idList !== '') {
                await FnCheckUpdateResponce();
            }
            $(document).on('mouseup', function (e) {
                var container = $("#Customer-GoodsReceiptNo-ul");
                if (!container.is(e.target) && container.has(e.target).length === 0) {
                    container.hide();
                }
            });
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [])



    // const handleCloseRecModal = async () => {
    //     switch (modalHeaderName) {

    //         case 'Material Type':
    //             resetGlobalQuery();
    //             globalQuery.columns.push("field_id");
    //             globalQuery.columns.push("field_name");
    //             globalQuery.columns.push("product_type_short_name");
    //             globalQuery.table = "smv_product_type"
    //             globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    //             globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["RM", "FG"] });
    //             comboDataFunc.current.fillFiltersCombo(globalQuery)
    //                 .then(getProductType => {
    //                     setmaterialtypeOptions(getProductType)
    //                     console.log("Product Type List: ", getProductType);
    //                 });
    //             break;
    //         default:
    //             break;
    //     }
    //     setShowAddRecModal(false);
    //     sessionStorage.removeItem('dataAddedByCombo')
    //     setTimeout(() => {
    //         $(".erp_top_Form").css({ "padding-top": "110px" });
    //     }, 200)

    // }



    useEffect(() => {
        const FunctionCall = () => {
            if (CustomerMaterialRetunData.length !== 0) {
                calculateGrandTotal(CustomerMaterialRetunData);
            }
        }
        FunctionCall();
    }, [CustomerMaterialRetunData])



    const ActionType = async () => {

        switch (keyForViewUpdate) {

            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                $('#cmb_customer_id').attr('disabled', true)
                $('#cmb_customer_order_no').attr('disabled', true)
                break;
            case 'view':
                setActionType('(View)');
                await validate.current.readOnly("CustomerMaterialReturnHeaderFormId");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");

                break;
            case 'delete':
                setActionType('(Delete)');
                await validate.current.readOnly("CustomerMaterialReturnHeaderFormId");
                // await validate.current.readOnly("CustomerMaterialReturnFooterFormId");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");

                break;
            default:
                setActionType('(Creation)');
                break;
        }

    };

    const displayRecordComponent = () => {
        switch (modalHeaderName) {

            case 'Material Type':
                return <FrmMProductTypeEntry />;

            default:
                return null;
        }
    }




    // --------------------------------------- Material Reciept NOtes Save Functionality Start------------------------------------------------------------------
    const addCustomerMaterialReturn = async () => {
        debugger
        try {
            setIsLoading(true);

            let mrnValidated = true;
            mrnValidated = await FnValidateCustomerMaterialReturnNote();
            if (cmb_customer_material_return_status === '') {
                $('#error_cmb_customer_material_return_status').show();
                mrnValidated = false;
            }

            // let latestCustomerMaterialReturnNo = txt_customer_material_return_no;
            // let customerContactIds = FnGetCustomerContactPersonIds();

            if (mrnValidated) {
                let json = { 'TransDetailData': [], 'commonIds': {} }
                // let json = { 'TransHeaderData': {}, 'TransDetailData': [], 'commonIds': {} }

                // latestCustomerMaterialReturnNo = await generateMaterialReturnNo();
                // const mrnMasterData = {
                //     // customer_material_return_master_transaction_id: 0,
                //     company_id: COMPANY_ID,
                //     company_branch_id: COMPANY_BRANCH_ID,
                //     financial_year: FINANCIAL_SHORT_NAME,
                //     // customer_material_return_no: txt_customer_material_return_no,
                //     // customer_material_return_date: dt_customer_material_return_date,
                //     // customer_material_retun_version: txt_customer_material_return_version,
                //     // customer_material_return_type_id: cmb_material_type,
                //     // customer_material_return_type: $('#cmb_material_type').find(":selected").text(),
                //     customer_id: cmb_customer_id,
                //     // customer_contacts_ids: customerContactIds,
                //     customer_state_id: cmb_customer_state_id,
                //     expected_branch_id: cmb_expected_branch_id,
                //     customer_order_no: cmb_customer_order_no,
                //     customer_order_date: customer_order_date,
                //     // customer_challan_no: txt_customer_challan_no,
                //     // customer_challan_Date: dt_customer_challan_Date,
                //     qa_by_id: cmb_qa_by_id,
                //     qa_date: dt_qa_date,
                //     // customer_material_return_status: cmb_customer_material_return_status,
                //     // grand_total: txt_grandtotal,
                //     // lr_no: txt_lr_no,
                //     // lr_date: dt_lr_date,
                //     // vehicle_no: txt_vehicle_no,
                //     // ewb_no: txt_ebw_no,
                //     // ewb_valid_till: dt_ebw_date,
                //     // remark: txt_remark,
                //     created_by: UserName,
                //     modified_by: keyForViewUpdate === 'update' ? UserName : '',
                //     // is_active: is_active,
                // }
                // json.TransHeaderData = mrnMasterData;

                for (let CustomerMaterialReturnIndex = 0; CustomerMaterialReturnIndex < CustomerMaterialRetunData.length; CustomerMaterialReturnIndex++) {
                    const CustomerMaterialReturnElement = CustomerMaterialRetunData[CustomerMaterialReturnIndex];
                    var crJson = {}

                    crJson['company_id'] = COMPANY_ID
                    crJson['company_branch_id'] = COMPANY_BRANCH_ID
                    crJson['financial_year'] = FINANCIAL_SHORT_NAME

                    crJson['customer_goods_receipt_master_transaction_id'] = CustomerMaterialReturnElement.customer_goods_receipt_master_transaction_id

                    // crJson['customer_material_return_no'] = txt_customer_material_return_no
                    // crJson['customer_material_return_date'] = dt_customer_material_return_date
                    // crJson['customer_material_retun_version'] = txt_customer_material_return_version
                    // crJson['customer_material_return_type_id'] = cmb_material_type
                    // crJson['customer_material_return_type'] = $('#cmb_material_type').find(":selected").text()
                    crJson['customer_batch_no'] = CustomerMaterialReturnElement.customer_batch_no
                    crJson['customer_material_id'] = CustomerMaterialReturnElement.customer_material_id
                    crJson['customer_material_tech_spect'] = CustomerMaterialReturnElement.customer_material_tech_spect
                    crJson['sr_no'] = CustomerMaterialReturnIndex + 1
                    crJson['customer_material_unit_id'] = CustomerMaterialReturnElement.customer_material_unit_id
                    crJson['customer_material_packing_id'] = CustomerMaterialReturnElement.customer_material_packing_id
                    crJson['customer_material_hsn_code_id'] = CustomerMaterialReturnElement.customer_material_hsn_code_id
                    crJson['customer_material_order_quantity'] = CustomerMaterialReturnElement.customer_material_order_quantity
                    crJson['customer_material_order_weight'] = CustomerMaterialReturnElement.customer_material_order_weight
                    crJson['customer_material_accepted_quantity'] = CustomerMaterialReturnElement.customer_material_accepted_quantity
                    crJson['customer_material_accepted_weight'] = CustomerMaterialReturnElement.customer_material_accepted_weight
                    // crJson['product_material_return_quantity'] = CustomerMaterialReturnElement.customer_material_return_quantity
                    // crJson['product_material_return_weight'] = CustomerMaterialReturnElement.customer_material_return_weight
                    // crJson['return_item_status'] = CustomerMaterialReturnElement.return_item_status
                    crJson['customer_material_conversion_factor'] = CustomerMaterialReturnElement.customer_material_conversion_factor
                    crJson['customer_material_rejected_quantity'] = CustomerMaterialReturnElement.customer_material_rejected_quantity
                    crJson['customer_material_rejected_weight'] = CustomerMaterialReturnElement.customer_material_rejected_weight
                    crJson['customer_material_rejection_reason_id'] = CustomerMaterialReturnElement.customer_material_rejection_reason_id
                    crJson['customer_material_rate'] = CustomerMaterialReturnElement.customer_material_rate
                    crJson['customer_material_item_amount'] = CustomerMaterialReturnElement.customer_material_item_amount
                    crJson['customer_material_prev_accepted_quantity'] = CustomerMaterialReturnElement.customer_material_prev_accepted_quantity
                    crJson['customer_material_prev_accepted_weight'] = CustomerMaterialReturnElement.customer_material_prev_accepted_weight
                    crJson['pree_closed_customer_quantity'] = CustomerMaterialReturnElement.pree_closed_customer_quantity
                    crJson['pree_closed_customer_weight'] = CustomerMaterialReturnElement.pree_closed_customer_weight
                    crJson['customer_return_quantity'] = CustomerMaterialReturnElement.customer_return_quantity
                    crJson['customer_return_weight'] = CustomerMaterialReturnElement.customer_return_weight
                    crJson['production_return_quantity'] = CustomerMaterialReturnElement.production_return_quantity
                    crJson['production_return_weight'] = CustomerMaterialReturnElement.production_return_weight
                    crJson['production_return_return_quantity'] = CustomerMaterialReturnElement.production_return_return_quantity
                    crJson['production_return_return_weight'] = CustomerMaterialReturnElement.production_return_return_weight
                    crJson['production_return_rejection_quantity'] = CustomerMaterialReturnElement.production_return_rejection_quantity
                    crJson['production_return_rejection_weight'] = CustomerMaterialReturnElement.production_return_rejection_weight
                    crJson['assembly_production_return_quantity'] = CustomerMaterialReturnElement.assembly_production_return_quantity
                    crJson['assembly_production_return_weight'] = CustomerMaterialReturnElement.assembly_production_return_weight
                    crJson['transfer_return_quantity'] = CustomerMaterialReturnElement.transfer_return_quantity
                    crJson['transfer_return_weight'] = CustomerMaterialReturnElement.transfer_return_weight
                    crJson['transfer_receipt_quantity'] = CustomerMaterialReturnElement.transfer_receipt_quantity
                    crJson['transfer_receipt_weight'] = CustomerMaterialReturnElement.transfer_receipt_weight
                    crJson['outsources_out_quantity'] = CustomerMaterialReturnElement.outsources_out_quantity
                    crJson['outsources_out_weight'] = CustomerMaterialReturnElement.outsources_out_weight
                    crJson['outsources_in_quantity'] = CustomerMaterialReturnElement.outsources_in_quantity
                    crJson['outsources_in_weight'] = CustomerMaterialReturnElement.outsources_in_weight
                    crJson['outsources_rejection_quantity'] = CustomerMaterialReturnElement.outsources_rejection_quantity
                    crJson['outsources_rejection_weight'] = CustomerMaterialReturnElement.outsources_rejection_weight
                    crJson['loan_receipt_quantity'] = CustomerMaterialReturnElement.loan_receipt_quantity
                    crJson['loan_receipt_weight'] = CustomerMaterialReturnElement.loan_receipt_weight
                    crJson['loan_return_quantity'] = CustomerMaterialReturnElement.loan_return_quantity
                    crJson['loan_return_weight'] = CustomerMaterialReturnElement.loan_return_weight
                    crJson['cancel_quantity'] = CustomerMaterialReturnElement.cancel_quantity
                    crJson['cancel_weight'] = CustomerMaterialReturnElement.cancel_weight
                    crJson['difference_quantity'] = CustomerMaterialReturnElement.difference_quantity
                    crJson['difference_weight'] = CustomerMaterialReturnElement.difference_weight
                    crJson['godown_id'] = CustomerMaterialReturnElement.godown_id
                    crJson['godown_section_id'] = CustomerMaterialReturnElement.godown_section_id
                    crJson['godown_section_beans_id'] = CustomerMaterialReturnElement.godown_section_beans_id
                    crJson['department_id'] = CustomerMaterialReturnElement.department_id
                    crJson['customer_item_status'] = CustomerMaterialReturnElement.customer_item_status
                    crJson['remark'] = CustomerMaterialReturnElement.remark
                    crJson['customer_goods_receipt_no'] = CustomerMaterialReturnElement.customer_goods_receipt_no
                    crJson['customer_goods_receipt_date'] = CustomerMaterialReturnElement.customer_goods_receipt_date
                    crJson['customer_goods_receipt_version'] = CustomerMaterialReturnElement.customer_goods_receipt_version
                    crJson['created_by'] = UserName
                    crJson['modified_by'] = keyForViewUpdate === 'update' ? UserName : ''
                    crJson['expiry_date'] = CustomerMaterialReturnElement.expiry_date
                    crJson['prev_return_quantity'] = CustomerMaterialReturnElement.prev_return_quantity
                    crJson['prev_return_weight'] = CustomerMaterialReturnElement.prev_return_weight
                    json.TransDetailData.push(crJson)
                }
                json.commonIds.company_id = COMPANY_ID
                // // json.commonIds.customer_material_return_no = keyForViewUpdate === '' ? latestCustomerMaterialReturnNo : txt_customer_material_return_no
                // json.commonIds.customer_material_retun_version = txt_customer_material_return_version
                // json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                console.log('json', json);
                const formData = new FormData();
                formData.append(`CustomerMaterialReceiptData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptMasterCustomer/FnUpdateCustomerStockDetailsRecord`, requestOptions)
                const responce = await apicall.json();
                if (responce.success === "0") {
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = comboDataFunc.current.evitCache();
                    console.log(evitCache);
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error", error);
            navigate('/Error')
        }
    }
    // --------------------------------------- Material Reciept NOtes Save Functionality End------------------------------------------------------------------
    // --------------------------------------- FnChechkUpdateResponce Functionality End------------------------------------------------------------------
    const FnCheckUpdateResponce = async () => {
        debugger
        try {
            if (idList !== '') {
                fin_year = idList.financial_year;
                customer_goods_receipt_version = idList.customer_goods_receipt_version;
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptMasterCustomer/FnShowAllDetailsAndMastermodelRecords/${customer_goods_receipt_version}/${fin_year}/${COMPANY_ID}?customer_goods_receipt_no=${idList.customer_goods_receipt_no}`)
                const responce = await apiCall.json();
                console.log('Customer Materails Details: ', responce);
                let customerMaterialreturnMasterData = responce.CustomerMaterialReturnMasterRecord;
                if (customerMaterialreturnMasterData !== null && customerMaterialreturnMasterData !== "") {
                    setCustomerMaterialRetunData(responce.GoodReceiptCustomerDetailsRecord)

                    // setmaterialType(customerMaterialreturnMasterData.customer_material_return_type_id)
                    // setcustomer_material_return_version(customerMaterialreturnMasterData.customer_material_retun_version)
                    // setcustomer_material_return_no(customerMaterialreturnMasterData.customer_material_return_no)
                    // setMaterialreturnDate(customerMaterialreturnMasterData.customer_material_return_date)
                    // setCustomerChallanNo(customerMaterialreturnMasterData.customer_challan_no)
                    // setdtCustomerChallanDate(customerMaterialreturnMasterData.customer_challan_Date)
                    // setExpectedBranchId(customerMaterialreturnMasterData.expected_branch_id)
                    setCustomerId(responce.GoodReceiptCustomerDetailsRecord[0].customer_id)
                    await comboOnChange('customer');
                    setCustomerOrderNo(responce.GoodReceiptCustomerDetailsRecord[0].customer_order_no)
                    await comboOnChange('CustomerOrder');

                    // setCustomerStateId(customerMaterialreturnMasterData.customer_state_id)
                    // comboOnChange('ExpectedbranchId')
                    // setCustomerOrderNo(customerMaterialreturnMasterData.customer_order_no)
                    // await comboOnChange('CustomerOrder');
                    // setCustomerOrderDate(customerMaterialreturnMasterData.customer_order_date)
                    // setQabyId(customerMaterialreturnMasterData.qa_by_id)
                    // setQadate(customerMaterialreturnMasterData.qa_date)
                    // setgrandtotal(customerMaterialreturnMasterData.grand_total)
                    // setCustomerMaterialReturnStatus(customerMaterialreturnMasterData.customer_material_return_status)
                    // setlrNo(customerMaterialreturnMasterData.lr_no)
                    // setlrDate(customerMaterialreturnMasterData.lr_date)
                    // setEbwNo(customerMaterialreturnMasterData.ewb_no)
                    // setEbwDate(customerMaterialreturnMasterData.ewb_valid_till)
                    // setVehicleNo(customerMaterialreturnMasterData.vehicle_no)
                    // setRemark(customerMaterialreturnMasterData.remark)
                    // setIsActive(customerMaterialreturnMasterData.is_active)

                    // if (responce.CustomerMaterialReturnDetailsRecords != null) {
                    //     setCustomerMaterialRetunData(prevArray => {
                    //         return responce.CustomerMaterialReturnDetailsRecords.map(item => {
                    //             let prevReturnWeight = item.product_material_return_quantity * item.product_material_std_weight;
                    //             return {
                    //                 ...item,
                    //                 prev_return_quantity: item.customer_return_quantity,
                    //                 prev_return_weight: validateNumberDateInput.current.decimalNumber(JSON.stringify(prevReturnWeight), 4),
                    //                 product_material_stock_quantity: parseInt(item.product_material_return_quantity + item.product_material_stock_quantity),
                    //                 // customer_return_quantity: item.product_material_return_quantity
                    //             };
                    //         });
                    //     });
                    // }
                }
            }
        } catch (error) {
            console.log('error: ', error);
            navigate('/Error')

        }

    }

    const deleteRecords = async () => {
        debugger
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtCustomerMaterialReturnMaster/FnDeleteRecord/${txt_customer_material_return_version}/${COMPANY_ID}/${UserName}?customer_material_return_no=${txt_customer_material_return_no}`, method)
            const responce = await deleteApiCall.json();
            console.log("Customer Material Return Entry: ", responce);
            if (responce.success == '1') {
                setShow(false)
                moveToListing();
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const deleteshow = () => {
        setShow(true)
    }

    // --------------------------------------- FnChechkUpdateResponce Functionality End------------------------------------------------------------------




    const FnValidateCustomerMaterialReturnNote = async () => {
        let headerDataIsValid = await validate.current.validateForm("CustomerMaterialReturnHeaderFormId");
        if (!headerDataIsValid) { return false; }
        let custGrnDetailsIsValid = true;
        if (CustomerMaterialRetunData.length === 0) {
            setErrMsg('Please add atleast one material...');
            setShowErrorMsgModal(true);
            return custGrnDetailsIsValid = false;
        }
        else {
            const tableRows = $('#mrnDetailsTbl tbody tr');
            custGrnDetailsIsValid = true;
            tableRows.each(function () {
                const returnQty = parseFloat($(this).find('input[id^="customer_return_quantity_"]').val());
                if (isNaN(returnQty) || returnQty === "") {
                    $(this).find('input[id^="customer_return_quantity_"]').parent().attr('data-tip', 'Please enter a valid Quantity...!');
                    $(this).find('input[id^="customer_return_quantity_"]').focus();
                    custGrnDetailsIsValid = false;
                    return false;
                }
            });
            return custGrnDetailsIsValid;
        }
    }

    // const generateMaterialReturnNo = async () => {
    //     const ptShortName = materialtypeOptions.find(item => item.field_id === parseInt($('#cmb_material_type').val()));
    //     const customerMaterialReturnNo = await generateAutoNoAPiCall.current.generateTAutoNo("pt_customer_material_return_master", "customer_material_return_no", ptShortName.product_type_short_name, 'CI', "6");
    //     setcustomer_material_return_no(customerMaterialReturnNo);
    //     return customerMaterialReturnNo;
    // }

    const fillComobos = async () => {

        try {
            // resetGlobalQuery();
            // globalQuery.columns.push("field_id");
            // globalQuery.columns.push("field_name");
            // globalQuery.columns.push("product_type_short_name");
            // globalQuery.table = "smv_product_type"
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["RM", "FG"] });

            // comboDataFunc.current.fillFiltersCombo(globalQuery)
            //     .then(getProductType => {
            //         setmaterialtypeOptions(getProductType)
            //         console.log("Product Type List: ", getProductType);
            //     });

            const customerOrderIdApiCall = await comboDataFunc.current.fillMasterData("cmv_customer", "", "")
            setCustomerOptions(customerOrderIdApiCall)

            resetGlobalQuery();
            globalQuery.columns = ["company_branch_id", "company_branch_name", "branch_state_id", "state_name", 'is_sez']
            globalQuery.table = "cmv_company_branch";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });

            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(getExpectedtBranchs => {
                    setExpectedBranchList(getExpectedtBranchs)
                });

            comboDataFunc.current.fillMasterData("cmv_employee", "", "")
                .then(getEmployees => {
                    setEmployeeList(getEmployees)
                });

            comboDataFunc.current.fillMasterData("smv_product_rejection_parameters", "", "")
                .then(getRejectionParameters => {
                    setRejectionParameterList(getRejectionParameters)
                });

            comboDataFunc.current.fillMasterData("cmv_godown", "", "")
                .then(getGodownsList => {
                    setGodownsList(getGodownsList)
                });

            // godownsList list for details table
            comboDataFunc.current.fillMasterData("cmv_godown_section", "", "")
                .then(getGodownsSectionlist => {
                    setGodownsSectionList(getGodownsSectionlist)
                });

            // godownsList list for details table
            comboDataFunc.current.fillMasterData("cmv_godown_section_beans", "", "")
                .then(getGodownsSectionbeansList => {
                    setGodownsSectionBeanList(getGodownsSectionbeansList)
                });

            // unit list for details table
            comboDataFunc.current.fillMasterData("smv_product_unit", "", "")
                .then(getUnitList => {
                    setUnitList(getUnitList)
                });

            // unit list for details table
            comboDataFunc.current.fillMasterData("cmv_department", "", "")
                .then(getDepartmentList => {
                    setDepartmentList(getDepartmentList)
                });

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }



    const comboOnChange = async (key) => {
        debugger
        switch (key) {
            // case 'challan_no':
            //     let challan_no = document.getElementById('txt_customer_challan_no').value
            //     setCustomerChallanNo(challan_no)

            //     if (challan_no === '') {
            //         $('#error_txt_customer_challan_no').text("Please fill this field...!")
            //         $('#error_txt_customer_challan_no').show()
            //     } else {
            //         $('#error_txt_customer_challan_no').hide()
            //     }
            //     break;

            case 'customer':
                let customerVal = document.getElementById("cmb_customer_id").value;
                setCustomerId(customerVal);
                if ($('#cmb_customer_id').val() === '0') {
                    localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master' }))
                    const newTab = window.open('/Masters/Customer', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                else if (customerVal !== "" & customerVal !== "0") {
                    resetGlobalQuery();
                    globalQuery.columns.push("customer_order_no");
                    globalQuery.columns.push("customer_order_Date");
                    globalQuery.columns.push("customer_state_id");
                    globalQuery.columns.push("customer_state_name");
                    globalQuery.table = "mtv_sales_order_master_trading_summary"
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customerVal });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "sales_order_status", operator: "=", value: 'A' });

                    const getCustomerOrdersApiCall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                    if (getCustomerOrdersApiCall.length !== 0) {
                        setCustomerOrdOpts(getCustomerOrdersApiCall);
                        setCustomerStateId(getCustomerOrdersApiCall[0].customer_state_id);
                        setCustomerStateName(getCustomerOrdersApiCall[0].customer_state_name);
                    } else {
                        setCustomerOrdOpts([]);
                        setCustomerStateId('');
                        setCustomerStateName('')
                        setCustomerOrderNo('')
                        setCustomerOrderDate('')
                    }
                    FnGetCustomersContactPersonsDetails(parseInt(customerVal));
                } else {
                    setCustomerOrdOpts([]);
                    setCustomerStateId('');
                    setCustomerOrderDate('');
                    setCustomerOrderNo('')
                    setCustomerOrderDate('')
                    setCustContactDetails([])
                }
                break;

            case 'CustomerOrder':
                debugger
                // const customerValue = document.getElementById("cmb_customer_id").value;

                let customerOrderNoVal = document.getElementById("cmb_customer_order_no").value;
                if (customerOrderNoVal !== null && customerOrderNoVal !== '') {
                    resetGlobalQuery();
                    globalQuery.columns.push("customer_contacts_ids");
                    globalQuery.table = "mtv_sales_order_master_trading_summary";
                    globalQuery.conditions.push({ field: "customer_order_no", operator: "=", value: customerOrderNoVal, });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                    const custContactsidsApiCall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);

                    FnSetExistingCustomerContactPersonIds(custContactsidsApiCall[0])
                    let selectedOption = $('#cmb_customer_order_no').find('option:selected');
                    let selectedOrderDate = selectedOption.attr('ord_date');
                    setCustomerOrderDate(selectedOrderDate);
                    await loadCustGoodsReceiptNos()
                } else {
                    setCustomerOrderDate('');
                    setCustomerMaterialRetunData([]);
                }
                break;

            // case 'ExpectedbranchId':
            //     let expectedBranchValue = parseInt(document.getElementById('cmb_expected_branch_id').value);
            //     setExpectedBranchId(expectedBranchValue)
            //     if (expectedBranchValue === 0) {
            //         const newTab = window.open('Masters/Company', '_blank');
            //         if (newTab) {
            //             newTab.focus();
            //         }
            //     }
            //     break;
            // case 'GoodReceiptStatus':
            //     let goodReceiptStatus = document.getElementById('goods_receipt_status')
            //     if (goodReceiptStatus !== '') {
            //         $('#error_goods_receipt_status').hide()
            //     }
            //     break;

            // case 'materialTypeId':
            //     var materialTypeId = document.getElementById('cmb_material_type').value;
            //     if (materialTypeId === "0") {
            //         sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            //         setHeaderName('Material Type')
            //         setShowAddRecModal(true)
            //         setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
            //     }
            //     setmaterialType(materialTypeId)
            //     if (materialTypeId !== "0" && materialTypeId !== "") {
            //         $('#error_cmb_indent_type').hide();
            //         await generateMaterialReturnNo();
            //     } else {
            //         setcustomer_material_return_no("0")
            //     }
            //     break;

            default:
                break;
        }
    }

    const FnSetExistingCustomerContactPersonIds = (contactIds) => {
        if (contactIds !== '') {
            const distinctCustomerContsIds = [...new Set(contactIds.customer_contacts_ids.split(":"))];
            $('input[name="selectCustContactPerson"]').prop('checked', false);
            distinctCustomerContsIds.forEach((id) => {
                $('input[name="selectCustContactPerson"][value="' + id + '"]').prop('checked', true);
            });
        } else {
            $('input[name="selectCustContactPerson"]').prop('checked', false);
        }
    }



    const FnGetCustomersContactPersonsDetails = async (customerId) => {
        try {
            if (customerId != null && customerId != undefined && customerId != '' && customerId != NaN) {
                resetGlobalQuery();
                globalQuery.columns.push("customer_id");
                globalQuery.columns.push("customer_branch_name");
                globalQuery.columns.push("cust_branch_id");
                globalQuery.columns.push("customer_contact_id");
                globalQuery.columns.push("cust_contact_person");
                globalQuery.columns.push("cust_contact_no");
                globalQuery.columns.push("cust_alternate_contact");
                globalQuery.columns.push("cust_email_id");
                globalQuery.columns.push("cust_alternate_EmailId");
                globalQuery.table = "cmv_customer_contacts"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "customer_id", operator: "=", value: parseInt(customerId) });
                const getContactDtlsApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);

                if (getContactDtlsApiCall.length !== 0) {
                    let column = [];
                    let columnHeads = Object.keys(getContactDtlsApiCall[0]);
                    for (let colKey = 0; colKey < columnHeads.length; colKey++) {
                        if (colKey === 0) {
                            column.push({
                                Headers: <div className="col-1"><input type='checkbox' class="form-check-input me-1" name="selectAllCustContact" id="selectAllCustContact" disabled />Select </div>,
                                accessor: "Action",
                                Cell: row => (
                                    <div className="text-center">
                                        <input type='checkbox' class="form-check-input selectCustContactPerson" name="selectCustContactPerson" id={'selectCustContactPerson_' + row.row.original.customer_contact_id} contact_personId={row.row.original.customer_contact_id} value={row.row.original.customer_contact_id} disabled />
                                    </div>
                                ),
                            });
                        }
                        if (!columnHeads[colKey].includes('customer_id') && !columnHeads[colKey].includes('customer_contact_id') && !columnHeads[colKey].includes('cust_branch_id')) {
                            column.push({ Headers: columnHeads[colKey], accessor: columnHeads[colKey] });
                        }
                    }
                    setCustContactDetailsCols(column);
                    setCustContactDetails(getContactDtlsApiCall);
                } else {
                    setCustContactDetailsCols([]);
                    setCustContactDetails([]);
                }
            } else {
                setCustContactDetailsCols([]);
                setCustContactDetails([]);
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const handleInputChange = async (currentRowData, event) => {
        debugger
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        let clickedColName = event.target.getAttribute('Headers');
        let enteredValue = event.target.value;
        let returnItemsQtyinp = document.querySelector('#' + event.target.id);
        // let returnItemsQtyinp = "";
        if (clickedColName === 'customer_return_quantity') {
            // returnItemsQtyinp = `#customer_material_return_quantity_${arrayIndex}`
            const AcceptedQty = currentRowData.customer_material_accepted_quantity
            const stockQty = currentRowData.product_material_stock_quantity

            if (event.target.value.trim() !== "") {
                delete returnItemsQtyinp.parentElement.dataset.tip;
                if (AcceptedQty > parseInt(event.target.value) && parseInt(event.target.value) !== 0) {
                    currentRowData.return_item_status = "I";
                } else if (AcceptedQty == parseInt(event.target.value)) {
                    currentRowData.return_item_status = "C";
                } else if (event.target.value == 0) {
                    currentRowData.return_item_status = "P";
                }

                //Qty cant exceed stock
                if (stockQty >= AcceptedQty) {
                    if (AcceptedQty < event.target.value) {
                        returnItemsQtyinp.parentElement.dataset.tip = 'Return quantity cant exceed Accepted quantity...!';
                        currentRowData[clickedColName] = '';
                        fnCalculateItemQtyandWeight(currentRowData)
                    } else {
                        delete returnItemsQtyinp.parentElement.dataset.tip;
                        currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(JSON.stringify(event.target.value), 4);
                        fnCalculateItemQtyandWeight(currentRowData)
                    }
                }
                else if (AcceptedQty >= stockQty) {
                    if (stockQty < event.target.value) {
                        returnItemsQtyinp.parentElement.dataset.tip = 'Return quantity cant exceed stock quantity...!';
                        currentRowData[clickedColName] = '';
                        fnCalculateItemQtyandWeight(currentRowData)
                    } else {
                        delete returnItemsQtyinp.parentElement.dataset.tip;
                        currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(JSON.stringify(event.target.value), 4);
                        fnCalculateItemQtyandWeight(currentRowData)
                    }
                }
            } else if (event.target.value.trim() == '' || event.target.value.trim() == " ") {
                currentRowData[clickedColName] = '';
                returnItemsQtyinp.parentElement.dataset.tip = 'Please enter valid return Quantity...!';
                fnCalculateItemQtyandWeight(currentRowData)
            }
        } else if (clickedColName === 'return_remark') {
            // returnItemsQtyinp = `#return_remark${arrayIndex}`
            if (event.target.value !== '') {
                currentRowData[clickedColName] = event.target.value;
                delete returnItemsQtyinp.parentElement.dataset.tip;
            } else {
                currentRowData[clickedColName] = "";
            }
        }
        else {
            [clickedColName] = '';
        }
        const detailData = [...CustomerMaterialRetunData]

        detailData[arrayIndex] = currentRowData
        setCustomerMaterialRetunData(detailData);

        localStorage.setItem('filteredMaterialData', JSON.stringify(detailData));
        calculateGrandTotal(detailData)
        fnManageReturnStatus(detailData)                   // Set that updated data in table data hook;

    };


    const fnManageReturnStatus = (detailData) => {
        const item = detailData.find(item => item.return_item_status === 'P' || item.return_item_status === 'I');
        if (item) {
            setCustomerMaterialReturnStatus(item.return_item_status);
        } else {
            setCustomerMaterialReturnStatus('C');
        }
    };


    const fnCalculateItemQtyandWeight = (currentRowData) => {
        debugger
        let returnWeight = 0;
        const materialstdweight = currentRowData.product_material_std_weight
        const returnQty = currentRowData.customer_return_quantity
        if (returnQty != '') {
            returnWeight = materialstdweight * returnQty
            currentRowData['customer_return_weight'] = validateNumberDateInput.current.decimalNumber(returnWeight.toString(), 4);
        } else {
            currentRowData['customer_return_weight'] = 0;
        }
        return currentRowData;
    }



    const calculateGrandTotal = (bomfilted_data) => {
        let basicTotal = bomfilted_data.reduce((total, item) => total + parseFloat(item.customer_material_item_amount), 0);
        setgrandtotal(basicTotal)
    }


    const validateFields = () => {
        validate.current.validateFieldsOnChange('CustomerMaterialReturnHeaderFormId')
        // validate.current.validateFieldsOnChange('CustomerMaterialReturnFooterFormId')
    }

    function scrollToTableRow() {
        var keywords = $("#txt-detail-data-highliter").val();
        var table = document.getElementById('mrnDetailsTbl');
        var rows = table.getElementsByTagName('tr');

        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
                var w = $(window);
                var row = $('#mrnDetailsTbl').find('tr').eq(trNum);
                if (row.length) {
                    w.scrollTop(row.offset().top - (w.height() / 2));
                }
                $(row).find('td').each(function () {
                    $(this).addClass('highlight');
                });
                removeOtherTrHighlightClass(trNum)
                break;
            } else {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }
    const removeOtherTrHighlightClass = (rowNum) => {
        var table = document.getElementById('mrnDetailsTbl');
        var rows = table.getElementsByTagName('tr');
        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (trNum !== rowNum) {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }

    const clearFormFields = async () => {
        $('#error_txt_customer_challan_no').hide()
        setCustomerOrdOpts([])
        // setmaterialType('')
        // setcustomer_material_return_no('')
        // setMaterialreturnDate('')
        // setcustomer_material_return_version('')
        // setCustContactDetails([])
        setCustomerOrderNo('')
        setCustomerOrderDate('')
        setQabyId(UserId)
        setQadate(today)
        setCustomerId('')
        setCustomerGoodsreciptsNoList('')
        setCheckedCustomerGoodsReceiptNoLength('')
        setCustContactDetails([])
        // setgrandtotal('')
        // setlrNo('');
        // setlrDate('')
        // setVehicleNo('')
        // setRemark('')
        // setIsActive(true)
        // setCustomerChallanNo('')
        // setdtCustomerChallanDate('')
        setExpectedBranchId(COMPANY_BRANCH_ID)
        setCustomerStateId('')
        setCustomerStateName('')
        localStorage.removeItem('filteredMaterialData')
        setCustomerMaterialRetunData([])
    }

    const moveToListing = () => {
        const Path = compType === 'Register' ? '/Transactions/TCustomerMaterialReturn/TCustomerMaterialReturnListing/reg' : `/Transactions/TCustomerMaterialReturn/TCustomerMaterialReturnListing`;
        navigate(Path);
    }


    // For update the combobox data after add new record.
    const FnUpdateComboBoxData = async (key) => {
        if (keyForViewUpdate === 'view') {
            return false;
        }
        switch (key) {
            case 'Customer':
                const customerOrderIdApiCall = await comboDataFunc.current.fillMasterData("cmv_customer", "", "")
                setCustomerOptions(customerOrderIdApiCall)
                break;
            default:
                break;
        }
    }
    //--------------------------------- Document List Starts--------------------------------------------------

    // // Fn for get document list
    // const showDocumentRecords = async () => {
    //     try {
    //         if (COMPANY_ID !== null) {
    //             const res = await fetch(
    //                 `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${documentGroup}&group_id=${txt_customer_material_return_no !== 0 ? txt_customer_material_return_no.replaceAll('/', '_') : null}`
    //             );
    //             const resp = await res.json();
    //             if (resp.content.length > 0) {
    //                 setDocumentData(resp.content);
    //             }
    //         }
    //     } catch (error) {
    //         console.log("error: ", error)
    //     }
    // }

    // // Fn for get document table
    // const renderDocumentTable = useMemo(() => {
    //     return <>
    //         <Table className="erp_table " id='document-table' responsive bordered striped>
    //             <thead className="erp_table_head">
    //                 <tr>
    //                     <th className="erp_table_th">Group Id</th>
    //                     <th className="erp_table_th">Document Group</th>
    //                     <th className="erp_table_th">Document Name</th>
    //                     <th className="erp_table_th">Registration No</th>
    //                     <th className="erp_table_th">Registration Date</th>
    //                     <th className="erp_table_th">Renewal Date</th>
    //                     <th className="erp_table_th">Document Path</th>
    //                     <th className="erp_table_th">Remark</th>
    //                     <th className="erp_table_th">File Name</th>
    //                 </tr>
    //             </thead>
    //             <tbody>
    //                 {
    //                     docData.map((docItem, Index) =>
    //                         <tr rowIndex={Index}>
    //                             <td className="erp_table_td"> {docItem.group_id} </td>
    //                             <td className="erp_table_td">{docItem.document_group}</td>
    //                             <td className="erp_table_td">{docItem.document_name}</td>
    //                             <td className="erp_table_td ">{docItem.document_registration_no}</td>
    //                             <td className="erp_table_td ">{docItem.document_registration_date}</td>
    //                             <td className="erp_table_td ">{docItem.document_renewal_date}</td>
    //                             <td className="erp_table_td ">{docItem.document_path}</td>
    //                             <td className="erp_table_td ">{docItem.remark}</td>
    //                             <td className="erp_table_td ">
    //                                 <MDTypography component="label" className="erp-form-label" variant="button" id="logoFile" fontWeight="regular" color="info" >
    //                                     <Link to="#" onClick={() => { fetchDocument(docItem) }}>
    //                                         {docItem.file_name}
    //                                     </Link></MDTypography>
    //                             </td>
    //                         </tr>
    //                     )
    //                 }
    //             </tbody>
    //         </Table>
    //     </>
    // }, [docData]);



    // Fn for get document download
    // const fetchDocument = async (docItem) => {
    //     try {
    //         const formData = new FormData()
    //         const data = {
    //             document_group: docItem.documentGroup,
    //             group_id: docItem.group_id,
    //             document_path: docItem.document_path
    //         }
    //         formData.append(`getFile`, JSON.stringify(data))
    //         const requestOptions = {
    //             method: 'POST',
    //             body: formData
    //         };

    //         const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
    //         const blob = await getDocApiCall.blob()

    //         const url = window.URL.createObjectURL(new Blob([blob]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', `${docItem.file_name}`,);
    //         document.body.appendChild(link);

    //         link.click();
    //         link.parentNode.removeChild(link);


    //     } catch (error) {
    //         console.log("error: ", error)
    //     }
    // }
    //--------------------------------- Document List Ends------------------------------------------------------------------------------------------------
    // const FnLoadAccordionData = async (eventKey) => {
    //     switch (eventKey) {
    //         case 'documentList':
    //             await showDocumentRecords();
    //             break;
    //         default:
    //             break;
    //     }
    // }

    // ----------------------------------------------------------------------------------
    // Hide show customer recipt no's
    const FnToggleCustomerGoodsReceiptNoList = () => {
        debugger
        var checkboxes = document.getElementById("Customer-GoodsReceiptNo-ul");
        const items = document.querySelectorAll(".customer_goods_receipt_no");
        if (!expanded) {
            if (items.length !== 0) {
                checkboxes.style.display = "block";
                expanded = true;
            } else {
                setErrMsg(`No Customer Goods Receipt No's Found ,against Customer Order No`)
                setShowErrorMsgModal(true)
            }
        } else {
            checkboxes.style.display = "none";
            expanded = false;
        }
    }




    function rendercustGoodsreciptNoCheckbox(item, index) {
        if (index === 0) {
            return (
                <>
                    <li className="item customer_goods_receipt_no" key={`All-${index}`}>
                        <span className="checkbox">
                            <input
                                type="checkbox" id="all_customer_goods_receipt_no_id" value={index} className="erp_radio_button filterCheckBox" onChange={(event) => FncustGoodsreciptNoSelection(event.target.value)} disabled={keyForViewUpdate === 'view' ? true : false} optional='optional' />
                        </span>
                        <span className="item-text">All</span>
                    </li>
                    <li className="item customer_goods_receipt_no" key={index}>
                        <span className="checkbox">
                            <input type="checkbox" name="customerGoodsReceiptNoCheckBox"
                                value={item.customer_goods_receipt_no} id={`filterCheck-${item.customer_goods_receipt_no}`} className="erp_radio_button customerGoodsReceiptNoCheckBox" onChange={(event) => FncustGoodsreciptNoSelection(event.target.value)} disabled={keyForViewUpdate === 'view' ? true : false} optional='optional' />
                        </span>
                        <span className="item-text">{item.customer_goods_receipt_no}</span>
                    </li>
                </>
            );
        }
        return (
            <li className="item customer_goods_receipt_no" key={index}>
                <span className="checkbox">
                    <input type="checkbox" fieldName={item.customer_goods_receipt_no} name="customerGoodsReceiptNoCheckBox" value={item.customer_goods_receipt_no} id={`filterCheck-${item.customer_goods_receipt_no}`} className="erp_radio_button customerGoodsReceiptNoCheckBox" onChange={(event) => FncustGoodsreciptNoSelection(event.target.value)} disabled={keyForViewUpdate === 'view' ? true : false} />
                </span>
                <span className="item-text">{item.customer_goods_receipt_no}</span>
            </li>
        );
    }



    const FncustGoodsreciptNoSelection = (customer_goods_receipt_no) => {
        if (parseInt(customer_goods_receipt_no) === 0) {
            if ($("#all_customer_goods_receipt_no_id").is(":checked") === false) {
                selectAllCustGoodsReceiptNo('uncheck');
            } else {
                selectAllCustGoodsReceiptNo('check');
            }
        }

        //Check Partiallly selected all or not if selected then check the check all radio of dispatch details
        const totalChkBoxes = document.querySelectorAll('input[name=customerGoodsReceiptNoCheckBox]').length;
        const totalChkBoxesChked = document.querySelectorAll('input[name=customerGoodsReceiptNoCheckBox]:checked').length;
        if (totalChkBoxes === totalChkBoxesChked) {
            document.getElementById('all_customer_goods_receipt_no_id').checked = true;
        } else if (totalChkBoxes > totalChkBoxesChked) {
            document.getElementById('all_customer_goods_receipt_no_id').checked = false;
        }
        setCheckedCustomerGoodsReceiptNoLength(totalChkBoxesChked)
    }



    const selectAllCustGoodsReceiptNo = (key) => { // fn to check all checkbox of custGoodsreciptNo 
        const fliterCheckbox = document.getElementsByName('customerGoodsReceiptNoCheckBox');
        if (key === 'check') {
            for (let chkAllCheckbox of fliterCheckbox) {
                document.getElementById(`${chkAllCheckbox.id}`).checked = true;
            }
        } else {
            for (let unchkAllCheckbox of fliterCheckbox) {
                document.getElementById(`${unchkAllCheckbox.id}`).checked = false;
            }
        }
    }


    const loadCustGoodsReceiptNos = async () => {
        debugger
        let customerOrderNoVal = document.getElementById("cmb_customer_order_no").value;
        resetGlobalQuery();
        globalQuery.columns.push("customer_goods_receipt_no");
        globalQuery.table = "ptv_goods_receipt_customer_summary";
        globalQuery.conditions.push({ field: "customer_goods_receipt_status", operator: "IN", values: ['C', 'I'], });
        globalQuery.conditions.push({ field: "customer_order_no", operator: "=", value: customerOrderNoVal, });
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
        const custGoodsreciptNoApiCall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
        setCustomerGoodsreciptsOption(custGoodsreciptNoApiCall)

        let listItems = custGoodsreciptNoApiCall.map(rendercustGoodsreciptNoCheckbox);
        setCustomerGoodsreciptsNoList(listItems)
    }


    const showCustGoodsReceiptDetails = async (storedCustGoodsReceiptNoData) => {
        debugger
        console.log("storedCustGoodsReceiptNoData", storedCustGoodsReceiptNoData);
        let customer_goods_receipt_nos = [];
        $("input:checkbox[name=customerGoodsReceiptNoCheckBox]:checked").each(function () {
            customer_goods_receipt_nos.push($(this).val())
        })
        const customerValue = document.getElementById("cmb_customer_id").value;
        let customerOrderNoVal = document.getElementById("cmb_customer_order_no").value;


        resetGlobalQuery();
        globalQuery.columns = ['*'];
        globalQuery.table = "ptv_goods_receipt_customer_details";
        globalQuery.conditions.push({ field: "customer_goods_receipt_status", operator: "IN", values: ['C', 'I'], });
        globalQuery.conditions.push({ field: "customer_goods_receipt_no", operator: "IN", values: customer_goods_receipt_nos, });
        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customerValue, });
        globalQuery.conditions.push({ field: "customer_order_no", operator: "=", value: customerOrderNoVal, });
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
        const custMaterialReturnDetailsidsApiCall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
        if (custMaterialReturnDetailsidsApiCall != null) {
            console.log("custMaterialReturnDetailsidsApiCall", custMaterialReturnDetailsidsApiCall);
            const orderdata = custMaterialReturnDetailsidsApiCall.map(item => {

                let material_return_quantity = 0;
                let return_weight = 0;
                let returnItemStatus;
                if (parseInt(item.customer_material_accepted_quantity) > parseInt(item.product_material_stock_quantity)) {
                    material_return_quantity = item.product_material_stock_quantity;
                    return_weight = material_return_quantity * item.product_material_std_weight
                } else if (parseInt(item.customer_material_accepted_quantity) === parseInt(item.product_material_stock_quantity)) {
                    material_return_quantity = item.customer_material_accepted_quantity;
                    return_weight = material_return_quantity * item.product_material_std_weight
                } else {
                    material_return_quantity = item.customer_material_accepted_quantity;
                    return_weight = material_return_quantity * item.product_material_std_weight
                }

                if (material_return_quantity == item.customer_material_accepted_quantity) {
                    returnItemStatus = "C"
                } else if (material_return_quantity == 0) {
                    returnItemStatus = "P"
                } else if (material_return_quantity != 0 && material_return_quantity < item.customer_material_accepted_quantity) {
                    returnItemStatus = "I"
                }

                return {
                    ...item,
                    customer_return_quantity: material_return_quantity,
                    customer_return_weight: validateNumberDateInput.current.decimalNumber(JSON.stringify(return_weight), 4),
                    return_item_status: returnItemStatus,
                    return_remark: '',
                    return_details_id: `${item.customer_goods_receipt_no}:${item.customer_material_id}`
                };
            });
            fnManageReturnStatus(orderdata)
            setCustomerMaterialRetunData(orderdata);
        }

    }
    // ---------------------Save customer Contacts---------------------------------------------------------------
    const FnGetCustomerContactPersonIds = () => {
        let customerContactPersonsIds = '';
        const checkboxes = $('.selectCustContactPerson:checked');
        checkboxes.each(function () {
            customerContactPersonsIds += $(this).val() + ":";
        });
        return customerContactPersonsIds.replace(/:$/, '');
    }
    // ---------------------Save customer Contacts---------------------------------------------------------------

    return (
        <>
            <DashboardLayout>
                <GenerateTAutoNo ref={generateAutoNoAPiCall} />
                <ValidateNumberDateInput ref={validateNumberDateInput} />
                <ComboBox ref={comboDataFunc} />
                <ExcelExport ref={exlsExp} />
                <FrmValidations ref={validate} />
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}
                <form id="CustomerMaterialReturnHeaderFormId">
                    <div className='card p-1'>
                        <div className='card-header text-center py-1'>
                            <label className='erp-form-label-lg text-center'> Customer Material Return {actionType}</label>
                        </div>
                        <div className="row p-1">
                            <div className="col-sm-6 erp_form_col_div">

                                {/* <div className='row'>
                                    <div className='col-sm-4 col-12'>
                                        <Form.Label className="erp-form-label">Material Return Type <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-sm-8 col-12'>
                                        <select id="cmb_material_type" className="form-select form-select-sm" value={cmb_material_type} onChange={() => { comboOnChange('materialTypeId'); validateFields(); }}  >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {materialtypeOptions.length !== 0 ? (
                                                <>
                                                    {materialtypeOptions.map(materialTypeId => (
                                                        <option key={materialTypeId.field_id} value={materialTypeId.field_id}>
                                                            {materialTypeId.field_name}
                                                        </option>
                                                    ))}
                                                </>
                                            ) : null}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_material_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div> */}


                                {/* <div className='row'>
                                    <div className="col-sm-4 col-12">
                                        <Form.Label className="erp-form-label">Customer Return No,Version & Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-8 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id="txt_customer_material_return_no" className="erp_input_field" value={txt_customer_material_return_no} optional='optional' disabled />
                                            </div>
                                            <div className="col-12 col-md-2 pt-md-0 pt-3 pe-md-0 ps-md-1">
                                                <Form.Control type="text" id='txt_customer_material_return_version' className="erp_input_field text-end" value={txt_customer_material_return_version} optional='optional' disabled />
                                            </div>
                                            <div className="col-12 col-md-4 pt-md-0 pt-3 ps-md-1">
                                                <Form.Control type="date" id='dt_customer_material_return_date' className="erp_input_field" value={dt_customer_material_return_date} onChange={e => { setMaterialreturnDate(e.target.value); validateFields(); }} />
                                                <MDTypography variant="button" id="error_dt_customer_material_return_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Customer <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-md-7 col-10'>
                                        <select id="cmb_customer_id" className="form-select form-select-sm" value={cmb_customer_id} onChange={(e) => { setCustomerId(e.target.value); comboOnChange('customer'); validateFields() }}  >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {customerOptions?.map(customer => (
                                                <option value={customer.field_id}>{customer.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className="col">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography >
                                                <MdRefresh onClick={() => { if (keyForViewUpdate !== 'delete' || keyForViewUpdate !== 'view') { FnUpdateComboBoxData('Customer') } }} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Customer Order No & Order Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-8 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <select id="cmb_customer_order_no" className="form-select form-select-sm" value={cmb_customer_order_no} onChange={(e) => { setCustomerOrderNo(e.target.value); comboOnChange('CustomerOrder'); validateFields(); }}>
                                                    <option value="">Select</option>
                                                    {customerOrdOpts.length !== 0 ?
                                                        <>
                                                            {customerOrdOpts?.map(order => (
                                                                <option value={order.customer_order_no} ord_date={order.customer_order_Date}>{order.customer_order_no}</option>
                                                            ))}
                                                        </>
                                                        : ''
                                                    }
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_customer_order_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3  ps-md-1">
                                                <Form.Control type="date" id='customer_order_date' className="erp_input_field" value={customer_order_date} optional='optional' disabled />
                                                <MDTypography variant="button" id="error_customer_order_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    keyForViewUpdate === '' ?
                                        <>
                                            <div className='row'>
                                                <div className="col-sm-4 col-12">
                                                    <Form.Label className="erp-form-label">Customer Goods Receipt No's <span className="required">*</span></Form.Label>
                                                </div>

                                                <div className="col-sm-8 col-12">
                                                    <div className="row">
                                                        <div className={`col-sm-6 col-lg-10 pe-md-0`}>

                                                            <div className="select-btn" onClick={() => FnToggleCustomerGoodsReceiptNoList()} data-optional="optional">
                                                                <span className="form-select form-select-sm">{checkedCustomerGoodsReceiptNoLength !== 0 ? checkedCustomerGoodsReceiptNoLength + ' Selected' : 'Select'}</span>
                                                            </div>
                                                            <ul className="list-items" id="Customer-GoodsReceiptNo-ul">
                                                                {CustomerGoodsreciptsNoList}
                                                            </ul>
                                                        </div>

                                                        {/* <div className="col-12 col-md-6 pt-md-0 pt-3  ps-md-1"   float-end> */}
                                                        <div className="col-12 col-md-6 col-lg-2 pt-md-0 pt-3 ps-md-1">
                                                            <MDButton type="button" id="CustomerGoodsReceiptNo-btn" className={`erp-gb-button float-end ${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={() => showCustGoodsReceiptDetails(storedCustGoodsReceiptNoData)} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' ? true : false}>show</MDButton>
                                                        </div >
                                                    </div>
                                                </div>

                                            </div>

                                        </> : ""
                                }

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Customer State </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="cmb_customer_state_id" getAttribute={cmb_customer_state_id} className="erp_input_field" value={cmb_customer_state_name} disabled='disabled' optional='optional' />
                                        <MDTypography variant="button" id="error_cmb_customer_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                {/* <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Customer Challan No. & Date<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-8 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id="txt_customer_challan_no" className="erp_input_field" value={txt_customer_challan_no} onBlur={e => { comboOnChange("challan_no"); validateFields(); }} onChange={e => { setCustomerChallanNo(e.target.value); validateFields(); }} />
                                                <MDTypography variant="button" id="error_txt_customer_challan_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3  ps-md-1">
                                                <Form.Control type="date" id='dt_customer_challan_Date' className="erp_input_field" value={dt_customer_challan_Date} onChange={e => { setdtCustomerChallanDate(e.target.value); validateFields(); }} />
                                                <MDTypography variant="button" id="error_dt_customer_challan_Date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                            {/* sec row */}
                            <div className="col-sm-6 erp_form_col_div">



                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Expected Branch <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_expected_branch_id" className="form-select form-select-sm" value={cmb_expected_branch_id} onChange={(e) => { comboOnChange('ExpectedbranchId'); validateFields(); }}   >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {expectedBranchList?.map(Expectedbranchid => (
                                                <option value={Expectedbranchid.company_branch_id} is_sez={`${Expectedbranchid.is_sez}`}>{Expectedbranchid.company_branch_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_expected_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Q.A. By & Q.A. Date <span className="required">*</span></Form.Label>
                                    </div>

                                    <div className="col-sm-8 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <select id="cmb_qa_by_id" className="form-select form-select-sm" value={cmb_qa_by_id} onChange={(e) => { setQabyId(e.target.value); validateFields(); }} disabled >
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {employeeList?.map(employee => (
                                                        <option value={employee.field_id}>{employee.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_qa_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3  ps-md-1">
                                                <Form.Control type="date" id='dt_qa_date' className="erp_input_field" value={dt_qa_date} onChange={(e) => { setQadate(e.target.value); validateFields(); }} />
                                                <MDTypography variant="button" id="error_dt_qa_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Material Return Status </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_customer_material_return_status" className="form-select form-select-sm" value={cmb_customer_material_return_status} onChange={(e) => { setCustomerMaterialReturnStatus(e.target.value); comboOnChange('GoodReceiptStatus') }} optional='optional'   >
                                            <option value="P">Pending</option>
                                            <option value="C">Complete</option>

                                        </select>
                                        <MDTypography variant="button" id="error_cmb_customer_material_return_status" className='erp_validation' fontWeight="regular" color="error" style={{ display: 'none' }}>
                                            Please select good receipt status!..
                                        </MDTypography>
                                    </div>

                                </div> */}

                                <div className="row">

                                    <Accordion defaultActiveKey="0" className="mt-3 mt-lg-1">
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header className="erp-form-label-md">Customer's Contact Details</Accordion.Header>
                                            <Accordion.Body className="p-0">

                                                {/* {
                                                    cmb_customer_id !== '' && cmb_customer_id !== '0'
                                                        ? <>
                                                            <div className={`row ps-3 py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' ? 'd-none' : 'display'}`}>

                                                                <div className={` d-flex justify-content-end align-items-center`}>
                                                                    <MDButton type="button" className={`erp-gb-button float-start col-1`} variant="button" fontWeight="regular" onClick={() => {
                                                                        localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                                        localStorage.setItem('customerIDs', JSON.stringify({ customerID: cmb_customer_id, keyForViewUpdate: 'update', compType: 'Master' }))
                                                                    }
                                                                    }>  <Link className={'text-light'} to={{ pathname: "/Masters/Customer" }} target="_blank" >Add</Link></MDButton>
                                                                    <MDButton type="button" className={`erp-gb-button float-end col-1 ms-2`} variant="button" fontWeight="regular" onClick={() => { FnGetCustomersContactPersonsDetails(cmb_customer_id) }}>Refresh</MDButton>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                } */}

                                                {
                                                    custContactDetails.length > 0
                                                        ? <>
                                                            <div className='row'>
                                                                <div className="col-12 erp_table_scroll">
                                                                    <Datatable data={custContactDetails} columns={custContactDetailsCols} />
                                                                </div>
                                                            </div>
                                                        </>
                                                        : <Card className='erp_no_recrd_card' >
                                                            <Card.Body className="text-center">No records found...</Card.Body>
                                                        </Card>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion >
                                    <MDTypography variant="button" id="error_customer_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                </div>
                            </div>
                        </div>
                    </div>


                </form>
                <hr />

                <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="erp-form-label-md">Customer Material Return Details</Accordion.Header>
                        <Accordion.Body>
                            <div className="d-flex row mb-2">
                                <div className="row">

                                    <div className={`col-md-7 col-12 d-flex`}>
                                        <Form.Control type="text" id="txt-detail-data-highliter" style={{ display: 'none' }} className="erp_input_field txt-filter-bom showData" />
                                        <MDButton type="button" className="erp-gb-button erp_MLeft_btn showData" style={{ display: 'none' }} variant="button" onClick={() => scrollToTableRow()}
                                            fontWeight="regular">Search</MDButton>
                                    </div>
                                </div>
                            </div>

                            <div className="erp-Mt-10">
                                <>
                                    {/* {renderDetailsTable} */}
                                    {CustomerMaterialRetunData.length !== 0 ?
                                        <>
                                            <div style={{ overflowX: "hidden", flex: 0 }}>
                                                <Table className="erp_table" id='mrnDetailsTbl' responsive bordered striped>
                                                    <thead className="erp_table_head">
                                                        <tr>
                                                            {/* <th className={`erp_table_th ${keyForViewUpdate === '' ? 'display' : 'd-none'}`}>Actions</th> */}
                                                            <th className="erp_table_th">Sr.no</th>
                                                            <th className="erp_table_th"> Material Name</th>
                                                            <th className="erp_table_th" style={{ paddingRight: "40px" }}> Material Unit</th>
                                                            <th className="erp_table_th"> Customer Goods Receipt No</th>
                                                            <th className="erp_table_th"> Customer Goods Receipt Date</th>
                                                            {/* <th className="erp_table_th"> Customer Goods Receipt Version</th> */}
                                                            <th className="erp_table_th"> Order Quantity</th>
                                                            <th className="erp_table_th"> Accepted Quantity</th>
                                                            <th className="erp_table_th"> stock Quantity</th>
                                                            {/* <th className={`erp_table_th ${keyForViewUpdate === "" ? "d-none" : "display"}`}>Previous Return Quantity</th> */}
                                                            <th className="erp_table_th"> Return Quantity</th>
                                                            <th className="erp_table_th"> Return Remark</th>
                                                            <th className="erp_table_th"> Material rate</th>
                                                            <th className="erp_table_th"> Material Amount</th>
                                                            <th className="erp_table_th"> Rejected Quantity</th>
                                                            <th className="erp_table_th"> Return Material Status</th>
                                                            <th className="erp_table_th"> Rejection Reason</th>
                                                            <th className="erp_table_th" style={{ paddingRight: "100px" }}> Department</th>
                                                            <th className="erp_table_th"> Material Godown </th>
                                                            <th className="erp_table_th"> Material Godown Section</th>
                                                            <th className="erp_table_th"> Material Godown Section Beans</th>
                                                            <th className="erp_table_th"> Expiry Date</th>
                                                            {/* <th className={`erp_table_th ${keyForViewUpdate === "" ? "d-none" : "display"}`}>Previous Return Weight</th> */}
                                                            <th className="erp_table_th"> Return Weight</th>
                                                            <th className="erp_table_th"> Material Weight</th>
                                                            <th className="erp_table_th"> Order Weight</th>
                                                            <th className="erp_table_th"> Accepted Weight</th>
                                                            <th className="erp_table_th"> Rejected Weight</th>
                                                            <th className="erp_table_th" style={{ paddingRight: "100px" }}> Batch No</th>
                                                            <th className="erp_table_th" style={{ paddingRight: "40px" }}>Material Receipt Item Status</th>
                                                            <th className="erp_table_th"> Material Tech. Spect.</th>
                                                            <th className="erp_table_th"> Material Packing</th>
                                                            <th className="erp_table_th"> Material Hsn Code</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {CustomerMaterialRetunData.map((CusRecNoteItem, Index) =>
                                                            <tr rowindex={Index} className="sticky-column">

                                                                <td className="erp_table_td text-end sticky-column">{Index + 1}</td>
                                                                <td className={`erp_table_td  sticky-column`}>{CusRecNoteItem.product_material_name}</td>
                                                                <td className="erp_table_td">
                                                                    <select className="form-select form-select-sm mb-0  " value={CusRecNoteItem.customer_material_unit_id}
                                                                        onChange={(e) => { handleInputChange(CusRecNoteItem, e); }}
                                                                        id={`customer_material_unit_id_${CusRecNoteItem.customer_material_id}`}
                                                                        Headers='customer_material_unit_id' disabled >
                                                                        <option value='0'>Select</option>
                                                                        {unitList.map(item =>
                                                                            <option value={item.field_id}>{item.field_name}</option>
                                                                        )}
                                                                    </select>
                                                                </td>
                                                                <td className="erp_table_td text-end">{CusRecNoteItem.customer_goods_receipt_no}</td>
                                                                <td className="erp_table_td text-end">{validateNumberDateInput.current.formatDateToDDMMYYYY(CusRecNoteItem.customer_goods_receipt_date)}</td>
                                                                {/* <td className="erp_table_td text-end">{CusRecNoteItem.customer_goods_receipt_version}</td> */}
                                                                <td className="erp_table_td text-end">{CusRecNoteItem.customer_material_order_quantity}</td>
                                                                <td className="erp_table_td text-end">{CusRecNoteItem.customer_material_accepted_quantity}</td>
                                                                <td className="erp_table_td text-end">{CusRecNoteItem.product_material_stock_quantity}</td>
                                                                {/* <td className={`erp_table_td text-end ${keyForViewUpdate === "" ? "d-none" : "display"}`}>{CusRecNoteItem.prev_return_quantity}</td> */}
                                                                <td className={`erp_table_td`}>
                                                                    {
                                                                        <input type="text"
                                                                            id={`customer_return_quantity_${Index}`} className="erp_input_field mb-0 text-end" value={CusRecNoteItem.customer_return_quantity}
                                                                            onChange={(e) => { handleInputChange(CusRecNoteItem, e); }} Headers='customer_return_quantity' disabled={(keyForViewUpdate === 'delete' || keyForViewUpdate === 'view')} />
                                                                    }
                                                                </td>

                                                                <td className="erp_table_td">
                                                                    <input type="text" id={`return_remark_${Index}`} className="erp_input_field mb-0" disabled={(keyForViewUpdate === 'view')}
                                                                        value={CusRecNoteItem.return_remark} onChange={(e) => { handleInputChange(CusRecNoteItem, e); }} Headers='return_remark' />
                                                                </td>
                                                                <td className="erp_table_td text-end">{CusRecNoteItem.customer_material_rate}</td>
                                                                <td className="erp_table_td text-end">{CusRecNoteItem.customer_material_item_amount}</td>
                                                                <td className="erp_table_td text-end" rejectedqty={CusRecNoteItem.customer_material_rejected_quantity}>{CusRecNoteItem.customer_material_rejected_quantity}</td>
                                                                <td className="erp_table_td">
                                                                    <select id={`return_item_status_${Index}`} disabled value={CusRecNoteItem.return_item_status}
                                                                        className="form-select form-select-sm mb-0 " Headers='return_item_status' onChange={(e) => { handleInputChange(CusRecNoteItem, e); }}>
                                                                        <option value="P">Pending</option>
                                                                        <option value="I">Partial Return</option>
                                                                        <option value="C">Completed</option>
                                                                    </select>
                                                                </td>
                                                                <td className="erp_table_td">
                                                                    <select className="form-select form-select-sm mb-0" value={CusRecNoteItem.customer_material_rejection_reason_id}
                                                                        onChange={(e) => { handleInputChange(CusRecNoteItem, e); }}
                                                                        id={`customer_material_rejection_reason_id_${Index}`}
                                                                        Headers='customer_material_rejection_reason_id' disabled={keyForViewUpdate !== "view" && keyForViewUpdate !== "delete" ? CusRecNoteItem.customer_material_rejected_quantity <= '0' ? true : false : true}>
                                                                        <option value='0'>Select</option>
                                                                        {rejectionParameterList.map(item =>
                                                                            <option value={item.field_id}>{item.field_name}</option>
                                                                        )}
                                                                    </select>
                                                                </td>
                                                                <td className="erp_table_td">
                                                                    <select className="form-select form-select-sm mb-0  " value={CusRecNoteItem.department_id}
                                                                        onChange={(e) => { handleInputChange(CusRecNoteItem, e); }}
                                                                        id={`department_id_${Index}`}
                                                                        Headers='department_id' disabled >
                                                                        <option value='0'>Select</option>
                                                                        {departmentList.map(item =>
                                                                            <option value={item.field_id}>{item.field_name}</option>
                                                                        )}
                                                                    </select>
                                                                </td>
                                                                <td className="erp_table_td">
                                                                    <select className="form-select form-select-sm mb-0  " value={CusRecNoteItem.godown_id}
                                                                        onChange={(e) => { handleInputChange(CusRecNoteItem, e); }}
                                                                        id={`godown_id_${Index}`}
                                                                        Headers='godown_id' gdDetailsId={CusRecNoteItem.customer_material_id} disabled >
                                                                        <option value=''>Select</option>
                                                                        {godownsList.map(item =>
                                                                            <option value={item.field_id}>{item.field_name}</option>)}
                                                                    </select>
                                                                </td>
                                                                <td className="erp_table_td text-end">
                                                                    <select className="form-select form-select-sm mb-0  " value={CusRecNoteItem.godown_section_id}
                                                                        onChange={(e) => { handleInputChange(CusRecNoteItem, e); }}
                                                                        id={`godown_section_id_${Index}`}
                                                                        Headers='godown_section_id' gdDetailsId={CusRecNoteItem.customer_material_id} disabled >
                                                                        <option value=''>Select</option>
                                                                        {godownsSectionList.map(item =>
                                                                            <option value={item.field_id}>{item.field_name}</option>)}
                                                                    </select>
                                                                </td>
                                                                <td className="erp_table_td">
                                                                    <select className="form-select form-select-sm mb-0  " value={CusRecNoteItem.godown_section_beans_id}
                                                                        onChange={(e) => { handleInputChange(CusRecNoteItem, e); }}
                                                                        id={`godown_section_beans_id_${Index}`}
                                                                        Headers='godown_section_beans_id' gdDetailsId={CusRecNoteItem.customer_material_id} disabled >
                                                                        <option value=''>Select</option>
                                                                        {godownsSectioonBeanList.map(item =>
                                                                            <option value={item.field_id}>{item.field_name}</option>)}
                                                                    </select>
                                                                </td>
                                                                <td className={`erp_table_td`} >
                                                                    <input type="date"
                                                                        id={`expiry_date_${Index}`} className="erp_input_field mb-0" value={CusRecNoteItem.expiry_date}
                                                                        onChange={(e) => { handleInputChange(CusRecNoteItem, e); }} Headers='expiry_date' disabled />
                                                                </td>
                                                                {/* <td className={`erp_table_td text-end ${keyForViewUpdate === "" ? "d-none" : "display"}`}>{CusRecNoteItem.prev_return_weight}</td> */}
                                                                <td className="erp_table_td text-end">{CusRecNoteItem.customer_return_weight}</td>
                                                                <td className="erp_table_td text-end">{CusRecNoteItem.product_material_std_weight}</td>
                                                                <td className="erp_table_td text-end">{CusRecNoteItem.customer_material_order_weight}</td>
                                                                <td className="erp_table_td text-end">{CusRecNoteItem.customer_material_accepted_weight}</td>
                                                                <td className="erp_table_td text-end">{CusRecNoteItem.customer_material_rejected_weight}</td>
                                                                <td className={`erp_table_td`}>{CusRecNoteItem.customer_batch_no === undefined || CusRecNoteItem.customer_batch_no === '' ? `${FINANCIAL_SHORT_NAME}/${txt_customer_material_return_no}/${today()}` : CusRecNoteItem.customer_batch_no}</td>
                                                                <td className="erp_table_td">
                                                                    <select id={`customer_item_status_${Index}`} value={CusRecNoteItem.customer_item_status}
                                                                        className="form-select form-select-sm  " Headers='customer_item_status'
                                                                        disabled onChange={(e) => { handleInputChange(CusRecNoteItem, e); }} >
                                                                        <option value="C">Material Reciept</option>
                                                                        <option value="Q">QC Done</option>
                                                                        <option value="R">Rejected</option>
                                                                        <option value="I">Used in production</option>
                                                                        <option value="X">Material return</option>
                                                                    </select></td>
                                                                <td className="erp_table_td sticky-column">{CusRecNoteItem.customer_material_tech_spect}</td>
                                                                <td className="erp_table_td text-end scrollable-table">{CusRecNoteItem.customer_material_packing_name}</td>
                                                                <td className="erp_table_td text-end scrollable-table">{CusRecNoteItem.customer_material_hsn_code}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </> :
                                        <Card className='erp_no_recrd_card' >
                                            <Card.Body className="text-center">No records found...</Card.Body>
                                        </Card>
                                    }
                                </>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                {/* 
                {keyForViewUpdate !== '' ?
                    <> <hr />
                        <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                            <Accordion.Item eventKey="documentList">
                                <Accordion.Header className="erp-form-label-md p-0">Document List</Accordion.Header>
                                <Accordion.Body>
                                    {docData.length !== 0 ? (
                                        renderDocumentTable
                                    ) : (
                                        <div className='row'>
                                            <div className="col-12">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                    No Records Found...
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                    : null
                } */}
                {/* <hr /> */}


                {/* <div className="card">

                    <div className="card-header main_heding text-start">
                        <label className='erp-form-label-lg'>Customer Material Return Totals</label>
                    </div>
                    <div className="card-body">
                        <form id="CustomerMaterialReturnFooterFormId">
                            <div className="row">
                                <div className="col-sm-6 erp_form_col_div">

                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Grand Total <span className="required"></span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id="txt_grandtotal" className="erp_input_field text-end" value={txt_grandtotal} onChange={(e) => { setgrandtotal(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); }} disabled optional='optional' />
                                            <MDTypography variant="button" id="error_txt_grandtotal" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Vehicle No <span className="required"></span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id="txt_vehicle_no" className="erp_input_field" value={txt_vehicle_no} onChange={(e) => { setVehicleNo(e.target.value); }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_vehicle_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">EWB No. & Valid till</Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_ebw_no" className="erp_input_field" value={txt_ebw_no} onChange={(e) => { setEbwNo(e.target.value); validateFields(); }} optional='optional' readOnly />
                                                    <MDTypography variant="button" id="error_txt_ebw_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3  ps-md-1">
                                                    <Form.Control type="datetime-local" id='dt_ebw_date' className="erp_input_field" value={dt_ebw_date} onChange={e => { setEbwDate(e.target.value); }} optional='optional' readOnly />
                                                    <MDTypography variant="button" id="dt_ebw_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">LR No. & Date</Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_lr_no" className="erp_input_field" value={txt_lr_no} onChange={(e) => { setlrNo(e.target.value); validateFields(); }} optional='optional' />
                                                    <MDTypography variant="button" id="error_txt_lr_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-6 pt-md-0 pt-3  ps-md-1">
                                                    <Form.Control type="date" id='dt_lr_date' className="erp_input_field" value={dt_lr_date} onChange={e => { setlrDate(e.target.value); }} optional='optional' />
                                                    <MDTypography variant="button" id="dt_lr_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Remark</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control as="textarea" rows={2} id="txt_remark" className="erp_input_field" value={txt_remark} onChange={(e) => { setRemark(e.target.value); validateFields(); }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Active</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="is_active" checked={is_active === true} onClick={() => { setIsActive(true); }} disabled={keyForViewUpdate === 'view' ? true : false} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" value="false" name="is_active" checked={is_active === false} onClick={() => { setIsActive(false); }} disabled={keyForViewUpdate === 'view' ? true : false} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> */}

                {/* Delete Modal */}
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to delete this record ?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={handleClose}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button>
                    </Modal.Footer>
                </Modal>
                {/* 
                {showConfirmationModal ? */}
                <ConfirmationModal close={() => closeConfirmationModal('Exit')} confirmation={() => closeConfirmationModal('Export&Exit')} show={[modalOrderDetails]} />
                {/* : null
                } */}


                {/* {showAddRecModal ? <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        <div className='row'>
                            <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
                        </div>
                        {displayRecordComponent()}
                    </Modal.Body>
                </Modal > : null} */}

                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />

                <div className="text-center">
                    <MDButton type="button" className="erp-gb-button erp_MLeft_btn ms-4" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>

                    <MDButton type="button" id="btn_action_label" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} onClick={() => addCustomerMaterialReturn()} variant="button"
                        fontWeight="regular">{actionLabel}</MDButton>

                    {/* <MDButton className={`erp-gb-button ms-2 ${keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" id='btn_view_document' onClick={viewDocumentForm} >Upload Document</MDButton>&nbsp; */}

                    <MDButton type="button" className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === '' ? 'display' : 'd-none'}`} variant="button" id='clearbutton-id' onClick={clearFormFields} fontWeight="regular" >Clear</MDButton>

                    <MDButton type="button" className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => deleteshow()}>Delete</MDButton>

                </div >
                {/* Document modal */}
                {/* <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <DocumentF group_id={txt_customer_material_return_no !== 0 ? txt_customer_material_return_no.replaceAll('/', '_') : null} document_group={documentGroup} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal> */}
            </DashboardLayout >
        </>
    )
}
export default TCustomerMaterialReturnEntry            