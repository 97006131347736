import React, { useState, useRef, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $, { event } from "jquery";

//File Imports
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";
import ConfigConstants from "assets/Constants/config-constant";
import ComboBox from "Features/ComboBox";
import ExcelExport from "Features/Exports/ExcelExport";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import FrmValidations from "FrmGeneric/FrmValidations";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import DocumentF from "Features/Document";
import ErrorModal from "components/Modals/ErrorModal";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import { renderToString } from "react-dom/server";
import html2pdf from 'html2pdf.js';
import { Link } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";


//React Bootstrap components
import Tooltip from "@mui/material/Tooltip";
import { Form, Button } from "react-bootstrap";
import { Accordion, Modal, Table } from "react-bootstrap";
import { MdRefresh } from "react-icons/md";
import { CircularProgress } from "@material-ui/core";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


// React icons
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import FrmTaxationEntry from "Masters/MTaxation/FrmTaxationEntry";
import { FiDownload } from "react-icons/fi";
import CustomerSalesReciptInvoice from "FrmGeneric/Invoice/CustomerSalesReciptInvoice";
import MailSentModal from "components/Modals/MailSentModal";


function FrmCustomerSalesReceiptEntry() {
  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, FINANCIAL_SHORT_NAME, UserId, UserName } = configConstants;

  const { state } = useLocation()
  const { idList, keyForViewUpdate, compType } = state || {};
  // UseRefs
  const navigate = useNavigate();
  const validate = useRef();
  const comboDataAPiCall = useRef();
  const generateAutoNoAPiCall = useRef();
  const validateNumberDateInput = useRef();
  const importFile = useRef(null);
  const exlsExp = useRef();

  //common hooks
  const [isLoading, setIsLoading] = useState(false);
  const currentDate = new Date().toISOString().split('T')[0];
  const [isApprove, setIsApprove] = useState(false);
  const [isView, setIsView] = useState(false);

  // to add new records in combo box 
  const [showAddRecModal, setShowAddRecModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('')

  // Document Form
  let docGroup = "Customer Sale's Receipt"
  const [showDocumentForm, setShowDocumentForm] = useState(false);
  const handleCloseDocumentForm = () => setShowDocumentForm(false);
  const viewDocumentForm = () => setShowDocumentForm(true);


  //Error Msg
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [showMailSentModal, setShowMailSentModal] = useState(false);

  // delete modal Fields
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  // master hooks          
  const [customer_sales_receipt_master_transaction_id, setcustomer_sales_receipt_master_transaction_id] = useState(idList !== null ? idList.customer_sales_receipt_master_transaction_id : 0)
  const [txt_customer_sales_receipt_no, setcustomer_sales_receipt_no] = useState(idList !== null ? idList.customer_sales_receipt_no : '0')
  const [txt_customer_sales_receipt_version, setcustomer_sales_receipt_version] = useState(idList !== null ? idList.customer_sales_receipt_version : 1)
  // const [cmb_customer_sales_receipt_type_id, setcustomer_sales_receipt_type_id] = useState('')
  const [txt_customer_sales_receipt_date, setcustomer_sales_receipt_date] = useState(currentDate)
  const [cmb_customer_id, setcustomer_id] = useState('')
  const [txt_customer_contacts_ids, setcustomer_contacts_ids] = useState('')
  const [cmb_expected_branch_id, setexpected_branch_id] = useState(COMPANY_BRANCH_ID)
  const [cmb_approved_by_id, setApproved_by_id] = useState("");
  const [txt_approved_date, setApproved_date] = useState("");
  const [cmb_bank_id, setbank_id] = useState('')
  const [chk_receipt_type, setreceipt_type] = useState('1')
  const [txt_customer_bank_details, setcustomer_bank_details] = useState("");
  const [cmb_customer_bank_id, setcustomer_bank_id] = useState("");
  const [txt_customer_bank_account_number, setcustomer_bank_account_number] = useState("");
  const [txt_customer_bank_ifsc_code, setcustomer_bank_ifsc_code] = useState("");
  const [chk_customer_sales_receipt_status, setcustomer_sales_receipt_status] = useState("P");
  const [dt_receipt_transaction_date, setreceipt_transaction_date] = useState(currentDate);
  const [txt_receipt_transaction_details, setreceipt_transaction_details] = useState("");
  const [txt_total_outstanding, setTotal_outstanding] = useState(0)
  const [txt_Header_paid_amount, setHeaderPaid_amount] = useState(0)
  const [chk_recipt_mail_sent_status, setRecipt_mail_sent_status] = useState('P')


  // master footer hooks
  const [txt_total_billing_amount, settotal_billing_amount] = useState(0)
  const [txt_total_billing_deduction, settotal_billing_deduction] = useState(0)
  const [txt_tax1_id, setTax1_id] = useState('')
  const [txt_tax1_percent, setTax1_percent] = useState(0)
  const [txt_tax1_amount, setTax1_amount] = useState(0)
  const [txt_tax2_id, setTax2_id] = useState('')
  const [txt_tax2_percent, setTax2_percent] = useState(0)
  const [txt_tax2_amount, setTax2_amount] = useState(0)

  const [txt_total_receipt_amount, settotal_receipt_amount] = useState(0)
  const [txt_deduction_remark, setDeduction_remark] = useState('')
  const [chk_CustomerSalesRecipt_isactive, setCustomerSalesRecipt_isactive] = useState(true)
  const [txt_remark, setRemark] = useState('')


  // cmb option hooks
  const [producttypeOptions, setProductTypeOptions] = useState([]);
  const [customerOptions, setcustomerOptions] = useState([]);
  const [expectedBranchOptions, setExpectedBranchOptions] = useState([]);
  const [approvedByOptions, setApprovedByOptions] = useState([]);
  const [bankOptions, setbankOptions] = useState([]);
  const [customerBankOptions, setcustomerBankOptions] = useState([]);

  // cmb footer option hooks
  const [tax1Options, setTax1Options] = useState([])

  // Customers contact details table data hooks
  const [CustContactDetails, setCustContactDetails] = useState([]);

  //pt_customer_sales_receipt_details detail table
  const [CustomerReciptDetailData, setCustomerReciptDetailData] = useState([]);

  // Hooks for handle the accordions.
  const [openContactsAccord, setOpenContactsAccord] = useState(false);
  const [openDetailsAccord, setOpenDetailsAccord] = useState(true);

  // Confirmation Modal fields
  const [modalOrderDetails, setModalOrderDetails] = useState('')
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [message, setMessage] = useState('')
  const [messageForConfirmation, setMessageForConfirmation] = useState('')

  const closeConfirmationModal = (key) => {
    if (key === 'Export&Exit') {
      ExportToExcel();
    }
    setShowConfirmationModal(false);
    moveToListing();
  }

  useEffect(async () => {
    try {
      setIsLoading(true)
      ActionType();
      // FnSetFieldsByUserAccess();
      await fillComobos();
      if (idList !== null) {
        await FnCheckUpdateResponse();
      } else {
        await GenerateCustomerSalesReceiptNo();
      }
      FnSetFieldsByUserAccess();

    } catch (error) {
      console.error(error);
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }

    // Close customer order no dropdown
    $(document).on('mouseup', function (e) {
      var container = $("#bill-booked-order-ul");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.hide();
      }
    });
  }, [])

  useEffect(() => {
    if (CustomerReciptDetailData.length !== 0) {
      FnCalculateDetailsTotalSum();
    }
  }, [CustomerReciptDetailData])






  const fillComobos = async () => {
    try {
      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.columns.push("product_type_short_name");
      globalQuery.table = "smv_product_type";
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
      const poTypeApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      setProductTypeOptions(poTypeApiCall);

      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "cmv_customer";
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      const CustomerApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
      setcustomerOptions(CustomerApiCall)

      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "cmv_banks";
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      const bankApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
      setbankOptions(bankApiCall)

      //get expected branch list
      resetGlobalQuery();
      globalQuery.columns.push("company_branch_id");
      globalQuery.columns.push("company_branch_name");
      globalQuery.table = "cmv_company_branch";
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
      const exptBranchApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      setExpectedBranchOptions(exptBranchApiCall);

      const getEmployees = await comboDataAPiCall.current.fillMasterData("cmv_employee", "", "");
      setApprovedByOptions(getEmployees);


      //get expected branch list
      resetGlobalQuery();
      globalQuery.columns.push("field_name");
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("tax_value");
      globalQuery.table = "cmv_taxation";
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
      const taxationApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      setTax1Options(taxationApiCall);

    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  // to scroll Customer contact ids box
  function scrollToTableRow() {
    var keywords = $("#txt-detail-data-highliter").val();
    var table = document.getElementById('CustomerSalesReceipt-details-table');
    var rows = table.getElementsByTagName('tr');
    for (var trNum = 1; trNum < rows.length; trNum++) {
      var row = rows[trNum];
      if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
        var w = $(window);
        var row = $('#CustomerSalesReceipt-details-table').find('tr').eq(trNum);

        if (row.length) {
          w.scrollTop(row.offset().top - (w.height() / 2));
        }

        $(row).find('td').each(function () {
          $(this).addClass('highlight');
        });
        removeOtherTrHighlightClass(trNum)
        break;
      } else {
        $(row).find('td').each(function () {
          $(this).removeClass('highlight');
        });
      }
    }
  }
  const removeOtherTrHighlightClass = (rowNum) => {
    var table = document.getElementById('CustomerSalesReceipt-details-table');
    var rows = table.getElementsByTagName('tr');
    for (var trNum = 1; trNum < rows.length; trNum++) {
      var row = rows[trNum];
      if (trNum !== rowNum) {
        $(row).find('td').each(function () {
          $(this).removeClass('highlight');
        });
      }
    }
  }

  // fn for fields onchange action
  const comboOnChange = async (key) => {
    switch (key) {
      // case "productType":
      //   var productTypeVal = document.getElementById("cmb_customer_sales_receipt_type_id").value;
      //   const shortname = $('#cmb_customer_sales_receipt_type_id option:selected').attr('shortname');
      //   setcustomer_sales_receipt_type_id(productTypeVal);

      //   if (productTypeVal !== '0' && productTypeVal !== "") {
      //     $("#error_cmb_customer_sales_receipt_type_id").hide();
      //     GenerateCustomerSalesReceiptNo(shortname);
      //   } else if (productTypeVal === '0') {
      //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
      //     setHeaderName('Product Type')
      //     setShowAddRecModal(true)
      //     setTimeout(() => {
      //       $(".erp_top_Form").css({
      //         "padding-top": "0px"
      //       });
      //     }, 200)
      //     setcustomer_sales_receipt_no("0");

      //   } else {
      //     setcustomer_sales_receipt_no("0");
      //   }
      //   break;

      case "customer":
        let customerTpVal = document.getElementById("cmb_customer_id").value;
        setcustomer_id(customerTpVal);
        if (customerTpVal === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Add Customer')
          const newTab = window.open('/Masters/CustomerListing', '_blank');
          if (newTab) {
            newTab.focus();
          }
        }
        if (customerTpVal != null && customerTpVal != '' & customerTpVal != '0') {
          await FnGetCustomersContacts(parseInt(customerTpVal));
          await FnloadCustomerBankDetails(customerTpVal);
          if (keyForViewUpdate === '') {
            await showCustomersalesreciptDetails(customerTpVal);
          }
        } else {
          setCustContactDetails([])
        }
        break;


      case "expectBranch":
        let exptBranchIdVal = document.getElementById("cmb_expected_branch_id").value;
        setexpected_branch_id(exptBranchIdVal);
        if (exptBranchIdVal === '0') {
          const newTab = window.open('Masters/Company', '_blank');
          if (newTab) {
            newTab.focus();
          }
        } else {
          // get Customer list with default state & city

        }
        break;

      case "customer_bank_id":
        let customerBankIdVal = document.getElementById("cmb_customer_bank_id").value;
        // if (customerBankIdVal === '0') {
        //   sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
        //   setHeaderName('Add Department')
        //   setShowAddRecModal(true)
        //   setTimeout(() => {
        //     $(".erp_top_Form").css({
        //       "padding-top": "0px"
        //     });
        //   }, 200)
        // }
        if (customerBankIdVal != null && customerBankIdVal != '' & customerBankIdVal != '0') {
          const custBankDetails = customerBankOptions.find(item => item.field_id === parseInt(customerBankIdVal));
          setcustomer_bank_account_number(custBankDetails.cust_bank_account_no)
          setcustomer_bank_ifsc_code(custBankDetails.cust_bank_ifsc_code)
        } else {
          setcustomer_bank_account_number('')
          setcustomer_bank_ifsc_code('')
          setcustomer_bank_details('')
        }
        break;
      case "tax1":
        var tax_1Val = document.getElementById("txt_tax1_id").value;
        setTax1_id(tax_1Val);
        if (tax_1Val !== '0' && tax_1Val !== "") {
          const tax_percent = $('#txt_tax1_id option:selected').attr('tax_value');
          setTax1_percent(tax_percent)
          FnCalculateTaxAmount(tax_percent, 'tax1amount');
        } else if (tax_1Val === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Add Taxation')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css({
              "padding-top": "0px"
            });
          }, 200)
          setTax1_percent('');
          setTax1_amount('');
        } else {
          setTax1_percent('');
          setTax1_amount('');
        }
        setTimeout(() => {
          FnCalculateDetailsTotalSum();
        }, 300);
        break;
      case "tax2":
        var tax_2Val = document.getElementById("txt_tax2_id").value;
        setTax2_id(tax_2Val);
        if (tax_2Val !== '0' && tax_2Val !== "") {
          const tax_percent = $('#txt_tax2_id option:selected').attr('tax_value');
          setTax2_percent(tax_percent)
          FnCalculateTaxAmount(tax_percent, 'tax2amount')
        } else if (tax_2Val === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Add Taxation')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css({
              "padding-top": "0px"
            });
          }, 200)
          setTax2_percent(0);
          setTax2_amount(0);
        } else {
          setTax2_percent(0);
          setTax2_amount(0);
        }
        setTimeout(() => {
          FnCalculateDetailsTotalSum();
        }, 300);
        break;
      case "selectreciptItem":
        calculateHeaderPaidAmount();
        toggleChkAllBoxes('PartiallyReciptItemSelection');
        break;

      default:
        break;
    }
  };

  const refreshData = async (key) => {

    switch (key) {
      case 'get_customer':
        // get Customer list with default state & city
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.table = "cmv_customer";
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        const CustomerApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
        setcustomerOptions(CustomerApiCall)
        break;
      case 'get_exptBranch':
        //get expected branch list
        resetGlobalQuery();
        globalQuery.columns.push("company_branch_id");
        globalQuery.columns.push("company_branch_name");
        globalQuery.columns.push("is_sez");
        globalQuery.columns.push("branch_EmailId");
        globalQuery.table = "cmv_company_branch";
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
        const exptBranchApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
        setExpectedBranchOptions(exptBranchApiCall);
        break;
      default:
        break;
    }
  }

  //Fn for generate bill bookrd no
  const GenerateCustomerSalesReceiptNo = async (ptShortName) => {
    const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("ft_customer_sales_receipt_master", "customer_sales_receipt_no", "", "SR", "5");
    setcustomer_sales_receipt_no(autoNoApiCall);
    setcustomer_sales_receipt_version(1);
    return autoNoApiCall;
  }


  // Fn for calculate tax on Total billing Amount
  const FnCalculateTaxAmount = (tax_percent, tax_type) => {
    const tax_amount = txt_total_billing_amount * tax_percent / 100;
    switch (tax_type) {
      case 'tax1amount':
        setTax1_amount(validateNumberDateInput.current.decimalNumber((tax_amount).toString(), 4))
        break;
      case 'tax2amount':
        setTax2_amount(validateNumberDateInput.current.decimalNumber((tax_amount).toString(), 4))
        break;
      default:
        break;
    }

  }


  const handleCloseMailSentModal = () => {
    setShowMailSentModal(false);
    moveToListing();
  }


  const ActionType = () => {
    switch (keyForViewUpdate) {
      case 'financeApprove':
        return '(Finance Approve)';
      case 'update':
        return '(Modification)';
      case 'view':
        return '(View)';
      case 'cancel':
        return '(cancelation)';
      case 'delete':
        return '(Delete)';
      default:
        return '(Creation)';
    }
  }


  // Fn For get customer bank details
  const FnloadCustomerBankDetails = async (customerId) => {

    resetGlobalQuery();
    globalQuery.columns = ["field_id", "field_name", "cust_bank_account_no", "cust_bank_ifsc_code", "customer_id"]
    globalQuery.table = "cmv_customer_banks";
    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customerId });
    const customersApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
    setcustomerBankOptions(customersApiCall)

  }

  // -----------------------------------------------------------------------------------
  const displayRecordComponent = () => {
    switch (modalHeaderName) {
      case 'Product Type':
        return <FrmMProductTypeEntry />;
      case 'Add Taxation':
        return <FrmTaxationEntry />;
      default:
        return null;
    }
  }

  // Show ADD record Modal
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {
      case 'Product Type':
        // set_customer_sales_receipt_type_id('');
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.columns.push("product_type_short_name");
        globalQuery.table = "smv_product_type";
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });

        const poTypeApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
        setProductTypeOptions(poTypeApiCall);
        break;

      case 'Add Taxation':
        //get expected branch list
        resetGlobalQuery();
        globalQuery.columns.push("field_name");
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("tax_value");
        globalQuery.table = "cmv_taxation";
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
        const taxationApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
        setTax1Options(taxationApiCall);
        break;

      default:
        break;

    }
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)

  }

  //fn for User Access Manage .
  const FnSetFieldsByUserAccess = () => {
    if (keyForViewUpdate != null) {
      setIsApprove(keyForViewUpdate === 'financeApprove' ? true : false);
      $('#cancel-btn').hide();
      $('#finance-btn').hide();

      switch (keyForViewUpdate) {

        case 'financeApprove':
        case 'cancel':
        case 'view':
          setIsView(true)
          $("form").find("input,textarea,select").attr("disabled", "disabled");
          $("table").find("input,button,textarea,select").attr("disabled", "disabled");
          $('input[type="checkbox"]').prop('disabled', true);

          $('#clearFormBtn').hide();
          $('#viewdocument-id').hide();
          $('#save-btn').hide();
          $('#calculate_btn_id').attr('disabled', true);
          if (keyForViewUpdate === 'cancel') {
            $('#cancel-btn').show();
          } else if (keyForViewUpdate === 'financeApprove') {
            $('#finance-btn').show();
          }

          break;
      }
    }
  }

  // -----------------------------------------------------------------------------------
  const validateFields = (form_id) => {
    var formObj = $("#" + form_id);
    var inputObj;
    for (var i = 0; i <= formObj.get(0).length - 1; i++) {
      inputObj = formObj.get(0)[i];
      if (inputObj.type === "text" && inputObj.value !== "") {
        $("#error_" + inputObj.id).hide();
      } else if (inputObj.type === "select-one" && inputObj.value !== "") {
        $("#error_" + inputObj.id).hide();
      } else if (inputObj.type === "textarea" && inputObj.value !== "") {
        $("#error_" + inputObj.id).hide();
      } else if (inputObj.type === "date" && inputObj.value !== "") {
        $("#error_" + inputObj.id).hide();
      }
    }
  };
  // ------------------------------------------------------------------------------------------------------------------------------
  const updateSalesReciptTblRows = (rowData, event) => {

    let clickedColName = event.target.getAttribute('Headers');
    let enteredValue = parseInt(event.target.value);
    if (isNaN(enteredValue)) {
      enteredValue = 0;
    }
    switch (clickedColName) {
      case 'item_billing_deduction':
        let focusedInputField = document.querySelector('#' + event.target.id);
        rowData[clickedColName] = event.target.value;
        rowData['item_paid_amount'] = validateNumberDateInput.current.decimalNumber(('0').toString(), 4);
        debugger

        if (event.target.value !== '') {
          const itemBillingAmount = rowData.item_billing_amount;
          const itemprevPaidAmt = rowData.item_prev_paid_amount;
          const billingMinusPrev = itemBillingAmount - itemprevPaidAmt;
          if (itemBillingAmount > itemprevPaidAmt || itemBillingAmount !== itemprevPaidAmt) {
            if (event.target.value < billingMinusPrev) {
              delete focusedInputField.parentElement.dataset.tip;
              const itemBillingDeduction = event.target.value;
              let itemPaidAmount = billingMinusPrev - itemBillingDeduction;
              rowData['item_paid_amount'] = validateNumberDateInput.current.decimalNumber((itemPaidAmount).toString(), 4);
              FnCalculatePurchaseDetails();
            } else {
              focusedInputField.parentElement.dataset.tip = "Please Enter Less Than " + billingMinusPrev + "...! ";
            }
          } else {
            rowData[clickedColName] = '0';
          }
        }

        break;
      case 'customer_sales_item_receipt_status':
      case 'remark':
        rowData[clickedColName] = event.target.value;
        break;
    }

    const detailData = [...CustomerReciptDetailData]
    const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
    detailData[arrayIndex] = rowData
    setCustomerReciptDetailData(detailData);

  }
  // const updateSalesReciptTblRows = (rowData, event) => {

  //   let clickedColName = event.target.getAttribute('Headers');
  //   let enteredValue = parseInt(event.target.value);
  //   if (isNaN(enteredValue)) {
  //     enteredValue = 0;
  //   }
  //   switch (clickedColName) {

  //     case 'item_billing_deduction':
  //       rowData[clickedColName] = event.target.value;
  //       rowData['item_paid_amount'] = validateNumberDateInput.current.decimalNumber(('0').toString(), 4);
  //       if (event.target.value !== '') {
  //         const itemBillingDeduction = event.target.value;
  //         const itemBillingAmount = rowData.item_billing_amount;
  //         let itemPaidAmount = itemBillingAmount - itemBillingDeduction;
  //         rowData['item_paid_amount'] = validateNumberDateInput.current.decimalNumber((itemPaidAmount).toString(), 4);
  //         FnCalculatePurchaseDetails();
  //       }

  //       break;
  //     case 'customer_sales_item_receipt_status':
  //     case 'remark':
  //       rowData[clickedColName] = event.target.value;
  //       break;
  //   }

  //   const detailData = [...CustomerReciptDetailData]
  //   const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
  //   detailData[arrayIndex] = rowData
  //   setCustomerReciptDetailData(detailData)
  // }


  // ------------------------------------------------------------------------------------------------------------------------------
  //Function for get contact ids,name & mobile nos of Customers
  const FnGetCustomersContacts = async (SelectedCustomers) => {
    if (SelectedCustomers !== '' || SelectedCustomers !== '0') {
      resetGlobalQuery();

      globalQuery.columns = ["customer_id", "customer_branch_name", "cust_branch_id", "customer_contact_id",
        "cust_contact_person", "cust_contact_no", "cust_alternate_contact", "cust_email_id", "cust_alternate_EmailId", "cust_designation"]

      globalQuery.table = "cmv_customer_contacts";
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
      globalQuery.conditions.push({ field: "customer_id", operator: "=", value: SelectedCustomers });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      const getContactDtlsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
      if (getContactDtlsApiCall.length !== 0) {
        setCustContactDetails(getContactDtlsApiCall);
      } else {
        setCustContactDetails([]);
      }
    }
    // Onchange event listener for the customer contact Selection
    $('.selectCustContactPerson').on('change', function () {
      toggleChkAllBoxes('PartiallyCustContactSelection');
    });

  }

  //Fn to render Customer Contact details table
  const renderCustomerContactsTbl = useMemo(() => {
    return <>
      <Table className="erp_table " id='customer-contacts-table' responsive bordered striped erp_table_scroll>
        <thead className="erp_table_head">
          <tr>
            <th className="erp_table_th">Select</th>
            <th className="erp_table_th">Branch Name</th>
            <th className="erp_table_th">Contact Person</th>
            <th className="erp_table_th">Contact No</th>
            <th className="erp_table_th">Alternate Contact No</th>
            <th className="erp_table_th">Email</th>
            <th className="erp_table_th">Alternate Email</th>
            <th className="erp_table_th">Designation</th>
          </tr>
        </thead>
        <tbody>
          {CustContactDetails.map((contactItem, index) => (
            <tr rowIndex={index}>
              <td className="erp_table_td">
                <div style={{ display: "flex" }}>
                  <div className="text-center">
                    <input type='checkbox' class="selectCustContactPerson" name="selectCustContactPerson" id={'selectCustContactPerson_' + contactItem.customer_contact_id} contact_personId={contactItem.customer_contact_id} value={contactItem.customer_contact_id} />
                  </div>
                </div>
              </td>
              <td className="erp_table_td text-end">{contactItem.customer_branch_name}</td>
              <td className="erp_table_td text-end">{contactItem.cust_contact_person}</td>
              <td className="erp_table_td text-end">{contactItem.cust_contact_no}</td>
              <td className="erp_table_td text-end">{contactItem.cust_alternate_contact}</td>
              <td className="erp_table_td text-end">{contactItem.cust_email_id}</td>
              <td className="erp_table_td text-end">{contactItem.cust_alternate_EmailId}</td>
              <td className="erp_table_td text-end">{contactItem.cust_designation}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  }, [CustContactDetails]);
  //function to check All checkBoxes of CustContact  
  function toggleChkAllBoxes(key) {
    switch (key) {
      // For Customer's contact
      case 'selectAllCustContact':
        $('.selectCustContactPerson').prop('checked', $('#selectAllCustContact').is(":checked"));
        break;
      case 'PartiallyCustContactSelection':
        $('#selectAllCustContact').prop('checked', $('input:checkbox.selectCustContactPerson:checked').length === $('input:checkbox.selectCustContactPerson').length);
        break;
      case 'selectAllReceiptItems':
        $('.selectreciptItem').prop('checked', $('#selectAllReceiptItems').is(":checked"));
        calculateHeaderPaidAmount();
        break;
      case 'PartiallyReciptItemSelection':
        $('#selectAllReceiptItems').prop('checked', $('input:checkbox.selectreciptItem:checked').length === $('input:checkbox.selectreciptItem').length);
        break;
    }
  }

  const FnGetCustomerContactPersonIds = () => {
    let customerContactPersonsIds = '';
    const checkboxes = $('.selectCustContactPerson:checked');
    checkboxes.each(function () {
      customerContactPersonsIds += $(this).val() + ":";
    });
    return customerContactPersonsIds.replace(/:$/, '');
  }





  const FnSetExistingCoustomerContactPersonIds = (contactIds) => {
    const customerContactPersonsIds = contactIds.split(':');
    customerContactPersonsIds.forEach(function (contactId, index) {
      $('#selectCustContactPerson_' + customerContactPersonsIds[index]).prop('checked', true);
    });
    toggleChkAllBoxes('PartiallyCustContactSelection');
  }



  // ---------------------------------------------------------------------------------------------------------------------------
  //Fn to render bill book  details table
  const renderCustomerSalesReceiptDetailsTbl = useMemo(() => {
    return <>
      <Table className="erp_table " id='CustomerSalesReceipt-details-table' responsive bordered striped erp_table_scroll>
        <thead className="erp_table_head">
          <tr>
            <th className='erp_table_th'>Action</th>
            <th className="erp_table_th">Sr.No.</th>
            <th className="erp_table_th">Sales Invoice No</th>
            <th className="erp_table_th">Sales Order No</th>
            <th className="erp_table_th">Schedule Payment Date</th>
            <th className="erp_table_th">Payment Due Date</th>
            <th className="erp_table_th">Billing Amount</th>
            <th className="erp_table_th">Previous paid Amount</th>
            <th className="erp_table_th">Billing Deduction</th>
            <th className="erp_table_th">Paid Amount</th>
            <th className="erp_table_th">Pending Amount</th>
            <th className="erp_table_th">Receipt Status</th>
            <th className="erp_table_th">Dispatch Challan No</th>
            <th className="erp_table_th">Receipt remark</th>
            <th className="erp_table_th">Sales Order Date</th>
            <th className="erp_table_th">Sales Order Version</th>
            <th className="erp_table_th">Sales Invoice Date</th>
            <th className="erp_table_th">Sales Invoice Version</th>
            <th className="erp_table_th">Dispatch Challan Date</th>
            <th className="erp_table_th">Dispatch Challan Version</th>
          </tr>
        </thead>
        <tbody>

          {CustomerReciptDetailData.map((reciptItem, index) => (
            <tr rowIndex={index}>
              <td className="erp_table_td">
                <div style={{ display: "flex" }}>
                  <div className="text-center">
                    <input type='checkbox' class="selectreciptItem" name="selectreciptItem" id={'selectreciptItem_' + reciptItem.sales_invoice_details_transaction_id}
                      onChange={() => comboOnChange("selectreciptItem")}
                      value={reciptItem.sales_invoice_details_transaction_id} />
                  </div>
                </div>
              </td>
              <td className="erp_table_td text-end">{index + 1}</td>
              <td className="erp_table_td">{reciptItem.sales_invoice_no}</td>
              <td className="erp_table_td">{reciptItem.sales_order_no}</td>
              <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(reciptItem.expected_schedule_date)}</td>
              <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(reciptItem.payment_due_date)}</td>
              <td className="erp_table_td text-end">{reciptItem.item_billing_amount}</td>
              <td className="erp_table_td text-end">{reciptItem.item_prev_paid_amount}</td>
              <td className="erp_table_td text-end">
                <input type="text" className="erp_input_field mb-0 text-end" Headers="item_billing_deduction"
                  id={`item_billing_deduction_${index}`} value={validateNumberDateInput.current.decimalNumber((reciptItem.item_billing_deduction).toString(), 4)} onChange={(e) => { updateSalesReciptTblRows(reciptItem, e); }} />
                {/* //FnCalculateOustandingPaidAmt();  */}
              </td>
              <td className="erp_table_td  text-end">{reciptItem.item_paid_amount}</td>
              <td className="erp_table_td  text-end">{reciptItem.item_pending_amount}</td>

              <td className="erp_table_td ">
                <select id={`customer_sales_item_receipt_status_${index}`} disabled={!isApprove} value={reciptItem.customer_sales_item_receipt_status} className="form-select form-select-sm approveField"
                  Headers='customer_sales_item_receipt_status' onChange={(e) => { updateSalesReciptTblRows(reciptItem, e); }} >
                  <option value="P">Pending</option>
                  <option value="C">Payment Done</option>
                  <option value="X">Canceled</option>
                  <option value="F">Finance Approve</option>
                </select>
              </td>
              <td className="erp_table_td">{reciptItem.dispatch_challan_no}</td>
              <td className="erp_table_td ">
                <input type="text" className="erp_input_field mb-0" Headers="remark"
                  id={`remark_${index}`} value={reciptItem.remark} onChange={(e) => { updateSalesReciptTblRows(reciptItem, e); }} />
              </td>
              <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(reciptItem.sales_order_Date)}</td>
              <td className="erp_table_td  text-end">{reciptItem.sales_order_version}</td>
              <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(reciptItem.sales_invoice_date)}</td>
              <td className="erp_table_td  text-end">{reciptItem.sales_invoice_version}</td>
              <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(reciptItem.dispatch_challan_date)}</td>
              <td className="erp_table_td  text-end">{reciptItem.dispatch_challan_version}</td>
            </tr>
          ))}
        </tbody>

      </Table>
    </>
  }, [CustomerReciptDetailData, isView, isApprove]);


  // ----------------------------------------------------------------------------------------------------------------------
  const showCustomersalesreciptDetails = async (customer_id, storeCustomerSalesreciptDetailsData) => {
    let apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtSupplierBillBookingMaster/FnShowSalesInvoiceDetailsRecords/${customer_id}/${COMPANY_ID}`)
    const response = await apicall.json()
    console.log(response);
    if (response.success === '1') {
      $('#error_good_receipt_orderNoEmpty').hide();
      displaySalesreciptDetailsData(response);
    } else {
      setErrMsg('No Records Found...!')
      setShowErrorMsgModal(true);
      console.log('Customer Sales receipt Data Error: ', response.error);
    }
  }



  const displaySalesreciptDetailsData = (response) => {
    let responseReciptDetailData = [];
    setCustomerReciptDetailData((prevArray) => {
      const calculateValues = (item) => {
        return {
          ...item,
          expected_schedule_date: item.item_payment_schedule_date,
          payment_due_date: item.payment_schedule_date,
          item_billing_amount: item.grand_total,
          item_billing_deduction: 0,
          item_pending_amount: 0,
          item_paid_amount: 0,
          customer_sales_item_receipt_status: "P",
        };
      };
      responseReciptDetailData = [...response.SalesInvoiceDetailsRecords.map(calculateValues)]
      return [...responseReciptDetailData];
    })
    FnCalculateReciptPaidAmt(responseReciptDetailData);
  };

  //Fn for calculate paid amount of Receipt details
  const FnCalculateReciptPaidAmt = (reciptDetailsData) => {
    reciptDetailsData.forEach(item => {
      item.item_paid_amount = item.item_billing_amount - item.item_prev_paid_amount - item.item_billing_deduction;
      $("input:checkbox[name=selectreciptItem][value='" + item.sales_invoice_details_transaction_id + "']").prop('checked', true);
    });
    console.log('Updated reciptDetailsData:', reciptDetailsData);
    setCustomerReciptDetailData(reciptDetailsData)
    FnCalculateOustandingPaidAmt(reciptDetailsData);
  };

  // -------------------------------------------------------------------------------------------

  const FnCalculateOustandingPaidAmt = (reciptDetailsData) => {
    if (reciptDetailsData === undefined || reciptDetailsData === '') {
      reciptDetailsData = [...CustomerReciptDetailData];
    }
    let outstandingAmt = 0;
    reciptDetailsData.forEach(item => {
      outstandingAmt = outstandingAmt + parseFloat(item.item_billing_amount);
    });

    setTotal_outstanding(validateNumberDateInput.current.decimalNumber((outstandingAmt).toString(), 4))
    setHeaderPaid_amount(validateNumberDateInput.current.decimalNumber((outstandingAmt).toString(), 4))
  }


  // ----------------------------------------------------------------------------------------------
  const FnCalculateDetailsTotalSum = () => {
    let total_billing_amount = 0;
    let total_deduction_amount = 0;
    let tax1_amount = 0;
    let tax2_amount = 0;
    // let tax1_amount = document.getElementById('txt_tax1_amount').value;
    // let tax2_amount = document.getElementById('txt_tax2_amount').value;
    let tax1_percent = document.getElementById('txt_tax1_percent').value;
    let tax2_percent = document.getElementById('txt_tax2_percent').value;
    let totalPaid_amount = 0;


    // details Data
    $("input:checkbox[name=selectreciptItem]:checked").each(function () {

      let findPaymentItemData = [...CustomerReciptDetailData].find(item => item.sales_invoice_details_transaction_id === parseInt($(this).val()));
      total_billing_amount += parseFloat(findPaymentItemData.item_billing_amount);
      total_deduction_amount += parseFloat(findPaymentItemData.item_billing_deduction);
    });
    console.log('Total_billing_amount: ', total_billing_amount, 'total_deduction_amount: ', total_deduction_amount);
    const netBillingAmount = total_billing_amount - total_deduction_amount;

    tax1_amount = netBillingAmount * parseInt(tax1_percent) / 100;
    tax2_amount = netBillingAmount * parseInt(tax2_percent) / 100;
    totalPaid_amount = netBillingAmount - tax1_amount - tax2_amount;

    setTax1_amount(validateNumberDateInput.current.decimalNumber((tax1_amount).toString(), 4))
    setTax2_amount(validateNumberDateInput.current.decimalNumber((tax2_amount).toString(), 4))
    settotal_billing_amount(validateNumberDateInput.current.decimalNumber((total_billing_amount).toString(), 4));
    settotal_billing_deduction(validateNumberDateInput.current.decimalNumber((total_deduction_amount).toString(), 4));
    settotal_receipt_amount(validateNumberDateInput.current.decimalNumber((totalPaid_amount).toString(), 4))

    // total_billing_amount = [...CustomerReciptDetailData].reduce((total, item) => total + parseFloat(item.item_billing_amount), 0);
    // total_deduction_amount = [...CustomerReciptDetailData].reduce((total, item) => total + parseFloat(item.item_billing_deduction), 0);


    // console.log('Total_billing_amount: ', total_billing_amount, 'total_deduction_amount: ', total_deduction_amount);
    // settotal_billing_amount(validateNumberDateInput.current.decimalNumber((total_billing_amount).toString(), 4));
    // settotal_billing_deduction(validateNumberDateInput.current.decimalNumber((total_deduction_amount).toString(), 4));

    // totalPaid_amount = total_billing_amount - tax1_amount - tax2_amount - total_deduction_amount;
    // settotal_receipt_amount(validateNumberDateInput.current.decimalNumber((totalPaid_amount).toString(), 4))


  }

  // --------------------------------------------------------------------------------------------
  const calculateHeaderPaidAmount = () => {
    debugger
    const totalHeaderPaidAmt = CustomerReciptDetailData.reduce((total, item) => {
      const checkbox = $(`input:checkbox[name=selectreciptItem][value='${item.sales_invoice_details_transaction_id}']`);
      if (checkbox.prop('checked')) {
        total += parseFloat(item.item_billing_amount);
      }
      return total;
    }, 0);
    const formattedAmount = validateNumberDateInput.current.decimalNumber(totalHeaderPaidAmt.toString(), 4);
    setHeaderPaid_amount(formattedAmount);
    FnCalPaymentDetailsOnChange(formattedAmount);
  }
  //Fn for set detail data on change checkbox
  const FnCalPaymentDetailsOnChange = (headerPaidAmount) => {
    debugger
    setCustomerReciptDetailData((prevData) => {
      const updatedPaymentDetails = [...prevData.map((selectedItemData) => {
        const isChecked = $("input:checkbox[name=selectreciptItem][value=" + selectedItemData.sales_invoice_details_transaction_id + "]:checked").length > 0;

        if (isChecked) {
          selectedItemData.item_paid_amount = selectedItemData.item_billing_amount - selectedItemData.item_prev_paid_amount - selectedItemData.item_billing_deduction;

          if (headerPaidAmount !== 0) {
            if (headerPaidAmount >= selectedItemData.item_paid_amount) {
              headerPaidAmount -= selectedItemData.item_paid_amount;
              selectedItemData.item_pending_amount = 0;
              $("input:checkbox[name='selectreciptItem'][value='" + selectedItemData.sales_invoice_details_transaction_id + "']").prop("checked", true);
            } else {
              selectedItemData.item_pending_amount = selectedItemData.item_paid_amount - headerPaidAmount;
              selectedItemData.item_paid_amount = headerPaidAmount;
              headerPaidAmount = 0;
              $("input:checkbox[name='selectreciptItem'][value='" + selectedItemData.sales_invoice_details_transaction_id + "']").prop("checked", true);
            }
          } else {
            selectedItemData.item_paid_amount = 0;
            selectedItemData.item_pending_amount = 0;
            selectedItemData.item_billing_deduction = 0;
            $("input:checkbox[name='selectreciptItem'][value='" + selectedItemData.sales_invoice_details_transaction_id + "']").prop("checked", false);
          }
        } else {
          selectedItemData.item_paid_amount = 0;
          selectedItemData.item_pending_amount = 0;
          $("input:checkbox[name='selectreciptItem'][value='" + selectedItemData.sales_invoice_details_transaction_id + "']").prop("checked", false);
        }

        return selectedItemData;
      })]
      return updatedPaymentDetails;
    });
  };

  const FnCalculatePurchaseDetails = () => {
    debugger
    // let headerPaidAmount = document.getElementById("txt_Header_paid_amount").value;
    let headerPaidAmount = $('#txt_Header_paid_amount').val() === '' || $('#txt_Header_paid_amount').val() === '0' ? 0 : $('#txt_Header_paid_amount').val();


    if (CustomerReciptDetailData.length !== 0) {

      const updatedDetailsData = CustomerReciptDetailData.map((item) => {
        item.item_paid_amount = item.item_billing_amount - item.item_prev_paid_amount - item.item_billing_deduction;

        if (headerPaidAmount !== 0) {
          if (headerPaidAmount > item.item_paid_amount) {
            headerPaidAmount -= item.item_paid_amount;
            item.item_pending_amount = 0;
            $("input:checkbox[name='selectreciptItem'][value='" + item.sales_invoice_details_transaction_id + "']").prop("checked", true);
          } else {
            item.item_pending_amount = item.item_paid_amount - headerPaidAmount;
            item.item_paid_amount = headerPaidAmount;
            headerPaidAmount = 0;
            $("input:checkbox[name='selectreciptItem'][value='" + item.sales_invoice_details_transaction_id + "']").prop("checked", true);
          }
          return item; // Ensure you return the updated item in the map function
        } else {
          item.item_paid_amount = 0;
          item.item_pending_amount = 0;
          $("input:checkbox[name='selectreciptItem'][value='" + item.sales_invoice_details_transaction_id + "']").prop("checked", false);
          return item;
        }
      });
      console.log('updatedDetailsData after change of HeaderPaidAmount: ', updatedDetailsData);
      // Update state after the loop
      setCustomerReciptDetailData(updatedDetailsData);
      toggleChkAllBoxes('PartiallyReciptItemSelection')
    }
  };

  // ----------------------------------------------------------------------------------------------------------------------

  const addCustomerRecipt = async (reciptstatus) => {
    debugger
    try {
      const validateForm = await FnValidateSalesrecipt();
      if (validateForm) {
        setIsLoading(true)
        let json = { 'customerSalesReceiptMasterData': {}, 'customerSalesReceiptDetailsData': [], 'commonIds': {} }
        let CustomerContactPersonsIds = FnGetCustomerContactPersonIds();
        let customer_sales_receipt_no = txt_customer_sales_receipt_no;

        if (keyForViewUpdate === '') {
          // let shortname = $('#cmb_customer_sales_receipt_type_id option:selected').attr('shortname');
          customer_sales_receipt_no = await GenerateCustomerSalesReceiptNo();
        }
        const CustomerReciptmasterdata = {
          customer_sales_receipt_master_transaction_id: customer_sales_receipt_master_transaction_id,
          company_id: COMPANY_ID,
          company_branch_id: COMPANY_BRANCH_ID,
          financial_year: FINANCIAL_SHORT_NAME,
          customer_sales_receipt_no: txt_customer_sales_receipt_no,
          customer_sales_receipt_date: txt_customer_sales_receipt_date,
          customer_sales_receipt_version: txt_customer_sales_receipt_version,
          // customer_sales_receipt_type_id: cmb_customer_sales_receipt_type_id,
          // customer_sales_receipt_type: $('#cmb_customer_sales_receipt_type_id').find(":selected").text(),
          customer_id: cmb_customer_id,
          customer_contacts_ids: CustomerContactPersonsIds,
          expected_branch_id: cmb_expected_branch_id,
          approved_by_id: cmb_approved_by_id,
          approved_date: txt_approved_date,
          bank_id: cmb_bank_id,
          receipt_type: chk_receipt_type,
          customer_bank_details: txt_customer_bank_details,
          customer_bank_id: cmb_customer_bank_id,
          customer_bank_account_number: txt_customer_bank_account_number,
          customer_bank_ifsc_code: txt_customer_bank_ifsc_code,
          total_billing_amount: txt_total_billing_amount,
          total_billing_deduction: txt_total_billing_deduction,
          tax1_id: txt_tax1_id,
          tax1_percent: txt_tax1_percent,
          tax1_amount: txt_tax1_amount,
          tax2_id: txt_tax2_id,
          tax2_percent: txt_tax2_percent,
          tax2_amount: txt_tax2_amount,
          total_receipt_amount: txt_total_receipt_amount,
          customer_sales_receipt_status: reciptstatus === 'X' ? reciptstatus : chk_customer_sales_receipt_status,
          receipt_transaction_details: txt_receipt_transaction_details,
          receipt_transaction_date: dt_receipt_transaction_date,
          deduction_remark: txt_deduction_remark,
          remark: txt_remark,
          is_active: chk_CustomerSalesRecipt_isactive,
          created_by: UserName,
          modified_by: keyForViewUpdate === 'cancel' || keyForViewUpdate === 'financeApprove' ? UserName : '',
          customer_receipt_mail_sent_status: chk_recipt_mail_sent_status,
        }
        json.customerSalesReceiptMasterData = CustomerReciptmasterdata;
        console.log('HeaderData: ', json.customerSalesReceiptMasterData);

        for (let custsalesrecipt = 0; custsalesrecipt < CustomerReciptDetailData.length; custsalesrecipt++) {
          const custSalesreciptDetailRow = CustomerReciptDetailData[custsalesrecipt];
          const custsalesreciptRow = {};
          //set values for eact column 
          custsalesreciptRow['customer_sales_receipt_details_transaction_id'] = isApprove || keyForViewUpdate === 'cancel' || keyForViewUpdate === 'financeApprove' ? custSalesreciptDetailRow.customer_sales_receipt_details_transaction_id : 0
          custsalesreciptRow['customer_sales_receipt_master_transaction_id'] = isApprove || keyForViewUpdate === 'cancel' || keyForViewUpdate === 'financeApprove' ? customer_sales_receipt_master_transaction_id : 0
          custsalesreciptRow['company_id'] = COMPANY_ID
          custsalesreciptRow['company_branch_id'] = COMPANY_BRANCH_ID
          custsalesreciptRow['financial_year'] = FINANCIAL_SHORT_NAME
          custsalesreciptRow['customer_sales_receipt_no'] = txt_customer_sales_receipt_no
          custsalesreciptRow['customer_sales_receipt_date'] = txt_customer_sales_receipt_date
          custsalesreciptRow['customer_sales_receipt_version'] = txt_customer_sales_receipt_version
          custsalesreciptRow['sales_order_no'] = custSalesreciptDetailRow.sales_order_no
          custsalesreciptRow['sales_invoice_no'] = custSalesreciptDetailRow.sales_invoice_no
          custsalesreciptRow['dispatch_challan_no'] = custSalesreciptDetailRow.dispatch_challan_no
          custsalesreciptRow['expected_schedule_date'] = custSalesreciptDetailRow.expected_schedule_date
          custsalesreciptRow['payment_due_date'] = custSalesreciptDetailRow.payment_due_date
          custsalesreciptRow['item_billing_amount'] = custSalesreciptDetailRow.item_billing_amount
          custsalesreciptRow['item_billing_deduction'] = custSalesreciptDetailRow.item_billing_deduction
          custsalesreciptRow['item_paid_amount'] = custSalesreciptDetailRow.item_paid_amount
          custsalesreciptRow['sales_order_date'] = custSalesreciptDetailRow.sales_order_Date
          custsalesreciptRow['sales_order_version'] = custSalesreciptDetailRow.sales_order_version
          custsalesreciptRow['sales_invoice_date'] = custSalesreciptDetailRow.sales_invoice_date
          custsalesreciptRow['sales_invoice_version'] = custSalesreciptDetailRow.sales_invoice_version
          custsalesreciptRow['dispatch_challan_date'] = custSalesreciptDetailRow.dispatch_challan_date
          custsalesreciptRow['dispatch_challan_version'] = custSalesreciptDetailRow.dispatch_challan_version
          custsalesreciptRow['customer_sales_item_receipt_status'] = reciptstatus === 'P' ? reciptstatus : custSalesreciptDetailRow.customer_sales_item_receipt_status
          custsalesreciptRow['sales_invoice_item_status'] = custSalesreciptDetailRow.sales_invoice_item_status
          custsalesreciptRow['remark'] = custSalesreciptDetailRow.remark
          custsalesreciptRow['is_active'] = chk_CustomerSalesRecipt_isactive
          custsalesreciptRow['created_by'] = keyForViewUpdate === '' ? UserName : custSalesreciptDetailRow.created_by
          custsalesreciptRow['sales_invoice_details_transaction_id'] = custSalesreciptDetailRow.sales_invoice_details_transaction_id
          custsalesreciptRow['modified_by'] = keyForViewUpdate === 'cancel' || keyForViewUpdate === 'financeApprove' ? UserName : ''
          json.customerSalesReceiptDetailsData.push(custsalesreciptRow);
        }
        // Common Ids  
        json.commonIds.company_id = COMPANY_ID
        json.commonIds.customer_sales_receipt_no = txt_customer_sales_receipt_no
        json.commonIds.financial_year = FINANCIAL_SHORT_NAME

        console.log("json of Custome Sales Receipt New: ", json);

        let isApproveOrCancel = 'AddUpdate';
        if (keyForViewUpdate === 'financeApprove') {
          isApproveOrCancel = 'financeApprove';
        } else if (keyForViewUpdate === 'cancel') {
          isApproveOrCancel = 'cancel';
        }
        const formData = new FormData();
        formData.append(`ptCustomerSalesReceiptData`, JSON.stringify(json))
        const requestOptions = {
          method: 'POST',
          body: formData
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtCustomerSalesReceiptMaster/FnAddUpdateRecord/${isApproveOrCancel}`, requestOptions);
        const response = await apicall.json();

        if (response.success === "0") {
          setErrMsg(response.error)
          setShowErrorMsgModal(true)
        } else {
          const evitCache = comboDataAPiCall.current.evitCache();
          console.log(evitCache);
          setMessage(response.message)
          if (response.data.hasOwnProperty('customer_sales_receipt_no') && response.data.hasOwnProperty('customer_sales_receipt_version')) {
            setModalOrderDetails(`Customer Sales Receipt No:${response.data.customer_sales_receipt_no} and Version: ${response.data.customer_sales_receipt_version}`)
          }
          if (chk_customer_sales_receipt_status !== 'P' && keyForViewUpdate === 'financeApprove') {
            setShowMailSentModal(true)
          } else if (customer_sales_receipt_master_transaction_id === 0) {
            setMessageForConfirmation('Do you want to export this Sales!...')
            setShowConfirmationModal(true);
          } else {
            setMessageForConfirmation('')
            setShowConfirmationModal(true);
          }
        }
      }
    } catch (error) {
      console.log('error on addBill: ', error);
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  }




  const FnCheckUpdateResponse = async () => {
    debugger
    let finnancialYear = idList.financial_year;
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtCustomerSalesReceiptMaster/FnShowAllDetailsAndMastermodelRecords/${txt_customer_sales_receipt_version}/${finnancialYear}/${COMPANY_ID}?customer_sales_receipt_no=${txt_customer_sales_receipt_no}`)
      const response = await apiCall.json();
      if (response.CustomerSalesReceiptMasterRecord !== null) {
        let CustomerReciptMasterData = response.CustomerSalesReceiptMasterRecord;
        let CustomerReciptDetailsRecords = response.CustomerSalesReceiptDetailsRecord;

        let {
          customer_sales_receipt_master_transaction_id, customer_sales_receipt_no, customer_sales_receipt_date,
          customer_sales_receipt_version, customer_sales_receipt_type_id, customer_sales_receipt_type, customer_id, customer_contacts_ids, expected_branch_id, approved_by_id, approved_date,
          bank_id, receipt_type, customer_bank_details, customer_bank_id, customer_bank_account_number, customer_bank_ifsc_code,
          total_billing_amount, total_billing_deduction, tax1_id, tax1_percent, tax1_amount, tax2_id, tax2_percent, tax2_amount, total_receipt_amount, customer_sales_receipt_status,
          receipt_transaction_details, receipt_transaction_date, deduction_remark, remark, is_active,
        } = CustomerReciptMasterData;

        setcustomer_sales_receipt_master_transaction_id(customer_sales_receipt_master_transaction_id)
        setcustomer_sales_receipt_no(customer_sales_receipt_no)
        setcustomer_sales_receipt_version(customer_sales_receipt_version)
        setcustomer_sales_receipt_date(customer_sales_receipt_date)
        // setcustomer_sales_receipt_type_id(customer_sales_receipt_type_id)
        // (customer_sales_receipt_type)
        setcustomer_id(customer_id)
        await comboOnChange("customer");
        setcustomer_contacts_ids(customer_contacts_ids)
        setexpected_branch_id(expected_branch_id)
        setApproved_by_id(approved_by_id)
        setApproved_date(approved_date)
        setbank_id(bank_id)
        setreceipt_type(receipt_type)
        setcustomer_bank_details(customer_bank_details)
        setcustomer_bank_id(customer_bank_id)
        setcustomer_bank_account_number(customer_bank_account_number)
        setcustomer_bank_ifsc_code(customer_bank_ifsc_code)
        settotal_billing_amount(total_billing_amount)
        settotal_billing_deduction(total_billing_deduction)
        setTax1_id(tax1_id)
        setTax1_percent(tax1_percent)
        setTax1_amount(tax1_amount)
        setTax2_id(tax2_id)
        setTax2_percent(tax2_percent)
        setTax2_amount(tax2_amount)
        settotal_receipt_amount(total_receipt_amount)
        setcustomer_sales_receipt_status(customer_sales_receipt_status)
        setreceipt_transaction_details(receipt_transaction_details)
        setreceipt_transaction_date(receipt_transaction_date)
        setDeduction_remark(deduction_remark)
        setRemark(remark)
        setCustomerSalesRecipt_isactive(is_active)

        if (customer_contacts_ids !== '') {
          FnSetExistingCoustomerContactPersonIds(customer_contacts_ids)
        }
        if (keyForViewUpdate === 'financeApprove') {
          setApproved_by_id(UserId)
          setApproved_date(currentDate)
        } else {
          setApproved_by_id(approved_by_id)
          setApproved_date(approved_date)
        }
        if (keyForViewUpdate === 'cancel' || keyForViewUpdate === 'financeApprove') {
          setcustomer_sales_receipt_status(keyForViewUpdate === 'cancel' ? 'X' : 'F')

          const updatedCustomerReciptDetailsRecords = CustomerReciptDetailsRecords.map(item => {
            const invoiceStatus = '';
            // Update the keys you want to modify
            if (keyForViewUpdate === 'financeApprove') {
              const isPending = FngetInvoiceStatus(item);
              if (isPending) {
                invoiceStatus = "R";
              } else {
                invoiceStatus = "C";
              }
            }
            return {
              ...item,
              sales_invoice_item_status: invoiceStatus,
              customer_sales_item_receipt_status: keyForViewUpdate === 'cancel' ? 'X' : 'F',
            };
          });
          setCustomerReciptDetailData(updatedCustomerReciptDetailsRecords)
        } else {
          setCustomerReciptDetailData(CustomerReciptDetailsRecords)
        }

        let outstandingAmt = 0;
        let headerPaidAmt = 0;
        debugger
        CustomerReciptDetailsRecords.forEach(element => {
          if (element.item_paid_amount > 0) {
            // $("input:checkbox[name=selectreciptItem][value='" + element.sales_invoice_details_transaction_id + "']").prop('checked', true);
            $(`#selectreciptItem_${element.sales_invoice_details_transaction_id}`).prop('checked', true);
          }
          outstandingAmt = outstandingAmt + element.item_billing_amount;
          headerPaidAmt = headerPaidAmt + element.item_paid_amount;
        });

        setTotal_outstanding(validateNumberDateInput.current.decimalNumber((outstandingAmt).toString(), 4))
        setHeaderPaid_amount(validateNumberDateInput.current.decimalNumber((headerPaidAmt).toString(), 4))
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }
  const FngetInvoiceStatus = (item) => {
    // const item_billing_amount = item.item_billing_amount;
    let isPending = false;
    const pendingAmt = parseFloat(item.item_pending_amount);
    if (pendingAmt > 0) {
      isPending = true;
    }
    return isPending;
  }
  // ----------------------------------------------------------------------------------------------------------------------



  // navigate to back listing page 
  const moveToListing = () => {
    const Path = compType === 'Register' ? '/Transactions/TPurchaseOrder/TPurchasePaymentCustomer/FrmCustomerSalesReceiptListing/reg' : `/Transactions/TPurchaseOrder/TPurchasePaymentCustomer/FrmCustomerSalesReceiptListing`;
    navigate(Path);

  }

  // ----------------------------------------------------------------------------------------------------------------------
  //validate po form 
  const FnValidateSalesrecipt = async () => {

    let headerDataIsValid = await validate.current.validateForm("customer_sales_receipt_header_formId");//billBook_footer_formId
    if (!headerDataIsValid) { return false; }
    if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
      $('#error_customer_contact_persons').text('Please select atleast one contact person.');
      $('#error_customer_contact_persons').show();
      setErrMsg('Please select atleast one contact person...!');
      setShowErrorMsgModal(true);
      setOpenContactsAccord(true);
      // $('#chk_billBook_status').focus();
      return false;
    } else {
      $('#error_customer_contact_persons').hide();
    }
    if (parseFloat(txt_Header_paid_amount) > parseFloat(txt_total_outstanding)) {
      $('#error_txt_Header_paid_amount').text('Please Enter Less Amount Than Outstanding Amount...!');
      $('#error_txt_Header_paid_amount').show();
      setHeaderPaid_amount(0)
      return false;
    } else if (parseFloat(txt_Header_paid_amount) === 0) {
      $('#error_txt_Header_paid_amount').text('Please Enter Greater Amount Than 0...!');
      $('#error_txt_Header_paid_amount').show();
      setHeaderPaid_amount(0)
      return false;
    } else if (parseFloat(txt_Header_paid_amount) === '') {
      $('#error_txt_Header_paid_amount').text('Please Enter Valid Amount...!');
      $('#error_txt_Header_paid_amount').show();
      setHeaderPaid_amount('')
      return false;
    } else {
      $('#error_txt_Header_paid_amount').hide();
      setHeaderPaid_amount(txt_Header_paid_amount)
    }
    let salesReciptDetailsIsValid = true;

    if (txt_customer_sales_receipt_date === '') {
      $('#error_txt_customer_sales_receipt_date').text('Please fill this field...!');
      $('#error_txt_customer_sales_receipt_date').show();
      return salesReciptDetailsIsValid = false;
    }
    if (txt_total_billing_deduction > 0 && salesReciptDetailsIsValid) {
      if (parseInt(txt_total_billing_deduction) > parseInt(txt_total_billing_amount)) {
        $('#error_txt_total_billing_deduction').text('Please Enter Less then Total Billing Amount...!');
        $('#error_txt_total_billing_deduction').show();
        return salesReciptDetailsIsValid = false;

      } else if (txt_deduction_remark === '') {
        $('#error_txt_deduction_remark').text('Please Enter Deduction Remark...!');
        $('#error_txt_deduction_remark').show();
        return salesReciptDetailsIsValid = false;
      }
    }
    return salesReciptDetailsIsValid;
  }

  // ------------------------------------------------------------------------------------------------------------------------------
  const FnValidatePaidAmt = (e) => {
    debugger
    if (e.target.value === "") {
      $('#error_txt_Header_paid_amount').text('Please fill this field...!');
      $('#error_txt_Header_paid_amount').show();
      setHeaderPaid_amount('')
    } else if (parseFloat(e.target.value) === 0) {
      $('#error_txt_Header_paid_amount').text('Please Enter Greater Than 0...!');
      $('#error_txt_Header_paid_amount').show();
      setHeaderPaid_amount(0)
    } else if (parseFloat(e.target.value) > parseFloat(txt_total_outstanding)) {
      $('#error_txt_Header_paid_amount').text('Please Enter Less Than Outstanding Amount...!');
      $('#error_txt_Header_paid_amount').show();
      setHeaderPaid_amount(0)
    } else {
      $('#error_txt_Header_paid_amount').hide();
      setHeaderPaid_amount(e.target.value)
    }
  }


  // ------------------------------------------------------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------------------------------------------------------
  //--------------------------------------- Payment EXcelToExport Start ----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  const ExportToExcel = () => {
    debugger
    const catCount = sessionStorage.getItem('compRMCatCount')
    var counter = 1;
    if (CustomerReciptDetailData.length !== 0) {
      var jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', editable_cols: [] }
      // jsonToExportExcel.filtrKeyValue['0'] = "Product Type" + ' : ' + $('#cmb_customer_sales_receipt_type_id').find(":selected").text() + "(" + cmb_customer_sales_receipt_type_id + ")";
      jsonToExportExcel.filtrKeyValue['0'] = "Customer Sales Receipt No" + ' : ' + txt_customer_sales_receipt_no;
      jsonToExportExcel.filtrKeyValue['1'] = "Customer Sales Receipt Version" + ' : ' + txt_customer_sales_receipt_version;
      jsonToExportExcel.filtrKeyValue['2'] = "Customer Sales Receipt Date" + ' : ' + validateNumberDateInput.current.formatDateToDDMMYYYY(txt_customer_sales_receipt_date);
      jsonToExportExcel.filtrKeyValue['3'] = "Customer" + ' : ' + $('#cmb_customer_id').find(":selected").text() + "(" + cmb_customer_id + ")";
      jsonToExportExcel.filtrKeyValue['4'] = "Customer Bank" + ' : ' + (cmb_customer_bank_id !== "" ? $('#cmb_customer_bank_id').find(":selected").text() + "(" + cmb_customer_bank_id + ")" : "");
      jsonToExportExcel.filtrKeyValue['5'] = "Customer Bank Account No" + ' : ' + txt_customer_bank_account_number;
      jsonToExportExcel.filtrKeyValue['6'] = "Customer Bank IFSC Code" + ' : ' + txt_customer_bank_ifsc_code;
      jsonToExportExcel.filtrKeyValue['7'] = "Receipt Type" + ' : ' + $('#chk_receipt_type').find(":selected").text() + "(" + chk_receipt_type + ")";
      jsonToExportExcel.filtrKeyValue['8'] = "Receipt Details" + ' : ' + txt_receipt_transaction_details;
      jsonToExportExcel.filtrKeyValue['9'] = "Receipt Date : " + validateNumberDateInput.current.formatDateToDDMMYYYY(dt_receipt_transaction_date);
      jsonToExportExcel.filtrKeyValue['10'] = "Company Branch" + ' : ' + $('#cmb_expected_branch_id').find(":selected").text() + "(" + cmb_expected_branch_id + ")";
      jsonToExportExcel.filtrKeyValue['11'] = "Approved By : " + (cmb_approved_by_id === '0' || cmb_approved_by_id === '' || cmb_approved_by_id === null ? '' : $('#cmb_approved_by_id').find(":selected").text() + "(" + cmb_approved_by_id + ")");
      jsonToExportExcel.filtrKeyValue['12'] = "Approved Date : " + validateNumberDateInput.current.formatDateToDDMMYYYY(txt_approved_date);
      jsonToExportExcel.filtrKeyValue['13'] = "Purchase Payment Status : " + $('#chk_customer_sales_receipt_status').find(":selected").text() + "(" + chk_customer_sales_receipt_status + ")";
      jsonToExportExcel.filtrKeyValue['14'] = "Total OutStanding" + ' : ' + txt_total_outstanding;
      jsonToExportExcel.filtrKeyValue['15'] = "Total Paid Amount" + ' : ' + txt_Header_paid_amount;
      jsonToExportExcel.filtrKeyValue['16'] = "Remark" + ' : ' + txt_remark;
      jsonToExportExcel.filtrKeyValue['17'] = "Mail Status" + ' : ' + $('input[name="chk_recipt_mail_sent_status"]:checked').attr("lbl") + "(" + chk_recipt_mail_sent_status + ")";
      let colNames = [
        "sr_no",
        "sales_order_no",
        "sales_invoice_no",
        "expected_schedule_date",
        "payment_due_date",
        "item_billing_amount",
        "item_prev_paid_amount",
        "item_billing_deduction",
        "item_paid_amount",
        "item_pending_amount",
        "customer_sales_item_receipt_status",
        "dispatch_challan_no",
        'remark',
        "sales_order_Date",
        "sales_order_version",
        "sales_invoice_date",
        "sales_invoice_version",
        "dispatch_challan_date",
        "dispatch_challan_version"
      ];
      for (let col = 0; col < colNames.length; col++) {
        jsonToExportExcel.columns.push({ "Headers": colNames[col], "accessors": colNames[col] })
      }
      CustomerReciptDetailData.map((orderDetail, Index) => {
        const keysConvertedOrderDetail = {
          ...orderDetail,
          sr_no: Index + 1,
        };
        jsonToExportExcel['allData'][Index] = keysConvertedOrderDetail;
      });
      jsonToExportExcel['headings']['ReportName'] = "Customer Sales Recipt"
      jsonToExportExcel['headings']['CompanyName'] = sessionStorage.getItem('companyLegalName')
      jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
      console.log("jsonToExportExcel: ", jsonToExportExcel)
      exlsExp.current.excel(jsonToExportExcel, txt_customer_sales_receipt_no + "@" + COMPANY_ID)
    } else {
      setErrMsg('Please add at least one material!... ')
      setShowErrorMsgModal(true)
    }
  }
  // --------------------------------------- PO EXcelToExport End ------------------------------------------------------------------

  // -----------------------------------------------Receipt Email starts------------------------------------------------------------------------------------

  const sendEmail = async () => {
    try {
      setIsLoading(true);
      setShowMailSentModal(false);
      $('#spinner_text').text('Sending Email...');
      const EmailData = await FngenerateMailJson();

      // Generate the sales-order PDF.
      let customerReciptContent = await printInvoice(false);
      const fileName = `${txt_customer_sales_receipt_no.replaceAll('/', '_')}_@${COMPANY_ID}.pdf`;

      const customerReciptContainer = document.createElement('div');
      customerReciptContainer.style.margin = '20px';
      customerReciptContainer.innerHTML = customerReciptContent;
      let worker = html2pdf().from(customerReciptContainer).set({
        margin: 0,
        filename: fileName,
        html2canvas: { scale: 2 },
        jsPDF: { orientation: 'portrait', unit: 'pt', format: 'a4', compressPDF: true }
      }).toPdf();

      let pages = [];
      pages.slice(1).forEach(function (page) {
        worker = worker.get('pdf').then(function (pdf) {
          pdf.addPage();
        }).from(page).toContainer().toCanvas().toPdf();
      });

      worker = worker.output("datauristring").then(async function (pdf) {
        const preBlob = dataURItoBlob(pdf);
        const file = new File([preBlob], fileName, { type: 'application/pdf' });

        const formData = new FormData();
        formData.append(`EmailData`, JSON.stringify(EmailData))
        formData.append("customerSalesReceiptPDF", file);

        const requestOptions = { method: 'POST', body: formData };
        const emailingApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtCustomerSalesReceiptMaster/FnSendEmail/${customer_sales_receipt_master_transaction_id}/${COMPANY_ID}`, requestOptions);
        const emailingApiCallResponse = await emailingApiCall.json();

        if (emailingApiCallResponse.success === "1") {
          // Show success message.
          setMessage(emailingApiCallResponse.message)
          setMessageForConfirmation('')
          setShowConfirmationModal(true);
        } else {
          // Show error message.
          setErrMsg(emailingApiCallResponse.message);
          setShowErrorMsgModal(true);
        }
        setIsLoading(false);
      });
    } catch (error) {
      console.log('Error in email sending: ', error);
      navigate('/Error')
    }
  }
  const FngenerateMailJson = async () => {
    let EmailData = { 'EmailType': "Customer Receipt Success", 'toMailData': [], 'bccData': [], 'ccData': [], 'subject': {}, 'mailAttachmentFilePaths': [], 'parameters': [] }
    const checkboxes = $('.selectCustContactPerson:checked');
    checkboxes.each(function () {
      let selectedContact_id = parseInt($(this).val());
      const customerContact = CustContactDetails.find(contactPerson => contactPerson.customer_contact_id === selectedContact_id);
      if (customerContact) {
        EmailData.toMailData.push(customerContact.cust_email_id);
      }
    });
    EmailData.subject = `Customer Receipt Sent ${txt_customer_sales_receipt_no}`;    // Subject
    EmailData.parameters.push("Customer Receipt Information");                            // Param0 It is a heading.
    EmailData.parameters.push($('#cmb_customer_id').find(":selected").text());          // Param1
    EmailData.parameters.push(`${txt_customer_sales_receipt_no}`);                                          // Param2
    EmailData.parameters.push($('#chk_customer_sales_receipt_status').find(":selected").text());                   // Param3
    EmailData.parameters.push(`${txt_customer_sales_receipt_date}`);                                        // Param4
    EmailData.parameters.push(sessionStorage.getItem('companyLegalName'));              // Param6
    EmailData.parameters.push(sessionStorage.getItem('companyAddress'));                // Param7
    EmailData.parameters.push($('#cmb_approved_by_id').find(":selected").text());       // Param8
    console.log('emailData: ', EmailData);
    return EmailData;
  }
  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  // -----------------------------------------------Receipt Email Ends------------------------------------------------------------------------------------

  const purchaseReciptInvoiceContent = {
    company: {
      company: '',
      company_branch: '',
      phone: '',
      city_name: '',
      state_name: '',
      company_pincode: '',
      bank_branch_name: '',
      bank_account_no: '',
      bank_ifsc_code: '',

    },
    customerDetails: {
      name: '',
      address: '',
      state: '',
      GSTIN: '',
      pan_no: '',
      contact: '',
      customer_bank_name: '',
      customer_bank_account_number: '',
      customer_bank_ifsc_code: '',
      cust_branch_EmailId: '',
      customer_transaction_details: '',
      customer_transaction_date: '',
      customer_sales_recipt_type: '',
    },

    ourDetails: {
      bank_name: '',
      bank_branch_name: '',
      bank_account_no: '',
      total_outstanding: '',
      total_paid_amount: '',
    },

    reciptDetails: {
      customer_sales_receipt_no: '',
      customer_sales_receipt_date: '',
      customer_sales_receipt_version: ''
    },

    footer: {
      total_billing_amount_words: '',
      total_billing_deduction_words: '',
      total_paid_amount_words: '',
      total_billing_amount: '',
      total_billing_deduction: '',
      tax1_percent: '',
      tax1_amount: '',
      tax2_percent: '',
      tax2_amount: '',
      taxtype_name1: '',
      taxtype_name2: '',
      total_paid_amount: '',
      deduction_remark: '',
      total_receipt_amount: '',
    },

    items: [],
    // paymentTerms: [],
  };

  const printInvoice = async (openPrintModal) => {

    let fin_year = idList.financial_year;
    let sales_receipt_version = idList.customer_sales_receipt_version;

    setcustomer_sales_receipt_no(idList.customer_sales_receipt_no)
    setcustomer_sales_receipt_version(idList.customer_sales_receipt_version)
    console.log(idList.customer_sales_receipt_version)
    console.log(idList.customer_sales_receipt_no)


    const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtCustomerSalesReceiptMaster/FnShowAllDetailsAndMastermodelRecords/${sales_receipt_version}/${fin_year}/${COMPANY_ID}?customer_sales_receipt_no=${idList.customer_sales_receipt_no}`)
    const response = await apiCall.json();
    console.log("Response Sales Invoice : ", response);

    let SalesReceiptMasterData = response.CustomerSalesReceiptMasterRecord;
    let SalesReceiptDetailsData = response.CustomerSalesReceiptDetailsRecord;

    //Purchase details     
    purchaseReciptInvoiceContent.reciptDetails.customer_sales_receipt_no = SalesReceiptMasterData.customer_sales_receipt_no
    purchaseReciptInvoiceContent.reciptDetails.customer_sales_receipt_date = validateNumberDateInput.current.formatDateToDDMMYYYY(SalesReceiptMasterData.customer_sales_receipt_date)
    purchaseReciptInvoiceContent.reciptDetails.customer_sales_receipt_version = SalesReceiptMasterData.customer_sales_receipt_version

    //Company details
    purchaseReciptInvoiceContent.company.company = SalesReceiptMasterData.company_name
    purchaseReciptInvoiceContent.company.company_branch = SalesReceiptMasterData.company_branch_name
    purchaseReciptInvoiceContent.company.phone = SalesReceiptMasterData.expected_phone_no
    purchaseReciptInvoiceContent.company.pan_no = SalesReceiptMasterData.expected_branch_city
    purchaseReciptInvoiceContent.company.state_name = SalesReceiptMasterData.expected_branch_state
    purchaseReciptInvoiceContent.company.company_pincode = SalesReceiptMasterData.expected_pincode

    //ourDetails
    purchaseReciptInvoiceContent.ourDetails.bank_name = SalesReceiptMasterData.bank_name
    purchaseReciptInvoiceContent.ourDetails.bank_branch_name = SalesReceiptMasterData.bank_branch_name
    purchaseReciptInvoiceContent.ourDetails.bank_account_no = SalesReceiptMasterData.bank_account_no

    purchaseReciptInvoiceContent.ourDetails.total_outstanding = $("#txt_total_outstanding").val();
    purchaseReciptInvoiceContent.ourDetails.total_paid_amount = $("#txt_Header_paid_amount").val();

    //customer details
    purchaseReciptInvoiceContent.customerDetails.name = SalesReceiptMasterData.customer_name
    // purchaseReciptInvoiceContent.customerDetails.GSTIN = SalesReceiptMasterData.cust_branch_gst_no
    // purchaseReciptInvoiceContent.customerDetails.pan_no = SalesReceiptMasterData.cust_branch_pan_no
    purchaseReciptInvoiceContent.customerDetails.contact = SalesReceiptMasterData.cust_branch_phone_no

    purchaseReciptInvoiceContent.customerDetails.customer_bank_name = SalesReceiptMasterData.customer_bank_name
    purchaseReciptInvoiceContent.customerDetails.customer_bank_account_number = SalesReceiptMasterData.customer_bank_account_number
    purchaseReciptInvoiceContent.customerDetails.customer_bank_ifsc_code = SalesReceiptMasterData.customer_bank_ifsc_code
    purchaseReciptInvoiceContent.customerDetails.cust_branch_EmailId = SalesReceiptMasterData.cust_branch_EmailId
    purchaseReciptInvoiceContent.customerDetails.receipt_transaction_details = SalesReceiptMasterData.receipt_transaction_details
    purchaseReciptInvoiceContent.customerDetails.receipt_transaction_date = validateNumberDateInput.current.formatDateToDDMMYYYY(SalesReceiptMasterData.receipt_transaction_date)
    purchaseReciptInvoiceContent.customerDetails.receipt_type_desc = SalesReceiptMasterData.receipt_type_desc

    //footer details 
    purchaseReciptInvoiceContent.footer.total_billing_amount = SalesReceiptMasterData.total_billing_amount
    purchaseReciptInvoiceContent.footer.total_billing_deduction = SalesReceiptMasterData.total_billing_deduction
    purchaseReciptInvoiceContent.footer.tax1_percent = SalesReceiptMasterData.tax1_percent
    purchaseReciptInvoiceContent.footer.tax1_amount = SalesReceiptMasterData.tax1_amount
    purchaseReciptInvoiceContent.footer.tax2_percent = SalesReceiptMasterData.tax2_percent
    purchaseReciptInvoiceContent.footer.tax2_amount = SalesReceiptMasterData.tax2_amount
    purchaseReciptInvoiceContent.footer.total_paid_amount = SalesReceiptMasterData.total_paid_amount
    purchaseReciptInvoiceContent.footer.deduction_remark = SalesReceiptMasterData.deduction_remark
    purchaseReciptInvoiceContent.footer.total_receipt_amount = SalesReceiptMasterData.total_receipt_amount
    purchaseReciptInvoiceContent.footer.taxtype_name1 = SalesReceiptMasterData.taxtype_name1
    purchaseReciptInvoiceContent.footer.taxtype_name2 = SalesReceiptMasterData.taxtype_name2


    for (let index = 0; index < SalesReceiptDetailsData.length; index++) {
      const element = SalesReceiptDetailsData[index];

      const detailsData = {
        sr_no: index + 1,
        customer_sales_receipt_no: element.customer_sales_receipt_no,
        customer_sales_receipt_date: validateNumberDateInput.current.formatDateToDDMMYYYY(element.customer_sales_receipt_date),
        sales_order_no: element.sales_order_no,
        dispatch_challan_no: element.dispatch_challan_no,
        dispatch_challan_date: validateNumberDateInput.current.formatDateToDDMMYYYY(element.dispatch_challan_date),
        sales_invoice_no: element.sales_invoice_no,
        sales_invoice_date: validateNumberDateInput.current.formatDateToDDMMYYYY(element.sales_invoice_date),
        payment_due_date: validateNumberDateInput.current.formatDateToDDMMYYYY(element.payment_due_date),
        item_prev_paid_amount: element.item_prev_paid_amount,
        item_pending_amount: element.item_pending_amount,
        item_billing_deduction: element.item_billing_deduction,
        item_paid_amount: element.item_paid_amount,
        item_billing_amount: element.item_billing_amount,
        // total_receipt_amount:element.total_receipt_amount,
      }
      purchaseReciptInvoiceContent.items.push(detailsData)
    }


    if (openPrintModal) {
      navigate('/Invoice', { state: { invoiceData: purchaseReciptInvoiceContent, navigationLink: `/Transactions/TPurchaseOrder/TPurchasePaymentCustomer/FrmCustomerSalesReceiptEntry`, invoiceType: 'CSR', title: 'Customer Sales Receipt', idList: idList, keyForViewUpdate: keyForViewUpdate } });

    } else {
      // return the html code.
      return renderToString(<CustomerSalesReciptInvoice invoiceContent={purchaseReciptInvoiceContent} />);
    }

  };


  // ------------------------------------------------------------------------------------------------------------------------------------


  const validateAccNo = (noKey) => {
    const regexNo = /^[0-9\b]+$/;
    const value = noKey.target.value
    switch (noKey.target.id) {
      case 'txt_customer_bank_account_number':
        if (regexNo.test(value) || value === '') {
          setcustomer_bank_account_number(value)
        }
        break;
    }
  }
  //------------------------------------------ Clear Form Fields -------------------------------------------------------------------------------------------------
  const FnClearFormFields = async () => {
    // //cleared master hooks
    setcustomer_sales_receipt_master_transaction_id(0);
    // setcustomer_sales_receipt_no("0");
    await GenerateCustomerSalesReceiptNo();
    setcustomer_sales_receipt_version(1);
    // setcustomer_sales_receipt_type_id("");
    setcustomer_sales_receipt_date("");
    setcustomer_id("");
    setcustomer_contacts_ids("");
    setexpected_branch_id("");
    setApproved_by_id("");
    setApproved_date("");
    setbank_id("");
    setreceipt_type("1");
    setcustomer_bank_details("");
    setcustomer_bank_id("")
    setcustomer_bank_account_number("");
    setcustomer_bank_ifsc_code("");
    setcustomer_sales_receipt_status("P");
    setreceipt_transaction_date("");
    setreceipt_transaction_details("");
    setTotal_outstanding("0");
    setHeaderPaid_amount("0");
    setRecipt_mail_sent_status("P");
    settotal_billing_amount(0);
    settotal_billing_deduction(0);
    setTax1_id("");
    setTax1_percent(0);
    setTax1_amount(0);
    setTax2_id("");
    setTax2_percent(0);
    setTax2_amount(0);
    settotal_receipt_amount(0);
    setDeduction_remark("");
    setCustomerSalesRecipt_isactive(true);
    setRemark("");

    setcustomerBankOptions([])
    setCustContactDetails([])
    setCustomerReciptDetailData([])
  }
  // ------------------------------------------------------------------------------------------------------------------------------------

  async function deleteSalesReceipt() {
    try {
      const method = { method: 'DELETE' }
      const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtCustomerSalesReceiptMaster/FnDeleteRecord/${idList.supplier_purchase_payment_version}/${COMPANY_ID}?customer_sales_receipt_no=${idList.customer_sales_receipt_no}&user_name=${UserName}`, method)
      const responce = await deleteApiCall.json();
      console.log("Payment Transaction Deleted: ", responce);
      setShowDeleteModal(false)
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }



  return (
    <>
      <ComboBox ref={comboDataAPiCall} />
      <ExcelExport ref={exlsExp} />
      <GenerateTAutoNo ref={generateAutoNoAPiCall} />
      <FrmValidations ref={validate} />
      <ValidateNumberDateInput ref={validateNumberDateInput} />
      <DashboardLayout>
        {
          isLoading ?
            <div className="spinner-overlay">
              < div className="spinner-container" >
                <CircularProgress color="primary" />
                <span id="spinner_text" className="text-dark">Loading...</span>
              </div >
            </div > :
            ''
        }
        <div className='main_heding'>
          <label className='erp-form-label-lg main_heding'>
            Customer Sales Receipt {ActionType()}</label>
        </div>
        <div className='card p-4'>
          <form id="customer_sales_receipt_header_formId">
            <div className='row'>
              {/* First column */}

              <div className="col-sm-6 erp_form_col_div">
                {/* <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Customer Sales Receipt Type <span className="required">*</span>  </Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_customer_sales_receipt_type_id" className="form-select form-select-sm" value={cmb_customer_sales_receipt_type_id} onChange={(e) => { setcustomer_sales_receipt_type_id(e.target.value); comboOnChange('productType'); validateFields('customer_sales_receipt_header_formId'); }}  >
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {producttypeOptions.length !== 0 ?
                        <>
                          {producttypeOptions?.map(poTypeId => (
                            <option value={poTypeId.field_id} shortname={poTypeId.product_type_short_name}>{poTypeId.field_name}</option>
                          ))}
                        </>
                        : ''
                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_customer_sales_receipt_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div> */}


                <div className='row'>
                  <div className='col-sm-4 col-12'>
                    <Form.Label className="erp-form-label">Customer Sales Receipt No,Version & Date</Form.Label>
                  </div>
                  <div className='col-sm-8 col-12'>
                    <div className="row">
                      <div className='col-12 col-md-6 pe-md-0'>
                        <Form.Control type="text" id="txt_customer_sales_receipt_no" className="erp_input_field " value={txt_customer_sales_receipt_no} optional='optional' disabled />

                      </div>
                      <div className="col-12 col-md-2 pt-md-0 pt-3">
                        <Form.Control type="text" id='txt_customer_sales_receipt_version' className="erp_input_field text-end" value={txt_customer_sales_receipt_version} optional='optional' disabled />

                      </div>
                      <div className="col-12 col-md-4 pt-md-0 pt-3">
                        <Form.Control type="date" id='txt_customer_sales_receipt_date' className="erp_input_field" value={txt_customer_sales_receipt_date} onChange={e => { setcustomer_sales_receipt_date(e.target.value); validateFields('customer_sales_receipt_header_formId'); }} min={currentDate} />
                        <MDTypography variant="button" id="error_txt_customer_sales_receipt_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4 col-12">
                    <Form.Label className="erp-form-label">Receipt Type & Date<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col-sm-4 col-12'>
                    <select id="chk_receipt_type" className="form-select form-select-sm " value={chk_receipt_type} onChange={(e) => { setreceipt_type(e.target.value); validateFields('customer_sales_receipt_header_formId'); }}  >
                      <option value="1">Cash</option>
                      <option value="2">Cheque</option>
                      <option value="3">Net Banking(NEFT/RTGS)</option>
                      <option value="4">UPI</option>
                    </select>
                  </div>
                  <div className="col-sm-4 col-12">
                    <Form.Control type="date" id='dt_receipt_transaction_date' className="erp_input_field" value={dt_receipt_transaction_date} onChange={(e) => { setreceipt_transaction_date(e.target.value); validateFields('customer_sales_receipt_header_formId'); }} />
                    <MDTypography variant="button" id="error_dt_receipt_transaction_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Receipt Transaction Details<span className="required">*</span></Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control as="textarea" rows={1} id="txt_receipt_transaction_details" className="erp_txt_area" value={txt_receipt_transaction_details} onChange={e => { setreceipt_transaction_details(e.target.value); validateFields('customer_sales_receipt_header_formId'); }} maxlength="1000" />
                    <MDTypography variant="button" id="error_txt_receipt_transaction_details" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                {/* customer_id */}
                <div className='row'>
                  <div className='col-sm-4 col-12'>
                    <Form.Label className="erp-form-label">Customer <span className="required">*</span> </Form.Label>
                  </div>
                  <div className='col-sm-7 col-10'>
                    <select id="cmb_customer_id" className="form-select form-select-sm" value={cmb_customer_id} onChange={() => { comboOnChange("customer"); validateFields('customer_sales_receipt_header_formId'); }}  >
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {customerOptions.length !== 0 ?
                        <>
                          {customerOptions?.map(cmb_customer_id => (
                            <option value={cmb_customer_id.field_id} >{cmb_customer_id.field_name}</option>
                          ))}
                        </>
                        : ''
                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                  <div className="col-sm-1 col-2">
                    <Tooltip title="Refresh" placement="top">
                      <MDTypography className={`${keyForViewUpdate !== '' ? 'd-none' : 'display'}`} >
                        <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_customer') } }} />
                      </MDTypography>
                    </Tooltip>
                  </div>
                </div>

                {/* <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Customer Bank Details <span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" className="erp_input_field" id="txt_customer_bank_details" value={txt_customer_bank_details} onInput={(e) => { setcustomer_bank_details(e.target.value.trim()); }} maxlength="200" optional="optional" />
                    <MDTypography variant="button" id="error_txt_customer_bank_details" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div> */}
                {/* Customer Banks */}
                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Customer Banks {chk_receipt_type === '1' || chk_receipt_type === '4' ? "" : <span className="required">*</span>}</Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_customer_bank_id" className="form-select form-select-sm" value={cmb_customer_bank_id} optional={`${chk_receipt_type === '1' || chk_receipt_type === '4' ? "optional" : ''}`}
                      onChange={(e) => { setcustomer_bank_id(e.target.value); comboOnChange('customer_bank_id'); validateFields('customer_sales_receipt_header_formId'); }} >
                      <option value="">Select</option>
                      {customerBankOptions.length !== 0 ?
                        <>
                          {customerBankOptions?.map(cmb_customer_bank_id => (
                            <option value={cmb_customer_bank_id.field_id} >{cmb_customer_bank_id.field_name}</option>
                          ))}
                        </>
                        : ''
                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_customer_bank_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* customer_bank_account_number */}
                <div className='row'>
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Customer Bank Account No {chk_receipt_type === '1' || chk_receipt_type === '4' ? "" : <span className="required">*</span>}</Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="text" id='txt_customer_bank_account_number' className="erp_input_field" value={txt_customer_bank_account_number} optional={`${chk_receipt_type === '1' || chk_receipt_type === '4' ? "optional" : ''}`}
                      onChange={e => { validateAccNo(e); validateFields('customer_sales_receipt_header_formId'); }} />
                    <MDTypography variant="button" id="error_txt_customer_bank_account_number" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Customer Bank IFSC Code {chk_receipt_type === '1' || chk_receipt_type === '4' ? "" : <span className="required">*</span>}</Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="text" id='txt_customer_bank_ifsc_code' className="erp_input_field" value={txt_customer_bank_ifsc_code} optional={`${chk_receipt_type === '1' || chk_receipt_type === '4' ? "optional" : ''}`}
                      onChange={e => { setcustomer_bank_ifsc_code(e.target.value.split(' ').join('').toUpperCase()); validateFields('customer_sales_receipt_header_formId'); }} />
                    <MDTypography variant="button" id="error_txt_customer_bank_ifsc_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>


                {/* approved_by_id */}
                {(keyForViewUpdate === 'financeApprove') ? (
                  <>
                    <div className='row'>
                      <div className='col-sm-4'>
                        <Form.Label className="erp-form-label">Finanace Approved By</Form.Label>
                      </div>
                      <div className='col'>
                        <select id="cmb_approved_by_id" className="form-select form-select-sm " value={cmb_approved_by_id} onChange={(e) => { setApproved_by_id(e.target.value); }} optional='optional' disabled='disabled' >
                          <option value="">Select</option>
                          {approvedByOptions.length !== 0 ?
                            <>
                              {approvedByOptions?.map(cmb_approved_by_id => (
                                <option value={cmb_approved_by_id.field_id}>{cmb_approved_by_id.field_name}</option>
                              ))}
                            </>
                            : ''
                          }
                        </select>
                        <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>

                    <div className='row'>
                      <div className="col-sm-4">
                        <Form.Label className="erp-form-label">Finanace Approved Date </Form.Label>
                      </div>
                      <div className="col">
                        <Form.Control type="date" id='txt_approved_date' className="erp_input_field" value={txt_approved_date} onChange={e => { setApproved_date(e.target.value); }} optional='optional' disabled='disabled' />
                        <MDTypography variant="button" id="error_txt_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>

                  </>)
                  : ''
                }

              </div>
              {/* second column */}
              <div className="col-sm-6 erp_form_col_div">

                <div className='row'>
                  <div className='col-sm-4 col-12'>
                    <Form.Label className="erp-form-label">Company Branch <span className="required">*</span> </Form.Label>
                  </div>
                  <div className='col-sm-7 col-10'>
                    <select id="cmb_expected_branch_id" className="form-select form-select-sm" value={cmb_expected_branch_id} onChange={(e) => { setexpected_branch_id(e.target.value); comboOnChange('expectBranch'); validateFields('customer_sales_receipt_header_formId'); }} >
                      <option value="">Select</option>
                      <option value="">Add Record+</option>
                      {expectedBranchOptions.length !== 0 ?
                        <>
                          {expectedBranchOptions?.map(cmb_expected_branch_id => (
                            <option value={cmb_expected_branch_id.company_branch_id} >{cmb_expected_branch_id.company_branch_name}</option>
                          ))}
                        </>
                        : ''
                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_expected_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                  <div className="col-sm-1 col-2">
                    <Tooltip title="Refresh" placement="top">
                      <MDTypography className={`${keyForViewUpdate !== '' ? 'd-none' : 'display'}`} >
                        <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_exptBranch') } }} />
                      </MDTypography>
                    </Tooltip>
                  </div>
                </div>
                {/* bank_id */}
                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Bank{chk_receipt_type === '1' || chk_receipt_type === '4' ? "" : <span className="required">*</span>}</Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_bank_id" className="form-select form-select-sm" value={cmb_bank_id} optional={`${chk_receipt_type === '1' || chk_receipt_type === '4' ? "optional" : ''}`}
                      onChange={(e) => { setbank_id(e.target.value); comboOnChange('expectBranch'); validateFields('customer_sales_receipt_header_formId'); }} >
                      <option value="">Select</option>
                      {bankOptions.length !== 0 ?
                        <>
                          {bankOptions?.map(cmb_bank_id => (
                            <option value={cmb_bank_id.field_id} >{cmb_bank_id.field_name}</option>
                          ))}
                        </>
                        : ''
                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_bank_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label"> Receipt Status </Form.Label>
                  </div>
                  <div className='col'>
                    <select id="chk_customer_sales_receipt_status" className="form-select form-select-sm " value={chk_customer_sales_receipt_status} onChange={(e) => { setcustomer_sales_receipt_status(e.target.value); validateFields('customer_sales_receipt_header_formId'); }} disabled  >
                      <option value="P">Pending</option>
                      <option value="C">receipt Done</option>
                      <option value="X">Canceled</option>
                      <option value="F">Finance Approve</option>

                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Mail Status</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck"> <Form.Check className="erp_radio_button" lbl="Pending" label="pending" type="radio" value="P" name="chk_recipt_mail_sent_status" checked={chk_recipt_mail_sent_status === 'P'} onClick={() => { setRecipt_mail_sent_status('P'); }} disabled /> </div>
                      <div className="sCheck"> <Form.Check className="erp_radio_button" lbl="Fail" label="Fail" type="radio" value="F" name="chk_recipt_mail_sent_status" checked={chk_recipt_mail_sent_status === 'F'} onClick={() => { setRecipt_mail_sent_status("F"); }} disabled /> </div>
                      <div className="sCheck"> <Form.Check className="erp_radio_button" lbl="Success" label="Success" type="radio" value="S" name="chk_recipt_mail_sent_status" checked={chk_recipt_mail_sent_status === 'S'} onClick={() => { setRecipt_mail_sent_status("S"); }} disabled /> </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Total OutStanding <span className="required">*</span></Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="text" id='txt_total_outstanding' className="erp_input_field text-end" value={txt_total_outstanding} onChange={(e) => { setTotal_outstanding(e.target.value) }} />
                    <MDTypography variant="button" id="error_txt_total_outstanding" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-sm-4 col-12">
                    <Form.Label className="erp-form-label">Total Paid Amount<span className="required">*</span></Form.Label>
                  </div>
                  <div className="col-sm-6 col-10">
                    <Form.Control type="text" id='txt_Header_paid_amount' className="erp_input_field text-end" value={txt_Header_paid_amount} onChange={(e) => { FnValidatePaidAmt(e); }} />
                    <MDTypography variant="button" id="error_txt_Header_paid_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                  <div className="col-sm-2 col-2 ">
                    <div class="col text-center float-start">
                      <MDButton type="button" id="calculate_btn_id" className="erp-gb-button erp_MLeft_btn" variant="button" onClick={() => { if (txt_Header_paid_amount !== "") { FnCalculatePurchaseDetails() } }} fontWeight="regular">Calculate</MDButton>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <Accordion className="mt-3 mt-lg-1" defaultActiveKey="0" activeKey={openContactsAccord ? '0' : null}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenContactsAccord(!openContactsAccord)}>Customer's Contact Details </Accordion.Header>
                      <Accordion.Body className="p-0">
                        {
                          cmb_customer_id !== '' && cmb_customer_id !== '0'
                            ? <>
                              <div className={`row ps-1 py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' ? 'd-none' : 'display'}`}>
                                <div className={` d-flex justify-content-start align-items-center`}>
                                  <Tooltip title="Add new customer's contact if doesn't exist in list...!" placement="top">
                                    <MDButton type="button" className={`erp-gb-button float-start col-12 col-md-4`} variant="button" fontWeight="regular" onClick={() => {
                                      localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                      localStorage.setItem('customerIDs', JSON.stringify({ customerID: cmb_customer_id, keyForViewUpdate: 'update', compType: 'Master' }))
                                    }}>
                                      <Link className={'text-light'} to={{ pathname: "/Masters/Customer" }} target="_blank" >Add New Customer's Contact +</Link></MDButton>
                                  </Tooltip>
                                  <Tooltip title="Refresh customer contact list...!" placement="top">
                                    <MDButton type="button" className={`erp-gb-button float-end col-1 ms-0 ms-md-2 mt-1 mt-md-0`} variant="button" fontWeight="regular" onClick={() => { FnGetCustomersContacts(cmb_customer_id) }}>Refresh</MDButton>
                                  </Tooltip>
                                </div>
                              </div>
                            </>
                            : null
                        }
                        {
                          CustContactDetails.length > 0 ?
                            <>
                              <div className='row ps-2'>
                                <div className="col-12 erp_table_scroll">
                                  <div class="col  pl-2">
                                    <input type='checkbox' class="me-1" name="selectAllCustContact" id="selectAllCustContact" onClick={(e) => { toggleChkAllBoxes('selectAllCustContact'); }} /> <label class="erp-form-label pb-1"> Select All </label>
                                  </div>
                                  {renderCustomerContactsTbl}
                                </div>
                              </div>
                            </>
                            :
                            <>
                              <div className='row text-center'>
                                <div className="col-12">
                                  <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                </div>
                              </div>
                            </>
                        }
                        {
                          CustContactDetails.length !== 0 && !['approve', 'view', 'delete', 'financeApprove', 'cancel'].includes(keyForViewUpdate)
                            ? <>
                              <div className="row justify-content-center my-1">
                                <Tooltip title="Confirm selection...!" placement="right">
                                  <MDButton type="button" className={`erp-gb-button float-end col-1`} variant="button" fontWeight="regular"
                                    onClick={(e) => {
                                      let selectedCustCont = $('.selectCustContactPerson:checked');
                                      if (selectedCustCont.length > 0) {
                                        setOpenContactsAccord(false)
                                        $('#error_customer_contact_persons').hide();
                                      } else {
                                        $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                                        $('#error_customer_contact_persons').show();
                                      }
                                    }}
                                  >Confirm</MDButton>
                                </Tooltip>
                              </div>
                            </>
                            : null
                        }
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion >
                  <MDTypography variant="button" id="error_customer_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                </div>
              </div>
            </div>
          </form>
        </div>
        <hr />

        <Accordion defaultActiveKey="0" activeKey={openDetailsAccord ? '0' : null}>
          <Accordion.Item eventKey="0" onClick={(e) => setOpenDetailsAccord(!openDetailsAccord)}>
            <Accordion.Header className="erp-form-label-md">Customer Sales Receipt Details</Accordion.Header>
            <Accordion.Body>
              <div className="d-flex row">
                <div className="col-md-4 col-12">
                  <div className="row">
                    <div className="col-sm-6 d-flex">
                      <Form.Control type="text" id="txt-detail-data-highliter" className={`erp_input_field txt-filter-bom ${CustomerReciptDetailData.length === 0 ? 'd-none' : 'display'}`} />
                      <MDButton type="button" className={`erp-gb-button erp_MLeft_btn ${CustomerReciptDetailData.length === 0 ? 'd-none' : 'display'}`} variant="button" onClick={() => scrollToTableRow()}
                        fontWeight="regular">Search</MDButton>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12">
                </div>
                <div className="col-md-2 col-12 text-end">
                  <MDButton type="button" id="export-btn" className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" disabled={keyForViewUpdate !== '' || customer_sales_receipt_master_transaction_id === 0 ? 'disabled' : ''} onClick={ExportToExcel}>Export</MDButton>
                </div>
              </div>

              <div className="erp-Mt-10">
                {CustomerReciptDetailData.length !== 0 ?
                  <>
                    <div class="col  pl-2">
                      <input type='checkbox' class="me-1" name="selectAllReceiptItems" id="selectAllReceiptItems" onClick={(e) => { toggleChkAllBoxes('selectAllReceiptItems'); }} /> <label class="erp-form-label pb-1"> Select All </label>
                    </div>
                    {renderCustomerSalesReceiptDetailsTbl}
                  </> :
                  ''
                }
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <hr />
        <div className="row">
          <form id="Customer_recipt_footer_formId">
            <div className='card p-4'>
              <div className='main_heding text-start'>
                <label className='erp-form-label-lg'> Customer Sales Receipt Totals:</label>
              </div>
              <div className="row">
                {/* //first column */}
                <div className="col-lg-6 col-md-6 col-12 erp_form_col_div">

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label ">Total Billing Amount</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" className="erp_input_field text-end" id="txt_total_billing_amount" value={txt_total_billing_amount} onInput={(e) => { settotal_billing_amount(e.target.value.trim()); }} disabled optional="optional" />
                      <MDTypography variant="button" id="error_txt_total_billing_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Total Billing Deduction <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" className="erp_input_field text-end" id="txt_total_billing_deduction" value={txt_total_billing_deduction} onInput={(e) => { settotal_billing_deduction(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); FnCalculateDetailsTotalSum(); validateFields('Customer_recipt_footer_formId'); }} maxlength="200" optional="optional" />
                      <MDTypography variant="button" id="error_txt_total_billing_deduction" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Deduction Remark  {txt_total_billing_deduction > 0 ? <span className="required">*</span> : ''}   </Form.Label>
                    </div>
                    <div className="col">
                      <Form.Control type="text" id='txt_deduction_remark' className="erp_input_field " value={txt_deduction_remark} disabled={txt_total_billing_deduction > 0 ? '' : 'disabled'} onChange={(e) => { setDeduction_remark(e.target.value); validateFields('Customer_recipt_footer_formId'); }} optional={txt_total_billing_deduction > 0 && txt_total_billing_deduction !== '' ? 'optional' : ''} />
                      <MDTypography variant="button" id="error_txt_deduction_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Remark</Form.Label>
                    </div>
                    <div className="col">
                      <Form.Control type="text" id='txt_remark' className="erp_input_field" value={txt_remark} onChange={(e) => { setRemark(e.target.value) }} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Customer Sales Receipt Active</Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="chk_CustomerSalesRecipt_isactive" checked={chk_CustomerSalesRecipt_isactive === true} onClick={() => { setCustomerSalesRecipt_isactive(true); }} /> </div>
                        <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" value="false" name="chk_CustomerSalesRecipt_isactive" checked={chk_CustomerSalesRecipt_isactive === false} onClick={() => { setCustomerSalesRecipt_isactive(false); }} /> </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* //second column */}
                <div className="col-lg-6 col-md-6 col-12 erp_form_col_div">

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Tax 1</Form.Label>
                    </div>
                    <div className="col">
                      <select id="txt_tax1_id" className="form-select form-select-sm" value={txt_tax1_id} onChange={(e) => { comboOnChange('tax1'); }} >
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {tax1Options?.map(txt_tax1_id => (
                          <option value={txt_tax1_id.field_id} tax_value={txt_tax1_id.tax_value}>{txt_tax1_id.field_name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Tax 1 Percent & Amount</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_tax1_percent" className="erp_input_field text-end" value={txt_tax1_percent} onChange={(e) => setTax1_percent(e.target.value)} disabled />
                      <MDTypography variant="button" id="error_txt_tax1_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_tax1_amount" className="erp_input_field text-end" value={txt_tax1_amount} onChange={(e) => { setTax1_amount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); }} disabled />
                      <MDTypography variant="button" id="error_txt_tax1_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Tax 2</Form.Label>
                    </div>
                    <div className="col">
                      <select id="txt_tax2_id" className="form-select form-select-sm" value={txt_tax2_id} onChange={(e) => { comboOnChange('tax2'); }} >
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {tax1Options?.map(txt_tax2_id => (
                          <option value={txt_tax2_id.field_id} tax_value={txt_tax2_id.tax_value}>{txt_tax2_id.field_name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Tax 2 Percent & Amount</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_tax2_percent" className="erp_input_field text-end" value={txt_tax2_percent} onChange={(e) => setTax2_percent(e.target.value)} disabled />
                      <MDTypography variant="button" id="error_txt_tax2_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_tax2_amount" className="erp_input_field text-end" value={txt_tax2_amount} onChange={(e) => { setTax2_amount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); }} disabled />
                      <MDTypography variant="button" id="error_txt_tax2_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Total Receipt Amount</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_total_receipt_amount" className="erp_input_field text-end" value={txt_total_receipt_amount} maxLength={19} disabled
                        onChange={(e) => { settotal_receipt_amount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); validateFields('Customer_recipt_footer_formId'); }} />
                      <MDTypography variant="button" id="error_txt_total_receipt_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="erp_frm_Btns">
          <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
          <MDButton type="button" id="save-btn" className="erp-gb-button erp_MLeft_btn" variant="button" onClick={() => addCustomerRecipt('P')} fontWeight="regular" >Save</MDButton>
          <MDButton type="button" id="cancel-btn" className={`erp-gb-button erp_MLeft_btn`} variant="button" onClick={() => addCustomerRecipt('X')}>Cancel</MDButton>
          <MDButton type="button" id="finance-btn" className={`erp-gb-button erp_MLeft_btn`} variant="button" onClick={() => addCustomerRecipt('F')}>Finanace Approve</MDButton>
          <MDButton type="button" id="clearForm_btn_id" className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? '' : 'd-none'}`} variant="button" onClick={FnClearFormFields} fontWeight="regular"
            disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'financeApprove' ? 'disabled' : ''} >Clear</MDButton>
          <MDButton type="button" id="viewdocument-id" className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" onClick={viewDocumentForm}
            disabled={customer_sales_receipt_master_transaction_id === 0 || txt_customer_sales_receipt_no === '' || isView === true ? true : false}>Upload Document</MDButton>&nbsp;
          <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' && (chk_customer_sales_receipt_status === 'A' || chk_customer_sales_receipt_status === 'F') ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => {
            printInvoice(true);
          }} disabled={chk_customer_sales_receipt_status !== "P" ? '' : 'disabled'}>Print Invoice<FiDownload className="erp-download-icon-btn" /></MDButton>
          <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => setShowDeleteModal(true)}>Delete</MDButton>

        </div >

        {/* Add new Record Popup */}
        <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
          <Modal.Header>
            <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
          </Modal.Header>
          <Modal.Body className='erp_city_modal_body'>
            {displayRecordComponent()}
          </Modal.Body>
          <Modal.Footer>
            <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
              fontWeight="regular">Close</MDButton>

          </Modal.Footer>
        </Modal >
        {showConfirmationModal ?
          <ConfirmationModal close={() => closeConfirmationModal()} show={[showConfirmationModal, message, modalOrderDetails, messageForConfirmation]} />
          : null
        }

        {/* Document modal */}
        <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
          <Modal.Header>
            <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
          </Modal.Header>
          <Modal.Body>
            <DocumentF group_id={txt_customer_sales_receipt_no} document_group={docGroup} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete Modal */}
        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} backdrop="static" keyboard={false} centered>
          <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDeleteModal}></button></span>
          <Modal.Body className='text-center'>
            <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
            <h6>Do you wish to delete this record ?</h6>
          </Modal.Body>
          <Modal.Footer className='justify-content-center'>
            <Button variant="success" className='erp-gb-button' onClick={handleCloseDeleteModal}> Cancel  </Button>&nbsp;
            <Button variant="danger" className='erp-gb-button' onClick={deleteSalesReceipt}>Delete</Button>
          </Modal.Footer>

        </Modal>
        {showErrorMsgModal ?
          <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />
          : null
        }


        {/* Send Email On Approval Modal */}
        {showConfirmationModal ?
          <ConfirmationModal close={() => closeConfirmationModal('Exit')} confirmation={() => closeConfirmationModal('Export&Exit')} show={[showConfirmationModal, message, modalOrderDetails, messageForConfirmation]} />
          : null
        }

        <MailSentModal handleCloseMailSentModal={() => handleCloseMailSentModal()} sendConfirm={() => sendEmail()} show={[showMailSentModal, message, modalOrderDetails]} />


      </DashboardLayout >
    </>
  )
}

export default FrmCustomerSalesReceiptEntry    
