import React from 'react'
import { forwardRef, useImperativeHandle } from "react"
import ConfigConstants from "assets/Constants/config-constant"
import { useNavigate } from "react-router-dom";

const GenerateMaterialId = forwardRef((props, ref) => {
   
    const configConstants = ConfigConstants();
    const { COMPANY_ID } = configConstants;
    const navigate = useNavigate();

    let generatedAutoNo = "0";
    useImperativeHandle(ref, () => ({

        async GenerateCode(tblName, fieldName, conditionsKey = '', productTpVal, product_typeShortName, Length) {
            const data = {
                tblName: tblName,
                fieldName: fieldName,
                Length: Length,
                conditionsKey:{key: conditionsKey, value:productTpVal},
                company_id: COMPANY_ID,
            }
            const formData = new FormData();
            formData.append(`data`, JSON.stringify(data))
            const requestOptions = { method: 'POST', body: formData };
            try {
                const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/GenerateNo/FnGenerateMaterialId`, requestOptions)
                const resp = await fetchResponse.text();
                console.log("GenerateTAuto Api: ", resp)
                if (product_typeShortName === " ") {
                    generatedAutoNo = product_typeShortName + resp;
                    return generatedAutoNo;
                }
                else {
                    generatedAutoNo = product_typeShortName + resp;
                    return generatedAutoNo;
                }
            } catch (error) {
                console.error(error);
                return generatedAutoNo;
                navigate('/Error')
            }
        },
        async GenerateTechnicalSpecName(productTpText, productCat1Text, productCat2Text, productCat3Text, productCat4Text, productCat5Text, productMakeText, productMaterialGradeText) {
            let techSpecName = "";

            try {
                if (productTpText !== "" || productTpText !== "Select") {
                    techSpecName += `${productTpText}`;
                    techSpecName += productCat1Text !== "" && productCat1Text !== "Select" ? `-${productCat1Text}` : '';
                    techSpecName += productCat2Text !== "" && productCat2Text !== "Select" ? `-${productCat2Text}` : '';
                    techSpecName += productCat3Text !== "" && productCat3Text !== "Select" ? `-${productCat3Text}` : '';
                    techSpecName += productCat4Text !== "" && productCat4Text !== "Select" ? `-${productCat4Text}` : '';
                    techSpecName += productCat5Text !== "" && productCat5Text !== "Select" ? `-${productCat5Text}` : '';
                    techSpecName += productMakeText !== "" && productMakeText !== "Select" ? `-${productMakeText}` : '';
                    techSpecName += productMaterialGradeText !== "" && productMaterialGradeText !== "Select" ? `-${productMaterialGradeText}` : '';
                }

                // console.log("GenerateText: ", techSpecName);
                return techSpecName;
            } catch (error) {
                console.error(error);
                return techSpecName;
                navigate('/Error')
            }

        }
    }))
    return <div> </div>
})

export default GenerateMaterialId
