import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import { Accordion, Form } from 'react-bootstrap';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import ComboBox from 'Features/ComboBox';
import { resetGlobalQuery } from 'assets/Constants/config-constant';
import { globalQuery } from 'assets/Constants/config-constant';
import SuccessModal from 'components/Modals/SuccessModal';
import ErrorModal from 'components/Modals/ErrorModal';
import FrmPlantEntry from 'Masters/MPlant/FrmPlantEntry';
import FrmValidations from "FrmGeneric/FrmValidations";
import ConfigConstants from "assets/Constants/config-constant";

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import DepartmentEntry from 'Masters/MDepartment/FrmDepartmentEntry';
import FrmProductionSectionEntry from 'Masters/MProductionSection/FrmProductionSectionEntry';
import FrmProductionSubSectionEntry from 'Masters/MProductionSubSection/FrmProductionSubSectionEntry';

export default function FrmMachineEntry() {
  const validate = useRef();
  const { state } = useLocation();
  const configConstants = ConfigConstants();

  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName } = configConstants;
  const { machineId = 0, keyForViewUpdate, compType = "Masters" } = state || {}

  // UseRefs
  const navigate = useNavigate();
  const comboDataAPiCall = useRef();

  //MODEL
  const [showAddRecModal, setShowAddRecModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('')

  //combo options
  const [plantOptions, setPlantOptions] = useState([]);
  const [departmentOotions, setDepartmentOptions] = useState([]);
  // const [subdepartmentOotions, setSubDepartmentOptions] = useState([]);
  const [prodsectionOptions, setProdSectionOptions] = useState([]);
  const [prodsubsectionOptions, setProdSubSectionOptions] = useState([]);


  //Machine Master fields 
  const [machine_id, setMachineId] = useState(machineId)
  const [machineName, setMachineName] = useState('')
  const [machineShortName, setMachineShortName] = useState('')
  const [technicalSpecification, setTechnicalSpecification] = useState('')
  const [machineSerialNo, setMachineSerialNo] = useState('')
  const [machineMake, setMachineMake] = useState('')
  const [machineModel, setMachineModel] = useState('')
  const [machineManufactureDate, setMachineManufactureDate] = useState('')
  const [machineExpiryDate, setMachineExpiryDate] = useState('')
  const [machineErectionDate, setMachineErectionDate] = useState('')
  const [machineProductionDate, setMachineProductionDate] = useState('')
  const [machineScrapeDate, setMachineScrapeDate] = useState('')
  const [machineMmaintenanceScheduleDate, setMachineMmaintenanceScheduleDate] = useState('')
  const [plantId, setPlantId] = useState('')
  const [departmentId, setDepartmentId] = useState('')
  // const [subDepartmentId, setSubDepartmentId] = useState('')
  const [cmb_section_id, setProdSection] = useState();
  const [cmb_sub_section_id, setProdSubSection] = useState();
  const [num_machineproductioncapacityperhour, setMachineProductionCapacityPerHour] = useState(0)
  const [machineProductionCapacityPerDay, setMachineProductionCapacityPerday] = useState(0)
  const [machineDepreciationRate, setMachineDepreciationRate] = useState(0)
  const [machineWorkingCapacity, setMachineWorkingCapacity] = useState(0)
  const [machineStopagecapacity, setMachineStopagecapacity] = useState(0)
  const [machineStatus, setMachineStatus] = useState('')
  const [actionType, setActionType] = useState('')
  const [actionLabel, setActionLabel] = useState('Save')


  const validateFields = () => {
    validate.current.validateFieldsOnChange('machineformId')
  }



  //function for fill all combo box
  const fillComboBox = async () => {
    try {
      //load plan list
      comboDataAPiCall.current.fillMasterData("cmv_plant", "", "").then((plantApiCall) => {
        setPlantOptions(plantApiCall)
      })

      //load department list
      comboDataAPiCall.current.fillMasterData("cmv_department", "department_group", "Production").then((departmentAPiCall) => {
        setDepartmentOptions(departmentAPiCall)
      })

      ////Production section
      comboDataAPiCall.current.fillMasterData("xmv_production_section", "", "").then((getProdSectionApiCall) => {
        setProdSectionOptions(getProdSectionApiCall);
      })

    } catch (error) {

    }
  }

  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
      navigate(`/Masters/FrmMachineListing`)
    }
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');


  // function for setup combo box value on change
  const addRecordInProperty = async (key) => {
    try {
      switch (key) {
        case 'cmb_plant':
          const palnt_id = document.getElementById('cmb_plant').value;

          if (palnt_id === "0") {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Plant')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(1).css("padding-top", "0px");
            }, 100)
          }
          setPlantId(palnt_id)
          $('#error_cmb_plant').hide();
          break;
        case 'cmb_department':
          const department_idval = document.getElementById('cmb_department').value;
          if (department_idval === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Department')

            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(1).css("padding-top", "0px");
            }, 100)
          }

          // setSelectedDepartment(department_idval); // Create a state for selectedDepartment
          setDepartmentId(department_idval)
          $('#error_cmb_department').hide();
          break;

        // case 'cmb_subdepartment':
        //   const sub_department_id = document.getElementById('cmb_subdepartment').value;
        //   if (sub_department_id === '0') {
        //     sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
        //     setHeaderName('Sub Department')
        //     setShowAddRecModal(true)
        //     setTimeout(() => {
        //       $(".erp_top_Form").eq(1).css("padding-top", "0px");
        //     }, 100)
        //   }
        //   setSubDepartmentId(sub_department_id)
        //   $('#error_cmb_subdepartment').hide();
        //   break;

        case 'cmb_section_id':
          const prod_section_Id = $('#cmb_section_id').val();
          if (prod_section_Id === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Production Section')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(1).css("padding-top", "0px");

            }, 100)
          }
          const getProdSubSectionApiCall = await comboDataAPiCall.current.fillMasterData("xmv_production_sub_section", "production_section_id", prod_section_Id);
          setProdSubSectionOptions(getProdSubSectionApiCall);

          break;

        case 'cmb_sub_section_id':
          const prod_sub_section_Id = $('#cmb_sub_section_id').val();
          if (prod_sub_section_Id === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Production Sub Section')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(1).css("padding-top", "0px");

            }, 100)
          }
          break;

        case 'cmb_machinestatus':
          const machine_status = document.getElementById('cmb_machinestatus').value;
          setMachineStatus(machine_status)
          $('#error_cmb_machinestatus').hide();
          break;
      }
    } catch (error) {

    }
  }

  useEffect(async () => {
    await fillComboBox();

    if (machine_id !== 0) {
      await FnCheckUpdateResponce();
    }
    await ActionType();
  }, [])

  const addMachine = async () => {
    try {
      const checkIsValidate = await validate.current.validateForm("machineformId");;
      if (checkIsValidate === true) {
        var active;
        var activeValue = document.querySelector('input[name=isMachineActive]:checked').value
        switch (activeValue) {
          case '0': active = false; break;
          case '1': active = true; break;
        }

        const data = {
          company_branch_id: COMPANY_BRANCH_ID,
          company_id: COMPANY_ID,
          machine_id: machine_id,
          machine_name: machineName,
          machine_short_name: machineShortName,
          technical_specification: technicalSpecification,
          machine_serial_no: machineSerialNo,
          machine_make: machineMake,
          machine_model: machineModel,
          machine_manufacture_date: machineManufactureDate,
          machine_expiry_date: machineExpiryDate,
          machine_erection_date: machineErectionDate,
          machine_production_date: machineProductionDate,
          machine_scrape_date: machineScrapeDate,
          machine_maintenance_schedule_date: machineMmaintenanceScheduleDate,
          plant_id: plantId,
          department_id: departmentId,
          sub_department_id: 1,
          section_id: cmb_section_id,
          sub_section_id: cmb_sub_section_id,
          machine_production_capacity_per_hour: num_machineproductioncapacityperhour,
          machine_production_capacity_per_day: machineProductionCapacityPerDay,
          machine_depreciation_rate: machineDepreciationRate,
          machine_working_capacity: machineWorkingCapacity,
          machine_stopage_capacity: machineStopagecapacity,
          machine_status: machineStatus,
          created_by: UserName,
          modified_by: machine_id === 0 ? null : UserName,
          is_active: active
        };
        console.log(data);

        const requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CmMachineDetails/FnAddUpdateMachineRecord`, requestOptions)
        const responce = await apicall.json()
        console.log("response: ", responce.data);
        if (responce.error !== "") {
          console.log("response error: ", responce.error);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)

        } else {
          console.log("machine_id", responce.data);
          const evitCache = comboDataAPiCall.current.evitCache();
          console.log(evitCache);
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);
          await FnCheckUpdateResponce();
        }
      }
    } catch (error) {
      console.log("Error: ", error);
      navigate('/Error')
    }
  }

  const displayRecordComponent = () => {
    switch (modalHeaderName) {
      case 'Plant':
        return <FrmPlantEntry btn_disabled={true} />;
      case 'Department':
        return <DepartmentEntry btn_disabled={true} />;

      case 'Sub Department':
        return <DepartmentEntry department={departmentId} btn_disabled={true} />;

      case 'Production Section':
        return <FrmProductionSectionEntry btn_disabled={true} />;

      case 'Production Sub Section':
        return <FrmProductionSubSectionEntry btn_disabled={true} />

      default:
        return null;
    }
  }

  // Show ADd record Modal
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {
      case 'Plant':
        comboDataAPiCall.current.fillMasterData("cmv_plant", "", "").then((plantApiCall) => {
          setPlantOptions(plantApiCall)
        })
        break;
      case 'Department':
        comboDataAPiCall.current.fillMasterData("cmv_department", "", "").then((departmentAPiCall) => {
          setDepartmentOptions(departmentAPiCall)
        })
        break;

      default:
        break;
    }
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)

  }


  const FnCheckUpdateResponce = async () => {
    try {
      if (machine_id !== "undefined" && machine_id !== '' && machine_id !== null) {

        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CmMachineDetails/FnShowParticularMachineRecordForUpdate/${machineId}/${COMPANY_ID}`)
        const updateRes = await apiCall.json();
        const data = updateRes.data

        if (data !== null && data !== "") {

          setMachineId(machine_id)
          setMachineName(data.machine_name)
          setMachineShortName(data.machine_short_name)
          setTechnicalSpecification(data.technical_specification)
          setMachineSerialNo(data.machine_serial_no)
          setMachineMake(data.machine_make)
          setMachineModel(data.machine_model)
          setMachineManufactureDate(data.machine_manufacture_date)
          setMachineExpiryDate(data.machine_expiry_date)
          setMachineErectionDate(data.machine_erection_date)
          setMachineProductionDate(data.machine_production_date)
          setMachineScrapeDate(data.machine_scrape_date)
          setMachineMmaintenanceScheduleDate(data.machine_maintenance_schedule_date)
          setPlantId(data.plant_id)
          setDepartmentId(data.department_id)
          // loadSubDepartment()
          // setSubDepartmentId(data.sub_department_id)
          setProdSection(data.section_id);
          addRecordInProperty('cmb_section_id')
          setProdSubSection(data.sub_section_id);
          setMachineProductionCapacityPerHour(data.machine_production_capacity_per_hour)
          setMachineProductionCapacityPerday(data.machine_production_capacity_per_day)
          setMachineDepreciationRate(data.machine_depreciation_rate)
          setMachineWorkingCapacity(data.machine_working_capacity)
          setMachineStopagecapacity(data.machine_stopage_capacity)
          setMachineStatus(data.machine_status)

          switch (data.is_active) {
            case true:
              document.querySelector('input[name="isMachineActive"][value="1"]').checked = true;
              break;
            case false:
              document.querySelector('input[name="isMachineActive"][value="0"]').checked = true;
              break;
          }
          sessionStorage.removeItem('MachineName')
          sessionStorage.setItem("MachineName", data.machine_name);


        }
      }

    } catch (error) {
      console.log("Error: ", error);
      navigate('/Error')
    }
  }

  {/* test case 10 shivanjali 5/2/24 */ }
  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modification)');
        setActionLabel('Update')
        $("input[type=radio]").attr('disabled', false);
        $('#btn_save').attr('disabled', false);
        $('#txt_machineename').attr('disabled', true);
        $('#txt_machineshortname').attr('disabled', true);
        $('#txt_machineserialno').attr('disabled', true);
        $('#txt_machinemake').attr('disabled', true);
        $('#dt_machinemanufacturedate').attr('disabled', true);
        $('#dt_machineproductiondate').attr('disabled', true);
        $('#btn_upload_doc').attr('disabled', false)

        break;
      case 'view':
        setActionType('(View)');
        await validate.current.readOnly("machineformId");
        $("input[type=radio]").attr('disabled', true);
        $('#btn_upload_doc').attr('disabled', true)
        break;
      default:
        setActionType('(Creation)');
        break;
    }
  };

  {/* test case 8 shivanjali 5/2/24 */ }
  const validateNo = (key) => {
    debugger
    const numCheck = /^[0-9]*\.?[0-9]*$/;
    const regexNo = /^[0-9]*\.[0-9]{5}$/

    var dot = '.';

    switch (key) {
      case 'ProductionCapacity':
        var ProductionCapacity = $('#num_machineproductioncapacityperhour').val();
        if (!regexNo.test(ProductionCapacity) && ProductionCapacity.length <= 14 || ProductionCapacity.indexOf(dot) === 14) {
          if (numCheck.test(ProductionCapacity)) {
            setMachineProductionCapacityPerHour(ProductionCapacity)
          }

        }
        break;
      case 'MachineStopagecapacity':
        var MachineStopagecapacity = $('#num_machinestopagecapacity').val();
        if (!regexNo.test(MachineStopagecapacity) && MachineStopagecapacity.length <= 14 || MachineStopagecapacity.indexOf(dot) === 14) {
          if (numCheck.test(MachineStopagecapacity)) {
            setMachineStopagecapacity(MachineStopagecapacity)
          }

        }
        break;

      case 'MachineProductionCapacityPerday':
        var MachineProductionCapacityPerday = $('#num_machineproductioncapacityperday').val();
        if (!regexNo.test(MachineProductionCapacityPerday) && MachineProductionCapacityPerday.length <= 14 || MachineProductionCapacityPerday.indexOf(dot) === 14) {
          if (numCheck.test(MachineProductionCapacityPerday)) {
            setMachineProductionCapacityPerday(MachineProductionCapacityPerday)
          }

        }
        break;

      case 'machineworkingcapacity':
        var machineworkingcapacity = $('#num_machineworkingcapacity').val();
        if (!regexNo.test(machineworkingcapacity) && machineworkingcapacity.length <= 14 || machineworkingcapacity.indexOf(dot) === 14) {
          if (numCheck.test(machineworkingcapacity)) {
            setMachineWorkingCapacity(machineworkingcapacity)
          }

        }
        break;
      case 'MachineDepreciationRate':
        var MachineDepreciationRate = $('#num_machinedepreciationrate').val();
        if (!regexNo.test(MachineDepreciationRate) && MachineDepreciationRate.length <= 14 || MachineDepreciationRate.indexOf(dot) === 14) {
          if (numCheck.test(MachineDepreciationRate)) {
            setMachineDepreciationRate(MachineDepreciationRate)
          }
        }
        break;
    }
  }

  return (
    <>
      <ComboBox ref={comboDataAPiCall} />
      <FrmValidations ref={validate} />
      <div className="erp_top_Form">
        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg main_heding'>Machine Master {actionType}</label>
          </div>
          <form id="machineformId">
            <div className="row erp_transporter_div">
              {/* //first column */}
              <div className="col-sm-6 erp_form_col_div">
                {/* Machine Name Row */}
                <div className="row">
                  <div className="col-sm-4">
                    {/* test case 1  and 3 and 4  6 shivanjali 5/2/24 */}
                    <Form.Label className="erp-form-label">Machine Name <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="text" className="erp_input_field" id="txt_machineename" value={machineName} onChange={e => { setMachineName(e.target.value); validateFields(); }} maxLength="500" />
                    <MDTypography variant="button" id="error_txt_machineename" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* Machine Short Name Row */}
                <div className="row">
                  <div className="col-sm-4">
                    {/* test case 1 shivanjali 5/2/24 */}
                    <Form.Label className="erp-form-label">Short Name <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="text" className="erp_input_field" id="txt_machineshortname" value={machineShortName} onChange={e => { setMachineShortName(e.target.value.toUpperCase()); validateFields(); }} maxLength="500" />
                    <MDTypography variant="button" id="error_txt_machineshortname" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* Technical Specification Row */}
                <div className="row">
                  <div className="col-sm-4">
                    {/* test case 9 shivanjali 5/2/24 */}
                    <Form.Label className="erp-form-label">Tech. Spec. <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control as="textarea" rows={1} className="erp_input_field mb-1" id="txt_technicalspecification" value={technicalSpecification} onChange={e => { setTechnicalSpecification(e.target.value); validateFields(); }} maxLength="255" />
                    <MDTypography variant="button" id="error_txt_technicalspecification" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                {/* Machine Serial No Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Serial No <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="text" className="erp_input_field" id="txt_machineserialno" value={machineSerialNo} onChange={e => { setMachineSerialNo(e.target.value); validateFields(); }} maxLength="255" />
                    <MDTypography variant="button" id="error_txt_machineserialno" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* Machine Make Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Machine Make <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="text" className="erp_input_field" id="txt_machinemake" value={machineMake} onChange={e => { setMachineMake(e.target.value); validateFields(); }} maxLength="255" />
                    <MDTypography variant="button" id="error_txt_machinemake" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* Machine Model Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Machine Model <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="text" className="erp_input_field" id="txt_machinemodel" value={machineModel} onChange={e => { setMachineModel(e.target.value); validateFields(); }} maxLength="255" />
                    <MDTypography variant="button" id="error_txt_machinemodel" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* Plant Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Plant</Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_plant" className="form-select form-select-sm" value={plantId} onChange={() => { addRecordInProperty("cmb_plant") }} optional="optional">
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {plantOptions.length !== 0 ? (
                        <>
                          {plantOptions?.map(plant => (
                            <option value={plant.field_id}>{plant.field_name}</option>
                          ))}
                        </>
                      ) : null

                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_plant" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} optional="optional">
                    </MDTypography>
                  </div>
                </div>

                {/* Department Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Department</Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_department" className="form-select form-select-sm" value={departmentId} onChange={() => { /*loadSubDepartment();*/ addRecordInProperty("cmb_department") }} optional="optional">
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {departmentOotions.length !== 0 ? (
                        <>
                          {departmentOotions?.map(department => (
                            <option value={department.field_id}>{department.field_name}</option>
                          ))}
                        </>
                      ) : null

                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_department" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* Sub Department Row */}
                {/* <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Sub Department</Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_subdepartment" className="form-select form-select-sm" value={subDepartmentId} onChange={() => { addRecordInProperty("cmb_subdepartment") }} optional="optional" >
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {subdepartmentOotions.length !== 0 ? (
                        <>
                          {subdepartmentOotions?.map(subdepartment => (
                            <option value={subdepartment.field_id}>{subdepartment.field_name}</option>
                          ))}
                        </>
                      ) : null

                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_subdepartment" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div> */}

                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Production Section <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_section_id" className="form-select form-select-sm" value={cmb_section_id} onChange={(e) => { addRecordInProperty('cmb_section_id'); setProdSection(e.target.value); }}>
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {prodsectionOptions.length !== 0 ? (
                        <>
                          {prodsectionOptions?.map(production => (
                            <option value={production.field_id}>{production.field_name}</option>
                          ))}
                        </>
                      ) : null

                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Prod. Sub Section <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_sub_section_id" className="form-select form-select-sm" value={cmb_sub_section_id} onChange={(e) => { addRecordInProperty('cmb_sub_section_id'); setProdSubSection(e.target.value); }}>
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {prodsubsectionOptions.length !== 0 ? (
                        <>
                          {prodsubsectionOptions?.map(subproduction => (
                            <option value={subproduction.field_id}>{subproduction.field_name}</option>
                          ))}
                        </>
                      ) : null

                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_sub_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* Machine Status Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Machine Status</Form.Label>
                  </div>
                  <div className="col">
                    <select id="cmb_machinestatus" className="form-select form-select-sm" value={machineStatus} onChange={() => { validateFields(); addRecordInProperty("cmb_machinestatus") }} optional="optional">
                      <option value="" selected>Select</option>
                      <option value="F">Functional</option>
                      <option value="M">Maintainance</option>
                      <option value="X">Stopped</option>
                      <option value="S">Scraped</option>
                    </select>
                    <MDTypography variant="button" id="error_cmb_machinestatus" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* Machine Manufacture Date Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Manufacture Date <span className="required">*</span></Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="date" id="dt_machinemanufacturedate" className="erp_input_field optional" value={machineManufactureDate} onChange={e => { setMachineManufactureDate(e.target.value); validateFields() }} />
                    <MDTypography variant="button" id="error_dt_machinemanufacturedate" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
              </div>

              {/* //second column */}
              <div className="col-sm-6 erp_form_col_div">


                {/* Machine Expiry Date Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Expiry Date<span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="date" id="dt_machineexpirydate" className="erp_input_field optional" value={machineExpiryDate} onChange={e => { setMachineExpiryDate(e.target.value); validateFields() }} />
                    <MDTypography variant="button" id="error_dt_machineexpirydate" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* Machine Erection Date Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Erection Date <span className="required">*</span></Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="date" id="dt_machineerectiondate" className="erp_input_field optional" value={machineErectionDate} onChange={e => { setMachineErectionDate(e.target.value); validateFields() }} />
                    <MDTypography variant="button" id="error_dt_machineerectiondate" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* Machine Production Date Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Production Date <span className="required">*</span></Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="date" id="dt_machineproductiondate" className="erp_input_field optional" value={machineProductionDate} onChange={e => { setMachineProductionDate(e.target.value); validateFields() }} />
                    <MDTypography variant="button" id="error_dt_machineproductiondate" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                {/* Machine Scrape Date Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Scrape Date <span className="required">*</span></Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="date" id="dt_machinescrapedate" className="erp_input_field optional" value={machineScrapeDate} onChange={e => { setMachineScrapeDate(e.target.value); validateFields() }} />
                    <MDTypography variant="button" id="error_dt_machinescrapedate" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* Maintenance Schedule Date Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Schedule Date<span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="date" id="dt_machinemaintenancescheduledate" className="erp_input_field optional" value={machineMmaintenanceScheduleDate} onChange={e => { setMachineMmaintenanceScheduleDate(e.target.value); validateFields() }} />
                    <MDTypography variant="button" id="error_dt_machinemaintenancescheduledate" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* Production Capacity Per Hour Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Production Capacity (Per Hour) </Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="text" className="erp_input_field text-end" id="num_machineproductioncapacityperhour" value={num_machineproductioncapacityperhour} onChange={e => { validateNo('ProductionCapacity'); }} maxLength="19" optional="optional" />
                    <MDTypography variant="button" id="error_num_machineproductioncapacityperhour" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* Production Capacity Per Day Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Production Capacity (Per Day) </Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="text" className="erp_input_field text-end" id="num_machineproductioncapacityperday" value={machineProductionCapacityPerDay} onChange={e => { validateNo('MachineProductionCapacityPerday') }} maxLength="255" />
                    <MDTypography variant="button" id="error_num_machineproductioncapacityperday" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* Machine Depreciation Rate Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Depreciation Rate </Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="text" className="erp_input_field text-end" id="num_machinedepreciationrate" value={machineDepreciationRate} onChange={e => { validateNo('MachineDepreciationRate') }} maxLength="255" />
                    <MDTypography variant="button" id="error_num_machinedepreciationrate" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* Machine Working Capacity Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Working Capacity </Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="text" className="erp_input_field text-end" id="num_machineworkingcapacity" value={machineWorkingCapacity} onChange={e => { validateNo('machineworkingcapacity') }} maxLength="255" />
                    <MDTypography variant="button" id="error_num_machineworkingcapacity" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* Machine Stopage Capacity Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Stopage Capacity </Form.Label>
                  </div>
                  <div className="col">
                    <Form.Control type="text" className="erp_input_field text-end" id="num_machinestopagecapacity" value={machineStopagecapacity} onChange={e => { validateNo('MachineStopagecapacity') }} maxLength="255" />
                    <MDTypography variant="button" id="error_num_machinestopagecapacity" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/*Machine Active Row */}
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Is Active</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="Yes"
                          type="radio"
                          value="1"
                          name="isMachineActive"
                          defaultChecked

                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="No"
                          value="0"
                          type="radio"
                          name="isMachineActive"
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </form>
          <div className="card-footer py-0 text-center">
            <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular"

              onClick={() => {
                const path = compType === 'Register' ? '/Masters/FrmMachineListing/reg' : '/Masters/FrmMachineListing';
                navigate(path);
              }}
            >Back</MDButton>
            <MDButton type="button" id="btn_save" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} onClick={addMachine} variant="button" fontWeight="regular">{actionLabel}</MDButton>

          </div >
        </div>



        {/* Success Msg Popup */}
        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />

        {/* Error Msg Popup */}
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

        <hr />
        {/* Documents section */}
        <Accordion defaultActiveKey="0" >
          <Accordion.Item eventKey="0">
            <Accordion.Header className="erp-form-label-md">Documents</Accordion.Header>
          </Accordion.Item>
          <Accordion.Body>
            <div className="d-flex row">
            </div>
          </Accordion.Body>
        </Accordion>
        <hr />
        {/* Machine Process section */}
        <Accordion defaultActiveKey="1" >
          <Accordion.Item eventKey="1">
            <Accordion.Header className="erp-form-label-md">Machine Process </Accordion.Header>
          </Accordion.Item>
          <Accordion.Body>
            <div className="d-flex row">
            </div>
          </Accordion.Body>
        </Accordion>
        <hr />
        {/* Production History section */}
        <Accordion defaultActiveKey="2" >
          <Accordion.Item eventKey="2">
            <Accordion.Header className="erp-form-label-md">Production History</Accordion.Header>
          </Accordion.Item>
          <Accordion.Body>
            <div className="d-flex row">
            </div>
          </Accordion.Body>
        </Accordion>
        <hr />
        {/* Maintainance History section */}
        <Accordion defaultActiveKey="3" >
          <Accordion.Item eventKey="3">
            <Accordion.Header className="erp-form-label-md">Maintainance History</Accordion.Header>
          </Accordion.Item>
          <Accordion.Body>
            <div className="d-flex row">
            </div>
          </Accordion.Body>
        </Accordion>
        <hr />
        {/* Stoppage History  section */}
        <Accordion defaultActiveKey="4" >
          <Accordion.Item eventKey="4">
            <Accordion.Header className="erp-form-label-md">Stoppage History </Accordion.Header>
          </Accordion.Item>
          <Accordion.Body>
            <div className="d-flex row">
            </div>
          </Accordion.Body>
        </Accordion>
        <hr />
        {/* Spares Utilazation History  section */}
        <Accordion defaultActiveKey="5" >
          <Accordion.Item eventKey="5">
            <Accordion.Header className="erp-form-label-md">Spares Utilazation History </Accordion.Header>
          </Accordion.Item>
          <Accordion.Body>
            <div className="d-flex row">
            </div>
          </Accordion.Body>
        </Accordion>

        {/* Add new Record Popup */}
        <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
          <Modal.Body className='erp_city_modal_body'>
            <div className='row'>
              <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
            </div>
            {displayRecordComponent()}
          </Modal.Body>
        </Modal >

      </div>

    </>
  )
} 
