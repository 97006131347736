import React, { useState, useRef, useEffect, useMemo, useLayoutEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $ from "jquery";
import { resetGlobalQuery, globalQuery } from 'assets/Constants/config-constant';

//React Bootstrap components
import { Table } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import ConfigConstants from "assets/Constants/config-constant";
import MDButton from "components/MDButton";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import MDTypography from "components/MDTypography";
import FrmValidations from 'FrmGeneric/FrmValidations';
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ComboBox from "Features/ComboBox";

function FrmLeavesApplicationEntry() {

    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const configConstants = ConfigConstants();
    const { COMPANY_ID, UserName, FINANCIAL_SHORT_NAME, UserId } = configConstants;

    const { state } = useLocation();
    const { keyForViewUpdate, leavesId = 0 } = state || {}

    // UseRefs
    const navigate = useNavigate();
    const validate = useRef();
    const frmValidation = useRef();
    const combobox = useRef();
    const generateAutoNoAPiCall = useRef();
    const comboDataAPiCall = useRef();
    const validateNumberPercentInput = useRef();
    const [leaves_transaction_id, setLeavesTransactionId] = useState(leavesId);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState("");

    const [leavesDetails, setLeavesApplicationData] = useState([]);
    const [leaves_applied_days, setLeavesAppliedDays] = useState(1);
    const [cmb_employee_id, setEmployeeName] = useState('');
    const [txt_leaves_sanction_days, setLeavesSanctionDays] = useState(0);
    const [txt_leaves_rejection_days, setLeavesRejectionDays] = useState(0);
    const [closing_balance, setClosingBalance] = useState(null);
    const [chk_isactive, setIsActive] = useState(true);
    const [chk_isLeavesandwich, setLeaveSandwich] = useState('No');
    const [cmb_employee_type, setEmployeeType] = useState([]);
    const [txt_employee_code, setEmployeeCode] = useState([]);
    const [txt_work_handover_id, setWorkHandoverId] = useState([]);
    const [txt_sanction_by_id, setSanctionById] = useState(UserId);
    const [applied_by_id, setAppliedById] = useState(UserId);
    const [cmb_leave_status, setLeaveStatus] = useState('Pending');
    const [txt_department_id, setDepartmentId] = useState('');
    const [reporting_to, setReportingTo] = useState('');
    const [txt_leave_type_id, setLeaveTypeId] = useState([]);
    const [dt_leaves_application_date, setApplicationDate] = useState(today);
    const [dt_leaves_requested_from_date, setLeavesRequestedFromDate] = useState(today);
    const [dt_leaves_requested_to_date, setLeavesRequestedToDate] = useState(today);
    const [dt_leaves_approved_from_date, setLeavesApprovedFromDate] = useState();
    const [dt_leaves_approved_to_date, setLeavesApprovedToDate] = useState();
    const [dt_approved_date, setApprovedDate] = useState(today());
    const [actionType, setActionType] = useState('')
    const [actionLabel, setActionLabel] = useState('Save')
    const [remarkLable, setRemarkLabel] = useState('Leave')

    const [txt_leave_approve_remark, setRemark] = useState('');
    const [txt_leave_reason, setLeaveReason] = useState('');

    const [txt_leaves_applications_id, setTransactionId] = useState('');
    const [existingCount, setExistingCount] = useState(0);

    const [employeeTypeOptions, setEmployeeTypeOptions] = useState([]);
    const [employeeAppliedBy, setEmployeeListBasedEmployeeType] = useState([]);
    const [departmentopt, setDepartmentOption] = useState([]);
    const [employeeListId, setEmployeeListOption] = useState([]);
    const [reportingToListId, setReportingToListOption] = useState([]);
    const [leaveTypeAll, setLeaveTypesAllOption] = useState([]);
    const [ApproveFlag, setApproveFlag] = useState(false);
    const currentDate = dt_leaves_requested_from_date;
    const leaveTodate = new Date().toISOString().split('T')[0];

    //Error Msg
    const handleCloseErrModal = () => { setShowErrorMsgModal(false) };

    useEffect(() => {
        const loadDataOnload = async () => {
            await ActionType()
            await fillCombos();
            if (leavesId !== 0) {
                await FnCheckUpdateResponce()
            } else {
                await generateLeavesNo();
            }
        }
        loadDataOnload()
    }, []);


    const generateLeavesNo = async () => {
        try {
            const transactionId = await generateAutoNoAPiCall.current.generateTAutoNo("hm_leaves_applications", "leaves_applications_id", "", 'L', "6");
            setTransactionId(transactionId);
            return transactionId;
        } catch (error) {
            navigate('/Error')
        }
    }
    const validateError = () => {
        frmValidation.current.validateFieldsOnChange('leaveForm');
    }
    const validateErrorMsgs = () => {
        frmValidation.current.validateFieldsOnChange('leavesDetailsFormId');
    }
    const validateForm = () => {
        frmValidation.current.validateFieldsOnChange('ApproveForm')
    }

    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        navigate(`/Masters/MLeavesApplication/FrmLeavesApplicationListing`)

    }

    const calculateAppliedDays = () => {
        const fromDateStr = document.getElementById('dt_leaves_requested_from_date').value;
        const toDateStr = document.getElementById('dt_leaves_requested_to_date').value;

        const fromDate = new Date(fromDateStr);
        const toDate = new Date(toDateStr);

        // Check if both dates are valid
        if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
            console.error('Invalid date input');
            return;
        }

        const diffTime = toDate.getTime() - fromDate.getTime();
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
        console.log(diffDays);
        if (diffDays < 0) {
            diffDays = 0;
        }
        setLeavesAppliedDays(diffDays);
    }

    const calculateApproveDays = (fromDateStrs, toDateStrs) => {
        const fromDate = new Date(fromDateStrs);
        const toDate = new Date(toDateStrs);
        // Check if both dates are valid
        if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
            console.error('Invalid date input');
            return;
        }

        const diffTime = toDate.getTime() - fromDate.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
        console.log(diffDays);
        setLeavesSanctionDays(diffDays);
        calculateRejectionDays(diffDays);
    }

    const calculateRejectionDays = (sanctionDays) => {
        const appliedDays = document.getElementById('leaves_applied_days').value;
        if (isNaN(sanctionDays)) {
            console.error('Invalid input for sanction days');
            return;
        }
        const rejectionDays = appliedDays - sanctionDays;
        setLeavesRejectionDays(rejectionDays);
    }

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                setRemarkLabel('Update')
                await validate.current.readOnly("ApproveForm");
                $('#leaves_applied_days').attr('disabled', true);

                break;
            case 'view':
                setActionType('(View)');
                setRemarkLabel('Leave')
                await validate.current.readOnly("leavesDetailsFormId");
                await validate.current.readOnly("leaveForm");
                await validate.current.readOnly("ApproveForm");
                $('#save-btn').hide();
                break;
            case 'approve':
                setActionType('(Approve)');
                setActionLabel('Approve')
                setRemarkLabel('Approve');
                setApproveFlag(true);
                setLeaveStatus("Approved");
                await validate.current.readOnly("leavesDetailsFormId");
                await validate.current.readOnly("leaveForm");
                $('#cmb_leave_status').attr('disabled', false);
                break;
            case 'cancel':
                setActionType('(Cancel)');
                setActionLabel('cancel');
                setRemarkLabel('Cancel');
                setLeaveStatus("Cancelled");
                await validate.current.readOnly("leavesDetailsFormId");
                await validate.current.readOnly("leaveForm");
                $('#cmb_leave_status').attr('disabled', false);
                break;
            default:
                setActionType('(Creation)');
                setSanctionById('');
                setApprovedDate('');
                await validate.current.readOnly("ApproveForm");
                $('#cmb_leave_status').attr('disabled', true);
                $('#leaves_applied_days').attr('disabled', true);
                break;
        }

    };

    const fillCombos = async () => {
        try {
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name'];
            globalQuery.table = "amv_properties";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'EmployeeType' });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.orderBy = ['property_name']
            const employeeTypesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setEmployeeTypeOptions(employeeTypesApiCall);

            const departmentIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_department", "", "");
            setDepartmentOption(departmentIdApiCall);

            const employeeListApiCall = await comboDataAPiCall.current.fillMasterData("cmv_employee_list", "", "");
            setEmployeeListOption(employeeListApiCall);

            const leaveTypeApiCall = await comboDataAPiCall.current.fillMasterData("hmv_leave_type", "", "");
            setLeaveTypesAllOption(leaveTypeApiCall);
        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }
    }

    const FnComboOnchange = async (key) => {
        switch (key) {
            case 'employee_type':
                const employeeType = document.getElementById('cmb_employee_type').value;
                $('#error_leaves_applied_days').hide();

                if (employeeType !== "") {
                    resetGlobalQuery();
                    setEmployeeCode('');
                    setEmployeeName('');
                    setDepartmentId('');
                    setReportingTo('');
                    globalQuery.columns = ['field_id', 'field_name', 'employee_code', 'department_name', 'reporting_to_name', 'department_id'];
                    globalQuery.table = "cmv_employee_summary";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "employee_type", operator: "=", value: employeeType });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const getAppliedByApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setEmployeeListBasedEmployeeType(getAppliedByApiCall);
                    break;
                }
            case 'employee_code':
                const employeeCode = document.getElementById('txt_employee_code').value;
                const employeetype = document.getElementById('cmb_employee_type').value;
                $('#error_leaves_applied_days').hide();
                setClosingBalance('');
                setLeaveTypeId('');
                if (employeeCode !== "" && employeetype !== "") {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'employee_code', 'department_name', 'reporting_to_name', 'department_id'];
                    globalQuery.table = "cmv_employee_summary";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "employee_code", operator: "=", value: employeeCode });
                    globalQuery.conditions.push({ field: "employee_type", operator: "=", value: employeetype });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const getAppliedByApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setEmployeeListBasedEmployeeType(getAppliedByApiCall);


                    const selectedEmployee = getAppliedByApiCall.find(employee => employee.employee_code === employeeCode);
                    if (selectedEmployee) {
                        setEmployeeCode(selectedEmployee.employee_code);
                        setReportingTo(selectedEmployee.reporting_to_name);
                        const departmentID = selectedEmployee.department_id;
                        setDepartmentId(departmentID);
                        FnComboOnchange('employee_baseDeparmentAndType');
                    }
                    break;
                }
            case 'employee_name':
                const selectedEmployeeFieldName = document.getElementById('cmb_employee_id').options[document.getElementById('cmb_employee_id').selectedIndex].textContent;
                const selectedEmployee = employeeAppliedBy.find(employee => employee.field_name === selectedEmployeeFieldName);
                $('#error_leaves_applied_days').hide();
                setClosingBalance('');
                setLeaveTypeId('');
                if (selectedEmployee) {
                    setEmployeeCode(selectedEmployee.employee_code);
                    setReportingTo(selectedEmployee.reporting_to_name);
                    const departmentID = selectedEmployee.department_id;
                    setDepartmentId(departmentID);

                    FnComboOnchange('employee_baseDeparmentAndType');
                }
                FnComboOnchange('leave_To_date');
                break;

            case 'employee_baseDeparmentAndType':
                const departmentID = document.getElementById('txt_department_id').value;
                const employee_type = document.getElementById('cmb_employee_type').value;
                $('#error_leaves_applied_days').hide();

                if (employee_type !== "" && departmentID !== "") {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'department_name'];
                    globalQuery.table = "cmv_employee_list";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "department_id", operator: "=", value: departmentID });
                    globalQuery.conditions.push({ field: "employee_type", operator: "=", value: employee_type });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const reportingToListApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setReportingToListOption(reportingToListApiCall);

                }

            case 'leave_type':
                const leaveType = document.getElementById('txt_leave_type_id').value;
                const leaveAppliedDays = parseFloat(document.getElementById('leaves_applied_days').value);
                const leaveTypeId = parseInt(leaveType, 10);
                setClosingBalance('');
                $('#error_leaves_applied_days').hide();
                let tempClosingBalance = null;
                for (const details of leavesDetails) {
                    // Check if leaveType matches the current details
                    if (leaveTypeId === details.leave_type_id) {
                        tempClosingBalance = details.closing_balance;
                        break;
                    }
                }
                setClosingBalance(tempClosingBalance);
                if (leaveAppliedDays !== "" && tempClosingBalance !== null) {
                    if (leaveAppliedDays > tempClosingBalance) {
                        $('#error_leaves_applied_days').text('The number of days you are applying for exceeds your available leave balance.');
                        $('#error_leaves_applied_days').show();
                    } else {
                        $('#error_leaves_applied_days').hide();
                    }
                }

                break;
            case 'leave_To_date':
                if (document.getElementById('txt_employee_code').value !== "") {
                    const employeeCode = document.getElementById('txt_employee_code').value
                    const leaveRequestedFromDate = document.getElementById('dt_leaves_requested_from_date').value
                    const leaveRequestedToDate = document.getElementById('dt_leaves_requested_to_date').value
                    const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HmLeavesApplications/FnShowExisitingCount/${employeeCode}/${leaveRequestedFromDate}/${leaveRequestedToDate}`)
                    const response = await apiCall.json();
                    const count = response.ShowExisitingCount.exsitingCount
                    setExistingCount(count)
                    if (count !== 0) {
                        $(`#error_dt_leaves_requested_to_date`).text(`Application Is Allready Exist.`);
                        $(`#error_dt_leaves_requested_to_date`).show();
                    } else {
                        $(`#error_dt_leaves_requested_to_date`).hide();
                    }
                }
                validatedatetimeFields('leave_date');
                break;
            case 'leave_status':
                const leave_Status = document.getElementById('cmb_leave_status').value;
                if (leave_Status === "Rejected" || leave_Status === "Pending") {
                    setLeavesSanctionDays(0);
                    setLeavesRejectionDays(leaves_applied_days);
                }

                break;
        }

    };

    useLayoutEffect(() => {
        const functionCall = async () => {
            await FnFillEmployeeLeavesBalance()
        }
        functionCall()
    }, [txt_employee_code, cmb_employee_type])


    const FnFillEmployeeLeavesBalance = async () => {
        const employeetype = document.getElementById('cmb_employee_type').value;
        try {
            if (document.getElementById('txt_employee_code').value !== "" && employeetype !== "") {
                resetGlobalQuery();
                globalQuery.columns = ['E.employee_code', 'E.employee_name', 'ML.leave_type_name', 'ML.leave_type_code',
                    `IFNULL((select IFNULL(LB1.opening_balance,0) from hm_leaves_balance LB1 where LB1.employee_code = E.employee_code and LB1.is_delete =0 and LB1.leave_type_id  = ML.leave_type_id  and LB1.financial_year =${FINANCIAL_SHORT_NAME} ),0) as opening_balance`,
                    `IFNULL((select IFNULL(LB2.leaves_earned,0) from hm_leaves_balance  LB2 where LB2.employee_code =E.employee_code and LB2.is_delete =0 and LB2.leave_type_id  = ML.leave_type_id  and LB2.financial_year = ${FINANCIAL_SHORT_NAME} ),0) as leaves_earned`,
                    `IFNULL((select IFNULL(LB2.leaves_taken,0) from hm_leaves_balance  LB2 where LB2.employee_code =E.employee_code and LB2.is_delete =0 and LB2.leave_type_id  = ML.leave_type_id  and LB2.financial_year = ${FINANCIAL_SHORT_NAME} ),0) as leaves_taken`,
                    `IFNULL((select IFNULL(LB4.leaves_adjusted,0) from hm_leaves_balance  LB4 where LB4.employee_code =E.employee_code and LB4.is_delete =0 and LB4.leave_type_id  = ML.leave_type_id  and LB4.financial_year = ${FINANCIAL_SHORT_NAME} ),0) as leaves_adjusted`,
                    `IFNULL((select IFNULL(LB3.closing_balance,0) from hm_leaves_balance  LB3 where LB3.employee_code =E.employee_code  and LB3.is_delete =0 and LB3.leave_type_id  = ML.leave_type_id  and LB3.financial_year =${FINANCIAL_SHORT_NAME}  ),0) as closing_balance`,
                    'ML.leave_type_id', 'E.employee_id', 'E.employee_code',
                ];
                globalQuery.table = "cmv_employee_list E";
                globalQuery.joins = [{
                    "table": "hmv_leave_type ML",
                    "type": "cross",
                    "on": {
                        "left": "",
                        "right": ""
                    }
                }
                ]
                globalQuery.conditions.push({ field: "E.is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "ML.leave_type_paid_flag", operator: "=", value: 'Paid' });
                globalQuery.conditions.push({ field: "ML.is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "E.employee_code", operator: "=", value: document.getElementById('txt_employee_code').value });
                globalQuery.conditions.push({ field: "E.employee_type", operator: "=", value: employeetype });

                globalQuery.orderBy = ['ML.leave_type_id Asc']

                const getLeavesDetails = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                console.log('getLeavesDetails: ', getLeavesDetails);
                setLeavesApplicationData(getLeavesDetails)

                if (getLeavesDetails.length === 0) {
                    $('#error_employee_code').text('This Type of Employee does not Exist.');
                    $('#error_employee_code').show();
                } else {
                    $('#error_employee_code').hide();
                }
                return getLeavesDetails;
            }

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    const validatedatetimeFields = (key) => {
        const startLeavesDate = document.getElementById('dt_leaves_requested_from_date').value;
        const endLeavesDate = document.getElementById('dt_leaves_requested_to_date').value;
        const startApproveDateTime = document.getElementById('dt_leaves_approved_from_date').value;
        const endApproveDateTime = document.getElementById('dt_leaves_approved_to_date').value;
        switch (key) {
            case 'leave_date':
                if (startLeavesDate !== "") {
                    if (endLeavesDate !== "") {
                        if (startLeavesDate > endLeavesDate) {
                            $(`#error_dt_leaves_requested_from_date`).text(`Leave To Date should be greater than Leave From Date`);
                            $(`#error_dt_leaves_requested_from_date`).show();
                        } else {
                            $(`#error_dt_leaves_requested_from_date`).hide();
                        }
                    }
                }
                break;
            case 'leave_Fromdate':
                if (startApproveDateTime !== "" && endApproveDateTime !== "") {
                    if (startApproveDateTime > endApproveDateTime) {
                        setLeavesApprovedFromDate(startLeavesDate);
                        calculateApproveDays(startLeavesDate, endApproveDateTime);

                    } else {
                        calculateApproveDays(startApproveDateTime, endApproveDateTime);
                    }

                }
            case 'approve_date':
                if (startApproveDateTime !== "" && endApproveDateTime !== "" && startApproveDateTime >= startLeavesDate && endApproveDateTime <= endLeavesDate) {
                    $(`#error_dt_leaves_approved_to_date`).hide();
                    setLeavesApprovedFromDate(startApproveDateTime);
                    setLeavesApprovedToDate(endApproveDateTime);
                    calculateApproveDays(startApproveDateTime, endApproveDateTime);
                } else {
                    $(`#error_dt_leaves_approved_to_date`).text(`The Approve To Date falls within the range of the Leave To Date.`);
                    $(`#error_dt_leaves_approved_to_date`).show();
                    setLeavesApprovedFromDate(startLeavesDate);
                    setLeavesApprovedToDate(endLeavesDate);
                    calculateApproveDays(startLeavesDate, endLeavesDate);
                };
                break;
        }
    }

    //Fn for render Leaves Details static table 
    const renderLeavesDetailsTable = useMemo(() => {
        return <>
            {leavesDetails.length !== 0 ? (
                <Table id='leavesDetails-table' className="erp_table" bordered striped >
                    <thead className="erp_table_head">
                        <tr>
                            <th className="erp_table_th col-2">LeaveType Desc </th>
                            <th className="erp_table_th col-1">Opening Balance</th>
                            <th className="erp_table_th col-1">Leaves Earned</th>
                            <th className="erp_table_th col-1">Leaves Taken</th>
                            <th className="erp_table_th col-1">Leaves Adjusted</th>
                            <th className="erp_table_th col-1">Closing Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            leavesDetails.map((leavesItem, Index) =>
                                <tr payTermItemIndex={Index} rowIndex={Index}>
                                    <td className="erp_table_td ">{leavesItem.leave_type_name}</td>
                                    <td className="erp_table_td text-end">{leavesItem.opening_balance}</td>
                                    <td className="erp_table_td text-end">{leavesItem.leaves_earned}</td>
                                    <td className="erp_table_td text-end">{leavesItem.leaves_taken}</td>
                                    <td className="erp_table_td text-end">{leavesItem.leaves_adjusted}</td>
                                    <td className="erp_table_td text-end">{leavesItem.closing_balance}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            ) : null}
        </>
    }, [leavesDetails]);

    const FnCheckUpdateResponce = async () => {
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HmLeavesApplications/FnShowParticularRecordForUpdate/${COMPANY_ID}/${leavesId}`)
            const response = await apiCall.json();
            const { leaves_transaction_id,
                leaves_applications_id,
                leaves_application_date,
                employee_type,
                employee_id,
                employee_code,
                department_id,
                leave_type_id,
                leaves_requested_from_date,
                leaves_requested_to_date,
                leaves_approved_from_date,
                leaves_approved_to_date,
                leaves_applied_days,
                leaves_sanction_days,
                leaves_rejection_days,
                work_handover_id,
                approved_date,
                applied_by_id,
                leave_status,
                leave_approve_remark,
                leave_reason,
                leave_sandwich,
                is_active, approved_by_id } = response.data;

            setLeavesTransactionId(leaves_transaction_id)
            setApplicationDate(leaves_application_date)
            setTransactionId(leaves_applications_id)
            setSanctionById(approved_by_id);
            setEmployeeType(employee_type);
            await FnComboOnchange('employee_type');
            setEmployeeName(employee_id);
            setEmployeeCode(employee_code);
            await FnComboOnchange('employee_code');
            setDepartmentId(department_id);
            const leaveBalnceData = await FnFillEmployeeLeavesBalance(employee_code, employee_type);
            setLeaveTypeId(leave_type_id);
            let tempClosingBalance = null;
            for (const details of leaveBalnceData) {
                if (leave_type_id === details.leave_type_id) {
                    tempClosingBalance = details.closing_balance;
                    break;
                }
            }
            setClosingBalance(tempClosingBalance);
            setLeavesRequestedFromDate(leaves_requested_from_date)
            setLeavesRequestedToDate(leaves_requested_to_date)
            setLeaveSandwich(leave_sandwich);
            setLeaveStatus(leave_status);
            if (leave_status === "Pending") {
                setLeavesSanctionDays(0);
                setLeavesRejectionDays(leaves_applied_days);
            }
            if (keyForViewUpdate === "approve") {
                setLeavesApprovedFromDate(leaves_requested_from_date);
                setLeavesApprovedToDate(leaves_requested_to_date);
                setApprovedDate(today);
                setLeaveStatus("Approved")
            } else {
                setLeavesApprovedFromDate(leaves_approved_from_date);
                setLeavesApprovedToDate(leaves_approved_to_date);
                setApprovedDate(approved_date);
            }
            setLeavesSanctionDays(leaves_sanction_days);
            if (keyForViewUpdate === "cancel") {
                calculateApproveDays(0);
                setLeavesApprovedFromDate(leaves_requested_from_date);
                setLeavesApprovedToDate(leaves_requested_to_date);
                setApprovedDate(today);
                setLeaveStatus("Cancelled")
                setLeavesSanctionDays(0);
                setLeavesRejectionDays(leaves_applied_days);
            } else {
                calculateApproveDays(leaves_requested_from_date, leaves_requested_to_date);

            }
            if (keyForViewUpdate === "view") {
                setLeavesSanctionDays(leaves_sanction_days);
            }
            if (keyForViewUpdate === "update") {
                setLeavesSanctionDays(0);
                setSanctionById('');
                setApprovedDate('');
            }
            setLeavesAppliedDays(leaves_applied_days);
            setLeavesRejectionDays(leaves_rejection_days);
            setWorkHandoverId(work_handover_id);
            setRemark(leave_approve_remark);
            setLeaveReason(leave_reason);
            setAppliedById(applied_by_id);
            setIsActive(is_active);
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }

    }

    const leavesApplicationData = async () => {
        const ClosingBalance = parseFloat(document.getElementById('closing_balance').value);
        const leaveAppliedDays = parseFloat(document.getElementById('leaves_applied_days').value);
        let checkIsValidate = false;
        if (keyForViewUpdate === "approve" || keyForViewUpdate === "cancel") {
            checkIsValidate = await frmValidation.current.validateForm("ApproveForm");
        } else if (keyForViewUpdate === "add") {
            const leavesDetailsFormValid = await frmValidation.current.validateForm("leavesDetailsFormId");
            const leaveFormValid = await frmValidation.current.validateForm("leaveForm");

            // Check if any of the validations fail
            if (!leavesDetailsFormValid || !leaveFormValid) {
                checkIsValidate = false;
            } else {
                checkIsValidate = true;
            }
        }
        if (leaveAppliedDays > ClosingBalance) {
            $('#error_leaves_applied_days').text('The number of days you are applying for exceeds your available leave balance.');
            $('#error_leaves_applied_days').show();
            return false;
        } else {
            $('#error_leaves_applied_days').hide();
        }
        if (existingCount !== 0 && keyForViewUpdate === "add") {
            FnComboOnchange('leave_To_date');
            return false;
        }
        if (checkIsValidate) {
            try {
                const leavesApplicationData = {
                    company_id: COMPANY_ID,
                    leaves_application_date: dt_leaves_application_date,
                    leaves_applications_id: txt_leaves_applications_id,
                    leaves_transaction_id: leaves_transaction_id,
                    employee_code: txt_employee_code,
                    employee_type: cmb_employee_type,
                    employee_id: cmb_employee_id,
                    department_id: txt_department_id,
                    leave_type_id: txt_leave_type_id,
                    leaves_requested_from_date: dt_leaves_requested_from_date,
                    created_by: UserName,
                    leaves_requested_to_date: dt_leaves_requested_to_date,
                    financial_year: FINANCIAL_SHORT_NAME,
                    is_active: chk_isactive,
                    leave_sandwich: chk_isLeavesandwich,
                    leaves_approved_from_date: dt_leaves_approved_from_date,
                    leaves_approved_to_date: dt_leaves_approved_to_date,
                    leaves_applied_days: leaves_applied_days,
                    leaves_sanction_days: txt_leaves_sanction_days,
                    leaves_rejection_days: txt_leaves_rejection_days,
                    work_handover_id: txt_work_handover_id,
                    approved_date: dt_approved_date,
                    applied_by_id: applied_by_id,
                    leave_status: cmb_leave_status,
                    leave_approve_remark: txt_leave_approve_remark,
                    leave_reason: txt_leave_reason,
                    approved_by_id: txt_sanction_by_id,
                    modified_by: leaves_transaction_id === 0 ? null : UserName,
                }

                console.log(leavesApplicationData);
                if (keyForViewUpdate !== "approve" && keyForViewUpdate !== "cancel") {
                    leavesApplicationData.leaves_approved_from_date = '';
                    leavesApplicationData.leaves_approved_to_date = '';
                    leavesApplicationData.approved_date = '';
                }

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(leavesApplicationData)
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/HmLeavesApplications/FnAddUpdateRecord/${ApproveFlag}`, requestOptions)
                const resp = await apicall.json()
                console.log(resp);
                if (resp.success === '0') {
                    setErrMsg(resp.error)
                    setShowErrorMsgModal(true)
                } else {
                    setIsActive(true);
                    console.log("resp: ", resp)
                    setSuccMsg(resp.message)
                    setShowSuccessMsgModal(true)
                }
            } catch (error) {
                console.log("error: ", error)
                navigate('/Error')
            }
        }
    }

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ComboBox ref={combobox} />
            <FrmValidations ref={validate} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <ValidateNumberDateInput ref={validateNumberPercentInput} />
            <FrmValidations ref={frmValidation} />
            <div>
                <div className="erp_top_Form">
                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg main_heding'>Leaves Application {actionType}</label>
                        </div>
                        <form id='leavesDetailsFormId'>
                            <div className="row erp_transporter_div ">
                                <div className="col-sm-4 erp_form_col_div">
                                    <div className="col-sm-12 erp_form_col_div">
                                        <div className="row">
                                            <div className="col-sm-4 col-12">
                                                <Form.Label className="erp-form-label"> Transactions Id <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col-12 col-md-4 pe-md-0">
                                                <Form.Control type="text" id="txt_leaves_applications_id" className="erp_input_field" value={txt_leaves_applications_id} onChange={e => { setTransactionId(e.target.value); }} disabled />
                                                <MDTypography variant="button" id="error_txt_leaves_applications_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Employee Type <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <select id="cmb_employee_type" className="form-select form-select-sm" value={cmb_employee_type} onChange={e => { FnComboOnchange('employee_type'); setEmployeeType(e.target.value); validateErrorMsgs() }} maxLength="255">
                                                    <option value="" disabled>Select</option>
                                                    {employeeTypeOptions?.map(employeeTypes => (
                                                        <option value={employeeTypes.field_name}>{employeeTypes.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_employee_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4 col-12">
                                                <Form.Label className="erp-form-label"> Employee Code & Name  <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col-12 col-md-3 pe-md-0">
                                                <Form.Control type="text" id="txt_employee_code" className="erp_input_field" value={txt_employee_code} onChange={(e) => { setEmployeeCode(e.target.value); validateErrorMsgs() }} onBlur={e => { FnComboOnchange('employee_code'); }} />
                                                <MDTypography variant="button" id="error_txt_employee_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-5 pt-md-0 pt-3 ps-md-1">
                                                <select id="cmb_employee_id" className="form-select form-select-sm" value={cmb_employee_id} onChange={e => { FnComboOnchange('employee_name'); setEmployeeName(e.target.value); validateErrorMsgs() }} maxLength="20">
                                                    <option value="" disabled>Select</option>
                                                    {employeeAppliedBy?.map(employeename => (
                                                        <option value={employeename.field_id}>{employeename.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_employee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <MDTypography variant="button" id="error_employee_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>
                                {/* second column */}
                                <div className="col-sm-4 erp_form_col_div">
                                    <div className="col-sm-12 erp_form_col_div">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Department Name<span className="required">*</span></Form.Label>
                                            </div>

                                            <div className="col">
                                                <select id="txt_department_id" className="form-select form-select-sm" value={txt_department_id} onChange={e => { setDepartmentId(e.target.value); validateErrorMsgs() }} maxLength="20" disabled>
                                                    <option value="" disabled>Select</option>
                                                    {departmentopt.map(department => (
                                                        <option value={department.field_id}>{department.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_txt_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Leave Status <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control as="select" id="cmb_leave_status" className="form-select form-select-sm" value={cmb_leave_status} onChange={e => { setLeaveStatus(e.target.value); FnComboOnchange('leave_status'); }} disabled={keyForViewUpdate === 'cancel'} maxLength="255" required>
                                                    <option value="Pending">Pending</option>
                                                    <option value="Approved">Approved</option>
                                                    <option value="Rejected">Rejected</option>
                                                    <option value="Cancelled">Cancelled</option>
                                                </Form.Control>
                                                <MDTypography variant="button" id="error_cmb_leave_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label"> Leave Reason <span className="required">*</span> </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control as="textarea" id="txt_leave_reason" className="erp_txt_area optional" value={txt_leave_reason} onChange={e => { setLeaveReason(e.target.value); validateErrorMsgs() }} maxlength="500" />
                                                <MDTypography variant="button" id="error_txt_leave_reason" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 erp_form_col_div">
                                    <div className="col-sm-12 erp_form_col_div">
                                        <div className="row">

                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Applied bY <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <select id="applied_by_id" className="form-select form-select-sm" value={applied_by_id} onChange={e => { setAppliedById(e.target.value); validateErrorMsgs(); }} maxLength="255">
                                                    <option value="" disabled>Select</option>
                                                    {employeeAppliedBy?.map(employeename => (
                                                        <option value={employeename.field_id}>{employeename.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_applied_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Reporting To Name<span className="required">*</span></Form.Label>
                                            </div>

                                            <div className="col">
                                                <select id="reporting_to" className="form-select form-select-sm" value={reporting_to} onChange={e => { setReportingTo(e.target.value); validateErrorMsgs(); }} maxLength="255">
                                                    <option value="" disabled>Select</option>
                                                    {employeeListId?.map(report => (
                                                        <option value={report.field_name}>{report.field_name}</option>
                                                    ))}
                                                </select>

                                                <MDTypography variant="button" id="error_reporting_to" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Work Handover To <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <select id="txt_work_handover_id" className="form-select form-select-sm" value={txt_work_handover_id} onChange={e => { setWorkHandoverId(e.target.value); validateErrorMsgs(); }} maxLength="255">
                                                    <option value="" disabled>Select</option>
                                                    {reportingToListId?.map(workHand => (
                                                        <option value={workHand.field_id}>{workHand.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_txt_work_handover_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form id="leaveForm">
                            <hr></hr>
                            <div class="erp-form-label-lg">Leaves Application Details</div>
                            <div className="row erp_transporter_div ">
                                <div className="col-sm-4 erp_form_col_div">
                                    <div className="col-sm-12 erp_form_col_div">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Leave Type <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <select
                                                    className="form-select form-select-sm" id="txt_leave_type_id" value={txt_leave_type_id} onChange={(e) => { FnComboOnchange('leave_type'); setLeaveTypeId(e.target.value); }} onBlur={e => { validateError(); }} maxLength="20">
                                                    <option value="" disabled>Select / Leave Type</option>
                                                    {leaveTypeAll?.map(leavetype => (
                                                        <option value={leavetype.field_id}> {leavetype.field_name} </option>
                                                    ))}
                                                </select>

                                                <MDTypography variant="button" id="error_txt_leave_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label"> Leave From <span className="required">*</span> </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="date" id="dt_leaves_requested_from_date" className="erp_input_field" value={dt_leaves_requested_from_date} onChange={e => { calculateAppliedDays(); setLeavesRequestedFromDate(e.target.value); }} onBlur={e => { calculateAppliedDays(); }} min={leaveTodate} />
                                                <MDTypography variant="button" id="error_dt_leaves_requested_from_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label"> Applied Days <span className="required">*</span></Form.Label>
                                            </div>

                                            <div className="col-sm-3">
                                                <Form.Control type="text" id="leaves_applied_days" className="erp_input_field text-end" value={leaves_applied_days} onChange={(e) => { setLeavesAppliedDays(e.target.value); validateError() }} />
                                            </div>
                                            <MDTypography variant="button" id="error_leaves_applied_days" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 erp_form_col_div">
                                    <div className="col-sm-12 erp_form_col_div">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label"> Leave Balance <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col-sm-4">
                                                <Form.Control type="text" id='closing_balance' className="erp_input_field text-end" value={closing_balance} onChange={(e) => { setClosingBalance(e.target.value); validateError(); }} optional="optional" disabled />
                                                <MDTypography variant="button" id="error_closing_balance" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label"> Leave To <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="date" id="dt_leaves_requested_to_date" className="erp_input_field" value={dt_leaves_requested_to_date}
                                                    onChange={e => { calculateAppliedDays(); setLeavesRequestedToDate(e.target.value); }}
                                                    onBlur={e => { FnComboOnchange('leave_To_date'); FnComboOnchange('leave_type'); }} min={leaveTodate} />
                                                <MDTypography variant="button" id="error_dt_leaves_requested_to_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Leave Sandwich ?</Form.Label>
                                            </div>
                                            <div className="col">
                                                <div className="erp_form_radio">
                                                    <div className="fCheck">
                                                        <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="Yes" name="chk_isLeavesandwich" checked={chk_isLeavesandwich === 'Yes'} onClick={() => { setLeaveSandwich('Yes'); }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'cancel'} />
                                                    </div>
                                                    <div className="sCheck">
                                                        <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="No" name="chk_isLeavesandwich" checked={chk_isLeavesandwich === 'No'} onClick={() => { setLeaveSandwich('No'); }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'cancel'} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 erp_form_col_div">
                                    <div className="row erp_table_scroll" style={{ height: "100px", display: "block", overflow: "auto" }}>
                                        <div>
                                            {renderLeavesDetailsTable}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <hr></hr>

                        <form id="ApproveForm">
                            <div class="erp-form-label-lg">Leaves Approve Details</div>
                            <div className="row erp_transporter_div ">
                                <div className="col-sm-4 erp_form_col_div">
                                    <div className="col-sm-12 erp_form_col_div">
                                        <div className="row">

                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Approve by <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <select id="txt_sanction_by_id" className="form-select form-select-sm" value={txt_sanction_by_id} onChange={e => { setSanctionById(e.target.value); validateForm() }} maxLength="255" disabled={keyForViewUpdate === 'cancel'}>
                                                    <option value="" disabled>Select</option>
                                                    {reportingToListId?.map(employeeTypes => (
                                                        <option value={employeeTypes.field_id}>{employeeTypes.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_txt_sanction_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label"> Approve From <span className="required">*</span> </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="date" id="dt_leaves_approved_from_date" className="erp_input_field" value={dt_leaves_approved_from_date} onChange={e => { setLeavesApprovedFromDate(e.target.value); validatedatetimeFields('leave_Fromdate'); }} min={currentDate} disabled={keyForViewUpdate === 'cancel'} />
                                                <MDTypography variant="button" id="error_dt_leaves_approved_from_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label"> Approve To <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="date" id="dt_leaves_approved_to_date" className="erp_input_field" value={dt_leaves_approved_to_date} onChange={e => { setLeavesApprovedToDate(e.target.value); validatedatetimeFields('approve_date'); }} disabled={keyForViewUpdate === 'cancel'} />
                                                <MDTypography variant="button" id="error_dt_leaves_approved_to_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 erp_form_col_div">
                                    <div className="col-sm-12 erp_form_col_div">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Approve Days <span className="required">*</span></Form.Label>
                                            </div>

                                            <div className="col-sm-3">
                                                <Form.Control type="text" id="txt_leaves_sanction_days" className="erp_input_field text-end" value={txt_leaves_sanction_days} onChange={(e) => { setLeavesSanctionDays(e.target.value); validateForm() }} disabled />
                                                <MDTypography variant="button" id="error_txt_leaves_sanction_days" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                                </MDTypography>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Rejected Days <span className="required">*</span></Form.Label>
                                            </div>

                                            <div className="col-sm-3">
                                                <Form.Control type="text" id="txt_leaves_rejection_days" className="erp_input_field text-end" value={txt_leaves_rejection_days} onChange={(e) => { setLeavesRejectionDays(e.target.value); validateForm() }} disabled />
                                                <MDTypography variant="button" id="error_txt_leaves_rejection_days" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label"> Approve Date <span className="required">*</span> </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="date" id="dt_approved_date" className="erp_input_field" value={dt_approved_date} onChange={e => { setApprovedDate(e.target.value); }} disabled />
                                                <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 erp_form_col_div">
                                    <div className="col-sm-12 erp_form_col_div">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">{remarkLable} Remark <span className="required">*</span> </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control as="textarea" id="txt_leave_approve_remark" className="erp_txt_area optional" value={txt_leave_approve_remark} onChange={e => { setRemark(e.target.value); validateForm() }} maxlength="500" />
                                                <MDTypography variant="button" id="error_txt_leave_approve_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Is Active</Form.Label>
                                            </div>
                                            <div className="col">
                                                <div className="erp_form_radio">
                                                    <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" name="chk_isactive" checked={chk_isactive} onClick={() => { setIsActive(true); }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'add' || keyForViewUpdate === 'update' || keyForViewUpdate === 'cancel'} /> </div>
                                                    <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" name="chk_isactive" checked={!chk_isactive} onClick={() => { setIsActive(false); }} disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'add' || keyForViewUpdate === 'update' || keyForViewUpdate === 'cancel'} /> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="row col-12">
                            <div className="text-center py-0 mb-5 pt-4">
                                <MDButton type="button" className="erp-gb-button ms-2" onClick={() => { navigate(`/Masters/MLeavesApplication/FrmLeavesApplicationListing`) }} variant="button"
                                    fontWeight="regular">Back</MDButton>
                                <MDButton type="submit" onClick={() => leavesApplicationData()} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                                    fontWeight="regular">{actionLabel}</MDButton>

                            </div >
                            {/* Success Msg Popup */}
                            <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                            {/* Error Msg Popup */}
                            <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
                        </div>
                    </div >
                </div >
            </div >
        </>
    )
}

export default FrmLeavesApplicationEntry;