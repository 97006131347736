import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ConfigConstants from 'assets/Constants/config-constant';

const TServiceJobReciptprint = React.forwardRef(({ invoiceContent }, ref) => {
    const configConstants = ConfigConstants();
    const { AFTER_DECIMAL_PLACES } = configConstants;
    debugger
    const responseValue = invoiceContent.accessoriesincludedDetails.equipmentaccessories;
    const responseArray = responseValue.split(',');

    const invoiceHeader = (
        <>
            <div className='bordered border-bottom-0 px-0'>
                <div className='row mb-0 p-2' >
                    <div className="col-sm-2 text-center text-sm-start">
                        <img src={DakshabhiLogo} alt="master card" width="100" height="100" mt={1} />
                    </div>
                    <div className="col-sm-8 transformed">
                        <div className='erp-invoice-print-label-lg text-center'>
                            {invoiceContent.companyDetails.company}
                        </div>
                        <div className="row">
                            <div className="text-center">
                                <div className='erp-invoice-print-label text-center'>
                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg' >{invoiceContent.companyDetails.address} {invoiceContent.companyDetails.state_name}-{invoiceContent.companyDetails.company_pincode}  </span>
                                    </div>
                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>WebSite: {invoiceContent.companyDetails.website}  || Email: {invoiceContent.companyDetails.email} </span>
                                    </div>
                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'> Phone: {invoiceContent.companyDetails.phone} || Cell:{invoiceContent.companyDetails.cell} </span>
                                    </div>
                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>GSTN No:{invoiceContent.companyDetails.GSTIN}|| PAN No:{invoiceContent.companyDetails.pan_no} </span>
                                    </div>
                                    <div className='erp-invoice-print-label-lg'> Service Job Receipt </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>






                <div className='row border-bottom border-top border-dark p-0 m-0'>
                    <div className="row p-0 m-0">
                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Company :</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'>{invoiceContent.company.company} </dd>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Service Job Receipt :</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'>{invoiceContent.customerDetails.servicejobreceiptcode} </dd>


                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Customer Name :</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.customerDetails.name}</dd>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Customer Mob No.:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.customerDetails.customer_mobile_no} </dd>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Customer Email :</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'>{invoiceContent.customerDetails.Email} </dd>

                            </dl>
                        </div>

                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Date :</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.customerDetails.servicejobreceiptdate}  </dd>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Customer Address :</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.customerDetails.address}  </dd>

                                {/* <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Assign To :</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'>  </dd> */}

                                {/* <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Priority level :</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'>{invoiceContent.customerDetails.prioritylevel}  </dd> */}

                                {/* <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Job Receipt Status :</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.customerDetails.jobreceiptstatus}  </dd> */}
                            </dl>
                        </div>
                    </div>

                    <div className="row border-bottom border-top border-dark  p-0 m-0 ">
                        <dt className='col erp-invoice-print-label-md-lg'>Details of Equipement:</dt>
                        <dd className='col-sm-8 erp-invoice-print-label'> </dd>

                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Type  :</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.equipmentDetails.equipmenttype}</dd>


                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Make  :</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.equipmentDetails.equipmentmake}</dd>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Model No. :</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.equipmentDetails.equipmentmodelno}</dd>
                            </dl>
                        </div>

                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Serial No :</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.equipmentDetails.equipmentserialno}</dd>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Issue Reported :</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.equipmentDetails.equipmentissuedesc}</dd>

                            </dl>
                        </div>

                    </div>


                    <div className="row p-0 m-0 ">
                        <dt className='col erp-invoice-print-label-md-lg'>Accessories Included :</dt>
                        <dd className='col-sm-8 erp-invoice-print-label'> </dd>

                        <div className="col-sm-6">
                            <dl className="row pb-0 ps-2 mb-1">
                                {
                                    responseArray?.map((acessories, Index) =>
                                        <>
                                            <dd className='col ps-1 mt-2 erp-invoice-print-label'> {Index + 1}. {acessories} </dd>

                                        </>
                                    )
                                }
                            </dl>

                        </div>

                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Accessories Note :</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {invoiceContent.accessoriesincludedDetails.accessoriesdesc}</dd>
                            </dl>
                        </div>
                    </div>

                    <div className="row p-0 m-0 ">
                        <dt className='col-sm-2 erp-invoice-print-label-md-lg'>Terms and Condition's :</dt>
                    </div>

                    <dt className='col-sm-12 erp-invoice-print-label-md-lg'>Testing Charges 350/- </dt>
                    <dt className='col-sm-12 erp-invoice-print-label-md-lg'>1. All Matenals received for repairs are subject to verifications by ITS.</dt>
                    <dt className='col-sm-12 erp-invoice-print-label-md-lg'>2. Contents and parts of dead jobs can not be confirmed without opening it. Hence any disputes regarding it will be entertained.</dt>
                    <dt className='col-sm-12 erp-invoice-print-label-md-lg'>ITS will be the final Authority in this regards. 3. Warranty given stands only for the problem solved by us or part replaced by us. </dt>
                    <dt className='col-sm-12 erp-invoice-print-label-md-lg'>4. Estimate for repairs will be given to customer via phone / e-mail for approval. Repairs will be initiated after getting the confirmation for the same.</dt>
                    <dt className='col-sm-12 erp-invoice-print-label-md-lg'>5. Customers are requested to approve the given estimate within 3 working days. quotes can be issued during the repairs for any other parts failure if found. It will be repaired on approval of subsequent </dt>
                    <dt className='col-sm-12 erp-invoice-print-label-md-lg'>6. Further quote </dt>
                    <dt className='col-sm-12 erp-invoice-print-label-md-lg'>7. If the customer doesn't want to get their job repaired after getting the estimate, service charge will be evade for our diagnostic efforts in case of repairable job. </dt>
                    <dt className='col-sm-12 erp-invoice-print-label-md-lg'>8. We will not take responsibility of functioning of spares of dead /partial working job received for repairs. </dt>
                    <dt className='col-sm-12 erp-invoice-print-label-md-lg'>9. All the spare parts supplied are under exchange, the defective parts will be retained by us.</dt>
                    <dt className='col-sm-12 erp-invoice-print-label-md-lg'>10. Mode of payment can be in cash/D.D./Card Payment. Payment should be given at the time of delivery of jobs. </dt>
                    <dt className='col-sm-12 erp-invoice-print-label-md-lg'>11. Customers are requested to pick up the jobs given for repairs within 30 days. We will be not responsible for any damage/loss of jobs given for repairs if material is not picked up within 30 days from the date or receipt.</dt>
                    <dt className='col-sm-12 erp-invoice-print-label-md-lg'>12. We have taken sufficient measures to preserve our customer's jobs from any kind of damage but we will not take any responsibility in case of natural calamities/dust/fire/theft.</dt>
                    <dt className='col-sm-12 erp-invoice-print-label-md-lg'>13. We are not responsible for originality/Authenticity of software contents and the hard disks.</dt>
                    <dt className='col-sm-12 erp-invoice-print-label-md-lg'>14. We got take any responsibility for data of machines received for repairs; customers are requested to preserve the backup.</dt>
                    <dt className='col-sm-12 erp-invoice-print-label-md-lg'>15. Customers are requested to check their jobs repaired for complete functionality before leaving the counter</dt>
                    <dt className='col-sm-12 erp-invoice-print-label-md-lg'> {invoiceContent.accessoriesincludedDetails.termscondition}</dt>

                </div>
            </div>
        </>
    )

    return (
        <>



            <div className="row">
                <div className="col-12">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">

                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    {invoiceHeader}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style>
                {`.erp-invoice-print-label-md-lg,
                      .erp-invoice-print-label
                       { line-height: 18px; } `}
            </style>
        </>
    );
})


export default TServiceJobReciptprint;

