import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $ from 'jquery';
// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { RxCrossCircled } from "react-icons/rx";
import { Button, Modal } from "react-bootstrap";
import { resetGlobalQuery, globalQuery } from "assets/Constants/config-constant";
import { MdRefresh } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";
import { FiPrinter } from "react-icons/fi";


// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import FrmValidations from "FrmGeneric/FrmValidations";
import ConfigConstants from "assets/Constants/config-constant";
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import { CircularProgress } from "@material-ui/core";
import FrmCustomerEntryAerotech from "Masters/MCCustomer/FrmCustomerEntryAerotech";
import TServiceJobReciptprint from "FrmGeneric/Invoice/SeviceJobReceipt";
import { renderToString } from "react-dom/server";
import FrmTaskEntry from "Transactions/TTaskManagement/FrmTaskEntry";

import Select from 'react-select';
import FrmCustomerEntry from "Masters/MCustomer/FrmCustomerEntry/components/FrmMCustomerEntry";


function TServiceJobReciptEntry(props) {

    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const validate = useRef();
    const validateNumberDateInput = useRef();
    const navigator = useNavigate();

    //loading
    const [isLoading, setIsLoading] = useState(false);

    const [txt_service_job_receipt_transaction_id, setservice_job_receipt_transaction_id] = useState(0);

    const [cmb_company_id, setcompany_id] = useState('');

    const [txt_service_job_receipt_code, setservice_job_receipt_code] = useState('');
    const [txt_name, setName] = useState('');
    const [txt_Address, setAddress] = useState('');
    const [dt_date, setDate] = useState(today);
    const [txt_email_id, setEmail] = useState('');
    const [txt_equipment_model_no, setequipment_model_no] = useState('');
    const [txt_equipment_make, setequipment_make] = useState('');
    const [txt_equipment_serial_no, setequipment_serial_no] = useState('');
    const [txt_equipment_issue_desc, setequipment_issue_desc] = useState('');
    // const [cmb_activity_id, setActivityId] = useState('');

    const [cmb_assign_Id, setAssignToId] = useState('');
    const [cmb_assign_employee, setAssignToempoyee] = useState('');


    const [cmb_priority_level, setpriority_level] = useState('Regular');
    const [cmb_equipment_type, setequipment_type] = useState('');
    const [cmb_Task_status, setTask_status] = useState('Pending');
    const [cmb_reference, setreference] = useState('');
    const [txt_phone_no, setPhoneNo] = useState('');
    const [txt_accessories_desc, setaccessories_desc] = useState('');
    const [txt_terms_condition, setterms_condition] = useState('');
    const [actionType, setActionType] = useState('');
    const [actionLabel, setActionLabel] = useState('Save');
    const [companyOptions, setcompanyOptions] = useState([]);
    const [customerOptions, setcustomerOptions] = useState([]);
    const [Equipment_option, setequipment_option] = useState([]);
    const [Reference_option, setReference_option] = useState([]);
    const [cmb_vendor, setVendor] = useState('');
    const[txt_outdoorName ,setOutdoorName]=useState('');
    const [customerAddressOption, setcustomerAddressOption] = useState([])


    const [custContactOptions, setCustContactOptions] = useState([])

    const [activityOpts, setActivityOpts] = useState([]);

    const [assignToEmplsOpts, setAssignToEmplsOpts] = useState([]);
    const [custom1Value, setCustom1Value] = useState('');
    const [custom2Value, setCustom2Value] = useState('');
    const [custom3Value, setCustom3Value] = useState('');
    const [custom1Checked, setCustom1Checked] = useState(false);
    const [custom2Checked, setCustom2Checked] = useState(false);
    const [custom3Checked, setCustom3Checked] = useState(false);

    // UseRefs
    const combobox = useRef();
    const navigate = useNavigate()


    let customerComboRef = useRef(null);
    const [cmb_customer, setCustomerId] = useState('');

    const [customerDetailConatcts, setcustomerDetailContacts] = useState([]);



    const { state } = useLocation();

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME, UserId } = configConstants;
    const { idList, keyForViewUpdate, compType = 'Masters' } = state || {}

    const [dt_task_start_time, setTaskStartTime] = useState(() => {
        if (keyForViewUpdate === '') {
            const currentDate = new Date();
            currentDate.setMinutes((currentDate.getMinutes() + 0) - currentDate.getTimezoneOffset());
            return currentDate.toISOString().slice(0, 16);
        } else {
            return '';
        }
    });

    const [dt_task_end_time, setTaskEndTime] = useState(() => {
        if (keyForViewUpdate === '') {
            const currentDate = new Date();
            const endDate = new Date(currentDate.getTime() + 6 * 60 * 60 * 1000); // Add 6 hours
            endDate.setMinutes((endDate.getMinutes() + 0) - endDate.getTimezoneOffset());
            return endDate.toISOString().slice(0, 16);
        } else {
            return '';
        }
    });

    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    //Delete Popup modal.
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const FnCloseDeleteModal = () => setShowDeleteModal(false);

    //MODEL
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Transactions/TServiceJobRecipt/TServiceJobReciptListing`)
        }
        // moveToListing();
    }

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    useEffect(async () => {
        setIsLoading(true);
        await fillComobos();
        if (idList !== 0) {
            await FnCheckUpdateResponce();
        }

        await ActionType();
        setIsLoading(false);
    }, [])


    const fillComobos = async () => {
        try {
            debugger
            resetGlobalQuery();
            globalQuery.columns.push("property_name");
            globalQuery.columns.push("property_value");
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: "EquipementType" });
            globalQuery.table = "amv_properties";
            const apiCall = await combobox.current.fillFiltersCombo(globalQuery);
            setequipment_option(apiCall)

            resetGlobalQuery();
            globalQuery.columns.push("property_name");
            globalQuery.columns.push("property_value");
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: "Refrencetype" });
            globalQuery.table = "amv_properties";
            const refrenceapiCall = await combobox.current.fillFiltersCombo(globalQuery);
            setReference_option(refrenceapiCall)


            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "company_short_name", "company_branch_id"];
            globalQuery.table = "cmv_company"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            combobox.current.removeCatcheFillCombo(globalQuery).then((getcompanyTypes) => {
                setcompanyOptions(getcompanyTypes);
            })

            // combobox.current.fillMasterData("cmv_customer", "", "")
            //     .then(getCustomers => {
            //         //  ** Changes for the Searchable Combo
            //         const options = [
            //             { value: '', label: 'Select', },
            //             { value: '0', label: 'Add New Record+' },
            //             ...getCustomers.map((customer) => ({ ...customer, value: customer.field_id, customrvalue: customer.field_name, label: customer.field_name })),
            //         ];
            //         setcustomerOptions(options);
            //         console.log("Customers List: ", getCustomers);
            //     });
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name"];
            globalQuery.table = "cmv_customer"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            combobox.current.removeCatcheFillCombo(globalQuery).then((getCustomerTypes) => {

                // const comboboxdata = await combobox.current.fillMasterData("cmv_customer", "", "");
                const options = [
                    { value: '', label: 'Select' },
                    { value: '0', label: 'Add New Record+' },
                    ...getCustomerTypes.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
                ];
                setcustomerOptions(options);
            });

            // Load the Employees options.
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name']
            globalQuery.table = "cmv_employee_list"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            combobox.current.fillFiltersCombo(globalQuery)
                .then(employeeList => {
                    setAssignToEmplsOpts(employeeList);
                });

            //for contacts
            resetGlobalQuery();
            globalQuery.columns.push("customer_id");
            globalQuery.columns.push("cust_contact_no");
            globalQuery.columns.push("cust_email_id");
            globalQuery.table = "cmv_customer_contacts"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            const customerConatctsApiCall = await combobox.current.fillFiltersCombo(globalQuery);
            setcustomerDetailContacts(customerConatctsApiCall);
            //  ** Changes for the Searchable Combo
            const contactsByOpts = [
                { value: '', label: 'Select', },
                ...customerConatctsApiCall.map((Contact) => ({ ...Contact, value: Contact.cust_contact_no, label: Contact.cust_contact_no })),
            ];
            setCustContactOptions(contactsByOpts);


            resetGlobalQuery();
            globalQuery.columns.push("cust_branch_address1");
            globalQuery.columns.push("customer_id");
            globalQuery.table = "cmv_customer"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getCustomerAddressApiCall = await combobox.current.fillFiltersCombo(globalQuery);
            setcustomerAddressOption(getCustomerAddressApiCall);


            // Load the Activities.
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'activity_description', 'std_hour',]
            globalQuery.table = "smv_product_service_activity_master"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            combobox.current.fillFiltersCombo(globalQuery)
                .then(activityList => { setActivityOpts(activityList); });

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const validateServiceJobReceipt = async () => {

        if (cmb_customer === '' || cmb_customer === '0') {
            $("#error_cmb_customer").text("Please fill this field...!");
            $("#error_cmb_customer").show();
            $("#cmb_customer").focus();
            return false;
        }

        const serviceJobReceipt = await validate.current.validateForm("salesJobReceipt");
        if (!serviceJobReceipt) {
            return false
        }
        return serviceJobReceipt;

    }



    const addSalesJobReceipt = async () => {
        debugger

        try {
            const checkIsValidate = await validateServiceJobReceipt();
            let companyBranchID = $('#cmb_company_id option:selected').attr('branchId');

            if (checkIsValidate) {
                setIsLoading(true);

                const checkboxIds = ["Adapter", "HDD", "Battery", "Memory", "Drive", "CarryCase", "Custom1Checkbox", "Custom2Checkbox", "Custom3Checkbox"];
                let concatenatedValues = "";

                checkboxIds.forEach(id => {
                    const checkbox = document.getElementById(id);
                    if (checkbox.checked) {
                        // For Custom checkboxes, get their input field values
                        if (id.startsWith("Custom")) {
                            const inputField = document.querySelector(`input[name='${id}']`);
                            if (inputField && inputField.value.trim() !== "") {
                                concatenatedValues += inputField.value.trim() + ",";
                            }
                        } else {
                            concatenatedValues += id + ",";
                        }
                    }
                });

                // Remove the trailing comma
                concatenatedValues = concatenatedValues.slice(0, -1);
                console.log('concatenatedValues', concatenatedValues);
                let customerNameVal = customerComboRef.current.value || '';

                const data = {
                    // company_branch_id: COMPANY_BRANCH_ID,
                    // company_id: COMPANY_ID,
                    modified_by: txt_service_job_receipt_transaction_id === 0 ? null : UserName,
                    service_job_receipt_transaction_id: txt_service_job_receipt_transaction_id,
                    service_job_receipt_code: txt_service_job_receipt_code,
                    service_job_receipt_date: dt_date,
                    company_id: cmb_company_id,
                    company_branch_id: companyBranchID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    customer_id: customerNameVal,
                    customer_mobile_no: txt_phone_no,
                    customer_email_id: txt_email_id,
                    customer_address: txt_Address,
                    vendor: cmb_vendor,
                    outdoor_name:txt_outdoorName,
                    equipment_type: cmb_equipment_type,
                    equipment_make: txt_equipment_make,
                    equipment_model_no: txt_equipment_model_no,
                    equipment_serial_no: txt_equipment_serial_no,
                    equipment_issue_desc: txt_equipment_issue_desc,
                    equipment_accessories: concatenatedValues,
                    accessories_desc: txt_accessories_desc,
                    assigned_to_id: cmb_assign_Id,
                    priority_level: cmb_priority_level,
                    job_receipt_status: cmb_Task_status,
                    terms_conditions: txt_terms_condition,
                    reference: cmb_reference,
                    created_by: UserName,
                };

                console.log(data);
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtServiceJobReceipts/FnAddUpdateRecord`, requestOptions)
                const responce = await apicall.json()
                console.log("response error: ", responce.data);
                let companyBranchId = $('#cmb_company_id option:selected').attr('branchId');

                if (responce.success === 1 && keyForViewUpdate === '') {
                    let JSONForSave = { 'TransHeaderData': {}, 'commonIds': {}, }

                    // // JSON Which Fields Common in all tables.
                    let commonFieldsInTransaction = {
                        task_transaction_id: 0,
                        task_date: dt_date,
                        task_status: "P",
                        company_id: cmb_company_id,
                        company_branch_id: companyBranchId,
                        // company_id: parseInt(COMPANY_ID),
                        // company_branch_id: parseInt(COMPANY_BRANCH_ID),
                        financial_year: FINANCIAL_SHORT_NAME,
                        created_by: UserName
                    };

                    // Generate JSON For Header Data.
                    JSONForSave.TransHeaderData = {
                        ...commonFieldsInTransaction,               // Common Fields. 
                        task_assign_type: 'Assignlater',
                        customer_id: customerNameVal,
                        assigned_to_id: null,
                        task_management_code: "",
                        assigned_by_id: UserId,
                        activity_type: "Repair job service",
                        // activity_id: "2",
                        activity_duration: null,
                        task_start_time: null,
                        task_end_time: null,
                        service_job_receipt_code: txt_service_job_receipt_code,
                    }

                    // Common Ids;
                    JSONForSave.commonIds.task_transaction_id = 0;
                    // JSONForSave.commonIds.company_id = COMPANY_ID;
                    // JSONForSave.commonIds.company_branch_id = COMPANY_BRANCH_ID
                    JSONForSave.commonIds.company_id = cmb_company_id;
                    JSONForSave.commonIds.company_branch_id = companyBranchId

                    JSONForSave.commonIds.financial_year = FINANCIAL_SHORT_NAME

                    console.log(`JSON Sending For Saving Task Management: `, JSONForSave);
                    const formData = new FormData();
                    formData.append(`TaskTransactionData`, JSON.stringify(JSONForSave))
                    const requestBody = { method: 'POST', body: formData };
                    const apiCallForSave = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtTask/FnAddUpdateRecord/${false}`, requestBody)
                    const response = await apiCallForSave.json();
                    console.log('responceAfterSave: ', response);
                }


                if (responce.error !== "") {
                    console.log("response error: ", responce.error);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {
                    console.log("service_job_receipt_transaction_id", responce.data);
                    const evitCache = combobox.current.evitCache();
                    console.log(evitCache);
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                   
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }
    }

    {/* Function for fetch details for view or edit */ }
    const setCheckboxStateFromAccessories = (accessoriesString, setStateFunctions) => {
        const [setHddChecked, setBatteryChecked, setCaseChecked, setMemoryChecked, setDriveChecked, setAdapterChecked] = setStateFunctions;
        const accessoriesArray = accessoriesString.split(',');
        setAdapterChecked(accessoriesArray.includes('Adapter'));
        setHddChecked(accessoriesArray.includes('HDD'));
        setBatteryChecked(accessoriesArray.includes('Battery'));
        setMemoryChecked(accessoriesArray.includes('Memory'));
        setDriveChecked(accessoriesArray.includes('Drive'));
        setCaseChecked(accessoriesArray.includes('CarryCase'));
    };


    const FnCheckUpdateResponce = async () => {
        debugger
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtServiceJobReceipts/FnShowParticularRecordForUpdate/${idList}/${COMPANY_ID}`)
            const updateRes = await apiCall.json();
            const data = updateRes.ServiceJobReceiptsModelRecord
            if (data !== null && data !== "") {
                setcompany_id(data.company_id)
                setservice_job_receipt_transaction_id(data.service_job_receipt_transaction_id);
                setservice_job_receipt_code(data.service_job_receipt_code)
                setCustomerId(data.customer_id)
                customerComboRef.current.value = data.customer_id
                customerComboRef.current.label = data.customer_name
                setAddress(data.customer_address)
                setDate(data.service_job_receipt_date)
                setEmail(data.customer_email_id)
                setVendor(data.vendor)
                setOutdoorName(data.outdoor_name)
                setequipment_model_no(data.equipment_model_no)
                setequipment_make(data.equipment_make)
                setequipment_serial_no(data.equipment_serial_no)
                setequipment_issue_desc(data.equipment_issue_desc)
                setequipment_type(data.equipment_type)
                setPhoneNo(data.customer_mobile_no)
                // setAssignToId(data.assigned_to_id)
                setpriority_level(data.priority_level)
                setTask_status(data.job_receipt_status)
                setterms_condition(data.terms_conditions)
                setreference(data.reference)


                setaccessories_desc(data.accessories_desc)

                const responseValue = data.equipment_accessories;
                const responseArray = responseValue.split(',');

                responseArray.forEach(value => {
                    // Find the standard checkbox element corresponding to the value
                    const checkbox = document.querySelector(`input[type="checkbox"][value="${value}"]`);

                    // If the standard checkbox exists, check it
                    if (checkbox) {
                        checkbox.checked = true;
                    } else {
                        // Otherwise, find the available custom input and checkbox elements
                        const customInputElements = [
                            { checkboxId: 'Custom1Checkbox', inputId: 'Custom1Input', setValue: setCustom1Value, setChecked: setCustom1Checked },
                            { checkboxId: 'Custom2Checkbox', inputId: 'Custom2Input', setValue: setCustom2Value, setChecked: setCustom2Checked },
                            { checkboxId: 'Custom3Checkbox', inputId: 'Custom3Input', setValue: setCustom3Value, setChecked: setCustom3Checked }
                        ];

                        // Loop through custom elements to find an available one
                        customInputElements.some(({ checkboxId, inputId, setValue, setChecked }) => {
                            const customCheckbox = document.querySelector(`#${checkboxId}`);
                            const customInput = document.querySelector(`#${inputId}`);

                            // If both custom checkbox and input exist, set the value and check the checkbox
                            if (customCheckbox && customInput && customInput.value === '') {
                                setValue(value);
                                setChecked(true);
                                return true; // Stop iteration
                            }
                            return false; // Continue iteration
                        });
                    }
                });
            }
            debugger
            if (keyForViewUpdate !== '') {
                resetGlobalQuery();
                globalQuery.columns.push("assigned_to_name");
                globalQuery.columns.push("assigned_to_id");
                globalQuery.table = "mtv_task"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "service_job_receipt_code", operator: "=", value: data.service_job_receipt_code });
                const assigned_to_nameCall = await combobox.current.fillFiltersCombo(globalQuery);
                if (assigned_to_nameCall.length !== 0) {
                    setAssignToId(assigned_to_nameCall[0].assigned_to_id);
                    setAssignToempoyee(assigned_to_nameCall[0].assigned_to_name);
                }
            }


        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                $("input[type=checkbox]").attr('disabled', false);
                $('#btn_save').attr('disabled', false);
                $('#btn_upload_doc').attr('disabled', false)
                break;
            case 'view':
                setActionType('(View)');
                $("input[type=checkbox]").attr('disabled', true);
                $('#btn_upload_doc').attr('disabled', true)
                // await validate.current.readOnly("salesJobReceipt");
                break;

            case 'delete':
                setActionType('(Delete)');
                $("input[type=checkbox]").attr('disabled', true);
                $('#btn_upload_doc').attr('disabled', true)
                await validate.current.readOnly("salesJobReceipt");
                break;
            default:
                setActionType('(Creation)');
                break;
        }
    }

    const validateFields = () => {
        var formObj = $('#salesJobReceipt');
        var inputObj;

        for (var i = 0; i <= formObj.get(0).length - 1; i++) {
            inputObj = formObj.get(0)[i];
            if (inputObj.type === 'text' && inputObj.value !== '' && !$('#' + inputObj.id).attr('optional')) {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'select-one' && inputObj.value !== '' && !$('#' + inputObj.id).attr('optional')) {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'textarea' && inputObj.value !== '' && !$('#' + inputObj.id).attr('optional')) {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === 'date' && inputObj.value !== '' && !$('#' + inputObj.id).attr('optional')) {
                $("#error_" + inputObj.id).hide();
            }
        }



    }

    const validateNo = (noKey) => {
        const telephoneRegex = /^[0-9+\(\)#\.\s\/ext-]+$/;
        const value = noKey.target.value;
        switch (noKey.target.id) {

            case 'txt_phone_no':
                if (telephoneRegex.test(value) || value === '') {
                    setPhoneNo(value)
                }
                break;
        }
    }


    const validateEmail = (event) => {
        if (event.target.type === 'email' && !validateNumberDateInput.current.validateEmail(event.target.value)) {
            $(`#error_${event.target.id}`).text('Please enter valid email!...')
            $(`#error_${event.target.id}`).show()
        } else {
            $(`#error_${event.target.id}`).hide()
        }
    }

    const FnShowDeleteModal = () => { setShowDeleteModal(true); }
    const DeleteRecord = async () => {
        try {
            setIsLoading(true);
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtServiceJobReceipts/FnDeleteRecord/${idList}/${COMPANY_ID}/${UserName}`, method)
            const responce = await deleteApiCall.json();
            console.log("Service Planning Deleted: ", responce);
            if (responce.success == 1) {
                setShowDeleteModal(false)
                moveToListing();
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }


    const moveToListing = () => {
        debugger
        const Path = compType === 'Register' ? '/Transactions/TServiceJobRecipt/TServiceJobReciptListing/reg' : `/Transactions/TServiceJobRecipt/TServiceJobReciptListing`;
        navigator(Path);
    }



    const toggleCheckbox = (checkboxId, inputValue) => {
        switch (checkboxId) {
            case 'Custom1Checkbox':
                setCustom1Checked(inputValue.trim());
                break;
            case 'Custom2Checkbox':
                setCustom2Checked(inputValue.trim());
                break;
            case 'Custom3Checkbox':
                setCustom3Checked(inputValue.trim());
                break;
            default:
                break;
        }
    };

    const getCurrentDateTime = () => {
        const currentDate = new Date();
        currentDate.setMinutes(currentDate.getMinutes() - currentDate.getTimezoneOffset());
        return currentDate.toISOString().slice(0, 16);
    };

    const getCurrentEndDateTime = () => {
        const currentDate = new Date();
        const endDate = new Date(currentDate.getTime() + 6 * 60 * 60 * 1000); // Add 6 hours
        endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset());
        return endDate.toISOString().slice(0, 16);
    };


    const comboOnChange = async (key) => {
        debugger
        setIsLoading(true);

        switch (key) {

            case 'customersearch':
                let customerVal = customerComboRef.current.value || '';
                if (customerVal === '0') {
                    if (cmb_company_id !== "3") {
                        const newTab = window.open('/Masters/Customer', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                    } else {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('customer')
                        setShowAddRecModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").eq(1).css("padding-top", "0px");
                        }, 200)
                    }
                } else if (customerVal !== "" && customerVal !== '0') {
                    resetGlobalQuery();
                    globalQuery.columns.push("cust_contact_no");
                    globalQuery.columns.push("cust_email_id");
                    globalQuery.table = "cmv_customer_contacts"
                    // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customerVal });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

                    const getCustomercontactApiCall = await combobox.current.fillFiltersCombo(globalQuery);
                    console.log("getCustomercontactApiCall", getCustomercontactApiCall);
                    if (getCustomercontactApiCall.length !== 0) {
                        setPhoneNo(getCustomercontactApiCall[0].cust_contact_no)
                        setEmail(getCustomercontactApiCall[0].cust_email_id)
                    } else {
                        setPhoneNo('')
                        setEmail('')
                    }

                    resetGlobalQuery();
                    globalQuery.columns.push("cust_branch_address1");
                    globalQuery.table = "cmv_customer"
                    // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customerVal });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const getCustomerApiCall = await combobox.current.fillFiltersCombo(globalQuery);

                    if (getCustomerApiCall.length !== 0) {
                        setAddress(getCustomerApiCall[0].cust_branch_address1)
                    } else {
                        setAddress('')
                    }
                }
                break;


            case 'company':
                debugger
                var companyType = document.getElementById('cmb_company_id').value;
                let companyTypeShortName = $('#cmb_company_id option:selected').attr('companyShortName');

                if (companyType !== '0') {
                    await generateSalesJobReceiptCode('mt_service_job_receipts', 'service_job_receipt_code', 5, companyTypeShortName, 'C');

                }
                if (companyType === '0') {
                    localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    const newTab = window.open('/Masters/Company', '_blank');
                    if (newTab) { newTab.focus(); }
                    setcompany_id('');
                }
                setTaskStartTime(getCurrentDateTime());
                setTaskEndTime(getCurrentEndDateTime());

                break;

            case 'AssignTo':
                debugger
                let assignedEmpl = $('#cmb_assign_employee').val();
                if (assignedEmpl === '') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')

                    resetGlobalQuery();
                    globalQuery.columns = ['task_transaction_id'];
                    globalQuery.table = "mtv_task";
                    globalQuery.conditions.push({ field: "service_job_receipt_code", operator: "=", value: txt_service_job_receipt_code });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

                    const servicejobreceiptcodeList = await combobox.current.fillFiltersCombo(globalQuery);
                    let taskid = servicejobreceiptcodeList[0].task_transaction_id;
                    console.log('taskid =>', taskid);

                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    localStorage.setItem('taskmanagement', JSON.stringify({
                        idList: {
                            task_transaction_id: taskid,
                        },
                        keyForViewUpdate: 'update',
                        compType: 'Transaction'
                    }))


                    setHeaderName('taskManagement')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");
                    }, 200)
                }
                break;



            case 'customerContact':
                var customerContact = document.getElementById('txt_phone_no').value;
                if (customerContact !== "" && customerContact !== '0') {
                    const contact = customerDetailConatcts.find(contact => contact.cust_contact_no === customerContact);
                    if (contact) {
                        setCustomerId(contact.customer_id)
                        setEmail(contact.cust_email_id)

                        if (contact.customer_id !== '') {
                            const address = customerAddressOption.find(address => address.customer_id === contact.customer_id);
                            setAddress(address.cust_branch_address1)
                        }

                    }
                }
                break;



            case 'customerEmail':

                var customerEmail = document.getElementById('email').value;
                if (customerEmail !== "" && customerEmail !== '0') {
                    const contact = customerDetailConatcts.find(contact => contact.cust_email_id === customerEmail);
                    if (contact) {
                        setCustomerId(contact.customer_id)
                        setPhoneNo(contact.cust_contact_no)
                        if (contact.customer_id !== '') {
                            const address = customerAddressOption.find(address => address.customer_id === contact.customer_id);
                            setAddress(address.cust_branch_address1)
                        }
                    }
                }
                break;


            default:
                break;


        }
        setIsLoading(false);
    }

    const generateSalesJobReceiptCode = async (tblName, fieldName, Length, companyTypeShortName, type) => {
        debugger
        var companyID = document.getElementById('cmb_company_id').value;
        const data = {
            tblName: tblName, fieldName: fieldName, Length: Length, company_id: companyID,
            additionalParam1: 'company_id',
            additionalParam1Value: ''
        };
        const formData = new FormData();
        formData.append(`data`, JSON.stringify(data))
        const requestOptions = { method: 'POST', body: formData };
        try {
            const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/customer/FnGenerateCode/${companyID}`, requestOptions)
            const resp = await fetchResponse.text();
            console.log("GenerateTAuto Api: ", resp)
            if (resp) {
                setservice_job_receipt_code(companyTypeShortName + resp);
                return companyTypeShortName + resp;
            }
        } catch (error) {
            console.error(error);
        }
    }


    const FnRefreshComboData = async (comboName) => {
        if (keyForViewUpdate === 'view' || keyForViewUpdate === 'approve') {
            return false;
        }

        try {
            switch (comboName) {

                case 'AssignedTo':
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name']
                    globalQuery.table = "cmv_employee_list"
                    // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const getUpdatedEmplsData = await combobox.current.removeCatcheFillCombo(globalQuery);
                    setAssignToEmplsOpts(getUpdatedEmplsData);
                    break;

                case 'Company':
                    resetGlobalQuery();
                    globalQuery.columns = ["field_id", "field_name", "company_short_name", "company_branch_id"];
                    globalQuery.table = "cmv_company"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
                    // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    const getcompanyTypes = await combobox.current.removeCatcheFillCombo(globalQuery);
                    setcompanyOptions(getcompanyTypes);

                    break;

                default:
                    break;
            }
        } catch (error) {
            console.log("error: ", error)
            navigator('/Error')
        }
    }


    const displayRecordComponent = () => {
        debugger
        switch (modalHeaderName) {
            case 'customer':
                // if (cmb_company_id === '1') {
                localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master', gstOptional: cmb_company_id !== '1' ? cmb_company_id : null }))
                return < FrmCustomerEntryAerotech btn_disabled />;
            // } else {
            //     localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master', gstOptional: cmb_company_id !== '1' ? cmb_company_id : null }))
            //     return < FrmCustomerEntry btn_disabled />;
            // }

            case 'taskManagement':
                return < FrmTaskEntry btn_disabled />;
            default:
                return null;
        }
    }


    // Show ADd record Modal
    const handleCloseRecModal = async () => {
        switch (modalHeaderName) {
            case 'customer':
                resetGlobalQuery();
                globalQuery.columns = ["field_id", "field_name"];
                globalQuery.table = "cmv_customer"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
                // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                combobox.current.removeCatcheFillCombo(globalQuery).then((getCustomerTypes) => {

                    // const comboboxdata = await combobox.current.fillMasterData("cmv_customer", "", "");
                    const options = [
                        { value: '', label: 'Select' },
                        { value: '0', label: 'Add New Record+' },
                        ...getCustomerTypes.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
                    ];
                    setcustomerOptions(options);
                });
                break;

            case 'taskManagement':
                resetGlobalQuery();
                globalQuery.columns.push("assigned_to_name");
                globalQuery.columns.push("assigned_to_id");
                globalQuery.table = "mtv_task"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "service_job_receipt_code", operator: "=", value: txt_service_job_receipt_code });
                const assigned_to_nameCall = await combobox.current.fillFiltersCombo(globalQuery);
                if (assigned_to_nameCall.length !== 0) {
                    setAssignToId(assigned_to_nameCall[0].assigned_to_id);
                    setAssignToempoyee(assigned_to_nameCall[0].assigned_to_name);
                }
                break;



            default:
                break;
        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)

    }

    // ============================================================================================================================
    const jobrecieptInvoiceContent = {
        company: {
            company: '',
            company_branch: '',
        },
        customerDetails: {
            name: '',
            address: '',
            state: '',
            contact: '',
            City: '',
            Email: '',
            prioritylevel: '',
            customer_mobile_no: '',
            servicejobreceiptcode: '',
            jobreceiptstatus: '',
            servicejobreceiptdate: '',

        },
        equipmentDetails: {
            equipmenttype: '',
            equipmentmake: '',
            equipmentmodelno: '',
            equipmentserialno: '',
            equipmentissuedesc: '',
        },
        accessoriesincludedDetails: {
            accessoriesdesc: '',
            termscondition: '',
            equipmentaccessories: '',
        },

        companyDetails: {
            company: '',
            company_branch: '',
            address: '',
            phone: '',
            cell: '',
            email: '',
            website: '',
            GSTIN: '',
            pan_no: '',
            state_name: '',
            company_pincode: '',
        },


    };


    const printInvoice = async (openPrintModal) => {
        debugger

        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtServiceJobReceipts/FnShowParticularRecordForUpdate/${idList}/${COMPANY_ID}`)
        const response = await apiCall.json();
        console.log("Response Service Job receipt Invoice : ", response);

        const data = response.ServiceJobReceiptsModelRecord

        //Company details
        jobrecieptInvoiceContent.company.company = data.company_name
        jobrecieptInvoiceContent.company.company_branch = data.company_branch_name




        // //customer details
        jobrecieptInvoiceContent.customerDetails.name = data.customer_name
        jobrecieptInvoiceContent.customerDetails.servicejobreceiptdate = validateNumberDateInput.current.formatDateToDDMMYYYY(data.service_job_receipt_date)
        jobrecieptInvoiceContent.customerDetails.address = data.customer_address
        jobrecieptInvoiceContent.customerDetails.Email = data.customer_email_id
        jobrecieptInvoiceContent.customerDetails.servicejobreceiptcode = data.service_job_receipt_code
        jobrecieptInvoiceContent.customerDetails.customer_mobile_no = data.customer_mobile_no
        jobrecieptInvoiceContent.customerDetails.prioritylevel = data.priority_level
        jobrecieptInvoiceContent.customerDetails.jobreceiptstatus = data.job_receipt_status
        jobrecieptInvoiceContent.equipmentDetails.equipmenttype = data.equipment_type
        jobrecieptInvoiceContent.equipmentDetails.equipmentmake = data.equipment_make
        jobrecieptInvoiceContent.equipmentDetails.equipmentmodelno = data.equipment_model_no
        jobrecieptInvoiceContent.equipmentDetails.equipmentserialno = data.equipment_serial_no
        jobrecieptInvoiceContent.equipmentDetails.equipmentissuedesc = data.equipment_issue_desc

        // //equipmentaccessories detail
        jobrecieptInvoiceContent.accessoriesincludedDetails.accessoriesdesc = data.accessories_desc
        jobrecieptInvoiceContent.accessoriesincludedDetails.termscondition = data.terms_conditions
        jobrecieptInvoiceContent.accessoriesincludedDetails.equipmentaccessories = data.equipment_accessories

        jobrecieptInvoiceContent.companyDetails.company = data.company_name
        jobrecieptInvoiceContent.companyDetails.address = data.company_address1
        jobrecieptInvoiceContent.companyDetails.phone = data.company_phone_no
        jobrecieptInvoiceContent.companyDetails.cell = data.company_cell_no
        jobrecieptInvoiceContent.companyDetails.email = data.company_EmailId
        jobrecieptInvoiceContent.companyDetails.website = data.company_website
        jobrecieptInvoiceContent.companyDetails.GSTIN = data.company_gst_no
        jobrecieptInvoiceContent.companyDetails.pan_no = data.company_pan_no
        jobrecieptInvoiceContent.companyDetails.state_name = data.company_state
        jobrecieptInvoiceContent.companyDetails.company_pincode = data.company_pincode


        if (openPrintModal) {
            navigator('/Invoice', { state: { invoiceData: jobrecieptInvoiceContent, navigationLink: "/Transactions/TServiceJobRecipt/TServiceJobReciptEntry", invoiceType: 'SJR', title: 'Service Job Receipt', idList: idList, keyForViewUpdate: keyForViewUpdate } });
        } else {
            // return the html code.
            return renderToString(<TServiceJobReciptprint invoiceContent={jobrecieptInvoiceContent} />);
        }

    };

    const refreshData = async (key) => {
        switch (key) {

            case 'get_customer':
                // const combobox = await combobox.current.fillMasterData("cmv_customer", "", "");
                // const options = [
                //     { value: '', label: 'Select' },
                //     { value: '0', label: 'Add New Record+' },
                //     ...combobox.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
                // ];
                // setcustomerOptions(options);
                resetGlobalQuery();
                globalQuery.columns = ["field_id", "field_name"];
                globalQuery.table = "cmv_customer"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
                // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                combobox.current.removeCatcheFillCombo(globalQuery).then((getCustomerTypes) => {

                    // const comboboxdata = await combobox.current.fillMasterData("cmv_customer", "", "");
                    const options = [
                        { value: '', label: 'Select' },
                        { value: '0', label: 'Add New Record+' },
                        ...getCustomerTypes.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name })),
                    ];
                    setcustomerOptions(options);
                });
                break;

            default:
                break;
        }
    }





    return (
        <>
            <ComboBox ref={combobox} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            {isLoading
                ? <div className="spinner-overlay"  >
                    <div className="spinner-container">
                        <CircularProgress color="primary" />
                        <span>Loading...</span>
                    </div>
                </div>
                : ''}

            <div className="erp_top_Form">
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg main_heding'>Service Job Receipt {actionType}</label>
                    </div>
                    <form id="salesJobReceipt">
                        <div className="row erp_transporter_div">
                            {/* First Column */}
                            <div className="col-sm-6 erp_form_col_div">

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label-md">Company <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-md-7 col-10'>
                                        <select id="cmb_company_id" className="form-select form-select-sm" value={cmb_company_id} onChange={(e) => { setcompany_id(e.target.value); comboOnChange('company'); validateFields() }} disabled={keyForViewUpdate === 'view' ? true : false} >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {companyOptions?.map(company => (
                                                <option value={company.field_id} companyShortName={company.company_short_name} branchId={company.company_branch_id} >{company.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_company_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className='col-sm-1 col-2 refreshIconCol'>
                                        <Tooltip title="Refresh" placement="right">
                                            <MdRefresh style={{ color: 'black' }} onClick={e => { FnRefreshComboData('Company'); }} />
                                        </Tooltip>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label-md">Service Job Receipt Code : <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-5 pe-md-0">
                                        <Form.Control type="text" className="erp_input_field" id="txt_service_job_receipt_code" value={txt_service_job_receipt_code} onChange={e => { setservice_job_receipt_code(e.target.value); validateFields(); }} maxLength="500" disabled="disabled" />
                                        {/* <MDTypography variant="button" id="error_txt_service_job_receipt_code" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography> */}
                                    </div>
                                    <div className="col-sm-3 pt-md-0 pt-3 ps-md-1">
                                        <Form.Control type="date" id="dt_date" className="erp_input_field " value={dt_date} onChange={e => { setDate(e.target.value); validateFields() }} disabled={keyForViewUpdate === 'view' ? true : false} />
                                        {/* <MDTypography variant="button" id="error_dt_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography> */}
                                    </div>
                                </div>

                                {/* <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Customer Name : <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="txt_name" className="form-select form-select-sm" value={txt_name} onChange={(e) => { setName(e.target.value); comboOnChange('customer'); validateFields() }} disabled={keyForViewUpdate === 'view' ? true : false}  >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {customerOptions?.map(customer => (
                                                <option value={customer.field_name} valueid={customer.field_id} >{customer.field_name}</option>
                                            ))}
                                        </select>
                                         <MDTypography variant="button" id="error_txt_name" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div> */}

                                <div className="row">
                                    <div className='col-sm-4 col-12'>
                                        <Form.Label className="erp-form-label-md">Customer Name <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-sm-7 col-10 mb-1'>
                                        <Select ref={customerComboRef}
                                            options={customerOptions}
                                            inputId="cmb_customer" // Provide the ID for the input box
                                            value={customerOptions.find(option => option.value === cmb_customer)}

                                            onChange={(selectedOpt) => {
                                                debugger
                                                setCustomerId(selectedOpt.value);
                                                customerComboRef.current = selectedOpt;
                                                comboOnChange('customersearch');
                                                validateFields();
                                            }}
                                            placeholder="Search for a customer..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_customer" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className={`col-sm-1 col-2  `}>
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography className={`${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' ? 'd-none' : 'display'}`} >
                                                <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'view') { refreshData('get_customer') } }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>



                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label-md">Customer Mob No. : <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" className="erp_input_field " id="txt_phone_no" value={txt_phone_no} onChange={e => { validateNo(e); validateFields() }} onBlur={() => { comboOnChange('customerContact'); }} maxLength="10" disabled={keyForViewUpdate === 'view' ? true : false} />
                                        <MDTypography variant="button" id="error_txt_phone_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label-md">Customer Email :</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="email" id="email" className="erp_input_field" value={txt_email_id} onChange={e => { setEmail(e.target.value); validateEmail(e); validateFields() }} maxLength="50" optional="optional" disabled={keyForViewUpdate === 'view' ? true : false} onBlur={() => { comboOnChange('customerEmail'); }} />
                                        <MDTypography variant="button" id="error_email" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                            {/* second column */}
                            <div className="col-sm-6 erp_form_col_div">

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label-md">Customer Address :</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" rows={1} id="txt_Address" className="erp_txt_area optional" value={txt_Address} onChange={e => { setAddress(e.target.value); validateFields() }} maxlength="500" optional="optional" disabled={keyForViewUpdate === 'view' ? true : false} />
                                        <MDTypography variant="button" id="error_txt_Address" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                {keyForViewUpdate !== '' ? (

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label-md">Assign To : <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-12 col-sm-6 pe-0'>
                                            <Form.Control type="text" className="erp_input_field " id="cmb_assign_employee" value={cmb_assign_employee} onChange={(e) => { setAssignToempoyee(e.target.value); validateFields(); comboOnChange('AssignTo'); }} disabled />
                                            <MDTypography variant="button" id="error_cmb_assign_Id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className='col-12 col-md-2 pt-md-0 pt-3 ps-md-1'>
                                            <MDButton type="button" className={`erp-gb-button  ${(keyForViewUpdate === 'view') ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={() => comboOnChange('AssignTo')}> Assign task </MDButton>

                                        </div>
                                    </div>
                                ) : null}
                                <div className="row">
                                    <div className='col-sm-4 col-12'>
                                        <Form.Label className="erp-form-label-md">Priority level : <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-sm-8 col-10'>
                                        <select id="cmb_priority_level" className="form-select form-select-sm" value={cmb_priority_level} onChange={(e) => { setpriority_level(e.target.value); validateFields(); }} disabled={keyForViewUpdate === 'view' ? true : false}>
                                            <option value="Regular">Regular</option>
                                            <option value="Moderate">Moderate</option>
                                            <option value="Urgent">Urgent</option>
                                            <option value="Very Urgent"> Very Urgent</option>
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_priority_level" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-sm-4 col-12'>
                                        <Form.Label className="erp-form-label-md">Job Receipt Status : <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-sm-8 col-10'>
                                        <select id="cmb_Task_status" className="form-select form-select-sm" value={cmb_Task_status} onChange={(e) => { setTask_status(e.target.value); validateFields(); }} disabled={keyForViewUpdate === 'view' ? true : false} >
                                            <option value="">Select</option>
                                            <option value="Pending">Pending</option>
                                            <option value="In Progress">In Progress</option>
                                            <option value="Completed">Completed</option>
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_Task_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label-md">Reference :<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_reference" className="form-select form-select-sm" value={cmb_reference} onChange={(e) => { setreference(e.target.value); validateFields(); }} disabled={keyForViewUpdate === 'view' ? true : false}>
                                            <option value="">Select</option>
                                            {Reference_option?.map(Reference_options => (
                                                <option value={Reference_options.property_name}>{Reference_options.property_value}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_reference" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-sm-4 col-12'>
                                        <Form.Label className="erp-form-label-md">Vendor : </Form.Label>
                                    </div>
                                    <div className='col-sm-8 col-10'>
                                        <select id="cmb_vendor" className="form-select form-select-sm" value={cmb_vendor} onChange={(e) => { setVendor(e.target.value); validateFields(); }} optional="optional" disabled={keyForViewUpdate === 'view' ? true : false} >
                                            <option value="">Select</option>
                                            <option value="indoor">Indoor</option>
                                            <option value="outdoor">Outdoor </option>
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_vendor" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>
                                {cmb_vendor === "outdoor" && (
                         
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label-md">Outdoor Name :  </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" className="erp_input_field" id="txt_outdoorName" value={txt_outdoorName} onChange={e => { setOutdoorName(e.target.value); }} optional="optional" maxLength="500" disabled={keyForViewUpdate === 'view' ? true : false} />
                                            <MDTypography variant="button" id="error_txt_outdoorName" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> 
                                )}
                            </div>
                            <hr />
                            <div className="col-sm-12 erp_form_col_div">
                                <div className='row'>
                                    <div className='erp-form-label-lg'>Details of Equipement : </div>
                                </div>
                            </div>

                            <div className="col-sm-6 erp_form_col_div">

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label-md">Type :<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        {/* <select id="cmb_equipment_type" className="form-select form-select-sm" value={cmb_equipment_type} onChange={e => { setequipment_type(e.target.value); validateFields(); }} disabled={keyForViewUpdate === 'view' ? true : false} > */}
                                        <select id="cmb_equipment_type" className="form-select form-select-sm" value={cmb_equipment_type} onChange={(e) => { setequipment_type(e.target.value); validateFields(); }} optional="optional" disabled={keyForViewUpdate === 'view' ? true : false}>
                                            {/* <option value="0">Add New Record+</option> */}
                                            <option value="">Select</option>
                                            {Equipment_option?.map(equpmentoptions => (
                                                <option value={equpmentoptions.property_name}>{equpmentoptions.property_value}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_equipment_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label-md">Make : <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" className="erp_input_field" id="txt_equipment_make" value={txt_equipment_make} onChange={e => { setequipment_make(e.target.value); validateFields(); }} maxLength="500" disabled={keyForViewUpdate === 'view' ? true : false} />
                                        <MDTypography variant="button" id="error_txt_equipment_make" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label-md">  Model No. : <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" className="erp_input_field" id="txt_equipment_model_no" value={txt_equipment_model_no} onChange={e => { setequipment_model_no(e.target.value); validateFields(); }} maxLength="500" disabled={keyForViewUpdate === 'view' ? true : false} />
                                        <MDTypography variant="button" id="error_txt_equipment_model_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                            </div>

                            <div className="col-sm-6 erp_form_col_div">
                                <div className="row">
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label-md">Serial No. : <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" className="erp_input_field" id="txt_equipment_serial_no" value={txt_equipment_serial_no} onChange={e => { setequipment_serial_no(e.target.value); validateFields(); }} maxLength="500" disabled={keyForViewUpdate === 'view' ? true : false} />
                                        <MDTypography variant="button" id="error_txt_equipment_serial_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label-md">  Issue Reported : <span className="required">*</span>  </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" rows={2} className="erp_txt_area" id="txt_equipment_issue_desc" value={txt_equipment_issue_desc} onChange={e => { setequipment_issue_desc(e.target.value); validateFields(); }} maxLength="500" disabled={keyForViewUpdate === 'view' ? true : false} />
                                        <MDTypography variant="button" id="error_txt_equipment_issue_desc" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                            </div>
                            <hr />

                            <div className="col-sm-12 erp_form_col_div">
                                <div className='row'>
                                    <div className='erp-form-label-lg'>Accessories Included : </div>
                                </div>
                            </div>

                            <div className="col-sm-6 erp_form_col_div">

                                <div class="row">
                                    <div class="col-sm-4 erp_form_radio">
                                        <input type="checkbox" id="Adapter" label="Adapter" value="Adapter" disabled={keyForViewUpdate === 'view' ? true : false} />
                                        <label class="form-check-label ms-1" for="Adapter">Adapter</label>
                                    </div>
                                    <div class="col-sm-4 erp_form_radio">
                                        <input type="checkbox" id="HDD" label="HDD" value="HDD" disabled={keyForViewUpdate === 'view' ? true : false} />
                                        <label class="form-check-label ms-1" for="HDD">HDD</label>
                                    </div>
                                    <div class="col-sm-4 erp_form_radio">
                                        <input type="checkbox" id="Battery" label="Battery" value="Battery" disabled={keyForViewUpdate === 'view' ? true : false} />
                                        <label class="form-check-label ms-1" for="Battery">Battery</label>
                                    </div>
                                    <div class="col-sm-4 erp_form_radio">
                                        <input type="checkbox" id="Memory" label="Memory" value="Memory" disabled={keyForViewUpdate === 'view' ? true : false} />
                                        <label class="form-check-label ms-1" for="Memory">Memory</label>
                                    </div>
                                    <div class="col-sm-4 erp_form_radio">
                                        <input type="checkbox" id="Drive" label="Drive" value="Drive" disabled={keyForViewUpdate === 'view' ? true : false} />
                                        <label class="form-check-label ms-1" for="Drive">Drive</label>
                                    </div>
                                    <div class="col-sm-4 erp_form_radio">
                                        <input type="checkbox" id="CarryCase" label="Carry Case" value="CarryCase" disabled={keyForViewUpdate === 'view' ? true : false} />
                                        <label class="form-check-label ms-1" for="CarryCase">Carry Case</label>
                                    </div>

                                    <div className="col-sm-4 erp_form_radio">
                                        <input type="checkbox" id="Custom1Checkbox" checked={custom1Checked} readOnly />
                                        <input type="text" id="Custom1Input" name='Custom1Checkbox' className="erp_input_field optional ms-1" value={custom1Value} onChange={(e) => {
                                            setCustom1Value(e.target.value);
                                            toggleCheckbox('Custom1Checkbox', e.target.value);
                                        }} optional="optional" disabled={keyForViewUpdate === 'view' ? true : false} />
                                    </div>

                                    <div className="col-sm-4 erp_form_radio">
                                        <input type="checkbox" id="Custom2Checkbox" checked={custom2Checked} readOnly />
                                        <input type="text" id="Custom2Input" name='Custom2Checkbox' className="erp_input_field optional ms-1" value={custom2Value} onChange={(e) => {
                                            setCustom2Value(e.target.value);
                                            toggleCheckbox('Custom2Checkbox', e.target.value);
                                        }} optional="optional" disabled={keyForViewUpdate === 'view' ? true : false} />
                                    </div>
                                    <div className="col-sm-4 erp_form_radio">
                                        <input type="checkbox" id="Custom3Checkbox" checked={custom3Checked} readOnly />
                                        <input type="text" id="Custom3Input" name='Custom3Checkbox' className="erp_input_field optional ms-1" value={custom3Value} onChange={(e) => {
                                            setCustom3Value(e.target.value);
                                            toggleCheckbox('Custom3Checkbox', e.target.value);
                                        }} optional="optional" disabled={keyForViewUpdate === 'view' ? true : false} />
                                    </div>

                                </div>
                            </div>
                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label-md">Accessories Note : </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" rows={2} id="txt_accessories_desc" className="erp_txt_area optional" value={txt_accessories_desc} onChange={e => { setaccessories_desc(e.target.value); validateFields() }} maxlength="500" optional="optional" disabled={keyForViewUpdate === 'view' ? true : false} />
                                        <MDTypography variant="button" id="error_txt_Note" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 erp_form_col_div mt-4">
                                <div className='row'>
                                    <div className="col-sm-2">
                                        <Form.Label className="erp-form-label-md">Terms and Condition's :  </Form.Label>
                                    </div>
                                    <div className="col-sm-10">
                                        <Form.Control as="textarea" rows={5} id="txt_terms_condition" className="erp_txt_area optional" value={txt_terms_condition} onChange={e => { setterms_condition(e.target.value); validateFields() }} maxlength="500" optional="optional" disabled={keyForViewUpdate === 'view' ? true : false} />
                                        <MDTypography variant="button" id="error_txt_Note" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                    <hr />

                    <div className="text-center  mb-5  ">
                        <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()} >Back</MDButton>
                        <MDButton type="submit" onClick={addSalesJobReceipt} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular">{actionLabel}</MDButton>
                        {keyForViewUpdate === 'delete' && (
                            <MDButton
                                type="button"
                                className="erp-gb-button erp_MLeft_btn"
                                variant="button"
                                fontWeight="regular"
                                onClick={() => FnShowDeleteModal()}
                            >
                                Delete
                            </MDButton>
                        )}

                        <MDButton className={`erp-gb-button erp_MLeft_btn ${(keyForViewUpdate === 'view' || keyForViewUpdate === 'update') ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => printInvoice(true)} >  Print &nbsp;<FiPrinter className="erp-download-icon-btn" /> </MDButton>
                    </div >
                </div >

                {/* Success Msg Popup */}
                < SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()
                } show={[showSuccessMsgModal, succMsg]} />

                <Modal show={showDeleteModal} onHide={FnCloseDeleteModal} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseDeleteModal}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        {isLoading
                            ? <div className="spinner-overlay"  >
                                <div className="spinner-container">
                                    <CircularProgress color="primary" />
                                    <span>Loading...</span>
                                </div>
                            </div>
                            : ''}

                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to delete this record ?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={FnCloseDeleteModal}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={DeleteRecord}>Delete</Button>
                    </Modal.Footer>
                </Modal>

                {/* Add new Record Popup */}
                {/* <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        {displayRecordComponent()}
                    </Modal.Body>
                    <Modal.Footer>
                        <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
                            fontWeight="regular">Close</MDButton>

                    </Modal.Footer>
                </Modal > */}


                {/* Add new Record Popup */}
                {showAddRecModal ?
                    <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                        <Modal.Body className='erp_city_modal_body'>
                            <div className='row'>
                                <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
                            </div>
                            {displayRecordComponent()}
                        </Modal.Body>
                    </Modal > : null}


                {/* Error Msg Popup */}
                < ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
            </div >
        </>
    )
}

export default TServiceJobReciptEntry
