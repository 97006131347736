
import React from 'react'
import { useState, useEffect, useRef } from "react";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


// import react icons
import { useNavigate, useLocation } from "react-router-dom";

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

// File Imports
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import FrmMProductCategory3Entry from 'Masters/MProductCategory3/FrmMProductCategory3Entry';
import FrmMProductTypeEntry from 'Masters/MProductType/FrmProductEntry';
import FrmMProductCategory1Entry from 'Masters/MProductCategory1/FrmProductCategory1Entry';
import FrmMProductCategory2Entry from 'Masters/MProductCategory2/FrmMProductCategory2Entry';
import ConfigConstants from "assets/Constants/config-constant";
import FrmValidations from 'FrmGeneric/FrmValidations';

export default function FrmMProductCategory4Entry(props) {

  const { state } = useLocation();
  const { productcategory4ID = 0, keyForViewUpdate = 'Add', compType = 'Masters' } = state || {};

  const configConstants = ConfigConstants();
  const { COMPANY_ID, UserName } = configConstants;

  var activeValue = '';

  // use refs
  const comboDataAPiCall = useRef();
  const validate = useRef();

  // combobox Option
  const [category3Options, setCategory3Options] = useState([])
  const [category2Options, setCategory2Options] = useState([])
  const [productTypeOptions, setProductTypeOptions] = useState([]);

  // Add Product Type Form Fields
  const [cmb_product_category2_id, setProductCategory2ID] = useState('');
  const [cmb_product_category1_id, setProductCategory1ID] = useState('');
  const [productCategory1Options, setProductCategory1Options] = useState([])
  const [cmb_product_type_id, setProductTypeId] = useState('');
  const [txt_product_category4_name, setProductcategory4Name] = useState('');
  const [txt_product_category4_short_name, setProductcategory4ShortName] = useState('');
  const [txt_remark, setRemark] = useState('');
  const [cmb_product_category3_id, setPCategory3Id] = useState('');
  const [actionType, setActionType] = useState('')


  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // For navigate
  const navigate = useNavigate();

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    navigate(`/Masters/ProductCategory4Listing`);
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  // Add rec modal
  const closeCat4RecModal = async () => {
    switch (cat4ModalHeaderName) {
      case 'Product Type':
        const productTypeApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_type", "", "")
        setProductTypeOptions(productTypeApiCall)

        break;
      case 'Product Category-1':
        var proctTypeIdVal = document.getElementById('cmb_product_type_id').value;
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: proctTypeIdVal });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: "0" });
        globalQuery.table = "smv_product_category1"

        var productCat1ApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
        setProductCategory1Options(productCat1ApiCall)
        break;
      case 'Product Category-2':
        var productCat1Val = document.getElementById('cmb_product_category1_id').value;
        setProductCategory1ID(productCat1Val)
        var proctTypeIdVal = document.getElementById('cmb_product_type_id').value;

        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: proctTypeIdVal });
        globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: productCat1Val });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: "0" });
        globalQuery.table = "smv_product_category2"

        var productCat2ApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
        setCategory2Options(productCat2ApiCall)
        break;
      case 'Product Category-3':
        var productCat2Val = document.getElementById('cmb_product_category2_id').value;
        setPCategory3Id(productCat2Val)
        var proctTypeIdVal = document.getElementById('cmb_product_type_id').value;

        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: proctTypeIdVal });
        globalQuery.conditions.push({ field: "product_category2_id", operator: "=", value: productCat2Val });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: "0" });
        globalQuery.table = "smv_product_category3"

        var productCat3ApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
        setCategory3Options(productCat3ApiCall)
        break;
      default:
        break;
    }
    setShowAddRecCat4Modal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({
        "padding-top": "110px"
      });
    }, 200)

  }
  const [showAddRecCat4Modal, setShowAddRecCat4Modal] = useState(false);
  const [cat4ModalHeaderName, setCat4ModalHeaderName] = useState('')

  useEffect(async () => {
    ActionType();
    await fillComobos();
    if (productcategory4ID !== 0) {
      await FnCheckUpdateResponce();
    }
  }, [])

  const ActionType = async () => {
    switch (keyForViewUpdate) {

      case 'update':
        setActionType('(Modify)');

        break;
      case 'view':
        setActionType('(View)');
        $("input[type=radio]").attr('disabled', true);
        break;
      default:
        setActionType('(Create)');
        break;
    }

  };

  const fillComobos = async () => {
    const productTypeApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_type", "", "")
    setProductTypeOptions(productTypeApiCall)
  }

  const handleSubmit = async () => {
    try {
      const checkIsValidate = await validate.current.validateForm('productcategory4FormId');
      if (checkIsValidate) {
        if (productcategory4ID === '' || productcategory4ID === null) { productcategory4ID = 0 }
        var active;

        activeValue = document.querySelector('input[name=isactive]:checked').value

        switch (activeValue) {
          case '1': active = true; break;
          case '0': active = false; break;

        }

        const data = {
          company_id: COMPANY_ID,
          product_category4_id: productcategory4ID,
          created_by: UserName,
          modified_by: productcategory4ID === 0 ? null : UserName,
          product_category1_id: cmb_product_category1_id,
          product_category2_id: cmb_product_category2_id,
          product_category3_id: cmb_product_category3_id,
          product_type_id: cmb_product_type_id,
          product_category4_name: txt_product_category4_name,
          product_category4_short_name: txt_product_category4_short_name.trim(),
          remark: txt_remark,
          is_active: active,

        };
        console.log(data);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productcategory4/FnAddUpdateRecord`, requestOptions)
        const responce = await apicall.json()
        console.log("response error: ", responce);
        if (responce.error !== "") {
          console.log("response error: ", responce.error);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)

        } else {
          var data1 = JSON.parse(responce.data)
          console.log("productcategory4ID", data1);
          const evitCache = comboDataAPiCall.current.evitCache();
          console.log(evitCache);
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);


        }

      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  };

  const FnCheckUpdateResponce = async () => {

    try {
      // var productcategory4ID = sessionStorage.getItem("productcategory4ID");
      if (productcategory4ID !== "undefined" && productcategory4ID !== '' && productcategory4ID !== null) {

        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productcategory4/FnShowParticularRecordForUpdate/${productcategory4ID}`)
        const updateRes = await apiCall.json();

        let resp = JSON.parse(updateRes.data)
        await fillComobos();

        setProductTypeId(resp.product_type_id);
        await comboOnChange('productType')
        setProductCategory1ID(resp.product_category1_id);
        await comboOnChange('productCategory1')
        setProductCategory2ID(resp.product_category2_id);
        await comboOnChange('productCategory2')
        setPCategory3Id(resp.product_category3_id)
        setProductcategory4Name(resp.product_category4_name);
        setProductcategory4ShortName(resp.product_category4_short_name);
        setRemark(resp.remark);

        switch (resp.is_active) {
          case true:
            document.querySelector('input[name="isactive"][value="1"]').checked = true;
            break;
          case false:
            document.querySelector('input[name="isactive"][value="0"]').checked = true;
            break;
        }
        // var keyForViewUpdate = sessionStorage.getItem('keyForViewUpdate');

        switch (keyForViewUpdate) {
          case 'view':
            $("input[type=radio]").attr('disabled', true);
            $('#saveBtn').attr('disabled', true);
            await validate.current.readOnly("productcategory4FormId");
            break;
          case 'update':
            $('#cmb_product_type_id').attr('disabled', true);
            $('#cmb_product_category1_id').attr('disabled', true);
            $('#cmb_product_category2_id').attr('disabled', true);
            $('#cmb_product_category3_id').attr('disabled', true);
            $('#txt_product_category4_name').attr('disabled', true);
            $('#txt_product_category4_short_name').attr('disabled', true);
            break;
        }

      }

    } catch (error) {
      console.log("error", error)
      navigate('/Error')
    }
  }

  const comboOnChange = async (key) => {
    switch (key) {
      case 'productType':
        var productTpVal = document.getElementById('cmb_product_type_id').value;
        if (productTpVal === '0') {
          setProductTypeId(productTpVal)
          setCat4ModalHeaderName('Product Type')
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setShowAddRecCat4Modal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
            // document.querySelectorAll(".erp_top_Form")[0].style.paddingTop = "0px";
          }, 200)
        } else {
          setProductTypeId(productTpVal)
          $('#error_cmb_product_type_id').hide();
          resetGlobalQuery();
          globalQuery.columns.push("field_id");
          globalQuery.columns.push("field_name");
          globalQuery.table = "smv_product_category1"
          globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTpVal });
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          const productCategory1ApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
          setProductCategory1Options(productCategory1ApiCall);
          setProductCategory1ID('');
          setProductCategory2ID('');
          setPCategory3Id('');

        }
        break;

      case 'productCategory1':
        var productCat1Val = document.getElementById('cmb_product_category1_id').value;
        var proctTypeIdVal = document.getElementById('cmb_product_type_id').value;
        if (productCat1Val === '0') {
          setProductCategory1ID(productCat1Val)
          setCat4ModalHeaderName('Product Category-1')
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setShowAddRecCat4Modal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
          }, 200)
        } else {
          setProductCategory1ID(productCat1Val)
          $('#error_cmb_product_category1_id').hide();

          resetGlobalQuery();
          globalQuery.columns.push("field_id");
          globalQuery.columns.push("field_name");
          globalQuery.table = "smv_product_category2"
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: proctTypeIdVal });
          globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: productCat1Val });
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          var productCat2ApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
          setCategory2Options(productCat2ApiCall)
          setProductCategory2ID('');
          setPCategory3Id('');
        }
        break;

      case 'productCategory2':
        var productCat2Val = document.getElementById('cmb_product_category2_id').value;
        var productCat1Val = document.getElementById('cmb_product_category1_id').value;
        var productTpVal = document.getElementById('cmb_product_type_id').value;
        if (productCat2Val === '0') {
          setProductCategory2ID(productCat2Val)
          setCat4ModalHeaderName('Product Category-2')
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setShowAddRecCat4Modal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
          }, 200)
        } else {
          $('#error_cmb_product_category2_id').hide();
          setProductCategory2ID(productCat2Val)
          // Product category 3 list
          resetGlobalQuery();
          globalQuery.columns.push("field_id");
          globalQuery.columns.push("field_name");
          globalQuery.table = "smv_product_category3"
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTpVal });
          globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: productCat1Val });
          globalQuery.conditions.push({ field: "product_category2_id", operator: "=", value: productCat2Val });
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          var productCat3ApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
          setCategory3Options(productCat3ApiCall)
          setPCategory3Id('');
        }
        break;
      case 'productCategory3':
        var productCat3Val = document.getElementById('cmb_product_category3_id').value;
        if (productCat3Val === '0') {
          setPCategory3Id(productCat3Val)
          setCat4ModalHeaderName('Product Category-3')
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setShowAddRecCat4Modal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
          }, 200)
        } else {
          setPCategory3Id(productCat3Val)
        }

        break;
    }
  }

  const cat4DisplayRecordComponent = () => {
    switch (cat4ModalHeaderName) {
      case 'Product Type':
        return <FrmMProductTypeEntry btn_disabled />;
      case 'Product Category-1':
        return <FrmMProductCategory1Entry btn_disabled />;
      case 'Product Category-2':
        return <FrmMProductCategory2Entry btn_disabled />
      case 'Product Category-3':
        return <FrmMProductCategory3Entry btn_disabled />
      default:
        return null;
    }
  }


  const validateFields = () => {
    validate.current.validateFieldsOnChange('productcategory4FormId')
  }

  return (
    <>
      <div className='erp_top_Form'>
        <ComboBox ref={comboDataAPiCall} />
        <FrmValidations ref={validate} />

        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg main_heding'>Product Category-4 {actionType}  </label>
          </div>

          <form id="productcategory4FormId">
            <div className="row erp_transporter_div">
              <div className="col-sm-6 erp_form_col_div">
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Type<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_product_type_id" className="form-select form-select-sm" value={cmb_product_type_id} onChange={() => { comboOnChange('productType'); validateFields(); }}>
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {productTypeOptions?.map(productType => (
                        <option value={productType.field_id}>{productType.field_name}</option>
                      ))}
                    </select>
                    <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Category1<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_product_category1_id" className="form-select form-select-sm" value={cmb_product_category1_id} onChange={() => { comboOnChange('productCategory1'); validateFields(); }}>
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {productCategory1Options?.map(productCategory1 => (
                        <option value={productCategory1.field_id}>{productCategory1.field_name}</option>
                      ))}
                    </select>
                    <MDTypography variant="button" id="error_cmb_product_category1_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Category2<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_product_category2_id" className="form-select form-select-sm" value={cmb_product_category2_id} onChange={() => { comboOnChange('productCategory2'); validateFields(); }}>
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {category2Options?.map(productCategory2 => (
                        <option value={productCategory2.field_id}>{productCategory2.field_name}</option>
                      ))}
                    </select>
                    <MDTypography variant="button" id="error_cmb_product_category2_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                {/* new combox category3 */}
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Category3<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_product_category3_id" className="form-select form-select-sm" value={cmb_product_category3_id} onChange={() => { comboOnChange('productCategory3'); validateFields(); }}>
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {category3Options?.map(productCategory3 => (
                        <option value={productCategory3.field_id}>{productCategory3.field_name}</option>

                      ))}

                    </select>
                    <MDTypography variant="button" id="error_cmb_product_category3_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
              </div>

              {/* second row */}

              <div className="col-sm-6 erp_form_col_div">
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> Category 4 Name<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_category4_name" className="erp_input_field" value={txt_product_category4_name} onChange={e => { setProductcategory4Name(e.target.value); validateFields(); }} maxLength="255" />
                    <MDTypography variant="button" id="error_txt_product_category4_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Short Name</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_category4_short_name" className="erp_input_field" value={txt_product_category4_short_name} onChange={e => { setProductcategory4ShortName(e.target.value.toUpperCase()); validateFields(); }} maxLength="4" optional="optional" />
                    <MDTypography variant="button" id="error_txt_product_category4_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>

                </div>

                <div className="row">
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> Remark </Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control as="textarea" rows={1} className="erp_txt_area" id="txt_remark" value={txt_remark} onChange={e => { setRemark(e.target.value); validateFields(); }} maxLength="255" optional="optional" />
                    <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">Is Active</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="Yes"
                          type="radio"
                          value="1"
                          name="isactive"
                          defaultChecked
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="No"
                          value="0"
                          type="radio"
                          name="isactive"
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </form>
          <div className="card-footer py-0 text-center">
            <MDButton type="button" className="erp-gb-button"
              onClick={() => {
                const path = compType === 'Register' ? '/Masters/ProductCategory4Listing/reg' : '/Masters/ProductCategory4Listing';
                navigate(path);
              }}
              variant="button"
              fontWeight="regular" disabled={props.btn_disabled ? true : false}
            >Back</MDButton>
            {keyForViewUpdate !== 'view' ? (
              <MDButton type="submit" onClick={handleSubmit} id="saveBtn" className="erp-gb-button erp_MLeft_btn " variant="button"
                fontWeight="regular">save</MDButton>
            ) : null}
          </div >
        </div>
        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />


        {/* Add new Record Popup */}
        {/* //changes by tushar */}
        <Modal size="lg" show={showAddRecCat4Modal} onHide={closeCat4RecModal} backdrop="static" keyboard={false} centered >

          <Modal.Body className='erp_city_modal_body'>
            <div className='row'>
              <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeCat4RecModal}></button></div>
            </div>
            {cat4DisplayRecordComponent()}
          </Modal.Body>
        </Modal >

      </div >

    </>
  )
}
