import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ConfigConstants from "assets/Constants/config-constant";


const DispatchChallan = React.forwardRef(({ dispatchChallanContent }, ref) => {
    debugger
    const data = dispatchChallanContent.items
    const configConstants = ConfigConstants();
    const { AFTER_DECIMAL_PLACES } = configConstants;

    const itemData = extractItemData(dispatchChallanContent.items);
    function extractItemData(items) {
        // Extract relevant data from the first item in the array
        if (items.length > 0) {
            const firstItem = items[0];
            return {
                company: firstItem.company,
                company_branch: firstItem.company_branch,
                address: firstItem.address,
                phone: firstItem.phone,
                cell: firstItem.cell,
                email: firstItem.email,
                website: firstItem.website,
                GSTIN: firstItem.GSTIN,
                pan_no: firstItem.pan_no,
                item_amount: firstItem.item_amount,
                item_rate: firstItem.item_rate,
                state_name: firstItem.state_name,
                company_pincode: firstItem.company_pincode,
                company_udyog_adhar_no: firstItem.company_udyog_adhar_no,
            };
        }
        // Return default values or handle the case where the array is empty
        return {
            company: 0,
            company_branch: 0,
            address: 0,
            phone: 0,
            cell: 0,
            email: 0,
            website: 0,
            GSTIN: 0,
            pan_no: 0,
            state_name: 0,
            company_pincode: 0,
            company_udyog_adhar_no: 0,
            item_rate: 0,
            item_amount: 0,
        };
    }

    const invoiceHeader = (
        <>
            <div className='bordered border-bottom-0 px-0'>
                <div className='row mb-0 p-2'>
                    <div className="col-sm-3 text-center text-sm-start">
                        <img src={DakshabhiLogo} alt="master card" width="100" height="100" mt={1} />
                    </div>
                    <div className="col-sm-9 transformed">
                        <div className='erp-invoice-print-label-lg text-center'>
                            {itemData.company}
                        </div>
                        <div className="row">
                            <div className="text-center">
                                <div className='erp-invoice-print-label text-center'>
                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>{itemData.address} ({itemData.company_branch})</span>
                                    </div>
                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>EmailId: {itemData.email} | Phone No.: {itemData.phone}  </span>
                                    </div>
                                    <div className='erp-invoice-print-label-lg'> Dispatch Challan </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row border-bottom border-top border-dark p-0 m-0'>
                    <div className='col-sm-6'>
                        <dt className='erp-invoice-print-label-md-lg'>Deliver To : {dispatchChallanContent.customerDetails.customer}</dt>
                    </div>
                    <div className='col-sm-6'>
                        <dt className='erp-invoice-print-label-md-lg text-end'>Dispatch CH. No. & version : {dispatchChallanContent.dispatchDetails.dispatch_challan_no} & {dispatchChallanContent.dispatchDetails.dispatch_challan_version}</dt>
                    </div>
                    <div className='col-sm-6'>
                        <dt className='erp-invoice-print-label-md-lg'>Customer Email : {dispatchChallanContent.customerDetails.customer_email}</dt>
                    </div>
                    <div className='col-sm-6'>
                        <dt className='erp-invoice-print-label-md-lg text-end '>Dispatch Date: {dispatchChallanContent.dispatchDetails.dispatch_challan_date}</dt>
                    </div>
                    <div className='col-sm-6'>
                        <dt className='erp-invoice-print-label-md-lg '>Customer Mobile no: {dispatchChallanContent.customerDetails.contact}</dt>

                    </div>
                    <div className='col-sm-6'>
                        <dt className='erp-invoice-print-label-md-lg text-end'>Challan Type: {dispatchChallanContent.dispatchDetails.dispatch_challan_creation_type_desc}</dt>

                    </div>
                    <div className='col-sm-6'>
                        <dt className='erp-invoice-print-label-md-lg '>Customer Address: {dispatchChallanContent.customerDetails.customer_address}</dt>
                    </div>
                </div>
                {/* <div className='row border-bottom border-dark p-0 m-0'>
                    <div className="row p-0 m-0">
                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>GSTIN No:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {itemData.GSTIN}</dd>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>State Name:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'>{`${itemData.state_name} (${itemData.company_pincode})`}</dd>

                                <dt className='col-sm-5 erp-invoice-print-label-md-lg'>Tax Is Pay. On reverse Charge: </dt>
                                <dd className='col-sm-7 erp-invoice-print-label'>{dispatchChallanContent.dispatchDetails.is_service}</dd>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Udyog Adhar No:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {itemData.company_udyog_adhar_no}</dd>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>PAN No.:</dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> {itemData.pan_no}</dd>
                            </dl>
                        </div>
                        <div className="col-sm-6">
                            <dl className="row pb-0 mb-0">
                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>PO No & Date: </dt>
                                <dd className='col-sm-8 erp-invoice-print-label'> </dd>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>CH. No. & Date: </dt>
                                <dd className='col-sm-8 erp-invoice-print-label'></dd>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Vendor Code: </dt>
                                <dd className='col-sm-8 erp-invoice-print-label'></dd>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Time of Issue : </dt>
                                <dd className='col-sm-8 erp-invoice-print-label'>{dispatchChallanContent.dispatchDetails.time_of_issue}</dd>

                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Time of Removal: </dt>
                                <dd className='col-sm-8 erp-invoice-print-label'>{dispatchChallanContent.dispatchDetails.time_of_removal}</dd>           
                            </dl>
                        </div>
                    </div>
                </div> */}
                {/* <div className="row border-dark p-0 m-0">
                    <div className="col-sm-6 border-end border-dark">
                        <dt className='erp-invoice-print-label-md-lg'>Bill To Details:</dt>
                        <div className='erp-invoice-print-label-md-lg'>
                            {dispatchChallanContent.customerDetails.customer}
                        </div>
                        <div className="erp-invoice-print-label">
                            {dispatchChallanContent.customerDetails.customer_address}
                        </div>
                        <dl className="row mb-0">
                            <dt className='col-sm-6 mb-0 pb-0'>
                                <dl class="row mb-0 pb-0">
                                    <dt class="col-sm-5 erp-invoice-print-label-md-lg">GSTIN No.:</dt>
                                    <dd class="col-sm-7 erp-invoice-print-label">{dispatchChallanContent.customerDetails.customer_gst_no}</dd>
                                </dl>
                            </dt>
                            <dt className='col-sm-6 mb-0 pb-0'>
                                <dl class="row mb-0 pb-0">
                                    <dt class="col-sm-4 erp-invoice-print-label-md-lg">PAN No.:</dt>
                                    <dd class="col-sm-8 erp-invoice-print-label">{dispatchChallanContent.customerDetails.customer_pan_no}</dd>
                                </dl>
                            </dt>
                            <dt className='col-sm-2  mb-0 pb-0 erp-invoice-print-label-md-lg'>State:</dt>
                            <dd className='col-sm-10 mb-0 pb-0 erp-invoice-print-label'> {dispatchChallanContent.customerDetails.state}</dd>
                        </dl>
                    </div>

                    <div className="col-sm-6">
                        <dt className='erp-invoice-print-label-md-lg'>Shipped To Details:</dt>
                        <div className='erp-invoice-print-label-md-lg'>
                            {dispatchChallanContent.consigneeDetails.consignee}
                        </div>
                        <div className="erp-invoice-print-label">
                            {dispatchChallanContent.consigneeDetails.consignee_address}
                        </div>
                        <dl className="row mb-0 pb-0">
                            <dt className='col-sm-6 mb-0 pb-0'>
                                <dl class="row mb-0 pb-0">
                                    <dt className="col-sm-5 erp-invoice-print-label-md-lg">GSTIN No.:</dt>
                                    <dd className="col-sm-7 erp-invoice-print-label">{dispatchChallanContent.consigneeDetails.consignee_gst_no}</dd>
                                </dl>
                            </dt>
                            <dt className='col-sm-6 mb-0 pb-0'>
                                <dl className="row mb-0 pb-0">
                                    <dt className="col-sm-4 mb-0 pb-0 erp-invoice-print-label-md-lg">PAN No.:</dt>
                                    <dd className="col-sm-8  mb-0 pb-0 erp-invoice-print-label">{dispatchChallanContent.consigneeDetails.consignee_pan_no}</dd>
                                </dl>
                            </dt>
                            <dt className='col-sm-2 erp-invoice-print-label-md-lg'>State:</dt>
                            <dd className='col-sm-10 erp-invoice-print-label'> {dispatchChallanContent.consigneeDetails.state}</dd>
                        </dl>

                    </div>
                </div> */}
                {/* <div className='row border-top border-dark p-0 m-0'>
                        <dt className="col-sm-4 erp-invoice-print-label-md-lg">Place Of Supply : {`${itemData.state_name} (${itemData.company_pincode})`}</dt>
                        <div className="row p-0 m-0">
                            <div className="col-sm-4">
                                <dl className="row pb-0 mb-0">
                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Transporter : </dt>
                                    <dd className='col-sm-8 erp-invoice-print-label'> </dd>

                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Vehicle No. :</dt>
                                    <dd className='col-sm-8 erp-invoice-print-label'>{dispatchChallanContent.dispatchDetails.vehicle_no}</dd>
                          
                                </dl>
                            </div>
                            <div className="col-sm-4">
                                <dl className="row pb-0 mb-0">
                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg'>L.R. No :</dt>
                                    <dd className='col-sm-8 erp-invoice-print-label'></dd>

                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg'>L.R. Date :</dt>
                                    <dd className='col-sm-8 erp-invoice-print-label'></dd>
                     
                                </dl>
                            </div>
                            <div className="col-sm-4">
                                <dl className="row pb-0 mb-0">
                                
                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg'>LC No :</dt>
                                    <dd className='col-sm-8 erp-invoice-print-label'></dd>

                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Insu. No :</dt>
                                    <dd className='col-sm-8 erp-invoice-print-label'></dd>
                                </dl>
                            </div>
                        </div>
                </div> */}
            </div>
        </>
    )
    const invoiceFooter = (
        <div className="invoice-footer">
            <div className='row mt-2'>
                <div className='col-sm-3'>
                    {/* <div className='text-center'>
                <dt className="erp-invoice-print-label-md-lg">For </dt>
              </div> */}
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg">Receiver's Signatory</dt>
                    </div>
                </div>
                <div className='col-sm-3 offset-sm-6'> {/* This offsets the third column */}
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">For {itemData.company}</dt>
                    </div>
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg">Authorised Signatory</dt>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            {/* <div className="container"> */}

            <div className="row">
                <div className="col-12">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0 mt-3">

                                {/* <!--BILL HEADER--> */}
                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    {invoiceHeader}
                                </div>

                                {/* <!--PARCEL DETAILS--> */}
                                {/* <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12 ">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            <thead>
                                                                <tr>
                                                                    <th className="erp_invoice_table_th" rowspan={2}>Sr No.</th>
                                                                    <th className="erp_invoice_table_th" rowspan={2}>Material Name(Technical Spect.)</th>
                                                                    <th className="erp_invoice_table_th" rowspan={2}>HSN Code</th>
                                                                    <th className="erp_invoice_table_th" rowspan={2}>Unit</th>
                                                                    <th className="erp_invoice_table_th" rowspan={2}>Dispatch Qty.</th>
                                                                    <th className="erp_invoice_table_th" rowspan={2}>Dispatch Wt.</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id='detailstableId'>
                                                                {
                                                                    data.map((item, index) =>
                                                                        <>
                                                                            <tr>
                                                                                <td className="erp_invoice_table_td text-center">{item.sr_no}</td>
                                                                                <td className="erp_invoice_table_td">{item.product_material_print_name}<span>( {item.product_material_tech_spect} )</span></td>
                                                                                <td className="erp_invoice_table_td">{item.hsn_code}</td>
                                                                                <td className="erp_invoice_table_td">{item.product_unit_name}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.dispatch_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.dispatch_weight}</td>
                                                                            </tr>
                                                                            {data.length - 1 === index ? <>
                                                                                    <tr id='footerTr'>
                                                                                        <td className="erp_invoice_table_th">Total</td>
                                                                                        <td className="erp_invoice_table_td text-end"></td>
                                                                                        <td className="erp_invoice_table_td text-end"></td>
                                                                                        <td className="erp_invoice_table_td text-end"></td>
                                                                                        <td className="erp_invoice_table_td text-end">{parseFloat(dispatchChallanContent.footer.total_dispatch_quantity).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                        <td className="erp_invoice_table_td text-end">{parseFloat(dispatchChallanContent.footer.total_dispatch_weight).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan={12}>
                                                                                            {invoiceFooter}
                                                                                        </td>
                                                                                    </tr>

                                                                                </> : null
                                                                            }
                                                                            
                                                                        </>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12 ">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            <thead>
                                                                <tr>
                                                                    <th className="erp_invoice_table_th" rowspan={2}>Sr No.</th>
                                                                    <th className="erp_invoice_table_th" rowspan={2}>Material Name(Technical Spect.)</th>
                                                                    <th className="erp_invoice_table_th" rowspan={2}>Qty</th>
                                                                    <th className="erp_invoice_table_th" rowspan={2}>Rate</th>
                                                                    <th className="erp_invoice_table_th" rowspan={2}>Amount</th>
                                                                    {/* <th className="erp_invoice_table_th" rowspan={2}>Dispatch Wt.</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody id='detailstableId'>
                                                                {
                                                                    data.map((item, index) =>
                                                                        <>
                                                                            <tr>
                                                                                <td className="erp_invoice_table_td text-center">{item.sr_no}</td>
                                                                                <td className="erp_invoice_table_td">{item.product_material_print_name}<span>( {item.product_material_tech_spect} )</span></td>
                                                                                <td className="erp_invoice_table_td">{item.dispatch_quantity}</td>
                                                                                <td className="erp_invoice_table_td">{item.item_rate}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.item_amount}</td>
                                                                            </tr>
                                                                            {data.length - 1 === index ? <>
                                                                                <tr id=''>
                                                                                    <td className="erp_invoice_table_th">Total</td>
                                                                                    <td className="erp_invoice_table_td text-end"></td>
                                                                                    <td className="erp_invoice_table_td text-end"></td>
                                                                                    <td className="erp_invoice_table_td text-end"></td>
                                                                                    <td className="erp_invoice_table_td text-end">{parseFloat(dispatchChallanContent.footer.total_dispatch_amount).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan={12}>
                                                                                        {invoiceFooter}
                                                                                    </td>
                                                                                </tr>

                                                                            </> : null
                                                                            }

                                                                        </>
                                                                    )
                                                                }
                                                            </tbody>
                                                          

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
})

export default DispatchChallan
