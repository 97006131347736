import React, { useEffect, useRef, useMemo } from 'react'
import { useState } from 'react'
import $ from 'jquery';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import Accordion from "react-bootstrap/Accordion";

import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import { Form, Table } from 'react-bootstrap';
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

function FrmProductionEntry() {
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_NAME, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME } = configConstants;

    const [action_Label, setActionLabel] = useState('(Add)');

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    ////useRef Hooks
    const comboDataAPiCall = useRef();
    const navigate = useRef();

    //Shift wise Ring Frame Prduction hooks
    const [dt_spinning_production_date, setProdPlanDate] = useState(today);
    const [cmb_prod_month, setProdMonth] = useState();
    const [cmb_prod_year, setProdYear] = useState();
    const [cmb_plant_id, setProdPlantName] = useState();
    const [cmb_section_id, setProdSection] = useState();
    const [cmb_sub_section_id, setProdSubSection] = useState();
    const [cmb_shift, setShift] = useState([]);
    const [txt_target_efficiency, setProdTargetEff] = useState();
    const [txt_no_of_machine, setProdNoOfMachine] = useState();

    //Shift wise Ring Frame Prduction comboboxes
    const [spinplanyearOptions, setSpinPlanYearOptions] = useState([]);
    const [plantOptions, setPlantOptions] = useState([]);
    const [prodsectionOptions, setProdSectionOptions] = useState([]);
    const [prodsubsectionOptions, setProdSubSectionOptions] = useState([]);
    const [shiftOptions, setShiftOptions] = useState([]);


    ///Shiftwise Machine Stoppage Entry(Ring Frame) Hooks
    const [cmb_machine_no, setMachineNo] = useState();
    const [cmb_stoppage_reason, setMachineStoppageReason] = useState();
    const [txt_stoppage_time, setMachineStoppageTime] = useState();
    const [txt_std_prod_loss, setStdProdLoss] = useState();
    const [txt_act_prod_loss, setActProdLoss] = useState();

    ///Shiftwise Machine Stoppage Entry(Ring Frame) Comboboxes
    const [machineOptions, setProdMachineOptions] = useState([]);
    const [stoppagereasonOptions, setProdStoppageReasonOptions] = useState([]);
    const [stoppageDetails, setStoppageDetails] = useState([]);


    ///Shiftwise Machine Wastage Entry(Ring Frame) Hooks
    const [cmb_prod_wastage_type, setProdWastageType] = useState();
    const [txt_act_wastages, setActWastage] = useState();
    const [txt_act_wastage_percent, setActWastagePercent] = useState();
    const [txt_std_wastages, setStdWastages] = useState();
    const [txt_std_wastage_percent, setStdWastagePercent] = useState();

    ///Shiftwise Machine Wastage Entry(Ring Frame) Combobox
    const [prodWastageOptions, setProdWastageOptions] = useState([]);
    const [wastageDetails, setWastageDetails] = useState([]);

    ///Production summary Hooks
    const [prodSummaryRowsFirstCol, setprodSummaryRowsFirstCol] = useState(['No Of Machine', '100% Production', 'Target Production', 'Actual Production', 'Production Loss', 'Stoppage Time(mins)', 'Shift Wastage(Kgs)', 'Spindle Utilize']);

      //Error Msg
      const handleCloseErrModal = () => setShowErrorMsgModal(false);
      const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
      const [errMsg, setErrMsg] = useState('');
  
  
      // Success Msg HANDLING
      const handleCloseSuccessModal = () => setShowSuccessMsgModal(false);
      const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
      const [succMsg, setSuccMsg] = useState('');

    useEffect(async () => {
        await fillComboBox();
    }, [])

    const fillComboBox = async () => {
        debugger
        const plantApiCall = await comboDataAPiCall.current.fillMasterData("amv_financial_year", "", "");
        setSpinPlanYearOptions(plantApiCall);

        const getProdPlantApiCall = await comboDataAPiCall.current.fillMasterData("cmv_plant", "", "");
        setPlantOptions(getProdPlantApiCall);

        const getProdSectionApiCall = await comboDataAPiCall.current.fillMasterData("xmv_production_section", "", "");
        setProdSectionOptions(getProdSectionApiCall);

        const shiftsApiCall = await comboDataAPiCall.current.fillComboBox('ProductionShifts');
        setShiftOptions(shiftsApiCall);

        const getProdMachineApiCall = await comboDataAPiCall.current.fillMasterData("cmv_machine", "", "");
        setProdMachineOptions(getProdMachineApiCall);

        const getProdStoppageReasonsApiCall = await comboDataAPiCall.current.fillMasterData(" xmv_production_stoppage_reasons", "", "");
        setProdStoppageReasonOptions(getProdStoppageReasonsApiCall);

        const getProdWastageReasonsApiCall = await comboDataAPiCall.current.fillMasterData(" xmv_production_wastage_types", "", "");
        setProdWastageOptions(getProdWastageReasonsApiCall);

    }

    const comboOnChange = async (key) => {
        switch (key) {
            case 'cmb_section_id':
                const prod_section_Id = $('#cmb_section_id').val();
                const getProdSubSectionApiCall = await comboDataAPiCall.current.fillMasterData("xmv_production_sub_section", "production_section_id", prod_section_Id);
                setProdSubSectionOptions(getProdSubSectionApiCall);
                break;
        }
    }

    /////////////////////////////////Machine Stoppage Details///////////////////////////////////
    const addDetailsMachineStoppage = () => {
        debugger
        const machineStoppageDetails = [...stoppageDetails];

        const newStoppageDetail = {
            machine_no: $('#cmb_machine_no option:selected').text(),
            stoppage_reason: $('#cmb_stoppage_reason option:selected').text(),
            stoppage_time: txt_stoppage_time,
            std_stoppage_prod_loss: txt_std_prod_loss,
            act_stoppage_prod_percent: txt_act_prod_loss,
        };

        machineStoppageDetails.push(newStoppageDetail);

        setStoppageDetails(machineStoppageDetails);
    }

    const renderMachineStoppageTable = useMemo(() => {
        return <Table id="machinestoppagetableId" style={{ display: "block", overflow: "auto" }} bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <td className="erp_table_th" style={{ width: "150px" }}>Machine No</td>
                    <td className="erp_table_th" style={{ width: "300px" }}>Stoppage Reason</td>
                    <td className="erp_table_th" style={{ width: "100px" }}>Stoppage Time</td>
                    <td className="erp_table_th" style={{ width: "100px" }}>Std. Prod. Loss</td>
                    <td className="erp_table_th" style={{ width: "100px" }}>Act. Prod. Loss</td>
                </tr>
            </thead>
            <tbody>
                {stoppageDetails?.map((details, indexOfItem) => (
                    <tr>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.machine_no}
                        </td>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.stoppage_reason}
                        </td>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.stoppage_time}
                        </td>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.std_stoppage_prod_loss}
                        </td>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.act_stoppage_prod_percent}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>

    }, [stoppageDetails]);

    /////////////////////////////////Machine Wastage Details///////////////////////////////////
    const addDetailsMachineWastage = () => {
        debugger
        const machineWastageDetails = [...wastageDetails];

        const newWastageDetail = {
            wastagetype: $('#cmb_prod_wastage_type option:selected').text(),
            act_wastage_Kg: txt_act_wastages,
            act_wastage_percent: txt_act_wastage_percent,
            std_wastage_Kg: txt_std_wastages,
            std_wastage_percent: txt_std_wastage_percent,
        };

        machineWastageDetails.push(newWastageDetail);

        setWastageDetails(machineWastageDetails);
    }

    const renderMachineWastageTable = useMemo(() => {
        return <Table id="machinewastagetableId" style={{ display: "block", overflow: "auto" }} bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <td className="erp_table_th" style={{ width: "150px" }}>Wastage Type</td>
                    <td className="erp_table_th" style={{ width: "300px" }}>Act. Wastage(Kg)</td>
                    <td className="erp_table_th" style={{ width: "100px" }}>Act. Wastage(%)</td>
                    <td className="erp_table_th" style={{ width: "100px" }}>Std. Wastage(Kg)</td>
                    <td className="erp_table_th" style={{ width: "100px" }}>Std. Wastage(%)</td>
                </tr>

            </thead>
            <tbody>
                {wastageDetails?.map((details, indexOfItem) => (
                    <tr>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.wastagetype}
                        </td>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.act_wastage_Kg}
                        </td>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.act_wastage_percent}
                        </td>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.std_wastage_Kg}
                        </td>
                        <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                            {details.std_wastage_percent}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>

    }, [wastageDetails]);

    const renderProductionShiftDetails = useMemo(() => {
        return <Table id="productionshifttableId" style={{ display: "block", overflow: "auto" }} bordered striped>
            <thead className="erp_table_head">
                <tr id="headerRowId">
                    <th className="erp_table_th">Machine Number</th>
                    <th className="erp_table_th">Machine Operator</th>
                    <th className="erp_table_th">Yarn Count</th>
                    <th className="erp_table_th">Target Efficiency%</th>
                    <th className="erp_table_th">Actual Count</th>
                    <th className="erp_table_th">TPI</th>
                    <th className="erp_table_th">TM</th>
                    <th className="erp_table_th">Speed</th>
                    <th className="erp_table_th">Spindles</th>
                    <th scope="col" colspan="2" className="erp_table_th">100 Percent</th>
                    <th scope="col" colspan="2" className="erp_table_th">Target</th>
                    <th scope="col" colspan="2" className="erp_table_th">Actual</th>
                    <th className="erp_table_th">Actual Effficiency%</th>
                    <th className="erp_table_th">Loss(KG) (With Reason)</th>
                    <th className="erp_table_th">Loss(KG) (Without Reason)</th>
                    <th className="erp_table_th">Ideal Spindle %</th>
                    <th className="erp_table_th">Waste Percent</th>
                    <th className="erp_table_th">Yarn Construction</th>
                    <th className="erp_table_th">Stoppage Time</th>
                    <th className="erp_table_th">Stoppage Spindles</th>
                    <th className="erp_table_th">Mixing</th>
                    <th className="erp_table_th">Count</th>
                    <th className="erp_table_th">40's Conversion</th>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th className="erp_table_th">GSM</th>
                    <th className="erp_table_th">KG</th>
                    <th className="erp_table_th">GSM</th>
                    <th className="erp_table_th">KG</th>
                    <th className="erp_table_th">GSM</th>
                    <th className="erp_table_th">KG</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            </tbody>
        </Table>
    }, [])

    const rendertodaysprodsummary = useMemo(() => {
        debugger
        return <Table id="todaysProdSummaryTableId" style={{ display: "block", overflow: "auto", height: "270px" }} bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <th className="erp_table_th">Description</th>
                    {shiftOptions?.map((header, indexOfItem) => (
                        <th key={indexOfItem} className="erp_table_th" id={`${header}`}>
                            {header.field_name}
                        </th>
                    ))}
                    <th className="erp_table_th">Total</th>
                </tr>
            </thead>
            <tbody>
                {prodSummaryRowsFirstCol?.map((firstCol, index) => (
                    <tr key={index}>
                        <td className="erp_table_td">{firstCol}</td>
                        <td colSpan={3}></td>
                    </tr>
                ))}
            </tbody>
        </Table>
    }, [shiftOptions, prodSummaryRowsFirstCol])

    const renderuptodaysprodsummary = useMemo(() => {
        return <Table id="upToDaysProdSummaryTableId" style={{ display: "block", overflow: "auto", height: "270px" }} bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <th className="erp_table_th">Description</th>
                    {shiftOptions?.map((header, indexOfItem) => (
                        <th key={indexOfItem} className="erp_table_th" id={`${header}`} >
                            {header.field_name}
                        </th>
                    ))}
                    <th className="erp_table_th">Total</th>
                </tr>
            </thead>
            <tbody>
                {prodSummaryRowsFirstCol?.map((firstCol, index) => (
                    <tr key={index}>
                        <td className="erp_table_td">{firstCol}</td>
                        <td colSpan={3}></td>
                    </tr>
                ))}
            </tbody>
        </Table>
    }, [shiftOptions, prodSummaryRowsFirstCol])

    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />

                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Ring Frame Production{action_Label} </label>
                    </div>

                    <form id='productionmasterId'>
                        <div className='row p-1'>
                            {/* 1 st Column */}
                            <div className='col-sm-4 erp_form_col_div'>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Prod. Start Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="date" id='dt_spinning_production_date' className="erp_input_field" value={dt_spinning_production_date} onChange={(e) => { setProdPlanDate(e.target.value) }} />
                                        <MDTypography variant="button" id="error_dt_spinning_production_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Production Month <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_prod_month" className="form-select form-select-sm" value={cmb_prod_month} onChange={(e) => { setProdMonth(e.target.value); }}>
                                            <option value="">Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_prod_month" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Production Year <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_prod_year" className="form-select form-select-sm" value={cmb_prod_year} onChange={(e) => { setProdYear(e.target.value); }}>
                                            <option value="">Select</option>
                                            {spinplanyearOptions.length !== 0 ? (
                                                <>
                                                    {spinplanyearOptions?.map(year => (
                                                        <option value={year.field_id}>{year.field_name}</option>
                                                    ))}
                                                </>
                                            ) : null

                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_prod_year" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Prod. Plant Name <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_plant_id" className="form-select form-select-sm" value={cmb_plant_id} onChange={(e) => { setProdPlantName(e.target.value); }}>
                                            <option value="" disabled>Select</option>
                                            {plantOptions.length !== 0 ? (
                                                <>{plantOptions?.map(plant => (
                                                    <option value={plant.field_id}>{plant.field_name}</option>
                                                ))} </>
                                            ) : null
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_plant_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                            </div>

                            <div className='col-sm-4 erp_form_col_div'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Production Section <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_section_id" className="form-select form-select-sm" value={cmb_section_id} onChange={(e) => { setProdSection(e.target.value); comboOnChange('cmb_section_id') }}>
                                            <option value="">Select</option>
                                            {prodsectionOptions.length !== 0 ? (
                                                <>
                                                    {prodsectionOptions?.map(production => (
                                                        <option value={production.field_id}>{production.field_name}</option>
                                                    ))}
                                                </>
                                            ) : null

                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Prod. Sub Section <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_sub_section_id" className="form-select form-select-sm" value={cmb_sub_section_id} onChange={(e) => { setProdSubSection(e.target.value); }}>
                                            <option value="">Select</option>
                                            {prodsubsectionOptions.length !== 0 ? (
                                                <>
                                                    {prodsubsectionOptions?.map(subproduction => (
                                                        <option value={subproduction.field_id}>{subproduction.field_name}</option>
                                                    ))}
                                                </>
                                            ) : null

                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_sub_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>



                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">No Of Machines<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_no_of_machine' className="erp_input_field" value={txt_no_of_machine} onChange={(e) => { setProdNoOfMachine(e.target.value); }} />
                                        <MDTypography variant="button" id="error_txt_no_of_machine" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-4 erp_form_col_div'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Shift <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_shift" className="form-select form-select-sm" value={cmb_shift} onChange={(e) => { setShift(e.target.value); }}>
                                            <option value="">Select</option>
                                            {shiftOptions.length !== 0 ? (
                                                <>
                                                    {shiftOptions?.map(shift => (
                                                        <option value={shift.field_id}>{shift.field_name}</option>
                                                    ))}
                                                </>
                                            ) : null

                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_shift" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Target Efficiency <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id='txt_target_efficiency' className="erp_input_field" value={txt_target_efficiency} onChange={(e) => { setProdTargetEff(e.target.value); }} />
                                        <MDTypography variant="button" id="error_txt_target_efficiency" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </form>

                    <hr />
                    {/* Production Shift Accordian */}
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="ProcessMapping">
                            <Accordion.Header className="erp-form-label-md">Ring Frame Production Shift Details</Accordion.Header>
                            <Accordion.Body>
                                {renderProductionShiftDetails}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <hr />

                    {/*..............Shiftwise Machine Stoppage Entry(Ring Frame)............................... */}
                    <div className='col-sm-5 erp_form_col_div'>
                        <div className='erp-form-label-lg'>Shiftwise Machine Stoppage Entry(Ring Frame)</div>
                    </div>


                    <div className='row p-1'>
                        {/* 1 st Column */}
                        <div className='col-sm-6 erp_form_col_div'>
                            <div className='row'>
                                <div className='col-sm-3'>
                                    <Form.Label className="erp-form-label">Machine No <span className="required">*</span> </Form.Label>
                                </div>
                                <div className="col-sm-4">
                                    <select id="cmb_machine_no" className="form-select form-select-sm" value={cmb_machine_no} onChange={(e) => { setMachineNo(e.target.value); }}>
                                        <option value="">Select</option>
                                        {machineOptions.length !== 0 ? (
                                            <>
                                                {machineOptions?.map(machine => (
                                                    <option value={machine.field_id}>{machine.field_name}</option>
                                                ))}
                                            </>
                                        ) : null

                                        }
                                    </select>
                                    <MDTypography variant="button" id="error_cmb_machine_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                                <div className='col-sm-4'>
                                    <div className="erp_form_radio">
                                        <div className="fCheck">
                                            <Form.Check
                                                className="erp_radio_button"
                                                label="Applicable To All Machines"
                                                type="checkbox"
                                                value="0"
                                                name="isTestCertRequired"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-3'>
                                    <Form.Label className="erp-form-label">Stoppage Reason <span className="required">*</span> </Form.Label>
                                </div>
                                <div className="col-sm-8">
                                    <select id="cmb_stoppage_reason" className="form-select form-select-sm" value={cmb_stoppage_reason} onChange={(e) => { setMachineStoppageReason(e.target.value); }}>
                                        <option value="">Select</option>
                                        {stoppagereasonOptions.length !== 0 ? (
                                            <>
                                                {stoppagereasonOptions?.map(stoppage => (
                                                    <option value={stoppage.field_id}>{stoppage.field_name}</option>
                                                ))}
                                            </>
                                        ) : null

                                        }
                                    </select>
                                    <MDTypography variant="button" id="error_cmb_stoppage_reason" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-3'>
                                    <Form.Label className="erp-form-label">Stoppage Time <span className="required">*</span> </Form.Label>
                                </div>
                                <div className="col-sm-8">
                                    <Form.Control type="text" id='txt_stoppage_time' className="erp_input_field" value={txt_stoppage_time} onChange={(e) => { setMachineStoppageTime(e.target.value); }} />
                                    <MDTypography variant="button" id="error_txt_stoppage_time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-3'>
                                    <Form.Label className="erp-form-label">Std. Prod. Loss </Form.Label>
                                </div>
                                <div className="col-sm-8">
                                    <Form.Control type="text" id='txt_std_prod_loss' className="erp_input_field" value={txt_std_prod_loss} onChange={(e) => { setStdProdLoss(e.target.value); }} disabled />
                                    <MDTypography variant="button" id="error_txt_std_prod_loss" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-3'>
                                    <Form.Label className="erp-form-label">Act. Prod. Loss </Form.Label>
                                </div>
                                <div className="col-sm-8">
                                    <Form.Control type="text" id='txt_act_prod_loss' className="erp_input_field" value={txt_act_prod_loss} onChange={(e) => { setActProdLoss(e.target.value); }} disabled />
                                    <MDTypography variant="button" id="error_txt_act_prod_loss" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                            <div className="erp_frm_Btns">
                                <MDButton type="button" id="action_Button" className="erp-gb-button ms-2" onClick={() => addDetailsMachineStoppage()} variant="button" fontWeight="regular">Add</MDButton>
                                <MDButton className="erp-gb-button ms-2" variant="button" id='back_Button' fontWeight="regular" >Remove</MDButton>
                                <MDButton className="erp-gb-button ms-2" variant="button" id='back_Button' fontWeight="regular" >Clear</MDButton>
                            </div >
                        </div>


                        <div className='col-sm-5 erp_form_col_div'>
                            <div className='row'>
                                {renderMachineStoppageTable}
                            </div>
                        </div>
                    </div>

                    <hr />
                    {/*..............Shiftwise Machine Wastage Entry(Ring Frame)............................... */}
                    <div className='col-sm-5 erp_form_col_div'>
                        <div className='erp-form-label-lg'>Shiftwise Machine Wastage Entry(Ring Frame)</div>
                    </div>


                    <div className='row p-1'>
                        {/* 1 st Column */}
                        <div className='col-sm-6 erp_form_col_div'>
                            <div className='row'>
                                <div className='col-sm-3'>
                                    <Form.Label className="erp-form-label">Prod. Wastage Type<span className="required">*</span> </Form.Label>
                                </div>
                                <div className="col-sm-4">
                                    <select id="cmb_prod_wastage_type" className="form-select form-select-sm" value={cmb_prod_wastage_type} onChange={(e) => { setProdWastageType(e.target.value); }}>
                                        <option value="">Select</option>
                                        {prodWastageOptions.length !== 0 ? (
                                            <>
                                                {prodWastageOptions?.map(machine => (
                                                    <option value={machine.field_id}>{machine.field_name}</option>
                                                ))}
                                            </>
                                        ) : null

                                        }
                                    </select>
                                    <MDTypography variant="button" id="error_cmb_prod_wastage_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-3'>
                                    <Form.Label className="erp-form-label">Act. Wastages(Kg)<span className="required">*</span> </Form.Label>
                                </div>
                                <div className="col-sm-8">
                                    <Form.Control type="text" id='txt_act_wastages' className="erp_input_field" value={txt_act_wastages} onChange={(e) => { setActWastage(e.target.value); }} />
                                    <MDTypography variant="button" id="error_txt_act_wastages" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-3'>
                                    <Form.Label className="erp-form-label">Act. Wastage (%)<span className="required">*</span> </Form.Label>
                                </div>
                                <div className="col-sm-8">
                                    <Form.Control type="text" id='txt_act_wastage_percent' className="erp_input_field" value={txt_act_wastage_percent} onChange={(e) => { setActWastagePercent(e.target.value); }} />
                                    <MDTypography variant="button" id="error_txt_act_wastage_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-3'>
                                    <Form.Label className="erp-form-label">Std. Wastage(Kg)</Form.Label>
                                </div>
                                <div className="col-sm-8">
                                    <Form.Control type="text" id='txt_std_wastages' className="erp_input_field" value={txt_std_wastages} onChange={(e) => { setStdWastages(e.target.value); }} disabled />
                                    <MDTypography variant="button" id="error_txt_std_wastages" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-3'>
                                    <Form.Label className="erp-form-label">Std. Wastage(%)</Form.Label>
                                </div>
                                <div className="col-sm-8">
                                    <Form.Control type="text" id='txt_std_wastage_percent' className="erp_input_field" value={txt_std_wastage_percent} onChange={(e) => { setStdWastagePercent(e.target.value); }} disabled />
                                    <MDTypography variant="button" id="error_txt_std_wastage_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                            <div className="erp_frm_Btns">
                                <MDButton type="button" id="action_Button" className="erp-gb-button ms-2" variant="button" onClick={() => addDetailsMachineWastage()} fontWeight="regular">Add</MDButton>
                                <MDButton className="erp-gb-button ms-2" variant="button" id='back_Button' fontWeight="regular" >Remove</MDButton>
                                <MDButton className="erp-gb-button ms-2" variant="button" id='back_Button' fontWeight="regular" >Clear</MDButton>
                            </div >
                        </div>


                        <div className='col-sm-5 erp_form_col_div'>
                            <div className='row'>
                                {renderMachineWastageTable}
                            </div>
                        </div>
                    </div>

                    <hr />
                    {/* Production Shift Accordian */}
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="ProcessMapping">
                            <Accordion.Header className="erp-form-label-md">Production Summary</Accordion.Header>
                            <Accordion.Body>
                                <div className='row p-1'>
                                    <div className='col-sm-6 erp_form_col_div'>
                                        <div className='erp-form-label-lg'>Today's Production Summary</div>
                                        {rendertodaysprodsummary}
                                    </div>

                                    <div className='col-sm-6 erp_form_col_div'>
                                        <div className='erp-form-label-lg'>Up To Day's Production Summary</div>
                                        {renderuptodaysprodsummary}
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div className="erp_frm_Btns">
                        <MDButton className="erp-gb-button ms-2" variant="button" id='back_Button' fontWeight="regular" >Back</MDButton>
                        <MDButton type="submit" id="action_Button" className="erp-gb-button ms-2" variant="button"
                           fontWeight="regular">Save</MDButton>
                    </div >

                </div>
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

            </DashboardLayout >
        </>
    )
}

export default FrmProductionEntry