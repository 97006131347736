import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import $ from 'jquery';
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Accordion from "react-bootstrap/Accordion";
import { RxCrossCircled } from "react-icons/rx";

// File Imports
import ComboBox from "Features/ComboBox";
import DocumentF from "Features/Document";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import FrmMProductCategory1Entry from "Masters/MProductCategory1/FrmProductCategory1Entry";
import FrmHSNSAC from "Masters/MHSN-SAC/FrmHSN-SAC";
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import FrmProductUnitEntry from "Masters/MProductUnit/FrmProductUnitEntry";
import FrmMProductPackingEntry from "Masters/MProductPacking/FrmMProductPackingEntry";
import { MdRefresh } from "react-icons/md";
import FrmMSuppliersEntry from "../FrmMSuppliersEntry";
import FrmMProcessEntry from "../FrmMProcessEntry";
import FrmMProductCategory2Entry from "Masters/MProductCategory2/FrmMProductCategory2Entry";
import FrmMProductCategory3Entry from "Masters/MProductCategory3/FrmMProductCategory3Entry";
import FrmMProductCategory4Entry from "Masters/MProductCategory4/FrmMProductCategory4Entry";
import FrmMProductCategory5Entry from "Masters/MProductCategory5/FrmMProductCategory5Entry";
import FrmMProductMaterialTypeEntry from "Masters/MProductMaterialType/FrmMProductMaterialTypeEntry";
import FrmMProductMaterialGradeEntry from "Masters/MProductMaterialGrade/FrmMProductMaterialGradeEntry";
import FrmMProductMaterialShapeEntry from "Masters/MProductMaterialShape/FrmMProductMaterialShapeEntry";
import FrmValidations from "FrmGeneric/FrmValidations";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import { CircularProgress } from "@material-ui/core";
import FrmMQaMappingEntry from "../FrmMQaMappingEntry";
import FrmMProductMakeEntry from "Masters/MProductMake/FrmMProductMakeEntry";
import GenerateMaterialId from "FrmGeneric/GenerateMaterialId/GenerateMaterialId";

function FrmMGeneralEntry({ goToNext, rm_id }) {
  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, COMPANY_CATEGORY_COUNT } = configConstants;

  let docGroup = "Raw Material";
  //In listing page we passed in navigate
  const { state } = useLocation();
  const { productRmId = 0, keyForViewUpdate = 'Add', productTypeId, productRmName, category1Id, compType = 'Masters' } = state || {}

  // For navigate
  const navigate = useNavigate();

  // useRef hooks
  var validationRef = useRef();
  const comboDataAPiCall = useRef();
  var percentValidateRef = useRef();
  const generateMaterialIdAPiCall = useRef();

  // Combo Options
  const [productTypeOptions, setProductTypeOptions] = useState([])
  const [productCategory1Options, setProductCategory1Options] = useState([])
  const [hsnSacCodeOptions, setProductHsnSacCodeOptions] = useState([])
  const [unitOptions, setProductUnitOptions] = useState([])
  const [productPackingOptions, setProductPackingOptions] = useState([])
  const [consumtionModeOptions, setProductConsumtionModeOptions] = useState([])
  const [materialOriginOptions, setProductMaterialOriginOptions] = useState([])
  const [countryOptions, setCountryOptions] = useState([])
  const [godownidOptions, setgodownidOptions] = useState([])
  const [godownidSectionOptions, setgodownidSectionOptions] = useState([])
  const [godownsectionbeansidOptions, setgodownsectionbeansidOptions] = useState([])

  //  Form Fields for General Entry
  const [product_id, setProduct_id] = useState(productRmId === 0 && rm_id !== 0 ? rm_id : 0);
  const [product_rm_id, setProductRmId] = useState(productRmId === 0 && rm_id !== 0 ? rm_id : productRmId);
  const [cmb_product_type_id, setProductTypeId] = useState()

  const [cmb_product_category1_id, setProductCategory1Id] = useState('');
  const [txt_rm_code, setRmCode] = useState('');
  const [txt_rm_name, setRmName] = useState(productRmName);
  const [txt_rm_short_name, setRmShortName] = useState('');
  const [txt_rm_print_name, setRmPrintName] = useState('');
  const [txt_rm_tech_spect, setRmTechSpect] = useState('');
  const [txt_rm_oem_part_code, setOemPartCode] = useState('');
  const [txt_rm_our_part_code, setOurPartCode] = useState('');
  const [txt_rm_item_sr_no, setItemSrNo] = useState('1');
  const [txt_rm_drawing_no, setRmDrawingNo] = useState('');
  const [txt_rm_model_no, setRmModelNo] = useState('');
  const [cmb_rm_hsn_sac_code_id, setRmHsnSacCodeId] = useState('');
  const [cmb_rm_purchase_unit_id, setRmPurchaseUnitId] = useState(6);
  const [cmb_rm_sales_unit_id, setRmSalesUnitId] = useState(6);
  const [cmb_rm_stock_unit_id, setRmStockUnitId] = useState(6);
  const [cmb_rm_packing_id, setRmPackingUnitId] = useState(1);
  const [txt_rm_bar_code, setRmBarCode] = useState('');
  const [file_rm_qr_code, setRmQrCode] = useState();
  const [file_rm_qr_codeName, setRmQrCodePath] = useState();
  const [cmb_consumption_mode, setConsumptionMode] = useState('All');
  const [is_activeGen, setIsActiveGen] = useState(true);
  const [cmb_godown_id, setgodownid] = useState('');
  const [cmb_godown_section_id, setgodownsectionid] = useState('')
  const [cmb_godown_section_beans_id, setgodownsectionbeansid] = useState('')
  const [cmb_origin_type, setOriginType] = useState('Indigenous');
  const [cmb_origin_country, setOriginCountry] = useState('India');
  const [txt_remarkforGeneralEntry, setRemarkforGeneralEntry] = useState('');
  const [is_BOMApplicable, setBOMApplicable] = useState('No');


  // Data & Columns for General, Technical & Commercial Entry 
  const [supplierData, setSupplierData] = useState([]);
  const [supplierColumns, setSupplierColumns] = useState([]);

  const [processData, setProccessData] = useState([]);
  const [processColumns, setProcessColumns] = useState([]);

  const [Qadata, setQaMappingData] = useState([]);
  const [Qacolumns, setQaColumns] = useState([]);

  //  Form Fields for Commercial Entry
  const [product_rm_commercial_id, setProductRmCommercialId] = useState(0)
  const [txt_product_rm_gross_weight, setPGrossWeight] = useState(0);
  const [txt_product_rm_net_weight, setPNetWeight] = useState(0);
  const [txt_product_rm_std_weight, setPStdWeight] = useState(0);
  const [txt_product_rm_volume, setPVolume] = useState(0);
  const [txt_product_rm_mrp, setPMrp] = useState(1);
  const [txt_product_rm_landed_price, setPLandedPrice] = useState(1);
  const [txt_product_rm_avg_price, setPAvgPrice] = useState(1);
  const [txt_product_rm_std_profit_percent, setPStdProfitPercent] = useState(0);
  const [txt_product_rm_std_discount_percent, setPDiscountPercent] = useState(0);
  const [txt_product_rm_moq, setPmoq] = useState(0);
  const [txt_product_rm_mov, setPmov] = useState(0);
  const [txt_product_rm_eoq, setPeoq] = useState(0);
  const [txt_product_rm_mpq, setPmpq] = useState(0);
  const [txt_product_rm_min_stock_level, setPMinStockLevel] = useState(0);
  const [txt_product_rm_max_stock_level, setPMaxStockLevel] = useState(0);
  const [txt_product_rm_reorder_stock_level, setPReorderStockLevel] = useState(0);
  const [txt_product_rm_depriciation_percent, setPDepricationPercent] = useState(0);
  const [txt_purchase_tolerance_percent, setPTolerancePercent] = useState(0);
  const [txt_remarkforCommercialEntry, setRemarkforCommercialEntrty] = useState('');
  const [txt_lead_time, setLeadTime] = useState('2');
  const [is_activeCom, setIsActiveCom] = useState(true);
  const [is_testCertified, setIsTestCertified] = useState(false);
  const [is_qaRequired, setIsQARequired] = useState(false);
  const [is_priceType, setIsPriceType] = useState('Fixed');
  const [is_abcAnalysis, setIsABCanalysis] = useState('C');


  // Form Fields for Technical Entry 
  const [product_rm_technical_id, setProductRmTechnicalId] = useState(0)
  const [txt_product_rm_technical_name, setPTechnivalName] = useState('');
  const [cmb_product_category2_id, setPCategory2Id] = useState('');
  const [cmb_product_category3_id, setPCategory3Id] = useState('');
  const [cmb_product_category4_id, setPCategory4Id] = useState('');
  const [cmb_product_category5_id, setPCategory5Id] = useState('');
  const [cmb_product_make_id, setPMakeId] = useState('');
  const [cmb_product_material_type_id, setPMaterialTypeId] = useState('');
  const [cmb_product_material_grade_id, setPMaterialGradeId] = useState('');
  const [cmb_product_material_shape_id, setPMaterialShapeId] = useState('');
  const [cmb_product_material_colour, setPMaterialColour] = useState('NA');
  const [cmb_product_alternate_rm_id, setPAlternateId] = useState('');
  const [txt_assembly_scrap_percent, setAssemblyScrapPercent] = useState(0);
  const [txt_remarkforTechnicalEntry, setRemarkforTechnicalEntry] = useState('');
  const [is_activeTech, setIsActiveTech] = useState(true);


  // Option Box for Technical Entry
  const [category2Options, setCategory2Options] = useState([])
  const [category3Options, setCategory3Options] = useState([])
  const [category4Options, setCategory4Options] = useState([])
  const [category5Options, setCategory5Options] = useState([])
  const [pMakeOptions, setPMakeOptions] = useState([])
  const [pMaterialTypeOptions, setPMaterialTypeOptions] = useState([])
  const [pMaterialGradeOptions, setPMaterialGradeOptions] = useState([])
  const [pMaterialShapeOptions, setPMaterialShapeOptions] = useState([])
  const [pMaterialColorOptions, setPMaterialColorOptions] = useState([])
  const [pAlternateMaterialOptions, setPAlternateMaterialOptions] = useState([])


  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  //For Delete Operation
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // Success Msg HANDLING
 const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    moveToListing()
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  // Document Form
  const [showDocumentForm, setShowDocumentForm] = useState(false);
  const handleCloseDocumentForm = () => setShowDocumentForm(false);
  const viewDocumentForm = () => setShowDocumentForm(true);

  // Loader
  const [isLoading, setIsLoading] = useState(false);

  // Show ADd record Modal
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {
      case 'Product Type':
        // const productTypeApiCall = await comboDataAPiCall.current.fillMasterDataWithOperator("smv_product_type", "product_type_short_name", "NOT IN", "()")
        // setProductTypeOptions(productTypeApiCall)

        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.columns.push("product_type_short_name");
        globalQuery.table = "smv_product_type"
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        // globalQuery.conditions.push({ field: "product_type_short_name", operator: "NOT IN", values: ['FG', 'BR'] });
        globalQuery.conditions.push({ field: "product_type_group", operator: "=", value: 'RM' });
        const product_Types = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
        setProductTypeOptions(product_Types)
        break;

      case 'Product Category1':
        comboOnChange('productType')
        break;
      case 'HSN-SAC':
        const HsnSacCodeApiCall = await comboDataAPiCall.current.fillMasterData("cmv_hsn_sac", "", "")
        setProductHsnSacCodeOptions(HsnSacCodeApiCall)
        break;

      case 'Packing':
        const productPackingApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_packing", "", "")
        setProductPackingOptions(productPackingApiCall)
        break;

      case 'Purchase Unit':
        const PurchaseUnitApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_unit", "", "")
        setProductUnitOptions(PurchaseUnitApiCall)
        break;

      case 'Sales Unit':
        const SalesUnitApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_unit", "", "")
        setProductUnitOptions(SalesUnitApiCall)

        break;

      case 'Stock Unit':
        const StockUnitApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_unit", "", "")
        setProductUnitOptions(StockUnitApiCall)
        break;
      case 'Material Type':
        // Product Material Type
        let productMaterialList = await comboDataAPiCall.current.fillMasterData("smv_product_material_type", "product_type_id", productTypeId)
        setPMaterialTypeOptions(productMaterialList)
        break;

      case 'Material Grade':
        comboOnChange('productMaterialType')
        break;

      case 'Material Shape':
        // Product Material Shape
        let productMaterialShapeList = await comboDataAPiCall.current.fillMasterData("smv_product_material_shape", "product_type_id", productTypeId)
        setPMaterialShapeOptions(productMaterialShapeList)
        break;
      case 'Product Category-2':
        comboOnChange('productCategory1')
        break;
      case 'Product Category-3':
        comboOnChange('productCategory2')
        break;
      case 'Product Category-4':
        comboOnChange('productCategory3')
        break;
      case 'Product Category-5':
        comboOnChange('productCategory4')
        break;
      case 'Product Make':
        // Product Make Fill
        var productMakeList = await comboDataAPiCall.current.fillMasterData("smv_product_make", "product_type_id", productTypeId)
        setPMakeOptions(productMakeList)
        break;

      default:
        break;
    }

    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)

  }
  const [showAddRecModal, setShowAddRecModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('')

  //hooks of update||View data onclick of accordian
  const [supplierMappingData, setSupplierMappingData] = useState([]);
  const [processMappingData, setProcessMappingData] = useState([]);
  const [qaMappingData, setQAMappingData] = useState([]);



  useEffect(async () => {
    setIsLoading(true);
    await fillComobos();
    //changes by tushar
    if (productRmId !== 0 && productRmId !== undefined) {
      await FnCheckUpdateResponceforGeneralEntry();
    }

    setIsLoading(false);
  }, [])


  const fillComobos = async () => {
    try {

      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.columns.push("product_type_short_name");
      globalQuery.table = "smv_product_type"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "product_type_group", operator: "=", value: 'RM' });

      const product_Types = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
      setProductTypeOptions(product_Types)

      // changes by tushar
      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "cmv_hsn_sac"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
      globalQuery.conditions.push({ field: "hsn_sac_type", operator: "=", value: "HSN" });
      const HsnSacCodeApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
      setProductHsnSacCodeOptions(HsnSacCodeApiCall)

      const productUnitApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_unit", "", "")
      setProductUnitOptions(productUnitApiCall)

      const productPackingApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_packing", "", "")
      setProductPackingOptions(productPackingApiCall)

      const consumtionModeApiCall = await comboDataAPiCall.current.fillComboBox("MaterialConsumptionMode")
      setProductConsumtionModeOptions(consumtionModeApiCall)

      const originApiCall = await comboDataAPiCall.current.fillComboBox("MaterialOrigin")
      setProductMaterialOriginOptions(originApiCall)

      const countryApiCall = await comboDataAPiCall.current.fillMasterData("cmv_country", "", "")
      setCountryOptions(countryApiCall)

      resetGlobalQuery();
      globalQuery.columns = ["field_id", "field_name"]
      globalQuery.table = "cmv_godown"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      const godownidApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
      setgodownidOptions(godownidApiCall)
      if (keyForViewUpdate === "") {
        setgodownid(1);
        await comboOnChange('godown')
        await comboOnChange('godownsection')
      }



      // fillcombos for Technical Entry Starts here

      // categoryCount = sessionStorage.getItem('compRMCatCount')
      for (let count = 2; count <= COMPANY_CATEGORY_COUNT; count++) {
        $('#product_category' + count + '_id').show()
      }

      // Category label display
      resetGlobalQuery();
      globalQuery.columns.push("property_value");
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'RMCategorySettings' });
      globalQuery.table = "amv_properties"
      var labelList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
      // Display category labels
      if (labelList.length !== 0) {
        for (let count = 1; count < COMPANY_CATEGORY_COUNT; count++) {
          $('#cat' + count + 'Label').append(labelList[count].property_value)
        }
      } else {
        for (let count = 1; count <= COMPANY_CATEGORY_COUNT; count++) {
          $('#cat' + count + 'Label').append('RM Category ' + [count] + ' :')
          $('#cat' + count + 'Label').append('RM Category ' + [count] + ' :')
        }
      }



      // Category 2 list
      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTypeId });
      globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: category1Id });
      globalQuery.table = "smv_product_category2"
      var masterList2 = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
      setCategory2Options(masterList2)
      console.log("masterList2: ", masterList2)

      // Alternate material ids
      debugger
      resetGlobalQuery();
      globalQuery.columns.push("product_alternate_rm_name");
      globalQuery.columns.push("product_alternate_rm_id");
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTypeId });
      globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: category1Id }); //yogesh disccuss
      globalQuery.table = "smv_product_rm_details"
      var pAltrRMIds = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
      setPAlternateMaterialOptions(pAltrRMIds)
      // Product Material Color 
      var productMaterialColorList = await comboDataAPiCall.current.fillComboBox("Colour")
      setPMaterialColorOptions(productMaterialColorList)
      if (productTypeId !== "" && productTypeId !== "0" && productRmId !== undefined) {
        await FnGetProductTypeDependentCombo(productTypeId);
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  //Fn for dependent on product Type
  const FnGetProductTypeDependentCombo = async (productTypeId) => {
    // Product Make Fill
    var productMakeList = await comboDataAPiCall.current.fillMasterData("smv_product_make", "product_type_id", productTypeId)
    setPMakeOptions(productMakeList)
    //changes by tushar
    setPMakeId(6);


    // Product Material Type
    var productMaterialList = await comboDataAPiCall.current.fillMasterData("smv_product_material_type", "product_type_id", productTypeId)
    setPMaterialTypeOptions(productMaterialList)

    // Product Material Shape
    var productMaterialShapeList = await comboDataAPiCall.current.fillMasterData("smv_product_material_shape", "product_type_id", productTypeId)
    setPMaterialShapeOptions(productMaterialShapeList)

  }
  const FnCheckUpdateResponceforGeneralEntry = async () => {
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productRm/FnShowAllProductRmSummaryDetailsAndMasterRecords/${product_rm_id}/${COMPANY_ID}`)
      const updateRes = await apiCall.json();

      let generalEntryData = updateRes.ProductRmDetailsRecords;
      let technicalEntryData = updateRes.ProductRmmaterialTechnicalRecords;
      let commercialEntryData = updateRes.ProductRmmaterialCommercialRecords;
      let supplierMappingData = updateRes.ProductRmmaterialSupplierRecords;
      let processMappingData = updateRes.ProductRmmaterialProcessRecords;
      let qaMappingData = updateRes.ProductRmmaterialQaMappingRecords;
      setSupplierMappingData(supplierMappingData)
      setProcessMappingData(processMappingData)
      setQAMappingData(qaMappingData)

      // General Entry Data
      let { product_id, product_type_id, product_category1_id, product_rm_code,
        product_rm_name, product_rm_short_name, product_rm_print_name, product_rm_tech_spect, product_rm_oem_part_code,
        product_rm_our_part_code, product_rm_item_sr_no, product_rm_drawing_no, product_rm_model_no, product_rm_hsn_sac_code_id,
        product_rm_purchase_unit_id, product_rm_sales_unit_id, product_rm_stock_unit_id, product_rm_packing_id,
        product_rm_bar_code, bom_applicable, product_rm_qr_code, product_consumption_mode, product_origin_type, godown_id, godown_section_id,
        godown_section_beans_id, product_origin_country, remark, is_active } = generalEntryData;

      setProduct_id(product_id)
      setProductTypeId(product_type_id)
      await comboOnChange('productType')
      setProductCategory1Id(product_category1_id)
      //changes by tushar
      await comboOnChange('productCategory1')
      setRmCode(product_rm_code)
      setRmName(product_rm_name)
      setRmShortName(product_rm_short_name)
      setRmPrintName(product_rm_print_name)
      setRmTechSpect(product_rm_tech_spect)
      setOemPartCode(product_rm_oem_part_code)
      setOurPartCode(product_rm_our_part_code)
      setItemSrNo(product_rm_item_sr_no)
      setRmDrawingNo(product_rm_drawing_no)
      setRmModelNo(product_rm_model_no)
      setRmHsnSacCodeId(product_rm_hsn_sac_code_id)
      // await comboOnChange('hsnSacCode')
      setRmPurchaseUnitId(product_rm_purchase_unit_id)
      setRmSalesUnitId(product_rm_sales_unit_id)
      setRmStockUnitId(product_rm_stock_unit_id)
      setRmPackingUnitId(product_rm_packing_id)
      setRmBarCode(product_rm_bar_code)
      setBOMApplicable(bom_applicable)
      setRmQrCodePath(product_rm_qr_code)
      setConsumptionMode(product_consumption_mode)
      setgodownid(godown_id)
      await comboOnChange('godown')
      setgodownsectionid(godown_section_id)
      await comboOnChange('godownsection')
      setgodownsectionbeansid(godown_section_beans_id)
      setOriginType(product_origin_type)
      setOriginCountry(product_origin_country)
      setRemarkforGeneralEntry(remark)
      setIsActiveGen(is_active)

      //Technical Entry Data
      let { product_rm_technical_id, product_rm_technical_name,
        product_category2_id, product_category3_id, product_category4_id, product_category5_id, product_make_id,
        product_material_type_id, product_material_grade_id, product_material_shape_id, product_material_colour,
        product_alternate_rm_id, assembly_scrap_percent } = technicalEntryData;

      setProductRmTechnicalId(product_rm_technical_id)
      setPTechnivalName(product_rm_technical_name)
      setPCategory2Id(product_category2_id)
      if (product_category2_id !== 0) {
        await comboOnChange('productCategory2')
      }
      setPCategory3Id(product_category3_id)
      await comboOnChange('productCategory3')
      setPCategory3Id(product_category3_id)
      if (product_category3_id !== 0) {
        await comboOnChange('productCategory3')
      }
      setPCategory4Id(product_category4_id)
      if (product_category4_id !== 0) {
        await comboOnChange('productCategory4')
      }
      setPCategory5Id(product_category5_id)
      setPMakeId(product_make_id)
      setPMaterialTypeId(technicalEntryData.product_material_type_id)
      if (technicalEntryData.product_material_type_id !== 0) {
        await comboOnChange('productMaterialType')
      }
      setPMaterialGradeId(product_material_grade_id)
      await comboOnChange('productMaterialGrade')
      setPMaterialShapeId(product_material_shape_id)
      setPMaterialColour(product_material_colour)
      setPAlternateId(product_alternate_rm_id)
      setAssemblyScrapPercent(assembly_scrap_percent)
      setRemarkforTechnicalEntry(technicalEntryData.remark)
      setIsActiveCom(technicalEntryData.is_active)

      // Commercial Data Entry
      let { product_rm_commercial_id, product_rm_gross_weight, product_rm_net_weight,
        product_rm_std_weight, product_rm_volume, product_rm_mrp, product_rm_landed_price, product_rm_avg_price, product_rm_std_profit_percent,
        product_rm_std_discount_percent, lead_time, product_rm_moq, product_rm_mov, product_rm_eoq, product_rm_mpq,
        product_rm_min_stock_level, product_rm_max_stock_level, product_rm_reorder_stock_level, product_rm_depriciation_percent, purchase_tolerance_percent,
        product_rm_price_type, product_rm_abc_analysis, qa_required, test_certificate_required } = commercialEntryData;

      setProductRmCommercialId(product_rm_commercial_id)
      setPGrossWeight(product_rm_gross_weight)
      setPNetWeight(product_rm_net_weight)
      setPStdWeight(product_rm_std_weight)
      setPVolume(product_rm_volume)
      setPMrp(product_rm_mrp)
      setPLandedPrice(product_rm_landed_price)
      setPAvgPrice(product_rm_avg_price)
      setPStdProfitPercent(product_rm_std_profit_percent)
      setPDiscountPercent(product_rm_std_discount_percent)
      setLeadTime(lead_time)
      setPmoq(product_rm_moq)
      setPmov(product_rm_mov)
      setPeoq(product_rm_eoq)
      setPmpq(product_rm_mpq)
      setPMinStockLevel(product_rm_min_stock_level)
      setPMaxStockLevel(product_rm_max_stock_level)
      setPReorderStockLevel(product_rm_reorder_stock_level)
      setPDepricationPercent(product_rm_depriciation_percent)
      setPTolerancePercent(purchase_tolerance_percent)
      setRemarkforCommercialEntrty(commercialEntryData.remark)
      setIsActiveTech(commercialEntryData.is_active)
      setIsQARequired(qa_required)
      setIsTestCertified(test_certificate_required)
      setIsPriceType(product_rm_price_type)
      setIsABCanalysis(product_rm_abc_analysis)

      //Updating Supplier CheckBoxes
      $("input:checkbox[name=checkSupplier]").each(function () {
        const checkboxValue = parseInt($(this).val());
        const orderNoIndex = supplierMappingData.findIndex((item) => item.supplier_id === checkboxValue)
        if (orderNoIndex !== -1) {
          $(this).prop('checked', true);
        }
      });

      //Updating Process CheckBoxes
      $("input:checkbox[name=checkProcess]").each(function () {
        const checkboxValue = parseInt($(this).val());
        const orderNoIndex = processMappingData.findIndex((item) => item.product_process_id === checkboxValue)
        if (orderNoIndex !== -1) {
          $(this).prop('checked', true);
        }
      });

      //Updating QA checkBoxes
      $("input:checkbox[name=checkQaMapp]").each(function () {
        const checkboxValue = parseInt($(this).val());
        const orderNoIndex = qaMappingData.findIndex((item) => item.product_qa_parameters_id === checkboxValue)
        if (orderNoIndex !== -1) {
          $(this).prop('checked', true);
        }
      });

      switch (keyForViewUpdate) {
        case 'update':
          $('#product_rm_id').attr('readOnly', true)
          $('#txt_rm_name').attr('readOnly', true)
          $('#txt_product_rm_technical_name').attr('readOnly', true)
          $('#cmb_product_type_id').attr('disabled', true)
          $('#cmb_product_category1_id').attr('disabled', true)
          $('#cmb_product_category2_id').attr('disabled', true)
          $('#cmb_product_category3_id').attr('disabled', true)
          $('#cmb_product_category4_id').attr('disabled', true)
          $('#cmb_product_category5_id').attr('disabled', true)
          $('#txt_product_rm_technical_name').attr('readOnly', true)
          $('#txt_rm_short_name').attr('readOnly', true)
          $('#btn_save').text("Update");
          break;

        case 'view':
          $("#btn_update").attr('disabled', true);
          $("input[type=radio]").attr('disabled', true);
          $('#btn_save').hide();
          await addReadOnlyAttr();
          break;

        case 'delete':
          $('#btn_save').hide();
          $('#btn_doc_upload').hide();
          break;
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  }


  const FnGetMappingsData = async (accordianSelectKey) => {
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productRm/FnShowAllRecords/${COMPANY_ID}?accordianSelectKey=${accordianSelectKey}`)
      const response = await apiCall.json();
      let supplierRecords = response.SupplierAllRecords;
      let processRecords = response.ProductRmmaterialProcessRecords;
      let qaParametersRecords = response.ProductQaParametersAllRecords;

      if (supplierRecords !== undefined) {
        //Supplier Records
        let suppColumnHeads = Object.keys(supplierRecords[0])
        let tempSupplierColumns = [];
        for (let colKey = 0; colKey < suppColumnHeads.length; colKey++) {
          if (colKey === 0) {
            tempSupplierColumns.push({
              Headers: "Action",
              accessor: "Action",
              Cell: row => (
                <div style={{ display: "flex" }}>
                  <input type='checkbox' name="checkSupplier" id={'checkSupplierId_' + row.row.original.supplier_id}
                    value={row.row.original.supplier_id} onClick={() => handleSupplierCheckbox(row.row.original.supplier_id)}>
                  </input>
                </div>
              ),
            });
          }
          if (!suppColumnHeads[colKey].includes('_id') && !suppColumnHeads[colKey].includes('field_name') && !suppColumnHeads[colKey].includes('is_') && !suppColumnHeads[colKey].includes('_on') && !suppColumnHeads[colKey].includes('_by') && !suppColumnHeads[colKey].includes('company_') && !suppColumnHeads[colKey].includes('supplier_sector')
            && !suppColumnHeads[colKey].includes('supplier_short_name') && !suppColumnHeads[colKey].includes('supplier_type') && !suppColumnHeads[colKey].includes('nature_of_business') && !suppColumnHeads[colKey].includes('supplier_gl_codes') && !suppColumnHeads[colKey].includes('supplier_gl_codes')
            && !suppColumnHeads[colKey].includes('supplier_history') && !suppColumnHeads[colKey].includes('supp_branch_short_name') && !suppColumnHeads[colKey].includes('supp_branch_address1') && !suppColumnHeads[colKey].includes('supp_branch_address2') && !suppColumnHeads[colKey].includes('supp_branch_pincode')
            && !suppColumnHeads[colKey].includes('district_name') && !suppColumnHeads[colKey].includes('country_name') && !suppColumnHeads[colKey].includes('supp_branch_region')
            && !suppColumnHeads[colKey].includes('supp_branch_linkedin_profile') && !suppColumnHeads[colKey].includes('supp_branch_facebook_profile') && !suppColumnHeads[colKey].includes('supp_branch_twitter_profile')
            && !suppColumnHeads[colKey].includes('supp_branch_gst_division') && !suppColumnHeads[colKey].includes('supp_branch_gst_range') && !suppColumnHeads[colKey].includes('supp_branch_pan_no')
            && !suppColumnHeads[colKey].includes('supp_branch_udyog_adhar_no') && !suppColumnHeads[colKey].includes('supp_branch_vat_no') && !suppColumnHeads[colKey].includes('supp_branch_service_tax_no') && !suppColumnHeads[colKey].includes('supp_branch_excise_no') && !suppColumnHeads[colKey].includes('supp_branch_cst_no')
            && !suppColumnHeads[colKey].includes('supp_branch_bst_no') && !suppColumnHeads[colKey].includes('supp_branch_rating') && !suppColumnHeads[colKey].includes('supp_branch_gl_codes')
            && !suppColumnHeads[colKey].includes('sez_name') && !suppColumnHeads[colKey].includes('supplier_username') && !suppColumnHeads[colKey].includes('supplier_password') && !suppColumnHeads[colKey].includes('remark') && !suppColumnHeads[colKey].includes('active') && !suppColumnHeads[colKey].includes('deleted')) {
            tempSupplierColumns.push({ Headers: suppColumnHeads[colKey], accessor: suppColumnHeads[colKey] });
          }
        }
        setSupplierColumns(tempSupplierColumns)
        setSupplierData(supplierRecords)
      }
      if (processRecords !== undefined) {
        //Process Records
        var proccColumn = [];
        let proccColumnHeads = Object.keys(processRecords[0]);
        console.log("column heads: ", processRecords)
        for (let colKey = 0; colKey < proccColumnHeads.length; colKey++) {
          if (colKey == 0) {
            proccColumn.push({
              Headers: "Action",
              accessor: "Action",
              Cell: row => (
                <div style={{ display: "flex" }}>
                  <input type='checkbox' name="checkProcess" id={'checkProcessId_' + row.row.original.product_process_id}
                    value={row.row.original.product_process_id} onClick={() => handleProcessCheckbox(row.row.original.product_process_id)}>
                  </input>
                </div>
              ),
            });
          }
          if (!proccColumnHeads[colKey].includes('_id') && !proccColumnHeads[colKey].includes('field_name') && !proccColumnHeads[colKey].includes('is_') && !proccColumnHeads[colKey].includes('_on') && !proccColumnHeads[colKey].includes('_by') && !proccColumnHeads[colKey].includes('company_')
            && !proccColumnHeads[colKey].includes('product_process_std_scrap_percent') && !proccColumnHeads[colKey].includes('product_process_std_production_hrs') && !proccColumnHeads[colKey].includes('remark') && !proccColumnHeads[colKey].includes('active') && !proccColumnHeads[colKey].includes('deleted')) {
            proccColumn.push({ Headers: proccColumnHeads[colKey], accessor: proccColumnHeads[colKey] });
          }
        }
        setProcessColumns(proccColumn)
        setProccessData(processRecords)
      }
      if (qaParametersRecords !== undefined) {
        //QA Data
        var qaMappColumn = [];
        let qaMappColumnHeads = Object.keys(qaParametersRecords[0]);
        console.log("column heads: ", qaParametersRecords)
        for (let colKey = 0; colKey < qaMappColumnHeads.length; colKey++) {
          if (colKey == 0) {
            qaMappColumn.push({
              Headers: "Action",
              accessor: "Action",
              Cell: row => (
                <div style={{ display: "flex" }}>
                  <input type='checkbox' name="checkQaMapp" id={'checkQaMappId_' + row.row.original.product_qa_parameters_id}
                    value={row.row.original.product_qa_parameters_id} onClick={() => handleqaMappingCheckbox(row.row.original.product_qa_parameters_id)}>
                  </input>
                </div>
              ),
            });
          }
          if (!qaMappColumnHeads[colKey].includes('_id') && !qaMappColumnHeads[colKey].includes('field_name') && !qaMappColumnHeads[colKey].includes('is_') && !qaMappColumnHeads[colKey].includes('_on') && !qaMappColumnHeads[colKey].includes('_by') && !qaMappColumnHeads[colKey].includes('company_') && !qaMappColumnHeads[colKey].includes('deleted') && !qaMappColumnHeads[colKey].includes('active') && !qaMappColumnHeads[colKey].includes('remark')) {
            qaMappColumn.push({ Headers: qaMappColumnHeads[colKey], accessor: qaMappColumnHeads[colKey] });
          }
        }
        setQaColumns(qaMappColumn)
        setQaMappingData(qaParametersRecords)
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  const FnGenerateMaterialId = async (productTpVal, ptShortName) => {
    const autoNoApiCall = await generateMaterialIdAPiCall.current.GenerateCode("sm_product_rm", "product_rm_id", 'product_type_id', productTpVal, ptShortName, "4");
    setProductRmId(autoNoApiCall);
    return autoNoApiCall;
  }

  const FnGenerateTechSpecName = async () => {
    let productTypeShortName = document.getElementById("cmb_product_type_id").options[document.getElementById("cmb_product_type_id").selectedIndex].getAttribute('ptShortName');
    let productTpText = productTypeShortName !== null ? productTypeShortName : "";
    let productCat1Text = document.getElementById("cmb_product_category1_id").options[document.getElementById("cmb_product_category1_id").selectedIndex].text;
    let productCat2Text = document.getElementById("cmb_product_category2_id").options[document.getElementById("cmb_product_category2_id").selectedIndex].text;
    let productCat3Text = document.getElementById("cmb_product_category3_id").options[document.getElementById("cmb_product_category3_id").selectedIndex].text;
    let productCat4Text = document.getElementById("cmb_product_category4_id").options[document.getElementById("cmb_product_category4_id").selectedIndex].text;

    let productCat5Text = document.getElementById("cmb_product_category5_id").options[document.getElementById("cmb_product_category5_id").selectedIndex].text;
    let productMakeText = document.getElementById("cmb_product_make_id").options[document.getElementById("cmb_product_make_id").selectedIndex].text;
    let productMaterialGradeText = document.getElementById("cmb_product_material_grade_id").options[document.getElementById("cmb_product_material_grade_id").selectedIndex].text;

    const autoTextApiCall = await generateMaterialIdAPiCall.current.GenerateTechnicalSpecName(productTpText, productCat1Text, productCat2Text, productCat3Text,
      productCat4Text, productCat5Text, productMakeText, productMaterialGradeText);
    setPTechnivalName(autoTextApiCall);
  }

  const comboOnChange = async (key) => {
    try {
      switch (key) {
        case 'productType':
          var productTpVal = document.getElementById('cmb_product_type_id').value;
          setProductCategory1Options([])
          setCategory2Options([])
          setCategory3Options([])
          setCategory4Options([])
          setCategory5Options([])

          setProductTypeId(productTpVal)

          if (productTpVal === '0') {
            setProductTypeId(productTpVal)
            setProductRmId(0)
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Product Type')
            setShowAddRecModal(true)
            setProductCategory1Options([])
            setTimeout(() => {
              $(".erp_top_Form").css({
                "padding-top": "0px"
              });
            }, 200)
          } else {
            if (productTpVal !== "") {
              $('#error_cmb_product_type_id').hide();
              const shortName = document.getElementById("cmb_product_type_id").options[document.getElementById("cmb_product_type_id").selectedIndex].getAttribute('ptShortName')
              localStorage.setItem('ptShortName', shortName)
              if (productRmId === 0 || productRmId === undefined) {
                await FnGenerateMaterialId(productTpVal, shortName);
                await FnGetProductTypeDependentCombo(productTpVal);
              }
              // Product altr material list
              resetGlobalQuery();
              globalQuery.columns.push("product_rm_id");
              globalQuery.columns.push("product_rm_name");
              globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
              globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
              globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTypeId });
              globalQuery.table = "smv_product_rm_details";

              var pAltrRMIds = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
              setPAlternateMaterialOptions(pAltrRMIds)
            } else {
              setProductRmId(0)
              setPMakeOptions([])
              setPMaterialTypeOptions([])
              setPMaterialGradeOptions([])
              setPMaterialShapeOptions([])
            }
            // if (productRmId === 0) {
            //   FnGenerateTechSpecName();
            // }
            FnGenerateTechSpecName();
            const productCategory1ApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_category1", "product_type_id", productTpVal)
            setProductCategory1Options(productCategory1ApiCall)
          }
          break;

        case 'productCategory1':
          var productCat1Val = document.getElementById('cmb_product_category1_id').value;
          setCategory2Options([])
          setCategory3Options([])
          setCategory4Options([])
          setCategory5Options([])
          if (productCat1Val === '0') {
            setProductCategory1Id(productCat1Val)
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Product Category1')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").css({ "padding-top": "0px" });
            }, 200)
          } else {
            setProductCategory1Id(productCat1Val)
            if (productCat1Val !== "") {

              $('#error_cmb_product_category1_id').hide();
              const productCategory2ApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_category2", "product_category1_id", productCat1Val)
              setCategory2Options(productCategory2ApiCall)

              // Product altr material list
              resetGlobalQuery();
              globalQuery.columns.push("product_rm_id");
              globalQuery.columns.push("product_rm_name");
              globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
              globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
              globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTypeId });
              globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: category1Id });
              globalQuery.table = "smv_product_rm_details";

              var pAltrRMIds = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
              setPAlternateMaterialOptions(pAltrRMIds)
            } else {
              setPCategory2Id('')
              setPCategory3Id('')
              setPCategory4Id('')
              setPCategory5Id('')
            }
            setTimeout(() => {
              FnGenerateTechSpecName();
            }, 200);


          }
          break;

        case 'hsnSacCode':
          var productHsnSacCode = document.getElementById('cmb_rm_hsn_sac_code_id').value;
          if (productHsnSacCode === '0') {
            setRmHsnSacCodeId(productHsnSacCode)
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('HSN-SAC')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").css({ "padding-top": "0px" });
            }, 200)
          } else {
            setRmHsnSacCodeId(productHsnSacCode)
            if (productCat1Val !== "") { $('#error_cmb_rm_hsn_sac_code_id').hide(); }
          }
          break;

        case 'puchaseUnit':
          var productPurchaseUnitId = document.getElementById('cmb_rm_purchase_unit_id').value;
          setRmPurchaseUnitId(productPurchaseUnitId)
          if (productPurchaseUnitId !== "") { $('#error_cmb_rm_purchase_unit_id').hide(); }

          if (productPurchaseUnitId === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Puchase Unit')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").css({ "padding-top": "0px" });
            }, 200)
          }
          break;

        case 'salesUnit':
          var productSalesUnitId = document.getElementById('cmb_rm_sales_unit_id').value;
          setRmSalesUnitId(productSalesUnitId)
          if (productSalesUnitId !== "") { $('#error_cmb_rm_sales_unit_id').hide(); }
          if (productSalesUnitId === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Sales Unit')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").css({ "padding-top": "0px" });
            }, 200)
          }

          break;

        case 'stockUnit':
          var productStockUnitId = document.getElementById('cmb_rm_stock_unit_id').value;
          setRmStockUnitId(productStockUnitId)
          if (productStockUnitId !== "") { $('#error_cmb_rm_stock_unit_id').hide(); }

          if (productStockUnitId === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Stock Unit')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").css({ "padding-top": "0px" });
            }, 200)
          }
          break;

        case 'packingId':
          var productPackingId = document.getElementById('cmb_rm_packing_id').value;
          setRmPackingUnitId(productPackingId)
          if (productPackingId !== "") { $('#error_cmb_rm_packing_id').hide(); }
          if (productPackingId === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Packing')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(1).css("padding-top", "0px");
            }, 200)
          }
          break;

        case 'consumptionMode':
          var productConsumptionModeId = document.getElementById('cmb_consumption_mode').value;
          setConsumptionMode(productConsumptionModeId)
          if (productConsumptionModeId !== "") { $('#error_cmb_consumption_mode').hide(); }
          break;

        case 'materialOrigin':
          var productOriginTypeId = document.getElementById('cmb_origin_type').value;
          setOriginType(productOriginTypeId)
          if (productOriginTypeId !== "") { $('#error_cmb_origin_type').hide(); }
          break;

        case 'originCountry':
          var countryId = document.getElementById('cmb_origin_country').value;
          setOriginCountry(countryId)
          if (countryId !== "") { $('#error_cmb_origin_country').hide(); }
          break;

        case 'godown':
          var godownval = document.getElementById('cmb_godown_id').value;
          setgodownid(godownval)
          if (godownval === '0') {
            const newTab = window.open('/Masters/Godown', '_blank');
            if (newTab) {
              newTab.focus();
            }
          }
          const godownidSectionApiCall = await comboDataAPiCall.current.fillMasterData("cmv_godown_section", "godown_id", godownval)
          setgodownsectionid('');
          setgodownidSectionOptions(godownidSectionApiCall)
          setgodownsectionbeansidOptions([])
          setgodownsectionbeansid('')
          //changes by tushar
          if (keyForViewUpdate === "") {
            if (godownval === "1") {
              setgodownsectionid(1);
            }
          }
          break;


        case 'godownsection':
          var godownval = document.getElementById('cmb_godown_id').value;
          var godownsectionval = document.getElementById('cmb_godown_section_id').value;
          console.log(godownsectionval);
          setgodownsectionid(godownsectionval)

          if (godownsectionval === '0') {
            const newTab = window.open('/Masters/Godown', '_blank');
            if (newTab) {
              newTab.focus();
            }
          }

          resetGlobalQuery();
          globalQuery.columns = ["field_id", "field_name"]
          globalQuery.table = "cmv_godown_section_beans"
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          globalQuery.conditions.push({ field: "godown_id", operator: "=", value: godownval });
          globalQuery.conditions.push({ field: "godown_section_id", operator: "=", value: godownsectionval });
          const godownidSectionbeansApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
          setgodownsectionbeansidOptions(godownidSectionbeansApiCall)
          if (keyForViewUpdate === "") {
            if (godownsectionval !== 1) {
              setgodownsectionbeansid(1)
            }
          }


          // const godownidSectionbeansApiCall = await comboDataAPiCall.current.fillMasterData("cmv_godown_section_beans", "", godownsectionval)
          // setgodownsectionbeansidOptions(godownidSectionbeansApiCall)
          // setgodownsectionbeansid('')
          break;

        case 'godownsectionbeans':
          var godownsectionbeansval = document.getElementById('cmb_godown_section_beans_id').value;
          setgodownsectionbeansid(godownsectionbeansval)

          if (godownsectionbeansval === '0') {
            const newTab = window.open('/Masters/Godown', '_blank');
            if (newTab) {
              newTab.focus();
            }
          }
          break;
        case 'productCategory2':
          let productCat2Val = document.getElementById('cmb_product_category2_id').value;
          setPCategory2Id(productCat2Val)

          setCategory3Options([])
          setCategory4Options([])
          setCategory5Options([])
          if (productCat2Val === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Product Category-2')
            setShowAddRecModal(true)
            setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
          } else {
            const productCat3ApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_category3", "product_category2_id", productCat2Val)
            setCategory3Options(productCat3ApiCall)

            // Product altr material list
            resetGlobalQuery();
            globalQuery.columns.push("product_rm_id");
            globalQuery.columns.push("product_rm_name");
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
            globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTypeId });
            globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: category1Id });
            globalQuery.conditions.push({ field: "product_category2_id", operator: "=", value: productCat2Val });
            globalQuery.table = "smv_product_rm_details";

            var pAltrRMIds = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setPAlternateMaterialOptions(pAltrRMIds)

            FnGenerateTechSpecName();
          }
          break;

        case 'productCategory3':
          let productCat3Val = document.getElementById('cmb_product_category3_id').value;

          setCategory4Options([])
          setCategory5Options([])
          if (productCat3Val === '0') {
            setPCategory3Id(productCat3Val)
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Product Category-3')
            setShowAddRecModal(true)
            setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
          } else {
            setPCategory3Id(productCat3Val)

            // Product category 4 list
            const productCat4ApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_category4", "product_category3_id", productCat3Val)
            setCategory4Options(productCat4ApiCall)


            // Product altr material list
            resetGlobalQuery();
            globalQuery.columns.push("product_rm_id");
            globalQuery.columns.push("product_rm_name");
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
            globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTypeId });
            globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: category1Id });
            globalQuery.conditions.push({ field: "product_category2_id", operator: "=", value: $('#cmb_product_category2_id').val() });
            globalQuery.conditions.push({ field: "product_category3_id", operator: "=", value: productCat3Val });
            globalQuery.table = "smv_product_rm_details";

            var pAltrRMIds = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setPAlternateMaterialOptions(pAltrRMIds)
            // if (productRmId === 0) {
            //   FnGenerateTechSpecName();
            // }
            FnGenerateTechSpecName();
          }
          break;

        case 'productCategory4':
          var productCat4Val = document.getElementById('cmb_product_category4_id').value;
          setCategory5Options([])

          if (productCat4Val === '0') {
            setPCategory4Id(productCat4Val)
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Product Category-4')
            setShowAddRecModal(true)
            setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)

          } else {
            setPCategory4Id(productCat4Val)

            // Product category 4 list
            const productCat5ApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_category5", "product_category4_id", productCat4Val)
            setCategory5Options(productCat5ApiCall)

            // Product altr material list
            resetGlobalQuery();
            globalQuery.columns.push("product_rm_id");
            globalQuery.columns.push("product_rm_name");
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
            globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTypeId });
            globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: category1Id });
            globalQuery.conditions.push({ field: "product_category2_id", operator: "=", value: $('#cmb_product_category2_id').val() });
            globalQuery.conditions.push({ field: "product_category3_id", operator: "=", value: $('#cmb_product_category3_id').val() });
            globalQuery.conditions.push({ field: "product_category4_id", operator: "=", value: productCat4Val });
            globalQuery.table = "smv_product_rm_details";

            var pAltrRMIds = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setPAlternateMaterialOptions(pAltrRMIds)
            // if (productRmId === 0) {
            //   FnGenerateTechSpecName();
            // }
            FnGenerateTechSpecName();
          }
          break;

        case 'productCategory5':
          var productCat5Val = document.getElementById('cmb_product_category5_id').value;
          if (productCat5Val === '0') {
            setPCategory5Id(productCat5Val)
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Product Category-5')
            setShowAddRecModal(true)
            setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)

          } else {
            setPCategory5Id(productCat5Val)

            FnGenerateTechSpecName();
            // Product altr material list
            resetGlobalQuery();
            globalQuery.columns.push("product_rm_id");
            globalQuery.columns.push("product_rm_name");
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
            globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTypeId });
            globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: category1Id });
            globalQuery.conditions.push({ field: "product_category2_id", operator: "=", value: $('#cmb_product_category2_id').val() });
            globalQuery.conditions.push({ field: "product_category3_id", operator: "=", value: $('#cmb_product_category3_id').val() });
            globalQuery.conditions.push({ field: "product_category4_id", operator: "=", value: $('#cmb_product_category4_id').val() });
            globalQuery.conditions.push({ field: "product_category5_id", operator: "=", value: productCat5Val });
            globalQuery.table = "smv_product_rm_details";

            var pAltrRMIds = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setPAlternateMaterialOptions(pAltrRMIds)
          }
          break;

        case 'productMake':
          var productMakeVal = document.getElementById('cmb_product_make_id').value;
          setPMakeId(productMakeVal)
          if (productMakeVal === '0') {
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Product Make')
            setShowAddRecModal(true)
            setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)

          } else {

            FnGenerateTechSpecName();
          }
          break;

        case 'productMaterialType':
          var productMaterialTypeVal = document.getElementById('cmb_product_material_type_id').value;
          if (productMaterialTypeVal === '0') {
            setPMaterialTypeId(productMaterialTypeVal)
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Material Type')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").eq(0).css("padding-top", "0px");
            }, 100)
          } else {
            setPMaterialTypeId(productMaterialTypeVal)
            if (productMaterialTypeVal !== "") {
              const productGradeApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_material_grade", "product_material_type_id", productMaterialTypeVal)
              setPMaterialGradeOptions(productGradeApiCall)
              FnGenerateTechSpecName();

            } else {
              setPMaterialGradeId('')
            }
          }
          break;

        case 'productAltrRM':
          var productAltrMaterialVal = document.getElementById('cmb_product_alternate_rm_id').value;
          setPAlternateId(productAltrMaterialVal)
          break;

        case 'productMaterialGrade':
          var productMaterialGradeVal = document.getElementById('cmb_product_material_grade_id').value;
          if (productMaterialGradeVal === '0') {
            setPMaterialGradeId(productMaterialGradeVal)
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Material Grade')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").css("padding-top", "0px");
            }, 100)
          } else {
            setPMaterialGradeId(productMaterialGradeVal)
            // if (productRmId === 0) {
            //   FnGenerateTechSpecName();
            // }
            FnGenerateTechSpecName();
          }
          break;

        case 'productMaterialShape':
          var productMaterialShape = document.getElementById('cmb_product_material_shape_id').value;
          setPMaterialShapeId(productMaterialShape)
          if (productMaterialShape === '0') {
            setPMaterialGradeId(productMaterialGradeVal)
            sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            setHeaderName('Material Shape')
            setShowAddRecModal(true)
            setTimeout(() => {
              $(".erp_top_Form").css("padding-top", "0px");
            }, 100)
          }
          break;

        case 'productMaterialColor':
          var productMaterialColor = document.getElementById('cmb_product_material_colour').value;
          setPMaterialColour(productMaterialColor)
          break;
      }

    } catch (error) {
      console.log(error);
      navigate('/Error')
    }
  }

  const displayRecordComponent = () => {
    switch (modalHeaderName) {
      case 'Product Type':
        return <FrmMProductTypeEntry btn_disabled={true} />;

      case 'Product Category1':
        return <FrmMProductCategory1Entry product_type={cmb_product_type_id} btn_disabled={true} />;

      case 'Product Category-2':
        return <FrmMProductCategory2Entry product_type={cmb_product_type_id} btn_disabled={true} />
      case 'Product Category-3':
        return <FrmMProductCategory3Entry product_type={cmb_product_type_id} btn_disabled={true} />
      case 'Product Category-4':
        return <FrmMProductCategory4Entry product_type={cmb_product_type_id} btn_disabled={true} />
      case 'Product Category-5':
        return <FrmMProductCategory5Entry product_type={cmb_product_type_id} btn_disabled={true} />

      case 'HSN-SAC':
        return <FrmHSNSAC btn_disabled={true} />

      case 'Puchase Unit':
        return <FrmProductUnitEntry btn_disabled={true} />

      case 'Sales Unit':
        return <FrmProductUnitEntry btn_disabled={true} />

      case 'Stock Unit':
        return <FrmProductUnitEntry btn_disabled={true} />

      case 'Packing':
        return <FrmMProductPackingEntry btn_disabled={true} />

      case 'Material Type':
        return <FrmMProductMaterialTypeEntry btn_disabled={true} />

      case 'Material Grade':
        return <FrmMProductMaterialGradeEntry btn_disabled={true} />

      case 'Material Shape':
        return <FrmMProductMaterialShapeEntry btn_disabled={true} />
      case 'Product Make':
        return <FrmMProductMakeEntry btn_disabled={true} />

      default:
        return null;
    }
  }


  const handleGeneralEntryData = async (functionType) => {
    try {
      setIsLoading(true)
      let checkIsValidate = await validate();
      let jsonTotalData = {
        'ProductRmData': {}, 'ProductRmTechnicalData': {}, 'ProductRmCommercialData': {},
        'ProductRmSupplierData': [], 'ProductRmProcessData': [], 'ProductQaMappingData': [], 'commonIds': { 'company_id': COMPANY_ID, 'product_rm_id': product_rm_id, 'product_id': productRmId !== 0 ? product_id : 0, }
        , 'saveKey': functionType
      }
      if (checkIsValidate === true) {
        const formData = new FormData();
        // For General Entry 
        if (functionType === 'generalEntry' || functionType === 'allRawMaterialData') {
          const generalEntryDATA = {
            product_id: productRmId !== 0 ? product_id : 0,
            company_id: COMPANY_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            product_rm_id: product_rm_id,
            product_type_id: cmb_product_type_id,
            product_category1_id: cmb_product_category1_id,
            product_rm_code: txt_rm_code,
            product_rm_name: txt_rm_name,
            product_rm_short_name: txt_rm_short_name,
            product_rm_print_name: txt_rm_print_name === "" ? txt_rm_name : txt_rm_print_name,
            product_rm_tech_spect: txt_rm_tech_spect,
            product_rm_oem_part_code: txt_rm_oem_part_code,
            product_rm_our_part_code: txt_rm_our_part_code,
            product_rm_item_sr_no: txt_rm_item_sr_no,
            product_rm_drawing_no: txt_rm_drawing_no,
            product_rm_model_no: txt_rm_model_no,
            product_rm_hsn_sac_code_id: cmb_rm_hsn_sac_code_id,
            product_rm_purchase_unit_id: cmb_rm_purchase_unit_id,
            product_rm_sales_unit_id: cmb_rm_sales_unit_id,
            product_rm_stock_unit_id: cmb_rm_stock_unit_id,
            product_rm_packing_id: cmb_rm_packing_id,
            product_rm_bar_code: txt_rm_bar_code !== "" ? txt_rm_bar_code : product_rm_id,
            product_rm_qr_code: file_rm_qr_codeName,
            product_consumption_mode: cmb_consumption_mode,

            godown_id: cmb_godown_id === null || cmb_godown_id === "" ? 1 : cmb_godown_id,
            godown_section_id: cmb_godown_section_id === null || cmb_godown_section_id === "" ? 1 : cmb_godown_section_id,
            godown_section_beans_id: cmb_godown_section_beans_id === null || cmb_godown_section_beans_id === "" ? 1 : cmb_godown_section_beans_id,

            product_origin_type: cmb_origin_type,
            product_origin_country: cmb_origin_country,
            remark: txt_remarkforGeneralEntry,
            is_active: is_activeGen,
            bom_applicable: is_BOMApplicable,
            created_by: productRmId === 0 ? UserName : null,
            modified_by: productRmId !== 0 ? UserName : null
          };
          jsonTotalData.ProductRmData = generalEntryDATA;
          formData.append(`qrCodeFile`, file_rm_qr_code)
        }

        //For Technical Entry
        if (functionType === 'technicalEntry' || functionType === 'allRawMaterialData') {
          const technicalEntryDATA = {
            company_id: COMPANY_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            product_rm_id: product_rm_id,
            product_rm_technical_id: product_rm_technical_id,
            product_rm_technical_name: txt_product_rm_technical_name,
            product_category2_id: cmb_product_category2_id,
            product_category3_id: cmb_product_category3_id,
            product_category4_id: cmb_product_category4_id,
            product_category5_id: cmb_product_category5_id,
            product_make_id: cmb_product_make_id,
            product_material_type_id: cmb_product_material_type_id,
            product_material_grade_id: cmb_product_material_grade_id,
            product_material_shape_id: cmb_product_material_shape_id,
            product_material_colour: cmb_product_material_colour,
            product_alternate_rm_id: cmb_product_alternate_rm_id,
            assembly_scrap_percent: txt_assembly_scrap_percent,
            remark: txt_remarkforGeneralEntry,
            is_active: is_activeGen,
            created_by: productRmId === 0 ? UserName : null,
            modified_by: productRmId !== 0 ? UserName : null
          };
          jsonTotalData.ProductRmTechnicalData = technicalEntryDATA;
        }

        // For Commercial Entry
        if (functionType === 'commercialEntry' || functionType === 'allRawMaterialData') {
          const commercialEntryDATA = {
            company_id: COMPANY_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            product_rm_id: product_rm_id,
            product_rm_commercial_id: productRmId !== 0 ? 0 : product_rm_commercial_id,
            product_rm_gross_weight: txt_product_rm_gross_weight,
            product_rm_net_weight: txt_product_rm_net_weight,
            product_rm_std_weight: txt_product_rm_std_weight,
            product_rm_volume: txt_product_rm_volume,
            product_rm_mrp: txt_product_rm_mrp,
            product_rm_landed_price: txt_product_rm_landed_price,
            product_rm_avg_price: txt_product_rm_avg_price,
            product_rm_std_profit_percent: txt_product_rm_std_profit_percent,
            product_rm_std_discount_percent: txt_product_rm_std_discount_percent,
            lead_time: txt_lead_time,
            product_rm_moq: txt_product_rm_moq,
            product_rm_mov: txt_product_rm_mov,
            product_rm_eoq: txt_product_rm_eoq,
            product_rm_mpq: txt_product_rm_mpq,
            product_rm_min_stock_level: txt_product_rm_min_stock_level,
            product_rm_max_stock_level: txt_product_rm_max_stock_level,
            product_rm_reorder_stock_level: txt_product_rm_reorder_stock_level,
            product_rm_depriciation_percent: txt_product_rm_depriciation_percent,
            product_rm_price_type: is_priceType,
            product_rm_abc_analysis: is_abcAnalysis,
            qa_required: is_qaRequired,
            test_certificate_required: is_testCertified,
            remark: txt_remarkforGeneralEntry,
            purchase_tolerance_percent: txt_purchase_tolerance_percent,
            is_active: is_activeGen,
            created_by: productRmId === 0 ? UserName : null,
            modified_by: productRmId !== null ? UserName : null

          };
          jsonTotalData.ProductRmCommercialData = commercialEntryDATA;
        }

        //For Supplier Mapping Information
        if (functionType === 'supplierMapping' || functionType === 'allRawMaterialData') {
          $("input:checkbox[name=checkSupplier]:checked").each(function () {
            const supplierMapDATA = {
              company_id: COMPANY_ID,
              company_branch_id: COMPANY_BRANCH_ID,
              product_rm_id: product_rm_id,
              supplier_id: $(this).val(),
              created_by: UserName,
              modified_by: productRmId !== null ? UserName : null
            }
            jsonTotalData.ProductRmSupplierData.push(supplierMapDATA)
          });
          if (jsonTotalData.ProductRmSupplierData.length === 0) {
            if (supplierMappingData.length !== 0) {
              jsonTotalData.ProductRmSupplierData = supplierMappingData;
            }
          }
        }

        //For Process Mapping Information
        if (functionType === 'processMapping' || functionType === 'allRawMaterialData') {
          $("input:checkbox[name=checkProcess]:checked").each(function () {
            const processMapDATA = {
              company_id: parseInt(COMPANY_ID),
              company_branch_id: parseInt(COMPANY_BRANCH_ID),
              product_rm_id: product_rm_id,
              product_process_id: $(this).val(),
              created_by: UserName,
              modified_by: productRmId !== null ? UserName : null
            }
            jsonTotalData.ProductRmProcessData.push(processMapDATA)
          });
          if (jsonTotalData.ProductRmProcessData.length === 0) {
            if (processMappingData.length !== 0) {
              jsonTotalData.ProductRmProcessData = processMappingData;
            }
          }
        }

        //For QA Mapping Information
        //changes by ujjwala on 10/1/2024
        if (functionType === 'qaMapping' || functionType === 'allRawMaterialData') {

          $("input:checkbox[name=checkQaMapp]:checked").each(function () {
            let qaMappingObj = Qadata.find((item) => item.product_qa_parameters_id === parseInt(this.value))
            const qaMapDATA = {
              company_id: parseInt(COMPANY_ID),
              company_branch_id: parseInt(COMPANY_BRANCH_ID),
              product_rm_id: product_rm_id,
              product_qa_parameters_id: $(this).val(),
              product_rm_qa_from_range: qaMappingObj.from_range,
              product_rm_qa_to_range: qaMappingObj.to_range,
              product_rm_qa_from_deviation_percent: qaMappingObj.from_deviation_percent,
              product_rm_qa_to_deviation_percent: qaMappingObj.to_deviation_percent,
              created_by: UserName,
              modified_by: productRmId !== null ? UserName : null
            }
            jsonTotalData.ProductQaMappingData.push(qaMapDATA)
          });
          if (jsonTotalData.ProductQaMappingData.length === 0) {
            if (qaMappingData.length !== 0) {
              jsonTotalData.ProductQaMappingData = qaMappingData;
            }
          }
        }


        formData.append(`TransData`, JSON.stringify(jsonTotalData))

        const requestOptions = {
          method: 'POST',
          body: formData
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productRm/FnAddUpdateRecord`, requestOptions)
        const response = await apicall.json();
        if (response.success !== 1) {
          setErrMsg(response.error)
          setShowErrorMsgModal(true)
        } else {
          const evitCache = comboDataAPiCall.current.evitCache();
          console.log(evitCache);
          const { product_rm_id, product_rm_name, product_rm_qr_code } = response.data;
          setProductRmId(product_rm_id)
          setRmName(product_rm_name)
          setRmQrCodePath(product_rm_qr_code)
          setSuccMsg(response.message)
          // if(is_BOMApplicable === 'yes'){
          //  $('#btn_next').hide();
          // }
          setShowSuccessMsgModal(true)
        }

      }
      setIsLoading(false)

    } catch (error) {
      console.log("error: ", error);
      navigate('/Error')
    }
  }

  const onFileUpload = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setRmQrCode(file);
    }
  }

  const uploadQRCodeFile = async (product_rm_id, product_rm_name) => {
    try {
      const formData = new FormData();
      formData.append(`file`, file_rm_qr_code)
      const requestOptions = {
        method: 'POST',
        body: formData
      };
      const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productRm/FnStoreQRFile/${product_rm_id}/${product_rm_name}`, requestOptions)
      const fetchRes = await apicall.json();
      console.log("fetchRes: ", fetchRes)
      return fetchRes;

    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  }

  const fetchQrCode = async () => {
    try {
      const downloadImageApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productRm/FnGetQRCode/${product_rm_id}`)
      const blob = await downloadImageApiCall.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `qrcode.png`,);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  async function DeleteRecord() {
    try {
      const method = { method: 'POST' }
      const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productRm/FnDeleteRecord/${product_rm_id}/${UserName}`, method)
      const responce = await deleteApiCall.json();
      console.log("Raw material Deleted: ", responce);
      if (responce.success == '1') {
        setShow(false)
        moveToListing();
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  // navigate to back listing page 
  const moveToListing = () => {
    const path = compType === 'Register' ? '/Masters/RawMaterialListing/reg' : '/Masters/RawMaterialListing';
    navigate(path);
  }

  const deleteshow = () => {
    setShow(true)
  }


  const FnRefreshbtn = async (key) => {
    if (keyForViewUpdate === 'update') {
      switch (key) {
        case 'godown_id':
          resetGlobalQuery();
          globalQuery.columns.push("field_id");
          globalQuery.columns.push("field_name");
          globalQuery.table = "cmv_godown"
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          const godownidApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
          setgodownidOptions(godownidApiCall)
          setgodownid('');
          setgodownsectionid('');
          setgodownidSectionOptions([])
          setgodownsectionbeansidOptions([])
          setgodownsectionbeansid('')
          break;
        case 'godown_Section':
          await comboOnChange("godown");
          break;

        case 'godown_Section_Beans':
          await comboOnChange("godownsection");
          break;

        default:
          break;
      }
    }
  }

  // Supplier Entry

  const handleSupplierCheckbox = (supp_id) => {
    $('input:checkbox[name="checkSupplier"][value="' + supp_id + '"]').attr('checked', false);
    const totalChkBoxes = document.querySelectorAll('input[name=checkSupplier]').length;
    const totalChkBoxesChked = document.querySelectorAll('input[name=checkSupplier]:checked').length;
    if (totalChkBoxes == totalChkBoxesChked) {
      document.getElementById('chkAllSuppliers').checked = true;
    } else if (totalChkBoxes > totalChkBoxesChked) {
      document.getElementById('chkAllSuppliers').checked = false;
    }
  }

  const memoizedSupplierComponent = useMemo(() => (
    <FrmMSuppliersEntry data={supplierData} columns={supplierColumns} />
  ), [supplierData]);

  // Process Entry

  const handleProcessCheckbox = (procc_id) => {
    $('input:checkbox[name="checkProcess"][value="' + procc_id + '"]').attr('checked', false);
    const totalChkBoxes = document.querySelectorAll('input[name=checkProcess]').length;
    const totalChkBoxesChked = document.querySelectorAll('input[name=checkProcess]:checked').length;
    if (totalChkBoxes == totalChkBoxesChked) {
      document.getElementById('chkAllProcess').checked = true;
    } else if (totalChkBoxes > totalChkBoxesChked) {
      document.getElementById('chkAllProcess').checked = false;
    }
  }

  const memoizedProcessComponent = useMemo(() => (
    <FrmMProcessEntry data={processData} columns={processColumns} />
  ), [processData]);

  //QA Mapping 

  const handleqaMappingCheckbox = (qaMapp_id) => {
    $('input:checkbox[name="checkQaMapp"][value="' + qaMapp_id + '"]').attr('checked', false);
    const totalChkBoxes = document.querySelectorAll('input[name=checkQaMapp]').length;
    const totalChkBoxesChked = document.querySelectorAll('input[name=checkQaMapp]:checked').length;
    if (totalChkBoxes == totalChkBoxesChked) {
      document.getElementById('chkAllQaMapping').checked = true;
    } else if (totalChkBoxes > totalChkBoxesChked) {
      document.getElementById('chkAllQaMapping').checked = false;
    }
  }

  const memoizedQaComponent = useMemo(() => (
    <FrmMQaMappingEntry data={Qadata} columns={Qacolumns} />
  ), [Qadata]);



  // Validations
  const validate = async () => {
    if (await validationRef.current.validateForm('generalEntryFormId')) {
      if (await validationRef.current.validateForm('technicalEntryFormId')) {
        return await validationRef.current.validateForm('commercialEntryFormId');
      }
    }
    return false;
  }

  const validateFields = () => {
    validationRef.current.validateFieldsOnChange('commercialEntryFormId');
    validationRef.current.validateFieldsOnChange('generalEntryFormId');
    validationRef.current.validateFieldsOnChange('technicalEntryFormId');
  }

  const addReadOnlyAttr = async () => {
    await validationRef.current.readOnly('commercialEntryFormId');
    await validationRef.current.readOnly('generalEntryFormId');
    await validationRef.current.readOnly('technicalEntryFormId');
  }

  const validateNo = (key) => {
    const numCheck = /^[0-9]*\.?[0-9]*$/;
    const regexNo = /^[0-9]*\.[0-9]{5}$/
    const regexNo1 = /^[0-9]*\.[0-9]{3}$/
    const regexNo2 = /^[0-9]*\.[0-9]{0}$/
    var dot = '.';
    switch (key) {
      case 'PGrossWeight':
        var grossWtVal = $('#txt_product_rm_gross_weight').val();
        if (!regexNo.test(grossWtVal) && grossWtVal.length < 15 || grossWtVal.indexOf(dot) === 14) {
          if (numCheck.test(grossWtVal)) {
            setPGrossWeight(grossWtVal)
          }

        }
        break;

      case 'PNetWeight':
        var netWtVal = $('#txt_product_rm_net_weight').val();
        if (!regexNo.test(netWtVal) && netWtVal.length < 15 || netWtVal.indexOf(dot) === 14) {
          if (numCheck.test(netWtVal)) {
            setPNetWeight(netWtVal)
          }
        }
        break;

      case 'PStdWeight':
        var stdWtVal = $('#txt_product_rm_std_weight').val();
        if (!regexNo.test(stdWtVal) && stdWtVal.length < 15 || stdWtVal.indexOf(dot) === 14) {
          if (numCheck.test(stdWtVal)) {
            setPStdWeight(stdWtVal)
          }
        }
        break;

      case 'PVolume':
        var volumeVal = $('#txt_product_rm_volume').val();
        if (!regexNo.test(volumeVal) && volumeVal.length < 15 || volumeVal.indexOf(dot) === 14) {
          if (numCheck.test(volumeVal)) {
            setPVolume(volumeVal)
          }
        }
        break;

      case 'PMrp':
        var mrpVal = $('#txt_product_rm_mrp').val();
        if (!regexNo.test(mrpVal) && mrpVal.length < 15 || mrpVal.indexOf(dot) === 14) {
          if (numCheck.test(mrpVal)) {
            setPMrp(mrpVal)
          }
        }
        break;

      case 'PLandedPrice':
        var landedPriceVal = $('#txt_product_rm_landed_price').val();
        if (!regexNo1.test(landedPriceVal) && landedPriceVal.length < 16 || landedPriceVal.indexOf(dot) === 15) {
          if (numCheck.test(landedPriceVal)) {
            setPLandedPrice(landedPriceVal)
          }
        }
        break;

      case 'PAvgPrice':
        var avgPriceVal = $('#txt_product_rm_avg_price').val();
        if (!regexNo1.test(avgPriceVal) && avgPriceVal.length < 16 || avgPriceVal.indexOf(dot) === 15) {
          if (numCheck.test(avgPriceVal)) {
            setPAvgPrice(avgPriceVal)
          }
        }
        break;

      case 'PMoq':
        var moqVal = $('#txt_product_rm_moq').val();
        if (!regexNo1.test(moqVal) && moqVal.length < 16 || moqVal.indexOf(dot) === 15) {
          if (numCheck.test(moqVal)) {
            setPmoq(moqVal)
          }
        }
        break;

      case 'PMpq':
        var mpqVal = $('#txt_product_rm_mpq').val();
        if (!regexNo1.test(mpqVal) && mpqVal.length < 16 || mpqVal.indexOf(dot) === 15) {
          if (numCheck.test(mpqVal)) {
            setPmpq(mpqVal)
          }
        }
        break;

      case 'PMinStockLevel':
        var minStockLevelVal = $('#txt_product_rm_min_stock_level').val();
        if (!regexNo1.test(minStockLevelVal) && minStockLevelVal.length < 16 || minStockLevelVal.indexOf(dot) === 15) {
          if (numCheck.test(minStockLevelVal)) {
            setPMinStockLevel(minStockLevelVal)
          }
        }
        break;

      case 'PMaxStockLevel':
        var maxStockLevelVal = $('#txt_product_rm_max_stock_level').val();
        if (!regexNo1.test(maxStockLevelVal) && maxStockLevelVal.length < 16 || maxStockLevelVal.indexOf(dot) === 15) {
          if (numCheck.test(maxStockLevelVal)) {
            setPMaxStockLevel(maxStockLevelVal)
          }
        }
        break;

      case 'PReorderStockLevel':
        var reorderStockLevelVal = $('#txt_product_rm_reorder_stock_level').val();
        if (!regexNo1.test(reorderStockLevelVal) && reorderStockLevelVal.length < 16 || reorderStockLevelVal.indexOf(dot) === 15) {
          if (numCheck.test(reorderStockLevelVal)) {
            setPReorderStockLevel(reorderStockLevelVal)
          }
        }
        break;

      case 'PMov':
        var movVal = $('#txt_product_rm_mov').val();
        if (!regexNo1.test(movVal) && movVal.length < 16 || movVal.indexOf(dot) === 15) {
          if (numCheck.test(movVal)) {
            setPmov(movVal)
          }
        }
        break;

      case 'PEoq':
        var eoqVal = $('#txt_product_rm_eoq').val();
        if (!regexNo2.test(eoqVal) && eoqVal.length < 11) {
          if (numCheck.test(eoqVal)) {
            setPeoq(eoqVal)
          }
        }
        break;
      //changes by tushar
      case 'lead_time':
        var leadVal = $('#txt_lead_time').val();
        if (numCheck.test(leadVal)) {
          leadVal = leadVal.replace(/\./g, '');
          setLeadTime(leadVal);
        }
        break;
    }
  }

  // ---------------------------------------------------------------------------------------------

  //changes by tushar
  const handleAccordionSelect = async (eventKey) => {
    let checkedLength = 0;
    switch (eventKey) {

      case 'SupplierMapping':
        checkedLength = $("input:checkbox[name=checkSupplier]:checked").length;
        if (checkedLength === 0) {
          await FnGetMappingsData("SupplierMapping");
        }
        if (productRmId !== 0) {
          //Updating Supplier CheckBoxes
          $("input:checkbox[name=checkSupplier]").each(function () {
            const checkboxValue = parseInt($(this).val());
            const orderNoIndex = supplierMappingData.findIndex((item) => item.supplier_id === checkboxValue)
            if (orderNoIndex !== -1) {
              $(this).prop('checked', true);
            }
            handleSupplierCheckbox(checkboxValue);  // Added for when data is-prefilled then check select-all
          });
        }
        console.log("SupplierMapping");
        break;
      case 'ProcessMapping':
        checkedLength = $("input:checkbox[name=checkProcess]:checked").length;
        if (checkedLength === 0) {
          await FnGetMappingsData("ProcessMapping");
        }
        if (productRmId !== 0) {
          //Updating Process CheckBoxes
          $("input:checkbox[name=checkProcess]").each(function () {
            const checkboxValue = parseInt($(this).val());
            const orderNoIndex = processMappingData.findIndex((item) => item.product_process_id === checkboxValue)
            if (orderNoIndex !== -1) {
              $(this).prop('checked', true);
            }
            handleProcessCheckbox(checkboxValue);  // Added for when data is-prefilled then check select-all
          });
        }
        console.log("ProcessMapping");
        break;
      case 'QAMapping':
        console.log("QAMapping");
        checkedLength = $("input:checkbox[name=checkQaMapp]:checked").length;
        if (checkedLength === 0) {
          await FnGetMappingsData("QAMapping");
        }
        if (productRmId !== 0) {
          //Updating QA checkBoxes
          $("input:checkbox[name=checkQaMapp]").each(function () {
            const checkboxValue = parseInt($(this).val());
            const orderNoIndex = qaMappingData.findIndex((item) => item.product_qa_parameters_id === checkboxValue)
            if (orderNoIndex !== -1) {
              $(this).prop('checked', true);
            }
            handleqaMappingCheckbox(checkboxValue);  // Added for when data is-prefilled then check select-all
          });
        }
        break;
      default:
        console.log('Invalid accordion key');
        break;
    }
    if (keyForViewUpdate === "view") {
      $('input[type="checkbox"]').prop('disabled', true);
    }
  };


  const FncheckIsBomApplicable = () => {
    if (is_BOMApplicable === "Yes") {
      goToNext(product_rm_id, txt_rm_name,
        document.getElementById("cmb_product_type_id").options[document.getElementById("cmb_product_type_id").selectedIndex].getAttribute('ptShortName'))
    } else {
      setShowErrorMsgModal(true)
      setErrMsg(`${txt_rm_name} Raw Material Is Not BOM Applicable..!`)
    }
  }

  // ---------------------------------------------------------------------------------------------


  return (
    <>
      <ComboBox ref={comboDataAPiCall} />
      <FrmValidations ref={validationRef} />
      <ValidateNumberDateInput ref={percentValidateRef} />
      <GenerateMaterialId ref={generateMaterialIdAPiCall} />

      {isLoading ?
        <div className="spinner-overlay"  >
          <div className="spinner-container">
            <CircularProgress color="primary" />
            <span id="spinner_text" className="text-dark">Loading...</span>
          </div>
        </div> :
        null}
      <form id="generalEntryFormId">
        <div className='row'>
          {/* first */}

          {/* //changes by tushar */}
          {/* added : after lables*/}

          <div className='col-sm-4 erp_form_col_div'>
            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Product Type : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_product_type_id" className="form-select form-select-sm" value={cmb_product_type_id} onChange={() => comboOnChange('productType')}>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {productTypeOptions?.map(productType => (
                    <option value={productType.field_id} ptShortName={productType.product_type_short_name}>{productType.field_name}</option>

                  ))}

                </select>
                <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Product Category 1 : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_product_category1_id" className="form-select form-select-sm" value={cmb_product_category1_id} onChange={() => comboOnChange('productCategory1')}>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {productCategory1Options?.map(productCategory1 => (
                    <option value={productCategory1.field_id}>{productCategory1.field_name}</option>

                  ))}

                </select>
                <MDTypography variant="button" id="error_cmb_product_category1_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Material ID : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="product_rm_id" className="erp_input_field" value={product_rm_id} maxLength="255" disabled="disabled" />
                <MDTypography variant="button" id="error_product_rm_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">RM Code : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_code" className="erp_input_field" value={txt_rm_code} onChange={e => { setRmCode(e.target.value); validateFields() }} maxLength="255" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">RM Name : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_name" className="erp_input_field" value={txt_rm_name} onChange={e => { setRmName(e.target.value); validateFields() }} maxLength="500" />
                <MDTypography variant="button" id="error_txt_rm_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Short Name : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_short_name" className="erp_input_field" value={txt_rm_short_name} onChange={e => { setRmShortName(e.target.value.toUpperCase()); validateFields() }} maxLength="5" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Print Name : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_print_name" className="erp_input_field" value={txt_rm_print_name} onChange={e => { setRmPrintName(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_print_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Tech. Spect. : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control as="textarea" rows={1} id="txt_rm_tech_spect" className="erp_txt_area" value={txt_rm_tech_spect} onChange={e => { setRmTechSpect(e.target.value); validateFields() }} maxlength="1000" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_tech_spect" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">OEM Part Code : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_oem_part_code" className="erp_input_field" value={txt_rm_oem_part_code} onChange={e => { setOemPartCode(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_oem_part_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">OUR Part Code : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_our_part_code" className="erp_input_field" value={txt_rm_our_part_code} onChange={e => { setOurPartCode(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_our_part_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>
          </div>

          {/* second */}
          <div className='col-sm-4 erp_form_col_div'>
            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Item Sr No. : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_item_sr_no" className="erp_input_field" value={txt_rm_item_sr_no} onChange={e => { setItemSrNo(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_item_sr_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Item Drawing No. : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_drawing_no" className="erp_input_field" value={txt_rm_drawing_no} onChange={e => { setRmDrawingNo(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_drawing_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Model No. : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_model_no" className="erp_input_field" value={txt_rm_model_no} onChange={e => { setRmModelNo(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_model_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">HSN-SAC Code : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_rm_hsn_sac_code_id" className="form-select form-select-sm" value={cmb_rm_hsn_sac_code_id} onChange={() => comboOnChange('hsnSacCode')}>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {hsnSacCodeOptions?.map(HsnSacCode => (
                    <option value={HsnSacCode.field_id}>{HsnSacCode.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_rm_hsn_sac_code_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Purchase Unit : </Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_rm_purchase_unit_id" className="form-select form-select-sm" value={cmb_rm_purchase_unit_id} onChange={() => comboOnChange('puchaseUnit')} optional='optional'>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {unitOptions?.map(units => (
                    <option value={units.field_id}>{units.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_rm_purchase_unit_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>


            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Sales Unit : </Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_rm_sales_unit_id" className="form-select form-select-sm" value={cmb_rm_sales_unit_id} onChange={() => comboOnChange('salesUnit')} optional='optional'>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {unitOptions?.map(units => (
                    <option value={units.field_id}>{units.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_rm_sales_unit_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Stock Unit : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_rm_stock_unit_id" className="form-select form-select-sm" value={cmb_rm_stock_unit_id} onChange={() => comboOnChange('stockUnit')}>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {unitOptions?.map(units => (
                    <option value={units.field_id}>{units.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_rm_stock_unit_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Packing : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_rm_packing_id" className="form-select form-select-sm" value={cmb_rm_packing_id} onChange={() => comboOnChange('packingId')}>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {productPackingOptions?.map(productPackings => (
                    <option value={productPackings.field_id}>{productPackings.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_rm_packing_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Bar Code : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_bar_code" className="erp_input_field" value={txt_rm_bar_code} onChange={e => { setRmBarCode(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_bar_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">BOM Applicable : </Form.Label>
              </div>
              <div className="col">
                <div className="erp_form_radio">
                  <div className="fCheck">
                    <Form.Check
                      className="erp_radio_button"
                      label="Yes"
                      type="radio"
                      value="yes" checked={is_BOMApplicable === 'Yes'} onClick={() => { setBOMApplicable('Yes'); }}
                      name="isBOMApplicable"

                    />
                  </div>
                  <div className="sCheck">
                    <Form.Check
                      className="erp_radio_button"
                      label="No"
                      value="no" checked={is_BOMApplicable === 'No'} onClick={() => { setBOMApplicable('No'); }}
                      type="radio"
                      name="isBOMApplicable"
                      defaultChecked
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Third */}
          <div className='col-sm-4 erp_form_col_div'>
            <div className="row">
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">QR Code : </Form.Label>
              </div>
              <div className="col">
                <Form.Control type="file" id="file_rm_qr_code" className="erp_input_field" onChange={onFileUpload} optional="optional" accept="image/*" />
                <MDTypography component="label" className={`erp-form-label ${file_rm_qr_codeName !== '' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" color="info"  >
                  <Link to="#" onClick={fetchQrCode}>{file_rm_qr_codeName}</Link>
                </MDTypography>
                <MDTypography variant="button" id="error_file_rm_qr_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Consumption Mode : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_consumption_mode" className="form-select form-select-sm" value={cmb_consumption_mode} onChange={() => comboOnChange('consumptionMode')}>
                  <option value="">Select</option>
                  {consumtionModeOptions?.map(consumptionModes => (
                    <option value={consumptionModes.field_name}>{consumptionModes.field_name}</option>

                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_consumption_mode" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4 col-12'>
                <Form.Label className="erp-form-label">Godown : </Form.Label>
              </div>
              <div className='col-sm-7 col-10'>
                <select id="cmb_godown_id" className="form-select form-select-sm" value={cmb_godown_id} onChange={(e) => { setgodownid(e.target.value); comboOnChange('godown'); }} optional='optional' >
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {godownidOptions?.map(godownid => (
                    <option value={godownid.field_id}>{godownid.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_godown_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>

              {keyForViewUpdate !== 'view' ? (
                <div className="col-sm-1 col-2">
                  <Tooltip title="Refresh" placement="top">
                    <MDTypography>
                      <MdRefresh id="btn_refresh" onClick={() => FnRefreshbtn("godown_id")} style={{ color: 'black' }} />
                    </MDTypography>
                  </Tooltip>
                </div>) : null}

            </div>

            <div className='row'>
              <div className='col-sm-4 col-12'>
                <Form.Label className="erp-form-label">Godown Sec. : </Form.Label>
              </div>
              <div className='col-sm-7 col-10'>
                <select id="cmb_godown_section_id" className="form-select form-select-sm" value={cmb_godown_section_id} onChange={(e) => { setgodownsectionid(e.target.value); comboOnChange('godownsection'); }} optional='optional'>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {godownidSectionOptions?.map(godownsection => (
                    <option value={godownsection.field_id}>{godownsection.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_godown_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>

              {keyForViewUpdate !== 'view' ? (
                <div className="col-sm-1 col-2">
                  <Tooltip title="Refresh" placement="top">
                    <MDTypography>
                      <MdRefresh id="btn_refresh" onClick={() => FnRefreshbtn("godown_Section")} style={{ color: 'black' }} />
                    </MDTypography>
                  </Tooltip>
                </div>) : null}
            </div>

            <div className='row'>
              <div className='col-sm-4 col-12'>
                <Form.Label className="erp-form-label">Godown Sec. Beans : </Form.Label>
              </div>
              <div className='col-sm-7 col-10'>
                <select id="cmb_godown_section_beans_id" className="form-select form-select-sm" value={cmb_godown_section_beans_id} onChange={(e) => { setgodownsectionbeansid(e.target.value); comboOnChange('godownsectionbeans'); }} optional='optional' >
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {godownsectionbeansidOptions?.map(godownsectionbeans => (
                    <option value={godownsectionbeans.field_id}>{godownsectionbeans.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_godown_section_beans_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>

              {keyForViewUpdate !== 'view' ? (
                <div className="col-sm-1 col-2">
                  <Tooltip title="Refresh" placement="top">
                    <MDTypography>
                      <MdRefresh id="btn_refresh" onClick={() => FnRefreshbtn("godown_Section_Beans")} style={{ color: 'black' }} />
                    </MDTypography>
                  </Tooltip>
                </div>) : null}
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Origin Type : </Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_origin_type" className="form-select form-select-sm" value={cmb_origin_type} optional='optional' onChange={() => comboOnChange('materialOrigin')}>
                  <option value="">Select</option>
                  {materialOriginOptions?.map(origins => (
                    <option value={origins.field_name}>{origins.field_name}</option>

                  ))}

                </select>
                <MDTypography variant="button" id="error_cmb_origin_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Origin Country : </Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_origin_country" className="form-select form-select-sm" value={cmb_origin_country} optional="optional" onChange={() => comboOnChange('originCountry')}>
                  <option value="">Select</option>
                  {countryOptions?.map(country => (
                    <option value={country.field_name}>{country.field_name}</option>

                  ))}

                </select>
                <MDTypography variant="button" id="error_cmb_origin_country" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Remark : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control as="textarea" rows={1} id="txt_remark_general_entry" className="erp_input_field" value={txt_remarkforGeneralEntry} onChange={e => { setRemarkforGeneralEntry(e.target.value); validateFields() }} maxLength="255" optional="optional" />
                <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Is Active : </Form.Label>
              </div>
              <div className="col">

                <div className="erp_form_radio">
                  <div className="fCheck">
                    <Form.Check
                      className="erp_radio_button"
                      label="Yes"
                      type="radio"
                      value="true" checked={is_activeGen === true} onClick={() => { setIsActiveGen(true); }}
                      name="isGeneralActive"


                    />
                  </div>
                  <div className="sCheck">
                    <Form.Check
                      className="erp_radio_button"
                      label="No"
                      value="false" checked={is_activeGen === false} onClick={() => { setIsActiveGen(false); }}
                      type="radio"
                      name="isGeneralActive"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>





      {/* Technical Info Accordian */}
      <hr />
      {/* changes by tushar */}
      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}> */}
      <Accordion defaultActiveKey="0" onSelect={handleAccordionSelect}>
        <Accordion.Item eventKey="TechnicalInformation">
          <Accordion.Header className="erp-form-label-md">Technical Information</Accordion.Header>
          <Accordion.Body>
            <form id="technicalEntryFormId">
              <div className='row'>
                <div className='col-sm-6 erp_form_col_div'>
                  <div className='row' id="product_category2_id" style={{ display: "none" }}>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label" id="cat1Label"></Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_category2_id" className="form-select form-select-sm" value={cmb_product_category2_id} onChange={() => comboOnChange('productCategory2')} optional='optional'>
                        <option value="1">Select</option>
                        <option value="0">Add New Record+</option>
                        {category2Options?.map((category2) => (
                          <option value={category2.field_id}> {category2.field_name} </option>

                        ))}

                      </select>
                      <MDTypography variant="button" id="error_cmb_product_category2_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row' id="product_category3_id" style={{ display: "none" }}>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label" id="cat2Label"></Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_category3_id" className="form-select form-select-sm" value={cmb_product_category3_id} onChange={() => comboOnChange('productCategory3')} optional='optional'>
                        <option value="1">Select</option>
                        <option value="0">Add New Record+</option>
                        {category3Options?.map((category3) => (
                          <option value={category3.field_id}>{category3.field_name}</option>

                        ))}

                      </select>
                      <MDTypography variant="button" id="error_cmb_product_category3_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row' id="product_category4_id" style={{ display: "none" }}>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label" id="cat3Label"></Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_category4_id" className="form-select form-select-sm" value={cmb_product_category4_id} onChange={() => comboOnChange('productCategory4')} optional='optional'>
                        <option value="1">Select</option>
                        <option value="0">Add New Record+</option>
                        {category4Options?.map((category4) => (
                          <option value={category4.field_id}>{category4.field_name}</option>

                        ))}

                      </select>
                      <MDTypography variant="button" id="error_cmb_product_category4_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row' id="product_category5_id" style={{ display: "none" }}>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label" id="cat4Label"></Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_category5_id" className="form-select form-select-sm" value={cmb_product_category5_id} onChange={() => comboOnChange('productCategory5')} optional='optional'>
                        <option value="1">Select</option>
                        <option value="0">Add New Record+</option>
                        {category5Options?.map(category5 => (
                          <option value={category5.field_id}>{category5.field_name}</option>

                        ))}

                      </select>
                      <MDTypography variant="button" id="error_cmb_product_category5_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Make : </Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_make_id" className="form-select form-select-sm" value={cmb_product_make_id} onChange={() => comboOnChange('productMake')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {pMakeOptions?.map(productMake => (
                          <option value={productMake.field_id}>{productMake.field_name}</option>
                        ))}

                      </select>
                      <MDTypography variant="button" id="error_cmb_product_make_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Material Type  : </Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_material_type_id" className="form-select form-select-sm" value={cmb_product_material_type_id} onChange={() => comboOnChange('productMaterialType')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {pMaterialTypeOptions?.map(productMaterialType => (
                          <option value={productMaterialType.field_id}>{productMaterialType.field_name}</option>

                        ))}

                      </select>
                      <MDTypography variant="button" id="error_cmb_product_material_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Material Grade : </Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_material_grade_id" className="form-select form-select-sm" value={cmb_product_material_grade_id} onChange={() => comboOnChange('productMaterialGrade')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {pMaterialGradeOptions?.map(productMaterialGrade => (
                          <option value={productMaterialGrade.field_id}>{productMaterialGrade.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_material_grade_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                </div>

                <div className='col-sm-6 erp_form_col_div'>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Technical Name : <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_technical_name" className="erp_input_field" value={txt_product_rm_technical_name} onChange={e => { setPTechnivalName(e.target.value); validateFields() }} maxLength="500" disabled="disabled" />
                      <MDTypography variant="button" id="error_txt_product_rm_technical_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Material Shape : </Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_material_shape_id" className="form-select form-select-sm" value={cmb_product_material_shape_id} onChange={() => comboOnChange('productMaterialShape')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {pMaterialShapeOptions?.map(productMaterialShape => (
                          <option value={productMaterialShape.field_id}>{productMaterialShape.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_material_shape_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Material Color : </Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_material_colour" className="form-select form-select-sm" value={cmb_product_material_colour} onChange={() => comboOnChange('productMaterialColor')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {pMaterialColorOptions?.map(productMaterialColor => (
                          <option value={productMaterialColor.field_name}>{productMaterialColor.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_material_colour" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Alternate RM : </Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_alternate_rm_id" className="form-select form-select-sm" value={cmb_product_alternate_rm_id} onChange={() => comboOnChange('productAltrRM')} optional='optional'>
                        <option value=""></option>
                        {pAlternateMaterialOptions?.map(productAltrMaterial => (
                          <option value={productAltrMaterial.product_rm_id}>{productAltrMaterial.product_rm_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_alternate_rm_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Assembly Scrap Percent(%) : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="number" id="txt_assembly_scrap_percent" className="erp_input_field erp_align-right" value={txt_assembly_scrap_percent} onChange={e => { if (percentValidateRef.current.percentValidate(e.target.value)) { setAssemblyScrapPercent(e.target.value) } }} optional="optional" />
                      <MDTypography variant="button" id="error_txt_assembly_scrap_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  {/* <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Remark : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control as="textarea" rows={1} id="txt_remark_technical_entry" className="erp_input_field" value={txt_remarkforTechnicalEntry} onChange={e => { setRemarkforTechnicalEntry(e.target.value); validateFields() }} maxLength="255" optional="optional" />
                      <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Is Active : </Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="Yes"
                            type="radio"
                            value="true" checked={is_activeTech === true} onClick={() => { setIsActiveTech(true); }}
                            name="isTechnicalActive"
                            defaultChecked
                          />
                        </div>
                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="No"
                            value="false" checked={is_activeTech === false} onClick={() => { setIsActiveTech(false); }}
                            type="radio"
                            name="isTechnicalActive"
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}

                </div>
              </div>
            </form>
            {/* <div className="text-center">
              <MDButton type="button" id="btn_update" className={`erp-gb-button ${product_rm_id !== 0 && keyForViewUpdate !== 'view' ? 'display' : 'd-none'}`} variant="button"
                fontWeight="regular" onClick={() => handleGeneralEntryData('technicalEntry')} >Update</MDButton>
            </div> */}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />

      {/* Commercial Info Accordian */}
      {/* changes by tushar */}

      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}> */}
      <Accordion defaultActiveKey="0" onSelect={handleAccordionSelect}>
        <Accordion.Item eventKey="CommercialInformation">
          <Accordion.Header className="erp-form-label-md">Commercial Information </Accordion.Header>
          <Accordion.Body>
            <form id="commercialEntryFormId">
              <div className='row'>
                <div className='col-sm-6 erp_form_col_div'>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Gross Weight : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_gross_weight" className="erp_input_field erp_align-right" value={txt_product_rm_gross_weight} onChange={e => { validateNo('PGrossWeight') }} maxLength="19" optional="optional" />
                      <MDTypography variant="button" id="error_txt_product_rm_gross_weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Net Weight : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_net_weight" className="erp_input_field erp_align-right" value={txt_product_rm_net_weight} onChange={e => { validateNo('PNetWeight'); }} maxLength="19" optional="optional" />
                      <MDTypography variant="button" id="error_txt_product_rm_net_weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Std Weight : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_std_weight" className="erp_input_field erp_align-right" value={txt_product_rm_std_weight} onChange={e => { validateNo('PStdWeight'); }} maxLength="19" optional="optional" />
                      <MDTypography variant="button" id="error_txt_product_rm_std_weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Volume : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_volume" className="erp_input_field erp_align-right" value={txt_product_rm_volume} onChange={e => { validateNo('PVolume'); }} maxLength="19" optional="optional" />
                      <MDTypography variant="button" id="error_txt_product_rm_volume" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Max.Retail Price : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_mrp" className="erp_input_field erp_align-right" value={txt_product_rm_mrp} onChange={e => { validateNo('PMrp'); }} maxLength="19" optional="optional" />
                      <MDTypography variant="button" id="error_txt_product_rm_mrp" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Landed Price : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_landed_price" className="erp_input_field erp_align-right" value={txt_product_rm_landed_price} onChange={e => { validateNo('PLandedPrice'); }} maxLength="19" optional="optional" />
                      <MDTypography variant="button" id="error_txt_product_rm_landed_price" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Average Price : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_avg_price" className="erp_input_field erp_align-right" value={txt_product_rm_avg_price} onChange={e => { validateNo('PAvgPrice'); }} maxLength="19" optional="optional" />
                      <MDTypography variant="button" id="error_txt_product_rm_avg_price" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Standard Profit(%) : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="number" id="txt_product_rm_std_profit_percent" className="erp_input_field erp_align-right" value={txt_product_rm_std_profit_percent} onChange={e => { if (percentValidateRef.current.percentValidate(e.target.value)) { setPStdProfitPercent(e.target.value) } }} maxLength="19" optional="optional" />
                      <MDTypography variant="button" id="error_txt_product_rm_std_profit_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Standard Discount(%) : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="number" id="txt_product_rm_std_discount_percent" className="erp_input_field erp_align-right" value={txt_product_rm_std_discount_percent} onChange={e => { if (percentValidateRef.current.percentValidate(e.target.value)) { setPDiscountPercent(e.target.value) } }} maxLength="19" optional="optional" />
                      <MDTypography variant="button" id="error_txt_product_rm_std_discount_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Lead Time (Days): </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_lead_time" className="erp_input_field erp_align-right " value={txt_lead_time} onChange={e => { validateNo('lead_time'); }} maxLength="11" optional="optional" />
                      <MDTypography variant="button" id="error_txt_lead_time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>


                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Min.Order Quantity : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_moq" className="erp_input_field erp_align-right" value={txt_product_rm_moq} onChange={e => { validateNo('PMoq'); }} maxLength="19" optional="optional" />
                      <MDTypography variant="button" id="error_txt_product_rm_moq" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Min.Order Value : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_mov" className="erp_input_field erp_align-right" value={txt_product_rm_mov} onChange={e => { validateNo('PMov'); }} maxLength="19" optional="optional" />
                      <MDTypography variant="button" id="error_txt_product_rm_mov" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>


                </div>

                <div className='col-sm-6 erp_form_col_div'>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Economical Order Quantity : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_eoq" className="erp_input_field erp_align-right" value={txt_product_rm_eoq} onChange={e => { validateNo('PEoq'); }} maxLength="10" optional="optional" />
                      <MDTypography variant="button" id="error_txt_product_rm_eoq" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Min.Production Quantity : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_mpq" className="erp_input_field erp_align-right" value={txt_product_rm_mpq} onChange={e => { validateNo('PMpq'); }} maxLength="19" optional="optional" />
                      <MDTypography variant="button" id="error_txt_product_rm_mpq" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Min. Stock Level : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_min_stock_level" className="erp_input_field erp_align-right" value={txt_product_rm_min_stock_level} onChange={e => { validateNo('PMinStockLevel'); }} maxLength="19" optional="optional" />
                      <MDTypography variant="button" id="error_txt_product_rm_min_stock_level" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Max. Stock Level : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_max_stock_level" className="erp_input_field erp_align-right" value={txt_product_rm_max_stock_level} onChange={e => { validateNo('PMaxStockLevel'); }} maxLength="19" optional="optional" />
                      <MDTypography variant="button" id="error_txt_product_rm_max_stock_level" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Record Stock Level : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_reorder_stock_level" className="erp_input_field erp_align-right" value={txt_product_rm_reorder_stock_level} onChange={e => { validateNo('PReorderStockLevel'); }} maxLength="19" optional="optional" />
                      <MDTypography variant="button" id="error_txt_product_rm_reorder_stock_level" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Deprecation Percent(%) : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="number" id="txt_product_rm_depriciation_percent" className="erp_input_field erp_align-right" value={txt_product_rm_depriciation_percent} onChange={e => { if (percentValidateRef.current.percentValidate(e.target.value)) { setPDepricationPercent(e.target.value) } }} maxLength="19" optional="optional" />
                      <MDTypography variant="button" id="error_txt_product_rm_depriciation_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Purchase Tolerance Percent(%) : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="number" id="txt_purchase_tolerance_percent" className="erp_input_field erp_align-right" value={txt_purchase_tolerance_percent} onChange={e => { if (percentValidateRef.current.percentValidate(e.target.value)) { setPTolerancePercent(e.target.value) } }} maxLength="19" optional="optional" />
                      <MDTypography variant="button" id="error_txt_purchase_tolerance_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Price Type : </Form.Label>
                    </div>
                    <div className="col">

                      <div className="erp_form_radio">
                        <div className="fCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="Fixed"
                            type="radio"
                            value="F" checked={is_priceType === 'Fixed'} onClick={() => { setIsPriceType('Fixed'); }}
                            name="pPriceType"
                            defaultChecked
                          />
                        </div>
                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="Moving Average"
                            value="M" checked={is_priceType === 'Moving Average'} onClick={() => { setIsPriceType('Moving Average'); }}
                            type="radio"
                            name="pPriceType"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">ABC Analysis : </Form.Label>
                    </div>
                    <div className="col">

                      <div className="erp_form_radio">
                        <div className="fCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="A"
                            type="radio"
                            value="A" checked={is_abcAnalysis === 'A'} onClick={() => { setIsABCanalysis('A'); }}
                            name="pABCAnalysis"
                            defaultChecked
                          />
                        </div>
                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="B"
                            value="B" checked={is_abcAnalysis === 'B'} onClick={() => { setIsABCanalysis('B'); }}
                            type="radio"
                            name="pABCAnalysis"
                          />
                        </div>

                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="C"
                            value="C" checked={is_abcAnalysis === 'C'} onClick={() => { setIsABCanalysis('C'); }}
                            type="radio"
                            name="pABCAnalysis"
                          />
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">QA Required : </Form.Label>
                    </div>
                    <div className="col">

                      <div className="erp_form_radio">
                        <div className="fCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="Yes"
                            type="radio"
                            value="true" checked={is_qaRequired === true} onClick={() => { setIsQARequired(true); }}
                            name="isQaRequired"
                          />
                        </div>
                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="No"
                            value="false" checked={is_qaRequired === false} onClick={() => { setIsQARequired(false); }}
                            type="radio"
                            name="isQaRequired"
                            defaultChecked

                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Test Certificate Required : </Form.Label>
                    </div>
                    <div className="col">

                      <div className="erp_form_radio">
                        <div className="fCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="Yes"
                            type="radio"
                            value="true" checked={is_testCertified === true} onClick={() => { setIsTestCertified(true); }}
                            name="isTestCertRequired"
                            defaultChecked

                          />
                        </div>
                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="No"
                            value="false" checked={is_testCertified === false} onClick={() => { setIsTestCertified(false); }}
                            type="radio"
                            name="isTestCertRequired"

                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Remark : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control as="textarea" rows={1} id="txt_remark_commercial_entry" className="erp_input_field" value={txt_remarkforCommercialEntry} onChange={e => { setRemarkforCommercialEntrty(e.target.value); }} maxLength="255" optional="optional" />
                      <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Is Active : </Form.Label>
                    </div>
                    <div className="col">

                      <div className="erp_form_radio">
                        <div className="fCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="Yes"
                            type="radio"
                            value="true" checked={is_activeCom === true} onClick={() => { setIsActiveCom(true); }}
                            name="isCommercialActive"
                            defaultChecked

                          />
                        </div>
                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="No"
                            value="false" checked={is_activeCom === false} onClick={() => { setIsActiveCom(false); }}
                            type="radio"
                            name="isCommercialActive"
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}

                </div>
              </div>
            </form>
            {/* <div className="text-center">
              <MDButton type="button" id="btn_update" className={`erp-gb-button ${product_rm_id !== 0 && keyForViewUpdate !== 'view' ? 'display' : 'd-none'}`} variant="button"
                fontWeight="regular" onClick={() => handleGeneralEntryData('commercialEntry')} >Update</MDButton>
            </div> */}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />

      {/* Supplier Mapping Accordian */}
      {/* changes by tushar */}

      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}> 
          <Accordion.Header className="erp-form-label-md">Supplier Mapping</Accordion.Header>
          <Accordion.Body>
            {memoizedSupplierComponent}
            <div className="text-center">
              <MDButton type="button" className={`erp-gb-button ${product_rm_id !== 0 && keyForViewUpdate !== 'view' ? 'display' : 'd-none'}`} variant="button"
                fontWeight="regular" id="btn_update" onClick={() => handleGeneralEntryData('supplierMapping')} >Update</MDButton>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion> */}

      <Accordion defaultActiveKey="0" onSelect={handleAccordionSelect}>
        <Accordion.Item eventKey="SupplierMapping">
          <Accordion.Header className="erp-form-label-md">Supplier Mapping</Accordion.Header>
          <Accordion.Body>
            {memoizedSupplierComponent}
            {/* <div className="text-center">
              <MDButton type="button" className={`erp-gb-button ${product_rm_id !== 0 && keyForViewUpdate !== 'view' ? 'display' : 'd-none'}`} variant="button"
                fontWeight="regular" id="btn_update" onClick={() => handleGeneralEntryData('supplierMapping')} >Update</MDButton>
            </div> */}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />

      {/* Process Mapping Accordian */}

      {/* changes by tushar */}

      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}> */}
      <Accordion defaultActiveKey="0" onSelect={handleAccordionSelect}>
        <Accordion.Item eventKey="ProcessMapping">
          <Accordion.Header className="erp-form-label-md">Process Mapping</Accordion.Header>
          <Accordion.Body>
            {memoizedProcessComponent}
            {/* <div className="text-center">
              <MDButton type="button" id="btn_update" className={`erp-gb-button ${product_rm_id !== 0 && keyForViewUpdate !== 'view' ? 'display' : 'd-none'}`} variant="button"
                fontWeight="regular" onClick={() => handleGeneralEntryData('processMapping')} >Update</MDButton>
            </div> */}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />

      {/* QA Mapping Accordian */}
      {/* changes by tushar */}

      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}> */}
      <Accordion defaultActiveKey="0" onSelect={handleAccordionSelect}>
        <Accordion.Item eventKey="QAMapping">
          <Accordion.Header className="erp-form-label-md">Q.A. Mapping</Accordion.Header>
          <Accordion.Body>
            {memoizedQaComponent}
            {/* <div className="text-center">
              <MDButton type="button" id="btn_update" className={`erp-gb-button ${product_rm_id !== 0 && keyForViewUpdate !== 'view' ? 'display' : 'd-none'}`} variant="button"
                fontWeight="regular" onClick={() => handleGeneralEntryData('qaMapping')} >Update</MDButton>
            </div> */}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />
      <div className="erp_frm_Btns">
        {/* used the common movetolisting function instead of inline*/}
        <MDButton type="button" className="erp-gb-button text-center"
          onClick={() => { moveToListing();
            // const path = compType === 'Register' ? '/Masters/RawMaterialListing/reg' : '/Masters/RawMaterialListing'; navigate(path);
          }}
          variant="button"
          fontWeight="regular">Home</MDButton>
        <MDButton type="button" id="btn_save" className={`erp-gb-button ms-2 ${keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`} variant="button"
          fontWeight="regular" onClick={() => handleGeneralEntryData('allRawMaterialData')} >Save</MDButton>
        <MDButton
          type="button" id="btn_next"
          className="erp-gb-button ms-2"
          onClick={() => FncheckIsBomApplicable()} variant="button" fontWeight="regular"
        > Next </MDButton>

        <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'} `} variant="button" fontWeight="regular" onClick={() => deleteshow()}>Delete</MDButton>        {/* 
        <MDButton type="button" id="btn_update" className={`erp-gb-button p-1${product_rm_id !== 0 && keyForViewUpdate !== 'view' ? 'display' : 'd-none'}`} variant="button"
          fontWeight="regular" onClick={() => handleGeneralEntryData('allRawMaterialData')} >Update</MDButton> */}

        <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate !== 'view' ? 'display' : 'd-none'}`} variant="button" onClick={() => viewDocumentForm()}
          fontWeight="regular" disabled={product_rm_id === null || product_rm_id === 0}>Upload Document</MDButton>
      </div>



      <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
      <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

      {/* Add new Record Popup */}
      {
        showAddRecModal ? <>
          {/* <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
            <Modal.Header>
              <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
              <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
            </Modal.Header>
            <Modal.Body className='erp_city_modal_body'>
              {displayRecordComponent()}
            </Modal.Body>
            <Modal.Footer>
              <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
                fontWeight="regular">Close</MDButton>
            </Modal.Footer>
          </Modal > */}

          {/* // changes by tushar  */}

          <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
            <Modal.Body className='erp_city_modal_body'>
              <div className='row'>
                <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
              </div>
              {displayRecordComponent()}
            </Modal.Body>
          </Modal >
        </> : null
      }

      {/* Delete Modal */}
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
        <Modal.Body className='erp_modal_body'>
          <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
          <h6>Are you sure?</h6>
          <div className="erp-form-label">Do you wish to delete this record ?</div>
        </Modal.Body>
        <Modal.Footer className='justify-content-center'>
          <Button variant="success" className='erp-gb-button' onClick={handleClose}>
            Cancel
          </Button>&nbsp;
          <Button variant="danger" className='erp-gb-button' onClick={DeleteRecord}>Delete</Button>
        </Modal.Footer>
      </Modal>

      {/* Document modal */}
      {showDocumentForm ?
        <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
          <Modal.Header>
            <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
          </Modal.Header>
          <Modal.Body>
            <DocumentF group_id={product_rm_id} document_group={docGroup} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal> : null
      }




    </>
  )
}
export default FrmMGeneralEntry;
