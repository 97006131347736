import React from 'react'
import { useState, useEffect, useRef } from "react";
import $ from 'jquery';
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

// import react icons
import { useNavigate, useLocation } from "react-router-dom";

// File Imports
import ComboBox from "Features/ComboBox";
import FrmMProductTypeEntry from 'Masters/MProductType/FrmProductEntry';
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import ConfigConstants from "assets/Constants/config-constant";

import FrmValidations from 'FrmGeneric/FrmValidations';

export default function FrmMProductCategory1Entry(props) {

  const { state } = useLocation();
  const { productcategory1ID = 0, keyForViewUpdate = 'Add', compType = 'Masters' } = state || {};

  const configConstants = ConfigConstants();
  const { COMPANY_ID, UserName } = configConstants;


  var activeValue = '';


  const comboDataAPiCall = useRef();
  const validate = useRef();


  // combobox option
  const [productTypeOptions, setProductTypeOptions] = useState([]);

  // Add Product Type Form Fields

  const [product_category1_id, setProductCategory1Id] = useState(productcategory1ID);
  const [cmb_product_type_id, setProductTypeId] = useState();
  const [txt_product_category1_name, setProductcategory1Name] = useState('');
  const [txt_product_category1_short_name, setProductcategory1ShortName] = useState('');
  const [txt_remark, setRemark] = useState('');

  const [actionType, setActionType] = useState('')


  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // Show ADD record Modal
  const handleCloseRecModal = async () => {

    const productTypeApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_type", "", "")
    setProductTypeOptions(productTypeApiCall)

    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')

    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)
  }
  const [showAddRecModal, setShowAddRecModal] = useState(false);

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
      navigate(`/Masters/ProductCategory1Listing`)
    }
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  // For navigate
  const navigate = useNavigate();

  useEffect(async () => {
    ActionType();
    addmodalproduct();
    await fillComobos();
    if (productcategory1ID !== 0) {
      await FnCheckUpdateResponce();
    }

  }, [])


  const addmodalproduct = () => {
    if (props.product_type !== "" && props.product_type !== undefined) {
      setProductTypeId(props.product_type)
      $('#cmb_product_type_id').attr('disabled', true);
    }
    else {
      $('#cmb_product_type_id').attr('disabled', false);
    }

  }

  const comboOnChange = async (key) => {
    switch (key) {
      case 'productType':
        var productTpVal = document.getElementById('cmb_product_type_id').value;
        setProductTypeId(productTpVal)
        if (productTpVal === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
          }, 200)

        }
        break;
    }
  }

  const fillComobos = async () => {
    try {
      const productTypeApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_type", "", "")
      setProductTypeOptions(productTypeApiCall)
    } catch (error) {
      console.log("error: ", error);
      navigate('/Error')
    }
  }



  const handleSubmit = async () => {

    try {
      // changes validation done by tushar

      const checkIsValidate = await validate.current.validateForm('productcategory1FormId');
      if (checkIsValidate) {

        var active;
        activeValue = document.querySelector('input[name=isactive]:checked').value

        switch (activeValue) {
          case '1': active = true; break;
          case '0': active = false; break;

        }

        const data = {
          company_id: COMPANY_ID,
          product_category1_id: product_category1_id,
          created_by: UserName,
          modified_by: product_category1_id === 0 ? null : UserName,
          product_type_id: cmb_product_type_id,
          product_category1_name: txt_product_category1_name,
          product_category1_short_name: txt_product_category1_short_name.trim(),
          remark: txt_remark,
          is_active: active,

        };
        const requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productcategory1/FnAddUpdateRecord`, requestOptions)
        const responce = await apicall.json()
        console.log("response error: ", responce);
        if (responce.error !== "") {
          console.log("response error: ", responce.error);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)

        } else {
          const evitCache = comboDataAPiCall.current.evitCache();
          console.log(evitCache);
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);
          clearFormFields();

        }

      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  };
  const clearFormFields = () => {
    setProductTypeId('');
    setProductcategory1Name('');
    setProductcategory1ShortName('');
    setRemark('');

  }

  const FnCheckUpdateResponce = async () => {
    try {

      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productcategory1/FnShowParticularRecordForUpdate/${product_category1_id}`)
      const updateRes = await apiCall.json();

      let resp = JSON.parse(updateRes.data)
      await fillComobos();
      setProductCategory1Id(resp.product_category1_id)
      setProductTypeId(resp.product_type_id);
      setProductcategory1Name(resp.product_category1_name);
      setProductcategory1ShortName(resp.product_category1_short_name);
      setRemark(resp.remark);

      switch (resp.is_active) {
        case true:
          document.querySelector('input[name="isactive"][value="1"]').checked = true;
          break;
        case false:
          document.querySelector('input[name="isactive"][value="0"]').checked = true;
          break;
      }

      switch (keyForViewUpdate) {
        case 'update':
          $('#saveBtn').attr('disabled', false);
          $('#cmb_product_type_id').attr('disabled', true);
          $('#txt_product_category1_name').attr('disabled', true);
          break;
        case 'view':
          $("input[type=radio]").attr('disabled', true);
          await validate.current.readOnly("productcategory1FormId");
          break;
      }
    } catch (error) {
      console.log("error", error)
      navigate('/Error')
    }
  }



  const validateFields = () => {
    validate.current.validateFieldsOnChange('productcategory1FormId')
  }




  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'update':
        setActionType('(Modify)');
        break;
      case 'view':
        setActionType('(View)');
        break;
      default:
        setActionType('(Create)');
        break;
    }
  };

  return (

    <>
      <div className='erp_top_Form'>
        <ComboBox ref={comboDataAPiCall} />
        <FrmValidations ref={validate} />

        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg main_heding'>Product Category 1 {actionType}  </label>
          </div>
          <form id="productcategory1FormId">
            <div className='row erp_transporter_div'>

              {/* first row */}
              <div className="col-sm-6 erp_form_col_div">
                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Product Type<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_product_type_id" className="form-select form-select-sm" value={cmb_product_type_id} onChange={() => { comboOnChange('productType'); validateFields(); }} disabled={keyForViewUpdate === 'update'}   >
                      <option value="">Select</option>
                      <option value="0">Add New Record +</option>
                      {productTypeOptions?.map(productType => (
                        <option value={productType.field_id}>{productType.field_name}</option>

                      ))}
                    </select>
                    <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label"> Category1 Name<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_category1_name" className="erp_input_field" value={txt_product_category1_name} onChange={e => { setProductcategory1Name(e.target.value); validateFields(); }} maxLength="255" />
                    <MDTypography variant="button" id="error_txt_product_category1_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>


                <div className="row">
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label">Short Name</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_category1_short_name" className="erp_input_field" value={txt_product_category1_short_name} onChange={e => { setProductcategory1ShortName(e.target.value.toUpperCase()); validateFields(); }} maxLength="4" optional="optional" />
                    <MDTypography variant="button" id="error_product_category1_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>

                </div>
              </div>

              {/* second row */}

              <div className="col-sm-6 erp_form_col_div">
                <div className="row">
                  <div className='col-sm-4'>
                    <Form.Label className="erp-form-label"> Remark </Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control as="textarea" rows={1} className="erp_txt_area" id="txt_remark" value={txt_remark} onChange={e => { setRemark(e.target.value); validateFields(); }} maxLength="255" optional="optional" />
                    <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">Is Active</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="Yes"
                          type="radio"
                          value="1"
                          name="isactive"
                          defaultChecked
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="No"
                          value="0"
                          type="radio"
                          name="isactive"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </form>
          <div className="card-footer py-0 text-center">
            <MDButton type="button" className="erp-gb-button"

              onClick={() => {
                const path = compType === 'Register' ? '/Masters/ProductCategory1Listing/reg' : '/Masters/ProductCategory1Listing';
                navigate(path);
              }}
              variant="button"
              fontWeight="regular" disabled={props.btn_disabled ? true : false}
            >Back</MDButton>
            {/* keyForViewUpdate */}
            {keyForViewUpdate !== 'view' ? (
              <MDButton type="submit" className="erp-gb-button erp_MLeft_btn" id="saveBtn" variant="button" fontWeight="regular" onClick={handleSubmit}>  save  </MDButton>) : null}
          </div>
        </div>

        {/* Add new Record Popup */}
        <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
          {/* <Modal.Header>
            <Modal.Title className='erp_modal_title'>{cat2ModalHeaderName}</Modal.Title>
            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecCat2Modal}></button></span>
          </Modal.Header> */}
          <Modal.Body className='erp_city_modal_body'>
            <div className='row'>
              <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
            </div>
            <FrmMProductTypeEntry btn_disabled />
          </Modal.Body>
          {/* <Modal.Footer>
            <MDButton type="button" onClick={handleCloseRecCat2Modal} className="btn erp-gb-button" variant="button"
              fontWeight="regular">Close</MDButton>

          </Modal.Footer> */}
        </Modal >
        {/* // chnages by tushar */}

        {/* Add new Record Popup */}
        {/* < Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
          <Modal.Header>
            <Modal.Title className='erp_modal_title'>Product Type</Modal.Title>
            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
          </Modal.Header>
          <Modal.Body className='erp_city_modal_body'>
            <FrmMProductTypeEntry btn_disabled />
          </Modal.Body>
          <Modal.Footer>
            <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
              fontWeight="regular">Close</MDButton>

          </Modal.Footer>
        </Modal > */}

        {/* Success Msg Popup */}
        < SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()
        } show={[showSuccessMsgModal, succMsg]} />
        {/* Error Msg Popup */}
        < ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

        {/* </div > */}

      </div >

    </>
  )
}
