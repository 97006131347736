import React from 'react'
import { useState, useEffect, useRef } from "react";
import $ from 'jquery';


// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap
import Form from 'react-bootstrap/Form';

// import react icons
import { useLocation, useNavigate } from "react-router-dom";
import FrmValidations from "FrmGeneric/FrmValidations";

// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import ConfigConstants from "assets/Constants/config-constant";

export default function FrmMProductTypeEntry(props) {
    const validate = useRef();
    var activeValue = '';

    // Call ConfigConstants to get the configuration constants
    const configConstants = ConfigConstants();
    const { COMPANY_ID, UserName } = configConstants;

    const { state } = useLocation()
    const { producttypeID = 0, keyForViewUpdate = 'Add', compType = 'Masters' } = state || {};


    const child = useRef();

    // combobox Option
    const [productTypeGroupOption, setProductTypeGroupOption] = useState([]);

    // For navigate
    const navigate = useNavigate();

    // Add Product Type Form Fields
    const [product_type_id, setProducttypeId] = useState(producttypeID === '' ? 0 : producttypeID);
    const [txt_product_type_name, setProducttypeName] = useState('');
    const [txt_product_type_short_name, setProducttypeShortName] = useState('');
    const [txt_product_type_group, setProducttypeGroup] = useState('');
    const [txt_remark, setRemark] = useState('');
    const [actionType, setActionType] = useState('')
    const [actionLabel, setActionLabel] = useState('Save')

    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Masters/ProductTypeListing`);
        }
    }
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');


    useEffect(async () => {
        await FnFetchPropertyRecords();
        if (product_type_id !== 0) {
            await FnCheckUpdateResponce();
        }
        await ActionType();

    }, [])

    const handleSubmit = async () => {
        try {
            const checkIsValidate = await validate.current.validateForm("producttypeFormId");;
            if (checkIsValidate === true) {
                var active;
                activeValue = document.querySelector('input[name=isactive]:checked').value

                switch (activeValue) {
                    case '1': active = true; break;
                    case '0': active = false; break;

                }
                const data = {
                    company_id: COMPANY_ID,
                    product_type_id: product_type_id,
                    created_by: UserName,
                    modified_by: product_type_id === 0 ? null : UserName,
                    product_type_name: txt_product_type_name,
                    product_type_short_name: txt_product_type_short_name,
                    product_type_group: txt_product_type_group,
                    remark: txt_remark,
                    is_active: active,

                };
                console.log(data);
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/producttype/FnAddUpdateRecord`, requestOptions)
                const responce = await apicall.json()
                console.log("response error: ", responce);
                if (responce.error !== "") {
                    console.log("response error: ", responce.error);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)

                } else {
                    const evitCache = child.current.evitCache();
                    console.log(evitCache);
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                    clearFormFields();

                }

            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }

    };
    const clearFormFields = () => {
        setProducttypeName('');
        setProducttypeShortName('');
        setProducttypeGroup('');
        setRemark('');

    }

    const FnCheckUpdateResponce = async () => {
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/producttype/FnShowParticularRecordForUpdate/${producttypeID}`)
            const updateRes = await apiCall.json();

            let resp = JSON.parse(updateRes.data)
            setProducttypeId(resp.product_type_id)
            setProducttypeName(resp.product_type_name);
            setProducttypeShortName(resp.product_type_short_name);
            setProducttypeGroup(resp.product_type_group);
            setRemark(resp.remark);
            $('#saveBtn').show();

            switch (resp.is_active) {
                case true:
                    document.querySelector('input[name="isactive"][value="1"]').checked = true;
                    break;
                case false:
                    document.querySelector('input[name="isactive"][value="0"]').checked = true;
                    break;
            }




        } catch (error) {
            console.log("error", error)
            navigate('/Error')
        }
    }
    const FnFetchPropertyRecords = async () => {
        if (child.current) {
            const productTypeGroupList = await child.current.fillComboBox("ProductTypeGroups", "", "")
            setProductTypeGroupOption(productTypeGroupList)
        }
    }


    // test case 7 shivanjali
    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modify)');
                setActionLabel('Update')
                $('#saveBtn').attr('disabled', false);
                $('#txt_product_type_name').attr('disabled', true);
                break;
            case 'view':
                setActionType('(View)');
                $("input[type=radio]").attr('disabled', true);
                await validate.current.readOnly("producttypeFormId");
                break;
            default:
                setActionType('(Create)');
                break;
        }
    };


    const validateFields = () => {
        validate.current.validateFieldsOnChange('producttypeFormId')
    }

    return (

        //test case 1,2 shivanjali
        <>
            <ComboBox ref={child} />
            <FrmValidations ref={validate} />
            <div className="erp_top_Form">
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg main_heding'>Product Type{actionType}</label>
                    </div>

                    <form id="producttypeFormId">
                        <div className="row erp_transporter_div text-start">

                            {/* first row */}

                            <div className="col-sm-6 erp_filter_group-by-result">
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        {/* test 4 shivanjali */}
                                        <Form.Label className="erp-form-label">Product Type Name<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_product_type_name" className="erp_input_field" value={txt_product_type_name} onChange={e => { setProducttypeName(e.target.value); validateFields(); }} maxLength="255" />
                                        <MDTypography variant="button" id="error_txt_product_type_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label"> Short Name<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_product_type_short_name" className="erp_input_field" value={txt_product_type_short_name} onChange={e => { setProducttypeShortName(e.target.value.toUpperCase()); validateFields(); }} maxLength="4" />
                                        <MDTypography variant="button" id="error_txt_product_type_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Product Type Group <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select size="sm" id="txt_product_type_group" value={txt_product_type_group} className="form-select form-select-sm erp_input_field" onChange={(e) => setProducttypeGroup(e.target.value)}>
                                            <option value="">Select</option>
                                            {productTypeGroupOption?.map(product_type_group => (
                                                <option value={product_type_group.field_name}>{product_type_group.field_name}</option>

                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_txt_product_type_group" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>


                            </div>

                            {/* second row */}

                            {/* test 3 shivanjali */}

                            <div className="col-sm-6 erp_filter_group-by-result">
                                <div className="row">
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label"> Remark</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control as="textarea" rows={2} id="txt_remark" className="erp_txt_area" value={txt_remark} onChange={e => { setRemark(e.target.value); validateFields(); }} optional="optional" maxLength="255" />
                                        <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-3">
                                        {/* test case 5 shivanjali */}
                                        <Form.Label className="erp-form-label">Is Active</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck">
                                                <Form.Check
                                                    className="erp_radio_button"
                                                    label="Yes"
                                                    type="radio"
                                                    value="1"
                                                    name="isactive"
                                                    defaultChecked
                                                />
                                            </div>
                                            <div className="sCheck">
                                                <Form.Check
                                                    className="erp_radio_button"
                                                    label="No"
                                                    value="0"
                                                    type="radio"
                                                    name="isactive"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </form>
                    <div className="card-footer py-0 text-center">
                        <MDButton type="button" className="erp-gb-button"

                            onClick={() => {
                                const path = compType === 'Register' ? '/Masters/ProductTypeListing/reg' : '/Masters/ProductTypeListing';
                                navigate(path);
                            }} variant="button"
                            fontWeight="regular" disabled={props.btn_disabled ? true : false}>Back</MDButton>
                        <MDButton type="submit" onClick={handleSubmit} id="saveBtn" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            fontWeight="regular">{actionLabel}</MDButton>

                    </div >
                </div>


                {/* Success Msg Popup */}
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                {/* Error Msg Popup */}
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

            </div >

        </>
    )

}
