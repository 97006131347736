import React from 'react'
import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

 // File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import FrmMProductTypeEntry from 'Masters/MProductType/FrmProductEntry';
import FrmMProductCategory1Entry from 'Masters/MProductCategory1/FrmProductCategory1Entry';
import FrmMProductCategory2Entry from 'Masters/MProductCategory2/FrmMProductCategory2Entry';
import FrmValidations from 'FrmGeneric/FrmValidations';
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";



export default function FrmMProductCategory3Entry(props) {
  // Call ConfigConstants to get the configuration constants
  const configConstants = ConfigConstants();
  const { COMPANY_ID, UserName } = configConstants;

  // use refs
  const comboDataAPiCall = useRef();
  const validate = useRef();

  // For navigate
  const navigate = useNavigate();

  // combobox Option
  const [category2Options, setCategory2Options] = useState([])
  const [productTypeOptions, setProductTypeOptions] = useState([]);


  const { state } = useLocation();
  const { productcategory3ID = 0, keyForViewUpdate = 'Add', compType = 'Masters' } = state || '';

  // Add Product Type Form Fields

  const [product_category3_ID, setProductCategory3ID] = useState(productcategory3ID === '' ? 0 : productcategory3ID);
  const [cmb_product_category2_id, setProductCategory2ID] = useState('');
  const [cmb_product_category1_id, setProductCategory1ID] = useState('');
  const [productCategory1Options, setProductCategory1Options] = useState([])
  const [cmb_product_type_id, setProductTypeId] = useState();
  const [txt_product_category3_name, setProductcategory3Name] = useState('');
  const [txt_product_category3_short_name, setProductcategory3ShortName] = useState('');
  const [txt_remark, setRemark] = useState('');

  const [actionType, setActionType] = useState('')
  const [actionLabel, setActionLabel] = useState('Save')


  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');


  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
      navigate(`/Masters/ProductCategory3Listing`);
    }
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  // Add rec modal
  const closeCat3RecModal = async () => {
    switch (cat3ModalHeaderName) {
      case 'Product Type':
        const productTypeApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_type", "", "")
        setProductTypeOptions(productTypeApiCall)
        break;
      case 'Product Category-1':
        var productTpVal = document.getElementById('cmb_product_type_id').value;

        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.table = "smv_product_category1"
        globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTpVal });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        const productCategory1ApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
        setProductCategory1Options(productCategory1ApiCall);
        break;

      case 'Product Category-2':
        var productCat1Val = document.getElementById('cmb_product_category1_id').value;
        var productTpVal = document.getElementById('cmb_product_type_id').value;
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.table = "smv_product_category2"
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTpVal });
        globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: productCat1Val });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        var productCat2ApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
        setCategory2Options(productCat2ApiCall)
        break;

      default:
        break;
    }
    setShowAddRecCat3Modal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({
        "padding-top": "110px"
      });
    }, 200)

  }
  const [showAddRecCat3Modal, setShowAddRecCat3Modal] = useState(false);
  const [cat3ModalHeaderName, setCat3ModalHeaderName] = useState('')

  useEffect(async () => {
    await ActionType()
    await fillComobos();
    if (product_category3_ID !== 0) {
      await FnCheckUpdateResponce();
    }
  }, [])

  const fillComobos = async () => {
    const productTypeApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_type", "", "")
    setProductTypeOptions(productTypeApiCall)
  }

  const handleSubmit = async () => {
    try {
      const checkIsValidate = await validate.current.validateForm('productcategory3FormId');
      if (checkIsValidate) {
        var active;
        var activeValue = document.querySelector('input[name=isactive]:checked').value
        switch (activeValue) {
          case '1': active = true; break;
          case '0': active = false; break;
        }
        const data = {
          company_id: COMPANY_ID,
          product_category3_id: product_category3_ID,
          created_by: UserName,
          modified_by: product_category3_ID === 0 ? null : UserName,
          product_category1_id: cmb_product_category1_id,
          product_category2_id: cmb_product_category2_id,
          product_type_id: cmb_product_type_id,
          product_category3_name: txt_product_category3_name,
          product_category3_short_name: txt_product_category3_short_name.trim(),
          remark: txt_remark,
          is_active: active,

        };
        console.log(data);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productcategory3/FnAddUpdateRecord`, requestOptions)
        const responce = await apicall.json()
        console.log("response error: ", responce);
        if (responce.error !== "") {
          console.log("response error: ", responce.error);
          setErrMsg(responce.error)
          setShowErrorMsgModal(true)

        } else {
          const evitCache = comboDataAPiCall.current.evitCache();
          console.log(evitCache);
          setSuccMsg(responce.message)
          setShowSuccessMsgModal(true);


        }

      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  };


  const FnCheckUpdateResponce = async () => {
    try {
      // var productcategory3ID = sessionStorage.getItem("productcategory3ID");


      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productcategory3/FnShowParticularRecordForUpdate/${product_category3_ID}`)
      const updateRes = await apiCall.json();

      let resp = JSON.parse(updateRes.data)
      await fillComobos();

      setProductTypeId(resp.product_type_id);
      await comboOnChange('productType')
      setProductCategory1ID(resp.product_category1_id);
      await comboOnChange('productCategory1')
      setProductCategory2ID(resp.product_category2_id);
      setProductcategory3Name(resp.product_category3_name);
      setProductcategory3ShortName(resp.product_category3_short_name);
      setRemark(resp.remark);

      switch (resp.is_active) {
        case true:
          document.querySelector('input[name="isactive"][value="1"]').checked = true;
          break;
        case false:
          document.querySelector('input[name="isactive"][value="0"]').checked = true;
          break;
      }

    } catch (error) {
      console.log("error", error)
      navigate('/Error')
    }
  }

  const comboOnChange = async (key) => {
    switch (key) {
      case 'productType':
        var productTpVal = document.getElementById('cmb_product_type_id').value;
        setProductTypeId(productTpVal)
        if (productTpVal === '0') {
          setProductCategory1ID('')
          setProductCategory2ID('')
          setCat3ModalHeaderName('Product Type')
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setShowAddRecCat3Modal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
          }, 200)
        } else {

          $('#error_cmb_product_type_id').hide();
          resetGlobalQuery();
          globalQuery.columns.push("field_id");
          globalQuery.columns.push("field_name");
          globalQuery.table = "smv_product_category1"
          globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTpVal });
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          const productCategory1ApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
          setProductCategory1Options(productCategory1ApiCall);
          setProductCategory1ID('')
          setProductCategory2ID('')

        }

        break;

      case 'productCategory1':
        var productCat1Val = document.getElementById('cmb_product_category1_id').value;
        var productTpVal = document.getElementById('cmb_product_type_id').value;
        if (productCat1Val === '0') {
          setProductCategory1ID(productCat1Val)
          setCat3ModalHeaderName('Product Category-1')
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setShowAddRecCat3Modal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
          }, 200)
        } else {
          setProductCategory1ID(productCat1Val)
          resetGlobalQuery();
          globalQuery.columns.push("field_id");
          globalQuery.columns.push("field_name");
          globalQuery.table = "smv_product_category2"
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTpVal });
          globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: productCat1Val });
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });


          var productCat2ApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
          setCategory2Options(productCat2ApiCall)

        }
        break;


      case 'productCategory2':
        var productCat2Val = document.getElementById('cmb_product_category2_id').value;
        if (productCat2Val === '0') {
          setProductCategory2ID(productCat1Val)
          setCat3ModalHeaderName('Product Category-2')
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setShowAddRecCat3Modal(true)
          setTimeout(() => {
            $(".erp_top_Form").eq(1).css("padding-top", "0px");
          }, 200)
        }
        break;


    }
  }

  const cat3DisplayRecordComponent = () => {
    switch (cat3ModalHeaderName) {
      case 'Product Type':
        return <FrmMProductTypeEntry btn_disabled />;
      case 'Product Category-1':
        return <FrmMProductCategory1Entry btn_disabled />;
      case 'Product Category-2':
        return <FrmMProductCategory2Entry btn_disabled />

      default:
        return null;
    }
  }



  const validateFields = () => {
    validate.current.validateFieldsOnChange('productcategory3FormId')
  }




  const ActionType = async () => {
    switch (keyForViewUpdate) {

      case 'update':
        setActionType('(Modify)');
        setActionLabel('Update')
        $('#saveBtn').attr('disabled', false);
        $('#cmb_product_type_id').attr('disabled', true);
        $('#cmb_product_category1_id').attr('disabled', true);
        $('#cmb_product_category2_id').attr('disabled', true);
        $('#txt_product_category3_name').attr('disabled', true);
        $('#txt_product_category3_short_name').attr('disabled', true);
        break;
      case 'view':
        setActionType('(View)');
        await validate.current.readOnly("productcategory3FormId");
        $("input[type=radio]").attr('disabled', true);
        break;
      default:
        setActionType('(Create)');
        break;
    }

  };

  return (
    <>
      <div className='erp_top_Form'>

        <ComboBox ref={comboDataAPiCall} />
        <FrmValidations ref={validate} />
        <div className='card p-1'>
          <div className='card-header text-center py-0'>
            <label className='erp-form-label-lg main_heding'>Product Category-3{actionType}   </label>
          </div>

          <form id="productcategory3FormId">
            <div className="row erp_transporter_div">
              <div className="col-sm-6 erp_form_col_div">
                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Type<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_product_type_id" className="form-select form-select-sm" value={cmb_product_type_id} onChange={() => { comboOnChange('productType'); validateFields(); }}>
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {productTypeOptions?.map(productType => (
                        <option value={productType.field_id}>{productType.field_name}</option>

                      ))}

                    </select>
                    <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Category1<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_product_category1_id" className="form-select form-select-sm" value={cmb_product_category1_id} onChange={() => { comboOnChange('productCategory1'); validateFields(); }}>
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {productCategory1Options?.map(productCategory1 => (
                        <option value={productCategory1.field_id}>{productCategory1.field_name}</option>

                      ))}

                    </select>
                    <MDTypography variant="button" id="error_cmb_product_category1_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Product Category2<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_product_category2_id" className="form-select form-select-sm" value={cmb_product_category2_id} onChange={(e) => { setProductCategory2ID(e.target.value); comboOnChange('productCategory2'); validateFields(); }}>
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {category2Options?.map(productCategory2 => (
                        <option value={productCategory2.field_id}>{productCategory2.field_name}</option>

                      ))}

                    </select>
                    <MDTypography variant="button" id="error_cmb_product_category2_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> Category3 Name<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_category3_name" className="erp_input_field" value={txt_product_category3_name} onChange={e => { setProductcategory3Name(e.target.value); validateFields(); }} maxLength="255" />
                    <MDTypography variant="button" id="error_txt_product_category3_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 erp_form_col_div">
                <div className="row">
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Short Name</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_product_category3_short_name" className="erp_input_field" value={txt_product_category3_short_name} onChange={e => { setProductcategory3ShortName(e.target.value.toUpperCase()); validateFields(); }} maxLength="4" optional="optional" />
                    <MDTypography variant="button" id="error_product_category3_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>

                </div>

                <div className="row">
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> Remark </Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control as="textarea" rows={1} className="erp_txt_area" id="txt_remark" value={txt_remark} onChange={e => { setRemark(e.target.value); validateFields(); }} maxLength="255" optional="optional" />
                    <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">Is Active</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="Yes"
                          type="radio"
                          value="1"
                          name="isactive"
                          defaultChecked
                        />
                      </div>
                      <div className="sCheck">
                        <Form.Check
                          className="erp_radio_button"
                          label="No"
                          value="0"
                          type="radio"
                          name="isactive"
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </form>
          <div className="card-footer py-0 text-center">
            <MDButton type="button" className="erp-gb-button"
              onClick={() => {
                const path = compType === 'Register' ? '/Masters/ProductCategory3Listing/reg' : '/Masters/ProductCategory3Listing';
                navigate(path);
              }} variant="button"
              fontWeight="regular" disabled={props.btn_disabled ? true : false}
            >Back</MDButton>
            
            {keyForViewUpdate !== 'view' ? (
              <MDButton type="submit" onClick={handleSubmit} id="saveBtn" className="erp-gb-button erp_MLeft_btn " variant="button"
                fontWeight="regular">save</MDButton>
            ) : null}
          </div >
        </div>

        <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
        <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

        {/* Add new Record Popup */}
        <Modal size="lg" show={showAddRecCat3Modal} onHide={closeCat3RecModal} backdrop="static" keyboard={false} centered >
          {/* <Modal.Header>
            <Modal.Title className='erp_modal_title'>{cat2ModalHeaderName}</Modal.Title>
            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeCat3RecModal}></button></span>
          </Modal.Header> */}
          <Modal.Body className='erp_city_modal_body'>
            <div className='row'>
              <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeCat3RecModal}></button></div>
            </div>
            {cat3DisplayRecordComponent()}
          </Modal.Body>
          {/* <Modal.Footer>
            <MDButton type="button" onClick={handleCloseRecCat2Modal} className="btn erp-gb-button" variant="button"
              fontWeight="regular">Close</MDButton>

          </Modal.Footer> */}
        </Modal >





        {/* Add new Record Popup */}
        {/* <Modal size="lg" show={showAddRecCat3Modal} onHide={closeCat3RecModal} backdrop="static" keyboard={false} centered >
          <Modal.Header>
            <Modal.Title className='erp_modal_title'>{cat3ModalHeaderName}</Modal.Title>
            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeCat3RecModal}></button></span>
          </Modal.Header>
          <Modal.Body className='erp_city_modal_body'>
            {cat3DisplayRecordComponent()}
          </Modal.Body>
          <Modal.Footer>
            <MDButton type="button" onClick={closeCat3RecModal} className="btn erp-gb-button" variant="button"
              fontWeight="regular">Close</MDButton>

          </Modal.Footer>
        </Modal > */}
      </div>

    </>
  )
}
