import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $, { error } from 'jquery';

// Imports React bootstrap
import Tooltip from "@mui/material/Tooltip";

import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table, Button } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// React icons
import { MdRefresh } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";

//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ExcelExport from "Features/Exports/ExcelExport";
import DocumentF from "Features/Document";
import FrmMEmployeesEntry from "Masters/MEmployee/MEmployeeEntry/components/FrmMEmployeesEntry";
import { CircularProgress } from "@material-ui/core";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import { Link } from "react-router-dom";
import DepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";


export default function FrmIndentMaterialIssueEntry() {
    const configConstants = ConfigConstants();
    const { FINANCIAL_SHORT_NAME, COMPANY_ID, COMPANY_BRANCH_ID, UserId, UserName } = configConstants;

    const { state } = useLocation();
    const { idList, keyForViewUpdate, compType } = state || {};
    const [isLoading, setIsLoading] = useState(false);
    const [isApprove, setIsApprove] = useState(false)
    const [IsView, setIsView] = useState(false)

    let docGroup = "Indent Material Issue"

    // UseRefs
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();
    const navigator = useNavigate();

    let fin_year = "";
    let issueVersion = "";


    const [issue_master_transaction_id, setIssueMasterTransaction_id] = useState(0);

    //combo options
    const [materialtypeOptions, setmaterialtypeOptions] = useState([])

    const [customerOptions, setCustomerOptions] = useState([])
    const [departmentIdOPtions, setDepartmentIdOptions] = useState([])
    const [indentedByIdOptions, setIndentedByIdOptions] = useState([])
    const [customerOrdOpts, setCustomerOrdOpts] = useState([]);
    const [indent_SummaryList, setIndentSummaryList] = useState([])

    const [materialIssueDetailsData, setMaterialIssueDetailsData] = useState([])

    // Hooks for handle the accordions.
    const [openDetailsAccord, setOpenDetailsAccord] = useState(true);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    // Confirmation Modal fields
    const [showConfirmationModal, setShowConfiemationModal] = useState(false)

    const [messageForConfirmation, setMessageForConfirmation] = useState(false)
    const closeConfirmationModal = async (key) => {
        switch (key) {
            case 'Exit':
                setShowSuccessMsgModal(false);
                break;
            default:
                break;
        }
        moveToListing();
    }


    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    // IUndent modal list
    const [showIndentModalForm, setShowIndentModalForm] = useState(false);
    // doc list
    const [docData, setDocumentData] = useState([]);

    const viewIndentsmodal = async () => {
        let checkIsValidate = await validate.current.validateForm("material_issue_form_id");
        if (checkIsValidate === true) {
            loadIndenstList();
            setShowIndentModalForm(true);
        }
    }
    // For navigate
    const navigate = useNavigate();

    //Form Fields
    const [cmb_material_type, setmaterialType] = useState('');

    const [txt_material_issue_no, setMaterialIssueNo] = useState(0);
    const [txt_material_issue_version, setmaterialissueversion] = useState(1);
    const [txt_issued_by, setissued_by] = useState(UserName);
    // const [txt_indent_no, setIndentno] = useState(0);
    // const [txt_indent_version, setIndentVersion] = useState(1);
    // const [cmb_indent_type, setIndentType] = useState('');
    const [chk_issue_source, setIssueSource] = useState('I')
    const [cmb_customer, setCustomerId] = useState('');
    const [cmb_customer_order_no, setCustomerOrderNo] = useState('')
    const [customer_order_date, setCustomerOrderDate] = useState('');
    const [cmb_department, setDepartmentId] = useState('');
    const [cmb_indented_by, setIndentedById] = useState('');
    const [txt_remark, setRemark] = useState('');
    const [chk_isactive, setIsActive] = useState(true);
    const [chk_Issue_status, setIssueStatus] = useState("P");

    // delete popup
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [showAddRecIndentIssueModal, setshowAddRecIndentIssueModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')
    // Document Form
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const handleCloseDocumentForm = async () => {
        await showDocumentRecords();
        setShowDocumentForm(false)
    };
    const viewDocumentForm = () => {
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            setShowDocumentForm(true);
        }
    }

    const todayDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const currentDate = new Date().toISOString().split('T')[0];
    // const [txt_indent_date, setIndentDate] = useState(todayDate);
    const [txt_schedule_date, setScheduleDate] = useState(todayDate);
    const [cmb_approved_by_id, setApproved_by_id] = useState("");
    const [approvedByOptions, setApprovedByOptions] = useState([]);
    const [txt_approved_date, setApproved_date] = useState("");
    const [dt_material_issue_date, setmaterialissueDate] = useState(todayDate);



    // Show ADD record Modals
    const closeRecordAddIndentIssueModal = async () => {
        switch (modalHeaderName) {
            case 'Indent By':
                comboOnChange('departmentId')
                break;
            case 'Department':
                const departmentIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_department", "", "")
                setDepartmentIdOptions(departmentIdApiCall)
                break;

            case 'Material Type':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("product_type_short_name");
                globalQuery.table = "smv_product_type"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["RM", "FG"] });
                comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    .then(getProductType => {
                        setmaterialtypeOptions(getProductType)
                        console.log("Product Type List: ", getProductType);
                    });

                break;
            default:
                break;
        }
        setshowAddRecIndentIssueModal(false);

    }


    useEffect(async () => {
        setIsLoading(true);
        try {
            FnSetFieldUserAccess();
            await fillComobos();
            if (idList !== "") {
                await FnCheckUpdateResponce();
            } else {
                await generateIndentIssueNo();
            }
            FnSetFieldUserAccess();
        } catch (error) {
            console.error(error);
        } finally {
            // End loading
            setIsLoading(false);
        }
    }, [])


    const deleteRecords = async () => {
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentIssueMaster/FnDeleteRecord/${txt_material_issue_version}/${COMPANY_ID}/${UserName}?issue_no=${txt_material_issue_no}`, method)
            const responce = await deleteApiCall.json();
            console.log("indent issue Delete entry: ", responce);
            if (responce.success == '1') {
                setShow(false)
                moveToListing();
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }


    // -------------------------------End Common terms -------------------------------------------------------------------------

    const fillComobos = async () => {
        try {

            // resetGlobalQuery();
            // globalQuery.columns = ["field_id", "field_name", "is_sez", "cust_branch_city_id", "cust_branch_state_id", "cust_branch_gst_no", "cust_branch_pan_no"]
            // globalQuery.table = "cmv_customer"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // comboDataFunc.current.fillFiltersCombo(globalQuery)
            //     .then(getCustomers => {
            //         setCustomerList(getCustomers);
            //         console.log("Customers List: ", getCustomers);
            //     });

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("product_type_short_name");
            globalQuery.table = "smv_product_type"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["RM", "FG"] });


            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getProductType => {
                    setmaterialtypeOptions(getProductType)
                    console.log("Product Type List: ", getProductType);
                });

            comboDataAPiCall.current.fillMasterData("cmv_customer", "", "")
                .then(getCustomersorderno => {
                    setCustomerOptions(getCustomersorderno)
                    console.log("Customers order no List: ", getCustomersorderno);
                });


            comboDataAPiCall.current.fillMasterData("cmv_department", "", "")
                .then(getdepartmentId => {
                    setDepartmentIdOptions(getdepartmentId)
                    console.log("Department List: ", getdepartmentId);
                });


            comboDataAPiCall.current.fillMasterData("cmv_employee", "", "")
                .then(getidentedbyId => {
                    setApprovedByOptions(getidentedbyId)
                    console.log("identedbyId List: ", getidentedbyId);
                });
            // setApprovedByOptions(identedbyIdApiCall)

            // Set the Default Intented By and It's Department.
            if (keyForViewUpdate !== 'view' && keyForViewUpdate !== 'update' && keyForViewUpdate !== 'approve') {
                resetGlobalQuery();
                globalQuery.columns = ["field_id", 'field_name', 'department_id'];
                globalQuery.table = "cmv_employee_summary";
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const allEmployeesList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                let defaultIndentedBy = allEmployeesList.find(empl => empl.field_id === parseInt(UserId));

                // Set the default Department as per the logged In User.
                if (defaultIndentedBy) {
                    setIndentedById(UserId);
                    setDepartmentId(defaultIndentedBy.department_id);
                    let emplListByDefaultDept = allEmployeesList.filter(empl => empl.department_id === defaultIndentedBy.department_id);
                    setIndentedByIdOptions(emplListByDefaultDept);
                }
            }

        } catch (error) {
            console.log("error: ", error)
        }
    }
    const FnSetFieldUserAccess = () => {
        if (keyForViewUpdate != '') {
            var saveBtn = document.getElementById('save-btn');
            switch (keyForViewUpdate) {
                case 'view':
                    $('input[type="checkbox"]').prop('readonly', true);
                    setIsView(true)
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(View)');
                    $("input[type=radio]").attr('disabled', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('#clearbutton-id').hide();
                    $('#viewdocument-id').hide();
                    $('#save-btn').hide();
                    $('#cmb_customer_order_no').attr('disabled', true);
                    $('#add-material').attr('disabled', 'disabled');
                    $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
                    break;

                case 'update':
                    $('#submain_heading').append('');
                    $('#cmb_material_type').attr('disabled', true);
                    $('#submain_heading').text('(Modification)');
                    $('#txt_indent_date').attr('disabled', true);
                    $('input[name="chk_issue_source"]').prop('disabled', true);
                    $('#cmb_customer_order_no').attr('disabled', false);
                    $('#cmb_customer').attr('disabled', true);
                    if (chk_issue_source !== 'S') {
                        $('#cmb_customer_order_no').attr('disabled', true);
                    }
                    setIsApprove(false)
                    $('#clearbutton-id').hide();
                    saveBtn.textContent = 'Update';
                    break;
                case 'delete':
                    $('input[type="checkbox"]').prop('readonly', true);
                    setIsView(true)
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(Delete)');
                    $("input[type=radio]").attr('disabled', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('#clearbutton-id').hide();
                    $('#viewdocument-id').hide();
                    $('#save-btn').hide();
                    $('#cmb_customer_order_no').attr('disabled', true);
                    $('#add-material').attr('disabled', 'disabled');
                    $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
                    break;
            }
        }
    }


    const FnCheckUpdateResponce = async () => {
        try {
            if (idList !== '') {
                fin_year = idList.financial_year;
                issueVersion = idList.issue_version;
                setMaterialIssueNo(idList.issue_no)
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentIssueMaster/FnShowAllDetailsAndMastermodelRecords/${issueVersion}/${fin_year}/${COMPANY_ID}?issue_no=${idList.issue_no}`)
                const responce = await apiCall.json();
                console.log('indent Issue data: ', responce);
                let UpdatedIssueMasterdata = responce.IndentIssueMasterRecord;
                if (UpdatedIssueMasterdata !== null && UpdatedIssueMasterdata !== "") {
                    setmaterialType(UpdatedIssueMasterdata.indent_issue_type_id)
                    setmaterialissueDate(UpdatedIssueMasterdata.issue_date)
                    setmaterialissueversion(UpdatedIssueMasterdata.issue_version)
                    setIssueSource(UpdatedIssueMasterdata.issue_source)
                    setCustomerId(UpdatedIssueMasterdata.customer_id)
                    await comboOnChange('customer')
                    setCustomerOrderNo(UpdatedIssueMasterdata.customer_order_no)
                    setCustomerOrderDate(UpdatedIssueMasterdata.customer_order_date)


                    setDepartmentId(UpdatedIssueMasterdata.department_id)
                    await comboOnChange('departmentId')
                    setIndentedById(UpdatedIssueMasterdata.indented_by_id)
                    setScheduleDate(UpdatedIssueMasterdata.expected_schedule_date)
                    setIssueStatus(UpdatedIssueMasterdata.issue_status)
                    // setissued_by(data.issued_by_id)
                    setRemark(UpdatedIssueMasterdata.remark)
                    setIsActive(UpdatedIssueMasterdata.is_active)
                    setMaterialIssueDetailsData(responce.IndentIssueDetailsRecords)

                    if (responce.IndentIssueDetailsRecords != null) {
                        setMaterialIssueDetailsData(prevArray => {
                            if (keyForViewUpdate === "update") {
                                return responce.IndentIssueDetailsRecords.map(item => {

                                    // let previousIssueQTy = 0;
                                    let previousIssueWeight = 0;

                                    //to calculate currnt approve Qty
                                    // currentupdatedapproveQty = parseInt(item.product_material_approved_quantity) - (item.prev_issue_quantity)
                                    // previousIssueQTy = item.product_material_issue_quantity
                                    previousIssueWeight = item.product_material_issue_quantity * item.product_std_weight

                                    return {
                                        ...item,
                                        prev_issue_quantity: item.product_material_issue_quantity,
                                        prev_issue_weight: validateNumberDateInput.current.decimalNumber(JSON.stringify(previousIssueWeight), 4),
                                        product_material_stock_quantity: parseInt(item.product_material_issue_quantity + item.product_material_stock_quantity),
                                    };
                                });
                            } else {
                                return responce.IndentIssueDetailsRecords;
                            }
                        });
                    }



                    // if (responce.IndentIssueDetailsRecords != null) {
                    //     setMaterialIssueDetailsData(prevArray => {
                    //         if (keyForViewUpdate === "update") {
                    //             return responce.IndentIssueDetailsRecords.map(item => {

                    //                 let product_material_issue_quantity = 0;
                    //                 let issue_weight = 0;
                    //                 let issueItemStatus;
                    //                 let currentupdatedapproveQty;

                    //                 //to calculate currnt approve Qty
                    //                 currentupdatedapproveQty = parseInt(item.product_material_approved_quantity) - (item.prev_issue_quantity)

                    //                 //to show issue qty 
                    //                 if (parseInt(currentupdatedapproveQty) > parseInt(item.product_material_stock_quantity)) {
                    //                     product_material_issue_quantity = item.product_material_stock_quantity;
                    //                     issue_weight = product_material_issue_quantity * item.product_std_weight;

                    //                 } else if (parseInt(currentupdatedapproveQty) === parseInt(item.product_material_stock_quantity)) {
                    //                     product_material_issue_quantity = currentupdatedapproveQty;
                    //                     issue_weight = currentupdatedapproveQty * item.product_std_weight;

                    //                 } else {
                    //                     product_material_issue_quantity = currentupdatedapproveQty;
                    //                     issue_weight = currentupdatedapproveQty * item.product_std_weight;

                    //                 }
                    //                 //set issue status
                    //                 if (product_material_issue_quantity == currentupdatedapproveQty) {
                    //                     issueItemStatus = "C"
                    //                 } else if (product_material_issue_quantity == 0) {
                    //                     issueItemStatus = "P"
                    //                 } else if (product_material_issue_quantity != 0 && product_material_issue_quantity < currentupdatedapproveQty) {
                    //                     issueItemStatus = "I"
                    //                 }


                    //                 return {
                    //                     ...item,
                    //                     product_material_issue_quantity: product_material_issue_quantity,
                    //                     product_material_issue_weight: validateNumberDateInput.current.decimalNumber(JSON.stringify(issue_weight), 4),
                    //                     issue_item_status: issueItemStatus

                    //                 };
                    //             });
                    //         } else {
                    //             return responce.IndentIssueDetailsRecords;
                    //         }
                    //     });
                    // }

                }
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }


    const comboOnChange = async (key) => {
        switch (key) {
            case 'customer':
                let customerVal = document.getElementById("cmb_customer").value;
                setCustomerId(customerVal);
                if ($('#cmb_customer').val() === '0') {
                    const newTab = window.open('/Masters/Customer', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                } else if (customerVal !== "" & customerVal !== "0") {
                    resetGlobalQuery();
                    globalQuery.columns.push("customer_order_no");
                    globalQuery.columns.push("customer_order_Date");
                    globalQuery.table = "mtv_sales_order_master_trading_summary"
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customerVal });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.orderBy = ["sales_order_master_transaction_id desc"];

                    const getCustomerOrdersApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setCustomerOrdOpts(getCustomerOrdersApiCall);
                    // setCustomerOrderNo('');
                    // setCustomerOrderDate('');
                    setMaterialIssueDetailsData([])

                } else {
                    setCustomerOrdOpts([]);
                    setMaterialIssueDetailsData([])

                }
                break;

            case 'CustomerOrder':
                if ($('#cmb_customer_order_no').val() != null && $('#cmb_customer_order_no').val() != '') {
                    let selectedOption = $('#cmb_customer_order_no').find('option:selected');
                    let selectedOrderDate = selectedOption.attr('ord_date');
                    setCustomerOrderDate(selectedOrderDate);
                    // let customer_ordNo = $('#cmb_customer_order_no').val();
                    // let json = { 'so_transIds': [] }
                    // if ($('#cmb_customer').val() != "") {
                    //     const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtSalesOrderMasterTrading/FnShowParticularRecords/${COMPANY_ID}?customer_order_no=${customer_ordNo}`)
                    //     const responce = await apicall.json();
                    //     const CustNo = responce.content;
                    // if (CustNo.length !== 0) {
                    //     // const poDetailsData = groupCustomerProducts(CustNo)

                    //     // setMaterialIssueDetailsData(poDetailsData);                        // Set that updated data in table data hook;
                    //       $('.showData').show();
                    // }
                    // }
                } else {
                    setCustomerOrderDate('');
                    $('.showData').hide();
                }
                break;
            case 'departmentId':
                var departmentIdVal = document.getElementById('cmb_department').value;
                setDepartmentId(departmentIdVal)
                if (departmentIdVal === "0") {
                    setHeaderName('Department')
                    setshowAddRecIndentIssueModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                if (departmentIdVal !== "" && departmentIdVal !== "0") {
                    resetGlobalQuery();
                    globalQuery.columns.push("field_id");
                    globalQuery.columns.push("field_name");
                    globalQuery.table = "cmv_employee"
                    globalQuery.conditions.push({ field: "department_id", operator: "=", value: departmentIdVal });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const identedbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setIndentedByIdOptions(identedbyIdApiCall)
                    $('#error_cmb_department').hide();
                } else {
                    setIndentedByIdOptions([])
                }
                break;
            case 'identedById':
                var indentedBYIdVal = document.getElementById('cmb_indented_by').value;
                setIndentedById(indentedBYIdVal)
                if (indentedBYIdVal === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Indent By')
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                if (indentedBYIdVal !== "" && indentedBYIdVal !== "0") {
                    $('#error_cmb_indented_by').hide();
                }
                break;
            case 'SO_source':
                setMaterialIssueDetailsData([])
                break;
            case 'Internal_source':
                setCustomerId('')
                setCustomerOrderNo("")
                setCustomerOrderDate('')
                setMaterialIssueDetailsData([])
                break;

            case 'materialTypeId':
                var materialTypeId = document.getElementById('cmb_material_type').value;
                if (materialTypeId === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Material Type')
                    setshowAddRecIndentIssueModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                setmaterialType(materialTypeId)
                if (materialTypeId !== "0" && materialTypeId !== "") {
                    $('#error_cmb_indent_type').hide();
                    // await generateCustGrnNo();
                    await generateIndentIssueNo();
                    // setBomfilteredData([])
                    // setScheduleDataArray([])
                    // localStorage.setItem('filteredMaterialData', JSON.stringify([]));
                } else {
                    setMaterialIssueNo("0")
                }
                break;

            default:
                break;
        }
    }

    const displayRecordIndentIssueComponent = () => {
        switch (modalHeaderName) {
            case 'Indent By':
                return <FrmMEmployeesEntry />;

            case 'Department':
                return <DepartmentEntry />;

            case 'Material Type':
                return <FrmMProductTypeEntry />;



            default:
                return null;
        }
    }
    const generateIndentIssueNo = async () => {
        const ptShortName = materialtypeOptions.find(item => item.field_id === parseInt($('#cmb_material_type').val()));
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("st_indent_material_issue_master", "issue_no", ptShortName.product_type_short_name, "II", "5");
        setMaterialIssueNo(autoNoApiCall);
        return autoNoApiCall;
    };

    //--------------------------------------------------------Start Details section----------------------------------------------------------------------

    const handleCloseIndentModalForm = () => { setShowIndentModalForm(false) };

    // Fn for show indent summary modal details
    const showIndentDetails = async () => {
        if (indent_SummaryList.length !== 0) {
            setShowIndentModalForm(false)
            setIsLoading(true)
            let json = { 'indent_nos': [], 'Ids': {} }
            $("input:checkbox[name=selectIndentRecord]:checked").each(function () {
                json.indent_nos.push($(this).val())
            })
            json.Ids.company_id = COMPANY_ID
            json.Ids.department_id = cmb_department
            json.Ids.indented_by_id = cmb_indented_by

            if (json.indent_nos.length !== 0) {
                const formData = new FormData();
                formData.append('indentNos', JSON.stringify(json))
                const requestOptions = { method: 'POST', body: formData };
                let apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentIssueMaster/FnShowIndentDetailsRecords`, requestOptions)

                const response = await apicall.json()
                console.log(response);
                if (response.IndentDetailsList.length !== '0') {
                    displayindentissueDetailsData(response, json.indent_nos);
                } else {
                    console.log('Indent Details Data Error: ', response.error);
                }
            } else {
                setMaterialIssueDetailsData([])
            }
            setIsLoading(false)
        }
    }


    const displayindentissueDetailsData = (response, indent_nos) => {
        setMaterialIssueDetailsData(prevArray => {
            return response.IndentDetailsList.map(item => {

                let product_material_issue_quantity = 0;
                let issue_weight = 0;
                let issueItemStatus;
                let prevIssueQuantity = 0;

                //to show issue qty 
                if (parseInt(item.product_material_approved_quantity) > parseInt(item.product_material_stock_quantity)) {
                    product_material_issue_quantity = item.product_material_stock_quantity;
                } else if (parseInt(item.product_material_approved_quantity) === parseInt(item.product_material_stock_quantity)) {
                    product_material_issue_quantity = item.product_material_approved_quantity - prevIssueQuantity;
                } else {
                    product_material_issue_quantity = item.product_material_approved_quantity - prevIssueQuantity;
                }
                //to calculate issue qty weight
                if (product_material_issue_quantity !== 0) {
                    issue_weight = product_material_issue_quantity * item.product_rm_std_weight
                } else {
                    issue_weight = 0;
                }

                //set issue status
                if (product_material_issue_quantity == item.product_material_approved_quantity) {
                    issueItemStatus = "C"
                } else if (product_material_issue_quantity == 0) {
                    issueItemStatus = "P"
                } else if (product_material_issue_quantity != 0 && product_material_issue_quantity < item.product_material_approved_quantity) {
                    issueItemStatus = "I"
                }
                return {
                    ...item,
                    product_material_indent_quantity: item.product_material_quantity,
                    product_material_indent_weight: item.product_material_weight,
                    product_std_weight: item.product_rm_std_weight,
                    issue_item_status: issueItemStatus,
                    issue_remark: '',
                    product_material_name: item.product_rm_name,
                    product_material_issue_quantity: product_material_issue_quantity,
                    product_material_issue_weight: validateNumberDateInput.current.decimalNumber(JSON.stringify(issue_weight), 4),
                    product_material_issue_return_quantity: 0,
                    product_material_issue_return_weight: 0,
                    product_material_unit_name: item.product_rm_stock_unit_name,
                    // prev_issue_quantity: prevIssueQuantity,
                    // prev_issue_weight: parseInt(item.prev_issue_weight * item.product_rm_std_weight)
                };
            });
        });
    };


    const fnManageIssueStatus = (detailData) => {
        const item = detailData.find(item => item.issue_item_status === 'P' || item.issue_item_status === 'I');
        if (item) {
            setIssueStatus(item.issue_item_status);
        } else {
            setIssueStatus('C');
        }
    };


    // const fnManageIssueStatus = (detailData) => {
    //     detailData.map(item => {
    //         if (item.issue_item_status === 'P') {
    //             setIssueStatus('P')
    //             return
    //         } else if (item.issue_item_status === 'I') {
    //             setIssueStatus('I')
    //             return
    //         } else if (item.issue_item_status === 'C') {
    //             setIssueStatus('C')
    //             return
    //         }
    //     });
    // };





    // Function to handle input changes and update state variables
    const handleIssueDetailsInputChange = (currentRowData, event) => {
        let clickedColName = event.target.getAttribute('Headers');               // Get the column name where event triggered.
        // let enteredValue = parseInt(event.target.value);
        let issueItemsQtyinp = document.querySelector('#' + event.target.id);

        if (clickedColName === 'product_material_issue_quantity') {
            const stockQty = currentRowData.product_material_stock_quantity
            const approveQty = parseInt(currentRowData.product_material_approved_quantity)

            if (event.target.value.trim() !== "") {
                delete issueItemsQtyinp.parentElement.dataset.tip;
                if (approveQty > parseInt(event.target.value) && parseInt(event.target.value) !== 0) {
                    currentRowData.issue_item_status = "I";
                } else if (approveQty == parseInt(event.target.value)) {
                    currentRowData.issue_item_status = "C";
                } else if (event.target.value == 0) {
                    currentRowData.issue_item_status = "P";
                }

                //Qty cant exceed stock
                if (stockQty >= approveQty) {
                    if (approveQty < event.target.value) {
                        issueItemsQtyinp.parentElement.dataset.tip = 'Issue quantity cant exceed Approved quantity...!';
                        currentRowData[clickedColName] = '';
                        fnCalculateItemQtyandWeight(currentRowData)
                    } else {
                        delete issueItemsQtyinp.parentElement.dataset.tip;
                        currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(JSON.stringify(event.target.value), 4);
                        fnCalculateItemQtyandWeight(currentRowData)
                    }
                }
                else if (approveQty >= stockQty) {
                    if (stockQty < event.target.value) {
                        issueItemsQtyinp.parentElement.dataset.tip = 'Issue quantity cant exceed stock quantity...!';
                        currentRowData[clickedColName] = '';
                        fnCalculateItemQtyandWeight(currentRowData)
                    } else {
                        delete issueItemsQtyinp.parentElement.dataset.tip;
                        currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(JSON.stringify(event.target.value), 4);
                        fnCalculateItemQtyandWeight(currentRowData)
                    }
                }
            }
        } else if (clickedColName === 'product_material_issue_weight') {
            if (event.target.value !== '') {
                currentRowData[clickedColName] = event.target.value;
                delete issueItemsQtyinp.parentElement.dataset.tip;
            }
        }
        else if (clickedColName === 'issue_item_status') {
            if (event.target.value !== '') {
                currentRowData[clickedColName] = event.target.value;
                delete issueItemsQtyinp.parentElement.dataset.tip;
            }
        } else if (clickedColName === 'issue_remark') {
            currentRowData[clickedColName] = event.target.value;
        }
        const detailData = [...materialIssueDetailsData]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        detailData[arrayIndex] = currentRowData
        setMaterialIssueDetailsData(detailData);
        fnManageIssueStatus(detailData)                   // Set that updated data in table data hook;

    };


    // ------------------------------Qty and Amout Calculation-------------------------------------------------------------------------------------------
    const fnCalculateItemQtyandWeight = (currentRowData) => {
        let issueWeight = 0;

        const indentstdweight = currentRowData.product_std_weight
        const issueQty = currentRowData.product_material_issue_quantity
        //to calculate issue weight
        if (issueQty != '') {
            // // into the weight field store the value as it is of the quantity.(Beacause aerotech client don't need the weight.)
            // issueWeight = indentstdweight * issueQty
            // currentRowData['product_material_issue_weight'] = validateNumberDateInput.current.decimalNumber(issueWeight.toString(), 4);
            currentRowData['product_material_issue_weight'] = validateNumberDateInput.current.decimalNumber(issueQty.toString(), 4);
        } else {
            currentRowData['product_material_issue_weight'] = 0;
        }

        return currentRowData;
    }


    function scrollToTableRow() {
        var keywords = $("#txt-detail-data-highliter").val();
        var table = document.getElementById('indent-details-tbl');
        var rows = table.getElementsByTagName('tr');

        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
                var w = $(window);
                var row = $('#indent-details-tbl').find('tr').eq(trNum);

                if (row.length) {
                    w.scrollTop(row.offset().top - (w.height() / 2));
                }

                $(row).find('td').each(function () {
                    $(this).addClass('highlight');
                });
                removeOtherTrHighlightClass(trNum)
                break;
            } else {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }

    const removeOtherTrHighlightClass = (rowNum) => {
        var table = document.getElementById('indent-details-tbl');
        var rows = table.getElementsByTagName('tr');
        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (trNum !== rowNum) {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }
    //-------------------------------------------End Details section --------------------------------------------------------------------------
    const validateFields = () => {
        validate.current.validateFieldsOnChange('material_issue_form_id')
    }
    // ---------------------fn for save indent ----------------------------------------------------------------
    const addIndentIssue = async () => {
        try {
            let checkIsValidate = true;
            if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
                checkIsValidate = await FnValidateIndentOrder();
            }
            if (checkIsValidate === true) {
                setIsLoading(true)
                const lastIndentissueNo = await generateIndentIssueNo();
                let json = { 'TransHeaderData': {}, 'TransDetailData': [], 'commonIds': {} }

                // TransHeaderData
                const indentMasterFormData = {
                    issue_master_transaction_id: 0,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    issue_no: txt_material_issue_no,
                    issue_date: dt_material_issue_date,
                    issue_version: txt_material_issue_version,
                    issue_source: chk_issue_source,
                    // indent_no : ,
                    // indent_date : ,
                    // indent_version : ,
                    customer_id: cmb_customer,
                    customer_order_no: cmb_customer_order_no,
                    customer_order_date: customer_order_date,
                    department_id: cmb_department,
                    indented_by_id: cmb_indented_by,
                    expected_schedule_date: txt_schedule_date,
                    issue_status: chk_Issue_status,
                    issued_by_id: UserId,
                    remark: txt_remark,
                    is_active: chk_isactive,
                    created_by: UserName,
                    modified_by: idList !== "" ? UserName : null,
                    company_id: COMPANY_ID,
                    indent_issue_type_id: cmb_material_type,
                    indent_issue_type: $('#cmb_material_type').find(":selected").text(),
                }
                json.TransHeaderData = indentMasterFormData;

                for (let issueItem = 0; issueItem < materialIssueDetailsData.length; issueItem++) {
                    const indentIssueDetailsRow = materialIssueDetailsData[issueItem];
                    const IndentIssueRow = {};
                    IndentIssueRow['company_id'] = COMPANY_ID
                    IndentIssueRow['company_branch_id'] = COMPANY_BRANCH_ID
                    IndentIssueRow['financial_year'] = FINANCIAL_SHORT_NAME
                    IndentIssueRow['issue_details_transaction_id'] = ''
                    IndentIssueRow['financial_year'] = FINANCIAL_SHORT_NAME
                    IndentIssueRow['issue_no'] = txt_material_issue_no
                    IndentIssueRow['issue_date'] = dt_material_issue_date
                    IndentIssueRow['issue_version'] = txt_material_issue_version
                    IndentIssueRow['indent_no'] = indentIssueDetailsRow.indent_no
                    IndentIssueRow['indent_date'] = indentIssueDetailsRow.indent_date
                    IndentIssueRow['indent_version'] = indentIssueDetailsRow.indent_version
                    IndentIssueRow['product_material_id'] = indentIssueDetailsRow.product_material_id
                    IndentIssueRow['product_material_unit_id'] = indentIssueDetailsRow.product_material_unit_id
                    IndentIssueRow['product_material_indent_quantity'] = indentIssueDetailsRow.product_material_indent_quantity
                    IndentIssueRow['product_material_indent_weight'] = indentIssueDetailsRow.product_material_indent_weight
                    IndentIssueRow['product_material_approved_quantity'] = indentIssueDetailsRow.product_material_approved_quantity
                    IndentIssueRow['product_material_approved_weight'] = indentIssueDetailsRow.product_material_approved_weight
                    IndentIssueRow['product_material_rejected_quantity'] = indentIssueDetailsRow.product_material_rejected_quantity
                    IndentIssueRow['product_material_rejected_weight'] = indentIssueDetailsRow.product_material_rejected_weight
                    IndentIssueRow['product_material_issue_quantity'] = indentIssueDetailsRow.product_material_issue_quantity
                    IndentIssueRow['product_material_issue_weight'] = indentIssueDetailsRow.product_material_issue_weight
                    IndentIssueRow['product_material_issue_return_quantity'] = indentIssueDetailsRow.product_material_issue_return_quantity
                    IndentIssueRow['product_material_issue_return_weight'] = indentIssueDetailsRow.product_material_issue_return_weight
                    IndentIssueRow['product_std_weight'] = indentIssueDetailsRow.product_std_weight
                    IndentIssueRow['issue_item_status'] = indentIssueDetailsRow.issue_item_status
                    IndentIssueRow['issue_remark'] = indentIssueDetailsRow.issue_remark
                    IndentIssueRow['issue_master_transaction_id'] = issue_master_transaction_id
                    IndentIssueRow['indent_details_id'] = indentIssueDetailsRow.indent_details_id
                    IndentIssueRow['customer_order_no'] = ''
                    IndentIssueRow['created_by'] = keyForViewUpdate === null ? UserName : indentIssueDetailsRow.created_by
                    IndentIssueRow['modified_by'] = keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'financeApprove' ? UserName : null
                    IndentIssueRow['prev_issue_quantity'] = indentIssueDetailsRow.prev_issue_quantity
                    IndentIssueRow['prev_issue_weight'] = indentIssueDetailsRow.prev_issue_weight
                    json.TransDetailData.push(IndentIssueRow);
                }
                console.log(json.TransDetailData);

                // Common Ids
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.issue_no = keyForViewUpdate === '' ? lastIndentissueNo : txt_material_issue_no
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                console.log(json);
                const formData = new FormData();
                formData.append(`StIndentMaterialIssueData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentIssueMaster/FnAddUpdateRecord`, requestOptions)
                const responce = await apicall.json();

                if (responce.success === "0") {
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)

                } else {
                    if (responce.data.hasOwnProperty('issue_no') && responce.data.hasOwnProperty('issue_version')) {
                        setModalOrderDetails(`Indent Issue No: ${responce.data.issue_no} and Version: ${responce.data.issue_version}`)
                    }
                    //  setIndentMasterId(responce.data.indent_master_id)
                    // setIndentno(responce.data.indent_no)
                    // setIndentDate(responce.data.indent_date)
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                }
                setIsLoading(false)
                console.log(json)
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }



    const FnValidateIndentOrder = async () => {
        let headerDataIsValid = await validate.current.validateForm("material_issue_form_id");
        if (!headerDataIsValid) { return false; }
        let issueDetailsIsValid = false;
        let addedIssuematerial = $('#issue-details-tbl tbody tr').length;
        if (addedIssuematerial <= 0 && keyForViewUpdate !== 'update' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add atleast one Issued Detail.');
            setOpenDetailsAccord(true);
            setShowErrorMsgModal(true);
            return issueDetailsIsValid;
        } else {
            const tableRows = $('#issue-details-tbl tbody tr');
            issueDetailsIsValid = true;
            tableRows.each(function () {
                const issueQty = parseFloat($(this).find('input[id^="product_material_issue_quantity_"]').val());
                if (isNaN(issueQty) || issueQty === "") {
                    $(this).find('input[id^="product_material_issue_quantity_"]').parent().attr('data-tip', 'Please enter a valid Quantity...!');
                    $(this).find('input[id^="product_material_issue_quantity_"]').focus();
                    setOpenDetailsAccord(true);
                    issueDetailsIsValid = false;
                    return false;
                }
            });
            return issueDetailsIsValid;
        }
    }



    const FnClearFormFields = async () => {

        await generateIndentIssueNo();
        setIssueSource('I')
        setCustomerId('');
        setmaterialissueversion('1');
        setScheduleDate(todayDate);
        setDepartmentId('');
        setIndentedById('');
        setIssueStatus("P");
        setissued_by(UserName);
        setRemark('');
        setIsActive(true);
        setmaterialissueDate(todayDate);
        // setIndentDate(todayDate);
        // setCustomerOrderDate('');
        setMaterialIssueDetailsData([]);

        // Set the Default Intented By and It's Department.
        if (keyForViewUpdate !== 'view' && keyForViewUpdate !== 'update' && keyForViewUpdate !== 'approve') {
            resetGlobalQuery();
            globalQuery.columns = ["field_id", 'field_name', 'department_id'];
            globalQuery.table = "cmv_employee_summary";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const allEmployeesList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            let defaultIndentedBy = allEmployeesList.find(empl => empl.field_id === parseInt(UserId));

            // Set the default Department as per the logged In User.
            if (defaultIndentedBy) {
                setIndentedById(UserId);
                setDepartmentId(defaultIndentedBy.department_id);
                let emplListByDefaultDept = allEmployeesList.filter(empl => empl.department_id === defaultIndentedBy.department_id);
                setIndentedByIdOptions(emplListByDefaultDept);
            }
        }
    }

    const moveToListing = () => {
        const Path = compType === 'Register' ? '/Transactions/TIndentMaterialIssue/FrmIndentMaterialIssueListing/reg' : `/Transactions/TIndentMaterialIssue/FrmIndentMaterialIssueListing`;
        navigate(Path);
    }

    const refreshData = async (key) => {

        switch (key) {
            case 'get_employee':
                var departmentIdVal = document.getElementById('cmb_department').value;
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_employee"
                globalQuery.conditions.push({ field: "department_id", operator: "=", value: departmentIdVal });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const identedbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setIndentedByIdOptions(identedbyIdApiCall)
                break;
            case 'get_customer':
                const customerOrderIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_customer", "", "")
                setCustomerOptions(customerOrderIdApiCall)
                break;
            default:
                break;
        }
    }




    $('body').on('click', '.RemoveTrBtn', function () {
        var $tr = $(this).closest('tr');
        $tr.remove();
    });




    const renderDetailsTable = useMemo(() => {
        return <>
            <Table className="erp_table erp_table_scroll" id='issue-details-tbl' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Sr.no</th>
                        <th className="erp_table_th">Material Name</th>
                        <th className="erp_table_th">Material unit</th>
                        <th className="erp_table_th">Indent No</th>
                        <th className="erp_table_th">Indent Date</th>
                        <th className="erp_table_th">Indent Quantity</th>
                        <th className="erp_table_th">Stock Quantity</th>
                        <th className="erp_table_th">Approved Quantity</th>
                        <th className={`erp_table_th ${keyForViewUpdate === "" ? "d-none" : "display"}`}>Previous Issue Quantity</th>
                        <th className="erp_table_th">Issue Quantity</th>
                        <th className="erp_table_th">Issue Remark</th>
                        <th className="erp_table_th" style={{ paddingRight: "20px" }}>Issue Item Status</th>
                        <th className="erp_table_th">Issue Return Quantity</th>
                        <th className="erp_table_th">Rejected Quantity</th>
                        <th className="erp_table_th d-none">Stock Weight</th>
                        <th className="erp_table_th d-none">Indent Weight</th>
                        <th className="erp_table_th d-none">Approved Weight</th>
                        <th className="erp_table_th d-none">Issue Return Weight</th>
                        <th className={`erp_table_th ${keyForViewUpdate === "" ? "d-none" : "display"} d-none`}>Previous Issue Weight</th>
                        <th className="erp_table_th d-none">Issue Weight</th>
                        <th className="erp_table_th d-none">Rejected Weight</th>
                        <th className="erp_table_th d-none">Std Weight</th>

                    </tr>
                </thead>
                <tbody>
                    {materialIssueDetailsData.map((issueItem, Index) =>
                        <tr rowindex={Index} className="sticky-column">
                            <td className="erp_table_td text-end sticky-column">{Index + 1}</td>
                            <td className={`erp_table_td sticky-column`}>{issueItem.product_material_name}</td>
                            <td className={`erp_table_td sticky-column`}>{issueItem.product_material_unit_name}</td>
                            <td className={`erp_table_td sticky-column`}>{issueItem.indent_no}</td>
                            <td className={`erp_table_td sticky-column`}>{validateNumberDateInput.current.formatDateToDDMMYYYY(issueItem.indent_date)}</td>
                            <td className="erp_table_td text-end sticky-column">{issueItem.product_material_indent_quantity}</td>
                            <td className={`erp_table_td text-end sticky-column`}>{issueItem.product_material_stock_quantity}</td>
                            <td className="erp_table_td text-end scrollable-table">{issueItem.product_material_approved_quantity}</td>
                            <td className={`erp_table_td text-end ${keyForViewUpdate === "" ? "d-none" : "display"}`}>{issueItem.prev_issue_quantity}</td>
                            <td className="erp_table_td">
                                <input type="text" id={`product_material_issue_quantity_${issueItem.indent_details_id}`} className="erp_input_field text-end mb-0"
                                    value={issueItem.product_material_issue_quantity} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} Headers='product_material_issue_quantity' />
                            </td>
                            <td className="erp_table_td">
                                <input type="text" id={`issue_remark_${issueItem.indent_details_id}`} className="erp_input_field mb-0"
                                    value={issueItem.issue_remark} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} Headers='issue_remark' />
                            </td>
                            <td className="erp_table_td">
                                <select id={`issue_item_status_${issueItem.indent_details_id}`} disabled value={issueItem.issue_item_status}
                                    className="form-select form-select-sm approveField" Headers='issue_item_status' onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }}>
                                    <option value="P">Pending</option>
                                    <option value="I">Partial Issue</option>
                                    <option value="C">Completed</option>
                                </select>
                            </td>
                            <td className="erp_table_td text-end">{issueItem.product_material_issue_return_quantity}</td>
                            <td className="erp_table_td text-end">{issueItem.product_material_rejected_quantity}</td>
                            <td className="erp_table_td text-end d-none">{issueItem.product_material_stock_weight}</td>
                            <td className="erp_table_td text-end d-none">{issueItem.product_material_indent_weight}</td>
                            <td className="erp_table_td text-end d-none">{issueItem.product_material_approved_weight}</td>
                            <td className="erp_table_td text-end d-none">{issueItem.product_material_issue_return_weight}</td>
                            <td className={`erp_table_td text-end ${keyForViewUpdate === "" ? "d-none" : "display"} d-none`}>{issueItem.prev_issue_weight}</td>
                            <td className="erp_table_td text-end d-none">{issueItem.product_material_issue_weight}</td>
                            <td className="erp_table_td text-end d-none">{issueItem.product_material_rejected_weight}</td>
                            <td className="erp_table_td text-end d-none">{issueItem.product_std_weight}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    }, [materialIssueDetailsData, keyForViewUpdate])

    //---------------------------------------------------- Accordian data load starts --------------------------------------------------------------------------------
    const FnLoadAccordionData = async (eventKey) => {

        switch (eventKey) {

            case 'documentList':
                await showDocumentRecords();
                break;
            default:
                break;
        }
        if (keyForViewUpdate === "view" || keyForViewUpdate === "approve" || keyForViewUpdate === "delete") {
            $('input[type="checkbox"]').prop('disabled', true);
        }
    }
    //---------------------------------------------------- Accordian data load Ends --------------------------------------------------------------------------------
    // Fn for get document list
    const showDocumentRecords = async () => {
        try {
            if (COMPANY_ID !== null) {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_material_issue_no !== 0 ? txt_material_issue_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    setDocumentData(resp.content);
                }
            }

        } catch (error) {
            console.log("error: ", error)
        }
    }

    // Fn for get document table
    const renderDocumentTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='document-table' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Group Id</th>
                        <th className="erp_table_th">Document Group</th>
                        <th className="erp_table_th">Document Name</th>
                        <th className="erp_table_th">Registration No</th>
                        <th className="erp_table_th">Registration Date</th>
                        <th className="erp_table_th">Renewal Date</th>
                        <th className="erp_table_th">Document Path</th>
                        <th className="erp_table_th">Remark</th>
                        <th className="erp_table_th">File Name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        docData.map((docItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td"> {docItem.group_id} </td>
                                <td className="erp_table_td">{docItem.document_group}</td>
                                <td className="erp_table_td">{docItem.document_name}</td>
                                <td className="erp_table_td ">{docItem.document_registration_no}</td>
                                <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(docItem.document_registration_date)}</td>
                                <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(docItem.document_renewal_date)}</td>
                                <td className="erp_table_td ">{docItem.document_path}</td>
                                <td className="erp_table_td ">{docItem.remark}</td>
                                <td className="erp_table_td ">
                                    <MDTypography component="label" className="erp-form-label" variant="button" id="logoFile" fontWeight="regular" color="info" >
                                        <Link to="#" onClick={() => { fetchDocument(docItem) }}>
                                            {docItem.file_name}
                                        </Link></MDTypography>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [docData]);



    // Fn for get document download
    const fetchDocument = async (docItem) => {
        try {
            const formData = new FormData()
            const data = {
                document_group: docItem.documentGroup,
                group_id: docItem.group_id,
                document_path: docItem.document_path
            }
            formData.append(`getFile`, JSON.stringify(data))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
            const blob = await getDocApiCall.blob()

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${docItem.file_name}`,);
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);


        } catch (error) {
            console.log("error: ", error)
        }
    }
    // ----------------------------------------------------------------------------------------
    const loadIndenstList = async () => {
        const deptval = document.getElementById('cmb_department').value
        resetGlobalQuery();
        globalQuery.columns = ["indent_no", "indent_date", "department_name", "indent_master_id"]
        globalQuery.table = "stv_indent_master_summary"
        globalQuery.conditions.push({ field: "department_id", operator: "=", value: deptval });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0', });
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "indented_by_id", operator: "=", value: cmb_indented_by });
        globalQuery.conditions.push({ field: "indent_status", operator: "NOT IN", values: ['P', 'C', 'R'] });
        if (chk_issue_source === 'S') {
            globalQuery.conditions.push({ field: "customer_order_no", operator: "=", value: cmb_customer_order_no })
            globalQuery.conditions.push({ field: "customer_id", operator: "=", value: cmb_customer })
        }

        const getIndentSummarytList = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
        if (getIndentSummarytList.length !== 0) {
            setIndentSummaryList(getIndentSummarytList)
            FnIndentChkBoxCheck(getIndentSummarytList)
        } else {
            // setCheckedGoodReciptNoLength(0)
            setIndentSummaryList([])
        }
    }

    const FnIndentChkBoxCheck = (getIndentSummarytList) => {
        const filterData = [...materialIssueDetailsData].map(existItem => {
            getIndentSummarytList.forEach(newItem => {
                if (existItem.indent_no === newItem.indent_no) {
                    const checkbox = $(`input:checkbox[name="selectIndentRecord"][value="${newItem.indent_no}"]`);
                    checkbox.prop('checked', true);
                }
            });
        });

        const allChecked = $('.selectIndentRecord:checked').length === $('.selectIndentRecord').length;
        $('#selectAllIndentRecords').prop('checked', allChecked);
    };

    // -----------------------------------------------------------------------------------------
    const renderIndentTbl = useMemo(() => {
        return <>
            <Table className="erp_table " id='grn-table-id' responsive bordered striped erp_table_scroll>
                <thead className="erp_table_head">
                    <tr>
                        <th className='erp_table_th'></th>
                        <th className='erp_table_th'>Sr. No.</th>
                        <th className="erp_table_th">Indent no</th>
                        <th className="erp_table_th">Indent Date</th>
                        <th className="erp_table_th">Department Name</th>

                    </tr>
                </thead>
                <tbody>
                    {indent_SummaryList.map((issueItem, index) => (
                        <tr rowIndex={index}>
                            <td>
                                <div style={{ display: "flex" }}>
                                    <input type='checkbox' class="selectIndentRecord" name="selectIndentRecord" id={'selectIndentRecord_' + issueItem.indent_master_id}
                                        value={issueItem.indent_no} onClick={(e) => toggleChkAllBoxes('PartiallySelectIndentRecord')}>
                                    </input>
                                </div>
                            </td>
                            <td className="erp_table_td text-end">{index + 1}</td>
                            <td className="erp_table_td ">{issueItem.indent_no}</td>
                            <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(issueItem.indent_date)}</td>
                            <td className="erp_table_td ">{issueItem.department_name}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    }, [indent_SummaryList])
    // -----------------------------------------------------------------------------------------

    //function to check All checkBoxes of po terms and payment terms
    function toggleChkAllBoxes(key) {
        switch (key) {

            case 'selectAllIndentRecords':
                $('.selectIndentRecord').prop('checked', $('#selectAllIndentRecords').is(":checked"));
                break;
            case 'PartiallySelectIndentRecord':
                $('#selectAllIndentRecords').prop('checked', $('input:checkbox.selectIndentRecord:checked').length === $('input:checkbox.selectIndentRecord').length);
                break;
        }
    }

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ExcelExport ref={exlsExp} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <DashboardLayout>
                <>
                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span>Loading...</span>
                            </div>
                        </div> :
                        ''}

                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg main_heding'> Indent Material Issue <span id="submain_heading">(Creation)</span></label>
                        </div>
                        <form id="material_issue_form_id">
                            <div className="row p-1">
                                {/* //first column */}
                                <div className="col-sm-6 erp_form_col_div">


                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Material Issue Type<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-9 col-12'>
                                            <select id="cmb_material_type" className="form-select form-select-sm" value={cmb_material_type} onChange={() => { comboOnChange('materialTypeId'); validateFields(); }}  >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {materialtypeOptions.length !== 0 ? (
                                                    <>
                                                        {materialtypeOptions.map(materialTypeId => (
                                                            <option key={materialTypeId.field_id} value={materialTypeId.field_id}>
                                                                {materialTypeId.field_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_material_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>




                                    <div className='row'>
                                        <div className="col-sm-3 col-12">
                                            <Form.Label className="erp-form-label">Material Issue No, Version & Date <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_material_issue_no" className="erp_input_field" value={txt_material_issue_no} disabled />
                                                    <MDTypography variant="button" id="error_txt_material_issue_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-2 pt-md-0 pt-3 pe-md-0">
                                                    <Form.Control type="text" id='txt_material_issue_version' className="erp_input_field text-end" value={txt_material_issue_version} disabled />
                                                    <MDTypography variant="button" id="error_txt_material_issue_version" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                                <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                    <Form.Control type="date" id='dt_material_issue_date' className="erp_input_field" value={dt_material_issue_date} onChange={e => { setmaterialissueDate(e.target.value); validateFields(); }} min={currentDate} />
                                                    <MDTypography variant="button" id="error_dt_material_issue_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className='row'>
                                        <div className="col-sm-3 col-12">
                                            <Form.Label className="erp-form-label">Indent No, Version & Date <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_indent_no" className="erp_input_field" value={txt_indent_no} disabled />
                                                    <MDTypography variant="button" id="error_txt_indent_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-2 pt-md-0 pt-3 pe-md-0">
                                                    <Form.Control type="text" id='txt_indent_version' className="erp_input_field text-end" value={txt_indent_version} disabled />
                                                    <MDTypography variant="button" id="error_txt_indent_version" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                                <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                    <Form.Control type="date" id='txt_indent_date' className="erp_input_field" value={txt_indent_date} onChange={e => { setIndentDate(e.target.value); validateFields(); }} min={currentDate} />
                                                    <MDTypography variant="button" id="error_txt_indent_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Issue Source <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck">
                                                    <Form.Check
                                                        className="erp_radio_button"
                                                        label="Internal"
                                                        type="radio"
                                                        lbl="Internal"
                                                        value="I"
                                                        name="chk_issue_source"
                                                        checked={chk_issue_source === "I"}
                                                        onChange={() => { setIssueSource("I"); comboOnChange('Internal_source') }}
                                                    />
                                                </div>
                                                <div className="sCheck">
                                                    <Form.Check
                                                        className="erp_radio_button"
                                                        label="So Based"
                                                        type="radio"
                                                        lbl="So Based"
                                                        value="S"
                                                        name="chk_issue_source"
                                                        checked={chk_issue_source === "S"}
                                                        onChange={() => {
                                                            setIssueSource("S"); comboOnChange('SO_source')
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className={`row`}>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Customer {chk_issue_source === 'S' ? <span className="required">*</span> : null}</Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-10'>
                                            <select
                                                id="cmb_customer" className="form-select form-select-sm" value={cmb_customer} onChange={(e) => { setCustomerId(e.target.value); comboOnChange('customer'); validateFields() }} disabled={chk_issue_source !== 'S'}
                                                {...(chk_issue_source !== 'S' && { optional: 'optional' })} >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {customerOptions?.map(customer => (
                                                    <option value={customer.field_id}>{customer.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_customer" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col-sm-1 col-2">
                                            <Tooltip title="Refresh" placement="top">
                                                <MDTypography className={`${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                                                    <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_customer') } }} />
                                                </MDTypography>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Customer Order No & Order on{chk_issue_source === 'S' ? <span className="required">*</span> : null}</Form.Label>
                                        </div>
                                        <div className='col-sm-9 col-10'>
                                            <div className="row">
                                                <div className="col-12 col-md-8 pe-md-0">
                                                    <select id="cmb_customer_order_no" className="form-select form-select-sm" value={cmb_customer_order_no} onChange={(e) => { setCustomerOrderNo(e.target.value); comboOnChange('CustomerOrder'); validateFields() }} {...(chk_issue_source !== 'S' && { optional: 'optional' })} disabled={chk_issue_source !== 'S'}>
                                                        <option value="">Select</option>
                                                        {customerOrdOpts.length !== 0 ?
                                                            <>
                                                                {customerOrdOpts?.map(order => (
                                                                    <option value={order.customer_order_no} ord_date={order.customer_order_Date}  >{order.customer_order_no}</option>
                                                                ))}
                                                            </>
                                                            : ''
                                                        }
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_customer_order_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col">
                                                    <Form.Control type="date" id='customer_order_date' className="erp_input_field" value={customer_order_date} optional='optional' disabled />
                                                    <MDTypography variant="button" id="error_customer_order_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_department" className="form-select form-select-sm" value={cmb_department} onChange={() => { comboOnChange('departmentId'); validateFields(); }}>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {
                                                    departmentIdOPtions.length !== 0 ? (
                                                        <>
                                                            {departmentIdOPtions?.map(departmentId => (
                                                                <option value={departmentId.field_id}>{departmentId.field_name}</option>
                                                            ))}
                                                        </>
                                                    ) : null
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_department" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                </div>
                                {/* //second column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    {/* {(keyForViewUpdate === 'view' && chk_Issue_status === 'A' || keyForViewUpdate === 'approve') ? (
                                        <>
                                            <div className='row'>
                                                <div className='col-sm-3'>
                                                    <Form.Label className="erp-form-label">Approved By</Form.Label>
                                                </div>
                                                <div className='col'>
                                                    <select
                                                        id="cmb_approved_by_id" className="form-select form-select-sm " value={cmb_approved_by_id}
                                                        onChange={(e) => { setApproved_by_id(e.target.value); }} optional='optional' disabled='disabled'
                                                    >
                                                        <option value="">Select</option>
                                                        {approvedByOptions.length !== 0 ?
                                                            <>
                                                                {approvedByOptions?.map(cmb_approved_by_id => (
                                                                    <option value={cmb_approved_by_id.field_id}>{cmb_approved_by_id.field_name}</option>
                                                                ))}
                                                            </>
                                                            : ''
                                                        }
                                                    </select>
                                                    <MDTypography
                                                        variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}
                                                    >
                                                    </MDTypography>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-sm-3">
                                                    <Form.Label className="erp-form-label">Approved Date </Form.Label>
                                                </div>
                                                <div className="col">
                                                    <Form.Control type="date" id='txt_approved_date' className="erp_input_field" value={txt_approved_date} onChange={e => { setApproved_date(e.target.value); }} optional='optional' disabled='disabled' />
                                                    <MDTypography variant="button" id="error_txt_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </>
                                    ) : null
                                    } */}

                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Indented By <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-10'>
                                            <select id="cmb_indented_by" className="form-select form-select-sm" value={cmb_indented_by} onChange={() => { comboOnChange('identedById'); validateFields(); }}>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {indentedByIdOptions.length !== 0 ? (
                                                    <>
                                                        {indentedByIdOptions?.map(identedById => (
                                                            <option value={identedById.field_id}>{identedById.field_name}</option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_indented_by" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col-sm-1 col-2">
                                            <Tooltip title="Refresh" placement="top">
                                                <MDTypography className={`${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                                                    <MdRefresh style={{ color: 'black' }} onClick={() => { refreshData('get_employee') }} />
                                                </MDTypography>
                                            </Tooltip>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Schedule Date<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="date" id='txt_schedule_date' className="erp_input_field" value={txt_schedule_date} onChange={(e) => { setScheduleDate(e.target.value); validateFields(); }} min={currentDate} />
                                            <MDTypography variant="button" id="error_txt_schedule_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Issued By <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id="txt_issued_by" className="erp_input_field" value={txt_issued_by} disabled />
                                            <MDTypography variant="button" id="error_txt_issued_by" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Issue Status </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="chk_Issue_status " className="form-select form-select-sm" value={chk_Issue_status} onChange={(e) => { setIssueStatus(e.target.value); }} disabled >
                                                <option value="P">Pending</option>
                                                <option value="C">Completed</option>
                                                <option value="I">Partial Issue</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Remark</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" id='txt_remark' className="erp_input_field" value={txt_remark} onChange={e => setRemark(e.target.value)} optional='optional' maxLength={1000} />
                                            <MDTypography variant="button" id="error_txt_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Active</Form.Label>
                                        </div>
                                        <div className="col">

                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" lbl="Yes" type="radio" value="true" name="chk_isactive" checked={chk_isactive === true} onClick={() => { setIsActive(true); }} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" lbl="No" type="radio" value="false" name="chk_isactive" checked={chk_isactive === false} onClick={() => { setIsActive(false); }} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <hr />

                    <Accordion defaultActiveKey="0" activeKey={openDetailsAccord ? '0' : null}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenDetailsAccord(!openDetailsAccord)}> Material Issue Details</Accordion.Header>
                            <Accordion.Body>
                                <div className="row">
                                    <div className="col-md-5 col-12">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <MDButton type="button" className={`erp-gb-button ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" id="add-material"
                                                    fontWeight="regular" onClick={viewIndentsmodal} >Add Indent</MDButton>
                                            </div>
                                            <div className="col-sm-6 d-flex">
                                                <Form.Control type="text" id="txt-detail-data-highliter" style={{ display: 'none' }} className="erp_input_field txt-filter-bom showData" />
                                                <MDButton type="button" className="erp-gb-button erp_MLeft_btn showData" style={{ display: 'none' }} variant="button" onClick={() => scrollToTableRow()}
                                                    fontWeight="regular">Search</MDButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="erp-Mt-10">
                                    {materialIssueDetailsData.length !== 0 ?
                                        <>
                                            {renderDetailsTable}
                                        </> :
                                        <div className='row  text-center'>
                                            <div className="col-12 ">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion >

                    {keyForViewUpdate !== '' ?
                        <> <hr />
                            <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                                <Accordion.Item eventKey="documentList">
                                    <Accordion.Header className="erp-form-label-md p-0">Document List</Accordion.Header>
                                    <Accordion.Body>
                                        {docData.length !== 0 ? (
                                            renderDocumentTable
                                        ) : (
                                            <div className='row text-center'>
                                                <div className="col-12">
                                                    <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                        No Records Found...
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </>
                        : null
                    }
                </>
                <div className="text-center mb-5">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton type="button" id="save-btn" className="erp-gb-button erp_MLeft_btn" variant="button" onClick={() => { addIndentIssue() }}
                        fontWeight="regular">Save</MDButton>
                    <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' || keyForViewUpdate === '' || keyForViewUpdate === 'delete' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" id='viewdocument-id' onClick={viewDocumentForm} >Upload Document</MDButton>&nbsp;
                    <MDButton type="button" className="erp-gb-button erp_MLeft_btn" variant="button" id='clearbutton-id' onClick={FnClearFormFields} fontWeight="regular" disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? 'disabled' : ''}>Clear</MDButton>
                    <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => setShow(true)}>Delete</MDButton>
                </div >




                {/* Document modal */}
                <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <DocumentF group_id={txt_material_issue_no !== 0 ? txt_material_issue_no.replaceAll('/', '_') : null} document_group={docGroup} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ConfirmationModal close={() => closeConfirmationModal('Exit')} confirmation={() => closeConfirmationModal('Export&Exit')} show={[showConfirmationModal, succMsg, modalOrderDetails, messageForConfirmation]} />
                <SuccessModal handleCloseSuccessModal={() => closeConfirmationModal('Exit')} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />

                {/* Add new Record Popup */}
                {/* <Modal size="lg" show={showAddRecIndentIssueModal} onHide={closeRecordAddIndentIssueModal} backdrop="static" keyboard={false} centered >
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeRecordAddIndentIssueModal}></button></span>
                    </Modal.Header>
                    <Modal.Body className='erp_city_modal_body'>
                        {displayRecordIndentIssueComponent()}
                    </Modal.Body>
                    <Modal.Footer>
                        <MDButton type="button" onClick={closeRecordAddIndentIssueModal} className="btn erp-gb-button" variant="button"
                            fontWeight="regular">Close</MDButton>
                    </Modal.Footer>
                </Modal > */}

                <Modal size="lg" show={showAddRecIndentIssueModal} onHide={closeRecordAddIndentIssueModal} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        <div className='row'>
                            <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeRecordAddIndentIssueModal}></button></div>
                        </div>
                        {displayRecordIndentIssueComponent()}

                    </Modal.Body>
                </Modal >



                {/* ----------------------------------------------------------------------------------- */}
                <Modal size="lg" className='erp_document_Form' show={showIndentModalForm} onHide={handleCloseIndentModalForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title text-center'>Indent List</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseIndentModalForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <React.Fragment>
                            <div className='row p-2'>
                                <div className="col-12">
                                    {indent_SummaryList.length !== 0 ?
                                        <>
                                            <div class="col  pl-2">
                                                <input type='checkbox' class="me-1" name="selectAllIndentRecords" id="selectAllIndentRecords" onClick={(e) => { toggleChkAllBoxes('selectAllIndentRecords'); }} /> <label class="erp-form-label pb-1"> Select All </label>
                                            </div>
                                            {renderIndentTbl}
                                        </> :
                                        <div className='row text-center'>
                                            <div className="col-12">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="erp_frm_Btns ">
                                <MDButton type="button" onClick={showIndentDetails} className="btn erp-gb-button" variant="button"
                                    fontWeight="regular">Add</MDButton>
                            </div>
                        </React.Fragment>
                    </Modal.Body>
                </Modal>
                {/* --------------------------------------------------------------------------------------- */}

                {/* Delete Modal */}
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to delete this record ?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={handleClose}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button>
                    </Modal.Footer>
                </Modal>
            </DashboardLayout >
        </>
    )
}