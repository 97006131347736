import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $ from 'jquery';


// Imports React bootstrap
import Form from 'react-bootstrap/Form';
// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ErrorModal from "components/Modals/ErrorModal";
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ConfigConstants from "assets/Constants/config-constant";
import ComboBox from "Features/ComboBox";



function FrmCountEntry() {
    var activeValue = '';

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName } = configConstants;
    const { state } = useLocation();
    const { production_count_id, keyForViewUpdate, compType } = state || {}


    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        navigate(`/Masters/MCount/FrmCountListing`);
    }
    const validate = useRef();
    const combobox = useRef();


    const navigate = useNavigate();
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [succMsg, setSuccMsg] = useState('');
    const [txt_Count_ID, settxt_Count_ID] = useState(production_count_id);
    const [txt_Mixing, settxt_Mixing] = useState(0);
    const [txt_Count_Desc, settxt_Count_Desc] = useState('');
    const [txt_Count_name, settxt_Count_name] = useState('');
    const [txt_Speed, settxt_Speed] = useState(0);
    const [txt_TPI, settxt_TPI] = useState(0);
    const [txt_TM, settxt_TM] = useState(0);
    const [txt_40_Conversion, settxt_40_Conversion] = useState(0);
    const [txt_GMSMin_40S, settxt_GMSMin_40S] = useState(0);
    const [txt_Actual_GMS, settxt_Actual_GMS] = useState(0);
    const [txt_CF_BlowRoom, settxt_CF_BlowRoom] = useState(0);
    const [txt_CF_Card, settxt_CF_Card] = useState(0);
    const [txt_CF_Draw_Frame, settxt_CF_Draw_Frame] = useState(0);
    const [txt_CF_Speed_Frame, settxt_CF_Speed_Frame] = useState(0);
    const [txt_40_Conversion_Act_Count, settxt_40_Conversion_Act_Count] = useState(0);
    const [txt_Yarn_Construction, settxt_Yarn_Construction] = useState(0);
    const [txt_Actual_Count, settxt_Actual_Count] = useState(0);
    const [txt_CF_Ring_Frame, settxt_CF_Ring_Frame] = useState(0);
    const [txt_40s_Electrical_Conv_Factor, settxt_40s_Electrical_ConvFactor] = useState(0);
    const [txt_GPS_Level1, settxt_GPS_Level1] = useState(0);
    const [txt_GPS_Level2, settxt_GPS_Level2] = useState(0);
    const [txt_GPS_Level3, settxt_GPS_Level3] = useState(0);
    const [txt_GPS_Level4, settxt_GPS_Level4] = useState(0);
    const [txt_GPS_Level5, settxt_GPS_Level5] = useState(0);
    const [txt_UKG_Conv_Factor, settxt_UKG_Conv_Factor] = useState(0);
    const [txt_Doff_Time_Tolerance, settxt_Doff_Time_Tolerance] = useState(0);
    const [txt_Peneumefil_Wastage, settxt_Peneumefil_Wastage] = useState(0);
    const [txt_Weaving_Cone_Weight,settxt_Weaving_Cone_Weight] = useState(0);
    const [txt_Single_Cone_Weight,settxt_Single_Cone_Weight] = useState(0);
    const [txt_TPO_Cone_Weight,settxt_TPO_Cone_Weight] = useState(0);
    const [actionType, setActionType] = useState('')
    const [actionLabel, setActionLabel] = useState('Save')


    useEffect(() => {
        const functionCall = async () => {
            await ActionType()

            if (production_count_id !== 0) {
                await FnCheckUpdateResponce();
            }

        }
        functionCall()
    }, [])
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);


    const inputBoxesOnChange = async (key) => {
        switch (key) {
            case 'count':
                const txt_Count_Desc = document.getElementById('txt_Count_Desc').value.trim();
                const txt_Mixing = document.getElementById('txt_Mixing').value.trim();
                settxt_Count_name(txt_Mixing + " " + txt_Count_Desc + " ");
                break;
        }
    }


    const FnCheckUpdateResponce = async () => {
        try {
            if (production_count_id !== "undefined" && production_count_id !== '' && production_count_id !== null) {

                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmSpinningProdCount/FnShowParticularRecordForUpdate/${production_count_id}/${COMPANY_ID}`)
                const updateRes = await apiCall.json();

                let resp = (updateRes.data)

                settxt_Count_ID(resp.production_count_id)
                settxt_Mixing(resp.production_mixing);
                settxt_Count_Desc(resp.production_count_desc);
                settxt_Count_name(resp.production_count_name);
                settxt_Speed(resp.count_speed);
                settxt_TPI(resp.count_tpi);
                settxt_Actual_GMS(resp.count_actual_grams);
                settxt_40_Conversion_Act_Count(resp.count_40conversion_actual_count);
                settxt_GMSMin_40S(resp.count_40grams_minutes);
                settxt_CF_BlowRoom(resp.count_conversionfactor_blowroom);
                settxt_CF_Card(resp.count_conversionfactor_carding);
                settxt_CF_Draw_Frame(resp.count_conversionfactor_drawframe);
                settxt_CF_Speed_Frame(resp.count_conversionfactor_speedframe);
                settxt_Actual_Count(resp.production_actual_count);
                settxt_Yarn_Construction(resp.count_yarn_construction)
                settxt_CF_Ring_Frame(resp.count_conversionfactor_ringframe)
                settxt_TM(resp.count_tm)
                settxt_40_Conversion(resp.count_40conversion)

                $('#saveBtn').show();

                switch (resp.is_active) {
                    case true:
                        document.querySelector('input[name="isactive"][value="1"]').checked = true;
                        break;
                    case false:
                        document.querySelector('input[name="isactive"][value="0"]').checked = true;
                        break;
                    default: break;
                }

            }

        } catch (error) {
            console.log("error", error)
            navigate('/Error')
        }
    }
    //test case 5 shivanjali
    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                $('#txt_Mixing').attr('disabled', true);
                $('#txt_Count_Desc').attr('disabled', true);
                $('#txt_Count_name').attr('disabled', true);
                $('#txt_Actual_Count').attr('disabled', true);
                $('#saveBtn').attr('disabled', false);
                break;
            case 'view':
                setActionType('(View)');
                $("input[type=radio]").attr('disabled', true);
                await validate.current.readOnly("CountFormID");
                break;
            default:
                setActionType('(Creation)');
                break;
        }
    };


    const validateFields = () => {
        validate.current.validateFieldsOnChange('CountFormID')
    }

    const handleSubmit = async () => {
        try {
            const checkIsValidate = await validate.current.validateForm("CountFormID");

            if (checkIsValidate === true) {
                var active;

                activeValue = document.querySelector('input[name=isactive]:checked').value

                switch (activeValue) {
                    case '1': active = true; break;
                    case '0': active = false; break;
                    default: break;
                }
                const data = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    created_by: UserName,
                    production_count_id: txt_Count_ID,
                    modified_by: production_count_id === 0 ? null : UserName,
                    production_count_id: production_count_id,
                    production_mixing: txt_Mixing,
                    production_count_desc: txt_Count_Desc,
                    production_count_name: txt_Count_name,
                    production_actual_count: txt_Actual_Count,
                    count_40grams_minutes: txt_GMSMin_40S,
                    count_speed: txt_Speed,
                    count_tpi: txt_TPI,
                    count_tm: txt_TM,
                    count_40conversion: txt_40_Conversion,
                    count_40conversion_actual_count: txt_40_Conversion_Act_Count,
                    count_actual_grams: txt_Actual_GMS,
                    count_yarn_construction: txt_Yarn_Construction,
                    count_conversionfactor_blowroom: txt_CF_BlowRoom,
                    count_conversionfactor_carding: txt_CF_Card,
                    count_conversionfactor_drawframe: txt_CF_Draw_Frame,
                    count_conversionfactor_speedframe: txt_CF_Speed_Frame,
                    count_conversionfactor_ringframe: txt_CF_Ring_Frame,
                    is_active: active,

                };
                console.log(data);
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmSpinningProdCount/FnAddUpdateRecord`, requestOptions)
                const responce = await apicall.json()
                console.log("response error: ", responce);
                if (responce.success === '0') {
                    console.log("response error: ", responce.error);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)

                } else {
                    const evitCache = combobox.current.evitCache();
                    console.log(evitCache);
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                    clearFormFields();
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }

    };

    const clearFormFields = () => {
        settxt_Mixing('')
        settxt_Count_Desc('')
        settxt_Count_name('')
        settxt_Speed('')
        settxt_TPI('')
        settxt_Actual_GMS('')
        settxt_40_Conversion_Act_Count('')
        settxt_GMSMin_40S('')
        settxt_Actual_GMS('')
        settxt_CF_BlowRoom('')
        settxt_CF_Card('')
        settxt_CF_Draw_Frame('')
        settxt_CF_Speed_Frame('')
        settxt_Actual_Count('')


    }


    //test case 4 shivanjali
    const validateNo = (key) => {
        const numCheck = /^[0-9]*\.?[0-9]*$/;
        const regexNo = /^[0-9]*\.[0-9]{5}$/


        var dot = '.';

        switch (key) {

            case 'txt_Mixing':
                var txt_Mixing = $('#txt_Mixing').val();
                if (!regexNo.test(txt_Mixing) && txt_Mixing.length <= 14 || txt_Mixing.indexOf(dot) === 14) {
                    if (numCheck.test(txt_Mixing)) {
                        settxt_Mixing(txt_Mixing)
                    }

                }
                break;

            case 'txt_Actual_Count':
                var txt_Actual_Count = $('#txt_Actual_Count').val();
                if (!regexNo.test(txt_Actual_Count) && txt_Actual_Count.length <= 14 || txt_Actual_Count.indexOf(dot) === 14) {
                    if (numCheck.test(txt_Actual_Count)) {
                        settxt_Actual_Count(txt_Actual_Count)
                    }

                }
                break;
            case 'txt_Speed':
                var MachineStopagecapacity = $('#txt_Speed').val();
                if (!regexNo.test(MachineStopagecapacity) && MachineStopagecapacity.length <= 14 || MachineStopagecapacity.indexOf(dot) === 14) {
                    if (numCheck.test(MachineStopagecapacity)) {
                        settxt_Speed(MachineStopagecapacity)
                    }

                }
                break;

            case 'txt_TPI':
                var txt_TPI = $('#txt_TPI').val();
                if (!regexNo.test(txt_TPI) && txt_TPI.length <= 14 || txt_TPI.indexOf(dot) === 14) {
                    if (numCheck.test(txt_TPI)) {
                        settxt_TPI(txt_TPI)
                    }

                }
                break;

            case 'txt_TM':
                var txt_TM = $('#txt_TM').val();
                if (!regexNo.test(txt_TM) && txt_TM.length <= 14 || txt_TM.indexOf(dot) === 14) {
                    if (numCheck.test(txt_TM)) {
                        settxt_TM(txt_TM)
                    }

                }
                break;
            case 'txt_40_Conversion':
                var txt_40_Conversion = $('#txt_40_Conversion').val();
                if (!regexNo.test(txt_40_Conversion) && txt_40_Conversion.length <= 14 || txt_40_Conversion.indexOf(dot) === 14) {
                    if (numCheck.test(txt_40_Conversion)) {
                        settxt_40_Conversion(txt_40_Conversion)
                    }

                }
                break;
            case 'txt_40_Conversion_Act_Count':
                var txt_40_Conversion_Act_Count = $('#txt_40_Conversion_Act_Count').val();
                if (!regexNo.test(txt_40_Conversion_Act_Count) && txt_40_Conversion_Act_Count.length <= 14 || txt_40_Conversion_Act_Count.indexOf(dot) === 14) {
                    if (numCheck.test(txt_40_Conversion_Act_Count)) {
                        settxt_40_Conversion_Act_Count(txt_40_Conversion_Act_Count)
                    }

                }
                break;
            case 'txt_Actual_GMS':
                var txt_Actual_GMS = $('#txt_Actual_GMS').val();
                if (!regexNo.test(txt_Actual_GMS) && txt_Actual_GMS.length <= 14 || txt_Actual_GMS.indexOf(dot) === 14) {
                    if (numCheck.test(txt_Actual_GMS)) {
                        settxt_Actual_GMS(txt_Actual_GMS)
                    }

                }
                break;
            case 'txt_GMSMin_40S':
                var txt_GMSMin_40S = $('#txt_GMSMin_40S').val();
                if (!regexNo.test(txt_GMSMin_40S) && txt_GMSMin_40S.length <= 14 || txt_GMSMin_40S.indexOf(dot) === 14) {
                    if (numCheck.test(txt_GMSMin_40S)) {
                        settxt_GMSMin_40S(txt_GMSMin_40S)
                    }

                }
                break;
            case 'txt_Yarn_Construction':
                var txt_Yarn_Construction = $('#txt_Yarn_Construction').val();
                if (!regexNo.test(txt_Yarn_Construction) && txt_Yarn_Construction.length <= 14 || txt_Yarn_Construction.indexOf(dot) === 14) {
                    if (numCheck.test(txt_Yarn_Construction)) {
                        settxt_Yarn_Construction(txt_Yarn_Construction)
                    }

                }
                break;
            case 'txt_CF_BlowRoom':
                var txt_CF_BlowRoom = $('#txt_CF_BlowRoom').val();
                if (!regexNo.test(txt_CF_BlowRoom) && txt_CF_BlowRoom.length <= 14 || txt_CF_BlowRoom.indexOf(dot) === 14) {
                    if (numCheck.test(txt_CF_BlowRoom)) {
                        settxt_CF_BlowRoom(txt_CF_BlowRoom)
                    }

                }
                break;
            case 'txt_CF_Card':
                var txt_CF_Card = $('#txt_CF_Card').val();
                if (!regexNo.test(txt_CF_Card) && txt_CF_Card.length <= 14 || txt_CF_Card.indexOf(dot) === 14) {
                    if (numCheck.test(txt_CF_Card)) {
                        settxt_CF_Card(txt_CF_Card)
                    }

                }
                break;
            case 'txt_CF_Draw_Frame':
                var txt_CF_Draw_Frame = $('#txt_CF_Draw_Frame').val();
                if (!regexNo.test(txt_CF_Draw_Frame) && txt_CF_Draw_Frame.length <= 14 || txt_CF_Draw_Frame.indexOf(dot) === 14) {
                    if (numCheck.test(txt_CF_Draw_Frame)) {
                        settxt_CF_Draw_Frame(txt_CF_Draw_Frame)
                    }

                }
                break;
            case 'txt_CF_Speed_Frame':
                var txt_CF_Speed_Frame = $('#txt_CF_Speed_Frame').val();
                if (!regexNo.test(txt_CF_Speed_Frame) && txt_CF_Speed_Frame.length <= 14 || txt_CF_Speed_Frame.indexOf(dot) === 14) {
                    if (numCheck.test(txt_CF_Speed_Frame)) {
                        settxt_CF_Speed_Frame(txt_CF_Speed_Frame)
                    }

                }
                break;
            case 'txt_CF_Ring_Frame':
                var txt_CF_Ring_Frame = $('#txt_CF_Ring_Frame').val();
                if (!regexNo.test(txt_CF_Ring_Frame) && txt_CF_Ring_Frame.length <= 14 || txt_CF_Ring_Frame.indexOf(dot) === 14) {
                    if (numCheck.test(txt_CF_Ring_Frame)) {
                        settxt_CF_Ring_Frame(txt_CF_Ring_Frame)
                    }

                }
                break;

            case 'txt_40s_Electrical_Conv_Factor':
                var MachineStopagecapacity = $('#txt_40s_Electrical_Conv_Factor').val();
                if (!regexNo.test(MachineStopagecapacity) && MachineStopagecapacity.length <= 14 || MachineStopagecapacity.indexOf(dot) === 14) {
                    if (numCheck.test(MachineStopagecapacity)) {
                        settxt_40s_Electrical_ConvFactor(MachineStopagecapacity)
                    }

                }
                break;

            case 'txt_GPS_Level1':
                var MachineStopagecapacity = $('#txt_GPS_Level1').val();
                if (!regexNo.test(MachineStopagecapacity) && MachineStopagecapacity.length <= 14 || MachineStopagecapacity.indexOf(dot) === 14) {
                    if (numCheck.test(MachineStopagecapacity)) {
                        settxt_GPS_Level1(MachineStopagecapacity)
                    }
                }
                break;

            case 'txt_GPS_Level2':
                var MachineStopagecapacity = $('#txt_GPS_Level2').val();
                if (!regexNo.test(MachineStopagecapacity) && MachineStopagecapacity.length <= 14 || MachineStopagecapacity.indexOf(dot) === 14) {
                    if (numCheck.test(MachineStopagecapacity)) {
                        settxt_GPS_Level2(MachineStopagecapacity)
                    }
                }
                break;

            case 'txt_GPS_Level3':
                var MachineStopagecapacity = $('#txt_GPS_Level3').val();
                if (!regexNo.test(MachineStopagecapacity) && MachineStopagecapacity.length <= 14 || MachineStopagecapacity.indexOf(dot) === 14) {
                    if (numCheck.test(MachineStopagecapacity)) {
                        settxt_GPS_Level3(MachineStopagecapacity)
                    }
                }
                break;

            case 'txt_GPS_Level4':
                var MachineStopagecapacity = $('#txt_GPS_Level4').val();
                if (!regexNo.test(MachineStopagecapacity) && MachineStopagecapacity.length <= 14 || MachineStopagecapacity.indexOf(dot) === 14) {
                    if (numCheck.test(MachineStopagecapacity)) {
                        settxt_GPS_Level4(MachineStopagecapacity)
                    }
                }
                break;

            case 'txt_GPS_Level5':
                var MachineStopagecapacity = $('#txt_GPS_Level5').val();
                if (!regexNo.test(MachineStopagecapacity) && MachineStopagecapacity.length <= 14 || MachineStopagecapacity.indexOf(dot) === 14) {
                    if (numCheck.test(MachineStopagecapacity)) {
                        settxt_GPS_Level5(MachineStopagecapacity)
                    }
                }
                break;

            case 'txt_UKG_Conv_Factor':
                var MachineStopagecapacity = $('#txt_UKG_Conv_Factor').val();
                if (!regexNo.test(MachineStopagecapacity) && MachineStopagecapacity.length <= 14 || MachineStopagecapacity.indexOf(dot) === 14) {
                    if (numCheck.test(MachineStopagecapacity)) {
                        settxt_UKG_Conv_Factor(MachineStopagecapacity)
                    }
                }
                break;

            case 'txt_Doff_Time_Tolerance':
                var MachineStopagecapacity = $('#txt_Doff_Time_Tolerance').val();
                if (!regexNo.test(MachineStopagecapacity) && MachineStopagecapacity.length <= 14 || MachineStopagecapacity.indexOf(dot) === 14) {
                    if (numCheck.test(MachineStopagecapacity)) {
                        settxt_Doff_Time_Tolerance(MachineStopagecapacity)
                    }
                }
                break;

            case 'txt_Peneumefil_Wastage':
                var MachineStopagecapacity = $('#txt_Peneumefil_Wastage').val();
                if (!regexNo.test(MachineStopagecapacity) && MachineStopagecapacity.length <= 14 || MachineStopagecapacity.indexOf(dot) === 14) {
                    if (numCheck.test(MachineStopagecapacity)) {
                        settxt_Peneumefil_Wastage(MachineStopagecapacity)
                    }
                }
                break;

            case 'txt_TPO_Cone_Weight':
                var MachineStopagecapacity = $('#txt_TPO_Cone_Weight').val();
                if (!regexNo.test(MachineStopagecapacity) && MachineStopagecapacity.length <= 14 || MachineStopagecapacity.indexOf(dot) === 14) {
                    if (numCheck.test(MachineStopagecapacity)) {
                        settxt_TPO_Cone_Weight(MachineStopagecapacity)
                    }
                }
                break;

                case 'txt_Single_Cone_Weight':
                var MachineStopagecapacity = $('#txt_Single_Cone_Weight').val();
                if (!regexNo.test(MachineStopagecapacity) && MachineStopagecapacity.length <= 14 || MachineStopagecapacity.indexOf(dot) === 14) {
                    if (numCheck.test(MachineStopagecapacity)) {
                        settxt_Single_Cone_Weight(MachineStopagecapacity)
                    }
                }
                break;

                case 'txt_Weaving_Cone_Weight':
                var MachineStopagecapacity = $('#txt_Weaving_Cone_Weight').val();
                if (!regexNo.test(MachineStopagecapacity) && MachineStopagecapacity.length <= 14 || MachineStopagecapacity.indexOf(dot) === 14) {
                    if (numCheck.test(MachineStopagecapacity)) {
                        settxt_Weaving_Cone_Weight(MachineStopagecapacity)
                    }
                }
                break;
        }
    }




    return (
        //test case 2 shivanjali
        <>
            <ComboBox ref={combobox} />
            <FrmValidations ref={validate} />
            <div className="erp_top_Form">
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg main_heding'>Production Count{actionType}</label>
                    </div>

                    <form id="CountFormID">

                        <div className="row erp_transporter_div">

                            {/* //first column */}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Production Mixing</Form.Label>
                                    </div>
                                    <div className="col">

                                        <div className='col'>
                                            <Form.Control type="text" id="txt_Mixing" className="erp_input_field text-end" value={txt_Mixing} onInput={e => { validateNo('txt_Mixing'); inputBoxesOnChange('count') }} maxLength="19" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_Mixing" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Count Desc<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_Count_Desc" className="erp_input_field" value={txt_Count_Desc} onInput={e => { settxt_Count_Desc(e.target.value); validateFields(); inputBoxesOnChange('count') }} maxLength="500" />
                                        <MDTypography variant="button" id="error_txt_Count_Desc" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Count Name<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_Count_name" className="erp_input_field" value={txt_Count_name} onChange={e => { settxt_Count_name(e.target.value); validateFields(); }} maxLength="500" />
                                        <MDTypography variant="button" id="error_txt_Count_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Actual Count</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_Actual_Count" className="erp_input_field text-end" value={txt_Actual_Count} onChange={e => { validateNo('txt_Actual_Count'); }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_Actual_Count" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Count Speed</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="col">
                                            <Form.Control type="text" id="txt_Speed" className="erp_input_field text-end" value={txt_Speed} onChange={e => { validateNo('txt_Speed'); validateFields(); }} maxLength="19" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_Speed" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Count Tpi</Form.Label>
                                    </div>
                                    <div className="col">

                                        <div className='col'>
                                            <Form.Control type="text" id="txt_TPI" className="erp_input_field text-end" value={txt_TPI} onChange={e => { validateNo('txt_TPI'); }} maxLength="19" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_TPI" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Count Tm</Form.Label>
                                    </div>
                                    <div className="col">

                                        <div className='col'>
                                            <Form.Control type="text" id="txt_TM" className="erp_input_field text-end" value={txt_TM} onChange={e => { validateNo('txt_TM'); }} maxLength="19" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_TM" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">40 Conversion</Form.Label>
                                    </div>
                                    <div className="col">

                                        <div className='col'>
                                            <Form.Control type="text" id="txt_40_Conversion" className="erp_input_field text-end" value={txt_40_Conversion} onChange={e => { validateNo('txt_40_Conversion'); validateFields(); }} maxLength="19" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_40_Conversion" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">40 Con Actual Count</Form.Label>
                                    </div>
                                    <div className="col">

                                        <div className='col'>
                                            <Form.Control type="text" id="txt_40_Conversion_Act_Count" className="erp_input_field text-end" value={txt_40_Conversion_Act_Count} onChange={e => { validateNo('txt_40_Conversion_Act_Count'); }} maxLength="19" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_40_Conversion_Act_Count" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">40 grams Minutes</Form.Label>
                                    </div>
                                    <div className="col">

                                        <div className='col'>
                                            <Form.Control type="text" id="txt_GMSMin_40S" className="erp_input_field text-end" value={txt_GMSMin_40S} onChange={e => { validateNo('txt_GMSMin_40S'); }} maxLength="19" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_GMSMin_40S" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">40's Electrical Conv Factor</Form.Label>
                                    </div>
                                    <div className="col">

                                        <div className='col'>
                                            <Form.Control type="text" id="txt_40s_Electrical_Conv_Factor" className="erp_input_field text-end" value={txt_40s_Electrical_Conv_Factor} onChange={e => { validateNo('txt_40s_Electrical_Conv_Factor'); }} maxLength="19" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_40s_Electrical_Conv_Factor" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            {/* //second column */}
                            <div className="col-sm-4 erp_form_col_div">



                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Actual Grams</Form.Label>
                                    </div>
                                    <div className="col">

                                        <div className='col'>
                                            <Form.Control type="text" id="txt_Actual_GMS" className="erp_input_field text-end" value={txt_Actual_GMS} onChange={e => { validateNo('txt_Actual_GMS') }} maxLength="19" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_Actual_GMS" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                </div>


                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Yarn Construction</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_Yarn_Construction" className="erp_input_field text-end" value={txt_Yarn_Construction} onChange={e => { validateNo('txt_Yarn_Construction'); }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_Yarn_Construction" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Target GPS Level1</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_GPS_Level1" className="erp_input_field text-end" value={txt_GPS_Level1} onChange={e => { validateNo('txt_GPS_Level1'); }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_GPS_Level1" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Target GPS Level2</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_GPS_Level2" className="erp_input_field text-end" value={txt_GPS_Level2} onChange={e => { validateNo('txt_GPS_Level2'); }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_GPS_Level2" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Target GPS Level3</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_GPS_Level3" className="erp_input_field text-end" value={txt_GPS_Level3} onChange={e => { validateNo('txt_GPS_Level3'); }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_GPS_Level3" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Target GPS Level4</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_GPS_Level4" className="erp_input_field text-end" value={txt_GPS_Level4} onChange={e => { validateNo('txt_GPS_Level4'); }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_GPS_Level4" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Target GPS Level5</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_GPS_Level5" className="erp_input_field text-end" value={txt_GPS_Level5} onChange={e => { validateNo('txt_GPS_Level5'); }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_GPS_Level5" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">UKG Conv. Factor</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_UKG_Conv_Factor" className="erp_input_field text-end" value={txt_UKG_Conv_Factor} onChange={e => { validateNo('txt_UKG_Conv_Factor'); }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_UKG_Conv_Factor" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Doff Time Tolerance%</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_Doff_Time_Tolerance" className="erp_input_field text-end" value={txt_Doff_Time_Tolerance} onChange={e => { validateNo('txt_Doff_Time_Tolerance'); }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_Doff_Time_Tolerance" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Peneumefil Wastage%</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_Peneumefil_Wastage" className="erp_input_field text-end" value={txt_Peneumefil_Wastage} onChange={e => { validateNo('txt_Peneumefil_Wastage'); }} maxLength="19" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_Peneumefil_Wastage" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                            </div>

                            <div className="col-sm-4 erp_form_col_div">


                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">CF Blowroom</Form.Label>
                                    </div>
                                    <div className="col">

                                        <div className='col'>
                                            <Form.Control type="text" id="txt_CF_BlowRoom" className="erp_input_field text-end" value={txt_CF_BlowRoom} onChange={e => { validateNo('txt_CF_BlowRoom'); }} maxLength="19" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_CF_BlowRoom" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">CF Carding</Form.Label>
                                    </div>
                                    <div className="col">

                                        <div className='col'>
                                            <Form.Control type="text" id="txt_CF_Card" className="erp_input_field text-end" value={txt_CF_Card} onChange={e => { validateNo('txt_CF_Card') }} maxLength="19" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_CF_Card" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">CF Drawframe</Form.Label>
                                    </div>
                                    <div className="col">

                                        <div className='col'>
                                            <Form.Control type="text" id="txt_CF_Draw_Frame" className="erp_input_field text-end" value={txt_CF_Draw_Frame} onChange={e => { validateNo('txt_CF_Draw_Frame') }} maxLength="19" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_CF_Draw_Frame" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                </div>





                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">CF Speedframe</Form.Label>
                                    </div>
                                    <div className="col">

                                        <div className='col'>
                                            <Form.Control type="text" id="txt_CF_Speed_Frame" className="erp_input_field text-end" value={txt_CF_Speed_Frame} onChange={e => { validateNo('txt_CF_Speed_Frame') }} maxLength="19" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_CF_Speed_Frame" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">CF Ringframe</Form.Label>
                                    </div>
                                    <div className="col">

                                        <div className='col'>
                                            <Form.Control type="text" id="txt_CF_Ring_Frame" className="erp_input_field text-end" value={txt_CF_Ring_Frame} onChange={e => { validateNo('txt_CF_Ring_Frame') }} maxLength="19" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_CF_Ring_Frame" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">TPO Cone Weight</Form.Label>
                                    </div>
                                    <div className="col">

                                        <div className='col'>
                                            <Form.Control type="text" id="txt_TPO_Cone_Weight" className="erp_input_field text-end" value={txt_TPO_Cone_Weight} onChange={e => { validateNo('txt_TPO_Cone_Weight') }} maxLength="19" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_TPO_Cone_Weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Single Cone Weight</Form.Label>
                                    </div>
                                    <div className="col">

                                        <div className='col'>
                                            <Form.Control type="text" id="txt_Single_Cone_Weight" className="erp_input_field text-end" value={txt_Single_Cone_Weight} onChange={e => { validateNo('txt_Single_Cone_Weight') }} maxLength="19" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_Single_Cone_Weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Weaving Cone Weight</Form.Label>
                                    </div>
                                    <div className="col">

                                        <div className='col'>
                                            <Form.Control type="text" id="txt_Weaving_Cone_Weight" className="erp_input_field text-end" value={txt_Weaving_Cone_Weight} onChange={e => { validateNo('txt_Weaving_Cone_Weight') }} maxLength="19" optional="optional" />
                                            <MDTypography variant="button" id="error_txt_Weaving_Cone_Weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Is Active</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck">
                                                <Form.Check
                                                    className="erp_radio_button"
                                                    label="Yes"
                                                    type="radio"
                                                    value="1"
                                                    name="isactive"
                                                    defaultChecked
                                                />
                                            </div>
                                            <div className="sCheck">
                                                <Form.Check
                                                    className="erp_radio_button"
                                                    label="No"
                                                    value="0"
                                                    type="radio"
                                                    name="isactive"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>

                    <div className="card-footer py-0 text-center">
                        <MDButton type="button" className="erp-gb-button" variant="button"
                            onClick={() => {
                                const path = compType === 'Register' ? '/Masters/MCount/FrmCountListing/reg' : '/Masters/MCount/FrmCountListing';
                                navigate(path);
                            }}
                            fontWeight="regular">Back</MDButton>
                        {/* <input type="file" id="Excelfile" ref={importFile} onChange={importedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" /> */}
                        <MDButton type="submit" onClick={handleSubmit} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            fontWeight="regular">{actionLabel}</MDButton>
                    </div>
                </div>

                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

            </div>

        </>

    )
}

export default FrmCountEntry