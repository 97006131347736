import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Modal from 'react-bootstrap/Modal';
import Tooltip from "@mui/material/Tooltip";
import { resetGlobalQuery } from 'assets/Constants/config-constant';
import { globalQuery } from 'assets/Constants/config-constant';
// Imports React bootstra
import Form from 'react-bootstrap/Form';

// Icons
import { MdRefresh } from "react-icons/md";

// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import MCostCenterEntry from "Masters/MFMCostCenter/MCostCenterEntry/Index";
import ProfitCenterEntry from "Masters/ProfitCenter/ProfitCenterEntry";
import ConfigConstants from "assets/Constants/config-constant";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmValidations from "FrmGeneric/FrmValidations";


function DepartmentEntry(props) {
    //changes by ujjwala on 27/12/2024
    // Call ConfigConstants to get the configuration constants
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName } = configConstants;
    const { state } = useLocation();
    const { departmentId = 0, keyForViewUpdate, compType = 'Masters' } = state || {}

    // Use Refs to call external functions
    const validateNumberDateInput = useRef();
    const combobox = useRef();
    const validate = useRef();
    const navigate = useNavigate()

    //department Feilds
    const [department_id, setDepartment_id] = useState(departmentId === null ? 0 : departmentId);
    const [parentDepartment, setParentDepartment] = useState('');
    const [rb_departmentType, setDepartmentType] = useState('M');
    const [departmentName, setDepartmentName] = useState('');
    const [shortName, setShortName] = useState('');
    const [departmentGroup, setDepartmentGroup] = useState('');
    const [costCenter, setCostCenter] = useState('');
    const [txt_profitCenter, setProfitCenter] = useState('');
    const [DepartmentHeadID, setDepartmentHeadID] = useState();
    const [DepartSubHeadID, setDepartSubHeadID] = useState();
    const [departStaffStrength, setDepartStaffStrength] = useState(1);
    const [txt_department_std_worker_strength, setDepartmentWorkerStrength] = useState(1);
    const [chk_isactive, setIsActive] = useState(true);
    const [actionType, setActionType] = useState('')
    const [actionLabel, setActionLabel] = useState('Save')

    //option Box
    const [parentDepartOption, setParentDepartOption] = useState([]);
    const [departmentGrpOption, setDepartmentGrOption] = useState([]);
    const [branchHeadOptions, setBranchHeadOptions] = useState([]);
    const [DeptSubHeadOptions, setDeptSubHeadOptions] = useState([]);
    const [costCenterOption, setCostCenterOption] = useState([]);
    const [profitCenterOption, setProfitCenterOption] = useState([]);

    // to add new records in combo box 
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Masters/DepartmentListing`)
        }
    }

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    useEffect(() => {
        const loadDataOnload = async () => {
            await ActionType()
            await FillComobos();
            if (departmentId !== 0) {
                await FnCheckUpdateResponce()
            }
        }
        loadDataOnload()
    }, [])

    const addDepartment = async () => {
        try {
            const checkIsValidate = await validate.current.validateForm("departmentform");
            if (checkIsValidate === true) {
                const data = {
                    company_branch_id: COMPANY_BRANCH_ID,
                    company_id: COMPANY_ID,
                    department_id: department_id,
                    created_by: UserName,
                    modified_by: department_id === null ? null : UserName,
                    parent_department_id: parentDepartment,
                    department_type: rb_departmentType,
                    department_name: departmentName,
                    department_short_name: shortName,
                    department_group: departmentGroup,
                    cost_center_id: costCenter,
                    profit_center_id: txt_profitCenter,
                    department_head_id: DepartmentHeadID,
                    department_subhead_id: DepartSubHeadID,
                    department_std_staff_strength: departStaffStrength,
                    department_std_worker_strength: txt_department_std_worker_strength,
                    is_active: chk_isactive,
                };
                console.log(data);
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/department/FnAddUpdateRecord`, requestOptions)
                const responce = await apicall.json()
                if (responce.error !== "") {
                    console.log("response error: ", responce.error);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = combobox.current.evitCache();
                    console.log(evitCache);
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    };

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modify)');
                setActionLabel('Update')
                $('#rb_departmentType').attr('disabled', true)
                $('#departmentGroup').attr('disabled', true)
                $('#departmentName').attr('disabled', true)
                $('#shortName').attr('disabled', true)
                break;
            case 'view':
                setActionType('(View)');
                await validate.current.readOnly("departmentform");
                $("input[type=radio]").attr('disabled', true);
                break;
            default:
                setActionType('(Create)');
                break;
        }
    };

    const validateFields = () => {
        validate.current.validateFieldsOnChange('departmentform')
    }

    const FnCheckUpdateResponce = async () => {
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/department/FnShowParticularRecordForUpdate/${department_id}/${COMPANY_ID}`)
            const updateRes = await apiCall.json();
            const data = JSON.parse(updateRes.data)
            setDepartment_id(data.department_id)
            setParentDepartment(data.parent_department_id)
            setDepartmentType(data.department_type);
            setDepartmentName(data.department_name);
            setShortName(data.department_short_name);
            setDepartmentGroup(data.department_group);
            setCostCenter(data.cost_center_id);
            setProfitCenter(data.profit_center_id);
            setDepartmentHeadID(data.department_head_id);
            setDepartSubHeadID(data.department_subhead_id);
            setDepartStaffStrength(data.department_std_staff_strength);
            setDepartmentWorkerStrength(data.department_std_worker_strength);
            setIsActive(data.is_active);
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const FillComobos = async () => {
        if (combobox.current) {
            combobox.current.fillMasterData("cmv_department", "", "").then((departmentList) => {
                setParentDepartOption(departmentList)

            })
            combobox.current.fillComboBox("DepartmentGroup").then((departmentGroupList) => {
                setDepartmentGrOption(departmentGroupList)
            })
            combobox.current.fillMasterData("cmv_employee", "", "").then((employeeList) => {
                setBranchHeadOptions(employeeList)
                setDeptSubHeadOptions(employeeList)
            })

            //Costcenter List
            try {
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
                globalQuery.table = "fmv_cost_center";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const costCenterList = await combobox.current.fillFiltersCombo(globalQuery);
                setCostCenterOption(costCenterList);

            } catch (error) {
                console.log("error : ", error)
            }
            ///Profitcenter List
            try {
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'profit_center_short_name'];
                globalQuery.table = "fmv_profit_center";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

                const profitCenterList = await combobox.current.fillFiltersCombo(globalQuery);
                setProfitCenterOption(profitCenterList);

            } catch (error) {
                console.log("error : ", error)
            }
        }
    }

    const addRecordInProperty = async (key) => {
        switch (key) {
            case 'cmv_department':
                const departlist = document.getElementById('parentDepartId').value
                setParentDepartment(departlist)
                $('#error_parentDepartId').hide();
                break;
            case 'DepartmentGroup':
                const departmentGrouplist = document.getElementById('departmentGroup').value
                setDepartmentGroup(departmentGrouplist)
                $('#error_departmentGroup').hide();
                break;
            case 'DepartmentHead':
                const departmentHead = document.getElementById('departmentHeadId').value;
                setDepartmentHeadID(departmentHead)
                $('#error_departmentHeadId').hide();
                if (departmentHead === '0') {
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                break;

            case 'DepartmentSubHead':
                const departmentSubHead = document.getElementById('deptSubHeadId').value;
                setDepartSubHeadID(departmentSubHead)
                $('#error_deptSubHeadId').hide();
                if (departmentSubHead === '0') {
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                break;
            case 'cost_center':
                const costcenter = document.getElementById('costcenterId').value;
                setCostCenter(costcenter)
                $('error_costcenterId').hide();
                if (costcenter === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Cost Center')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");
                    }, 100)
                }
                break;
            case 'profit_center':
                const profitcenter = document.getElementById('txt_profitCenter').value;
                setProfitCenter(profitcenter)
                $('error_txt_profitCenter').hide();
                if (profitcenter === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Profit Center')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");
                    }, 100)
                }
                break;
            default: break;
        }
    }


    const handleCloseRecModal = async () => {
        switch (modalHeaderName) {
            case 'Cost Center':
                try {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
                    globalQuery.table = "fmv_cost_center";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const profitCenterList = await combobox.current.fillFiltersCombo(globalQuery);
                    setCostCenterOption(profitCenterList);

                } catch (error) {
                    console.log("error : ", error)
                }
                break;
            case 'Profit Center':
                try {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'profit_center_short_name'];
                    globalQuery.table = "fmv_profit_center";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const profitCenterList = await combobox.current.fillFiltersCombo(globalQuery);
                    setProfitCenterOption(profitCenterList);

                } catch (error) {
                    console.log("error : ", error)
                }
                break;
            default:
                break;
        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 100)
    }


    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'Cost Center':
                return <MCostCenterEntry btn_disabled={true} />;
            case 'Profit Center':
                return <ProfitCenterEntry btn_disabled={true} />;

            default:
                return null;
        }
    }


    const FnRefreshbtn = async (key) => {
        switch (key) {
            case 'DepartmentHead':
                combobox.current.fillMasterData("cmv_employee", "", "").then((empList1) => {
                    setBranchHeadOptions(empList1)
                })
                break;

            case 'DepartmentSubHead':
                combobox.current.fillMasterData("cmv_employee", "", "").then((empList) => {
                    setDeptSubHeadOptions(empList)
                })
                break;

            default:
                break;
        }
    }


    return (
        <>
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <ComboBox ref={combobox} />
            <FrmValidations ref={validate} />
            <div className="erp_top_Form">
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Department Information{actionType} </label>
                    </div>
                    <form id="departmentform">
                        <div className="row erp_transporter_div">
                            <div className="col-sm-6 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Department Type <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Main" type="radio" lbl="Main" value="M" id="rb_departmentType" name="rb_departmentType" checked={rb_departmentType === "M"} onClick={() => { setDepartmentType("M"); }} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="Sub" type="radio" lbl="Sub" value="S" id="rb_departmentType" name="rb_departmentType" checked={rb_departmentType === "S"} onClick={() => { setDepartmentType("S"); }} /> </div>
                                        </div>
                                    </div>

                                </div>
                                {rb_departmentType === "S" ?
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Parent Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <select size="sm" id="parentDepartId" value={parentDepartment} className="form-select form-select-sm erp_input_field" onChange={() => addRecordInProperty('cmv_department')} disabled={keyForViewUpdate === 'view'}>
                                                <option value="">Select</option>

                                                {parentDepartOption?.map(parentDepart => (
                                                    <option value={parentDepart.field_id}>{parentDepart.field_name}</option>

                                                ))}

                                            </select>

                                            <MDTypography variant="button" id="error_parentDepartId" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    : ''
                                }

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Department Name <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" className="erp_input_field" id="departmentName" value={departmentName} onChange={e => { setDepartmentName(e.target.value); validateFields() }} maxLength="255" />
                                        <MDTypography variant="button" id="error_departmentName" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Short Name </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="shortName" className="erp_input_field" value={shortName} onChange={(e) => { setShortName(e.target.value.toUpperCase()); validateFields() }} maxLength="5" optional="optional" />
                                        <MDTypography variant="button" id="error_shortName" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Department Group <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select size="sm" id="departmentGroup" value={departmentGroup} className="form-select form-select-sm erp_input_field" onChange={() => { addRecordInProperty('DepartmentGroup'); }}>
                                            <option value="">Select</option>
                                            {departmentGrpOption?.map(deptGroup => (
                                                <option value={deptGroup.field_name}>{deptGroup.field_name}</option>

                                            ))}

                                        </select>
                                        <MDTypography variant="button" id="error_departmentGroup" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Profit Center</Form.Label>
                                    </div>
                                    <div className="col">
                                        <select size="sm" id="txt_profitCenter" value={txt_profitCenter} className="form-select form-select-sm erp_input_field" onChange={() => { addRecordInProperty('profit_center'); }} optional="optional" >
                                            <option value="" disabled>Select</option>
                                            <option value="0">Add New Record+</option>
                                            {profitCenterOption?.map(option => (
                                                <option value={option.field_id}>[{option.profit_center_short_name} ] {option.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_txt_profitCenter" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Cost Center </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select size="sm" id="costcenterId" value={costCenter} className="form-select form-select-sm erp_input_field" onChange={() => { addRecordInProperty('cost_center'); }} optional="optional" >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>

                                            {costCenterOption?.map(option => (
                                                <option value={option.field_id}>[{option.cost_center_short_name} ] {option.field_name}</option>
                                            ))}

                                        </select>

                                        <MDTypography variant="button" id="error_costcenterId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            {/* second column */}
                            <div className="col-sm-6 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4 col-12">
                                        <Form.Label className="erp-form-label"> Head <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-7 col-10">
                                        <select id="departmentHeadId" value={DepartmentHeadID} className="form-select form-select-sm erp_input_field" onChange={() => { addRecordInProperty("DepartmentHead"); }}>
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>

                                            {branchHeadOptions?.map(option => (
                                                <option value={option.field_id}>{option.field_name}</option>

                                            ))}
                                        </select>

                                        <MDTypography variant="button" id="error_departmentHeadId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography>
                                                <MdRefresh className={`${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} onClick={() => { FnRefreshbtn("DepartmentHead"); }} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4 col-12">
                                        <Form.Label className="erp-form-label"> SubHead</Form.Label>
                                    </div>
                                    <div className="col-sm-7 col-10">
                                        <select id="deptSubHeadId" value={DepartSubHeadID} className="form-select form-select-sm erp_input_field" onChange={() => { addRecordInProperty("DepartmentSubHead"); }} optional="optional" >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>

                                            {DeptSubHeadOptions?.map(option => (
                                                <option value={option.field_id}>{option.field_name}</option>

                                            ))}
                                        </select>

                                        <MDTypography variant="button" id="error_deptSubHeadId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography>
                                                <MdRefresh className={`${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} onClick={() => { FnRefreshbtn("DepartmentSubHead"); }} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Staff Strength <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="departmentStrength" className="erp_input_field erp_align-right" value={departStaffStrength} onChange={e => { if (validateNumberDateInput.current.isInteger(e.target.value)) { setDepartStaffStrength(e.target.value) } }} maxLength="11" />
                                        <MDTypography variant="button" id="error_departmentStrength" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Staff Wroker </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_department_std_worker_strength" className="erp_input_field erp_align-right" value={txt_department_std_worker_strength} onChange={e => { if (validateNumberDateInput.current.isInteger(e.target.value)) { setDepartmentWorkerStrength(e.target.value) } }} maxLength="11" />
                                        <MDTypography variant="button" id="error_txt_department_std_worker_strength" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Is Active</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" name="chk_isactive" checked={chk_isactive} onClick={() => { setIsActive(true); }} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" name="chk_isactive" checked={!chk_isactive} onClick={() => { setIsActive(false); }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="card-footer py-0 text-center">
                        <MDButton type="button" className="erp-gb-button"

                            onClick={() => {
                                const path = compType === 'Register' ? '/Masters/DepartmentListing/reg' : '/Masters/DepartmentListing';
                                navigate(path);
                            }}

                            variant="button"
                            fontWeight="regular" disabled={props.btn_disabled ? true : false}>Back</MDButton>
                        <MDButton type="submit" onClick={addDepartment} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            fontWeight="regular">{actionLabel}</MDButton>
                    </div >
                </div>


                {/* Success Msg Popup */}
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />

                {/* Error Msg Popup */}
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

                <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        <div className='row'>
                            <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
                        </div>
                        {displayRecordComponent()}

                    </Modal.Body>
                </Modal >
            </div>
        </>
    )
}

export default DepartmentEntry;
