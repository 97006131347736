
// File imports
import Datatable from 'components/DataTable';

const FrmMSuppliersEntry = ({ data, columns }) => {
  function toggleBankChkBoxes(key) {
    switch (key) {
      case "chkAllSuppliers":
        const selectAll = document.getElementById('chkAllSuppliers').checked
        if (selectAll) {
          const bankChkBoxes = document.querySelectorAll('input[name=checkSupplier]');
          for (var checkbox of bankChkBoxes) {
            checkbox.checked = true;
          }
        } else {
          const bankChkBoxes = document.querySelectorAll('input[name=checkSupplier]:checked');
          for (var checkbox of bankChkBoxes) {
            checkbox.checked = false;
          }
        }
        break;
    }
  }
  return (
    <>
      <div class="row">
        <div class="col pt-sm-1">
          <input type='checkbox' id="chkAllSuppliers" onClick={(e) => toggleBankChkBoxes('chkAllSuppliers')} /> <label class="erp-form-label pb-1"> Select All </label>
        </div>
      </div>
      <div className="erp_table_scroll">
        <Datatable data={data} columns={columns} />
      </div>
    </>
  )
}
export default FrmMSuppliersEntry;