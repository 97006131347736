import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'

let itemsPerPage = 0; // Number of items per page
const SalesOrderServiceCustomerMaterialReceipt = React.forwardRef(({ invoiceContent }, ref) => {
  itemsPerPage = 18;
  const data = invoiceContent.items
  const receiptMaterials = invoiceContent.customerReciptMaterials

  const itemData = extractItemData(invoiceContent.items);
  function extractItemData(items) {
    // Extract relevant data from the first item in the array
    if (items.length > 0) {
      const firstItem = items[0];
      return {
        company: firstItem.company,
        company_branch: firstItem.company_branch,
        address: firstItem.address,
        phone: firstItem.phone,
        cell: firstItem.cell,
        email: firstItem.email,
        website: firstItem.website,
        GSTIN: firstItem.GSTIN,
        pan_no: firstItem.pan_no,
        state_name: firstItem.state_name,
        company_pincode: firstItem.company_pincode,
      };
    }
    // Return default values or handle the case where the array is empty
    return {
      company: 0,
      company_branch: 0,
      address: 0,
      phone: 0,
      cell: 0,
      email: 0,
      website: 0,
      GSTIN: 0,
      pan_no: 0,
      state_name: 0,
      company_pincode: 0,
    };
  }

  const invoiceFooter = (
    <div className="invoice-footer m-0 p-0">
      {/* <div className='row mt-1 m-0 p-0 px-0 border-bottom border-dark'>
        <dt className="col-sm-12 erp-invoice-print-label">
          Certified that the particulars given above are true and correct and the amount indicated represents the price actualy charged and that there is no flow of additional consideration directly or indirectly from the buyer.
        </dt>
      </div> */}

      {/* <div className='row mt-1 m-0 p-0 px-0 border-bottom border-dark'>
        <dt className="col-sm-3 erp-invoice-print-label-md-lg">Expected Days For Repairing:</dt>
        <dd className="col-sm-2 erp-invoice-print-label"> {invoiceContent.salesOrderDetails.expec_repairing_days} Days</dd>
      </div> */}

      <div className='row m-0 p-0'>
        <div className='col-sm-6 border-end border-dark text-center'>
          <div className='text-center'>
            <dt className="erp-invoice-print-label-md-lg mt-2">For {invoiceContent.customerDetails.customer}</dt>
          </div>
          <div className='mt-3'>
            <dt className="erp-invoice-print-label-md-lg mt-5 pt-3">Receiver's Signatory</dt>
          </div>
        </div>

        <div className='col-sm-6 text-center'>
          <div className='text-center'>
            <dt className="erp-invoice-print-label-md-lg mt-2">For {itemData.company}</dt>
          </div>
          <div className='mt-3'>
            <dt className="erp-invoice-print-label-md-lg mt-5 pt-3">Authorised Signatory</dt>
          </div>
        </div>
      </div>
    </div>
  );

  const invoiceHeader = (

    <div className='bordered border-bottom-0 px-0'>
      <div className='row mb-0 p-2'>
        <div className="col-sm-2 text-center text-sm-start">
          <img src={DakshabhiLogo} alt="master card" width="100" height="100" mt={1} />
        </div>
        <div className="col-sm-10 transformed">
          <div className='erp-invoice-print-label-lg text-center'>
            {itemData.company}
          </div>
          <div className="row">
            <div className="text-center">
              <div className='erp-invoice-print-label text-center'>
                <div className='erp-invoice-print-label-md-lg'>
                  <span className='erp-invoice-print-label-md-lg'>{itemData.address} ({itemData.company_branch}) {itemData.state_name}-{itemData.company_pincode} </span>
                </div>
                <div className='erp-invoice-print-label-md-lg'>
                  <span className='erp-invoice-print-label-md-lg'>WebSite: <a href={itemData.website}>{itemData.website}</a> || Email: {itemData.email} </span>
                </div>
                <div className='erp-invoice-print-label-md-lg'>
                  <span className='erp-invoice-print-label-md-lg'> Phone: {itemData.phone} || Cell:{itemData.cell} </span>
                </div>
                <div className='erp-invoice-print-label-md-lg'>
                  <span className='erp-invoice-print-label-md-lg'>GSTN No: {itemData.GSTIN} || PAN No:{itemData.pan_no} </span>
                </div>
                <div className='erp-invoice-print-label-lg'> Customer Material Receipt </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row border-top border-dark p-0 m-0'>
        <div className='col-sm-6'>
          <dl className="erp-invoice-print-label-md-lg">
            <dt>Sales Order No & version: {invoiceContent.salesOrderDetails.sales_order_no} & {invoiceContent.salesOrderDetails.sales_order_version}</dt>
          </dl>
          {invoiceContent.salesOrderDetails.sales_order_creation_type != 'Mannual' ?
            <dl className="erp-invoice-print-label-md-lg">
              <dt>Sales Quotation No: {invoiceContent.salesOrderDetails.sales_order_creation_type === 'Mannual' ? '-' : invoiceContent.salesOrderDetails.sales_quotation_no}</dt>
            </dl>
            : null
          }
        </div>
        <div className='col-sm-3'>
          <dl className="erp-invoice-print-label-md-lg">
            <dt>Sales Order Type: {invoiceContent.salesOrderDetails.sales_order_creation_type}</dt>
          </dl>
          {invoiceContent.salesOrderDetails.sales_order_creation_type != 'Mannual' ?
            <dl className="erp-invoice-print-label-md-lg">
              <dt>Sales Quotation Date: {invoiceContent.salesOrderDetails.sales_order_creation_type === 'Mannual' ? '-' : invoiceContent.salesOrderDetails.sales_quotation_date}</dt>
            </dl>
            : null
          }
        </div>
        <div className='col-sm-3'>
          <dl className="erp-invoice-print-label-md-lg">
            <dt>Sales Order Date: {invoiceContent.salesOrderDetails.sales_order_date}</dt>
          </dl>
        </div>
      </div>
      <div className='row border-bottom border-dark p-0 m-0'>
        <div className='col-sm-6'>
          <dl className="erp-invoice-print-label-md-lg">
            <dt>Customer Order No: {invoiceContent.salesOrderDetails.customer_order_no}</dt>
          </dl>
        </div>
        <div className='col-sm-6'>
          <dl className="erp-invoice-print-label-md-lg">
            <dt>Customer Order Date: {invoiceContent.salesOrderDetails.customer_order_Date}</dt>
          </dl>
        </div>
      </div>

      <div className="row p-0 m-0">
        <div className='col-sm-6 ps-0' id="customerDetailsCol">
          <div className='row p-0 m-0'>
            <dt className='erp-invoice-print-label-md-lg'>Customer Details:</dt>

            <div className='row p-0 m-0'>
              <dt className="col-sm-2 erp-invoice-print-label-md-lg">Name:</dt>
              <dd className="col-sm-10 erp-invoice-print-label"> {invoiceContent.customerDetails.customer}</dd>
            </div>

            <div className='row p-0 m-0'>
              <dt className="col-sm-2 erp-invoice-print-label-md-lg">State:</dt>
              <dd className="col-sm-4 erp-invoice-print-label">{invoiceContent.customerDetails.state}</dd>

              <dt className="col-sm-2 erp-invoice-print-label-md-lg">City:</dt>
              <dd className="col-sm-4 erp-invoice-print-label">{invoiceContent.customerDetails.customer_city_name}</dd>
            </div>
          </div>
        </div>

        <div className='col-sm-6 ps-0' id="companyDetailsCol">
          <div className='row p-0 m-0'>
            <dt className='erp-invoice-print-label-md-lg'>Our Details:</dt>

            <div className='row p-0 m-0'>
              <dt className="col-sm-2 erp-invoice-print-label-md-lg">Branch:</dt>
              <dd className="col-sm-9 erp-invoice-print-label"> {invoiceContent.Ourdetails.company_branch_name}</dd>
            </div>

            <div className='row p-0 m-0'>
              <dt className="col-sm-2 erp-invoice-print-label-md-lg">State:</dt>
              <dd className="col-sm-4 erp-invoice-print-label">{invoiceContent.Ourdetails.state_name}</dd>

              <dt className="col-sm-2 erp-invoice-print-label-md-lg">City:</dt>
              <dd className="col-sm-4 erp-invoice-print-label">{invoiceContent.Ourdetails.city_name}</dd>
            </div>
          </div>
        </div>
      </div>

    </div>
  )

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="container-invoice">
            <div id="content">
              <div className="invoice p-0">

                {/* <!--HEADER--> */}
                <div className="row" style={{ padding: '0px 15px 0px' }}>
                  {invoiceHeader}
                </div>
                {/* <!--DETAILS--> */}
                <div className="row" style={{ padding: '0px 15px 15px' }}>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 px-0" >
                        <div className="row p-0">
                          <div className="table-responsive">
                            <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                              {receiptMaterials.length !== 0 && (
                                <thead>
                                  <tr>
                                    <th className="erp_invoice_table_th text-center">Sr No.</th>
                                    <th className="erp_invoice_table_th text-center col-3">Material Name</th>
                                    <th className="erp_invoice_table_th text-center col-3">Material Description</th>
                                    <th className="erp_invoice_table_th text-center col-3">Material Tech. Spec.</th>
                                    <th className="erp_invoice_table_th text-center col-2">Material Remark</th>
                                    <th className="erp_invoice_table_th text-center">Serial No.</th>
                                    <th className="erp_invoice_table_th text-center">Model No.</th>
                                    <th className="erp_invoice_table_th text-center">Quantity</th>
                                    <th className="erp_invoice_table_th text-center">Expected Repairing Days</th>
                                  </tr>
                                </thead>
                              )}
                              <tbody id="detailstableId">
                                {
                                  receiptMaterials.map((item, index) =>
                                    <>
                                      <tr className={`tblBodyTrHt-${index}`}>
                                        <td className="erp_invoice_table_td">{index + 1}</td>
                                        <td className="erp_invoice_table_td">{item.material_name}</td>
                                        <td className="erp_invoice_table_td">{item.material_desc}</td>
                                        <td className="erp_invoice_table_td">{item.material_tech_spec}</td>
                                        <td className="erp_invoice_table_td">{item.material_remark}</td>
                                        <td className="erp_invoice_table_td">{item.material_serial_no}</td>
                                        <td className="erp_invoice_table_td">{item.material_model_no}</td>
                                        <td className="erp_invoice_table_td text-end">{item.material_quantity}</td>
                                        <td className="erp_invoice_table_td text-end">{item.expected_repairing_days}</td>
                                      </tr>
                                      {
                                        receiptMaterials.length - 1 === index
                                          ? <>
                                            <tr id='footerTr'> </tr>
                                            <tr>
                                              <td className='m-0 p-0' colSpan={12}>{invoiceFooter}</td>
                                            </tr>
                                          </>
                                          : null
                                      }
                                    </>
                                  )
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
})

export default SalesOrderServiceCustomerMaterialReceipt;
