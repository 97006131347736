import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import $ from 'jquery';
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { DatatableWithId } from "components/DataTable";

// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Accordion from "react-bootstrap/Accordion";

// File Imports
import ComboBox from "Features/ComboBox";
import DocumentF from "Features/Document";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import FrmValidations from "FrmGeneric/FrmValidations";

// Import React icons
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import FrmMProductCategory1Entry from "Masters/MProductCategory1/FrmProductCategory1Entry";
import FrmHSNSAC from "Masters/MHSN-SAC/FrmHSN-SAC";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import FrmProductUnitEntry from "Masters/MProductUnit/FrmProductUnitEntry";
import FrmMProductPackingEntry from "Masters/MProductPacking/FrmMProductPackingEntry";
import { MdRefresh } from "react-icons/md";
import ConfigConstants from "assets/Constants/config-constant";
import { CircularProgress } from "@material-ui/core";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmMProductMaterialShapeEntry from "Masters/MProductMaterialShape/FrmMProductMaterialShapeEntry";
import FrmMProductMaterialGradeEntry from "Masters/MProductMaterialGrade/FrmMProductMaterialGradeEntry";
import FrmMProductMaterialTypeEntry from "Masters/MProductMaterialType/FrmMProductMaterialTypeEntry";
import FrmMProductCategory2Entry from "Masters/MProductCategory2/FrmMProductCategory2Entry";
import FrmMProductCategory3Entry from "Masters/MProductCategory3/FrmMProductCategory3Entry";
import FrmMProductCategory4Entry from "Masters/MProductCategory4/FrmMProductCategory4Entry";
import FrmMProductCategory5Entry from "Masters/MProductCategory5/FrmMProductCategory5Entry";
import GenerateMaterialId from "FrmGeneric/GenerateMaterialId/GenerateMaterialId";
import FrmPropertyEntry from "Masters/MProperty/FrmPropertyEntry";
import DeleteModal from "components/Modals/DeleteModal";
import { RxCrossCircled } from "react-icons/rx";


export default function FrmMProductFGEntry({ goToNext, fg_id }) {

  // Call ConfigConstants to get the configuration constants
  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, COMPANY_CATEGORY_COUNT } = configConstants;

  //In listing page we passed in navigate
  const { state } = useLocation();
  const { productFGId, productFGName, keyForViewUpdate, productTypeId, category1Id, compType, productId = 0 } = state || {}

  let categoryCount = "";

  let docGroup = "Finish Goods";

  // For navigate
  const navigate = useNavigate();
  const generateMaterialIdAPiCall = useRef();
  const comboBoxRef = useRef();
  const validationRef = useRef();
  const validatePercentage = useRef();

  // Combo Options for Product Finish Good Entry
  const [productTypeOptions, setProductTypeOptions] = useState([])
  const [productCategory1Options, setProductCategory1Options] = useState([])
  const [hsnSacCodeOptions, setProductHsnSacCodeOptions] = useState([])
  const [unitOptions, setProductUnitOptions] = useState([])
  const [productPackingOptions, setProductPackingOptions] = useState([])
  const [consumtionModeOptions, setProductConsumtionModeOptions] = useState([])
  const [materialOriginOptions, setProductMaterialOriginOptions] = useState([])
  const [countryOptions, setCountryOptions] = useState([])

  //  Form Fields for Product Finish Goods
  const [product_id, setProduct_id] = useState(productId);
  const [product_fg_id, setProductFgId] = useState(productFGId === 0 && fg_id !== 0 ? fg_id : productFGId)
  const [cmb_product_type_id, setproductTypeId] = useState()
  const [cmb_product_category1_id, setProductcategory1Id] = useState(''); // previously '3' set the default cat-1 value.
  const [txt_product_fg_code, setFGCode] = useState('');
  const [txt_product_fg_name, setFGName] = useState(productFGName);
  const [txt_fg_short_name, setFGShortName] = useState('');
  const [txt_fg_print_name, setFGPrintName] = useState('');
  const [txt_fg_tech_spect, setFGTechSpect] = useState('');
  const [txt_fg_oem_part_code, setFGOemPartCode] = useState('');
  const [txt_fg_our_part_code, setFGOurPartCode] = useState('');
  const [txt_fg_item_sr_no, setFGItemSrNo] = useState(1);
  const [txt_fg_drawing_no, setFGDrawingNo] = useState('');
  const [txt_fg_model_no, setFGModelNo] = useState('');
  const [cmb_fg_hsn_sac_code_id, setFGHsnSacCodeId] = useState('');
  const [cmb_fg_purchase_unit_id, setFGPurchaseUnitId] = useState(6);
  const [cmb_fg_sales_unit_id, setFGSalesUnitId] = useState(6);
  const [cmb_fg_stock_unit_id, setFGStockUnitId] = useState(6);
  const [cmb_fg_packing_id, setFGPackingUnitId] = useState(1);
  const [txt_fg_bar_code, setFGBarCode] = useState('');
  const [file_fg_qr_code, setFGQrCode] = useState();
  const [file_fg_qr_codeName, setFGQrCodePath] = useState();
  const [cmb_consumption_mode, setConsumptionMode] = useState('All');
  const [cmb_origin_type, setOriginType] = useState('Indigenous');
  const [cmb_origin_country, setOriginCountry] = useState('India');
  const [txt_remarkForProductEntry, setRemarkForProductEntry] = useState('');
  const [is_activeGen, setIsActiveGen] = useState(true);
  const [is_BOMApplicable, setBOMApplicable] = useState('No');


  const [cmb_godown_id, setgodownid] = useState('');
  const [cmb_godown_section_id, setgodownsectionid] = useState('')
  const [cmb_godown_section_beans_id, setgodownsectionbeansid] = useState('')

  //combo option
  const [godownidOptions, setgodownidOptions] = useState([])
  const [godownidSectionOptions, setgodownidSectionOptions] = useState([])
  const [godownsectionbeansidOptions, setgodownsectionbeansidOptions] = useState([])

  // Option Box
  const [category2Options, setCategory2Options] = useState([])
  const [category3Options, setCategory3Options] = useState([])
  const [category4Options, setCategory4Options] = useState([])
  const [category5Options, setCategory5Options] = useState([])
  const [pMaterialTypeOptions, setPMaterialTypeOptions] = useState([])
  const [pMaterialGradeOptions, setPMaterialGradeOptions] = useState([])
  const [pMaterialShapeOptions, setPMaterialShapeOptions] = useState([])
  const [pMaterialColorOptions, setPMaterialColorOptions] = useState([])
  const [pAlternateMaterialOptions, setPAlternateMaterialOptions] = useState([])

  // Technical Entry Form Fields
  const [product_fg_technical_id, setProductFGTechnicalId] = useState(0)
  const [txt_product_fg_technical_name, setPTechnivalName] = useState('');
  const [cmb_product_category2_id, setPCategory2Id] = useState('');
  const [cmb_product_category3_id, setPCategory3Id] = useState('');
  const [cmb_product_category4_id, setPCategory4Id] = useState('');
  const [cmb_product_category5_id, setPCategory5Id] = useState('');
  const [cmb_product_material_type_id, setPMaterialTypeId] = useState('');
  const [cmb_product_material_grade_id, setPMaterialGradeId] = useState('');
  const [cmb_product_material_shape_id, setPMaterialShapeId] = useState('');
  const [cmb_product_material_colour, setPMaterialColour] = useState('NA');
  const [cmb_product_alternate_fg_id, setPAlternateId] = useState('');
  const [txt_assembly_scrap_percent, setAssemblyScrapPercent] = useState(0);

  //  Form Fields For Commercial Entry
  const [product_fg_commercial_id, setProductFGCommercialId] = useState(0)
  const [txt_product_fg_gross_weight, setPFGGrossWeight] = useState(0);
  const [txt_product_fg_net_weight, setPFGNetWeight] = useState(0);
  const [txt_product_fg_std_weight, setPFGStdWeight] = useState(0);
  const [txt_product_fg_volume, setPFGVolume] = useState(0);
  const [txt_product_fg_mrp, setPFGMrp] = useState(1);
  const [txt_product_fg_landed_price, setPFGLandedPrice] = useState(1);
  const [txt_product_fg_avg_price, setPFGAvgPrice] = useState(1);
  const [txt_product_fg_std_profit_percent, setPFGStdProfitPercent] = useState(0);
  const [txt_product_fg_std_discount_percent, setPFGDiscountPercent] = useState(0);
  const [txt_product_fg_moq, setPFGmoq] = useState(0);
  const [txt_product_fg_mov, setPFGmov] = useState(0);
  const [txt_product_fg_eoq, setPFGeoq] = useState(0);
  const [txt_product_fg_mpq, setPFGmpq] = useState(0);
  const [txt_product_fg_min_stock_level, setPFGMinStockLevel] = useState(0);
  const [txt_product_fg_max_stock_level, setPFGMaxStockLevel] = useState(0);
  const [txt_product_fg_reorder_stock_level, setPFGReorderStockLevel] = useState(0);
  const [txt_product_fg_depriciation_percent, setPFGDepricationPercent] = useState(0);
  const [txt_sales_tolerance_percent, setSalesTolerancePercent] = useState(0);
  const [is_testCertified, setIsTestCertified] = useState(false);
  const [is_qaRequired, setIsQARequired] = useState(false);
  const [is_priceType, setIsPriceType] = useState('Fixed');
  const [is_abcAnalysis, setIsABCanalysis] = useState('C');

  //hooks of update||View data onclick of accordian
  const [supplierMappingData, setSupplierMappingData] = useState([]);
  const [processMappingData, setProcessMappingData] = useState([]);
  const [qaMappingData, setQAMappingData] = useState([]);
  const [customerMappingData, setCustomerMappingData] = useState([]);

  // Data & Columns For Supplier Entry
  const [dataForSuppEntry, setSuppData] = useState([]);
  const [columnsForSuppEntry, setColumns] = useState([]);

  // Data & Columns For Customer Entry
  const [dataForCust, setCustomerData] = useState([]);
  const [columnsForCust, setCustomerColumns] = useState([]);

  // Table Data Process
  const [dataForProcess, setProccessData] = useState([]);
  const [columnsForProcess, setProcessColumns] = useState([]);

  // Data & Cols For QA 
  const [dataQA, setQaMappingData] = useState([]);
  const [columnsQA, setQAColumns] = useState([]);

  const [showAddRecModal, setShowAddRecModal] = useState(false);

  // Error Msg HANDLING
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    const path = compType === 'Register' ? '/Masters/FinishGoodsListing/reg' : '/Masters/FinishGoodsListing';
    navigate(path);
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState('');

  // Document Form
  const [showDocumentForm, setShowDocumentForm] = useState(false);
  const handleCloseDocumentForm = () => setShowDocumentForm(false);
  const viewDocumentForm = () => setShowDocumentForm(true);

  // Show ADD record Modal
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {
      case 'Product Type':
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.columns.push("product_type_short_name");
        globalQuery.table = "smv_product_type"
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "product_type_group", operator: "=", value: "FG" });
        comboBoxRef.current.removeCatcheFillCombo(globalQuery).then((product_Types) => {
          setProductTypeOptions(product_Types)
        })

        break;
      case 'Product Category1':
        comboOnChange('productType')
        break;

      case 'HSN-SAC':
        comboBoxRef.current.fillMasterData("cmv_hsn_sac", "", "").then((HsnSacCodeApiCall) => {
          setProductHsnSacCodeOptions(HsnSacCodeApiCall)
        })
        break;

      case 'Puchase Unit':
        comboBoxRef.current.fillMasterData("smv_product_unit", "", "").then((PuchaseUnitApiCall) => {
          setProductUnitOptions(PuchaseUnitApiCall)
        })
        break;

      case 'Sales Unit':
        comboBoxRef.current.fillMasterData("smv_product_unit", "", "").then((SalesUnitApiCall) => {
          setProductUnitOptions(SalesUnitApiCall)
        })
        break;

      case 'Stock Unit':
        comboBoxRef.current.fillMasterData("smv_product_unit", "", "").then((StockUnitApiCall) => {
          setProductUnitOptions(StockUnitApiCall)
        })
        break;

      case 'Packing':
        comboBoxRef.current.fillMasterData("smv_product_packing", "", "").then((productPackingApiCall) => {
          setProductPackingOptions(productPackingApiCall)
        })
        break;

      case 'Material Type':
        // Product Material Type
        comboBoxRef.current.fillMasterData("smv_product_material_type", "product_type_id", productTypeId).then((productMaterialList) => {
          setPMaterialTypeOptions(productMaterialList)
        })
        break;

      case 'Material Grade':
        comboOnChange('productMaterialType')
        break;

      case 'Material Shape':
        // Product Material Shape
        comboBoxRef.current.fillMasterData("smv_product_material_shape", "product_type_id", productTypeId).then((productMaterialShapeList) => {
          setPMaterialShapeOptions(productMaterialShapeList)
        })

        break;
      case 'Product Category-2':
        comboOnChange('productCategory1')
        break;
      case 'Product Category-3':
        comboOnChange('productCategory2')
        break;
      case 'Product Category-4':
        comboOnChange('productCategory3')
        break;
      case 'Product Category-5':
        comboOnChange('productCategory4')
        break;
      case 'Consumption Mode':
        comboOnChange('consumptionMode')
        break;

      default:
        break;
    }
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)
  }


  const [modalHeaderName, setHeaderName] = useState('')
  // Loader
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    setIsLoading(true);
    await fillComobos();
    if (product_fg_id !== 0 && product_fg_id !== undefined) {
      await FnCheckUpdateResponce();
    }
    setIsLoading(false);
  }, [])

  const excludedcustFields = [
    '_id', 'field_name', 'is_', '_on', '_by', 'company_', 'customer_type', 'customer_code', 'customer_short_name', 'nature_of_business',
    'customer_gl_codes', 'customer_history', 'cust_branch_short_name', 'cust_branch_address1', 'cust_branch_address2', 'cust_branch_pincode', 'branch_type', 'cust_branch_region',
    'cust_branch_linkedin_profile', 'cust_branch_twitter_profile', 'cust_branch_facebook_profile', 'cust_branch_gst_division', 'cust_branch_gst_range', 'cust_branch_pan_no',
    'cust_branch_udyog_adhar_no', 'cust_branch_vat_no', 'cust_branch_service_tax_no', 'cust_branch_excise_no', 'cust_branch_cst_no', 'cust_branch_bst_no', 'cust_branch_rating',
    'cust_branch_gl_codes', 'customer_username', 'customer_password', 'remark', 'active', 'deleted'
  ];

  const fillComobos = async () => {
    try {

      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.columns.push("product_type_short_name");
      globalQuery.table = "smv_product_type"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "product_type_group", operator: "=", value: "FG" });
      comboBoxRef.current.fillFiltersCombo(globalQuery).then(async (productTypes) => {
        setProductTypeOptions(productTypes);
        // For New Entry set the default product-type.
        if (product_fg_id === 0) {
            setproductTypeId(3);
            const defaultProductType = productTypes.find(productType => productType.field_id === 3);
            if(defaultProductType){
              await FnGenerateMaterialId(defaultProductType.field_id.toString(), defaultProductType.product_type_short_name);
              const productCategory1ApiCall = await comboBoxRef.current.fillMasterData("smv_product_category1", "product_type_id", defaultProductType.field_id)
              setProductCategory1Options(productCategory1ApiCall)
            }
          }
      })

      // const HsnSacCodeApiCall = await comboBoxRef.current.fillMasterData("cmv_hsn_sac", "", "")
      // setProductHsnSacCodeOptions(HsnSacCodeApiCall)

      // changes by tushar
      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "cmv_hsn_sac"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
      globalQuery.conditions.push({ field: "hsn_sac_type", operator: "=", value: "HSN" });
      comboBoxRef.current.fillFiltersCombo(globalQuery).then((HsnSacCodeApiCall) => {
        setProductHsnSacCodeOptions(HsnSacCodeApiCall)
      })

      comboBoxRef.current.fillMasterData("smv_product_unit", "", "").then((productUnitApiCall) => {
        setProductUnitOptions(productUnitApiCall)
      })

      comboBoxRef.current.fillMasterData("smv_product_packing", "", "").then((productPackingApiCall) => {
        setProductPackingOptions(productPackingApiCall)
      })

      comboBoxRef.current.fillComboBox("MaterialConsumptionMode").then((consumtionModeApiCall) => {
        setProductConsumtionModeOptions(consumtionModeApiCall)
      })

      const originApiCall = await comboBoxRef.current.fillComboBox("MaterialOrigin")
      setProductMaterialOriginOptions(originApiCall);

      comboBoxRef.current.fillMasterData("cmv_country", "", "").then((countryApiCall) => {
        setCountryOptions(countryApiCall)
      })

      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.table = "cmv_godown"
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      comboBoxRef.current.fillFiltersCombo(globalQuery).then((godownidApiCall) => {
        setgodownidOptions(godownidApiCall)
      })

      if (keyForViewUpdate === "") {
        setgodownid(1);
        await comboOnChange('godown')
        await comboOnChange('godownsection')
      }

      //Fillcombos for Tech. Entry starts here!!
      // get category count from sesssion
      // categoryCount = sessionStorage.getItem('compRMCatCount')

      for (let count = 2; count <= COMPANY_CATEGORY_COUNT; count++) {
        $('#product_category' + count + '_id').show()
      }

      // Category label display
      resetGlobalQuery();
      globalQuery.columns.push("property_value");
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'FGCategorySettings' });
      globalQuery.orderBy = ["property_id"];
      globalQuery.table = "amv_properties"
      var labelList = await comboBoxRef.current.fillFiltersCombo(globalQuery)

      // Display category labels
      if (labelList.length !== 0) {
        for (let count = 1; count < COMPANY_CATEGORY_COUNT; count++) {
          $('#cat' + count + 'Label').append(labelList[count].property_value)
        }
      } else {
        for (let count = 1; count <= COMPANY_CATEGORY_COUNT; count++) {
          $('#cat' + count + 'Label').append('FG Category ' + [count] + ' :')
        }
      }


      // Category 2 list
      debugger
      resetGlobalQuery();
      globalQuery.columns.push("field_id");
      globalQuery.columns.push("field_name");
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTypeId });
      globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: category1Id });
      globalQuery.table = "smv_product_category2"

      comboBoxRef.current.fillFiltersCombo(globalQuery).then((masterList2) => {
        setCategory2Options(masterList2)
      })

      // Alternate FG ids
      resetGlobalQuery();
      globalQuery.columns.push("product_fg_name");
      globalQuery.columns.push("product_fg_id");
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: productTypeId });
      globalQuery.conditions.push({ field: "product_fg_category1_id", operator: "=", value: category1Id });
      globalQuery.table = "smv_product_fg_details"

      comboBoxRef.current.fillFiltersCombo(globalQuery).then((pAltrRMIds) => {
        setPAlternateMaterialOptions(pAltrRMIds)
      })

      // Product Material Color 
      comboBoxRef.current.fillComboBox("Colour").then((productMaterialColorList) => {
        setPMaterialColorOptions(productMaterialColorList)
      })

      if (productTypeId !== "" && productTypeId !== "0" && product_fg_id !== undefined) {
        await FnGetProductTypeDependentCombo(productTypeId);
      }
    } catch (error) {
      console.log('error: ', error)
      navigate('/Error')
    }
  }

  const FnGenerateMaterialId = async (productTpVal, ptShortName) => {
    const autoNoApiCall = await generateMaterialIdAPiCall.current.GenerateCode("sm_product_fg", "product_fg_id", 'product_type_id', productTpVal, ptShortName, "4");
    setProductFgId(autoNoApiCall);
    return autoNoApiCall;
  }

  const FnGenerateTechSpecName = async () => {

    let productTypeShortName = document.getElementById("cmb_product_type_id").options[document.getElementById("cmb_product_type_id").selectedIndex].getAttribute('ptShortName');
    let productTpText = productTypeShortName !== null ? productTypeShortName : "";
    let productCat1Text = document.getElementById("cmb_product_category1_id").options[document.getElementById("cmb_product_category1_id").selectedIndex].text;
    let productCat2Text = document.getElementById("cmb_product_category2_id").options[document.getElementById("cmb_product_category2_id").selectedIndex].text;
    let productCat3Text = document.getElementById("cmb_product_category3_id").options[document.getElementById("cmb_product_category3_id").selectedIndex].text;
    let productCat4Text = document.getElementById("cmb_product_category4_id").options[document.getElementById("cmb_product_category4_id").selectedIndex].text;
    let productCat5Text = document.getElementById("cmb_product_category5_id").options[document.getElementById("cmb_product_category5_id").selectedIndex].text;
    let productMakeText = "";
    let productMaterialGradeText = document.getElementById("cmb_product_material_grade_id").options[document.getElementById("cmb_product_material_grade_id").selectedIndex].text;

    const autoTextApiCall = await generateMaterialIdAPiCall.current.GenerateTechnicalSpecName(productTpText, productCat1Text, productCat2Text, productCat3Text,
      productCat4Text, productCat5Text, productMakeText, productMaterialGradeText);
    setPTechnivalName(autoTextApiCall);
  }

  //Fn for dependent on product Type
  const FnGetProductTypeDependentCombo = async (productTypeId) => {
    // Product Material Type
    var productMaterialList = await comboBoxRef.current.fillMasterData("smv_product_material_type", "product_type_id", productTypeId)
    setPMaterialTypeOptions(productMaterialList)

    // Product Material Shape
    var productMaterialShapeList = await comboBoxRef.current.fillMasterData("smv_product_material_shape", "product_type_id", productTypeId)
    setPMaterialShapeOptions(productMaterialShapeList)
  }

  const FnCheckUpdateResponce = async () => {
    try {
      //Update Response for Entry data
      const apiCallForEntryData = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductFg/FnShowAllProductFgDetailsAndMasterRecords/${product_fg_id}/${COMPANY_ID}`)
      const updateRes = await apiCallForEntryData.json();

      let generalEntryData = updateRes.ProductFgRecords;
      let technicalEntryData = updateRes.TechnicalRecords;
      let commercialEntryData = updateRes.CommercialRecords;
      let supplierMappingData = updateRes.SupplierRecords;
      let customerMappingData = updateRes.CustomerRecords;
      let processMappingData = updateRes.ProcessRecords;
      let qaMappingData = updateRes.QaMappingRecords;

      setSupplierMappingData(supplierMappingData)
      setCustomerMappingData(customerMappingData)
      setProcessMappingData(processMappingData)
      setQAMappingData(qaMappingData)

      //Filling General Entry
      setproductTypeId(generalEntryData.product_type_id)
      await comboOnChange('productType')
      setProductcategory1Id(generalEntryData.product_category1_id)
      setProduct_id(generalEntryData.product_id)
      setProductFgId(generalEntryData.product_fg_id)
      setFGCode(generalEntryData.product_fg_code)
      setFGName(generalEntryData.product_fg_name)
      setFGShortName(generalEntryData.product_fg_short_name)
      setFGPrintName(generalEntryData.product_fg_print_name)
      setFGTechSpect(generalEntryData.product_fg_tech_spect)
      setFGOemPartCode(generalEntryData.product_fg_oem_part_code)
      setFGOurPartCode(generalEntryData.product_fg_our_part_code)
      setFGItemSrNo(generalEntryData.product_fg_item_sr_no)
      setFGDrawingNo(generalEntryData.product_fg_drawing_no)
      setFGModelNo(generalEntryData.product_fg_model_no)
      setFGHsnSacCodeId(generalEntryData.product_fg_hsn_sac_code_id)
      setFGPurchaseUnitId(generalEntryData.product_fg_purchase_unit_id)
      setFGSalesUnitId(generalEntryData.product_fg_sales_unit_id)
      setFGStockUnitId(generalEntryData.product_fg_stock_unit_id)
      setFGPackingUnitId(generalEntryData.product_fg_packing_id)
      setFGBarCode(generalEntryData.product_fg_bar_code)
      setFGQrCodePath(generalEntryData.product_fg_qr_code)
      setConsumptionMode(generalEntryData.product_consumption_mode)
      setgodownid(generalEntryData.godown_id)
      await comboOnChange('godown')
      setgodownsectionid(generalEntryData.godown_section_id)
      await comboOnChange('godownsection')
      setgodownsectionbeansid(generalEntryData.godown_section_beans_id)
      setOriginType(generalEntryData.product_origin_type)
      setOriginCountry(generalEntryData.product_origin_country)
      setRemarkForProductEntry(generalEntryData.remark)
      setIsActiveGen(generalEntryData.is_active)
      setBOMApplicable(generalEntryData.bom_applicable)

      //Filling Technical Entry

      setProductFGTechnicalId(technicalEntryData.product_fg_technical_id)
      setPTechnivalName(technicalEntryData.product_fg_technical_name)
      setProductcategory1Id(technicalEntryData.product_category1_id)
      if (technicalEntryData.product_category1_id !== 0) {
        await comboOnChange('productCategory1')
      }
      setPCategory2Id(technicalEntryData.product_category2_id)
      if (technicalEntryData.product_category2_id !== 0) {
        await comboOnChange('productCategory2')
      }

      setPCategory3Id(technicalEntryData.product_category3_id)
      if (technicalEntryData.product_category3_id !== 0) {
        await comboOnChange('productCategory3')
      }
      setPCategory4Id(technicalEntryData.product_category4_id)
      if (technicalEntryData.product_category4_id !== 0) {
        await comboOnChange('productCategory4')
      }
      setPCategory5Id(technicalEntryData.product_category5_id)
      setPMaterialTypeId(technicalEntryData.product_material_type_id)
      if (technicalEntryData.product_material_type_id !== 0) {
        await comboOnChange('productMaterialType')
      }
      setPMaterialGradeId(technicalEntryData.product_material_grade_id)
      setPMaterialShapeId(technicalEntryData.product_material_shape_id)
      setPMaterialColour(technicalEntryData.product_material_colour)
      setPAlternateId(technicalEntryData.product_alternate_fg_id)
      setAssemblyScrapPercent(technicalEntryData.assembly_scrap_percent)

      //Filling Commercial Entry
      setProductFGCommercialId(commercialEntryData.product_fg_commercial_id)
      setPFGGrossWeight(commercialEntryData.product_fg_gross_weight)
      setPFGNetWeight(commercialEntryData.product_fg_net_weight)
      setPFGStdWeight(commercialEntryData.product_fg_std_weight)
      setPFGVolume(commercialEntryData.product_fg_volume)
      setPFGMrp(commercialEntryData.product_fg_mrp)
      setPFGLandedPrice(commercialEntryData.product_fg_landed_price)
      setPFGAvgPrice(commercialEntryData.product_fg_avg_price)
      setPFGStdProfitPercent(commercialEntryData.product_fg_std_profit_percent)
      setPFGDiscountPercent(commercialEntryData.product_fg_std_discount_percent)
      setPFGmoq(commercialEntryData.product_fg_moq)
      setPFGmov(commercialEntryData.product_fg_mov)
      setPFGeoq(commercialEntryData.product_fg_eoq)
      setPFGmpq(commercialEntryData.product_fg_mpq)
      setPFGMinStockLevel(commercialEntryData.product_fg_min_stock_level)
      setPFGMaxStockLevel(commercialEntryData.product_fg_max_stock_level)
      setPFGReorderStockLevel(commercialEntryData.product_fg_reorder_stock_level)
      setPFGDepricationPercent(commercialEntryData.product_fg_depriciation_percent)
      setSalesTolerancePercent(commercialEntryData.sales_tolerance_percent)
      setIsPriceType(commercialEntryData.product_fg_price_type)
      setIsABCanalysis(commercialEntryData.product_fg_abc_analysis)
      setIsQARequired(commercialEntryData.qa_required)
      setIsTestCertified(commercialEntryData.test_certificate_required)

      switch (keyForViewUpdate) {
        case 'update':
          $('#txt_rm_name').attr('readOnly', true)
          $('#txt_product_rm_technical_name').attr('readOnly', true)
          $('#cmb_product_type_id').attr('disabled', true)
          $('#txt_rm_short_name').attr('readOnly', true)
          // $('#txt_rm_short_name').attr('readOnly', true)
          // $('#txt_rm_short_name').attr('readOnly', true)
          $('#cmb_product_category1_id').attr('disabled', true)
          $('#cmb_product_category2_id').attr('readOnly', true)
          $('#cmb_product_category3_id').attr('readOnly', true)
          $('#cmb_product_category4_id').attr('readOnly', true)
          $('#cmb_product_category5_id').attr('readOnly', true)
          $('#btn_save').text("Update");
          break;

        case 'view':
          $("input[type=radio]").attr('disabled', true);
          $('input:checkbox[name=checkSupplier]').attr('disabled', true);
          $('input:checkbox[name=checkCustomer]').attr('disabled', true);
          $('input:checkbox[name=checkQaMapp]').attr('disabled', true);
          $('input:checkbox[name=checkProcess]').attr('disabled', true);
          await validationRef.current.readOnly('productFgFormId');
          await validationRef.current.readOnly('technicalEntryFormId');
          await validationRef.current.readOnly('commercialEntryFormId');
          $('#btn_save').hide();
          break;

        case 'delete':
          $("input[type=radio]").attr('disabled', true);
          $('input:checkbox[name=checkSupplier]').attr('disabled', true);
          $('input:checkbox[name=checkCustomer]').attr('disabled', true);
          $('input:checkbox[name=checkQaMapp]').attr('disabled', true);
          $('input:checkbox[name=checkProcess]').attr('disabled', true);
          await validationRef.current.readOnly('productFgFormId');
          await validationRef.current.readOnly('technicalEntryFormId');
          await validationRef.current.readOnly('commercialEntryFormId');
          $('#btn_save').hide();
          $('#upload_btn_id').hide();
          break;
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  const FnGetMappingsData = async (updatAllRecordsKey) => {
    try {
      const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductFg/FnShowAllRecords/${COMPANY_ID}/${updatAllRecordsKey}`)
      const response = await apiCall.json();
      let supplierRecords = response.SupplierAllRecords;
      let customerRecords = response.CustomerRecords;
      let processRecords = response.ProcessRecords;
      let qaParametersRecords = response.QaParametersAllRecords;
      if (supplierRecords !== undefined) {

        //Supplier Records
        if (supplierRecords.length > 0) {
          var suppColumn = [];
          var suppColumnHeads = Object.keys(supplierRecords[0]);
          console.log("column heads: ", suppColumnHeads)
          for (let colKey = 0; colKey < suppColumnHeads.length; colKey++) {
            if (colKey == 0) {
              suppColumn.push({
                Headers: "Action",
                accessor: "Action",
                Cell: row => (
                  <div style={{ display: "flex" }}>
                    <input type='checkbox' name="checkSupplier" id={'checkSupplierId_' + row.row.original.supplier_id} className="checkSupplier"
                      value={row.row.original.supplier_id} onClick={() => toggleQuotationTermsChkBoxes('PartiallySupplierSelection')}>
                    </input>
                  </div>
                ),
              });
            }
            if (!suppColumnHeads[colKey].includes('_id') && !suppColumnHeads[colKey].includes('field_name') && !suppColumnHeads[colKey].includes('is_') && !suppColumnHeads[colKey].includes('_on')
              && !suppColumnHeads[colKey].includes('_by') && !suppColumnHeads[colKey].includes('company_') && !suppColumnHeads[colKey].includes('supplier_type')
              && !suppColumnHeads[colKey].includes('supplier_short_name') && !suppColumnHeads[colKey].includes('supplier_sector') && !suppColumnHeads[colKey].includes('nature_of_business')
              && !suppColumnHeads[colKey].includes('supplier_gl_codes') && !suppColumnHeads[colKey].includes('supplier_history') && !suppColumnHeads[colKey].includes('supp_branch_short_name')
              && !suppColumnHeads[colKey].includes('supp_branch_address1') && !suppColumnHeads[colKey].includes('supp_branch_address2') && !suppColumnHeads[colKey].includes('supp_branch_pincode')
              && !suppColumnHeads[colKey].includes('district_name') && !suppColumnHeads[colKey].includes('country_name') && !suppColumnHeads[colKey].includes('supp_branch_region')
              && !suppColumnHeads[colKey].includes('supp_branch_linkedin_profile') && !suppColumnHeads[colKey].includes('supp_branch_twitter_profile') && !suppColumnHeads[colKey].includes('supp_branch_facebook_profile')
              && !suppColumnHeads[colKey].includes('supp_branch_gst_division') && !suppColumnHeads[colKey].includes('supp_branch_gst_range') && !suppColumnHeads[colKey].includes('supp_branch_pan_no')
              && !suppColumnHeads[colKey].includes('supp_branch_udyog_adhar_no') && !suppColumnHeads[colKey].includes('supp_branch_vat_no') && !suppColumnHeads[colKey].includes('supp_branch_service_tax_no')
              && !suppColumnHeads[colKey].includes('supp_branch_excise_no') && !suppColumnHeads[colKey].includes('supp_branch_cst_no') && !suppColumnHeads[colKey].includes('supp_branch_bst_no')
              && !suppColumnHeads[colKey].includes('supp_branch_rating') && !suppColumnHeads[colKey].includes('supp_branch_gl_codes') && !suppColumnHeads[colKey].includes('sez_name')
              && !suppColumnHeads[colKey].includes('supplier_username') && !suppColumnHeads[colKey].includes('supplier_password') && !suppColumnHeads[colKey].includes('remark') && !suppColumnHeads[colKey].includes('active')
              && !suppColumnHeads[colKey].includes('deleted')) {
              suppColumn.push({ Headers: suppColumnHeads[colKey], accessor: suppColumnHeads[colKey] });
            }
          }
          setColumns(suppColumn)
          setSuppData(supplierRecords)
        }
      }
      if (customerRecords !== undefined) {

        //Customer Records
        if (customerRecords.length > 0) {
          var custColumn = [];
          let custColumnHeads = Object.keys(customerRecords[0]);
          for (let colKey = 0; colKey < custColumnHeads.length; colKey++) {
            if (colKey == 0) {
              custColumn.push({
                Headers: "Action",
                accessor: "Action",
                Cell: row => (
                  <div style={{ display: "flex" }}>
                    <input type='checkbox' name="checkCustomer" id={'checkCustomerId_' + row.row.original.customer_id} className="checkCustomer"
                      value={row.row.original.customer_id} onClick={() => toggleQuotationTermsChkBoxes('PartiallyCustomerSelection')}>
                    </input>
                  </div>
                ),
              });
            }
            if (!excludedcustFields.some(field => custColumnHeads[colKey].includes(field))) {
              custColumn.push({ Headers: custColumnHeads[colKey], accessor: custColumnHeads[colKey] });
            }
          }
          setCustomerColumns(custColumn)
          setCustomerData(customerRecords)
        }
      }
      if (processRecords !== undefined) {


        //Process Records
        if (processRecords.length > 0) {
          var proccColumn = [];
          let proccColumnHeads = Object.keys(processRecords[0]);
          for (let colKey = 0; colKey < proccColumnHeads.length; colKey++) {
            if (colKey == 0) {
              proccColumn.push({
                Headers: "Action",
                accessor: "Action",
                Cell: row => (
                  <div style={{ display: "flex" }}>
                    <input type='checkbox' name="checkProcess" id={'checkProcessId_' + row.row.original.product_process_id} className="checkProcess"
                      value={row.row.original.product_process_id} onClick={() => toggleQuotationTermsChkBoxes('PartiallyProcessSelection')}>
                    </input>
                  </div>
                ),
              });
            }
            if (!proccColumnHeads[colKey].includes('_id') && !proccColumnHeads[colKey].includes('field_name') && !proccColumnHeads[colKey].includes('is_') && !proccColumnHeads[colKey].includes('_on') && !proccColumnHeads[colKey].includes('_by') && !proccColumnHeads[colKey].includes('company_') && !proccColumnHeads[colKey].includes('deleted') && !proccColumnHeads[colKey].includes('active') && !proccColumnHeads[colKey].includes('remark') && !proccColumnHeads[colKey].includes('product_process_std_production_hrs') && !proccColumnHeads[colKey].includes('product_process_std_scrap_percent')) {
              proccColumn.push({ Headers: proccColumnHeads[colKey], accessor: proccColumnHeads[colKey] });
            }
          }
          setProcessColumns(proccColumn)
          setProccessData(processRecords)
        }
      }
      if (qaParametersRecords !== undefined) {
        //QA Data
        if (qaParametersRecords.length > 0) {
          var qaMappColumn = [];
          let qaMappColumnHeads = Object.keys(qaParametersRecords[0]);

          for (let colKey = 0; colKey < qaMappColumnHeads.length; colKey++) {
            if (colKey == 0) {
              qaMappColumn.push({
                Headers: "Action",
                accessor: "Action",
                Cell: row => (
                  <div style={{ display: "flex" }}>
                    <input type='checkbox' name="checkQaMapp" id={'checkQaMappId_' + row.row.original.product_qa_parameters_id} className="checkQaMapp"
                      value={row.row.original.product_qa_parameters_id} onClick={() => toggleQuotationTermsChkBoxes('PartiallyQAmappingSelection')}>
                    </input>
                  </div>
                ),
              });
            }
            if (!qaMappColumnHeads[colKey].includes('_id') && !qaMappColumnHeads[colKey].includes('field_name') && !qaMappColumnHeads[colKey].includes('is_') && !qaMappColumnHeads[colKey].includes('_on') && !qaMappColumnHeads[colKey].includes('_by') && !qaMappColumnHeads[colKey].includes('company_') && !qaMappColumnHeads[colKey].includes('deleted') && !qaMappColumnHeads[colKey].includes('active') && !qaMappColumnHeads[colKey].includes('remark')) {
              qaMappColumn.push({ Headers: qaMappColumnHeads[colKey], accessor: qaMappColumnHeads[colKey] });
            }
          }
          setQAColumns(qaMappColumn)
          setQaMappingData(qaParametersRecords)
        }
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  //Supplier Records
  const handleSupplierCheckbox = (supp_id) => {
    $('input:checkbox[name="checkSupplier"][value="' + supp_id + '"]').attr('checked', false);

  }

  //Customer Records
  const handleCustomerCheckbox = (supp_id) => {
    $('input:checkbox[name="checkCustomer"][value="' + supp_id + '"]').attr('checked', false);
  }

  //Process Records
  const handleProcessCheckbox = (procc_id) => {
    $('input:checkbox[name="checkProcess"][value="' + procc_id + '"]').attr('checked', false);

  }

  //QA Records
  const handleqaMappingCheckbox = (qaMapp_id) => {
    $('input:checkbox[name="checkQaMapp"][value="' + qaMapp_id + '"]').attr('checked', false);
  }

  const comboOnChange = async (key) => {
    switch (key) {
      case 'productType':
        let productTpVal = document.getElementById('cmb_product_type_id').value;
        setproductTypeId(productTpVal)
        setCategory2Options([]);
        setCategory3Options([]);
        setCategory4Options([]);
        setCategory5Options([]);
        setProductFgId("")
        setProductcategory1Id('')
        setPCategory2Id('')
        setPCategory3Id('')
        setPCategory4Id('')
        setPCategory5Id('')

        if (productTpVal === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Product Type')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css({
              "padding-top": "0px"
            });
          }, 200)
        } else {
          if (productTpVal !== "") {
            $('#error_cmb_product_type_id').hide();
            const shortName = document.getElementById("cmb_product_type_id").options[document.getElementById("cmb_product_type_id").selectedIndex].getAttribute('ptShortName')
            localStorage.setItem('ptShortName', shortName)

            await FnGenerateMaterialId(productTpVal, shortName);
            await FnGetProductTypeDependentCombo(productTpVal);
            const productCategory1ApiCall = await comboBoxRef.current.fillMasterData("smv_product_category1", "product_type_id", productTpVal)
            setProductCategory1Options(productCategory1ApiCall)
          }
        }
        setTimeout(async () => {
          await FnGenerateTechSpecName();
        }, 200);
        break;

      case 'productCategory1':
        var productCat1Val = document.getElementById('cmb_product_category1_id').value;
        setCategory2Options([]);
        setCategory3Options([]);
        setCategory4Options([]);
        setCategory5Options([]);
        if (productCat1Val === '0') {
          setProductcategory1Id(productCat1Val)
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Product Category1')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "0px" });
          }, 200)
        } else {
          setProductcategory1Id(productCat1Val)
          if (productCat1Val !== "") {
            $('#error_cmb_product_category1_id').hide();
            const productCategory2ApiCall = await comboBoxRef.current.fillMasterData("smv_product_category2", "product_category1_id", productCat1Val)
            setCategory2Options(productCategory2ApiCall)
            setPCategory2Id('3')
          } else {
            setPCategory2Id('')
            setPCategory3Id('')
            setPCategory4Id('')
            setPCategory5Id('')
          }
        }
        setTimeout(async () => {
          await FnGenerateTechSpecName();
        }, 200);
        break;

      case 'hsnSacCode':
        var productHsnSacCode = document.getElementById('cmb_fg_hsn_sac_code_id').value;
        if (productHsnSacCode === '0') {
          setFGHsnSacCodeId(productHsnSacCode)
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('HSN-SAC')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "0px" });
          }, 200)
        } else {
          setFGHsnSacCodeId(productHsnSacCode)
          if (productCat1Val !== "") { $('#error_cmb_fg_hsn_sac_code_id').hide(); }
        }
        break;

      case 'puchaseUnit':
        var productPurchaseUnitId = document.getElementById('cmb_fg_purchase_unit_id').value;
        setFGPurchaseUnitId(productPurchaseUnitId)
        if (productPurchaseUnitId !== "") { $('#error_cmb_fg_purchase_unit_id').hide(); }

        if (productPurchaseUnitId === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Puchase Unit')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "0px" });
          }, 200)
        }
        break;

      case 'salesUnit':
        var productSalesUnitId = document.getElementById('cmb_fg_sales_unit_id').value;
        setFGSalesUnitId(productSalesUnitId)
        if (productSalesUnitId !== "") { $('#error_cmb_fg_sales_unit_id').hide(); }

        if (productSalesUnitId === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Sales Unit')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "0px" });
          }, 200)
        }
        break;

      case 'stockUnit':
        var productStockUnitId = document.getElementById('cmb_fg_stock_unit_id').value;
        setFGStockUnitId(productStockUnitId)
        if (productStockUnitId !== "") { $('#error_cmb_fg_stock_unit_id').hide(); }

        if (productStockUnitId === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Stock Unit')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "0px" });
          }, 200)
        }
        break;

      case 'packingId':
        var productPackingId = document.getElementById('cmb_fg_packing_id').value;
        setFGPackingUnitId(productPackingId)
        if (productPackingId !== "") { $('#error_cmb_3fg_packing_id').hide(); }
        if (productPackingId === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Packing')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css("padding-top", "0px");
          }, 200)
        }
        break;

      case 'consumptionMode':
        var productConsumptionModeId = document.getElementById('cmb_consumption_mode').value;
        setConsumptionMode(productConsumptionModeId)
        if (productConsumptionModeId === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Consumption Mode')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css("padding-top", "0px");
          }, 200)
        }
        if (productConsumptionModeId !== "") { $('#error_cmb_consumption_mode').hide(); }
        break;

      case 'materialOrigin':
        var productOriginTypeId = document.getElementById('cmb_origin_type').value;
        setOriginType(productOriginTypeId)
        if (productOriginTypeId !== "") { $('#error_cmb_origin_type').hide(); }
        break;

      case 'originCountry':
        var countryId = document.getElementById('cmb_origin_country').value;
        setOriginCountry(countryId)
        if (countryId !== "") { $('#error_cmb_origin_country').hide(); }
        break;
      case 'godown':
        var godownval = document.getElementById('cmb_godown_id').value;
        setgodownid(godownval)

        if (godownval === '0') {
          const newTab = window.open('/Masters/Godown', '_blank');
          if (newTab) {
            newTab.focus();
          }
        }
        const godownidSectionApiCall = await comboBoxRef.current.fillMasterData("cmv_godown_section", "godown_id", godownval)
        setgodownsectionid('');
        setgodownidSectionOptions(godownidSectionApiCall)
        setgodownsectionbeansidOptions([])
        setgodownsectionbeansid('')
        //changes by tushar
        if (keyForViewUpdate === "") {
          if (godownval === "1") {
            setgodownsectionid(1);
          }
        }
        break;


      case 'godownsection':
        var godownsectionval = document.getElementById('cmb_godown_section_id').value;
        setgodownsectionid(godownsectionval)

        if (godownsectionval === '0') {
          const newTab = window.open('/Masters/Godown', '_blank');
          if (newTab) {
            newTab.focus();
          }
        }
        const godownidSectionbeansApiCall = await comboBoxRef.current.fillMasterData("cmv_godown_section_beans", "godown_section_id", godownsectionval)
        setgodownsectionbeansidOptions(godownidSectionbeansApiCall)
        if (keyForViewUpdate === "") {
          if (godownsectionval !== 1) {
            setgodownsectionbeansid(1)
          }
        }

        break;

      case 'godownsectionbeans':
        var godownsectionbeansval = document.getElementById('cmb_godown_section_beans_id').value;
        setgodownsectionbeansid(godownsectionbeansval)

        if (godownsectionbeansval === '0') {
          const newTab = window.open('/Masters/Godown', '_blank');
          if (newTab) {
            newTab.focus();
          }
        }
        break;
      case 'productCategory2':
        var productCat2Val = document.getElementById('cmb_product_category2_id').value;
        setPCategory2Id(productCat2Val)
        setCategory3Options([]);
        setCategory4Options([]);
        setCategory5Options([]);
        setPCategory3Id('')
        setPCategory4Id('')
        setPCategory5Id('')
        if (productCat2Val === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Product Category-2')
          setShowAddRecModal(true)
          setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
        } else {
          if (productCat2Val !== "") {
            // Product category 3 list
            const productCat3ApiCall = await comboBoxRef.current.fillMasterData("smv_product_category3", "product_category2_id", productCat2Val)
            setCategory3Options(productCat3ApiCall)
            setPCategory3Id('3')

            // Product altr material list
            resetGlobalQuery();
            globalQuery.columns.push("product_fg_id");
            globalQuery.columns.push("product_fg_name");
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: document.getElementById('cmb_product_type_id').value });
            globalQuery.conditions.push({ field: "product_fg_category1_id", operator: "=", value: document.getElementById('cmb_product_category1_id').value });
            globalQuery.conditions.push({ field: "product_fg_category2_id", operator: "=", value: productCat2Val });
            globalQuery.table = "smv_product_fg_details";

            var pAltrRMIds = await comboBoxRef.current.fillFiltersCombo(globalQuery)
            setPAlternateMaterialOptions(pAltrRMIds)
          }
        }
        setTimeout(async () => {
          await FnGenerateTechSpecName();
        }, 200);
        break;

      case 'productCategory3':
        var productCat3Val = document.getElementById('cmb_product_category3_id').value;
        setPCategory3Id(productCat3Val)
        setCategory4Options([]);
        setCategory5Options([]);
        setPCategory4Id('')
        setPCategory5Id('')
        if (productCat3Val === '0') {
          setPCategory3Id(productCat3Val)
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Product Category-3')
          setShowAddRecModal(true)
          setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
        } else {

          if (productCat3Val !== "") {
            // Product category 4 list
            const productCat4ApiCall = await comboBoxRef.current.fillMasterData("smv_product_category4", "product_category3_id", productCat3Val)
            setCategory4Options(productCat4ApiCall)
            setPCategory4Id("3")

            // Product altr material list
            resetGlobalQuery();
            globalQuery.columns.push("product_fg_id");
            globalQuery.columns.push("product_fg_name");
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: document.getElementById('cmb_product_type_id').value });
            globalQuery.conditions.push({ field: "product_fg_category1_id", operator: "=", value: document.getElementById('cmb_product_category1_id').value });
            globalQuery.conditions.push({ field: "product_fg_category2_id", operator: "=", value: $('#cmb_product_category2_id').val() });
            globalQuery.conditions.push({ field: "product_fg_category3_id", operator: "=", value: productCat3Val });
            globalQuery.table = "smv_product_fg_details";

            var pAltrRMIds = await comboBoxRef.current.fillFiltersCombo(globalQuery)
            setPAlternateMaterialOptions(pAltrRMIds)
          }
        }
        setTimeout(async () => {
          await FnGenerateTechSpecName();
        }, 200);

        break;

      case 'productCategory4':
        var productCat4Val = document.getElementById('cmb_product_category4_id').value;
        setPCategory4Id(productCat4Val)
        setCategory5Options([]);
        setPCategory5Id('')
        if (productCat4Val === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Product Category-4')
          setShowAddRecModal(true)
          setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
        } else {
          if (productCat4Val !== "") {
            // Product category 4 list
            const productCat5ApiCall = await comboBoxRef.current.fillMasterData("smv_product_category5", "product_category4_id", productCat4Val)
            setCategory5Options(productCat5ApiCall)
            setPCategory5Id('3')
            // // Product altr material list
            resetGlobalQuery();
            globalQuery.columns.push("product_fg_id");
            globalQuery.columns.push("product_fg_name");
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: document.getElementById('cmb_product_type_id').value });
            globalQuery.conditions.push({ field: "product_fg_category1_id", operator: "=", value: document.getElementById('cmb_product_category1_id').value });
            globalQuery.conditions.push({ field: "product_fg_category2_id", operator: "=", value: $('#cmb_product_category2_id').val() });
            globalQuery.conditions.push({ field: "product_fg_category3_id", operator: "=", value: $('#cmb_product_category3_id').val() });
            globalQuery.conditions.push({ field: "product_fg_category4_id", operator: "=", value: productCat4Val });
            globalQuery.table = "smv_product_fg_details";

            var pAltrRMIds = await comboBoxRef.current.fillFiltersCombo(globalQuery)
            setPAlternateMaterialOptions(pAltrRMIds)
          }
        }
        setTimeout(async () => {
          await FnGenerateTechSpecName();
        }, 200);
        break;

      case 'productCategory5':
        var productCat5Val = document.getElementById('cmb_product_category5_id').value;
        if (productCat4Val === '0') {
          setPCategory5Id(productCat5Val)
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Product Category-5')
          setShowAddRecModal(true)
          setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
          await FnGenerateTechSpecName();
        } else {
          setPCategory5Id(productCat5Val)
        }
        setTimeout(async () => {
          await FnGenerateTechSpecName();
        }, 200);
        break;

      case 'productMaterialType':
        var productMaterialTypeVal = document.getElementById('cmb_product_material_type_id').value;
        setPMaterialTypeId(productMaterialTypeVal)
        setPMaterialGradeOptions([])
        if (productMaterialTypeVal === '0') {
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Material Type')
          setShowAddRecModal(true)
          setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
        } else {
          if (productMaterialTypeVal !== "") {
            setPMaterialTypeId(productMaterialTypeVal)
            const productGradeApiCall = await comboBoxRef.current.fillMasterData("smv_product_material_grade", "product_material_type_id", productMaterialTypeVal)
            setPMaterialGradeOptions(productGradeApiCall)
          }
        }
        break;
      case 'productMaterialGrade':
        var productMaterialGradeVal = document.getElementById('cmb_product_material_grade_id').value;
        if (productMaterialGradeVal === '0') {
          setPMaterialGradeId(productMaterialGradeVal)
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Material Grade')
          setShowAddRecModal(true)
          setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)

        } else {
          setPMaterialGradeId(productMaterialGradeVal)
        }
        await FnGenerateTechSpecName();
        break;

      case 'productMaterialShape':
        var productMaterialShape = document.getElementById('cmb_product_material_shape_id').value;
        setPMaterialShapeId(productMaterialShape)

        if (productMaterialShape === '0') {
          setPMaterialGradeId(productMaterialGradeVal)
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Material Shape')
          setShowAddRecModal(true)
          setTimeout(() => {
            $(".erp_top_Form").css("padding-top", "0px");
          }, 100)
        }
        break;

      case 'productMaterialColor':
        var productMaterialColor = document.getElementById('cmb_product_material_colour').value;
        setPMaterialColour(productMaterialColor)
        break;
      case 'productAltrFG':
        var productAltrFGid = document.getElementById('cmb_product_alternate_FG_id').value;
        setPAlternateId(productAltrFGid)
        break;
    }
  }

  const displayRecordComponent = () => {
    switch (modalHeaderName) {
      case 'Product Type':
        return <FrmMProductTypeEntry btn_disabled={true} />;
      case 'Product Category1':
        return <FrmMProductCategory1Entry btn_disabled={true} />;
      case 'Product Category-2':
        return <FrmMProductCategory2Entry product_type={cmb_product_type_id} btn_disabled={true} />
      case 'Product Category-3':
        return <FrmMProductCategory3Entry product_type={cmb_product_type_id} btn_disabled={true} />
      case 'Product Category-4':
        return <FrmMProductCategory4Entry product_type={cmb_product_type_id} btn_disabled={true} />
      case 'Product Category-5':
        return <FrmMProductCategory5Entry product_type={cmb_product_type_id} btn_disabled={true} />
      case 'HSN-SAC':
        return <FrmHSNSAC btn_disabled={true} />
      case 'Puchase Unit':
        return <FrmProductUnitEntry btn_disabled={true} />
      case 'Sales Unit':
        return <FrmProductUnitEntry btn_disabled={true} />
      case 'Stock Unit':
        return <FrmProductUnitEntry btn_disabled={true} />
      case 'Packing':
        return <FrmMProductPackingEntry btn_disabled={true} />
      case 'Material Type':
        return <FrmMProductMaterialTypeEntry btn_disabled={true} />
      case 'Material Grade':
        return <FrmMProductMaterialGradeEntry btn_disabled={true} />
      case 'Material Shape':
        return <FrmMProductMaterialShapeEntry btn_disabled={true} />
      case 'Consumption Mode':
        return <FrmPropertyEntry btn_disabled={true} />
      default:
        return null;
    }
  }

  const FnAddFinishGood = async (functionType) => {
    debugger
    try {
      setIsLoading(true)
      var checkIsValidate = await validate();
      if (checkIsValidate) {
        var jsonData = {
          'ProductFgData': {}, 'TechnicalData': {}, 'CommercialData': {},
          'CustomerData': [], 'ProcessData': [], 'QaMappingData': [], 'SupplierData': [], 'commonIds': { 'company_id': COMPANY_ID, 'product_fg_id': product_fg_id, 'product_id': product_id },
          'saveKey': functionType
        }
        const formData = new FormData();
        // For General Entry 
        if (functionType === 'generalEntry' || functionType === 'allFGData') {
          const generalEntryDATA = {
            company_id: COMPANY_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            product_id: productFGId !== 0 ? product_id : 0,
            product_fg_id: product_fg_id,
            product_type_id: cmb_product_type_id,
            product_category1_id: cmb_product_category1_id,
            // product_fg_id: product_fg_id,
            product_fg_code: txt_product_fg_code,
            product_fg_name: txt_product_fg_name,
            product_fg_short_name: txt_fg_short_name,
            product_fg_print_name: txt_fg_print_name === "" ? txt_product_fg_name : txt_fg_print_name,
            product_fg_tech_spect: txt_fg_tech_spect,
            product_fg_oem_part_code: txt_fg_oem_part_code,
            product_fg_our_part_code: txt_fg_our_part_code,
            product_fg_item_sr_no: txt_fg_item_sr_no,
            product_fg_drawing_no: txt_fg_drawing_no,
            product_fg_model_no: txt_fg_model_no,
            product_fg_hsn_sac_code_id: cmb_fg_hsn_sac_code_id,
            product_fg_purchase_unit_id: cmb_fg_purchase_unit_id,
            product_fg_sales_unit_id: cmb_fg_sales_unit_id,
            product_fg_stock_unit_id: cmb_fg_stock_unit_id,
            product_fg_packing_id: cmb_fg_packing_id,
            product_fg_bar_code: txt_fg_bar_code !== "" ? txt_fg_bar_code : product_fg_id,
            product_consumption_mode: cmb_consumption_mode,
            godown_id: cmb_godown_id === null || cmb_godown_id === "" ? 1 : cmb_godown_id,
            godown_section_id: cmb_godown_section_id === null || cmb_godown_section_id === "" ? 1 : cmb_godown_section_id,
            godown_section_beans_id: cmb_godown_section_beans_id === null || cmb_godown_section_beans_id === "" ? 1 : cmb_godown_section_beans_id,
            product_origin_type: cmb_origin_type,
            product_origin_country: cmb_origin_country,
            remark: txt_remarkForProductEntry,
            is_active: is_activeGen,
            bom_applicable: is_BOMApplicable,
            created_by: product_id === 0 ? UserName : null,
            modified_by: product_id !== 0 ? UserName : null,
          };
          jsonData.ProductFgData = generalEntryDATA;
          formData.append(`qrCodeFile`, file_fg_qr_code)
        }

        //For Technical Entry
        if (functionType === 'technicalEntry' || functionType === 'allFGData') {
          const technicalEntryDATA = {
            company_id: COMPANY_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            product_fg_id: product_fg_id,
            product_fg_technical_id: product_fg_technical_id,
            product_fg_technical_name: txt_product_fg_technical_name,
            product_category2_id: cmb_product_category2_id,
            product_category3_id: cmb_product_category3_id,
            product_category4_id: cmb_product_category4_id,
            product_category5_id: cmb_product_category5_id,
            product_material_type_id: cmb_product_material_type_id,
            product_material_grade_id: cmb_product_material_grade_id,
            product_material_shape_id: cmb_product_material_shape_id,
            product_material_colour: cmb_product_material_colour,
            product_alternate_fg_id: cmb_product_alternate_fg_id,
            assembly_scrap_percent: txt_assembly_scrap_percent,
            remark: txt_remarkForProductEntry,
            is_active: is_activeGen,
            created_by: product_id === 0 ? UserName : null,
            modified_by: product_id !== 0 ? UserName : null
          };
          jsonData.TechnicalData = technicalEntryDATA;
        }

        // For Commercial Entry
        if (functionType === 'commercialEntry' || functionType === 'allFGData') {
          const commercialEntryDATA = {
            company_id: COMPANY_ID,
            company_branch_id: COMPANY_BRANCH_ID,
            product_fg_id: product_fg_id,
            product_fg_commercial_id: productFGId !== 0 ? 0 : product_fg_commercial_id,
            product_fg_gross_weight: txt_product_fg_gross_weight,
            product_fg_net_weight: txt_product_fg_net_weight,
            product_fg_std_weight: txt_product_fg_std_weight,
            product_fg_volume: txt_product_fg_volume,
            product_fg_mrp: txt_product_fg_mrp,
            product_fg_landed_price: txt_product_fg_landed_price,
            product_fg_avg_price: txt_product_fg_avg_price,
            product_fg_std_profit_percent: txt_product_fg_std_profit_percent,
            product_fg_std_discount_percent: txt_product_fg_std_discount_percent,
            product_fg_moq: txt_product_fg_moq,
            product_fg_mov: txt_product_fg_mov,
            product_fg_eoq: txt_product_fg_eoq,
            product_fg_mpq: txt_product_fg_mpq,
            product_fg_min_stock_level: txt_product_fg_min_stock_level,
            product_fg_max_stock_level: txt_product_fg_max_stock_level,
            product_fg_reorder_stock_level: txt_product_fg_reorder_stock_level,
            product_fg_depriciation_percent: txt_product_fg_depriciation_percent,
            sales_tolerance_percent: txt_sales_tolerance_percent,
            product_fg_price_type: is_priceType,
            product_fg_abc_analysis: is_abcAnalysis,
            qa_required: is_qaRequired,
            test_certificate_required: is_testCertified,
            remark: txt_remarkForProductEntry,
            is_active: is_activeGen,
            created_by: product_id === 0 ? UserName : null,
            modified_by: product_id !== 0 ? UserName : null

          };
          jsonData.CommercialData = commercialEntryDATA;
        }

        //For Supplier Mapping Information
        if (functionType === 'supplierMapping' || functionType === 'allFGData') {
          $("input:checkbox[name=checkSupplier]:checked").each(function () {
            const supplierMapDATA = {
              company_id: parseInt(COMPANY_ID),
              company_branch_id: parseInt(COMPANY_BRANCH_ID),
              product_fg_id: product_fg_id,
              supplier_id: $(this).val(),
              created_by: product_id === 0 ? UserName : null,
              modified_by: product_id !== 0 ? UserName : null
            }
            jsonData.SupplierData.push(supplierMapDATA)
          });
        }

        //Customer Mapping Information
        if (functionType === 'customerMapping' || functionType === 'allFGData') {
          $("input:checkbox[name=checkCustomer]:checked").each(function () {
            const customerMapDATA = {
              company_id: parseInt(COMPANY_ID),
              company_branch_id: parseInt(COMPANY_BRANCH_ID),
              product_fg_id: product_fg_id,
              customer_id: $(this).val(),
              created_by: product_id === 0 ? UserName : null,
              modified_by: product_id !== 0 ? UserName : null
            }
            jsonData.CustomerData.push(customerMapDATA)
          });
        }

        // 
        //For Process Mapping Information
        if (functionType === 'processMapping' || functionType === 'allFGData') {
          $("input:checkbox[name=checkProcess]:checked").each(function () {
            const processMapDATA = {
              company_id: parseInt(COMPANY_ID),
              company_branch_id: parseInt(COMPANY_BRANCH_ID),
              product_fg_id: product_fg_id,
              product_process_id: $(this).val(),
              created_by: product_id === 0 ? UserName : null,
              modified_by: product_id !== 0 ? UserName : null
            }
            jsonData.ProcessData.push(processMapDATA)
          });
        }

        //For QA Mapping Information
        //chnges by ujjwala on 10 /1/2024 start
        if (functionType === 'qaMapping' || functionType === 'allFGData') {
          $("input:checkbox[name=checkQaMapp]:checked").each(function () {
            let qaMappingObj = dataQA.find((item) => item.product_qa_parameters_id === parseInt(this.value))
            const qaMapDATA = {
              company_id: parseInt(COMPANY_ID),
              company_branch_id: parseInt(COMPANY_BRANCH_ID),
              product_fg_id: product_fg_id,
              product_qa_parameters_id: $(this).val(),
              created_by: product_id === 0 ? UserName : null,
              modified_by: product_id !== 0 ? UserName : null,
              product_fg_qa_from_range: qaMappingObj.from_range,
              product_fg_qa_to_range: qaMappingObj.to_range,
              product_fg_qa_from_deviation_percent: qaMappingObj.from_deviation_percent,
              product_fg_qa_to_deviation_percent: qaMappingObj.to_deviation_percent,
            }
            jsonData.QaMappingData.push(qaMapDATA)
          });
        }

        //end by ujjwala 
        formData.append(`TransData`, JSON.stringify(jsonData))
        const requestOptions = {
          method: 'POST',
          body: formData
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductFg/FnAddUpdateRecord`, requestOptions)
        const response = await apicall.json();
        if (response.success !== 1) {
          setErrMsg(response.error)
          setShowErrorMsgModal(true)
        } else {
          const evitCache = comboBoxRef.current.evitCache();
          console.log(evitCache);
          const { product_fg_id, product_fg_name, product_fg_qr_code } = response.data;
          setProductFgId(product_fg_id)
          setFGName(product_fg_name)
          setFGQrCodePath(product_fg_qr_code)
          setSuccMsg(response.message)

          setShowSuccessMsgModal(true)
          $('#upload_btn_id').attr("disabled", false);
        }
      }
      setIsLoading(false)

    } catch (error) {
      console.log("error: ", error);
      navigate('/Error')
    }
  }

  const onFileUpload = (e) => {
    if (e.target.files) {
      var file = e.target.files[0];
      setFGQrCode(file);
    }
  }

  const fetchQrCode = async () => {
    try {
      const downloadImageApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductFg/FnGetQRCode/${product_fg_id}`)
      const blob = await downloadImageApiCall.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `qrcode.png`,);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }

  const percentValidate = (val, key) => {
    switch (key) {
      case 'AssemblyScrap':
        if (validatePercentage.current.percentValidate(val)) {
          setAssemblyScrapPercent(val)
        }
        break;


      case 'StandardProfit':
        if (validatePercentage.current.percentValidate(val)) {
          setPFGStdProfitPercent(val)
        }
        break;

      case 'PDiscountPercent':
        if (validatePercentage.current.percentValidate(val)) {
          setPFGDiscountPercent(val)
        }
        break;

      case 'PDeprecationPercent':
        if (validatePercentage.current.percentValidate(val)) {
          setPFGDepricationPercent(val)
        }
        break;

      case 'PTolerancePercent':
        if (validatePercentage.current.percentValidate(val)) {
          setSalesTolerancePercent(val)
        }
        break;
    }
  }

  // Validations
  const validate = async () => {
    if (await validationRef.current.validateForm('productFgFormId')) {
      if (await validationRef.current.validateForm('technicalEntryFormId')) {
        return await validationRef.current.validateForm('commercialEntryFormId');
      }
    }
    return false;
  }

  const validateFields = () => {
    validationRef.current.validateFieldsOnChange('productFgFormId');
    validationRef.current.validateFieldsOnChange('technicalEntryFormId');
    validationRef.current.validateFieldsOnChange('commercialEntryFormId');
  }

  const validateNo = (key) => {
    const numCheck = /^[0-9]*\.?[0-9]*$/;
    const regexNo = /^[0-9]*\.[0-9]{5}$/
    const regexNo1 = /^[0-9]*\.[0-9]{3}$/
    const regexNo2 = /^[0-9]*\.[0-9]{0}$/
    var dot = ".";
    switch (key) {
      case 'PGrossWeight':
        var grossWtVal = $('#txt_product_rm_gross_weight').val();
        if (!regexNo.test(grossWtVal) && grossWtVal.length < 15 || grossWtVal.indexOf(dot) === 14) {
          if (numCheck.test(grossWtVal)) {
            setPFGGrossWeight(grossWtVal)
          }
        }
        break;

      case 'PNetWeight':
        var netWtVal = $('#txt_product_rm_net_weight').val();
        if (!regexNo.test(netWtVal) && netWtVal.length < 15 || netWtVal.indexOf(dot) === 14) {
          if (numCheck.test(netWtVal)) {
            setPFGNetWeight(netWtVal)
          }
        }
        break;

      case 'PStdWeight':
        var stdWtVal = $('#txt_product_rm_std_weight').val();
        if (!regexNo.test(stdWtVal) && stdWtVal.length < 15 || stdWtVal.indexOf(dot) === 14) {
          if (numCheck.test(stdWtVal)) {
            setPFGStdWeight(stdWtVal)
          }
        }
        break;

      case 'PVolume':
        var volumeVal = $('#txt_product_rm_volume').val();
        if (!regexNo.test(volumeVal) && volumeVal.length < 15 || volumeVal.indexOf(dot) === 14) {
          if (numCheck.test(volumeVal)) {
            setPFGVolume(volumeVal)
          }
        }
        break;

      case 'PMrp':
        var mrpVal = $('#txt_product_rm_mrp').val();
        if (!regexNo.test(mrpVal) && mrpVal.length < 15 || mrpVal.indexOf(dot) === 14) {
          if (numCheck.test(mrpVal)) {
            setPFGMrp(mrpVal)
          }
        }
        break;

      case 'PLandedPrice':
        var landedPriceVal = $('#txt_product_rm_landed_price').val();
        if (!regexNo1.test(landedPriceVal) && landedPriceVal.length < 17 || landedPriceVal.indexOf(dot) === 16) {
          if (numCheck.test(landedPriceVal)) {
            setPFGLandedPrice(landedPriceVal)
          }
        }
        break;

      case 'PAvgPrice':
        var avgPriceVal = $('#txt_product_rm_avg_price').val();
        if (!regexNo1.test(avgPriceVal) && avgPriceVal.length < 17 || avgPriceVal.indexOf(dot) === 16) {
          if (numCheck.test(avgPriceVal)) {
            setPFGAvgPrice(avgPriceVal)
          }
        }
        break;

      case 'PMoq':
        var moqVal = $('#txt_product_rm_moq').val();
        if (!regexNo1.test(moqVal) && moqVal.length < 17 || moqVal.indexOf(dot) === 16) {
          if (numCheck.test(moqVal)) {
            setPFGmoq(moqVal)
          }
        }
        break;

      case 'PMpq':
        var mpqVal = $('#txt_product_rm_mpq').val();
        if (!regexNo1.test(mpqVal) && mpqVal.length < 17 || mpqVal.indexOf(dot) === 16) {
          if (numCheck.test(mpqVal)) {
            setPFGmpq(mpqVal)
          }
        }
        break;

      case 'PMinStockLevel':
        var minStockLevelVal = $('#txt_product_rm_min_stock_level').val();
        if (!regexNo1.test(minStockLevelVal) && minStockLevelVal.length < 17 || minStockLevelVal.indexOf(dot) === 16) {
          if (numCheck.test(minStockLevelVal)) {
            setPFGMinStockLevel(minStockLevelVal)
          }
        }
        break;

      case 'PMaxStockLevel':
        var maxStockLevelVal = $('#txt_product_rm_max_stock_level').val();
        if (!regexNo1.test(maxStockLevelVal) && maxStockLevelVal.length < 17 || maxStockLevelVal.indexOf(dot) === 16) {
          if (numCheck.test(maxStockLevelVal)) {
            setPFGMaxStockLevel(maxStockLevelVal)
          }
        }
        break;

      case 'PReorderStockLevel':
        var reorderStockLevelVal = $('#txt_product_rm_reorder_stock_level').val();
        if (!regexNo1.test(reorderStockLevelVal) && reorderStockLevelVal.length < 17 || reorderStockLevelVal.indexOf(dot) === 16) {
          if (numCheck.test(reorderStockLevelVal)) {
            setPFGReorderStockLevel(reorderStockLevelVal)
          }
        }
        break;

      case 'PMov':
        var movVal = $('#txt_product_rm_mov').val();
        if (!regexNo1.test(movVal) && movVal.length < 17 || movVal.indexOf(dot) === 16) {
          if (numCheck.test(movVal)) {
            setPFGmov(movVal)
          }
        }
        break;

      case 'PEoq':
        var eoqVal = $('#txt_product_rm_eoq').val();
        if (!regexNo2.test(eoqVal)) {
          if (numCheck.test(eoqVal)) {
            setPFGeoq(eoqVal)
          }
        }
        break;
    }
  }

  const FnRefreshbtn = async (key) => {
    if (keyForViewUpdate === 'update') {
      // 
      switch (key) {
        case 'godown_id':
          resetGlobalQuery();
          globalQuery.columns.push("field_id");
          globalQuery.columns.push("field_name");
          globalQuery.table = "cmv_godown"
          globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });

          comboBoxRef.current.fillFiltersCombo(globalQuery).then((godownidApiCall) => {
            setgodownidOptions(godownidApiCall);
          })

          setgodownid('');
          setgodownidSectionOptions([])
          setgodownsectionid('');
          setgodownsectionbeansidOptions([])
          setgodownsectionbeansid('')
          break;

        case 'godown_Section':
          await comboOnChange("godown");
          break;

        case 'godown_Section_Beans':
          await comboOnChange("godownsection");
          break;
        default:
          break;
      }
    }
  }


  // ---------------------------------------------------------------------------------------------

  //changes by tushar
  const handleAccordionSelect = async (eventKey) => {

    let checkedLength = 0;
    switch (eventKey) {
      case 'SupplierMapping':
        checkedLength = $("input:checkbox[name=checkSupplier]:checked").length;
        if (checkedLength === 0) {
          await FnGetMappingsData("SupplierMapping");
          console.log("SupplierMapping");
        }
        if (supplierMappingData.length !== 0) {
          let supplierCheckbox = document.getElementsByName('checkSupplier');
          for (let i = 0; i < supplierMappingData.length; i++) {
            for (let checkbox of supplierCheckbox) {
              if (parseInt(checkbox.value) === supplierMappingData[i].supplier_id) {
                $('input:checkbox[name="checkSupplier"][value="' + supplierMappingData[i].supplier_id + '"]').attr('checked', true)
              }
            }
          }
        }
        break;

      case 'CustomerMapping':
        checkedLength = $("input:checkbox[name=checkCustomer]:checked").length;
        if (checkedLength === 0) {
          await FnGetMappingsData("CustomerMapping");
          console.log("CustomerMapping");
        }
        //Updating Customer CheckBoxes
        if (customerMappingData.length !== 0) {
          let customerCheckbox = document.getElementsByName('checkCustomer');
          for (let i = 0; i < customerMappingData.length; i++) {
            for (let checkbox of customerCheckbox) {
              if (parseInt(checkbox.value) === customerMappingData[i].customer_id) {
                $('input:checkbox[name="checkCustomer"][value="' + customerMappingData[i].customer_id + '"]').attr('checked', true);
              }
            }
          }
        }
        break;

      case 'ProcessMapping':
        checkedLength = $("input:checkbox[name=checkProcess]:checked").length;
        if (checkedLength === 0) {
          await FnGetMappingsData("ProcessRecords");
          console.log("ProcessMapping");
        }
        //Updating Process Records
        if (processMappingData.length !== 0) {
          let processCheckbox = document.getElementsByName('checkProcess');
          for (let i = 0; i < processMappingData.length; i++) {
            for (let checkbox of processCheckbox) {
              if (parseInt(checkbox.value) === processMappingData[i].product_process_id) {
                $('input:checkbox[name="checkProcess"][value="' + processMappingData[i].product_process_id + '"]').attr('checked', true);
              }
            }
          }
        }
        break;

      case 'QAMapping':
        checkedLength = $("input:checkbox[name=checkQaMapp]:checked").length;
        if (checkedLength === 0) {
          await FnGetMappingsData("QaParameters");
        }
        //Updating QA Records
        if (qaMappingData.length !== 0) {
          let qaMappCheckbox = document.getElementsByName('checkQaMapp');
          for (let i = 0; i < qaMappingData.length; i++) {
            for (let checkbox of qaMappCheckbox) {
              if (parseInt(checkbox.value) === qaMappingData[i].product_qa_parameters_id) {
                $('input:checkbox[name="checkQaMapp"][value="' + qaMappingData[i].product_qa_parameters_id + '"]').attr('checked', true);
              }
            }
          }
        }
        console.log("QAMapping");
        break;

      default:
        console.log('Invalid accordion key');
    }
    if (keyForViewUpdate === "view") {
      $('input[type="checkbox"]').prop('disabled', true);
    }
  };


  const forwardToNxtPage = async () => {
debugger
    if (product_id !== 0) {
      const isValidate = await validate();
      if (isValidate) {
        if (is_BOMApplicable === "Yes") {
          resetGlobalQuery();
          globalQuery.columns.push("bom_applicable");
          globalQuery.table = "sm_product_fg"
          globalQuery.conditions.push({ field: "product_fg_id", operator: "=", value: product_fg_id });
          const IsBomApplicable = await comboBoxRef.current.removeCatcheFillCombo(globalQuery)
          if (IsBomApplicable[0].bom_applicable === "Yes") {
            goToNext(product_fg_id, txt_product_fg_name, document.getElementById("cmb_product_type_id").options[document.getElementById("cmb_product_type_id").selectedIndex].getAttribute('ptShortName'));
          } else {
            setShowErrorMsgModal(true)
            setErrMsg(`${txt_product_fg_name} Finish Good Is Not BOM Applicable First Update Record..!`)
          }
        } else {
          setShowErrorMsgModal(true)
          setErrMsg(`${txt_product_fg_name} Finish Good Is Not BOM Applicable..!`)
        }
      }
    } else {
      const isValidate = await validate();
      if (isValidate) {
        setShowErrorMsgModal(true)
        setErrMsg(` Please Save ${txt_product_fg_name} First..!`)
      }

    }
  }
  // --------------------------------------delete -------------------------------------------------------
  // Popup Fields
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    navigate('/Masters/FinishGoodsListing')
  }

  const deleteshow = () => { setShow(true) }

  async function DeleteRecord() {

    try {
      const method = { method: 'DELETE' }
      await fetch(`${process.env.REACT_APP_BASE_URL}/api/SmProductFg/FnDeleteRecord/${product_fg_id}/${UserName}`, method)
      setShow(false)
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }


  // --------------------------------------delete -------------------------------------------------------

  function toggleQuotationTermsChkBoxes(key) {

    switch (key) {
      // For Supplier Mapping
      case "checkAllSupplier":
        $('.checkSupplier').prop('checked', $('#checkAllSupplier').is(":checked"));
        break;
      case 'PartiallySupplierSelection':
        $('#checkAllSupplier').prop('checked', $('input:checkbox.checkSupplier:checked').length === $('input:checkbox.checkSupplier').length);
        break;
      // For Process Mapping
      case 'checkAllProcess':
        $('.checkProcess').prop('checked', $('#checkAllProcess').is(":checked"));
        break;
      case 'PartiallyProcessSelection':
        $('#checkAllProcess').prop('checked', $('input:checkbox.checkProcess:checked').length === $('input:checkbox.checkProcess').length);
        break;
      // For QA Mapping
      case 'checkAllQa':
        $('.checkQaMapp').prop('checked', $('#checkAllQa').is(":checked"));
        break;
      case 'PartiallyQAmappingSelection':
        $('#checkAllQa').prop('checked', $('input:checkbox.checkQaMapp:checked').length === $('input:checkbox.checkQaMapp').length);
        break;
      // For customer Mapping
      case 'checkAllCustomer':
        $('.checkCustomer').prop('checked', $('#checkAllCustomer').is(":checked"));
        break;
      case 'PartiallyCustomerSelection':
        $('#checkAllCustomer').prop('checked', $('input:checkbox.checkCustomer:checked').length === $('input:checkbox.checkCustomer').length);
        break;
      default:
        break;
    }
  }
  //--------------------------------------check all checkboxes ----------------------------------------------------
  return (
    <>
      <GenerateMaterialId ref={generateMaterialIdAPiCall} />
      <ComboBox ref={comboBoxRef} />
      <FrmValidations ref={validationRef} />
      <ValidateNumberDateInput ref={validatePercentage} />

      {isLoading ?
        <div className="spinner-overlay"  >
          <div className="spinner-container">
            <CircularProgress color="primary" />
            <span id="spinner_text" className="text-dark">Loading...</span>
          </div>
        </div> :
        null}
      <form id="productFgFormId">

        <div className='row'>
          {/* first */}
          <div className='col-sm-4 erp_form_col_div'>
            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Product Type : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_product_type_id" className="form-select form-select-sm" value={cmb_product_type_id} onChange={() => comboOnChange('productType')}>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {productTypeOptions?.map(productType => (
                    <option value={productType.field_id} ptShortName={productType.product_type_short_name}>{productType.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Product Category 1 : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_product_category1_id" className="form-select form-select-sm" value={cmb_product_category1_id} onChange={() => comboOnChange('productCategory1')}>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {productCategory1Options?.map(productCategory1 => (
                    <option value={productCategory1.field_id}>{productCategory1.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_product_category1_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Material ID : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="product_fg_id" className="erp_input_field" value={product_fg_id} maxLength="255" disabled />
                <MDTypography variant="button" id="error_product_fg_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">FG Code :  </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_code" className="erp_input_field" value={txt_product_fg_code} onChange={e => { setFGCode(e.target.value); validateFields() }} maxLength="255" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">FG Name : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_name" className="erp_input_field" value={txt_product_fg_name} onChange={e => { setFGName(e.target.value); validateFields() }} maxLength="500" />
                <MDTypography variant="button" id="error_txt_rm_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Short Name : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_short_name" className="erp_input_field" value={txt_fg_short_name} onChange={e => { setFGShortName(e.target.value.toUpperCase()); validateFields() }} maxLength="5" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Print Name : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_print_name" className="erp_input_field" value={txt_fg_print_name} onChange={e => { setFGPrintName(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_print_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Tech. Spect. : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control as="textarea" rows={1} id="txt_rm_tech_spect" className="erp_txt_area" value={txt_fg_tech_spect} onChange={e => { setFGTechSpect(e.target.value); validateFields() }} maxlength="1000" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_tech_spect" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">OEM Part Code : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_oem_part_code" className="erp_input_field" value={txt_fg_oem_part_code} onChange={e => { setFGOemPartCode(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_oem_part_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">OUR Part Code : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_our_part_code" className="erp_input_field" value={txt_fg_our_part_code} onChange={e => { setFGOurPartCode(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_our_part_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

          </div>

          {/* second */}
          <div className='col-sm-4 erp_form_col_div'>
            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Item Sr No. : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_item_sr_no" className="erp_input_field" value={txt_fg_item_sr_no} onChange={e => { setFGItemSrNo(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_item_sr_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Item Drawing No. : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_drawing_no" className="erp_input_field" value={txt_fg_drawing_no} onChange={e => { setFGDrawingNo(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_drawing_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Model No. : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_model_no" className="erp_input_field" value={txt_fg_model_no} onChange={e => { setFGModelNo(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_model_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">HSN-SAC Code : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_fg_hsn_sac_code_id" className="form-select form-select-sm" value={cmb_fg_hsn_sac_code_id} onChange={() => comboOnChange('hsnSacCode')}>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {hsnSacCodeOptions?.map(HsnSacCode => (
                    <option value={HsnSacCode.field_id}>{HsnSacCode.field_name}</option>
                  ))}

                </select>
                <MDTypography variant="button" id="error_cmb_fg_hsn_sac_code_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Purchase Unit : </Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_fg_purchase_unit_id" className="form-select form-select-sm" value={cmb_fg_purchase_unit_id} onChange={() => comboOnChange('puchaseUnit')} optional='optional'>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {unitOptions?.map(units => (
                    <option value={units.field_id}>{units.field_name}</option>
                  ))}

                </select>
                <MDTypography variant="button" id="error_cmb_fg_purchase_unit_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>


            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Sales Unit : </Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_fg_sales_unit_id" className="form-select form-select-sm" value={cmb_fg_sales_unit_id} onChange={() => comboOnChange('salesUnit')} optional='optional'>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {unitOptions?.map(units => (
                    <option value={units.field_id}>{units.field_name}</option>
                  ))}

                </select>
                <MDTypography variant="button" id="error_cmb_fg_sales_unit_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Stock Unit : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_fg_stock_unit_id" className="form-select form-select-sm" value={cmb_fg_stock_unit_id} onChange={() => { comboOnChange('stockUnit'); validateFields() }}>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {unitOptions?.map(units => (
                    <option value={units.field_id}>{units.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_fg_stock_unit_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>


            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Packing : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_fg_packing_id" className="form-select form-select-sm" value={cmb_fg_packing_id} onChange={() => { comboOnChange('packingId'); validateFields() }}>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {productPackingOptions?.map(productPackings => (
                    <option value={productPackings.field_id}>{productPackings.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_fg_packing_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Bar Code : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control type="text" id="txt_rm_bar_code" className="erp_input_field" value={txt_fg_bar_code} onChange={e => { setFGBarCode(e.target.value); validateFields() }} maxLength="500" optional="optional" />
                <MDTypography variant="button" id="error_txt_rm_bar_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">BOM Applicable : </Form.Label>
              </div>
              <div className="col">
                <div className="erp_form_radio">
                  <div className="fCheck">
                    <Form.Check
                      className="erp_radio_button"
                      label="Yes"
                      type="radio"
                      value="Yes" checked={is_BOMApplicable === 'Yes'} onClick={() => { setBOMApplicable('Yes'); }}
                      name="isBOMApplicable"
                      defaultChecked
                    />
                  </div>
                  <div className="sCheck">
                    <Form.Check
                      className="erp_radio_button"
                      label="No"
                      value="No" checked={is_BOMApplicable === 'No'} onClick={() => { setBOMApplicable('No'); }}
                      type="radio"
                      name="isBOMApplicable"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Third */}
          <div className='col-sm-4 erp_form_col_div'>
            <div className="row">
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">QR Code : </Form.Label>
              </div>
              <div className="col">
                <Form.Control type="file" id="file_rm_qr_code" className="erp_input_field" onChange={onFileUpload} optional="optional" accept="image/*" />
                <MDTypography component="label" className={`erp-form-label ${file_fg_qr_codeName !== '' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" color="info"  >
                  <Link to="#" onClick={fetchQrCode}>{file_fg_qr_codeName}</Link>

                </MDTypography>
                <MDTypography variant="button" id="error_file_rm_qr_code" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Consumption Mode : <span className="required">*</span></Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_consumption_mode" className="form-select form-select-sm" value={cmb_consumption_mode} onChange={() => comboOnChange('consumptionMode')}>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {consumtionModeOptions?.map(consumptionModes => (
                    <option value={consumptionModes.field_name}>{consumptionModes.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_consumption_mode" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4 col-12'>
                <Form.Label className="erp-form-label">Godown  : </Form.Label>
              </div>
              <div className='col-sm-7 col-10'>
                <select id="cmb_godown_id" className="form-select form-select-sm" value={cmb_godown_id} onChange={(e) => { setgodownid(e.target.value); comboOnChange('godown'); }} optional='optional' >
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {godownidOptions?.map(godownid => (
                    <option value={godownid.field_id}>{godownid.field_name}</option>
                  ))}

                </select>
                <MDTypography variant="button" id="error_cmb_godown_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
              <div className={`col-sm-1 col-2 ${keyForViewUpdate === 'delete' || keyForViewUpdate === 'view' ? 'd-none' : ' display'}`}>
                <Tooltip title="Refresh" placement="top">
                  <MDTypography>
                    <MdRefresh id="btn_refresh" onClick={() => FnRefreshbtn("godown_id")} style={{ color: 'black' }} />
                  </MDTypography>
                </Tooltip>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4 col-12'>
                <Form.Label className="erp-form-label">Godown Section : </Form.Label>
              </div>
              <div className='col-sm-7 col-10'>
                <select id="cmb_godown_section_id" className="form-select form-select-sm" value={cmb_godown_section_id} onChange={(e) => { setgodownsectionid(e.target.value); comboOnChange('godownsection'); }} optional='optional'>
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {godownidSectionOptions?.map(godownsection => (
                    <option value={godownsection.field_id}>{godownsection.field_name}</option>
                  ))}

                </select>
                <MDTypography variant="button" id="error_cmb_godown_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>

              <div className={`col-sm-1 col-2 ${keyForViewUpdate === 'delete' || keyForViewUpdate === 'view' ? 'd-none' : ' display'}`}>
                <Tooltip title="Refresh" placement="top">
                  <MDTypography>
                    <MdRefresh id="btn_refresh" onClick={() => FnRefreshbtn("godown_Section")} style={{ color: 'black' }} />
                  </MDTypography>
                </Tooltip>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4 col-12'>
                <Form.Label className="erp-form-label">Godown Sec. Beans : </Form.Label>
              </div>
              <div className='col-sm-7 col-10'>
                <select id="cmb_godown_section_beans_id" className="form-select form-select-sm" value={cmb_godown_section_beans_id} onChange={(e) => { setgodownsectionbeansid(e.target.value); comboOnChange('godownsectionbeans'); }} optional='optional' >
                  <option value="">Select</option>
                  <option value="0">Add New Record+</option>
                  {godownsectionbeansidOptions?.map(godownsectionbeans => (
                    <option value={godownsectionbeans.field_id}>{godownsectionbeans.field_name}</option>
                  ))}

                </select>
                <MDTypography variant="button" id="error_cmb_godown_section_beans_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
              <div className={`col-sm-1 col-2 ${keyForViewUpdate === 'delete' || keyForViewUpdate === 'view' ? 'd-none' : ' display'}`}>
                <Tooltip title="Refresh" placement="top">
                  <MDTypography>
                    <MdRefresh id="btn_refresh" onClick={() => FnRefreshbtn("godown_Section_Beans")} style={{ color: 'black' }} />
                  </MDTypography>
                </Tooltip>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Origin Type : </Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_origin_type" className="form-select form-select-sm" value={cmb_origin_type} optional='optional' onChange={() => comboOnChange('materialOrigin')}>
                  {materialOriginOptions?.map(origins => (
                    <option value={origins.field_name}>{origins.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_origin_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Origin Country : </Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_origin_country" className="form-select form-select-sm" value={cmb_origin_country} optional="optional" onChange={() => comboOnChange('originCountry')}>
                  <option value="">Select</option>
                  {countryOptions?.map(country => (
                    <option value={country.field_name}>{country.field_name}</option>
                  ))}
                </select>
                <MDTypography variant="button" id="error_cmb_origin_country" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <Form.Label className="erp-form-label">Remark : </Form.Label>
              </div>
              <div className='col'>
                <Form.Control as="textarea" rows={1} id="txt_remarkForProductEntry" className="erp_input_field" value={txt_remarkForProductEntry} onChange={e => { setRemarkForProductEntry(e.target.value); validateFields() }} maxLength="255" optional="optional" />
                <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                </MDTypography>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-4">
                <Form.Label className="erp-form-label">Is Active : </Form.Label>
              </div>
              <div className="col">
                <div className="erp_form_radio">
                  <div className="fCheck">
                    <Form.Check
                      className="erp_radio_button"
                      label="Yes"
                      type="radio"
                      value="true" checked={is_activeGen === true} onClick={() => { setIsActiveGen(true); }}
                      name="isProductFGActive"
                      defaultChecked
                    />
                  </div>
                  <div className="sCheck">
                    <Form.Check
                      className="erp_radio_button"
                      label="No"
                      value="false" checked={is_activeGen === false} onClick={() => { setIsActiveGen(false); }}
                      type="radio"
                      name="isProductFGActive"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <hr />

      {/* Technical Info Accordian */}
      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}> */}
      {/* changes by tushar */}
      <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey="TechnicalInformation">
          <Accordion.Header className="erp-form-label-md">Technical Information</Accordion.Header>
          <Accordion.Body>
            <form id="technicalEntryFormId">
              <div className='row'>
                <div className='col-sm-6 erp_form_col_div'>
                  <div className='row' id="product_category2_id" style={{ display: "none" }}>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label" id="cat1Label"></Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_category2_id" className="form-select form-select-sm" value={cmb_product_category2_id} onChange={() => comboOnChange('productCategory2')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {category2Options?.map((category2) => (
                          <option value={category2.field_id}>{category2.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_category2_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row' id="product_category3_id" style={{ display: "none" }}>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label" id="cat2Label"></Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_category3_id" className="form-select form-select-sm" value={cmb_product_category3_id} onChange={() => comboOnChange('productCategory3')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {category3Options?.map((category3) => (
                          <option value={category3.field_id}>{category3.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_category3_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row' id="product_category4_id" style={{ display: "none" }}>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label" id="cat3Label"></Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_category4_id" className="form-select form-select-sm" value={cmb_product_category4_id} onChange={() => comboOnChange('productCategory4')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {category4Options?.map((category4) => (
                          <option value={category4.field_id}>{category4.field_name}</option>
                        ))}

                      </select>
                      <MDTypography variant="button" id="error_cmb_product_category4_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row' id="product_category5_id" style={{ display: "none" }}>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label" id="cat4Label"></Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_category5_id" className="form-select form-select-sm" value={cmb_product_category5_id} onChange={() => comboOnChange('productCategory5')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {category5Options?.map(category5 => (
                          <option value={category5.field_id}>{category5.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_category5_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Material Type : </Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_material_type_id" className="form-select form-select-sm" value={cmb_product_material_type_id} onChange={() => comboOnChange('productMaterialType')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {pMaterialTypeOptions?.map(productMaterialType => (
                          <option value={productMaterialType.field_id}>{productMaterialType.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_material_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Material Grade : </Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_material_grade_id" className="form-select form-select-sm" value={cmb_product_material_grade_id} onChange={() => comboOnChange('productMaterialGrade')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {pMaterialGradeOptions?.map(productMaterialGrade => (
                          <option value={productMaterialGrade.field_id}>{productMaterialGrade.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_material_grade_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                </div>

                <div className='col-sm-6 erp_form_col_div'>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Technical Name : <span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_technical_name" className="erp_input_field" value={txt_product_fg_technical_name} onChange={e => { setPTechnivalName(e.target.value); validateFields() }} disabled maxLength="500" />
                      <MDTypography variant="button" id="error_txt_product_rm_technical_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Material Shape : </Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_material_shape_id" className="form-select form-select-sm" value={cmb_product_material_shape_id} onChange={() => comboOnChange('productMaterialShape')} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {pMaterialShapeOptions?.map(productMaterialShape => (
                          <option value={productMaterialShape.field_id}>{productMaterialShape.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_material_shape_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Material Color : </Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_material_colour" className="form-select form-select-sm" value={cmb_product_material_colour} onChange={() => comboOnChange('productMaterialColor')} optional='optional'>
                        <option value="">Select</option>
                        {pMaterialColorOptions?.map(productMaterialColor => (
                          <option value={productMaterialColor.field_name}>{productMaterialColor.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_material_colour" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Product Alternate FG : </Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_product_alternate_FG_id" className="form-select form-select-sm" value={cmb_product_alternate_fg_id} onChange={() => comboOnChange('productAltrFG')} optional='optional'>
                        <option value="">select</option>
                        {pAlternateMaterialOptions?.map(productAltrMaterial => (
                          <option value={productAltrMaterial.product_fg_id}>{productAltrMaterial.product_fg_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_product_alternate_FG_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Assembly Scrap Percent(%) : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="number" id="txt_assembly_scrap_percent" className="erp_input_field text-end" value={txt_assembly_scrap_percent} onChange={e => { percentValidate(e.target.value, 'AssemblyScrap'); validateFields() }} optional='optional' />
                      <MDTypography variant="button" id="error_txt_assembly_scrap_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  {/* <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Remark : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control as="textarea" rows={1} id="txt_remarkForTechnicalEntry" className="erp_input_field" value={txt_remarkForTechnicalEntry} onChange={e => { setRemarkForTechnicalEntry(e.target.value); validateFields() }} optional='optional' />
                      <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Is Active : </Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="Yes"
                            type="radio"
                            value="true" checked={is_activeTech === true} onClick={() => { setIsActiveTech(true); }}
                            name="isTechnicalActive"
                            defaultChecked
                          />
                        </div>
                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="No"
                            value="false" checked={is_activeTech === false} onClick={() => { setIsActiveTech(false); }}
                            type="radio"
                            name="isTechnicalActive"
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}

                </div>
              </div>
            </form>

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />

      {/* Commercial Info Accordian */}
      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}>*/}
      {/* changes by tushar */}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="CommercialInformation">
          <Accordion.Header className="erp-form-label-md">Commercial Information</Accordion.Header>
          <Accordion.Body>
            <form id="commercialEntryFormId">
              <div className='row'>
                <div className='col-sm-6 erp_form_col_div'>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Gross Weight : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_gross_weight" className="erp_input_field erp_align-right" value={txt_product_fg_gross_weight} onChange={e => { validateNo('PGrossWeight') }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_gross_weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Net Weight : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_net_weight" className="erp_input_field erp_align-right" value={txt_product_fg_net_weight} onChange={e => { validateNo('PNetWeight'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_net_weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Std Weight : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_std_weight" className="erp_input_field erp_align-right" value={txt_product_fg_std_weight} onChange={e => { validateNo('PStdWeight'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_std_weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Volume : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_volume" className="erp_input_field erp_align-right" value={txt_product_fg_volume} onChange={e => { validateNo('PVolume'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_volume" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Max.Retail Price : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_mrp" className="erp_input_field erp_align-right" value={txt_product_fg_mrp} onChange={e => { validateNo('PMrp'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_mrp" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Landed Price : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_landed_price" className="erp_input_field erp_align-right" value={txt_product_fg_landed_price} onChange={e => { validateNo('PLandedPrice'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_landed_price" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Average Price : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_avg_price" className="erp_input_field erp_align-right" value={txt_product_fg_avg_price} onChange={e => { validateNo('PAvgPrice'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_avg_price" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Standard Profit(%) : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="number" id="txt_product_rm_std_profit_percent" className="erp_input_field erp_align-right" value={txt_product_fg_std_profit_percent} onChange={e => { percentValidate(e.target.value, 'StandardProfit') }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_std_profit_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Standard Discount(%) : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="number" id="txt_product_rm_std_discount_percent" className="erp_input_field erp_align-right" value={txt_product_fg_std_discount_percent} onChange={e => { percentValidate(e.target.value, 'PDiscountPercent'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_std_discount_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Min.Order Quantity : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_moq" className="erp_input_field erp_align-right" value={txt_product_fg_moq} onChange={e => { validateNo('PMoq'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_moq" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Min.Order Value : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_mov" className="erp_input_field erp_align-right" value={txt_product_fg_mov} onChange={e => { validateNo('PMov'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_mov" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                </div>

                <div className='col-sm-6 erp_form_col_div'>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Economical Order Quantity : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_eoq" className="erp_input_field erp_align-right" value={txt_product_fg_eoq} onChange={e => { validateNo('PEoq'); }} maxLength="10" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_eoq" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Min.Production Quantity : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_mpq" className="erp_input_field erp_align-right" value={txt_product_fg_mpq} onChange={e => { validateNo('PMpq'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_mpq" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Min. Stock Level : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_min_stock_level" className="erp_input_field erp_align-right" value={txt_product_fg_min_stock_level} onChange={e => { validateNo('PMinStockLevel'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_min_stock_level" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Max. Stock Level : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_max_stock_level" className="erp_input_field erp_align-right" value={txt_product_fg_max_stock_level} onChange={e => { validateNo('PMaxStockLevel'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_max_stock_level" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Record Stock Level : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_product_rm_reorder_stock_level" className="erp_input_field erp_align-right" value={txt_product_fg_reorder_stock_level} onChange={e => { validateNo('PReorderStockLevel'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_reorder_stock_level" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Deprecation Percent(%) : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="number" id="txt_product_rm_depriciation_percent" className="erp_input_field erp_align-right" value={txt_product_fg_depriciation_percent} onChange={e => { percentValidate(e.target.value, 'PDeprecationPercent'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_product_rm_depriciation_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Sales Tolerance Percent(%) : </Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="number" id="txt_sales_tolerance_percent" className="erp_input_field erp_align-right" value={txt_sales_tolerance_percent} onChange={e => { percentValidate(e.target.value, 'PTolerancePercent'); }} maxLength="19" optional='optional' />
                      <MDTypography variant="button" id="error_txt_sales_tolerance_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Price Type : </Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="Fixed"
                            type="radio"
                            value="F" checked={is_priceType === 'Fixed'} onClick={() => { setIsPriceType('Fixed'); }}
                            name="pPriceType"
                            defaultChecked
                          />
                        </div>
                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="Moving Average"
                            value="M" checked={is_priceType === 'Moving Average'} onClick={() => { setIsPriceType('Moving Average'); }}
                            type="radio"
                            name="pPriceType"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">ABC Analysis : </Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="A"
                            type="radio"
                            value="A" checked={is_abcAnalysis === 'A'} onClick={() => { setIsABCanalysis('A'); }}
                            name="pABCAnalysis"
                            defaultChecked
                          />
                        </div>
                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="B"
                            value="B" checked={is_abcAnalysis === 'B'} onClick={() => { setIsABCanalysis('B'); }}
                            type="radio"
                            name="pABCAnalysis"
                          />
                        </div>

                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="C"
                            value="C" checked={is_abcAnalysis === 'C'} onClick={() => { setIsABCanalysis('C'); }}
                            type="radio"
                            name="pABCAnalysis"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">QA Required : </Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="Yes"
                            type="radio"
                            value="true" checked={is_qaRequired === true} onClick={() => { setIsQARequired(true); }}
                            name="isQaRequired"
                            defaultChecked
                          />
                        </div>
                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="No"
                            value="false" checked={is_qaRequired === false} onClick={() => { setIsQARequired(false); }}
                            type="radio"
                            name="isQaRequired"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Test Certificate Required : </Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="Yes"
                            type="radio"
                            value="true" checked={is_testCertified === true} onClick={() => { setIsTestCertified(true); }}
                            name="isTestCertRequired"
                            defaultChecked
                          />
                        </div>
                        <div className="sCheck">
                          <Form.Check
                            className="erp_radio_button"
                            label="No"
                            value="false" checked={is_testCertified === false} onClick={() => { setIsTestCertified(false); }}
                            type="radio"
                            name="isTestCertRequired"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </form>

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />

      {/* Supplier Mapping Accordian */}
      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}> */}

      {/* changes by tushar */}
      <Accordion defaultActiveKey="0" onSelect={handleAccordionSelect}>
        <Accordion.Item eventKey="SupplierMapping">
          <Accordion.Header className="erp-form-label-md">Supplier Mapping</Accordion.Header>
          <Accordion.Body>
            <div class="col pt-sm-1">
              <input type='checkbox' name="checkAllSupplier" id="checkAllSupplier"
                onClick={(e) => toggleQuotationTermsChkBoxes('checkAllSupplier')} /> <label class="erp-form-label pb-1"> Select All</label>
            </div>
            <div className=" row erp_table_scroll">
              <DatatableWithId data={dataForSuppEntry} columns={columnsForSuppEntry} />
            </div>

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />

      {/* Customer Mapping Accordian */}
      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}>*/}
      {/* changes by tushar */}
      <Accordion defaultActiveKey="0" onSelect={handleAccordionSelect}>
        <Accordion.Item eventKey="CustomerMapping">
          <Accordion.Header className="erp-form-label-md">Customer Mapping</Accordion.Header>
          <Accordion.Body>
            <div class="col pt-sm-1">
              <input type='checkbox' name="checkAllCustomer" id="checkAllCustomer"
                onClick={(e) => toggleQuotationTermsChkBoxes('checkAllCustomer')}
              /> <label class="erp-form-label pb-1"> Select All</label>
            </div>
            <div className="erp_table_scroll">
              <DatatableWithId data={dataForCust} columns={columnsForCust} />
            </div>

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />

      {/* Process Mapping Accordian */}
      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}> */}
      {/* changes by tushar */}
      <Accordion defaultActiveKey="0" onSelect={handleAccordionSelect}>
        <Accordion.Item eventKey="ProcessMapping">
          <Accordion.Header className="erp-form-label-md">Process Mapping</Accordion.Header>
          <Accordion.Body>
            <div class="col pt-sm-1">
              <input type='checkbox' name="checkAllProcess" id="checkAllProcess"
                onClick={(e) => toggleQuotationTermsChkBoxes('checkAllProcess')}
              /> <label class="erp-form-label pb-1"> Select All</label>
            </div>
            <div className="row">
              <div className="erp_table_scroll">
                <thead className="erp_table_head"></thead>
                <DatatableWithId data={dataForProcess} columns={columnsForProcess} />

              </div>
            </div>

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />

      {/* QA Mapping Accordian */}
      {/* <Accordion defaultActiveKey="0" >
        <Accordion.Item eventKey={0}> */}
      {/* changes by tushar */}
      <Accordion defaultActiveKey="0" onSelect={handleAccordionSelect}>
        <Accordion.Item eventKey="QAMapping">
          <Accordion.Header className="erp-form-label-md">QA Mapping</Accordion.Header>
          <Accordion.Body>
            <div class="col pt-sm-1">
              <input type='checkbox' name="checkAllQa" id="checkAllQa"
                onClick={(e) => toggleQuotationTermsChkBoxes('checkAllQa')}
              /> <label class="erp-form-label pb-1"> Check All QA Mapping</label>
            </div>
            <div className="row">
              <div className="erp_table_scroll">
                <DatatableWithId data={dataQA} columns={columnsQA} />
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />
      <div className="text-center">
        <MDButton type="button" className="erp-gb-button"
          onClick={() => { const path = compType === 'Register' ? '/Masters/FinishGoodsListing/reg' : '/Masters/FinishGoodsListing'; navigate(path); }}
          variant="button" fontWeight="regular">Back</MDButton>
        <MDButton type="button" onClick={() => FnAddFinishGood('allFGData')} className={`erp-gb-button ms-2`} variant="button" id="btn_save"
          fontWeight="regular">save</MDButton>
        <Tooltip title={productId === 0 ? "Upload disabled" : ""} placement="top">
          <MDButton type="button" id="upload_btn_id" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} variant="button" onClick={() => viewDocumentForm()}
            fontWeight="regular" disabled={productFGId === 0} >Upload Document</MDButton>
        </Tooltip>

        <MDButton type="button" id='btn_next' onClick={() => forwardToNxtPage()} className="ms-2 erp-gb-button" variant="button" fontWeight="regular">next</MDButton>
        {/* //changes by tushar */}
        <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => deleteshow()}>Delete</MDButton>
      </div>

      <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
      <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

      {/* Document modal */}
      {showDocumentForm ?
        <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
          <Modal.Header>
            <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
          </Modal.Header>
          <Modal.Body>
            <DocumentF group_id={product_fg_id} document_group={docGroup} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal> : null
      }


      {/* Add new Record Popup */}
      {
        showAddRecModal ?
          <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
            <Modal.Header>
              <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
              <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
            </Modal.Header>
            <Modal.Body className='erp_city_modal_body'>
              {displayRecordComponent()}
            </Modal.Body>
            <Modal.Footer>
              <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
                fontWeight="regular">Close</MDButton>
            </Modal.Footer>
          </Modal > : null
      }


      {/* Delete Modal */}
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
        <Modal.Body className='erp_modal_body'>
          <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
          <h6>Are you sure?</h6>
          <div className="erp-form-label">Do you wish to delete this record ?</div>
        </Modal.Body>
        <Modal.Footer className='justify-content-center'>
          <Button variant="success" className='erp-gb-button' onClick={handleClose}>
            Cancel
          </Button>&nbsp;
          <Button variant="danger" className='erp-gb-button' onClick={DeleteRecord}>Delete</Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}
