import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Modal from 'react-bootstrap/Modal';
import Tooltip from "@mui/material/Tooltip";

// Imports React bootstra
import Form from 'react-bootstrap/Form';

// Icons
import { MdRefresh } from "react-icons/md";

// File Imports
import ComboBox from "Features/ComboBox";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import MCostCenterEntry from "Masters/MFMCostCenter/MCostCenterEntry/Index";
import ConfigConstants from "assets/Constants/config-constant";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmValidations from "FrmGeneric/FrmValidations";
import FrmProductionSectionEntry from "Masters/MProductionSection/FrmProductionSectionEntry";
import DepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";


function FrmProductionSubSectionEntry(props) {
    //changes by ujjwala on 27/12/2024
    // Call ConfigConstants to get the configuration constants
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName } = configConstants;
    const { state } = useLocation();
    const { productionSubsectionId = 0, keyForViewUpdate, compType } = state || {}

    // Use Refs to call external functions
    const validateNumberDateInput = useRef();
    const combobox = useRef();
    const validate = useRef();
    const navigate = useNavigate()


    const [txt_production_sub_section_id, setproduction_sub_section_id] = useState(productionSubsectionId);
    const [txt_sub_production_section_id, set_sub_production_section_id] = useState('');
    const [txt_sub_prod_parentDepartment, set_sub_prod_parentDepartment] = useState('');
    const [cmb_sub_prod_plant_id, set_sub_prod_PlantName] = useState('');
    const [txt_SubSectionName, setSubSectionName] = useState('');
    const [txt_sub_section_short_type, setSubSectionShortType] = useState('');
    const [txt_sub_prod_shortName, set_sub_prod_ShortName] = useState();
    const [txt_sub_prod_costCenter, set_sub_prod_CostCenter] = useState('');
    const [txt_sub_prodSectionHeadID, setsubSectionHeadID] = useState();
    const [txt_sub_prodSectionSubHeadID, setsubSectionSubHeadID] = useState();
    const [txt_sub_prodSectionStaffStrength, setSubProdSectionStaffStrength] = useState(1);
    const [txt_sub_prodSection_std_worker_strength, setSubProdSectionWorkerStrength] = useState(1);
    // const [txt_SectionName, setSectionName] = useState('');
    const [txt_SectionShortName, setSectionShortName] = useState('');
    const [chk_isactive, setIsActive] = useState(true);
    const [actionType, setActionType] = useState('')
    const [actionLabel, setActionLabel] = useState('Save')

    //option Box

    const [sectionOption, setSectionOption] = useState([]);

    const [subProd_parentDepartOption, setsubProdParentDepartOption] = useState([]);
    const [subProd_branchHeadOptions, setsubProdBranchHeadOptions] = useState([]);
    const [sectionSubHeadOptions, setsubProdsectionSubHeadOptions] = useState([]);
    const [subProd_costCenterOption, setsubProdCostCenterOption] = useState([]);
    const [subProd_plantOptions, setsubProdPlantOptions] = useState([]);

    // to add new records in combo box 
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        debugger
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Masters/MProductionSubSection/FrmProductionSubSectionListing`)
        }
    }

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    useEffect(() => {
        const loadDataOnload = async () => {
            await ActionType()
            await FillComobos();
            if (productionSubsectionId !== 0) {
                await FnCheckUpdateResponce()
            }
        }
        loadDataOnload()
    }, [])

    const addProductionSubSection = async () => {
        debugger
        try {
            const checkIsValidate = await validate.current.validateForm("productionSubSectionformid");
            if (checkIsValidate === true) {
                let sectionname = $('#txt_sub_production_section_id').find(":selected").text();
                const data = {

                    company_branch_id: COMPANY_BRANCH_ID,
                    company_id: COMPANY_ID,
                    production_sub_section_id: txt_production_sub_section_id,
                    production_section_id: txt_sub_production_section_id,
                    parent_department_id: txt_sub_prod_parentDepartment,
                    plant_id: cmb_sub_prod_plant_id,
                    plant_name: $('#mySelect option:selected').text(),
                    production_sub_section_name: txt_SubSectionName,
                    production_sub_section_short_type: txt_sub_section_short_type,
                    production_sub_section_short_name: txt_sub_prod_shortName,
                    production_section_name: sectionname,
                    production_section_short_name: txt_SectionShortName,
                    cost_center_id: txt_sub_prod_costCenter,
                    production_section_head_id: txt_sub_prodSectionHeadID,
                    production_section_subhead_id: txt_sub_prodSectionSubHeadID,
                    production_section_std_staff_strength: txt_sub_prodSectionStaffStrength,
                    production_section_std_worker_strength: txt_sub_prodSection_std_worker_strength,
                    is_active: chk_isactive,
                    created_by: UserName,
                    modified_by: txt_production_sub_section_id === 0 ? null : UserName,
                };
                console.log(data);
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmProductionSection/FnAddUpdateProdSubSectionRecord`, requestOptions)
                const responce = await apicall.json()
                if (responce.error !== "") {
                    console.log("response error: ", responce.error);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = combobox.current.evitCache();
                    console.log(evitCache);
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                }
            }
        } catch (error) {
            console.log("error: ", error)
        }
    };

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                $('#txt_SubSectionName').attr('disabled', true)
                $('#txt_sub_prod_shortName').attr('disabled', true)
                break;
            case 'view':
                setActionType('(View)');
                await validate.current.readOnly("productionSubSectionformid");
                $("input[type=radio]").attr('disabled', true);
                break;
            default:
                setActionType('(Creation)');
                break;
        }
    };

    const validateFields = () => {
        validate.current.validateFieldsOnChange('productionSubSectionformid')
    }

    const FnCheckUpdateResponce = async () => {
        debugger
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XmProductionSection/FnShowParticularProdSubSectionRecordForUpdate/${txt_production_sub_section_id}/${COMPANY_ID}`)
            const updateRes = await apiCall.json();
            const data = updateRes.productionSubSectionData
            console.log(data);

            setproduction_sub_section_id(data.production_sub_section_id)
            set_sub_production_section_id(data.production_section_id)
            await addRecordInProperty('Section')
            set_sub_prod_parentDepartment(data.parent_department_id)
            set_sub_prod_PlantName(data.plant_id);
            setSubSectionName(data.production_sub_section_name)
            setSubSectionShortType(data.production_sub_section_short_type)
            set_sub_prod_ShortName(data.production_sub_section_short_name)
            set_sub_prod_CostCenter(data.cost_center_id)
            setsubSectionHeadID(data.production_section_head_id)
            setsubSectionSubHeadID(data.production_section_subhead_id)
            setSubProdSectionStaffStrength(data.production_section_std_staff_strength)
            setSubProdSectionWorkerStrength(data.production_section_std_worker_strength)

            // setproduction_sub_section_id(data.txt_production_sub_section_id)
            // set_sub_prod_parentDepartment(data.parent_department_id)
            // setSubSectionName(data.production_section_name);
            // set_sub_prod_ShortName(data.production_section_short_name);
            // set_sub_prod_CostCenter(data.cost_center_id);
            // setsubSectionHeadID(data.production_section_head_id);
            // setsubSectionSubHeadID(data.production_section_subhead_id);
            // setSubProdSectionStaffStrength(data.production_section_std_staff_strength);
            // setSubProdSectionWorkerStrength(data.production_section_std_worker_strength);

            setIsActive(data.is_active);
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const FillComobos = async () => {
        if (combobox.current) {

            combobox.current.fillMasterData("cmv_department", "parent_department", "Production Department").then((departmentList) => {
                setsubProdParentDepartOption(departmentList)
            })

            combobox.current.fillMasterData("cmv_employee", "", "").then((employeeList) => {
                setsubProdBranchHeadOptions(employeeList)
                setsubProdsectionSubHeadOptions(employeeList)
            })

            combobox.current.fillMasterData("fmv_cost_center", "", "").then((costCenterList) => {
                setsubProdCostCenterOption(costCenterList)
            })

            combobox.current.fillMasterData("xmv_production_section", "", "").then((Sectionlist) => {
                setSectionOption(Sectionlist)
            })

            combobox.current.fillMasterData("cmv_plant", "", "").then((getProdPlantApiCall) => {
                setsubProdPlantOptions(getProdPlantApiCall);
            })
        }
    }

    const addRecordInProperty = async (key) => {
        debugger
        switch (key) {

            case 'Section':
                const Sectionval = document.getElementById('txt_sub_production_section_id').value;
                set_sub_production_section_id(Sectionval)
                $('error_txt_production_section_id').hide();
                if (Sectionval === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Production Section')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");
                    }, 100)
                } else if (Sectionval === '') {

                } else {
                    resetGlobalQuery();
                    globalQuery.columns.push("production_section_name");
                    globalQuery.columns.push("production_section_short_name");
                    globalQuery.columns.push("field_name");
                    globalQuery.columns.push("field_id");
                    globalQuery.table = "xmv_production_section";
                    globalQuery.conditions.push({ field: "production_section_id", operator: "=", value: Sectionval, });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                    const SectionApiCall = await combobox.current.removeCatcheFillCombo(globalQuery);
                    // setSectionName(SectionApiCall[0].production_section_name)
                    setSectionShortName(SectionApiCall[0].production_section_short_name)

                    // setExpectedBranchOptions(SectionApiCall);   
                    console.log(SectionApiCall);

                }

                break;

            case 'department':
                const departlist = document.getElementById('txt_sub_prod_parentDepartment').value
                set_sub_prod_parentDepartment(departlist)
                $('#error_txt_sub_prod_parentDepartment').hide();
                if (departlist === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Department')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");
                    }, 100)
                }
                break;

            case 'SectionHead':
                const SectionHeadval = document.getElementById('txt_sub_prodSectionHeadID').value;
                setsubSectionHeadID(SectionHeadval)
                $('#error_txt_sub_prodSectionHeadID').hide();
                if (SectionHeadval === '0') {
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                break;

            case 'SectionSubHead':
                const SectionSubHeadval = document.getElementById('txt_sub_prodSectionSubHeadID').value;
                setsubSectionSubHeadID(SectionSubHeadval)
                $('#error_txt_sub_prodSectionSubHeadID').hide();
                if (SectionSubHeadval === '0') {
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                break;
            case 'cost_center':
                const costcenter = document.getElementById('txt_sub_prod_costCenter').value;
                set_sub_prod_CostCenter(costcenter)
                $('error_costcenterId').hide();
                if (costcenter === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Cost Center')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(1).css("padding-top", "0px");
                    }, 100)
                }
                break;
            default: break;
        }
    }


    const handleCloseRecModal = async () => {
        switch (modalHeaderName) {
            case 'Cost Center':
                combobox.current.fillMasterData("fmv_cost_center", "", "").then((costcenterlist) => {
                    setsubProdCostCenterOption(costcenterlist)
                })
                break;

            case 'Department':
                combobox.current.fillMasterData("cmv_department", "", "").then((departmentList) => {
                    setsubProdParentDepartOption(departmentList)
                })
                break;

            default:
                break;
        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 100)
    }


    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'Cost Center':
                return <MCostCenterEntry btn_disabled={true} />;

            case 'Production Section':
                return <FrmProductionSectionEntry btn_disabled={true} />;

            case 'Department':
                return <DepartmentEntry btn_disabled={true} />;

            default:
                return null;
        }
    }


    const FnRefreshbtn = async (key) => {
        switch (key) {
            case 'SectionHead':
                combobox.current.fillMasterData("cmv_employee", "", "").then((empList1) => {
                    setsubProdBranchHeadOptions(empList1)
                })
                break;

            case 'SectionSubHead':
                combobox.current.fillMasterData("cmv_employee", "", "").then((empList) => {
                    setsubProdsectionSubHeadOptions(empList)
                })
                break;

            default:
                break;
        }
    }


    return (
        <>
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <ComboBox ref={combobox} />
            <FrmValidations ref={validate} />
            <div className="erp_top_Form">
                <div className='card p-1'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Production Sub Section {actionType} </label>
                    </div>
                    <form id="productionSubSectionformid">
                        <div className="row erp_transporter_div">
                            <div className="col-sm-6 erp_form_col_div">

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Production Section<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select size="sm" id="txt_sub_production_section_id" value={txt_sub_production_section_id} className="form-select form-select-sm erp_input_field" onChange={() => addRecordInProperty('Section')} disabled={keyForViewUpdate === 'view'}>
                                            <option value="">Select</option>
                                            <option value="0" disabled={props.btn_disabled}>Add New Record+</option>
                                            {sectionOption?.map(parentDepart => (
                                                <option value={parentDepart.field_id}>{parentDepart.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_txt_production_section_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Parent Department <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select size="sm" id="txt_sub_prod_parentDepartment" value={txt_sub_prod_parentDepartment} className="form-select form-select-sm erp_input_field" onChange={() => addRecordInProperty('department')} disabled={keyForViewUpdate === 'view'}>
                                            <option value="">Select</option>
                                            <option value="0" disabled={props.btn_disabled}>Add New Record+</option>

                                            {subProd_parentDepartOption?.map(parentDepart => (
                                                <option value={parentDepart.field_id}>{parentDepart.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_txt_sub_prod_parentDepartment" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Plant Name <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <select id="cmb_sub_prod_plant_id" className="form-select form-select-sm" value={cmb_sub_prod_plant_id} onChange={(e) => { set_sub_prod_PlantName(e.target.value); }}>
                                            <option value="" disabled>Select</option>
                                            {subProd_plantOptions.length !== 0 ? (
                                                <>{subProd_plantOptions?.map(plant => (
                                                    <option value={plant.field_id}>{plant.field_name}</option>
                                                ))} </>
                                            ) : null
                                            }
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_sub_prod_plant_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>


                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Sub Section Name <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" className="erp_input_field" id="txt_SubSectionName" value={txt_SubSectionName} onChange={e => { setSubSectionName(e.target.value); validateFields() }} maxLength="255" />
                                        <MDTypography variant="button" id="error_txt_SubSectionName" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Sub Section Short Type</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_sub_section_short_type" className="erp_input_field" value={txt_sub_section_short_type} onChange={(e) => { setSubSectionShortType(e.target.value.toUpperCase()); validateFields() }} maxLength="3" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_sub_section_short_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Sub Section Short Name </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_sub_prod_shortName" className="erp_input_field" value={txt_sub_prod_shortName} onChange={(e) => { set_sub_prod_ShortName(e.target.value.toUpperCase()); validateFields() }} maxLength="255" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_sub_prod_shortName" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                                {/* <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Section Name <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" className="erp_input_field" id=" txt_SectionName" value={txt_SectionName} onChange={e => { setSectionName(e.target.value); validateFields() }} maxLength="255" />
                                        <MDTypography variant="button" id="error_txt_SectionName" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div> */}

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Section Short Name </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_SectionShortName" className="erp_input_field" value={txt_SectionShortName} onChange={(e) => { setSectionShortName(e.target.value.toUpperCase()); validateFields() }} maxLength="255" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_SectionShortName" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>


                            </div>

                            {/* second column */}
                            <div className="col-sm-6 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Cost Center <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col">
                                        <select size="sm" id="txt_sub_prod_costCenter" value={txt_sub_prod_costCenter} className="form-select form-select-sm erp_input_field" onChange={() => { addRecordInProperty('cost_center'); }} >
                                            <option value="">Select</option>
                                            <option value="0" disabled={props.btn_disabled}>Add New Record+</option>
                                            {subProd_costCenterOption?.map(option => (
                                                <option value={option.field_id}>{option.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_txt_sub_prod_costCenter" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4 col-12">
                                        <Form.Label className="erp-form-label">Section Head <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-7 col-10">
                                        <select id="txt_sub_prodSectionHeadID" value={txt_sub_prodSectionHeadID} className="form-select form-select-sm erp_input_field" onChange={() => { addRecordInProperty("SectionHead"); }}>
                                            <option value="">Select</option>
                                            <option value="0" disabled={props.btn_disabled}>Add New Record+</option>

                                            {subProd_branchHeadOptions?.map(option => (
                                                <option value={option.field_id}>{option.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_txt_sub_prodSectionHeadID" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography>
                                                <MdRefresh className={`${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} onClick={() => { FnRefreshbtn("SectionHead"); }} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4 col-12">
                                        <Form.Label className="erp-form-label">Section SubHead</Form.Label>
                                    </div>
                                    <div className="col-sm-7 col-10">
                                        <select id="txt_sub_prodSectionSubHeadID" value={txt_sub_prodSectionSubHeadID} className="form-select form-select-sm erp_input_field" onChange={() => { addRecordInProperty("SectionSubHead"); }} optional="optional" >
                                            <option value="">Select</option>
                                            <option value="0" disabled={props.btn_disabled}>Add New Record+</option>
                                            {sectionSubHeadOptions?.map(option => (
                                                <option value={option.field_id}>{option.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_txt_sub_prodSectionSubHeadID" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                    <div className="col-sm-1 col-2">
                                        <Tooltip title="Refresh" placement="top">
                                            <MDTypography>
                                                <MdRefresh className={`${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} onClick={() => { FnRefreshbtn("SectionSubHead"); }} style={{ color: 'black' }} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Staff Strength <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_sub_prodSectionStaffStrength" className="erp_input_field erp_align-right" value={txt_sub_prodSectionStaffStrength} onChange={e => { if (validateNumberDateInput.current.isInteger(e.target.value)) { setSubProdSectionStaffStrength(e.target.value) } }} maxLength="11" />
                                        <MDTypography variant="button" id="error_txt_sub_prodSectionStaffStrength" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label"> Staff Wroker </Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="text" id="txt_sub_prodSection_std_worker_strength" className="erp_input_field erp_align-right" value={txt_sub_prodSection_std_worker_strength} onChange={e => { if (validateNumberDateInput.current.isInteger(e.target.value)) { setSubProdSectionWorkerStrength(e.target.value) } }} maxLength="11" />
                                        <MDTypography variant="button" id="error_txt_sub_prodSection_std_worker_strength" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Is Active</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" name="chk_isactive" checked={chk_isactive} onClick={() => { setIsActive(true); }} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" name="chk_isactive" checked={!chk_isactive} onClick={() => { setIsActive(false); }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="card-footer py-0 text-center">
                        <MDButton type="button" className="erp-gb-button"

                            onClick={() => {
                                const path = compType === 'Register' ? '/Masters/MProductionSubSection/FrmProductionSubSectionListing/reg' : '/Masters/MProductionSubSection/FrmProductionSubSectionListing';
                                navigate(path);
                            }}

                            variant="button"
                            fontWeight="regular" disabled={props.btn_disabled ? true : false}>Back</MDButton>
                        <MDButton type="submit" onClick={addProductionSubSection} className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                            fontWeight="regular">{actionLabel}</MDButton>
                    </div >
                </div>


                {/* Success Msg Popup */}
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                {/* Error Msg Popup */}
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

                <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        <div className='row'>
                            <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></div>
                        </div>
                        {displayRecordComponent()}

                    </Modal.Body>
                </Modal >
            </div>
        </>
    )
}

export default FrmProductionSubSectionEntry;
