import React, { useState, useRef, useEffect, useLayoutEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Accordion, Button, Modal, Table } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { MdDelete, MdRefresh } from "react-icons/md";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";
import ConfigConstants from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import FrmCity from "FrmGeneric/MCity/FrmCity";
import ExcelExport from "Features/Exports/ExcelExport";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import Document from "Features/Document";
import TaxSummary from "./TaxSummary";
import ProductListProductTypeWise from "FrmGeneric/ProductListProductTypeWise";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import { CircularProgress } from "@material-ui/core";
import { RxCrossCircled } from "react-icons/rx";
import { FiDownload } from "react-icons/fi";

function FrmSalesInvoiceServiceEntry() {
    const { state } = useLocation()
    let { idList, keyForViewUpdate, compType, deletionKey = false } = state || {};

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME, UserId } = configConstants;
    const [isLoading, setIsLoading] = useState(false);

    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    // UseRefs
    const validate = useRef();
    const comboDataFunc = useRef();
    const exlsExp = useRef();
    const generateAutoNoAPiCall = useRef();
    const validateNumberDateInput = useRef();
    const importFile = useRef(null)
    const navigate = useNavigate();

    // Hooks for handle the accordions.
    const [openCustContAccord, setOpenCustContAccord] = useState(false);
    const [openDetailsAccord, setOpenDetailsAccord] = useState(true);
    const [openActivitiesAccord, setOpenActivitiesAccord] = useState(true);

    const [isApprove, setIsApprove] = useState(false);
    const [actionLabel, setActionLabel] = useState('Save')
    const [actionType, setActionType] = useState('')
    let documentGroup = "Service Sales Invoice"
    var expanded = false;

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'approve':
                setActionType('(Approval)');
                setActionLabel('Approve')
                setIsApprove(true)
                await validate.current.readOnly("SalesInvoiceHeaderFormId");
                await validate.current.readOnly("SalesInvoiceFooterFormId");
                $('#cmb_sales_invoice_status').attr('disabled', false)
                $('#txt_sales_invoice_remark').attr('disabled', false)
                $('#txt_remark').removeAttr('readOnly')
                break;
            case 'update':
                setActionType('(Modification)');
                setActionLabel('Update')
                break;
            // case 'delete':
            case 'view':
                if (deletionKey === true) {
                    setActionType('(Deletion)');
                    setActionLabel('Delete');
                } else {
                    let key = keyForViewUpdate
                    setActionType(`(${key.replace(/[-_](.)/g, (_, char) => char.toUpperCase())
                        .replace(/[-_]/g, '')  // Remove any remaining dashes or underscores
                        .replace(/^(.)/, (_, char) => char.toUpperCase()) // Capitalize the first character
                        })`);
                    setActionLabel(`${keyForViewUpdate} `)
                }

                keyForViewUpdate = 'view'
                await validate.current.readOnly("SalesInvoiceHeaderFormId");
                await validate.current.readOnly("SalesInvoiceFooterFormId");
                break;
            default:
                setActionType('(Create)');
                break;
        }
    };

    //Header hooks
    const financial_year = idList !== null ? idList.financial_year : '';
    const [sales_invoice_master_transaction_id, setSalesInvoiceMasterTransactionId] = useState(idList !== null ? idList.sales_invoice_master_transaction_id : 0)
    const [cmb_sales_invoice_type_id, setSalesInvoiceTypeId] = useState('');
    const [rb_sales_invoice_creation_type, setSalesInvoiceCreationType] = useState('O');
    const [txt_sales_invoice_no, setSalesInvoiceNo] = useState(idList !== null ? idList.sales_invoice_no : '');
    const [txt_sales_invoice_version, setSalesInvoiceVersion] = useState(idList !== null ? idList.sales_invoice_version : 1);
    const [dt_sales_invoice_date, setSalesInvoiceDate] = useState(today());
    const [cmb_customer_id, setCustomerId] = useState('');
    const [cmb_customer_state_id, setCustomerState] = useState('');
    const [cmb_customer_city_id, setCustomerCity] = useState('');
    const [txt_customer_gst_no, setCustomerGstNo] = useState('');
    const [txt_customer_pan_no, setCustomerPanNo] = useState('');
    const [cmb_consignee_id, setConsigneeId] = useState('');
    const [cmb_consignee_state_id, setConsigneeState] = useState('');
    const [cmb_consignee_city_id, setConsigneeCity] = useState('');
    const [txt_consignee_gst_no, setConsigneeGstNo] = useState('');
    const [txt_consignee_pan_no, setConsigneePanNo] = useState('');
    const [cmb_transporter_id, setTransporterId] = useState('');
    const [cmb_transporter_state_id, setTransporterState] = useState('');
    const [txt_transporter_gst_no, setTransporterGstNo] = useState('');
    const [cmb_expected_branch_id, setExpectedBranchId] = useState(COMPANY_BRANCH_ID);
    const [cmb_expected_branch_state_id, setExpectedBranchStateId] = useState('');
    const [cmb_expected_branch_city_id, setExpectedBranchCityId] = useState('');
    const [txt_vehicle_no, setVehicleNo] = useState('');
    const [txt_vehicle_type, setVehicleType] = useState('');
    const [txt_transporter_challan_no, setTransporterChallanNo] = useState('');
    const [dt_transporter_challan_Date, setTransporterChallanDate] = useState('');
    const [cmb_transport_mode, setTransporterMode] = useState('');
    const [cmb_transaction_type, setTransactionType] = useState('1');
    const [is_service, setIsService] = useState('Y')
    const [is_sez, setIsSez] = useState('N')
    const [interim, setInterim] = useState('Y')
    const [dt_loading_time, setLoadingTime] = useState(() => {
        const now = new Date();
        const formattedDate = now.toISOString().slice(0, 16);
        return formattedDate;
    });
    const [cmb_approved_by_id, setApprovedById] = useState();
    const [dt_approved_date, setApprovedDate] = useState(today);
    const [cmb_sales_invoice_status, setSalesInvoiceStatus] = useState('I');
    const [dt_payment_schedule_date, setPaymentScheduleDate] = useState(today);

    //footer hooks
    const [txt_basic_total, setBasicTotal] = useState(0);
    const [txt_transport_amount, setTransportAmount] = useState(0);
    const [txt_freight_amount, setFreightAmount] = useState(0);
    const [cmb_freight_hsn_id, setFreightHSNId] = useState('')
    const [chk_freight_isTaxable, setFreightIsTaxable] = useState(false);
    const [txt_packing_amount, setPackingAmount] = useState(0);
    const [txt_discount_percent, setDiscountPercent] = useState(0);
    const [txt_discount_amount, setDiscountAmount] = useState(0);
    const [txt_other_amount, setOtherAmount] = useState(0);
    const [txt_taxable_total, setTaxableTotal] = useState(0);
    const [txt_cgst_total, setCgstTotal] = useState(0);
    const [txt_sgst_total, setSgstTotal] = useState(0);
    const [txt_igst_total, setIgstTotal] = useState(0);
    const [txt_roundOff, setRoundOff] = useState(0);
    const [txt_grand_total, setGrandTotal] = useState(0);
    const [cmb_agent_id, setAgentId] = useState()
    const [txt_agent_amount, setAgentAmount] = useState(0);
    const [txt_agent_percent, setAgentPercent] = useState(0);
    const [cmb_agent_paid_status, setAgentPaidStatus] = useState('P');
    const [txt_other_terms_conditions, setOtherTermsConditions] = useState('');
    const [txt_sales_invoice_remark, setSalesInvoiceRemark] = useState('');
    const [txt_remark, setRemark] = useState();
    const [is_active, setIsActive] = useState(true);
    const [rb_e_transaction_type, setETransactionType] = useState('INV Data')
    const [txt_tcs_amount, setTcsAmount] = useState('')
    const [txt_cess_amount, setCessAmount] = useState('')
    const [txt_stcess_amount, setStcessAmount] = useState('')
    const [txt_cess_advance_amount, setCessAdvanceAmount] = useState('')
    const [checkedCustomerOrderNoLength, setcheckedCustomerOrderNoLength] = useState(0)

    //combo options
    const [stateList, setStateList] = useState([])
    const [customerOrderNoList, setCustomerOrderNosList] = useState([])
    const [customerList, setCustomerList] = useState([])
    const [customerCityList, setCustomerCityList] = useState([])
    const [consigneeList, setConsigneeList] = useState([])
    const [consigneeCityList, setConsigneeCityList] = useState([])
    const [transporterList, setTransporterList] = useState([])
    const [expectedBranchList, setExpectedBranchList] = useState([])
    const [expectedBranchCityList, setExpectedBranchCityList] = useState([])
    const [salesInvoiceTypeList, setSalesInvoiceTypeList] = useState([])
    const [employeeList, setEmployeeList] = useState([]);
    const [freightHSNList, setfreightHSNList] = useState([]);
    const [agentsList, setAgentsList] = useState([]);
    const [unitList, setUnitList] = useState([]);

    // Customers contact details table data hooks
    const [custContactDetails, setCustContactDetails] = useState([]);

    // Use the replace method to replace "/" with "_" its to upload document
    let group_id = txt_sales_invoice_no.replace(/\//g, "_");

    // Confirmation Modal fields
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [message, setMessage] = useState('')
    const [messageForConfirmation, setMessageForConfirmation] = useState('')
    const closeConfirmationModal = () => {
        setShowConfirmationModal(false);
        navigate(`/Transactions/SalesInvoiceServicesListing`)
    }

    // Succ Msg Modal
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        navigate(`/Transactions/SalesInvoiceServicesListing`)
    }
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    // const [succMsg, setSuccMsg] = useState("");
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    //Delete Popup modal.
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const FnCloseDeleteModal = () => setShowDeleteModal(false);

    // Add new Record functionality of combos
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')
    const handleCloseRecModal = async () => {
        switch (modalHeaderName) {
            case 'Customer City':
                FnComboOnChange('CustomerState');
                break;
            case 'Consignee City':
                FnComboOnChange('ConsigneeState');
                break;
            case 'Sales Invoice Type':
                resetGlobalQuery();
                globalQuery.columns = ["field_id", "field_name", "product_type_short_name"]
                globalQuery.table = "smv_product_type"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                const product_Types = await comboDataFunc.current.fillFiltersCombo(globalQuery)
                setSalesInvoiceTypeList(product_Types)
                break;
            default:
                break;

        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)

    }

    // Document Form
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const handleCloseDocumentForm = () => setShowDocumentForm(false);

    // podetails Table Data
    const [salesInvoiceDetailData, setSalesInvoiceDetailData] = useState([])
    const [salesInvoiceActivitiesData, setSalesInvoiceActivitiesData] = useState([])
    const storedSalesInvoiceDetailData = []

    // Taxation Table Data Hooks
    const [salesInvoiceTaxSummaryData, setSalesInvoiceTaxSummaryData] = useState([])

    // Print Header data states
    const [invoiceMastersDetails, setInvoiceMasterDetails] = useState({})

    // Column Names Declatration for Manual Sales Invoice & Modal fields
    const colNames = rb_sales_invoice_creation_type === 'M'
        ? [
            'product_name',
            'product_tech_spect',
            'product_std_weight',
            'product_unit_name',
            'product_lead_time',
            "stock_quantity",
            "stock_weight",
            'invoice_quantity',
            'invoice_weight',
            'product_landed_price',
            'product_mrp',
            'product_category1_name',
            'product_category2_name',
            'product_category3_name',
            'product_category4_name',
            'product_category5_name',
            'product_packing_name',
            'product_hsn_sac_code',
            'product_hsn_sac_rate',
            'product_material_type_name',
            'product_material_grade_name',
            'product_make_name',
            'product_id',
            'product_unit_id',
            'product_packing_id',
            'product_hsn_sac_code_id',
            "item_rate",
            "item_amount",
            "item_discount_percent",
            "item_discount_amount",
            "item_taxable_amount",
            "item_cgst_percent",
            "item_cgst_amount",
            "item_sgst_percent",
            "item_sgst_amount",
            "item_igst_percent",
            "item_igst_amount",
            "item_total_amount"
        ] : null

    const [showBomFilterForm, setShowBomFilterForm] = useState(false);
    const viewBomFilterForm = async () => {
        if (cmb_sales_invoice_type_id === '0' || cmb_sales_invoice_type_id === '') {
            $('#error_cmb_sales_invoice_type_id').text('Please select schedule type!..')
            $("#cmb_sales_invoice_type_id").focus();
            $('#error_cmb_sales_invoice_type_id').show()
            return;
        }
        sessionStorage.setItem('requiredCols', JSON.stringify(colNames));
        setShowBomFilterForm(true);
    }

    const FnCloseProductList = () => {
        setShowBomFilterForm(false)
        const getData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];
        if (getData !== null && getData.length !== 0) {
            setSalesInvoiceDetailData((prevArray) => {

                // Filter prevArray to keep only items that are also in getData.
                const filteredArray = prevArray.filter(prevItem => {
                    return getData.some(item => item.product_id === prevItem.product_id);
                });
                // Map the keys to the new names for the items in getData
                const updatedGetData = getData.map(item => ({
                    product_id: item.product_id,
                    product_name: item.product_name,
                    product_std_weight: item.product_std_weight,
                    product_material_print_name: item.product_tech_spect,
                    product_tech_spect: item.product_tech_spect,
                    product_unit_id: item.product_unit_id,
                    product_packing_id: item.product_packing_id,
                    product_packing_name: item.product_packing_name,
                    hsn_sac_code: item.product_hsn_sac_code,
                    hsn_sac_rate: item.product_hsn_sac_rate,
                    item_rate: item.product_mrp,
                    hsn_sac_id: item.product_hsn_sac_code_id,
                    invoice_quantity: 0,
                    invoice_weight: 0,
                    item_rate: 0,
                    item_amount: 0,
                    item_discount_percent: 0,
                    item_discount_amount: 0,
                    item_taxable_amount: 0,
                    item_cgst_percent: 0,
                    item_cgst_amount: 0,
                    item_sgst_percent: 0,
                    item_sgst_amount: 0,
                    item_igst_percent: 0,
                    item_igst_amount: 0,
                    item_total_amount: 0,
                }));

                return [
                    ...filteredArray,
                    ...updatedGetData.filter(item => !prevArray.some(prevItem => prevItem.product_id === item.product_id))
                ];
            });
        }

        sessionStorage.setItem('isComboFilterExist', false)
        // Remove Bom Filter Session
        var LocalArr = JSON.parse(localStorage.getItem(`localStorageKeyArr`))
        console.log('LocalArr: ', LocalArr)
        if (LocalArr !== null) {
            for (let localArrIndex = 0; localArrIndex < LocalArr.length; localArrIndex++) {
                localStorage.removeItem(LocalArr[localArrIndex])
            }
        }
    };

    const generateSalesInvoiceNo = async () => {
        const ptShortName = salesInvoiceTypeList.find((item) => item.field_id === parseInt(document.getElementById("cmb_sales_invoice_type_id").value));
        const sales_invoice_no = await generateAutoNoAPiCall.current.generateTAutoNo("ft_sales_invoice_master_services", "sales_invoice_no", ptShortName.product_type_short_name, 'SI', "6");
        setSalesInvoiceNo(sales_invoice_no);
        return sales_invoice_no;
    }

    useEffect(() => {
        setIsLoading(true);
        const loadDataOnload = async () => {
            await ActionType()
            await FnFillComobos();
            if (idList !== null) {
                await FnCheckUpdateResponce()
            }
            setIsLoading(false);
        }
        sessionStorage.removeItem('filteredMaterialData')
        loadDataOnload()

        // Close customer order no dropdown
        $(document).on('mouseup', function (e) {
            var container = $("#customer-order-ul");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
    }, [])

    const FnFillComobos = async () => {
        try {
            // Product Type List
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "product_type_short_name"]
            globalQuery.table = "smv_product_type";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ['SR'], });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(getProductTypes => {
                    setSalesInvoiceTypeList(getProductTypes);
                });

            // Customer List
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "is_sez", "cust_branch_city_id", "cust_branch_state_id", "cust_branch_gst_no", "cust_branch_pan_no"]
            globalQuery.table = "cmv_customer"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(getCustomers => {
                    setCustomerList(getCustomers);
                });

            // Consignee List
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "is_sez", "cust_branch_city_id", "cust_branch_state_id", "cust_branch_gst_no", "cust_branch_pan_no"]
            globalQuery.table = "cmv_customer"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(getConsignees => {
                    setConsigneeList(getConsignees)
                });


            // Transporter List
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "transporter_gst_no", "transporter_pan_no", "transporter_state_id"]
            globalQuery.table = "cmv_transporter"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(getTransporter => {
                    setTransporterList(getTransporter);
                })

            // State List
            comboDataFunc.current.fillMasterData("cmv_state", "", "")
                .then(getStates => {
                    setStateList(getStates)
                })

            // Expected Branch List
            resetGlobalQuery();
            globalQuery.columns = ["company_branch_id", "company_branch_name", "branch_state_id", "branch_city_id", "state_name", "city_name", 'is_sez']
            globalQuery.table = "cmv_company_branch";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });

            const getExpectedtBranchs = await comboDataFunc.current.fillFiltersCombo(globalQuery)
            setExpectedBranchList(getExpectedtBranchs);
            let expectedBranch = getExpectedtBranchs.find(item => item.company_branch_id === parseInt(COMPANY_BRANCH_ID))
            setExpectedBranchStateId(expectedBranch.branch_state_id)
            await FnComboOnChange('ExpectedBranchState')
            setExpectedBranchCityId(expectedBranch.branch_city_id)

            //get hsn code for freight amount
            resetGlobalQuery();
            globalQuery.columns = ["hsn_sac_id", "hsn_sac_code", "hsn_sac_rate"];
            globalQuery.table = "cmv_hsn_sac"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery).then(getFreightHSNCodes => {
                setfreightHSNList(getFreightHSNCodes);
            });

            // for agentsList List
            comboDataFunc.current.fillMasterData("cmv_agent", "", "")
                .then(agentsList => {
                    setAgentsList(agentsList);
                    // Set the Default Agent as NA and load it's releated %.
                    const defaultAgent = agentsList.find(agent => agent.field_name === 'NA');
                    if (defaultAgent) { setAgentId(defaultAgent.field_id); }
                });

            // unit list for details table
            comboDataFunc.current.fillMasterData("smv_product_unit", "", "")
                .then(getUnitList => {
                    setUnitList(getUnitList)
                });

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const FnCheckUpdateResponce = async () => {
        try {
            const invoiceIdentity = {
                sales_invoice_no: txt_sales_invoice_no,
                sales_invoice_version: txt_sales_invoice_version,
                financial_year: financial_year,
                company_id: COMPANY_ID
            }
            const formData = new FormData()
            formData.append(`invoiceIdentity`, JSON.stringify(invoiceIdentity))
            const request = {
                method: 'POST',
                body: formData
            }

            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/FtSalesInvoiceMasterServices/FnShowAllDetailsAndMastermodelRecords`, request)
            const response = await apiCall.json();
            let invoiceMasterData = response.salesInvoiceMasterServicesRecords;
            let invoiceDetailsData = response.salesInvoiceDetailsRecords;
            let invoiceTaxSummryData = response.salesInvoiceTaxSummaryServicesRecords;
            let invoiceActivitiesData = response.salesInvoiceServicesActivitiesMappingRecord
            const customerOrderNosList = response.customerOrderNosList

            let { sales_invoice_master_transaction_id, sales_invoice_type_id, sales_invoice_creation_type,
                sales_invoice_no, sales_invoice_date, sales_invoice_version, customer_id, customer_contacts_ids, customer_state_id,
                customer_city_id, customer_gst_no, customer_pan_no, consignee_id, consignee_state_id, consignee_city_id,
                consignee_gst_no, consignee_pan_no, transporter_id, transporter_state_id, transporter_gst_no,
                expected_branch_id, expected_branch_state_id, expected_branch_city_id, vehicle_no, vehicle_type,
                transporter_challan_no, transporter_challan_date, transport_mode, transaction_type, is_service,
                is_sez, interim, loading_time, approved_by_id, approved_date, sales_invoice_status, payment_schedule_date,
                basic_total, transport_amount, freight_amount, is_freight_taxable, freight_hsn_code_id,
                packing_amount, discount_percent, discount_amount, other_amount,
                taxable_total, cgst_total, sgst_total, igst_total,
                roundoff, grand_total, agent_id, agent_percent, agent_amount, agent_paid_status, sales_invoice_remark,
                other_terms_conditions, remark
            } = invoiceMasterData;

            setSalesInvoiceMasterTransactionId(sales_invoice_master_transaction_id)
            setSalesInvoiceCreationType(sales_invoice_creation_type)
            setSalesInvoiceTypeId(sales_invoice_type_id)
            setSalesInvoiceNo(sales_invoice_no)
            setSalesInvoiceDate(sales_invoice_date)
            setSalesInvoiceVersion(sales_invoice_version)
            setCustomerId(customer_id)
            setCustomerState(customer_state_id)
            await FnComboOnChange('CustomerState')
            setCustomerCity(customer_city_id)
            setCustomerGstNo(customer_gst_no)
            setCustomerPanNo(customer_pan_no)
            FnGetCustomersContactPersonsDetails(customer_id);
            let custContactData = await FnGetCustomersContactPersonsDetails(customer_id);
            const customerContactPersonsIds = customer_contacts_ids.split(":").map(Number);
            const rearrangedContactsRows = [
                ...custContactData.filter(contact => customerContactPersonsIds.includes(contact.customer_contact_id)),
                ...custContactData.filter(contact => !customerContactPersonsIds.includes(contact.customer_contact_id)),
            ];
            setCustContactDetails(rearrangedContactsRows);

            setIsSez(is_sez)
            setConsigneeId(consignee_id)
            setConsigneeState(consignee_state_id)
            await FnComboOnChange('ConsigneeState')
            setConsigneeCity(consignee_city_id)
            setConsigneeGstNo(consignee_gst_no)
            setConsigneePanNo(consignee_pan_no)
            setTransporterId(transporter_id)
            setTransporterState(transporter_state_id)
            setTransporterGstNo(transporter_gst_no)
            setTransporterChallanNo(transporter_challan_no)
            setTransporterChallanDate(transporter_challan_date)
            setTransporterMode(transport_mode)
            setExpectedBranchId(expected_branch_id)
            setExpectedBranchStateId(expected_branch_state_id)
            await FnComboOnChange('ExpectedBranchState')
            setExpectedBranchCityId(expected_branch_city_id)
            setVehicleNo(vehicle_no)
            setVehicleType(vehicle_type)
            setTransactionType(transaction_type)
            setIsService(is_service)
            setInterim(interim)
            setLoadingTime(loading_time)

            if (keyForViewUpdate === 'view' || isApprove) {
                setApprovedById(approved_by_id);
                setApprovedDate(approved_date);
            }

            setSalesInvoiceStatus(sales_invoice_status)
            setPaymentScheduleDate(payment_schedule_date)
            setBasicTotal(basic_total)
            setTransportAmount(transport_amount)
            setFreightAmount(freight_amount)
            setFreightIsTaxable(is_freight_taxable)
            setFreightHSNId(freight_hsn_code_id)
            setPackingAmount(packing_amount)
            setDiscountAmount(discount_amount)
            setDiscountPercent(discount_percent)
            setOtherAmount(other_amount)
            setTaxableTotal(taxable_total)
            setCgstTotal(cgst_total)
            setSgstTotal(sgst_total)
            setIgstTotal(igst_total)
            setGrandTotal(grand_total)
            setRoundOff(roundoff)
            setAgentId(agent_id)
            setAgentPercent(agent_percent)
            setAgentAmount(agent_amount)
            setAgentPaidStatus(agent_paid_status)
            setSalesInvoiceRemark(sales_invoice_remark)
            setOtherTermsConditions(other_terms_conditions)
            setRemark(remark)

            let listItems = customerOrderNosList.map(renderCustomerOrderNosCheckbox);
            setCustomerOrderNosList(listItems)

            invoiceDetailsData = invoiceDetailsData.map(item => {
                // Create a new object with the updated key name
                const newItem = {
                    ...item,
                    product_id: item.product_material_id,
                    product_name: item.product_material_name,
                    product_std_weight: item.product_material_std_weight,
                    product_unit_id: item.product_material_unit_id,
                    product_tech_spect: item.product_material_tech_spect,
                    product_packing_id: item.product_material_packing_id,

                };
                return newItem;
            })
            if (sales_invoice_creation_type === 'O') {
                // Loop through each checkbox with the name "customerOrderNoCheckBox"
                $("input:checkbox[name=customerOrderNoCheckBox]").each(function () {
                    const checkboxValue = $(this).val();
                    const orderNoIndex = customerOrderNosList.findIndex((item) => item.customer_order_no === checkboxValue)
                    if (orderNoIndex !== -1) {
                        $(this).prop('checked', true);
                    }
                });
                setcheckedCustomerOrderNoLength(customerOrderNosList.length);
                setSalesInvoiceDetailData(invoiceDetailsData);

            } else {

                setSalesInvoiceDetailData(invoiceDetailsData)
                sessionStorage.setItem('filteredMaterialData', invoiceDetailsData)
                setSalesInvoiceTaxSummaryData(invoiceTaxSummryData)
            }

            setInvoiceMasterDetails(invoiceMasterData)
            setSalesInvoiceDetailData(invoiceDetailsData)
            setSalesInvoiceActivitiesData(invoiceActivitiesData);
            // $("input:checkbox[name=customerOrderNoCheckBox]").each(function () {
            //     const checkboxValue = $(this).val();
            //     const orderNoIndex = customerOrderNosList.findIndex((item) => item.customer_order_no === checkboxValue)
            //     if (orderNoIndex !== -1) {
            //         $(this).prop('checked', true);
            //     }
            // });

            customerContactPersonsIds.forEach(function (contactId, index) {
                $('#selectCustContactPerson_' + customerContactPersonsIds[index]).prop('checked', true);
            });

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }

    }

   
    // Invoice Printing 


    const invoiceContent = {
        "company": {
            "company_name": "",
            "branch_address1": "",
            'branch_address2': '',
            "email": "",
            "phone": "",
        },
        "gstdetails": {
            "gst_no": "",
            "pan_no": "",
            "tan_no": "",
            "commissionerate": "",
            "tax_is_pay": ""
        },
        "invoicedetails": {
            "invoice_no": "",
            "invoice_date": "",
            "irn_no": "",
            irn_date: '',
            "ack_no_date": "",
            "eway_bill_no": "",
            "eway_bill_date": '',
            place_of_supply: '',
            "transporter": "",
            "lc_no": '',
            "lr_no": "",
            'lr_date': '',
            "insurance_no": '',
        },
        "podetails": {
            purchase_order_no: "",
            purchase_order_date: '',
            dispatch_challan_no: '',
            dispatch_challan_date: '',
            vendor_code: "",
            time_of_issue: '',
            time_of_removal: ''
        },
        "billAddress": {
            "billToDetails": {
                "company": "",
                "address": "",
                "GSTIN": "",
                "contact": "",
                "email": "",
                customer_gst_no: "",
                customer_pan_no: "",
                customer_state_code: "",
                customer_state_name: ''
            },
            "shipToDetails": {
                "company": "",
                "address": "",
                "state": "",
                "GSTIN": "",
                "pan_no": "",
                "contact": "",
                "email": "",
                consignee_gst_no: "",
                consignee_pan_no: "",
                consignee_state_code: "",
                consignee_state_name: ''
            }
        },
        "footer": {
            "total_qty": "",
            "basic_total": "",
            "packing_amount": "",
            "transport": "",
            "freight_amount": "",
            "taxable_total": "",
            "central_tax_percent": "",
            "central_tax_value": "",
            "state_tax_percent": "",
            "state_tax_value": "",
            "integrated_tax_percent": "",
            "integrated_tax_value": "",
            "state_tax": "",
            "integrated_tax": "",
            "tcs_amount": "",
            "grand_total": "",
            "bank_details": {
                "bank_name": "",
                "account_no": "",
                "ifsc_code": ""
            }
        },
        "items": []
    }

    const printInvoice = async () => {
        const {
            company_branch_name, sales_invoice_no, sales_invoice_date, sales_invoice_version,
            sales_invoice_status_desc, customer_name, sales_order_no, sales_order_version, sales_order_Date,
            customer_order_no, customer_order_Date, customer_state_name, consignee_name, consignee_state_name,
            approved_date, sales_invoice_remark, other_terms_conditions, customer_gst_no, consignee_gst_no,
            customer_pan_no, consignee_pan_no, transporter_gst_no, vehicle_no, vehicle_type, transporter_challan_no,
            transporter_challan_date, e_inv_irn_no, e_inv_irn_date, e_inv_ack_no, e_inv_ack_date, e_inv_ewb_no,
            e_inv_ewb_valid_till_date, e_inv_qr_code, loading_time, transport_mode,
            transaction_type, is_service, is_sez, interim, payment_schedule_date, basic_total,
            product_material_print_name,
            transport_amount,  packing_amount, discount_percent, discount_amount,
            other_amount, taxable_total, cgst_percent, cgst_total, sgst_percent, sgst_total, igst_percent, igst_total,
            roundoff, grand_total, e_transaction_type, tcs_amount, cess_amount, stcess_amount, cess_advance_amount,
            company_name, financial_year, customer_email, customer_city_name,
            consignee_email, consignee_city_name, branch_address1, branch_gst_no,
            branch_pan_no,
            branch_address2,
            branch_EmailId, branch_phone_no, company_branch_id, expected_branch_id, branch_udyog_adhar_no,
            branch_state_name, branch_state_code,   // Not avl in view.
            cust_branch_address, customer_state_code, consignee_branch_address, consignee_state_code, transporter_name
        } = invoiceMastersDetails;

        debugger
        invoiceContent.company.company_name = company_name
        invoiceContent.company.branch_address1 = branch_address1
        invoiceContent.company.branch_address2 = branch_address2
        invoiceContent.company.email = branch_EmailId
        invoiceContent.company.phone = branch_phone_no
        invoiceContent.company.state_name = branch_state_name || ''
        invoiceContent.company.state_code = branch_state_code || ''

        invoiceContent.gstdetails.gst_no = branch_gst_no
        invoiceContent.gstdetails.pan_no = branch_pan_no
        invoiceContent.gstdetails.tax_is_pay = is_service
        invoiceContent.gstdetails.udyog_adhar_no = branch_udyog_adhar_no

        invoiceContent.podetails.purchase_order_no = salesInvoiceDetailData[0].hasOwnProperty('purchase_order_no') ? salesInvoiceDetailData[0].purchase_order_no : ''
        invoiceContent.podetails.purchase_order_date = salesInvoiceDetailData[0].hasOwnProperty('purchase_order_date') ? salesInvoiceDetailData[0].purchase_order_date : ''
        invoiceContent.podetails.dispatch_challan_no = salesInvoiceDetailData[0].hasOwnProperty('dispatch_challan_no') ? salesInvoiceDetailData[0].dispatch_challan_no : ''
        invoiceContent.podetails.dispatch_challan_date = salesInvoiceDetailData[0].hasOwnProperty('dispatch_challan_date') ? salesInvoiceDetailData[0].dispatch_challan_date : ''
        invoiceContent.podetails.time_of_issue = loading_time
        invoiceContent.podetails.time_of_removal = loading_time
        invoiceContent.podetails.vendor_code = customer_order_no


        invoiceContent.billAddress.billToDetails.company = customer_name
        invoiceContent.billAddress.billToDetails.address = cust_branch_address
        invoiceContent.billAddress.billToDetails.customer_gst_no = customer_gst_no
        invoiceContent.billAddress.billToDetails.customer_pan_no = customer_pan_no
        invoiceContent.billAddress.billToDetails.customer_state_code = customer_state_code
        invoiceContent.billAddress.billToDetails.customer_state_name = customer_state_name

        invoiceContent.billAddress.shipToDetails.company = consignee_name || ''
        invoiceContent.billAddress.shipToDetails.address = consignee_branch_address || ''
        invoiceContent.billAddress.shipToDetails.consignee_gst_no = consignee_gst_no || ''
        invoiceContent.billAddress.shipToDetails.consignee_pan_no = consignee_pan_no || ''
        invoiceContent.billAddress.shipToDetails.consignee_state_code = consignee_state_code || ''
        invoiceContent.billAddress.shipToDetails.consignee_state_name = consignee_state_name || ''


        invoiceContent.invoicedetails.invoice_no = sales_invoice_no
        invoiceContent.invoicedetails.invoice_date = sales_invoice_date
        invoiceContent.invoicedetails.irn_no = e_inv_irn_no
        invoiceContent.invoicedetails.irn_date = e_inv_irn_date
        invoiceContent.invoicedetails.irn_no = e_inv_irn_no
        invoiceContent.invoicedetails.eway_bill_no = e_inv_ewb_no
        invoiceContent.invoicedetails.eway_bill_date = e_inv_ewb_valid_till_date
        invoiceContent.invoicedetails.place_of_supply = '(' + customer_state_code + ')' + customer_state_name
        invoiceContent.invoicedetails.transporter = transporter_name
        invoiceContent.invoicedetails.vehicle_no = vehicle_no

        //      Items
        invoiceContent.items = salesInvoiceDetailData
        console.log("salesInvoiceDetailData: ",salesInvoiceDetailData);

        //     Footer data
        
        invoiceContent.footer.basic_total = txt_basic_total
        invoiceContent.footer.packing_amount = packing_amount
        invoiceContent.footer.transport = transport_amount
        invoiceContent.footer.taxable_total = taxable_total
        invoiceContent.footer.central_tax_percent = cgst_percent
        invoiceContent.footer.central_tax_value = cgst_total
        invoiceContent.footer.state_tax_percent = sgst_percent
        invoiceContent.footer.state_tax_value = sgst_total
        invoiceContent.footer.integrated_tax_percent = igst_percent
        invoiceContent.footer.integrated_tax_value = igst_total
        invoiceContent.footer.tcs_amount = tcs_amount
        invoiceContent.footer.grand_total = grand_total

        resetGlobalQuery();
        globalQuery.columns = ["bank_name", "bank_branch_name", "bank_account_no", "bank_ifsc_code"]
        globalQuery.table = "cmv_company_banks"
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "company_branch_id", operator: "=", value: expected_branch_id });
        globalQuery.orderBy = ['company_bank_id DESC']

        const company_bank_details = await comboDataFunc.current.fillFiltersCombo(globalQuery)
        if (company_bank_details.length !== 0) {
            const { company_branch_bank_name,
                company_branch_bank_account_no,
                company_branch_bank_ifsc_code,
            } = company_bank_details[company_bank_details.length - 1]
            invoiceContent.footer.bank_details.bank_name = company_branch_bank_name
            invoiceContent.footer.bank_details.account_no = company_branch_bank_account_no
            invoiceContent.footer.bank_details.ifsc_code = company_branch_bank_ifsc_code

        }

        navigate('/Invoice', { state: { invoiceData: invoiceContent, navigationLink: '/Transactions/TSalesInvoiceServices/FrmSalesInvoiceServicesEntry', invoiceType: 'SIS', title: 'Sales Invoice Service', idList: idList, keyForViewUpdate: keyForViewUpdate, compType: compType } });
    };
	

    const FnComboOnChange = async (key) => {
        try {
            switch (key) {
                case 'CreationType':
                    setSalesInvoiceDetailData([]);
                    setcheckedCustomerOrderNoLength(0);
                    break;

                case 'SalesInvoiceType':
                    let salesInvoiceTpValue = parseInt(document.getElementById("cmb_sales_invoice_type_id").value);

                    if (salesInvoiceTpValue !== 0 && !isNaN(salesInvoiceTpValue)) {
                        setSalesInvoiceTypeId(salesInvoiceTpValue)
                        await generateSalesInvoiceNo();
                        $('#error_cmb_sales_invoice_type_id').hide();
                    } else if (salesInvoiceTpValue === 0) {
                        setSalesInvoiceNo('')
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Sales Invoice Type')
                        setShowAddRecModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").css("padding-top", "0px");
                        }, 100)
                    } else {
                        setSalesInvoiceNo('')
                    }
                    break;

                case 'Customer':
                    let selectedCustomer = parseInt(document.getElementById('cmb_customer_id').value);
                    if (selectedCustomer === 0) {
                        localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        localStorage.setItem('customerIDs', JSON.stringify({ customerID: 0, keyForViewUpdate: 'Add', compType: 'Master' }))
                        const newTab = window.open('/Masters/Customer', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                    } else if (!isNaN(selectedCustomer)) {
                        if (customerList.length !== 0) {
                            let customer = customerList.find(item => item.field_id === selectedCustomer)
                            setCustomerState(customer.cust_branch_state_id)
                            setCustomerGstNo(customer.cust_branch_gst_no)
                            setCustomerPanNo(customer.cust_branch_pan_no)
                            FnComboOnChange('CustomerState')
                            setCustomerCity(customer.cust_branch_city_id)
                            setIsSez(customer.is_sez ? 'Y' : 'N')
                        }
                        if (rb_sales_invoice_creation_type !== 'M')
                            loadCustomerOrderNos()
                        FnGetCustomersContactPersonsDetails(selectedCustomer);

                        // If branch Changed then remove the details, tax summary & footer data of previous branch
                        setSalesInvoiceDetailData([])
                        setSalesInvoiceTaxSummaryData([])
                        setBasicTotal(0);
                        setCgstTotal(0);
                        setSgstTotal(0);
                        setIgstTotal(0)
                        setTaxableTotal(0)
                        setGrandTotal(0);
                        setRoundOff(0);
                    } else {
                        setCustomerState('');
                        setCustomerCity('');
                        setCustomerCityList([]);
                        setCustContactDetails([]);
                    }
                    break;
                case 'CustomerState':
                    let customerState = parseInt(document.getElementById('cmb_customer_state_id').value);
                    const getCustomerCities = await comboDataFunc.current.fillMasterData("cmv_city", "state_id", customerState)
                    setCustomerCityList(getCustomerCities);
                    break;

                case 'CustomerCity':
                    if ($('#cmb_customer_city_id').val() === '0') {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Add Customer City')
                        setShowAddRecModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").css({
                                "padding-top": "0px"
                            });
                        }, 200)
                        setCustomerCity('');
                    }
                    break;
                case 'Consignee':
                    let selectedConsignee = parseInt(document.getElementById('cmb_consignee_id').value);
                    if (selectedConsignee === 0) {
                        const newTab = window.open('/Masters/Customer', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                        // setConsigneeId('');
                    } else if (selectedConsignee !== "" && !isNaN(selectedConsignee)) {
                        if (consigneeList.length !== 0) {
                            let consignee = consigneeList.find(item => item.field_id === selectedConsignee)
                            setConsigneeState(consignee.cust_branch_state_id)
                            setConsigneeGstNo(consignee.cust_branch_gst_no)
                            setConsigneePanNo(consignee.cust_branch_pan_no)
                            FnComboOnChange('ConsigneeState')
                            setConsigneeCity(consignee.cust_branch_city_id)

                        }
                    } else {
                        setConsigneeState('');
                        setConsigneeCity('');
                        setConsigneeCityList([]);
                    }
                    break;

                case 'ConsigneeState':
                    let consigneeState = parseInt(document.getElementById('cmb_consignee_state_id').value);
                    const getConsigneeCities = await comboDataFunc.current.fillMasterData("cmv_city", "state_id", consigneeState)
                    setConsigneeCityList(getConsigneeCities);
                    break;

                case 'ConsigneeCity':
                    if (parseInt(document.getElementById('cmb_consignee_city_id')).value === 0) {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Add Consignee City')
                        setShowAddRecModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").css({
                                "padding-top": "0px"
                            });
                        }, 200);
                        setConsigneeCity('');
                    }
                    break;
                case 'ExpectedbranchId':
                    let expectedBranchValue = parseInt(document.getElementById('cmb_expected_branch_id').value);
                    setExpectedBranchId(expectedBranchValue)
                    if (expectedBranchValue !== 0 && !isNaN(expectedBranchValue)) {
                        const getBranchCityAndState = expectedBranchList.find(item => item.company_branch_id === expectedBranchValue)
                        setExpectedBranchStateId(getBranchCityAndState.branch_state_id)
                        FnComboOnChange('ExpectedBranchState')
                        setExpectedBranchCityId(getBranchCityAndState.branch_city_id)
                        // setIsSez(getBranchCityAndState.is_sez)

                        // If branch Changed then remove the details, tax summary & footer data of previous branch
                        setSalesInvoiceDetailData([])
                        setSalesInvoiceTaxSummaryData([])
                        setBasicTotal(0);
                        setCgstTotal(0);
                        setSgstTotal(0);
                        setIgstTotal(0)
                        setTaxableTotal(0)
                        setGrandTotal(0);
                        setRoundOff(0);
                        // await loadPurchaseOrderNos()
                    } else {
                        setExpectedBranchCityId('')
                        setExpectedBranchStateId('')
                    }

                    if (expectedBranchValue === 0) {
                        const newTab = window.open('Masters/Company', '_blank');
                        if (newTab) {
                            newTab.focus();
                        }
                    }
                    break;
                case 'ExpectedBranchState':
                    let expectedBranchStateValue = parseInt(document.getElementById('cmb_expected_branch_state_id').value);
                    const getExpecBranchCities = await comboDataFunc.current.fillMasterData("cmv_city", "state_id", expectedBranchStateValue)
                    setExpectedBranchCityList(getExpecBranchCities)
                    break;

                case 'Transporter':
                    let selectedTransporter = parseInt(document.getElementById('cmb_transporter_id').value);
                    if (selectedTransporter !== 0 && !isNaN(selectedTransporter)) {
                        const getTransporterCityAndState = transporterList.find(item => item.field_id === selectedTransporter)
                        setTransporterState(getTransporterCityAndState.transporter_state_id)
                        setTransporterGstNo(getTransporterCityAndState.transporter_gst_no)

                    } else {
                        setTransporterState('')
                        setTransporterGstNo('')
                    }
                    break;

                default:
                    break;
            }
        } catch (error) {
            console.log("Error: " + error)
            navigate('/Error')
        }
    }

    const FnGetCustomersContactPersonsDetails = async (customerId) => {
        try {
            resetGlobalQuery();
            globalQuery.columns = [
                "customer_id",
                "customer_branch_name",
                "cust_branch_id",
                "customer_contact_id",
                "cust_contact_person",
                "cust_contact_no",
                "cust_alternate_contact",
                "cust_email_id",
                "cust_alternate_EmailId"
            ];

            globalQuery.table = "cmv_customer_contacts"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "customer_id", operator: "=", value: parseInt(customerId) });
            const getContactDtlsApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);

            if (getContactDtlsApiCall.length !== 0) {
                let column = [];
                let columnHeads = Object.keys(getContactDtlsApiCall[0]);
                for (let colKey = 0; colKey < columnHeads.length; colKey++) {

                    if (!columnHeads[colKey].includes('customer_id') && !columnHeads[colKey].includes('customer_contact_id') && !columnHeads[colKey].includes('cust_branch_id')) {
                        column.push({ Headers: columnHeads[colKey], accessor: columnHeads[colKey] });
                    }
                }
                setCustContactDetails(getContactDtlsApiCall);
                return getContactDtlsApiCall;
            } else {
                setCustContactDetails([]);
                return [];
            }

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    function FnCustomerContactSelection(key) {
        switch (key) {
            case 'selectAllCustContact':
                $('.selectCustContactPerson').prop('checked', $('#selectAllCustContact').is(":checked"));
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;

            case 'PartiallyCustContactSelection':
                $('#selectAllCustContact').prop('checked', $('input:checkbox.selectCustContactPerson:checked').length === $('input:checkbox.selectCustContactPerson').length);
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;
            default:
                break;
        }
    }

    function renderCustomerOrderNosCheckbox(item, index) {
        if (index === 0) {
            return (
                <>
                    <li className="item customer_order_no" key={`All - ${index} `}>
                        <span className="checkbox">
                            <input
                                type="checkbox"
                                id="all_customer_order_no_id"
                                value={index}
                                className="erp_radio_button filterCheckBox"
                                onChange={(event) => FnCustomerOrderNosSelection(event.target.value)}
                                disabled={isApprove || keyForViewUpdate === 'view' ? true : false}
                                optional='optional'
                            />
                        </span>
                        <span className="item-text">All</span>
                    </li>
                    <li className="item customer_order_no" key={index}>
                        <span className="checkbox">
                            <input
                                type="checkbox"
                                name="customerOrderNoCheckBox"
                                value={item.customer_order_no}
                                id={`filterCheck_${item.customer_order_no} `}
                                className="erp_radio_button customerOrderNoCheckBox"
                                onChange={(event) => FnCustomerOrderNosSelection(event.target.value)}
                                disabled={isApprove || keyForViewUpdate === 'view' ? true : false}
                                optional='optional'
                            />
                        </span>
                        <span className="item-text">{item.customer_order_no}</span>
                    </li>
                </>
            );
        }
        return (
            <li className="item customer_order_no" key={index}>
                <span className="checkbox">
                    <input
                        type="checkbox"
                        fieldName={item.customer_order_no}
                        name="customerOrderNoCheckBox"
                        value={item.customer_order_no}
                        id={`filterCheck_${item.customer_order_no} `}
                        className="erp_radio_button customerOrderNoCheckBox"
                        onChange={(event) => FnCustomerOrderNosSelection(event.target.value)}
                        disabled={isApprove || keyForViewUpdate === 'view' ? true : false}
                    />
                </span>
                <span className="item-text">{item.customer_order_no}</span>
            </li>
        );
    }

    const loadCustomerOrderNos = async () => {
        resetGlobalQuery();
        globalQuery.columns = ["customer_order_no", "sales_order_no", "sales_order_version", "sales_order_master_transaction_id"]
        globalQuery.table = "mtv_sales_order_master_services_summary"
        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: document.getElementById('cmb_customer_id').value });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0', });
        globalQuery.conditions.push({ field: "sales_order_status", operator: "IN", values: ['A', 'I'] });
        globalQuery.orderBy = ["sales_order_master_transaction_id desc"];
        const getCustomerOrderNos = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);

        let listItems = getCustomerOrderNos.map(renderCustomerOrderNosCheckbox);
        setCustomerOrderNosList(listItems)
    }

    const FnCustomerOrderNosSelection = (customer_order_no) => {
        if (parseInt(customer_order_no) === 0) {
            if ($("#all_customer_order_no_id").is(":checked") === false) {
                selectAllCustomerOrderNos('uncheck');
            } else {
                selectAllCustomerOrderNos('check');
            }
        }

        //Check Partiallly selected all or not if selected then check the check all radio of dispatch details
        const totalChkBoxes = document.querySelectorAll('input[name=customerOrderNoCheckBox]').length;
        const totalChkBoxesChked = document.querySelectorAll('input[name=customerOrderNoCheckBox]:checked').length;
        if (totalChkBoxes === totalChkBoxesChked) {
            document.getElementById('all_customer_order_no_id').checked = true;
        } else if (totalChkBoxes > totalChkBoxesChked) {
            document.getElementById('all_customer_order_no_id').checked = false;
        }

        setcheckedCustomerOrderNoLength(totalChkBoxesChked)
    }

    const selectAllCustomerOrderNos = (key) => { // fn to check all checkbox of contact ids
        const fliterCheckbox = document.getElementsByName('customerOrderNoCheckBox');
        if (key === 'check') {
            for (let chkAllCheckbox of fliterCheckbox) {
                document.getElementById(`${chkAllCheckbox.id} `).checked = true;
            }
        } else {
            for (let unchkAllCheckbox of fliterCheckbox) {
                document.getElementById(`${unchkAllCheckbox.id} `).checked = false;
            }
        }
    }


    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'Customer City':
                return <FrmCity btn_disabled={true} />;
            case 'Consignee City':
                return <FrmCity btn_disabled={true} />;
            case 'Sales Invoice Type':
                return <FrmMProductTypeEntry btn_disabled={true} />;
            default:
                return null;
        }
    }

    // For update the combobox data after add new record.
    const FnUpdateComboBoxData = async (key) => {
        if (keyForViewUpdate === 'view' || keyForViewUpdate === 'approve') {
            return false;
        }
        switch (key) {
            case 'UpdateConsignees':
            case 'UpdateCustomers':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("is_sez");
                globalQuery.table = "cmv_customer"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedCustomerData = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
                if (modalHeaderName === 'Add Customer') {
                    setCustomerList(getUpdatedCustomerData);
                } else if (modalHeaderName === 'Add Consignee') {
                    setConsigneeList(getUpdatedCustomerData);
                }
                break;

            case 'UpdateAgents':
                const getAgentsList = await comboDataFunc.current.fillMasterData("cmv_agent", "", "");
                setAgentsList(getAgentsList);
                break;

            default:
                break;
        }

    }

    const FnFooterPercentChange = (e) => {
        const percent = e.target.value || 0;
        const amount = parseFloat((percent / 100)) * txt_basic_total;
        setDiscountAmount(validateNumberDateInput.current.decimalNumber(JSON.stringify(amount), 4));
        setDiscountPercent(validateNumberDateInput.current.decimalNumber(JSON.stringify(percent), 2));

        setTimeout(() => {
            reverseCalculationForDetailData(salesInvoiceDetailData);
            calculateGrandTotal(salesInvoiceDetailData, salesInvoiceTaxSummaryData);
        }, 200);

    };

    const FnFooterAmountChange = (e) => {
        const amount = document.getElementById('txt_discount_amount').value || 0;
        const percent = (amount * 100) / txt_basic_total;

        setDiscountAmount(validateNumberDateInput.current.decimalNumber(JSON.stringify(amount), 4));
        setDiscountPercent(validateNumberDateInput.current.decimalNumber(JSON.stringify(percent), 2));
        // setTimeout(() => {
        //     calculateGrandTotal(salesInvoiceDetailData, salesInvoiceTaxSummaryData);
        // }, 200);
    };


    const showSalesOrderDetails = async (storedSalesInvoiceDetailData) => {
        try {
            setIsLoading(true);
            let json = { 'customerOrderNos': [], 'company_id': COMPANY_ID }
            $("input:checkbox[name=customerOrderNoCheckBox]:checked").each(function () {
                json.customerOrderNos.push($(this).val())
            })

            if (json.customerOrderNos.length !== 0) {
                resetGlobalQuery();
                globalQuery.columns = ["*",]
                globalQuery.table = "mtv_sales_order_details_services"
                globalQuery.conditions.push({ field: "customer_order_no", operator: "IN", values: json.customerOrderNos });
                globalQuery.conditions.push({ field: "sales_order_item_status", operator: "IN", values: ['A', 'I'] });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0', });
                globalQuery.orderBy = ["sales_order_details_transaction_id desc"];
                const customerOrderDetails = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                if (customerOrderDetails.length !== 0) {
                    await displaySalesInvoiceDetailsData(customerOrderDetails, json.customerOrderNos, storedSalesInvoiceDetailData)
                }
            }
            setIsLoading(false);

        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }
    }

    const displaySalesInvoiceDetailsData = async (response, customer_order_nos, storedSalesInvoiceDetailData) => {
        let totalBasicAmount = 0;
        let totalDiscountAmount = 0;

        // Extracted common mapping logic to a separate function
        const mapSODetails = (item) => ({
            ...item,
            product_id: item.product_material_id,
            product_name: item.product_material_name,
            product_std_weight: 0,      //
            product_unit_id: item.product_material_unit_id,
            product_tech_spect: item.product_material_tech_spect,
            product_packing_id: item.product_material_packing_id,
            invoice_quantity: parseFloat(item.material_quantity) - parseFloat(item.prev_sales_invoice_quantity),
            invoice_weight: item.material_weight,
            hsn_sac_id: item.product_material_hsn_code_id,
            hsn_sac_code: item.product_material_hsn_sac_code,
            hsn_sac_rate: item.product_material_hsn_sac_rate,
            hsn_sac_percent: item.product_material_hsn_sac_rate,
            item_cgst_percent: item.material_cgst_percent,
            item_cgst_amount: item.material_cgst_total,
            item_sgst_percent: item.material_sgst_percent,
            item_sgst_amount: item.material_sgst_total,
            item_igst_percent: item.material_igst_percent,
            item_igst_amount: item.material_igst_total,
            item_total_amount: item.material_total_amount,
            item_taxable_amount: item.material_taxable_amount,
            item_discount_amount: item.material_discount_amount,
            item_discount_percent: item.material_discount_percent,
            item_rate: item.so_rate,
            item_amount: item.material_basic_amount,
        });

        const calculateTotalAmounts = (items) => {
            return items.reduce((acc, item) => {
                acc.totalDiscountAmount += item.item_discount_amount;
                acc.totalBasicAmount += item.item_amount;
                return acc;
            }, { totalDiscountAmount, totalBasicAmount });
        };

        // setSalesInvoiceDetailData((prevArray) => {
        //     if (sales_invoice_master_transaction_id === 0) {
        //         const newItems = response.filter(item =>
        //             customer_order_nos.includes(item.customer_order_no) &&
        //             !prevArray.some(prevItem => prevItem.customer_order_no === item.customer_order_no)
        //         ).map(mapSODetails);
        //         const detailData = [...prevArray.filter(item => customer_order_nos.includes(item.custome_order_no)), ...newItems];
        //         const result = calculateTotalAmounts(detailData)
        //         const percent = (result.totalDiscountAmount * 100) / result.totalBasicAmount;
        //         setDiscountAmount(validateNumberDateInput.current.decimalNumber(JSON.stringify(result.totalDiscountAmount), 4));
        //         setDiscountPercent(validateNumberDateInput.current.decimalNumber(JSON.stringify(percent), 2));

        //         // Load the it's releated service reports activities. (Use an object to store distinct values)
        //         const distinctValues = detailData.reduce((acc, item) => {
        //             acc.customer_order_nos.add(item.customer_order_no);
        //             acc.product_ids.add(item.product_id);
        //             return acc;
        //         }, { customer_order_nos: new Set(), product_ids: new Set() });

        //         FnGetServiceReportedActivities(cmb_customer_id, Array.from(distinctValues.customer_order_nos), Array.from(distinctValues.product_ids));
        //         return detailData;
        //     } else {
        //         const uniqueChallanNos = new Set(prevArray.map(item => item.custome_order_no));
        //         const matchingItems = prevArray.filter(item => customer_order_nos.includes(item.custome_order_no));

        //         const processedItems = response.filter(item => (
        //             customer_order_nos.includes(item.custome_order_no) &&
        //             !uniqueChallanNos.has(item.custome_order_no) &&
        //             !storedSalesInvoiceDetailData.some(storedItem => storedItem.custome_order_no === item.custome_order_no)
        //         )).map(mapSODetails);

        //         const additionalItems = storedSalesInvoiceDetailData.filter(item => (
        //             customer_order_nos.includes(item.custome_order_no) &&
        //             !uniqueChallanNos.has(item.custome_order_no)
        //         )).map(mapSODetails);

        //         const mergedSODetails = [...matchingItems, ...processedItems, ...additionalItems];
        //         const result = calculateTotalAmounts(mergedSODetails)

        //         const percent = (result.totalDiscountAmount * 100) / result.totalBasicAmount;
        //         setDiscountAmount(validateNumberDateInput.current.decimalNumber(JSON.stringify(result.totalDiscountAmount), 4));
        //         setDiscountPercent(validateNumberDateInput.current.decimalNumber(JSON.stringify(percent), 2));

        //         return mergedSODetails;
        //     }
        // });

        let existingSalesInvoiceDetails = [...salesInvoiceDetailData]
        if (sales_invoice_master_transaction_id === 0) {
            const newItems = response.filter(item =>
                customer_order_nos.includes(item.customer_order_no) &&
                !existingSalesInvoiceDetails.some(prevItem => prevItem.customer_order_no === item.customer_order_no)
            ).map(mapSODetails);
            const detailData = [...existingSalesInvoiceDetails.filter(item => customer_order_nos.includes(item.custome_order_no)), ...newItems];
            const result = calculateTotalAmounts(detailData)
            const percent = (result.totalDiscountAmount * 100) / result.totalBasicAmount;
            setDiscountAmount(validateNumberDateInput.current.decimalNumber(JSON.stringify(result.totalDiscountAmount), 4));
            setDiscountPercent(validateNumberDateInput.current.decimalNumber(JSON.stringify(percent), 2));

            // Load the it's releated service reports activities. (Use an object to store distinct values)
            const distinctValues = detailData.reduce((acc, item) => {
                acc.customer_order_nos.add(item.customer_order_no);
                acc.product_ids.add(item.product_id);
                return acc;
            }, { customer_order_nos: new Set(), product_ids: new Set() });

            await FnGetServiceReportedActivities(cmb_customer_id, customer_order_nos, Array.from(distinctValues.product_ids));
            // await FnGetServiceReportedActivities(cmb_customer_id, Array.from(distinctValues.customer_order_nos), Array.from(distinctValues.product_ids));
            // return detailData;
            setSalesInvoiceDetailData(detailData);
        } else {
            const uniqueChallanNos = new Set(existingSalesInvoiceDetails.map(item => item.custome_order_no));
            const matchingItems = existingSalesInvoiceDetails.filter(item => customer_order_nos.includes(item.custome_order_no));

            const processedItems = response.filter(item => (
                customer_order_nos.includes(item.custome_order_no) &&
                !uniqueChallanNos.has(item.custome_order_no) &&
                !storedSalesInvoiceDetailData.some(storedItem => storedItem.custome_order_no === item.custome_order_no)
            )).map(mapSODetails);

            const additionalItems = storedSalesInvoiceDetailData.filter(item => (
                customer_order_nos.includes(item.custome_order_no) &&
                !uniqueChallanNos.has(item.custome_order_no)
            )).map(mapSODetails);

            const mergedSODetails = [...matchingItems, ...processedItems, ...additionalItems];
            const result = calculateTotalAmounts(mergedSODetails)

            const percent = (result.totalDiscountAmount * 100) / result.totalBasicAmount;
            setDiscountAmount(validateNumberDateInput.current.decimalNumber(JSON.stringify(result.totalDiscountAmount), 4));
            setDiscountPercent(validateNumberDateInput.current.decimalNumber(JSON.stringify(percent), 2));

            // return mergedSODetails;
            setSalesInvoiceDetailData(mergedSODetails);
        }
    };

    // For load the selected customer's order reported service activities.
    const FnGetServiceReportedActivities = async (customer_id, customer_order_nos, material_ids) => {
        try {
            resetGlobalQuery();
            globalQuery.columns = ["*",]
            globalQuery.table = "mtv_service_reporting_activities_details"
            globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customer_id, });
            globalQuery.conditions.push({ field: "customer_order_no", operator: "IN", values: customer_order_nos });
            // globalQuery.conditions.push({ field: "product_material_id", operator: "IN", values: material_ids });
            globalQuery.conditions.push({ field: "customer_status", operator: "IN", values: ['A', 'Q'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0', });
            globalQuery.orderBy = ["service_reporting_activities_details_transaction_id desc"];
            const getReportedActivities = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            let existingSalesInvoiceActivities = [...salesInvoiceActivitiesData]

            if (getReportedActivities.length !== 0) {
                const newActivities = getReportedActivities.filter(item =>
                    customer_order_nos.includes(item.customer_order_no) &&
                    !existingSalesInvoiceActivities.some(prevItem => prevItem.customer_order_no === item.customer_order_no && prevItem.product_material_id === item.product_material_id)
                );
                const updatedActivities = [...existingSalesInvoiceActivities.filter(item => customer_order_nos.includes(item.custome_order_no)), ...newActivities];
                setSalesInvoiceActivitiesData(updatedActivities);
            }

        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }
    }

    // Calculate discount amount 
    const calculateMaterialWiseDiscount = (rowData) => {
        const {
            item_discount_percent,
            item_amount,
        } = rowData;

        const calculatedAmount = (item_discount_percent / 100) * item_amount;
        rowData.item_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedAmount), 4);
    }

    // MaterialWise Calculations
    const FnMaterialWiseCalculations = (currentRowData) => {
        let {
            hsn_sac_rate,
            item_discount_amount,
            item_amount,
        } = currentRowData

        let total_amount = 0;
        let cgst_sgst_percent = 0;
        let cgst_amt = 0;
        let sgst_amt = 0;
        let igst_percent = 0;
        let igst_amt = 0;
        const item_taxable_amount = item_amount - item_discount_amount;

        if (cmb_customer_state_id === '39') {
            // Other territories
            cgst_sgst_percent = 0; cgst_amt = 0; sgst_amt = 0; igst_percent = 0; igst_amt = 0;
            total_amount = item_taxable_amount;
        } else if (is_sez === 'Y') {
            // Special Economical Zone
            cgst_sgst_percent = 0; cgst_amt = 0; sgst_amt = 0; igst_percent = 0; igst_amt = 0;
            total_amount = item_taxable_amount;
        } else if (cmb_customer_state_id === cmb_expected_branch_state_id) {
            // Same Supplier & Branch state SGST,CGST.
            cgst_sgst_percent = hsn_sac_rate / 2; //for gst per% cal
            const gst_amt = (item_taxable_amount * hsn_sac_rate) / 100; //for gst amount cal
            cgst_amt = gst_amt / 2;
            sgst_amt = gst_amt / 2;
            total_amount = item_taxable_amount + gst_amt;
        } else {
            // Diiferent Supplier & Branch state IGST.
            igst_percent = hsn_sac_rate;
            igst_amt = (item_taxable_amount * hsn_sac_rate) / 100;
            total_amount = item_taxable_amount + igst_amt;
        }

        currentRowData.item_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(item_amount), 4);
        currentRowData.item_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(item_discount_amount), 4);
        currentRowData.item_taxable_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(item_taxable_amount), 4);
        currentRowData.item_cgst_percent = validateNumberDateInput.current.decimalNumber(JSON.stringify(cgst_sgst_percent), 2);
        currentRowData.item_cgst_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(cgst_amt), 4);
        currentRowData.item_sgst_percent = validateNumberDateInput.current.decimalNumber(JSON.stringify(cgst_sgst_percent), 2);
        currentRowData.item_sgst_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(sgst_amt), 4);
        currentRowData.item_igst_percentcent = validateNumberDateInput.current.decimalNumber(JSON.stringify(igst_percent), 2);
        currentRowData.item_igst_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(igst_amt), 4);
        currentRowData.item_total_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(total_amount), 4);
    }

    const FnUpdateSalesInvoiceDetailsTblRow = async (rowData, event) => {
        // Destructuring the object
        let {
            dispatch_quantity,
            material_quantity,
            invoice_quantity,
            product_std_weight,
            item_amount,
            item_rate,
        } = rowData;

        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        let clickedColName = event.target.getAttribute('Headers');
        let enteredValue = parseInt(event.target.value);
        if (isNaN(enteredValue)) {
            enteredValue = 0;
        }

        switch (clickedColName) {
            case 'invoice_quantity':
                // if (enteredValue > dispatch_quantity && rb_sales_invoice_creation_type !== 'M') {
                if (enteredValue > material_quantity && rb_sales_invoice_creation_type !== 'M') {
                    let actualValidation = document.querySelector(`#${event.target.id} `);
                    // actualValidation.parentElement.dataset.tip = `Cannot be greater than ${dispatch_quantity}...!`;;
                    actualValidation.parentElement.dataset.tip = `Cannot be greater than ${material_quantity}...!`;;
                    return false;
                } else {
                    rowData[clickedColName] = enteredValue;
                    rowData.invoice_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(enteredValue), 4);
                    rowData.item_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(enteredValue * item_rate), 4);
                    FnMaterialWiseCalculations(rowData)
                    delete event.target.parentElement.dataset.tip;
                }
                break;

            case 'invoice_weight':
                const enteredWeight = validateNumberDateInput.current.decimalNumber(event.target.value, 2) || 0;
                rowData[clickedColName] = enteredWeight;
                if (enteredWeight > 0) {
                    delete event.target.parentElement.dataset.tip;
                }
                break;

            case 'item_rate':
                rowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
                rowData.item_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(invoice_quantity * rowData[clickedColName]), 4);
                calculateMaterialWiseDiscount(rowData)
                FnMaterialWiseCalculations(rowData)
                if (rowData[clickedColName] > 0) {
                    delete event.target.parentElement.dataset.tip;
                }
                break;

            case 'item_discount_percent':
                const enteredPercent = validateNumberDateInput.current.decimalNumber(event.target.value, 2) || 0;
                const calculatedAmount = (enteredPercent / 100) * rowData.item_amount;
                if (validateNumberDateInput.current.percentValidate(enteredPercent)) {
                    rowData.item_discount_percent = enteredPercent;
                    rowData.item_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedAmount), 4);
                    FnMaterialWiseCalculations(rowData)
                    FnAddDiscountAmountInFooter(calculatedAmount, arrayIndex)
                } else {
                    return;
                }
                break;

            case 'item_discount_amount':
                const enteredAmount = validateNumberDateInput.current.decimalNumber(event.target.value, 4) || 0;
                const calculatedPercent = (enteredAmount * 100) / item_amount;
                if (parseFloat(enteredAmount) <= parseFloat(item_amount)) {
                    rowData.item_discount_amount = enteredAmount;
                    rowData.item_discount_percent = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedPercent), 2);
                    FnMaterialWiseCalculations(rowData)
                    FnAddDiscountAmountInFooter(enteredAmount, arrayIndex)
                } else {
                    return;
                }
                break;

            case 'product_material_unit_id':
            case 'item_payment_schedule_date':
            case 'sales_invoice_item_status':
            case 'sales_invoice_remark':
                rowData[clickedColName] = event.target.value;
                break;
        }

        const detailData = [...salesInvoiceDetailData]
        detailData[arrayIndex] = rowData
        setSalesInvoiceDetailData(detailData);
    }

    const FnAddDiscountAmountInFooter = (calculatedAmount, arrayIndex) => {
        const detailData = [...salesInvoiceDetailData]
        let totalDiscountAmount = 0;
        detailData.map((item, index) => {
            if (index !== arrayIndex)
                totalDiscountAmount += isNaN(parseFloat(item.item_discount_amount)) ? 0 : parseFloat(item.item_discount_amount);
        })
        totalDiscountAmount += parseFloat(calculatedAmount)
        setDiscountAmount(totalDiscountAmount)
        setTimeout(() => {
            FnFooterAmountChange()
        }, 200);
        return totalDiscountAmount;
    }

    const calculateGrandTotal = (salesInvoiceDetailData, salesInvoiceTaxSummaryData) => {
        let customerIsSez = $('#cmb_customer_id option:selected').attr('is_sez');
        let basic_total = parseFloat(salesInvoiceDetailData.reduce((total, item) => total + parseFloat(item.item_amount), 0));
        let cgst_total = parseFloat(salesInvoiceTaxSummaryData.reduce((total, item) => total + parseFloat(item.summary_cgst_total), 0));
        let sgst_total = parseFloat(salesInvoiceTaxSummaryData.reduce((total, item) => total + parseFloat(item.summary_sgst_total), 0));
        let igst_total = parseFloat(salesInvoiceTaxSummaryData.reduce((total, item) => total + parseFloat(item.summary_igst_total), 0));
        let grandTotal = salesInvoiceTaxSummaryData.reduce((total, item) => total + parseFloat(item.summary_total_amount), 0);

        const discount_amount = isNaN(parseFloat($('#txt_discount_amount').val())) ? 0 : parseFloat(parseFloat($('#txt_discount_amount').val()))
        const transport_amount = isNaN(parseFloat($('#txt_transport_amount').val())) ? 0 : parseFloat($('#txt_transport_amount').val())
        // const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
        const packingAmt = isNaN(parseFloat($('#txt_packing_amount').val())) ? 0 : parseFloat($('#txt_packing_amount').val())
        const other_amount = isNaN(parseFloat($('#txt_other_amount').val())) ? 0 : parseFloat($('#txt_other_amount').val())

        const taxableAmount = basic_total - discount_amount;
        // grandTotal = taxableAmount + transport_amount + freightAmt + packingAmt + other_amount + cgst_total + sgst_total + igst_total;
        grandTotal = taxableAmount + transport_amount + packingAmt + other_amount + cgst_total + sgst_total + igst_total;

        let roundOffValue = (grandTotal - Math.floor(grandTotal));
        if (roundOffValue >= 0.50) {
            roundOffValue = (roundOffValue - 1); // Subtract 1 to get negative round-off
        }

        // Check if freight is taxable
        // let freightIsTaxable = document.getElementById('chk_freight_isTaxable').checked;
        // let freightTaxHSNIsSelected = document.getElementById('cmb_freight_hsn_id').value;

        // if (freightIsTaxable && (freightTaxHSNIsSelected === '' || freightTaxHSNIsSelected === null)) {
        //     $('#cmb_freight_hsn_id').removeAttr('optional', 'optional');
        //     $('#error_cmb_freight_hsn_id').text('Please select the freight tax hsn code.');
        //     $('#error_cmb_freight_hsn_id').show();
        // } else if (!freightIsTaxable) {
        //     setFreightHSNId('');
        // } else {
        //     $('#error_cmb_freight_hsn_id').hide();
        // }

        // if (freightIsTaxable) {
        //     let freightHsnSelectedOption = document.getElementById('cmb_freight_hsn_id').options[document.getElementById('cmb_freight_hsn_id').selectedIndex];
        //     let freightTaxRate = isNaN(parseFloat(freightHsnSelectedOption.getAttribute('hsn_rate'))) ? 0 : parseFloat(freightHsnSelectedOption.getAttribute('hsn_rate'));
        //     const freightTaxAmt = (freightAmt * freightTaxRate) / 100;

        //     if (cmb_customer_state_id === cmb_expected_branch_state_id && customerIsSez !== 'true' && cmb_expected_branch_state_id !== 39) {
        //         cgst_total += freightTaxAmt / 2;
        //         sgst_total += freightTaxAmt / 2;
        //     } else if (customerIsSez !== 'true' && cmb_expected_branch_state_id !== 39) {
        //         igst_total += freightTaxAmt;
        //     }
        // }

        setBasicTotal(validateNumberDateInput.current.decimalNumber(JSON.stringify(basic_total), 4));
        setCgstTotal(validateNumberDateInput.current.decimalNumber((cgst_total).toString(), 4));
        setSgstTotal(validateNumberDateInput.current.decimalNumber((sgst_total).toString(), 4));
        setIgstTotal(validateNumberDateInput.current.decimalNumber((igst_total).toString(), 4))
        setTaxableTotal(validateNumberDateInput.current.decimalNumber((taxableAmount).toString(), 4))
        setGrandTotal(Math.round(grandTotal));
        setRoundOff((roundOffValue).toFixed(2));
    }

    // Function to normalize hsnCode values for grouping
    function normalizeHsnCode(hsnCode) {
        return hsnCode.replace(/\s+/g, '').toUpperCase();
    }

    const calculateTaxationSummary = (salesInvoiceDetailData) => {
        try {
            const taxableData = [];
            // Use reduce to group data by HSN rate
            const groupedData = salesInvoiceDetailData.reduce((acc, item) => {
                const key = normalizeHsnCode(item.hsn_sac_code); // Group by HSN rate
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(item);
                return acc;
            }, {});

            // Convert the grouped data object into an array of arrays
            const groupedDataArray = Object.values(groupedData);

            groupedDataArray.forEach(group => {
                const {
                    hsn_sac_code,
                    hsn_sac_id,
                    dispatch_challan_no,
                    dispatch_challan_date,
                    dispatch_challan_version,
                    item_cgst_percent,
                    item_sgst_percent,
                    item_igst_percent,
                } = group[0];

                const groupTotalCGSTAmt = group.reduce((total, item) => total + (parseFloat(item.item_cgst_amount) || 0), 0);
                const groupTotalSGSTAmt = group.reduce((total, item) => total + (parseFloat(item.item_sgst_amount) || 0), 0);
                const groupTotalIGSTAmt = group.reduce((total, item) => total + (parseFloat(item.item_igst_amount) || 0), 0);
                const groupTotalAmt = group.reduce((total, item) => total + (parseFloat(item.item_total_amount) || 0), 0);
                const groupTaxableAmt = group.reduce((total, item) => total + (parseFloat(item.item_taxable_amount) || 0), 0);

                // Create an object for each group
                const groupObject = {
                    hsn_sac_code,
                    hsn_sac_id,
                    dispatch_challan_no,
                    dispatch_challan_date,
                    dispatch_challan_version,
                    summary_taxable_amount: groupTaxableAmt,
                    summary_cgst_percent: item_cgst_percent,
                    summary_cgst_total: groupTotalCGSTAmt,
                    summary_sgst_percent: item_sgst_percent,
                    summary_sgst_total: groupTotalSGSTAmt,
                    summary_igst_percent: item_igst_percent,
                    summary_igst_total: groupTotalIGSTAmt,
                    summary_total_amount: groupTotalAmt,
                };

                taxableData.push(groupObject);
            });

            // // Check if freight is taxable
            // let freightIsTaxable = document.getElementById('chk_freight_isTaxable').checked;
            // if (freightIsTaxable)
            //     FnCalculateFreightTaxation(taxableData)

            setSalesInvoiceTaxSummaryData(taxableData)
            calculateGrandTotal(salesInvoiceDetailData, taxableData)

        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }

    }

    // const FnCalculateFreightTaxation = (taxableData) => {
    //     let customerIsSez = $('#cmb_customer_id option:selected').attr('is_sez');
    //     let freightHsnSelectedOption = document.getElementById('cmb_freight_hsn_id').options[document.getElementById('cmb_freight_hsn_id').selectedIndex];
    //     let freightTaxRate = isNaN(parseFloat(freightHsnSelectedOption.getAttribute('hsn_rate'))) ? 0 : parseFloat(freightHsnSelectedOption.getAttribute('hsn_rate'));
    //     const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
    //     const freightTaxAmt = (freightAmt * freightTaxRate) / 100;
    //     // Create an object for each group
    //     const taxSummary = {
    //         hsn_sac_code: $('#cmb_freight_hsn_id option:selected').attr('lbl'),
    //         hsn_sac_id: $('#cmb_freight_hsn_id').val(),
    //         summary_taxable_amount: freightAmt,
    //         summary_cgst_percent: 0,
    //         summary_cgst_total: 0,
    //         summary_sgst_percent: 0,
    //         summary_sgst_total: 0,
    //         summary_igst_percent: 0,
    //         summary_igst_total: 0,
    //         summary_total_amount: freightAmt,
    //     };

    //     if (cmb_customer_state_id === cmb_expected_branch_state_id && customerIsSez !== 'true' && cmb_expected_branch_state_id !== 39) {
    //         // Set inter state tax totals
    //         taxSummary.summary_cgst_total = (freightTaxAmt / 2);
    //         taxSummary.summary_cgst_percent = (freightTaxRate / 2);
    //         taxSummary.summary_sgst_total = (freightTaxAmt / 2);
    //         taxSummary.summary_sgst_percent = (freightTaxRate / 2);
    //         taxSummary.summary_total_amount = (freightAmt + freightTaxAmt);
    //     } else if (customerIsSez !== 'true' && cmb_expected_branch_state_id !== 39) {
    //         // Set outer state tax totals
    //         taxSummary.summary_igst_total = (freightTaxAmt);
    //         taxSummary.summary_igst_percent = (freightTaxRate);
    //         taxSummary.summary_total_amount = (freightAmt + freightTaxAmt);
    //     }

    //     const existingFreightTaxationObj = taxableData.findIndex((record) => record.hsn_sac_id === parseInt(taxSummary.hsn_sac_id));
    //     if (existingFreightTaxationObj !== -1) {
    //         let object = taxableData[existingFreightTaxationObj]
    //         object.summary_taxable_amount = object.summary_taxable_amount + taxSummary.summary_taxable_amount
    //         object.summary_cgst_total = object.summary_cgst_total + taxSummary.summary_cgst_total;
    //         object.summary_sgst_total = object.summary_sgst_total + taxSummary.summary_sgst_total;
    //         object.summary_total_amount = object.summary_total_amount + taxSummary.summary_total_amount;
    //         object.summary_igst_total = object.summary_igst_total + taxSummary.summary_igst_total;
    //     } else {
    //         taxableData.push(taxSummary);                           // Push the new object if not found
    //     }
    // }

    // Reverse Calculations to update dettails data table on change of discount & freight amount
    const reverseCalculationForDetailData = (salesInvoiceDetailData) => {
        // const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
        const discount_amount = isNaN(parseFloat($('#txt_discount_amount').val())) ? 0 : parseFloat(parseFloat($('#txt_discount_amount').val()))

        if (salesInvoiceDetailData.length !== 0) {
            const updatedData = salesInvoiceDetailData.map(obj => {
                // Calculate item_freight_amount and discount_amount
                // let itemFreightAmount = (obj.item_amount / txt_basic_total) * freightAmt;
                let discountAmount;
                let calculatedPercent;

                if (discountAmount !== 0) {
                    discountAmount = (obj.item_amount / txt_basic_total) * discount_amount;
                    calculatedPercent = (discountAmount * 100) / obj.item_amount;
                }

                // Update the object with item_freight_amount, item_discount_amount, and item_discount_percent
                const updatedObj = {
                    ...obj,
                    // item_freight_amount: validateNumberDateInput.current.decimalNumber(JSON.stringify(itemFreightAmount), 4),
                    // item_discount_amount: discountAmount === 0 ? obj.item_discount_amount : validateNumberDateInput.current.decimalNumber(JSON.stringify(discountAmount), 4),
                    // item_discount_percent: discountAmount === 0 ? obj.item_discount_percent : validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedPercent), 2),
                    item_discount_amount: discountAmount === 0 ? 0 : validateNumberDateInput.current.decimalNumber(JSON.stringify(discountAmount), 4),
                    item_discount_percent: discountAmount === 0 ? 0 : validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedPercent), 2),
                };

                // Call FnMaterialWiseCalculations to update other properties
                const calculatedValues = FnMaterialWiseCalculations(updatedObj);

                // Merge the calculated values with the updated object
                const finalUpdatedObj = {
                    ...updatedObj,
                    ...calculatedValues,
                };

                return finalUpdatedObj;
            });

            setSalesInvoiceDetailData(updatedData);
        }
    }

    // This edffect call when Grn Details Data hook update
    useLayoutEffect(() => {
        if (salesInvoiceDetailData.length !== 0) {
            calculateTaxationSummary(salesInvoiceDetailData)
        }
    }, [salesInvoiceDetailData])

    const FnGetCustomerContactIds = () => {
        let customerContactPersonsIds = '';
        const checkboxes = $('.selectCustContactPerson:checked');
        checkboxes.each(function () {
            customerContactPersonsIds += $(this).val() + ":";
        });
        return customerContactPersonsIds.replace(/:$/, '');
    }

    const FnValidateSalesInvoice = async () => {
        // Validating header footer forms data.
        let headerDataIsValid = await validate.current.validateForm("SalesInvoiceHeaderFormId");
        if (!headerDataIsValid) { return false; }
        let InvoiceDetailsIsValid = true;

        if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
            $('#error_customer_contact_persons').text('Please select atleast one contact person.');
            $('#error_customer_contact_persons').show();
            setErrMsg('Please select atleast one contact person.');
            setOpenCustContAccord(true);
            setShowErrorMsgModal(true);
            return false;
        } else {
            $('#error_customer_contact_persons').hide();
        }

        if (salesInvoiceDetailData.length === 0) {
            setErrMsg('Please add atleast one material...');
            setShowErrorMsgModal(true);
            setOpenDetailsAccord(true);
            return InvoiceDetailsIsValid = false;
        } else {
            const tableRows = document.querySelectorAll('#salesInvoiceDetailsTbl tbody tr');
            let InvoiceDetailsIsValid = true;

            for (const row of tableRows) {
                const invoice_quantity = parseFloat(row.querySelector('input[id^="invoice_quantity_"]').value);
                const item_rate = parseFloat(row.querySelector('input[id^="item_rate_"]').value);
                // const materialUnit = row.querySelector('select[id^="product_material_unit_id_"]').value;

                if (isNaN(invoice_quantity) || invoice_quantity <= 0) {
                    row.querySelector('input[id^="invoice_quantity_"]').parentElement.dataset.tip = 'Invoice Quantity should not be zero or blank...!';
                    row.querySelector('input[id^="invoice_quantity_"]').focus();
                    InvoiceDetailsIsValid = false;
                    setOpenDetailsAccord(true);
                    break; // Exit the loop entirely if InvoiceDetailsIsValid is false
                } else {
                    delete row.querySelector('input[id^="invoice_quantity_"]').parentElement.dataset.tip;
                }

                if (isNaN(item_rate) || item_rate <= 0) {
                    row.querySelector('input[id^="item_rate_"]').parentElement.dataset.tip = 'Item rate should not be zero or blank...!';
                    row.querySelector('input[id^="item_rate_"]').focus();
                    InvoiceDetailsIsValid = false;
                    setOpenDetailsAccord(true);
                    break; // Exit the loop entirely if InvoiceDetailsIsValid is false
                } else {
                    delete row.querySelector('input[id^="item_rate_"]').parentElement.dataset.tip
                }

                // if (materialUnit === '') {
                //     row.querySelector('select[id^="product_material_unit_id_"]').parentElement.dataset.tip = 'Please select material unit...!';
                //     row.querySelector('select[id^="product_material_unit_id_"]').focus();
                //     InvoiceDetailsIsValid = false;
                //     setOpenDetailsAccord(true);
                //     break; // Exit the loop entirely if InvoiceDetailsIsValid is false
                // } else {
                //     delete row.querySelector('select[id^="product_material_unit_id_"]').parentElement.dataset.tip
                // }
            }
            // Code after the loop continues here...
        }

        let footerDataIsValid = await validate.current.validateForm("SalesInvoiceFooterFormId");
        if (!footerDataIsValid) { return false; }

        return InvoiceDetailsIsValid;
    }

    const FnCreateSalesInvoice = async (invoiceStatus) => {
        try {
            setIsLoading(true)
            let salesInvoiceValidated = true;
            if (!isApprove) {
                salesInvoiceValidated = await FnValidateSalesInvoice();
            } else {
                if (cmb_sales_invoice_status === '') {
                    $('#error_cmb_sales_invoice_status').show();
                    salesInvoiceValidated = false;
                    setIsLoading(false)
                }
            }
            let latestSalesInvoiceNo = txt_sales_invoice_no;
            if (salesInvoiceValidated) {
                let json = {
                    'TransHeaderData': {}, 'TransDetailData': [], 'TransActivityData': [], 'TransTaxSummaryData': [],
                    'commonIds': {
                        company_id: COMPANY_ID, company_branch_id: COMPANY_BRANCH_ID,
                        sales_invoice_no: latestSalesInvoiceNo, sales_invoice_version: txt_sales_invoice_version, financial_year: FINANCIAL_SHORT_NAME
                    }
                }
                if (sales_invoice_master_transaction_id === 0) {
                    latestSalesInvoiceNo = await generateSalesInvoiceNo();
                }

                let customerContactsIds = FnGetCustomerContactIds();
                const salesInvoiceMasterData = {
                    sales_invoice_master_transaction_id: sales_invoice_master_transaction_id,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    sales_invoice_type_id: cmb_sales_invoice_type_id,
                    sales_invoice_type: $('#cmb_sales_invoice_type_id').find(':selected').text(),
                    sales_invoice_creation_type: rb_sales_invoice_creation_type,
                    sales_invoice_no: txt_sales_invoice_no,
                    sales_invoice_date: dt_sales_invoice_date,
                    sales_invoice_version: txt_sales_invoice_version,
                    customer_id: cmb_customer_id,
                    customer_contacts_ids: customerContactsIds,
                    customer_state_id: cmb_customer_state_id,
                    customer_city_id: cmb_customer_city_id,
                    customer_gst_no: txt_customer_gst_no,
                    customer_pan_no: txt_customer_pan_no,
                    consignee_id: cmb_consignee_id,
                    consignee_state_id: cmb_consignee_state_id,
                    consignee_city_id: cmb_consignee_city_id,
                    consignee_gst_no: txt_consignee_gst_no,
                    consignee_pan_no: txt_consignee_pan_no,
                    transporter_id: '', // cmb_transporter_id
                    transporter_state_id: '',   // cmb_transporter_state_id
                    transporter_gst_no: '',   // txt_transporter_gst_no
                    expected_branch_id: cmb_expected_branch_id,
                    expected_branch_state_id: cmb_expected_branch_state_id,
                    expected_branch_city_id: cmb_expected_branch_city_id,
                    vehicle_no: '',   // txt_vehicle_no
                    vehicle_type: '', // txt_vehicle_type
                    transporter_challan_no: '',   // txt_transporter_challan_no
                    transporter_challan_date: '', // dt_transporter_challan_Date
                    transport_mode: '',   // cmb_transport_mode
                    transaction_type: cmb_transaction_type,
                    is_service: is_service,
                    is_sez: is_sez,
                    interim: interim,
                    loading_time: '', // dt_loading_time
                    approved_by_id: cmb_approved_by_id,
                    approved_date: dt_approved_date,
                    sales_invoice_status: cmb_sales_invoice_status === '' ? 'I' : cmb_sales_invoice_status,
                    payment_schedule_date: dt_payment_schedule_date,
                    basic_total: txt_basic_total,
                    transport_amount: 0, // txt_transport_amount
                    freight_amount: 0,   // txt_freight_amount
                    is_freight_taxable: 0,   // chk_freight_isTaxable
                    freight_hsn_code_id: '',  // cmb_freight_hsn_id
                    packing_amount: 0,   // txt_packing_amount
                    discount_percent: txt_discount_percent,
                    discount_amount: txt_discount_amount,
                    other_amount: txt_other_amount,
                    taxable_total: txt_taxable_total,
                    cgst_total: txt_cgst_total,
                    sgst_total: txt_sgst_total,
                    igst_total: txt_igst_total,
                    grand_total: txt_grand_total,
                    roundoff: txt_roundOff,
                    agent_id: cmb_agent_id,
                    agent_percent: txt_agent_percent,
                    agent_paid_status: cmb_agent_paid_status === '' ? 'P' : cmb_agent_paid_status,
                    agent_amount: txt_agent_amount,
                    sales_invoice_remark: txt_sales_invoice_remark,
                    other_terms_conditions: txt_other_terms_conditions,
                    remark: txt_remark,
                    is_active: is_active,
                    created_by: UserName
                }

                json.TransHeaderData = salesInvoiceMasterData;

                if (!isApprove) {
                    for (let index = 0; index < salesInvoiceDetailData.length; index++) {
                        const element = salesInvoiceDetailData[index];
                        let jsonRecord = {}
                        jsonRecord.sales_invoice_details_transaction_id = isApprove ? element.sales_invoice_details_transaction_id : 0;
                        jsonRecord.company_id = COMPANY_ID;
                        jsonRecord.company_branch_id = COMPANY_BRANCH_ID;
                        jsonRecord.financial_year = FINANCIAL_SHORT_NAME;
                        jsonRecord.sales_invoice_type_id = cmb_sales_invoice_type_id;
                        jsonRecord.sales_invoice_type = $('#cmb_sales_invoice_type_id').find(":selected").text();
                        jsonRecord.sales_invoice_creation_type = rb_sales_invoice_creation_type
                        jsonRecord.sales_invoice_no = latestSalesInvoiceNo;
                        jsonRecord.sales_invoice_date = dt_sales_invoice_date;
                        jsonRecord.sales_invoice_version = txt_sales_invoice_version;
                        // jsonRecord.dispatch_challan_no = element.dispatch_challan_no;
                        // jsonRecord.dispatch_challan_date = element.dispatch_challan_date;
                        // jsonRecord.dispatch_challan_version = element.dispatch_challan_version;
                        jsonRecord.service_reporting_code = '';
                        jsonRecord.service_reporting_date = '';
                        jsonRecord.sales_order_no = element.sales_order_no;
                        jsonRecord.sales_order_Date = element.sales_order_date;
                        jsonRecord.sales_order_version = element.sales_order_version;
                        jsonRecord.customer_order_no = element.customer_order_no;
                        jsonRecord.customer_order_Date = element.customer_order_Date;
                        // jsonRecord.dispatch_schedule_no = element.dispatch_schedule_no;
                        // jsonRecord.dispatch_schedule_date = element.dispatch_schedule_date;
                        // jsonRecord.dispatch_schedule_version = element.dispatch_schedule_version;
                        jsonRecord.work_schedule_code = '';
                        jsonRecord.planning_date = '';
                        jsonRecord.sr_no = element.sr_no
                        jsonRecord.so_sr_no = element.so_sr_no
                        jsonRecord.product_material_id = element.product_id;
                        jsonRecord.product_material_print_name = element.product_material_print_name;
                        jsonRecord.product_material_tech_spect = element.product_tech_spect;
                        jsonRecord.product_material_unit_id = element.product_unit_id;
                        jsonRecord.product_material_packing_id = element.product_packing_id;
                        jsonRecord.invoice_quantity = element.invoice_quantity;
                        jsonRecord.invoice_weight = element.invoice_weight;
                        jsonRecord.hsn_sac_id = element.hsn_sac_id;
                        jsonRecord.hsn_sac_percent = element.hsn_sac_percent;
                        jsonRecord.item_rate = element.item_rate;
                        jsonRecord.item_amount = element.item_amount;
                        jsonRecord.item_discount_percent = element.item_discount_percent;
                        jsonRecord.item_discount_amount = element.item_discount_amount;
                        jsonRecord.item_taxable_amount = element.item_taxable_amount;
                        jsonRecord.item_cgst_percent = element.item_cgst_percent;
                        jsonRecord.item_cgst_amount = element.item_cgst_amount;
                        jsonRecord.item_sgst_percent = element.item_sgst_percent;
                        jsonRecord.item_sgst_amount = element.item_sgst_amount;
                        jsonRecord.item_igst_percent = element.item_igst_percent;
                        jsonRecord.item_igst_amount = element.item_igst_amount;
                        jsonRecord.item_total_amount = element.item_total_amount;
                        // jsonRecord.invoice_return_quantity = element.invoice_return_quantity
                        // jsonRecord.invoice_return_weight = element.invoice_return_weight
                        jsonRecord.invoice_return_quantity = 0
                        jsonRecord.invoice_return_weight = 0
                        jsonRecord.item_payment_schedule_date = element.item_payment_schedule_date
                        jsonRecord.sales_invoice_item_status = 'I'
                        jsonRecord.sales_invoice_remark = element.sales_invoice_remark
                        jsonRecord.created_by = UserName;
                        // jsonRecord.dispatch_challan_details_transaction_id = element.dispatch_challan_details_transaction_id;

                        json.TransDetailData.push(jsonRecord);
                    }

                    // Activities.
                    $("input:checkbox[name=selectServiceReportedActivity]:checked").each(function () {
                        let selectedActivity = salesInvoiceActivitiesData.find(activity => activity.service_reporting_activities_details_transaction_id === parseInt($(this).val()));
                        const activity = {
                            sales_invoice_service_activity_transaction_id: 0,
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            financial_year: FINANCIAL_SHORT_NAME,
                            sales_invoice_no: txt_sales_invoice_no,
                            sales_invoice_date: dt_sales_invoice_date,
                            sales_invoice_version: txt_sales_invoice_version,
                            sales_invoice_type_id: cmb_sales_invoice_type_id,
                            sales_invoice_type: $('#cmb_sales_invoice_type_id').find(":selected").text(),
                            service_reporting_activities_details_transaction_id: selectedActivity.service_reporting_activities_details_transaction_id,
                            service_reporting_master_transaction_id: selectedActivity.service_reporting_master_transaction_id,
                            product_material_id: selectedActivity.product_material_id,
                            approved_by_id: selectedActivity.approved_by_id,
                            assigned_service_engineer_id: selectedActivity.assigned_service_engineer_id,
                            activity_id: selectedActivity.activity_id,
                            created_by: UserName,
                        }
                        json.TransActivityData.push(activity);
                    });

                    for (let index = 0; index < salesInvoiceTaxSummaryData.length; index++) {
                        const element = salesInvoiceTaxSummaryData[index];
                        const taxSummaryData = {
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            financial_year: FINANCIAL_SHORT_NAME,
                            sales_invoice_type_id: cmb_sales_invoice_type_id,
                            sales_invoice_type: $('#cmb_sales_invoice_type_id').find(':selected').text(),
                            customer_id: cmb_customer_id,
                            customer_state_id: cmb_customer_state_id,
                            customer_city_id: cmb_customer_city_id,
                            customer_contacts_ids: customerContactsIds,
                            expected_branch_id: cmb_expected_branch_id,
                            expected_branch_state_id: cmb_expected_branch_state_id,
                            expected_branch_city_id: cmb_expected_branch_city_id,
                            sales_invoice_no: txt_sales_invoice_no,
                            sales_invoice_date: dt_sales_invoice_date,
                            sales_invoice_version: txt_sales_invoice_version,
                            hsn_code_id: element.hsn_sac_id,
                            summary_taxable_amount: element.summary_taxable_amount,
                            summary_cgst_percent: element.summary_cgst_percent,
                            summary_cgst_total: element.summary_cgst_total,
                            summary_sgst_percent: element.summary_sgst_percent,
                            summary_sgst_total: element.summary_sgst_percent,
                            summary_igst_percent: element.summary_igst_percent,
                            summary_igst_total: element.summary_igst_total,
                            summary_total_amount: element.summary_total_amount,
                            created_by: UserName
                        }
                        json.TransTaxSummaryData.push(taxSummaryData)
                    }
                }
                const formData = new FormData();
                formData.append(`salesInvoiceMasterServiceData`, JSON.stringify(json))
                const requestOptions = { method: 'POST', body: formData };
                const saveInvoiceApi = await fetch(`${process.env.REACT_APP_BASE_URL}/api/FtSalesInvoiceMasterServices/FnAddUpdateRecord`, requestOptions)
                const response = await saveInvoiceApi.json();

                if (saveInvoiceApi.status !== 200 && response.success === 0) {
                    setIsLoading(false);
                    setErrMsg(response.error)
                    setShowErrorMsgModal(true)

                } else {
                    setIsLoading(false);
                    setMessage(response.message)
                    if (response.data.hasOwnProperty('sales_invoice_version') && response.data.hasOwnProperty('sales_invoice_no')) {
                        setModalOrderDetails(`Sales Invoice No: ${response.data.sales_invoice_no} and Version: ${response.data.sales_invoice_version} `)
                    }
                    if (sales_invoice_master_transaction_id === 0) {
                        setMessageForConfirmation('Do you want to export this sales invoice!...')
                    }
                    // setShowConfirmationModal(true);      // If confirmation required for email or export then uncomment it.
                    setShowSuccessMsgModal(true);
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }

    }

    const FnCancelSalesInvoice = async () => {
        try {
            let json = {
                'TransDetailData': salesInvoiceDetailData,
                'commonIds': {
                    company_id: COMPANY_ID,
                    sales_invoice_no: txt_sales_invoice_no
                }
            }

            const formData = new FormData();
            formData.append(`salesInvoiceData`, JSON.stringify(json))
            const requestOptions = { method: 'POST', body: formData };

            const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/FtSalesInvoiceMasterServices/FnCancelSalesInvoice`, requestOptions)
            const response = await apicall.json();
            if (response.success === 0) {
                setErrMsg(response.error)
                setShowErrorMsgModal(true)

            } else {
                setMessage(response.message)
                setModalOrderDetails(`Sales Invoice No: ${txt_sales_invoice_no} and Version: ${txt_sales_invoice_version} `)
                // setShowConfirmationModal(true);
                setShowSuccessMsgModal(true);
            }

        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }
    }


    const validateFields = () => {
        validate.current.validateFieldsOnChange('SalesInvoiceHeaderFormId')
        validate.current.validateFieldsOnChange('SalesInvoiceFooterFormId')
    }

    // Hide show customer order no's
    const FnToggleCustomerOrderNoList = () => {
        var checkboxes = document.getElementById("customer-order-ul");
        const items = document.querySelectorAll(".customer_order_no");
        if (!expanded) {
            if (items.length !== 0) {
                checkboxes.style.display = "block";
                expanded = true;
            }
        } else {
            checkboxes.style.display = "none";
            expanded = false;
        }
    }

    const removeMaterial = (product_id) => {
        const filterdedData = salesInvoiceDetailData.filter(item => item.product_id !== product_id);
        sessionStorage.setItem('filteredMaterialData', JSON.stringify(filterdedData))
        setSalesInvoiceDetailData(filterdedData)
    }

    // Sales Invoice Details
    const renderDetailsTable = useMemo(() => {
        return <>
            {
                salesInvoiceDetailData.length !== 0 ?
                    <Table className="erp_table erp_table_scroll" id='salesInvoiceDetailsTbl' responsive bordered striped>
                        <thead className="erp_table_head">
                            <tr>
                                {rb_sales_invoice_creation_type === 'M' ?
                                    <th className='erp_table_th'>Action</th> : null
                                }
                                <th className='erp_table_th'>Sr. No.</th>
                                <th className='erp_table_th'>Service</th>
                                {/* <th className='erp_table_th'>Material Unit</th> */}
                                {/* <th className={`erp_table_th ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'} `}>Dispatch Challan No</th> */}
                                <th className={`erp_table_th ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'} `}>Customer Order No</th>
                                {/* <th className={`erp_table_th ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'} `}>So Sr No</th> */}
                                {/* <th className={`erp_table_th ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'} `}>Dispatch quantity  </th> */}
                                {/* <th className={`erp_table_th ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'}  d-none`}>Dispatch weight</th> */}
                                <th className='erp_table_th'>Invoice Qty</th>
                                <th className='erp_table_th d-none'>Invoice Wt.</th>
                                <th className='erp_table_th'>Service Rate</th>
                                <th className='erp_table_th'>Service Amt.</th>
                                {/* <th className='erp_table_th'>Freight Amount</th> */}
                                <th className='erp_table_th'> Discount %</th>
                                <th className='erp_table_th'> Discount Amt.</th>
                                <th className='erp_table_th'> Taxable Amt.</th>
                                <th className='erp_table_th'> CGST %</th>
                                <th className='erp_table_th'> CGST Total</th>
                                <th className='erp_table_th'> SGST %</th>
                                <th className='erp_table_th'> SGST Total</th>
                                <th className='erp_table_th'> IGST %</th>
                                <th className='erp_table_th'> IGST Total</th>
                                <th className='erp_table_th'> Total Amt.</th>
                                {/* <th className='erp_table_th'> Invoice Return Qty.</th> */}
                                {/* <th className='erp_table_th d-none'>Invoice Return Wt.</th> */}
                                <th className='erp_table_th'>Payment Schedule Date</th>
                                <th className='erp_table_th'>Service Print Name</th>
                                <th className='erp_table_th' style={{ width: '100px', paddingRight: '100px' }}>Technical Specification</th>
                                {/* <th className='erp_table_th'> Packing Name</th> */}
                                <th className='erp_table_th'>HSN-SAC Code</th>
                                <th className='erp_table_th'>HSN-SAC %</th>
                                <th className={`erp_table_th ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'} `}>Cust. Ord. Date</th>
                                <th className={`erp_table_th ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'} `}>Sales Ord. No.</th>
                                <th className={`erp_table_th ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'} `}>Sales Ord. Date</th>
                                {/* <th className={`erp_table_th ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'} `}>Dispatch Schedule No</th> */}
                                {/* <th className={`erp_table_th ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'} `}>Dispatch Schedule Date</th> */}

                                <th className='erp_table_th' style={{ paddingRight: '50px' }}>Item Status</th>
                                <th className='erp_table_th' style={{ paddingRight: '100px' }}>Remark</th>
                                <th className="erp_table_th">Item Tcs Amt.</th>
                                <th className="erp_table_th">Item Cess Amt.</th>
                                <th className="erp_table_th">Item Stcess Amt.</th>
                                <th className="erp_table_th">Cess Advance Amt.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                salesInvoiceDetailData.map((salesInvoiceItem, index) =>
                                    <tr rowindex={index}>
                                        {
                                            rb_sales_invoice_creation_type === 'M' && (!isApprove || keyForViewUpdate !== 'view') ?
                                                <td className='erp_table_td'>
                                                    <div className="d-flex">
                                                        <MdDelete className="erp-delete-btn" onClick={() => removeMaterial(salesInvoiceItem.product_id)} />
                                                    </div>
                                                </td> : null
                                        }

                                        <td className="erp_table_td">{salesInvoiceItem.sr_no = index + 1}</td>
                                        <td className="erp_table_td">{salesInvoiceItem.product_name}</td>
                                        {/* <td className="erp_table_td">
                                            <select className="form-select form-select-sm mb-0" value={salesInvoiceItem.product_material_unit_id}
                                                onChange={(e) => { FnUpdateSalesInvoiceDetailsTblRow(salesInvoiceItem, e); }}
                                                id={`product_material_unit_id_${index} `}
                                                Headers='product_material_unit_id' disabled={keyForViewUpdate === 'view' || isApprove ? true : false}>
                                                <option value='0'>Select</option>
                                                {unitList.map(item =>
                                                    <option value={item.field_id}>{item.field_name}</option>
                                                )
                                                }
                                            </select>
                                        </td> */}
                                        {/* <td className={`erp_table_td ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'} `}>{salesInvoiceItem.dispatch_challan_no}</td> */}
                                        <td className={`erp_table_td ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'} `}>{validateNumberDateInput.current.formatDateToDDMMYYYY(salesInvoiceItem.customer_order_no)}</td>
                                        {/* <td className={`erp_table_td ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'} `}>{salesInvoiceItem.so_sr_no}</td> */}
                                        {/* <td className={`erp_table_td text - end ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'} `}>{salesInvoiceItem.dispatch_quantity}</td> */}
                                        {/* <td className={`erp_table_td text - end ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'}  d-none`}>{salesInvoiceItem.dispatch_weight}</td> */}
                                        <td className="erp_table_td text-end">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? salesInvoiceItem.invoice_quantity :
                                                <input type="text"
                                                    id={`invoice_quantity_${index}`}
                                                    className="erp_input_field mb-0 text-end"
                                                    value={salesInvoiceItem.invoice_quantity}
                                                    onChange={(e) => {
                                                        if (validateNumberDateInput.current.isInteger(e.target.value)) {
                                                            FnUpdateSalesInvoiceDetailsTblRow(salesInvoiceItem, e);
                                                        }
                                                    }}
                                                    onBlur={(e) => { FnUpdateSalesInvoiceDetailsTblRow(salesInvoiceItem, e); }}
                                                    Headers='invoice_quantity'
                                                />
                                            }
                                        </td>
                                        <td className="erp_table_td text-end d-none">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? salesInvoiceItem.invoice_weight :
                                                <input type="text"
                                                    id={`invoice_weight_${index}`}
                                                    className="erp_input_field mb-0 text-end"
                                                    value={salesInvoiceItem.invoice_weight}
                                                    onChange={(e) => { FnUpdateSalesInvoiceDetailsTblRow(salesInvoiceItem, e); }}
                                                    Headers='invoice_weight'
                                                />
                                            }
                                        </td>
                                        <td className="erp_table_td text-end">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? salesInvoiceItem.item_rate :
                                                <input type="text"
                                                    id={`item_rate_${index}`}
                                                    className="erp_input_field mb-0 text-end"
                                                    value={salesInvoiceItem.item_rate}
                                                    onChange={(e) => { FnUpdateSalesInvoiceDetailsTblRow(salesInvoiceItem, e); }}
                                                    onBlur={(e) => { FnUpdateSalesInvoiceDetailsTblRow(salesInvoiceItem, e); }}
                                                    Headers='item_rate'
                                                />
                                            }</td>


                                        <td className='erp_table_td text-end'>{salesInvoiceItem.item_amount}</td>
                                        {/* <td className='erp_table_td text-end'>{salesInvoiceItem.item_freight_amount}</td> */}
                                        <td className='erp_table_td text-end'>
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? salesInvoiceItem.item_discount_percent :
                                                <input type="text"
                                                    id={`item_discount_percent_${index}`}
                                                    className="erp_input_field mb-0 text-end"
                                                    value={salesInvoiceItem.item_discount_percent}
                                                    onChange={(e) => { FnUpdateSalesInvoiceDetailsTblRow(salesInvoiceItem, e); }}
                                                    Headers='item_discount_percent'
                                                />
                                            }
                                        </td>
                                        <td className='erp_table_td text-end'>
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? salesInvoiceItem.item_discount_amount :
                                                <input type="text"
                                                    id={`item_discount_amount_${index}`}
                                                    className="erp_input_field mb-0 text-end"
                                                    value={salesInvoiceItem.item_discount_amount}
                                                    onChange={(e) => { FnUpdateSalesInvoiceDetailsTblRow(salesInvoiceItem, e); }}
                                                    onBlur={(e) => { FnUpdateSalesInvoiceDetailsTblRow(salesInvoiceItem, e); }}
                                                    Headers='item_discount_amount'
                                                />
                                            }
                                        </td>

                                        <td className='erp_table_td text-end'> {salesInvoiceItem.item_taxable_amount}  </td>
                                        <td className='erp_table_td text-end'>{salesInvoiceItem.item_cgst_percent}</td>
                                        <td className='erp_table_td text-end'>{salesInvoiceItem.item_cgst_amount}</td>
                                        <td className='erp_table_td text-end'> {salesInvoiceItem.item_sgst_percent}</td>
                                        <td className='erp_table_td text-end'> {salesInvoiceItem.item_sgst_amount}</td>
                                        <td className='erp_table_td text-end'>{salesInvoiceItem.item_igst_percent}</td>
                                        <td className='erp_table_td text-end'> {salesInvoiceItem.item_igst_amount}</td>
                                        <td className='erp_table_td text-end'> {salesInvoiceItem.item_total_amount}</td>

                                        {/* <td className='erp_table_td text-end'> {salesInvoiceItem.invoice_return_quantity}</td> */}
                                        {/* <td className='erp_table_td text-end d-none'> {salesInvoiceItem.invoice_return_weight}</td> */}
                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                                ? validateNumberDateInput.current.formatDateToDDMMYYYY(salesInvoiceItem.item_payment_schedule_date)
                                                :
                                                <input type="date"
                                                    id={`item_payment_schedule_date_${index}`}
                                                    className="erp_input_field mb-0"
                                                    value={salesInvoiceItem.item_payment_schedule_date === '' || salesInvoiceItem.item_payment_schedule_date === undefined ? salesInvoiceItem.item_payment_schedule_date = today() : salesInvoiceItem.item_payment_schedule_date}
                                                    onChange={(e) => { FnUpdateSalesInvoiceDetailsTblRow(salesInvoiceItem, e); }}
                                                    Headers='item_payment_schedule_date'
                                                />
                                            }
                                        </td>
                                        <td className="erp_table_td">{salesInvoiceItem.product_material_print_name}</td>
                                        <td className="erp_table_td" style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{salesInvoiceItem.product_material_tech_spect}</td>
                                        {/* <td className="erp_table_td">{salesInvoiceItem.product_packing_name}</td> */}
                                        <td className="erp_table_td">{salesInvoiceItem.hsn_sac_code}</td>
                                        <td className='erp_table_td text-end'>{salesInvoiceItem.hsn_sac_percent}</td>
                                        <td className={`erp_table_td ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'} `}>{validateNumberDateInput.current.formatDateToDDMMYYYY(salesInvoiceItem.customer_order_Date)}</td>
                                        <td className={`erp_table_td ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'} `}>{salesInvoiceItem.sales_order_no}</td>
                                        <td className={`erp_table_td ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'} `}>{validateNumberDateInput.current.formatDateToDDMMYYYY(salesInvoiceItem.sales_order_date)}</td>

                                        {/* <td className={`erp_table_td ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'} `}>{salesInvoiceItem.dispatch_schedule_no}</td> */}
                                        {/* <td className={`erp_table_td ${rb_sales_invoice_creation_type === 'O' ? 'display' : 'd-none'} `}> {salesInvoiceItem.dispatch_schedule_Date}</td> */}
                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? salesInvoiceItem.sales_invoice_item_status_desc :
                                                <select id={`sales_invoice_item_status_${index}`} value={salesInvoiceItem.sales_invoice_item_status} className="form-select form-select-sm erp_input_field_table_txt m-0"
                                                    Headers="sales_invoice_item_status" onChange={(e) => { FnUpdateSalesInvoiceDetailsTblRow(salesInvoiceItem, e); }} disabled={keyForViewUpdate !== 'approve' ? true : false}  >
                                                    <option value="">Select</option>
                                                    <option value="I">Invoice Created</option>
                                                    <option value="E">Invoice Uploaded</option>
                                                    <option value="W">Eway Bill Uploaded</option>
                                                    <option value="B"> E-Invoice & E-way Bill Uploaded</option>
                                                    <option value="C">Payment Done</option>
                                                    <option value="X">Canceled</option>
                                                </select>
                                            }
                                        </td>
                                        <td className="erp_table_td">
                                            {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? salesInvoiceItem.sales_invoice_remark :
                                                <input type="text"
                                                    id={`sales_invoice_remark_${index}`}
                                                    className="erp_input_field mb-0"
                                                    value={salesInvoiceItem.sales_invoice_remark}
                                                    onChange={(e) => { FnUpdateSalesInvoiceDetailsTblRow(salesInvoiceItem, e); }}
                                                    Headers='sales_invoice_remark'
                                                />
                                            }
                                        </td>
                                        <td className="erp_table_td text-end">{salesInvoiceItem.item_stcess_amount}</td>
                                        <td className="erp_table_td text-end">{salesInvoiceItem.item_cess_amount}</td>
                                        <td className="erp_table_td text-end">{salesInvoiceItem.item_stcess_amount}</td>
                                        <td className="erp_table_td text-end">{salesInvoiceItem.item_cess_advance_amount}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table> : null
            }
        </>
    }, [salesInvoiceDetailData, isApprove])


    const renderActivitiesTable = useMemo(() => {
        return <>
            {
                salesInvoiceActivitiesData.length !== 0 ?
                    <Table className="erp_table erp_table_scroll" id='salesInvoiceActivitiesTbl' responsive bordered striped>
                        <thead className="erp_table_head">
                            <tr>
                                {rb_sales_invoice_creation_type !== 'M' && keyForViewUpdate !== 'view' ? <th className='erp_table_th'>Action</th> : null}
                                <th className='erp_table_th'>Sr. No.</th>
                                <th className='erp_table_th'>Cust. Ord. No.</th>
                                <th className='erp_table_th'>Service Report Code</th>
                                <th className='erp_table_th'>Service Report Date</th>
                                <th className='erp_table_th'>Assigned Service Eng.</th>
                                <th className='erp_table_th' style={{ width: '100px', paddingRight: '100px' }}>Service Name</th>
                                <th className='erp_table_th' style={{ width: '100px', paddingRight: '100px' }}>Activity</th>
                                <th className='erp_table_th' style={{ width: '200px', paddingRight: '150px' }}>Activity Desc.</th>
                                <th className='erp_table_th' style={{ width: '100px', paddingRight: '100px' }}>Extra Activities</th>
                                <th className='erp_table_th'>Activity Std. Hrs.</th>
                                <th className='erp_table_th'>Activity Actual Hrs.</th>
                                {/* <th className='erp_table_th'>Exp. Service Start Date</th> */}
                                {/* <th className='erp_table_th'>Exp. Service End Date</th> */}

                            </tr>
                        </thead>
                        <tbody>
                            {
                                salesInvoiceActivitiesData.map((reportedActivity, index) =>
                                    <tr rowindex={index}>
                                        {
                                            rb_sales_invoice_creation_type !== 'M' && keyForViewUpdate !== 'view' ?
                                                <td className='erp_table_td'>
                                                    <div className="d-flex">
                                                        <Form.Check className="erp-form-label selectServiceReportedActivity" type="checkbox"
                                                            name="selectServiceReportedActivity" id={'sselectServiceReportedActivity_' + reportedActivity.service_reporting_activities_details_transaction_id}
                                                            value={reportedActivity.service_reporting_activities_details_transaction_id}
                                                            {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}
                                                        />
                                                    </div>
                                                </td> : null
                                        }
                                        <td className="erp_table_td">{reportedActivity.sr_no = index + 1}</td>
                                        <td className="erp_table_td">{reportedActivity.customer_order_no}</td>
                                        <td className="erp_table_td">{reportedActivity.service_reporting_code}</td>
                                        <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(reportedActivity.service_reporting_date)}</td>

                                        <td className="erp_table_td">{reportedActivity.assigned_service_engineer}</td>
                                        <td className="erp_table_td" style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{reportedActivity.product_material_name}</td>
                                        <td className="erp_table_td" style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{reportedActivity.product_material_activity_name}</td>
                                        <td className="erp_table_td" style={{ width: '200px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{reportedActivity.product_material_activity_description}</td>
                                        <td className="erp_table_td" style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{reportedActivity.extra_activities}</td>
                                        <td className="erp_table_td text-end">{reportedActivity.product_material_activity_std_hour}</td>
                                        <td className="erp_table_td text-end">{reportedActivity.actual_hour}</td>
                                        {/* <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(reportedActivity.expected_service_schedule_start_date)}</td> */}
                                        {/* <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(reportedActivity.expected_service_schedule_end_date)}</td>                                        	 */}
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table> : null
            }
        </>
    }, [salesInvoiceActivitiesData, isApprove])



    const memoizedTaxSummaryComponent = useMemo(() => (
        <TaxSummary salesInvoiceTaxSummaryData={salesInvoiceTaxSummaryData} validateNumberDateInput={validateNumberDateInput} />
    ), [salesInvoiceTaxSummaryData]);

    const memorizedDocumentComponent = useMemo(() => {
        return <>
            {/* Document modal */}
            <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
                    <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                </Modal.Header>
                <Modal.Body>
                    <Document group_id={group_id} document_group={documentGroup} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    }, [showDocumentForm])

    // --------------------------------------- Delete the transaction functionality starts.------------------------------------------------------------------
    const deleteRecords = async () => {
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/FtSalesInvoiceMasterServices/FnDeleteRecord/${idList.sales_invoice_version}/${COMPANY_ID}/${UserName}?sales_invoice_no=${idList.sales_invoice_no}`, method)
            const responce = await deleteApiCall.json();
            if (responce.success == '1') {
                setShowDeleteModal(false)
                navigate(`/Transactions/SalesInvoiceServicesListing`);
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    // --------------------------------------- Delete the transaction functionality ends.------------------------------------------------------------------

    return (
        <>
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <ComboBox ref={comboDataFunc} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ExcelExport ref={exlsExp} />

            {isLoading ?
                <div className="spinner-overlay"  >
                    <div className="spinner-container">
                        <CircularProgress />
                        <span>Loading...</span>
                    </div>
                </div> :
                ''}
            <div className='erp_top_Form'>

                <div className='card p-2'>
                    <div className='card-header text-center py-0 mb-2'>
                        <label className='erp-form-label-lg main_heding'>Service Sales Invoice {actionType}</label>
                    </div>
                    <form id="SalesInvoiceHeaderFormId">


                        <div className="row">
                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Sales Invoice<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_sales_invoice_type_id" className="form-select form-select-sm" value={cmb_sales_invoice_type_id} onChange={(e) => { FnComboOnChange('SalesInvoiceType'); validateFields(); }} disabled={keyForViewUpdate === 'update'}>
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {salesInvoiceTypeList?.map(SalesInvoiceType => (
                                                <option value={SalesInvoiceType.field_id}>{SalesInvoiceType.field_name}</option>
                                            ))}

                                        </select>
                                        <MDTypography variant="button" id="error_cmb_sales_invoice_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Creation Type<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Sales Order Based" type="radio" lbl="Sales Order Based" value="O" name="rb_sales_invoice_creation_type" checked={rb_sales_invoice_creation_type === "O"} onClick={(e) => { setSalesInvoiceCreationType('O'); FnComboOnChange('CreationType'); }} disabled /> </div>
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Manual" type="radio" lbl="Mannual" value="M" name="rb_sales_invoice_creation_type" checked={rb_sales_invoice_creation_type === "M"} onClick={(e) => { setSalesInvoiceCreationType('M'); FnComboOnChange('CreationType'); }} disabled /> </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-3 col-12">
                                        <Form.Label className="erp-form-label">Invoice No, Date & Ver.<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-9 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id="txt_sales_invoice_no" className="erp_input_field" value={txt_sales_invoice_no} optional='optional' disabled />
                                            </div>
                                            <div className="col-12 col-md-4 pe-md-0 pt-md-0 pt-3 ps-md-1">
                                                <Form.Control type="date" id='dt_sales_invoice_date' className="erp_input_field" value={dt_sales_invoice_date} onChange={(e) => { setSalesInvoiceDate(e.target.value); validateFields(); }} />
                                                <MDTypography variant="button" id="error_dt_sales_invoice_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-2 pt-md-0 pt-3 ps-md-1">
                                                <Form.Control type="text" id='txt_sales_invoice_version' className="erp_input_field" value={txt_sales_invoice_version} optional='optional' disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Sales Invoice Status</Form.Label>
                                    </div>
                                    <div className='col-sm-9'>
                                        <select id="cmb_sales_invoice_status" className="form-select form-select-sm" value={cmb_sales_invoice_status} onChange={(e) => { setSalesInvoiceStatus(e.target.value); FnComboOnChange('SalesInvoiceStatus') }} optional='optional' disabled={isApprove ? false : true} >
                                            <option value="">Select</option>
                                            <option value="I">Invoice Created</option>
                                            <option value="E">Invoice Uploaded</option>
                                            <option value="W">Eway Bill Uploaded</option>
                                            <option value="B"> E-Invoice & E-way Bill Uploaded</option>
                                            <option value="C">Payment Done</option>
                                            <option value="X">Canceled</option>
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_sales_invoice_status" className='erp_validation' fontWeight="regular" color="error" style={{ display: 'none' }}>
                                            Please select sales invoice status!..
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3 col-12'>
                                        <Form.Label className="erp-form-label">Customer </Form.Label>
                                    </div>
                                    <div className='col-sm-8 col-10'>
                                        <select id="cmb_customer_id" className="form-select form-select-sm" value={cmb_customer_id} onChange={(e) => { { if (e.target.value !== '0') { setCustomerId(e.target.value) } }; FnComboOnChange('Customer'); validateFields(); }} optional='optional'>
                                            <option value="" disabled>Select</option>
                                            <option value="0">Add New Record+</option>
                                            {customerList?.map(customer => (
                                                <option value={customer.field_id} is_sez={`${customer.is_sez} `}>{customer.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                    <div className='col-sm-1 col-2'>
                                        <MdRefresh style={{ color: 'black' }} onClick={e => { FnUpdateComboBoxData('UpdateCustomers'); }} />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Customer State & City</Form.Label>
                                    </div>
                                    <div className="col-sm-9 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <select id="cmb_customer_state_id" className="form-select form-select-sm" value={cmb_customer_state_id} onChange={(e) => { setCustomerState(e.target.value); FnComboOnChange('CustomerState'); }} optional='optional' disabled>
                                                    <option value="" disabled>Select</option>
                                                    {stateList?.map(state => (
                                                        <option value={state.field_id} stateCode={state.state_code}>{state.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_customer_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                <select id="cmb_customer_city_id" className="form-select form-select-sm" value={cmb_customer_city_id} onChange={(e) => { setCustomerCity(e.target.value); FnComboOnChange('CustomerCity'); validateFields(); }} optional="optional" disabled>
                                                    <option value="" disabled>Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {customerCityList?.map(city => (
                                                        <option value={city.field_id}>{city.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_customer_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Customer GST & PAN No.</Form.Label>
                                    </div>
                                    <div className="col-sm-9 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id="txt_customer_gst_no" className="erp_input_field" value={txt_customer_gst_no} onChange={e => { setCustomerGstNo(e.target.value); validateFields(); }} optional='optional' />
                                                <MDTypography variant="button" id="error_txt_customer_gst_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                <Form.Control type="text" id="txt_customer_pan_no" className="erp_input_field" value={txt_customer_pan_no} onChange={e => { setCustomerPanNo(e.target.value); validateFields(); }} optional='optional' />
                                                <MDTypography variant="button" id="error_txt_customer_pan_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3 col-12'>
                                        <Form.Label className="erp-form-label">Consignee</Form.Label>
                                    </div>
                                    <div className='col-sm-8 col-10'>
                                        <select id="cmb_consignee_id" className="form-select form-select-sm" value={cmb_consignee_id} onChange={(e) => { setConsigneeId(e.target.value); FnComboOnChange('Consignee'); validateFields(); }} optional="optional">
                                            <option value="" disabled>Select</option>
                                            <option value="0">Add New Record+</option>
                                            {consigneeList?.map(consignee => (
                                                <option value={consignee.field_id}>{consignee.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_consignee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                    <div className='col-sm-1 col-2 refreshIconCol'>
                                        <MdRefresh style={{ color: 'black' }} onClick={e => { FnUpdateComboBoxData('UpdateConsignees'); }} />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Consignee State & City</Form.Label>
                                    </div>
                                    <div className="col-sm-9 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <select id="cmb_consignee_state_id" className="form-select form-select-sm" value={cmb_consignee_state_id} onChange={(e) => { setConsigneeState(e.target.value); FnComboOnChange('ConsigneeState'); validateFields(); }} optional="optional">
                                                    <option value="" disabled>Select</option>
                                                    {stateList?.map(state => (
                                                        <option value={state.field_id} stateCode={state.state_code}>{state.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_consignee_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                <select id="cmb_consignee_city_id" className="form-select form-select-sm" value={cmb_consignee_city_id} onChange={(e) => { setConsigneeCity(e.target.value); FnComboOnChange('ConsigneeCity'); }} optional="optional">
                                                    <option value="" disabled>Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {consigneeCityList?.map(city => (
                                                        <option value={city.field_id}>{city.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_consignee_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Consignee GST & PAN No.</Form.Label>
                                    </div>
                                    <div className="col-sm-9 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id="txt_consignee_gst_no" className="erp_input_field" value={txt_consignee_gst_no} onChange={e => { setConsigneeGstNo(e.target.value); validateFields(); }} optional='optional' />
                                                <MDTypography variant="button" id="error_txt_consignee_gst_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                <Form.Control type="text" id="txt_consignee_pan_no" className="erp_input_field" value={txt_consignee_pan_no} onChange={e => { setConsigneePanNo(e.target.value); validateFields(); }} optional='optional' />
                                                <MDTypography variant="button" id="error_txt_consignee_pan_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* sec row */}
                            <div className="col-sm-6 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Is Sez</Form.Label>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="Y" name="is_sez" checked={is_sez === "Y"} onClick={() => { setIsSez("Y"); }} disabled /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" value="N" name="is_sez" checked={is_sez === "N"} onClick={() => { setIsSez("N"); }} disabled /> </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    rb_sales_invoice_creation_type === 'O' ?
                                        <div className='row mb-1'>
                                            <div className="col-sm-3">
                                                <Form.Label className="erp-form-label">Customer Order<span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col-sm-7 mt-1">
                                                <div className="select-btn" onClick={() => FnToggleCustomerOrderNoList()} data-optional="optional">
                                                    <span className="form-select form-select-sm">{checkedCustomerOrderNoLength !== 0 ? checkedCustomerOrderNoLength + ' Selected' : 'Select'}</span>
                                                </div>
                                                <ul className="list-items" id="customer-order-ul">
                                                    {customerOrderNoList}
                                                </ul>
                                            </div>
                                            <div className="col-sm-2 mt-2 mt-md-0">
                                                <MDButton type="button" className={`erp-gb-button ${isApprove || keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={() => showSalesOrderDetails(storedSalesInvoiceDetailData)} >show</MDButton>
                                            </div >
                                        </div> : null
                                }

                                {/* <div className='row'>
                                    <div className='col-sm-3 col-12'>
                                        <Form.Label className="erp-form-label">Transporter</Form.Label>
                                    </div>
                                    <div className='col-sm-8 col-10'>
                                        <select id="cmb_transporter_id" className="form-select form-select-sm" value={cmb_transporter_id} onChange={(e) => { setTransporterId(e.target.value); FnComboOnChange('Transporter'); validateFields(); }} optional="optional">
                                            <option value="" disabled>Select</option>
                                            <option value="0">Add New Record+</option>
                                            {transporterList?.map(transporter => (
                                                <option value={transporter.field_id}>{transporter.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_transporter_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                    <div className='col-sm-1 col-2 refreshIconCol'>
                                        <MdRefresh style={{ color: 'black' }} onClick={e => { FnUpdateComboBoxData('UpdateTransporter'); }} />
                                    </div>
                                </div> */}

                                {/* <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Trans. State</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_transporter_state_id" className="form-select form-select-sm" value={cmb_transporter_state_id} onChange={(e) => { setTransporterState(e.target.value); FnComboOnChange('TransporterState'); validateFields(); }} optional="optional" disabled>
                                            <option value="" disabled>Select</option>
                                            {stateList?.map(state => (
                                                <option value={state.field_id} stateCode={state.state_code}>{state.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_transporter_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div> */}

                                {/* <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Trans. GST No. </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_transporter_gst_no" className="erp_input_field" value={txt_transporter_gst_no} onChange={e => { setTransporterGstNo(e.target.value); validateFields(); }} optional="optional" disabled />
                                        <MDTypography variant="button" id="error_txt_transporter_gst_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div> */}

                                {/* <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Trans. Challan No. & Date<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Form.Control type="text" id="txt_transporter_challan_no" className="erp_input_field" value={txt_transporter_challan_no} onChange={e => { setTransporterChallanNo(e.target.value); validateFields(); }} />
                                        <MDTypography variant="button" id="error_txt_transporter_challan_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                    <div className="col-sm-4">
                                        <Form.Control type="date" id='dt_transporter_challan_Date' className="erp_input_field" value={dt_transporter_challan_Date} onChange={e => { setTransporterChallanDate(e.target.value); validateFields(); }} />
                                        <MDTypography variant="button" id="error_dt_transporter_challan_Date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div> */}

                                {/* <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Transporter Mode</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_transport_mode" className="form-select form-select-sm" value={cmb_transport_mode} onChange={(e) => { setTransporterMode(e.target.value); FnComboOnChange('TransporterMode'); validateFields(); }} optional="optional">
                                            <option value="">Select</option>
                                            <option value="By Road">By Road</option>
                                            <option value="By Rail">By Rail</option>
                                            <option value="By Ship">By Ship</option>
                                            <option value="By Air">By Air</option>
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_transport_mode" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div> */}

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Transaction Type</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_transaction_type" className="form-select form-select-sm" value={cmb_transaction_type} onChange={(e) => { setTransactionType(e.target.value); FnComboOnChange('TransporterType'); validateFields(); }} optional="optional">
                                            <option value="">Select</option>
                                            <option value="1">1.&nbsp;Regular</option>
                                            <option value="2">2.&nbsp;Bill To-Ship</option>
                                            <option value="3">3.&nbsp;Bill From-Dispatch</option>
                                            <option value="4">4.&nbsp;Both 2 & 3</option>
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_transaction_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Expected Branch<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_expected_branch_id" className="form-select form-select-sm" value={cmb_expected_branch_id} onChange={(e) => { FnComboOnChange('ExpectedbranchId'); validateFields(); }}   >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {expectedBranchList?.map(Expectedbranchid => (<option value={Expectedbranchid.company_branch_id} is_sez={`${Expectedbranchid.is_sez} `}>{Expectedbranchid.company_branch_name}</option>))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_expected_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Exp. Branch State & City<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-9 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <select id="cmb_expected_branch_state_id" className="form-select form-select-sm" value={cmb_expected_branch_state_id} optional='optional' disabled>
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {stateList?.map(state => (<option value={state.field_id}>{state.field_name}</option>))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_expected_branch_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                <select id="cmb_expected_branch_city_id" className="form-select form-select-sm" value={cmb_expected_branch_city_id} optional='optional' disabled >
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {expectedBranchCityList?.map(city => (<option value={city.field_id}>{city.field_name}</option>))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_expected_branch_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Loading Time</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="datetime-local" id="dt_loading_time" className="erp_input_field" value={dt_loading_time} onChange={e => { setLoadingTime(e.target.value); validateFields(); }} optional='optional' />
                                        <MDTypography variant="button" id="error_dt_loading_time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div> */}

                                {
                                    isApprove ? <>
                                        <div className='row'>
                                            <div className='col-sm-3'>
                                                <Form.Label className="erp-form-label">Apprv. By & Date <span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col-sm-9 col-12">
                                                <div className="row">
                                                    <div className="col-12 col-md-8 ">
                                                        <select id="cmb_approved_by_id" className="form-select form-select-sm" value={UserId} onChange={(e) => { setApprovedById(e.target.value); validateFields(); }} disabled >
                                                            <option value="">Select</option>
                                                            <option value="0">Add New Record+</option>
                                                            {employeeList?.map(employee => (
                                                                <option value={employee.field_id}>{employee.field_name}</option>
                                                            ))}
                                                        </select>
                                                        <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                    <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                        <Form.Control type="date" id='dt_approved_date' className="erp_input_field" value={dt_approved_date} onChange={(e) => { setApprovedDate(e.target.value); validateFields(); }} />
                                                        <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> : null
                                }
                                <div className="row">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Is Service</Form.Label>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="Y" name="is_service" checked={is_service === "Y"} onClick={() => { setIsService("Y"); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" value="N" name="is_service" checked={is_service === "N"} onClick={() => { setIsService("N"); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <Form.Label className="erp-form-label">Interim</Form.Label>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="Y" name="interim" checked={interim === 'Y'} onClick={() => { setInterim('Y'); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" value="N" name="interim" checked={interim === 'N'} onClick={() => { setInterim('N'); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Pay. Schedule Date</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control type="date" id='dt_payment_schedule_date' className="erp_input_field" value={dt_payment_schedule_date} onChange={(e) => { setPaymentScheduleDate(e.target.value); validateFields(); }} optional='optional' />
                                        <MDTypography variant="button" id="error_dt_payment_schedule_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                {/* {
                                    custContactDetails.length !== 0 ? <> */}
                                <div className="row mt-3 mt-sm-1">
                                    <Accordion defaultActiveKey="0" activeKey={openCustContAccord ? '0' : null}>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenCustContAccord(!openCustContAccord)}>Customer Contact Details</Accordion.Header>
                                            <Accordion.Body className={`${keyForViewUpdate === 'view' ? 'p-0' : 'p-0'}`}>
                                                {
                                                    cmb_customer_id !== '' && cmb_customer_id !== '0'
                                                        ? <>
                                                            <div className={`row py-1 ps-3 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : ''}`}>
                                                                <Tooltip title="Add new customer's contact if doesn't exist in list...!" placement="top">
                                                                    <MDButton type="button" className={`erp-gb-button float-start col-12 col-md-4`} variant="button" fontWeight="regular" onClick={() => {
                                                                        localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                                        localStorage.setItem('customerIDs', JSON.stringify({ customerID: cmb_customer_id, keyForViewUpdate: 'update', compType: 'Master' }))
                                                                    }
                                                                    }  ><Link className={'text-light'} to={{ pathname: "/Masters/Customer" }} target="_blank" >Add New Customer's Contact +</Link></MDButton>
                                                                </Tooltip>
                                                                <Tooltip title="Refresh customer contact list...!" placement="top">
                                                                    <MDButton type="button" className={`erp-gb-button float-start col-1 ms-0 ms-md-2 mt-1 mt-md-0`} variant="button" fontWeight="regular" onClick={() => { FnGetCustomersContactPersonsDetails(parseInt(cmb_customer_id)) }}>Refresh</MDButton>
                                                                </Tooltip>
                                                            </div>
                                                        </>
                                                        : null
                                                }


                                                {
                                                    custContactDetails.length !== 0
                                                        ? <>
                                                            <div className="row">
                                                                <Table className={`erp_table ${custContactDetails.length > 4 ? 'erp_table_scroll' : ''} mt-2`} responsive bordered striped>
                                                                    <thead className="erp_table_head">
                                                                        <tr>
                                                                            <th className={`erp_table_th`}> <div className="col-1"><input type='checkbox' className="form-check-input me-1" name="selectAllCustContact" id="selectAllCustContact" onClick={(e) => { FnCustomerContactSelection('selectAllCustContact'); }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false} />Select</div></th>
                                                                            <th className='erp_table_th'>Contact Person</th>
                                                                            <th className='erp_table_th'>Designation</th>
                                                                            <th className='erp_table_th'>Contact No</th>
                                                                            <th className={`erp_table_th `}>Email</th>
                                                                            <th className={`erp_table_th`}>Alternate Email</th>
                                                                            <th className={`erp_table_th`}>Alternate Contact No</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            custContactDetails.map((item, index) =>
                                                                                <tr rowindex={index}>
                                                                                    <td className='erp_table_td'>
                                                                                        <input type='checkbox' className="form-check-input selectCustContactPerson" name="selectCustContactPerson" id={'selectCustContactPerson_' + item.customer_contact_id} contact_personId={item.customer_contact_id} value={item.customer_contact_id}
                                                                                            onClick={() => FnCustomerContactSelection('PartiallyCustContactSelection')} {...keyForViewUpdate === 'view' ? { disabled: 'disabled' } : {}} />
                                                                                    </td>
                                                                                    <td className="erp_table_td">{item.cust_contact_person}</td>
                                                                                    <td className="erp_table_td">{item.cust_designation}</td>
                                                                                    <td className="erp_table_td"> {item.cust_contact_no} </td>
                                                                                    <td className={`erp_table_td`}>{item.cust_email_id}</td>
                                                                                    <td className={`erp_table_td`}>{item.cust_alternate_EmailId}</td>
                                                                                    <td className={`erp_table_td`}>{item.cust_alternate_contact}</td>
                                                                                </tr>
                                                                            )

                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </>
                                                        : null
                                                }

                                                {
                                                    custContactDetails.length !== 0 && !['approve', 'view'].includes(keyForViewUpdate)
                                                        ? <>
                                                            <div className="row justify-content-center my-1">
                                                                <Tooltip title="Confirm selection...!" placement="right">
                                                                    <MDButton type="button" className={`erp-gb-button float-end col-1`} variant="button" fontWeight="regular"
                                                                        onClick={(e) => {
                                                                            let selectedCustCont = $('.selectCustContactPerson:checked');
                                                                            if (selectedCustCont.length > 0) {
                                                                                setOpenCustContAccord(false)
                                                                                $('#error_customer_contact_persons').hide();
                                                                            } else {
                                                                                $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                                                                                $('#error_customer_contact_persons').show();
                                                                            }
                                                                        }}
                                                                    >Confirm</MDButton>
                                                                </Tooltip>
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion >
                                    <MDTypography variant="button" id="error_customer_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                </div>
                                {/* </>
                                        : null
                                } */}

                            </div>
                        </div>


                    </form>
                </div>
                <hr />

                <Accordion defaultActiveKey="0" activeKey={openDetailsAccord ? '0' : null}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenDetailsAccord(!openDetailsAccord)}>Invoice Details </Accordion.Header>
                        <Accordion.Body className={`${rb_sales_invoice_creation_type === 'M' ? 'p-2' : 'p-0'} `}>
                            {/* {actionType === '(Create)' ? <div className="mb-2">
                                <MDButton type="button" className="erp-gb-button ms-2" variant="button" fontWeight="regular" onClick={ExportToExcel}>Export</MDButton>
                                <input type="file" id="Excelfile" ref={importFile} onChange={FnImportedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                <MDButton type="button" className="erp-gb-button ms-2" variant="button" fontWeight="regular" onClick={onImportClick}>Import</MDButton>
                            </div> : null} */}
                            {rb_sales_invoice_creation_type === 'M' && (
                                <div className="row" >

                                    <div className="col-md-4 col-12">
                                        <div className={`row ${(keyForViewUpdate === '' || keyForViewUpdate === 'update')} `}>
                                            <div className="col-md-8 col-12">
                                                <SearchItemFilter getSearchData={FnCloseProductList} formIdForValidate="" requiredCols={colNames} />
                                            </div>
                                            <div className="col-md-4">
                                                <MDButton type="button" fontWeight="regular" className="erp-gb-button" variant="button"
                                                    onClick={viewBomFilterForm} disabled={isApprove || keyForViewUpdate === 'view'} >
                                                    Add Services
                                                </MDButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {renderDetailsTable}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <hr />

                <Accordion defaultActiveKey="0" activeKey={openActivitiesAccord ? '0' : null}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenActivitiesAccord(!openActivitiesAccord)}> Activities </Accordion.Header>
                        <Accordion.Body className={`p-0 `}>
                            {renderActivitiesTable}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <hr />

                {memoizedTaxSummaryComponent}

                <hr />
                <div className='card p-2'>
                    <div className="card-header main_heding text-start">
                        <label className='erp-form-label-lg'>Sales Invoice Totals</label>
                    </div>
                    <div className="card-body">
                        <form id="SalesInvoiceFooterFormId">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">

                                    {/* first column */}
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Basic Total<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_basic_total" className="erp_input_field text-end" value={txt_basic_total} onChange={(e) => setBasicTotal(e.target.value)} optional='optional' disabled />
                                        </div>
                                    </div>

                                    {/* <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Transport Amount</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_transport_amount" className="erp_input_field text-end" value={txt_transport_amount} onChange={(e) => { setTransportAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(salesInvoiceDetailData, salesInvoiceTaxSummaryData) }} optional='optional' />
                                            <MDTypography variant="button" id="error_transport_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div> */}

                                    {/* <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Freight Amount</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="row">
                                                <div className="col-12 col-md-3 pt-md-0 pt-3 pe-md-0">
                                                    <Form.Check className="erp-form-label" type="checkbox" label={`IsTaxable`} id="chk_freight_isTaxable" checked={chk_freight_isTaxable} onChange={(e) => {
                                                        setFreightIsTaxable(e.target.checked);
                                                        if (!e.target.checked) {
                                                            calculateTaxationSummary(salesInvoiceDetailData);
                                                        }
                                                    }}
                                                        disabled={keyForViewUpdate === 'view' || isApprove ? true : false} />
                                                </div>
                                                <div className="col-12 col-md-3 pe-md-0">
                                                    <Form.Control type="text" id='txt_freight_amount' className="erp_input_field text-end" value={txt_freight_amount}
                                                        onChange={(e) => {
                                                            setFreightAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); reverseCalculationForDetailData(salesInvoiceDetailData);
                                                            // calculateTaxationSummary(salesInvoiceDetailData); 
                                                        }}
                                                        optional='optional'
                                                    />
                                                    <MDTypography variant="button" id="error_txt_freight_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>

                                                <div className="col-12 col-md-6">
                                                    <select id="cmb_freight_hsn_id" className="form-select form-select-sm" value={chk_freight_isTaxable ? cmb_freight_hsn_id : ''}
                                                        onChange={(e) => {
                                                            setFreightHSNId(e.target.value);
                                                            calculateTaxationSummary(salesInvoiceDetailData);
                                                        }}
                                                        disabled={!chk_freight_isTaxable || keyForViewUpdate === 'view' || isApprove} optional='optional'>
                                                        <option value="" disabled>Select</option>
                                                        {freightHSNList?.map(hsnCode => (
                                                            <option value={hsnCode.hsn_sac_id} hsn_rate={hsnCode.hsn_sac_rate} lbl={hsnCode.hsn_sac_code}>{hsnCode.hsn_sac_code}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_freight_hsn_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Packing Amount</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_packing_amount" className="erp_input_field text-end" value={txt_packing_amount} onChange={(e) => { setPackingAmount(e.target.value); calculateGrandTotal(salesInvoiceDetailData, salesInvoiceTaxSummaryData) }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_packing_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div> */}

                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Disc. % & Amt.</Form.Label>
                                        </div>
                                        <div className='col-12 col-md-4 pe-md-0'>
                                            <Form.Control type="text" id="txt_discount_percent" className="erp_input_field text-end" value={txt_discount_percent} onChange={(e) => { if (validateNumberDateInput.current.percentValidate(e.target.value)) { setDiscountPercent(e.target.value); FnFooterPercentChange(e); } }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_discount_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                        <div className="col-12 col-md-4 pt-md-0 pt-3 ps-md-1">
                                            <Form.Control type="text" id='txt_discount_amount' className="erp_input_field text-end" value={txt_discount_amount} onChange={(e) => {
                                                if (parseFloat(e.target.value) <= txt_basic_total) {
                                                    FnFooterAmountChange(e);
                                                    reverseCalculationForDetailData(salesInvoiceDetailData);
                                                }
                                            }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_discount_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Other Amt.</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_other_amount" className="erp_input_field text-end" value={txt_other_amount}
                                                onChange={(e) => { setOtherAmount(e.target.value); calculateGrandTotal(salesInvoiceDetailData, salesInvoiceTaxSummaryData) }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_other_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Taxable Total</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_taxable_total" className="erp_input_field text-end" value={txt_taxable_total} onChange={(e) => setTaxableTotal(e.target.value)} optional='optional' disabled />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">CGST Total</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_cgst_total" className="erp_input_field text-end" value={txt_cgst_total} onChange={(e) => { setCgstTotal(e.target.value); }} optional='optional' disabled />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">SGST total</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_sgst_total" className="erp_input_field text-end" value={txt_sgst_total} onChange={(e) => setSgstTotal(e.target.value)} optional='optional' disabled />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">IGST Total</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_igst_total" className="erp_input_field text-end" value={txt_igst_total} onChange={(e) => setIgstTotal(e.target.value)} optional='optional' disabled />
                                        </div>
                                    </div>


                                </div>

                                <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Grand Total & RoundOff<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-12 col-md-4 pe-md-0'>
                                            <Form.Control type="text" id="txt_grand_total" className="erp_input_field text-end" value={txt_grand_total} optional='optional' disabled />
                                        </div>
                                        <div className='col-12 col-md-4 pt-md-0 pt-3 ps-md-1'>
                                            <Form.Control type="text" id="txt_roundOff" className="erp_input_field text-end" value={txt_roundOff} optional='optional' disabled />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">E-Trans. Type</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="INV Data" type="radio" value="true" name="e_transaction_type" checked={rb_e_transaction_type === 'INV Data'} onClick={() => { setETransactionType('INV Data'); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Cess Amt.</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_cess_amount" className="erp_input_field text-end" value={txt_cess_amount} onChange={(e) => setCessAmount(e.target.value)} optional='optional' />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Tcs Amt.</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_tcs_amount" className="erp_input_field text-end" value={txt_tcs_amount} onChange={(e) => setTcsAmount(e.target.value)} optional='optional' />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Stcess Amt.</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_stcess_amount" className="erp_input_field text-end" value={txt_stcess_amount} onChange={(e) => setStcessAmount(e.target.value)} optional='optional' />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Cess Advance Amt.</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_cess_advance_amount" className="erp_input_field text-end" value={txt_cess_advance_amount} onChange={(e) => setCessAdvanceAmount(e.target.value)} optional='optional' />
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Agent</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_agent_id" className="form-select form-select-sm" value={cmb_agent_id} onChange={(e) => { setAgentId(e.target.value); FnComboOnChange('agent'); }} optional='optional'>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {agentsList?.map(cmb_agentId => (
                                                    <option value={cmb_agentId.field_id}>{cmb_agentId.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_agent_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                        <div className="col-1 refreshIconCol">
                                            <MdRefresh style={{ color: 'black' }} onClick={e => { FnUpdateComboBoxData('UpdateAgents'); }} />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Agent % & Amt.</Form.Label>
                                        </div>
                                        <div className='col-12 col-md-4 pe-md-0'>
                                            <Form.Control type="number" id="txt_agent_percent" className="erp_input_field" value={txt_agent_percent} onChange={(e) => { if (validateNumberDateInput.current.percentValidate(e.target.value)) { setAgentPercent(e.target.value) }; }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_agent_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                        <div className='col-12 col-md-4 pt-md-0 pt-3 ps-md-1'>
                                            <Form.Control type="text" id="txt_agent_amount" className="erp_input_field text-end" value={txt_agent_amount}
                                                onChange={(e) => { setAgentAmount(e.target.value); calculateGrandTotal(salesInvoiceDetailData, salesInvoiceTaxSummaryData) }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_agent_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Agent Paid Status</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_agent_paid_status" className="form-select form-select-sm" value={cmb_agent_paid_status} onChange={(e) => { setAgentPaidStatus(e.target.value); validateFields(); }} optional='optional' >
                                                <option value="">Select</option>
                                                <option value="P">Pending</option>
                                                <option value="A">Aprroved</option>
                                                <option value="C">Completed</option>
                                                <option value="X">Canceled</option>
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_agent_paid_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>

                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Sales Invoice Remark</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control as="textarea" rows={2} id="txt_sales_invoice_remark" className="erp_input_field" value={txt_sales_invoice_remark} optional='optional' onChange={(e) => { setSalesInvoiceRemark(e.target.value); }} />
                                            <MDTypography variant="button" id="error_txt_sales_invoice_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row mb-1'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Remark</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control as="textarea" rows={2} id="txt_remark" className="erp_input_field" value={txt_remark} onChange={(e) => { setRemark(e.target.value); validateFields(); }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row d-none'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Vehicle no</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_vehicle_no" className="erp_input_field" value={txt_vehicle_no} onChange={(e) => { setVehicleNo(e.target.value); }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_vehicle_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row d-none'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Vehicle Type</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_vehicle_type" className="erp_input_field" value={txt_vehicle_type} onChange={(e) => { setVehicleType(e.target.value); }} optional='optional' />
                                            <MDTypography variant="button" id="error_txt_vehicle_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Other terms and Conditions</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control as="textarea" rows={2} id="txt_other_terms_conditions" className="erp_input_field" value={txt_other_terms_conditions} optional='optional' onChange={(e) => { setOtherTermsConditions(e.target.value); }} />
                                            <MDTypography variant="button" id="error_txt_other_terms_conditions" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label"> Is Active</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="is_active" checked={is_active === true} onClick={() => { setIsActive(true); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" value="false" name="is_active" checked={is_active === false} onClick={() => { setIsActive(false); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>


                    <div className="text-center mb-5">
                        <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => { navigate(`/Transactions/SalesInvoiceServicesListing`) }} >Back</MDButton>
                        <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'} `} variant="button" onClick={() => FnCreateSalesInvoice(!isApprove ? 'Add' : 'A')}
                            fontWeight="regular">{actionLabel}</MDButton>
                        <MDButton type="button" className={`erp-gb-button ms-2 ${isApprove ? 'display' : 'd-none'} `}
                            variant="button" onClick={() => FnCreateSalesInvoice('R')}
                            fontWeight="regular">Reject</MDButton>

                        <MDButton type="button" className={`erp-gb-button ms-2 ${deletionKey === true ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => setShowDeleteModal(true)}> Delete </MDButton>

                        <MDButton className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' || isApprove ? 'd-none' : 'display'} `} onClick={() => setShowDocumentForm(true)} variant="button" fontWeight="regular" disabled={sales_invoice_master_transaction_id === 0}>Upload Document</MDButton>&nbsp;
                        {keyForViewUpdate === 'Cancel' && (
                            <MDButton className="erp-gb-button ms-2" variant="button" fontWeight="regular" onClick={FnCancelSalesInvoice}>Cancel
                            </MDButton>
                        )}
                        <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' ? '' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => printInvoice()} >Print Invoice<FiDownload className="erp-download-icon-btn" /></MDButton>

                        {/* <MDButton type="button" className="erp-gb-button ms-2" variant="button" fontWeight="regular" onClick={() => { navigate(`/Transactions/EInvoicingAndEWayBillingEntry`) }} >Upload E-Invoice</MDButton> */}
                    </div >

                </div>
                {/* Add new Record Popup */}
                {showAddRecModal ?
                    <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                        <Modal.Header>
                            <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
                            <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
                        </Modal.Header>
                        <Modal.Body className='erp_city_modal_body'>
                            {displayRecordComponent()}
                        </Modal.Body>
                        <Modal.Footer>
                            <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
                                fontWeight="regular">Close</MDButton>

                        </Modal.Footer>
                    </ Modal> : null
                }

                {memorizedDocumentComponent}

                {/* Generic Response Messaage Modals */}
                {/* <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} /> */}
                {showErrorMsgModal ?
                    <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />
                    : null
                }
            </div>
            {/* Send Email On Approval Modal ( // If confirmation required for email or export then uncomment it.)*/}
            {/* {showConfirmationModal ?
                <ConfirmationModal close={() => closeConfirmationModal()} confirmation={() => closeConfirmationModal()} show={[showConfirmationModal, message, modalOrderDetails, messageForConfirmation]} />
                : null
            } */}

            {showSuccessMsgModal ?
                <>
                    <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, message, modalOrderDetails]} />
                </>
                : null
            }

            {/* Delete Modal */}
            <Modal show={showDeleteModal} onHide={FnCloseDeleteModal} backdrop="static" keyboard={false} centered>
                <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseDeleteModal}></button></span>
                <Modal.Body className='erp_modal_body'>
                    <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                    <h6>Are you sure?</h6>
                    <div className="erp-form-label">Do you wish to delete this record ?</div>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <Button variant="success" className='erp-gb-button' onClick={FnCloseDeleteModal}>
                        Cancel
                    </Button>&nbsp;
                    <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button>
                </Modal.Footer>
            </Modal>

            {rb_sales_invoice_creation_type === 'M' ?
                <Modal
                    size="lg"
                    className="erp_document_Form"
                    show={showBomFilterForm}
                    onHide={FnCloseProductList}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title className="erp_modal_title">Materials List</Modal.Title>
                        <span>
                            <button
                                type="button"
                                className="erp-modal-close btn-close"
                                aria-label="Close"
                                onClick={FnCloseProductList}
                            ></button>
                        </span>
                    </Modal.Header>
                    <Modal.Body>
                        <React.Fragment>
                            <ProductListProductTypeWise closeModal={FnCloseProductList} props={{ product_type_id: cmb_sales_invoice_type_id }} />

                        </React.Fragment>
                    </Modal.Body>
                </Modal> : null}
        </>

    )
}

export default FrmSalesInvoiceServiceEntry
