import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'


let itemsPerPage = 0; // Number of items per page

const DispatchNote = React.forwardRef(({ dispatchNoteContent }, ref) => {
    const data = dispatchNoteContent.items
    const squareBorder = {
        border: '2px solid #000',
        padding: '15px',
    };

    const invoiceHeader = (
        <>
            <div className='bordered border-bottom-0 px-0'>
                <div className='row mb-0 p-2'>
                <div className="col-sm-2 text-center text-sm-start">
                        <img src={DakshabhiLogo} alt="master card" width="100" height="100" mt={1} />
                    </div>
                    <div className="col-sm-10 transformed">
                        <div className='erp-invoice-print-label text-center'>
                            <span className='erp-invoice-print-label-lg'>{dispatchNoteContent.company.company}</span><br />
                            <span className='erp-invoice-print-label-md'>({dispatchNoteContent.company.company_branch})</span>
                        </div>
                        <div className='erp-invoice-print-label-lg text-center'>
                            Dispatch Note
                        </div>
                    </div>
                </div>
                <div className='row border-top border-dark p-0 m-0'>
                    <div className='col-sm-4'>
                        <dt className='erp-invoice-print-label-md-lg'>Dispatch No & version : {dispatchNoteContent.dispatchDetails.dispatch_schedule_no} & {dispatchNoteContent.dispatchDetails.dispatch_schedule_version}</dt>
                    </div>
                    <div className='col-sm-4'>
                        <dt className='erp-invoice-print-label-md-lg'>Dispatch Type : {dispatchNoteContent.dispatchDetails.dispatch_schedule_creation_type_desc}</dt>
                    </div>
                    <div className='col-sm-4'>
                        <dt className='erp-invoice-print-label-md-lg text-end'>Dispatch Date: {dispatchNoteContent.dispatchDetails.dispatch_schedule_date}</dt>
                    </div>
                </div>
                <div className='row border-top border-dark p-0 m-0'>
                    <div className='row border-bottom border-top border-dark p-0 m-0'>
                        <div className="row p-0 m-0">
                            <div className="col-sm-6">
                                <dt className='erp-invoice-print-label-md-lg'>Customer  Details:</dt>
                                <dl className="row pb-0 mb-0">
                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Name:</dt>
                                    <dd className='col-sm-8 erp-invoice-print-label'> {dispatchNoteContent.customerDetails.customer}</dd>

                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Address:</dt>
                                    <dd className='col-sm-8 erp-invoice-print-label'> {dispatchNoteContent.customerDetails.address}</dd>

                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Email:</dt>
                                    <dd className='col-sm-8 erp-invoice-print-label'> {dispatchNoteContent.customerDetails.customer_email}</dd>
                                    


                                    <dl className="row pb-0 mb-0">
                                        <dt class="col-sm-2 erp-invoice-print-label-md-lg">State:</dt>
                                        <dd class="col-sm-10">
                                            <dl class="row m-0 p-0">
                                                <dt class="col-sm-4  erp-invoice-print-label"> {dispatchNoteContent.customerDetails.state}</dt>
                                                <dd class="col-sm-2 erp-invoice-print-label-md-lg">City:</dd>
                                                <dt class="col-sm-3 erp-invoice-print-label">{dispatchNoteContent.customerDetails.customer_city}</dt>
                                            </dl>
                                    </dd>
                                    </dl>
                                    {/* <dl className="row mb-0 mt-0">
                                        <div className="col-sm-6">
                                            <dt className='col-sm-6 erp-invoice-print-label-md-lg'>City: <span class="erp-invoice-print-label"> {dispatchNoteContent.customerDetails.customer_city} </span></dt>
                                        </div>
                                        <div className="col-sm-6">
                                            <dt className='col-sm-6 erp-invoice-print-label-md-lg'>State: <span class="erp-invoice-print-label"> {dispatchNoteContent.customerDetails.state}</span></dt>
                                        </div>
                                    </dl> */}
                                </dl>
                            </div>
                            <div className="col-sm-6">
                                <dt className='erp-invoice-print-label-md-lg'>Consignee Details:</dt>
                                <dl className="row pb-0 mb-0">
                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Name:</dt>
                                    <dd className='col-sm-8 erp-invoice-print-label'> {dispatchNoteContent.consigneeDetails.consignee}</dd>

                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Address:</dt>
                                    <dd className='col-sm-8 erp-invoice-print-label'> {dispatchNoteContent.consigneeDetails.address}</dd>

                                    <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Email:</dt>
                                    <dd className='col-sm-8 erp-invoice-print-label'> {dispatchNoteContent.consigneeDetails.consignee_email}</dd>

                                    <dl className="row pb-0 mb-0">
                                        <dt class="col-sm-2 erp-invoice-print-label-md-lg">State:</dt>
                                        <dd class="col-sm-10">
                                            <dl class="row m-0 p-0">
                                                <dt class="col-sm-4  erp-invoice-print-label"> {dispatchNoteContent.consigneeDetails.state}</dt>
                                                <dd class="col-sm-2 erp-invoice-print-label-md-lg">City:</dd>
                                                <dt class="col-sm-3 erp-invoice-print-label">{dispatchNoteContent.consigneeDetails.consignee_city}</dt>
                                            </dl>
                                    </dd>
                                    </dl>
{/* 
                                    <dl className="row mb-0 mt-0">
                                        <div className="col-sm-6">
                                            <dt className='col-sm-6 erp-invoice-print-label-md-lg'>City: <span class="erp-invoice-print-label"> {dispatchNoteContent.consigneeDetails.consignee_city} </span></dt>
                                        </div>
                                        <div className="col-sm-6">
                                            <dt className='col-sm-6 erp-invoice-print-label-md-lg'>State: <span class="erp-invoice-print-label"> {dispatchNoteContent.consigneeDetails.state}</span></dt>
                                        </div>
                                    </dl> */}

                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    const invoiceFooter = (
        <div className="invoice-footer">
            <div className='row'>
                <div className='col-sm-6'>
                </div>
                <div className='col-sm-6'>
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">For {dispatchNoteContent.company.company}</dt>
                    </div>
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg">Authorised Signatory</dt>
                    </div>
                </div>
            </div>
        </div>
    );
    return (
        <>
            {/* <div className="container"> */}

            <div className="row">
                <div className="col-12">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0 mt-3">

                                {/* <!--BILL HEADER--> */}
                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    {invoiceHeader}
                                </div>

                                {/* <!--PARCEL DETAILS--> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12 ">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            <thead>
                                                                <tr>
                                                                    <th className="erp_invoice_table_th" rowspan={2}>Sr No.</th>
                                                                    <th className="erp_invoice_table_th" rowspan={2}>Material Name(Technical Spect.)</th>
                                                                    {
                                                                        dispatchNoteContent.dispatchDetails.dispatch_schedule_creation_type_desc !== 'Manual' ? <>
                                                                            <th className="erp_invoice_table_th" rowspan={2}>Customer Order No</th>
                                                                        </> : null
                                                                    }
                                                                    <th className="erp_invoice_table_th" rowspan={2}>So <br/>Sr No.</th>
                                                                    <th className="erp_invoice_table_th" rowspan={2}>Exp. schedule date</th>
                                                                    <th className="erp_invoice_table_th" rowspan={2}>Unit</th>
                                                                    <th className="erp_invoice_table_th" rowspan={2}>Location</th>
                                                                    <th className="erp_invoice_table_th" rowspan={2}>Stock Qty.</th>
                                                                    <th className="erp_invoice_table_th" rowspan={2}>Expected Qty.</th>
                                                                    {/* <th className="erp_invoice_table_th" rowspan={2}>Expected Wt.</th> */}
                                                                    <th className="erp_invoice_table_th" rowspan={2}>Actual Qty.</th>
                                                                    {/* <th className="erp_invoice_table_th" rowspan={2}>Actual Wt.</th> */}
                                                                </tr>

                                                            </thead>
                                                            <tbody id='detailstableId'>
                                                                {
                                                                    data.map((item, index) =>
                                                                        <>
                                                                            <tr>
                                                                                <td className="erp_invoice_table_td text-center">{item.sr_no}</td>
                                                                                {/* <td className="erp_invoice_table_td">{item.product_material_print_name}<span>( {item.product_material_tech_spect} )</span></td> */}
                                                                                <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.product_material_print_name}<span>{item.product_material_tech_spect !== '' && item.product_material_tech_spect !== null && item.product_material_tech_spect !== undefined ? (item.product_material_tech_spect) : ''}</span></td>
                                                                                {
                                                                                    dispatchNoteContent.dispatchDetails.dispatch_schedule_creation_type_desc !== 'Manual' ? <>
                                                                                        <td className="erp_invoice_table_td">{item.customer_order_no}</td>
                                                                                    </> : null
                                                                                }
                                                                                <td className="erp_invoice_table_td text-end">{item.so_sr_no}</td>
                                                                                <td className="erp_invoice_table_td text-center">{item.expected_schedule_date}</td>
                                                                                <td className="erp_invoice_table_td">{item.product_rm_stock_unit_name}</td>
                                                                                <td className="erp_invoice_table_td">{item.location}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.stock_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.expected_dispatch_quantity}</td>
                                                                                {/* <td className="erp_invoice_table_td text-end">{item.expected_dispatch_weight}</td> */}
                                                                                <td className="erp_invoice_table_td text-end">{item.actual_dispatch_quantity}</td>
                                                                                {/* <td className="erp_invoice_table_td text-end">{item.actual_dispatch_weight}</td> */}
                                                                            </tr>

                                                                            {
                                                                                data.length - 1 === index ? <>

                                                                                    <tr id='footerTr'>
                                                                                        <td className="erp_invoice_table_td" colSpan={6}>
                                                                                            <div className='row'>
                                                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">Supervisor:</dt>
                                                                                                <dd className="col-sm-8 erp-invoice-print-label">{dispatchNoteContent.footer.supervisor}</dd>
                                                                                            </div>
                                                                                            <div className='row'>
                                                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">Approved By:</dt>
                                                                                                <dd className="col-sm-8 erp-invoice-print-label">{dispatchNoteContent.footer.approved_by}</dd>
                                                                                            </div>
                                                                                            <div className='row'>
                                                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">Other Terms Conditions:</dt>
                                                                                                <dd className="col-sm-8 erp-invoice-print-label">{dispatchNoteContent.footer.other_terms_conditions}</dd>
                                                                                            </div>
                                                                                            <div className='row'>
                                                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg">Dispatch Note Remark:</dt>
                                                                                                <dd className="col-sm-8 erp-invoice-print-label">{dispatchNoteContent.footer.dispatch_note_remark}</dd>
                                                                                            </div>

                                                                                        </td>
                                                                                        <td className="erp_invoice_table_td p-0 m-0" colSpan={6}>
                                                                                            <table className="table p-0 m-0 table-borderless">
                                                                                                <tbody>
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Total Qty:</span> </td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{dispatchNoteContent.footer.total_qty}</td>
                                                                                                    </tr>
                                                                                                    {/* <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Total Wt:</span> </td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{dispatchNoteContent.footer.total_weight}</td>
                                                                                                    </tr> */}
                                                                                                    <tr className="border-bottom border-dark">
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Actual Qty:</span></td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{dispatchNoteContent.footer.actual_qty}</td>
                                                                                                    </tr>
                                                                                                    {/* <tr className='border-0'>
                                                                                                        <td className="pt-0 pb-0"><span className="erp-invoice-print-label-md-lg pt-0 pb-0">Actual Wt:</span></td>
                                                                                                        <td className="erp-invoice-print-label-md-lg  text-end pt-0 pb-0">{dispatchNoteContent.footer.actual_weight}</td>
                                                                                                    </tr> */}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>

                                                                                    </tr>

                                                                                    <tr>
                                                                                        <td colSpan={12}>
                                                                                            {/* Your invoice footer content */}
                                                                                            {invoiceFooter}
                                                                                        </td>
                                                                                    </tr>
                                                                                </> : null
                                                                            }
                                                                        </>
                                                                    )
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
})

export default DispatchNote
