import { React, forwardRef, useImperativeHandle } from "react"

const ExcelExport = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({

        async excel(jsonToExportExcel,reportName) {
            var formData = new FormData();
            formData.append(`export`, JSON.stringify(jsonToExportExcel))
            const requestOptions = { method: 'POST', body: formData };
            const exporttoExcelApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/excelexport/FnExportToExcel`, requestOptions)
            const blob = await exporttoExcelApiCall.blob();
        
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', reportName + ".xlsx",);
            document.body.appendChild(link);
        
            link.click();
            link.parentNode.removeChild(link);


        }
    }))
    return (<div> </div>)
})

export default ExcelExport
