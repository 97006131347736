import React, { useState, useRef, useEffect, useLayoutEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Accordion, Button, Modal, Table } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { MdRefresh } from "react-icons/md";
import { CircularProgress } from "@material-ui/core";

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Datatable from "components/DataTable";
import { Link } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";

//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";
import ConfigConstants from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import FrmCity from "FrmGeneric/MCity/FrmCity";
import TaxSummary from "./TaxSummary";
import ExcelExport from "Features/Exports/ExcelExport";
import { FiDownload, FiPrinter } from "react-icons/fi";
import { renderToString } from "react-dom/server";
import GRNInvoice from "FrmGeneric/Invoice/GRNInvoice";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import Document from "Features/Document";
import GoodReceiptNote from "FrmGeneric/Invoice/GRNInvoice";

function FrmGoodsReceiptNoteEntry() {
  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_BRANCH_ID, FINANCIAL_SHORT_NAME, UserId, UserName, IS_EXCESS_ALLOWED, COMPANY_NAME } = configConstants;
  var expanded = false;
  const { state } = useLocation()
  let { idList, keyForViewUpdate, compType } = state || {};

  //for current date
  const today = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  // const currentDate = today();
  const currentDate = new Date().toISOString().split('T')[0];
  // UseRefs
  const validate = useRef();
  const comboDataFunc = useRef();
  const exlsExp = useRef();
  const generateAutoNoAPiCall = useRef();
  const validateNumberDateInput = useRef();
  const importFile = useRef(null)
  const navigator = useNavigate();

  // const financial_year = idList !== '' ? idList.financial_year : '';
  // const [financial_year, setFinancial_year] = useState(idList !== '' ? idList.financial_year : '');

  const [isApprove, setIsApprove] = useState(false);
  const [actionLabel, setActionLabel] = useState('Save')
  const [actionType, setActionType] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  let documentGroup = "GoodReceiptNote";
  let goodsReceiptNo = "";
  let goodsReceiptVersion = "";
  let financialYear = "";

  //Header hooks
  const [goods_receipt_master_transaction_id, setGoodsReceiptMasterTransactionId] = useState(0)
  const [txt_grn_no, setGRNno] = useState('0');
  const [txt_grn_version, setGRNVersion] = useState(1);
  const [checkedPurchaseOrderNoLength, setCheckedPurchaseOrderNoLength] = useState(0)

  const [dt_goods_receipt_date, setGoodReceiptDate] = useState(today());
  const [cmb_supplier_id, setSupplierId] = useState('');
  const [cmb_supplier_state_id, setSuppliersStateId] = useState('');
  const [cmb_supplier_city_id, setSuppliersCityId] = useState('');
  const [txt_supplier_challan_no, setSupplierChallanNo] = useState('');
  const [dt_supplier_challan_Date, setdtSupplierChallanDate] = useState('');
  const [cmb_expected_branch_id, setExpectedBranchId] = useState(COMPANY_BRANCH_ID);
  const [cmb_expected_branch_state_id, setExpectedBranchStateId] = useState('');
  const [cmb_expected_branch_city_id, setExpectedBranchCityId] = useState('');
  // const [cmb_goods_receipt_type_id, setGoodsReceiptTypeId] = useState('');
  const [rb_purchase_order_life, setPurchaseOrderLife] = useState('C')
  const [cmb_approved_by_id, setApprovedById] = useState();
  const [dt_approved_date, setApprovedDate] = useState(today);
  const [cmb_qa_by_id, setQabyId] = useState(UserId);
  const [dt_qa_date, setQadate] = useState(today);

  //footer hooks
  const [txt_basic_total, setBasicTotal] = useState(0);
  const [txt_transport_amount, setTransportAmount] = useState(0);
  const [txt_freight_amount, setFreightAmount] = useState(0);
  const [cmb_freight_hsn_id, setFreightHSNId] = useState('')
  const [chk_freight_isTaxable, setFreightIsTaxable] = useState(false);
  const [txt_packing_amount, setPackingAmount] = useState(0);
  const [txt_goods_receipt_discount_percent, setGoodsReceiptDiscountPercent] = useState(0);
  const [txt_goods_receipt_discount_amount, setGoodsReceiptDiscountAmount] = useState(0);
  const [txt_other_amount, setOtherAmount] = useState(0);
  const [txt_taxable_total, setTaxableTotal] = useState(0);
  const [txt_cgst_total, setCgstTotal] = useState(0);
  const [txt_sgst_total, setSgstTotal] = useState(0);
  const [txt_igst_total, setIgstTotal] = useState(0);
  const [txt_grand_total, setGrandTotal] = useState(0);
  const [txt_roundOff, setRoundOff] = useState(0);
  const [txt_agent_percent, setAgentPercent] = useState(0);
  const [cmb_agent_id, setAgentId] = useState()
  const [cmb_agent_paid_status, setAgentPaidStatus] = useState('');
  const [cmb_goods_receipt_status, setGoodsReceiptStatus] = useState('');
  const [txt_lr_no, setlrNo] = useState('');
  const [dt_lr_date, setlrDate] = useState('');
  const [txt_invoice_no, setInvoice_no] = useState('');
  const [dt_invoice_date, setInvoice_date] = useState('');
  const [txt_vehicle_no, setVehicleNo] = useState('');
  const [txt_other_terms_conditions, setOtherTermsConditions] = useState('');
  const [txt_remark, setRemark] = useState('');
  const [is_active, setIsActive] = useState(true);
  const [is_pree_closed, setIsPreeClosed] = useState(false);
  const [is_sez, setIsSez] = useState(false)


  //combo options
  const [rejectionParameterList, setRejectionParameterList] = useState([])
  const [purchaseOrderNoList, setPurchaseOrderNoList] = useState([])
  const [poInfoList, setPOInfoList] = useState([])
  const [supplierList, setSupplierList] = useState([])
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])
  const [expectedBranchList, setExpectedBranchList] = useState([])
  const [expectedBranchCityList, setExpectedBranchCityList] = useState([])
  const [goodReceiptTypeList, setGoodReceiptTypeList] = useState([])
  const [employeeList, setEmployeeList] = useState([]);
  const [freightHSNList, setfreightHSNList] = useState([]);
  const [agentsList, setAgentsList] = useState([]);
  const [godownsList, setGodownsList] = useState([]);
  const [unitList, setUnitList] = useState([]);

  // podetails Table Data
  const [grnDetailsData, setGrnDetailsData] = useState([])
  const [storedGrnDetailsData, setStoredGrnDetailsData] = useState([])

  // Taxation Table Data Hooks
  const [grnTaxSummaryData, setGrnTaxSummaryData] = useState([])

  //payment terms accordian
  const [paymentTermsData, setPaymentTermsData] = useState([]);
  const [existingPaymentTermsData, setExistingPaymentTermsData] = useState([])

  // supp contact details table data hooks
  const [SuppContactDetails, setSuppContactDetails] = useState([]);
  const [SuppContactDetailsCols, setSuppContactDetailsCols] = useState([]);

  // Hooks for handle the accordions.
  const [openContactsAccord, setOpenContactsAccord] = useState(false);
  const [openDetailsAccord, setOpenDetailsAccord] = useState(true);

  //Error Msg
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  // delete popup msg
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // Document Form
  const [showDocumentForm, setShowDocumentForm] = useState(false);
  const handleCloseDocumentForm = async () => {
    await showDocumentRecords();
    setShowDocumentForm(false);
  }

  // doc list
  const [docData, setDocumentData] = useState([]);

  // Succ Msg Modal
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    navigator(`/Transactions/TPurchaseOrder/GoodsReceiptNote/GoodsReceiptNoteListing`)
  }
  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState("");
  const [modalOrderDetails, setModalOrderDetails] = useState('')

  const [showAddRecModal, setShowAddRecModal] = useState(false);
  const [modalHeaderName, setHeaderName] = useState('')
  const handleCloseRecModal = async () => {
    switch (modalHeaderName) {
      case 'Customer City':
        comboOnChange('CustomerState');
        break;
      case 'Consignee City':
        comboOnChange('ConsigneeState');
        break;
      case 'Good Receipt Type':
        resetGlobalQuery();
        globalQuery.columns = ["field_id", "field_name", "product_type_short_name"]
        globalQuery.table = "smv_product_type"
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        const product_Types = await comboDataFunc.current.fillFiltersCombo(globalQuery)
        setGoodReceiptTypeList(product_Types)
        break;
      default:
        break;

    }
    setShowAddRecModal(false);
    sessionStorage.removeItem('dataAddedByCombo')
    setTimeout(() => {
      $(".erp_top_Form").css({ "padding-top": "110px" });
    }, 200)

  }

  // Confirmation Modal fields
  const [showConfirmationModal, setShowConfiemationModal] = useState(false)
  const [message, setMessage] = useState('')
  const [messageForConfirmation, setMessageForConfirmation] = useState('')
  const closeConfirmationModal = (key) => {
    switch (key) {
      case 'Export&Exit':
        ExportToExcel();
        setShowConfiemationModal(false);
        break;
      case 'Exit':
        setShowSuccessMsgModal(false);
        break;
      default:
        break;
    }
    navigator(`/Transactions/TPurchaseOrder/GoodsReceiptNote/GoodsReceiptNoteListing`)
  }


  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case 'approve':
        setActionType('(Approval)');
        setActionLabel('Approve')
        setIsApprove(true)
        await validate.current.readOnly("GRNHeaderFormId");
        await validate.current.readOnly("GRNFooterFormId");
        $('#cmb_goods_receipt_status').attr('disabled', false)
        $('#txt_remark').removeAttr('readOnly')
        break;
      case 'update':
        setActionType('(Modification)');
        setActionLabel('Update')
        break;
      case 'view':
        setActionType('(View)');
        await validate.current.readOnly("GRNHeaderFormId");
        await validate.current.readOnly("GRNFooterFormId");
        $("table").find("input,button,textarea,select").attr("disabled", "disabled");
        $('input[type="checkbox"]').prop('disabled', true);
        break;
      case 'delete':
        setActionType('(Delete)');
        await validate.current.readOnly("GRNHeaderFormId");
        await validate.current.readOnly("GRNFooterFormId");
        $("table").find("input,button,textarea,select").attr("disabled", "disabled");
        $('input[type="checkbox"]').prop('disabled', true);
        break;
      default:
        setActionType('(Creation)');
        break;
    }

  };

  useEffect(async () => {

    try {
      setIsLoading(true);
      await ActionType();
      await fillComobos();

      if (idList === '' || idList === undefined) {
        await generateGrnNo();
      } else {
        await FnCheckUpdateResponse()
      }
      await ActionType();
      // Close customer order no dropdown
      $(document).on('mouseup', function (e) {
        var container = $("#purchase-order-ul");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          container.hide();
        }
      });
    } catch (error) {
      console.error(error);
      navigator('/Error')
    } finally {
      setIsLoading(false);
    }
  }, [])


  const generateGrnNo = async () => {
    // const ptShortName = goodReceiptTypeList.find((item) => item.field_id === parseInt($("#cmb_goods_receipt_type_id").val()));
    const grn_no = await generateAutoNoAPiCall.current.generateTAutoNo("pt_goods_receipt_master", "goods_receipt_no", "", 'GR', "5");
    setGRNno(grn_no);
    return grn_no;
  }

  const fillComobos = async () => {
    try {
      resetGlobalQuery();
      globalQuery.columns = ["field_id", "field_name", "city_name", "state_name", "supp_branch_city_id", "supp_branch_state_id"]
      globalQuery.table = "cmv_supplier";
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
      const getSuppliers = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
      setSupplierList(getSuppliers)

      const getStates = await comboDataFunc.current.fillMasterData("cmv_state", "", "")
      setStateList(getStates)

      resetGlobalQuery();
      globalQuery.columns = ["company_branch_id", "company_branch_name", "branch_state_id", "branch_city_id", "state_name", "city_name", 'is_sez']
      globalQuery.table = "cmv_company_branch";
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });

      const getExpectedtBranchs = await comboDataFunc.current.fillFiltersCombo(globalQuery);
      setExpectedBranchList(getExpectedtBranchs);
      let expectedBranch = getExpectedtBranchs.find(item => item.company_branch_id === parseInt(COMPANY_BRANCH_ID))
      setExpectedBranchStateId(expectedBranch.branch_state_id)
      await comboOnChange('ExpectedBranchState')
      setExpectedBranchCityId(expectedBranch.branch_city_id)
      setIsSez(expectedBranch.is_sez)


      resetGlobalQuery();
      globalQuery.columns = ["field_id", "field_name", "product_type_short_name"]
      globalQuery.table = "smv_product_type";
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });

      const getProductTypes = await comboDataFunc.current.fillFiltersCombo(globalQuery);
      setGoodReceiptTypeList(getProductTypes);

      const getEmployees = await comboDataFunc.current.fillMasterData("cmv_employee", "", "");
      setEmployeeList(getEmployees);

      const getRejectionParameters = await comboDataFunc.current.fillMasterData("smv_product_rejection_parameters", "", "")
      setRejectionParameterList(getRejectionParameters)

      //get hsn code for freight amount
      resetGlobalQuery();
      globalQuery.columns = ["hsn_sac_id", "hsn_sac_code", "hsn_sac_rate"];
      globalQuery.table = "cmv_hsn_sac"
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      const getFreightHSNCodesApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
      setfreightHSNList(getFreightHSNCodesApiCall);

      // for agentsList List
      const getAgentsList = await comboDataFunc.current.fillMasterData("cmv_agent", "", "");
      setAgentsList(getAgentsList);
      // Set the default Agent
      let defaultAgent = getAgentsList.find(agent => agent.field_name === 'NA');
      if (defaultAgent) { setAgentId(defaultAgent.field_id); }


      // godownsList list for details table
      const getGodownsList = await comboDataFunc.current.fillMasterData("cmv_godown", "", "");
      setGodownsList(getGodownsList)

      // unit list for details table
      const getUnitList = await comboDataFunc.current.fillMasterData("smv_product_unit", "", "");
      setUnitList(getUnitList)

    } catch (error) {
      console.log("error: ", error)
      navigator('/Error')
    }
  }

  const FnCheckUpdateResponse = async () => {
    try {
      if (idList !== "") {
        goodsReceiptNo = idList.goods_receipt_no;
        setGRNno(goodsReceiptNo)
        goodsReceiptVersion = idList.goods_receipt_version;
        financialYear = idList.financial_year;
        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptDetails/FnShowAllDetailsAndMastermodelRecords/${goodsReceiptVersion}/${financialYear}/${COMPANY_ID}?goods_receipt_no=${goodsReceiptNo}`)
        const response = await apiCall.json();
        let grnMasterData = response.GoodsReceiptMasterRecord;
        let grnDetailsRecords = response.GoodsReceiptDetailsRecords;
        let grnTaxSummaryRecords = response.GoodsReceiptNotesTaxSummaryRecords;
        let paymentTermRecords = response.GoodsReceiptNotesPaymentTermsRecords;

        let { goods_receipt_master_transaction_id, goods_receipt_date, supplier_id, supplier_state_id,
          supplier_city_id, supplier_contacts_ids, expected_branch_id,
          expected_branch_state_id, expected_branch_city_id,
          goods_receipt_type_id,
          purchase_order_life, supplier_challan_no,
          supplier_challan_date, approved_by_id, approved_date, qa_by_id, qa_date,
          expected_schedule_date, basic_total, transport_amount, freight_amount,
          is_freight_taxable, freight_hsn_code_id, packing_amount, goods_receipt_discount_percent,
          goods_receipt_discount_amount, other_amount, taxable_total, cgst_total,
          sgst_total, igst_total, grand_total, roundoff, agent_id, agent_percent,
          agent_paid_status, goods_receipt_status, lr_no, lr_date, supplier_invoice_no, supplier_invoice_date, vehicle_no,
          other_terms_conditions, remark, is_active, is_sez, is_preeclosed
        } = grnMasterData;


        setGoodsReceiptMasterTransactionId(goods_receipt_master_transaction_id)

        // setGoodsReceiptTypeId(goods_receipt_type_id)
        setGoodReceiptDate(goods_receipt_date)
        setSupplierId(supplier_id)
        setExpectedBranchId(expected_branch_id)
        setExpectedBranchStateId(expected_branch_state_id)
        await comboOnChange('ExpectedBranchState')
        setExpectedBranchCityId(expected_branch_city_id)
        setIsSez(is_sez)

        await comboOnChange('Supplier')
        setSuppliersStateId(supplier_state_id)
        await comboOnChange('SupplierState')
        setSuppliersCityId(supplier_city_id)
        if (supplier_contacts_ids !== '') {
          const supplierContactIds = supplier_contacts_ids.split(':');
          supplierContactIds.forEach(function (contactId, index) {
            $('#selectSuppContactPerson_' + supplierContactIds[index]).prop('checked', true);
          });
          FnSupplierContactSelection('PartiallySuppContactSelection')
        }


        resetGlobalQuery();
        globalQuery.columns.push("DISTINCT (purchase_order_no)");
        globalQuery.table = "pt_goods_receipt_details"

        globalQuery.conditions.push({ field: "goods_receipt_no", operator: "=", value: txt_grn_no });
        globalQuery.conditions.push({ field: "goods_receipt_version", operator: "=", value: txt_grn_version });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

        const purchaseOrderNos = await comboDataFunc.current.fillFiltersCombo(globalQuery);

        // Loop through each checkbox with the name "purchaseOrderNoCheckBox"
        $("input:checkbox[name=purchaseOrderNoCheckBox]").each(function () {
          const checkboxValue = $(this).val();
          const orderNoIndex = purchaseOrderNos.findIndex((item) => item.purchase_order_no === checkboxValue)
          if (orderNoIndex !== -1) {
            $(this).prop('checked', true);
          }
        });
        setCheckedPurchaseOrderNoLength(purchaseOrderNos.length);

        setSupplierChallanNo(supplier_challan_no)
        setdtSupplierChallanDate(supplier_challan_date)
        setPurchaseOrderLife(purchase_order_life)
        setQabyId(qa_by_id)
        setQadate(qa_date)

        if (keyForViewUpdate === 'update') {
          // Assuming grnDetailsRecords is an array of objects
          const updatedGrnDetailsRecords = grnDetailsRecords.map(item => {
            // Update the keys you want to modify
            return {
              ...item,
              prev_grn_quantity: item.prev_grn_quantity - item.product_material_grn_accepted_quantity, // Replace with the actual updated value for key1
              prev_grn_weight: item.prev_grn_weight - item.product_material_grn_accepted_weight, // Replace with the actual updated value for key2
              // Add more key-value pairs to update as needed
            };
          });

          // Set the state with the updated array
          setGrnDetailsData(updatedGrnDetailsRecords)
          setStoredGrnDetailsData(updatedGrnDetailsRecords)
          // Process other asynchronous operations
          await Promise.all(updatedGrnDetailsRecords.map(async (grnItem) => {
            await renderGodownSectionList(grnItem.godown_id, grnItem.purchase_order_details_transaction_id);
            await renderGodownSectionBeansList(grnItem.godown_section_id, grnItem.purchase_order_details_transaction_id);
          }));

        } else {
          setGrnDetailsData(grnDetailsRecords)
          setStoredGrnDetailsData(grnDetailsRecords)
          // Process other asynchronous operations
          await Promise.all(grnDetailsRecords.map(async (grnItem) => {
            await renderGodownSectionList(grnItem.godown_id, grnItem.purchase_order_details_transaction_id);
            await renderGodownSectionBeansList(grnItem.godown_section_id, grnItem.purchase_order_details_transaction_id);
          }));

        }
        setGrnTaxSummaryData(grnTaxSummaryRecords)
        setPaymentTermsData(paymentTermRecords)

        setBasicTotal(basic_total)
        setTransportAmount(transport_amount)
        setFreightAmount(freight_amount)
        setFreightIsTaxable(is_freight_taxable)
        setFreightHSNId(freight_hsn_code_id)
        setPackingAmount(packing_amount)
        setGoodsReceiptDiscountAmount(goods_receipt_discount_amount)
        setGoodsReceiptDiscountPercent(goods_receipt_discount_percent)
        setOtherAmount(other_amount)
        setTaxableTotal(taxable_total)
        setCgstTotal(cgst_total)
        setSgstTotal(sgst_total)
        setIgstTotal(igst_total)
        setGrandTotal(grand_total)
        setRoundOff(roundoff)
        setAgentId(agent_id)
        setAgentPercent(agent_percent)
        setAgentPaidStatus(agent_paid_status)
        setGoodsReceiptStatus(goods_receipt_status)
        setRemark(remark)
        setlrNo(lr_no)
        setlrDate(lr_date)
        setInvoice_no(supplier_invoice_no)
        setInvoice_date(supplier_invoice_date)
        setVehicleNo(vehicle_no)
        setOtherTermsConditions(other_terms_conditions)
        setIsActive(is_active)
        setIsPreeClosed(is_preeclosed)

        // if (keyForViewUpdate === 'view' || isApprove) {
        //   setApprovedById(approved_by_id);
        //   setApprovedDate(approved_date);
        // }
        if (keyForViewUpdate === 'approve') {
          setApprovedById(UserId);
          setApprovedDate(today);
        } else {
          setApprovedById(approved_by_id);
          setApprovedDate(approved_date);
        }
      }
    } catch (error) {
      console.log("error: ", error)
      navigator('/Error')
    }

  }
  function FnSupplierContactSelection(key) {
    switch (key) {
      case 'selectAllSuppContact':
        $('.selectSuppContactPerson').prop('checked', $('#selectAllSuppContact').is(":checked"));
        break;

      case 'PartiallySuppContactSelection':
        $('#selectAllSuppContact').prop('checked', $('input:checkbox.selectSuppContactPerson:checked').length === $('input:checkbox.selectSuppContactPerson').length);
        break;
      default:
        break;
    }
  }

  // Hide show customer order no's
  const FnTogglePurchaseOrderNoList = () => {

    var checkboxes = document.getElementById("purchase-order-ul");
    const items = document.querySelectorAll(".purchase_order_no");
    if (!expanded) {
      if (items.length !== 0) {
        checkboxes.style.display = "block";
        expanded = true;
      } else {
        setErrMsg(`No Record's Found, against Supplier: ${$('#cmb_supplier_id').find(":selected").text()} & Branch: ${$('#cmb_expected_branch_id').find(":selected").text()}`)
        setShowErrorMsgModal(true)
      }
    } else {
      checkboxes.style.display = "none";
      expanded = false;
    }
  }

  const FnPurchaseOrderNoSelection = (purchase_order_no) => {
    if (parseInt(purchase_order_no) === 0) {
      if ($("#all_purchase_order_no_id").is(":checked") === false) {
        selectAllPurchaseOrderNo('uncheck');
      } else {
        selectAllPurchaseOrderNo('check');
      }
    }

    //Check Partiallly selected all or not if selected then check the check all radio of dispatch details
    const totalChkBoxes = document.querySelectorAll('input[name=purchaseOrderNoCheckBox]').length;
    const totalChkBoxesChked = document.querySelectorAll('input[name=purchaseOrderNoCheckBox]:checked').length;
    if (totalChkBoxes === totalChkBoxesChked) {
      document.getElementById('all_purchase_order_no_id').checked = true;
    } else if (totalChkBoxes > totalChkBoxesChked) {
      document.getElementById('all_purchase_order_no_id').checked = false;
    }
    setCheckedPurchaseOrderNoLength(totalChkBoxesChked)
  }


  const selectAllPurchaseOrderNo = (key) => { // fn to check all checkbox of contact ids
    const fliterCheckbox = document.getElementsByName('purchaseOrderNoCheckBox');
    if (key === 'check') {
      for (let chkAllCheckbox of fliterCheckbox) {
        document.getElementById(`${chkAllCheckbox.id}`).checked = true;
      }
    } else {
      for (let unchkAllCheckbox of fliterCheckbox) {
        document.getElementById(`${unchkAllCheckbox.id}`).checked = false;
      }
    }
  }

  function renderPurchaseOrderCheckbox(item, index) {
    if (index === 0) {
      return (
        <>
          <li className="item purchase_order_no" key={`All-${index}`}>
            <span className="checkbox">
              <input
                type="checkbox"
                id="all_purchase_order_no_id"
                value={index}
                className="erp_radio_button filterCheckBox"
                onChange={(event) => FnPurchaseOrderNoSelection(event.target.value)}
                disabled={isApprove || keyForViewUpdate === 'view' ? true : false}
                optional='optional'
              />
            </span>
            <span className="item-text">All</span>
          </li>
          <li className="item purchase_order_no" key={index}>
            <span className="checkbox">
              <input
                type="checkbox"
                name="purchaseOrderNoCheckBox"
                value={item.purchase_order_no}
                id={`filterCheck-${item.purchase_order_no}`}
                className="erp_radio_button purchaseOrderNoCheckBox"
                onChange={(event) => FnPurchaseOrderNoSelection(event.target.value)}
                disabled={isApprove || keyForViewUpdate === 'view' ? true : false}
                optional='optional'

              />
            </span>
            <span className="item-text">{item.purchase_order_no}</span>
          </li>
        </>
      );
    }
    return (
      <li className="item purchase_order_no" key={index}>
        <span className="checkbox">
          <input
            type="checkbox"
            fieldName={item.purchase_order_no}
            name="purchaseOrderNoCheckBox"
            value={item.purchase_order_no}
            id={`filterCheck-${item.purchase_order_no}`}
            className="erp_radio_button purchaseOrderNoCheckBox"
            onChange={(event) => FnPurchaseOrderNoSelection(event.target.value)}
            disabled={isApprove || keyForViewUpdate === 'view' ? true : false}

          />
        </span>
        <span className="item-text">{item.purchase_order_no}</span>
      </li>
    );
  }

  const comboOnChange = async (key) => {

    switch (key) {
      case 'Supplier':
        debugger;
        let supplierValue = document.getElementById("cmb_supplier_id").value;
        setSupplierId(supplierValue)
        if (supplierValue === "0") {
          localStorage.setItem('supplierIDs', JSON.stringify({ supplierId: 0, keyForViewUpdate: '', compType: 'Master' }))
          sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
          setHeaderName('Add Supplier')
          const newTab = window.open('/Masters/FrmSupplier', '_blank');
          if (newTab) {
            newTab.focus();
          }
        }
        if (supplierValue !== "0" && !isNaN(supplierValue)) {
          const getCityAndState = supplierList.find(item => item.field_id === parseInt(supplierValue))
          if (getCityAndState !== undefined) {
            setSuppliersStateId(getCityAndState.supp_branch_state_id);
            await comboOnChange('SupplierState')
            setSuppliersCityId(getCityAndState.supp_branch_city_id);
          }
          await loadPurchaseOrderNos()
          await FnGetsuppContactPersonsDetails(supplierValue);
        } else {
          setSuppliersStateId('')
          setSuppliersCityId('')
          setPurchaseOrderNoList([])
          setSuppContactDetails([])
          setSuppContactDetailsCols([])
          setPOInfoList([])
          setCheckedPurchaseOrderNoLength(0)
          setGrnDetailsData([])
          setGrnTaxSummaryData([])
          setPaymentTermsData([])
          setBasicTotal(0)
          setTransportAmount(0)
          setGoodsReceiptDiscountAmount(0)
          setGoodsReceiptDiscountPercent(0)
          setTaxableTotal(0)
          setCgstTotal(0)
          setSgstTotal(0)
          setIgstTotal(0)
          setGrandTotal(0)
          setRoundOff(0)

          // setExistingPaymentTermsData([])
        }

        break;

      case 'SupplierState':
        let supplierStateValue = parseInt(document.getElementById('cmb_supplier_state_id').value);
        const getCities = await comboDataFunc.current.fillMasterData("cmv_city", "state_id", supplierStateValue)
        setCityList(getCities)
        break;

      case 'ExpectedbranchId':
        let expectedBranchValue = parseInt(document.getElementById('cmb_expected_branch_id').value);
        setExpectedBranchId(expectedBranchValue)
        if (expectedBranchValue !== 0 && !isNaN(expectedBranchValue)) {
          const getBranchCityAndState = expectedBranchList.find(item => item.company_branch_id === expectedBranchValue)
          setExpectedBranchStateId(getBranchCityAndState.branch_state_id)
          comboOnChange('ExpectedBranchState')
          setExpectedBranchCityId(getBranchCityAndState.branch_city_id)
          setIsSez(getBranchCityAndState.is_sez)

          // If branch Changed then remove the details, tax summary & footer data of previous branch
          setGrnDetailsData([])
          setGrnTaxSummaryData([])
          setPaymentTermsData([])
          // setExistingPaymentTermsData([])
          setBasicTotal(0);
          setCgstTotal(0);
          setSgstTotal(0);
          setIgstTotal(0)
          setTaxableTotal(0)
          setGrandTotal(0);
          setRoundOff(0);
          await loadPurchaseOrderNos()
        } else {
          setExpectedBranchCityId('')
          setExpectedBranchStateId('')
        }

        if (expectedBranchValue === 0) {
          const newTab = window.open('Masters/Company', '_blank');
          if (newTab) {
            newTab.focus();
          }
        }
        break;
      case 'ExpectedBranchState':
        let expectedBranchStateValue = parseInt(document.getElementById('cmb_expected_branch_state_id').value);
        const getExpecBranchCities = await comboDataFunc.current.fillMasterData("cmv_city", "state_id", expectedBranchStateValue)
        setExpectedBranchCityList(getExpecBranchCities)
        break;
      case 'agent':
        if (document.getElementById('cmb_agent_id').value === "0") {
          const newTab = window.open('/Masters/Agent', '_blank');
          if (newTab) {
            newTab.focus();
          }
          setAgentId('')
        }
        break;
      case 'GoodReceiptStatus':
        let goodReceiptStatus = document.getElementById('cmb_goods_receipt_status').value
        if (goodReceiptStatus !== '') {
          $('#error_cmb_goods_receipt_status').hide()
        }
        break;
      case 'challan_no':
        let challan_no = document.getElementById('txt_supplier_challan_no').value
        setSupplierChallanNo(challan_no);
        if (challan_no !== '') {
          $('#error_txt_supplier_challan_no').hide()
          if (keyForViewUpdate === "") {
            const getchallanNo = await checkDuplicateChallan(challan_no);
            if (getchallanNo.length > 0) {
              $('#error_txt_supplier_challan_no').text("Challan No. Already Exists...!")
              $('#error_txt_supplier_challan_no').show()
            }
          }
        } else {
          $('#error_txt_supplier_challan_no').text("Please fill this field...!")
          $('#error_txt_supplier_challan_no').show()
        }
        break;

      default:
        break;
    }
  }
  const checkDuplicateChallan = async (challan_no) => {
    resetGlobalQuery();
    globalQuery.columns = ["supplier_id, supplier_challan_no"]
    globalQuery.table = "pt_goods_receipt_master"
    globalQuery.conditions.push({ field: "supplier_challan_no", operator: "=", value: challan_no });
    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0', });
    const getchallanNo = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
    return getchallanNo;
  }
  const loadPurchaseOrderNos = async () => {
    resetGlobalQuery();
    globalQuery.columns = ["DISTINCT purchase_order_no", "purchase_order_master_transaction_id", "purchase_order_version", "supplier_contacts_ids"]
    // globalQuery.table = "ptv_purchase_order_master_summary"
    globalQuery.table = "ptv_purchase_order_details"
    globalQuery.conditions.push({ field: "supplier_id", operator: "=", value: document.getElementById('cmb_supplier_id').value });
    globalQuery.conditions.push({ field: "expected_branch_id", operator: "=", value: document.getElementById('cmb_expected_branch_id').value });
    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0', });
    globalQuery.conditions.push({ field: "purchase_order_status", operator: "IN", values: ['A', 'I'] });
    globalQuery.orderBy = ["purchase_order_master_transaction_id desc"];
    // globalQuery.conditions.push({ field: "product_type_group", operator: "!=", value: 'SR' });
    const getPurchaseOrderNos = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
    setPOInfoList(getPurchaseOrderNos)
    let listItems = getPurchaseOrderNos.map(renderPurchaseOrderCheckbox);
    setPurchaseOrderNoList(listItems)
  }

  const displayRecordComponent = () => {
    switch (modalHeaderName) {
      case 'Customer City':
        return <FrmCity btn_disabled={true} />;
      case 'Consignee City':
        return <FrmCity btn_disabled={true} />;
      case 'Good Receipt Type':
        return <FrmMProductTypeEntry btn_disabled={true} />;
      default:
        return null;
    }
  }

  const FnFooterPercentChange = (e) => {
    const percent = e.target.value || 0;
    const amount = parseFloat((percent / 100)) * txt_basic_total;
    setGoodsReceiptDiscountAmount(validateNumberDateInput.current.decimalNumber(JSON.stringify(amount), 4));
    setGoodsReceiptDiscountPercent(validateNumberDateInput.current.decimalNumber(JSON.stringify(percent), 2));

    setTimeout(() => {
      calculateGrandTotal(grnDetailsData, grnTaxSummaryData);
      reverseCalculationForDiscount(grnDetailsData);

    }, 200);

  };

  const FnFooterAmountChange = (taxSummaryData) => {
    const amount = document.getElementById('txt_goods_receipt_discount_amount').value || 0;
    const percent = (amount * 100) / document.getElementById('txt_basic_total').value;
    setGoodsReceiptDiscountAmount(validateNumberDateInput.current.decimalNumber(JSON.stringify(amount), 4));
    setGoodsReceiptDiscountPercent(validateNumberDateInput.current.decimalNumber(JSON.stringify(percent), 2));
    setTimeout(() => {
      calculateGrandTotal(grnDetailsData, taxSummaryData);
    }, 200);
  };

  const FnGetsuppContactPersonsDetails = async (supplierval) => {
    try {
      resetGlobalQuery();
      globalQuery.columns = ["supplier_id", "supplier_branch_name", "supp_branch_id", "supplier_contact_id", "supp_contact_person", "supp_contact_no", "supp_alternate_contact", "supp_email_id", "supp_alternate_EmailId"]

      globalQuery.table = "cmv_supplier_contacts"
      globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
      globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
      globalQuery.conditions.push({ field: "supplier_id", operator: "=", value: parseInt(supplierval) });
      const getSupplierContacts = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);

      if (getSupplierContacts.length !== 0) {
        let column = [];
        let columnHeads = Object.keys(getSupplierContacts[0]);
        for (let colKey = 0; colKey < columnHeads.length; colKey++) {
          if (colKey === 0) {
            column.push({
              Headers: <div className="col-1"><input type='checkbox' className="me-1" name="selectAllSuppContact" id="selectAllSuppContact" onClick={(e) => { FnSupplierContactSelection('selectAllSuppContact'); }} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false} />Select</div>,
              accessor: "Action",
              Cell: row => (
                <div className="text-center">
                  <input type='checkbox' className="selectSuppContactPerson" name="selectSuppContactPerson" id={`selectSuppContactPerson_${row.row.original.supplier_contact_id}`} onClick={() => FnSupplierContactSelection('PartiallySuppContactSelection')} value={row.row.original.supplier_contact_id} disabled={keyForViewUpdate === 'approve' || keyForViewUpdate === 'view' ? true : false} />
                </div>
              ),
            });
          }
          if (!columnHeads[colKey].includes('_id')) {
            column.push({ Headers: columnHeads[colKey], accessor: columnHeads[colKey] });
          }
        }
        setSuppContactDetailsCols(column);
        setSuppContactDetails(getSupplierContacts);
      }

    } catch (error) {
      console.log("error: ", error)
      navigator('/Error')
    }
  }

  const showPODetails = async (storedGrnDetailsData) => {
    let json = { 'purchase_order_nos': [], 'Ids': {} }
    $("input:checkbox[name=purchaseOrderNoCheckBox]:checked").each(function () {
      json.purchase_order_nos.push($(this).val())
    })
    json.Ids.goods_receipt_version = txt_grn_version
    json.Ids.company_id = COMPANY_ID
    json.Ids.goods_receipt_no = goods_receipt_master_transaction_id === 0 ? "0" : txt_grn_no
    json.Ids.expected_branch_id = document.getElementById(`cmb_expected_branch_id`).value

    if (json.purchase_order_nos.length !== 0) {
      const formData = new FormData();
      formData.append('purchaseOrderNos', JSON.stringify(json))
      const requestOptions = { method: 'POST', body: formData };
      let apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptDetails/FnShowPurchaseOrderDetailsRecords`, requestOptions)
      const response = await apicall.json()
      if (response.length !== 0) {
        displayGrnDetailsData(response, json.purchase_order_nos, storedGrnDetailsData)
        FnCheckSuppContacts(json.purchase_order_nos);
        setPaymentTermsData(response.purchasePaymentTerms)

      }
    }
    else {
      setGrnDetailsData([])
      setGrnTaxSummaryData([])
      setBasicTotal(0)
      setFreightAmount(0)
      setGoodsReceiptDiscountAmount(0)
      setGoodsReceiptDiscountPercent(0)
      setTaxableTotal(0)
      setCgstTotal(0)
      setSgstTotal(0)
      setIgstTotal(0)
      setGrandTotal(0)
      setRoundOff(0)
      $("input:checkbox[name=selectSuppContactPerson]").prop('checked', false);
    }
  }
  //Fn for set checked supplier contacts from POs
  const FnCheckSuppContacts = (po_nos) => {
    let supplier_conts_id = "";
    poInfoList.forEach((item) => {
      const isMatchingId = po_nos.includes(item.purchase_order_no);
      supplier_conts_id += isMatchingId ? item.supplier_contacts_ids + ":" : "";
    });
    // Convert the concatenated string to an array, remove duplicates, and join back to a string
    const distinctSupplierContsIds = [...new Set(supplier_conts_id.split(':'))];
    $('input[name="selectSuppContactPerson"]').prop('checked', false);
    distinctSupplierContsIds.forEach((id) => {
      $('input[name="selectSuppContactPerson"][value="' + id + '"]').prop('checked', true);
    });
    FnSupplierContactSelection('PartiallySuppContactSelection');
  };

  let footer_disc_amt = 0;
  let totalBasicAmount = 0;

  const calculateValues = (item) => {
    const product_material_grn_accepted_quantity = item.product_material_po_approved_quantity - item.prev_grn_quantity;
    const product_material_grn_accepted_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(parseFloat(item.product_material_po_approved_weight) - parseFloat(item.prev_grn_weight)), 4);
    const material_basic_amount = product_material_grn_accepted_quantity * item.material_rate;
    footer_disc_amt += item.material_discount_amount;
    totalBasicAmount += material_basic_amount;
    return {
      ...item,
      product_material_grn_quantity: product_material_grn_accepted_quantity,
      product_material_grn_weight: product_material_grn_accepted_weight,
      product_material_grn_rejected_quantity: product_material_grn_accepted_quantity - product_material_grn_accepted_quantity,
      product_material_grn_rejected_weight: product_material_grn_accepted_weight - product_material_grn_accepted_weight,
      product_material_grn_accepted_quantity,
      product_material_grn_accepted_weight,
      material_basic_amount,
      product_material_rejection_reason_id: '',
    };
  };

  const displayGrnDetailsData = async (response, purchase_order_nos, storedGrnDetailsData) => {
    let updatedGrnDataForGodown = [];
    // Calculate all values first
    const calculatedGrnDetailsData = response.data.map(calculateValues);

    // Calculate total values
    const totalFooterDiscAmt = calculatedGrnDetailsData.reduce((acc, item) => acc + item.material_discount_amount, 0);
    const totalMaterialBasicAmount = calculatedGrnDetailsData.reduce((acc, item) => acc + item.material_basic_amount, 0);

    // Set the total values
    footer_disc_amt = totalFooterDiscAmt;
    totalBasicAmount = totalMaterialBasicAmount;

    // Set the derived values using setGoodsReceiptDiscountAmount and setGoodsReceiptDiscountPercent
    const percent = (footer_disc_amt * 100) / totalBasicAmount;
    setGoodsReceiptDiscountAmount(validateNumberDateInput.current.decimalNumber(JSON.stringify(footer_disc_amt), 4));
    setGoodsReceiptDiscountPercent(validateNumberDateInput.current.decimalNumber(JSON.stringify(percent), 2));

    // Now set the GrnDetailsData array
    setGrnDetailsData((prevArray) => {
      if (goods_receipt_master_transaction_id === 0) {
        const combinedArray = [
          ...prevArray.filter(item => purchase_order_nos.includes(item.purchase_order_no)),
          ...calculatedGrnDetailsData,
        ];
        updatedGrnDataForGodown = combinedArray;
        return combinedArray;
      } else {
        const uniquePurchaseOrderNos = new Set(prevArray.map(item => item.purchase_order_no));
        const matchingItems = prevArray.filter(item => purchase_order_nos.includes(item.purchase_order_no));

        const processedItems = calculatedGrnDetailsData.filter(item => {
          return (
            purchase_order_nos.includes(item.purchase_order_no) &&
            !uniquePurchaseOrderNos.has(item.purchase_order_no) &&
            !storedGrnDetailsData.some(storedItem => storedItem.purchase_order_no === item.purchase_order_no)
          );
        });

        const additionalItems = storedGrnDetailsData.filter(item => purchase_order_nos.includes(item.purchase_order_no) && !uniquePurchaseOrderNos.has(item.purchase_order_no));
        const combinedArray = [...matchingItems, ...processedItems, ...additionalItems];
        updatedGrnDataForGodown = combinedArray;
        return combinedArray;
      }
    });

    // Process other asynchronous operations
    await Promise.all(updatedGrnDataForGodown.map(async (grnItem) => {
      await renderGodownSectionList(grnItem.godown_id, grnItem.purchase_order_details_transaction_id);
      await renderGodownSectionBeansList(grnItem.godown_section_id, grnItem.purchase_order_details_transaction_id);
    }));

  };


  // This edffect call when Grn Details Data hook update
  useEffect(() => {
    const FunctionCall = () => {
      if (grnDetailsData.length !== 0) {
        calculateTaxationSummary(grnDetailsData)
      }
    }
    FunctionCall();
  }, [grnDetailsData])


  const updateGrnDetailsTblRow = async (rowData, event) => {
    // Destructuring the object
    let {
      product_material_po_approved_quantity,
      prev_grn_quantity,
      product_material_grn_quantity,
      product_material_std_weight,
      product_material_grn_accepted_quantity,
      material_rate,
      material_basic_amount
    } = rowData;

    const detailData = [...grnDetailsData]
    const taxSummaryData = [...grnTaxSummaryData]
    let clickedColName = event.target.getAttribute('Headers');
    const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))

    let enteredValue = event.target.value;
    if (isNaN(enteredValue)) {
      enteredValue = 0;
    }

    switch (clickedColName) {
      case 'product_material_grn_quantity':
        if (parseInt(enteredValue) > parseInt(product_material_po_approved_quantity) - parseInt(prev_grn_quantity)) {
          let actualValidation = document.querySelector(`#${event.target.id}`);
          actualValidation.parentElement.dataset.tip = `Cannot be greater than ${product_material_po_approved_quantity - prev_grn_quantity}...!`;;
          return false;
        } else {
          rowData[clickedColName] = enteredValue;
          // // into the weight field store the value as it is of the quantity.(Beacause aerotech client don't need the weight.)
          // rowData.product_material_grn_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(enteredValue * product_material_std_weight), 4);
          rowData.product_material_grn_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(enteredValue), 4);

          // if (parseInt(product_material_grn_accepted_quantity) > parseInt(enteredValue)) {
          rowData.product_material_grn_accepted_quantity = enteredValue;
          rowData.product_material_grn_rejected_quantity = parseInt(rowData.product_material_grn_quantity) - enteredValue

          // // into the weight field store the value as it is of the quantity.(Beacause aerotech client don't need the weight.)
          // rowData.product_material_grn_accepted_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(enteredValue * product_material_std_weight), 4);
          // rowData.product_material_grn_rejected_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(rowData.product_material_grn_rejected_quantity * product_material_std_weight), 4);
          rowData.product_material_grn_accepted_weight = rowData.product_material_grn_accepted_quantity
          rowData.product_material_grn_rejected_weight = rowData.product_material_grn_rejected_quantity

          rowData.material_basic_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(rowData.product_material_grn_accepted_quantity * material_rate), 4);
          FnMaterialWiseCalculations(rowData)
          // }
          delete event.target.parentElement.dataset.tip;
        }
        break;
      case 'product_material_grn_accepted_quantity':
        if (parseInt(enteredValue) > parseInt(product_material_grn_quantity)) {
          let actualValidation = document.querySelector(`#${event.target.id}`);
          actualValidation.parentElement.dataset.tip = `Cannot be greater than GRN Quantity...!`;
          return false;
        } else {
          rowData[clickedColName] = enteredValue;
          rowData.product_material_grn_rejected_quantity = parseInt(product_material_grn_quantity) - enteredValue

          // // into the weight field store the value as it is of the quantity.(Beacause aerotech client don't need the weight.)
          // rowData.product_material_grn_accepted_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(enteredValue * product_material_std_weight), 4);
          // rowData.product_material_grn_rejected_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(rowData.product_material_grn_rejected_quantity * product_material_std_weight), 4);
          rowData.product_material_grn_accepted_weight = enteredValue
          rowData.product_material_grn_rejected_weight = rowData.product_material_grn_rejected_quantity

          rowData.material_basic_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(rowData.product_material_grn_accepted_quantity * material_rate), 4);
          if (rowData.product_material_grn_rejected_quantity < 1) {
            rowData.product_material_rejection_reason_id = '';
          }
          FnMaterialWiseCalculations(rowData)
          calculateMaterialWiseDiscount(rowData)
          delete event.target.parentElement.dataset.tip;
        }
        break;
      case 'product_material_rejection_reason_id':
        if (clickedColName === 'product_material_rejection_reason_id') {
          if (validateText(event)) {
            rowData[clickedColName] = event.target.value;
          } else {
            rowData[clickedColName] = '';
          }
        }
        break;
      case 'excess_quantity':
        rowData[clickedColName] = enteredValue;
        break;

      case 'excess_weight':
        rowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
        break;

      case 'material_rate':
        rowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
        rowData.material_basic_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(product_material_grn_accepted_quantity * rowData[clickedColName]), 4);
        calculateMaterialWiseDiscount(rowData)
        FnMaterialWiseCalculations(rowData)
        break;

      case 'material_discount_percent':
        const enteredPercent = validateNumberDateInput.current.decimalNumber(event.target.value, 2) || 0;
        const calculatedAmount = (enteredPercent / 100) * rowData.material_basic_amount;
        if (validateNumberDateInput.current.percentValidate(enteredPercent)) {
          rowData.material_discount_percent = enteredPercent;
          rowData.material_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedAmount), 4);
          FnMaterialWiseCalculations(rowData)
          FnAddDiscountAmountInFooter(calculatedAmount, arrayIndex, detailData, taxSummaryData)
        } else {
          return;
        }
        break;

      case 'material_discount_amount':
        const enteredAmount = validateNumberDateInput.current.decimalNumber(event.target.value, 4) || 0;
        const calculatedPercent = (enteredAmount * 100) / material_basic_amount;
        if (parseFloat(enteredAmount) <= parseFloat(material_basic_amount)) {
          rowData.material_discount_amount = enteredAmount;
          rowData.material_discount_percent = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedPercent), 2);
          FnMaterialWiseCalculations(rowData)
          FnAddDiscountAmountInFooter(enteredAmount, arrayIndex, detailData, taxSummaryData)
        }
        break;

      case 'product_material_grn_weight':
      case 'product_material_grn_accepted_weight':
        rowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
        break;
      case 'godown_id': {
        rowData[clickedColName] = enteredValue;
        await renderGodownSectionList(enteredValue, event.target.getAttribute('gdDetailsId'))
        break;
      }
      case 'godown_section_id':
        rowData[clickedColName] = enteredValue;
        await renderGodownSectionBeansList(enteredValue, event.target.getAttribute('gdDetailsId'))
        break;

      case 'godown_section_beans_id':
      case 'product_material_unit_id':
      case 'material_schedule_date':
      case 'batch_no':
      case 'expiry_date':
      case 'grn_item_status':
      case 'remark':
        rowData[clickedColName] = event.target.value;
        break;
    }


    detailData[arrayIndex] = rowData
    setGrnDetailsData(detailData);

  }
  //fn for validate text input 
  const validateText = (event) => {
    let indentItemsQtyinp = document.querySelector('#' + event.target.id);
    if (event.target.value === '') {
      indentItemsQtyinp.parentElement.dataset.tip = 'Please Select Atleast One...!';
      return false;
    }
    else {
      delete indentItemsQtyinp.parentElement.dataset.tip;
      return true;
    }
  }
  const FnAddDiscountAmountInFooter = (calculatedAmount, arrayIndex, detailData, taxSummaryData) => {
    let totalDiscountAmount = 0;
    let totalFreightAmount = 0;
    detailData.map((item, index) => {
      totalFreightAmount += isNaN(Number(item.material_freight_amount)) ? 0 : Number(item.material_freight_amount);
      if (index !== arrayIndex)
        totalDiscountAmount += isNaN(Number(item.material_discount_amount)) ? 0 : Number(item.material_discount_amount);

    })
    totalDiscountAmount += Number(calculatedAmount)
    setGoodsReceiptDiscountAmount(totalDiscountAmount)
    setFreightAmount(totalFreightAmount)
    setTimeout(() => {
      FnFooterAmountChange(taxSummaryData)
    }, 200);
    return totalDiscountAmount;
  }

  const renderGodownSectionList = async (godown_id, record_transaction_id) => {
    let godownSectionObject = document.getElementById(`godown_section_id_${record_transaction_id}`);
    let godownSectionBeansObject = document.getElementById(`godown_section_beans_id_${record_transaction_id}`);

    // Remove filled elements first & then filled new
    godownSectionObject.innerHTML = '<option value="">Select</option>';
    godownSectionBeansObject.innerHTML = '<option value="">Select</option>';

    // getGodownsSectionList list for details table
    let godownSectionList = await comboDataFunc.current.fillMasterData("cmv_godown_section", "godown_id", godown_id);
    FnFillGodownOptions(godownSectionList, godownSectionObject)
  }

  const renderGodownSectionBeansList = async (godown_section_id, record_transaction_id) => {
    let godownSectionBeansObject = document.getElementById(`godown_section_beans_id_${record_transaction_id}`);

    // Remove filled elements
    godownSectionBeansObject.innerHTML = '<option value="">Select</option>';

    // getGodownsSectionList list for details table
    let godownSectionBeansList = await comboDataFunc.current.fillMasterData("cmv_godown_section_beans", "godown_section_id", godown_section_id);
    FnFillGodownOptions(godownSectionBeansList, godownSectionBeansObject)
  }

  // Fill Options of godown section & beans
  const FnFillGodownOptions = (options, object) => {
    options.forEach(item => {
      const optionElement = document.createElement('option');
      optionElement.value = item.field_id;
      optionElement.text = item.field_name;
      object.appendChild(optionElement);
    });
  }

  // Calculate discount amount 
  const calculateMaterialWiseDiscount = (rowData) => {
    const {
      material_discount_percent,
      material_basic_amount,
    } = rowData;

    const calculatedAmount = (material_discount_percent / 100) * material_basic_amount;
    rowData.material_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedAmount), 4);
  }

  // MaterialWise Calculations
  const FnMaterialWiseCalculations = (currentRowData) => {
    const supplier_state_id = document.getElementById("cmb_supplier_state_id").value;
    const expected_branch_state_id = document.getElementById("cmb_expected_branch_state_id").value;
    let {
      hsn_sac_rate,
      material_discount_amount,
      material_basic_amount,
    } = currentRowData

    let total_amount = 0;
    let cgst_sgst_percent = 0;
    let cgst_amt = 0;
    let sgst_amt = 0;
    let igst_percent = 0;
    let igst_amt = 0;
    const material_taxable_amount = material_basic_amount - material_discount_amount;

    if (supplier_state_id === '39') {
      // Other territories
      cgst_sgst_percent = 0; cgst_amt = 0; sgst_amt = 0; igst_percent = 0; igst_amt = 0;
      total_amount = material_taxable_amount;
    } else if (is_sez) {
      // Special Economical Zone
      cgst_sgst_percent = 0; cgst_amt = 0; sgst_amt = 0; igst_percent = 0; igst_amt = 0;
      total_amount = material_taxable_amount;
    } else if (supplier_state_id === expected_branch_state_id) {
      // Same Supplier & Branch state SGST,CGST.
      cgst_sgst_percent = hsn_sac_rate / 2; //for gst per% cal
      const gst_amt = (material_taxable_amount * hsn_sac_rate) / 100; //for gst amount cal
      cgst_amt = gst_amt / 2;
      sgst_amt = gst_amt / 2;
      total_amount = material_taxable_amount + gst_amt;
    } else {
      // Diiferent Supplier & Branch state IGST.
      igst_percent = hsn_sac_rate;
      igst_amt = (material_taxable_amount * hsn_sac_rate) / 100;
      total_amount = material_taxable_amount + igst_amt;
    }

    currentRowData.material_basic_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(material_basic_amount), 4);
    currentRowData.material_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(material_discount_amount), 4);
    currentRowData.material_taxable_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(material_taxable_amount), 4);
    currentRowData.material_cgst_percent = validateNumberDateInput.current.decimalNumber(JSON.stringify(cgst_sgst_percent), 2);
    currentRowData.material_cgst_total = validateNumberDateInput.current.decimalNumber(JSON.stringify(cgst_amt), 4);
    currentRowData.material_sgst_percent = validateNumberDateInput.current.decimalNumber(JSON.stringify(cgst_sgst_percent), 2);
    currentRowData.material_sgst_total = validateNumberDateInput.current.decimalNumber(JSON.stringify(sgst_amt), 4);
    currentRowData.material_igst_percentcent = validateNumberDateInput.current.decimalNumber(JSON.stringify(igst_percent), 2);
    currentRowData.material_igst_total = validateNumberDateInput.current.decimalNumber(JSON.stringify(igst_amt), 4);
    currentRowData.material_total_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(total_amount), 4);
    return currentRowData;
  }


  const calculateGrandTotal = (grnDetailsData, grnTaxSummaryData) => {

    let expectedBranchIsSez = $('#cmb_expected_branch_id option:selected').attr('is_sez');
    // let basic_total = parseFloat(grnDetailsData.reduce((total, item) => total + parseFloat(item.material_taxable_amount), 0));
    let basic_total = parseFloat(grnDetailsData.reduce((total, item) => total + parseFloat(item.material_basic_amount), 0));
    let cgst_total = parseFloat(grnTaxSummaryData.reduce((total, item) => total + parseFloat(item.summary_cgst_total), 0));
    let sgst_total = parseFloat(grnTaxSummaryData.reduce((total, item) => total + parseFloat(item.summary_sgst_total), 0));
    let igst_total = parseFloat(grnTaxSummaryData.reduce((total, item) => total + parseFloat(item.summary_igst_total), 0));
    let grandTotal = grnTaxSummaryData.reduce((total, item) => total + parseFloat(item.summary_total_amount), 0);

    const discount_amount = isNaN(parseFloat($('#txt_goods_receipt_discount_amount').val())) ? 0 : parseFloat(parseFloat($('#txt_goods_receipt_discount_amount').val()))
    // const discount_amount = material_discount_amount;
    const transport_amount = isNaN(parseFloat($('#txt_transport_amount').val())) ? 0 : parseFloat($('#txt_transport_amount').val())
    const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
    const packingAmt = isNaN(parseFloat($('#txt_packing_amount').val())) ? 0 : parseFloat($('#txt_packing_amount').val())
    const other_amount = isNaN(parseFloat($('#txt_other_amount').val())) ? 0 : parseFloat($('#txt_other_amount').val())

    const taxableAmount = basic_total - discount_amount;
    grandTotal = taxableAmount + transport_amount + freightAmt + packingAmt + other_amount + cgst_total + sgst_total + igst_total;

    let roundOffValue = (grandTotal - Math.floor(grandTotal));
    if (roundOffValue >= 0.50) {
      roundOffValue = (roundOffValue - 1); // Subtract 1 to get negative round-off
    }

    // Check if freight is taxable
    let freightIsTaxable = document.getElementById('chk_freight_isTaxable').checked;
    let freightTaxHSNIsSelected = document.getElementById('cmb_freight_hsn_id').value;

    if (freightIsTaxable && (freightTaxHSNIsSelected === '' || freightTaxHSNIsSelected === null)) {
      $('#cmb_freight_hsn_id').removeAttr('optional', 'optional');
      $('#error_cmb_freight_hsn_id').text('Please select the freight tax hsn code.');
      $('#error_cmb_freight_hsn_id').show();
    } else if (!freightIsTaxable) {
      setFreightHSNId('');
    } else {
      $('#error_cmb_freight_hsn_id').hide();
    }

    if (freightIsTaxable) {
      let freightHsnSelectedOption = document.getElementById('cmb_freight_hsn_id').options[document.getElementById('cmb_freight_hsn_id').selectedIndex];
      let freightTaxRate = isNaN(parseFloat(freightHsnSelectedOption.getAttribute('hsn_rate'))) ? 0 : parseFloat(freightHsnSelectedOption.getAttribute('hsn_rate'));
      const freightTaxAmt = (freightAmt * freightTaxRate) / 100;

      if (cmb_supplier_state_id === cmb_expected_branch_state_id && expectedBranchIsSez !== 'true' && cmb_expected_branch_state_id !== 39) {
        cgst_total += freightTaxAmt / 2;
        sgst_total += freightTaxAmt / 2;
      } else if (expectedBranchIsSez !== 'true' && cmb_expected_branch_state_id !== 39) {
        igst_total += freightTaxAmt;
      }
    }

    setBasicTotal(validateNumberDateInput.current.decimalNumber(JSON.stringify(basic_total), 4));
    setCgstTotal(validateNumberDateInput.current.decimalNumber((cgst_total).toString(), 4));
    setSgstTotal(validateNumberDateInput.current.decimalNumber((sgst_total).toString(), 4));
    setIgstTotal(validateNumberDateInput.current.decimalNumber((igst_total).toString(), 4))
    setFreightAmount(validateNumberDateInput.current.decimalNumber((freightAmt).toString(), 4))
    setTaxableTotal(taxableAmount)
    setGrandTotal(Math.round(grandTotal));
    setRoundOff((roundOffValue).toFixed(2));
  }

  // Function to normalize hsnCode values for grouping
  function normalizeHsnCode(hsnCode) {
    return hsnCode.replace(/\s+/g, '').toUpperCase();
  }
  const calculateTaxationSummary = (grnDetailsData) => {
    try {

      const taxableData = [];
      // Use reduce to group data by HSN rate
      const groupedData = grnDetailsData.reduce((acc, item) => {
        const hsnCode = normalizeHsnCode(item.hsn_sac_code);
        // const key = item.hsnCode; // Group by HSN rate
        if (!acc[hsnCode]) {
          acc[hsnCode] = [];
        }
        acc[hsnCode].push(item);
        return acc;
      }, {});

      // Convert the grouped data object into an array of arrays
      const groupedDataArray = Object.values(groupedData);

      groupedDataArray.forEach(group => {
        const {
          hsn_sac_code,
          product_material_hsn_code_id: hsn_code_id,
          purchase_order_no,
          purchase_order_date,
          purchase_order_version,
          material_cgst_percent,
          material_sgst_percent,
          material_igst_percent,
          purchase_order_master_transaction_id
        } = group[0];

        const groupTotalCGSTAmt = group.reduce((total, item) => total + parseFloat(item.material_cgst_total), 0);

        const groupTotalSGSTAmt = group.reduce((total, item) => total + parseFloat(item.material_sgst_total), 0);

        const groupTotalIGSTAmt = group.reduce((total, item) => total + parseFloat(item.material_igst_total), 0);

        const groupTotalAmt = group.reduce((total, item) => total + parseFloat(item.material_total_amount), 0);
        const groupTaxableAmt = group.reduce((total, item) => total + parseFloat(item.material_taxable_amount), 0);

        // Create an object for each group
        const groupObject = {
          hsn_sac_code,
          hsn_code_id,
          purchase_order_no,
          purchase_order_date,
          purchase_order_version,
          purchase_order_master_transaction_id,
          summary_taxable_amount: groupTaxableAmt,
          summary_cgst_percent: material_cgst_percent,
          summary_cgst_total: groupTotalCGSTAmt,
          summary_sgst_percent: material_sgst_percent,
          summary_sgst_total: groupTotalSGSTAmt,
          summary_igst_percent: material_igst_percent,
          summary_igst_total: groupTotalIGSTAmt,
          summary_total_amount: groupTotalAmt,
        };

        taxableData.push(groupObject);
      });

      // Check if freight is taxable
      let freightIsTaxable = document.getElementById('chk_freight_isTaxable').checked;
      if (freightIsTaxable)
        FnCalculateFreightTaxation(taxableData)

      setGrnTaxSummaryData(taxableData)
      calculateGrandTotal(grnDetailsData, taxableData)

    } catch (error) {
      console.log("error: ", error);
      navigator('/Error')
    }

  }

  const FnCalculateFreightTaxation = (taxableData) => {
    let expectedBranchIsSez = $('#cmb_expected_branch_id option:selected').attr('is_sez');
    let freightHsnSelectedOption = document.getElementById('cmb_freight_hsn_id').options[document.getElementById('cmb_freight_hsn_id').selectedIndex];
    let freightTaxRate = isNaN(parseFloat(freightHsnSelectedOption.getAttribute('hsn_rate'))) ? 0 : parseFloat(freightHsnSelectedOption.getAttribute('hsn_rate'));
    const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
    const freightTaxAmt = (freightAmt * freightTaxRate) / 100;
    // Create an object for each group
    const taxSummary = {
      hsn_sac_code: $('#cmb_freight_hsn_id option:selected').attr('lbl'),
      hsn_code_id: $('#cmb_freight_hsn_id').val(),
      summary_taxable_amount: freightAmt,
      summary_cgst_percent: 0,
      summary_cgst_total: 0,
      summary_sgst_percent: 0,
      summary_sgst_total: 0,
      summary_igst_percent: 0,
      summary_igst_total: 0,
      summary_total_amount: freightAmt,
    };

    if (cmb_supplier_state_id === cmb_expected_branch_state_id && expectedBranchIsSez !== 'true' && cmb_expected_branch_state_id !== 39) {
      // Set inter state tax totals
      taxSummary.summary_cgst_total = (freightTaxAmt / 2);
      taxSummary.summary_cgst_percent = (freightTaxRate / 2);
      taxSummary.summary_sgst_total = (freightTaxAmt / 2);
      taxSummary.summary_sgst_percent = (freightTaxRate / 2);
      taxSummary.summary_total_amount = (freightAmt + freightTaxAmt);
    } else if (expectedBranchIsSez !== 'true' && cmb_expected_branch_state_id !== 39) {
      // Set outer state tax totals
      taxSummary.summary_igst_total = (freightTaxAmt);
      taxSummary.summary_igst_percent = (freightTaxRate);
      taxSummary.summary_total_amount = (freightAmt + freightTaxAmt);
    }

    const existingFreightTaxationObj = taxableData.findIndex((record) => record.hsn_code_id === parseInt(taxSummary.hsn_code_id));
    if (existingFreightTaxationObj !== -1) {
      let object = taxableData[existingFreightTaxationObj]
      object.summary_taxable_amount = object.summary_taxable_amount + taxSummary.summary_taxable_amount
      object.summary_cgst_total = object.summary_cgst_total + taxSummary.summary_cgst_total;
      object.summary_sgst_total = object.summary_sgst_total + taxSummary.summary_sgst_total;
      object.summary_total_amount = object.summary_total_amount + taxSummary.summary_total_amount;
      object.summary_igst_total = object.summary_igst_total + taxSummary.summary_igst_total;
    } else {
      taxableData.push(taxSummary);                           // Push the new object if not found
    }
  }

  // Reverse Calculations to update dettails data table on change of discount & freight amount
  const reverseCalculationForFrieght = (grnDetailsData) => {

    const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
    // const discount_amount = isNaN(parseFloat($('#txt_goods_receipt_discount_amount').val())) ? 0 : parseFloat(parseFloat($('#txt_goods_receipt_discount_amount').val()))
    if (grnDetailsData.length !== 0) {
      const updatedData = grnDetailsData.map(obj => {
        // Calculate item_freight_amount and discount_amount
        let itemFreightAmount = (obj.material_basic_amount / txt_basic_total) * freightAmt;
        // let discountAmount;
        // let calculatedPercent;

        // if (discountAmount !== 0) {
        //   discountAmount = (obj.material_basic_amount / txt_basic_total) * discount_amount;
        //   calculatedPercent = (discountAmount * 100) / obj.material_basic_amount;
        // }

        // Update the object with item_freight_amount, item_discount_amount, and item_discount_percent
        const updatedObj = {
          ...obj,
          material_freight_amount: validateNumberDateInput.current.decimalNumber(JSON.stringify(itemFreightAmount), 4),
          // material_discount_amount: discountAmount === 0 ? obj.material_discount_amount : validateNumberDateInput.current.decimalNumber(JSON.stringify(discountAmount), 4),
          // material_discount_percent: discountAmount === 0 ? obj.material_discount_percent : validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedPercent), 2),
        };

        // Call FnMaterialWiseCalculations to update other properties
        const calculatedValues = FnMaterialWiseCalculations(updatedObj);
        // Merge the calculated values with the updated object
        const finalUpdatedObj = {
          ...updatedObj,
          ...calculatedValues,
        };
        return finalUpdatedObj;
      });
      setGrnDetailsData(updatedData);
    }
  }
  // Reverse Calculations to update dettails data table on change of discount & freight amount
  const reverseCalculationForDiscount = (grnDetailsData) => {

    const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
    const discount_amount = isNaN(parseFloat($('#txt_goods_receipt_discount_amount').val())) ? 0 : parseFloat(parseFloat($('#txt_goods_receipt_discount_amount').val()))

    if (grnDetailsData.length !== 0) {
      const updatedData = grnDetailsData.map(obj => {
        // Calculate item_freight_amount and discount_amount
        let itemFreightAmount = (obj.material_basic_amount / txt_basic_total) * freightAmt;
        let discountAmount;
        let calculatedPercent;

        if (discountAmount !== 0) {
          discountAmount = (obj.material_basic_amount / txt_basic_total) * discount_amount;
          calculatedPercent = (discountAmount * 100) / obj.material_basic_amount;
        }
        // Update the object with item_freight_amount, item_discount_amount, and item_discount_percent
        const updatedObj = {
          ...obj,
          material_freight_amount: validateNumberDateInput.current.decimalNumber(JSON.stringify(itemFreightAmount), 4),
          material_discount_amount: discountAmount === 0 ? obj.material_discount_amount : validateNumberDateInput.current.decimalNumber(JSON.stringify(discountAmount), 4),
          material_discount_percent: discountAmount === 0 ? obj.material_discount_percent : validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedPercent), 2),
        };
        // Call FnMaterialWiseCalculations to update other properties
        const calculatedValues = FnMaterialWiseCalculations(updatedObj);
        // Merge the calculated values with the updated object
        const finalUpdatedObj = { ...calculatedValues, };
        return finalUpdatedObj;
      });
      setGrnDetailsData(updatedData);
    }
  }

  const validateFields = () => {
    validate.current.validateFieldsOnChange('GRNHeaderFormId')
    validate.current.validateFieldsOnChange('GRNFooterFormId')
  }

  const FnValidateGoodReceiptNote = async () => {
    // Validating header footer forms data.
    let headerDataIsValid = await validate.current.validateForm("GRNHeaderFormId");
    if (!headerDataIsValid) { return false; }
    if (keyForViewUpdate === "") {
      const getchallanNo = await checkDuplicateChallan(txt_supplier_challan_no);
      if (getchallanNo.length > 0) {
        $('#error_txt_supplier_challan_no').text("Challan No. Already Exists...!")
        $('#error_txt_supplier_challan_no').show();
        $('#txt_supplier_challan_no').focus();
        return false;
      }
    }
    let GrnDetailsIsValid = true;
    if (grnDetailsData.length === 0) {
      setErrMsg('Please add atleast one material...');
      setShowErrorMsgModal(true);
      setOpenDetailsAccord(true);
      return GrnDetailsIsValid = false;
    } else {
      const tableRows = document.querySelectorAll('#grnDetailsTbl tbody tr');
      tableRows.forEach(row => {
        const materialGrnQty = parseFloat(row.querySelector('input[id^="product_material_grn_quantity_"]').value);
        const materialAcceptedQty = parseFloat(row.querySelector('input[id^="product_material_grn_accepted_quantity_"]').value);
        const materialRate = parseFloat(row.querySelector('input[id^="material_rate_"]').value);
        const materialRejectionReason = row.querySelector('select[id^="product_material_rejection_reason_id_"]').value;
        const materialUnit = row.querySelector('select[id^="product_material_unit_id_"]').value;

        if (isNaN(materialGrnQty) || materialGrnQty < 0) {
          row.querySelector('input[id^="product_material_grn_quantity_"]').parentElement.dataset.tip = 'GRN Quantity should not be zero or blank on approval...!';
          row.querySelector('input[id^="product_material_grn_quantity_"]').focus();
          setOpenDetailsAccord(true);
          return GrnDetailsIsValid = false;
        } else {
          delete row.querySelector('input[id^="product_material_grn_quantity_"]').parentElement.dataset.tip;
        }

        if (isNaN(materialAcceptedQty) || materialAcceptedQty < 0) {
          row.querySelector('input[id^="product_material_grn_accepted_quantity_"]').parentElement.dataset.tip = 'GRN Accepted Quantity should not be zero or blank on approval...!';
          row.querySelector('input[id^="product_material_grn_accepted_quantity_"]').focus();
          setOpenDetailsAccord(true);
          return GrnDetailsIsValid = false;
        } else {
          delete row.querySelector('input[id^="product_material_grn_accepted_quantity_"]').parentElement.dataset.tip
        }

        if (isNaN(materialRate) || materialRate <= 0) {
          row.querySelector('input[id^="material_rate_"]').parentElement.dataset.tip = 'Material rate should not be zero or blank...!';
          row.querySelector('input[id^="material_rate_"]').focus();
          setOpenDetailsAccord(true);
          return GrnDetailsIsValid = false;
        } else {
          delete row.querySelector('input[id^="material_rate_"]').parentElement.dataset.tip

        }
        if (isNaN(materialRate) || materialRate <= 0) {
          row.querySelector('input[id^="material_rate_"]').parentElement.dataset.tip = 'Material rate should not be zero or blank...!';
          row.querySelector('input[id^="material_rate_"]').focus();
          setOpenDetailsAccord(true);
          return GrnDetailsIsValid = false;
        } else {
          delete row.querySelector('input[id^="material_rate_"]').parentElement.dataset.tip
        }

        if (materialUnit === '') {
          row.querySelector('select[id^="product_material_unit_id_"]').parentElement.dataset.tip = 'Please select material unit...!';
          row.querySelector('select[id^="product_material_unit_id_"]').focus();
          setOpenDetailsAccord(true);
          return GrnDetailsIsValid = false;
        } else {
          delete row.querySelector('select[id^="product_material_unit_id_"]').parentElement.dataset.tip
        }

        const tdWithRejectedQty = row.querySelector('td[rejectedqty]');
        const inputElement = row.querySelector('select[id^="product_material_rejection_reason_id_"]');
        if (tdWithRejectedQty && parseInt(tdWithRejectedQty.textContent) > 0) {
          if (materialRejectionReason === '0' || materialRejectionReason === undefined) {
            if (inputElement) {
              inputElement.parentElement.dataset.tip = 'Please select grn quantity rejection reason...!';
              inputElement.focus();
              setOpenDetailsAccord(true);
              return GrnDetailsIsValid = false;
            }
          } else {
            delete inputElement.parentElement.dataset.tip;
          }
        }
      });
    }
    let footerDataIsValid = await validate.current.validateForm("GRNFooterFormId");
    if (!footerDataIsValid) { return false; }

    return GrnDetailsIsValid;
  }

  const FnGetSupplierContactIds = () => {
    let supplierContactPersonsIds = '';
    const checkboxes = $('.selectSuppContactPerson:checked');
    checkboxes.each(function () {
      supplierContactPersonsIds += $(this).val() + ":";
    });
    return supplierContactPersonsIds.replace(/:$/, '');
  }

  // saveGRN Note
  const createGoodReceiptNote = async (grnStatus) => {
    try {
      let grnValidated = true;
      if (!isApprove && keyForViewUpdate !== "delete") {
        grnValidated = await FnValidateGoodReceiptNote();
        if (!grnValidated) { return false; }

      } else if (keyForViewUpdate !== "delete") {
        if (cmb_goods_receipt_status === '') {
          $('#error_cmb_goods_receipt_status').show();
          grnValidated = false;
        }
      }
      let latestGrnNo = txt_grn_no;
      if (grnValidated) {
        setIsLoading(true)
        let json = { 'TransHeaderData': {}, 'TransDetailData': [], 'TransTaxSummaryData': [], 'TransPaymentTermsData': [], 'commonIds': {} }
        if (goods_receipt_master_transaction_id === 0)
          latestGrnNo = await generateGrnNo();

        let supplierContactsIds = FnGetSupplierContactIds();
        const grnMasterData = {
          goods_receipt_master_transaction_id: isApprove ? goods_receipt_master_transaction_id : 0,
          company_id: COMPANY_ID,
          company_branch_id: COMPANY_BRANCH_ID,
          financial_year: FINANCIAL_SHORT_NAME,
          goods_receipt_no: latestGrnNo,
          goods_receipt_date: dt_goods_receipt_date,
          goods_receipt_version: txt_grn_version,
          supplier_id: cmb_supplier_id,
          supplier_state_id: cmb_supplier_state_id,
          supplier_city_id: cmb_supplier_city_id,
          supplier_contacts_ids: supplierContactsIds,
          expected_branch_id: cmb_expected_branch_id,
          expected_branch_state_id: cmb_expected_branch_state_id,
          expected_branch_city_id: cmb_expected_branch_city_id,
          purchase_order_no: null,
          purchase_order_date: null,
          purchase_order_version: null,
          // goods_receipt_type_id: cmb_goods_receipt_type_id,
          // goods_receipt_type: $('#cmb_goods_receipt_type_id').find(":selected").text(),
          purchase_order_life: rb_purchase_order_life,
          supplier_challan_no: txt_supplier_challan_no,
          supplier_challan_date: dt_supplier_challan_Date,
          approved_by_id: isApprove ? cmb_approved_by_id : '',
          approved_date: isApprove ? dt_approved_date : '',
          qa_by_id: cmb_qa_by_id,
          qa_date: dt_qa_date,
          expected_schedule_date: null,
          basic_total: txt_basic_total,
          transport_amount: txt_transport_amount,
          freight_amount: txt_freight_amount,
          is_freight_taxable: chk_freight_isTaxable,
          freight_hsn_code_id: cmb_freight_hsn_id,
          packing_amount: txt_packing_amount,
          goods_receipt_discount_percent: txt_goods_receipt_discount_percent,
          goods_receipt_discount_amount: txt_goods_receipt_discount_amount,
          other_amount: txt_other_amount,
          taxable_total: txt_taxable_total,
          cgst_total: txt_cgst_total,
          sgst_total: txt_sgst_total,
          igst_total: txt_igst_total,
          grand_total: txt_grand_total,
          roundoff: txt_roundOff,
          agent_id: cmb_agent_id,
          agent_percent: txt_agent_percent,
          agent_paid_status: cmb_agent_paid_status,
          goods_receipt_status: cmb_goods_receipt_status,
          lr_no: txt_lr_no,
          lr_date: dt_lr_date,
          vehicle_no: txt_vehicle_no,
          other_terms_conditions: txt_other_terms_conditions,
          supplier_invoice_no: txt_invoice_no,
          supplier_invoice_date: dt_invoice_date,
          remark: txt_remark,
          is_active: is_active,
          created_by: UserName,
          is_preeclosed: is_pree_closed,
          modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''
        }

        json.TransHeaderData = grnMasterData;

        if (!isApprove) {
          for (let index = 0; index < grnDetailsData.length; index++) {
            const element = grnDetailsData[index];
            let jsonRecord = {}
            jsonRecord.goods_receipt_details_transaction_id = isApprove ? element.goods_receipt_details_transaction_id : 0;
            jsonRecord.company_id = COMPANY_ID;
            jsonRecord.company_branch_id = COMPANY_BRANCH_ID;
            jsonRecord.financial_year = FINANCIAL_SHORT_NAME;
            jsonRecord.goods_receipt_no = latestGrnNo;
            jsonRecord.goods_receipt_date = dt_goods_receipt_date;
            jsonRecord.goods_receipt_version = txt_grn_version;
            jsonRecord.purchase_order_no = element.purchase_order_no;
            jsonRecord.purchase_order_date = element.purchase_order_date;
            jsonRecord.purchase_order_version = element.purchase_order_version;
            jsonRecord.customer_id = element.customer_id;
            jsonRecord.customer_order_no = element.customer_order_no;
            jsonRecord.customer_order_Date = element.customer_order_Date;
            jsonRecord.item_qa_by_id = cmb_qa_by_id;
            jsonRecord.item_qa_date = dt_qa_date;
            jsonRecord.goods_receipt_type_id = element.purchase_order_type_id;
            jsonRecord.goods_receipt_type = element.purchase_order_type;
            jsonRecord.batch_no = element.batch_no;
            jsonRecord.product_material_id = element.product_material_id;
            jsonRecord.product_material_tech_spect = element.product_material_tech_spect;
            jsonRecord.lead_time = element.lead_time;
            jsonRecord.sr_no = element.sr_no;
            jsonRecord.product_material_unit_id = element.product_material_unit_id;
            jsonRecord.product_material_packing_id = element.product_material_packing_id;
            jsonRecord.product_material_hsn_code_id = element.product_material_hsn_code_id;
            jsonRecord.product_material_po_approved_quantity = element.product_material_po_approved_quantity;
            jsonRecord.product_material_po_approved_weight = element.product_material_po_approved_weight;
            jsonRecord.product_material_grn_quantity = element.product_material_grn_quantity;
            jsonRecord.product_material_grn_weight = element.product_material_grn_weight;
            jsonRecord.product_material_grn_accepted_quantity = element.product_material_grn_accepted_quantity;
            jsonRecord.product_material_grn_accepted_weight = element.product_material_grn_accepted_weight;
            jsonRecord.product_material_conversion_factor = element.product_material_conversion_factor;
            jsonRecord.product_material_grn_rejected_quantity = element.product_material_grn_rejected_quantity;
            jsonRecord.product_material_grn_rejected_weight = element.product_material_grn_rejected_weight;
            jsonRecord.product_material_rejection_reason_id = element.product_material_rejection_reason_id;
            jsonRecord.material_rate = element.material_rate;
            jsonRecord.material_basic_amount = element.material_basic_amount;
            jsonRecord.material_freight_amount = element.material_freight_amount;
            jsonRecord.material_discount_percent = element.material_discount_percent;
            jsonRecord.material_discount_amount = element.material_discount_amount;
            jsonRecord.material_taxable_amount = element.material_taxable_amount;
            jsonRecord.material_cgst_percent = element.material_cgst_percent;
            jsonRecord.material_cgst_total = element.material_cgst_total;
            jsonRecord.material_sgst_percent = element.material_sgst_percent;
            jsonRecord.material_sgst_total = element.material_sgst_total;
            jsonRecord.material_igst_percent = element.material_igst_percent;
            jsonRecord.material_igst_total = element.material_igst_total;
            jsonRecord.material_total_amount = element.material_total_amount;
            jsonRecord.material_schedule_date = element.material_schedule_date;
            jsonRecord.excess_quantity = element.excess_quantity;
            jsonRecord.excess_weight = element.excess_weight;
            jsonRecord.product_material_prev_accepted_quantity = element.product_material_prev_accepted_quantity;
            jsonRecord.product_material_prev_accepted_weight = element.product_material_prev_accepted_weight;
            jsonRecord.pree_closed_grn_quantity = is_pree_closed ? element.product_material_po_approved_quantity - element.prev_grn_quantity - element.product_material_grn_accepted_quantity : 0;
            jsonRecord.pree_closed_grn_weight = is_pree_closed ? element.product_material_po_approved_weight - element.prev_grn_weight - element.product_material_grn_accepted_weight : 0;

            jsonRecord.department_id = element.department_id;
            jsonRecord.grn_item_status = element.grn_item_status;
            jsonRecord.remark = element.remark;
            jsonRecord.created_by = UserName;
            jsonRecord.modified_by = keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : '';
            jsonRecord.expiry_date = element.expiry_date;
            jsonRecord.purchase_order_details_transaction_id = element.purchase_order_details_transaction_id
            jsonRecord.godown_id = element.godown_id
            jsonRecord.godown_section_id = element.godown_section_id
            jsonRecord.godown_section_beans_id = element.godown_section_beans_id
            jsonRecord.product_material_prev_accepted_quantity = element.goods_receipt_details_transaction_id !== undefined ? element.product_material_prev_accepted_quantity : 0

            json.TransDetailData.push(jsonRecord);
          }

          for (let index = 0; index < grnTaxSummaryData.length; index++) {
            const element = grnTaxSummaryData[index];
            const taxSummaryData = {
              company_id: COMPANY_ID,
              company_branch_id: COMPANY_BRANCH_ID,
              financial_year: FINANCIAL_SHORT_NAME,
              goods_receipt_no: latestGrnNo,
              goods_receipt_date: dt_goods_receipt_date,
              goods_receipt_version: txt_grn_version,
              purchase_order_type_id: null,
              purchase_order_type: null,
              purchase_order_master_transaction_id: element.purchase_order_master_transaction_id,
              supplier_id: cmb_supplier_id,
              supplier_state_id: cmb_supplier_state_id,
              supplier_city_id: cmb_supplier_city_id,
              supplier_contacts_ids: supplierContactsIds,
              expected_branch_id: cmb_expected_branch_id,
              expected_branch_state_id: cmb_expected_branch_state_id,
              expected_branch_city_id: cmb_expected_branch_city_id,
              purchase_order_no: element.purchase_order_no,
              purchase_order_date: element.purchase_order_date,
              purchase_order_version: element.purchase_order_version,
              hsn_code_id: element.hsn_code_id,
              summary_taxable_amount: element.summary_taxable_amount,
              summary_cgst_percent: element.summary_cgst_percent,
              summary_cgst_total: element.summary_cgst_total,
              summary_sgst_percent: element.summary_sgst_percent,
              summary_sgst_total: element.summary_sgst_total,
              summary_igst_percent: element.summary_igst_percent,
              summary_igst_total: element.summary_igst_total,
              summary_total_amount: element.summary_total_amount,
              // tax_upload__status
              // tax_upload_id
              // tax_upload_date
              created_by: UserName,
              modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''

            }
            json.TransTaxSummaryData.push(taxSummaryData)
          }
          for (let index = 0; index < paymentTermsData.length; index++) {
            const element = paymentTermsData[index];
            const payTermsData = {
              company_id: COMPANY_ID,
              company_branch_id: COMPANY_BRANCH_ID,
              financial_year: FINANCIAL_SHORT_NAME,
              goods_receipt_no: latestGrnNo,
              goods_receipt_date: dt_goods_receipt_date,
              goods_receipt_version: txt_grn_version,
              purchase_order_no: element.purchase_order_no,
              purchase_order_date: element.purchase_order_date,
              purchase_order_version: element.purchase_order_version,
              payment_terms_id: element.payment_terms_id,
              payment_terms_name: element.payment_terms_name,
              payment_terms_days: element.payment_terms_days,
              payment_terms_grace_days: element.payment_terms_grace_days,
              payment_terms_Milestome: element.payment_terms_Milestome,
              payment_percent: element.payment_percent,
              payment_expected_value: element.payment_expected_value,
              payment_expected_date: element.payment_expected_date,
              payment_paid_flag: element.payment_paid_flag,
              created_by: UserName,
              modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''
            }
            json.TransPaymentTermsData.push(payTermsData)
          }
          if (json.TransPaymentTermsData.length === 0) {
            json.TransPaymentTermsData = existingPaymentTermsData.map((pay_term) => {
              pay_term.goods_receipt_no = keyForViewUpdate === 'copy' ? latestGrnNo : pay_term.goods_receipt_no;
              pay_term.goods_receipt_date = keyForViewUpdate === 'copy' ? dt_goods_receipt_date : pay_term.goods_receipt_date;
              pay_term.goods_receipt_version = keyForViewUpdate === 'copy' ? txt_grn_version : pay_term.goods_receipt_version;
              pay_term.created_by = keyForViewUpdate === 'copy' ? UserName : pay_term.created_by;
              pay_term.modified_by = keyForViewUpdate === 'copy' ? UserName : pay_term.modified_by;
              pay_term.goods_receipt_master_transaction_id = 0;
              pay_term.goods_receipt_payment_terms_transaction_id = 0;
              return pay_term;
            })
          }
        }
        //Common Ids
        json.commonIds.company_id = COMPANY_ID
        json.commonIds.company_branch_id = COMPANY_BRANCH_ID
        json.commonIds.goods_receipt_no = latestGrnNo
        json.commonIds.goods_receipt_version = txt_grn_version
        json.commonIds.financial_year = FINANCIAL_SHORT_NAME

        const formData = new FormData();
        formData.append(`GoodReceiptNoteData`, JSON.stringify(json))
        const requestOptions = {
          method: 'POST',
          body: formData
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptDetails/FnAddUpdateRecord/${isApprove}`, requestOptions)
        const response = await apicall.json();

        if (response.success === "0") {
          setErrMsg(response.error)
          setShowErrorMsgModal(true)

        } else {
          const evitCache = await comboDataFunc.current.evitCache();
          console.log(evitCache);
          setMessage(response.message)
          if (response.data.hasOwnProperty('goods_receipt_version') && response.data.hasOwnProperty('goods_receipt_no')) {
            setModalOrderDetails(`Good Receipt No: ${response.data.goods_receipt_no} and Version: ${response.data.goods_receipt_version}`)
          }
          if (goods_receipt_master_transaction_id === 0) {
            setMessageForConfirmation('')
          }
          setShowConfiemationModal(true);
        }
      }
    } catch (error) {
      console.log("error: ", error);
      navigator('/Error')
    } finally {
      setIsLoading(false)
    }

  }



  const ExportToExcel = () => {
    if (grnDetailsData.length !== 0) {
      const checkedValues = $("input:checkbox[name=purchaseOrderNoCheckBox]:checked").map(function () {
        return $(this).val();
      }).get();

      const resultString = checkedValues.join(', ');

      let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', editable_cols: [7, 9, 13, 15] }

      // jsonToExportExcel.filtrKeyValue['0'] = "Goods Receipt Type" + ' : ' + $('#cmb_goods_receipt_type_id').find(":selected").text() + "(" + cmb_goods_receipt_type_id + ")";
      jsonToExportExcel.filtrKeyValue['0'] = "GRN No" + ' : ' + txt_grn_no;
      jsonToExportExcel.filtrKeyValue['1'] = "GRN Date" + ' : ' + validateNumberDateInput.current.formatDateToDDMMYYYY(dt_goods_receipt_date);
      jsonToExportExcel.filtrKeyValue['2'] = "GRN Version" + ' : ' + txt_grn_version;
      jsonToExportExcel.filtrKeyValue['3'] = "Supplier" + ' : ' + $('#cmb_supplier_id').find(":selected").text() + "(" + cmb_supplier_id + ")";
      jsonToExportExcel.filtrKeyValue['4'] = "Purchase Order No." + ' : ' + '(' + resultString + ')';
      jsonToExportExcel.filtrKeyValue['5'] = "Supplier State" + ' : ' + $('#cmb_supplier_state_id').find(":selected").text() + "(" + cmb_supplier_state_id + ")";
      jsonToExportExcel.filtrKeyValue['6'] = "Supplier City" + ' : ' + $('#cmb_supplier_city_id').find(":selected").text() + "(" + cmb_supplier_city_id + ")";
      jsonToExportExcel.filtrKeyValue['7'] = "Supplier Challan No." + ' : ' + txt_supplier_challan_no;
      jsonToExportExcel.filtrKeyValue['8'] = "Supplier Challan Date" + ' : ' + validateNumberDateInput.current.formatDateToDDMMYYYY(dt_supplier_challan_Date);
      jsonToExportExcel.filtrKeyValue['9'] = "Expected Branch" + ' : ' + $('#cmb_expected_branch_id').find(":selected").text() + "(" + cmb_expected_branch_id + ")";
      jsonToExportExcel.filtrKeyValue['10'] = "Expected Branch State" + ' : ' + $('#cmb_expected_branch_state_id').find(":selected").text() + "(" + cmb_expected_branch_state_id + ")";
      jsonToExportExcel.filtrKeyValue['11'] = "Expected Branch City" + ' : ' + $('#cmb_expected_branch_city_id').find(":selected").text() + "(" + cmb_expected_branch_city_id + ")";
      jsonToExportExcel.filtrKeyValue['12'] = "Purchase Order Life" + ' : ' + rb_purchase_order_life;
      jsonToExportExcel.filtrKeyValue['13'] = "Qa By" + ' : ' + $('#cmb_qa_by_id').find(":selected").text() + "(" + cmb_qa_by_id + ")";
      jsonToExportExcel.filtrKeyValue['14'] = "Qa By Date" + ' : ' + validateNumberDateInput.current.formatDateToDDMMYYYY(dt_qa_date);

      let colNames = [
        "sr_no",
        "product_material_name",
        "purchase_order_no",
        "product_material_po_approved_quantity",
        "product_material_po_approved_weight",
        "prev_grn_quantity",
        "prev_grn_weight",
        "product_material_grn_quantity",
        "product_material_grn_weight",
        "product_material_grn_accepted_quantity",
        "product_material_grn_accepted_weight",
        "product_material_grn_rejected_quantity",
        "product_material_grn_rejected_weight",
        // "product_rejection_type",
        "material_rate",
        "material_basic_amount",
        "material_discount_percent",
        "material_discount_amount",
        "material_taxable_amount",
        "material_cgst_percent",
        "material_cgst_total",
        "material_sgst_percent",
        "material_sgst_total",
        "material_igst_percent",
        "material_igst_total",
        "material_total_amount",
        "material_schedule_date",
        "customer_order_no",
        "customer_order_Date",
        "batch_no",
        "product_material_conversion_factor",
        "product_material_tech_spect",
        "product_material_hsn_sac_code",
        "product_material_std_weight",
        "hsn_sac_rate",
        "product_packing_name",
        "lead_time",
        "godown_id",
        "godown_section_id",
        "godown_section_beans_id",
        "company_id",
        "company_branch_id",
        "purchase_order_details_transaction_id",
        "product_material_unit_id",
        "product_material_packing_id",
        "product_material_hsn_code_id",
        "customer_id",
        "product_material_id",
        "product_material_type_id",
        "department_id",
      ];

      if (IS_EXCESS_ALLOWED) {
        colNames.push("excess_quantity", "excess_weight");
        jsonToExportExcel.editable_cols.push(49);
      }
      // columns
      for (let col = 0; col < colNames.length; col++) {
        jsonToExportExcel.columns.push({ "Headers": colNames[col], "accessor": colNames[col] });
      }

      for (let index = 0; index < grnDetailsData.length; index++) {
        jsonToExportExcel['allData'][index] = grnDetailsData[index]
      }
      jsonToExportExcel['headings']['ReportName'] = "Goods Receipt Note Details"
      jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
      jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
      exlsExp.current.excel(jsonToExportExcel, txt_grn_no + "@" + COMPANY_ID)
    } else {
      setErrMsg('Please add at least one material!... ')
      setShowErrorMsgModal(true)
    }
  }
  const refreshData = async (key) => {

    switch (key) {
      case 'get_supplier':
        // get suppliers list with default state & city
        resetGlobalQuery();
        globalQuery.columns.push("field_id");
        globalQuery.columns.push("field_name");
        globalQuery.columns.push("supp_branch_state_id");
        globalQuery.columns.push("supp_branch_city_id");
        globalQuery.table = "cmv_supplier";
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        const suppliersApiCall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
        setSupplierList(suppliersApiCall)
        break;
      case 'get_exptBranch':
        resetGlobalQuery();
        globalQuery.columns = ["company_branch_id", "company_branch_name", "branch_state_id", "branch_city_id", "state_name", "city_name", 'is_sez']
        globalQuery.table = "cmv_company_branch";
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
        const getExpectedtBranchs = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
        setExpectedBranchList(getExpectedtBranchs);
        break;
      case 'get_agent':
        // for agents List
        const agentApiCall = await comboDataFunc.current.fillMasterData("cmv_agent", "", "");
        setAgentsList(agentApiCall)
        break;

      default:
        break;
    }
  }

  const memoizedTaxSummaryComponent = useMemo(() => (
    <TaxSummary grnTaxSummaryData={grnTaxSummaryData} validateNumberDateInput={validateNumberDateInput} />
  ), [grnTaxSummaryData]);

  // Use the replace method to replace "/" with "_"
  let group_id = txt_grn_no.replace(/\//g, "_");
  const memorizedDocumentComponent = useMemo(() => {
    return <>
      {/* Document modal */}
      <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
          <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
        </Modal.Header>
        <Modal.Body>
          <Document group_id={group_id} document_group={documentGroup} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  }, [showDocumentForm])


  //GRN printout Start
  const grnInvoiceContent = {
    company: {
      company: '',
      company_branch: '',
    },
    supplierDetails: {
      name: '',
      address: '',
      state: '',
      contact: '',
      City: '',
      Email: '',
      agent_name: '',
      supplier_invoice_no: '',
      supplier_invoice_date: '',
      qa_by_name: '',
      qa_date: '',
      is_sez: '',
      supplier_challan_no: '',
      supplier_challan_date: '',
      purchase_order_life: ''

    },
    grnDetails: {
      goods_receipt_no: '',
      goods_receipt_date: '',
      goods_receipt_version: '',
    },
    purchaseDetails: {
      purchase_order_no: '',
      purchase_order_date: ''

    },
    footer: {
      basic_total: '',
      cgst_total: '',
      sgst_total: '',
      igst_total: '',
      taxable_total: '',
      grand_total: '',
      freight_amount: '',
      discount_amount: '',
      discount_percent: ''

    },
    items: [],
    paymentTerms: [],
  };

  const printInvoice = async (openPrintModal) => {
    const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptDetails/FnShowAllDetailsAndMastermodelRecords/${txt_grn_version}/${idList.financial_year}/${COMPANY_ID}?goods_receipt_no=${txt_grn_no}`)
    const response = await apiCall.json();
    console.log("Response GRN Invoice : ", response);
    let GoodsReceiptMasterData = response.GoodsReceiptMasterRecord;
    let GoodsReceiptDetailsData = response.GoodsReceiptDetailsRecords;

    //GRN details
    grnInvoiceContent.grnDetails.goods_receipt_no = txt_grn_no
    grnInvoiceContent.grnDetails.goods_receipt_date = validateNumberDateInput.current.formatDateToDDMMYYYY(dt_goods_receipt_date)
    grnInvoiceContent.grnDetails.goods_receipt_version = txt_grn_version


    //Purchase details
    grnInvoiceContent.purchaseDetails.purchase_order_no = GoodsReceiptMasterData.purchase_order_no
    grnInvoiceContent.purchaseDetails.purchase_order_date = validateNumberDateInput.current.formatDateToDDMMYYYY(GoodsReceiptMasterData.purchase_order_date)

    //Company details
    grnInvoiceContent.company.company = GoodsReceiptMasterData.company_name
    grnInvoiceContent.company.company_branch = GoodsReceiptMasterData.company_branch_name

    //supplier details
    grnInvoiceContent.supplierDetails.name = GoodsReceiptMasterData.supplier_name
    grnInvoiceContent.supplierDetails.address = GoodsReceiptMasterData.supp_branch_address1
    grnInvoiceContent.supplierDetails.state = $("#cmb_supplier_state_id option:selected").text();
    grnInvoiceContent.supplierDetails.contact = GoodsReceiptMasterData.supp_branch_phone_no
    // grnInvoiceContent.supplierDetails.City = GoodsReceiptMasterData.supplier_city_name
    grnInvoiceContent.supplierDetails.City = $("#cmb_supplier_city_id option:selected").text();
    grnInvoiceContent.supplierDetails.Email = GoodsReceiptMasterData.supp_branch_EmailId

    let is_sez = null;
    if (GoodsReceiptMasterData.is_sez === "true") {
      is_sez = "Yes";
    } else {
      is_sez = "No";
    }
    grnInvoiceContent.supplierDetails.agent_name = GoodsReceiptMasterData.agent_name
    grnInvoiceContent.supplierDetails.supplier_invoice_no = GoodsReceiptMasterData.supplier_invoice_no
    grnInvoiceContent.supplierDetails.supplier_invoice_date = GoodsReceiptMasterData.supplier_invoice_date
    grnInvoiceContent.supplierDetails.supplier_challan_no = GoodsReceiptMasterData.supplier_challan_no
    grnInvoiceContent.supplierDetails.supplier_challan_date = GoodsReceiptMasterData.supplier_challan_date
    grnInvoiceContent.supplierDetails.qa_by_name = GoodsReceiptMasterData.qa_by_name
    grnInvoiceContent.supplierDetails.qa_date = GoodsReceiptMasterData.qa_date
    grnInvoiceContent.supplierDetails.is_sez = is_sez
    grnInvoiceContent.supplierDetails.purchase_order_life = GoodsReceiptMasterData.purchase_order_life_desc

    //Customer details
    grnInvoiceContent.company.company = GoodsReceiptMasterData.company_name
    grnInvoiceContent.company.company_branch = GoodsReceiptMasterData.company_branch_name

    //footer details    

    grnInvoiceContent.footer.basic_total = txt_basic_total
    grnInvoiceContent.footer.cgst_total = txt_cgst_total
    grnInvoiceContent.footer.sgst_total = txt_sgst_total
    grnInvoiceContent.footer.igst_total = txt_igst_total
    grnInvoiceContent.footer.taxable_total = txt_taxable_total
    grnInvoiceContent.footer.grand_total = txt_grand_total
    grnInvoiceContent.footer.freight_amount = txt_freight_amount
    grnInvoiceContent.footer.discount_amount = txt_goods_receipt_discount_amount
    grnInvoiceContent.footer.discount_percent = txt_goods_receipt_discount_percent

    debugger
    for (let i = 0; i < paymentTermsData.length; i++) {
      const element = paymentTermsData[i];
      const paymentemsData = {
        payment_terms_name: element.payment_terms_name,
      }
      grnInvoiceContent.paymentTerms.push(paymentemsData)
    }

    for (let index = 0; index < grnDetailsData.length; index++) {
      const element = grnDetailsData[index];

      const detailsData = {
        sr_no: element.sr_no,
        material_name: element.product_material_name,
        material_tech_spect: element.product_material_tech_spect,
        hsn_code: element.product_material_hsn_sac_code,
        grn_quantity: element.product_material_grn_quantity,
        grn_accepted_quantity: element.product_material_grn_accepted_quantity,
        grn_rejected_quantity: element.product_material_grn_rejected_quantity,
        unit: element.product_material_stock_unit_name,
        rate: element.material_rate,
        discount_amount: element.material_discount_amount,
        cgst_amount: element.material_cgst_total,
        sgst_amount: element.material_sgst_total,
        igst_amount: element.material_igst_total,
        cgst_percent: element.material_cgst_percent,
        sgst_percent: element.material_sgst_percent,
        igst_percent: element.material_igst_percent,
        basic_amount: element.material_basic_amount,
        taxable_amount: element.material_taxable_amount,
        material_total_amount: element.material_total_amount,
        purchase_order_no: element.purchase_order_no,
        purchase_order_version: element.purchase_order_version,
        supplier_challan_no: element.supplier_challan_date,
        purchase_order_date: validateNumberDateInput.current.formatDateToDDMMYYYY(element.purchase_order_date),
        customer_order_no: element.customer_order_no
      }
      grnInvoiceContent.items.push(detailsData)
    }


    if (openPrintModal) {
      navigator('/Invoice', { state: { invoiceData: grnInvoiceContent, navigationLink: "/Transactions/TPurchaseOrder/GoodsReceiptNote/GoodsReceiptNoteEntry", invoiceType: 'GRN', title: 'Goods Receipt Note', idList: idList, keyForViewUpdate: keyForViewUpdate } });
    } else {
      // return the html code.
      return renderToString(<GoodReceiptNote invoiceContent={grnInvoiceContent} />);
    }

  };



  const onImportClick = () => {
    importFile.current.click();
  };
  const importedExcelFile = async (event) => {
    try {
      if (event.target.files && event.target.files.length > 0) {
        setIsLoading(true)
        var file = event.target.files[0];

        const formData = new FormData();
        formData.append(`file`, file)
        const requestOptions = {
          method: 'POST',
          body: formData
        };
        const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptDetails/FnReadExcel`, requestOptions)
        const response = await apicall.json();

        console.log(response)
        if (response.success === 1) {
          let purhcase_orderNO = "";
          const readedOrderDetailsFromExcel = response.data?.map(dataRow => {
            const orderDetail = response.columns.reduce((acc, key, secIndex) => {
              acc[key] = dataRow[secIndex];
              return acc;
            }, {});
            if (isNaN(parseFloat(orderDetail.product_material_grn_quantity))) {
              orderDetail.product_material_grn_quantity = 0;
              orderDetail.product_material_grn_weight = 0;
            } else {
              if (orderDetail.product_material_grn_quantity !== "0") {
                orderDetail.product_material_grn_quantity = parseInt(orderDetail.product_material_po_approved_quantity) - parseInt(orderDetail.prev_grn_quantity);
                orderDetail.product_material_grn_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(orderDetail.product_material_grn_quantity * orderDetail.product_material_std_weight), 4);
              }
            }
            if (isNaN(parseFloat(orderDetail.product_material_grn_accepted_quantity))) {
              orderDetail.product_material_grn_accepted_quantity = 0;
              orderDetail.product_material_grn_accepted_weight = 0;
            } else {
              if (orderDetail.product_material_grn_accepted_quantity !== "0") {
                orderDetail.product_material_grn_accepted_quantity = parseInt(orderDetail.product_material_grn_quantity);
                orderDetail.product_material_grn_accepted_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(orderDetail.product_material_grn_accepted_quantity * orderDetail.product_material_std_weight), 4);
                orderDetail.product_material_grn_rejected_quantity = parseInt(orderDetail.product_material_grn_quantity) - parseInt(orderDetail.product_material_grn_accepted_quantity)
                orderDetail.product_material_grn_rejected_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(orderDetail.product_material_grn_rejected_quantity * orderDetail.product_material_std_weight), 4);
                orderDetail.material_basic_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(orderDetail.product_material_grn_accepted_quantity * orderDetail.material_rate), 4);
                if (orderDetail.product_material_grn_rejected_quantity < 1) {
                  orderDetail.product_material_rejection_reason_id = '';
                }
              }
            }
            if (isNaN(parseFloat(orderDetail.material_rate))) {
              orderDetail.material_rate = 0;
            } else {
              if (orderDetail.material_rate !== "0") {
                orderDetail.material_basic_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(orderDetail.product_material_grn_accepted_quantity * orderDetail.material_rate), 4);
              }
            }
            if (isNaN(parseFloat(orderDetail.material_discount_percent))) {
              orderDetail.material_discount_percent = 0;
            } else {
              const enteredPercent = validateNumberDateInput.current.decimalNumber(orderDetail.material_discount_percent, 2) || 0;
              const calculatedAmount = (enteredPercent / 100) * orderDetail.material_basic_amount;
              if (validateNumberDateInput.current.percentValidate(enteredPercent)) {
                orderDetail.material_discount_percent = enteredPercent;
                orderDetail.material_discount_amount = validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedAmount), 4);
              }
            }

            return orderDetail;
          });
          purhcase_orderNO = readedOrderDetailsFromExcel[0].purchase_order_no;
          for (let formFieldIndex = 0; formFieldIndex < response.formFieldData.length; formFieldIndex++) {
            const element = response.formFieldData[formFieldIndex];
            if (element !== "") {
              const field = element.split(':');
              const fieldName = field[0].trim();
              const fieldVal = field[1].trim();
              let comboVal;
              if (fieldVal !== "" && fieldName === 'Supplier' || fieldName === 'Expected Branch' || fieldName === 'Qa By') {
                const splitVal = fieldVal.split('(')
                comboVal = splitVal[1].replace(')', '')
              }
              switch (fieldName) {
                case 'Supplier':
                  setSupplierId(comboVal)
                  comboOnChange('Supplier')
                  break;
                case 'Supplier Challan No.':
                  setSupplierChallanNo(fieldVal)
                  break;
                case 'Supplier Challan Date':
                  setdtSupplierChallanDate(fieldVal)
                  break;
                case 'Purchase Order Life':
                  setPurchaseOrderLife(fieldVal)
                  break;
              }

            }
          }

          setGrnDetailsData(() => {
            let modifiedImportData = readedOrderDetailsFromExcel.map((ordDetail) => ({
              ...ordDetail,
              goods_receipt_details_transaction_id: 0,
              hsn_sac_code: ordDetail.product_material_hsn_sac_code,
              material_schedule_date: validateNumberDateInput.current.formatDateToDDMMYYYY(ordDetail.material_schedule_date)

            }));

            modifiedImportData = modifiedImportData.map(FnMaterialWiseCalculations);
            return modifiedImportData;
          });
          if (purhcase_orderNO !== "") {
            resetGlobalQuery();
            globalQuery.columns = ["*"];
            globalQuery.table = "ptv_purchase_order_payment_terms";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "purchase_order_no", operator: "=", value: purhcase_orderNO });
            const getGaymentTermsData = await comboDataFunc.current.fillFiltersCombo(globalQuery)
            setPaymentTermsData(getGaymentTermsData)
          } else {
            setPaymentTermsData([])
          }



          setIsLoading(false)
        } else {
          setErrMsg(response.error)
          setIsLoading(false)
          setShowErrorMsgModal(true)

        }
      }
    } catch (error) {
      console.log("error: ", error)
      navigator('/Error')
    }
  }


  //---------------------------------------------------- Accordian data load starts --------------------------------------------------------------------------------
  const FnLoadAccordionData = async (eventKey) => {
    switch (eventKey) {

      case 'paymentTerms':
        // if (grnTaxSummaryData.length === 0) {
        //   FnShowPaymentTermsRecrds();
        // }
        break;
      case 'documentList':
        await showDocumentRecords();
        break;
      default:
        break;
    }
    if (keyForViewUpdate === "view" || keyForViewUpdate === "approve" || keyForViewUpdate === "delete") {
      $('input[type="checkbox"]').prop('disabled', true);
    }
  }
  //---------------------------------- Payment Terms section Start -----------------------------------------------------------------------


  const FnShowPaymentTermsRecrds = () => {
    let existingPayTermsData = existingPaymentTermsData;
    setPaymentTermsData(existingPayTermsData);
  }
  //Fn for render payment terms static table 
  const renderPaymentTermsTable = useMemo(() => {
    return <>
      <Table className="erp_table " id='paymentTerms-table-id' responsive bordered striped >
        <thead className="erp_table_head">
          <tr>
            <th className="erp_table_th">Sr.No.</th>
            <th className="erp_table_th">Purchase Order No</th>
            <th className="erp_table_th">Purchase Order Date</th>
            <th className="erp_table_th">Purchase Order Version</th>
            <th className="erp_table_th">Term Names</th>
            <th className="erp_table_th">Days </th>
            <th className="erp_table_th">Grace Days</th>
            <th className="erp_table_th">Milestome</th>
            <th className="erp_table_th">Payment Percent</th>
            <th className="erp_table_th">Expected Value</th>
            <th className="erp_table_th">Expected Date</th>
            <th className="erp_table_th">Payment Paid</th>
            <th className="erp_table_th">Payment Paid Transaction Id</th>
            <th className="erp_table_th">Payment Paid Date</th>
          </tr>
        </thead>
        <tbody>
          {
            paymentTermsData.map((payTermItem, Index) =>
              <tr payTermItemIndex={Index}>
                <td className="erp_table_td text-end">{Index + 1}</td>
                <td className="erp_table_td">{payTermItem.purchase_order_no}</td>
                <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(payTermItem.purchase_order_date)}</td>
                <td className="erp_table_td text-end">{payTermItem.purchase_order_version}</td>
                <td className="erp_table_td">{payTermItem.payment_terms_name}</td>
                <td className="erp_table_td text-end">{payTermItem.payment_terms_days}</td>
                <td className="erp_table_td text-end">{payTermItem.payment_terms_grace_days}</td>
                <td className="erp_table_td">{payTermItem.payment_terms_Milestome}  </td>
                <td className="erp_table_td text-end">{payTermItem.payment_percent}</td>
                <td className="erp_table_td text-end">{payTermItem.payment_expected_value} </td>
                <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(payTermItem.payment_expected_date)}</td>
                <td className="erp_table_td ">
                  <select id={`payment_paid_flag_${payTermItem.payment_terms_id}`} value={payTermItem.payment_paid_flag} className="form-select form-select-sm mb-1"
                    Headers='payment_paid_flag' disabled >
                    <option value="P">Pending</option>
                    <option value="T">Partail</option>
                    <option value="R">Receipt</option>
                  </select>
                </td>
                <td className="erp_table_td text-end">{payTermItem.payment_paid_transaction_id} </td>
                <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(payTermItem.payment_paid_date)} </td>
              </tr>
            )
          }
        </tbody>
      </Table>
    </>
  }, [paymentTermsData]);


  //----------------------------------------  Payment Terms Section End ----------------------------------------------------------


  //--------------------------------- Clear Details form start------------------------------------------------------------------------------------------------
  const FnClearForm = async () => {
    await generateGrnNo();
    setGRNVersion(1)
    setGoodReceiptDate(today())
    setSupplierId('')
    setSuppliersStateId('')
    setSuppliersCityId('')
    setSupplierChallanNo('')
    setdtSupplierChallanDate('')
    setExpectedBranchId(COMPANY_BRANCH_ID)
    comboOnChange('')
    setExpectedBranchStateId('')
    setExpectedBranchCityId('')
    setPurchaseOrderLife('C')
    setApprovedById('')
    setApprovedDate(today)
    setQabyId(UserId)
    setQadate(today)
    setBasicTotal(0)
    setTransportAmount(0)
    setFreightAmount(0)
    setFreightHSNId('')
    setFreightIsTaxable(false)
    setPackingAmount(0)
    setGoodsReceiptDiscountPercent(0)
    setGoodsReceiptDiscountAmount(0)
    setOtherAmount(0)
    setTaxableTotal(0)
    setCgstTotal(0)
    setSgstTotal(0)
    setIgstTotal(0)
    setGrandTotal(0)
    setRoundOff(0)
    setAgentPercent(0)
    // setAgentId('')
    // Set the default Agent
    let defaultAgent = agentsList.find(agent => agent.field_name === 'NA');
    if (defaultAgent) { setAgentId(defaultAgent.field_id); }
    setAgentPaidStatus('');
    setGoodsReceiptStatus('')
    setlrNo('')
    setlrDate('')
    setInvoice_no('')
    setInvoice_date('')
    setVehicleNo('')
    setOtherTermsConditions('')
    setRemark('')
    setIsActive(true)
    setIsPreeClosed(false)
    setIsSez(false)
    setPurchaseOrderNoList([])
    setSuppContactDetails([])
    setSuppContactDetailsCols([])
    setPOInfoList([])
    setCheckedPurchaseOrderNoLength(0)
    setGrnDetailsData([])
    setGrnTaxSummaryData([])
    setPaymentTermsData([])
    let expectedBranch = expectedBranchList.find(item => item.company_branch_id === parseInt(COMPANY_BRANCH_ID))
    setExpectedBranchStateId(expectedBranch.branch_state_id)
    await comboOnChange('ExpectedBranchState')
    setExpectedBranchCityId(expectedBranch.branch_city_id)
    setIsSez(expectedBranch.is_sez)
  }

  //--------------------------------- Clear Details form Ends------------------------------------------------------------------------------------------------
  //--------------------------------- Delete PO Starts------------------------------------------------------------------------------------------------

  async function deleteGrn() {
    try {
      const method = { method: 'delete' }
      const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtGoodsReceiptDetails/FnDeleteRecord/${goods_receipt_master_transaction_id}/${UserName}`, method)
      const responce = await deleteApiCall.json();
      console.log("Raw material Deleted: ", responce);
      if (responce.success == '1') {
        setShow(false)
        navigator('/Transactions/TPurchaseOrder/GoodsReceiptNote/GoodsReceiptNoteListing');
      }
    } catch (error) {
      console.log("error: ", error)
    }
  }
  //--------------------------------- Delete PO Ends------------------------------------------------------------------------------------------------
  //--------------------------------- Document List Starts--------------------------------------------------

  // Fn for get document list
  const showDocumentRecords = async () => {
    try {
      if (COMPANY_ID !== null) {
        const res = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${documentGroup}&group_id=${txt_grn_no !== 0 ? txt_grn_no.replaceAll('/', '_') : null}`
        );
        const resp = await res.json();
        if (resp.content.length > 0) {
          setDocumentData(resp.content);
        }
      }

    } catch (error) {
      console.log("error: ", error)
    }
  }

  // Fn for get document table
  const renderDocumentTable = useMemo(() => {
    return <>
      <Table className="erp_table " id='document-table' responsive bordered striped>
        <thead className="erp_table_head">
          <tr>
            <th className="erp_table_th">Group Id</th>
            <th className="erp_table_th">Document Group</th>
            <th className="erp_table_th">Document Name</th>
            <th className="erp_table_th">Registration No</th>
            <th className="erp_table_th">Registration Date</th>
            <th className="erp_table_th">Renewal Date</th>
            <th className="erp_table_th">Document Path</th>
            <th className="erp_table_th">Remark</th>
            <th className="erp_table_th">File Name</th>
          </tr>
        </thead>
        <tbody>
          {
            docData.map((docItem, Index) =>
              <tr rowIndex={Index}>
                <td className="erp_table_td"> {docItem.group_id} </td>
                <td className="erp_table_td">{docItem.document_group}</td>
                <td className="erp_table_td">{docItem.document_name}</td>
                <td className="erp_table_td ">{docItem.document_registration_no}</td>
                <td className="erp_table_td ">{docItem.document_registration_date}</td>
                <td className="erp_table_td ">{docItem.document_renewal_date}</td>
                <td className="erp_table_td ">{docItem.document_path}</td>
                <td className="erp_table_td ">{docItem.remark}</td>
                <td className="erp_table_td ">
                  <MDTypography component="label" className="erp-form-label" variant="button" id="logoFile" fontWeight="regular" color="info" >
                    <Link to="#" onClick={() => { fetchDocument(docItem) }}>
                      {docItem.file_name}
                    </Link></MDTypography>
                </td>
              </tr>
            )
          }
        </tbody>
      </Table>
    </>
  }, [docData]);



  // Fn for get document download
  const fetchDocument = async (docItem) => {
    try {

      const formData = new FormData()
      const data = {
        document_group: docItem.document_group,
        group_id: docItem.group_id,
        document_path: docItem.document_path
      }
      formData.append(`getFile`, JSON.stringify(data))
      const requestOptions = {
        method: 'POST',
        body: formData
      };

      const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
      const blob = await getDocApiCall.blob()

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${docItem.file_name}`,);
      document.body.appendChild(link);

      link.click();
      link.parentNode.removeChild(link);


    } catch (error) {
      console.log("error: ", error)
    }
  }
  //--------------------------------- Document List Ends------------------------------------------------------------------------------------------------

  return (
    <>
      <ValidateNumberDateInput ref={validateNumberDateInput} />
      <ComboBox ref={comboDataFunc} />
      <GenerateTAutoNo ref={generateAutoNoAPiCall} />
      <FrmValidations ref={validate} />
      <ExcelExport ref={exlsExp} />

      <DashboardLayout>
        {isLoading ?
          <div className="spinner-overlay"  >
            <div className="spinner-container">
              <CircularProgress color="primary" />
              <span id="spinner_text" className="text-dark">Loading...</span>
            </div>
          </div> :
          ''}
        <form id="GRNHeaderFormId">
          <div className='card mt-4 px-2 py-2'>
            <div className="card-header text-center py-0">
              <label className='erp-form-label-lg main_heding'>Good Receipt Notes {actionType}</label>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 erp_form_col_div">
                {/* <div className='row'>
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">Goods Receipt Type <span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_goods_receipt_type_id" className="form-select form-select-sm" value={cmb_goods_receipt_type_id} onChange={(e) => { comboOnChange('GoodsReceiptType'); validateFields(); }} disabled={keyForViewUpdate === 'update'}>
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {goodReceiptTypeList?.map(GoodsReceiptType => (
                        <option value={GoodsReceiptType.field_id}>{GoodsReceiptType.field_name}</option>
                      ))}
                    </select>
                    <MDTypography variant="button" id="error_cmb_goods_receipt_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                 
                </div> */}

                <div className='row'>
                  <div className="col-sm-3 col-12">
                    <Form.Label className="erp-form-label">GRN No, Version & Date<span className="required">*</span></Form.Label>
                  </div>
                  <div className="col-sm-9 col-12">
                    <div className="row">
                      <div className="col-12 col-md-6 pe-md-0">
                        <Form.Control type="text" id="txt_grn_no" className="erp_input_field" value={txt_grn_no} optional='optional' disabled />
                      </div>
                      <div className="col-12 col-md-2 pt-md-0 pt-3">
                        <Form.Control type="text" id='txt_grn_version' className="erp_input_field" value={txt_grn_version} optional='optional' disabled />
                      </div>
                      <div className="col-12 col-md-4 pt-md-0 pt-3 ">
                        <Form.Control type="date" id='dt_goods_receipt_date' className="erp_input_field" value={dt_goods_receipt_date} onChange={(e) => { setGoodReceiptDate(e.target.value); validateFields(); }} min={currentDate} />
                        <MDTypography variant="button" id="error_dt_goods_receipt_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className="col-sm-3 col-12">
                    <Form.Label className="erp-form-label">Supplier <span className="required">*</span> </Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_supplier_id" className="form-select form-select-sm" value={cmb_supplier_id} onChange={(e) => { comboOnChange('Supplier'); validateFields(); }}   >
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {supplierList?.map(supplier => (
                        <option value={supplier.field_id}>{supplier.field_name}</option>
                      ))}
                    </select>
                    <MDTypography variant="button" id="error_cmb_supplier_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                  <div className="col-sm-1 col-2">
                    <Tooltip title="Refresh" placement="top">
                      <MDTypography className={`${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                        <MdRefresh style={{ color: 'black' }} onClick={() => { refreshData('get_supplier') }} />
                      </MDTypography>
                    </Tooltip>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">Supplier State <span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_supplier_state_id" className="form-select form-select-sm" value={cmb_supplier_state_id} optional='optional' disabled>
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {stateList?.map(SupplierState => (
                        <option value={SupplierState.field_id}>{SupplierState.field_name}</option>
                      ))}
                    </select>
                    <MDTypography variant="button" id="error_cmb_supplier_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">Supplier city <span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_supplier_city_id" className="form-select form-select-sm" value={cmb_supplier_city_id} optional='optional' disabled >
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {cityList?.map(Suppliercity => (
                        <option value={Suppliercity.field_id}>{Suppliercity.field_name}</option>
                      ))}
                    </select>
                    <MDTypography variant="button" id="error_cmb_supplier_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>

                <div className='row'>
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">PO No.<span className="required">*</span></Form.Label>
                  </div>

                  <div className="col-sm-7">
                    <div className="select-btn" onClick={() => FnTogglePurchaseOrderNoList()} data-optional="optional">
                      <span className="form-select form-select-sm">{checkedPurchaseOrderNoLength !== 0 ? checkedPurchaseOrderNoLength + ' Selected' : 'Select'}</span>
                    </div>
                    <ul className="list-items" id="purchase-order-ul">
                      {purchaseOrderNoList}
                    </ul>
                  </div>
                  <div className="col-sm-2 ">
                    <MDButton type="button" id="podetails-btn" className={`erp-gb-button ${isApprove || keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={() => showPODetails(storedGrnDetailsData)} disabled={isApprove || keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' ? true : false}>show</MDButton>
                  </div >
                </div>

                <div className='row'>
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">Supplier Challan No. & Date<span className="required">*</span></Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_supplier_challan_no" className="erp_input_field" value={txt_supplier_challan_no} disabled={keyForViewUpdate === "" ? false : true}
                      onBlur={(e) => { comboOnChange("challan_no"); validateFields(); }} onChange={(e) => { setSupplierChallanNo(e.target.value); validateFields(); }} />
                    <MDTypography variant="button" id="error_txt_supplier_challan_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                  <div className="col">
                    <Form.Control type="date" id='dt_supplier_challan_Date' className="erp_input_field" value={dt_supplier_challan_Date} disabled={keyForViewUpdate === "" ? false : true}
                      onChange={e => { setdtSupplierChallanDate(e.target.value); validateFields(); }} />
                    <MDTypography variant="button" id="error_dt_supplier_challan_Date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
              </div>

              {/* sec row */}
              <div className="col-sm-6 erp_form_col_div">
                <div className='row'>
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">Invoice No & Date</Form.Label>
                  </div>
                  <div className='col'>
                    <Form.Control type="text" id="txt_invoice_no" className="erp_input_field" value={txt_invoice_no} onChange={(e) => { setInvoice_no(e.target.value); validateFields(); }} optional='optional' />
                    <MDTypography variant="button" id="error_txt_invoice_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>

                  <div className="col">
                    <Form.Control type="date" id='dt_invoice_date' className="erp_input_field" value={dt_invoice_date} min={currentDate} onChange={e => { setInvoice_date(e.target.value); }} optional='optional' />
                    <MDTypography variant="button" id="error_dt_invoice_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-3 col-12'>
                    <Form.Label className="erp-form-label">Expected Branch <span className="required">*</span> </Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_expected_branch_id" className="form-select form-select-sm" value={cmb_expected_branch_id} onChange={(e) => { comboOnChange('ExpectedbranchId'); validateFields(); }}   >
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {expectedBranchList?.map(Expectedbranchid => (
                        <option value={Expectedbranchid.company_branch_id} is_sez={`${Expectedbranchid.is_sez}`}>{Expectedbranchid.company_branch_name}</option>
                      ))}
                    </select>
                    <MDTypography variant="button" id="error_cmb_expected_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                  <div className="col-sm-1 col-2">
                    <Tooltip title="Refresh" placement="top">
                      <MDTypography className={`${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                        <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_exptBranch') } }} />
                      </MDTypography>
                    </Tooltip>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label"> Expected Branch State <span className="required">*</span> </Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_expected_branch_state_id" className="form-select form-select-sm" value={cmb_expected_branch_state_id} optional='optional' disabled>
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {stateList?.map(state => (
                        <option value={state.field_id}>{state.field_name}</option>

                      ))}
                    </select>
                    <MDTypography variant="button" id="error_cmb_expected_branch_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>


                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Expected Branch City <span className="required">*</span> </Form.Label>
                  </div>
                  <div className='col'>
                    <select id="cmb_expected_branch_city_id" className="form-select form-select-sm" value={cmb_expected_branch_city_id} optional='optional' disabled >
                      <option value="">Select</option>
                      <option value="0">Add New Record+</option>
                      {expectedBranchCityList?.map(city => (
                        <option value={city.field_id}>{city.field_name}</option>

                      ))}
                    </select>
                    <MDTypography variant="button" id="error_cmb_expected_branch_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div>


                <div className="row">
                  <div className="col-sm-3">
                    <Form.Label className="erp-form-label">Purchase Order life</Form.Label>
                  </div>
                  <div className="col">
                    <div className="erp_form_radio">
                      <div className="fCheck"> <Form.Check className="erp_radio_button" label="Closed" type="radio" lbl="Closed" value="C" name="rb_purchase_order_life" checked={rb_purchase_order_life === "C"} onClick={() => { setPurchaseOrderLife("C"); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                      <div className="sCheck"> <Form.Check className="erp_radio_button" label="Open" type="radio" lbl="Open" value="O" name="rb_purchase_order_life" checked={rb_purchase_order_life === "O"} onClick={() => { setPurchaseOrderLife("O"); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>

                    </div>
                  </div>
                </div>



                {
                  isApprove || (keyForViewUpdate === 'view' && cmb_goods_receipt_status !== '') ? <>
                    <div className='row'>
                      <div className='col-sm-3'>
                        <Form.Label className="erp-form-label">Apprv. By & Date <span className="required">*</span></Form.Label>
                      </div>
                      <div className="col-sm-9 col-12">
                        <div className="row">
                          <div className="col-12 col-md-8 ">
                            <select id="cmb_approved_by_id" className="form-select form-select-sm" value={cmb_approved_by_id} onChange={(e) => { setApprovedById(e.target.value); validateFields(); }} disabled >
                              <option value="">Select</option>
                              <option value="0">Add New Record+</option>
                              {employeeList?.map(employee => (
                                <option value={employee.field_id}>{employee.field_name}</option>
                              ))}
                            </select>
                            <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                            </MDTypography>
                          </div>
                          <div className="col-12 col-md-4 pt-md-0 pt-3">
                            <Form.Control type="date" id='dt_approved_date' className="erp_input_field" value={dt_approved_date} onChange={(e) => { setApprovedDate(e.target.value); validateFields(); }} readOnly />
                            <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </> : null
                }

                <div className='row'>
                  <div className='col-sm-3'>
                    <Form.Label className="erp-form-label">Qa by & Qa date <span className="required">*</span></Form.Label>
                  </div>
                  <div className="col-sm-9 col-12">
                    <div className="row">
                      <div className="col-12 col-md-8 ">
                        <select id="cmb_qa_by_id" className="form-select form-select-sm" value={cmb_qa_by_id} onChange={(e) => { setQabyId(e.target.value); validateFields(); }} disabled >
                          <option value="">Select</option>
                          <option value="0">Add New Record+</option>
                          {employeeList?.map(employee => (
                            <option value={employee.field_id}>{employee.field_name}</option>
                          ))}
                        </select>
                        <MDTypography variant="button" id="error_cmb_qa_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                        </MDTypography>
                      </div>
                      <div className="col-12 col-md-4 pt-md-0 pt-3">
                        <Form.Control type="date" id='dt_qa_date' className="erp_input_field" value={dt_qa_date} onChange={(e) => { setQadate(e.target.value); validateFields(); }} />
                        <MDTypography variant="button" id="error_dt_qa_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <Accordion defaultActiveKey="0" activeKey={openContactsAccord ? '0' : null}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header onClick={(e) => setOpenContactsAccord(!openContactsAccord)} className="erp-form-label-md">Supplier's Contact Details</Accordion.Header>
                      <Accordion.Body className="p-0">
                        {
                          cmb_supplier_id !== '' && cmb_supplier_id !== "0"
                            ? <>
                              <div className={`row ps-1 py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'delete' ? 'd-none' : 'display'}`}>
                                <div className={` d-flex justify-content-start align-items-center`}>
                                  <Tooltip title="Add new supplier's contact if doesn't exist in list...!" placement="top">
                                    <MDButton type="button" className={`erp-gb-button float-start col-12 col-md-4`} variant="button" fontWeight="regular" onClick={() => {
                                      localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                      localStorage.setItem('supplierIDs', JSON.stringify({ supplierId: cmb_supplier_id, keyForViewUpdate: 'update', compType: 'Master' }))
                                    }
                                    }>  <Link className={'text-light'} to={{ pathname: "/Masters/FrmSupplier" }} target="_blank" >Add New Supplier's Contact +</Link></MDButton>
                                  </Tooltip>
                                  <Tooltip title="Refresh supplier contact list...!" placement="top">
                                    <MDButton type="button" className={`erp-gb-button float-end col-1 ms-0 ms-md-2 mt-1 mt-md-0`} variant="button" fontWeight="regular" onClick={() => { FnGetsuppContactPersonsDetails(cmb_supplier_id) }}>Refresh</MDButton>
                                  </Tooltip>
                                </div>
                              </div>
                            </>
                            : null
                        }
                        {SuppContactDetails.length !== 0 ?
                          <>
                            <Datatable data={SuppContactDetails} columns={SuppContactDetailsCols} />
                          </>
                          : <div className='row'>
                            <div className="col-12">
                              <span className="erp_validation text-center" fontWeight="regular" color="error">
                                No Records Found...
                              </span>
                            </div>
                          </div>
                        }
                        {
                          SuppContactDetails.length !== 0 && !['approve', 'view'].includes(keyForViewUpdate)
                            ? <>
                              <div className="row justify-content-center my-1">
                                <Tooltip title="Confirm selection...!" placement="right">
                                  <MDButton type="button" className={`erp-gb-button col-1`} variant="button" fontWeight="regular"
                                    onClick={(e) => { setOpenContactsAccord(false) }} >Confirm</MDButton>
                                </Tooltip>
                              </div>
                            </>
                            : null
                        }
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion >
                </div>

              </div>
            </div>
          </div>
        </form>
        <hr />
        <Accordion defaultActiveKey="0" activeKey={openDetailsAccord ? '0' : null}>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenDetailsAccord(!openDetailsAccord)}>Good Receipt Details </Accordion.Header>
            <Accordion.Body>
              <div className="d-flex row mb-2">
                <div className="col-md-4 col-12"> </div>
                <div className="col-md-6 col-12"> </div>
                <div className="col-md-2 col-12">
                  <MDButton type="button" id="export-btn" className={`erp-gb-button  erp_MLeft_btn ${keyForViewUpdate !== "view" || keyForViewUpdate !== "delete" ? "display" : "d-none"} `} variant="button" fontWeight="regular" onClick={ExportToExcel}>Export</MDButton>
                  <input type="file" id="Excelfile" ref={importFile} onChange={importedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                  <MDButton type="button" id="import-btn" className={`erp-gb-button  erp_MLeft_btn ${keyForViewUpdate !== '' && keyForViewUpdate !== 'copy' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={onImportClick} >Import</MDButton>
                </div>
              </div>
              {
                grnDetailsData.length !== 0 ?
                  <Table className="erp_table erp_table_scroll" id='grnDetailsTbl' responsive bordered striped>
                    <thead className="erp_table_head">
                      <tr>
                        <th className='erp_table_th'>Sr. No.</th>
                        <th className='erp_table_th'>Material</th>
                        <th className='erp_table_th' style={{ paddingRight: "30px" }}>Material Unit</th>
                        <th className='erp_table_th'>PO No</th>
                        <th className='erp_table_th'>PO Qty</th>
                        <th className='erp_table_th d-none'>PO Wt.</th>
                        <th className='erp_table_th'>Prev. GRN Qty</th>
                        <th className='erp_table_th d-none'>Prev GRN Wt.</th>
                        <th className='erp_table_th'>GRN Quantity</th>
                        <th className='erp_table_th d-none'>GRN Weight</th>
                        <th className='erp_table_th'>GRN Accepted Qty</th>
                        <th className='erp_table_th d-none'>GRN Accepted Wt</th>
                        <th className='erp_table_th'>GRN Rejected Qty</th>
                        <th className='erp_table_th d-none'>GRN Rejected Wt</th>
                        <th className='erp_table_th'>Rejection Reason</th>
                        <th className='erp_table_th'>Material Godown</th>
                        <th className='erp_table_th'>Godown Section</th>
                        <th className='erp_table_th'>Godown Section Beans</th>
                        <th className='erp_table_th'>PO Material Rate</th>
                        <th className='erp_table_th'>Basic Amount</th>
                        <th className='erp_table_th'>Freight Amount</th>
                        <th className='erp_table_th'> Discount %</th>
                        <th className='erp_table_th'> Discount Amount</th>
                        <th className='erp_table_th'> Taxable Amount</th>
                        <th className='erp_table_th'> Cgst %</th>
                        <th className='erp_table_th'> Cgst Total</th>
                        <th className='erp_table_th'> Sgst %</th>
                        <th className='erp_table_th'> Sgst Total</th>
                        <th className='erp_table_th'> Igst %</th>
                        <th className='erp_table_th'> Igst Total</th>
                        <th className='erp_table_th'> Total Amount</th>
                        <th className='erp_table_th'>Material Schedule Date</th>
                        <th className='erp_table_th'>Material Batch No</th>
                        <th className='erp_table_th'>Material Expiry Date</th>
                        <th className={`erp_table_th ${IS_EXCESS_ALLOWED === 'true' ? 'display' : 'd-none'}`}>Excess Quantity</th>
                        <th className={`erp_table_th ${IS_EXCESS_ALLOWED === 'true' ? 'display' : 'd-none'}`}>Excess Weight</th>
                        <th className='erp_table_th'>Customer Name</th>
                        <th className='erp_table_th'>Customer Order No</th>
                        <th className='erp_table_th'>Customer Order Date</th>
                        <th className='erp_table_th'>Technical Specification</th>
                        <th className='erp_table_th'>HSN-SAC Code</th>
                        <th className='erp_table_th'> Packing Name</th>
                        <th className='erp_table_th'> Lead Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        grnDetailsData.map((grnItem, index) =>
                          <tr rowindex={index}>
                            <td className="erp_table_td">{index + 1}</td>
                            <td className="erp_table_td">{grnItem.product_material_name}</td>
                            <td className="erp_table_td">
                              <select className="form-select form-select-sm mb-0" value={grnItem.product_material_unit_id}
                                onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                id={`product_material_unit_id_${grnItem.purchase_order_details_transaction_id}`}
                                Headers='product_material_unit_id' disabled={keyForViewUpdate === 'view' || isApprove ? true : false}>
                                <option value='0'>Select</option>
                                {unitList.map(item =>
                                  <option value={item.field_id}>{item.field_name}</option>
                                )
                                }
                              </select>
                            </td>
                            <td className="erp_table_td">{grnItem.purchase_order_no}</td>
                            <td className="erp_table_td text-end">{grnItem.product_material_po_approved_quantity}</td>
                            <td className="erp_table_td text-end d-none">{grnItem.product_material_po_approved_weight}</td>
                            <td className="erp_table_td text-end">{grnItem.prev_grn_quantity}</td>
                            <td className="erp_table_td text-end d-none">{validateNumberDateInput.current.decimalNumber(JSON.stringify(grnItem.prev_grn_weight), 4)}</td>

                            <td className="erp_table_td text-end">
                              {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.product_material_grn_quantity :
                                <input type="text"
                                  id={`product_material_grn_quantity_${grnItem.purchase_order_details_transaction_id}`}
                                  className="erp_input_field mb-0 text-end"
                                  value={grnItem.product_material_grn_quantity}
                                  onChange={(e) => {
                                    if (validateNumberDateInput.current.isInteger(e.target.value)) {
                                      updateGrnDetailsTblRow(grnItem, e);
                                    }
                                  }}
                                  onBlur={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                  Headers='product_material_grn_quantity'
                                />
                              }
                            </td>
                            <td className="erp_table_td text-end d-none">{grnItem.product_material_grn_weight}
                            </td>
                            <td className="erp_table_td text-end">
                              {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.product_material_grn_accepted_quantity :
                                <input type="text"
                                  id={`product_material_grn_accepted_quantity_${grnItem.purchase_order_details_transaction_id}`}
                                  className="erp_input_field mb-0 text-end"
                                  value={grnItem.product_material_grn_accepted_quantity}
                                  onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                  onBlur={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                  Headers='product_material_grn_accepted_quantity'
                                />
                              }
                            </td>
                            <td className="erp_table_td text-end d-none">
                              {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.product_material_grn_accepted_weight :
                                <input type="text"
                                  id={`product_material_grn_accepted_weight_${grnItem.purchase_order_details_transaction_id}`}
                                  className="erp_input_field mb-0 text-end"
                                  value={grnItem.product_material_grn_accepted_weight}
                                  onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                  Headers='product_material_grn_accepted_weight'
                                />
                              }
                            </td>
                            <td className="erp_table_td text-end" rejectedQty={grnItem.product_material_grn_rejected_quantity}>{grnItem.product_material_grn_rejected_quantity}</td>
                            <td className="erp_table_td text-end d-none">{grnItem.product_material_grn_rejected_weight}</td>

                            <td className="erp_table_td">
                              {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.product_rejection_parameters_name :
                                <select className="form-select form-select-sm mb-0" value={grnItem.product_material_rejection_reason_id}
                                  onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                  id={`product_material_rejection_reason_id_${grnItem.purchase_order_details_transaction_id}`}
                                  Headers='product_material_rejection_reason_id' disabled={keyForViewUpdate === 'view' || isApprove ? true : grnItem.product_material_grn_rejected_quantity > 0 ? false : true}>

                                  <option value='0'>Select</option>
                                  {rejectionParameterList.map(item =>
                                    <option value={item.field_id}>{item.field_name}</option>
                                  )
                                  }
                                </select>}
                            </td>

                            <td className="erp_table_td">
                              <select className="form-select form-select-sm mb-0" id={`godown_id_${grnItem.purchase_order_details_transaction_id}`} value={grnItem.godown_id}
                                onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                disabled={isApprove ? true : false}
                                Headers='godown_id' gdDetailsId={grnItem.purchase_order_details_transaction_id}>
                                <option value="">Select</option>
                                {godownsList?.map(godown => (
                                  <option value={godown.field_id}>{godown.field_name}</option>
                                ))}
                              </select>
                            </td>

                            <td className="erp_table_td text-end">
                              <select className="form-select form-select-sm mb-0" id={`godown_section_id_${grnItem.purchase_order_details_transaction_id}`} value={grnItem.godown_section_id}
                                onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                disabled={isApprove ? true : false}
                                Headers='godown_section_id' gdDetailsId={grnItem.purchase_order_details_transaction_id}>
                                <option value="">Select</option>
                              </select>
                            </td>

                            <td className="erp_table_td text-end">
                              <select className="form-select form-select-sm mb-0" id={`godown_section_beans_id_${grnItem.purchase_order_details_transaction_id}`} value={grnItem.godown_section_beans_id}
                                onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                godown_section_id={grnItem.godown_section_id}
                                disabled={isApprove ? true : false}
                                Headers='godown_section_beans_id' gdDetailsId={grnItem.purchase_order_details_transaction_id}>
                                <option value="">Select</option>
                              </select>
                            </td>

                            <td className='erp_table_td text-end'>
                              {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.material_rate :
                                <input type="text"
                                  id={`material_rate_${grnItem.purchase_order_details_transaction_id}`}
                                  className="erp_input_field mb-0 text-end"
                                  value={grnItem.material_rate}
                                  onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                  onBlur={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                  Headers='material_rate'
                                />
                              }
                            </td>
                            <td className='erp_table_td text-end'>{grnItem.material_basic_amount}</td>
                            <td className='erp_table_td text-end'>{grnItem.material_freight_amount}</td>
                            <td className='erp_table_td text-end'>
                              {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.material_discount_percent :
                                <input type="text"
                                  id={`material_discount_percent_${grnItem.purchase_order_details_transaction_id}`}
                                  className="erp_input_field mb-0 text-end"
                                  value={grnItem.material_discount_percent}
                                  onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                  Headers='material_discount_percent'
                                />
                              }
                            </td>
                            <td className='erp_table_td text-end'>
                              {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.material_discount_amount :
                                <input type="text"
                                  id={`material_discount_amount_${grnItem.purchase_order_details_transaction_id}`}
                                  className="erp_input_field mb-0 text-end"
                                  value={grnItem.material_discount_amount}
                                  onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                  onBlur={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                  Headers='material_discount_amount'
                                />
                              }
                            </td>

                            <td className='erp_table_td text-end'> {grnItem.material_taxable_amount}  </td>
                            <td className='erp_table_td text-end'>{grnItem.material_cgst_percent}</td>
                            <td className='erp_table_td text-end'>{grnItem.material_cgst_total}</td>
                            <td className='erp_table_td text-end'> {grnItem.material_sgst_percent}</td>
                            <td className='erp_table_td text-end'> {grnItem.material_sgst_total}</td>
                            <td className='erp_table_td text-end'>{grnItem.material_igst_percent}</td>
                            <td className='erp_table_td text-end'> {grnItem.material_igst_total}</td>
                            <td className='erp_table_td text-end'> {grnItem.material_total_amount}</td>
                            <td className="erp_table_td">
                              {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.material_schedule_date :
                                <input type="date"
                                  id={`material_schedule_date_${grnItem.purchase_order_details_transaction_id}`}
                                  className="erp_input_field mb-0"
                                  value={grnItem.material_schedule_date}
                                  onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                  Headers='material_schedule_date'
                                />
                              }
                            </td>

                            <td className="erp_table_td">
                              {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.batch_no :
                                <input type="text"
                                  id={`batch_no_${grnItem.purchase_order_details_transaction_id}`}
                                  className="erp_input_field mb-0"
                                  value={grnItem.batch_no === undefined ? grnItem.batch_no = `${FINANCIAL_SHORT_NAME}/${txt_grn_no}/${today()} ` : grnItem.batch_no}
                                  onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                  Headers='batch_no'
                                />
                              }
                            </td>
                            <td className="erp_table_td">
                              {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.expiry_date :
                                <input type="date"
                                  id={`expiry_date_${grnItem.purchase_order_details_transaction_id}`}
                                  className="erp_input_field mb-0"
                                  value={grnItem.expiry_date}
                                  onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                  Headers='expiry_date'
                                />
                              }
                            </td>
                            <td className={`erp_table_td ${IS_EXCESS_ALLOWED === 'true' ? 'display' : 'd-none'}`}>
                              {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.excess_quantity :
                                <input type="text"
                                  id={`excess_quantity_${grnItem.purchase_order_details_transaction_id}`}
                                  className="erp_input_field mb-0"
                                  value={grnItem.excess_quantity}
                                  onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                  Headers='excess_quantity'
                                />
                              }
                            </td>
                            <td className={`erp_table_td ${IS_EXCESS_ALLOWED === 'true' ? 'display' : 'd-none'}`}>
                              {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? grnItem.excess_weight :
                                <input type="text"
                                  id={`excess_weight_${grnItem.purchase_order_details_transaction_id}`}
                                  className="erp_input_field mb-0"
                                  value={grnItem.excess_weight}
                                  onChange={(e) => { updateGrnDetailsTblRow(grnItem, e); }}
                                  Headers='excess_weight'
                                />
                              }
                            </td>
                            <td className="erp_table_td">{grnItem.customer_name}</td>
                            <td className="erp_table_td">{grnItem.customer_order_no}</td>
                            <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(grnItem.customer_order_Date)}</td>
                            <td className='erp_table_td'>{grnItem.product_material_tech_spect}</td>
                            <td className='erp_table_td'>{grnItem.hsn_sac_code}</td>
                            <td className='erp_table_td'> {grnItem.product_packing_name}</td>
                            <td className='erp_table_td'> {grnItem.lead_time}</td>

                          </tr>
                        )

                      }
                    </tbody>
                  </Table> : null
              }

              {grnTaxSummaryData.length !== 0 ?
                <>
                  <hr />
                  {memoizedTaxSummaryComponent} </> :
                null
              }

            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {/* Payment terms details */}
        <hr />
        <Accordion defaultActiveKey="1" onSelect={FnLoadAccordionData} >
          <Accordion.Item eventKey="paymentTerms">
            <Accordion.Header className="erp-form-label-md">Payment Terms</Accordion.Header>
            <Accordion.Body>
              {/* <div className={`row py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                <div className="col-12 col-sm-6">
                  <input type='checkbox' class="selectAllPayTerms" id="selectAllPayTerms" onClick={(e) => toggleChkAllBoxes('selectAllPayTerms')} /> <label class="erp-form-label pb-1"> Select All </label>
                </div>
                <div className="col-12 col-sm-6">
                  <MDButton type="button" className={`erp-gb-button float-sm-end col-1 ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular"
                    onClick={() => {
                      sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                      setHeaderName('Payment Terms'); // CommonParamterEntry
                      setShowAddRecModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                    }}>Add
                  </MDButton>
                </div>
              </div> */}
              {paymentTermsData.length !== 0 ? (
                renderPaymentTermsTable
              ) : (
                <div className='row'>
                  <div className="col-12  text-center">
                    <span className="erp_validation text-center" fontWeight="regular" color="error">
                      No Records Found...
                    </span>
                  </div>
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {keyForViewUpdate !== '' ?
          <> <hr />
            <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
              <Accordion.Item eventKey="documentList">
                <Accordion.Header className="erp-form-label-md p-0">Document List</Accordion.Header>
                <Accordion.Body>
                  <div className={`table-responsive ${docData.length > 4 ? 'erp_table_scroll' : ''}`}>
                    {docData.length !== 0 ? (
                      renderDocumentTable
                    ) : (null)}
                  </div>
                  {docData.length === 0
                    ? <div className='row'>
                      <div className="col-12 text-center">
                        <span className="erp_validation text-center" fontWeight="regular" color="error">
                          No Records Found...
                        </span>
                      </div>
                    </div>
                    : null
                  }
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
          : null
        }
        <hr />
        <div className="card">
          <div className="card-header main_heding text-start">
            <label className='erp-form-label-lg'>Good Receipt Note Totals</label>
          </div>
          <div className="card-body">
            <form id="GRNFooterFormId">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">
                  {/* first column */}
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Basic Total</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_basic_total" className="erp_input_field text-end" value={txt_basic_total} onChange={(e) => setBasicTotal(e.target.value)} optional='optional' disabled />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Transport Amount</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_transport_amount" className="erp_input_field text-end" value={txt_transport_amount} onChange={(e) => { setTransportAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(grnDetailsData, grnTaxSummaryData) }} optional='optional' />
                      <MDTypography variant="button" id="error_transport_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Freight Amount</Form.Label>
                    </div>
                    <div className="col-sm-8 col-12">
                      <div className="row">
                        <div className="col-12 col-md-3">
                          <Form.Check className="erp-form-label" type="checkbox" label={`IsTaxable`} id="chk_freight_isTaxable" checked={chk_freight_isTaxable} onChange={(e) => { setFreightIsTaxable(e.target.checked); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} />
                        </div>
                        <div className="col-12 col-md-3">
                          <Form.Control type="text" id='txt_freight_amount' className="erp_input_field text-end" value={txt_freight_amount}
                            onChange={(e) => { setFreightAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); reverseCalculationForDiscount(grnDetailsData); calculateTaxationSummary(grnDetailsData); }}
                            optional='optional'
                          />
                          <MDTypography variant="button" id="error_txt_freight_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                        <div className="col-12 col-md-6">
                          <select id="cmb_freight_hsn_id" className="form-select form-select-sm" value={cmb_freight_hsn_id}
                            onChange={(e) => {
                              setFreightHSNId(e.target.value);
                              calculateTaxationSummary(grnDetailsData);
                            }}
                            disabled={!chk_freight_isTaxable || keyForViewUpdate === 'view' || isApprove} optional='optional'>
                            <option value="" disabled>Select</option>
                            {freightHSNList?.map(hsnCode => (
                              <option value={hsnCode.hsn_sac_id} hsn_rate={hsnCode.hsn_sac_rate} lbl={hsnCode.hsn_sac_code}>{hsnCode.hsn_sac_code}</option>
                            ))}
                          </select>
                          <MDTypography variant="button" id="error_cmb_freight_hsn_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                          </MDTypography>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Packing Amount</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_packing_amount" className="erp_input_field text-end" value={txt_packing_amount} onChange={(e) => { setPackingAmount(e.target.value); calculateGrandTotal(grnDetailsData, grnTaxSummaryData) }} optional='optional' />
                      <MDTypography variant="button" id="error_txt_packing_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Discount Percent & Amount</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="number" id="txt_goods_receipt_discount_percent" className="erp_input_field text-end" value={txt_goods_receipt_discount_percent} onChange={(e) => { if (validateNumberDateInput.current.percentValidate(e.target.value)) { setGoodsReceiptDiscountPercent(e.target.value); FnFooterPercentChange(e) } }} optional='optional' />
                      <MDTypography variant="button" id="error_txt_goods_receipt_discount_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    <div className="col">
                      <Form.Control type="text" id='txt_goods_receipt_discount_amount' className="erp_input_field text-end" value={txt_goods_receipt_discount_amount} onChange={(e) => { if (parseFloat(e.target.value) <= txt_basic_total) { setGoodsReceiptDiscountAmount(e.target.value); reverseCalculationForDiscount(grnDetailsData); } }} optional='optional' />
                      <MDTypography variant="button" id="error_txt_goods_receipt_discount_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>


                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Other Amount</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_other_amount" className="erp_input_field text-end" value={txt_other_amount}
                        onChange={(e) => { setOtherAmount(e.target.value); calculateGrandTotal(grnDetailsData, grnTaxSummaryData) }} optional='optional' />
                      <MDTypography variant="button" id="error_txt_other_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Taxable Total</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_taxable_total" className="erp_input_field text-end" value={txt_taxable_total} onChange={(e) => setTaxableTotal(e.target.value)} optional='optional' disabled />
                      <MDTypography variant="button" id="error_txt_taxable_total" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">CGST Total</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_cgst_total" className="erp_input_field text-end" value={txt_cgst_total} onChange={(e) => { setCgstTotal(e.target.value); }} optional='optional' disabled />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">SGST total</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_sgst_total" className="erp_input_field text-end" value={txt_sgst_total} onChange={(e) => setSgstTotal(e.target.value)} optional='optional' disabled />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">IGST Total</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_igst_total" className="erp_input_field text-end" value={txt_igst_total} onChange={(e) => setIgstTotal(e.target.value)} optional='optional' disabled />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Grand Total & RoundOff<span className="required">*</span></Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_grand_total" className="erp_input_field text-end" value={txt_grand_total} optional='optional' disabled />
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_roundOff" className="erp_input_field text-end" value={txt_roundOff} optional='optional' disabled />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 col-12">
                      <Form.Label className="erp-form-label">Agent</Form.Label>
                    </div>
                    <div className='col-sm-7 col-10'>
                      <select id="cmb_agent_id" className="form-select form-select-sm" value={cmb_agent_id} onChange={(e) => { setAgentId(e.target.value); comboOnChange('agent'); }} optional='optional'>
                        <option value="">Select</option>
                        <option value="0">Add New Record+</option>
                        {agentsList?.map(cmb_agentId => (
                          <option value={cmb_agentId.field_id}>{cmb_agentId.field_name}</option>
                        ))}
                      </select>
                      <MDTypography variant="button" id="error_cmb_agent_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    <div className="col-sm-1 col-2">
                      <Tooltip title="Refresh" placement="top">
                        <MDTypography className={`${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                          <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_agent') } }} />
                        </MDTypography>
                      </Tooltip>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Agent Percent & Paid Status</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="number" id="txt_agent_percent" className="erp_input_field" value={txt_agent_percent} onChange={(e) => { if (validateNumberDateInput.current.percentValidate(e.target.value)) { setAgentPercent(e.target.value) }; }} optional='optional' />
                      <MDTypography variant="button" id="error_txt_agent_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                    <div className='col'>
                      <select id="cmb_agent_paid_status" className="form-select form-select-sm" value={cmb_agent_paid_status} onChange={(e) => { setAgentPaidStatus(e.target.value); validateFields(); }} optional='optional' >
                        <option value="">Select</option>
                        <option value="P">Pending</option>
                        <option value="A">Aprroved</option>
                        <option value="C">Completed</option>
                        <option value="X">Canceled</option>
                      </select>
                      <MDTypography variant="button" id="error_cmb_agent_paid_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <Form.Label className="erp-form-label">Goods Receipt Status</Form.Label>
                    </div>
                    <div className='col'>
                      <select id="cmb_goods_receipt_status" className="form-select form-select-sm" value={cmb_goods_receipt_status} onChange={(e) => { setGoodsReceiptStatus(e.target.value); comboOnChange('GoodReceiptStatus') }} optional='optional' disabled={isApprove ? false : true} >
                        <option value="">Select</option>
                        <option value="G">GRN Done</option>
                        <option value="Q">QC Done</option>
                        <option value="R">Rejected</option>
                        <option value="I">Partial Receipt</option>
                        <option value="C">Completed</option>
                        <option value="X">Canceled</option>
                        <option value="B">Bill Booked</option>
                      </select>
                      <MDTypography variant="button" id="error_cmb_goods_receipt_status" className='erp_validation' fontWeight="regular" color="error" style={{ display: 'none' }}>
                        Please select good receipt status!..
                      </MDTypography>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">
                  <div className='row mb-1'>
                    <div className='col-sm-3'>
                      <Form.Label className="erp-form-label">Remark</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control as="textarea" rows={2} id="txt_remark" className="erp_input_field" value={txt_remark} onChange={(e) => { setRemark(e.target.value); validateFields(); }} optional='optional' />
                      <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-sm-3">
                      <Form.Label className="erp-form-label">LR No & Date</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_lr_no" className="erp_input_field" value={txt_lr_no} onChange={(e) => { setlrNo(e.target.value); validateFields(); }} optional='optional' />
                      <MDTypography variant="button" id="error_txt_lr_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>

                    <div className="col-sm-4">
                      <Form.Control type="date" id='dt_lr_date' className="erp_input_field" value={dt_lr_date} onChange={e => { setlrDate(e.target.value); }} optional='optional' />
                      <MDTypography variant="button" id="dt_lr_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>


                  <div className='row'>
                    <div className="col-sm-3">
                      <Form.Label className="erp-form-label">Vehicle no</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control type="text" id="txt_vehicle_no" className="erp_input_field" value={txt_vehicle_no} onChange={(e) => { setVehicleNo(e.target.value); }} optional='optional' />
                      <MDTypography variant="button" id="error_txt_vehicle_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-sm-3">
                      <Form.Label className="erp-form-label">Other terms and Conditions</Form.Label>
                    </div>
                    <div className='col'>
                      <Form.Control as="textarea" rows={2} id="txt_other_terms_conditions" className="erp_input_field" value={txt_other_terms_conditions} optional='optional' onChange={(e) => { setOtherTermsConditions(e.target.value); }} />
                      <MDTypography variant="button" id="error_txt_other_terms_conditions" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                      </MDTypography>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">PreeClosed</Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="is_pree_closed" checked={is_pree_closed === true} onClick={() => { setIsPreeClosed(true); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                        <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" value="false" name="is_pree_closed" checked={is_pree_closed === false} onClick={() => { setIsPreeClosed(false); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <Form.Label className="erp-form-label">Active</Form.Label>
                    </div>
                    <div className="col">
                      <div className="erp_form_radio">
                        <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="is_active" checked={is_active === true} onClick={() => { setIsActive(true); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                        <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" value="false" name="is_active" checked={is_active === false} onClick={() => { setIsActive(false); }} disabled={keyForViewUpdate === 'view' || isApprove ? true : false} /> </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>


        <div className="text-center">
          <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular"
            onClick={() => {
              const path = compType === 'Register' ? '/Transactions/TPurchaseOrder/GoodsReceiptNote/GoodsReceiptNoteListing/reg' : '/Transactions/TPurchaseOrder/GoodsReceiptNote/GoodsReceiptNoteListing';
              navigator(path);
            }}
          >Back</MDButton>
          <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' ? 'd-none' : 'display'}`} variant="button" onClick={() => createGoodReceiptNote(!isApprove ? 'Add' : 'A')}
            fontWeight="regular">{actionLabel}</MDButton>
          <MDButton type="button" id="clear-btn-id" className={`erp-gb-button erp_MLeft_btn  ${keyForViewUpdate === '' ? 'display' : 'd-none'}`} variant="button" onClick={FnClearForm} fontWeight="regular">Clear</MDButton>
          <MDButton type="button" className={`erp-gb-button ms-2 ${isApprove ? 'display' : 'd-none'}`}
            variant="button" onClick={() => createGoodReceiptNote('R')} fontWeight="regular">Reject</MDButton>
          <MDButton className={`erp-gb-button ms-2 ${keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? 'display' : 'd-none'}`} onClick={() => setShowDocumentForm(true)} variant="button" fontWeight="regular" disabled={goods_receipt_master_transaction_id === 0}>Upload Document</MDButton>&nbsp;
          <MDButton className={`erp-gb-button erp_MLeft_btn ${(keyForViewUpdate === 'view' && cmb_goods_receipt_status !== '') || keyForViewUpdate === 'view' && is_pree_closed !== false ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => printInvoice(true)}>Print &nbsp;<FiPrinter className="erp-download-icon-btn" />
          </MDButton>
          <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => setShow(true)}>Delete</MDButton>
        </div >

        {/* Add new Record Popup */}
        {showAddRecModal ?
          <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
            <Modal.Header>
              <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
              <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
            </Modal.Header>
            <Modal.Body className='erp_city_modal_body'>
              {displayRecordComponent()}
            </Modal.Body>
            <Modal.Footer>
              <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
                fontWeight="regular">Close</MDButton>

            </Modal.Footer>
          </ Modal> : null
        }

        {memorizedDocumentComponent}
        {/* Delete Modal */}
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
          <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
          <Modal.Body className='text-center'>
            <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
            <h6>Do you wish to delete this record ?</h6>
          </Modal.Body>
          <Modal.Footer className='justify-content-center'>
            <Button variant="success" className='erp-gb-button' onClick={handleClose}>
              Cancel
            </Button>&nbsp;
            <Button variant="danger" className='erp-gb-button' onClick={deleteGrn}>Delete</Button>
          </Modal.Footer>

        </Modal>
        {/* Generic Response Messaage Modals */}
        {/* <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} /> */}
        {showErrorMsgModal ?
          <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />
          : null
        }
      </DashboardLayout >

      {/* Send Email On Approval Modal */}
      {showConfirmationModal ?
        <ConfirmationModal close={() => closeConfirmationModal('Exit')} confirmation={() => closeConfirmationModal('Export&Exit')} show={[showConfirmationModal, message, modalOrderDetails, messageForConfirmation]} />
        : null
      }
    </>
  )
}

export default FrmGoodsReceiptNoteEntry
