import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import $ from 'jquery';
import MDBox from 'components/MDBox';
import FrmValidations from 'FrmGeneric/FrmValidations';


// Imports React bootstrap
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// import react icons
import { RxCheckCircled } from "react-icons/rx";

// File Imports
import ComboBox from "Features/ComboBox";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import ConfigConstants from "assets/Constants/config-constant";

function FrmMGodownEntry({ goToNext, godownID, keyForViewUpdate, compType }) {
    // Call ConfigConstants to get the configuration constants
    const configConstants = ConfigConstants();
    const { UserName, COMPANY_ID, COMPANY_BRANCH_ID } = configConstants;

    // var { state } = useLocation();
    // const { godownID = 0, keyForViewUpdate, compType = 'Masters' } = state || {}

    //combo 
    const [godownNameOption, setGodownNameOption] = useState([])
    const [productTypeOptions, setProductTypeOptions] = useState([])

    //  Form Fields
    const [godown_id, setGodownId] = useState(godownID)
    const [cmb_product_type_id, setProductTypeId] = useState()
    const [cmb_godown_type, setGodownType] = useState()
    const [txt_godown_name, setGodownName] = useState('');
    const [txt_godown_short_name, setGodownShortName] = useState('');
    const [txt_godown_area, setGodownArea] = useState('');
    const [txt_godown_section_count, setGodownSectionCount] = useState(1);

    // to add new records in combo box 
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => setShowSuccessMsgModal(false);
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');


    const comboDataAPiCall = useRef();
    const validationRef = useRef();
    // For navigate
    const navigate = useNavigate();
    useEffect(async () => {
        await fillComobos();
        await FnCheckUpdateResponce();

    }, [])

    const addGodown = async () => {
        debugger;
        try {
            const checkIsValidate = await validate();
            if (checkIsValidate === true) {
                var active;
                var activeValue = document.querySelector('input[name=isGodownActive]:checked').value
                switch (activeValue) {
                    case '0': active = false; break;
                    case '1': active = true; break;
                }
                const data = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    product_type_id: cmb_product_type_id,
                    godown_type: cmb_godown_type,
                    godown_id: godown_id,
                    created_by: UserName,
                    modified_by: godown_id === 0 ? null : UserName,
                    godown_name: txt_godown_name,
                    godown_short_name: txt_godown_short_name,
                    godown_area: txt_godown_area,
                    godown_section_count: txt_godown_section_count,
                    is_active: active,

                };
                console.log(data);
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/godown/FnAddUpdateRecord`, requestOptions)
                const responce = await apicall.json()
                console.log("response error: ", responce.data);
                if (responce.error !== "") {
                    console.log("response error: ", responce.error);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)

                } else {
                    debugger
                    var data1 = JSON.parse(responce.data)
                    const evitCache = comboDataAPiCall.current.evitCache();
                    console.log(evitCache);
                    console.log("Godown Data", data1);
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                    // await FnCheckUpdateResponce();
                    sessionStorage.removeItem('GodownName')
                    sessionStorage.setItem("GodownName", data.godown_name);
                    setGodownId(data1.godown_id)
                    sessionStorage.setItem("godownID", data.godown_id);
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }

    };

    const FnCheckUpdateResponce = async () => {
        try {
            if (godownID !== "undefined" && godownID !== '' && godownID !== null) {
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/godown/FnShowParticularRecordForUpdate/${godownID}`)
                const updateRes = await apiCall.json();
                const data = JSON.parse(updateRes.data)
                if (data !== null && data !== "") {
                    setGodownId(godownID)
                    setProductTypeId(data.product_type_id)
                    setGodownType(data.godown_type)
                    setGodownName(data.godown_name)
                    setGodownShortName(data.godown_short_name)
                    setGodownArea(data.godown_area)
                    setGodownSectionCount(data.godown_section_count)

                    switch (data.is_active) {
                        case true:
                            document.querySelector('input[name="isGodownActive"][value="1"]').checked = true;
                            break;
                        case false:
                            document.querySelector('input[name="isGodownActive"][value="0"]').checked = true;
                            break;
                    }
                    sessionStorage.removeItem('GodownName')
                    sessionStorage.setItem("GodownName", data.godown_name);


                    if (data.product_rm_qr_code !== '') {
                        $('#file_rm_qr_codeName').show()
                    }

                    switch (keyForViewUpdate) {
                        case 'update':
                            $("input[type=radio]").attr('disabled', false);
                            $('#btn_save').attr('disabled', false);
                            $('#txt_godown_name').attr('disabled', true)
                            $('#cmb_product_type_id').prop('disabled', true)
                            removeReadAttr();
                            break;
                        case 'view':
                            $("input[type=radio]").attr('disabled', true);
                            $('#btn_save').attr('disabled', true);
                            addReadOnlyAttr();
                            break;
                    }
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }

    }

    const fillComobos = async () => {
        comboDataAPiCall.current.fillMasterData("smv_product_type", "", "").then((productTypeApiCall) => {
            setProductTypeOptions(productTypeApiCall)
        })
    }
    const comboOnChange = async (key) => {
        switch (key) {
            case 'productType':
                var productTpVal = document.getElementById('cmb_product_type_id').value;
                setProductTypeId(productTpVal)
                if (productTpVal !== "") {
                    $('#error_cmb_product_type_id').hide();
                }
                if (productTpVal === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Product Type')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(0).css("padding-top", "0px");
                    }, 100)
                }
                break;
        }
    }
    // godown_type  combochanges function
    const comboOnChangefunction = async (key) => {
        debugger
        switch (key) {
            case 'godownType':
                var godownTpVal = document.getElementById('cmb_godown_type').value;
                setGodownType(godownTpVal)
                if (godownTpVal !== "") {
                    $('#error_cmb_godown_type').hide();
                }
                if (godownTpVal === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Product Type')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(0).css("padding-top", "0px");
                    }, 100)
                }
                break;
        }
    }


    //Validations starts here
    const validate = () => {
        return validationRef.current.validateForm('goDownFormId');
    }

    const validateFields = () => {
        return validationRef.current.validateFieldsOnChange('goDownFormId');
    }

    const addReadOnlyAttr = () => {
        return validationRef.current.readOnly('goDownFormId');
    }

    const removeReadAttr = () => {
        return validationRef.current.removeReadOnlyAttr('goDownFormId');
    }

    const validateNo = (key) => {
        const numCheck = /^[0-9]*\.?[0-9]*$/;
        const regexNo1 = /^[0-9]*\.[0-9]{5}$/
        switch (key) {
            case 'GodownArea':
                var godownArea = $('#txt_godown_area').val();
                if (!regexNo1.test(godownArea)) {
                    if (numCheck.test(godownArea)) {
                        setGodownArea(godownArea)
                    }
                }
                break;
            case 'GodownSectionCount':
                var GodownSectionCountVal = $('#txt_godown_section_count').val();
                if (numCheck.test(GodownSectionCountVal)) {
                    setGodownSectionCount(GodownSectionCountVal)
                }
                break;
        }
    }

    const validationfornext = async () => {
        const checkIsValidate = await validationRef.current.validateForm("goDownFormId");
        if (checkIsValidate && godown_id !== 0) {
            goToNext(godown_id, keyForViewUpdate);
        } else {
            console.log("Validation failed!");
        }
    }

    // Show ADd record Modal
    const handleCloseRecModal = async () => {
        switch (modalHeaderName) {

            case 'Product Type':
                comboDataAPiCall.current.fillMasterData("smv_product_type", "", "").then((productTypeApiCall) => {
                    setProductTypeOptions(productTypeApiCall)
                })
                break;

            default:
                break;
        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "0px" });
        }, 200)

    }

    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'Product Type':
                return <FrmMProductTypeEntry btn_disabled={true} />;

            default:
                return null;
        }
    }
    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <FrmValidations ref={validationRef} />
            <MDBox>
                <form id="goDownFormId">
                    <div className="row">
                        {/* //first column */}
                        <div className="col-sm-6 erp_form_col_div">
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <Form.Label className="erp-form-label">Product Type <span className="required">*</span></Form.Label>
                                </div>
                                <div className='col'>
                                    <select id="cmb_product_type_id" className="form-select form-select-sm" value={cmb_product_type_id} onChange={() => comboOnChange('productType')}>
                                        <option value="">Select</option>
                                        <option value="0">Add New Record+</option>

                                        {productTypeOptions?.map(productType => (
                                            <option value={productType.field_id}>{productType.field_name}</option>
                                        ))}

                                    </select>
                                    <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>
                            {/* add godown type */}

                            <div className='row'>
                                <div className='col-sm-4'>
                                    <Form.Label className="erp-form-label">Godown Type <span className="required">*</span></Form.Label>
                                </div>
                                <div className='col'>
                                    <select id="cmb_godown_type" className="form-select form-select-sm" value={cmb_godown_type} onChange={() => comboOnChangefunction('godownType')}>
                                        <option value="">Select</option>
                                        <option value="Both">Both</option>
                                        <option value="Generals">Generals</option>
                                        <option value="Production">Production</option>


                                    </select>
                                    <MDTypography variant="button" id="error_cmb_godown_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>





                            <div className='row'>
                                <div className='col-sm-4'>
                                    <Form.Label className="erp-form-label">Name <span className="required">*</span></Form.Label>
                                </div>
                                <div className='col'>
                                    <Form.Control type="text" id="txt_godown_name" className="erp_input_field" value={txt_godown_name} onChange={e => { setGodownName(e.target.value); validateFields() }} maxLength="255" />
                                    <MDTypography variant="button" id="error_txt_godown_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label">Short Name </Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control type="text" id="txt_godown_short_name" className="erp_input_field" value={txt_godown_short_name} onChange={(e) => { setGodownShortName(e.target.value.toUpperCase()); validateFields(); }} maxLength="5" optional="optional" />
                                    <MDTypography variant="button" id="error_txt_godown_short_name" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} >
                                    </MDTypography>
                                </div>
                            </div>

                        </div>

                        {/* second column */}
                        <div className="col-sm-6 erp_form_col_div">
                            <div className='row'>
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label">Area(sq.mtr) </Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control type="text" id='txt_godown_area' className="erp_input_field text-end" value={txt_godown_area} onChange={e => { validateNo('GodownArea'); }} maxLength="14" optional='optional' />
                                    <MDTypography variant="button" id="error_txt_godown_area" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-4'>
                                    <Form.Label className="erp-form-label">Section Count </Form.Label>
                                </div>
                                <div className='col'>
                                    <Form.Control type="text" id="txt_godown_section_count" className="erp_input_field erp_align-right" value={txt_godown_section_count} onChange={e => { setGodownSectionCount(e.target.value.split('.').join('')); validateFields() }} maxLength="9" optional='optional' />
                                    <MDTypography variant="button" id="error_txt_godown_section_count" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                    </MDTypography>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <Form.Label className="erp-form-label">Is Active </Form.Label>
                                </div>
                                <div className="col">

                                    <div className="erp_form_radio">
                                        <div className="fCheck">
                                            <Form.Check
                                                className="erp_radio_button"
                                                label="Yes"
                                                type="radio"
                                                value="1"
                                                name="isGodownActive"
                                                defaultChecked
                                            />
                                        </div>
                                        <div className="sCheck">
                                            <Form.Check
                                                className="erp_radio_button"
                                                label="No"
                                                value="0"
                                                type="radio"
                                                name="isGodownActive"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
                <div className="erp_frm_Btns">
                    {/* onClick={updateCustomer}  */}
                    <MDButton type="button" className="erp-gb-button"
                        onClick={() => {
                            const path = compType === 'Register' ? '/Masters/GodownListing/reg' : '/Masters/GodownListing';
                            navigate(path);
                        }}
                        variant="button"
                        fontWeight="regular">Back</MDButton>
                    <MDButton type="button" id="btn_save" onClick={addGodown} className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button"
                        fontWeight="regular">save</MDButton>
                    <MDButton type="button" onClick={validationfornext} id="nxtOnlyBtn" className="erp_MLeft_btn erp-gb-button" variant="button"
                        fontWeight="regular">next</MDButton>
                </div >
                {/* Success Msg Popup */}
                <Modal show={showSuccessMsgModal} onHide={handleCloseSuccessModal} backdrop="static" keyboard={false} centered>
                    <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseSuccessModal}></button></span>
                    <Modal.Body className='text-center'>
                        <span className='erp_modal_success_icon'><RxCheckCircled /></span>
                        <h6>{succMsg}</h6>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className="btn erp-gb-button" onClick={handleCloseSuccessModal}>
                            ok
                        </Button>

                    </Modal.Footer>
                </Modal>

                {/* Error Msg Popup */}
                <Modal show={showErrorMsgModal} onHide={handleCloseErrModal} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Error</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseErrModal}></button></span>
                    </Modal.Header>
                    <Modal.Body className='erp_modal_body'>
                        {errMsg}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={handleCloseErrModal}>
                            ok
                        </Button>

                    </Modal.Footer>
                </Modal>

                {/* Add new Record Popup */}
                <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
                    </Modal.Header>
                    <Modal.Body className='erp_city_modal_body'>
                        {displayRecordComponent()}
                    </Modal.Body>
                    <Modal.Footer>
                        <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
                            fontWeight="regular">Close</MDButton>

                    </Modal.Footer>
                </Modal >


            </MDBox>

        </>
    )
}

export default FrmMGodownEntry
