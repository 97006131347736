import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $, { event } from "jquery";


//File Imports
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";
import ConfigConstants from "assets/Constants/config-constant";
import ComboBox from "Features/ComboBox";
import ExcelExport from "Features/Exports/ExcelExport";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import FrmMBOMEntry from "FrmGeneric/MBOMFilter/FrmMBOMEntry";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import Datatable from "components/DataTable";
import html2pdf from 'html2pdf.js';
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import MailSentModal from "components/Modals/MailSentModal";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import DocumentF from "Features/Document";
import { FiDownload } from "react-icons/fi";
import { renderToString } from "react-dom/server";
import PurchaseOrderInvoice from "FrmGeneric/Invoice/PurchaseOrderInvoice";


//React Bootstrap components
import Tooltip from "@mui/material/Tooltip";
import { Form, Button } from "react-bootstrap";
import { Accordion, Modal, Table } from "react-bootstrap";
import { MdRefresh } from "react-icons/md";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";

//import components
import FrmDepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";
import FrmCity from "FrmGeneric/MCity/FrmCity";

// React icons
import { MdDelete, MdModeEdit } from "react-icons/md";
import { AiOutlineSchedule } from "react-icons/ai";
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ProductList from "FrmGeneric/ProductList";
import CommonParamterEntry from "Masters/MCommonParameter/CommonParamterEntry";
import FrmPaymentTermsEntry from "Masters/MPaymentTerms/FrmPaymentTermsEntry";

//get gracedays for schedule date
let expectedScheduleGraceDays = 0;

function FrmManualPOEntry() {

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, FINANCIAL_SHORT_NAME, UserId, UserName, COMPANY_NAME } = configConstants;
    const { state } = useLocation();
    const { idList, keyForViewUpdate, compType } = state || {};

    // UseRefs
    const navigate = useNavigate();
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const validateNumberDateInput = useRef();
    const importFile = useRef(null);
    const exlsExp = useRef();

    let expanded = false;
    const [isLoading, setIsLoading] = useState(false);
    const [importedData, setImportedData] = useState([]);
    const [isApprove, setIsApprove] = useState(false);
    const [isView, setIsView] = useState(false);

    // Hooks for handle the accordions.
    const [openContactsAccord, setOpenContactsAccord] = useState(false);
    const [openDetailsAccord, setOpenDetailsAccord] = useState(true);
    const [openPaymentTermsAccord, setOpenPaymentTermsAccord] = useState(false);
    const [openCommonTermsAccord, setOpenCommonTermsAccord] = useState(false);

    // delete popup msg
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    //Error Msg
    const handleCloseErrModal = () => {
        setShowErrorMsgModal(false)
    };
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState("");
    // Confirmation Modal fields
    const [showConfirmationModal, setShowConfiemationModal] = useState(false)
    const [messageForConfirmation, setMessageForConfirmation] = useState(false)
    const closeConfirmationModal = (key) => {

        setShowSuccessMsgModal(false);
        moveToListing();
    }


    // After approved mail sent confirmation modal
    const handleCloseMailSentModal = () => {
        setShowMailSentModal(false);
        moveToListing();
    }
    const [showMailSentModal, setShowMailSentModal] = useState(false);
    let po_status = '';
    let po_life = '';
    //for current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };
    const currentDate = new Date().toISOString().split('T')[0];
    //key for get opration
    const ActionType = () => {
        switch (keyForViewUpdate) {
            case 'approve':
                return '(Approval)';
            case 'update':
                return '(Modification)';
            case 'view':
                return '(View)';
            case 'copy':
                return '(Copy Purchase Order)';
            case 'delete':
                return '(Delete)';
            default:
                return '(Creation)';
        }
    }
    let fin_year = "";
    let PO_version = "";

    //combo options
    // const [producttypeOptions, setProductTypeOptions] = useState([]);
    const [indnetNosList, setIndentNosList] = useState([])
    const [customerOptions, setCustomerOptions] = useState([]);
    const [selectIndentNo, setselectIndentNo] = useState('');
    const [customerOrdOpts, setCustomerOrdOpts] = useState([]);
    const [departmentIdOPtions, setDepartmentIdOptions] = useState([]);
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [supplierStateOptions, setSupplierStateOptions] = useState([]);
    const [supplierCityOpts, setSupplierCityOpts] = useState([]);
    const [expectedBranchOptions, setExpectedBranchOptions] = useState([]);
    const [expectedBranchStateOptions, setExpectedBranchStateOptions] = useState([]);
    const [expectedBranchCityOptions, setExpectedBranchCityOptions] = useState([]);
    const [approvedByOptions, setApprovedByOptions] = useState([]);
    const [agents, setAgents] = useState([]);
    const [freightHSNOpts, setFreightHSNOpts] = useState([]);
    const [productUnitOptions, setProductUnitOptions] = useState([]);

    //Header Data form fields
    const [checkCustomerLenght, setCheckCustomerLenght] = useState(0);
    const [checkedIndnetNosLength, setCheckedIndentNosLength] = useState(0);
    const [po_master_transaction_id, setPO_master_transactionID] = useState(0);
    // const [cmb_product_type_id, setProductTypeID] = useState("");
    const [chek_po_life, setPO_life] = useState("C");
    const [chk_purchase_process, setPurchase_process] = useState("M")
    const [chk_po_creation_type, setPO_creation_type] = useState("M")
    const [txt_PO_no, setPOno] = useState(0);
    const [txt_po_version, setPOVersion] = useState(1);
    const [txt_po_date, setPODate] = useState(today);
    const [cmb_indent_no, setPOIndentNo] = useState('');
    const [txt_indent_date, setPOIndentDate] = useState('');
    const [txt_indent_version, setPOIndentVersion] = useState('');

    const [cmb_customerIds, setCustomerId] = useState("");
    const [cmb_customer_order_no, setCustomerOrderNo] = useState("");
    const [customer_order_date, setCustomerOrderDate] = useState("");

    const [cmb_supplier_id, setSupplier_id] = useState("");
    const [cmb_supplier_state_id, setSupplier_state_id] = useState("");
    const [cmb_supplier_city_id, setSupplier_city_id] = useState("");

    const [cmb_expected_branch_id, setExpected_branch_id] = useState(COMPANY_BRANCH_ID);
    const [cmb_expected_branch_state_id, setExpected_branch_state_id] = useState("");
    const [cmb_expected_branch_city_id, setExpected_branch_city_id] = useState("");
    const [cmb_department, setDepartmentId] = useState("")

    const [cmb_approved_by_id, setApproved_by_id] = useState("");
    const [txt_approved_date, setApproved_date] = useState("");
    const [txt_schedule_date, setScheduleDate] = useState(today());
    const [chk_po_mail_sent_status, setPO_mail_sent_status] = useState("P");
    const [chk_PO_acceptance_status, setPO_acceptance_status] = useState("P");
    const [chk_po_status, setPO_status] = useState("P");
    const [txt_status_remark, setStatus_remark] = useState("");
    const [txt_remark, setRemark] = useState("");
    const [chk_po_isactive, setPOIsActive] = useState(true);

    //Footer Data form fields
    const [txt_basic_total, setBasic_total] = useState(0)
    const [txt_freight_amount, setFreightAmount] = useState(0);
    const [chk_is_freight_taxable, SetIsFreightTaxable] = useState(false);
    const [cmb_freight_hsn_id, setFreightHSNId] = useState('')
    const [txt_packing_amount, setPackingAmount] = useState(0);
    const [txt_po_discount_percent, setPODiscountPercent] = useState(0);
    const [txt_po_discount_amount, setPODiscountAmount] = useState(0);
    const [txt_transportAmount, setTransportAmount] = useState(0);
    const [txt_cgst_total, setCgstTotal] = useState(0);
    const [txt_sgst_total, setSgstTotal] = useState(0);
    const [txt_igst_total, setIgstTotal] = useState(0);

    const [txt_round_off, setRoundOff] = useState(0);
    const [txt_grand_amount, setGrandTotal] = useState(0);
    const [txt_otherAmount, setOtherAmount] = useState(0);
    const [txt_taxableTotal, setTaxableTotal] = useState(0);

    const [cmb_agentId, setAgentId] = useState('');
    const [txt_agentPercent, setAgentPercent] = useState('0');
    const [chk_agentPaidStatus, setAgentPaidStatus] = useState('P');
    const [txt_other_term_con, setOther_term_con] = useState("");

    // const [poNoGroupId, setPoNoGroupId] = useState(txt_PO_no.replaceAll('/', '_'))


    //For Print
    const [PurchaseOrderMasterJson, setPurchaseOrderMasterJson] = useState({});

    //Bom po Details filter
    const [po_details_data, setPODetailsData] = useState([]);
    const [showBomFilterForm, setShowBomFilterForm] = useState(false);

    // let requiredCols = ['sr_no', 'product_rm_id', 'product_rm_name', 'product_rm_tech_spect', 'product_rm_hsn_sac_code', 'product_rm_quantity', 'product_rm_rate',
    //     'material_basic_amount', 'material_discount_percent', 'material_discount_amount',
    //     'material_taxable_amount', 'material_cgst_percent', 'material_cgst_total', 'material_sgst_percent',
    //     'material_sgst_total', 'material_igst_percent', 'material_igst_total', 'material_total_amount', 'product_rm_weight', 'material_freight_amount', 'material_schedule_date',
    //     'product_rm_approved_quantity', 'product_rm_approved_weight', 'product_rm_rejected_quantity', 'product_rm_rejected_weight', 'material_po_approval_remark'
    //     , 'department_id', 'indented_by_id', 'indent_date', 'indent_no', 'indent_version', 'approved_by_id', 'approved_date', 'product_rm_std_weight', 'product_rm_conversion_factor', 'lead_time', 'product_rm_stock_unit_name', 'product_rm_packing_name', 'product_rm_stock_unit_id',
    //     'product_rm_packing_id', 'product_rm_hsn_sac_code_id', 'product_rm_hsn_sac_rate', 'purchase_order_item_status',
    //     'pree_closed_quantity', 'pree_closed_weight', 'purchase_return_quantity', 'purchase_return_weight', 'customer_id', 'customer_order_no', 'customer_order_Date',
    //     'pending_quantity', 'pending_weight', 'excess_quantity', 'excess_weight', 'production_issue_quantity', 'production_issue_weight',
    //     'production_issue_return_quantity', 'production_issue_return_weight', 'production_issue_rejection_quantity', 'production_issue_rejection_weight',
    //     'assembly_production_issue_quantity', 'assembly_production_issue_weight', 'sales_quantity', 'sales_weight',
    //     'sales_return_quantity', 'sales_return_weight', 'sales_rejection_quantity', 'sales_rejection_weight', 'transfer_issue_quantity',
    //     'transfer_issue_weight', 'transfer_receipt_quantity', 'transfer_receipt_weight', 'outsources_out_quantity', 'outsources_out_weight',
    //     'outsources_in_quantity', 'outsources_in_weight', 'outsources_rejection_quantity', 'outsources_rejection_weight', 'loan_receipt_quantity',
    //     'loan_receipt_weight', 'loan_issue_quantity', 'loan_issue_weight', 'cancel_quantity', 'cancel_weight', 'difference_quantity',
    //     'difference_weight',
    // ];
    let requiredCols = ['sr_no', 'product_type_id', 'product_type_name', 'product_id', 'product_name', 'product_tech_spect', 'product_hsn_sac_code', 'product_rm_quantity', 'product_rm_rate',
        'material_basic_amount', 'material_discount_percent', 'material_discount_amount',
        'material_taxable_amount', 'material_cgst_percent', 'material_cgst_total', 'material_sgst_percent',
        'material_sgst_total', 'material_igst_percent', 'material_igst_total', 'material_total_amount', 'product_rm_weight', 'material_freight_amount', 'material_schedule_date',
        'product_rm_approved_quantity', 'product_rm_approved_weight', 'product_rm_rejected_quantity', 'product_rm_rejected_weight', 'material_po_approval_remark'
        , 'department_id', 'indented_by_id', 'indent_date', 'indent_no', 'indent_version', 'approved_by_id', 'approved_date', 'product_std_weight', 'product_rm_conversion_factor', 'product_lead_time', 'product_unit_name', 'product_packing_name', 'product_unit_id',
        'product_packing_id', 'product_hsn_sac_code_id', 'product_hsn_sac_rate', 'purchase_order_item_status',
        'pree_closed_quantity', 'pree_closed_weight', 'purchase_return_quantity', 'purchase_return_weight', 'customer_id', 'customer_order_no', 'customer_order_Date',
        'pending_quantity', 'pending_weight', 'excess_quantity', 'excess_weight', 'production_issue_quantity', 'production_issue_weight',
        'production_issue_return_quantity', 'production_issue_return_weight', 'production_issue_rejection_quantity', 'production_issue_rejection_weight',
        'assembly_production_issue_quantity', 'assembly_production_issue_weight', 'sales_quantity', 'sales_weight',
        'sales_return_quantity', 'sales_return_weight', 'sales_rejection_quantity', 'sales_rejection_weight', 'transfer_issue_quantity',
        'transfer_issue_weight', 'transfer_receipt_quantity', 'transfer_receipt_weight', 'outsources_out_quantity', 'outsources_out_weight',
        'outsources_in_quantity', 'outsources_in_weight', 'outsources_rejection_quantity', 'outsources_rejection_weight', 'loan_receipt_quantity',
        'loan_receipt_weight', 'loan_issue_quantity', 'loan_issue_weight', 'cancel_quantity', 'cancel_weight', 'difference_quantity',
        'difference_weight',
    ];

    const [defaultExpecScheduleDate, setDefaultExpecScheduleDate] = useState('')

    //Add Schedules popup open 
    const [scheduleDataArray, setScheduleDataArray] = useState([]);
    const [showSChedules, setSchedules] = useState(false);
    const handleCloseSchedulesPopup = () => setSchedules(false)
    const [schedulerowcount, setSchedulerowcount] = useState(1);
    var scheduleRowCount = 1;
    var remainingSchduleQty = 0;

    // Customers contact details table data hooks
    const [suppContactDetails, setSuppContactDetails] = useState([]);
    const [suppContactDetailsCols, setSuppContactDetailsCols] = useState([]);

    // PO_terms Table Data
    const [POTermsdata, setPOTermsData] = useState([]);
    const [existingCommonTermsData, setExistingCommonTermsData] = useState([])

    //payment terms accordian
    const [paymentTermsData, setPaymentTermsData] = useState([]);
    const [existingPaymentTermsData, setExistingPaymentTermsData] = useState([])

    // For Taxation Summary.
    let taxationSummary = JSON.parse(localStorage.getItem('taxationSummary')) === null ? [] : JSON.parse(localStorage.getItem('taxationSummary'));;
    // Taxation Table Data Hooks
    let [taxationSummaryData, setTaxationSummaryData] = useState([]);

    // Document Form
    let documentGroup = "Purchase Order";

    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const handleCloseDocumentForm = () => setShowDocumentForm(false);
    const viewDocumentForm = () => setShowDocumentForm(true);
    // doc list
    const [docData, setDocumentData] = useState([]);

    useEffect(async () => {
        setIsLoading(true);
        try {
            removeSessions('Onload');
            FnSetFieldsByUserAccess();
            await loadDefaultPoOrdData();
            if (idList !== '') {
                await FnCheckUpdateResponce();
            } else {
                await GeneratePONo();
            }
            FnSetFieldsByUserAccess();
        } catch (error) {
            // Handle any errors
            console.error(error);
            navigate('/Error')
        } finally {
            // End loading
            setIsLoading(false);
        }
        // Close indent order no dropdown 
        $(document).on('mouseup', function (e) {
            var container = $("#indent-order-ul");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
        // Close customer order no dropdown 
        $(document).on('mouseup', function (e) {
            var container = $(".erp_dropdown-content");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });


    }, [])

    //------------------------------------------------Add New Record start-------------------------------------
    // to add new records in combo box 
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')
    const commonTermMstName = "Purchase Orders Technical Terms";
    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'Product Type':
                return <FrmMProductTypeEntry />;
            case 'Add Department':
                return <FrmDepartmentEntry departmentId={cmb_department} />
            case 'Add City':
                return <FrmCity />;
            case 'Common Parameter':
                return <CommonParamterEntry btn_disabled={true} master_name={commonTermMstName} />
            case 'Payment Terms':
                return <FrmPaymentTermsEntry btn_disabled={true} master_name={commonTermMstName} />
            default:
                return null;
        }
    }

    // Show ADd record Modal
    const handleCloseRecModal = async () => {

        switch (modalHeaderName) {
            // case 'Product Type':
            //     setProductTypeID('');
            //     resetGlobalQuery();
            //     globalQuery.columns.push("field_id");
            //     globalQuery.columns.push("field_name");
            //     globalQuery.columns.push("product_type_short_name");
            //     globalQuery.table = "smv_product_type";
            //     globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            //     globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });

            //     const poTypeApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
            //     setProductTypeOptions(poTypeApiCall);
            //     break;
            case 'Add City':
                setSupplier_city_id('');
                setExpected_branch_city_id('');
                if ($('#cmb_supplier_id').val() !== '') {
                    const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + $('#cmb_supplier_id').val() + "");
                    setExpectedBranchCityOptions(cityApiCall);
                }
                break;
            case 'Add Department':
                setDepartmentId('');
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("department_head_id");
                globalQuery.table = "cmv_department";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const departmentIdApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setDepartmentIdOptions(departmentIdApiCall);
                break;
            case 'Common Parameter':
                await FnShowPOTermsRecords();
                break;
            case 'Payment Terms':
                await FnShowPaymentTermsRecrds();
                break;
            default:
                break;
        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)

    }
    //------------------------------------------------Add New Record End---------------------------------------------------

    // navigate to back listing page 
    const moveToListing = () => {
        localStorage.removeItem('filteredMaterialData');
        localStorage.removeItem('purchaseOrdCommonTermsData');
        localStorage.removeItem('purchaseOrdPaymentTermsData');
        localStorage.removeItem('purchaseOrdScheduleData');
        localStorage.removeItem('taxationSummary')
        //navigate(`/Transactions/TPurchaseOrder/ManualPO/ManualPOListing`)
        const Path = compType === 'Register' ? '/Transactions/TPurchaseOrder/ManualPO/POListing/reg' : `/Transactions/TPurchaseOrder/ManualPO/POListing`;
        navigate(Path);

    }
    //fn to get po auto no
    const GeneratePONo = async () => {
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("pt_purchase_order_master", "purchase_order_no", "", "PO", "5");
        setPOno(autoNoApiCall);
        setPOVersion(1);
        return autoNoApiCall;
    };
    // Fn for getgracedays for scheduledate
    const FnGetExpectedScheduleGraceDate = (afterDays) => {
        let currentDate = new Date();
        const dtAfterGraceDays = new Date(currentDate);
        dtAfterGraceDays.setDate(currentDate.getDate() + afterDays);

        const year = dtAfterGraceDays.getFullYear();
        const month = String(dtAfterGraceDays.getMonth() + 1).padStart(2, '0');
        const day = String(dtAfterGraceDays.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    //Fn for load default data of page
    const loadDefaultPoOrdData = async () => {
        try {
            // First get the grace days count from company settings.
            resetGlobalQuery();
            globalQuery.columns.push('po_schedule_grace_days');
            globalQuery.table = "am_company_settings"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getScheduleGraceDaysApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
            expectedScheduleGraceDays = getScheduleGraceDaysApiCall.length === 0 ? 0 : getScheduleGraceDaysApiCall[0].po_schedule_grace_days;
            setScheduleDate(FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays));

            // get product types list
            // resetGlobalQuery();
            // globalQuery.columns.push("field_id");
            // globalQuery.columns.push("field_name");
            // globalQuery.columns.push("product_type_short_name");
            // globalQuery.table = "smv_product_type";
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            // const poTypeApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            // setProductTypeOptions(poTypeApiCall);

            // get suppliers list with default state & city
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("supp_branch_state_id");
            globalQuery.columns.push("supp_branch_city_id");
            globalQuery.table = "cmv_supplier";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const suppliersApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setSupplierOptions(suppliersApiCall);
            // setSupplier_state_id('')
            // setSupplier_city_id('')

            const stateApiCall = await comboDataAPiCall.current.fillMasterData("cmv_state", "", "");
            setSupplierStateOptions(stateApiCall);
            setExpectedBranchStateOptions(stateApiCall);

            //get customer list
            const customerApiCall = await comboDataAPiCall.current.fillMasterData("cmv_customer", "", "");
            setCustomerOptions(customerApiCall);

            // FnshowCustByIndent();
            //get expected branch list
            resetGlobalQuery();
            globalQuery.columns.push("company_branch_id");
            globalQuery.columns.push("company_branch_name");
            globalQuery.columns.push("branch_state_id");
            globalQuery.columns.push("branch_city_id");
            globalQuery.columns.push("is_sez");
            globalQuery.columns.push("branch_EmailId");
            globalQuery.table = "cmv_company_branch";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            const exptBranchApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setExpectedBranchOptions(exptBranchApiCall);
            if (exptBranchApiCall !== null) {
                const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + exptBranchApiCall[0].branch_state_id + "");
                setExpectedBranchCityOptions(cityApiCall);
                setExpected_branch_state_id(exptBranchApiCall[0].branch_state_id)
                setExpected_branch_city_id(exptBranchApiCall[0].branch_city_id)
            }
            // for agents List
            const agentApiCall = await comboDataAPiCall.current.fillMasterData("cmv_agent", "", "");
            setAgents(agentApiCall);
            // Set the default Agent
            let defaultAgent = agentApiCall.find(agent => agent.field_name === 'NA');
            if (defaultAgent) { setAgentId(defaultAgent.field_id); }



            //load department list with dept head ids
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("department_head_id");
            globalQuery.table = "cmv_department";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const departmentIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setDepartmentIdOptions(departmentIdApiCall);

            const getApproveByApiCall = await comboDataAPiCall.current.fillMasterData('cmv_employee', '', '');
            setApprovedByOptions(getApproveByApiCall)

            resetGlobalQuery();//get hsn code for freight amount
            globalQuery.columns.push("hsn_sac_id");
            globalQuery.columns.push("hsn_sac_code");
            globalQuery.columns.push("hsn_sac_rate");
            globalQuery.table = "cmv_hsn_sac"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getFreightHSNCodesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setFreightHSNOpts(getFreightHSNCodesApiCall);

            //get product units  
            const prUnitsApiCall = await comboDataAPiCall.current.fillMasterData("smv_product_unit", "", "");
            setProductUnitOptions(prUnitsApiCall);
        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }
    };
    // fn for fields onchange action
    const comboOnChange = async (key, event) => {
        switch (key) {
            // case "productType":
            //     var productTypeVal = document.getElementById("cmb_product_type_id").value;
            //     setProductTypeID(productTypeVal);
            //     if (productTypeVal !== '0' && productTypeVal !== "") {
            //         const shortname = $('#cmb_product_type_id option:selected').attr('shortname');
            //         $("#error_cmb_product_type_id").hide();
            //         await GeneratePONo(shortname);
            //     } else if (productTypeVal === '0') {
            //         setProductTypeID(productTypeVal)
            //         sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
            //         setHeaderName('Product Type')
            //         setShowAddRecModal(true)
            //         setTimeout(() => {
            //             $(".erp_top_Form").css({
            //                 "padding-top": "0px"
            //             });
            //         }, 200)
            //         setPOno("0");

            //     } else {
            //         setPOno("0");
            //     }
            //     break;
            case "supplier":
                let supplierTpVal = document.getElementById("cmb_supplier_id").value;
                setSupplier_id(supplierTpVal);
                if (supplierTpVal === '0') {
                    localStorage.setItem('supplierIDs', JSON.stringify({ supplierId: 0, keyForViewUpdate: '', compType: 'Master' }))
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add Supplier')
                    const newTab = window.open('/Masters/FrmSupplier', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                if (supplierTpVal != null && supplierTpVal != '' & supplierTpVal != '0') {
                    let selectedOption = $('#cmb_supplier_id').find('option:selected');
                    let selectedState = selectedOption.attr('suppl_state');
                    let selectedCity = selectedOption.attr('suppl_city');
                    setSupplier_state_id(selectedState);
                    const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + selectedState + "");
                    setSupplierCityOpts(cityApiCall);
                    setSupplier_city_id(selectedCity);
                    await FnGetSupplierContacts(parseInt(supplierTpVal));
                    setTimeout(() => {
                        if (po_details_data.length !== 0) {
                            updateTblCalsForSupplStateChange();
                        }
                    }, 200);
                } else {
                    setSupplier_state_id('');
                    setSupplier_city_id('');
                }
                break;
            case "supplier_state":
                var supplierStateTpVal = document.getElementById("cmb_supplier_state_id").value;
                setSupplier_state_id(supplierStateTpVal)
                if (supplierStateTpVal !== "" && supplierStateTpVal !== "0") {
                    const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + supplierStateTpVal + "");
                    setSupplierCityOpts(cityApiCall);
                    setSupplier_city_id('');
                    if (po_details_data.length !== 0) {
                        updateTblCalsForSupplStateChange();
                    }
                }
                else {
                    setSupplierCityOpts([]);
                }
                break;
            case "supplier_city":
                var suppliercityTpVal = document.getElementById("cmb_supplier_city_id").value;
                setSupplier_city_id(suppliercityTpVal)
                if (suppliercityTpVal === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add City')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200)
                }
                break;
            case "company_city":
                var companycityTpVal = document.getElementById("cmb_expected_branch_city_id").value;
                if (companycityTpVal === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add City')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200)
                }
                break;
            case "departmentId":
                var departmentIdVal = document.getElementById("cmb_department").value;

                if (departmentIdVal === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Add Department')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css({
                            "padding-top": "0px"
                        });
                    }, 200)
                } else {
                    if (chk_po_creation_type === 'A') {
                        setIndentNosList([]);
                        setCheckedIndentNosLength(0);
                        setPODetailsData([])
                        setScheduleDataArray([])
                        setTaxationSummaryData([])
                        setBasic_total(0)
                        setTaxableTotal(0)
                        setCgstTotal(0)
                        setSgstTotal(0)
                        setIgstTotal(0)
                        setGrandTotal(0)
                        setRoundOff(0)
                        localStorage.removeItem('filteredMaterialData');
                        localStorage.removeItem('purchaseOrdScheduleData');
                        localStorage.removeItem('taxationSummary');
                        await FnGetIndentNos();
                    }
                }
                break;
            case "expt_branch":
                var exptBrachTpVal = document.getElementById("cmb_expected_branch_id").value;
                setExpected_branch_id(exptBrachTpVal)
                if (exptBrachTpVal !== '0' && exptBrachTpVal !== "") {
                    resetGlobalQuery();
                    globalQuery.columns.push("branch_state_id");
                    globalQuery.columns.push("branch_city_id");
                    // globalQuery.columns.push("is_sez");
                    globalQuery.table = "cm_company_branch";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                    globalQuery.conditions.push({ field: "company_branch_id", operator: "=", value: exptBrachTpVal, });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });

                    const exptBranchApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setExpected_branch_state_id(exptBranchApiCall[0].branch_state_id)
                    const cityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", "" + exptBranchApiCall[0].branch_state_id + "");
                    setExpectedBranchCityOptions(cityApiCall);
                    setExpected_branch_city_id(exptBranchApiCall[0].branch_city_id)
                    if (po_details_data.length !== 0) {
                        updateTblCalsForSupplStateChange();
                    }
                } else if (exptBrachTpVal === '0') {
                    const newTab = window.open('/Masters/Company', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                    setExpected_branch_state_id("")
                    setExpected_branch_city_id("")
                } else {
                    setExpected_branch_state_id("")
                    setExpected_branch_city_id("")
                }
                break;
            case "expt_branchState":
                var exptBrchStateTpVal = document.getElementById("cmb_expected_branch_state_id").value;
                setExpected_branch_state_id(exptBrchStateTpVal)
                if (exptBrchStateTpVal !== '0' && exptBrchStateTpVal !== "") {
                    setExpected_branch_state_id(exptBrchStateTpVal);
                    const exptBranchcityApiCall = await comboDataAPiCall.current.fillMasterData("cmv_city", "state_id", exptBrchStateTpVal);
                    setExpectedBranchCityOptions(exptBranchcityApiCall);
                    if (po_details_data.length !== 0) {
                        updateTblCalsForSupplStateChange();
                    }
                } else {
                    setExpectedBranchCityOptions([]);
                    setExpected_branch_city_id("")
                }
                break;
            case "agent":
                var cmb_agentId = document.getElementById("cmb_agentId").value;

                if (cmb_agentId === '0') {
                    const newTab = window.open('/Masters/Agent', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                    setAgentId('')
                }
                break;
            case "by_indent":
                $('#add-material-id').hide();
                setPODetailsData([]);
                setScheduleDataArray([]);
                setTaxationSummaryData([])
                localStorage.removeItem('filteredMaterialData');
                localStorage.removeItem('purchaseOrdScheduleData');
                localStorage.removeItem('taxationSummary');
                calculateGrandTotal();
                break;
            case "by_direct":
                $('#add-material-id').show();
                setIndentNosList([]);
                setCheckedIndentNosLength(0);
                setPODetailsData([]);
                setScheduleDataArray([]);
                setTaxationSummaryData([])
                localStorage.removeItem('filteredMaterialData');
                localStorage.removeItem('purchaseOrdScheduleData');
                localStorage.removeItem('taxationSummary');
                calculateGrandTotal();
                break;
            case "po_status":

                break;
            default:
                break;
        }
    };
    const FnsetMaterialItemStatus = (statusVal) => {
        if (keyForViewUpdate === 'view') {
            setPODetailsData((prevArray) => {
                let updatedMateialStatus = [];
                if (statusVal === 'Z') {
                    updatedMateialStatus = prevArray.map((item) => {
                        return {
                            ...item,
                            pree_closed_quantity: item.pending_quantity,
                            pree_closed_weight: item.pending_weight,
                            purchase_order_item_status: statusVal,
                        };
                    });
                    $("#save-btn").prop("disabled", false);
                } else {
                    updatedMateialStatus = prevArray.map((item) => {
                        return {
                            ...item,
                            pree_closed_quantity: 0,
                            pree_closed_weight: 0,
                            purchase_order_item_status: statusVal,
                        };
                    });
                    $("#save-btn").prop("disabled", true);
                }

                localStorage.setItem('filteredMaterialData', JSON.stringify(updatedMateialStatus));
                return updatedMateialStatus;

            });


        } else {
            setPODetailsData((prevArray) => {
                const updatedMateialStatus = prevArray.map((item) => {
                    return {
                        ...item,
                        purchase_order_item_status: statusVal,
                    };
                });
                localStorage.setItem('filteredMaterialData', JSON.stringify(updatedMateialStatus));
                return updatedMateialStatus;

            });
            setScheduleDataArray((prevArray) => {
                const updatedScheduleStatus = prevArray.map((item) => {
                    return {
                        ...item,
                        purchase_order_schedules_item_status: statusVal,
                    };
                });
                sessionStorage.setItem('PurchaseOrderSchedules', JSON.stringify(updatedScheduleStatus));
                return updatedScheduleStatus;

            })
        }
    }
    //Function for get contact ids,name & mobile nos of supplier
    const FnGetSupplierContacts = async (SelectedSupplier) => {

        if (SelectedSupplier !== '' || SelectedSupplier !== '0') {
            resetGlobalQuery();
            globalQuery.columns = ["supplier_id", "supplier_branch_name", "supp_branch_id", "supplier_contact_id",
                "supp_contact_person", "supp_contact_no", "supp_alternate_contact", "supp_email_id", "supp_alternate_EmailId", "supp_designation"]

            globalQuery.table = "cmv_supplier_contacts";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "supplier_id", operator: "=", value: SelectedSupplier });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getContactDtlsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

            if (getContactDtlsApiCall.length !== 0) {
                let column = [];
                let columnHeads = Object.keys(getContactDtlsApiCall[0]);
                for (let colKey = 0; colKey < columnHeads.length; colKey++) {
                    if (colKey === 0) {
                        column.push({
                            Headers: <div className="col-1"><input type='checkbox' class="me-1" name="selectAllSuppContact" id="selectAllSuppContact" onClick={(e) => { toggleChkAllBoxes('selectAllSuppContact'); }} />Select</div>,
                            accessor: "Action",
                            Cell: row => (
                                <div className="text-center">
                                    <input type='checkbox' class="selectSuppContactPerson" name="selectSuppContactPerson" id={'selectSuppContactPerson_' + row.row.original.supplier_contact_id} contact_personId={row.row.original.supplier_contact_id} value={row.row.original.supplier_contact_id} />
                                    {/* onChange={(e) => { handleSuppCheckboxChange(e) }}  */}
                                </div>
                            ),
                        });
                    }
                    if (!columnHeads[colKey].includes('supplier_id') && !columnHeads[colKey].includes('supplier_contact_id') && !columnHeads[colKey].includes('supp_branch_id')) {
                        column.push({ Headers: columnHeads[colKey], accessor: columnHeads[colKey] });
                    }
                }
                setSuppContactDetailsCols(column);
                setSuppContactDetails(getContactDtlsApiCall);

            } else {
                setSuppContactDetailsCols([]);
                setSuppContactDetails([]);
            }
        }
        // Onchange event listener for the customer contact Selection
        $('.selectSuppContactPerson').on('change', function () {
            toggleChkAllBoxes('PartiallySuppContactSelection');
        });

    }

    const validateFields = () => {
        var formObj = $("#PO_header_formId");
        var inputObj;
        for (var i = 0; i <= formObj.get(0).length - 1; i++) {
            inputObj = formObj.get(0)[i];
            if (inputObj.type === "text" && inputObj.value !== "") {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === "select-one" && inputObj.value !== "") {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === "textarea" && inputObj.value !== "") {
                $("#error_" + inputObj.id).hide();
            } else if (inputObj.type === "date" && inputObj.value !== "") {
                $("#error_" + inputObj.id).hide();
            }
        }
    };

    // to scroll suppliers contact ids box
    function scrollToTableRow() {
        var keywords = $("#txt-detail-data-highliter").val();
        var table = document.getElementById('po-details-table');
        var rows = table.getElementsByTagName('tr');
        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
                var w = $(window);
                var row = $('#po-details-table').find('tr').eq(trNum);

                if (row.length) {
                    w.scrollTop(row.offset().top - (w.height() / 2));
                }

                $(row).find('td').each(function () {
                    $(this).addClass('highlight');
                });
                removeOtherTrHighlightClass(trNum)
                break;
            } else {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }
    const removeOtherTrHighlightClass = (rowNum) => {
        var table = document.getElementById('po-details-table');
        var rows = table.getElementsByTagName('tr');
        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (trNum !== rowNum) {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }

    // -------------------------------- PO Details section start---------------------------------------------------------------------------
    // Fn to get BOM modal with items
    const viewBomFilterForm = async () => {
        let checkIsValidate = await validate.current.validateForm("PO_header_formId");
        if (checkIsValidate === true) {
            localStorage.setItem('requiredCols', JSON.stringify(requiredCols));
            setShowBomFilterForm(true);
        }
    }
    // function for PO Details table
    const handleCloseBomFilterForm = () => {

        setShowBomFilterForm(false)
        //session which get data from bomfilter component
        const poDetailsData = JSON.parse(localStorage.getItem('filteredMaterialData')) || [];
        if (poDetailsData.length !== 0) {
            let updatedArray = [];
            setPODetailsData((prevArray) => {
                // const filteredArray = prevArray.filter(prevItem => {
                //     return poDetailsData.some(item => item.product_id === prevItem.product_rm_id);
                // });
                updatedArray = [
                    ...prevArray,
                    ...poDetailsData.filter(item => !prevArray.some(prevItem => prevItem.product_rm_id === item.product_id)).map(FnupdateDetailsRowData)
                ];
                updatedArray.forEach(handleMaterialWiseCalculations);
                localStorage.setItem('filteredMaterialData', JSON.stringify(updatedArray));
                FnGetTaxationSummary();
                calculateGrandTotal();
                return updatedArray;
            });

            setScheduleDataArray((prevArray) => {
                // Separate the filtering logic for better readability
                const updatedArrayIds = updatedArray.map((detailsItem) => detailsItem.product_rm_id);

                const filteredScheduleData = prevArray.filter((item) => {
                    // Use descriptive variable names for better readability
                    const isMatchingId = updatedArrayIds.includes(item.product_material_id);
                    return isMatchingId;
                });

                // Store filtered data in local storage
                localStorage.setItem('purchaseOrdScheduleData', JSON.stringify(filteredScheduleData));

                return filteredScheduleData;
            });
            // setTaxationSummaryData((prevArray) => {
            //     // Separate the filtering logic for better readability
            //     const updatedArrayIds = updatedArray.map((detailsItem) => parseInt(detailsItem.product_rm_id));

            //     const filteredTaxData =  prevArray.filter((item) => {
            //         // Use descriptive variable names for better readability
            //         const isMatchingId = updatedArrayIds.includes(parseInt(item.materialId));
            //         return isMatchingId;
            //     });

            //     // Store filtered data in local filteredTaxData
            //     localStorage.setItem('taxationSummary', JSON.stringify(filteredTaxData));

            //     return filteredTaxData;
            // });

            sessionStorage.setItem('isComboFilterExist', false)
            var LocalArr = JSON.parse(localStorage.getItem(`localStorageKeyArr`))// Remove Bom Filter Session
            console.log('LocalArr: ', LocalArr)
            for (let localArrIndex = 0; localArrIndex < LocalArr.length; localArrIndex++) {
                localStorage.removeItem(LocalArr[localArrIndex])
            }
        } else {
            setPODetailsData([])
            setScheduleDataArray([])
            setTaxationSummaryData([])
            localStorage.setItem('filteredMaterialData', JSON.stringify([]));
            localStorage.setItem('taxationSummary', JSON.stringify([]));
            localStorage.setItem('purchaseOrdScheduleData', JSON.stringify([]));
        }

        removeSessions();
        setTimeout(() => { categoryDisplay(); }, 300);
    };
    const FnupdateDetailsRowData = (rowData) => {
        const newMaterial = {
            ...rowData,
            material_schedule_date: txt_schedule_date, material_po_approval_remark: '', approved_date: "",
            customer_id: '', customer_order_no: '', customer_order_Date: '', purchase_order_item_status: 'P',

            product_rm_id: rowData.product_id,
            product_rm_name: rowData.product_name,
            product_rm_tech_spect: rowData.product_tech_spect,
            product_rm_hsn_sac_code: rowData.product_hsn_sac_code,
            product_rm_weight: rowData.product_std_weight,
            lead_time: rowData.product_lead_time,
            product_rm_stock_unit_name: rowData.product_unit_name,
            product_rm_packing_name: rowData.product_packing_name,
            product_rm_stock_unit_id: rowData.product_unit_id,
            product_rm_packing_id: rowData.product_packing_id,
            product_rm_hsn_sac_code_id: rowData.product_hsn_sac_code_id,
            product_rm_hsn_sac_rate: rowData.product_hsn_sac_rate,
            product_rm_std_weight: rowData.product_std_weight,
            product_rm_quantity: 1
        }
        return newMaterial;

    }
    const categoryDisplay = () => {
        const catCount = sessionStorage.getItem('compRMCatCount')
        for (let catCountIndex = 1; catCountIndex <= catCount; catCountIndex++) {
            $('.cat' + catCountIndex + 'Id').show();
        }
    }
    //Fn to get customer order nos on customer change
    const FngetCustOrdNos = async (object, creation_type) => {

        let customer_id = object.customer_id;
        let product_rm_id = object.product_rm_id;
        let selectedOrderValue = object.customer_order_no;
        var custOrdNoId = '';
        if (creation_type !== undefined && creation_type === 'A') {
            const indentNoAsId = object.indent_no.replace(/\//g, '_');
            custOrdNoId = document.getElementById("cmb_customer_order_no_" + `${indentNoAsId}` + "_" + product_rm_id + "_" + object.so_sr_no);
        } else {
            custOrdNoId = document.getElementById("cmb_customer_order_no_" + product_rm_id);
        }
        if (customer_id !== "" & customer_id !== "0") {
            resetGlobalQuery();
            globalQuery.columns.push("customer_order_no");
            globalQuery.columns.push("customer_order_date");
            globalQuery.table = "mtv_sales_order_master_trading_summary"
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customer_id });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.orderBy = ["sales_order_master_transaction_id desc"];
            const getCustomerOrdersApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            getCustomerOrdersApiCall.forEach(item => {
                const optionElement = document.createElement('option');
                optionElement.value = item.customer_order_no;
                optionElement.setAttribute('ord_date', item.customer_order_Date);
                optionElement.text = item.customer_order_no;
                custOrdNoId.appendChild(optionElement);
            });
            custOrdNoId.value = selectedOrderValue;
        } else {
            while (custOrdNoId.options.length > 2) {
                custOrdNoId.remove(2); // Remove the second option (index 1).
            }
        }

    }
    // Get indent nos
    const FnshowCustByIndent = (product_rm_id) => {
        if (customerOptions.length !== 0) {
            let listItems = customerOptions.map((item, index) => {

                return (
                    <label class="checkbox"><input type="checkbox" value={item.field_id} id={`customerIdCheckBox_${product_rm_id}_${item.field_id}`} name={`customerIdCheckBox_${product_rm_id}`} fieldName={item.field_name} disabled /> <span className="pl-2">{item.field_name}</span></label>
                );
            });
            return listItems;
        } else {
            return null;
        }
    }
    // Define a function to determine whether a checkbox should be checked
    const isCustCheckBoxChk = (customerIds, product_rm_id) => {
        const checkboxes = document.querySelectorAll(`input[type="checkbox"][name=customerIdCheckBox_${product_rm_id}]`);
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
        customerIds.forEach(function (contactId, index) {
            $(`#customerIdCheckBox_${product_rm_id}_${contactId}`).prop('checked', true);
        });
        setCheckCustomerLenght(customerIds.length);
    };

    const FnshowIndentCustOrdDt = (object) => {

        let product_rm_id = object.product_rm_id;
        const custOrdNoId = document.getElementById("cmb_customer_order_no_" + product_rm_id);
        // while (custOrdNoId.options.length > 1) {
        //     custOrdNoId.remove(1); // Remove the second option (index 1).
        // }                    <label class="checkbox"><input type="checkbox" value={item.field_id} id={`customerIdCheckBox_${product_rm_id}_${item.field_id}`} name={`customerIdCheckBox_${product_rm_id}`} fieldName={item.field_name} disabled /> <span className="pl-2">{item.field_name}</span></label>

        object.customer_order_no.split(':').forEach(item => {
            const optionElement = document.createElement('option');
            optionElement.value = item;
            optionElement.setAttribute('indent_no', object.indent_no);
            optionElement.text = item;
            custOrdNoId.appendChild(optionElement);
        });
    }
    function FnToggleDropdown(opendropdown, product_rm_id) {
        switch (opendropdown) {
            case 'displayCustomers':
                var element = document.getElementById(`customer-order-ul_${product_rm_id}`);
                if (element.style.display === 'none' || element.style.display === '') {
                    element.style.display = 'block';
                } else {
                    element.style.display = 'none';
                }
                break;
            case 'displayCustOrdNos':
                var divElement = document.getElementById(`cmb_customer_order_no_${product_rm_id}`);

                if (divElement.childNodes.length > 0) {
                    divElement.style.display = 'block';
                } else {
                    divElement.style.display = 'none';
                }
                // if (divElement.style.display === 'none' || divElement.style.display === '') {

                // } else {

                // }
                break;
            case 'displayIndentNos':
                var element = document.getElementById(`indent_no_${product_rm_id}`);
                if (element.style.display === 'none' || element.style.display === '') {
                    element.style.display = 'block';
                } else {
                    element.style.display = 'none';
                }
                break;
            default:
                break;
        }

    }

    //Fn to render po details table
    const renderPODetailsTbl = useMemo(() => {
        return <>
            {po_details_data.length !== 0 ?
                <Table className="erp_table erp_table_scroll" id='po-details-table' responsive bordered striped>
                    <thead className="erp_table_head">
                        <tr>
                            <th className={`erp_table_th  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`}>Action</th>
                            <th className="erp_table_th">Sr No</th>
                            <th className="erp_table_th">Material Name</th>
                            <th className="erp_table_th">Tech Spect</th>
                            <th className="erp_table_th">Customer Name   </th>
                            <th className="erp_table_th">Customer Order No</th>
                            <th className={`erp_table_th ${chk_po_creation_type === 'M' ? 'display' : 'd-none'}`}>Customer Date</th>
                            <th className="erp_table_th">Stock Unit Name</th>
                            <th className="erp_table_th"> Hsn Sac Code</th>
                            <th className="erp_table_th">Material Quantity</th>
                            <th className="erp_table_th">Approved Quantity</th>
                            <th className="erp_table_th">Material Rate</th>
                            <th className="erp_table_th">Basic Amount</th>
                            <th className="erp_table_th">Discount %</th>
                            <th className="erp_table_th">Discount Amount</th>
                            <th className="erp_table_th">Taxable Amount</th>
                            <th className="erp_table_th">Cgst %</th>
                            <th className="erp_table_th">Cgst Total</th>
                            <th className="erp_table_th">Sgst %</th>
                            <th className="erp_table_th">Sgst Total</th>
                            <th className="erp_table_th">Igst %</th>
                            <th className="erp_table_th">Igst Total</th>
                            <th className="erp_table_th">Total Amount</th>
                            <th className="erp_table_th d-none">Weight</th>
                            <th className="erp_table_th">Freight Amount</th>
                            <th className="erp_table_th">Schedule Date</th>
                            <th className="erp_table_th d-none">Approved Weight</th>
                            <th className="erp_table_th">Rejected Quantity</th>
                            <th className="erp_table_th d-none">Rejected Weight</th>
                            <th className="erp_table_th">Approval Remark</th>
                            <th className="erp_table_th">Approved Date</th>
                            <th className="erp_table_th">Material Item Status</th>
                            <th className="erp_table_th d-none">Std Weight</th>
                            <th className={`erp_table_th ${chk_po_creation_type === 'A' ? 'display' : 'd-none'}`} style={{ minWidth: '100px' }}> Indent No </th>
                            <th className="erp_table_th">Lead Time</th>
                            <th className="erp_table_th">Conversion Factor</th>
                            <th className="erp_table_th">Packing Name</th>
                            <th className="erp_table_th">Hsn Sac Rate</th>
                        </tr>
                    </thead>

                    <tbody>
                        {po_details_data.map((poItem, index) => (
                            <tr rowIndex={index}>
                                <td className={`erp_table_td sticky-column  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`}>
                                    <div style={{ display: "flex" }}>
                                        <Tooltip title="Delete" placement="top">
                                            <MDTypography className="erp-view-btn">
                                                <MdDelete className="erp-delete-btn" onClick={() => removeMaterial(poItem)} />
                                            </MDTypography>
                                        </Tooltip>
                                        <Tooltip title="Schedule" placement="top">
                                            <MDTypography className="erp-schedule-btn">
                                                <AiOutlineSchedule className="erp-schedule-btn" onClick={() => openSchedules(poItem)} />
                                            </MDTypography>
                                        </Tooltip>
                                    </div>
                                </td>
                                <td className="erp_table_td text-end">{index + 1}</td>
                                <td className="erp_table_td ">{poItem.product_rm_name}</td>
                                <td className="erp_table_td ">{poItem.product_rm_tech_spect}</td>
                                {chk_po_creation_type === 'A' ?
                                    <>
                                        <td className="erp_table_td ">
                                            <div className={`erp_dropdown`} onClick={(e) => { FnToggleDropdown('displayCustomers', `${poItem.product_rm_id}`) }} optional='optional' >
                                                <span className="form-select form-select-sm">
                                                    {checkCustomerLenght === 0 ? 'Select' : `${checkCustomerLenght} Selected`}
                                                </span>
                                                <div class="erp_dropdown-content" id={`customer-order-ul_${poItem.product_rm_id}`}>
                                                    {FnshowCustByIndent(poItem.product_rm_id)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="erp_table_td ">
                                            <div className={`erp_dropdown`} onClick={(e) => { FnToggleDropdown('displayCustOrdNos', `${poItem.product_rm_id}`) }}
                                                value={poItem.customer_order_no} Headers='customer_order_no' optional='optional' >
                                                <span className="form-select form-select-sm">Select</span>
                                                <div class="erp_dropdown-content" id={`cmb_customer_order_no_${poItem.product_rm_id}`}>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={`erp_table_td ${chk_po_creation_type === 'M' ? 'display' : 'd-none'}`}>
                                            <input
                                                type="date"
                                                className="erp_input_field mb-0" Headers='customer_order_Date'
                                                id={`customer_order_Date_${poItem.product_rm_id}`} onChange={(e) => { setCustomerOrderDate(e.target.value) }}
                                                value={poItem.customer_order_Date} min={currentDate} disabled // Use a function to get the input value based on propertyName
                                            /></td>
                                    </>
                                    :
                                    <>
                                        <td className="erp_table_td ">
                                            <select id="cmb_customer" className="form-select form-select-sm" value={poItem.customer_id} product_rm_id={`${poItem.product_rm_id}`} Headers='customer_id'
                                                onChange={(e) => { updatePODetailsTblData(poItem, e); setCustomerId(e.target.value) }} optional='optional'>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {customerOptions.length !== 0 ?
                                                    <>
                                                        {customerOptions?.map(customer => (
                                                            <option value={customer.field_id}>{customer.field_name}</option>

                                                        ))}
                                                    </>
                                                    : ''
                                                }
                                            </select>
                                        </td>
                                        <td className="erp_table_td ">
                                            <select id={
                                                chk_po_creation_type === 'A'
                                                    ? `cmb_customer_order_no_${poItem.indent_no.replace(/\//g, '_')}_${poItem.product_rm_id}_${poItem.so_sr_no}`
                                                    : `cmb_customer_order_no_${poItem.product_rm_id}`
                                            } className="form-select form-select-sm" value={poItem.customer_order_no} Headers='customer_order_no'
                                                onChange={(e) => { setCustomerOrderNo(e.target.value); updatePODetailsTblData(poItem, e); }} optional='optional'>
                                                <option value="" ord_date="">Select</option>
                                                <option value="NA" ord_date="">NA</option>
                                                {customerOrdOpts.length !== 0 ?
                                                    <>
                                                        {customerOrdOpts?.map(order => (
                                                            <option value={order.customer_order_no} ord_date={order.customer_order_Date}>{order.customer_order_no}</option>
                                                        ))}
                                                    </>
                                                    : ''
                                                }
                                            </select></td>
                                        <td className="erp_table_td ">
                                            <input
                                                type="date"
                                                className="erp_input_field mb-0" Headers='customer_order_Date'
                                                id={`customer_order_Date_${poItem.product_rm_id}`} onChange={(e) => { setCustomerOrderDate(poItem.customer_order_Date) }}
                                                value={poItem.customer_order_Date} disabled // Use a function to get the input value based on propertyName
                                            /></td>
                                    </>
                                }
                                <td className="erp_table_td ">{poItem.product_rm_hsn_sac_code}</td>
                                <td>
                                    <select id={`product_rm_stock_unit_id_${poItem.product_rm_id}`} className="form-select form-select-sm" value={poItem.product_rm_stock_unit_id} Headers='product_rm_stock_unit_id'
                                        onChange={(e) => { updatePODetailsTblData(poItem, e); }} >
                                        <option value=''>Select</option>
                                        {productUnitOptions.length !== 0 ?
                                            <>
                                                {productUnitOptions?.map(unitItem => (
                                                    <option value={unitItem.field_id} >{unitItem.field_name}</option>
                                                ))}
                                            </>
                                            : ''
                                        }
                                    </select></td>
                                <td className="erp_table_td ">
                                    <input
                                        type="text"
                                        className="erp_input_field mb-0 text-end" Headers='product_rm_quantity'
                                        id={`product_rm_quantity_${poItem.product_rm_id}`}
                                        value={poItem.product_rm_quantity} // Use a function to get the input value based on propertyName
                                        onChange={(e) => { updatePODetailsTblData(poItem, e); }}
                                    /></td>
                                <td className="erp_table_td ">
                                    <input
                                        type="text"
                                        className="erp_input_field mb-0 text-end approveField" Headers='product_rm_approved_quantity'
                                        id={`product_rm_approved_quantity_${poItem.product_rm_id}`} disabled={!isApprove}
                                        value={poItem.product_rm_approved_quantity} // Use a function to get the input value based on propertyName
                                        onChange={(e) => { updatePODetailsTblData(poItem, e); }} onBlur={(e) => { updatePODetailsTblData(poItem, e); }}
                                    /></td>
                                <td className="erp_table_td text-end">
                                    <input
                                        type="text"
                                        className="erp_input_field mb-0 text-end" Headers='product_rm_rate'
                                        id={`product_rm_rate_${poItem.product_rm_id}`}
                                        value={poItem.product_rm_rate} // Use a function to get the input value based on propertyName
                                        onChange={(e) => { updatePODetailsTblData(poItem, e); }}
                                    />
                                </td>
                                <td className="erp_table_td text-end">{poItem.material_basic_amount}</td>
                                <td className="erp_table_td ">
                                    <input
                                        type="text"
                                        className="erp_input_field mb-0 text-end" Headers='material_discount_percent'
                                        id={`material_discount_percent_${poItem.product_rm_id}`}
                                        value={poItem.material_discount_percent} // Use a function to get the input value based on propertyName
                                        onChange={(e) => { updatePODetailsTblData(poItem, e); }}
                                    />
                                </td>
                                <td className="erp_table_td text-end">{poItem.material_discount_amount}</td>
                                <td className="erp_table_td text-end">{poItem.material_taxable_amount}</td>
                                <td className="erp_table_td text-end">{poItem.material_cgst_percent}</td>
                                <td className="erp_table_td text-end">{poItem.material_cgst_total}</td>
                                <td className="erp_table_td text-end">{poItem.material_sgst_percent}</td>
                                <td className="erp_table_td text-end">{poItem.material_sgst_total}</td>
                                <td className="erp_table_td text-end">{poItem.material_igst_percent}</td>
                                <td className="erp_table_td text-end">{poItem.material_igst_total}</td>
                                <td className="erp_table_td text-end">{poItem.material_total_amount}</td>
                                <td className="erp_table_td text-end d-none">{poItem.product_rm_weight}</td>
                                <td className="erp_table_td text-end">{poItem.material_freight_amount}</td>
                                <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(poItem.material_schedule_date)}</td>
                                <td className="erp_table_td text-end d-none">{poItem.product_rm_approved_weight}</td>
                                <td className="erp_table_td text-end">{poItem.product_rm_rejected_quantity}</td>
                                <td className="erp_table_td text-end d-none">{poItem.product_rm_rejected_weight}</td>
                                <td className="erp_table_td ">
                                    <input
                                        as="textarea"
                                        className="erp_input_field mb-0 approveField" Headers='material_po_approval_remark'
                                        id={`material_po_approval_remark_${poItem.product_rm_id}`} disabled={!isApprove}
                                        value={poItem.material_po_approval_remark} maxLength={500} // Use a function to get the input value based on propertyName
                                        onChange={(e) => { updatePODetailsTblData(poItem, e); }}
                                    /></td>
                                <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(poItem.approved_date)}</td>

                                <td className="erp_table_td ">
                                    <select id={`purchase_order_item_status_${poItem.product_rm_id}`} disabled={!isApprove} value={poItem.purchase_order_item_status} className="form-select form-select-sm approveField"
                                        Headers='purchase_order_item_status' onChange={(e) => { updatePODetailsTblData(poItem, e); }} >
                                        <option value="P">Pending</option>
                                        <option value="A">Approved</option>
                                        <option value="R">Rejected</option>
                                        <option value="I">Partial Issue</option>
                                        <option value="C">Completed</option>
                                        <option value="X">Canceled</option>
                                        <option value="Z">PreeClosed</option>
                                        <option value="B">Bill Booked</option>
                                    </select>
                                </td>
                                <td className="erp_table_td text-end d-none">{poItem.product_rm_std_weight}</td>
                                {chk_po_creation_type === 'A' ? <>
                                    <td className={`erp_table_td ${chk_po_creation_type === 'A' ? 'display' : 'd-none'}`}>
                                        <div className={`erp_dropdown`} onClick={(e) => { FnToggleDropdown('displayIndentNos', `${poItem.product_rm_id}`) }}
                                            value={poItem.customer_order_no} Headers='indent_no' optional='optional' >
                                            <span className="form-select form-select-sm">Select</span>
                                            <div class="erp_dropdown-content" id={`indent_no_${poItem.product_rm_id}`}>
                                                {poItem.indent_nos.map((indentNo, index) => (
                                                    <label class="checkbox"><input type="checkbox" value={indentNo.trim()} id={`indentNoCheckBox_${poItem.product_rm_id}`} name={`indentNoCheckBox_${poItem.product_rm_id}`} disabled checked={true} /> <span className="pl-2">{indentNo.trim()}</span></label>
                                                ))}
                                            </div>
                                        </div>
                                    </td>
                                </> :
                                    ''
                                }
                                <td className="erp_table_td text-end">{poItem.lead_time}</td>
                                <td className="erp_table_td text-end">{poItem.product_rm_conversion_factor}</td>
                                <td className="erp_table_td ">{poItem.product_rm_packing_name}</td>
                                <td className="erp_table_td text-end">{poItem.product_rm_hsn_sac_rate}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table> : null
            }
        </>
    }, [po_details_data, isView]);

    //function for update Details table data
    const updatePODetailsTblData = async (currentRowData, event) => {

        let focusedInputField = document.querySelector('#' + event.target.id);
        let clickedColName = event.target.getAttribute('Headers');
        if (clickedColName === 'material_schedule_date') {
            if (event.target.value !== '' || event.target.value !== undefined) {
                currentRowData[clickedColName] = event.target.value;
            } else {
                currentRowData[clickedColName] = '';
            }
        } else if (clickedColName === 'material_po_approval_remark') {
            if (validateText(event)) {
                currentRowData[clickedColName] = event.target.value;
            } else {
                currentRowData[clickedColName] = '';
            }
        } else if (clickedColName.includes('_status')) {
            currentRowData[clickedColName] = event.target.value;

        }
        else if (clickedColName.includes('customer_id')) {
            var custOrdNoId = event.target.parentElement.nextSibling.querySelector('select');
            currentRowData[clickedColName] = event.target.value;
            let customerVal = event.target.value;
            if (customerVal === '0') {
                sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                setHeaderName('Add Customer')
                const newTab = window.open('/Masters/Customer', '_blank');
                if (newTab) {
                    newTab.focus();
                }
            }
            if (customerVal !== "" & customerVal !== "0") {

                resetGlobalQuery();
                globalQuery.columns.push("customer_order_no");
                globalQuery.columns.push("customer_order_Date");
                globalQuery.table = "mtv_sales_order_master_trading_summary"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customerVal });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.orderBy = ["sales_order_master_transaction_id desc"];
                const getCustomerOrdersApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                if (getCustomerOrdersApiCall.length !== 0) {
                    getCustomerOrdersApiCall.forEach(item => {
                        const optionElement = document.createElement('option');
                        optionElement.value = item.customer_order_no;
                        optionElement.setAttribute('ord_date', item.customer_order_Date);
                        optionElement.text = item.customer_order_no;
                        custOrdNoId.appendChild(optionElement);
                    });
                } else {
                    while (custOrdNoId.options.length > 2) {
                        custOrdNoId.remove(2); // Remove the second option (index 1).
                    }
                    currentRowData['customer_order_no'] = '';
                    currentRowData['customer_order_Date'] = '';
                }
            } else {
                while (custOrdNoId.options.length > 2) {
                    custOrdNoId.remove(2); // Remove the second option (index 1).
                }
                currentRowData['customer_order_no'] = '';
                currentRowData['customer_order_Date'] = '';
            }
        }
        else if (clickedColName.includes('customer_order_no')) {
            let selectedOption = $('#' + event.target.id).find('option:selected');
            let selectedOrderDate = selectedOption.attr('ord_date');
            currentRowData[clickedColName] = event.target.value;
            currentRowData['customer_order_Date'] = selectedOrderDate;

        } else if (clickedColName.includes('product_rm_stock_unit_id')) {
            if (event.target.value !== '') {

                currentRowData[clickedColName] = event.target.value;
                delete focusedInputField.parentElement.dataset.tip;
            } else {
                currentRowData[clickedColName] = '';
                focusedInputField.parentElement.dataset.tip = 'Please Select Atleast One...!';
            }
        }
        else {
            currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber((event.target.value).toString(), 4);

            if (currentRowData[clickedColName] == '') {
                focusedInputField.parentElement.dataset.tip = 'Please fill this field...!';
            }
            else {
                delete focusedInputField.parentElement.dataset.tip;
                if (keyForViewUpdate === 'approve') {
                    let product_rm_qty = currentRowData['product_rm_quantity']
                    let product_app_qty = currentRowData['product_rm_approved_quantity']
                    let product_rejct_qty = (product_rm_qty - product_app_qty);
                    // let product_std_wgt = currentRowData['product_rm_std_weight']
                    // let product_approved_wgt = product_app_qty * product_std_wgt;
                    // let product_rejct_wgt = product_rejct_qty * product_std_wgt;
                    // currentRowData['product_rm_approved_weight'] = product_approved_wgt.toFixed(2);
                    // currentRowData['product_rm_rejected_weight'] = product_rejct_wgt.toFixed(2);

                    currentRowData['product_rm_approved_weight'] = parseFloat(product_app_qty).toFixed(2);
                    currentRowData['product_rm_rejected_weight'] = parseFloat(product_rejct_qty).toFixed(2);

                    let poItemsQtyinp = document.querySelector('#' + event.target.id);

                    if (product_app_qty > product_rm_qty) {
                        focusedInputField.parentElement.dataset.tip = 'cannot exceed material qauntity...!';
                        return false;
                    }
                    if (product_rejct_qty > 0) {
                        currentRowData['product_rm_rejected_quantity'] = product_rejct_qty
                        if ($(`#material_po_approval_remark_${currentRowData.product_rm_id}`).val() === '') {
                            $(`#material_po_approval_remark_${currentRowData.product_rm_id}`).prop('disabled', false);

                            if (event._reactName === 'onBlur') {
                                $(`#material_po_approval_remark_${currentRowData.product_rm_id}`).focus();
                            }
                        }
                    } else {
                        currentRowData['product_rm_rejected_quantity'] = 0
                        delete focusedInputField.parentElement.dataset.tip;
                    }
                }
                else if (clickedColName.includes('material_discount_percent')) {
                    if (chk_po_status === 'P' || chek_po_life === 'O') {
                        let material_discount_percent = parseFloat([...po_details_data].reduce((total, item) => total + parseFloat(item.material_discount_percent), 0));
                        if (material_discount_percent > 100) {
                            currentRowData[clickedColName] = 0;
                            focusedInputField.parentElement.dataset.tip = 'cannot exceed Sum Of Discount Percent (100%)...!';
                        } else {
                            delete focusedInputField.parentElement.dataset.tip;
                        }
                    }
                } else if (clickedColName === 'product_rm_quantity') {
                    if (chk_po_creation_type === 'A') {
                        const cust_materialqty = currentRowData['prev_indent_quantity']
                        if (event.target.value <= cust_materialqty) {
                            currentRowData[clickedColName] = event.target.value;
                            delete focusedInputField.parentElement.dataset.tip;
                        } else {
                            currentRowData[clickedColName] = '';
                            focusedInputField.parentElement.dataset.tip = `Please enter less than ${cust_materialqty} ...!`;
                        }
                    } else {
                        currentRowData[clickedColName] = event.target.value;
                    }

                }
                currentRowData = handleMaterialWiseCalculations(currentRowData);
            }
        }
        const tblRows = [...po_details_data]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        tblRows[arrayIndex] = currentRowData
        setPODetailsData(tblRows);                        // Set that updated data in table data hook;
        localStorage.setItem('filteredMaterialData', JSON.stringify(tblRows));
        FnCalculateDiscSum();
        FnGetTaxationSummary();
        setTimeout(() => {
            calculateGrandTotal();
        }, 200);
    }
    // Reverse Calculations to update dettails data table on change of discount & freight amount
    const reverseCalculationForDetailData = () => {

        let poDetailData = [...po_details_data];
        const discount_amount = isNaN(parseFloat($('#txt_po_discount_amount').val())) ? 0 : parseFloat(parseFloat($('#txt_po_discount_amount').val()))

        if (poDetailData.length !== 0) {
            const updatedData = poDetailData.map(obj => {
                // Calculate item_freight_amount and discount_amount
                let discountAmount;
                let calculatedPercent;
                if (discountAmount !== 0) {
                    discountAmount = (obj.material_basic_amount / txt_basic_total) * discount_amount;
                    calculatedPercent = (discountAmount * 100) / obj.material_basic_amount;
                }
                // Create a new object with the updated properties
                return {
                    ...obj,
                    material_discount_amount: discountAmount === 0 ? obj.material_discount_amount : validateNumberDateInput.current.decimalNumber(JSON.stringify(discountAmount), 4),
                    material_discount_percent: discountAmount === 0 ? obj.material_discount_amount : validateNumberDateInput.current.decimalNumber(JSON.stringify(calculatedPercent), 2)
                };
            });
            setPODetailsData(updatedData)
            localStorage.setItem('filteredMaterialData', JSON.stringify(updatedData));
        }
    }
    // Reverse Calculations to update dettails data table on change of discount & freight amount
    const reverseCalculationForFrieghtAmt = () => {

        let poDetailData = [...po_details_data];
        const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) ? 0 : parseFloat($('#txt_freight_amount').val())
        if (poDetailData.length !== 0) {
            const updatedData = poDetailData.map(obj => {
                // Calculate item_freight_amount and discount_amount
                let itemFreightAmount = (obj.material_basic_amount / txt_basic_total) * freightAmt;
                return {
                    ...obj,
                    material_freight_amount: validateNumberDateInput.current.decimalNumber(JSON.stringify(itemFreightAmount), 4),
                };
            });
            setPODetailsData(updatedData)
            localStorage.setItem('filteredMaterialData', JSON.stringify(updatedData));
        }
    }
    //fn for calculate details data onchange
    const handleMaterialWiseCalculations = (currentRowData) => {
        let suppl_stateval = parseInt($('#cmb_supplier_state_id').val())
        if (!isNaN(suppl_stateval)) {

            let material_rate = isNaN(parseFloat(currentRowData.product_rm_rate)) === NaN ? 0 : parseFloat(currentRowData.product_rm_rate);
            let quantity = isNaN(parseInt(currentRowData.product_rm_quantity)) === NaN ? 0 : parseInt(currentRowData.product_rm_quantity);
            let discPercent = isNaN(parseInt(currentRowData.material_discount_percent)) === NaN ? 0 : parseInt(currentRowData.material_discount_percent);
            let discAmt = isNaN(parseFloat(currentRowData.material_discount_amount)) === NaN ? 0 : parseFloat(currentRowData.material_discount_amount);
            let hsn_code_rate = isNaN(parseFloat(currentRowData.product_rm_hsn_sac_rate)) === NaN ? 0 : parseFloat(currentRowData.product_rm_hsn_sac_rate);
            let std_weight = isNaN(parseFloat(currentRowData.product_rm_std_weight)) === NaN ? 0 : parseFloat(currentRowData.product_rm_std_weight);

            const optionselected = $('#cmb_expected_branch_id').find('option:selected');
            const is_sez = optionselected.attr('chk_issez');
            // console.log('rate: ' + material_rate, 'qnty: ' + quantity, 'disc: ' + discPercent, 'hsn_code_rate: ' + hsn_code_rate)

            let total_amount = 0;
            let cgst_sgst_per = 0;
            let cgst_amt = 0;
            let sgst_amt = 0;
            let igst_per = 0;
            let igst_amt = 0;

            const total_weight = std_weight * quantity;
            const material_basic_amount = material_rate * quantity;
            const material_discount_amount = (material_basic_amount * discPercent) / 100;
            const material_taxable_amount = material_basic_amount - material_discount_amount;
            if (suppl_stateval === '39') {
                cgst_sgst_per = 0; cgst_amt = 0; sgst_amt = 0; igst_per = 0; igst_amt = 0;
                total_amount = material_taxable_amount;
            } else if (is_sez === 'true') {
                cgst_sgst_per = 0; cgst_amt = 0; sgst_amt = 0; igst_per = 0; igst_amt = 0;
                total_amount = material_taxable_amount;

            } else if (suppl_stateval === parseInt($('#cmb_expected_branch_state_id').val())) { // for maharashtra means same state cal SGST,CGST.
                if (!isNaN(material_rate) && material_rate !== "" && material_rate !== 0) {
                    cgst_sgst_per = hsn_code_rate / 2; //for gst per% cal
                    const gst_amt = (material_taxable_amount * hsn_code_rate) / 100; //for gst amount cal
                    cgst_amt = gst_amt / 2;
                    sgst_amt = gst_amt / 2;
                    total_amount = material_taxable_amount + gst_amt;
                }

            } else {//for cal IGST for other states.
                if (!isNaN(material_rate) && material_rate !== "" && material_rate !== 0) {
                    igst_per = hsn_code_rate;
                    igst_amt = (material_taxable_amount * hsn_code_rate) / 100;
                    total_amount = material_taxable_amount + igst_amt;
                }
            }
            console.log('material_basic_amount: ' + material_basic_amount, 'material_discount_amount: ' + material_discount_amount, 'material_taxable_amount: ' + material_taxable_amount,);
            console.log('cgst_sgst_per: ' + cgst_sgst_per, 'cgst_amt: ' + cgst_amt, 'sgst_amt: ' + sgst_amt, 'igst_per: ' + igst_per, 'igst_amt: ' + igst_amt, 'total_amount: ' + total_amount,)

            currentRowData['material_basic_amount'] = validateNumberDateInput.current.decimalNumber((material_basic_amount).toString(), 4);
            currentRowData['material_discount_amount'] = validateNumberDateInput.current.decimalNumber((material_discount_amount).toString(), 4);
            currentRowData['material_taxable_amount'] = validateNumberDateInput.current.decimalNumber((material_taxable_amount).toString(), 4);
            currentRowData['material_cgst_percent'] = validateNumberDateInput.current.decimalNumber((cgst_sgst_per).toString(), 2);
            currentRowData['material_cgst_total'] = validateNumberDateInput.current.decimalNumber((cgst_amt).toString(), 4);
            currentRowData['material_sgst_percent'] = validateNumberDateInput.current.decimalNumber((cgst_sgst_per).toString(), 2);
            currentRowData['material_sgst_total'] = validateNumberDateInput.current.decimalNumber((sgst_amt).toString(), 4);
            currentRowData['material_igst_percent'] = validateNumberDateInput.current.decimalNumber((igst_per).toString(), 2);
            currentRowData['material_igst_total'] = validateNumberDateInput.current.decimalNumber((igst_amt).toString(), 4);
            currentRowData['material_total_amount'] = validateNumberDateInput.current.decimalNumber((total_amount).toString(), 4);

            // // into the weight field store the value as it is of the quantity.(Beacause aerotech client don't need the weight.)
            // currentRowData['product_rm_weight'] = validateNumberDateInput.current.decimalNumber((total_weight).toString(), 4);
            currentRowData['product_rm_weight'] = validateNumberDateInput.current.decimalNumber((quantity).toString(), 4);

            let taxSummary = {
                materialId: currentRowData.product_rm_id,
                hsnCode: currentRowData.product_rm_hsn_sac_code,
                hsnRate: currentRowData.product_rm_hsn_sac_rate,
                hsn_code_id: currentRowData.product_rm_hsn_sac_code_id,
                taxableAmt: currentRowData.material_taxable_amount,
                CGSTPercent: currentRowData.material_cgst_percent,
                CGSTAmt: currentRowData.material_cgst_total,
                SGSTPercent: currentRowData.material_sgst_percent,
                SGSTAmt: currentRowData.material_sgst_total,
                IGSTPercent: currentRowData.material_igst_percent,
                IGSTAmt: currentRowData.material_igst_total,
                totalAmt: currentRowData.material_total_amount
            }
            let taxationSummary = JSON.parse(localStorage.getItem('taxationSummary')) === null ? [] : JSON.parse(localStorage.getItem('taxationSummary'));
            if (taxationSummary !== null) {
                const materialIndex = taxationSummary.findIndex((obj) => obj.materialId === currentRowData.product_rm_id);
                if (materialIndex !== -1) {// Update the object if a match is found
                    taxationSummary[materialIndex] = taxSummary; // Replace the object in-place
                } else {
                    taxationSummary.push(taxSummary); // Push the new object if not found
                }
                localStorage.setItem('taxationSummary', JSON.stringify(taxationSummary));
            }
        } else {
            $('#error_cmb_supplier_state_id').text('Please select atleast one...!');
            $('#error_cmb_supplier_state_id').show();
            $('#cmb_supplier_state_id').focus();
        }
        FnGetTaxationSummary();
        currentRowData['material_schedule_date'] = currentRowData.material_schedule_date;
        return currentRowData;
    };
    //fn for calculations on change of supplier and company state
    const updateTblCalsForSupplStateChange = async () => {
        const detailsTblData = JSON.parse(localStorage.getItem('filteredMaterialData'));
        if (detailsTblData !== null) {
            let updatedTblData = [];
            for (let rowIndex = 0; rowIndex < detailsTblData.length; rowIndex++) {
                let updatedRowData = handleMaterialWiseCalculations(detailsTblData[rowIndex]);
                updatedTblData.push(updatedRowData);
            }
            setPODetailsData(updatedTblData);
            localStorage.setItem('filteredMaterialData', JSON.stringify(updatedTblData));
            calculateGrandTotal();
            FnGetTaxationSummary();
        }
    }
    //fn for validate text input 
    const validateText = (event) => {
        let indentItemsQtyinp = document.querySelector('#' + event.target.id);
        if (event.target.value === '') {
            indentItemsQtyinp.parentElement.dataset.tip = 'Please enter here...!';
            return false;
        }
        else {
            delete indentItemsQtyinp.parentElement.dataset.tip;
            return true;
        }
    }
    // remove material from po details table
    const removeMaterial = async (row) => {

        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            let tblRows = [...po_details_data]; // Store all table rows original object in array.
            let taxationData = [...taxationSummary];
            const findElementIndex = tblRows.findIndex(record => record.product_rm_id === row.product_rm_id);
            const deletedMaterialIndex = taxationData.findIndex((obj) => obj.materialId === row.product_rm_id);
            // }
            if (findElementIndex !== -1) { tblRows.splice(findElementIndex, 1); }
            if (deletedMaterialIndex !== -1) { taxationData.splice(deletedMaterialIndex, 1); }
            localStorage.setItem('taxationSummary', JSON.stringify(taxationData));

            setPODetailsData(tblRows);
            localStorage.setItem('filteredMaterialData', JSON.stringify(tblRows));                      // Set that updated data in table data hook;
            await updateTblCalsForSupplStateChange(tblRows);
            calculateGrandTotal();


            // remove the schedules for that material
            let materialScheduleTblRows = JSON.parse(localStorage.getItem('purchaseOrdScheduleData')) === null ? [] : JSON.parse(localStorage.getItem('purchaseOrdScheduleData'));
            if (materialScheduleTblRows.length > 0) {
                const updatedMaterialSchedules = materialScheduleTblRows.filter(item => item.product_material_name !== row.product_rm_name && item.product_material_id !== row.product_rm_id);
                setScheduleDataArray(updatedMaterialSchedules);
                localStorage.setItem('purchaseOrdScheduleData', JSON.stringify(updatedMaterialSchedules));
            }
        }
    }

    const validatePoDetails = (event, inputType) => {
        const floatNumReg = /^\d{1,18}(?:\.\d{0,4})?$/;
        let isValidInput = false;
        let errorMsg = "";
        switch (inputType) {
            case "TextInput":
                if (event.target.value === '' || event.target.value === undefined || event.target.value === null) {
                    errorMsg = 'Please fill this field...!'
                    isValidInput = false
                } else {
                    isValidInput = true
                }
                break;
            case "FloatInput":
                if (event.target.value === '' || event.target.value === undefined) {
                    errorMsg = 'Please fill this field...!'
                    isValidInput = false
                } else if (!floatNumReg.test(event.target.value)) {
                    errorMsg = 'Please enter only numeric input...!...!'
                    isValidInput = false
                } else {
                    isValidInput = true
                }
                break;
            case "PercentageInput":
                if (event.target.value === '' || event.target.value === undefined) {
                    errorMsg = 'Please fill this field...!'
                    isValidInput = false
                } else if (event.target.value > 100) {
                    errorMsg = 'Percentage should not be greater than 100%...!'
                    isValidInput = false
                } else {
                    isValidInput = true
                }
                break;
            case "DateInput":
                if (event.target.value === '' || event.target.value === undefined || event.target.value === null) {
                    errorMsg = 'Please select the date...!'
                    isValidInput = false
                } else {
                    isValidInput = true
                }
                break;
            default:
                isValidInput = true;
                break;
        }
        let focusedInputField = document.querySelector('#' + event.target.id);
        if (!isValidInput) {
            focusedInputField.parentElement.dataset.tip = errorMsg;
            return false;
        } else {
            delete focusedInputField.parentElement.dataset.tip;
            return true;
        }
    }

    const validateNumericInputs = (event) => {
        // const floatNumReg = /^\d{1,18}(?:\.\d{0,4})?$/; || !floatNumReg.test(event.target.value)
        let tblInputField = document.querySelector('#' + event.target.id);
        if (event.target.value === '' || event.target.value === undefined) {
            $("#error_" + event.target.id).show();
            $("#" + event.target.id).focus();
            $("#error_" + event.target.id).text("Please enter valid input...!");
            return false;
        } else {
            delete tblInputField.parentElement.dataset.tip;
            $("#error_" + event.target.id).hide();
            $("#error_" + event.target.id).text("");
            return true;
        }
    }
    //Fn to render po details table
    const renderPOTrackDetailsTbl = useMemo(() => {
        return <>
            <Table className="erp_table  erp_table_scroll" id='po-tracking-table' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Sr No</th>
                        <th className="erp_table_th">Material Name</th>
                        <th className="erp_table_th">Material Quantity</th>
                        <th className="erp_table_th"> Weight</th>
                        <th className="erp_table_th">Material Item Status</th>
                        {/* <th className="erp_table_th"> Std Weight</th> */}
                        <th className={`erp_table_th ${chk_po_creation_type === 'A' ? 'display' : 'd-none'}`}> Indent No</th>
                        <th className={`erp_table_th ${chk_po_creation_type === 'A' ? 'display' : 'd-none'}`}> Indent Version</th>
                        <th className={`erp_table_th ${chk_po_creation_type === 'A' ? 'display' : 'd-none'}`}> Indent Date</th>

                        <th className="erp_table_th">Pree Closed Quantity</th>
                        <th className="erp_table_th">Pree Closed Weight</th>
                        <th className="erp_table_th">Purchase Return Quantity</th>
                        <th className="erp_table_th">Purchase Return Weight</th>
                        <th className="erp_table_th">Pending Quantity</th>
                        <th className="erp_table_th">Pending Weight</th>
                        <th className="erp_table_th">Excess Quantity</th>
                        <th className="erp_table_th">Excess Weight</th>
                        <th className="erp_table_th">Production Issue Quantity</th>
                        <th className="erp_table_th">Production Issue Weight</th>
                        <th className="erp_table_th">Production Issue Return Quantity</th>
                        <th className="erp_table_th">Production Issue Return Weight</th>
                        <th className="erp_table_th">Production Issue Rejection Quantity</th>
                        <th className="erp_table_th">Production Issue Rejection Weight</th>
                        <th className="erp_table_th">Assembly Production Issue Quantity</th>
                        <th className="erp_table_th">Assembly Production Issue Weight</th>
                        <th className="erp_table_th">Sales Quantity</th>
                        <th className="erp_table_th">Sales Weight</th>
                        <th className="erp_table_th">Sales Return Quantity</th>
                        <th className="erp_table_th">Sales Return Weight</th>
                        <th className="erp_table_th">Sales Rejection Quantity</th>
                        <th className="erp_table_th">Sales Rejection Weight</th>
                        <th className="erp_table_th">Transfer Issue Quantity</th>
                        <th className="erp_table_th">Transfer Issue Weight</th>
                        <th className="erp_table_th">Transfer Receipt Quantity</th>
                        <th className="erp_table_th">Transfer Receipt Weight</th>
                        <th className="erp_table_th">Outsources Out Quantity</th>
                        <th className="erp_table_th">Outsources Out Weight</th>
                        <th className="erp_table_th">Outsources In Quantity</th>
                        <th className="erp_table_th">Outsources In Weight</th>
                        <th className="erp_table_th">Outsources Rejection Quantity</th>
                        <th className="erp_table_th">Outsources Rejection Weight</th>
                        <th className="erp_table_th">Loan Receipt Quantity</th>
                        <th className="erp_table_th">Loan Receipt Weight</th>
                        <th className="erp_table_th">Loan Issue Quantity</th>
                        <th className="erp_table_th">Loan Issue Weight</th>
                        <th className="erp_table_th">Cancel Quantity</th>
                        <th className="erp_table_th">Cancel Weight</th>
                        <th className="erp_table_th">Difference Quantity</th>
                        <th className="erp_table_th">Difference Weight</th>
                    </tr>
                </thead>

                <tbody>
                    {po_details_data.map((poItem, index) => (
                        <tr rowIndex={index}>

                            <td className="erp_table_td text-end">{index + 1}</td>
                            <td className="erp_table_td ">{poItem.product_rm_name}</td>
                            <td className="erp_table_td text-end"> {poItem.product_rm_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.product_rm_weight}</td>

                            <td className="erp_table_td ">{poItem.purchase_order_item_status_desc}
                                {/* <select id={`purchase_order_item_status_${poItem.product_rm_id}`} disabled={!isApprove} value={poItem.purchase_order_item_status} className="form-select form-select-sm approveField"
                                    Headers='purchase_order_item_status' onChange={(e) => { updatePODetailsTblData(poItem, e); }} >
                                    <option value="P">Pending</option>
                                    <option value="A">Approved</option>
                                    <option value="R">Rejected</option>
                                    <option value="I">Partial Issue</option>
                                    <option value="C">Completed</option>
                                    <option value="X">Canceled</option>
                                    <option value="Z">PreeClosed</option>
                                </select> */}
                            </td>
                            {/* <td className="erp_table_td text-end">{poItem.product_rm_std_weight}</td> */}
                            <td className={`erp_table_td ${chk_po_creation_type === 'A' ? 'display' : 'd-none'}`}>{poItem.indent_no}</td>
                            <td className={`erp_table_td text-end ${chk_po_creation_type === 'A' ? 'display' : 'd-none'}`}>{poItem.indent_version}</td>
                            <td className={`erp_table_td ${chk_po_creation_type === 'A' ? 'display' : 'd-none'}`}>{validateNumberDateInput.current.formatDateToDDMMYYYY(poItem.indent_date)}</td>

                            <td className="erp_table_td text-end">{poItem.pree_closed_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.pree_closed_weight}</td>
                            <td className="erp_table_td text-end">{poItem.purchase_return_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.purchase_return_weight}</td>
                            <td className="erp_table_td text-end">{poItem.pending_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.pending_weight}</td>
                            <td className="erp_table_td text-end">{poItem.excess_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.excess_weight}</td>
                            <td className="erp_table_td text-end">{poItem.production_issue_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.production_issue_weight}</td>
                            <td className="erp_table_td text-end">{poItem.production_issue_return_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.production_issue_return_weight}</td>
                            <td className="erp_table_td text-end">{poItem.production_issue_rejection_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.production_issue_rejection_weight}</td>
                            <td className="erp_table_td text-end">{poItem.assembly_production_issue_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.assembly_production_issue_weight}</td>
                            <td className="erp_table_td text-end">{poItem.sales_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.sales_weight}</td>
                            <td className="erp_table_td text-end">{poItem.sales_return_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.sales_return_weight}</td>
                            <td className="erp_table_td text-end">{poItem.sales_rejection_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.sales_rejection_weight}</td>
                            <td className="erp_table_td text-end">{poItem.transfer_issue_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.transfer_issue_weight}</td>
                            <td className="erp_table_td text-end">{poItem.transfer_receipt_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.transfer_receipt_weight}</td>
                            <td className="erp_table_td text-end">{poItem.outsources_out_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.outsources_out_weight}</td>
                            <td className="erp_table_td text-end">{poItem.outsources_in_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.outsources_in_weight}</td>
                            <td className="erp_table_td text-end">{poItem.outsources_rejection_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.outsources_rejection_weight}</td>
                            <td className="erp_table_td text-end">{poItem.loan_receipt_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.loan_receipt_weight}</td>
                            <td className="erp_table_td text-end">{poItem.loan_issue_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.loan_issue_weight}</td>
                            <td className="erp_table_td text-end">{poItem.cancel_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.cancel_weight}</td>
                            <td className="erp_table_td text-end">{poItem.difference_quantity}</td>
                            <td className="erp_table_td text-end">{poItem.difference_weight}</td>
                        </tr>
                    ))}
                </tbody>

            </Table>
        </>
    }, [po_details_data, isView]);
    // ---------------------- PO Details section End ------------------------------------------------------------------------------
    //----------------------  schedule section start --------------------------------------------------------------------------------

    const renderPOScheduleTbl = useMemo(() => {
        return <>
            <Table className="erp_table " id='po-schedule-table' responsive bordered striped >
                <thead className="erp_table_head">
                    <tr>
                        <th className={`erp_table_th  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`}>Action</th>
                        <th className="erp_table_th">Material Name</th>
                        <th className="erp_table_th">Purchase Order No</th>
                        <th className="erp_table_th">Purchase Order Date</th>
                        <th className="erp_table_th">Purchase Order Version</th>
                        <th className="erp_table_th">Schedule Quantity</th>
                        <th className="erp_table_th d-none">Schedule Weight</th>
                        <th className="erp_table_th">Expected Schedule Date</th>
                        <th className="erp_table_th">Receipt Quantity</th>
                        <th className="erp_table_th d-none">Receipt Weight</th>
                        <th className="erp_table_th">Receipt Date</th>
                        <th className="erp_table_th">PO Item Status</th>
                        <th className="erp_table_th">Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        scheduleDataArray.map((scheduleItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className={`erp_table_td  ${keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`}><div style={{ display: "flex" }}>
                                    <MdDelete className="erp-delete-btn" id={scheduleItem.id} onClick={() => deleteSchedules(scheduleItem.id)} />
                                </div></td>
                                <td className="erp_table_td"> {scheduleItem.product_material_name} </td>
                                <td className="erp_table_td">{scheduleItem.purchase_order_no}</td>
                                <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(scheduleItem.purchase_order_date)}</td>
                                <td className="erp_table_td text-end">{scheduleItem.purchase_order_version}</td>
                                <td className="erp_table_td text-end">{scheduleItem.product_material_po_schedule_quantity}</td>
                                <td className="erp_table_td text-end d-none">{scheduleItem.product_material_po_schedule_weight}</td>
                                <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(scheduleItem.expected_schedule_date)}</td>
                                <td className="erp_table_td text-end">{scheduleItem.product_material_receipt_quantity}</td>
                                <td className="erp_table_td text-end d-none">{scheduleItem.product_material_receipt_weight}</td>
                                <td className="erp_table_td">{validateNumberDateInput.current.formatDateToDDMMYYYY(scheduleItem.product_material_receipt_date)}</td>
                                <td> <select id={`#purchase_order_schedules_item_status_${scheduleItem.product_material_id}`} disabled value={scheduleItem.purchase_order_schedules_item_status}
                                    className="form-select form-select-sm approveField" onChange={(e) => { handleScheduleData(scheduleItem, e) }} Headers={'purchase_order_schedules_item_status'}>
                                    <option value="P">Pending</option>
                                    <option value="A">Approved</option>
                                    <option value="R">Rejected</option>
                                    <option value="I">Partial receipt</option>
                                    <option value="C">Completed</option>
                                    <option value="X">Canceled</option>
                                </select></td>
                                <td className="erp_table_td">
                                    <input
                                        as="textarea" onChange={(e) => { handleScheduleData(scheduleItem, e) }}
                                        className="erp_input_field mb-0 approveField" Headers={'remark'}
                                        id={`${`remark`}_${scheduleItem.product_material_id}`} disabled={true}
                                        value={scheduleItem.remark} maxLength={500} // Use a function to get the input value based on propertyName
                                    />
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>

    }, [scheduleDataArray]);
    const handleScheduleData = (currentRowData, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        currentRowData[clickedColName] = event.target.value;            // Get the column name where event triggered.
        const poschedule_data = [...scheduleDataArray]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        poschedule_data[arrayIndex] = currentRowData
        setScheduleDataArray(poschedule_data);
    }
    //fn to open schedule modal
    const openSchedules = (poItem) => {

        let product_rm_name = poItem.product_rm_name;
        let product_material_id = poItem.product_rm_id;
        let product_rm_quantity = poItem.product_rm_quantity;
        let approve_qty = poItem.product_rm_approved_quantity;
        let unit_name = poItem.product_rm_stock_unit_name;
        let unit_id = poItem.product_rm_stock_unit_id;
        let std_weight = poItem.product_rm_std_weight;
        let so_srNo = poItem.so_sr_no;
        let indent_no = poItem.indent_no;
        let cust_ord_no = poItem.customer_order_no;
        let cust_ord_date = poItem.customer_order_Date;

        if (keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete') {
            let schCount = 1;
            setSchedules(true)
            setTimeout(() => {

                $('#productMatName').append(product_rm_name);
                $('#productMatName').attr('product_material_id', product_material_id);

                $('#productMatQty').attr('material_quantity', product_rm_quantity);
                if (keyForViewUpdate !== 'approve') {
                    $('#productMatQty').append(product_rm_quantity);
                } else {
                    $('#productMatQty').append(approve_qty);
                }
                $('#productMatStdWeight').append(std_weight);
                const avgWt = std_weight * product_rm_quantity;
                $('#productMatTotalWeight').append(avgWt.toFixed(2));
                $('#productMatUnit').append(unit_name);
                $('#productMatUnit').attr('product_material_unit_id', unit_id);
                $('#soSrNo').append(so_srNo);
                $('#soSrNo').attr('soSrNo', so_srNo);
                $('#soSrNo').attr('indent_no', indent_no);
                $('#soSrNo').attr('cust_ord_no', cust_ord_no);
                $('#soSrNo').attr('cust_ord_date', cust_ord_date);

                let updatedScheduleDataArray = JSON.parse(localStorage.getItem('purchaseOrdScheduleData'));
                console.log(updatedScheduleDataArray)
                let filteredSchedule = [];
                if (updatedScheduleDataArray !== null) {
                    filteredSchedule = updatedScheduleDataArray.filter(item => {
                        return item.product_material_id === product_material_id;
                    });
                }
                if (filteredSchedule.length !== 0) {
                    filteredSchedule.forEach(item => {
                        addScheduleTblRow(schCount);

                        $('#scheduleQty-' + schCount).val(item.product_material_po_schedule_quantity)
                        // // into the weight field store the value as it is of the quantity.(Beacause aerotech client don't need the weight.)
                        // $('#scheduleWt-' + schCount).val(item.product_material_po_schedule_weight)
                        $('#scheduleWt-' + schCount).val(item.product_material_po_schedule_quantity)

                        $('#scheduleDate-' + schCount).val(item.expected_schedule_date)
                        $('#mtRecptQty-' + schCount).val(item.product_material_receipt_quantity)
                        // // into the weight field store the value as it is of the quantity.(Beacause aerotech client don't need the weight.)
                        // $('#mtRecptWt-' + schCount).val(item.product_material_receipt_weight)
                        $('#mtRecptWt-' + schCount).val(item.product_material_receipt_quantity)

                        $('#mtRecptDate-' + schCount).val(item.product_material_receipt_date)
                        schCount += 1;
                        console.log(item);
                    });

                } else {
                    remainingSchduleQty = product_rm_quantity;
                    addScheduleTblRow(schCount);
                    schCount += 1;
                }

                setSchedulerowcount(schCount)

            }, 300)
        }
    }
    //add schedule row
    const addScheduleTblRow = (rowCount) => {

        if (rowCount !== 1) {
            let newRow = $(`<tr role="row" scheduleTrCount="${rowCount}" id="RMSchedulesEntry-tr-${rowCount}"></tr>`);
            let secondLastRow = $("#scheduleTable tr").eq(-1);
            newRow.insertBefore(secondLastRow);
            $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append('<span class="RemoveTrBtn"  id="RemoveTrBtn-' + rowCount + '" name=' + rowCount + ' value=' + rowCount + '><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="erp_trRemove_icon"  height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-miterlimit="10" stroke-width="32" d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"></path><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M336 256H176"></path></svg></span>'))
        }
        const materialStdWt = parseFloat($('#productMatStdWeight').text().trim());
        const averageWt = materialStdWt * remainingSchduleQty;

        const createTd1 = $('<input type="number" rowIndex="' + rowCount + '" class="scheduleQty" id="scheduleQty-' + rowCount + '" max="' + remainingSchduleQty + '" value="' + remainingSchduleQty + '"/>').addClass("erp_input_field mb-0")
        const createTd2 = $('<input type="text" rowIndex="' + rowCount + '" rowIndex="' + rowCount + '" id="scheduleWt-' + rowCount + '" value="' + averageWt.toFixed(2) + '" maxLength="50"/>').addClass("erp_input_field mb-0 d-none")
        const createTd3 = $('<input type="date" rowIndex="' + rowCount + '" id="scheduleDate-' + rowCount + '" value="' + txt_schedule_date + '" />').addClass("erp_input_field mb-0")
        const createTd4 = $('<input type="number" rowIndex="' + rowCount + '" id="mtRecptQty-' + rowCount + '" maxLength="50"/>').addClass("erp_input_field mb-0")
        const createTd5 = $('<input type="text" rowIndex="' + rowCount + '" id="mtRecptWt-' + rowCount + '" maxLength="50" optional="optional"/>').addClass("erp_input_field mb-0 d-none")
        const createTd6 = $('<input type="date" rowIndex="' + rowCount + '" id="mtRecptDate-' + rowCount + '"/>').addClass("erp_input_field mb-0")
        const createTd7 = $(`<select rowIndex='${rowCount}' id='scheduleStatus-${rowCount}' disabled='disabled' class="form-select form-select-sm">
                                <option value="P">Pending</option> <option value="A">Approved</option>
                                <option value="R">Rejected</option> <option value="I">Partial receipt</option>
                                <option value="C">Completed</option>  <option value="X">Canceled</option>
                            </select>`);
        const createTd8 = $('<textarea type="text" rowIndex="' + rowCount + '" rowIndex="' + rowCount + '" id="schedulrRemark-' + rowCount + '" maxLength="255" ></textarea>').addClass("erp_input_field_table_txt mb-0")

        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd1));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td class=' d-none'>").append(createTd2));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd3));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd4));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td class=' d-none'>").append(createTd5));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd6));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd7));
        $('#RMSchedulesEntry-tr-' + rowCount).append($("<td>").append(createTd8));


        const datePicker = document.getElementById('scheduleDate-' + rowCount);
        datePicker.min = today();
        const datePicker2 = document.getElementById('mtRecptDate-' + rowCount);
        datePicker2.min = today();

        $('#scheduleTable tbody').on('click', '.RemoveTrBtn', function () {
            if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
                var $tr = $(this).closest('tr');
                $tr.remove();
            }
        });
        // scheduleQty
        $('.scheduleQty').on('input', function (e) {
            if (validatePoDetails(e, 'FloatInput')) {
                $(this).val(parseInt($(this).val(), 10));
                let rowIndex = $(this).attr("rowIndex");
                // const materialStdWt = parseFloat($('#productMatStdWeight').text().trim());
                // const averageWt = materialStdWt * parseFloat($(this).val().trim());
                // $(`#scheduleWt-${rowIndex}`).val(averageWt.toFixed(2));

                // // into the weight field store the value as it is of the quantity.(Beacause aerotech client don't need the weight.)
                $(`#scheduleWt-${rowIndex}`).val(parseFloat($(this).val().trim()).toFixed(4));

            } else {
                $(this).focus();
            }
        });

    }

    const addNewScheduleRow = () => {
        setSchedulerowcount(schedulerowcount + 1)
        addScheduleTblRow(schedulerowcount + 1);
    }

    function schedule() {

        let checkIsValidated = validateSchedules();

        if (checkIsValidated === true) {
            const table = document.getElementById('scheduleTable');
            const tbody = table.querySelector('tbody');
            const trElements = tbody.querySelectorAll('tr');


            let updatedpurchaseOrdScheduleData = [...scheduleDataArray];
            let soSrNo = $('#soSrNo').attr('soSrNo');
            let materialId = $('#productMatName').attr('product_material_id');
            let indent_no = $('#soSrNo').attr('indent_no');
            let cust_ord_no = $('#soSrNo').attr('cust_ord_no');
            let cust_ord_date = $('#soSrNo').attr('cust_ord_date');


            updatedpurchaseOrdScheduleData = updatedpurchaseOrdScheduleData.filter(item => {
                return item.product_material_id !== materialId;
            });
            for (let count = 0; count < trElements.length; count++) {

                let scheduleTrCount = trElements[count].getAttribute('scheduleTrCount');
                const product_material_id = $('#productMatName').attr('product_material_id');

                const pm_recpt_qty = $('#mtRecptQty-' + scheduleTrCount).val();
                const pm_recpt_wgt = validateNumberDateInput.current.decimalNumber($('#mtRecptWt-' + scheduleTrCount).val(), 4);
                const pm_schedule_ststus = $('#scheduleStatus-' + scheduleTrCount).val();
                const pm_schedule_ststus_desc = $(`#scheduleStatus-${scheduleTrCount} option:selected`).text();
                const pm_schedule_rmk = $('#schedulrRemark-' + scheduleTrCount).val();
                const scheduleData = {
                    id: isNaN(indent_no) || indent_no === '' ? materialId + ':' + parseInt(soSrNo) + ":" + count : indent_no + ':' + materialId + ':' + parseInt(soSrNo) + ":" + count,
                    product_material_name: $('#productMatName').text(),
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    purchase_order_master_transaction_id: po_master_transaction_id,
                    so_sr_no: isNaN(parseInt(soSrNo)) ? 0 : parseInt(soSrNo),
                    indent_no: indent_no,
                    purchase_order_no: txt_PO_no,
                    purchase_order_date: txt_po_date,
                    purchase_order_version: txt_po_version,
                    customer_order_no: cust_ord_no,
                    customer_order_Date: cust_ord_date,
                    // product_type_id: $('#cmb_product_type_id').val(),
                    // product_type: $('#cmb_product_type_id').find(":selected").text(),
                    product_material_id: product_material_id,
                    product_material_unit_id: parseInt($('#productMatUnit').attr('product_material_unit_id')),
                    material_po_quantity: $('#productMatQty').text(),

                    // // into the weight field store the value as it is of the quantity.(Beacause aerotech client don't need the weight.)
                    // material_po_weight: $('#productMatTotalWeight').text(),
                    material_po_weight: $('#productMatQty').text(),

                    product_material_po_schedule_quantity: $('#scheduleQty-' + scheduleTrCount).val(),
                    // // into the weight field store the value as it is of the quantity.(Beacause aerotech client don't need the weight.)
                    // product_material_po_schedule_weight: validateNumberDateInput.current.decimalNumber($('#scheduleWt-' + scheduleTrCount).val(), 4),
                    product_material_po_schedule_weight: validateNumberDateInput.current.decimalNumber($('#scheduleQty-' + scheduleTrCount).val(), 4),

                    expected_schedule_date: $('#scheduleDate-' + scheduleTrCount).val(),
                    product_material_receipt_quantity: pm_recpt_qty === '' ? 0 : pm_recpt_qty,

                    // // into the weight field store the value as it is of the quantity.(Beacause aerotech client don't need the weight.)
                    // product_material_receipt_weight: pm_recpt_wgt === '' ? 0 : pm_recpt_wgt,
                    product_material_receipt_weight: pm_recpt_qty === '' ? 0 : pm_recpt_qty,

                    material_receipt_date: $('#mtRecptDate-' + scheduleTrCount).val(),
                    purchase_order_schedules_item_status: pm_schedule_ststus,
                    purchase_order_schedules_item_status_desc: pm_schedule_ststus_desc,
                    remark: pm_schedule_rmk,
                    created_by: UserName,
                    modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''

                }
                // Add the new data to the updatedScheduleDataArray
                updatedpurchaseOrdScheduleData.push(scheduleData);
            }
            // Update the state with the updated array
            setScheduleDataArray(updatedpurchaseOrdScheduleData);
            localStorage.setItem('purchaseOrdScheduleData', JSON.stringify(updatedpurchaseOrdScheduleData));
            setSchedules(false)
        }
        console.log(checkIsValidated)
    }

    const deleteSchedules = (idToRemove) => {
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            let scheduleData = JSON.parse(localStorage.getItem('purchaseOrdScheduleData')) === null ? [] : JSON.parse(localStorage.getItem('purchaseOrdScheduleData'));

            const filteredScheduleData = scheduleData.filter(item => item.id !== idToRemove);
            setScheduleDataArray(filteredScheduleData);
            localStorage.setItem('purchaseOrdScheduleData', JSON.stringify(filteredScheduleData));
        }
    }

    const validateSchedules = () => {
        let result = true;
        const table = document.getElementById('scheduleTable');
        const tbody = table.querySelector('tbody');
        const trElements = tbody.querySelectorAll('tr');
        let addedQty = 0;

        for (let count = 0; count < trElements.length; count++) {
            let scheduleTrCount = trElements[count].getAttribute('scheduleTrCount');

            let scheduleQty = $('#scheduleQty-' + scheduleTrCount).val();
            let scheduleQtyinp = document.querySelector('#scheduleQty-' + scheduleTrCount);
            addedQty += parseInt(scheduleQty);
            if (scheduleQty === '' || (parseInt(scheduleQty) === 0 || parseInt(scheduleQty) < 0)) {
                scheduleQtyinp.parentElement.dataset.tip = 'Please enter valid quantity!...';
                return false;
            } else {
                delete scheduleQtyinp.parentElement.dataset.tip;
            }

            const scheduleDate = $('#scheduleDate-' + scheduleTrCount).val();
            const scheduleDateinp = document.querySelector('#scheduleDate-' + scheduleTrCount);

            if (scheduleDate === '') {
                scheduleDateinp.parentElement.dataset.tip = 'Please enter schedule date!...';
                return false;
            } else {
                delete scheduleDateinp.parentElement.dataset.tip;
            }
        }

        if (addedQty > parseInt($('#productMatQty').text())) {
            setErrMsg('Schedule quantity not more than Total Quantity ' + parseInt($('#productMatQty').text()) + ' ...!')
            setShowErrorMsgModal(true)
            return false;
        } else if (addedQty < parseInt($('#productMatQty').text())) {
            setErrMsg('Schedule quantity not less than Total Quantity ' + parseInt($('#productMatQty').text()) + ' ...!')
            setShowErrorMsgModal(true)
            return false;
        }
        return result;
    }

    //--------------------------Schedule section End---------------------------------------------------------------------------------------
    //------------------------ Taxation section Start------------------------------------------------------------------------------

    // Function to normalize hsnCode values for grouping
    function normalizeHsnCode(hsnCode) {

        return hsnCode.replace(/\s+/g, '').toUpperCase();
    }
    // Fn for grouped taxation table
    const FnGetTaxationSummary = () => {

        let taxSummaryData = JSON.parse(localStorage.getItem('taxationSummary'));
        if (taxSummaryData !== null) {

            // Use reduce() to group objects by normalized hsnCode
            const groupedData = taxSummaryData.reduce((acc, item) => {
                const hsnCode = normalizeHsnCode(item.hsnCode);
                if (!acc[hsnCode]) { acc[hsnCode] = []; }
                acc[hsnCode].push(item);
                return acc;
            }, {});
            let taxationTblData = [];
            if (Object.keys(groupedData).length > 0) {
                const hsnCodesGrps = Object.keys(groupedData);

                for (let hsnType = 0; hsnType < hsnCodesGrps.length; hsnType++) {
                    let particularHSNGrpList = groupedData[hsnCodesGrps[hsnType]]
                    const groupTotalIGSTAmt = particularHSNGrpList.reduce((total, item) => total + parseFloat(item.IGSTAmt), 0);
                    const groupTotalSGSTAmt = particularHSNGrpList.reduce((total, item) => total + parseFloat(item.SGSTAmt), 0);
                    const groupTotalCGSTAmt = particularHSNGrpList.reduce((total, item) => total + parseFloat(item.CGSTAmt), 0);
                    const groupTotalAmt = particularHSNGrpList.reduce((total, item) => total + parseFloat(item.totalAmt), 0);
                    const groupTaxableAmt = particularHSNGrpList.reduce((total, item) => total + parseFloat(item.taxableAmt), 0);
                    let groupData = {
                        srNo: hsnType + 1,
                        hsn_sac_code: particularHSNGrpList[0].hsnCode,
                        hsn_code_id: particularHSNGrpList[0].hsn_code_id,
                        summary_taxable_amount: groupTaxableAmt,
                        summary_cgst_percent: particularHSNGrpList[0].CGSTPercent,
                        summary_cgst_total: groupTotalCGSTAmt,
                        summary_sgst_percent: particularHSNGrpList[0].SGSTPercent,
                        summary_sgst_total: groupTotalSGSTAmt,
                        summary_igst_percent: particularHSNGrpList[0].IGSTPercent,
                        summary_igst_total: groupTotalIGSTAmt,
                        summary_total_amount: groupTotalAmt,
                    }
                    taxationTblData.push(groupData);
                }
                // console.log('taxationTblData: ' + JSON.stringify(taxationTblData, null, 2));
            }
            setTaxationSummaryData(taxationTblData)
            sessionStorage.setItem('taxationSummary', JSON.stringify(taxationTblData));
        } else {
            setTaxationSummaryData([])
            sessionStorage.setItem('taxationSummary', JSON.stringify([]));
            return null;
        }
    }
    // Fn render taxation table columns
    const renderPOTaxationSummaryTable = useMemo(() => {
        return <>
            <Table className="erp_table" id='po-taxationSummary-table' responsive bordered striped >
                <thead className="erp_table_head">
                    <tr>
                        {/* text-center */}
                        <th className="erp_table_th ">Sr. No</th>
                        <th className="erp_table_th">HSN SAC Code</th>
                        <th className="erp_table_th">Taxable Amount</th>
                        <th className="erp_table_th">CGST Percent</th>
                        <th className="erp_table_th">CGST Total</th>
                        <th className="erp_table_th">SGST Percent</th>
                        <th className="erp_table_th">SGST Total</th>
                        <th className="erp_table_th">IGST Percent</th>
                        <th className="erp_table_th">IGST Total</th>
                    </tr>
                </thead>
                <tbody>
                    {taxationSummaryData.map((taxItem, Index) =>
                        <tr rowIndex={Index}>
                            <td className="erp_table_td text-end">{Index + 1}</td>
                            <td className="erp_table_td ">{taxItem.hsn_sac_code}</td>
                            <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(JSON.stringify(taxItem.summary_taxable_amount), 4)}</td>
                            <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(JSON.stringify(taxItem.summary_cgst_percent), 2)}</td>
                            <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(JSON.stringify(taxItem.summary_cgst_total), 4)} </td>
                            <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(JSON.stringify(taxItem.summary_sgst_percent), 2)}</td>
                            <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(JSON.stringify(taxItem.summary_sgst_total), 4)}</td>
                            <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(JSON.stringify(taxItem.summary_igst_percent), 2)}</td>
                            <td className="erp_table_td text-end">{validateNumberDateInput.current.decimalNumber(JSON.stringify(taxItem.summary_igst_total), 4)}</td>
                        </tr>
                    )
                    }
                </tbody>
            </Table>
        </>
    }, [taxationSummaryData]);
    //------------------------------------------- Taxation section End-----------------------------------------------------------------
    //------------------------------------------- PO Terms section Start-----------------------------------------------------------
    const FnShowPOTermsRecords = async () => {
        try {
            const MSTName = encodeURIComponent(commonTermMstName);
            const poTermsApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/CmCommonParameters/FnShowAllActiveRecords/${MSTName}/${COMPANY_ID}`)
            const responce = await poTermsApiCall.json();
            if (responce.content.length !== 0) {
                const updatedContent = responce.content.map((commonTerm) => ({
                    ...commonTerm, common_parameters_expected_value: commonTerm.common_parameters_value,
                }))
                    .sort((a, b) => a.common_parameters_id - b.common_parameters_id);
                responce.content = updatedContent;

                const existingCommonData = existingCommonTermsData;
                let renderedCommonTermsData = responce.content;
                for (let existCommonCounter = 0; existCommonCounter < existingCommonData.length; existCommonCounter++) {
                    const existingCommonTermId = existingCommonData[existCommonCounter].purchase_order_terms_parameters_id;
                    const renderedCommonTerm = renderedCommonTermsData.map((pmtTerm, index) => ({
                        index, item: pmtTerm,
                    })).find(data => data.item.common_parameters_id === existingCommonTermId);
                    if (renderedCommonTerm) {
                        const existingIndex = renderedCommonTerm.index;
                        // Move the found object to the first index
                        const movedItem = renderedCommonTermsData.splice(existingIndex, 1)[0];
                        renderedCommonTermsData.unshift(movedItem);
                        renderedCommonTermsData[0].common_parameters_expected_value = existingCommonData[existCommonCounter].purchase_order_terms_parameters_expected_value;
                    }
                }
                localStorage.setItem('purchaseOrdCommonTermsData', JSON.stringify(renderedCommonTermsData));
                setPOTermsData(renderedCommonTermsData);
                setTimeout(() => {
                    existingCommonData.forEach(function (existingCommonTerm) {  // mark the checked checkboxes for order common term.
                        $('#selectPOTerm_' + existingCommonTerm.purchase_order_terms_parameters_id).prop('checked', true);
                    });
                }, 200);
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    //table for common terms
    const renderPOTermsTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='po-commonTerms-table' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Action</th>
                        <th className="erp_table_th">Common Parameters Master </th>
                        <th className="erp_table_th">Common Parameters </th>
                        <th className="erp_table_th">Common Parameters Value</th>
                        <th className="erp_table_th">Common Prameters Expected Value</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        POTermsdata.map((poTerms, Index) =>
                            <tr rowIndex={Index}>

                                <td className="erp_table_td">
                                    <input type="checkbox" className="selectPOTerm" name="selectPOTerm" value={poTerms.common_parameters_id} id={'selectPOTerm_' + poTerms.common_parameters_id} />
                                </td>
                                <td className="erp_table_td">{poTerms.common_parameters_master_name}</td>
                                <td className="erp_table_td">{poTerms.common_parameters_name}</td>
                                <td className="erp_table_td">{poTerms.common_parameters_value}</td>
                                <td className="erp_table_td">
                                    <input type="text" id={`po-terms-expected-Value-${poTerms.common_parameters_id}`}
                                        className="erp_input_field mb-0"
                                        value={poTerms.common_parameters_expected_value}
                                        onChange={(e) => { updatePOOrdCommonTermsTblData(poTerms, e); }}
                                        disabled={isView}
                                        Headers='common_parameters_expected_value'
                                    />
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [POTermsdata, isView]);
    // Onchange event listener for the POTerm Selection
    $('.selectPOTerm').on('change', function () {
        toggleChkAllBoxes('PartiallyPOTermsSelection');
    });
    //Fn For update po_term editable value
    const updatePOOrdCommonTermsTblData = (currentRowData, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        const exptvalueId = document.getElementById(event.target.id);
        if (event.target.value === '') {
            exptvalueId.parentElement.dataset.tip = 'Please fill this field...!';
        } else {
            delete exptvalueId.parentElement.dataset.tip;
        }
        currentRowData[clickedColName] = event.target.value;            // Get the column name where event triggered.
        const poTerms_data = [...POTermsdata]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        poTerms_data[arrayIndex] = currentRowData
        setPOTermsData(poTerms_data);
    }
    //------------------------------------------- PO Terms section End -------------------------------------------------------------------

    //function to check All checkBoxes of po terms and payment terms
    function toggleChkAllBoxes(key) {
        switch (key) {

            case "selectAllPOTerms":
                $('.selectPOTerm').prop('checked', $('#selectAllPOTerms').is(":checked"));
                break;
            case 'PartiallyPOTermsSelection':
                $('#selectAllPOTerms').prop('checked', $('input:checkbox.selectPOTerm:checked').length == $('input:checkbox.selectPOTerm').length);
                break;
            case "selectAllPayTerms":
                $('.selectPayTerm').prop('checked', $('#selectAllPayTerms').is(":checked"));
                break;
            case 'PartiallyPayTermsSelection':
                $('#selectAllPayTerms').prop('checked', $('input:checkbox.selectPayTerm:checked').length == $('input:checkbox.selectPayTerm').length);
                break;
            // For customer contact
            case 'selectAllSuppContact':
                $('.selectSuppContactPerson').prop('checked', $('#selectAllSuppContact').is(":checked"));
                break;
            case 'PartiallySuppContactSelection':
                $('#selectAllSuppContact').prop('checked', $('input:checkbox.selectSuppContactPerson:checked').length === $('input:checkbox.selectSuppContactPerson').length);
                break;
        }
    }


    //---------------------------------- Payment Terms section Start -----------------------------------------------------------------------
    const FnShowPaymentTermsRecrds = async () => {
        try {
            const pyTermsApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/paymentterms/FnShowAllActiveRecords`)
            const responce = await pyTermsApiCall.json();
            if (responce.data.content.length !== 0 && responce.data.content.length > 0) {

                const updatedContent = responce.data.content.sort((a, b) => a.payment_terms_id - b.payment_terms_id);
                let existingPayTermsData = existingPaymentTermsData;
                // if (existingPayTermsData.length > 0) {
                let renderedPaymentTermsData = updatedContent;
                for (let existPaymentCounter = 0; existPaymentCounter < existingPayTermsData.length; existPaymentCounter++) {
                    const existingPaymentTermId = existingPayTermsData[existPaymentCounter].payment_terms_id;
                    const renderedPaymentTerm = renderedPaymentTermsData.map((pmtTerm, index) => ({
                        index, item: pmtTerm,
                    })).find(data => data.item.payment_terms_id === existingPaymentTermId);
                    if (renderedPaymentTerm) {
                        const existingIndex = renderedPaymentTerm.index;
                        // Move the found object to the first index
                        const movedItem = renderedPaymentTermsData.splice(existingIndex, 1)[0];
                        renderedPaymentTermsData.unshift(movedItem);
                        renderedPaymentTermsData[0].payment_terms_Milestome = existingPayTermsData[existPaymentCounter].payment_terms_Milestome;
                        renderedPaymentTermsData[0].payment_percent = existingPayTermsData[existPaymentCounter].payment_percent;
                        renderedPaymentTermsData[0].payment_expected_value = existingPayTermsData[existPaymentCounter].payment_expected_value;
                        renderedPaymentTermsData[0].payment_expected_date = existingPayTermsData[existPaymentCounter].payment_expected_date;
                        renderedPaymentTermsData[0].payment_paid_transaction_id = existingPayTermsData[existPaymentCounter].payment_paid_transaction_id;
                        renderedPaymentTermsData[0].payment_paid_flag = existingPayTermsData[existPaymentCounter].payment_paid_flag;
                        renderedPaymentTermsData[0].payment_paid_date = existingPayTermsData[existPaymentCounter].payment_paid_date;
                    }
                    // Update Data if order is copied then set default data.
                    if (keyForViewUpdate === 'copy') {
                        renderedPaymentTermsData[0].payment_paid_transaction_id = '';
                        renderedPaymentTermsData[0].payment_paid_flag = 'P';
                        renderedPaymentTermsData[0].payment_paid_date = '';
                        renderedPaymentTermsData[0].payment_expected_date = defaultExpecScheduleDate
                    }
                }
                localStorage.setItem('purchaseOrdPaymentTermsData', JSON.stringify(renderedPaymentTermsData));
                setPaymentTermsData(renderedPaymentTermsData);
                setTimeout(() => {
                    existingPaymentTermsData.forEach(function (existingPayTerm) {
                        $('#selectPayTerm_' + existingPayTerm.payment_terms_id).prop('checked', true);
                    });
                }, 200);
                // }
            } else {
                setPaymentTermsData([]);
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
        // Onchange event listener for the POTerm Selection
        $('.selectPayTerm').on('change', function (e) {

            // if it is checked then focus the.
            let paymentTermId = $(this).attr("pmtTermId");
            if (this.checked) {
                if ($(`#payment_milestone_${paymentTermId}`).val() === '') {
                    $(`#payment_milestone_${paymentTermId}`).focus();

                } else if ($(`#payment_percent_${paymentTermId}`).val() === '') {
                    $(`#payment_percent_${paymentTermId}`).focus();

                } else if ($(`#payment_expected_date_${paymentTermId}`).val() === '') {
                    $(`#payment_expected_date_${paymentTermId}`).focus();

                }
                else if ($(`#payment_expect_val_${paymentTermId}`).val() === '') {
                    $(`#payment_expect_val_${paymentTermId}`).focus();
                }
            } else {
                let mileStoneField = document.querySelector('#payment_milestone_' + paymentTermId);
                let percentageField = document.querySelector('#payment_percent_' + paymentTermId);
                let dateField = document.querySelector('#payment_expected_date_' + paymentTermId);
                let paymentExptValField = document.querySelector('#payment_expect_val_' + paymentTermId);

                delete mileStoneField.parentElement.dataset.tip;
                delete percentageField.parentElement.dataset.tip;
                delete dateField.parentElement.dataset.tip;
                delete paymentExptValField.parentElement.dataset.tip;
            }
            toggleChkAllBoxes('PartiallyPayTermsSelection');
        });

    }
    //Fn for render payment terms static table 
    const renderPaymentTermsTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='po-paymentTerms-table' responsive bordered striped >
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Action</th>
                        <th className="erp_table_th">Term Names</th>
                        <th className="erp_table_th">Days </th>
                        <th className="erp_table_th">Grace Days</th>
                        <th className="erp_table_th">Milestome</th>
                        <th className="erp_table_th">Payment Percent</th>
                        <th className="erp_table_th">Expected Value</th>
                        <th className="erp_table_th">Expected Date</th>
                        <th className="erp_table_th">Payment Paid</th>
                        <th className="erp_table_th">Payment Paid Transaction Id</th>
                        <th className="erp_table_th">Payment Paid Date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        paymentTermsData.map((payTermItem, Index) =>
                            <tr payTermItemIndex={Index}>

                                <td className="erp_table_td">
                                    <input type="checkbox" name="selectPayTerm" className="selectPayTerm" pmtTermId={payTermItem.payment_terms_id} value={payTermItem.payment_terms_id} id={'selectPayTerm_' + payTermItem.payment_terms_id}
                                        onChange={(e) => { setDefaultPaymentScheduleDateForPmtTerm(e, payTermItem) }} />
                                </td>
                                <td className="erp_table_td">{payTermItem.payment_terms_name}</td>
                                <td className="erp_table_td text-end">{payTermItem.payment_terms_days}</td>
                                <td className="erp_table_td text-end">{payTermItem.payment_terms_grace_days}</td>
                                <td className="erp_table_td">
                                    <input type="text" className="erp_input_field mb-0" value={payTermItem.payment_terms_Milestome} pmtTermId={payTermItem.payment_terms_id} id={"payment_milestone_" + payTermItem.payment_terms_id} maxlength='255' onChange={(e) => { updatePmtTermsTblData(payTermItem, e); }}
                                        onBlur={(e) => { updatePmtTermsTblData(payTermItem, e); }}
                                        Headers='payment_terms_Milestome' />
                                </td>
                                <td className="erp_table_td">
                                    <input type="text" className="erp_input_field mb-0 text-end" value={payTermItem.payment_percent} pmtTermId={payTermItem.payment_terms_id} id={"payment_percent_" + payTermItem.payment_terms_id} onChange={(e) => { updatePmtTermsTblData(payTermItem, e); }}
                                        onBlur={(e) => { updatePmtTermsTblData(payTermItem, e); }}
                                        Headers='payment_percent' />
                                </td>
                                <td className="erp_table_td">
                                    <input type="text" className="erp_input_field mb-0 text-end" value={payTermItem.payment_expected_value} pmtTermId={payTermItem.payment_terms_id} id={"payment_expect_val_" + payTermItem.payment_terms_id} onChange={(e) => { updatePmtTermsTblData(payTermItem, e); }}
                                        Headers='payment_expected_value' />
                                </td>
                                <td className="erp_table_td">
                                    <input type="date" className="erp_input_field mb-0" value={payTermItem.payment_expected_date} pmtTermId={payTermItem.payment_terms_id} id={"payment_expected_date_" + payTermItem.payment_terms_id} onChange={(e) => { updatePmtTermsTblData(payTermItem, e); }}
                                        onBlur={(e) => { updatePmtTermsTblData(payTermItem, e); }} min={currentDate}
                                        Headers='payment_expected_date' />
                                </td>
                                <td className="erp_table_td ">
                                    <select id={`payment_paid_flag_${payTermItem.payment_terms_id}`} value={payTermItem.payment_paid_flag} className="form-select form-select-sm "
                                        Headers='payment_paid_flag' disabled onChange={(e) => { updatePmtTermsTblData(payTermItem, e); }} >
                                        <option value="P">Pending</option>
                                        <option value="T">Partail</option>
                                        <option value="R">Receipt</option>
                                    </select>
                                </td>
                                <td className="erp_table_td">
                                    <input type="text" className="erp_input_field mb-0 text-end" value={payTermItem.payment_paid_transaction_id} pmtTermId={payTermItem.payment_terms_id} id={"payment_paid_transaction_id_" + payTermItem.payment_terms_id}
                                        Headers='payment_paid_transaction_id' disabled />
                                </td>
                                <td className="erp_table_td">
                                    <input type="date" className="erp_input_field mb-0" value={payTermItem.payment_paid_date} pmtTermId={payTermItem.payment_terms_id} id={"payment_paid_date_" + payTermItem.payment_terms_id}
                                        Headers='payment_paid_date' disabled />
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [paymentTermsData, isView]);
    const updatePmtTermsTblData = (currentRowData, event) => {

        const ischecked = event.target.parentElement.parentElement.firstChild.children.selectPayTerm.checked;
        if (ischecked) {
            let clickedColName = event.target.getAttribute('Headers');// Get the column name where event triggered.
            let focusedInputField = document.getElementById(event.target.id);
            if (event.target.value !== '') {
                if (!clickedColName.includes('_percent')) {
                    currentRowData[clickedColName] = event.target.value;
                    delete focusedInputField.parentElement.dataset.tip;
                } else if (clickedColName.includes('_percent')) {
                    if (validateNumberDateInput.current.percentValidate(event.target.value)) {
                        currentRowData[clickedColName] = event.target.value;
                        delete focusedInputField.parentElement.dataset.tip;
                    }
                } else {
                    currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
                    delete focusedInputField.parentElement.dataset.tip;
                }

            } else {
                if (!clickedColName.includes('payment_expected_value')) {
                    focusedInputField.parentElement.dataset.tip = 'Please fill this field...!';
                }
                currentRowData[clickedColName] = '';
            }
            const payTerms_data = [...paymentTermsData]
            const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
            payTerms_data[arrayIndex] = currentRowData
            setPaymentTermsData(payTerms_data);
        }
    }

    // Function To set the default payment schedule date.
    const setDefaultPaymentScheduleDateForPmtTerm = (event, currentPaymentTerm) => {
        if (event.target.checked) {
            let defaultDate = FnGetExpectedScheduleGraceDate(parseInt(currentPaymentTerm.payment_terms_days));
            setPaymentTermsData(prevItems => {
                return prevItems.map((pmtTerm) => {
                    if (pmtTerm.payment_terms_id === currentPaymentTerm.payment_terms_id) {
                        pmtTerm.payment_expected_date = defaultDate;
                    }
                    return pmtTerm;
                });
            });
        }
    }

    //fn to validate payment terms inputs
    const validatePmtTerms = (event, inputType) => {
        let pmtTermId = event.target.getAttribute('pmttermid');
        if ($(`#selectPayTerm_${pmtTermId}`).is(":checked")) {

            const floatNumReg = /^\d{1,18}(?:\.\d{0,4})?$/;
            let isValidInput = false;
            let errorMsg = "";
            switch (inputType) {
                case "TextInput":
                    if (event.target.value === '' || event.target.value === undefined || event.target.value === null) {
                        errorMsg = 'Please fill this field...!'
                        isValidInput = false
                    } else {
                        isValidInput = true
                    }
                    break;
                case "PercentageInput":
                    if (event.target.value === '' || event.target.value === undefined) {
                        errorMsg = 'Please fill this field...!'
                        isValidInput = false
                    } else if (event.target.value > '100') {
                        errorMsg = 'Percentage should not be greater than 100%...!'
                        isValidInput = false
                    } else {
                        isValidInput = true
                    }
                    break;
                case "DateInput":
                    if (event.target.value === '' || event.target.value === undefined || event.target.value === null) {
                        errorMsg = 'Please select the date...!'
                        isValidInput = false
                    } else {
                        isValidInput = true
                    }
                    break;
                default:
                    isValidInput = true;
                    break;
            }

            let focusedInputField = document.querySelector('#' + event.target.id);
            if (!isValidInput) {
                focusedInputField.parentElement.dataset.tip = errorMsg;
                return false;
            } else {
                delete focusedInputField.parentElement.dataset.tip;
                return true;
            }
        } else {

            let mileStoneField = document.querySelector('#payment_milestone_' + pmtTermId);
            let percentageField = document.querySelector('#payment_percent_' + pmtTermId);
            let dateField = document.querySelector('#payment_expected_date_' + pmtTermId);


            delete mileStoneField.parentElement.dataset.tip;
            delete percentageField.parentElement.dataset.tip;
            delete dateField.parentElement.dataset.tip;

            return true;
        }
    }
    //----------------------------------------  Payment Terms Section End ----------------------------------------------------------

    const handleFooterDiscountPercentChange = async (e) => {
        const percent = parseFloat(e.target.value) || 0;
        const amount = (percent / 100) * txt_basic_total;
        setPODiscountAmount(validateNumberDateInput.current.decimalNumber(JSON.stringify(amount), 4));
        setPODiscountPercent(percent);
        setTimeout(() => {
            calculateGrandTotal();
            reverseCalculationForDetailData();
        }, 200);
    };

    const handleFooterDiscountAmountChange = async (e) => {

        const amount = parseFloat(e.target.value) || 0;
        const percent = (amount * 100) / txt_basic_total;
        setPODiscountPercent(validateNumberDateInput.current.decimalNumber(JSON.stringify(percent), 2));
        setPODiscountAmount(amount);

        setTimeout(() => {
            calculateGrandTotal();
            reverseCalculationForDetailData();
        }, 200);

    };
    // Fn for sum of discount percent and amount on material chabge
    const FnCalculateDiscSum = () => {

        let poDetailsData = JSON.parse(localStorage.getItem('filteredMaterialData'));
        let material_basic_totals = parseFloat(poDetailsData.reduce((total, item) => total + parseFloat(item.material_basic_amount), 0));
        let material_discount_amount = parseFloat(poDetailsData.reduce((total, item) => total + parseFloat(item.material_discount_amount), 0));
        let material_discount_percent = (material_discount_amount * 100) / material_basic_totals;
        setPODiscountPercent(validateNumberDateInput.current.decimalNumber((material_discount_percent).toString(), 2));
        setPODiscountAmount(validateNumberDateInput.current.decimalNumber((material_discount_amount).toString(), 4));
    }
    // Fn for calculate footer data entry nd Grand total
    const calculateGrandTotal = () => {

        let taxSummaryData = JSON.parse(localStorage.getItem('taxationSummary'));
        let poDetailsData = JSON.parse(localStorage.getItem('filteredMaterialData'));
        let supplier_state_id = parseInt($('#cmb_supplier_state_id').val());
        let exp_branch_state_id = parseInt($('#cmb_expected_branch_state_id').val());
        let is_freight_taxable = document.getElementById('chk_is_freight_taxable').checked;
        let freightTaxHSNIsSelected = $('#cmb_freight_hsn_id').val();

        if (is_freight_taxable && freightTaxHSNIsSelected === '') {
            $('#error_cmb_freight_hsn_id').text('Please select the freight tax hsn code.');
            $('#error_cmb_freight_hsn_id').show();
            return false;
        } else if (!is_freight_taxable) {
            $('#error_cmb_freight_hsn_id').hide();
            setFreightHSNId('');
        } else if (is_freight_taxable && freightTaxHSNIsSelected !== '') {
            $('#error_cmb_freight_hsn_id').hide();
        }

        if (poDetailsData != null) {
            let basic_total = parseFloat(poDetailsData.reduce((total, item) => total + parseFloat(item.material_basic_amount), 0));

            // let taxableAmount = parseFloat(poDetailsData.reduce((total, item) => total + parseFloat(item.material_taxable_amount), 0));
            // setTaxableTotal(validateNumberDateInput.current.decimalNumber((taxableAmount).toString(), 4));
            let cgst_total = parseFloat(poDetailsData.reduce((total, item) => total + parseFloat(item.material_cgst_total), 0));
            let sgst_total = parseFloat(poDetailsData.reduce((total, item) => total + parseFloat(item.material_sgst_total), 0));
            let igst_total = parseFloat(poDetailsData.reduce((total, item) => total + parseFloat(item.material_igst_total), 0));
            let grandTotal = poDetailsData.reduce((total, item) => total + parseFloat(item.material_total_amount), 0);

            const discAmt = isNaN(parseFloat($('#txt_po_discount_amount').val())) === true ? 0 : parseFloat($('#txt_po_discount_amount').val())
            const txt_transport_amount = isNaN(parseFloat($('#txt_transportAmount').val())) === true ? 0 : parseFloat($('#txt_transportAmount').val())
            const freightAmt = isNaN(parseFloat($('#txt_freight_amount').val())) === true ? 0 : parseFloat($('#txt_freight_amount').val())
            const packingAmt = isNaN(parseFloat($('#txt_packing_amount').val())) === true ? 0 : parseFloat($('#txt_packing_amount').val())
            const txt_other_amount = isNaN(parseFloat($('#txt_otherAmount').val())) === true ? 0 : parseFloat($('#txt_otherAmount').val())

            const freightoptionselected = $('#cmb_freight_hsn_id').find('option:selected');
            const freight_HsnRate = isNaN(parseFloat(freightoptionselected.attr('hsn_rate'))) === true ? 0 : freightoptionselected.attr('hsn_rate');

            const taxableAmount = basic_total - discAmt;

            const Sezoptionselected = $('#cmb_expected_branch_id').find('option:selected');
            const is_sez = Sezoptionselected.attr('chk_issez');
            if (is_freight_taxable) {
                let taxSummary = {
                    materialId: 'Freight',
                    hsnCode: $('#cmb_freight_hsn_id option:selected').attr('lbl'),
                    hsn_code_id: $('#cmb_freight_hsn_id').val(),
                    hsnRate: $('#cmb_freight_hsn_id option:selected').attr('hsn_rate'),
                    taxableAmt: freightAmt, CGSTPercent: 0, CGSTAmt: 0,
                    SGSTPercent: 0, SGSTAmt: 0, IGSTPercent: 0, IGSTAmt: 0, totalAmt: 0
                };
                let freightTaxVal = (freightAmt * freight_HsnRate) / 100;
                if (supplier_state_id === '39') {
                    cgst_total += 0;
                    sgst_total += 0;
                    igst_total += 0;
                } else if (is_sez === 'true') {
                    cgst_total += 0;
                    sgst_total += 0;
                    igst_total += 0;
                } else if (supplier_state_id === exp_branch_state_id) {

                    // Set inter state tax totals
                    taxSummary['CGSTAmt'] = (freightTaxVal / 2);
                    taxSummary['CGSTPercent'] = (freight_HsnRate / 2);
                    taxSummary['SGSTAmt'] = (freightTaxVal / 2);
                    taxSummary['SGSTPercent'] = (freight_HsnRate / 2);
                    taxSummary['totalAmt'] = (freightAmt + freightTaxVal);

                    cgst_total += freightTaxVal / 2;
                    sgst_total += freightTaxVal / 2;
                } else {
                    // Set outer state tax totals
                    taxSummary['IGSTAmt'] = (freightTaxVal);
                    taxSummary['IGSTPercent'] = (freight_HsnRate);
                    taxSummary['totalAmt'] = (freightAmt + freightTaxVal);

                    igst_total += freightTaxVal;
                }
                if (taxSummaryData !== null) {
                    const existingFreightTaxationObj = taxSummaryData.findIndex((record) => record.materialId === "Freight");
                    if (existingFreightTaxationObj !== -1) {
                        taxSummaryData[existingFreightTaxationObj] = taxSummary;   // Replace the object in-place
                    } else {
                        taxSummaryData.push(taxSummary);                           // Push the new object if not found
                    }
                }
            } else {
                $('#cmb_freight_hsn_id').attr('optional', 'optional');
                // if it is exist in the taxation summary object then remove it
                if (taxSummaryData !== null) {
                    const existingFreightTaxationObj = taxSummaryData.findIndex((record) => record.materialId === "Freight");
                    // }
                    if (existingFreightTaxationObj !== -1) { taxSummaryData.splice(existingFreightTaxationObj, 1); }
                }
            }
            localStorage.setItem('taxationSummary', JSON.stringify(taxSummaryData));
            grandTotal = taxableAmount + txt_transport_amount + freightAmt + packingAmt + txt_other_amount + cgst_total + sgst_total + igst_total;

            setBasic_total(validateNumberDateInput.current.decimalNumber((basic_total).toString(), 4));
            setTaxableTotal(validateNumberDateInput.current.decimalNumber((taxableAmount).toString(), 4));
            setCgstTotal(validateNumberDateInput.current.decimalNumber((cgst_total).toString(), 4));
            setSgstTotal(validateNumberDateInput.current.decimalNumber((sgst_total).toString(), 4));
            setIgstTotal(validateNumberDateInput.current.decimalNumber((igst_total).toString(), 4));
            setGrandTotal(Math.round(grandTotal));
            let roundOffValue = (grandTotal - Math.floor(grandTotal));
            if (roundOffValue >= 0.50) {
                roundOffValue = (roundOffValue - 1); // Subtract 1 to get negative round-off
            }
            setRoundOff((roundOffValue).toFixed(2));
        } else {
            setBasic_total(0);
            setTaxableTotal(0);
            setCgstTotal(0);
            setSgstTotal(0);
            setIgstTotal(0);
            setGrandTotal(0);
            setRoundOff(0);
        }
        FnGetTaxationSummary();
    }

    const removeSessions = (reloaded) => {

        if (reloaded === 'Onload') {
            localStorage.removeItem(`filteredMaterialData`);
            localStorage.removeItem(`purchaseOrdScheduleData`);
            localStorage.removeItem(`taxationSummary`);
        }

        sessionStorage.removeItem(`comboFilterData`);
        sessionStorage.removeItem('isComboFilterExist');
        sessionStorage.removeItem('smv_product_type');
        sessionStorage.removeItem('smv_product_material_type');
        sessionStorage.removeItem('smv_product_material_grade');
        sessionStorage.removeItem('smv_product_material_shape');

        for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
            sessionStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
        }
        localStorage.removeItem('smv_product_material_type');
        localStorage.removeItem('smv_product_material_grade');
        localStorage.removeItem('smv_product_material_shape');
        localStorage.removeItem('smv_product_type');

        localStorage.removeItem('smv_product_material_typeName');
        localStorage.removeItem('smv_product_material_gradeName');
        localStorage.removeItem('smv_product_material_shapeName');
        localStorage.removeItem('smv_product_typeName');

    }
    // Fn to add , update & approve PO 
    const addPO = async (po_status) => {

        try {
            let povalidate = true;
            if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
                povalidate = await FnValidatePoOrder();
            }

            if (povalidate) {
                setIsLoading(true)
                let json = { 'TransHeaderFooterData': {}, 'TransDetailData': [], 'TransScheduleData': [], 'TransPOTermsData': [], 'TransPaymentTermsData': [], 'TransTaxSummaryTermsData': [], 'commonIds': {} }

                let supplierContactPersonsIds = await FnGetSupplierContactPersonIds();
                let purchase_order_no = txt_PO_no
                if (keyForViewUpdate !== 'update' && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
                    purchase_order_no = await GeneratePONo();
                }
                // TransHeaderData
                const po_form_data = {
                    purchase_order_master_transaction_id: isApprove || chk_po_status === 'Z' && keyForViewUpdate === 'view' ? po_master_transaction_id : 0,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    // purchase_order_type_id: cmb_product_type_id,
                    // purchase_order_type: $('#cmb_product_type_id').find(":selected").text(),
                    purchase_order_life: chek_po_life,
                    purchase_process_entry: chk_purchase_process,
                    purchase_order_creation_type: chk_po_creation_type,
                    purchase_order_no: purchase_order_no,
                    purchase_order_date: txt_po_date,
                    purchase_order_version: txt_po_version,
                    // customer_id: cmb_customerIds,
                    // customer_order_no: cmb_customer_order_no,
                    // customer_order_Date: customer_order_date,
                    supplier_id: cmb_supplier_id,
                    supplier_state_id: cmb_supplier_state_id,
                    supplier_city_id: cmb_supplier_city_id,
                    supplier_contacts_ids: supplierContactPersonsIds,
                    expected_branch_id: cmb_expected_branch_id,
                    expected_branch_state_id: cmb_expected_branch_state_id,
                    expected_branch_city_id: cmb_expected_branch_city_id,
                    approved_by_id: isApprove || chk_po_status === 'Z' && keyForViewUpdate === 'view' ? cmb_approved_by_id : '',
                    approved_date: isApprove || chk_po_status === 'Z' && keyForViewUpdate === 'view' ? txt_approved_date : '',
                    purchase_order_acceptance_status: chk_PO_acceptance_status,
                    purchase_order_mail_sent_status: chk_po_mail_sent_status,
                    purchase_order_status: po_status === 'R' ? po_status : chk_po_status,
                    status_remark: txt_status_remark,
                    remark: txt_remark,
                    is_active: chk_po_isactive,
                    expected_schedule_date: txt_schedule_date,

                    //footer data start
                    basic_total: txt_basic_total,
                    transport_amount: txt_transportAmount,
                    freight_amount: txt_freight_amount,
                    is_freight_taxable: chk_is_freight_taxable,
                    freight_hsn_code_id: cmb_freight_hsn_id,
                    packing_amount: txt_packing_amount,
                    po_discount_percent: txt_po_discount_percent,
                    po_discount_amount: txt_po_discount_amount,
                    other_amount: txt_otherAmount,
                    taxable_total: txt_taxableTotal,
                    cgst_total: txt_cgst_total,
                    sgst_total: txt_sgst_total,
                    igst_total: txt_igst_total,
                    roundoff: txt_round_off,
                    grand_total: txt_grand_amount,
                    agent_id: cmb_agentId,
                    agent_percent: txt_agentPercent,
                    agent_paid_status: chk_agentPaidStatus,
                    other_terms_conditions: txt_other_term_con,
                    created_by: UserName,
                    modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''
                }
                json.TransHeaderFooterData = po_form_data;
                // Trans PO DetailsData
                for (let poDetailIndex = 0; poDetailIndex < po_details_data.length; poDetailIndex++) {
                    const poDetailDataElement = po_details_data[poDetailIndex];

                    const detailRowProperty = {}
                    detailRowProperty['company_id'] = COMPANY_ID
                    detailRowProperty['company_branch_id'] = COMPANY_BRANCH_ID
                    detailRowProperty['financial_year'] = FINANCIAL_SHORT_NAME
                    detailRowProperty['purchase_order_type_id'] = poDetailDataElement.product_type_id
                    detailRowProperty['purchase_order_type'] = poDetailDataElement.product_type_name
                    detailRowProperty['purchase_order_no'] = purchase_order_no
                    detailRowProperty['purchase_order_date'] = txt_po_date
                    detailRowProperty['purchase_order_version'] = txt_po_version
                    detailRowProperty['indent_no'] = poDetailDataElement.indent_no === 0 ? '' : poDetailDataElement.indent_no
                    detailRowProperty['indent_date'] = poDetailDataElement.indent_date === 0 ? '' : poDetailDataElement.indent_date
                    detailRowProperty['indent_version'] = poDetailDataElement.indent_version === 0 ? '' : poDetailDataElement.indent_version
                    detailRowProperty['indented_by_id'] = poDetailDataElement.indented_by_id === 0 ? '' : poDetailDataElement.indented_by_id
                    detailRowProperty['product_material_id'] = poDetailDataElement.product_rm_id
                    detailRowProperty['product_material_print_name'] = poDetailDataElement.product_rm_name
                    detailRowProperty['product_material_tech_spect'] = poDetailDataElement.product_rm_tech_spect
                    detailRowProperty['lead_time'] = poDetailDataElement.lead_time
                    detailRowProperty['sr_no'] = poDetailIndex + 1
                    detailRowProperty['product_material_unit_id'] = poDetailDataElement.product_rm_stock_unit_id
                    detailRowProperty['product_material_packing_id'] = poDetailDataElement.product_rm_packing_id
                    detailRowProperty['product_material_hsn_code_id'] = poDetailDataElement.product_rm_hsn_sac_code_id
                    detailRowProperty['product_material_po_quantity'] = poDetailDataElement.product_rm_quantity
                    detailRowProperty['product_material_conversion_factor'] = poDetailDataElement.product_rm_conversion_factor
                    detailRowProperty['product_material_po_weight'] = poDetailDataElement.product_rm_weight
                    detailRowProperty['product_material_po_approved_quantity'] = poDetailDataElement.product_rm_approved_quantity
                    detailRowProperty['product_material_po_approved_weight'] = poDetailDataElement.product_rm_approved_weight
                    detailRowProperty['product_material_po_rejected_quantity'] = poDetailDataElement.product_rm_rejected_quantity
                    detailRowProperty['product_material_po_rejected_weight'] = poDetailDataElement.product_rm_rejected_weight
                    detailRowProperty['customer_id'] = poDetailDataElement.customer_id
                    detailRowProperty['customer_order_no'] = poDetailDataElement.customer_order_no
                    detailRowProperty['customer_order_Date'] = poDetailDataElement.customer_order_Date
                    detailRowProperty['pree_closed_quantity'] = poDetailDataElement.pree_closed_quantity
                    detailRowProperty['pree_closed_weight'] = poDetailDataElement.pree_closed_weight
                    detailRowProperty['purchase_return_quantity'] = poDetailDataElement.purchase_return_quantity
                    detailRowProperty['purchase_return_weight'] = poDetailDataElement.purchase_return_weight
                    detailRowProperty['material_rate'] = poDetailDataElement.product_rm_rate
                    detailRowProperty['material_basic_amount'] = poDetailDataElement.material_basic_amount
                    detailRowProperty['material_discount_percent'] = poDetailDataElement.material_discount_percent
                    detailRowProperty['material_discount_amount'] = poDetailDataElement.material_discount_amount
                    detailRowProperty['material_taxable_amount'] = poDetailDataElement.material_taxable_amount
                    detailRowProperty['material_cgst_percent'] = poDetailDataElement.material_cgst_percent
                    detailRowProperty['material_cgst_total'] = poDetailDataElement.material_cgst_total
                    detailRowProperty['material_sgst_percent'] = poDetailDataElement.material_sgst_percent
                    detailRowProperty['material_sgst_total'] = poDetailDataElement.material_sgst_total
                    detailRowProperty['material_igst_percent'] = poDetailDataElement.material_igst_percent
                    detailRowProperty['material_igst_total'] = poDetailDataElement.material_igst_total
                    detailRowProperty['material_total_amount'] = poDetailDataElement.material_total_amount
                    detailRowProperty['material_freight_amount'] = poDetailDataElement.material_freight_amount
                    detailRowProperty['material_schedule_date'] = poDetailDataElement.material_schedule_date
                    detailRowProperty['so_sr_no'] = poDetailDataElement.so_sr_no
                    detailRowProperty['purchase_order_item_status'] = po_status === 'R' ? po_status : poDetailDataElement.purchase_order_item_status
                    detailRowProperty['department_id'] = cmb_department
                    //display fields for only track
                    detailRowProperty['material_po_approval_remark'] = poDetailDataElement.material_po_approval_remark
                    detailRowProperty['approved_date'] = poDetailDataElement.approved_date
                    detailRowProperty['is_active'] = chk_po_isactive
                    detailRowProperty['approved_by_id'] = cmb_approved_by_id
                    detailRowProperty['created_by'] = UserName
                    detailRowProperty['modified_by'] = keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''
                    if (isApprove === true || chk_po_status === 'Z') {
                        detailRowProperty['purchase_order_master_transaction_id'] = poDetailDataElement.purchase_order_master_transaction_id
                        detailRowProperty['purchase_order_details_transaction_id'] = poDetailDataElement.purchase_order_details_transaction_id !== undefined ? poDetailDataElement.purchase_order_details_transaction_id : 0;
                    } else {
                        detailRowProperty['purchase_order_master_transaction_id'] = 0;
                        detailRowProperty['purchase_order_details_transaction_id'] = 0;
                    }
                    json.TransDetailData.push(detailRowProperty)
                }

                if (!isApprove) { // If user not come for approve then send whole data.
                    // Trans Schedule Data
                    json.TransScheduleData = scheduleDataArray;
                    for (let index = 0; index < json.TransDetailData.length; index++) {
                        const element = json.TransDetailData[index];
                        const scData = scheduleDataArray.find(item => item.product_material_id === element.product_material_id)
                        if (scData === undefined || scData.length === 0) {
                            const scheduleData = {
                                company_id: COMPANY_ID,
                                company_branch_id: COMPANY_BRANCH_ID,
                                purchase_order_master_transaction_id: po_master_transaction_id,
                                purchase_order_no: purchase_order_no,
                                purchase_order_date: txt_po_date,
                                purchase_order_version: txt_po_version,
                                customer_order_no: element.customer_order_no,
                                customer_order_Date: element.customer_order_Date,
                                // product_type_id: $('#cmb_product_type_id').val(),
                                // product_type: $('#cmb_product_type_id').find(":selected").text(),
                                product_material_id: element.product_material_id,
                                product_material_name: element.product_rm_name,
                                product_material_unit_id: element.product_material_unit_id,
                                material_po_quantity: element.product_material_po_quantity,
                                // // into the weight field store the value as it is of the quantity.(Beacause aerotech client don't need the weight.)
                                // material_po_weight: element.product_material_po_weight, //product_rm_std_weight * Po_product_rm_quantity
                                material_po_weight: element.product_material_po_quantity,

                                product_material_po_schedule_quantity: element.product_material_po_quantity,
                                // // into the weight field store the value as it is of the quantity.(Beacause aerotech client don't need the weight.)
                                // product_material_po_schedule_weight: element.product_material_po_weight, //product_rm_std_weight * Po_product_rm_quantity
                                product_material_po_schedule_weight: element.product_material_po_quantity, //product_rm_std_weight * Po_product_rm_quantity


                                expected_schedule_date: txt_schedule_date,
                                product_material_receipt_quantity: 0,
                                product_material_receipt_weight: 0,
                                material_receipt_date: "",
                                purchase_order_schedules_item_status: element.purchase_order_item_status,
                                so_sr_no: element.so_sr_no,
                                indent_no: element.indent_no,
                                created_by: UserName,
                                modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''
                            }
                            json.TransScheduleData.push(scheduleData)
                        }
                    }
                    setScheduleDataArray(json.TransScheduleData);
                    localStorage.setItem('purchaseOrdScheduleData', JSON.stringify(json.TransScheduleData));

                    // taxationSummaryData
                    for (let index = 0; index < taxationSummaryData.length; index++) {
                        const element = taxationSummaryData[index];
                        const taxationSummarygroup = {
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            financial_year: FINANCIAL_SHORT_NAME,
                            // purchase_order_type_id: $('#cmb_product_type_id').val(),
                            // purchase_order_type: $('#cmb_product_type_id').find(":selected").text(),
                            purchase_order_master_transaction_id: 0,
                            supplier_id: cmb_supplier_id,
                            supplier_state_id: cmb_supplier_state_id,
                            supplier_city_id: cmb_supplier_city_id,
                            supplier_contacts_ids: supplierContactPersonsIds,
                            expected_branch_id: cmb_expected_branch_id,
                            expected_branch_state_id: cmb_expected_branch_state_id,
                            expected_branch_city_id: cmb_expected_branch_city_id,
                            purchase_order_no: purchase_order_no,
                            purchase_order_date: txt_po_date,
                            purchase_order_version: txt_po_version,
                            hsn_code_id: element.hsn_code_id,
                            summary_taxable_amount: element.summary_taxable_amount,
                            summary_cgst_percent: element.summary_cgst_percent,
                            summary_cgst_total: element.summary_cgst_total,
                            summary_sgst_percent: element.summary_sgst_percent,
                            summary_sgst_total: element.summary_sgst_total,
                            summary_igst_percent: element.summary_igst_percent,
                            summary_igst_total: element.summary_igst_total,
                            summary_total_amount: element.summary_total_amount,
                            created_by: UserName,
                            modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''
                        }
                        json.TransTaxSummaryTermsData.push(taxationSummarygroup)
                    }

                    // Trans PO Terms Data
                    $("input:checkbox[name=selectPOTerm]:checked").each(function () {

                        let findPoTermsData = POTermsdata.find(item => item.common_parameters_id === parseInt($(this).val()));
                        const poTermsData = {
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            purchase_order_master_transaction_id: po_master_transaction_id,
                            purchase_order_no: purchase_order_no,
                            purchase_order_date: txt_po_date,
                            purchase_order_version: txt_po_version,
                            customer_order_no: cmb_customer_order_no,
                            customer_order_Date: customer_order_date,
                            purchase_order_terms_parameters_id: parseInt($(this).val()),
                            purchase_order_terms_parameters_name: findPoTermsData.common_parameters_name,
                            purchase_order_terms_parameters_value: findPoTermsData.common_parameters_value,
                            purchase_order_terms_parameters_expected_value: $("#po-terms-expected-Value-" + $(this).val()).val() === "" ? findPoTermsData.common_parameters_value : $("#po-terms-expected-Value-" + $(this).val()).val(),
                            created_by: UserName,
                            modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''
                        }
                        json.TransPOTermsData.push(poTermsData);
                    });
                    if (json.TransPOTermsData.length === 0) {
                        json.TransPOTermsData = existingCommonTermsData.map((po_term) => {
                            po_term.purchase_order_no = keyForViewUpdate === 'copy' ? purchase_order_no : po_term.purchase_order_no;
                            po_term.purchase_order_version = keyForViewUpdate === 'copy' ? txt_po_version : po_term.purchase_order_version;
                            po_term.purchase_order_date = keyForViewUpdate === 'copy' ? txt_po_date : po_term.purchase_order_date;
                            po_term.customer_order_no = keyForViewUpdate === 'copy' ? cmb_customer_order_no : po_term.purchase_order_date;
                            po_term.customer_order_Date = keyForViewUpdate === 'copy' ? customer_order_date : po_term.purchase_order_date;
                            po_term.created_by = keyForViewUpdate === 'copy' ? UserName : po_term.created_by;
                            po_term.modified_by = keyForViewUpdate === 'copy' ? UserName : po_term.modified_by;
                            po_term.purchase_order_master_transaction_id = 0;

                            return po_term;
                        })
                    }
                    // Trans Payment Terms Data
                    $("input:checkbox[name=selectPayTerm]:checked").each(function () {

                        let findPaymentTermsData = paymentTermsData.find(item => item.payment_terms_id === parseInt($(this).val()));
                        let payment_term_idVal = parseInt($(this).val());
                        const paymentTermData = {
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            purchase_order_master_transaction_id: po_master_transaction_id,
                            purchase_order_no: purchase_order_no,
                            purchase_order_date: txt_po_date,
                            purchase_order_version: txt_po_version,
                            customer_order_no: cmb_customer_order_no,
                            customer_order_Date: customer_order_date,
                            payment_terms_id: payment_term_idVal,
                            payment_terms_name: findPaymentTermsData.payment_terms_name,
                            payment_terms_days: findPaymentTermsData.payment_terms_days,
                            payment_terms_grace_days: findPaymentTermsData.payment_terms_grace_days,
                            payment_terms_Milestome: $('#payment_milestone_' + parseInt($(this).val())).val(),
                            payment_percent: $('#payment_percent_' + parseInt($(this).val())).val(),
                            payment_expected_value: $('#payment_expect_val_' + parseInt($(this).val())).val(),
                            payment_expected_date: $('#payment_expected_date_' + parseInt($(this).val())).val(),
                            payment_paid_flag: $('#payment_paid_flag_' + parseInt($(this).val())).val(),
                            created_by: UserName,
                            modified_by: keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : ''
                        }
                        json.TransPaymentTermsData.push(paymentTermData);
                    });
                    if (json.TransPaymentTermsData.length === 0) {
                        json.TransPaymentTermsData = existingPaymentTermsData.map((pay_term) => {
                            pay_term.purchase_order_no = keyForViewUpdate === 'copy' ? purchase_order_no : pay_term.purchase_order_no;
                            pay_term.purchase_order_version = keyForViewUpdate === 'copy' ? txt_po_version : pay_term.purchase_order_version;
                            pay_term.purchase_order_date = keyForViewUpdate === 'copy' ? txt_po_date : pay_term.purchase_order_date;
                            pay_term.customer_order_no = keyForViewUpdate === 'copy' ? cmb_customer_order_no : pay_term.purchase_order_date;
                            pay_term.customer_order_Date = keyForViewUpdate === 'copy' ? customer_order_date : pay_term.purchase_order_date;
                            pay_term.created_by = keyForViewUpdate === 'copy' ? UserName : pay_term.created_by;
                            pay_term.modified_by = keyForViewUpdate === 'copy' ? UserName : pay_term.modified_by;
                            pay_term.purchase_order_master_transaction_id = 0;
                            return pay_term;
                        })
                    }

                    // If no-one payment term will be selected then select the immediate payment term default.
                    if (keyForViewUpdate !== 'update' && keyForViewUpdate !== 'approve' && json.TransPaymentTermsData.length === 0) {
                        let defaultPaymentTerm;
                        if (paymentTermsData.length > 0) {
                            defaultPaymentTerm = paymentTermsData.find(item => item.payment_terms_id === 1);            // By PaymentTerm Id.
                            // defaultPaymentTerm = paymentTermsData.find(item => item.payment_terms_name === 'Immediate');     // By PaymentTerm Name.
                        }

                        // Means there is no data in paymentTerm Data into the array. (Then load immediate payment term.)
                        if (defaultPaymentTerm === undefined) {
                            resetGlobalQuery();
                            globalQuery.columns = ['payment_terms_id', 'payment_terms_name', 'payment_terms_days', 'payment_terms_grace_days']
                            globalQuery.table = "cmv_payment_terms";
                            globalQuery.conditions.push({ field: "payment_terms_name", operator: "=", value: 'Immediate', });
                            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                            const getDefaultPmtTerm = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                            defaultPaymentTerm = getDefaultPmtTerm[0];
                        }
                        // Then set the default payment term and it's fields value;
                        let immediatePaymentTerm = {
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            purchase_order_master_transaction_id: po_master_transaction_id,
                            purchase_order_no: purchase_order_no,
                            purchase_order_date: txt_po_date,
                            purchase_order_version: txt_po_version,
                            customer_order_no: cmb_customer_order_no,
                            customer_order_Date: customer_order_date,
                            payment_terms_id: defaultPaymentTerm.payment_terms_id,
                            payment_terms_name: defaultPaymentTerm.payment_terms_name,
                            payment_terms_days: defaultPaymentTerm.payment_terms_days,
                            payment_terms_grace_days: defaultPaymentTerm.payment_terms_grace_days,
                            // Setting Default values for fields.
                            payment_terms_Milestome: '100%',
                            payment_percent: 100,
                            payment_expected_value: txt_grand_amount,
                            payment_expected_date: txt_schedule_date,
                            payment_paid_flag: txt_schedule_date,
                            created_by: UserName,
                        }
                        json.TransPaymentTermsData.push(immediatePaymentTerm);
                    }

                } else {

                    if (po_status === 'R') {
                        setScheduleDataArray(prevData => {
                            const updatedData = prevData.map(schedulerow => ({
                                ...schedulerow,
                                purchase_order_schedules_item_status: 'R',
                            }));

                            json.TransScheduleData.push(...updatedData);

                            return updatedData;
                        });
                    }
                    else {
                        json.TransDetailData.map(element => {
                            let foundElement = scheduleDataArray.find(item => {
                                return parseInt(item.product_material_id) === parseInt(element.product_material_id);
                            });
                            if (po_status === 'R') {
                                foundElement.purchase_order_schedules_item_status = 'R';
                            }
                            setScheduleDataArray(prevData => {
                                if (element.purchase_order_item_status === 'R') {
                                    const updatedData = prevData.map(schedulerow => {
                                        if (element.product_material_id === schedulerow.product_material_id) {
                                            return {
                                                ...schedulerow,
                                                purchase_order_schedules_item_status: 'R'
                                            };
                                        } else {
                                            return schedulerow;
                                        }
                                    });
                                    json.TransScheduleData.push(...updatedData);
                                    return updatedData;
                                } else {
                                    const updatedData = prevData.map(schedulerow =>
                                        schedulerow === foundElement ? { ...foundElement } : schedulerow
                                    );
                                    json.TransScheduleData.push(...updatedData);
                                    return updatedData;
                                }
                            });

                        });
                    }
                }
                // Common Ids
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.company_branch_id = COMPANY_BRANCH_ID
                json.commonIds.purchase_order_no = purchase_order_no
                json.commonIds.purchase_order_date = txt_po_date
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                let isApproveOrPreClosed = 'AddUpdate';
                if (keyForViewUpdate === 'approve') {
                    isApproveOrPreClosed = 'approve';
                } else if (keyForViewUpdate === 'view' && chk_po_status === 'Z') {
                    isApproveOrPreClosed = 'preClosed';
                }
                console.log("json of PO New: ", json);
                const formData = new FormData();
                formData.append(`StPurchaseData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtPurchaseDetails/FnAddUpdateRecord/${isApproveOrPreClosed}`, requestOptions);
                const responce = await apicall.json();
                if (responce.success === "0") {
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = await comboDataAPiCall.current.evitCache();
                    console.log(evitCache);
                    setSuccMsg(responce.message)
                    if (responce.data.hasOwnProperty('purchase_order_version') && responce.data.hasOwnProperty('purchase_order_no')) {
                        setModalOrderDetails(`purchase Order No: ${responce.data.purchase_order_no} and Version: ${responce.data.purchase_order_version}`)
                    }
                    if (po_status !== 'R' && keyForViewUpdate === 'approve') {
                        setShowMailSentModal(true);
                    }
                    //  else if (po_master_transaction_id === 0) {
                    //     setMessageForConfirmation('Do you want to export this Purchase Order!...')
                    //     setShowConfiemationModal(true);
                    // }
                    else {
                        setShowSuccessMsgModal(true);
                    }
                    if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'reject') {
                        $('#viewdocument-id').removeAttr('disabled');
                    }
                }
                setIsLoading(false)
                console.log(json)
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }
    const sendEmail = async () => {
        try {

            setIsLoading(true);
            setShowMailSentModal(false);
            $('#spinner_text').text('Sending Email...');
            const EmailData = await FngenerateMailJson();

            // Generate the sales-order PDF.
            let purchaseOrderContent = await printInvoice(false);
            const fileName = `${txt_PO_no.replaceAll('/', '_')}_@${COMPANY_ID}.pdf`;
            const purchaseOrderContainer = document.createElement('div');
            purchaseOrderContainer.style.margin = '20px';
            purchaseOrderContainer.innerHTML = purchaseOrderContent;
            let worker = html2pdf().from(purchaseOrderContainer).set({
                margin: 0,
                filename: fileName,
                html2canvas: { scale: 2 },
                jsPDF: { orientation: 'portrait', unit: 'pt', format: 'a4', compressPDF: true }
            }).toPdf();

            let pages = [];
            pages.slice(1).forEach(function (page) {
                worker = worker.get('pdf').then(function (pdf) {
                    pdf.addPage();
                }).from(page).toContainer().toCanvas().toPdf();
            });

            worker = worker.output("datauristring").then(async function (pdf) {
                const preBlob = dataURItoBlob(pdf);
                const file = new File([preBlob], fileName, { type: 'application/pdf' });

                const formData = new FormData();
                formData.append(`EmailData`, JSON.stringify(EmailData))
                formData.append("purchaseOrderPDF", file);

                const requestOptions = { method: 'POST', body: formData };
                const emailingApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtPurchaseDetails/FnSendEmail/${po_master_transaction_id}/${COMPANY_ID}`, requestOptions);
                const emailingApiCallResponse = await emailingApiCall.json();

                if (emailingApiCallResponse.success === "1") {
                    // Show success message.
                    setSuccMsg(emailingApiCallResponse.message);
                    setShowSuccessMsgModal(true);
                } else {
                    // Show error message.
                    setErrMsg(emailingApiCallResponse.message);
                    setShowErrorMsgModal(true);
                }
                setIsLoading(false);
            });
        } catch (error) {
            console.log('Error in email sending: ', error);
            navigate('/Error')
        }
    }

    const FngenerateMailJson = async () => {
        let EmailData = { 'EmailType': "Purchase Order Status Updatation", 'toMailData': [], 'bccData': [], 'ccData': [], 'subject': {}, 'mailAttachmentFilePaths': [], 'parameters': [] }
        const checkboxes = $('.selectSuppContactPerson:checked');
        checkboxes.each(function () {
            let selectedContact_id = parseInt($(this).val());
            const supplierContact = suppContactDetails.find(contactPerson => contactPerson.supplier_contact_id === selectedContact_id);
            if (supplierContact) {
                EmailData.toMailData.push(supplierContact.supp_email_id);
            }
        });
        EmailData.subject = `Purchase Order Approval Confirmation - Order ${txt_PO_no}`;    // Subject
        EmailData.parameters.push("Purchase Order Information");                            // Param0 It is a heading.
        EmailData.parameters.push($('#cmb_supplier_id').find(":selected").text());          // Param1
        EmailData.parameters.push(`${txt_PO_no}`);                                          // Param2
        EmailData.parameters.push($('#chk_po_status').find(":selected").text());            // Param3
        EmailData.parameters.push(validateNumberDateInput.current.formatDateToDDMMYYYY(`${txt_po_date}`));
        EmailData.parameters.push(`${txt_PO_no}`);                                        // Param4
        EmailData.parameters.push(COMPANY_NAME);              // Param6
        EmailData.parameters.push(sessionStorage.getItem('companyAddress'));                // Param7
        EmailData.parameters.push($('#cmb_approved_by_id').find(":selected").text());       // Param8
        console.log('emailData: ', EmailData);
        return EmailData;
    }
    // Fn function for accept purchase order
    const acceptPurchaseOrder = async () => {
        setIsLoading(true)
        $('#spinner_text').text('Validating Order Details...');
        try {
            let POAcceptanceJson = {
                purchase_order_master_transaction_id: po_master_transaction_id,
                purchase_order_acceptance_status: 'A'
            }
            const formData = new FormData();
            formData.append(`POAcceptanceJson`, JSON.stringify(POAcceptanceJson))
            const requestOptions = { method: 'POST', body: formData };
            const acceptPurchaseOrderAPICall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtPurchaseDetails/FnAcceptPurchaseOrder/${COMPANY_ID}`, requestOptions)
            const acceptPurchaseOrderAPICallResponse = await acceptPurchaseOrderAPICall.json();
            setIsLoading(false)
            if (acceptPurchaseOrderAPICallResponse.success === "1") {
                setSuccMsg(acceptPurchaseOrderAPICallResponse.message)
                setShowSuccessMsgModal(true);
            } else {
                setErrMsg(acceptPurchaseOrderAPICallResponse.error);
                setShowErrorMsgModal(true);
            }


        } catch (error) {
            console.log('Error in accepting Purchase order: ', error)
            navigate('/Error')
            $('#spinner_id').hide();
        }
    }

    const FnClearFormData = async () => {
        setPO_master_transactionID(0)
        // setProductTypeID('')
        setPO_life('C')
        setPurchase_process('M')
        setPO_creation_type('M')
        // setPOno(0)
        await GeneratePONo();
        setPOVersion(1)
        setPODate(today)
        setCustomerId('')
        setCustomerOrderNo('')
        setCustomerOrderDate('')
        setSupplier_id('')
        setSupplier_state_id('')
        setSupplier_city_id('')
        //Supplier contacts ids 
        setSuppContactDetails([])
        setSuppContactDetailsCols([])
        setExpected_branch_id(COMPANY_BRANCH_ID)
        setExpected_branch_state_id('')
        setExpected_branch_city_id('')
        setDepartmentId('')
        setApproved_by_id('')
        setApproved_date('')
        setScheduleDate(FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays))
        setPO_mail_sent_status('P')
        setPO_status('P')
        setStatus_remark('')
        //footer data reset pending
        setBasic_total(0)
        setTransportAmount(0)
        setFreightAmount(0)
        SetIsFreightTaxable(false)
        setPackingAmount(0)
        setPODiscountPercent(0)
        setPODiscountAmount(0)
        setOtherAmount(0)
        setTaxableTotal(0)
        setCgstTotal(0)
        setSgstTotal(0)
        setIgstTotal(0)
        setGrandTotal(0)
        setRoundOff(0)
        // setAgentId('');
        let defaultAgent = agents.find(agent => agent.field_name === 'NA');
        if (defaultAgent) { setAgentId(defaultAgent.field_id); } else { setAgentId(''); }
        setAgentPercent(0);
        setAgentPaidStatus('P')
        setPO_acceptance_status('P')
        setOther_term_con('')
        setRemark('')
        setPOIsActive(true)
        localStorage.removeItem('filteredMaterialData')
        localStorage.removeItem('purchaseOrdScheduleData')
        localStorage.removeItem('taxationSummary');
        taxationSummary = [];
        setPODetailsData([])
        setScheduleDataArray([])
        setTaxationSummaryData([])
        setPaymentTermsData([])
        setPOTermsData([])
        await loadDefaultPoOrdData();
        $('#selectAllPOTerms').prop('checked', false);
        $('#selectAllPayTerms').prop('checked', false);
    }
    //fn for User Access Manage .
    const FnSetFieldsByUserAccess = () => {
        if (keyForViewUpdate != null) {
            setIsApprove(keyForViewUpdate === 'approve' ? true : false);
            switch (keyForViewUpdate) {
                case 'update':
                    $('input[name="chk_po_creation_type"]').prop('disabled', true);
                    // $('#cmb_product_type_id').attr('disabled', true);
                    $('#txt_po_date').attr('disabled', true);
                    $('#save-btn').text('Update');
                    $('#print-btn-id').hide();
                    break;
                case 'view':
                    setIsView(true)
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("#save-btn").prop("disabled", true);
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('input[type="checkbox"]').prop('disabled', true);
                    $('#selectAllPOTerms, #selectAllPayTerms').prop("disabled", true);
                    if ((po_status === 'A' || po_status === 'I') && po_life === 'O') {
                        $("form").find('select#chk_po_status').removeAttr("disabled");
                    }
                    // Disable specific options
                    $("form").find('select#chk_po_status option').each(function () {
                        if ($(this).val() !== 'Z' && $(this).val() !== po_status) {// Assuming you want to disable options with values 'option2' and 'option3'
                            $(this).prop('disabled', true);
                        } else {
                            $(this).prop('disabled', false); // Enable the specific option
                        }
                    });
                    $('#clear-btn-id').hide();
                    $('#viewdocument-id').hide();
                    $('#save-btn').hide();
                    break;
                case 'approve':
                    setIsView(true)
                    $('input[type="checkbox"]').prop('readonly', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('table .approveField').removeAttr("disabled");
                    $("form").find('select#chk_po_status').removeAttr("disabled");
                    $("form").find('textarea#txt_status_remark').removeAttr("disabled");
                    $('#save-btn').text('Approve');
                    $("form").find('select#chk_po_status option').each(function () {
                        if ($(this).val() === 'Z' || $(this).val() === 'B') {// Assuming you want to disable options with values 'option2' and 'option3'
                            $(this).prop('disabled', true);
                        } else {
                            $(this).prop('disabled', false); // Enable the specific option
                        }
                    });
                    $('#clear-btn-id').hide();
                    $('#print-btn-id').hide();
                    $('#viewdocument-id').hide();
                    break;
                case 'delete':
                    setIsView(true)
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("#save-btn").prop("disabled", true);
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('input[type="checkbox"]').prop('disabled', true);
                    $('#selectAllPOTerms, #selectAllPayTerms').prop("disabled", true);
                    if ((po_status === 'A' || po_status === 'I') && po_life === 'O') {
                        $("form").find('select#chk_po_status').removeAttr("disabled");
                    }
                    // Disable specific options
                    $("form").find('select#chk_po_status option').each(function () {
                        if ($(this).val() !== 'Z' && $(this).val() !== po_status) {// Assuming you want to disable options with values 'option2' and 'option3'
                            $(this).prop('disabled', true);
                        } else {
                            $(this).prop('disabled', false); // Enable the specific option
                        }
                    });
                    $('#clear-btn-id').hide();
                    $('#viewdocument-id').hide();
                    $('#save-btn').hide();
                    break;
            }
        }
    }
    const FnGetSupplierContactPersonIds = async () => {
        let supplierContactPersonsIds = '';
        const checkboxes = $('.selectSuppContactPerson:checked');
        checkboxes.each(function () {
            supplierContactPersonsIds += $(this).val() + ":";
        });
        return supplierContactPersonsIds.replace(/:$/, '');
    }

    const FnSetExistingSupplierContactPersonIds = async (contactIds) => {
        const supplierContactPersonsIds = contactIds.split(':');
        supplierContactPersonsIds.forEach(function (contactId, index) {
            $('#selectSuppContactPerson_' + supplierContactPersonsIds[index]).prop('checked', true);
        });
        toggleChkAllBoxes('PartiallySuppContactSelection');
    }
    const FnCheckUpdateResponce = async () => {

        let defaultExtpScheduleDate = FnGetExpectedScheduleGraceDate(expectedScheduleGraceDays);
        setDefaultExpecScheduleDate(defaultExtpScheduleDate)
        try {
            if (idList !== '') {
                fin_year = idList.financial_year;
                PO_version = idList.purchase_order_version;

                let purchase_order_no;
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtPurchaseDetails/FnShowAllDetailsAndMastermodelRecords/${PO_version}/${fin_year}/${COMPANY_ID}?purchase_order_no=${idList.purchase_order_no}`)
                const responce = await apiCall.json();
                console.log(responce);
                debugger
                if (responce.PurchaseOrderMasterRecord != null) {
                    setPurchaseOrderMasterJson(responce.PurchaseOrderMasterRecord);
                    const transPOHeaderFooterData = responce.PurchaseOrderMasterRecord;
                    let creation_type = transPOHeaderFooterData.purchase_order_creation_type;
                    setPOno(idList.purchase_order_no)
                    setPOVersion(idList.purchase_order_version)
                    setPO_master_transactionID(transPOHeaderFooterData.purchase_order_master_transaction_id)
                    setPO_creation_type(creation_type)
                    if (creation_type === 'A') {
                        $('#add-material-id').hide();
                    } else {
                        $('#add-material-id').show();
                    }
                    // setProductTypeID(transPOHeaderFooterData.purchase_order_type_id)
                    setPOno(transPOHeaderFooterData.purchase_order_no)
                    purchase_order_no = transPOHeaderFooterData.purchase_order_no;
                    setPOVersion(transPOHeaderFooterData.purchase_order_version)
                    setPODate(transPOHeaderFooterData.purchase_order_date)
                    setSupplier_id(transPOHeaderFooterData.supplier_id)
                    await comboOnChange('supplier')
                    setSupplier_state_id(transPOHeaderFooterData.supplier_state_id)
                    await comboOnChange("supplier_state")
                    setSupplier_city_id(transPOHeaderFooterData.supplier_city_id)
                    await FnSetExistingSupplierContactPersonIds(transPOHeaderFooterData.supplier_contacts_ids);
                    setPO_life(transPOHeaderFooterData.purchase_order_life)
                    setPurchase_process(transPOHeaderFooterData.purchase_process_entry)
                    setPOIndentNo(transPOHeaderFooterData.indent_no)
                    setPOIndentDate(transPOHeaderFooterData.indent_date)
                    setPOIndentVersion(transPOHeaderFooterData.indent_version)
                    setCustomerId(transPOHeaderFooterData.customer_id)
                    await comboOnChange('customer')
                    setCustomerOrderNo(transPOHeaderFooterData.customer_order_no)
                    setCustomerOrderDate(transPOHeaderFooterData.customer_order_Date)
                    setExpected_branch_id(transPOHeaderFooterData.expected_branch_id)
                    setExpected_branch_state_id(transPOHeaderFooterData.expected_branch_state_id)
                    await comboOnChange('expt_branchState')
                    setExpected_branch_city_id(transPOHeaderFooterData.expected_branch_city_id)
                    setDepartmentId(responce.PurchaseOrderDetailsRecords[0].department_id)
                    setScheduleDate(transPOHeaderFooterData.expected_schedule_date)
                    setPO_mail_sent_status(transPOHeaderFooterData.purchase_order_mail_sent_status)
                    setPO_acceptance_status(transPOHeaderFooterData.purchase_order_acceptance_status)
                    setStatus_remark(transPOHeaderFooterData.status_remark)
                    setRemark(transPOHeaderFooterData.remark)
                    setPOIsActive(transPOHeaderFooterData.is_active)
                    //set footer data
                    setBasic_total(transPOHeaderFooterData.basic_total)
                    setFreightAmount(transPOHeaderFooterData.freight_amount)
                    SetIsFreightTaxable(transPOHeaderFooterData.is_freight_taxable)
                    setFreightHSNId(transPOHeaderFooterData.freight_hsn_code_id)
                    setPackingAmount(transPOHeaderFooterData.packing_amount)
                    setPODiscountPercent(transPOHeaderFooterData.po_discount_percent)
                    setPODiscountAmount(transPOHeaderFooterData.po_discount_amount)
                    setTransportAmount(transPOHeaderFooterData.transport_amount)
                    setCgstTotal(transPOHeaderFooterData.cgst_total)
                    setSgstTotal(transPOHeaderFooterData.sgst_total)
                    setIgstTotal(transPOHeaderFooterData.igst_total)
                    setRoundOff(transPOHeaderFooterData.roundoff)
                    setGrandTotal(transPOHeaderFooterData.grand_total)
                    setOtherAmount(transPOHeaderFooterData.other_amount)
                    setTaxableTotal(transPOHeaderFooterData.taxable_total)
                    setAgentId(transPOHeaderFooterData.agent_id)
                    setAgentPercent(transPOHeaderFooterData.agent_percent)
                    setAgentPaidStatus(transPOHeaderFooterData.agent_paid_status)
                    setOther_term_con(transPOHeaderFooterData.other_terms_conditions)

                    if (keyForViewUpdate === 'approve') {
                        setPO_status('A')
                        setApproved_by_id(UserId)
                        setApproved_date(today)
                    } else {
                        setPO_status(transPOHeaderFooterData.purchase_order_status)
                        po_status = transPOHeaderFooterData.purchase_order_status;
                        po_life = transPOHeaderFooterData.purchase_order_life;
                        setApproved_by_id(transPOHeaderFooterData.approved_by_id)
                        setApproved_date(transPOHeaderFooterData.approved_date)
                    }

                    if (keyForViewUpdate === 'copy') {
                        // const shortname = $('#cmb_product_type_id option:selected').attr('shortname');
                        // purchase_order_no = await GeneratePONo(shortname);
                        setPO_creation_type('M')
                        purchase_order_no = await GeneratePONo();
                        setPO_master_transactionID(0);
                        setPODate(today);
                        setPO_status('P');
                        setScheduleDate(defaultExtpScheduleDate);
                        setApproved_by_id('');
                        setApproved_date('');
                        setAgentPaidStatus('P');
                        setPO_acceptance_status('P');
                        setStatus_remark('');
                        setPO_mail_sent_status('P');
                        $('#add-material-id').show();
                    }

                    // Function to update keys dynamically 
                    const updateKeys = (object) => {
                        object.product_id = object.product_material_id;
                        object.prev_indent_quantity = object.product_material_po_quantity;
                        object.prev_indent_weight = object.product_material_po_weight;
                        if (keyForViewUpdate !== 'copy') {
                            if (creation_type === 'A') {
                                setselectIndentNo((prevOptions) => prevOptions + ':' + object.indent_no);
                                object.indent_nos = object.indent_no.split(':');
                            }
                        }

                        let taxSummary = {
                            materialId: object.product_material_id,
                            so_sr_no: parseInt(object.so_sr_no),
                            indent_no: object.indent_no,
                            hsnCode: object.hsn_sac_code,
                            hsn_code_id: object.product_material_hsn_code_id,
                            hsnRate: object.hsn_sac_rate,
                            taxableAmt: object.material_taxable_amount,
                            CGSTPercent: object.material_cgst_percent,
                            CGSTAmt: object.material_cgst_total,
                            SGSTPercent: object.material_sgst_percent,
                            SGSTAmt: object.material_sgst_total,
                            IGSTPercent: object.material_igst_percent,
                            IGSTAmt: object.material_igst_total,
                            totalAmt: object.material_total_amount
                        }
                        const materialIndex = taxationSummary.findIndex((obj) => obj.materialId === object.product_rm_id);
                        if (materialIndex !== -1) {
                            taxationSummary[materialIndex] = taxSummary; // Replace the object in-place
                        } else {
                            taxationSummary.push(taxSummary); // Push the new object if not found
                        }
                        localStorage.setItem('taxationSummary', JSON.stringify(taxationSummary));

                        const updatedObject = {};
                        for (const key in object) {
                            if (key === 'product_material_id') {
                                updatedObject[`product_rm_id`] = object[key];

                            } else if (key === 'product_material_packing_id') {
                                updatedObject[`product_rm_packing_id`] = object[key];

                            } else if (key === 'product_material_po_quantity') {
                                updatedObject[`product_rm_quantity`] = object[key];

                            } else if (key === 'product_material_std_weight') {
                                updatedObject[`product_rm_std_weight`] = object[key];

                            } else if (key === 'product_material_conversion_factor') {
                                updatedObject[`product_rm_conversion_factor`] = object[key];

                            } else if (key === 'product_material_name') {
                                updatedObject[`product_rm_name`] = object[key];

                            } else if (key === 'product_material_tech_spect') {
                                updatedObject[`product_rm_tech_spect`] = object[key];

                            } else if (key === 'product_material_hsn_code_id') {
                                updatedObject[`product_rm_hsn_sac_code_id`] = object[key];

                            }
                            else if (key === 'hsn_sac_code') {
                                updatedObject[`product_rm_hsn_sac_code`] = object[key];

                            }
                            else if (key === 'hsn_sac_rate') {
                                updatedObject[`product_rm_hsn_sac_rate`] = object[key];

                            } else if (key === 'material_rate') {
                                updatedObject[`product_rm_rate`] = object[key];
                            }
                            else if (key === 'product_material_po_approved_quantity') {
                                updatedObject[`product_rm_approved_quantity`] = object[key];

                            } else if (key === 'product_material_po_approved_weight') {
                                updatedObject[`product_rm_approved_weight`] = object[key];
                            }
                            else if (key === 'product_material_po_rejected_quantity') {
                                updatedObject[`product_rm_rejected_quantity`] = object[key];

                            } else if (key === 'product_material_po_rejected_weight') {
                                updatedObject[`product_rm_rejected_weight`] = object[key];

                            } else if (key === 'product_material_unit_id') {
                                updatedObject[`product_rm_stock_unit_id`] = object[key];

                            } else if (key === 'product_material_stock_unit_name') {
                                updatedObject[`product_rm_stock_unit_name`] = object[key];

                            } else if (key === 'product_material_po_weight') {
                                updatedObject[`product_rm_weight`] = object[key];

                            } else if (key === 'product_packing_name') {
                                updatedObject[`product_rm_packing_name`] = object[key];
                            } else if (key === 'purchase_order_type_id') {
                                updatedObject[`product_type_id`] = object[key];
                            }

                            else {
                                updatedObject[`${key}`] = object[key];
                            }
                        }
                        return updatedObject;
                    };
                    if (responce.PurchaseOrderDetailsRecords != null) {

                        let updatedPODetailsDataArray = responce.PurchaseOrderDetailsRecords.map(updateKeys);
                        if (keyForViewUpdate === 'approve') {
                            updatedPODetailsDataArray = updatedPODetailsDataArray.map(function (rowData) {
                                return {
                                    ...rowData,
                                    approved_date: today(), purchase_order_item_status: 'A', product_rm_approved_quantity: rowData.product_rm_quantity,
                                    product_rm_approved_weight: rowData.product_rm_weight,
                                };
                            });
                        } else if (keyForViewUpdate === 'copy') {
                            // Update the data for copied purchase order.
                            updatedPODetailsDataArray = updatedPODetailsDataArray.map((ordDetail) => ({
                                ...ordDetail,
                                purchase_order_item_status: 'P',
                                purchase_order_master_transaction_id: 0,
                                purchase_order_details_transaction_id: 0,
                                purchase_order_no: purchase_order_no,
                                purchase_order_date: today(),
                                purchase_order_version: 1,
                                material_schedule_date: defaultExtpScheduleDate,
                                indent_no: '',
                                indent_date: '',
                                indent_version: '',
                                customer_id: '',
                                customer_order_no: '',
                                customer_order_Date: '',
                                product_rm_approved_quantity: 0,
                                product_rm_approved_weight: 0,
                                material_po_approval_remark: '',
                                approved_date: '',
                            }));

                        }
                        setPODetailsData(updatedPODetailsDataArray)
                        localStorage.setItem('filteredMaterialData', JSON.stringify(updatedPODetailsDataArray));
                        if (creation_type === 'M') {
                            setTimeout(() => {
                                updatedPODetailsDataArray.forEach((item) => {
                                    if (item.customer_id !== null) {
                                        FngetCustOrdNos(item, creation_type)
                                    }
                                });
                            }, 200);
                        } else if (keyForViewUpdate !== 'copy') {
                            updatedPODetailsDataArray.forEach((item) => {
                                if (item.customer_id !== null && item.customer_id !== '') {
                                    FnshowIndentCustOrdDt(item)
                                    isCustCheckBoxChk(item.customer_id.split(':'), item.product_rm_id);
                                } else {
                                    setCheckCustomerLenght(0);
                                }
                            })
                        }

                    }
                    //set PO Schedule Data
                    if (responce.PurchaseOrderSchedules !== null && responce.PurchaseOrderSchedules.length > 0) {
                        const existingpoOrdSchedules = responce.PurchaseOrderSchedules;
                        const updatedSchedulesData = [];
                        const materialWiseSchedules = {};
                        existingpoOrdSchedules.forEach((item) => {
                            const key = `${item.product_material_id}:${item.so_sr_no}`;
                            if (!materialWiseSchedules[key]) {
                                materialWiseSchedules[key] = [];
                            }
                            materialWiseSchedules[key].push(item);
                        });
                        // Looping for set the id for schedule
                        for (const key of Object.keys(materialWiseSchedules)) {
                            let [indent_no, rm_id, so_sr_no] = '';
                            [rm_id, so_sr_no] = key.split(':');
                            const materialGroup = materialWiseSchedules[key];
                            materialGroup.forEach((schedule, scheduleCounter) => {
                                schedule.id = `${rm_id}:${so_sr_no}:${scheduleCounter}`;
                                schedule.modified_by = keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? UserName : '';
                                if (keyForViewUpdate === 'copy') {
                                    //  //Update the keys for copied sales order.
                                    schedule.purchase_order_schedules_transaction_id = 0;
                                    schedule.purchase_order_master_transaction_id = 0;
                                    schedule.purchase_order_no = purchase_order_no;
                                    schedule.purchase_order_date = today();
                                    schedule.purchase_order_version = 1;
                                    schedule.purchase_order_schedules_item_status = 'P';
                                    schedule.purchase_order_schedules_item_status_desc = 'Pending';
                                    schedule.expected_schedule_date = defaultExtpScheduleDate;
                                } else if (keyForViewUpdate === 'approve') {
                                    schedule.purchase_order_schedules_transaction_id = 0;
                                    schedule.purchase_order_schedules_item_status = 'P' ? 'A' : schedule.purchase_order_schedules_item_status;
                                    schedule.purchase_order_schedules_item_status_desc = 'P' ? 'Aprroved' : schedule.purchase_order_schedules_item_status_desc;
                                }
                                // else if (keyForViewUpdate !== 'approve') {
                                //     schedule.purchase_order_schedules_transaction_id = 0;

                                // }
                                updatedSchedulesData.push(schedule);
                            });
                        }
                        setScheduleDataArray(updatedSchedulesData)
                        localStorage.setItem('purchaseOrdScheduleData', JSON.stringify(updatedSchedulesData));
                    }
                    //  Sets the taxation Summary
                    if (responce.PurchaseOrderTaxSummary !== null) {
                        FnGetTaxationSummary();
                    }
                    //to render payment terms data for update
                    setExistingPaymentTermsData(responce.PurchaseOrderPaymentTerms)
                    // Set PO order terms data.
                    setExistingCommonTermsData(responce.PurchaseOrderTermsTrading)
                    if (isApprove) {
                        $(`#product_rm_approved_quantity_${responce.PurchaseOrderDetailsRecords.product_rm_id}`).focus();
                    }
                }

                console.log("Response for existing PO data for update: ", responce);
            }
        } catch (error) {
            console.log("error in getting existing PO data for update: ", error)
            navigate('/Error')
        }
    }

    //validate po form 
    const FnValidatePoOrder = async () => {
        let headerDataIsValid = await validate.current.validateForm("PO_header_formId");
        if (!headerDataIsValid) { return false; }


        if ($('input:checkbox.selectSuppContactPerson:checked').length === 0) {
            $('#error_supplier_contact_persons').text('Please select atleast one contact person.');
            $('#error_supplier_contact_persons').show();
            $('#error_txt_status_remark').focus();
            setErrMsg('Please select atleast one contact person...!');
            setShowErrorMsgModal(true);
            setOpenContactsAccord(true);
            return false;
        } else {
            $('#error_supplier_contact_persons').hide();
        }

        let PoDetailsIsValid = false;
        let addedMaterials = $('#po-details-table tbody tr').length;
        if (addedMaterials <= 0 && keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add atleast one material.');
            setShowErrorMsgModal(true);
            setOpenDetailsAccord(true);
            return PoDetailsIsValid = false;
        } else {
            $('#po-details-table tbody tr').each(function () {
                let currentTblRow = $(this);
                let materialRate = parseFloat(currentTblRow.find('input[id^="product_rm_rate_"]').val());
                let materialQty = parseFloat(currentTblRow.find('input[id^="product_rm_quantity_"]').val());
                let materialApproveQty = parseFloat(currentTblRow.find('input[id^="product_rm_approved_quantity_"]').val());
                let materialRemark = (currentTblRow.find('input[id^="material_po_approval_remark_"]')).val();
                let materialDiscPercent = parseFloat(currentTblRow.find('input[id^="material_discount_percent_"]').val());
                let product_rm_stock_unit_id = currentTblRow.find('select[id^="product_rm_stock_unit_id_"]').val();

                if (keyForViewUpdate === 'approve') {
                    if (isNaN(materialApproveQty) || materialApproveQty < 0) {
                        $(currentTblRow.find('input[id^="product_rm_approved_quantity_"]'))[0].parentElement.dataset.tip = 'Product Quantity should not be a zero or blank on approval...!';
                        $(currentTblRow.find('input[id^="product_rm_approved_quantity_"]'))[0].focus();
                        // openAccordion('orderDetailsAccordionItem', 'orderDetailsAccordionHeader');
                        setOpenDetailsAccord(true);
                        return PoDetailsIsValid = false;
                    } else if (materialQty !== materialApproveQty) {
                        if (materialRemark === '') {
                            $(currentTblRow.find('input[id^="material_po_approval_remark_"]'))[0].parentElement.dataset.tip = 'Approval Remark should not be blank on approval...!';
                            $(currentTblRow.find('input[id^="material_po_approval_remark_"]'))[0].focus();
                            setOpenDetailsAccord(true);
                            return PoDetailsIsValid = false;
                        }
                    }
                } else {
                    if (isNaN(materialQty) || materialQty <= 0 || materialQty === '') {
                        $(currentTblRow.find('input[id^="product_rm_quantity_"]'))[0].parentElement.dataset.tip = 'Material quantity should not be a zero or blank...!';
                        $(currentTblRow.find('input[id^="product_rm_quantity_"]'))[0].focus();
                        setOpenDetailsAccord(true);
                        // openAccordion('orderDetailsAccordionItem', 'orderDetailsAccordionHeader');
                        return PoDetailsIsValid = false;

                    } else if (isNaN(materialRate) || materialRate <= 0 || materialRate === '') {
                        $(currentTblRow.find('input[id^="product_rm_rate_"]'))[0].parentElement.dataset.tip = 'Material rate should not be a zero or blank...!';
                        $(currentTblRow.find('input[id^="product_rm_rate_"]'))[0].focus();
                        setOpenDetailsAccord(true);
                        // openAccordion('orderDetailsAccordionItem', 'orderDetailsAccordionHeader');
                        return PoDetailsIsValid = false;

                    } else if (isNaN(materialDiscPercent) || materialDiscPercent === '') {
                        $(currentTblRow.find('input[id^="material_discount_percent_"]'))[0].parentElement.dataset.tip = 'Material Discount Percentage should not be blank otherwise set ...!';
                        $(currentTblRow.find('input[id^="material_discount_percent_"]'))[0].focus();
                        // openAccordion('orderDetailsAccordionItem', 'orderDetailsAccordionHeader');
                        setOpenDetailsAccord(true);
                        return PoDetailsIsValid = false;

                    } else if (isNaN(product_rm_stock_unit_id) || product_rm_stock_unit_id === '') {
                        $(currentTblRow.find('select[id^="product_rm_stock_unit_id_"]'))[0].parentElement.dataset.tip = 'Please Select Atleast One Unit...!';
                        $(currentTblRow.find('select[id^="product_rm_stock_unit_id_"]'))[0].focus();
                        setOpenDetailsAccord(true);
                        return PoDetailsIsValid = false;
                    }
                }
                PoDetailsIsValid = true;
            });
            if (keyForViewUpdate !== 'view' && PoDetailsIsValid) {
                let ScheduleIsvalid = false;
                let scheduledata = JSON.parse(localStorage.getItem('purchaseOrdScheduleData'));
                if (scheduledata != null) {
                    const groupedData = scheduledata.reduce((result, item) => {
                        const key = `${item.product_material_id}:${item.so_sr_no}`;

                        const { product_material_id, product_material_po_schedule_quantity } = item;
                        const total_product_material_po_schedule_quantity = parseFloat(product_material_po_schedule_quantity) || 0;

                        if (!result[key]) {
                            result[key] = {
                                product_material_id,
                                total_product_material_po_schedule_quantity: 0,
                            };
                        }
                        result[key].total_product_material_po_schedule_quantity += total_product_material_po_schedule_quantity;
                        return result;
                    }, {});
                    let groupedArray = Object.values(groupedData);
                    console.log(groupedArray);

                    let podetailsdata = [...po_details_data];
                    for (let detailItem = 0; detailItem < groupedArray.length; detailItem++) {
                        let groupedElement = groupedArray[detailItem];
                        const isDetailpressent = podetailsdata.find(item => item.product_rm_id === groupedElement.product_material_id)
                        if (isDetailpressent) {

                            if (keyForViewUpdate !== 'approve') {
                                if (parseInt(isDetailpressent.product_rm_quantity) !== parseInt(groupedElement.total_product_material_po_schedule_quantity)) {
                                    setErrMsg(`The schedule quantity for "${isDetailpressent.product_rm_name}" is not properly allocated...! (Total Quanity is " ${isDetailpressent.product_rm_quantity} " and are you scheduling "${groupedElement.total_product_material_po_schedule_quantity}")`)
                                    setShowErrorMsgModal(true)
                                    ScheduleIsvalid = false;
                                    return ScheduleIsvalid = false;
                                }
                                ScheduleIsvalid = true;
                            } else {
                                if (parseInt(isDetailpressent.product_rm_approved_quantity) !== parseInt(groupedElement.total_product_material_po_schedule_quantity)) {
                                    setErrMsg(`The schedule quantity for ${isDetailpressent.product_rm_name} is not properly allocated...! (Total Approved Quanity is "${isDetailpressent.product_rm_approved_quantity}" and you are scheduling "${groupedElement.total_product_material_po_schedule_quantity}")`)
                                    setShowErrorMsgModal(true)
                                    ScheduleIsvalid = false;
                                    return ScheduleIsvalid = false;
                                }
                                ScheduleIsvalid = true;
                            }
                        }
                    }
                }

                // Payment term validations
                let isExistPaymentData = false;
                let selectedPmtTerms = $('#po-paymentTerms-table tbody tr .selectPayTerm:checked')
                if (selectedPmtTerms.length === 0) {
                    selectedPmtTerms = existingPaymentTermsData;
                    isExistPaymentData = true;
                }
                let totalPercentage = 0;
                let poPayTermsIsValid = true;
                // // Commented this 'If Condition' because Prashant sir told to remove it if not selected then Select the Immediate Payment term. on 01/04/2024
                // if (selectedPmtTerms.length < 1) {
                //     setErrMsg('Please Select Atleast One Payment Term...!');
                //     setShowErrorMsgModal(true);
                //     return poPayTermsIsValid = false;
                // } else 
                if (!isExistPaymentData) {
                    selectedPmtTerms.each(function () {
                        let currentTblRow = $(this.parentElement.parentElement)
                        let pmtMilestome = currentTblRow.find('input[id^="payment_milestone_"]').val();
                        let pmtPercent = parseFloat(currentTblRow.find('input[id^="payment_percent_"]').val());
                        let pmtExpectedDate = currentTblRow.find('input[id^="payment_expected_date_"]').val();
                        totalPercentage += pmtPercent;
                        if (pmtMilestome === '') {
                            $(currentTblRow.find('input[id^="payment_milestone_"]'))[0].parentElement.dataset.tip = 'Please enter payment milestome...!';
                            $(currentTblRow.find('input[id^="payment_milestone_"]'))[0].focus();
                            setOpenPaymentTermsAccord(true);
                            return poPayTermsIsValid = false;
                        } else if (isNaN(pmtPercent) || pmtPercent === '') {
                            $(currentTblRow.find('input[id^="payment_percent_"]'))[0].parentElement.dataset.tip = 'Payment term Percentage should not be blank otherwise set ...!';
                            $(currentTblRow.find('input[id^="payment_percent_"]'))[0].focus();
                            setOpenPaymentTermsAccord(true);
                            return poPayTermsIsValid = false;
                        } else if (pmtExpectedDate === '' || pmtExpectedDate === null || pmtExpectedDate === undefined) {
                            $(currentTblRow.find('input[id^="payment_expected_date_"]'))[0].parentElement.dataset.tip = 'Please select payment expected schdule date...!';
                            $(currentTblRow.find('input[id^="payment_expected_date_"]'))[0].focus();
                            setOpenPaymentTermsAccord(true);
                            return poPayTermsIsValid = false;
                        }
                    });
                    if (poPayTermsIsValid && totalPercentage !== 100) {
                        setErrMsg('Please Divide Payment Percent Properly in 100%...!');
                        setShowErrorMsgModal(true);
                        setOpenPaymentTermsAccord(true);
                        return poPayTermsIsValid = false;
                    } else {
                        return poPayTermsIsValid;
                    }
                }
                // POcommon terms validations
                let PoCommonTermsIsValid = true;
                if (poPayTermsIsValid) {
                    PoCommonTermsIsValid = true;
                    let selectedCommonTerms = $('#po-commonTerms-table tbody tr .selectPOTerm:checked')
                    selectedCommonTerms.each(function () {
                        let currentTblRow = $(this.parentElement.parentElement)
                        let expectedValue = currentTblRow.find('input[id^="po-terms-expected-Value-"]').val();
                        if (expectedValue === '') {
                            $(currentTblRow.find('input[id^="po-terms-expected-Value-"]'))[0].parentElement.dataset.tip = 'Please enter valid common parameters expected value...!';
                            $(currentTblRow.find('input[id^="po-terms-expected-Value-"]'))[0].focus();
                            setOpenCommonTermsAccord(true);
                            return PoCommonTermsIsValid = false;
                        }
                    });
                    return PoCommonTermsIsValid;
                }
                return PoDetailsIsValid === true && poPayTermsIsValid === true && PoCommonTermsIsValid === true;

            } else if (keyForViewUpdate === 'approve' && PoDetailsIsValid) {
                return true;
            } else {
                return keyForViewUpdate !== 'approve' && PoDetailsIsValid
            }
        }
    }
    const refreshData = async (key) => {

        switch (key) {
            case 'get_supplier':
                // get suppliers list with default state & city
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("supp_branch_state_id");
                globalQuery.columns.push("supp_branch_city_id");
                globalQuery.table = "cmv_supplier";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const suppliersApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setSupplierOptions(suppliersApiCall)
                break;
            case 'get_customer':
                //get customer list
                const customerApiCall = await comboDataAPiCall.current.fillMasterData("cmv_customer", "", "");
                setCustomerOptions(customerApiCall)
                break;
            case 'get_agent':
                // for agents List
                const agentApiCall = await comboDataAPiCall.current.fillMasterData("cmv_agent", "", "");
                setAgents(agentApiCall)
                break;
            case 'get_expectedBranch':
                //get expected branch list
                resetGlobalQuery();
                globalQuery.columns.push("company_branch_id");
                globalQuery.columns.push("company_branch_name");
                globalQuery.columns.push("branch_state_id");
                globalQuery.columns.push("branch_city_id");
                globalQuery.columns.push("is_sez");
                globalQuery.columns.push("branch_EmailId");
                globalQuery.table = "cmv_company_branch";
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                const exptBranchApiCall = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                setExpectedBranchOptions(exptBranchApiCall);
                break;

            default:
                break;
        }
    }
    // Hide & show indent order no's
    const showCheckboxCombo = async (key, product_rm_id) => {
        let headerDataIsValid = await validate.current.validateForm("PO_header_formId");
        switch (key) {
            case 'indent-order':
                if (headerDataIsValid) {
                    await FnGetIndentNos();
                    let checkboxes = document.getElementById("indent-order-ul");
                    if (!expanded) {
                        checkboxes.style.display = "block";
                        expanded = true;
                    } else {
                        checkboxes.style.display = "none";
                        expanded = false;
                    }
                } else {
                    return false;
                }
                break;
            // case 'customer-order':
            //     // await FnshowCustByIndent();
            //     let checkboxes = document.getElementById("customer-order-ul" + product_rm_id);
            //     if (!expanded) {
            //         checkboxes.style.display = "block";
            //         expanded = true;
            //     } else {
            //         checkboxes.style.display = "none";
            //         expanded = false;
            //     }
            //     break;

            default:
                break;
        }


    }
    // Get indent nos
    const FnGetIndentNos = async () => {

        if (cmb_department !== '' && cmb_department !== '0') {
            resetGlobalQuery();
            globalQuery.columns = ['indent_no', 'indent_date', 'indent_version'];
            globalQuery.table = "stv_indent_master_summary"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
            globalQuery.conditions.push({ field: "indent_status", operator: "=", value: 'A' });
            globalQuery.conditions.push({ field: "department_id", operator: "=", value: cmb_department });
            globalQuery.orderBy = ["indent_master_id desc"];
            const indent_nos_list = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);

            if (indent_nos_list.length !== 0) {
                let listItems = indent_nos_list.map((item, index) => {
                    if (index === 0) {
                        return (
                            <>
                                <li className="item indent_order_no">
                                    <span className="checkbox">
                                        <input
                                            type="checkbox"
                                            id="all_indent_no_id"
                                            value={index}
                                            className="erp_radio_button filterCheckBox"
                                            onChange={(event) => checkIndentNos(event.target.value)}
                                            disabled={isApprove || keyForViewUpdate === 'view' ? true : false}
                                            optional='optional'
                                        />
                                    </span>
                                    <span className="item-text">All</span>
                                </li>
                                <li className="item indent_order_no">
                                    <span className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="IndentNoCheckBox"
                                            value={item.indent_no} indent_ver={item.indent_version}
                                            id={`filterCheck-${item.indent_no}`}
                                            className="erp_radio_button IndentNoCheckBox"
                                            onChange={(event) => checkIndentNos(event.target.value)}
                                            disabled={isApprove || keyForViewUpdate === 'view' ? true : false}
                                            optional='optional'

                                        />
                                    </span>
                                    <span className="item-text">{item.indent_no}</span>
                                </li>
                            </>
                        );
                    }
                    return (
                        <li className="item indent_order_no">
                            <span className="checkbox">
                                <input
                                    type="checkbox"
                                    fieldName={item.indent_no}
                                    name="IndentNoCheckBox"
                                    value={item.indent_no}
                                    id={`filterCheck-${item.indent_no}`}
                                    className="erp_radio_button IndentNoCheckBox"
                                    onChange={(event) => checkIndentNos(event.target.value)}
                                    disabled={isApprove || keyForViewUpdate === 'view' ? true : false}

                                />
                            </span>
                            <span className="item-text">{item.indent_no}</span>
                        </li>
                    );
                });
                setIndentNosList(listItems)
                setTimeout(() => {

                    const distinctNos = Array.from(new Set(selectIndentNo.split(':')));
                    distinctNos.forEach((item) => {
                        $(`input:checkbox[name="IndentNoCheckBox"][value="${item}"]`).attr('checked', true);
                    });

                }, 200);

            } else {
                setIndentNosList([]);
                setCheckedIndentNosLength(0)
            }
        }
    }
    const checkIndentNos = async (indent_order_no) => {
        if (parseInt(indent_order_no) === 0) {
            $('.IndentNoCheckBox').prop('checked', $('#all_indent_no_id').is(":checked"));
        }
        //Check Partiallly selected all or not if selected then check the check all radio of dispatch details
        const totalChkBoxes = document.querySelectorAll('input[name=IndentNoCheckBox]').length;
        const totalChkBoxesChked = document.querySelectorAll('input[name=IndentNoCheckBox]:checked').length;
        if (totalChkBoxes === totalChkBoxesChked) {
            document.getElementById('all_indent_no_id').checked = true;
        } else if (totalChkBoxes > totalChkBoxesChked) {
            document.getElementById('all_indent_no_id').checked = false;
        }
        setCheckedIndentNosLength(totalChkBoxesChked)
    }
    //fn to get podetails by indent
    async function ShowPODetailsByIndent() {
        let checkIsValidate = await validate.current.validateForm("PO_header_formId");
        if (checkIsValidate === true) {
            setIsLoading(true);
            let json = { 'indent_nos': [] }
            $("input:checkbox[name=IndentNoCheckBox]:checked").each(function () {
                json.indent_nos.push($(this).val())
            })
            if (json.indent_nos.length !== 0) {
                $('#error_indnetNos').hide();
                const formData = new FormData();
                formData.append('indentNos', JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };

                let apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtPurchaseDetails/FnShowIndentDetailsRecords/${COMPANY_ID}`, requestOptions)
                const response = await apicall.json()
                if (response.indentDetailsData.length !== 0) {
                    console.log(response);
                    const poDetailsData = FngroupedIndentProducts(response.indentDetailsData, json)
                    let modifyIndentDetails = poDetailsData.map(handleMaterialWiseCalculations);
                    setPODetailsData(modifyIndentDetails === null ? [] : modifyIndentDetails)
                    localStorage.setItem('filteredMaterialData', JSON.stringify(modifyIndentDetails))
                    if (chk_po_creation_type === 'M') {
                        setTimeout(() => {
                            modifyIndentDetails.forEach((item) => {
                                if (item.customer_id !== null) {
                                    FngetCustOrdNos(item, chk_po_creation_type)
                                }
                            }
                            );
                        }, 200);
                    } else {
                        modifyIndentDetails.forEach((item) => {

                            if (item.customer_id !== null && item.customer_id !== "") {
                                FnshowIndentCustOrdDt(item)
                                isCustCheckBoxChk(item.customer_id.split(':'), item.product_rm_id);
                            } else {
                                setCheckCustomerLenght(0);
                            }
                        })
                    }
                }
                setIsLoading(false);
            } else {
                setPODetailsData([]);
                setScheduleDataArray([]);
                setTaxationSummaryData([]);
                setIsLoading(false);
                $('#error_indnetNos').show();
            }
        }
    }

    const FngroupedIndentProducts = (indentDetailsData, indt_nosjson) => {

        indentDetailsData = indentDetailsData.map(item => ({
            ...item,
            product_rm_id: item.product_material_id,
            customer_order_Date: item.customer_order_date,
            product_rm_quantity: item.product_material_approved_quantity,
            product_rm_weight: item.product_material_approved_weight,

        }));
        const eixtingCumulativeData = [...po_details_data].filter(item => {
            return indentDetailsData.some(intItem => intItem.product_rm_id === item.product_rm_id);
        }) || [];

        try {
            // Use reduce to group data by HSN rate
            const groupedData = indentDetailsData.reduce((acc, item) => {
                const key = item.product_rm_id; // Group by product_rm_id
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(item);
                return acc;
            }, {});

            // Convert the grouped data object into an array of arrays
            const groupedDataArray = Object.values(groupedData);
            console.log('groupedDataArray: ', groupedDataArray);

            groupedDataArray.forEach(group => {
                const {
                    product_type_id,
                    product_type_name,
                    product_rm_id,
                    product_rm_name,
                    // customer_order_Date,
                    product_rm_packing_id,
                    product_material_unit_id,
                    product_rm_packing_name,
                    indent_details_id,
                    product_rm_hsn_sac_code_id,
                    product_rm_hsn_sac_code,
                    product_rm_hsn_sac_rate,
                    product_rm_std_weight,
                    product_rm_stock_unit_name,
                    product_rm_tech_spect,
                    so_sr_no
                } = group[0];

                const product_rm_quantity = group.reduce((sum, item) => sum + parseFloat(item.product_rm_quantity), 0);
                const product_rm_weight = group.reduce((total, item) => total + parseFloat(item.product_rm_weight), 0);

                // Concatenate string using reduce
                const concatenated_indent_no = group.reduce((concatenated, item) => {
                    const itemString = `${item.indent_no}`;
                    return concatenated + itemString + ':';
                }, '');

                // Remove the trailing separator (if any)
                const indent_no = concatenated_indent_no.slice(0, -1); // Remove the last two characters (': ')
                const indent_nos = indent_no.split(':');
                const concatenated_indent_date = group.reduce((concatenated, item) => {
                    const itemString = `${item.indent_date}`;
                    return concatenated + itemString + ':'; // Use ';' as a separator
                }, '');

                const indent_date = concatenated_indent_date.slice(0, -1);

                const concatenated_indent_version = group.reduce((concatenated, item) => {
                    const itemString = `${item.indent_version}`;
                    return concatenated + itemString + ':'; // Use ';' as a separator
                }, '');
                const indent_version = concatenated_indent_version.slice(0, -1);

                const concatenated_customer_id = group.reduce((concatenated, item) => {
                    const itemString = item.customer_id !== null ? `${item.customer_id}:` : '';
                    return concatenated + itemString;
                }, '');
                // get customer_order_id by multiple  indent data
                const customer_id = concatenated_customer_id.slice(0, -1);
                const uniqueCustomerOrderNo_date = new Set();
                const concatenated_customer_order_no_date = group.reduce((concatenated, item) => {
                    if (item.customer_order_no_date !== 'NA' && !uniqueCustomerOrderNo_date.has(item.customer_order_no_date)) {
                        uniqueCustomerOrderNo_date.add(item.customer_order_no_date);
                        const itemString = `${item.customer_order_no} (${item.customer_order_date})`;
                        return concatenated + itemString + ':'; // Use ';' as a separator
                    }
                    return concatenated;
                }, '');

                const customer_order_no_date = concatenated_customer_order_no_date.slice(0, -1);
                // get customer_order_no by multiple  indent data
                const uniqueCustomerOrderNos = new Set();
                const concatenated_customer_order_no = group.reduce((concatenated, item) => {
                    if (item.customer_order_no !== 'NA' && !uniqueCustomerOrderNos.has(item.customer_order_no)) {
                        uniqueCustomerOrderNos.add(item.customer_order_no);
                        const itemString = `${item.customer_order_no}`;
                        return concatenated + itemString + ':'; // Use ';' as a separator
                    }
                    return concatenated;
                }, '');

                const customer_order_no = concatenated_customer_order_no.slice(0, -1);

                // get customer_order_date by multiple  indent data
                const uniqueCustomerOrderDate = new Set();
                const concatenated_customer_order_date = group.reduce((concatenated, item) => {
                    if (item.customer_order_date !== 'NA' && !uniqueCustomerOrderDate.has(item.customer_order_date)) {
                        uniqueCustomerOrderDate.add(item.customer_order_date);
                        const itemString = `${item.customer_order_date}`;
                        return concatenated + itemString + ':'; // Use ';' as a separator
                    }
                    return concatenated;
                }, '');
                const customer_order_Date = concatenated_customer_order_date.slice(0, -1);

                // get indented_by_id by multiple  indent data
                const concatenated_indented_by_id = group.reduce((concatenated, item) => {
                    const itemString = `${item.indented_by_id}`;
                    return concatenated + itemString + ':'; // Use ';' as a separator
                }, '');
                const indented_by_id = concatenated_indented_by_id.slice(0, -1);

                // Iterate through the filteredIndentDetailsData array 
                let matchingPoItemIndex = -1;
                group.forEach(filteredItem => {
                    matchingPoItemIndex = eixtingCumulativeData.findIndex(
                        poItem => poItem.product_rm_id === filteredItem.product_rm_id
                    );
                    if (matchingPoItemIndex !== -1) {
                        eixtingCumulativeData[matchingPoItemIndex] = {
                            ...eixtingCumulativeData[matchingPoItemIndex],
                            product_rm_quantity: product_rm_quantity,
                            product_rm_weight: product_rm_weight,
                            indent_no: indent_no,
                            indent_nos: indent_nos,
                            indent_date: indent_date,
                            indent_version: indent_version,
                            customer_id: customer_id,
                            customer_order_no: customer_order_no,
                            customer_order_Date: customer_order_Date,
                            customer_order_no_date: customer_order_no_date,
                            indented_by_id: indented_by_id
                        };
                    } else {
                        //Fn to create po details object:
                        const groupObject = createGroupObject( // If no matching item is found, add the filtered item to cumulativeData
                            indent_no,
                            indent_nos,
                            indent_date,
                            indent_version,
                            product_type_id,
                            product_type_name,
                            product_rm_id,
                            product_rm_name,
                            customer_id,
                            customer_order_no,
                            customer_order_Date,
                            customer_order_no_date,
                            indented_by_id,
                            product_rm_quantity,
                            product_rm_weight,
                            txt_schedule_date,
                            product_rm_packing_id,
                            product_rm_packing_name,
                            product_material_unit_id,
                            indent_details_id,
                            product_rm_hsn_sac_code_id,
                            product_rm_hsn_sac_code,
                            product_rm_hsn_sac_rate,
                            product_rm_std_weight,
                            product_rm_stock_unit_name,
                            product_rm_tech_spect,
                            so_sr_no);
                        eixtingCumulativeData.push(groupObject);
                    }
                });
            });
            console.log("cumulativeData: ", eixtingCumulativeData);
            return eixtingCumulativeData;
        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }
    }
    function createGroupObject(
        indent_no,
        indent_nos,
        indent_date,
        indent_version,
        product_type_id,
        product_type_name,
        product_rm_id,
        product_rm_name,
        customer_id,
        customer_order_no,
        customer_order_Date,
        customer_order_no_date,
        indented_by_id,
        product_rm_quantity,
        product_rm_weight,
        txt_schedule_date,
        product_rm_packing_id,
        product_rm_packing_name,
        product_material_unit_id,
        indent_details_id,
        product_rm_hsn_sac_code_id,
        product_rm_hsn_sac_code,
        product_rm_hsn_sac_rate,
        product_rm_std_weight,
        product_rm_stock_unit_name,
        product_rm_tech_spect,
        so_sr_no
    ) {
        return {
            indent_no,
            indent_nos,
            indent_date,
            indent_version,
            product_type_id,
            product_type_name,
            product_rm_id,
            product_rm_name,
            customer_id,
            customer_order_no,
            customer_order_Date,
            customer_order_no_date,
            indented_by_id,
            product_rm_quantity,
            product_rm_rate: 0,
            material_basic_amount: 0,
            material_discount_percent: 0,
            material_discount_amount: 0,
            material_taxable_amount: 0,
            material_cgst_percent: 0,
            material_cgst_total: 0,
            material_sgst_percent: 0,
            material_sgst_total: 0,
            material_igst_percent: 0,
            material_igst_total: 0,
            material_total_amount: 0,
            product_rm_weight: product_rm_weight,
            material_freight_amount: 0,
            material_schedule_date: txt_schedule_date,
            product_rm_approved_quantity: 0,
            product_rm_approved_weight: 0,
            product_rm_rejected_quantity: 0,
            product_rm_rejected_weight: 0,
            material_po_approval_remark: '',
            approved_by_id: '',
            approved_date: '',
            product_rm_conversion_factor: 0,
            lead_time: 0,
            product_rm_packing_id,
            product_rm_packing_name,
            product_rm_stock_unit_id: product_material_unit_id,
            purchase_order_item_status: 'P',
            pree_closed_quantity: 0,
            pree_closed_weight: 0,
            purchase_return_quantity: 0,
            purchase_return_weight: 0,
            pending_quantity: 0,
            pending_weight: 0,
            excess_quantity: 0,
            excess_weight: 0,
            production_issue_quantity: 0,
            production_issue_weight: 0,
            production_issue_return_quantity: 0,
            production_issue_return_weight: 0,
            production_issue_rejection_quantity: 0,
            production_issue_rejection_weight: 0,
            assembly_production_issue_quantity: 0,
            assembly_production_issue_weight: 0,
            sales_quantity: 0,
            sales_weight: 0,
            sales_return_quantity: 0,
            sales_return_weight: 0,
            sales_rejection_quantity: 0,
            sales_rejection_weight: 0,
            transfer_issue_quantity: 0,
            transfer_issue_weight: 0,
            transfer_receipt_quantity: 0,
            transfer_receipt_weight: 0,
            outsources_out_quantity: 0,
            outsources_out_weight: 0,
            outsources_in_quantity: 0,
            outsources_in_weight: 0,
            outsources_rejection_quantity: 0,
            outsources_rejection_weight: 0,
            loan_receipt_quantity: 0,
            loan_receipt_weight: 0,
            loan_issue_quantity: 0,
            loan_issue_weight: 0,
            cancel_quantity: 0,
            cancel_weight: 0,
            difference_quantity: 0,
            difference_weight: 0,
            indent_details_id,
            product_rm_hsn_sac_code_id,
            product_rm_hsn_sac_code,
            product_rm_hsn_sac_rate,
            product_rm_std_weight,
            product_rm_stock_unit_name,
            product_rm_tech_spect,
            so_sr_no,
            prev_indent_quantity: product_rm_quantity,
            prev_indent_weight: product_rm_weight,
        };
    }

    // --------------------------------------- PO Printout generate Start ------------------------------------------------------------------

    const purchaseOrderInvoiceContent = {
        supplierDetails: {
            name: '',
            address: '',
            state: '',
            GSTIN: '',
            pan_no: '',
            contact: '',
            schedule_date: '',
            city: '',
        },
        customerDetails: {
            customer: '',
            address: '',
            state: '',
            GSTIN: '',
            pan_no: '',
            contact: '',
            email: ''
        },
        purchaseDetails: {
            purchase_order_no: '',
            purchase_order_date: '',
            purchase_order_version: '',
            purchase_order_type: '',
        },

        ourdetails: {
            expected_branch_name: '',
            expected_schedule_date: '',
            expected_branch_city_name: '',
            expected_branch_state_name: '',
            expected_branch_phone_no: '',
            expected_branch_address1: '',
            expected_branch_address2: '',
        },

        footer: {
            cgst_words: '',
            sgst_words: '',
            igst_words: '',
            grand_total_words: '',

            basic_total: '',
            cgst_total: '',
            sgst_total: '',
            igst_total: '',
            taxable_total: '',
            grand_total: '',
            packing_amount: '',
            discount_percent: '',
            discount_amount: '',
            freight_amount: '',
            other_amount: '',
            remark: '',

        },

        items: [],
        paymentTerms: [],
        PurchaseOrderTerms: [],
        contactDetails: [],

    };

    const printInvoice = async (openPrintModal) => {

        //Purchase details
        purchaseOrderInvoiceContent.purchaseDetails.purchase_order_no = PurchaseOrderMasterJson.purchase_order_no
        purchaseOrderInvoiceContent.purchaseDetails.purchase_order_date = validateNumberDateInput.current.formatDateToDDMMYYYY(PurchaseOrderMasterJson.purchase_order_date)
        purchaseOrderInvoiceContent.purchaseDetails.purchase_order_version = PurchaseOrderMasterJson.purchase_order_version
        purchaseOrderInvoiceContent.purchaseDetails.purchase_order_type = PurchaseOrderMasterJson.purchase_order_creation_type_desc

        //supplier details
        purchaseOrderInvoiceContent.supplierDetails.name = PurchaseOrderMasterJson.supplier_name
        purchaseOrderInvoiceContent.supplierDetails.address = PurchaseOrderMasterJson.supp_branch_address1
        purchaseOrderInvoiceContent.supplierDetails.state = PurchaseOrderMasterJson.state_name
        purchaseOrderInvoiceContent.supplierDetails.city = $("#cmb_supplier_city_id option:selected").text();
        purchaseOrderInvoiceContent.supplierDetails.GSTIN = PurchaseOrderMasterJson.supp_branch_gst_no
        purchaseOrderInvoiceContent.supplierDetails.contact = PurchaseOrderMasterJson.supp_branch_phone_no
        purchaseOrderInvoiceContent.supplierDetails.pan_no = PurchaseOrderMasterJson.supp_branch_pan_no
        purchaseOrderInvoiceContent.supplierDetails.schedule_date = validateNumberDateInput.current.formatDateToDDMMYYYY(PurchaseOrderMasterJson.expected_schedule_date)

        //outDetails
        purchaseOrderInvoiceContent.ourdetails.expected_branch_name = PurchaseOrderMasterJson.expected_branch_name
        purchaseOrderInvoiceContent.ourdetails.expected_schedule_date = PurchaseOrderMasterJson.expected_schedule_date
        purchaseOrderInvoiceContent.ourdetails.expected_branch_state_name = PurchaseOrderMasterJson.expected_branch_state_name
        purchaseOrderInvoiceContent.ourdetails.expected_branch_city_name = PurchaseOrderMasterJson.expected_branch_city_name
        purchaseOrderInvoiceContent.ourdetails.expected_branch_address1 = PurchaseOrderMasterJson.expected_branch_address1
        purchaseOrderInvoiceContent.ourdetails.expected_branch_address2 = PurchaseOrderMasterJson.expected_branch_address2
        purchaseOrderInvoiceContent.ourdetails.expected_branch_phone_no = PurchaseOrderMasterJson.expected_branch_phone_no

        //Customer details
        purchaseOrderInvoiceContent.customerDetails.customer = PurchaseOrderMasterJson.customer_name
        purchaseOrderInvoiceContent.customerDetails.address = PurchaseOrderMasterJson.cust_branch_address1
        purchaseOrderInvoiceContent.customerDetails.state = PurchaseOrderMasterJson.customer_state_name
        purchaseOrderInvoiceContent.customerDetails.GSTIN = PurchaseOrderMasterJson.cust_branch_gst_no
        purchaseOrderInvoiceContent.customerDetails.pan_no = PurchaseOrderMasterJson.cust_branch_pan_no
        purchaseOrderInvoiceContent.customerDetails.contact = PurchaseOrderMasterJson.cust_branch_phone_no
        purchaseOrderInvoiceContent.customerDetails.email = PurchaseOrderMasterJson.customer_email

        //footer details    
        purchaseOrderInvoiceContent.footer.basic_total = PurchaseOrderMasterJson.basic_total
        purchaseOrderInvoiceContent.footer.cgst_total = PurchaseOrderMasterJson.cgst_total
        purchaseOrderInvoiceContent.footer.sgst_total = PurchaseOrderMasterJson.sgst_total
        purchaseOrderInvoiceContent.footer.igst_total = PurchaseOrderMasterJson.igst_total
        purchaseOrderInvoiceContent.footer.taxable_total = PurchaseOrderMasterJson.taxable_total
        purchaseOrderInvoiceContent.footer.grand_total = PurchaseOrderMasterJson.grand_total
        purchaseOrderInvoiceContent.footer.packing_amount = PurchaseOrderMasterJson.packing_amount
        purchaseOrderInvoiceContent.footer.freight_amount = PurchaseOrderMasterJson.freight_amount

        purchaseOrderInvoiceContent.footer.discount_percent = PurchaseOrderMasterJson.po_discount_percent
        purchaseOrderInvoiceContent.footer.discount_amount = PurchaseOrderMasterJson.po_discount_amount
        purchaseOrderInvoiceContent.footer.other_amount = PurchaseOrderMasterJson.other_amount
        purchaseOrderInvoiceContent.footer.remark = PurchaseOrderMasterJson.remark

        for (let index = 0; index < po_details_data.length; index++) {
            const element = po_details_data[index];

            const detailsData = {
                sr_no: element.sr_no,
                material_name: element.product_material_print_name,
                // material_tech_spect: element.product_rm_tech_spect,
                // product_material_type_name: element.product_material_type_name,
                // product_make_name: element.product_make_name,
                // product_material_grade_name: element.product_material_grade_name,
                // // Not show the NA in PrintOut.
                material_tech_spect: element.product_rm_tech_spect !== '' && element.product_rm_tech_spect !== 'NA' && element.product_rm_tech_spect !== null ? element.product_rm_tech_spect : '',
                product_material_type_name: element.product_material_type_name !== '' && element.product_material_type_name !== 'NA' && element.product_material_type_name !== null ? element.product_material_type_name : '',
                product_make_name: element.product_make_name !== '' && element.product_make_name !== 'NA' && element.product_make_name !== null ? element.product_make_name : '',
                product_material_grade_name: element.product_material_grade_name === '' && element.product_material_grade_name !== 'NA' && element.product_material_grade_name !== null ? element.product_material_grade_name : '',

                hsn_code: element.product_material_hsn_sac_code,
                quantity: element.product_rm_quantity,
                unit: element.product_rm_stock_unit_name,
                rate: element.product_rm_rate,
                discount_percent: element.material_discount_percent,
                material_basic_amount: element.material_basic_amount,
                cgst_percent: element.material_cgst_percent,
                sgst_percent: element.material_sgst_percent,
                igst_percent: element.material_igst_percent,
                material_total_amount: element.material_total_amount,
                department_name: element.department_name,
                company: element.company_name,
                company_branch: element.company_branch_name,
                address: element.company_address1,
                phone: element.company_phone_no,
                cell: element.company_cell_no,
                email: element.company_EmailId,
                website: element.company_website,
                GSTIN: element.company_pan_no,
                pan_no: element.company_pan_no,
                state_name: element.company_state,
                company_pincode: element.company_pincode,
            }
            purchaseOrderInvoiceContent.items.push(detailsData)
        }
        //Supplier Contact Details
        const checkboxes = $('.selectSuppContactPerson:checked');
        checkboxes.each(function () {
            let selectedContact_id = parseInt($(this).val());
            const supplierContact = suppContactDetails.find(contactPerson => contactPerson.supplier_contact_id === selectedContact_id);
            if (supplierContact) {
                const contactData = {
                    supp_contact_person: supplierContact.supp_contact_person,
                    supp_contact_no: supplierContact.supp_contact_no,
                }
                purchaseOrderInvoiceContent.contactDetails.push(contactData)
            }
        });

        //PO Payment Terms
        for (let index = 0; index < existingPaymentTermsData.length; index++) {
            const element = existingPaymentTermsData[index];

            const detailsData = {

                payment_terms_name: element.payment_terms_name,
                payment_terms_days: element.payment_terms_days,

            }
            purchaseOrderInvoiceContent.paymentTerms.push(detailsData)
        }
        //PO common Terms
        for (let index = 0; index < existingCommonTermsData.length; index++) {
            const element = existingCommonTermsData[index];

            const detailsData = {

                purchase_order_terms_parameters_name: element.purchase_order_terms_parameters_name,
                purchase_order_terms_parameters_value: element.purchase_order_terms_parameters_value,

            }
            purchaseOrderInvoiceContent.PurchaseOrderTerms.push(detailsData)
        }

        if (openPrintModal) {
            navigate('/Invoice', { state: { invoiceData: purchaseOrderInvoiceContent, navigationLink: '/Transactions/TPurchaseOrder/ManualPO/FrmPurchaseOrderEntry', invoiceType: 'PO', title: 'Purchase Order', idList: idList, keyForViewUpdate: keyForViewUpdate, compType: compType } });

        } else {
            // return the html code.
            return renderToString(<PurchaseOrderInvoice invoiceContent={purchaseOrderInvoiceContent} />);
        }

    };

    // --------------------------------------- PO Printout generate End ------------------------------------------------------------------
    // --------------------------------------- PO Import Start ------------------------------------------------------------------

    const onImportClick = () => {
        importFile.current.click();
    };

    const importedExcelFile = async (event) => {
        try {
            if (event.target.files && event.target.files.length > 0) {
                setIsLoading(true)
                var file = event.target.files[0];

                const formData = new FormData();
                formData.append(`file`, file)
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtPurchaseDetails/FnReadExcel`, requestOptions)
                const fetchRes = await apicall.json();

                console.log(fetchRes)
                if (fetchRes.success === 1) {
                    if (fetchRes.data.length !== 0) {
                        let creation_type = '';
                        // Set order details data.
                        const readedOrderDetailsFromExcel = fetchRes.data?.map(dataRow => {
                            const orderDetail = fetchRes.columns.reduce((acc, key, secIndex) => {
                                acc[key] = dataRow[secIndex];
                                return acc;
                            }, {});
                            if (isNaN(parseFloat(orderDetail.product_rm_quantity))) {
                                orderDetail.product_rm_quantity = 0;
                            }
                            return orderDetail;
                        });

                        for (let formFieldIndex = 0; formFieldIndex < fetchRes.formFieldData.length; formFieldIndex++) {
                            const element = fetchRes.formFieldData[formFieldIndex];
                            if (element !== "") {
                                const field = element.split(':');
                                const fieldName = field[0].trim();
                                const fieldVal = field[1].trim();
                                let comboVal;
                                if (fieldVal !== "" && fieldName === 'Po Creation Type' || fieldName === 'Po Life' || fieldName === 'Company city' || fieldName === 'Product Type' || fieldName === 'Supplier' || fieldName === 'Purchase Process' || fieldName === 'Supplier State' || fieldName === 'Company Branch' || fieldName === 'Department' || fieldName === 'Company Branch' || fieldName === 'Supplier City' || fieldName === 'Company Branch State' || fieldName === 'Mail Status') {
                                    if (fieldVal !== "") {
                                        const splitVal = fieldVal.split('(')
                                        comboVal = splitVal[1].replace(')', '')
                                    }
                                }
                                switch (fieldName) {
                                    case 'Po Creation Type':
                                        setPO_creation_type(comboVal)
                                        creation_type = comboVal;
                                        if (comboVal === 'A') {
                                            $('#add-material-id').hide();
                                        } else {
                                            $('#add-material-id').show();
                                        }
                                        break;
                                    case 'Po Life':
                                        setPO_life(comboVal)
                                        break;
                                    case 'Company city':
                                        setExpected_branch_city_id(comboVal)
                                        break;
                                    // case 'Product Type':
                                    //     setProductTypeID(comboVal)
                                    //     // comboOnChange('productType');
                                    //     break;
                                    case 'Supplier':
                                        setSupplier_id(comboVal)
                                        comboOnChange('supplier')
                                        break;
                                    case 'Purchase Process':
                                        setPurchase_process(comboVal)
                                        break;
                                    case 'Po Status':
                                        setPO_status("P")
                                        break;
                                    case 'Department':
                                        setDepartmentId(comboVal)
                                        break;
                                    case 'Company Branch':
                                        setExpected_branch_id(comboVal)
                                        comboOnChange('expt_branch')
                                        break;
                                    case 'Status Remark':
                                        setStatus_remark(fieldVal)
                                        break;
                                    case 'Mail Status':
                                        setPO_mail_sent_status("P")
                                        break;
                                }
                            }
                        }

                        // setPOVersion(fetchRes.Purchase_order_version)
                        setPODetailsData(() => {
                            let modifiedImportData = readedOrderDetailsFromExcel.map((ordDetail) => ({
                                ...ordDetail,
                                purchase_order_item_status: 'P',
                                product_rm_id: ordDetail.product_rm_id,
                                purchase_order_master_transaction_id: 0,
                                purchase_order_details_transaction_id: 0,
                                indent_nos: creation_type === 'A' ? ordDetail.indent_no.split(':') : null,
                                prev_indent_quantity: ordDetail.product_rm_quantity,
                                prev_indent_weight: ordDetail.product_rm_weight,
                            }))

                            modifiedImportData = modifiedImportData.map(handleMaterialWiseCalculations)
                            localStorage.setItem('filteredMaterialData', JSON.stringify(modifiedImportData))
                            modifiedImportData.map((item) => {
                                setselectIndentNo((prevOptions) => prevOptions + ':' + item.indent_no)
                            })
                            return modifiedImportData;

                        })
                        calculateGrandTotal();

                        categoryDisplay();

                        if (creation_type === 'M') {
                            setTimeout(() => {
                                importedData.forEach((item) => {
                                    if (item.customer_id !== null) {
                                        FngetCustOrdNos(item, creation_type)
                                    }
                                });
                            }, 200);
                        } else {
                            importedData.forEach((item) => {
                                if (item.customer_id !== null) {
                                    FnshowIndentCustOrdDt(item)
                                    isCustCheckBoxChk(item.customer_id.split(':'), item.product_rm_id);
                                } else {
                                    setCheckCustomerLenght(0);
                                }
                            })
                        }
                        setIsLoading(false)
                        return fetchRes;
                    }
                } else {
                    setErrMsg(fetchRes.error)
                    setIsLoading(false)
                    setShowErrorMsgModal(true)
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    // --------------------------------------- PO Import End ------------------------------------------------------------------

    // --------------------------------------- PO EXcelToExport Start ------------------------------------------------------------------
    let requiredColsForExport = ['sr_no', 'product_type_id', 'product_type_name', 'product_rm_id', 'product_rm_name', 'product_rm_tech_spect', 'product_rm_hsn_sac_code', 'product_rm_quantity', 'product_rm_rate',
        'material_basic_amount', 'material_discount_percent', 'material_discount_amount',
        'material_taxable_amount', 'material_cgst_percent', 'material_cgst_total', 'material_sgst_percent',
        'material_sgst_total', 'material_igst_percent', 'material_igst_total', 'material_total_amount', 'product_rm_weight', 'material_freight_amount', 'material_schedule_date',
        'product_rm_approved_quantity', 'product_rm_approved_weight', 'product_rm_rejected_quantity', 'product_rm_rejected_weight', 'material_po_approval_remark'
        , 'department_id', 'indented_by_id', 'indent_date', 'indent_no', 'indent_version', 'approved_by_id', 'approved_date', 'product_rm_std_weight', 'product_rm_conversion_factor', 'lead_time', 'product_rm_stock_unit_name', 'product_rm_packing_name', 'product_rm_stock_unit_id',
        'product_rm_packing_id', 'product_rm_hsn_sac_code_id', 'product_rm_hsn_sac_rate', 'purchase_order_item_status',
        'pree_closed_quantity', 'pree_closed_weight', 'purchase_return_quantity', 'purchase_return_weight', 'customer_id', 'customer_order_no', 'customer_order_Date',
        'pending_quantity', 'pending_weight', 'excess_quantity', 'excess_weight', 'production_issue_quantity', 'production_issue_weight',
        'production_issue_return_quantity', 'production_issue_return_weight', 'production_issue_rejection_quantity', 'production_issue_rejection_weight',
        'assembly_production_issue_quantity', 'assembly_production_issue_weight', 'sales_quantity', 'sales_weight',
        'sales_return_quantity', 'sales_return_weight', 'sales_rejection_quantity', 'sales_rejection_weight', 'transfer_issue_quantity',
        'transfer_issue_weight', 'transfer_receipt_quantity', 'transfer_receipt_weight', 'outsources_out_quantity', 'outsources_out_weight',
        'outsources_in_quantity', 'outsources_in_weight', 'outsources_rejection_quantity', 'outsources_rejection_weight', 'loan_receipt_quantity',
        'loan_receipt_weight', 'loan_issue_quantity', 'loan_issue_weight', 'cancel_quantity', 'cancel_weight', 'difference_quantity',
        'difference_weight',
    ];
    const ExportToExcel = () => {

        const catCount = sessionStorage.getItem('compRMCatCount')
        var counter = 1;
        if (po_details_data.length !== 0) {
            var jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', editable_cols: [5, 6, 8] }
            jsonToExportExcel.filtrKeyValue['0'] = "Po Creation Type" + ' : ' + $('input[name="chk_po_creation_type"]:checked').attr("lbl") + "(" + chk_po_creation_type + ")";
            // jsonToExportExcel.filtrKeyValue['1'] = "Product Type" + ' : ' + $('#cmb_product_type_id').find(":selected").text() + "(" + cmb_product_type_id + ")";
            jsonToExportExcel.filtrKeyValue['1'] = "Purchase Order No" + ' : ' + txt_PO_no;
            jsonToExportExcel.filtrKeyValue['2'] = "Purchase Order Version" + ' : ' + txt_po_version;
            jsonToExportExcel.filtrKeyValue['3'] = "Purchase Order Date" + ' : ' + validateNumberDateInput.current.formatDateToDDMMYYYY(txt_po_date);
            jsonToExportExcel.filtrKeyValue['4'] = "Supplier" + ' : ' + $('#cmb_supplier_id').find(":selected").text() + "(" + cmb_supplier_id + ")";
            jsonToExportExcel.filtrKeyValue['5'] = "Supplier State" + ' : ' + $('#cmb_supplier_state_id').find(":selected").text() + "(" + cmb_supplier_state_id + ")";
            jsonToExportExcel.filtrKeyValue['6'] = "Supplier City" + ' : ' + $('#cmb_supplier_city_id').find(":selected").text() + "(" + cmb_supplier_city_id + ")";
            jsonToExportExcel.filtrKeyValue['7'] = "Po Life" + ' : ' + $('input[name="chek_po_life"]:checked').attr("lbl") + "(" + chek_po_life + ")";
            jsonToExportExcel.filtrKeyValue['8'] = "Purchase Process" + ' : ' + $('input[name="chk_purchase_process"]:checked').attr("lbl") + "(" + chk_purchase_process + ")";
            jsonToExportExcel.filtrKeyValue['9'] = "Company Branch" + ' : ' + $('#cmb_expected_branch_id').find(":selected").text() + "(" + cmb_expected_branch_id + ")";
            jsonToExportExcel.filtrKeyValue['10'] = "Company Branch State" + ' : ' + $('#cmb_expected_branch_state_id').find(":selected").text() + "(" + cmb_expected_branch_state_id + ")";
            jsonToExportExcel.filtrKeyValue['11'] = "Company city" + ' : ' + $('#cmb_expected_branch_city_id').find(":selected").text() + "(" + cmb_expected_branch_city_id + ")";
            jsonToExportExcel.filtrKeyValue['12'] = "Approved By : " + (cmb_approved_by_id === '0' || cmb_approved_by_id === '' || cmb_approved_by_id === null ? '' : $('#cmb_approved_by_id').find(":selected").text() + "(" + cmb_approved_by_id + ")");
            jsonToExportExcel.filtrKeyValue['13'] = "Department : " + $('#cmb_department').find(":selected").text() + "(" + cmb_department + ")";
            jsonToExportExcel.filtrKeyValue['14'] = "Approved Date : " + (txt_approved_date === null || txt_approved_date === '' ? "" : validateNumberDateInput.current.formatDateToDDMMYYYY(txt_approved_date));
            jsonToExportExcel.filtrKeyValue['15'] = "Expected Schedule Date : " + validateNumberDateInput.current.formatDateToDDMMYYYY(txt_schedule_date);
            jsonToExportExcel.filtrKeyValue['16'] = "Po Status : " + $('#chk_po_status').find(":selected").text() + "(" + chk_po_status + ")";
            jsonToExportExcel.filtrKeyValue['17'] = "Status Remark" + ' : ' + txt_status_remark;
            jsonToExportExcel.filtrKeyValue['18'] = "Mail Status" + ' : ' + $('input[name="chk_po_mail_sent_status"]:checked').attr("lbl") + "(" + chk_po_mail_sent_status + ")";

            for (let col = 0; col < requiredColsForExport.length; col++) {
                jsonToExportExcel.columns.push({ "Headers": requiredColsForExport[col], "accessor": requiredColsForExport[col] })
            }

            po_details_data.map((orderDetail, Index) => {
                const keysConvertedOrderDetail = {
                    ...orderDetail,
                    sr_no: Index + 1,
                    department_id: cmb_department,
                };
                jsonToExportExcel['allData'][Index] = keysConvertedOrderDetail;
            });
            jsonToExportExcel['headings']['ReportName'] = "Purchase Order"
            jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
            jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
            console.log("jsonToExportExcel: ", jsonToExportExcel)
            exlsExp.current.excel(jsonToExportExcel, txt_PO_no + "@" + COMPANY_ID)
        } else {
            setErrMsg('Please add at least one material!... ')
            setShowErrorMsgModal(true)
        }
    }
    // --------------------------------------- PO EXcelToExport End ------------------------------------------------------------------
    //---------------------------------------------------- Accordian data load starts --------------------------------------------------------------------------------
    const FnLoadAccordionData = async (eventKey) => {
        let checkedLength = 0;
        switch (eventKey) {
            // case 'purchaseOrderTaxation':
            //     checkedLength = $("input:checkbox[name=selectPayTerm]:checked").length;
            //     if (checkedLength === 0) {
            //         await FnShowPurchaseOrderTaxation();
            //     }
            //     break;
            case 'paymentTerms':

                checkedLength = $("input:checkbox[name=selectPayTerm]:checked").length;
                if (checkedLength === 0) {
                    await FnShowPaymentTermsRecrds();
                }
                break;
            case 'purchaseOrderTerms':
                checkedLength = $("input:checkbox[name=selectPOTerm]:checked").length;
                if (checkedLength === 0) {
                    await FnShowPOTermsRecords();
                }
                break;
            case 'documentList':
                await showDocumentRecords();
                break;
            default:
                break;
        }
        if (keyForViewUpdate === "view" || keyForViewUpdate === "approve" || keyForViewUpdate === "delete") {
            $('input[type="checkbox"]').prop('disabled', true);
        }
    }

    // const FnShowPurchaseOrderTaxation = () => { }
    //---------------------------------------------------- Accordian data load Ends --------------------------------------------------------------------------------
    //--------------------------------- Document List Starts--------------------------------------------------

    // Fn for get document list
    const showDocumentRecords = async () => {
        try {
            if (COMPANY_ID !== null) {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${documentGroup}&group_id=${txt_PO_no !== 0 ? txt_PO_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    setDocumentData(resp.content);
                }
            }

        } catch (error) {
            console.log("error: ", error)
        }
    }

    // Fn for get document table
    const renderDocumentTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='document-table' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Group Id</th>
                        <th className="erp_table_th">Document Group</th>
                        <th className="erp_table_th">Document Name</th>
                        <th className="erp_table_th">Registration No</th>
                        <th className="erp_table_th">Registration Date</th>
                        <th className="erp_table_th">Renewal Date</th>
                        <th className="erp_table_th">Document Path</th>
                        <th className="erp_table_th">Remark</th>
                        <th className="erp_table_th">File Name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        docData.map((docItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td"> {docItem.group_id} </td>
                                <td className="erp_table_td">{docItem.document_group}</td>
                                <td className="erp_table_td">{docItem.document_name}</td>
                                <td className="erp_table_td ">{docItem.document_registration_no}</td>
                                <td className="erp_table_td ">{docItem.document_registration_date}</td>
                                <td className="erp_table_td ">{docItem.document_renewal_date}</td>
                                <td className="erp_table_td ">{docItem.document_path}</td>
                                <td className="erp_table_td ">{docItem.remark}</td>
                                <td className="erp_table_td ">
                                    <MDTypography component="label" className="erp-form-label" variant="button" id="logoFile" fontWeight="regular" color="info" >
                                        <Link to="#" onClick={() => { fetchDocument(docItem) }}>
                                            {docItem.file_name}
                                        </Link></MDTypography>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [docData]);



    // Fn for get document download
    const fetchDocument = async (docItem) => {
        try {

            const formData = new FormData()
            const data = {
                document_group: docItem.document_group,
                group_id: docItem.group_id,
                document_path: docItem.document_path
            }
            formData.append(`getFile`, JSON.stringify(data))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
            const blob = await getDocApiCall.blob()

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${docItem.file_name}`,);
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);


        } catch (error) {
            console.log("error: ", error)
        }
    }
    //--------------------------------- Document List Ends------------------------------------------------------------------------------------------------
    //--------------------------------- Delete PO Starts------------------------------------------------------------------------------------------------

    async function deletePO() {
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/PtPurchaseDetails/FnDeleteRecord/${idList.purchase_order_version}/${COMPANY_ID}?purchase_order_no=${idList.purchase_order_no}&user_name=${UserName}`, method)
            const responce = await deleteApiCall.json();
            setShow(false)
            console.log("PO Transaction Deleted: ", responce);
            if (responce.success == '1') {
                setShow(false)
                moveToListing();
            }

        } catch (error) {
            console.log("error: ", error)
        }
    }
    //--------------------------------- Delete PO Ends------------------------------------------------------------------------------------------------

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ExcelExport ref={exlsExp} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />

            <DashboardLayout>
                <>

                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span id="spinner_text" className="text-dark">Loading...</span>
                            </div>
                        </div> :
                        ''}


                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg main_heding'>
                                Purchase Order {ActionType()}</label>
                        </div>
                        <form id="PO_header_formId">
                            <div className="row p-1">
                                {/* //first column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">PO Creation Type </Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Direct" type="radio" lbl="Direct" value="M" name="chk_po_creation_type" checked={chk_po_creation_type === "M"} onClick={() => { setPO_creation_type("M"); comboOnChange('by_direct') }} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="Indent" type="radio" lbl="Indent" value="A" name="chk_po_creation_type" checked={chk_po_creation_type === "A"} onClick={() => { setPO_creation_type("A"); comboOnChange('by_indent') }} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Product Type<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_product_type_id" className="form-select form-select-sm" value={cmb_product_type_id} onChange={(e) => { setProductTypeID(e.target.value); comboOnChange('productType'); validateFields(); }}  >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {producttypeOptions.length !== 0 ?
                                                    <>
                                                        {producttypeOptions?.map(poTypeId => (
                                                            <option value={poTypeId.field_id} shortname={poTypeId.product_type_short_name}>{poTypeId.field_name}</option>
                                                        ))}
                                                    </>
                                                    : ''
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div> */}
                                    {/* </div> */}
                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">PO No,Version & Date</Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <div className="row">
                                                <div className='col-12 col-md-6 pe-md-0'>
                                                    <Form.Control type="text" id="txt_PO_no" className="erp_input_field" value={txt_PO_no} optional='optional' disabled />

                                                </div>
                                                <div className="col-12 col-md-2 pt-md-0 pt-3">
                                                    <Form.Control type="text" id='txt_po_version' className="erp_input_field" value={txt_po_version} optional='optional' disabled />

                                                </div>
                                                <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                    <Form.Control type="date" id='txt_po_date' className="erp_input_field" value={txt_po_date} onChange={e => { setPODate(e.target.value); validateFields(); }} min={currentDate} />
                                                    <MDTypography variant="button" id="error_txt_po_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Supplier<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-7 col-10'>
                                            <select id="cmb_supplier_id" className="form-select form-select-sm" value={cmb_supplier_id} onChange={() => { comboOnChange("supplier"); validateFields(); }}  >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {supplierOptions.length !== 0 ?
                                                    <>
                                                        {supplierOptions?.map(cmb_supplier_id => (
                                                            <option value={cmb_supplier_id.field_id} suppl_state={cmb_supplier_id.supp_branch_state_id} suppl_city={cmb_supplier_id.supp_branch_city_id}>{cmb_supplier_id.field_name}</option>
                                                        ))}
                                                    </>
                                                    : ''
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_supplier_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col-sm-1 col-2">
                                            <Tooltip title="Refresh" placement="top">
                                                <MDTypography className={`  ${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                                                    <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_supplier') } }} />
                                                </MDTypography>
                                            </Tooltip>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Supplier State<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">

                                            <select id="cmb_supplier_state_id" className="form-select form-select-sm" value={cmb_supplier_state_id} onChange={() => { comboOnChange("supplier_state"); validateFields(); }} optional='optional' disabled >
                                                <option value="">Select</option>
                                                {supplierStateOptions.length !== 0 ?
                                                    <>
                                                        {supplierStateOptions?.map(cmb_supplier_state_id => (
                                                            <option value={cmb_supplier_state_id.field_id}>{cmb_supplier_state_id.field_name}</option>
                                                        ))}
                                                    </>
                                                    : ''
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_supplier_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Supplier City</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_supplier_city_id" className="form-select form-select-sm" value={cmb_supplier_city_id} onChange={(e) => { setSupplier_city_id(e.target.value); comboOnChange('supplier_city'); validateFields(); }} optional='optional' disabled>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {supplierCityOpts.length !== 0 ?
                                                    <>
                                                        {supplierCityOpts?.map(cmb_supplier_city_id => (
                                                            <option value={cmb_supplier_city_id.field_id}>{cmb_supplier_city_id.field_name}</option>
                                                        ))}
                                                    </>
                                                    : ''
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_supplier_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">PO Life</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Close" lbl='Close' type="radio" value="C" name="chek_po_life" checked={chek_po_life === "C"} onClick={() => { setPO_life("C"); }} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="Open" lbl='Open' type="radio" value="O" name="chek_po_life" checked={chek_po_life === "O"} onClick={() => { setPO_life("O"); }} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Purchase Process</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Mannual" lbl="Mannual" type="radio" value="M" name="chk_purchase_process" checked={chk_purchase_process === "M"} onClick={() => { setPurchase_process("M"); }} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="Auto" lbl="Auto" type="radio" value="A" name="chk_purchase_process" checked={chk_purchase_process === "A"} onClick={() => { setPurchase_process("A"); }} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Schedule Date<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="date" id='txt_schedule_date' className="erp_input_field" value={txt_schedule_date} onChange={(e) => { setScheduleDate(e.target.value); validateFields(); }} min={currentDate} />
                                            <MDTypography variant="button" id="error_txt_schedule_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>
                                {/* //second column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Company Branch<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-7 col-10'>
                                            <select id="cmb_expected_branch_id" className="form-select form-select-sm" value={cmb_expected_branch_id} onChange={(e) => { comboOnChange("expt_branch"); validateFields(); }} >
                                                <option value="">Select</option>
                                                <option value="0">Add Record+</option>
                                                {expectedBranchOptions.length !== 0 ?
                                                    <>
                                                        {expectedBranchOptions?.map(cmb_expected_branch_id => (
                                                            <option value={cmb_expected_branch_id.company_branch_id} chk_isSez={`${cmb_expected_branch_id.is_sez}`}>{cmb_expected_branch_id.company_branch_name}</option>
                                                        ))}
                                                    </>
                                                    : ''
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_expected_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col-sm-1 col-2">
                                            <Tooltip title="Refresh" placement="top">
                                                <MDTypography className={`  ${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                                                    <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_expectedBranch') } }} />
                                                </MDTypography>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Company Branch State<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col">

                                            <select id="cmb_expected_branch_state_id" className="form-select form-select-sm" value={cmb_expected_branch_state_id} onChange={(e) => { comboOnChange("expt_branchState"); validateFields(); }} optional='optional' disabled>
                                                <option value="">Select</option>
                                                {expectedBranchStateOptions.length !== 0 ?
                                                    <>
                                                        {expectedBranchStateOptions?.map(cmb_expected_branch_state_id => (
                                                            <option value={cmb_expected_branch_state_id.field_id}>{cmb_expected_branch_state_id.field_name}</option>
                                                        ))}
                                                    </>
                                                    : ''
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_expected_branch_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Company City</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_expected_branch_city_id" className="form-select form-select-sm" value={cmb_expected_branch_city_id} onChange={(e) => { setExpected_branch_city_id(e.target.value); comboOnChange('company_city'); validateFields(); }} optional='optional' disabled>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {expectedBranchCityOptions.length !== 0 ?
                                                    <>
                                                        {expectedBranchCityOptions?.map(cmb_expected_branch_city_id => (
                                                            <option value={cmb_expected_branch_city_id.field_id}>{cmb_expected_branch_city_id.field_name}</option>
                                                        ))}</>
                                                    : ''
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_expected_branch_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>

                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Department<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_department" className="form-select form-select-sm" value={cmb_department} onChange={(e) => { setDepartmentId(e.target.value); comboOnChange('departmentId'); validateFields(); }}>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {departmentIdOPtions.length !== 0 ?
                                                    <>
                                                        {departmentIdOPtions?.map(departmentId => (
                                                            <option value={departmentId.field_id} dpt_head={departmentId.department_head_id}>{departmentId.field_name}</option>

                                                        ))}
                                                    </>
                                                    : ''
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_department" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    {((keyForViewUpdate === 'view' && chk_po_status === 'A' || chk_po_status === 'Z') || keyForViewUpdate === 'approve') ? (
                                        <>
                                            <div className='row'>
                                                <div className='col-sm-4'>
                                                    <Form.Label className="erp-form-label">Approved By</Form.Label>
                                                </div>
                                                <div className='col'>
                                                    <select id="cmb_approved_by_id" className="form-select form-select-sm " value={cmb_approved_by_id} onChange={(e) => { setApproved_by_id(e.target.value); }} optional='optional' disabled='disabled' >
                                                        <option value="">Select</option>
                                                        {approvedByOptions.length !== 0 ?
                                                            <>
                                                                {approvedByOptions?.map(cmb_approved_by_id => (
                                                                    <option value={cmb_approved_by_id.field_id}>{cmb_approved_by_id.field_name}</option>
                                                                ))}
                                                            </>
                                                            : ''
                                                        }
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-sm-4">
                                                    <Form.Label className="erp-form-label">Approved Date </Form.Label>
                                                </div>
                                                <div className="col">
                                                    <Form.Control type="date" id='txt_approved_date' className="erp_input_field" value={txt_approved_date} onChange={e => { setApproved_date(e.target.value); }} optional='optional' disabled='disabled' />
                                                    <MDTypography variant="button" id="error_txt_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </>)
                                        : ''
                                    }
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">PO Status </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="chk_po_status" className="form-select form-select-sm " value={chk_po_status} onChange={(e) => { setPO_status(e.target.value); FnsetMaterialItemStatus(e.target.value); }} disabled >
                                                <option value="P">Pending</option>
                                                <option value="A">Approved</option>
                                                <option value="R">Rejected</option>
                                                <option value="I">Partial Issue</option>
                                                <option value="C">Completed</option>
                                                <option value="X">Canceled</option>
                                                <option value="Z">PreeClosed</option>
                                                <option value="B">Bill Booked</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Status Remark</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" id='txt_status_remark' className="erp_input_field" value={txt_status_remark} onChange={e => setStatus_remark(e.target.value)} disabled={!isApprove} optional='optional' maxLength={1000} />
                                            <MDTypography variant="button" id="error_txt_status_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Mail Status</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Pending" type="radio" lbl="Pending" value="P" name="chk_po_mail_sent_status" checked={chk_po_mail_sent_status === "P"} onClick={() => { setPO_mail_sent_status("P"); }} disabled /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="Send" type="radio" lbl="Send" value="S" name="chk_po_mail_sent_status" checked={chk_po_mail_sent_status === "S"} onClick={() => { setPO_mail_sent_status("S"); }} disabled /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="Failed" type="radio" lbl="Failed" value="F" name="chk_po_mail_sent_status" checked={chk_po_mail_sent_status === "F"} onClick={() => { setPO_mail_sent_status("F"); }} disabled /> </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <Accordion className="mt-3 mt-lg-1" defaultActiveKey="0" activeKey={openContactsAccord ? '0' : null}>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenContactsAccord(!openContactsAccord)}>Supplier's Contact Details</Accordion.Header>
                                                <Accordion.Body className="p-0">
                                                    {
                                                        cmb_supplier_id !== '' && cmb_supplier_id !== '0'
                                                            ? <>
                                                                <div className={`row ps-1 py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' || keyForViewUpdate === 'delete' ? 'd-none' : 'display'}`}>
                                                                    <div className={` d-flex justify-content-start align-items-center`}>
                                                                        <Tooltip title="Add new supplier's contact if doesn't exist in list...!" placement="top">
                                                                            <MDButton type="button" className={`erp-gb-button float-start col-12 col-md-4`} variant="button" fontWeight="regular" onClick={() => {
                                                                                localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                                                localStorage.setItem('supplierIDs', JSON.stringify({ supplierId: cmb_supplier_id, keyForViewUpdate: 'update', compType: 'Master' }))
                                                                            }
                                                                            }>  <Link className={'text-light'} to={{ pathname: "/Masters/FrmSupplier" }} target="_blank" >Add New Supplier's Contact +</Link></MDButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="Refresh supplier contact list...!" placement="top">
                                                                            <MDButton type="button" className={`erp-gb-button float-end col-1 ms-0 ms-md-2 mt-1 mt-md-0`} variant="button" fontWeight="regular" onClick={() => { FnGetSupplierContacts(cmb_supplier_id) }}>Refresh</MDButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : null
                                                    }
                                                    {suppContactDetails.length > 0
                                                        ? <>
                                                            <div className='row'>
                                                                <div className="col-12 erp_table_scroll">
                                                                    <Datatable data={suppContactDetails} columns={suppContactDetailsCols} />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <> <div className='row text-center'>
                                                            <div className="col-12">
                                                                <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                                            </div>
                                                        </div></>
                                                    }

                                                    {
                                                        suppContactDetails.length !== 0 && !['approve', 'view'].includes(keyForViewUpdate)
                                                            ? <>
                                                                <div className="row justify-content-center my-1">
                                                                    <Tooltip title="Confirm selection...!" placement="right">
                                                                        <MDButton type="button" className={`erp-gb-button col-1`} variant="button" fontWeight="regular"
                                                                            onClick={(e) => {
                                                                                let selectedCustCont = $('.selectSuppContactPerson:checked');
                                                                                if (selectedCustCont.length > 0) {
                                                                                    setOpenContactsAccord(false)
                                                                                    $('#error_supplier_contact_persons').hide();
                                                                                } else {
                                                                                    $('#error_supplier_contact_persons').text('Please select atleast one contact person.');
                                                                                    $('#error_supplier_contact_persons').show();
                                                                                }
                                                                            }}
                                                                        >Confirm</MDButton>
                                                                    </Tooltip>
                                                                </div>
                                                            </>
                                                            : null
                                                    }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion >
                                        <MDTypography variant="button" id="error_supplier_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <hr />

                    <Accordion defaultActiveKey="0" activeKey={openDetailsAccord ? '0' : null}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenDetailsAccord(!openDetailsAccord)}>PO Details</Accordion.Header>
                            <Accordion.Body>
                                <div className="d-flex row">
                                    <div className="col-md-4 col-12">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <MDButton type="button" className={`erp-gb-button ${(keyForViewUpdate === '' || keyForViewUpdate === 'copy' || keyForViewUpdate === 'update') && chk_po_creation_type === 'M' ? 'display' : 'd-none'}`} variant="button" id='add-material-id' disabled={isView === false ? false : true}
                                                    fontWeight="regular" onClick={viewBomFilterForm}>Add Materials</MDButton>
                                            </div>
                                            <div className="col-sm-6 d-flex">
                                                <Form.Control type="text" id="txt-detail-data-highliter" className={`erp_input_field txt-filter-bom ${po_details_data.length === 0 ? 'd-none' : 'display'}`} />
                                                <MDButton type="button" className={`erp-gb-button erp_MLeft_btn ${po_details_data.length === 0 ? 'd-none' : 'display'}`} variant="button" onClick={() => scrollToTableRow()}
                                                    fontWeight="regular">Search</MDButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className={`row ${(keyForViewUpdate !== 'view') && chk_po_creation_type === 'A' ? 'display' : 'd-none'}`}>
                                            <div className="col-sm-4 col-12 ">
                                                <Form.Label className="erp-form-label float-end">Indent Nos <span className="required">*</span> </Form.Label>
                                            </div>
                                            <div className="col-sm-8 col-12">
                                                <div className="row">
                                                    <div className="col-12 col-md-8 pe-md-0">
                                                        <div className="select-btn" onClick={() => { if (chk_po_creation_type === 'A') { showCheckboxCombo('indent-order') } }} optional='optional'>
                                                            <span className="form-select form-select-sm" id="">{checkedIndnetNosLength !== 0 ? checkedIndnetNosLength + ' Selected' : 'Select'} </span>
                                                        </div>
                                                        <ul className="list-items" id="indent-order-ul">
                                                            {
                                                                indnetNosList.length > 0
                                                                    ? <>
                                                                        {indnetNosList}
                                                                    </>
                                                                    : <li className="item indent_order_no">
                                                                        <span className="item-text"> No Records Found... </span>
                                                                    </li>
                                                            }
                                                        </ul>
                                                        <MDTypography variant="button" id="error_indnetNos" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>Please Select atleast one...!
                                                        </MDTypography>
                                                    </div>
                                                    <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                        <MDButton type="button" id="show-btn" className="erp-gb-button erp_MLeft_btn" variant="button" onClick={() => { if (chk_po_creation_type === 'A') { ShowPODetailsByIndent() } }}
                                                            fontWeight="regular" disabled={isApprove || keyForViewUpdate === 'view' || chk_po_creation_type === 'M' ? true : false}>Show</MDButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-12">
                                        <MDButton type="button" id="export-btn" className={`erp-gb-button  erp_MLeft_btn ${keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view' && keyForViewUpdate !== 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={ExportToExcel}>Export</MDButton>
                                        <input type="file" id="Excelfile" ref={importFile} onChange={importedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                        <MDButton type="button" id="import-btn" className={`erp-gb-button  erp_MLeft_btn ${keyForViewUpdate !== '' && keyForViewUpdate !== 'copy' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={onImportClick} >Import</MDButton>
                                    </div>
                                </div>
                                <div className="erp-Mt-10">
                                    {po_details_data.length !== 0 ?
                                        <>
                                            {renderPODetailsTbl}
                                        </> :
                                        null
                                    }
                                    {scheduleDataArray.length !== 0 ?
                                        <>
                                            <hr />
                                            <Accordion defaultActiveKey="1" onSelect={FnLoadAccordionData}>
                                                <Accordion.Item eventKey="purchaseOrderSchedules">
                                                    <Accordion.Header className="erp-form-label-md">Material Schedules</Accordion.Header>
                                                    <Accordion.Body className="p-0">
                                                        {renderPOScheduleTbl}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </> : ""
                                    }
                                    {taxationSummaryData.length !== 0 ?
                                        <>
                                            <hr />
                                            <Accordion defaultActiveKey="1" onSelect={FnLoadAccordionData}>
                                                <Accordion.Item eventKey="purchaseOrderTaxation">
                                                    <Accordion.Header className="erp-form-label-md">Taxation Summary</Accordion.Header>
                                                    <Accordion.Body className="p-0">
                                                        {renderPOTaxationSummaryTable}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion >
                                        </> : ""
                                    }
                                    {keyForViewUpdate !== '' && keyForViewUpdate !== 'copy' ?
                                        <>
                                            <hr />
                                            <Accordion defaultActiveKey="1" >
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header className="erp-form-label-md">Track Details</Accordion.Header>
                                                    <Accordion.Body className="p-0">
                                                        {renderPOTrackDetailsTbl}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </> : ""
                                    }

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    {/* Payment terms details */}
                    <hr />
                    <Accordion defaultActiveKey="0" activeKey={openPaymentTermsAccord ? '0' : null} onSelect={(e) => FnLoadAccordionData('paymentTerms')}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenPaymentTermsAccord(!openPaymentTermsAccord)}>Payment Terms</Accordion.Header>
                            <Accordion.Body>
                                <div className={`row py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                    <div className="col-12 col-sm-6">
                                        <input type='checkbox' class="selectAllPayTerms" id="selectAllPayTerms" onClick={(e) => toggleChkAllBoxes('selectAllPayTerms')} disabled={isView === false ? false : true} /> <label class="erp-form-label pb-1"> Select All </label>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <MDButton type="button" className={`erp-gb-button float-sm-end col-1 ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular"
                                            onClick={() => {
                                                sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                setHeaderName('Payment Terms'); // CommonParamterEntry
                                                setShowAddRecModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                                            }}>Add
                                        </MDButton>
                                    </div>
                                </div>
                                {/* <div class="col pt-sm-1">
                                    <input type='checkbox' class="selectAllPayTerms" id="selectAllPayTerms" onClick={(e) => toggleChkAllBoxes('selectAllPayTerms')} disabled={isView === false ? false : true} /> <label class="erp-form-label pb-1"> Select All </label>
                                </div> */}
                                {renderPaymentTermsTable}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <hr />
                    {/* PO terms details */}
                    <Accordion defaultActiveKey="0" activeKey={openCommonTermsAccord ? '0' : null} onSelect={(e) => FnLoadAccordionData('purchaseOrderTerms')}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className="erp-form-label-md" onClick={(e) => setOpenCommonTermsAccord(!openCommonTermsAccord)}>Purchase Order Terms</Accordion.Header>
                            <Accordion.Body>
                                <div className={`row py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                    <div className="col-12 col-sm-6">
                                        <input type='checkbox' class="selectAllPOTerms" id="selectAllPOTerms" onClick={(e) => toggleChkAllBoxes('selectAllPOTerms')} disabled={isView === false ? false : true} /> <label class="erp-form-label pb-1 selectAllPOTerms"> Select All </label>                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <MDButton type="button" className={`erp-gb-button float-sm-end col-1 ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular"
                                            onClick={() => {
                                                sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                setHeaderName('Common Parameter'); // CommonParamterEntry
                                                setShowAddRecModal(true); setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                                            }}>Add
                                        </MDButton>
                                    </div>
                                </div>
                                {/* <div class="col pt-sm-1">
                                    <input type='checkbox' class="selectAllPOTerms" id="selectAllPOTerms" onClick={(e) => toggleChkAllBoxes('selectAllPOTerms')} disabled={isView === false ? false : true} /> <label class="erp-form-label pb-1 selectAllPOTerms"> Select All </label>
                                </div> */}
                                {renderPOTermsTable}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    {keyForViewUpdate !== '' ?
                        <> <hr />
                            <Accordion defaultActiveKey="0" onSelect={(e) => FnLoadAccordionData('documentList')}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className="erp-form-label-md p-0">Document List</Accordion.Header>
                                    <Accordion.Body>
                                        {docData.length !== 0 ? (
                                            renderDocumentTable
                                        ) : (
                                            <div className='row text-center'>
                                                <div className="col-12">
                                                    <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                        No Records Found...
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </>
                        : null
                    }
                    <hr />
                    {/* footer Section Starts*/}
                    <div className="row">
                        <div className='main_heding text-start'>
                            <label className='erp-form-label-lg'>Purchase Order Totals:</label>
                        </div>
                        <form id="po_footer_formId">
                            <div className="row">
                                {/* //first column */}
                                <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Basic Total</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_basic_total" className="erp_input_field text-end" value={txt_basic_total} disabled onChange={(e) => setBasic_total(e.target.value)} />
                                            <MDTypography variant="button" id="error_txt_basic_total" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Transport Amount</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_transportAmount' className="erp_input_field text-end" value={txt_transportAmount} maxLength={19} onChange={(e) => { setTransportAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); if (validateNumericInputs(e)) { calculateGrandTotal(); } }}
                                                onBlur={(e) => { setTransportAmount(validateNumberDateInput.current.decimalNumber((e.target.value).toString(), 4)); if (validateNumericInputs(e)) { calculateGrandTotal(); } }} />
                                            <MDTypography variant="button" id="error_txt_transportAmount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Freight Amount</Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-3">
                                                    <Form.Control type="text" id='txt_freight_amount' className="erp_input_field text-end" value={txt_freight_amount} maxLength={19}
                                                        onChange={(e) => { setFreightAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); calculateGrandTotal(); reverseCalculationForFrieghtAmt(); }} />
                                                    <MDTypography variant="button" id="error_txt_freight_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-3">
                                                    <Form.Check className="erp-form-label" type="checkbox" label={`IsTaxable`} id="chk_is_freight_taxable" checked={chk_is_freight_taxable} onChange={(e) => { SetIsFreightTaxable(e.target.checked); calculateGrandTotal(); }} />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <select id="cmb_freight_hsn_id" className="form-select form-select-sm" value={cmb_freight_hsn_id} disabled={chk_is_freight_taxable ? '' : 'disabled'} onChange={async (e) => { setFreightHSNId(e.target.value); calculateGrandTotal(); }} optional={chk_is_freight_taxable === false ? "optional" : ''} >
                                                        <option value="">Select</option>
                                                        {freightHSNOpts?.map(hsnCode => (
                                                            <option value={hsnCode.hsn_sac_id} hsn_rate={hsnCode.hsn_sac_rate} lbl={hsnCode.hsn_sac_code}>{hsnCode.hsn_sac_code}</option>
                                                        ))}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_freight_hsn_id" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Packing Amount</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_packing_amount" className="erp_input_field text-end" value={txt_packing_amount} maxLength={19} onChange={(e) => { setPackingAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); if (validateNumericInputs(e)) { calculateGrandTotal(); } }}
                                                onBlur={(e) => { setPackingAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); if (validateNumericInputs(e)) { calculateGrandTotal(); } }} />
                                            <MDTypography variant="button" id="error_txt_packing_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Discount Percent & Amount</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="number" id="txt_po_discount_percent" className="erp_input_field text-end" value={txt_po_discount_percent}
                                                onChange={(e) => { if (e.target.value === '') { setPODiscountPercent(e.target.value); } if (validatePoDetails(e, 'PercentageInput')) { handleFooterDiscountPercentChange(e); } }} />
                                            <MDTypography variant="button" id="error_txt_po_discount_percent" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id="txt_po_discount_amount" className="erp_input_field text-end" value={txt_po_discount_amount} maxLength={19}
                                                onChange={(e) => { setPODiscountAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); if (validateNumericInputs(e)) { handleFooterDiscountAmountChange(e); } }} />
                                            <MDTypography variant="button" id="error_txt_po_discount_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Other Amount</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_otherAmount' className="erp_input_field text-end" value={txt_otherAmount} onChange={(e) => { setOtherAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); if (validateNumericInputs(e)) { calculateGrandTotal(); } }}
                                                onBlur={(e) => { setOtherAmount(validateNumberDateInput.current.decimalNumber(e.target.value, 4)); if (validateNumericInputs(e)) { calculateGrandTotal(); } }} />
                                            <MDTypography variant="button" id="error_txt_otherAmount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Taxable Total</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_taxableTotal' className="erp_input_field text-end" value={txt_taxableTotal} disabled onChange={(e) => { setTaxableTotal(validateNumberDateInput.current.decimalNumber(e.target.value, 4)) }} />
                                            <MDTypography variant="button" id="error_txt_taxableTotal" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>


                                </div>
                                {/* //second column */}
                                <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">
                                    {/* <div className="row"> */}
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">CGST Total</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_cgst_total' className="erp_input_field text-end" value={txt_cgst_total} optional='optional' disabled onChange={(e) => setCgstTotal(validateNumberDateInput.current.decimalNumber((e.target.value).toString(), 4))} />
                                            <MDTypography variant="button" id="error_txt_cgst_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">SGST Total </Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_sgst_total' className="erp_input_field text-end" value={txt_sgst_total} optional='optional' disabled onChange={(e) => setSgstTotal(validateNumberDateInput.current.decimalNumber((e.target.value).toString(), 4))} />
                                            <MDTypography variant="button" id="error_txt_sgst_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>

                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">IGST Total</Form.Label>
                                        </div>
                                        <div className='col'>
                                            <Form.Control type="text" id='txt_igst_total' className="erp_input_field text-end" value={txt_igst_total} optional='optional' disabled onChange={(e) => setIgstTotal(validateNumberDateInput.current.decimalNumber((e.target.value).toString(), 4))} />
                                            <MDTypography variant="button" id="error_txt_igst_total" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>

                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Grand Total & Round Off</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_grand_amount' className="erp_input_field text-end" disabled value={txt_grand_amount} onChange={e => { setGrandTotal(e.target.value) }} />
                                            <MDTypography variant="button" id="error_txt_grand_amount" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id='txt_round_off' className="erp_input_field text-end" disabled value={txt_round_off} onChange={e => { setRoundOff(validateNumberDateInput.current.decimalNumber(e.target.value, 4)) }} />
                                            <MDTypography variant="button" id="error_txt_round_off" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    {/* </div> */}
                                    <div className="row">
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label">Agent</Form.Label>
                                        </div>
                                        <div className="col-sm-7 col-10">
                                            <select id="cmb_agentId" className="form-select form-select-sm" value={cmb_agentId} onChange={(e) => { setAgentId(e.target.value); comboOnChange('agent'); validateFields(); }} >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {agents?.map(cmb_agentId => (
                                                    <option value={cmb_agentId.field_id}>{cmb_agentId.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_agentId" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className="col-sm-1 col-2">
                                            <Tooltip title="Refresh" placement="top">
                                                <MDTypography className={`  ${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                                                    <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_agent') } }} />
                                                </MDTypography>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Agent Percent & Agent Paid Status</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="text" id="txt_agentPercent" className="erp_input_field text-end" value={txt_agentPercent} maxLength={2}
                                                onChange={(e) => { if (validateNumberDateInput.current.percentValidate(e.target.value)) { setAgentPercent(e.target.value); } }} />
                                            <MDTypography variant="button" id="error_txt_agentPercent" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className='col'>
                                            <select id="chk_agentPaidStatus" className="form-select form-select-sm" value={chk_agentPaidStatus} onChange={(e) => { setAgentPaidStatus(e.target.value); validateFields(); }} >
                                                <option value="">select</option>
                                                <option value="P">Pending</option>
                                                <option value="A">Approved</option>
                                                <option value="C">Completed</option>
                                                <option value="X">Canceled</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {/* //Third column */}
                                <div className="col-lg-4 col-md-6 col-12 erp_form_col_div">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">PO Acceptance Status</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Pending" type="radio" lbl="Pending" value="P" name="chk_po_mail_sent_status" checked={chk_PO_acceptance_status === "P"} onClick={() => { setPO_acceptance_status("P"); }} disabled /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="Approved" type="radio" lbl="Approved" value="A" name="chk_po_mail_sent_status" checked={chk_PO_acceptance_status === "A"} onClick={() => { setPO_acceptance_status("A"); }} disabled /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="Canceled" type="radio" lbl="Canceled" value="X" name="chk_po_mail_sent_status" checked={chk_PO_acceptance_status === "X"} onClick={() => { setPO_acceptance_status("X"); }} disabled /> </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Other Terms Conditions</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" id='txt_other_term_con' className="erp_input_field" value={txt_other_term_con} onChange={e => { setOther_term_con(e.target.value) }} optional='optional' maxLength={1000} />

                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">Remark</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" id='txt_remark' className="erp_input_field" value={txt_remark} onChange={e => setRemark(e.target.value)} optional='optional' maxLength={1000} />

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Form.Label className="erp-form-label">PO IsActive</Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" value="true" name="chk_po_isactive" checked={chk_po_isactive === true} onClick={() => { setPOIsActive(true); }} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" value="false" name="chk_po_isactive" checked={chk_po_isactive === false} onClick={() => { setPOIsActive(false); }} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </>
                {/* Total Section Ends*/}
                <div className="erp_frm_Btns">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton type="button" id="save-btn" className="erp-gb-button erp_MLeft_btn" variant="button" onClick={() => addPO('A')} fontWeight="regular"  >Save</MDButton>
                    <MDButton type="button" id="reject-btn" className={`erp-gb-button erp_MLeft_btn ${isApprove ? 'display' : 'd-none'}`} variant="button" onClick={() => addPO('R')}>Rejected</MDButton>
                    <MDButton type="button" id="viewdocument-id" className={`erp-gb-button erp_MLeft_btn ${po_master_transaction_id === 0 || po_master_transaction_id === '' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={viewDocumentForm}>Upload Document</MDButton>&nbsp;
                    <MDButton type="button" id="clear-btn-id" className={`erp-gb-button erp_MLeft_btn  ${keyForViewUpdate === '' ? 'display' : 'd-none'}`} variant="button" onClick={FnClearFormData} fontWeight="regular">Clear</MDButton>
                    <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' ? chk_po_status !== 'P' && chk_po_status !== 'R' ? 'display' : 'd-none' : 'd-none'}`} variant="button" fontWeight="regular" id='print-btn-id' onClick={() => {
                        printInvoice(true);
                    }} disabled={chk_po_status !== "P" ? '' : 'disabled'} >Print<FiDownload className="erp-download-icon-btn" /></MDButton>
                    {keyForViewUpdate === 'view' && chk_po_status === 'A' && chk_PO_acceptance_status !== 'A'
                        ? <MDButton type="button" className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" onClick={() => acceptPurchaseOrder()}>Accept</MDButton>
                        : null
                    }
                    <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => setShow(true)}>Delete</MDButton>

                </div >


                {/* ADD Filter modal */}
                <Modal size="lg" className='erp_document_Form' show={showBomFilterForm} onHide={handleCloseBomFilterForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Product List</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseBomFilterForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <React.Fragment>
                            <ProductList closeModal={handleCloseBomFilterForm} props={{ selectedProduct: ['smv_product_rm_summary', 'smv_product_fg_summary',] }} /> {/* 'smv_product_sr_summary' */}
                        </React.Fragment>
                    </Modal.Body>
                </Modal>

                {/* Add new Record Popup */}
                <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>{modalHeaderName}</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button></span>
                    </Modal.Header>
                    <Modal.Body className='erp_city_modal_body'>
                        {displayRecordComponent()}
                    </Modal.Body>
                    <Modal.Footer>
                        <MDButton type="button" onClick={handleCloseRecModal} className="btn erp-gb-button" variant="button"
                            fontWeight="regular">Close</MDButton>

                    </Modal.Footer>
                </Modal >

                {/* Modal for schedule quantity */}
                <Modal size="lg" className='erp_document_Form' show={showSChedules} onHide={handleCloseSchedulesPopup} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Add Schedules</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseSchedulesPopup}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <dl className="row mb-0">
                            <div className="col-md-8 col-sm-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-12 col-md-3 erp-form-label-md-lg">Material Name:</dt>
                                    <dd className="col-sm-12 col-md-9 erp-form-label" id='productMatName'></dd>
                                </dl>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-12 col-md-6 erp-form-label-md-lg"> Total Quantity:</dt>
                                    <dd className="col-sm-12 col-md-6 erp-form-label" id='productMatQty'></dd>
                                </dl>
                            </div>

                        </dl>
                        <dl className="row mb-0">
                            <div className="col-md-8 col-sm-12">
                                <dl className="row mb-0">
                                    <dt className="col-sm-6 col-md-3 erp-form-label-md-lg"> Material Unit:</dt>
                                    <dd className="col-sm-6 col-md-9 erp-form-label" id='productMatUnit'></dd>  </dl>
                            </div>
                            <div className="col-md-4 col-sm-12 d-none">
                                <dl className="row mb-0">
                                    <dt className="col-sm-12 col-md-6 erp-form-label-md-lg">Total Weight:</dt>
                                    <dd className="col-sm-12 col-md-6 erp-form-label" id='productMatTotalWeight'></dd>
                                </dl>
                            </div>
                        </dl>
                        <dl className="row mb-0 d-none">
                            <div className="col-md-8 col-sm-12">
                                <dl className="row mb-0">
                                    <dt className="col-md-3 col-sm-12 erp-form-label-md-lg">Std. Weight:</dt>
                                    <dd className="col-md-9 col-sm-12  erp-form-label" id='productMatStdWeight'></dd>
                                </dl>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <dl className="row mb-0 d-none">
                                    <dt className="col-sm-12 col-md-6 erp-form-label-md-lg">SO SrNo:</dt>
                                    <dd className="col-sm-12 col-md-6 erp-form-label" id='soSrNo'></dd>
                                </dl>
                            </div>
                        </dl>
                        <div className='row table-responsive'>
                            <Table className="erp_table" id="scheduleTable" bordered striped >
                                <thead className='erp_table_head erp_table_th'>
                                    <tr>
                                        <th>Action</th>
                                        <th>Schedule Quantity</th>
                                        <th className="d-none">Schedule Weight</th>
                                        <th>Expected Schedule Date</th>
                                        <th>Material Receipt Quantity</th>
                                        <th className="d-none">Material Receipt Weight</th>
                                        <th>Material Receipt Date</th>
                                        <th>Schedule Item Status</th>
                                        <th>Remark</th>
                                    </tr>
                                </thead>

                                <tbody role="rowgroup">
                                    <tr scheduleTrCount={scheduleRowCount} id="RMSchedulesEntry-tr-1">
                                        <td style={{ width: '100px' }}><IoRemoveCircleOutline className='erp_trRemove_icon' /> <IoAddCircleOutline className='erp_trAdd_icon disableClass' onClick={() => addNewScheduleRow()} /></td>
                                    </tr>

                                </tbody>

                            </Table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='erp-modal-footer'>
                        <MDButton type="button" className="erp-gb-button" variant="button"
                            fontWeight="regular" onClick={() => schedule()}>Schedule</MDButton>
                    </Modal.Footer>
                </Modal>
                {/* Document modal */}
                <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <DocumentF group_id={txt_PO_no !== 0 ? txt_PO_no.replaceAll('/', '_') : null} document_group={documentGroup} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Delete Modal */}
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
                    <Modal.Body className='text-center'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Do you wish to delete this record ?</h6>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={handleClose}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deletePO}>Delete</Button>
                    </Modal.Footer>

                </Modal>
                {/* <ConfirmationModal close={() => closeConfirmationModal('Exit')} confirmation={() => closeConfirmationModal('Export&Exit')} show={[showConfirmationModal, succMsg, modalOrderDetails, messageForConfirmation]} /> */}
                <SuccessModal handleCloseSuccessModal={() => closeConfirmationModal('Exit')} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />
                <MailSentModal handleCloseMailSentModal={() => handleCloseMailSentModal()} sendConfirm={() => sendEmail()} show={[showMailSentModal, succMsg, modalOrderDetails]} />
            </DashboardLayout>
        </>
    )
}

export default FrmManualPOEntry
